import React, { useEffect } from 'react';
import StyledLiveShareMap from '../../../styles/LiveShare/Common/StyledLiveShareMap';
import useGetLiveShareMap from '../../../hooks/LiveShare/useGetLiveShareMap';
import getAuthToken from '../../../services/Common/Tracking/Get/getAuthToken';
import MapComponent from './MapComponent';

const LiveShareMap = ({ ...props }) => {
  /** Get props data */
  const { liveShareData, setMessageModalOpener, setMessageModalMessage } =
    props;

  const { initMap, locationProvider, mapView } = useGetLiveShareMap({
    projectId: 'nglmobilitytest',
    tripId: liveShareData?.google_tracking_id,
    elementId: 'liveShareMap',
    authTokenFetcher: getAuthToken,
    authTokenFetcherParam: {
      woNo: liveShareData?.wo_no,
    },
    setMessageModalOpener,
    setMessageModalMessage,
  });

  return (
    <StyledLiveShareMap data-testid="mapWrapper">
      <MapComponent googleTrackingID={liveShareData?.google_tracking_id} />
    </StyledLiveShareMap>
  );
};

export default LiveShareMap;
