import { useQuery } from '@tanstack/react-query';
import getMapList from '../../../services/Trace/Map/Get/getMapList';
import traceKeys from '../keys';

const useGetTraceMapList = ({ target }) => {
  const { isLoading: loadingMapList, data: traceMapList } = useQuery({
    queryKey: traceKeys.mapList({ target }),
    queryFn: () => getMapList({ target }),
    refetchOnWindowFocus: false,
  });

  return { loadingMapList, traceMapList };
};

export default useGetTraceMapList;
