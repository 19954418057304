import rowPinnedCellRendererWithCellTypeGenerator from './rowPinnedCellRendererWithCellTypeGenerator';

/**
 * @description
 * Function that generate cell renderer when row pinned.
 * @param {object} gridParam
 * Parameter that contains the grid object(API).
 * @param {string} cellType
 * Grid cell type.
 * text: Pure text component
 * select: Select component
 * autoComplete: Auto complete component
 * date: Date component
 * time: Time component
 */
const rowPinnedCellRendererGenerator = ({
  gridParam,
  cellType,
  adornment,
  regex,
  ...props
}) => {
  const defaultPinnedRowCellSettings = {
    component: rowPinnedCellRendererWithCellTypeGenerator({ cellType }),
    params: {
      maxLength: props?.maxLength || 20,
      option: props?.option || [],
      cellType,
      adornment,
      regex,
      ...props,
    },
    filter: false,
    floatingFilter: false,
    sortable: false,
    editable: false,
    suppressRowClickSelection: true,
    onCellDoubleClicked: null,
  };

  return defaultPinnedRowCellSettings;
};

export default rowPinnedCellRendererGenerator;
