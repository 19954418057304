import React from 'react';

const LiveShareETA = ({ ...props }) => {
  /** Get props data */
  const { liveShareData } = props;

  return (
    <div className="pt-[46px] pb-[54px]">
      <p
        className="pl-[7px] text-[#000] text-[19px] font-bold"
        data-testid="ETALabelWrapper"
      >
        Estimated Time of Arrival
      </p>
      <p
        className="pt-[10px] text-[#C24D4D] text-[39px] font-medium"
        data-testid="ETAWrapper"
      >
        {liveShareData?.eta || '---- -- -- --:--:--'}
      </p>
    </div>
  );
};

export default LiveShareETA;
