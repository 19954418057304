import React from 'react';
import { useFormContext } from 'react-hook-form';
import CommonButton from '../../../../CommonButton/CommonButton';
import updateButtonDisabled from '../../../../../utils/Common/Handler/updateButtonDisabled';

const ButtonContent = ({ handleSave }) => {
  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = useFormContext();

  return (
    <div className="flex justify-center">
      <div className="mr-[18px]">
        <CommonButton category="clear" onClick={() => reset()}>
          Clear
        </CommonButton>
      </div>
      <CommonButton
        type="button"
        category="save"
        preventDupClick
        onClick={({ buttonRef }) => {
          if (!isValid && buttonRef)
            updateButtonDisabled({ ref: buttonRef, disabled: false });
          handleSubmit(data => handleSave({ ...data, buttonRef }))();
        }}
      >
        Save
      </CommonButton>
    </div>
  );
};

export default ButtonContent;
