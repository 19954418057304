import { useEffect, useState } from 'react';

const useGetLoadingOpener = ({ ...props }) => {
  /** Get loading related value */
  const {
    isMainLoading,
    isMainPending,
    isMainRefetching,
    isPostSavedPending,
    isPostMainPending,
  } = props;

  /** Common loading opener */
  const [loadingOpener, setLoadingOpener] = useState(false);

  useEffect(() => {
    if (isMainLoading) setLoadingOpener(true);
  }, [isMainLoading]);
  useEffect(() => {
    if (isMainPending) setLoadingOpener(true);
  }, [isMainPending]);
  useEffect(() => {
    if (isMainRefetching) setLoadingOpener(true);
  }, [isMainRefetching]);
  useEffect(() => {
    if (isPostSavedPending) setLoadingOpener(true);
  }, [isPostSavedPending]);
  useEffect(() => {
    if (isPostMainPending) setLoadingOpener(true);
  }, [isPostMainPending]);

  /** Turn off common loading component */
  useEffect(() => {
    if (
      !isMainLoading &&
      !isMainPending &&
      !isMainRefetching &&
      !isPostSavedPending &&
      !isPostMainPending
    )
      setLoadingOpener(false);
  }, [
    isMainLoading,
    isMainPending,
    isMainRefetching,
    isPostSavedPending,
    isPostMainPending,
  ]);

  return { loadingOpener, setLoadingOpener };
};

export default useGetLoadingOpener;
