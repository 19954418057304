import React from 'react';
import CommonMessageModal from '../../../../../CommonMessageModal/CommonMessageModal';
import CompleteConfirmModalContent from './CompleteConfirmModalContent';
import CompleteConfirmModalButton from './CompleteConfirmModalButton';

const CompleteConfirmModal = ({ ...props }) => {
  const { open, setOpen, isCompleted, mutator, woNo } = props;

  return (
    open && (
      <CommonMessageModal
        open={open}
        setOpen={setOpen}
        header="Confirmation"
        content={<CompleteConfirmModalContent isCompleted={isCompleted} />}
        buttonContent={
          <CompleteConfirmModalButton
            setOpen={setOpen}
            mutator={mutator}
            woNo={woNo}
          />
        }
      />
    )
  );
};

export default CompleteConfirmModal;
