import React, { useEffect } from 'react';
import * as ReactDOMServer from 'react-dom/server';
import JobInfoWindow from '../../../components/Trace/Module/JobModule/JobInfoWindow';
import '../../../components/Trace/traceStyle.css';

const useGetMapContent = ({
  projectId,
  authTokenFetcher,
  setAlertModalOpen,
  setAlertModalMessage,
  setIsSuccess,
  mapRef,
  checkedList,
  dataList,
  checkedDriverList,
}) => {
  let mapView = null;

  useEffect(() => {
    let deliveryVehicleFilter = '';
    if (checkedList.length === 1) {
      deliveryVehicleFilter = `attributes.vehicle_id = "${checkedList[0]}"`;
    } else if (checkedList.length > 1) {
      deliveryVehicleFilter = checkedList
        .map(vehicleId => `attributes.vehicle_id = "${vehicleId}"`)
        .join(' OR ');
    } else {
      deliveryVehicleFilter = 'attributes.vehicle_id = ""';
    }

    const deliveryFleetLocationProvider =
      new google.maps.journeySharing.FleetEngineDeliveryFleetLocationProvider({
        projectId,
        authTokenFetcher,

        locationRestriction: {
          north: 49,
          east: -66,
          south: 24,
          west: -125,
        },
        deliveryVehicleFilter,
      });

    const deliveryVehicleLocationProvider =
      new google.maps.journeySharing.FleetEngineDeliveryVehicleLocationProvider(
        {
          projectId,
          authTokenFetcher,
        },
      );

    mapView = new google.maps.journeySharing.JourneySharingMapView({
      element: mapRef.current,
      locationProviders: [
        deliveryFleetLocationProvider,
        deliveryVehicleLocationProvider,
      ],
      mapOptions: {
        center: { lat: 39.8, lng: -98.6 },
        zoom: 5,
        styles: [
          {
            featureType: 'road.arterial',
            elementType: 'geometry',
            stylers: [{ color: '#CCFFFF' }],
          },
        ],
      },
    });

    const infoWindow = new google.maps.InfoWindow({ disableAutoPan: true });

    deliveryFleetLocationProvider.deliveryVehicleMarkerCustomization =
      params => {
        if (!params?.marker?.getIcon().url) {
          params?.marker?.setIcon({
            url: '/assets/images/traceIcon/truck-fleet.svg',
            scaledSize: new google.maps.Size(48, 48),
          });
        }
        if (params?.isNew) {
          const driver = checkedDriverList.find(
            item => item?.vehicleId === params?.vehicle?.attributes?.vehicle_id,
          )?.driver;

          const label = {
            text: driver,
            color: 'white',
            fontSize: '14px',
            fontWeight: 'bold',
            className: 'Label',
          };

          params?.marker?.setLabel(label);
          params?.marker?.addListener('click', () => {
            params?.marker?.setLabel();
            mapView?.map?.setCenter(params?.marker?.getPosition());
            mapView?.map?.setZoom(15);
            const matchingData = dataList?.find(
              item => item?.key === params?.vehicle?.attributes?.vehicle_id,
            );
            const content = ReactDOMServer?.renderToString(
              <JobInfoWindow
                data={matchingData?.data}
                marker={params?.marker}
              />,
            );
            infoWindow?.setContent(content);
            infoWindow?.open(mapView?.map, params?.marker);

            infoWindow?.addListener('closeclick', () => {
              params?.marker?.setLabel(label);
            });
          });
        }
      };

    deliveryFleetLocationProvider?.addListener('error', e => {
      setIsSuccess(false);
      setAlertModalMessage('Error with fleet tracking.');
      setAlertModalOpen(true);
    });

    deliveryVehicleLocationProvider?.addListener('error', e => {
      setIsSuccess(false);
      setAlertModalMessage('Error with vehicle tracking.');
      setAlertModalOpen(true);
    });

    return () => {
      mapView?.removeLocationProvider(deliveryFleetLocationProvider);
      mapView?.removeLocationProvider(deliveryVehicleLocationProvider);
    };
  }, [
    projectId,
    authTokenFetcher,
    mapRef,
    setAlertModalOpen,
    setAlertModalMessage,
    checkedList,
  ]);

  return { mapView };
};

export default useGetMapContent;
