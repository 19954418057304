import { VALIDATE_FAILED_CONTAINER_NUMBER } from '../../../constants/Order/Common/message';
import checkContainerNumberConstraint from '../../Common/Handler/checkContainerNumberConstraint';

/**
 * @description
 * Function to check container and chassis constraint
 * @param {object} orderPostForm
 * Order post form data
 * @param {object} modalMessageSetter
 * Setter to display in the modal
 * @returns {boolean}
 * True if container and chassis constraint is valid
 */
const checkContainerChassisConstraint = ({
  orderPostForm,
  modalMessageSetter,
}) => {
  const { setMessageModalOpen, setModalMessage } = modalMessageSetter;
  // 0. Check Post Form Key
  /** @type {list} Key list of post form */
  const orderPostFormKey = Object.keys(orderPostForm);

  // 1. Check Equip key
  const getOrderPostFormEquip = orderPostForm?.equips;
  const getEquipKey = Object.keys(getOrderPostFormEquip?.[0]);
  if (getEquipKey?.includes('cont_no')) {
    for (let i = 0; i < getOrderPostFormEquip?.length; i++) {
      if (
        !checkContainerNumberConstraint({
          containerNo: getOrderPostFormEquip?.[i]?.cont_no,
          containerSize: getOrderPostFormEquip?.[i]?.size,
          containerType: getOrderPostFormEquip?.[i]?.type,
        })
      ) {
        setMessageModalOpen(true);
        setModalMessage(VALIDATE_FAILED_CONTAINER_NUMBER);
        return false;
      }
    }
  }

  return true;
};

export default checkContainerChassisConstraint;
