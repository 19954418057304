import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import masterKeys from '../keys';
import { commonCompanyAPI } from '../../../services/Common/Company/commonCompanyAPI';
import createObjOption from '../../../utils/Master/Company/Generator/createObjOption';

const useGetTermsList = ({ setTermsOption }) => {
  const result = useQuery({
    queryKey: masterKeys?.termsList(),
    queryFn: () => commonCompanyAPI.getCommonTermsList(),
    staleTime: 0,
  });

  useEffect(() => {
    if (!result?.isError && result?.isSuccess)
      setTermsOption(createObjOption(result?.data?.data, 'terms'));
  }, [result?.isError, result?.isSuccess]);

  return result;
};

export default useGetTermsList;
