import React from 'react';
import CommonButton from '../../../../CommonButton/CommonButton';

const AdvancedSearchButtonContent = ({ setAdvancedSearchOpen }) => {
  return (
    <div className="flex justify-center space-x-[18px]">
      <div>
        <CommonButton
          category="cancel"
          onClick={() => {
            setAdvancedSearchOpen(false);
          }}
        >
          Cancel
        </CommonButton>
      </div>
      <div>
        <CommonButton
          type="submit"
          category="search"
          onClick={() => {
            setAdvancedSearchOpen(false);
          }}
        >
          Search
        </CommonButton>
      </div>
    </div>
  );
};

export default AdvancedSearchButtonContent;
