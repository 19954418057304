export const MANIFEST_STATUS = {
  PAID: 'bg-[#8BC68B]',
  'PARTIAL PAID': 'bg-[#5C8CD4]',
  INVOICED: 'bg-[#AF7AD7]',
  'IN PROCESS': 'bg-[#EFB253]',
  'IN REVIEW': 'bg-[#A1A1A1]',
  REJECTED: 'bg-[#E8454C]',
};

export const MANIFEST_DETAIL_HEADERS = {
  status: 'STATUS',
  bill_date: 'INVOICE DATE',
  bill_no: 'BILL #',
  acct_code: 'ACCT CODE',
  acct_code_name: 'ACCT CODE NAME',
  amt: 'RATE',
  bill_amt: 'TOTAL AMT',
  paid_amt: 'PAID AMT',
  description: 'DESC',
};

export const MANIFEST_DETAIL_CELL_WIDTH = {
  status: 'w-[90px]',
  bill_date: 'w-[111px]',
  bill_no: 'w-[161px]',
  acct_code: 'w-[87px]',
  acct_code_name: 'w-[181px]',
  amt: 'w-[76px]',
  bill_amt: 'w-[116px]',
  paid_amt: 'w-[116px]',
  description: 'w-[153px]',
};
