import React from 'react';
import { useForm } from 'react-hook-form';
import CommonButton from '../../../CommonButton/CommonButton';

const MemoTextField = ({
  memoContent,
  id,
  isEditable,
  isCreator,
  patchWOMemoMutator,
}) => {
  const { register, watch } = useForm();

  return (
    <div>
      <textarea
        aria-multiline="true"
        rows={2}
        defaultValue={memoContent}
        {...register('content')}
        className="w-[282px] my-0.5 h-[50px] resize-none outline-none text-[11px] font-[500] bg-[#F2F4F8] pt-[10px] px-[18px]
    "
        disabled={!(isEditable && isCreator)}
      />
      {isEditable && isCreator && (
        <div className="mt-[25px] mr-[15px] flex justify-end space-x-1.5">
          <div>
            <CommonButton
              size="small"
              preventDupClick
              onClick={({ buttonRef }) => {
                patchWOMemoMutator.mutate({
                  action: 'delete',
                  id,
                  buttonRef,
                });
              }}
              borderColor="#B74747"
              category="redDelete"
            >
              Delete
            </CommonButton>
          </div>
          <div>
            <CommonButton
              size="small"
              category="save"
              preventDupClick
              onClick={({ buttonRef }) => {
                patchWOMemoMutator.mutate({
                  action: 'update',
                  id,
                  content: watch('content'),
                  buttonRef,
                });
              }}
            >
              Save
            </CommonButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default MemoTextField;
