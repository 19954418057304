import handleQueryError from '../../../utils/Common/Handler/handleQueryError';
import cusReportDefaultAPI from '../api';

const postEmailList = async ({ postData }) => {
  try {
    const response = await cusReportDefaultAPI.post(
      `setting/email-list`,
      postData,
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default postEmailList;
