import styled from 'styled-components';

const StyledBCDetailBillingInfoPaymentModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 572px;
  height: 100%;
  padding: 20px;
  background-color: #f9f9f9;
  margin-bottom: 10px;
`;

export default StyledBCDetailBillingInfoPaymentModalContainer;
