const handleRowDelete = ({
  delType,
  woNo,
  documentList,
  documentMutator,
  buttonRef,
}) => {
  const docData = {};
  const newList = [...documentList].filter(doc => doc.type !== delType);

  docData.doc = JSON.stringify(newList);

  documentMutator({
    woNo,
    docData,
    buttonRef,
  });
};

export default handleRowDelete;
