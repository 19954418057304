import React, { useEffect, useState } from 'react';
import APModalTableContentRow from '../APModalTableContentRow';
import APModalTableContentHeader from '../APModalTableContentHeader';

const APModalTableContent = ({
  apInputData,
  apTableContent,
  setAPTableContent,
  ...props
}) => {
  /** AP Input Data */
  const { product_codes: productCodes = [] } = apInputData || {};

  return (
    <div className="w-[502px] mt-[18px]">
      <APModalTableContentHeader
        apTableContent={apTableContent}
        setAPTableContent={setAPTableContent}
      />
      {apTableContent?.map((apRecommendation, index) => {
        return (
          <APModalTableContentRow
            key={`${String(apRecommendation?.amt)} ${String(
              apRecommendation?.productCode,
            )} ${String(index)}`}
            index={index}
            data={apRecommendation}
            productCodes={productCodes}
            isLastRow={index === apTableContent.length - 1}
            apTableContent={apTableContent}
            setAPTableContent={setAPTableContent}
          />
        );
      })}
    </div>
  );
};

export default APModalTableContent;
