const handleReset = controller => {
  controller.reset({
    keepDefaultValues: true,
  });

  const fileFields = document.querySelectorAll('input[type="file"]');
  fileFields.forEach(field => {
    field.value = '';
  });
};

export default handleReset;
