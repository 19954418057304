import { useMutation } from '@tanstack/react-query';
import patchChassisStatus from '../../../../services/Acct/ChassisReconcile/Patch/patchStatus';
import useControlToast from '../../../Common/Toast/useControlToast';
import { SUCCESS_UPDATE } from '../../../../constants/Common/successMessage';
import updateGridData from '../../../../utils/Common/Handler/updateGridData';
import checkIncludingStatus from '../../../../utils/Acct/Common/Handler/checkIncludingStatus';
import resetRow from '../../../../utils/Common/Handler/resetRow';

const usePatchStatus = ({ gridRef, setErrorModalOptions, queryObject }) => {
  const { setErrorModalOpen, setErrorMessage, setIsSuccess } =
    setErrorModalOptions;
  const { addToast } = useControlToast();

  const mutate = useMutation({
    mutationFn: patchChassisStatus,
    onSuccess: (data, variables) => {
      const isIncludeStatus = checkIncludingStatus({
        queryObject,
        status: variables?.patchData?.status,
      });

      updateGridData({
        variables,
        newData: { seq: variables?.seq, ...variables?.patchData },
        isUpdate: isIncludeStatus,
      });

      addToast({ message: SUCCESS_UPDATE });
    },
    onError: error => {
      resetRow({ gridRef });
      setIsSuccess(false);
      setErrorMessage(error?.message);
      setErrorModalOpen(true);
    },
  });
  return { ...mutate };
};

export default usePatchStatus;
