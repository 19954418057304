import { useQuery } from '@tanstack/react-query';
import getPerdiemReconcileList from '../../../../services/Acct/PerdiemReconcile/Get/getPerdiemReconcileList';
import acctKeys from '../../keys';
import queryStringGenerator from '../../../../utils/Acct/Common/Generator/queryStringGenerator';

const useGetPerdiemReconcileList = ({ queryObject }) => {
  const queryString = queryStringGenerator({ queryObject });

  const {
    data: perdiemReconcileList,
    isLoading,
    isFetching,
    refetch: refetchPerdiemReconcileList,
  } = useQuery({
    queryKey: acctKeys.perdiemReconcileList({ queryString }),
    queryFn: () => getPerdiemReconcileList({ queryString }),
    enabled: !!queryString,
    refetchOnWindowFocus: false,
  });

  return {
    perdiemReconcileList,
    isLoading,
    isFetching,
    refetchPerdiemReconcileList,
  };
};

export default useGetPerdiemReconcileList;
