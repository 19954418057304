import {
  CELL_TYPE_AUTO_COMPLETE,
  CELL_TYPE_SELECT,
  CELL_TYPE_TEXT,
} from '../../../../constants/Common/DataGrid/cellType';
import { REGEX_NUMBER_MINUS_DECIMAL } from '../../../../constants/Common/regex';
import {
  DRIVER_POSITION,
  DRIVER_POSITION_OBJECT,
} from '../../../../constants/Master/APRate/elements';
import convertStandardCodeToText from '../../../Common/convertStandardCodeToText';
import cellEditorGenerator from '../../../Common/Datagrid/Generator/cellEditorGenerator';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';

const createMasterAPRateColDefs = nglCneeList => {
  return [
    {
      headerName: 'POSITION',
      field: 'category',
      width: 90,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_SELECT,
          option: DRIVER_POSITION,
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_SELECT,
          option: DRIVER_POSITION,
        }),
      valueGetter: params =>
        convertStandardCodeToText(
          params.data.category,
          DRIVER_POSITION,
          DRIVER_POSITION_OBJECT,
        ) || params.data.category,
    },
    {
      headerName: 'FROM LOC',
      field: 'from_location',
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_AUTO_COMPLETE,
          option: nglCneeList,
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_AUTO_COMPLETE,
          option: nglCneeList,
        }),
      valueGetter: params => {
        return !params.data.from_location?.includes('/')
          ? nglCneeList.find(
              company =>
                company.value.split('/')[0] === params.data.from_location,
            )?.label
          : params.data.from_location;
      },
    },
    {
      headerName: 'TO LOC',
      field: 'to_location',
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_AUTO_COMPLETE,
          option: nglCneeList,
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_AUTO_COMPLETE,
          option: nglCneeList,
        }),
      valueGetter: params => {
        return !params.data.to_location?.includes('/')
          ? nglCneeList.find(
              company =>
                company.value.split('/')[0] === params.data.to_location,
            )?.label
          : params.data.to_location;
      },
    },
    {
      headerName: 'RATE',
      field: 'rate',
      width: 85,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          customStyle: {
            textAlign: 'right',
          },
          adornment: {
            position: 'start',
            content: '$',
          },
          regex: REGEX_NUMBER_MINUS_DECIMAL,
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          customStyle: {
            textAlign: 'right',
          },
          adornment: {
            position: 'start',
            content: '$',
          },
          regex: REGEX_NUMBER_MINUS_DECIMAL,
        }),
    },
  ];
};
export default createMasterAPRateColDefs;
