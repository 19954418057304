import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import masterDefaultApi from '../../api';

/**
 * It sends a post request to the server with the data, and if it's successful, it returns true,
 * otherwise it returns an empty object.
 * @returns The return value is a Promise.
 */
const postDriverData = async data => {
  try {
    const response = await masterDefaultApi.post('driver', data);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }), { cause: error });
  }
};

export default postDriverData;
