import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import patchSchedule from '../../../../services/BCDetail/DetailInfo/Schedule/Patch/patchSchedule';
import bcDetailKeys from '../../keys';
import useControlToast from '../../../Common/Toast/useControlToast';
import { SUCCESS_DELETE } from '../../../../constants/Common/successMessage';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';

/**
 * @description
 * Custom hook to delete schedule
 * @returns
 * Mutation utility
 */
const usePatchDeleteSchedule = ({
  setDeleteModalOpener,
  setLoadingOpener,
  setMessageModalOpener,
  setMessageModalMessage,
  setSelectedScheduleSeq,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  const mutator = useMutation({
    mutationFn: patchSchedule,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
      setDeleteModalOpener(false);
      setSelectedScheduleSeq(null);
    },
    onSuccess: () => {
      queryClient?.invalidateQueries({ queryKey: bcDetailKeys?.all });
      setLoadingOpener(false);
      addToast({ message: SUCCESS_DELETE });
    },
    onError: error => {
      setLoadingOpener(false);
      setMessageModalMessage(error?.message);
      setMessageModalOpener(true);
    },
  });

  /** Control loading modal */
  useEffect(() => {
    if (mutator?.isPending) {
      setLoadingOpener(true);
    } else if (!mutator?.isPending) {
      setLoadingOpener(false);
    }
  }, [mutator?.isPending]);

  return { ...mutator };
};

export default usePatchDeleteSchedule;
