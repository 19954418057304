import styled from 'styled-components';

const StyledStatContentsContainer = styled.div`
  display: flex;
  column-gap: 2px;

  .row-title {
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 14px;
    min-width: 110px;
    width: 110px;
    height: 25px;
    font-size: 12px;
    font-weight: 400;
    color: ${props =>
      props?.isCellHovered ? props?.divisionColor?.text : '#222'};
    background-color: ${props =>
      props?.isCellHovered ? props?.divisionColor?.background : '#fff'};
  }

  .cell {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 110px;
    width: 110px;
    height: 25px;
    font-size: 12px;
    font-weight: 400;
    color: #222222;
    background-color: #fff;
  }

  .cell:hover {
    color: ${props => props?.divisionColor?.text};
    background-color: ${props => props?.divisionColor?.background};
  }
`;

export default StyledStatContentsContainer;
