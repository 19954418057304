import React from 'react';
import CommonMessageModal from '../../../CommonMessageModal/CommonMessageModal';
import CommonButton from '../../../CommonButton/CommonButton';
import StyledCommonErrorModalWrapper from '../../../../styles/Common/CommonErrorModal/StyledCommonErrorModalWrapper';
import StyledCommonErrorModalButton from '../../../../styles/Common/CommonErrorModal/StyledCommonErrorModalButton';
import handleConfirmClick from '../../../../utils/MobileAdmin/BillConfirm/Handler/handleConfirmClick';

const ConfirmModal = ({ open, setOpen, confirmContent, ...props }) => {
  const renderContent = () => {
    if (confirmContent.action === 'reject') {
      return <p>{confirmContent.message}</p>;
    }
    if (confirmContent.action === 'approve') {
      return (
        <div className="flex flex-col leading-[14px] items-center justify-center">
          <p className="font-bold">{confirmContent.message[0]}</p>
          <p>{confirmContent.message[1]}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <CommonMessageModal
      open={open}
      setOpen={setOpen}
      header="Confirmation"
      content={
        <StyledCommonErrorModalWrapper>
          {renderContent()}
        </StyledCommonErrorModalWrapper>
      }
      buttonContent={
        <StyledCommonErrorModalButton className="gap-x-[15px]">
          <CommonButton
            preventDupClick
            onClick={({ buttonRef }) =>
              handleConfirmClick({
                confirmContent,
                setOpen,
                buttonRef,
                ...props,
              })
            }
            category="yes"
            type="button"
          >
            Yes
          </CommonButton>
          <CommonButton
            onClick={() => setOpen(false)}
            category="no"
            type="button"
          >
            Cancel
          </CommonButton>
        </StyledCommonErrorModalButton>
      }
    />
  );
};
export default ConfirmModal;
