/**
 * @description
 * Generator for schedule update item
 * @param {list} jobSet
 * Job set list
 * @param {object} selectedRowData
 * Selected row data
 * @returns {list}
 * Schedule update item
 */
const generateScheduleUpdateItem = ({ jobSet, selectedRowData }) => {
  /** Update item container */
  const result = [];

  /** Get selected row data */
  const { scheduleType } = selectedRowData ?? {};

  /** Process when schedule type is job, location */
  if (scheduleType === 'job') {
    /** Push updated item feature is deprecated. */
    // result.push(...generateJobScheduleUpdateItem({ jobSet, selectedRowData }));
    return [];
  }
  if (scheduleType === 'location') {
    return [];
  }
  return result;
};

export default generateScheduleUpdateItem;
