import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import getDriverList from '../../../../services/BCDetail/BillingInfo/AP/Get/getDriverList';
import { commonCompanyGetAPI } from '../../../../services/Common/Company/get';
import bcDetailKeys from '../../keys';

const useGetDriverVendorList = ({
  basicInfoData,
  setIsAlertOpen,
  setErrorMessage,
}) => {
  const [driverList, setDriverList] = useState([]);
  const [vendorList, setVendorList] = useState([]);

  const [woDivision, setWODivision] = useState('');

  useEffect(() => {
    if (basicInfoData?.div) {
      setWODivision(basicInfoData?.div?.div);
    }
  }, [basicInfoData?.div]);

  // Driver Query
  const driverQuery = useQuery({
    queryKey: bcDetailKeys?.billingInfoAPDriver(),
    queryFn: () => getDriverList({ driverType: 'DRIVER', div: woDivision }),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!woDivision,
  });

  useEffect(() => {
    if (driverQuery?.data?.data) {
      setDriverList(driverQuery?.data?.data);
    }
  }, [driverQuery?.data]);

  useEffect(() => {
    if (driverQuery?.error) {
      setIsAlertOpen(true);
      setErrorMessage('Failed to get AP driver list.');
    }
  }, [driverQuery?.error]);

  // Vendor Query
  const vendorQuery = useQuery({
    queryKey: bcDetailKeys?.billingInfoAPVendor(),
    queryFn: () => commonCompanyGetAPI.getCommonVendorCompanyList(),
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    if (vendorQuery?.data?.data) {
      setVendorList(vendorQuery?.data?.data);
    }
  }, [vendorQuery?.data]);

  useEffect(() => {
    if (vendorQuery?.error) {
      setIsAlertOpen(true);
      setErrorMessage('Failed to get AP vendor list.');
    }
  }, [vendorQuery?.error]);

  return { driverList, vendorList, driverQuery, vendorQuery };
};

export default useGetDriverVendorList;
