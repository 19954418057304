const findWOType = wo => {
  if (wo.length === 12) {
    return wo[1];
  }
  if (wo.length === 13) {
    return wo[2];
  }
  return null;
};

export default findWOType;
