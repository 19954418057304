import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import masterDefaultApi from '../../api';

/**
 * Fetches the chassis rate list.
 * Returns the data if successful, or null in case of an error.
 * @returns {Object[]|null} - The chassis rate list or null on error.
 */
const getChassisRateList = async ({ div }) => {
  try {
    const response = await masterDefaultApi.get(`/chassisrate?div=${div}`);
    return response.data;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getChassisRateList;
