const viewDualMoveContentFilter = ({ dualMoveRowData, dualJob, completed }) => {
  const { type, status } = dualMoveRowData;
  const { poJob } = dualJob || {
    poJob: null,
    rtnJob: null,
  };

  return type !== 'P/O' && !poJob && status !== 'Complete' && !completed;
};

export default viewDualMoveContentFilter;
