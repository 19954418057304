import React from 'react';

const DualMoveModalContentJobColumn = ({ ...props }) => {
  return (
    <div className="h-[30px] text-[#222] text-[11px] font-medium flex flex-row gap-[2px]">
      <div className="flex justify-center items-center h-full w-[80px]" />
      <div className="flex justify-center items-center h-full w-[127px]">
        SCHEDULE DATE
      </div>
      <div className="flex justify-center items-center h-full w-[48px]">TZ</div>
      <div className="flex justify-center items-center h-full w-[99px]">
        LOCATION ID
      </div>
      <div className="flex justify-center items-center h-full w-[126px]">
        WO #
      </div>
      <div className="flex justify-center items-center h-full w-[126px]">
        CONT #
      </div>
      <div className="flex justify-center items-center h-full w-[68px]">
        SIZE
      </div>
      <div className="flex justify-center items-center h-full w-[59px]">
        TYPE
      </div>
      <div className="flex justify-center items-center h-full w-[95px]">
        DRIVER ID
      </div>
      <div className="flex justify-center items-center h-full w-[24px]" />
    </div>
  );
};

export default DualMoveModalContentJobColumn;
