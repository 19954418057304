import React, { memo, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import StyledSidebarMenuItem from '../../../styles/Common/Sidebar/StyledSidebarMenuItem';
import StyledSidebarMenuName from '../../../styles/Common/Sidebar/StyledSidebarMenuName';
import StyledSidebarMenuArrow from '../../../styles/Common/Sidebar/StyledSidebarMenuArrow';
import { closeSidebar } from '../../../utils/Common/Sidebar/Handler/handleSidebarVisible';
import checkCurrentMenu from '../../../utils/Common/Sidebar/Handler/checkCurrentMenu';
import SidebarSubMenu from './SidebarSubMenu';
import SidebarMenuIcon from './SidebarMenuIcon';
import { PATH_SIDEBAR_MENU_ARROW_ICON } from '../../../constants/Common/path';

/**
 * @description
 * Sidebar menu component.
 * @param {string} label
 * Label for the menu item.
 * @param {Array} subMenu
 * Array of sub menu items.
 * @param {string} to
 * Path for the menu item.
 * @param {boolean} isOpen
 * Flag to indicate the sidebar open state.
 * @param {function} setOpen
 * Function to handle the sidebar open event.
 * @returns {React.ReactComponentElement}
 */
const SidebarMenu = memo(({ ...props }) => {
  const { label, subMenu, to, isOpen, setOpen, iconSet } = props;
  const { pathname } = useLocation();

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isCurrentMenu, setIsCurrentMenu] = useState(false);

  useEffect(() => {
    if (checkCurrentMenu({ pathname, to, isSubMenu: false })) {
      setIsCurrentMenu(true);
    } else {
      setIsCurrentMenu(false);
    }
  }, [pathname]);

  return (
    <Link
      to={to}
      onClick={() =>
        closeSidebar({
          setIsSidebarOpen: setOpen,
          sidebarElement: document.getElementById('sidebarWrapper'),
        })
      }
    >
      <StyledSidebarMenuItem
        $isCurrentMenu={isCurrentMenu}
        onMouseEnter={() => setIsSubMenuOpen(prev => true)}
        onMouseLeave={() => setIsSubMenuOpen(prev => false)}
      >
        <SidebarMenuIcon
          isCurrentMenu={isCurrentMenu}
          iconSet={iconSet}
          label={label}
        />
        <StyledSidebarMenuName $isCurrentMenu={isCurrentMenu}>
          {label}
        </StyledSidebarMenuName>
        <StyledSidebarMenuArrow>
          <img
            src={PATH_SIDEBAR_MENU_ARROW_ICON?.greyScale}
            alt="Sidebar menu arrow icon"
          />
        </StyledSidebarMenuArrow>
        {isSubMenuOpen && isOpen && (
          <SidebarSubMenu menu={subMenu} setOpen={setOpen} />
        )}
      </StyledSidebarMenuItem>
    </Link>
  );
}, null);

export default SidebarMenu;
