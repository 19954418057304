import { useMutation } from '@tanstack/react-query';
import { SUCCESS_UPDATE } from '../../../constants/Common/successMessage';
import updateTierWeight from '../../../services/Master/TierWeight/Patch/updateTierWeight';
import resetRow from '../../../utils/Common/Handler/resetRow';
import updateGridData from '../../../utils/Common/Handler/updateGridData';
import useControlToast from '../../Common/Toast/useControlToast';

const useUpdateTierWeightMutation = ({
  gridRef,
  setIsMessageOpen,
  setMessage,
  div,
  setIsSuccess,
}) => {
  const { addToast } = useControlToast();

  return useMutation({
    mutationFn: updateTierWeight,
    onSuccess: (res, variables) => {
      updateGridData({
        gridRef,
        newData: res.data,
        isUpdate: true,
      });
      addToast({
        message: SUCCESS_UPDATE,
      });
    },
    onError: error => {
      resetRow({ gridRef });
      setIsSuccess(false);
      setMessage(error.message);
      setIsMessageOpen(true);
    },
  });
};

export default useUpdateTierWeightMutation;
