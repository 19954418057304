import masterDefaultApi from '../../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

/**
 *
 * API that add company
 *
 * @param {*} data Form Data
 * @returns Server response
 */
const postCompanyData = async data => {
  try {
    const response = await masterDefaultApi.post(`company`, data);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }), { cause: error });
  }
};

export default postCompanyData;
