import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';
import billingInfoDefaultAPI from '../../api';

const deleteAPData = async ({ seq }) => {
  try {
    const response = await billingInfoDefaultAPI.delete(`/ap/${seq}`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default deleteAPData;
