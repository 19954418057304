import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CommonErrorModal from '../../CommonErrorModal/CommonErrorModal';
import CommonMessageModal from '../../CommonMessageModal/CommonMessageModal';
import ButtonContent from '../LiveShareModal/ButtonContent';
import HeaderContent from '../LiveShareModal/HeaderContent';
import ModalContent from '../LiveShareModal/ModalContent';
import MarkerComponent from './MapModule/MarkerComponent';

// Libraries for polygon-related operations
const LIBRARIES = ['geometry'];

/**
 * Use Google Map API to display Google Maps on web
 *
 * @param mapSettings
 * Settings of the google maps.
 * @param markerList
 * List to store markers.
 * @param setMarkerList
 * Set state of the markerList.
 * @returns
 */
const GoogleMapComponentMap = ({ mapSettings, markerList, setMarkerList }) => {
  const searchController = useForm();

  const [isLiveShareModalOpen, setIsLiveShareModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState({});

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
    libraries: LIBRARIES,
  });

  return isLoaded ? (
    <>
      <div>
        <GoogleMap
          mapContainerStyle={mapSettings.size}
          center={mapSettings.center}
          zoom={mapSettings.zoom}
        >
          {markerList &&
            markerList.map(marker => (
              <MarkerComponent
                marker={marker}
                setMarkerList={setMarkerList}
                setIsLiveShareModalOpen={setIsLiveShareModalOpen}
                setSelectedMarker={setSelectedMarker}
              />
            ))}
          <FormProvider {...searchController}>
            <form>
              <CommonMessageModal
                header={<HeaderContent />}
                open={isLiveShareModalOpen}
                setOpen={setIsLiveShareModalOpen}
                width="398px"
                height="480px"
                content={
                  <ModalContent
                    setIsErrorModalOpen={setIsErrorModalOpen}
                    setErrorMessage={setErrorMessage}
                    setIsSuccess={setIsSuccess}
                    selectedMarker={selectedMarker}
                  />
                }
                buttonContent={
                  <ButtonContent
                    setIsLiveShareModalOpen={setIsLiveShareModalOpen}
                  />
                }
              />
            </form>
          </FormProvider>
        </GoogleMap>
      </div>
      <CommonErrorModal
        open={isErrorModalOpen}
        setIsOpen={setIsErrorModalOpen}
        message={errorMessage}
        isSuccess={isSuccess}
      />
    </>
  ) : (
    <div>Loading...!</div>
  );
};

export default GoogleMapComponentMap;
