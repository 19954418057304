import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import tmsReportAPI from '../../api';

const postSendEmail = async ({ woNo }) => {
  try {
    await tmsReportAPI.post(`/email`, { wo_no: woNo, type: 'Perdiem' });
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default postSendEmail;
