const onAPGroupRowsByGroupIdHandler = rows => {
  return rows.reduce((acc, row) => {
    if (row.group_id !== null && row.group_id !== undefined) {
      const groupId = row.group_id;
      if (!acc[groupId]) {
        acc[groupId] = [];
      }
      acc[groupId].push(row);
    }
    return acc;
  }, {});
};

export default onAPGroupRowsByGroupIdHandler;
