import {
  DISPATCH_PAGE_VAN_AVAILABLE,
  DISPATCH_PAGE_VAN_DELIVERY,
  DISPATCH_PAGE_VAN_RETURN,
} from '../../Common/category';
import {
  AVAILABLE_SUB_FILTERS_WITHOUT_YARD,
  DELIVERY_SUB_FILTERS_WITHOUT_YARD,
  RETURN_SUB_FILTERS_WITHOUT_YARD,
} from '../../Common/filterButtonSettings';

const VAN_FILTERS = [
  {
    header: 'AVAILABLE',
    key: 'available',
    status: DISPATCH_PAGE_VAN_AVAILABLE,
    to: '/dispatch/van/available',
    children: AVAILABLE_SUB_FILTERS_WITHOUT_YARD,
  },
  {
    header: 'DELIVERY Ready',
    key: 'delivery',
    status: DISPATCH_PAGE_VAN_DELIVERY,
    to: '/dispatch/van/delivery',
    children: DELIVERY_SUB_FILTERS_WITHOUT_YARD,
  },
  {
    header: 'RETURN Ready',
    key: 'return',
    status: DISPATCH_PAGE_VAN_RETURN,
    to: '/dispatch/van/return',
    children: RETURN_SUB_FILTERS_WITHOUT_YARD,
  },
];

export default VAN_FILTERS;
