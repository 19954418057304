import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import checkDateDiff from '../../../../utils/Common/Handler/checkDateDiff';
import CommonMessageModal from '../../../CommonMessageModal/CommonMessageModal';
import ButtonContent from './ButtonContent';
import ModalContent204Or322 from './ModalContent/ModalContent204Or322';
import ModalContentMain from './ModalContent/ModalContentMain';

const SearchModal = ({
  isSearchModalOpen,
  setIsSearchModalOpen,
  selectedEDI,
  setSearchFrom204,
  setSearchTo204,
  setSearchTypeMain,
  setSearchFromMain,
  setSearchToMain,
  setSearchFrom322,
  setSearchTo322,
  searchFrom204,
  searchTo204,
  searchTypeMain,
  searchFromMain,
  searchToMain,
  searchFrom322,
  searchTo322,
  setIsAlertOpen,
  setIsSuccessAlert,
  setAlertMessage,
}) => {
  const searchController = useForm();

  const handleSearch = e => {
    if (
      !checkDateDiff({
        fromDate: searchController.getValues('fromDate'),
        toDate: searchController.getValues('toDate'),
      })
    ) {
      setIsSuccessAlert(false);
      setAlertMessage(
        'The date range cannot exceed one year. Please try again.',
      );
      setIsAlertOpen(true);

      return;
    }
    setIsSearchModalOpen(false);
    if (selectedEDI === '204') {
      setSearchFrom204(e.fromDate);
      setSearchTo204(e.toDate);
    }
    if (selectedEDI === 'MAIN') {
      setSearchTypeMain(e.type);
      setSearchFromMain(e.fromDate);
      setSearchToMain(e.toDate);
    }
    if (selectedEDI === '322') {
      setSearchFrom322(e.fromDate);
      setSearchTo322(e.toDate);
    }
  };

  return (
    <FormProvider {...searchController}>
      <form>
        <CommonMessageModal
          header="Advanced Search"
          open={isSearchModalOpen}
          setOpen={setIsSearchModalOpen}
          width={selectedEDI === 'MAIN' ? '588px' : '414px'}
          height="229px"
          content={
            selectedEDI === 'MAIN' ? (
              <ModalContentMain
                searchTypeMain={searchTypeMain}
                searchFromMain={searchFromMain}
                searchToMain={searchToMain}
              />
            ) : (
              <ModalContent204Or322
                selectedEDI={selectedEDI}
                searchFrom204={searchFrom204}
                searchTo204={searchTo204}
                searchFrom322={searchFrom322}
                searchTo322={searchTo322}
              />
            )
          }
          buttonContent={
            <ButtonContent
              setIsSearchModalOpen={setIsSearchModalOpen}
              handleSearch={handleSearch}
            />
          }
        />
      </form>
    </FormProvider>
  );
};

export default SearchModal;
