import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import StyledTMSReportCommonModalButtonContainer from '../../../../styles/TmsReport/StyledTMSReportCommonModalButtonContainer';
import StyledTMSReportDetailModalNGLCUSContainer from '../../../../styles/TmsReport/StyledTMSReportDetailModalNGLCUSContainer';
import StyledTMSReportDetailModalNGLCUSInput from '../../../../styles/TmsReport/StyledTMSReportDetailModalNGLCUSInput';
import StyledTMSReportDetailModalNGLCUSSlash from '../../../../styles/TmsReport/StyledTMSReportDetailModalNGLCUSSlash';
import StyledTMSReportDetailModalNGLCUSTitle from '../../../../styles/TmsReport/StyledTMSReportDetailModalNGLCUSTitle';
import StyledTMSReportDetailModalReasonContainer from '../../../../styles/TmsReport/StyledTMSReportDetailModalReasonContainer';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';
import CommonButton from '../../../CommonButton/CommonButton';
import CommonMessageModal from '../../../CommonMessageModal/CommonMessageModal';

const DetailModal = ({
  open,
  setOpen,
  data,
  setAlertOpen,
  setAlertMessage,
  patchPerdiemMutation,
}) => {
  const buttonRef = useRef(null);

  const { register, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      ngl: data?.ngl_ratio,
      cus: data?.customer_ratio,
      reason: data?.reason,
    },
  });

  useEffect(() => {
    if (data) {
      setValue('ngl', data.ngl_ratio);
      setValue('cus', data.customer_ratio);
      setValue('reason', data.reason);
    }
  }, [data, setValue]);

  const updatePerdiem = formData => {
    if (buttonRef) updateButtonDisabled({ ref: buttonRef, disabled: true });

    if (formData.ngl > 10 || formData.ngl < 0) {
      setAlertOpen(true);
      setAlertMessage('NGL must be between 0 and 10.');
      updateButtonDisabled({ ref: buttonRef, disabled: false });
      return;
    }
    if (formData.cus > 10 || formData.cus < 0) {
      setAlertOpen(true);
      setAlertMessage('CUS must be between 0 and 10.');
      updateButtonDisabled({ ref: buttonRef, disabled: false });
      return;
    }
    if (Number(formData.ngl) + Number(formData.cus) !== 10) {
      setAlertOpen(true);
      setAlertMessage('NGL + CUS must be equal to 10.');
      updateButtonDisabled({ ref: buttonRef, disabled: false });
      return;
    }

    if (formData.reason === '') {
      setAlertOpen(true);
      setAlertMessage('Please enter a reason.');
      updateButtonDisabled({ ref: buttonRef, disabled: false });
      return;
    }

    const body = {
      ngl_ratio: Number(formData.ngl),
      customer_ratio: Number(formData.cus),
      reason: formData.reason,
    };

    patchPerdiemMutation({ wo_no: data.wo_no, body, buttonRef });

    if (!patchPerdiemMutation.isLoading) {
      setOpen(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(updatePerdiem)}>
      <CommonMessageModal
        open={open}
        setOpen={setOpen}
        header="Responsibility"
        onCloseClick={() => {
          reset({
            ngl: '',
            cus: '',
            reason: '',
          });
        }}
        content={
          <>
            <StyledTMSReportDetailModalNGLCUSContainer>
              <StyledTMSReportDetailModalNGLCUSTitle>
                NGL
              </StyledTMSReportDetailModalNGLCUSTitle>
              <StyledTMSReportDetailModalNGLCUSInput
                {...register('ngl')}
                defaultValue={data?.ngl_ratio}
              />
              <StyledTMSReportDetailModalNGLCUSSlash>
                /
              </StyledTMSReportDetailModalNGLCUSSlash>
              <StyledTMSReportDetailModalNGLCUSTitle>
                CUS
              </StyledTMSReportDetailModalNGLCUSTitle>
              <StyledTMSReportDetailModalNGLCUSInput
                {...register('cus')}
                defaultValue={data?.customer_ratio}
              />
            </StyledTMSReportDetailModalNGLCUSContainer>
            <StyledTMSReportDetailModalReasonContainer
              placeholder="REASON"
              {...register('reason')}
              defaultValue={data?.reason}
            />
            <StyledTMSReportCommonModalButtonContainer>
              <CommonButton
                type="button"
                category="clear"
                onClick={() => {
                  reset();
                }}
              >
                Reset
              </CommonButton>
              <CommonButton type="submit" category="save" ref={buttonRef}>
                Save
              </CommonButton>
            </StyledTMSReportCommonModalButtonContainer>
          </>
        }
      />
    </form>
  );
};

export default DetailModal;
