import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import APContainer from '../../../components/Admin/DivConf/APContainer/APContainer';
import APTableContainer from '../../../components/Admin/DivConf/APTableContainer';
import APTableRateContainer from '../../../components/Admin/DivConf/APTableRateContainer';
import ARContainer from '../../../components/Admin/DivConf/ARContainer';
import BGColorContainer from '../../../components/Admin/DivConf/BGColorContainer';
import EmailButtonContainer from '../../../components/Admin/DivConf/EmailButtonContainer';
import FunctionsContainer from '../../../components/Admin/DivConf/FunctionsContainer';
import MobileContainer from '../../../components/Admin/DivConf/MobileContainer';
import TimeZoneContainer from '../../../components/Admin/DivConf/TimeZoneContainer';
import WeightPerZipCodeContainer from '../../../components/Admin/DivConf/WeightPerZipCodeContainer';
import CommonDataGrid from '../../../components/CommonDataGrid/index';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import { MAPPING_PAGE } from '../../../constants/Admin/DivConf/adminDivConfElements';
import { GRID_MOD_BOTH } from '../../../constants/Common/DataGrid/setting';
import useDeleteProductCodeMutation from '../../../hooks/Admin/DivConf/Delete/useDeleteProductCodeMutation';
import useDeleteWeightPerZipMutation from '../../../hooks/Admin/DivConf/Delete/useDeleteWeightPerZipMutation';
import useGetAPProductCode from '../../../hooks/Admin/DivConf/Get/useGetAPProductCode';
import useGetARProductCode from '../../../hooks/Admin/DivConf/Get/useGetARProductCode';
import useGetDivInfo from '../../../hooks/Admin/DivConf/Get/useGetDivInfo';
import useGetProductCodeList from '../../../hooks/Admin/DivConf/Get/useGetProductCodeList';
import useGetTimeZoneList from '../../../hooks/Admin/DivConf/Get/useGetTimeZoneList';
import useGetWeightPerZIP from '../../../hooks/Admin/DivConf/Get/useGetWeightPerZIP';
import usePatchAPTableActivateMutation from '../../../hooks/Admin/DivConf/Patch/usePatchAPTableActivateMutation';
import usePatchAPTableRateMutation from '../../../hooks/Admin/DivConf/Patch/usePatchAPTableRateMutation';
import usePatchBGColorActivateMutation from '../../../hooks/Admin/DivConf/Patch/usePatchBGColorActivateMutation';
import usePatchBGColorLBSMutation from '../../../hooks/Admin/DivConf/Patch/usePatchBGColorLBSMutation';
import usePatchBGColorMutation from '../../../hooks/Admin/DivConf/Patch/usePatchBGColorMutation';
import usePatchEmailButtonMutation from '../../../hooks/Admin/DivConf/Patch/usePatchEmailButtonMutation';
import usePatchFunctionsMutation from '../../../hooks/Admin/DivConf/Patch/usePatchFunctionsMutation';
import usePatchProductCodeMutation from '../../../hooks/Admin/DivConf/Patch/usePatchProductCodeMutation';
import usePatchTimeZoneMutation from '../../../hooks/Admin/DivConf/Patch/usePatchTimeZoneMutation';
import usePatchWeightPerZipMutation from '../../../hooks/Admin/DivConf/Patch/usePatchWeightPerZipMutation';
import usePostProductCodeMutation from '../../../hooks/Admin/DivConf/Post/usePostProductCodeMutation';
import usePostWeightPerZipMutation from '../../../hooks/Admin/DivConf/Post/usePostWeightPerZipMutation';
import createSettingsColumnDefs from '../../../utils/Admin/DivConf/Generator/createSettingsColumnDefs';
import handleAddClicked from '../../../utils/Admin/DivConf/handleAddClicked';
import handleDeleteClicked from '../../../utils/Admin/DivConf/handleDeleteClicked';
import handleSaveClicked from '../../../utils/Admin/DivConf/handleSaveClicked';

const DivConf = () => {
  const gridRef = useRef(null);
  const [columnDefs, setColumnDefs] = useState(createSettingsColumnDefs());

  const divConfController = useForm();
  const nowDiv = useSelector(state => state.nowDiv);
  const [divInfo, setDivInfo] = useState(null);
  const [weightPerZIP, setWeightPerZIP] = useState([]);
  const [timeZoneOption, setTimeZoneOption] = useState([]);
  const [isMessageOpen, setIsMessageOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [apProductCodeOption, setAPProductCodeOption] = useState([]);
  const [arProductCodeOption, setARProductCodeOption] = useState([]);
  const [apRate, setAPRate] = useState({});
  const [apTableRate, setAPTableRate] = useState({});
  const [truckingCharge, setTruckingCharge] = useState({});
  const [fuelSurcharge, setFuelSurcharge] = useState({});
  const [chassisRate, setChassisRate] = useState({});
  const [dryRun, setDryRun] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [other, setOther] = useState(false);

  const { isLoadingDivInfo, data } = useGetDivInfo({
    div: nowDiv,
  });

  const { isLoadingTimeZoneList, data: timeZoneList } = useGetTimeZoneList();

  const { isLoadingWeightPerZIP, data: weightPerZIPData } = useGetWeightPerZIP({
    div: nowDiv,
  });

  const { isLoadingProductCodeList, data: productCodeList } =
    useGetProductCodeList({ div: nowDiv });

  const { isLoadingAPProductCode, data: apProductCode } = useGetAPProductCode();

  const { isLoadingARProductCode, data: arProductCode } = useGetARProductCode();

  const { mutate: patchTimeZoneMutation } = usePatchTimeZoneMutation({
    setIsMessageOpen,
    setMessage,
    setIsSuccess,
    div: nowDiv,
  });

  const { mutate: patchBGColorActivateMutation } =
    usePatchBGColorActivateMutation({
      setIsMessageOpen,
      setMessage,
      setIsSuccess,
      div: nowDiv,
    });

  const { mutate: patchBGColorMutation } = usePatchBGColorMutation({
    setIsMessageOpen,
    setMessage,
    setIsSuccess,
    div: nowDiv,
  });

  const { mutate: patchBGColorLBSMutation } = usePatchBGColorLBSMutation({
    setIsMessageOpen,
    setMessage,
    setIsSuccess,
    div: nowDiv,
  });

  const { mutate: patchEmailButtonMutation } = usePatchEmailButtonMutation({
    setIsMessageOpen,
    setMessage,
    setIsSuccess,
    div: nowDiv,
  });

  const { mutate: patchFunctionsMutation } = usePatchFunctionsMutation({
    setIsMessageOpen,
    setMessage,
    setIsSuccess,
    div: nowDiv,
  });

  const { mutate: patchAPTAbleActivateMutation } =
    usePatchAPTableActivateMutation({
      setIsMessageOpen,
      setMessage,
      setIsSuccess,
      div: nowDiv,
    });

  const { mutate: patchAPTAbleRateMutation } = usePatchAPTableRateMutation({
    setIsMessageOpen,
    setMessage,
    setIsSuccess,
    div: nowDiv,
  });

  const { mutate: postWeightPerZIPMutation } = usePostWeightPerZipMutation({
    setIsMessageOpen,
    setMessage,
    setIsSuccess,
    div: nowDiv,
    gridRef,
  });

  const { mutate: patchWeightPerZIPMutation } = usePatchWeightPerZipMutation({
    gridRef,
    setIsMessageOpen,
    setMessage,
    setIsSuccess,
    div: nowDiv,
  });

  const { mutate: deleteWeightPerZIPMutation } = useDeleteWeightPerZipMutation({
    setIsMessageOpen,
    setMessage,
    setIsSuccess,
    div: nowDiv,
  });

  const { mutate: postProductCodeMutation } = usePostProductCodeMutation({
    setIsMessageOpen,
    setMessage,
    setIsSuccess,
    div: nowDiv,
  });

  const { mutate: patchProductCodeMutation } = usePatchProductCodeMutation({
    setIsMessageOpen,
    setMessage,
    setIsSuccess,
    div: nowDiv,
  });

  const { mutate: deleteProductCodeMutation } = useDeleteProductCodeMutation({
    setIsMessageOpen,
    setMessage,
    setIsSuccess,
    div: nowDiv,
  });

  useEffect(() => {
    if (data) {
      setDivInfo(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (weightPerZIPData) {
      weightPerZIPData.forEach(item => {
        item.weight = item.weight.toString();
      });
      setWeightPerZIP(weightPerZIPData);
    }
  }, [weightPerZIPData]);

  useEffect(() => {
    if (timeZoneList) {
      setTimeZoneOption(
        timeZoneList.map(item => ({
          label: item.combine,
          value: item.combine,
        })),
      );
    }
  }, [timeZoneList]);

  useEffect(() => {
    if (productCodeList) {
      setAPRate(
        productCodeList.find(
          item => item.mapping_page === MAPPING_PAGE.AP_RATE,
        ) || {},
      );
      setAPTableRate(
        productCodeList.find(
          item => item.mapping_page === MAPPING_PAGE.DIV_AP_TABLE,
        ) || {},
      );
      setTruckingCharge(
        productCodeList.find(
          item => item.mapping_page === MAPPING_PAGE.TRUCKING_AR_RATE,
        ) || {},
      );
      setFuelSurcharge(
        productCodeList.find(
          item => item.mapping_page === MAPPING_PAGE.FUEL_AR_RATE,
        ) || {},
      );
      setChassisRate(
        productCodeList.find(
          item => item.mapping_page === MAPPING_PAGE.CHASSIS_RATE,
        ) || {},
      );
      setDryRun(
        productCodeList.find(
          item => item.mapping_page === MAPPING_PAGE.DRY_RUN,
        ),
      );
      setWaiting(
        productCodeList.find(
          item => item.mapping_page === MAPPING_PAGE.WAITING,
        ),
      );
      setOther(
        productCodeList.find(item => item.mapping_page === MAPPING_PAGE.OTHERS),
      );
    }
  }, [productCodeList]);

  useEffect(() => {
    if (apProductCode) {
      setAPProductCodeOption(
        apProductCode.map(item => ({
          label: `${item.product_code} / ${item.product_code_name}`,
          value: item.seq.toString(),
          name: item.product_code_name,
        })),
      );
    }
  }, [apProductCode]);

  useEffect(() => {
    if (arProductCode) {
      setARProductCodeOption(
        arProductCode.map(item => ({
          label: `${item.product_code} / ${item.product_code_name}`,
          value: item.seq.toString(),
          name: item.product_code_name,
        })),
      );
    }
  }, [arProductCode]);

  return (
    <>
      <CommonLoading
        open={
          isLoadingDivInfo ||
          isLoadingTimeZoneList ||
          isLoadingWeightPerZIP ||
          isLoadingProductCodeList
        }
      />
      {!isSuccess && (
        <CommonErrorModal
          open={isMessageOpen}
          setIsOpen={setIsMessageOpen}
          message={message}
          isSuccess={isSuccess}
        />
      )}
      {divInfo && weightPerZIP && (
        <FormProvider {...divConfController}>
          <form>
            <div className="w-[1768px] flex flex-col bg-white mt-[30px] pt-[40px] pb-[40px] pl-[44px] pr-[50px]">
              <div className="flex">
                <div className="flex-col">
                  <TimeZoneContainer
                    timeZoneOption={timeZoneOption}
                    timeZone={divInfo.timezone}
                    div={nowDiv}
                    patchTimeZoneMutation={patchTimeZoneMutation}
                  />
                  <BGColorContainer
                    bgColor={divInfo.bg_color}
                    div={nowDiv}
                    patchBGColorActivateMutation={patchBGColorActivateMutation}
                    patchBGColorMutation={patchBGColorMutation}
                    patchBGColorLBSMutation={patchBGColorLBSMutation}
                    setIsMessageOpen={setIsMessageOpen}
                    setMessage={setMessage}
                    setIsSuccess={setIsSuccess}
                  />
                </div>
                <EmailButtonContainer
                  emailBtn={divInfo.email_btn}
                  div={nowDiv}
                  patchEmailButtonMutation={patchEmailButtonMutation}
                />
                <FunctionsContainer
                  isActive={divInfo.tracking_func}
                  div={nowDiv}
                  patchFunctionsMutation={patchFunctionsMutation}
                />
              </div>
              <div className="w-[1768px] h-[0px] mt-[40px] ml-[-44px] border border-zinc-400" />
              <div className="flex">
                <APContainer
                  apRate={apRate}
                  apProductCodeOption={apProductCodeOption}
                  div={nowDiv}
                  postProductCodeMutation={postProductCodeMutation}
                  patchProductCodeMutation={patchProductCodeMutation}
                  deleteProductCodeMutation={deleteProductCodeMutation}
                  isLoadingAPProductCode={isLoadingAPProductCode}
                />
                <ARContainer
                  truckingCharge={truckingCharge}
                  fuelSurcharge={fuelSurcharge}
                  chassisRate={chassisRate}
                  arProductCodeOption={arProductCodeOption}
                  div={nowDiv}
                  postProductCodeMutation={postProductCodeMutation}
                  patchProductCodeMutation={patchProductCodeMutation}
                  deleteProductCodeMutation={deleteProductCodeMutation}
                  isLoadingARProductCode={isLoadingARProductCode}
                />
                <MobileContainer
                  dryRun={dryRun}
                  waiting={waiting}
                  other={other}
                  apProductCodeOption={apProductCodeOption}
                  div={nowDiv}
                  postProductCodeMutation={postProductCodeMutation}
                  patchProductCodeMutation={patchProductCodeMutation}
                  deleteProductCodeMutation={deleteProductCodeMutation}
                  isLoadingAPProductCode={isLoadingAPProductCode}
                />
              </div>
              <div className="w-[1768px] h-[0px] mt-[40px] ml-[-44px] border border-zinc-400" />
              <div className="flex">
                <APTableContainer
                  apTable={divInfo.ap_table}
                  div={nowDiv}
                  patchAPTAbleActivateMutation={patchAPTAbleActivateMutation}
                  patchAPTAbleRateMutation={patchAPTAbleRateMutation}
                  setIsMessageOpen={setIsMessageOpen}
                  setMessage={setMessage}
                  setIsSuccess={setIsSuccess}
                />
                <APTableRateContainer
                  apTableRate={apTableRate}
                  apProductCodeOption={apProductCodeOption}
                  div={nowDiv}
                  postProductCodeMutation={postProductCodeMutation}
                  patchProductCodeMutation={patchProductCodeMutation}
                  deleteProductCodeMutation={deleteProductCodeMutation}
                  isLoadingAPProductCode={isLoadingAPProductCode}
                />
              </div>
              <WeightPerZipCodeContainer />
              <div className="mt-[10px] ml-[20px] h-[700px] w-[460px]">
                <CommonDataGrid
                  gridRef={gridRef}
                  addable
                  modType={GRID_MOD_BOTH}
                  data={weightPerZIP}
                  columnDefs={columnDefs}
                  onAddClicked={e =>
                    handleAddClicked({
                      e,
                      div: nowDiv,
                      postWeightPerZIPMutation,
                    })
                  }
                  onSaveClicked={e =>
                    handleSaveClicked({
                      e,
                      patchWeightPerZIPMutation,
                    })
                  }
                  onDeleteClicked={e =>
                    handleDeleteClicked({
                      e,
                      deleteWeightPerZIPMutation,
                    })
                  }
                />
              </div>
            </div>
          </form>
        </FormProvider>
      )}
    </>
  );
};

export default DivConf;
