import React from 'react';
import dayjs from 'dayjs';
import CommonDate from '../../../components/Inputs/CommonDate/CommonDate';

const getDateComponent = ({ ...props }) => {
  const commonProperty = {
    disabled: props?.disabled,
    required: props?.required,
    labelStyle: props?.labelStyle,
    onChangeHandler: ({ e, onChange, ...handlerProps }) => {
      console.log('E  : ', e);
      onChange?.(dayjs(e)?.isValid() ? e : null);
    },
  };

  const inputProperty = [
    {
      key: 0,
      label: 'Date Label default',
      inputName: 'date00',
      width: null,
      ...commonProperty,
    },
    {
      key: 1,
      label: 'Date Label 25%',
      inputName: 'date00',
      width: 'w-1/4',
      ...commonProperty,
    },
    {
      key: 2,
      label: 'Date Label 50%',
      inputName: 'date00',
      width: 'w-2/4',
      ...commonProperty,
    },
    {
      key: 3,
      label: 'Date Label 75%',
      inputName: 'date00',
      width: 'w-3/4',
      ...commonProperty,
    },
    {
      key: 4,
      label: 'Date Label 100%',
      inputName: 'date00',
      width: 'w-full',
      ...commonProperty,
    },
    {
      key: 5,
      label: 'Date Label Custom',
      inputName: 'date00',
      width: props?.customWidth,
      ...commonProperty,
    },
  ];

  return inputProperty?.map(element => (
    <CommonDate
      key={element?.key}
      {...element}
      labelStyle={props?.labelStyle && 'row'}
    />
  ));
};
export default getDateComponent;
