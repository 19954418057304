export const addForm = ({ invoiceKey, setInvoiceKey, setInvoiceList }) => {
  const newInvoice = { key: invoiceKey, data: {} };
  setInvoiceList(prevInvoiceList => [...prevInvoiceList, newInvoice]);
  setInvoiceKey(prev => prev + 1);
};

export const deleteForm = ({ formKey, setInvoiceList }) => {
  setInvoiceList(prevInvoiceList =>
    prevInvoiceList?.filter?.(invoice => invoice?.key !== formKey),
  );
};
