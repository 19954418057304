import checkContainerChassisConstraint from './checkContainerChassisConstraint';
import checkScheduleConstraint from './checkScheduleConstraint';
import checkSendToConstraint from './checkSendToConstraint';

/**
 * @description
 * Function to check order post form constraint
 * @param {object} orderPostForm
 * Target order post form to be checked
 * @returns {boolean}
 * True if order post form constraint is valid
 */
const checkOrderPostFormConstraint = ({
  orderPostForm,
  modalMessageSetter,
}) => {
  // 0. Check container and chassis number constraint
  const ccNumberConstraintPass = checkContainerChassisConstraint({
    orderPostForm,
    modalMessageSetter,
  });
  if (!ccNumberConstraintPass) return false;

  // 1. Check email(send to) constraint
  const sendToConstraintPass = checkSendToConstraint({
    orderPostForm,
    modalMessageSetter,
  });
  if (!sendToConstraintPass) return false;

  // 2. Check schedule constraint
  const scheduleConstraintPass = checkScheduleConstraint({
    orderPostForm,
    modalMessageSetter,
  });
  if (!scheduleConstraintPass) return false;

  return true;
};

export default checkOrderPostFormConstraint;
