import styled from 'styled-components';

const StyledSaveItemLabel = styled.div`
  color: #222;
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 8px;
`;

export default StyledSaveItemLabel;
