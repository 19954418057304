import dayjs from 'dayjs';
import FloatingFilterInput from '../../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterInput';
import dateComparator from '../../../Common/Datagrid/Comparator/dateComparator';

const getDateDef = field => {
  return {
    cellClass: 'date',
    comparator: dateComparator,
    floatingFilterComponent: FloatingFilterInput,
    floatingFilterComponentParams: {
      placeholder: 'MM/DD/YY',
      inputType: 'date',
      maxLength: 8,
    },
    valueGetter: params => {
      const dateValue = params.data?.[field];
      return dateValue ? dayjs(dateValue).format('MM/DD/YY') : null;
    },
  };
};

export default getDateDef;
