import styled from 'styled-components';

const StyledScheduleAPModalTableContentRowWrapper = styled.div`
  width: 100%;
  height: 31px;
  background-color: white;
  display: flex;
  flex-direction: row;
`;

export default StyledScheduleAPModalTableContentRowWrapper;
