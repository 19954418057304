import { useQuery } from '@tanstack/react-query';
import getServiceCodeList from '../../../services/Master/ServiceCode/Get/getServiceCodeList';
import masterKeys from '../keys';

const useGetServiceCode = () => {
  const {
    isLoading: serviceCodeLoading,
    data,
    isError: serviceCodeError,
  } = useQuery({
    queryKey: masterKeys?.serviceCodeList(),
    queryFn: () => getServiceCodeList(),
    refetchOnWindowFocus: false,
  });
  return {
    serviceCodeLoading,
    serviceCodeList: data?.data,
    serviceCodeError,
  };
};

export default useGetServiceCode;
