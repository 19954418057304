import styled, { css } from 'styled-components';
import {
  SUB_ORDER_TYPE_BARECHASSIS,
  SUB_ORDER_TYPE_BROKERAGE,
  SUB_ORDER_TYPE_VAN,
} from '../../constants/Order/Common/basic';

const StyledTopInfo = styled.div`
  display: grid;

  ${props => {
    if (props?.subCategory === SUB_ORDER_TYPE_BARECHASSIS) {
      return css`
        padding-bottom: 20px;
        grid-template-rows: repeat(2, 1fr);
        grid-template-columns: repeat(3, 1fr);
        column-gap: 20px;
      `;
    }
    if (
      props?.subCategory === SUB_ORDER_TYPE_VAN ||
      props?.subCategory === SUB_ORDER_TYPE_BROKERAGE
    ) {
      return css`
        padding-bottom: 20px;
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: repeat(3, 1fr);
        column-gap: 20px;
      `;
    }
    return css`
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(4, 1fr);
      column-gap: 20px;
    `;
  }}
`;

export default StyledTopInfo;
