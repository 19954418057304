import { acctDefaultAPI } from '../../api';
import generateDuplicateError from '../../../../utils/Acct/Common/Generator/generateDuplicateError';

const postPerdiemInvoice = async ({ postData, duplicateCheckFields }) => {
  try {
    const response = await acctDefaultAPI.post('perdiem-recon', postData);
    return response;
  } catch (error) {
    throw generateDuplicateError({ error, duplicateCheckFields });
  }
};

export default postPerdiemInvoice;
