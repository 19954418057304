import styled from 'styled-components';

const StyledTMSReportDetailModalReasonContainer = styled.textarea`
  width: 100%;
  height: 152px;
  border: 1px solid #eee;
  resize: none;
  padding: 15px;
  box-sizing: border-box;
  font-size: 12px;
`;

export default StyledTMSReportDetailModalReasonContainer;
