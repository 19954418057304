import React from 'react';
import { Link } from 'react-router-dom';

const BCDetailHeaderTab = ({ label, to, tabType, detailType, ...props }) => {
  return (
    <Link to={to}>
      <div
        className={`h-[42px] min-h-[42px] w-[120px] min-w-[120px] shrink-0 ${
          tabType === detailType ? `bg-[#ffffff]` : `bg-[#F2F4F8]`
        }`}
      >
        <div
          className={`h-[40px] min-h-[40px] w-full flex justify-center items-center
                        text-[14px] font-bold ${
                          tabType === detailType
                            ? `text-[#001E5F]`
                            : `text-[#B2BDC9]`
                        } cursor-pointer`}
        >
          {label}
        </div>
        <div
          className={`h-[2px] max-h-[2px] min-h-[2px] ${
            tabType === detailType ? `bg-[#001E5F]` : `bg-[#F2F4F8]`
          }`}
        />
      </div>
    </Link>
  );
};

export default BCDetailHeaderTab;
