import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import formatNumber from '../../../../../utils/BCDetail/BillingInfo/Common/formatNumber';
import StyledBCDetailBillingInfoCommonFooterContainer from '../../../../../styles/BCDetail/BillingInfo/Common/TableFooter/StyledBCDetailBillingInfoCommonFooterContainer';
import StyledBCDetailBillingInfoCommonFooterTotalHeader from '../../../../../styles/BCDetail/BillingInfo/Common/TableFooter/StyledBCDetailBillingInfoCommonFooterTotalHeader';
import StyledBCDetailBillingInfoCommonFooterTotalValueContainer from '../../../../../styles/BCDetail/BillingInfo/Common/TableFooter/StyledBCDetailBillingInfoCommonFooterTotalValueContainer';
import StyledBCDetailBillingInfoCommonFooterTotalValue from '../../../../../styles/BCDetail/BillingInfo/Common/TableFooter/StyledBCDetailBillingInfoCommonFooterTotalValue';
import StyledBCDetailBillingInfoCommonFooterDollarSign from '../../../../../styles/BCDetail/BillingInfo/Common/TableFooter/StyledBCDetailBillingInfoCommonFooterDollarSign';

const ARTableFooter = ({ ARQuery, setProfit }) => {
  const [ARTotal, setARTotal] = useState(
    ARQuery?.ARData?.reduce((total, obj) => total + parseFloat(obj.amt), 0),
  );

  useEffect(() => {
    if (ARQuery?.ARData) {
      const calcARTotal = ARQuery?.ARData?.reduce(
        (total, obj) => total + parseFloat(obj.amt),
        0,
      );
      setARTotal(calcARTotal);
      setProfit(prev => {
        return { ...prev, ar: calcARTotal };
      });
    }
  }, [ARQuery?.ARData]);

  return (
    !ARQuery?.isLoading && (
      <StyledBCDetailBillingInfoCommonFooterContainer>
        <StyledBCDetailBillingInfoCommonFooterTotalHeader backgroundColor="#EAC5C5">
          WO AR TOTAL
        </StyledBCDetailBillingInfoCommonFooterTotalHeader>
        <StyledBCDetailBillingInfoCommonFooterTotalValueContainer backgroundColor="#EAC5C5">
          <StyledBCDetailBillingInfoCommonFooterTotalValue>
            <StyledBCDetailBillingInfoCommonFooterDollarSign>
              $
            </StyledBCDetailBillingInfoCommonFooterDollarSign>
            {formatNumber(ARTotal)}
          </StyledBCDetailBillingInfoCommonFooterTotalValue>
        </StyledBCDetailBillingInfoCommonFooterTotalValueContainer>
      </StyledBCDetailBillingInfoCommonFooterContainer>
    )
  );
};

export default ARTableFooter;
