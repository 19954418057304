import React, { useEffect, useState } from 'react';
import DualMoveModalContentMemoHeader from './DualMoveModalContentMemoHeader';
import DualMoveModalContentMemoContent from './DualMoveModalContentMemoContent';
import usePatchMemoContent from '../../../../../../../../../hooks/BCDetail/Memo/usePatchMemoContent';

const DualMoveModalContentMemo = ({ ...props }) => {
  const { job, setMessageModalOpen, setMessageModalMessage } = props;

  const { mutate } = usePatchMemoContent({
    setMobileModalOpener: null,
    woNo: job?.wo_no,
    setMessageModalOpen,
    setMessageContent: setMessageModalMessage,
  });

  const [memo, setMemo] = useState(job?.memo?.content || '');
  const [isMemoOpen, setIsMemoOpen] = useState(true);
  useEffect(() => {
    setMemo(prev => job?.memo?.content);
  }, [job?.memo?.content]);

  return (
    <div className="flex justify-center flex-col">
      <DualMoveModalContentMemoHeader
        isMemoOpen={isMemoOpen}
        setIsMemoOpen={setIsMemoOpen}
      />
      {isMemoOpen && (
        <DualMoveModalContentMemoContent
          memo={memo}
          setMemo={setMemo}
          saveMutate={mutate}
          jobSeq={job?.job_seq}
          {...props}
        />
      )}
    </div>
  );
};

export default DualMoveModalContentMemo;
