import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import CommonButton from '../../../../../../CommonButton/CommonButton';
import bcDetailKeys from '../../../../../../../hooks/BCDetail/keys';
import usePostAssignDriverAPInput from '../../../../../../../hooks/BCDetail/DetailInfo/Schedule/usePostAssignDriverAPInput';
import generateAPData from '../../../../../../../utils/BCDetail/DetailInfo/Schedule/Generator/generateAPData';

const ScheduleAPModalButtonContent = ({
  apInputData,
  selectedDriver,
  apTableContent,
  setAPModalOpener,
  setSelectedScheduleSeq,
  setSelectedDriverData,
  selectedScheduleSeq,
  setMessageModalOpener,
  setMessageModalMessage,
  setLoadingOpener,
  ...props
}) => {
  const woNo =
    document.URL?.split('/')?.[document?.URL?.split('/')?.length - 1];

  const queryClient = useQueryClient();
  const { mutate: apPostMutator } = usePostAssignDriverAPInput({
    setAPModalOpener,
    setModalOpener: setMessageModalOpener,
    setModalMessage: setMessageModalMessage,
    setLoadingOpener,
  });

  const { product_codes: productCodes } = apInputData;

  return (
    <div className="w-full flex justify-center items-center">
      <div className="flex gap-[20px]">
        <CommonButton
          category="cancel"
          onClick={() => {
            queryClient?.removeQueries({
              queryKey: bcDetailKeys?.driverListDefault(),
            });
            queryClient?.removeQueries({
              queryKey: bcDetailKeys?.apInputDefault(),
            });
            setSelectedScheduleSeq(null);
            setSelectedDriverData(null);
            setAPModalOpener(false);
          }}
        >
          Cancel
        </CommonButton>
        <CommonButton
          category="save"
          preventDupClick
          onClick={({ buttonRef }) => {
            const tempAPdata = generateAPData({
              apData: apTableContent,
              productCodes,
            });
            apPostMutator({
              woNo,
              seq: selectedScheduleSeq,
              driver: selectedDriver,
              apData: tempAPdata,
              buttonRef,
            });
          }}
        >
          Save
        </CommonButton>
      </div>
    </div>
  );
};

export default ScheduleAPModalButtonContent;
