import { useQuery } from '@tanstack/react-query';
import tmsReportKeys from '../keys';
import getPerdiemList from '../../../services/TmsReport/Perdiem/Get/getPerdiemList';

const useGetPerdiemList = ({ division, fromDate, toDate }) => {
  const { isLoading, data, isRefetching, isError } = useQuery({
    queryKey: tmsReportKeys?.perdiemList({ division, fromDate, toDate }),
    queryFn: () => getPerdiemList({ division, fromDate, toDate }),
    refetchOnWindowFocus: false,
  });

  return { isLoading, data: data?.data, isRefetching, isError };
};

export default useGetPerdiemList;
