import { CELL_TYPE_BUTTON } from '../../../../constants/Common/DataGrid/cellType';
import {
  PATH_BUTTON_GRID_EXPORT_DISABLED_ICON,
  PATH_BUTTON_GRID_EXPORT_UNSELECTED_ICON,
} from '../../../../constants/Common/path';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';

const getExportButtonDef = ({ onClickHandler }) => {
  return {
    floatingFilter: false,
    cellRendererSelector: gridParam => {
      const disabled = !(gridParam.data.bk_no && gridParam.data.st);
      return cellRendererGenerator({
        gridParam,
        cellType: CELL_TYPE_BUTTON,
        customStyle: {
          size: 'small',
        },
        content: 'Export',
        disabled,
        icon: disabled
          ? PATH_BUTTON_GRID_EXPORT_DISABLED_ICON
          : PATH_BUTTON_GRID_EXPORT_UNSELECTED_ICON,
        onClickHandler,
      });
    },
    suppressChangeDisabled: true,
  };
};

export default getExportButtonDef;
