import checkDateDiff from '../../../Common/Handler/checkDateDiff';

const searchReconcileContent = ({
  handleSubmit,
  setQueryObject,
  setOpenModal,
  setErrorModalOptions,
}) => {
  const { setErrorModalOpen, setErrorMessage, setIsSuccess } =
    setErrorModalOptions;

  handleSubmit(submitData => {
    if (
      !checkDateDiff({
        fromDate: submitData?.from,
        toDate: submitData?.to,
      })
    ) {
      setIsSuccess(false);
      setErrorMessage(
        'The date range cannot exceed one year. Please try again.',
      );
      setErrorModalOpen(true);
      return;
    }
    setQueryObject(submitData);
    setOpenModal(false);
  })();
};

export default searchReconcileContent;
