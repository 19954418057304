import React from 'react';
import { PAHT_BUTTON_DELETE_RED_CROSS_ICON } from '../../../../../../constants/Common/path';
import handleDetailInfoUpdate from '../../../../../../utils/BCDetail/DetailInfo/Common/Handler/handleDetailInfoUpdate';
import { UPDATE_TYPE } from '../../../../../../constants/BCDetail/Common/type';

const ScheduleDriverDeleteButton = ({
  jobData,
  setSelectedRowData,
  setDeleteDriverModalOpener,
  updateProps,
  basicInfoData,
  scheduleData,
  setSelectedScheduleSeq,
  scheduleSeq,
  basicInfoFormController,
  scheduleFormController,
  setChangeDriverWarningModalOpen,
  setChangeDriverWarningModalType,
}) => {
  const handleDeleteClick = e => {
    e.stopPropagation();
    if (jobData && jobData?.status && jobData?.status !== 'JS0001') {
      setChangeDriverWarningModalType(prev => UPDATE_TYPE.DELETE_DRIVER);
      setChangeDriverWarningModalOpen(prev => true);
    } else {
      handleDetailInfoUpdate({
        ...updateProps,
        basicInfoData: basicInfoFormController?.getValues(),
        scheduleData: scheduleFormController?.getValues(),
        setSelectedScheduleSeq,
        scheduleSeq,
        setSelectedRowData,
        jobData,
        setDeleteDriverModalOpener,
        updateType: UPDATE_TYPE.DELETE_DRIVER,
      });
    }
  };
  return (
    <div
      className="absolute right-[4px] top-[8px] cursor-pointer"
      onClick={handleDeleteClick}
    >
      <img
        src={PAHT_BUTTON_DELETE_RED_CROSS_ICON}
        width={15}
        height={15}
        alt="redCrossIcon"
      />
    </div>
  );
};

export default ScheduleDriverDeleteButton;
