import dispatchDefaultAPI from '../../api';

const getUserColumn = async ({ category, isYard, status }) => {
  try {
    const response = await dispatchDefaultAPI.get(
      `user-column/${category}/${status}${isYard ? '/yard' : ''}`,
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};
export default getUserColumn;
