import React from 'react';
import { MANIFEST_DETAIL_CELL_WIDTH } from '../../../../../constants/MobileAdmin/Manifest/manifestDetailValues';

const ManifestDetailHeader = ({ headers }) => {
  return (
    <div className="row w-full h-[35px]">
      {Object.entries(headers)?.map(([key, header]) => {
        return (
          <div
            key={header}
            className={`cell bg-transparent ${MANIFEST_DETAIL_CELL_WIDTH[key]}`}
          >
            {header}
          </div>
        );
      })}
    </div>
  );
};

export default ManifestDetailHeader;
