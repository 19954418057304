const orderKeys = {
  all: ['ORDER'],
  billTo: () => [...orderKeys.all, 'BILL_TO'],
  otherLoc: () => [...orderKeys.all, 'OTHER'],
  bookingInfo: () => [...orderKeys.all, 'BOOKING_INFO'],
  bookingInfoWithKey: ({ bookingSequence }) => [
    ...orderKeys.bookingInfo(),
    bookingSequence,
  ],
};

export default orderKeys;
