export const generateSSLOption = sslList => {
  const optionsList = sslList?.map(ssl => {
    return { label: ssl?.name, value: ssl?.ssl };
  });
  return optionsList;
};

export const generateWOOption = ({ woData }) => {
  return woData?.map((wo, index) => {
    return { key: index, label: wo?.wo_no, value: wo?.wo_no };
  });
};
