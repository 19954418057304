import { quickBooksDefaultApi } from '../../../QuickBooks/api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

const postMultipleQB = async ({ submitData }) => {
  try {
    const response = await quickBooksDefaultApi.post(``, submitData);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default postMultipleQB;
