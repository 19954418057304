const DISPATCH_EXPORT_FILTER_DATE = [
  {
    value: 'create-',
    label: 'WO CREATE DATE',
  },
  {
    value: 'po-schedule-',
    label: 'P/O S DATE',
  },
  {
    value: 'po-actual-out-',
    label: 'P/O A OUT DATE',
  },
  {
    value: 'dilv-schedule-',
    label: 'DILV S DATE',
  },
  {
    value: 'dilv-actual-out-',
    label: 'DILV A OUT DATE',
  },
  {
    value: 'rtn-schedule-',
    label: 'RTN S DATE',
  },
  {
    value: 'rtn-actual-out-',
    label: 'RTN A OUT DATE',
  },
];

const DISPATCH_EXPORT_FILTER_CATEGORY = [
  {
    value: '',
    label: 'Select',
  },
  {
    value: 'booking',
    label: 'BOOKING #',
  },
  {
    value: 'cont-no',
    label: 'CONT #',
  },
  {
    value: 'wo-no',
    label: 'WO #',
  },
  {
    value: 'ref-no',
    label: 'REF #',
  },
  {
    value: 'purchase-no',
    label: 'PO #',
  },
  {
    value: 'mbl-no',
    label: 'MBL #',
  },
  {
    value: 'billto',
    label: 'BILL TO',
  },
  {
    value: 'po-loc',
    label: 'P/O LOC',
  },
  {
    value: 'dilv-loc',
    label: 'DILV LOC',
  },
];

const DISPATCH_BOOKING_FILTER_DATE = [
  {
    value: 'create-',
    label: 'CREATE DATE',
  },
  {
    value: 'erd-',
    label: 'ERD',
  },
  {
    value: 'co-',
    label: 'CUT OFF',
  },
];

const DISPATCH_BOOKING_FILTER_STATUS = [
  {
    value: 'active',
    label: 'ACTIVE',
  },
  {
    value: 'inactive',
    label: 'INACTIVE',
  },
];

export {
  DISPATCH_BOOKING_FILTER_DATE,
  DISPATCH_BOOKING_FILTER_STATUS,
  DISPATCH_EXPORT_FILTER_CATEGORY,
  DISPATCH_EXPORT_FILTER_DATE,
};
