import React from 'react';
import CommonButton from '../../../../CommonButton/CommonButton';

const EmailConfirmModalButtonContent = ({ ...props }) => {
  const { setOpen, onSaveClick } = props;

  return (
    <div className="w-full flex flex-row gap-[15px] justify-center items-center">
      <CommonButton category="cancel" onClick={() => setOpen(prev => false)}>
        Cancel
      </CommonButton>
      <CommonButton
        preventDupClick
        category="save"
        onClick={({ buttonRef }) => onSaveClick?.({ buttonRef })}
      >
        Save
      </CommonButton>
    </div>
  );
};

export default EmailConfirmModalButtonContent;
