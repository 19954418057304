const bcDetailKeys = {
  all: ['BC_DETAIL'],
  defaultDetailInfo: () => [...bcDetailKeys.all, 'DETAIL_INFO'],
  detailInfo: woNo => [...bcDetailKeys?.defaultDetailInfo(), woNo],
  driverListDefault: () => [...bcDetailKeys.all, 'DRIVER_LIST'],
  driverList: ({ woNo, driver }) => [
    ...bcDetailKeys.driverListDefault(),
    woNo,
    driver,
  ],
  apInputDefault: () => [...bcDetailKeys.all, 'AP_INPUT'],
  apInput: ({ woNo, scheduleSeq, driver }) => [
    ...bcDetailKeys.apInputDefault(),
    woNo,
    scheduleSeq,
    driver,
  ],
  mobileMemoDefault: () => [...bcDetailKeys.all, 'MOBILE_MEMO'],
  mobileMemo: ({ seq }) => [...bcDetailKeys.mobileMemoDefault(), seq],
  mobileMemoList: ({ woNo }) => [...bcDetailKeys.mobileMemoDefault(), woNo],
  billingInfoAP: woNo => [...bcDetailKeys.all, 'BILLING_INFO_AP', woNo],
  billingInfoAPProductCode: () => [
    ...bcDetailKeys.all,
    'BILLING_INFO_AP_PRODUCT_CODE',
  ],
  billingInfoAPDriver: () => ['BILLING_INFO_AP_DRIVER'],
  billingInfoAPVendor: () => [
    ...bcDetailKeys.all,
    'BILLING_INFO_AP_DRIVER_VENDOR',
  ],
  billingInfoAPPDFRateCFM: () => [
    ...bcDetailKeys.all,
    'BILLING_INFO_AP_PDF_RATE_CFM',
  ],
  billingInfoAPRateCFM: seq => [
    ...bcDetailKeys.all,
    'BILLING_INFO_AP_RATE_CFM',
    seq,
  ],
  billingInfoAR: woNo => [...bcDetailKeys.all, 'BILLING_INFO_AR', woNo],
  billingInfoARProductCode: () => [
    ...bcDetailKeys.all,
    'BILLING_INFO_AR_PRODUCT_CODE',
  ],
  billingInfoARDriverVendor: () => [
    ...bcDetailKeys.all,
    'BILLING_INFO_AR_DRIVER_VENDOR',
  ],
  document: ({ woNo }) => [...bcDetailKeys.all, 'DOCUMENT_LIST', woNo],
  billingInfoJobAssign: ({ woNo, driverID }) => [
    ...bcDetailKeys.all,
    'BILLING_INFO_JOB_ASSIGN',
    woNo,
    driverID,
  ],
  email: () => [...bcDetailKeys.all, 'EMAIL'],
  emailWO: ({ woNo }) => [...bcDetailKeys.email(), woNo],
  bookingNumberDefault: () => [...bcDetailKeys.all, 'BOOKING_NUMBER'],
  bookingNumber: ({ woNo, size, type, ssl }) => [
    ...bcDetailKeys?.bookingNumberDefault(),
    woNo,
    size,
    type,
    ssl,
  ],
  trackingList: ({ woNo }) => [...bcDetailKeys.all, 'TRACKING_LIST', woNo],
  trackingModalDefault: () => [...bcDetailKeys.all, 'TRACKING_MODAL'],
  trackingModal: ({ woNo }) => [
    ...bcDetailKeys.trackingModalDefault(),
    { woNo },
  ],
  woMemo: () => [...bcDetailKeys.all, 'WO_MEMO_LIST'],
  woMemoList: ({ woNo }) => [...bcDetailKeys.woMemo(), woNo],
  woPDFLink: ({ woNo, from, to }) => [
    ...bcDetailKeys.all,
    'PDF',
    woNo,
    from,
    to,
  ],
  defaultDualJobPullOut: () => [...bcDetailKeys.all, 'DUAL_MOVE_PULL_OUT'],
  dualJobPullOut: ({ contNo, fromScheDate, rtnLoc, toScheDate }) => [
    ...bcDetailKeys.defaultDualJobPullOut(),
    { contNo, fromScheDate, rtnLoc, toScheDate },
  ],
  defaultDualJob: () => [...bcDetailKeys.all, 'DUAL_JOB'],
  dualJob: ({ loc }) => [...bcDetailKeys.defaultDualJob(), loc],
  defaultDualAPInput: () => [...bcDetailKeys.all, 'DUAL_AP_INPUT'],
  dualAPInput: ({ woNo, scheduleSeq, driver }) => [
    ...bcDetailKeys.defaultDualAPInput(),
    {
      woNo,
      scheduleSeq,
      driver,
    },
  ],
  yardList: ({ woNo }) => [...bcDetailKeys.all, 'YARD_LIST', woNo],
};

export default bcDetailKeys;
