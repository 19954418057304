import styled, { css } from 'styled-components';
import {
  SUB_ORDER_TYPE_BARECHASSIS,
  SUB_ORDER_TYPE_BROKERAGE,
  SUB_ORDER_TYPE_VAN,
} from '../../../constants/Order/Common/basic';

const StyledContainerInfoWrapper = styled.div`
  ${props => {
    if (props?.subCategory === SUB_ORDER_TYPE_BARECHASSIS) {
      return css`
        height: 175px;
        min-height: 175px;
      `;
    }
    if (props?.subCategory === SUB_ORDER_TYPE_VAN) {
      return css`
        height: 245px;
        min-height: 245px;
      `;
    }
    if (props?.subCategory === SUB_ORDER_TYPE_BROKERAGE) {
      return css`
        height: 378px;
        min-height: 378px;
      `;
    }
    return css`
      height: 315px;
      min-height: 315px;
    `;
  }}

  background-color: #556da11a;
  border-radius: 5px;

  padding: 0 15px 0 15px;
`;

export default StyledContainerInfoWrapper;
