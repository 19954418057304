export const MASTER_COMPANY_CUSTOMER_TYPE = [
  { key: 0, label: 'FF', value: 'COC001' },
  { key: 1, label: 'SSL', value: 'COC002' },
  { key: 2, label: 'BCO', value: 'COC003' },
  { key: 3, label: 'NGL', value: 'COC004' },
];

export const MASTER_COMPANY_VENDOR_TYPE = [
  { key: 0, label: 'Service Vendor', value: 'COV001' },
  { key: 1, label: 'Non-Service Vendor', value: 'COV002' },
];

export const MASTER_COMPANY_SEARCH_TYPE_OPTION = [
  { key: 0, label: 'Select', value: '' },
  { key: 1, label: 'FF', value: 'COC001' },
  { key: 2, label: 'SSL', value: 'COC002' },
  { key: 3, label: 'BCO', value: 'COC003' },
  { key: 4, label: 'NGL', value: 'COC004' },
  { key: 5, label: 'Service Vendor', value: 'COV001' },
  { key: 6, label: 'Non-Service Vendor', value: 'COV002' },
];

export const MASTER_COMPANY_W9_OPTION = [
  { key: 0, label: 'IND/SOLE', value: 'CW0001' },
  { key: 1, label: 'C CORP', value: 'CW0002' },
  { key: 2, label: 'S CORP', value: 'CW0003' },
  { key: 3, label: 'PART', value: 'CW0004' },
  { key: 4, label: 'TRUST', value: 'CW0005' },
  { key: 5, label: 'LLC', value: 'CW0006' },
];
