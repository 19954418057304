import {
  DISPATCH_PAGE_EXPORT_AVAILABLE,
  DISPATCH_PAGE_EXPORT_BOOKING,
  DISPATCH_PAGE_EXPORT_DELIVERY,
  DISPATCH_PAGE_EXPORT_RETURN,
  DISPATCH_PAGE_EXPORT_UNAVAILABLE,
} from '../../Common/category';
import {
  AVAILABLE_SUB_FILTERS,
  DELIVERY_SUB_FILTERS,
  EXPORT_UNAVAILABLE_SUB_FILTERS,
  RETURN_SUB_FILTERS,
} from '../../Common/filterButtonSettings';

const EXPORT_FILTERS = [
  {
    header: 'BOOKING',
    key: 'booking',
    status: DISPATCH_PAGE_EXPORT_BOOKING,
    to: '/dispatch/export/booking',
    children: [
      {
        header: 'Active',
        width: '90px',
        key: 'all',
      },
    ],
  },
  {
    header: 'UNAVAILABLE',
    key: 'unavailable',
    status: DISPATCH_PAGE_EXPORT_UNAVAILABLE,
    to: '/dispatch/export/unavailable',
    children: EXPORT_UNAVAILABLE_SUB_FILTERS,
  },
  {
    header: 'AVAILABLE',
    key: 'available',
    status: DISPATCH_PAGE_EXPORT_AVAILABLE,
    to: '/dispatch/export/available',
    children: AVAILABLE_SUB_FILTERS,
  },
  {
    header: 'DELIVERY Ready',
    key: 'delivery',
    status: DISPATCH_PAGE_EXPORT_DELIVERY,
    to: '/dispatch/export/delivery',
    children: DELIVERY_SUB_FILTERS,
  },
  {
    header: 'RETURN Ready',
    key: 'return',
    status: DISPATCH_PAGE_EXPORT_RETURN,
    to: '/dispatch/export/return',
    children: RETURN_SUB_FILTERS,
  },
];

export default EXPORT_FILTERS;
