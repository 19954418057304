import {
  SUB_ORDER_TYPE_BROKERAGE,
  SUB_ORDER_TYPE_EXPREG,
  SUB_ORDER_TYPE_EXPSHUTTLE,
  SUB_ORDER_TYPE_IMPREG,
  SUB_ORDER_TYPE_IMPSHUTTLE,
  SUB_ORDER_TYPE_VAN,
} from '../../../constants/Order/Common/basic';

/**
 * @description
 * Function to generate post sub category according to sub category
 * @param {string} subCategory
 * Sub category of order
 * @returns {string}
 * Page sub category
 */
const generatePostSubCategory = ({ subCategory }) => {
  if (subCategory === SUB_ORDER_TYPE_IMPREG) return 'import-regular';
  if (subCategory === SUB_ORDER_TYPE_IMPSHUTTLE) return 'import-shuttle';
  if (subCategory === SUB_ORDER_TYPE_EXPREG) return 'export-regular';
  if (subCategory === SUB_ORDER_TYPE_EXPSHUTTLE) return 'export-shuttle';
  if (subCategory === SUB_ORDER_TYPE_VAN) return 'van';
  if (subCategory === SUB_ORDER_TYPE_BROKERAGE) return 'brokerage';
  return 'bare-chassis';
};

export default generatePostSubCategory;
