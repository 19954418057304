import { useQuery } from '@tanstack/react-query';
import getDivInfo from '../../../../services/Admin/DivConf/Get/getDivInfo';
import adminKeys from '../../keys';

const useGetDivInfo = ({ div }) => {
  const { isLoading: isLoadingDivInfo, data } = useQuery({
    queryKey: adminKeys?.divInfo({ div }),
    queryFn: () => getDivInfo({ div }),
    refetchOnWindowFocus: false,
  });

  return {
    isLoadingDivInfo,
    data,
  };
};

export default useGetDivInfo;
