import { useMutation } from '@tanstack/react-query';
import { ERROR_INACTIVE_USER } from '../../constants/Common/errorMessage';
import { signIn } from '../../services/SignIn/Post/signIn';

const useSignInMutation = ({ setIsMessageOpen, setMessage }) =>
  useMutation({
    mutationFn: signIn,
    onSuccess: res => {
      if (res?.status === 201) {
        setMessage(ERROR_INACTIVE_USER);
        setIsMessageOpen(true);
        return;
      }
      const data = res.data;
      localStorage.setItem('csrfToken', data.csrf_token);
      localStorage.setItem('userId', data.username);
      localStorage.setItem('permission', data.permissions);
      // localStorage.setItem('firstPage', data.first_page);
      localStorage.setItem('firstPage', 'order/impreg');
      localStorage.setItem('allDiv', data.all_div);
      localStorage.setItem('defaultDiv', data?.default_div);
      sessionStorage.setItem('nowDiv', data?.default_div);
      // window.location.href = `/${data.first_page}`;
      window.location.href = '/order/impreg';
    },
    onError: error => {
      setMessage(error.message);
      setIsMessageOpen(true);
    },
  });

export default useSignInMutation;
