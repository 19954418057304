import axios from 'axios';
import interceptRequest from '../../utils/Common/Interceptor/interceptRequest';

export const BASE_URL = process.env.REACT_APP_BASE_URL;

/* account base url */
const EDI_BASE_URL = `${process.env.REACT_APP_BASE_URL}/edi/`;

/* Axios Setting */
const ediDefaultAPI = axios.create({
  withCredentials: true,
  baseURL: EDI_BASE_URL,
  xsrfHeaderName: 'X-CSRFTOKEN',
  xsrfCookieName: 'XCSRF-TOKEN',
  headers: {
    'Content-Type': 'multipart/form-data',
    common: {
      'X-CSRFToken': localStorage.getItem('csrfToken'),
    },
  },
});

interceptRequest(ediDefaultAPI);

export default ediDefaultAPI;
