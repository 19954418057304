import onARSubmit from './onARSubmit';

const onARSubmitHandler = async ({
  handleSubmit,
  submitType,
  arMutator,
  seq,
  ARProductCodeQuery,
  setIsAlertOpen,
  setErrorMessage,
  setIsSuccess,
  buttonRef,
}) => {
  await handleSubmit(data => {
    if (submitType === 'ADD') {
      onARSubmit({
        data,
        arMutator,
        submitType,
        ARProductCodeQuery,
        setIsAlertOpen,
        setErrorMessage,
        setIsSuccess,
        buttonRef,
      });
    } else if (submitType === 'MOD') {
      onARSubmit({
        data,
        arMutator,
        submitType,
        ARProductCodeQuery,
        seq,
        setIsAlertOpen,
        setErrorMessage,
        setIsSuccess,
        buttonRef,
      });
    } else if (submitType === 'DEL') {
      onARSubmit({
        data,
        arMutator,
        submitType,
        ARProductCodeQuery,
        seq,
        setIsSuccess,
        buttonRef,
      });
    }
  })();
};

export default onARSubmitHandler;
