import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import divBasicDefaultApi from '../../divApi';

const deleteProductCode = async ({ seq, productCode }) => {
  try {
    const response = await divBasicDefaultApi.delete(
      `product-code-map/${seq}`,
      {
        product_code: productCode,
      },
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default deleteProductCode;
