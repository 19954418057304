import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';
import bcDetailDefaultApi from '../../../api';

const postAssignDriverAPInput = async ({
  woNo,
  seq,
  driver,
  apData,
  dual,
  dualSeq,
}) => {
  try {
    const response = await bcDetailDefaultApi.post(
      `${woNo}/assign-driver/ap-input/${seq}`,
      {
        driver,
        ap_data: apData,
      },
      { params: { page: 'bcdetail', dual, dual_seq: dualSeq } },
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default postAssignDriverAPInput;
