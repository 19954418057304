import { useQuery } from '@tanstack/react-query';
import masterKeys from '../keys';
import getARRateList from '../../../services/Master/ARRate/Get/getARRateList';

const useGetARRateList = ({ div }) => {
  const {
    isLoading: loadingARList,
    data: arRateList,
    isRefetching,
    isError: errorARRateList,
  } = useQuery({
    queryKey: masterKeys?.arRateList({ div }),
    queryFn: () => getARRateList({ div }),
    refetchOnWindowFocus: false,
  });
  return { loadingARList, arRateList, isRefetching, errorARRateList };
};

export default useGetARRateList;
