import React from 'react';
import CommonButton from '../../../CommonButton/CommonButton';
import ProductInputArea from '../ProductInputArea';
import exportToExcel from '../../../../utils/Common/Datagrid/Handler/exportToExcel';
import { RED_FILE_UPLOAD_ICON } from '../../../../constants/Acct/Common/iconPath';
import { ACCT_EXCEL_FILE_NAMES } from '../../../../constants/Acct/Common/staticNames';

const HeaderButtonArea = ({
  page,
  setOpenModal,
  productStatus,
  setProductStatus,
  setOpenUploadModal,
  setQueryObject,
  gridRef,
}) => {
  return (
    <div className="w-full flex flex-row justify-between item-center">
      <ProductInputArea
        productStatus={productStatus}
        setProductStatus={setProductStatus}
        setQueryObject={setQueryObject}
      />
      <div className="w-full flex flex-row justify-end text-right mt-[25px] mb-[21px] gap-x-[12px]">
        <CommonButton
          type="button"
          category="greySearch"
          border="0"
          backgroundColor="transparent"
          color="#666666"
          fontWeight="700"
          onClick={() => {
            setOpenModal?.(true);
          }}
        >
          Search
        </CommonButton>
        <CommonButton
          type="button"
          category="excel"
          fontWeight="700"
          onClick={() => {
            exportToExcel({ gridRef, fileName: ACCT_EXCEL_FILE_NAMES?.[page] });
          }}
        >
          EXCEL
        </CommonButton>
        <CommonButton
          type="button"
          category="upload"
          icon={<img src={RED_FILE_UPLOAD_ICON} alt="hyphen-icon" width={13} />}
          fontWeight="700"
          borderColor="#B74747"
          onClick={() => {
            setOpenUploadModal(true);
          }}
        >
          Upload File
        </CommonButton>
      </div>
    </div>
  );
};

export default HeaderButtonArea;
