import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SUCCESS_DELETE } from '../../../../constants/Common/successMessage';
import deleteWeightPerZIP from '../../../../services/Admin/DivConf/Delete/deleteWeightPerZIP';
import useControlToast from '../../../Common/Toast/useControlToast';
import adminKeys from '../../keys';

const useDeleteWeightPerZipMutation = ({
  setIsMessageOpen,
  setMessage,
  setIsSuccess,
  div,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  return useMutation({
    mutationFn: deleteWeightPerZIP,
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: adminKeys?.weightPerZIP({ div }),
      });
      addToast({
        message: SUCCESS_DELETE,
      });
    },
    onError: error => {
      queryClient.refetchQueries({
        queryKey: adminKeys?.weightPerZIP({ div }),
      });
      setIsSuccess(false);
      setMessage(error.message);
      setIsMessageOpen(true);
    },
  });
};

export default useDeleteWeightPerZipMutation;
