import React, { useState } from 'react';
import { Box } from '@mui/material';
import Contents from '../Content';
import Header from '../Header';
import Sidebar from '../Sidebar';
import handleSidebarOpen from '../../utils/Common/Sidebar/Handler/handleSidebarOpen';

const MainLayOut = ({ tabsData, children, openMenu, suppressSidebar }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const handleMenu = () => {
    // setIsSidebarOpen(prev => !prev);
    handleSidebarOpen({ setIsSidebarOpen });
  };
  const handleModal = () => {
    // setIsSidebarOpen(prev => !prev);
    handleSidebarOpen({ setIsSidebarOpen });
  };
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        '& .MuiContainer-root': { padding: 0 },
      }}
    >
      <Header
        tabsData={tabsData}
        isMenuOpen={isMenuOpen}
        isModalOpen={isModalOpen}
        handleMenu={handleMenu}
        handleModal={handleModal}
        setModalOpen={setModalOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        suppressSidebar={suppressSidebar}
      />
      {!suppressSidebar && (
        <Sidebar isOpen={isSidebarOpen} setOpen={setIsSidebarOpen} />
      )}
      <Contents>{children}</Contents>
    </Box>
  );
};

export default MainLayOut;
