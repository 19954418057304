import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';
import bcDetailDefaultApi from '../../../api';

const getDualJob = async ({ ...props }) => {
  const { loc } = props;

  try {
    const response = await bcDetailDefaultApi.get(`dual-job`, {
      params: {
        loc,
      },
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getDualJob;
