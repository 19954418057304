export const SELECT_ITEM_AE = [
  { label: 'None', value: 'None' },
  { label: 'Edit', value: 'Edit' },
];

export const SELECT_ITEM_VIEW = [
  { label: 'None', value: 'None' },
  { label: 'View', value: 'View' },
];

export const SELECT_ITEM_ALL = [
  { label: 'None', value: 'None' },
  { label: 'View', value: 'View' },
  { label: 'Edit', value: 'Edit' },
];

export const PERM_FORMAT = {
  acct_ch_recon: null,
  acct_drv_pay: null,
  acct_per_recon: null,
  acct_qb_ap_ar: null,
  acct_qb_recon: null,
  acct_uninv: null,
  admin_auth: null,
  admin_conf: null,
  admin_div_conf: null,
  admin_div_ew: null,
  admin_user: null,
  bcdetail_ap: null,
  bcdetail_ap_ar_delete: null,
  bcdetail_ar: null,
  bcdetail_bare: null,
  bcdetail_brok: null,
  bcdetail_email: null,
  bcdetail_exp: null,
  bcdetail_imp: null,
  bcdetail_po_ch: null,
  bcdetail_van: null,
  cus_main: null,
  cus_setting: null,
  dispatch_bk: null,
  dispatch_brok: null,
  dispatch_exp: null,
  dispatch_imp: null,
  dispatch_van: null,
  edi_accept: null,
  edi_decline: null,
  edi_page: null,
  main_bare: null,
  main_brok: null,
  main_exp: null,
  main_imp: null,
  main_van: null,
  master_acct_code: null,
  master_ap_rate: null,
  master_ar_rate: null,
  master_ch_rate: null,
  master_cnee: null,
  master_comp: null,
  master_drv: null,
  master_prod_code: null,
  master_tier_weight: null,
  mobile_bill: null,
  mobile_manifest: null,
  mobile_pod: null,
  order_bare: null,
  order_brok: null,
  order_exp: null,
  order_imp: null,
  order_van: null,
  qb_delete: null,
  qb_locked: null,
  qb_send: null,
  qb_update: null,
  tms_dem: null,
  tms_dilv: null,
  tms_ew: null,
  tms_per: null,
  tms_po: null,
  tms_rtn: null,
  tms_stat: null,
  trace_geofence: null,
  trace_job: null,
  trace_map: null,
  wo_complete: null,
  wo_convert: null,
  wo_delete: null,
};
