import {
  getFormattedDateGenerator,
  getFormattedTimeGenerator,
} from '../../../../Common/Generator/formattedDateTimeGenerator';

/**
 * @description
 * Function that checks if schedule data is changed
 * @param {object} originData
 * Origin data of schedule
 * @param {object} inputData
 * Input data of schedule
 * @param {string} dataKey
 * Key of schedule data
 * @param {string} locationInfo
 * Location information
 * @returns {boolean}
 * If schedule data is changed
 */
const checkScheduleChanged = ({
  originData,
  inputData,
  dataKey,
  locationInfo,
}) => {
  if (originData && inputData) {
    if (
      originData?.company?.company !==
        inputData?.[`company_${dataKey}_${locationInfo}`] &&
      `company_${dataKey}_${locationInfo}` in inputData
    ) {
      return { isChanged: true, where: 'company' };
    }
    if (
      originData?.sche_date !==
        getFormattedDateGenerator({
          date: inputData?.[`sche_date_${dataKey}_${locationInfo}`],
        }) &&
      `sche_date_${dataKey}_${locationInfo}` in inputData
    ) {
      return { isChanged: true, where: 'sche_date' };
    }
    if (
      originData?.sche_time !==
        getFormattedTimeGenerator({
          date: inputData?.[`sche_date_${dataKey}_${locationInfo}`],
          time: inputData?.[`sche_time_${dataKey}_${locationInfo}`],
        }) &&
      `sche_date_${dataKey}_${locationInfo}` in inputData &&
      `sche_time_${dataKey}_${locationInfo}` in inputData
    ) {
      return { isChanged: true, where: 'sche_time' };
    }
    if (
      originData?.actual_in_date !==
        getFormattedDateGenerator({
          date: inputData?.[`actual_in_date_${dataKey}_${locationInfo}`],
        }) &&
      `actual_in_date_${dataKey}_${locationInfo}` in inputData
    ) {
      return { isChanged: true, where: 'actual_in_date' };
    }
    if (
      originData?.actual_in_time !==
        getFormattedTimeGenerator({
          date: inputData?.[`actual_in_date_${dataKey}_${locationInfo}`],
          time: inputData?.[`actual_in_time_${dataKey}_${locationInfo}`],
        }) &&
      `actual_in_date_${dataKey}_${locationInfo}` in inputData &&
      `actual_in_time_${dataKey}_${locationInfo}` in inputData
    ) {
      return { isChanged: true, where: 'actual_in_time' };
    }
    if (
      originData?.actual_out_date !==
        getFormattedDateGenerator({
          date: inputData?.[`actual_out_date_${dataKey}_${locationInfo}`],
        }) &&
      `actual_out_date_${dataKey}_${locationInfo}` in inputData
    ) {
      return { isChanged: true, where: 'actual_out_date' };
    }
    if (
      originData?.actual_out_time !==
        getFormattedTimeGenerator({
          date: inputData?.[`actual_out_date_${dataKey}_${locationInfo}`],
          time: inputData?.[`actual_out_time_${dataKey}_${locationInfo}`],
        }) &&
      `actual_out_date_${dataKey}_${locationInfo}` in inputData &&
      `actual_out_time_${dataKey}_${locationInfo}` in inputData
    ) {
      return { isChanged: true, where: 'actual_out_time' };
    }
  }
  return false;
};

/**
 * @description
 * Function that gets exact target job in job-set
 * @param {object} targetJobSet
 * Target job-set data
 * @param {string} locationInfo
 * Location information
 * @returns {object | null}
 * Exact target job
 */
const getExactTargetJob = ({ targetJobSet, locationInfo }) => {
  if (locationInfo === 'fr') {
    return targetJobSet?.from_location;
  }
  if (locationInfo === 'to') {
    return targetJobSet?.to_location;
  }
  if (locationInfo === 'ld') {
    return targetJobSet?.live_dilv_location;
  }
  return null;
};

/**
 * @description
 * Function that checks if job-set schedule is changed
 * @param {number} jobSetSeq
 * Job-set sequence
 * @param {number} locationSeq
 * Location sequence
 * @param {string} locationInfo
 * Location information
 * @param {list} jobSetData
 * Data of job-set from API
 * @param {object} inputData
 * Input data of schedule
 * @returns {boolean}
 * If job-set schedule is changed
 */
export const checkJobScheduleChanged = ({
  jobSetSeq,
  locationSeq,
  locationInfo,
  jobSetData,
  inputData,
}) => {
  const targetJobSet = jobSetData?.find(job => {
    if (locationInfo === 'fr') {
      return job?.seq === jobSetSeq && job?.from_location?.seq === locationSeq;
    }
    if (locationInfo === 'ld') {
      return (
        job?.seq === jobSetSeq && job?.live_dilv_location?.seq === locationSeq
      );
    }
    if (locationInfo === 'to') {
      return job?.seq === jobSetSeq && job?.to_location?.seq === locationSeq;
    }
    return null;
  });
  const exactTargetJob = getExactTargetJob({ targetJobSet, locationInfo });
  const isScheduleChanged = checkScheduleChanged({
    originData: exactTargetJob,
    inputData,
    dataKey: `${jobSetSeq}_${locationSeq}`,
    locationInfo,
  });
  return isScheduleChanged;
};

/**
 * @description
 * Function that gets exact target location in location-set
 * @param {number} locationSetSeq
 * Location-set sequence
 * @param {string} locationInfo
 * Location information
 * @param {list} locationSetData
 * Data of location-set from API
 * @returns {boolean}
 * If location-set schedule is changed
 */
export const checkLocationScheduleChanged = ({
  locationSetSeq,
  locationInfo,
  locationSetData,
  inputData,
}) => {
  const targetLocationSet = locationSetData?.find(location => {
    return location?.seq === locationSetSeq;
  });
  const isScheduleChanged = checkScheduleChanged({
    originData: targetLocationSet,
    inputData,
    dataKey: `${locationSetSeq}_location`,
    locationInfo,
  });
  return isScheduleChanged;
};
