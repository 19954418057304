import styled from 'styled-components';

const StyledLiveShareMap = styled.div`
  height: 100%;
  width: 100%;
  flex: 1;
  background-color: #fff;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.25);
`;

export default StyledLiveShareMap;
