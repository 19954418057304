import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import CommonButton from '../../../../../../CommonButton/CommonButton';
import useGetPDF from '../../../../../../../hooks/BCDetail/DetailInfo/Common/useGetPDF';

const PDFModalButton = ({ ...props }) => {
  const { control, setValue } = useFormContext();
  const from = useWatch({ control, name: 'pu_from' });
  const to = useWatch({ control, name: 'dilv_to' });

  const woNo = document.location.pathname.split('/')?.pop();
  const pageCategory = document.location.pathname.split('/')?.[3];

  const { refetch: pdfRefetch } = useGetPDF({
    woNo,
    pageCategory,
    from,
    to,
    ...props,
  });

  return (
    <div className="w-full flex justify-center items-center gap-[20px]">
      <CommonButton
        category="clear"
        onClick={() => {
          setValue('pu_from', null);
          setValue('dilv_to', null);
        }}
      >
        Reset
      </CommonButton>
      <CommonButton
        category="save"
        onClick={async () => {
          const result = await pdfRefetch();
          if (result?.isSuccess) {
            props.setOpen(prev => false);
          }
        }}
      >
        Save
      </CommonButton>
    </div>
  );
};

export default PDFModalButton;
