const viewDualScheduleButton = ({ ...props }) => {
  const { ordering, data, driver, jobData } = props;

  const woType =
    document.URL?.split('/')?.[document?.URL?.split('/')?.length - 2];

  return (
    (woType === 'import' || woType === 'export') &&
    ordering === 0 &&
    (data?.type === 'P/O' || data?.type === 'RTN') &&
    (data?.type === 'RTN' ? driver : true) &&
    data?.sche_date &&
    data?.sche_time &&
    (data.type === 'P/O' ? data.type === 'P/O' && jobData?.dual_job : true)
  );
};

export default viewDualScheduleButton;
