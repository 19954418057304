const getReturnYardRowStyle = params => {
  if (params.data.color === 'Blue') {
    return { background: '#CDE5FF' };
  }
  if (params.data.color === 'Red') {
    return { background: '#FFE9E9' };
  }
  if (params.data.color === 'Yellow') {
    return { background: '#FFD70090' };
  }
  if (params.data.color === 'Green') {
    return { background: '#A2E47F' };
  }
  return {};
};

export default getReturnYardRowStyle;
