const adminConfKeys = {
  all: ['ADMIN', 'CONF'],
  confDIV: () => [...adminConfKeys?.all, 'DIVISION'],
  confDIVList: ({ active }) => [...adminConfKeys?.confDIV(), { active }],
  confSSL: () => [...adminConfKeys?.all, 'SSL'],
  confSSLList: ({ active }) => [...adminConfKeys?.confSSL(), { active }],
  confPool: () => [...adminConfKeys?.all, 'POOL'],
  confPoolList: ({ active }) => [...adminConfKeys?.confPool(), { active }],
  confSize: () => [...adminConfKeys?.all, 'SIZE'],
  confSizeList: ({ active }) => [...adminConfKeys?.confSize(), { active }],
  confType: () => [...adminConfKeys?.all, 'TYPE'],
  confTypeList: ({ active }) => [...adminConfKeys?.confType(), { active }],
};

export default adminConfKeys;
