import createCustomerReportColDefs from './createCustomerReportColDefs';

const generateReportMainColumn = () => {
  return [...createCustomerReportColDefs];
};

const generateReportSettingColumn = () => {
  return [
    { headerName: 'NAME', field: 'name', width: 180, editable: false },
    { headerName: 'URL', field: 'url', width: 400, editable: false },
  ];
};

const generateReportGridColumnDefinition = ({ subCategory }) => {
  if (subCategory === 'report-main') return generateReportMainColumn();
  if (subCategory === 'report-setting') return generateReportSettingColumn();
  return [];
};

export default generateReportGridColumnDefinition;
