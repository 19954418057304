import React, { useEffect, useState } from 'react';
import StyledBCDetailBillingInfoCommonHeaderButtonContainer from '../../../../../styles/BCDetail/BillingInfo/Common/Header/StyledBCDetailBillingInfoCommonHeaderButtonContainer';
import StyledBCDetailBillingInfoCommonHeaderContainer from '../../../../../styles/BCDetail/BillingInfo/Common/Header/StyledBCDetailBillingInfoCommonHeaderContainer';
import StyledBCDetailBillingInfoCommonHeaderTitleText from '../../../../../styles/BCDetail/BillingInfo/Common/Header/StyledBCDetailBillingInfoCommonHeaderTitleText';
import onCreateInvHandler from '../../../../../utils/BCDetail/BillingInfo/AP/Handler/onCreateInvHandler';
import handlePostQB from '../../../../../utils/BCDetail/BillingInfo/Common/handlePostQB';
import CommonButton from '../../../../CommonButton/CommonButton';

const APHeader = ({
  APQuery,
  apInvoiceMutator,
  qbMutator,
  APTableFormController,
  setIsAlertOpen,
  setIsSuccess,
  setErrorMessage,
  isCompanyDriver,
  isQBDisabled,
  isCreateBillNoDisabled,
  isAPEditable,
  isQBAvailable,
}) => {
  const [isDisableBillNo, setIsDisableBillNo] = useState(false);
  const [isDisableQB, setIsDisableQB] = useState(false);

  useEffect(() => {
    setIsDisableBillNo(isCreateBillNoDisabled);
  }, [isCreateBillNoDisabled]);

  useEffect(() => {
    setIsDisableQB(isCompanyDriver || isQBDisabled);
  }, [isCompanyDriver, isQBDisabled]);

  return (
    <StyledBCDetailBillingInfoCommonHeaderContainer>
      <StyledBCDetailBillingInfoCommonHeaderTitleText color="#264B9F">
        A/P
      </StyledBCDetailBillingInfoCommonHeaderTitleText>
      <StyledBCDetailBillingInfoCommonHeaderButtonContainer>
        {isAPEditable && (
          <CommonButton
            type="button"
            borderColor="#556DA1"
            category="createBillNo"
            preventDupClick
            onClick={({ buttonRef }) =>
              onCreateInvHandler({
                originData: APQuery?.APData,
                mutator: apInvoiceMutator,
                getValues: APTableFormController?.getValues,
                setIsAlertOpen,
                setIsSuccess,
                setErrorMessage,
                buttonRef,
              })
            }
            disabled={isDisableBillNo}
          >
            Create Bill#
          </CommonButton>
        )}
        {isQBAvailable && (
          <CommonButton
            type="button"
            borderColor="#3A825D"
            category="quickBooks"
            preventDupClick
            onClick={({ buttonRef }) =>
              handlePostQB({
                originData: APQuery?.APData,
                type: 'AP',
                mutator: qbMutator,
                getValues: APTableFormController?.getValues,
                setIsAlertOpen,
                setIsSuccess,
                setErrorMessage,
                buttonRef,
              })
            }
            disabled={isDisableQB}
          >
            QuickBooks
          </CommonButton>
        )}
      </StyledBCDetailBillingInfoCommonHeaderButtonContainer>
    </StyledBCDetailBillingInfoCommonHeaderContainer>
  );
};

export default APHeader;
