import React from 'react';
import AuthRouter from '../../../../../components/Common/AuthRouter';
import EDIInterface from '../../../../../components/EDI/EDIInterface';
import { PERM_EDI_PAGE } from '../../../permission';

const EDI_CHILDREN_ROUTE = [
  {
    index: true,
    element: (
      <AuthRouter permission={PERM_EDI_PAGE}>
        <EDIInterface />
      </AuthRouter>
    ),
  },
];

export default EDI_CHILDREN_ROUTE;
