import React from 'react';
import CommonButton from '../../../../../../CommonButton/CommonButton';
import {
  PERM_BC_DETAIL_VAN_EDIT,
  PERM_BC_DETAIL_BROK_EDIT,
  PERM_BC_DETAIL_IMP_EDIT,
  PERM_BC_DETAIL_EXP_EDIT,
} from '../../../../../../../constants/Common/permission';
import checkPermission from '../../../../../../../utils/Common/Router/Handler/checkPermission';
import useGetPDF from '../../../../../../../hooks/BCDetail/DetailInfo/Common/useGetPDF';

const PDFButton = ({ woNo, pageCategory, setPDFOpen, ...props }) => {
  const setPagePerm = () => {
    if (pageCategory === 'import') return PERM_BC_DETAIL_IMP_EDIT;
    if (pageCategory === 'export') return PERM_BC_DETAIL_EXP_EDIT;
    if (pageCategory === 'brokerage') return PERM_BC_DETAIL_BROK_EDIT;
    if (pageCategory === 'van') return PERM_BC_DETAIL_VAN_EDIT;
    return null;
  };
  const permission = setPagePerm();

  return (
    pageCategory !== 'bare-chassis' &&
    checkPermission({ permission }) && (
      <CommonButton
        category="pdf"
        type="button"
        disabled={!checkPermission({ permission })}
        onClick={() => {
          setPDFOpen(prev => true);
        }}
      >
        {(pageCategory === 'van' || pageCategory === 'brokerage') && 'B/L'}
        {pageCategory === 'import' && 'POD'}
        {pageCategory === 'export' && 'POL'}
      </CommonButton>
    )
  );
};

export default PDFButton;
