export const PRESET_DOC = [
  'DO',
  'POD',
  'POL',
  'BL',
  'IT',
  'ITE',
  'CF',
  'CFS',
  'EM',
  'RTN',
  'SCR',
  'SCALE',
  'Perdiem',
  'Demurrage',
];
