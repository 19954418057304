import { useQuery } from '@tanstack/react-query';
import getGeofenceList from '../../../services/Trace/Geofence/Get/getGeofenceList';
import traceKeys from '../keys';

const useGetGeofenceList = ({ company }) => {
  const { isLoading, data: geofenceListFiltered } = useQuery({
    queryKey: traceKeys.geofenceList({ company }),
    queryFn: () => getGeofenceList({ company }),
    staleTime: 0,
  });

  return { isLoading, geofenceListFiltered };
};

export default useGetGeofenceList;
