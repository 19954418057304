import {
  MAIN_CATEGORY_BARE_CHASSIS,
  MAIN_CATEGORY_BROKERAGE,
  MAIN_CATEGORY_EXPORT,
  MAIN_CATEGORY_IMPORT,
  MAIN_CATEGORY_VAN,
} from './category';

const EXCEL_FILE_NAMES = {
  [MAIN_CATEGORY_IMPORT]: 'IMPORT',
  [MAIN_CATEGORY_EXPORT]: 'EXPORT',
  [MAIN_CATEGORY_VAN]: 'VAN',
  [MAIN_CATEGORY_BROKERAGE]: 'BROKERAGE',
  [MAIN_CATEGORY_BARE_CHASSIS]: 'BARE CHASSIS',
};

export default EXCEL_FILE_NAMES;
