import styled from 'styled-components';

const StyledDataGridWrapper = styled.div`
  width: 100%;
  height: 100%;

  .ag-cell-inline-editing,
  .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
    --ag-input-focus-border-color: #264b9f !important;
    border: 1px solid var(--ag-input-focus-border-color) !important;
    border-radius: 3px;
    box-shadow: 0px 0px 4px 0px rgba(38, 75, 159, 0.25);
  }
  .ag-cell-inline-editing .ag-checkbox-edit {
    justify-content: center;
  }
`;

export default StyledDataGridWrapper;
