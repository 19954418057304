import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';
import billingInfoDefaultAPI from '../../api';

const getRateCFM = async ({ seq }) => {
  try {
    if (typeof seq === 'number') {
      const response = await billingInfoDefaultAPI.get(`/rate-cfm/${seq}`);
      return response;
    }
    return null;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getRateCFM;
