import styled from 'styled-components';

const StyledLabsActiveBox = styled.div`
  min-height: 150px;
  min-width: 300px;
  background-color: #fff;
  border: 3px solid black;
  margin: 20px;
  display: flex;
  flex-direction: column;
`;

export default StyledLabsActiveBox;
