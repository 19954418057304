import React, { useRef, useState } from 'react';
import CommonButton from '../../../../../CommonButton/CommonButton';
import usePostConvertToExport from '../../../../../../hooks/BCDetail/DetailInfo/Common/usePostConvertToExport';
import usePostRollbackToImport from '../../../../../../hooks/BCDetail/DetailInfo/Common/usePostRollbackToImport';
import usePatchSwitchComplete from '../../../../../../hooks/BCDetail/DetailInfo/Common/usePatchSwitchComplete';
import handleWOConvert from '../../../../../../utils/BCDetail/DetailInfo/Common/Handler/handleWOConvert';
import generateWOConvert from '../../../../../../utils/BCDetail/DetailInfo/Common/Generator/generateWOConvert';
import handleDetailInfoUpdate from '../../../../../../utils/BCDetail/DetailInfo/Common/Handler/handleDetailInfoUpdate';
import generateSavePermission from '../../../../../../utils/BCDetail/DetailInfo/Common/Generator/generateSavePermission';
import {
  PERM_WO_CONVERT_VIEW_EDIT,
  PERM_WO_COMPLETE_VIEW_EDIT,
  PERM_WO_DELETE_VIEW_EDIT,
} from '../../../../../../constants/Common/permission';
import checkPermission from '../../../../../../utils/Common/Router/Handler/checkPermission';
import PDFButton from './PDFButton';
import RollbackConfirmModal from '../../ModalContents/RollbackConfirmModal';
import PDFModal from '../../ModalContents/PDFModal';
import CompleteConfirmModalContent from '../../ModalContents/CompleteConfirmModal';

const WOManageButtonComponent = ({
  woNo,
  detailInfoData,
  basicInfoData,
  scheduleData,
  setDeleteOpen,
  infoRefetch,
  updateDetailInfo,
  ...props
}) => {
  const pageCategory = location?.pathname?.split('/')[3];

  const isCompleted = detailInfoData?.completed;
  const { isEnabled, converter } = generateWOConvert({
    woNo,
    realtedWo: detailInfoData?.related_wo,
  });

  const saveAuthenticated = generateSavePermission({ woNo });

  const convertToExport = usePostConvertToExport({ ...props });
  const rollbackToImport = usePostRollbackToImport({ ...props });
  const completeSwitch = usePatchSwitchComplete({ infoRefetch, ...props });

  /** Rollback confirm modal relate state */
  const [isRollbackOpen, setRollbackOpen] = useState(false);

  /** POD / POL / B/L modal button */
  const [isPDFOpen, setPDFOpen] = useState(false);

  /** Complete check modal */
  const [isCompleteCheckOpen, setCompleteCheckOpen] = useState(false);

  const convertButtonRef = useRef(null);

  const convertProps = {
    woNo,
    converter,
    convertToExport,
    rollbackToImport,
    buttonRef: convertButtonRef,
  };

  const updateProps = {
    woNo,
    basicInfoData,
    scheduleData,
    detailInfoData,
    updateDetailInfo,
    ...props,
  };

  return (
    <>
      <div className="manage-container">
        <div className="manage-content">
          <PDFButton
            woNo={woNo}
            pageCategory={pageCategory}
            setPDFOpen={setPDFOpen}
            {...props}
          />
        </div>
        <div className="manage-content">
          {isEnabled &&
            checkPermission({ permission: [PERM_WO_CONVERT_VIEW_EDIT] }) && (
              <CommonButton
                ref={convertButtonRef}
                category={converter.toLowerCase()}
                disabled={
                  !checkPermission({
                    permission: [PERM_WO_CONVERT_VIEW_EDIT],
                  }) || !!detailInfoData?.completed
                }
                preventDupClick
                onClick={() => {
                  if (converter !== 'Import')
                    handleWOConvert({ ...convertProps });
                  else if (converter === 'Import')
                    setRollbackOpen(prev => true);
                }}
              >
                {converter}
              </CommonButton>
            )}
          {checkPermission({ permission: [PERM_WO_COMPLETE_VIEW_EDIT] }) && (
            <CommonButton
              category={isCompleted ? 'incomplete' : 'complete'}
              disabled={
                !checkPermission({ permission: [PERM_WO_COMPLETE_VIEW_EDIT] })
              }
              onClick={() => setCompleteCheckOpen(prev => true)}
            >
              {isCompleted ? 'Incomplete' : 'Complete'}
            </CommonButton>
          )}
        </div>
        <div className="manage-content">
          {checkPermission({ permission: [saveAuthenticated] }) && (
            <CommonButton
              category="save"
              disabled={
                !checkPermission({ permission: [saveAuthenticated] }) ||
                !!detailInfoData?.completed
              }
              preventDupClick
              onClick={({ buttonRef }) =>
                handleDetailInfoUpdate({ buttonRef, ...updateProps })
              }
            >
              Save
            </CommonButton>
          )}
          {checkPermission({ permission: [PERM_WO_DELETE_VIEW_EDIT] }) && (
            <CommonButton
              category="delete"
              disabled={
                !checkPermission({ permission: [PERM_WO_DELETE_VIEW_EDIT] }) ||
                (pageCategory === 'export' && detailInfoData?.related_wo)
              }
              onClick={() => setDeleteOpen(true)}
            >
              Delete
            </CommonButton>
          )}
        </div>
      </div>
      {isCompleteCheckOpen && (
        <CompleteConfirmModalContent
          open={isCompleteCheckOpen}
          setOpen={setCompleteCheckOpen}
          isCompleted={isCompleted}
          mutator={completeSwitch.mutate}
          woNo={woNo}
        />
      )}
      {isRollbackOpen && (
        <RollbackConfirmModal
          open={isRollbackOpen}
          setOpen={setRollbackOpen}
          convertProps={convertProps}
        />
      )}
      {isPDFOpen && (
        <PDFModal
          open={isPDFOpen}
          setOpen={setPDFOpen}
          detailInfoData={detailInfoData}
          {...props}
        />
      )}
    </>
  );
};

export default WOManageButtonComponent;
