import React, { useState } from 'react';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import GoogleMapComponentGeofence from '../../../components/Trace/Module/GoogleMapComponentGeofence';
import TraceSidebarGeofence from '../../../components/TraceSidebarGeofence/index';
import useGetGeofenceList from '../../../hooks/Trace/Get/useGetGeofenceList';
import StyledTraceContainer from '../../../styles/Trace/Common/StyledTraceContainer';

const Geofence = () => {
  const [geofenceList, setGeofenceList] = useState([]);
  const [mapSettings, setMapSettings] = useState({
    size: {
      width: '1358px',
      height: '804px',
    },
    center: {
      lat: 39.817293816682295,
      lng: -101.35897508374268,
    },
    zoom: 5,
  });
  const [target, setTarget] = useState('');

  const { isLoading: loadingList, geofenceListFiltered } = useGetGeofenceList({
    company: target,
  });

  return (
    <>
      <CommonLoading open={loadingList} />
      <StyledTraceContainer>
        <TraceSidebarGeofence
          geofenceList={geofenceList}
          setGeofenceList={setGeofenceList}
          mapSettings={mapSettings}
          setMapSettings={setMapSettings}
          setTarget={setTarget}
          geofenceListFiltered={geofenceListFiltered}
          loadingList={loadingList}
        />
        <GoogleMapComponentGeofence
          mapSettings={mapSettings}
          geofenceList={geofenceList}
          company={target}
        />
      </StyledTraceContainer>
    </>
  );
};

export default Geofence;
