import styled from 'styled-components';

const StyledBottomInfo = styled.div`
  display: grid;

  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: 129px 60px 216px 211px;

  column-gap: 10px;
`;

export default StyledBottomInfo;
