import React from 'react';
import CommonButton from '../../../CommonButton/CommonButton';

const AdvancedSearchButtonContent = ({ ...props }) => {
  return (
    <div className="flex justify-center space-x-[18px]">
      <div>
        <CommonButton
          category="cancel"
          onClick={() => {
            props.setAdvancedSearchOpen(false);
          }}
        >
          Cancel
        </CommonButton>
      </div>
      <div>
        <CommonButton type="submit" category="search">
          Search
        </CommonButton>
      </div>
    </div>
  );
};

export default AdvancedSearchButtonContent;
