import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useGetCompanyList from '../../../../hooks/Acct/Common/Get/useGetCompanyList';
import useGetDriverList from '../../../../hooks/Acct/Common/Get/useGetDriverList';
import CommonMessageModal from '../../../CommonMessageModal/CommonMessageModal';
import AdvancedSearchModalButton from '../AdvancedSearchModalButton';
import AdvancedSearchModalContent from '../AdvancedSearchModalContent';

const AdvancedSearchModal = ({
  isOpenModal,
  setIsOpenModal,
  queryObject,
  setQueryObject,
  page,
  errorModalOptions,
  ...props
}) => {
  const {
    vendorOptionList: customerList,
    isLoadingCompanyList: isLoadingCustomerList,
  } = useGetCompanyList({ category: 1 });

  const {
    vendorOptionList: vendorList,
    isLoadingCompanyList: isLoadingVendorList,
  } = useGetCompanyList({ category: 2 });

  const { driverList, isLoadingDriverList } = useGetDriverList();

  const optionList = { customerList, vendorList, driverList };
  const searchController = useForm();
  return (
    <FormProvider {...searchController}>
      <form>
        <CommonMessageModal
          header="Advanced Search"
          open={isOpenModal}
          setOpen={setIsOpenModal}
          width="588px"
          height="404px"
          content={
            <AdvancedSearchModalContent
              queryObject={queryObject}
              page={page}
              optionList={optionList}
              isOpenModal={isOpenModal}
              isLoadingCustomerList={isLoadingCustomerList}
              isLoadingVendorList={isLoadingVendorList}
              isLoadingDriverList={isLoadingDriverList}
            />
          }
          buttonContent={
            <AdvancedSearchModalButton
              setIsOpenModal={setIsOpenModal}
              setQueryObject={setQueryObject}
              errorModalOptions={errorModalOptions}
            />
          }
        />
      </form>
    </FormProvider>
  );
};

export default AdvancedSearchModal;
