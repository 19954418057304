import React from 'react';
import StatTableHeader from './StatTableHeader';
import StatDivisionGroup from './StatDivisionGroup';
import StyledStatTableContainer from '../../../../styles/TmsReport/Statistics/StyledStatTableContainer';
import {
  STATISTICS_CYCLE_WIDTH_VALUE,
  STATISTICS_HEADER_CYCLE_VALUE,
} from '../../../../constants/TmsReport/Statistics/statisticsValues';

const StatTable = ({ tableData, ...props }) => {
  const { cycle } = props;
  const cycleUnits = STATISTICS_HEADER_CYCLE_VALUE[cycle];
  const cycleWidth = STATISTICS_CYCLE_WIDTH_VALUE[cycle];

  return (
    <StyledStatTableContainer cycleWidth={cycleWidth} {...props}>
      <StatTableHeader cycleUnits={cycleUnits} {...props} />
      {Object.entries(tableData)?.map(([division, rowData]) => (
        <StatDivisionGroup
          key={String(cycle + division)}
          division={division}
          rowData={rowData}
          cycleUnits={cycleUnits}
          cycleWidth={cycleWidth}
          {...props}
        />
      ))}
    </StyledStatTableContainer>
  );
};

export default StatTable;
