import { useQuery } from '@tanstack/react-query';
import getBillConfirmList from '../../../services/MobileAdmin/BillConfirm/Get/getBillConfirmList';
import mobileAdminKeys from '../keys';

const useGetBillConfirmList = ({ division }) => {
  const { isLoading, data, isError } = useQuery({
    queryKey: mobileAdminKeys.billConfirm({ division }),
    queryFn: () => getBillConfirmList({ division }),
    refetchOnWindowFocus: false,
  });

  return {
    isLoading,
    billConfirm: data?.data,
    isError,
  };
};

export default useGetBillConfirmList;
