import memoDefaultAPi from '../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

const getMobileMemoList = async woNo => {
  try {
    const response = await memoDefaultAPi.get(`related-job/${woNo}`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getMobileMemoList;
