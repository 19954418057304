import styled from 'styled-components';

const StyledBCDetailBillingInfoPaymentModalValue = styled.div`
  color: #666;
  font-size: 12px;
  font-weight: 400;
  margin-left: 20px;
`;

export default StyledBCDetailBillingInfoPaymentModalValue;
