import handleQueryError from '../../../utils/Common/Handler/handleQueryError';
import ediDefaultAPI from '../api';

const get204List = async ({ div, from, to, sender }) => {
  try {
    const response = await ediDefaultAPI.get('204', {
      params: {
        div,
        'created-from': from,
        'created-to': to,
        sender,
      },
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default get204List;
