import { useMutation } from '@tanstack/react-query';
import { SUCCESS_UPDATE } from '../../../constants/Common/successMessage';
import patchConfActivate from '../../../services/Admin/Conf/Patch/patchConfActivate';
import useControlToast from '../../Common/Toast/useControlToast';
import updateGridData from '../../../utils/Common/Handler/updateGridData';

const usePatchConfActivate = ({
  active,
  disabledRef,
  setMessageContent,
  setMessageModalOpen,
}) => {
  const { addToast } = useControlToast();

  const mutator = useMutation({
    mutationFn: patchConfActivate,
    onSuccess: async (data, variables) => {
      updateGridData({
        variables,
        newData: { ...data?.data },
        isUpdate: active === null,
      });
      addToast({ message: SUCCESS_UPDATE });
    },
    onError: (error, variables) => {
      variables?.setChecked(checked => !checked);
      setMessageContent(error?.message);
      setMessageModalOpen(true);
    },
    onSettled: () => {
      disabledRef.current.disabled = false;
      disabledRef.current.disabledRow = null;
    },
  });
  return mutator;
};

export default usePatchConfActivate;
