import {
  DISPATCH_TYPE_BROKERAGE,
  DISPATCH_TYPE_EXPORT,
  DISPATCH_TYPE_IMPORT,
  DISPATCH_TYPE_VAN,
} from '../../../../constants/Dispatch/Common/category';

const convertCategoryToStandardCode = category => {
  if (category === DISPATCH_TYPE_IMPORT) {
    return 'WC0001';
  }
  if (category === DISPATCH_TYPE_EXPORT) {
    return 'WC0003';
  }
  if (category === DISPATCH_TYPE_VAN) {
    return 'WC0005';
  }
  if (category === DISPATCH_TYPE_BROKERAGE) {
    return 'WC0006';
  }
  return '';
};

export default convertCategoryToStandardCode;
