import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SUCCESS_CONVERT } from '../../../constants/Common/successMessage';
import postConvertToExport from '../../../services/Dispatch/Common/post/postConvertToExport';
import useControlToast from '../../Common/Toast/useControlToast';
import dispatchKeys from '../../Dispatch2/keys';

const usePostConvertToExport = ({
  setMessageModalMessage,
  setIsMessageModalOpen,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();
  const mutator = useMutation({
    mutationFn: postConvertToExport,
    onSuccess: success => {
      queryClient?.invalidateQueries({ queryKey: dispatchKeys?.count() });
      queryClient?.invalidateQueries({ queryKey: dispatchKeys?.list() });
      addToast({ message: SUCCESS_CONVERT });
    },
    onError: error => {
      setMessageModalMessage(error.message);
      setIsMessageModalOpen(true);
    },
  });
  return mutator;
};

export default usePostConvertToExport;
