import React, { useState, forwardRef, useEffect } from 'react';
import CustomAccordion from '../CustomAccordion';
import { WARNING_ICON } from '../../../../constants/Order/Common/path';
import CommonErrorModal from '../../../CommonErrorModal/CommonErrorModal';
import usePatchEarlyWarning from '../../../../hooks/TmsReport/EarlyWarning/Patch/usePatchEarlyWarning';
import EarlyWarningToast from '../EarlyWarningToast';

const EarlyWarningModal = forwardRef(
  (
    {
      isModalOpen,
      earlyWarningData,
      refetchEarlyWarningList,
      earlyWarningEditPermission,
    },
    ref,
  ) => {
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(null);

    const [isShowToast, setIsShowToast] = useState(false);
    const [confirmStatus, setConfirmStatus] = useState(null);

    const { mutate: earlyWarningPatchMutator } = usePatchEarlyWarning({
      refetchEarlyWarningList,
      setErrorModalOpen,
      setErrorMessage,
      setIsSuccess,
      setIsShowToast,
      setConfirmStatus,
    });

    useEffect(() => {
      if (isShowToast) {
        setTimeout(() => {
          setIsShowToast(false);
          setConfirmStatus(null);
        }, 1700);
      }
    }, [isShowToast]);

    return (
      <>
        <div
          ref={ref}
          className={`${isModalOpen ? 'block' : 'hidden'} absolute`}
        >
          <div className="w-[352px] max-h-[405px] bg-white absolute top-[35px] left-[20px] rounded-[10px] pb-[14px]">
            <div className="flex flex-row items-center bg-[#001E5F] h-[37px] text-white  px-[35px] py-[9px] space-x-[9px] font-bold rounded-t-[10px] ">
              <div>
                <img
                  src={WARNING_ICON}
                  alt="warningIcon"
                  width={20}
                  height={18}
                />
              </div>
              <div>
                <p className="font-[600] text-[16px]">Early Warning</p>
              </div>
            </div>
            <div className="bg-white pt-[29px]">
              {earlyWarningData?.length > 0 ? (
                <>
                  <div className="max-h-[284px] overflow-y-scroll">
                    {earlyWarningData?.map(warningData => {
                      return (
                        <CustomAccordion
                          key={warningData?.seq}
                          warningData={warningData}
                          earlyWarningPatchMutator={earlyWarningPatchMutator}
                          setIsShowToast={setIsShowToast}
                          setConfirmStatus={setConfirmStatus}
                          earlyWarningEditPermission={
                            earlyWarningEditPermission
                          }
                        />
                      );
                    })}
                  </div>
                  <div
                    className={`h-[40px] px-[35px] pt-[9px] flex justify-center ${
                      isShowToast ? 'opacity-100' : 'opacity-0'
                    }`}
                  >
                    {isShowToast && (
                      <EarlyWarningToast category={confirmStatus} />
                    )}
                  </div>
                </>
              ) : (
                <div className="w-full h-hull flex  justify-center items-center pb-[20px] font-[500] text-[#424242]">
                  No Warning found.
                </div>
              )}
            </div>
          </div>
        </div>
        <CommonErrorModal
          open={errorModalOpen}
          setIsOpen={setErrorModalOpen}
          message={errorMessage}
          isSuccess={isSuccess}
        />
      </>
    );
  },
);

export default EarlyWarningModal;
