import React, { useState } from 'react';
import CommonMessageModal from '../../../../../CommonMessageModal/CommonMessageModal';
import DualMoveModalContent from './DualMoveModalContent';
import DualMoveModalButtonContent from './DualMoveModalButtonContent';

const DualMoveModal = ({ ...props }) => {
  const { open, setOpen } = props;

  return (
    open && (
      <CommonMessageModal
        open={open}
        setOpen={setOpen}
        header="Dual Move - Return before Pull Out"
        content={<DualMoveModalContent {...props} />}
        buttonContent={<DualMoveModalButtonContent setOpen={setOpen} />}
      />
    )
  );
};

export default DualMoveModal;
