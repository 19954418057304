import dayjs from 'dayjs';

/** Local TextColor Codes */
const TEXT_COLOR_RED = '#ff0000';
const TEXT_COLOR_PURPLE = '#b000FF';
const TEXT_COLOR_BLACK = '#000000';

/**
 * @description
 * Function that set the text color of the LFD and RFD
 * @param {string} dateKey
 * @param {function} watch
 * @param {function} setColor
 * @returns
 */
const setLFDRFDColor = (dateKey, watcher, setColor) => {
  /** Get data using watch function */
  const date = watcher;

  /** If input date exist */
  if (date) {
    /** Convert date string to object */
    const dateObj = dayjs(date);
    /** Get current date */
    const currentDate = dayjs();
    /** Calculate day difference */
    const dayDifference = dateObj.diff(currentDate, 'day', true);

    if (dateObj.isSame(currentDate, 'day')) {
      setColor(TEXT_COLOR_RED);
      return;
    }
    if (dateObj.isBefore(currentDate, 'day')) {
      setColor(TEXT_COLOR_RED);
      return;
    }
    if (dateObj.isAfter(currentDate, 'day') && dayDifference <= 1) {
      setColor(TEXT_COLOR_PURPLE);
      return;
    }
    setColor(TEXT_COLOR_BLACK);
  }
  setColor(TEXT_COLOR_BLACK);
};

export default setLFDRFDColor;
