import React from 'react';
import { QueryClient } from '@tanstack/react-query';
import DriverModalButtonContent from './DriverModalButtonContent';
import DriverModalContent from './DriverModalContent';
import CommonMessageModal from '../../CommonMessageModal/CommonMessageModal';
import dailyReportKeys from '../../../hooks/DailyReport/keys';

const DriverModal = ({ ...props }) => {
  /** Query client */
  const queryClient = new QueryClient();

  /** Modal opener setter */
  const { isDriverAPModalOpen, setAPModalOpen, setDriverAPModalOpen } = props;

  /** Data setter */
  const {
    setWONo,
    setSelectedScheduleSeq,
    setSelectedDriverData,
    getAPRefetcher,
  } = props;

  /** API Status */
  const { isFetchingGetAP, isErrorGetAP } = props;

  /** Data */
  const { driverList, selectedDriverData, isPendingDriverList } = props;

  /** Render */
  return (
    driverList && (
      <CommonMessageModal
        open={isDriverAPModalOpen}
        setOpen={setDriverAPModalOpen}
        onCloseClick={() => {
          queryClient?.invalidateQueries({
            queryKey: dailyReportKeys?.driver(),
          });
          setWONo('');
          setSelectedScheduleSeq(null);
          setSelectedDriverData(null);
        }}
        width="600px"
        height="533px"
        header="Driver List"
        content={
          <DriverModalContent
            driverList={driverList}
            isPending={isPendingDriverList}
            setSelectedDriverData={setSelectedDriverData}
            setSelectedScheduleSeq={setSelectedScheduleSeq}
            setOpen={setDriverAPModalOpen}
            setAPModalOpen={setAPModalOpen}
            getAPDataRefetch={getAPRefetcher}
          />
        }
        buttonContent={
          <DriverModalButtonContent
            setWONo={setWONo}
            setOpen={setDriverAPModalOpen}
            setAPModalOpen={setAPModalOpen}
            getAPDataRefetch={getAPRefetcher}
            selectedDriverData={selectedDriverData}
            setSelectedScheduleSeq={setSelectedScheduleSeq}
            setSelectedDriverData={setSelectedDriverData}
            isFetchingGetAP={isFetchingGetAP}
            isErrorGetAP={isErrorGetAP}
          />
        }
      />
    )
  );
};

export default DriverModal;
