import FloatingFilterSelect from '../../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterSelect';
import { COMMON_CHECK_BOX_FLOATING_FILTER_OPTION } from '../../../../constants/Common/DataGrid/floatingFilterOpions';
import getCellStyle from './getCellStyle';

const getEditableCheckBoxDef = ({ divColorSet }) => {
  return {
    floatingFilterComponent: FloatingFilterSelect,
    floatingFilterComponentParams: {
      option: COMMON_CHECK_BOX_FLOATING_FILTER_OPTION,
    },
    cellRenderer: 'agCheckboxCellRenderer',
    cellRendererParams: {
      disabled: true,
    },
    cellEditor: 'agCheckboxCellEditor',
    valueFormatter: params => {
      return params.value ? 'Yes' : 'No';
    },
    sortable: false,
    cellStyle: params => ({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...getCellStyle({ ...params, divColorSet }),
    }),
  };
};

export default getEditableCheckBoxDef;
