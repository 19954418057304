import React from 'react';
import CommonButton from '../../../../../../../../../CommonButton/CommonButton';
import { PATH_BUTTON_EXPAND_ICON } from '../../../../../../../../../../constants/Common/path';

const DualMoveModalContentMemoHeader = ({ ...props }) => {
  const { isMemoOpen, setIsMemoOpen } = props;

  return (
    <div className="flex items-center h-[30px] text-[#222] text-[#222] font-normal text-[11px] gap-[2px]">
      <div className="flex justify-end items-center h-full w-[80px] pr-[10px]">
        <CommonButton
          icon={
            <img
              src={PATH_BUTTON_EXPAND_ICON}
              alt="Memo expand icon"
              height={6}
            />
          }
          customStyle={{
            border: '1px solid #D9D9D9',
            borderRadius: '5px',
            width: '20px',
            height: '20px',
            padding: '5px',
            transform: isMemoOpen ? 'rotate(90deg)' : 'rotate(0)',
            transitionDuration: '0.3s',
          }}
          onClick={() => setIsMemoOpen(prev => !prev)}
        />
      </div>
      <div className="flex justify-center text-[#222] text-[11px] font-normal">
        MEMO(MOBILE)
      </div>
    </div>
  );
};

export default DualMoveModalContentMemoHeader;
