const convertScheduleType = ({ data }) => {
  const pageCategory =
    document?.URL?.split('/')?.[document?.URL?.split('/')?.length - 2];

  if (pageCategory === 'van') {
    if (data?.ordering === 0 && data?.type === 'P/O') return 'P/U';
  }

  return data?.type;
};

export default convertScheduleType;
