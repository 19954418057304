import React, { useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { TextField } from '@mui/material';
import defaultInputRules from '../../../../../../utils/Common/Input/defaultInputRules';

const TableTextStyled = styled.input`
  border: 0.5px solid #eeeeee;
  height: 30px;
  text-align: right;

  .input {
    margin: 12px;
  }

  &:hover {
    z-index: 999;
  }
`;

const TableText = ({ row, basicCellStyle, ...props }) => {
  const {
    control,
    unregister,
    setValue,
    formState: { errors: formErrors },
  } = useFormContext();

  const [maxLength, setMaxLength] = useState(row?.maxLength || 20);

  const [defaultValue, setDefaultValue] = useState(row?.defaultValue || '');
  useEffect(() => {
    if (row?.defaultValue !== undefined && row?.defaultValue !== null) {
      setDefaultValue(row?.defaultValue);
      setValue(row?.inputName, row?.defaultValue);
    }
  }, [row?.defaultValue]);

  const [disabled, setDisabled] = useState(row?.disabled || false);
  useEffect(() => {
    if (row?.disabled !== undefined && row?.disabled !== null) {
      setDisabled(row?.disabled);
    }
  }, [row?.disabled]);

  const [required, setRequired] = useState(row?.required || false);
  useEffect(() => {
    if (row?.required !== undefined && row?.required !== null) {
      setRequired(row?.required);
    }
  }, [row?.required]);

  useEffect(() => {
    return () => unregister(row?.inputName);
  }, []);

  const {
    field: { ref, onChange, value, ...field },
    fieldState: { invalid, error },
  } = useController({
    name: row?.InputName,
    control,
    defaultValue,
    rules: defaultInputRules({ ...props }),
  });

  const handlerProps = {
    onChange,
    value,
    maxLength,
    ...field,
    ...props,
  };

  return (
    <TableTextStyled
      {...field}
      type="text"
      onChange={e =>
        props?.onChangeHandler?.({
          e,
          ...handlerProps,
        })
      }
      value={value === null || value === undefined ? '' : value}
      ref={ref}
      required={required}
      disabled={disabled}
      className={`flex ${basicCellStyle} ${row?.width || 'w-full'}
      text-[12px] text-right outline-0 box-border basis-full
      hover:border-[1px] hover:border-[#8E9396]
      focus:z-[499] focus:border-[#264B9F] focus:border-[1.2px] focus:shadow-[0_0_4px_0_#8BBCE9]
      ${row?.disabled ? 'bg-[#F9F9F9]' : 'bg-white'}`}
    />
  );
};

export default TableText;
