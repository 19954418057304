import {
  SUB_ORDER_TYPE_BROKERAGE,
  SUB_ORDER_TYPE_EXPREG,
  SUB_ORDER_TYPE_EXPSHUTTLE,
  SUB_ORDER_TYPE_IMPREG,
  SUB_ORDER_TYPE_IMPSHUTTLE,
  SUB_ORDER_TYPE_VAN,
} from '../../../constants/Order/Common/basic';

/**
 * @description
 * Function to generate post sub category according to sub category
 * @param {string} subCategory
 * Sub category of order
 * @returns {string}
 * Page sub category
 */
const createOCRSubCategory = ({ subCategory }) => {
  if (subCategory === SUB_ORDER_TYPE_IMPREG) return 'IMP';
  if (subCategory === SUB_ORDER_TYPE_IMPSHUTTLE) return 'IMP';
  if (subCategory === SUB_ORDER_TYPE_EXPREG) return 'EXP';
  if (subCategory === SUB_ORDER_TYPE_EXPSHUTTLE) return 'EXP';
  if (subCategory === SUB_ORDER_TYPE_VAN) return 'VAN';
  if (subCategory === SUB_ORDER_TYPE_BROKERAGE) return 'BROK';
  return 'BARE';
};

export default createOCRSubCategory;
