import onAPGroupingHandler from '../../BillingInfo/AP/Handler/onAPGroupingHandler';
import onARGroupingHandler from '../../BillingInfo/AR/Handler/onARGroupingHandler';

const checkedItems = new Set();

const setCheckDataKey = ({ billingType, apType }) => {
  let checkDataKey = '';
  if (billingType === 'AP') {
    if (apType === 'DRIVER') {
      checkDataKey = 'driver';
    } else if (apType === 'VENDOR') {
      checkDataKey = 'ven';
    }
  } else if (billingType === 'AR') {
    checkDataKey = 'company';
  }

  return checkDataKey;
};

const handleChecked = ({
  data,
  selectedRow,
  setValue,
  checkDataKey,
  billingType,
  apType,
  setCheckedRow,
  setIsQBDisabled,
  setIsCreateBillNoDisabled,
}) => {
  if (billingType === 'AR') {
    onARGroupingHandler({
      data,
      selectedRow,
      setValue,
      checkDataKey,
      setCheckedRow,
    });
  } else if (billingType === 'AP') {
    onAPGroupingHandler({
      data,
      selectedRow,
      setValue,
      checkDataKey,
      apType,
      setIsQBDisabled,
      setIsCreateBillNoDisabled,
      checkedItems,
    });
  }
};

const onRowCheckboxGroupingHandler = ({
  event,
  data,
  selectedRow,
  apType,
  billingType,
  setValue,
  setCheckedRow,
  setIsQBDisabled,
  setIsCreateBillNoDisabled,
  setIsCompanyDriver,
}) => {
  const checkDataKey = setCheckDataKey({ billingType, apType });

  if (event?.target?.checked) {
    handleChecked({
      data,
      selectedRow,
      setValue,
      checkDataKey,
      billingType,
      apType,
      setCheckedRow,
      setIsQBDisabled,
      setIsCreateBillNoDisabled,
      setIsCompanyDriver,
    });
  } else {
    if (billingType === 'AP') {
      setIsCompanyDriver(false);
      setIsCreateBillNoDisabled(false);
      checkedItems.delete(selectedRow?.seq);
      const hasItemWithoutBillNo = Array.from(checkedItems).some(
        seq => !data.find(item => item.seq === seq)?.bill_no,
      );
      setIsQBDisabled(hasItemWithoutBillNo);
    }
    checkedItems.delete(selectedRow?.seq);

    setCheckedRow?.(prev => {
      return prev.filter(item => item !== selectedRow?.seq);
    });
  }
};

export default onRowCheckboxGroupingHandler;
