import deleteQueryParameter from './deleteQueryParameter';

const setAdvancedSearchQueryParameter = ({
  searchParams,
  setSearchParams,
  advancedSearchData,
  setOpen,
}) => {
  /** Delete all of query parameter */
  deleteQueryParameter({ searchParams, setSearchParams });

  const copiedAdvancedSearchData = { ...advancedSearchData };

  Object.keys(copiedAdvancedSearchData).forEach(key => {
    if (copiedAdvancedSearchData[key] && copiedAdvancedSearchData[key] !== '')
      searchParams?.set(key, copiedAdvancedSearchData?.[key]);
    else searchParams?.delete(key);
  });

  /** Set search params */
  setSearchParams(prev => searchParams);

  setOpen(false);
};

export default setAdvancedSearchQueryParameter;
