import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import LabsComponentBox from '../../../styles/Labs/Input/LabsComponentBox';
import getRenderComponent from '../../../utils/Labs/Input/getRenderComponent';

const LabsInputBoxWrapper = ({ ...props }) => {
  const BoxWrapperController = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const onSubmitHandler = ({ e }) => {
    e.preventDefault();
    BoxWrapperController.handleSubmit(data => console.log(data))();
  };

  return (
    <LabsComponentBox>
      <div className="p-[10px] bg-[#EEE] border-b-2 border-black flex justify-center items-center font-bold">
        {props?.inputName}
      </div>
      <FormProvider {...BoxWrapperController}>
        <form onSubmit={e => onSubmitHandler({ e })}>
          <div className="px-[8px] py-[16px]">
            {getRenderComponent({ inputType: props?.inputType, ...props })}
          </div>
          <div className="p-[10px] bg-[#EEE] border-t-2 border-black flex justify-center items-center font-bold">
            <Button
              variant="contained"
              endIcon={<SendIcon />}
              size="small"
              // onClick={e => onSubmitHandler({ e })}
              type="submit"
            >
              Submit
            </Button>
          </div>
        </form>
      </FormProvider>
    </LabsComponentBox>
  );
};

export default LabsInputBoxWrapper;
