import styled from 'styled-components';

const StyledMainFilterButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
  gap: 4px;
  background-color: white;
  box-shadow: ${props => (props?.active ? '0px 0px 5px 0 #00000029' : 'none')}};
  color: ${props => (props?.active ? props.subColor : '#B2BDC9')};
  background-color: ${props => (props?.active ? props.mainColor : 'white')};
  outline-width: 0px;

  &:hover {
    background-color: ${props => props.mainColor};
  }
  
  &:focus {
    background-color: ${props => props.mainColor};  
    color: ${props => props.subColor};
  } 
`;

export default StyledMainFilterButton;
