import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import tmsReportAPI from '../../api';

const patchDemurrageList = async ({ wo_no: woNo, body }) => {
  try {
    const response = await tmsReportAPI.patch(`/penalty/${woNo}`, {
      type: 'Demurrage',
      ...body,
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchDemurrageList;
