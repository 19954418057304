import updateButtonDisabled from '../../../Common/Handler/updateButtonDisabled';

const handlePostQB = ({
  originData,
  type,
  mutator,
  getValues,
  setIsAlertOpen,
  setIsSuccess,
  setErrorMessage,
  buttonRef,
}) => {
  const woNo =
    document.URL?.split('/')?.[document?.URL?.split('/')?.length - 1];
  let submitData = {};

  const isSubmitted = originData.some(row => {
    if (getValues(`mod.checked_${row?.seq}`)) {
      if (type === 'AP') {
        const driverId = row.driver;
        const groupId = row.group_id;
        submitData = {
          wo_no: woNo,
          driver: driverId,
          group_id: Number(groupId),
        };
      } else if (type === 'AR') {
        const invNo = row.inv_no;
        submitData = {
          inv_no: invNo,
        };
      }

      mutator({
        type,
        submitData,
        buttonRef,
      });
      return true;
    }
    return false;
  });

  if (!isSubmitted) {
    setIsAlertOpen(true);
    setIsSuccess(false);
    setErrorMessage('Please select data to create QuickBooks.');
    updateButtonDisabled({ ref: buttonRef, disabled: false });
  }
};

export default handlePostQB;
