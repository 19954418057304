import React, { useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import defaultInputRules from '../../../utils/Common/Input/defaultInputRules';

const CommonFilePreviewInput = ({ ...props }) => {
  const { control, unregister, setValue, getValues } = useFormContext();

  const [inputName, setInputName] = useState(
    props?.inputName ? props?.inputName : 'file_input',
  );

  const [defaultValue, setDefaultValue] = useState(props?.defaultValue || null);
  const [url, setUrl] = useState(defaultValue);

  useEffect(() => {
    if (props?.defaultValue !== undefined && props?.defaultValue !== null) {
      setDefaultValue(props?.defaultValue);
      setValue(inputName, props?.defaultValue);
      setUrl(props?.defaultValue);
    }
  }, [props?.defaultValue]);

  const [disabled, setDisabled] = useState(props?.disabled || false);
  useEffect(() => {
    /**
     * If default disabled value changed, component disabled value would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.disabled !== undefined && props?.disabled !== null) {
      setDisabled(props?.disabled);
    }
  }, [props?.disabled]);

  const [width, setWidth] = useState(props?.width || ['w-[160px]', 'w-[60px]']);
  const [height, setHeight] = useState(
    props?.height || ['h-[166px]', 'h-[80px]'],
  );

  useEffect(() => {
    unregister(props?.inputName);
  }, []);

  const onFileChanged = ({ e, ...params }) => {
    const fileElement = document.getElementById(inputName)?.files[0];
    params?.onChange?.(fileElement);
    const fileReader = new FileReader();

    fileReader.onloadend = () => {
      setUrl(fileReader.result);
    };

    fileReader.readAsDataURL(fileElement);
  };

  useEffect(() => {
    if (getValues(inputName) === defaultValue) {
      setUrl(defaultValue);
    }
  }, [getValues(inputName)]);

  return (
    <Controller
      control={control}
      name={inputName}
      defaultValue={defaultValue}
      rules={defaultInputRules({ ...props })}
      render={({ field: { ref, onChange, value, ...field } }) => {
        const handlerProps = {
          onChange,
          value,
          ...field,
          ...props,
        };
        return (
          <div
            className={`${width[0]} ${
              height[0]
            } bg-[#EFF1F6] flex justify-center items-center ${
              !disabled && 'cursor-pointer'
            } overflow-hidden`}
            onClick={() => document.getElementById(inputName).click()}
          >
            <input
              {...field}
              accept={('image/jpg', 'image/jpeg', 'image/png')}
              id={inputName}
              type="file"
              className="hidden"
              onChange={e => onFileChanged({ ...handlerProps, e })}
              disabled={disabled}
            />
            {!url ? (
              <div
                className={`${width[1]} ${height[1]} border-dashed border-2 flex flex-col justify-center items-center bg-white`}
              >
                <img
                  src="/assets/images/common_icon/newFileUpload.svg"
                  alt="file upload"
                />
                <div className="text-center text-[#556DA1] font-medium text-[12px] leading-[16px] mt-[8px]">
                  Photo
                </div>
              </div>
            ) : (
              <div className="w-full h-full flex justify-center items-center relative group">
                <img src={url} alt="file upload" className="w-3/4" />
                <div
                  className={`absolute w-full h-[166px] transition duration-400 opacity-0 bg-black bg-opacity-20  ${
                    !disabled && 'group-hover:opacity-40'
                  } `}
                />
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

export default CommonFilePreviewInput;
