import bcDetailDefaultApi from '../../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

const getBasicInfo = async woNo => {
  const nowPage = window.location.pathname?.split('/')[2];

  try {
    const response = await bcDetailDefaultApi.get(`${woNo}`, {
      params: {
        page: nowPage === 'billing-info' ? 'billing' : 'bcdetail',
      },
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getBasicInfo;
