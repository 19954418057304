import {
  PERM_CUS_REPORT_MAIN_VIEW_EDIT,
  PERM_CUS_REPORT_SETTING_VIEW_EDIT,
} from '../../../constants/Common/permission';

/**
 * @description
 * Function that generate page tab according to permission
 * @returns {list}
 * List of customer-report page tab
 */
const pageTabWithPermissionGenerator = () => {
  const customerReportTabList = [];

  /** Get permission */
  const permissionList = localStorage.getItem('permission')?.split(',');

  /** Add page tab according to permission */
  if (permissionList?.includes(PERM_CUS_REPORT_MAIN_VIEW_EDIT)) {
    customerReportTabList.push({
      label: 'REPORT MAIN',
      to: '/customer-report/report-main',
      pathValue: 0,
    });
  }
  if (permissionList?.includes(PERM_CUS_REPORT_SETTING_VIEW_EDIT)) {
    customerReportTabList.push({
      label: 'REPORT SETTING',
      to: '/customer-report/report-setting',
      pathValue: 1,
    });
  }

  return customerReportTabList;
};

export default pageTabWithPermissionGenerator;
