import FormControlLabel from '@mui/material/FormControlLabel';
import React from 'react';
import StyledTraceCommonSidebarContainer from '../../styles/Trace/Common/StyledTraceCommonSidebarContainer';
import StyledTraceGeofenceAccordion from '../../styles/Trace/Geofence/StyledTraceGeofenceAccordion';
import StyledTraceGeofenceAccordionDetails from '../../styles/Trace/Geofence/StyledTraceGeofenceAccordionDetails';
import StyledTraceGeofenceAccordionSummary from '../../styles/Trace/Geofence/StyledTraceGeofenceAccordionSummary';
import StyledTraceGeofenceAccordionSummaryWrapper from '../../styles/Trace/Geofence/StyledTraceGeofenceAccordionSummaryWrapper';
import GeofenceCheckbox from '../Trace/Module/GeofenceModule/GeofenceCheckbox';
import SearchBar from '../Trace/Module/SearchBar';

const TraceSidebarGeofence = ({
  geofenceList,
  setGeofenceList,
  mapSettings,
  setMapSettings,
  setTarget,
  geofenceListFiltered,
  loadingList,
}) => {
  if (loadingList) {
    return (
      <StyledTraceCommonSidebarContainer>
        <SearchBar setTarget={setTarget} />
      </StyledTraceCommonSidebarContainer>
    );
  }

  return (
    <StyledTraceCommonSidebarContainer data-testid="geofence-sidebar">
      <SearchBar setTarget={setTarget} />
      {Object.keys(geofenceListFiltered).map(key => {
        const geofence = geofenceListFiltered[key];
        return (
          <StyledTraceGeofenceAccordion className="AccordionGeo">
            <StyledTraceGeofenceAccordionSummaryWrapper>
              <FormControlLabel
                control={<div />}
                label={key}
                sx={{
                  width: '426px',
                  margin: '12px 0px 12px 9px',
                  color: '#64696E',
                }}
              />
              <StyledTraceGeofenceAccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              />
            </StyledTraceGeofenceAccordionSummaryWrapper>
            {geofence.map((company, index) => (
              <StyledTraceGeofenceAccordionDetails
                className="AccordionDetailsGeo"
                sx={
                  (index === geofence.length - 1 && {
                    marginLeft: '0px !important',
                    marginTop: '8px',
                    marginBottom: '43px',
                    height: '6px',
                    width: '426px',
                  }) || {
                    marginLeft: '0px !important',
                    marginTop: '8px',
                    height: '6px',
                    width: '426px',
                  }
                }
              >
                <FormControlLabel
                  key={company}
                  control={
                    <GeofenceCheckbox
                      company={company}
                      geofenceList={geofenceList}
                      setGeofenceList={setGeofenceList}
                      mapSettings={mapSettings}
                      setMapSettings={setMapSettings}
                    />
                  }
                  sx={{
                    width: '426px',
                  }}
                />
              </StyledTraceGeofenceAccordionDetails>
            ))}
          </StyledTraceGeofenceAccordion>
        );
      })}
    </StyledTraceCommonSidebarContainer>
  );
};

export default TraceSidebarGeofence;
