import styled from 'styled-components';

const StyledTraceSearchBarWrapper = styled.div`
  border-radius: 3px;
  margin: 23px 0px 22px 23px;
  width: 380px;
  height: 30px;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  padding: 0px 21px;
`;

export default StyledTraceSearchBarWrapper;
