import { useEffect, useState } from 'react';
import WEEK_DAY_LIST from '../../../constants/TmsReport/Schedules/weeklyDayList';

const useControlWeeklyData = ({ weeklyDateQuery, scheduleData }) => {
  const [weeklyData, setWeeklyData] = useState(
    WEEK_DAY_LIST.reduce((acc, day) => {
      acc[day] = { date: '', data: [] };
      return acc;
    }, {}),
  );

  useEffect(() => {
    const isExistWeeklyDate = Object?.keys?.(weeklyDateQuery)?.length > 0;

    if (isExistWeeklyDate) {
      const tempWeeklyData = WEEK_DAY_LIST.reduce((acc, day) => {
        acc[day] = { date: weeklyDateQuery?.[day], data: [] };
        return acc;
      }, {});

      setWeeklyData(tempWeeklyData);
    }
  }, [weeklyDateQuery]);

  /** Set weekly data when data and date query are changed */
  useEffect(() => {
    const isExistScheduleData = Object?.keys?.(scheduleData)?.length > 0;
    const isExistWeeklyDate = Object?.keys?.(weeklyDateQuery)?.length > 0;

    if (isExistScheduleData && isExistWeeklyDate) {
      const tempWeeklyData = WEEK_DAY_LIST.reduce((acc, day) => {
        acc[day] = {
          date: weeklyDateQuery?.[day],
          data: scheduleData?.[weeklyDateQuery?.[day]]
            ? [...scheduleData?.[weeklyDateQuery?.[day]]]
            : [],
        };
        return acc;
      }, {});

      setWeeklyData(tempWeeklyData);
    }
  }, [scheduleData]);

  return { weeklyData };
};

export default useControlWeeklyData;
