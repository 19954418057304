import { useMutation } from '@tanstack/react-query';
import useControlToast from '../../../Common/Toast/useControlToast';
import { SUCCESS_SEND } from '../../../../constants/Common/successMessage';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';
import postEmailList from '../../../../services/CustomerReport/Post/postEmailList';

const usePostEmailList = ({
  setIsOpenErrorModal,
  setMessage,
  setIsSuccess,
}) => {
  const { addToast } = useControlToast();
  const mutate = useMutation({
    mutationFn: postEmailList,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: () => {
      addToast({ message: SUCCESS_SEND });
    },
    onError: error => {
      setIsOpenErrorModal(true);
      setIsSuccess(false);
      setMessage(error?.message);
    },
  });

  return { ...mutate };
};

export default usePostEmailList;
