import React from 'react';
import SidebarBlur from './SidebarBlur';
import SidebarMenu from './SidebarMenu';
import {
  SIDEBAR_MENU,
  SIDEBAR_SUB_MENU,
} from '../../constants/Common/Option/Sidebar';
import StyledSidebarWrapper from '../../styles/Common/Sidebar/StyledSidebarWrapper';
import StyledSidebarMenuWrapper from '../../styles/Common/Sidebar/StyledSidebarMenuWrapper';
import getAuthorizedMenu from '../../utils/Common/Sidebar/Handler/getAuthorizedMenu';

/**
 * @description
 * Component to display the sidebar.
 * @param {boolean} isOpen
 * State to check if the sidebar is open or not.
 * @param {function} setOpen
 * Function to handle the sidebar open event.
 * @returns {React.ReactElement}
 */
const Sidebar = ({ ...props }) => {
  const { isOpen, setOpen } = props;

  return (
    <>
      {isOpen && <SidebarBlur setOpen={setOpen} />}
      <StyledSidebarWrapper id="sidebarWrapper">
        <StyledSidebarMenuWrapper>
          {getAuthorizedMenu({ allMenu: SIDEBAR_MENU })?.map((menu, index) => (
            <SidebarMenu
              key={menu?.seq}
              label={menu?.label}
              subMenu={getAuthorizedMenu({
                allMenu: SIDEBAR_SUB_MENU?.[menu?.seq],
              })}
              to={
                getAuthorizedMenu({
                  allMenu: SIDEBAR_SUB_MENU?.[menu?.seq],
                })?.[0]?.to || menu?.to
              }
              iconSet={menu?.iconSet}
              isOpen={isOpen}
              setOpen={setOpen}
            />
          ))}
        </StyledSidebarMenuWrapper>
      </StyledSidebarWrapper>
    </>
  );
};

export default Sidebar;
