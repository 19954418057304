export const WO_CATEGORY_SHORT_NAME = {
  IMPORT: 'IMP',
  EXPORT: 'EXP',
};

export const SCHEDULE_PAGE_TYPE = {
  po: 'pull-out',
  dilv: 'delivery',
  rtn: 'return',
};
