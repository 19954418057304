import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthRouter from '../../../../../components/Common/AuthRouter';
import Map from '../../../../../pages/Trace/Map';
import {
  PERM_TRACE_GEOFENCE,
  PERM_TRACE_JOB,
  PERM_TRACE_MAP,
} from '../../../permission';
import Job from '../../../../../pages/Trace/Job';
import Geofence from '../../../../../pages/Trace/Geofence';
import getAuthorizedMenu from '../../../../../utils/Common/Sidebar/Handler/getAuthorizedMenu';
import { SIDEBAR_SUB_MENU } from '../../../Option/Sidebar';

const TRACE_CHILDREN_ROUTE = [
  {
    index: true,
    element: (
      <Navigate
        to={
          getAuthorizedMenu({
            allMenu: SIDEBAR_SUB_MENU?.[6],
          })?.[0]?.to
        }
      />
    ),
  },
  {
    path: 'asset/*',
    element: (
      <AuthRouter permission={PERM_TRACE_MAP}>
        <Map />
      </AuthRouter>
    ),
  },
  {
    path: 'job/*',
    element: (
      <AuthRouter permission={PERM_TRACE_JOB}>
        <Job />
      </AuthRouter>
    ),
  },
  {
    path: 'geofence/*',
    element: (
      <AuthRouter permission={PERM_TRACE_GEOFENCE}>
        <Geofence />
      </AuthRouter>
    ),
  },
];

export default TRACE_CHILDREN_ROUTE;
