import GridAutoCompleteCell from '../../../../components/CommonDataGrid/Component/GridCellRenderer/GridAutoCompleteCell';
import GridDateCell from '../../../../components/CommonDataGrid/Component/GridCellRenderer/GridDateCell';
import GridDatetimeCell from '../../../../components/CommonDataGrid/Component/GridCellRenderer/GridDatetimeCell';
import GridSelectCell from '../../../../components/CommonDataGrid/Component/GridCellRenderer/GridSelectCell';
import GridSwitchCell from '../../../../components/CommonDataGrid/Component/GridCellRenderer/GridSwitchCell';
import GridTextCell from '../../../../components/CommonDataGrid/Component/GridCellRenderer/GridTextCell';
import GridTimeCell from '../../../../components/CommonDataGrid/Component/GridCellRenderer/GridTimeCell';
import {
  CELL_TYPE_AUTO_COMPLETE,
  CELL_TYPE_DATE,
  CELL_TYPE_DATETIME,
  CELL_TYPE_SELECT,
  CELL_TYPE_TEXT,
  CELL_TYPE_TIME,
  CELL_TYPE_SWITCH,
} from '../../../../constants/Common/DataGrid/cellType';

/**
 * @description
 * Function that generate cell according to cell type.
 * @param {string} cellType
 * Cell type
 * @returns
 * Cell renderer
 */
const cellEditorRendererWithCellTypeGenerator = ({ cellType, ...props }) => {
  if (cellType === CELL_TYPE_TEXT) return GridTextCell;
  if (cellType === CELL_TYPE_SELECT) return GridSelectCell;
  if (cellType === CELL_TYPE_AUTO_COMPLETE) return GridAutoCompleteCell;
  if (cellType === CELL_TYPE_DATE) return GridDateCell;
  if (cellType === CELL_TYPE_DATETIME) return GridDatetimeCell;
  if (cellType === CELL_TYPE_TIME) return GridTimeCell;
  if (cellType === CELL_TYPE_SWITCH) return GridSwitchCell;
  return null;
};

export default cellEditorRendererWithCellTypeGenerator;
