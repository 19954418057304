export const BOX_COLOR_OBJECT = {
  7: {
    color: '#570005',
    bgColor: 'bg-[#570005]',
    borderColor: 'border-[#570005]',
    fontColor: 'text-[#570005]',
  },
  6: {
    color: '#880008',
    bgColor: 'bg-[#880008]',
    borderColor: 'border-[#880008]',
    fontColor: 'text-[#880008]',
  },
  5: {
    color: '#BA0000',
    bgColor: 'bg-[#BA0000]',
    borderColor: 'border-[#BA0000]',
    fontColor: 'text-[#BA0000]',
  },
  4: {
    color: '#E7000D',
    bgColor: 'bg-[#E7000D]',
    borderColor: 'border-[#E7000D]',
    fontColor: 'text-[#E7000D]',
  },
  3: {
    color: '#FF5C00',
    bgColor: 'bg-[#FF5C00]',
    borderColor: 'border-[#FF5C00]',
    fontColor: 'text-[#FF5C00]',
  },
  2: {
    color: '#FF9900',
    bgColor: 'bg-[#FF9900]',
    borderColor: 'border-[#FF9900]',
    fontColor: 'text-[#FF9900]',
  },
  1: {
    color: '#FFC700',
    bgColor: 'bg-[#FFC700]',
    borderColor: 'border-[#FFC700]',
    fontColor: 'text-[#FFC700]',
  },
};
