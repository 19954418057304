import React, { memo, useEffect } from 'react';
import {
  PATH_MODAL_CLOSE_ICON,
  PATH_SCANNING_ICON,
} from '../../../constants/Common/path';
import CommonButton from '../../CommonButton/CommonButton';
import createOCRSubCategory from '../../../utils/Order/Generator/createOCRSubCategory';

const doViewerPropsAreEqual = (prevProps, nextProps) => {
  return (
    prevProps?.data === nextProps?.data &&
    prevProps?.scanDOStatus?.isError === nextProps?.scanDOStatus?.isError &&
    prevProps?.scanDOStatus?.isPending === nextProps?.scanDOStatus?.isPending
  );
};

const CommonDOViewer = ({
  data,
  scanDOStatus,
  setValue,
  scanDOMuator,
  ...props
}) => {
  const { isPending } = scanDOStatus;
  const subCategory = document.URL?.split('/')?.pop();

  return (
    <div className="w-full h-full relative">
      <div className="w-full h-full flex justify-center items-center absolute flex-col">
        <div className="top-0 right-0 absolute pt-[20px] pr-[24px]">
          <div
            className="cursor-pointer"
            onClick={() => {
              setValue?.('do_file_name', '');
              setValue?.('do', null);
              document.getElementById('do_file_name').value = '';
              document.getElementById('do').value = null;
            }}
          >
            <img
              src={PATH_MODAL_CLOSE_ICON}
              width={12}
              height={12}
              alt="OCR Cancel Icon"
            />
          </div>
        </div>
        <div className={`${isPending ? `blur-sm` : ''} w-[527px] h-[721px]`}>
          {data?.type === 'application/pdf' ? (
            <iframe
              title="doViewer"
              src={data && `${URL.createObjectURL(data)}#toolbar=0`}
              className="w-full h-full"
            />
          ) : (
            <div className="w-full h-full flex justify-center items-center flex-col gap-[10px]">
              <p className="text-[#556DA1] font-bold text-[18px]">
                Viewer and Scan are only supported with PDF file
              </p>
              <p className="text-[#7F90B6] font-medium text-[18px]">
                Upload is available for all types
              </p>
            </div>
          )}
        </div>
        <div className="pt-[30px] flex gap-[10px] flex-row">
          {!isPending && (
            <>
              <CommonButton
                category="cancel"
                onClick={() => {
                  setValue?.('do_file_name', '');
                  setValue?.('do', null);
                  document.getElementById('do_file_name').value = '';
                  document.getElementById('do').value = null;
                }}
              >
                Cancel
              </CommonButton>
              <CommonButton
                category="areaScan"
                onClick={() =>
                  scanDOMuator({
                    file: data,
                    category: createOCRSubCategory({ subCategory }),
                  })
                }
                disabled={data?.type !== 'application/pdf'}
              >
                Scan
              </CommonButton>
            </>
          )}
        </div>
      </div>
      {isPending && (
        <div className="absolute flex justify-center items-center w-full h-full">
          <img
            src={PATH_SCANNING_ICON}
            alt="scanning"
            className="w-[235px] h-[135px] ml-2"
          />
        </div>
      )}
    </div>
  );
};

export default memo(CommonDOViewer, doViewerPropsAreEqual);
