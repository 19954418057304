import React from 'react';
import CommonButton from '../../../CommonButton/CommonButton';
import exportToExcel from '../../../../utils/Common/Datagrid/Handler/exportToExcel';

const MainUtilityBar = ({ ...props }) => {
  /** Get props data */
  const { gridRef } = props;

  /** Modal opener */
  const {
    setAdvancedSearchOpener,
    setSavedListOpener,
    setSaveItemOpener,
    setCustomColumnOpener,
  } = props;

  /** Style of extra utility button (Search, Customize Columns, Saved List) */
  const extraUtilityButtonStyle = {
    border: 'unset',
    backgroundColor: 'unset',
    color: '#666666',
    fontSize: '12px',
    fontWeight: '500',
  };

  return (
    <div className="flex justify-center items-center w-fit min-w-fit">
      <CommonButton
        category="greySearch"
        customStyle={extraUtilityButtonStyle}
        onClick={() => setAdvancedSearchOpener(true)}
      >
        Search
      </CommonButton>
      <div className="h-[14px] w-[0px] border-solid border-[#00000099] border-[0.2px] mx-[5px]" />
      <CommonButton
        category="customizeColumn"
        customStyle={extraUtilityButtonStyle}
        onClick={() => setCustomColumnOpener(true)}
      >
        Customize Columns
      </CommonButton>
      <div className="h-[14px] w-[0px] border-solid border-[#00000099] border-[0.2px] mx-[5px]" />
      <CommonButton
        category="greySavedList"
        customStyle={extraUtilityButtonStyle}
        onClick={() => setSavedListOpener(true)}
      >
        Saved List
      </CommonButton>
      <CommonButton
        category="excel"
        onClick={() =>
          exportToExcel({ gridRef, fileName: 'CUSTOMER REPORT MAIN' })
        }
      >
        EXCEL
      </CommonButton>
      <div className="mr-[10px]" />
      <CommonButton category="save" onClick={() => setSaveItemOpener(true)}>
        Save
      </CommonButton>
    </div>
  );
};

export default MainUtilityBar;
