import React, { useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  initDragEvents,
  onChangeFile,
  resetDragEvents,
} from '../../../../utils/Common/Handler/dragAndDrop';
import CommonDOViewer from '../../CommonDOViewer';
import ScannerReady from '../../ScannerReady';

const CommonContainerScanner = ({ scanDOMuator, scanDOStatus }) => {
  const doWatcher = useWatch({ name: 'do' });
  const { setValue } = useFormContext();
  const [isDragEnter, setIsDragEnter] = useState(false);
  const [fileList, setFileList] = useState(null);
  // Drag reference for file upload.
  const dragRef = useRef(null);

  useEffect(() => {
    // Initialize drag and drop events.
    initDragEvents({ ref: dragRef, setIsDragEnter, setFileList, setValue });

    return () => resetDragEvents({ ref: dragRef });
  }, [initDragEvents, resetDragEvents]);

  return (
    <div
      ref={dragRef}
      className={`w-[638px] min-w-[638px] h-[844px] min-h-[844px] bg-[#DDDFE5]
      flex justify-center items-center ${!doWatcher && `cursor-pointer`} ${
        isDragEnter ? `blur-sm` : ``
      }`}
      onClick={() => {
        if (!doWatcher) document.getElementById('scanDoScanner').click();
      }}
    >
      <input
        type="file"
        id="scanDoScanner"
        className="hidden"
        onChange={e => onChangeFile({ e, setFileList, setValue })}
      />

      {doWatcher ? (
        <CommonDOViewer
          data={doWatcher}
          scanDOMuator={scanDOMuator}
          scanDOStatus={scanDOStatus}
          setValue={setValue}
        />
      ) : (
        <ScannerReady />
      )}
    </div>
  );
};

export default CommonContainerScanner;
