const handleActiveSwitchClick = ({
  e,
  title,
  gridParam,
  mutator,
  setChecked,
  disabledRef,
}) => {
  const isRowPinned = gridParam?.node?.rowPinned;
  const rowData = gridParam?.data;

  if (!isRowPinned) {
    if (
      disabledRef?.current?.disabled &&
      disabledRef?.current?.disabledRow === gridParam?.rowIndex
    ) {
      return;
    }
    disabledRef.current.disabled = true;
    disabledRef.current.disabledRow = gridParam?.rowIndex;

    setChecked(e?.target?.checked);
    mutator.mutate({ title, seq: rowData?.seq, gridParam, setChecked });
  }
};

export default handleActiveSwitchClick;
