const handleAutoCompleteChange = ({
  e,
  selectProps,
  onChange,
  ...handlerProps
}) => {
  onChange?.(selectProps?.value);
};

export default handleAutoCompleteChange;
