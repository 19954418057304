const IMPORT_UNAVAILABLE_SUB_FILTERS = [
  { header: 'Total', width: '85px', key: 'unavailable_all' },
  { header: 'LFD', width: '85px', key: 'unavailable_lfd' },
  { header: 'No LFD', width: '85px', key: 'unavailable_no_lfd' },
];

const EXPORT_UNAVAILABLE_SUB_FILTERS = [
  { header: 'Total', width: '85px', key: 'unavailable_all' },
  { header: 'Booking #', width: '100px', key: 'unavailable_booking' },
  { header: 'No Booking #', width: '100px', key: 'unavailable_no_booking' },
  {
    header: 'Yard',
    width: '85px',
    key: 'unavailable_yard',
  },
];

const AVAILABLE_SUB_FILTERS_WITHOUT_YARD = [
  { header: 'Total', width: '85px', key: 'available_all' },
  {
    header: 'Schedule',
    width: '85px',
    key: 'available_schd',
  },
  {
    header: 'No Schedule',
    width: '93px',
    key: 'available_no_schd',
  },
];

const AVAILABLE_SUB_FILTERS = [
  { header: 'Total', width: '85px', key: 'available_all' },
  {
    header: 'Schedule',
    width: '85px',
    key: 'available_schd',
  },
  {
    header: 'No Schedule',
    width: '93px',
    key: 'available_no_schd',
  },
  {
    header: 'Yard',
    width: '85px',
    key: 'available_yard',
  },
];

const DELIVERY_SUB_FILTERS_WITHOUT_YARD = [
  { header: 'Total', width: '85px', key: 'delivery_all' },
  {
    header: 'Schedule',
    width: '85px',
    key: 'delivery_schd',
  },
  {
    header: 'No Schedule',
    width: '93px',
    key: 'delivery_no_schd',
  },
];

const DELIVERY_SUB_FILTERS = [
  { header: 'Total', width: '85px', key: 'delivery_all' },
  {
    header: 'Schedule',
    width: '85px',
    key: 'delivery_schd',
  },
  {
    header: 'No Schedule',
    width: '93px',
    key: 'delivery_no_schd',
  },
  {
    header: 'Cnee',
    width: '85px',
    key: 'delivery_yard',
  },
];

const RETURN_SUB_FILTERS_WITHOUT_YARD = [
  { header: 'Total', width: '85px', key: 'return_all' },
  {
    header: 'Schedule',
    width: '85px',
    key: 'return_schd',
  },
  {
    header: 'No Schedule',
    width: '93px',
    key: 'return_no_schd',
  },
];

const RETURN_SUB_FILTERS = [
  { header: 'Total', width: '85px', key: 'return_all' },
  {
    header: 'Schedule',
    width: '85px',
    key: 'return_schd',
  },
  {
    header: 'No Schedule',
    width: '93px',
    key: 'return_no_schd',
  },
  {
    header: 'Yard',
    width: '85px',
    key: 'return_yard',
  },
];

const TOTAL_TAB = 'total';
const ACTIVE_TAB = 'active';
const YARD_TAB = 'yard';

const LOCATION_TAB_FILTERS = ['yard', 'cnee'];
const INIT_FILTERS = ['active', 'total', 'yard', 'cnee'];

export {
  IMPORT_UNAVAILABLE_SUB_FILTERS,
  EXPORT_UNAVAILABLE_SUB_FILTERS,
  AVAILABLE_SUB_FILTERS_WITHOUT_YARD,
  AVAILABLE_SUB_FILTERS,
  DELIVERY_SUB_FILTERS_WITHOUT_YARD,
  DELIVERY_SUB_FILTERS,
  RETURN_SUB_FILTERS_WITHOUT_YARD,
  RETURN_SUB_FILTERS,
  LOCATION_TAB_FILTERS,
  INIT_FILTERS,
  TOTAL_TAB,
  ACTIVE_TAB,
  YARD_TAB,
};
