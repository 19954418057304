export const DISPATCH_BOOKING_COLUMNS = [
  'bk_no',
  'ssl',
  'billto',
  'erd',
  'cutoff',
  'dilv_location',
  'dilv_city',
  'dilv_state',
  'rtn_location',
  {
    cont_1: ['cont_size_1', 'cont_type_1', 'total_1', 'balance_1'],
  },
  {
    cont_2: ['cont_size_2', 'cont_type_2', 'total_2', 'balance_2'],
  },
  {
    cont_3: ['cont_size_3', 'cont_type_3', 'total_3', 'balance_3'],
  },
  'doc',
  'status',
];
