import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import dayjs from 'dayjs';
import CommonDate from '../../../Inputs/CommonDate/CommonDate';
import CommonSelect from '../../../Inputs/CommonSelect/CommonSelect';
import { dateOption } from '../../../../constants/CustomerReport/advancedSearchOption';
import getDateAfterDisableCondition from '../../../../utils/CustomerReport/Handler/getDateAfterDisableCondition';

const Content = ({ ...props }) => {
  /** Get props data */
  const { columnDefs } = props;
  const { setValue } = useFormContext();

  const dateTypeWatcher = useWatch({
    name: 'advanced.dateType',
  });

  return (
    <div className="w-[473px] h-[87px] bg-[#F9F9F9] flex justify-center flex-col pl-[30px] gap-[6px]">
      <div className="text-[#595656] text-[12px] font-medium">Date</div>
      <div className="flex items-center flex-row gap-[5px]">
        <div className="flex justify-center items-center w-[150px]">
          <CommonSelect
            inputName="advanced.dateType"
            labelVisible={false}
            option={columnDefs
              ?.flatMap(column => {
                if (column?.children) {
                  return column?.children?.map(child => {
                    return dateOption?.find(
                      option => option?.value === child?.field,
                    );
                  });
                }
                return dateOption?.find(
                  option => option?.value === column?.field,
                );
              })
              ?.filter(column => column)}
            onChangeHandler={({ e, onChange, ...handlerProps }) => {
              onChange?.(e?.target?.value);
              setValue('advanced.before', '');
              setValue('advanced.after', '');
            }}
          />
        </div>
        <div className="flex justify-center items-center w-[116px]">
          <CommonDate
            inputName="advanced.before"
            labelVisible={false}
            disabled={!dateTypeWatcher}
            onChangeHandler={({ e, onChange, ...handlerProps }) => {
              onChange(e && dayjs(e)?.isValid ? e : null);
            }}
          />
        </div>
        <div className="w-[13px] h-[1px] bg-[#D9D9D9]" />
        <div className="flex justify-center items-center w-[116px]">
          <CommonDate
            inputName="advanced.after"
            labelVisible={false}
            disabled={!dateTypeWatcher}
            onChangeHandler={({ e, onChange, ...handlerProps }) => {
              onChange(e && dayjs(e)?.isValid ? e : null);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Content;
