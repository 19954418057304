import { useMutation } from '@tanstack/react-query';
import postRollbackToImport from '../../../../services/BCDetail/DetailInfo/Common/Post/postRollbackToImport';
import useControlToast from '../../../Common/Toast/useControlToast';
import { SUCCESS_ROLLBACK } from '../../../../constants/Common/successMessage';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';

const usePostRollbackToImport = ({
  setMessage,
  setErrorModalOpen,
  setAlertModalOpen,
}) => {
  const { addToast } = useControlToast();

  const mutator = useMutation({
    mutationFn: postRollbackToImport,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: success => {
      addToast({
        message: SUCCESS_ROLLBACK,
      });
      setTimeout(() => {
        window.close();
      }, 3000);
    },
    onError: error => {
      setMessage(error.message);
      setErrorModalOpen(true);
    },
  });
  return mutator;
};

export default usePostRollbackToImport;
