import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import getDriverList from '../../../services/Dispatch/Common/get/getDriverList';
import dispatchKeys from '../../Dispatch2/keys';

const useGetDriverList = ({ woNo, driver }) => {
  const [driverList, setDriverList] = useState([]);
  const [paramDriver, setParamDriver] = useState('');

  useEffect(() => {
    if (!driver || driver === '') {
      setParamDriver('');
    } else {
      setParamDriver(`${driver}`);
    }
  }, [driver]);

  const query = useQuery({
    queryKey: dispatchKeys?.driverList({ woNo, driver: paramDriver }),
    queryFn: () => getDriverList({ woNo, driver: paramDriver }),
    refetchOnWindowFocus: false,
    enabled: Boolean(woNo),
  });

  useEffect(() => {
    if (query?.data?.data) {
      setDriverList(query?.data?.data);
    }
  }, [query?.data]);

  return { driverList, ...query };
};

export default useGetDriverList;
