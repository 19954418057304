import React, { useState } from 'react';
import StyledTraceCommonSidebarContainer from '../../styles/Trace/Common/StyledTraceCommonSidebarContainer';
import StyledTraceMapAccordionWrapper from '../../styles/Trace/Map/StyledTraceMapAccordionWrapper';
import StyledTraceMapCheckbox from '../../styles/Trace/Map/StyledTraceMapCheckbox';
import StyledTraceMapCheckboxLabel from '../../styles/Trace/Map/StyledTraceMapCheckboxLabel';
import StyledTraceMapSubList from '../../styles/Trace/Map/StyledTraceMapSubList';
import StyledTraceMapTitle from '../../styles/Trace/Map/StyledTraceMapTitle';
import StyledTraceMapTitleWithIcon from '../../styles/Trace/Map/StyledTraceMapTitleWithIcon';
import StyledTraceMapVehicleName from '../../styles/Trace/Map/StyledTraceMapVehicleName';
import createCategory from '../../utils/Master/ServiceCode/Generator/createCategory';
import toggleDivision from '../../utils/Trace/Map/toggleDivision';
import JobSearchBar from '../Trace/Module/JobSearchBar';

const TraceSidebarJob = ({
  setCheckedList,
  setCheckedDriverList,
  setSeq,
  isTraceJobDetailLoading,
  traceJobList,
  isLoadingJobList,
  setTarget,
  setCategory,
}) => {
  const [checkedItems, setCheckedItems] = useState({});
  const allDiv = localStorage.getItem('allDiv');
  const allDivArray = allDiv.split(',');
  const [expandedDivisions, setExpandedDivisions] = useState({});

  const handleCheckboxChange = ({ division, vehicleId, driver }) => {
    setCheckedItems(prevCheckedItems => {
      const isChecked = !prevCheckedItems[division]?.[vehicleId];
      setSeq(vehicleId);
      return {
        ...prevCheckedItems,
        [division]: {
          ...prevCheckedItems[division],
          [vehicleId]: isChecked,
        },
      };
    });

    setCheckedList(prevCheckedList => {
      const newCheckedList = new Set(prevCheckedList);
      if (newCheckedList.has(vehicleId)) {
        newCheckedList.delete(vehicleId);
      } else {
        newCheckedList.add(vehicleId);
      }
      return Array.from(newCheckedList);
    });

    setCheckedDriverList(prevCheckedWoList => {
      const newCheckedWoList = new Set(prevCheckedWoList);
      if (newCheckedWoList.has(vehicleId)) {
        newCheckedWoList.delete(vehicleId);
      } else {
        newCheckedWoList.add({ vehicleId, driver });
      }
      return Array.from(newCheckedWoList);
    });
  };

  if (isLoadingJobList)
    return (
      <StyledTraceCommonSidebarContainer>
        <JobSearchBar setTarget={setTarget} setCategory={setCategory} />
      </StyledTraceCommonSidebarContainer>
    );

  return (
    <StyledTraceCommonSidebarContainer>
      <JobSearchBar setTarget={setTarget} setCategory={setCategory} />
      <StyledTraceMapAccordionWrapper>
        {traceJobList &&
          Object.entries(traceJobList)
            .filter(([division]) => allDivArray.includes(division))
            .map(([division, vehicles]) => (
              <div key={division}>
                <StyledTraceMapTitleWithIcon
                  onClick={() =>
                    toggleDivision({ division, setExpandedDivisions })
                  }
                >
                  <StyledTraceMapTitle>{division}</StyledTraceMapTitle>
                  <div>
                    {expandedDivisions[division] ? (
                      <img
                        src="/assets/images/traceIcon/arrowOpenedBlack.svg"
                        alt="arrowOpenedBlack"
                      />
                    ) : (
                      <img
                        src="/assets/images/traceIcon/arrowClosedBlack.svg"
                        alt="arrowClosedBlack"
                      />
                    )}
                  </div>
                </StyledTraceMapTitleWithIcon>
                <img
                  src="/assets/images/traceIcon/separateLine.svg"
                  alt="separateLine"
                />
                {expandedDivisions[division] && (
                  <StyledTraceMapSubList>
                    {Object.entries(vehicles).map(
                      ([vehicleId, { wo_no: wo, driver }]) => {
                        const woCategory = createCategory(wo);
                        return (
                          <div key={vehicleId}>
                            <StyledTraceMapCheckboxLabel>
                              <StyledTraceMapCheckbox
                                type="checkbox"
                                checked={
                                  checkedItems[division]?.[vehicleId] || false
                                }
                                onChange={() =>
                                  handleCheckboxChange({
                                    division,
                                    vehicleId,
                                    driver,
                                  })
                                }
                                disabled={isTraceJobDetailLoading}
                              />
                              <StyledTraceMapVehicleName
                                onClick={() => {
                                  const bcDetailTab = window.open(
                                    `/bc-detail/detail-info/${woCategory.toLowerCase()}/${wo}`,
                                  );
                                }}
                              >
                                {wo} / {driver}
                              </StyledTraceMapVehicleName>
                            </StyledTraceMapCheckboxLabel>
                          </div>
                        );
                      },
                    )}
                  </StyledTraceMapSubList>
                )}
              </div>
            ))}
      </StyledTraceMapAccordionWrapper>
    </StyledTraceCommonSidebarContainer>
  );
};

export default TraceSidebarJob;
