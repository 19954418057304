import React from 'react';

const EmailConfirmModalContent = ({ ...props }) => {
  const { email } = props;

  return (
    <div className="w-full h-full p-[20px] flex justify-center items-center bg-[#f9f9f9] text-[12px] font-normal flex-col">
      <b>Are you sure you want to send mail to the email below?</b>
      <div className="w-full flex flex-col gap-[5px] pt-[10px]">
        {email?.map((item, index) => {
          return (
            <div
              key={String(index + 1)}
              className="w-full justify-center items-center py-[5px]"
            >
              - {item}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EmailConfirmModalContent;
