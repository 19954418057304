import {
  MAIN_BARE_CHASSIS_FILTER_DATE,
  MAIN_BROKERAGE_FILTER_DATE,
  MAIN_EXPORT_FILTER_DATE,
  MAIN_IMPORT_FILTER_DATE,
  MAIN_VAN_FILTER_DATE,
} from '../../../constants/Main/Common/advancedSearchOptions';
import {
  MAIN_CATEGORY_BARE_CHASSIS,
  MAIN_CATEGORY_BROKERAGE,
  MAIN_CATEGORY_EXPORT,
  MAIN_CATEGORY_IMPORT,
  MAIN_CATEGORY_VAN,
} from '../../../constants/Main/Common/category';

const getSearchCategoryItems = category => {
  if (category === MAIN_CATEGORY_IMPORT) {
    return MAIN_IMPORT_FILTER_DATE;
  }
  if (category === MAIN_CATEGORY_EXPORT) {
    return MAIN_EXPORT_FILTER_DATE;
  }
  if (category === MAIN_CATEGORY_VAN) {
    return MAIN_VAN_FILTER_DATE;
  }
  if (category === MAIN_CATEGORY_BROKERAGE) {
    return MAIN_BROKERAGE_FILTER_DATE;
  }
  if (category === MAIN_CATEGORY_BARE_CHASSIS) {
    return MAIN_BARE_CHASSIS_FILTER_DATE;
  }
  return [];
};
export default getSearchCategoryItems;
