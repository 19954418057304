import {
  DISPATCH_PAGE_IMPORT_AVAILABLE,
  DISPATCH_PAGE_IMPORT_DELIVERY,
  DISPATCH_PAGE_IMPORT_RETURN,
  DISPATCH_PAGE_IMPORT_UNAVAILABLE,
} from '../../Common/category';
import {
  AVAILABLE_SUB_FILTERS,
  DELIVERY_SUB_FILTERS,
  IMPORT_UNAVAILABLE_SUB_FILTERS,
  RETURN_SUB_FILTERS,
} from '../../Common/filterButtonSettings';

const IMPORT_FILTERS = [
  {
    header: 'UNAVAILABLE',
    key: 'unavailable',
    status: DISPATCH_PAGE_IMPORT_UNAVAILABLE,
    to: '/dispatch/import/unavailable',
    children: IMPORT_UNAVAILABLE_SUB_FILTERS,
  },
  {
    header: 'AVAILABLE',
    key: 'available',
    status: DISPATCH_PAGE_IMPORT_AVAILABLE,
    to: '/dispatch/import/available',
    children: AVAILABLE_SUB_FILTERS,
  },
  {
    header: 'DELIVERY Ready',
    key: 'delivery',
    status: DISPATCH_PAGE_IMPORT_DELIVERY,
    to: '/dispatch/import/delivery',
    children: DELIVERY_SUB_FILTERS,
  },
  {
    header: 'RETURN Ready',
    key: 'return',
    status: DISPATCH_PAGE_IMPORT_RETURN,
    to: '/dispatch/import/return',
    children: RETURN_SUB_FILTERS,
  },
];

export default IMPORT_FILTERS;
