import { useMutation, useQueryClient } from '@tanstack/react-query';
import postDriverData from '../../../services/Master/Driver/Post/postDriverData';
import masterKeys from '../keys';
import handleDriverData from '../../../utils/Master/Driver/Handler/handleDriverData';
import useControlToast from '../../Common/Toast/useControlToast';
import { SUCCESS_CREATE } from '../../../constants/Common/successMessage';
import updateButtonDisabled from '../../../utils/Common/Handler/updateButtonDisabled';

const usePostDriverData = ({
  setMessageContent,
  setMessageModalOpen,
  editMode,
  handleReset,
  inputController,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  const mutator = useMutation({
    mutationFn: ({ submitData }) => {
      handleDriverData(submitData);
      return postDriverData(submitData);
    },
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: response => {
      queryClient.invalidateQueries({ queryKey: masterKeys.driverList() });
      if (!editMode) handleReset(inputController);
      addToast({
        message: `Driver ID "${response?.data?.driver}" ${SUCCESS_CREATE}`,
      });
    },
    onError: error => {
      if (
        error?.cause?.response?.status === 302 &&
        error?.cause?.response?.data?.code === QB_AUTH_CODE
      ) {
        window.open(error?.cause?.response?.data?.url, '_blank');
      } else {
        setMessageContent(error.message);
        setMessageModalOpen(true);
      }
    },
  });
  return mutator;
};

export default usePostDriverData;
