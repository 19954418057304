import {
  PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
  PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
} from '../../Common/path';

const DAILY_REPORT_BASIC_STYLE = {
  po_group: { backgroundColor: '#DEF5F8' },
  dilv_group: { backgroundColor: '#AEDEE4' },
  rtn_group: {
    backgroundColor: '#5E9FA8',
    color: 'white',
    icon: {
      fold: PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
      unfold: PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
    },
  },
};

export default DAILY_REPORT_BASIC_STYLE;
