import deleteQueryParameter from './deleteQueryParameter';

const setQueryParameter = ({ searchParams, setSearchParams, selectedList }) => {
  /** Delete all of query parameter */
  deleteQueryParameter({ searchParams, setSearchParams });

  const cusFilter = selectedList?.cus_filter;

  /** Apply basic filter */
  if (cusFilter?.billto && cusFilter?.billto !== '')
    searchParams?.set('billto', cusFilter?.billto);
  else searchParams?.delete('billto');

  if (cusFilter?.status && cusFilter?.status !== '')
    searchParams?.set('status', cusFilter?.status);
  else searchParams?.delete('status');

  if (cusFilter?.po_location && cusFilter?.po_location !== '')
    searchParams?.set('po_location', cusFilter?.po_location);
  else searchParams?.delete('po_location');

  if (cusFilter?.dilv_location && cusFilter?.dilv_location !== '')
    searchParams?.set('dilv_location', cusFilter?.dilv_location);
  else searchParams?.delete('dilv_location');

  if (cusFilter?.rtn_location && cusFilter?.rtn_location !== '')
    searchParams?.set('rtn_location', cusFilter?.rtn_location);
  else searchParams?.delete('rtn_location');

  /** Apply date filter */
  if (cusFilter?.date_filter_1) {
    const cusFilterDate = cusFilter?.date_filter_1;
    if (cusFilterDate?.date && cusFilterDate?.date !== '')
      searchParams?.set('date_1', cusFilterDate?.date);
    else searchParams?.delete('date_1');
    if (cusFilterDate?.after && cusFilterDate?.after !== '')
      searchParams?.set('after_1', cusFilterDate?.after);
    else searchParams?.delete('after_1');
    if (cusFilterDate?.before && cusFilterDate?.before !== '')
      searchParams?.set('before_1', cusFilterDate?.before);
    else searchParams?.delete('before_1');
  }
  if (cusFilter?.date_filter_2) {
    const cusFilterDate = cusFilter?.date_filter_2;
    if (cusFilterDate?.date && cusFilterDate?.date !== '')
      searchParams?.set('date_2', cusFilterDate?.date);
    else searchParams?.delete('date_2');
    if (cusFilterDate?.after && cusFilterDate?.after !== '')
      searchParams?.set('after_2', cusFilterDate?.after);
    else searchParams?.delete('after_2');
    if (cusFilterDate?.before && cusFilterDate?.before !== '')
      searchParams?.set('before_2', cusFilterDate?.before);
    else searchParams?.delete('before_2');
  }
  if (cusFilter?.date_filter_3) {
    const cusFilterDate = cusFilter?.date_filter_3;
    if (cusFilterDate?.date && cusFilterDate?.date !== '')
      searchParams?.set('date_3', cusFilterDate?.date);
    else searchParams?.delete('date_3');
    if (cusFilterDate?.after && cusFilterDate?.after !== '')
      searchParams?.set('after_3', cusFilterDate?.after);
    else searchParams?.delete('after_3');
    if (cusFilterDate?.before && cusFilterDate?.before !== '')
      searchParams?.set('before_3', cusFilterDate?.before);
    else searchParams?.delete('before_3');
  }

  /** Set search params */
  setSearchParams(prev => searchParams);
};

export default setQueryParameter;
