import React from 'react';
import { useFormContext } from 'react-hook-form';
import { EDITABLE, SAVE } from '../../../../constants/Acct/Common/staticNames';
import useGetProductList from '../../../../hooks/Acct/Common/Get/useGetProductList';
import { changeAutoCompleteWithLabel } from '../../../../utils/Acct/Common/Handler/changedInputFieldHandler';
import CommonButton from '../../../CommonButton/CommonButton';
import CommonAutoComplete from '../../../Inputs/CommonAutoComplete/CommonAutoComplete';

const ProductInputArea = ({
  productStatus,
  setProductStatus,
  setQueryObject,
}) => {
  const { productOptionsList, isProductListLoading } = useGetProductList({
    type: 'AP',
  });
  const { handleSubmit } = useFormContext();

  return (
    <div className="w-[500px] flex flex-row items-center gap-x-[5px]">
      <CommonAutoComplete
        labelStyle="row"
        label="SERVICE CODE"
        inputName="product_code"
        width="w-[173px]"
        maxLength={100}
        required
        disabled={productStatus === SAVE}
        option={productOptionsList}
        onChangeHandler={changeAutoCompleteWithLabel}
        isLoading={isProductListLoading}
      />
      {productStatus === EDITABLE && (
        <CommonButton
          category="approval"
          type="button"
          width="130px"
          height="24px"
          onClick={() => {
            handleSubmit(data => {
              setProductStatus(SAVE);
            })();
          }}
        >
          Apply
        </CommonButton>
      )}
      {productStatus === SAVE && (
        <CommonButton
          category="edit"
          type="button"
          width="130px"
          height="24px"
          onClick={() => {
            setProductStatus(EDITABLE);
            setQueryObject(prev => {
              const newQueryObject = { ...prev };
              delete newQueryObject.div;
              return newQueryObject;
            });
          }}
        >
          Edit
        </CommonButton>
      )}
    </div>
  );
};

export default ProductInputArea;
