import emailFormatValidateHandler from '../../Handler/emailFormatValidateHandler';

const validateEmail = ({ ...props }) => {
  return {
    email: v => {
      const email = v;
      const isEmailValid = emailFormatValidateHandler({ email });
      if (isEmailValid) return true;
      return 'Please enter a valid email format.';
    },
  };
};

export default validateEmail;
