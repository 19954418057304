const convertStatusToStandardCode = ({ status, data }) => {
  let standardCode;
  switch (status) {
    case 'imp_unava':
      standardCode = 'WSM001';
      break;
    case 'imp_ava':
    case 'imp_ava_yard':
      standardCode = 'WSM002';
      break;
    case 'imp_dilv':
    case 'imp_dilv_yard':
      standardCode = 'WSM003';
      break;
    case 'imp_rtn':
    case 'imp_rtn_yard':
    case 'exp_unava':
    case 'exp_unava_yard':
      standardCode = 'WSM004';
      break;
    case 'exp_booking':
      return data?.booking?.all;
    case 'exp_ava':
    case 'exp_ava_yard':
      standardCode = 'WSX001';
      break;
    case 'exp_dilv':
    case 'exp_dilv_yard':
      standardCode = 'WSX002';
      break;
    case 'exp_rtn':
    case 'exp_rtn_yard':
      standardCode = 'WSX003';
      break;
    case 'van_ava':
      standardCode = 'WSV001';
      break;
    case 'van_dilv':
      standardCode = 'WSV002';
      break;
    case 'van_rtn':
      standardCode = 'WSV003';
      break;
    case 'bkg_ava':
      standardCode = 'WSB001';
      break;
    case 'bkg_dilv':
      standardCode = 'WSB002';
      break;
    case 'bkg_rtn':
      standardCode = 'WSB003';
      break;

    default:
      break;
  }
  return standardCode;
};

export default convertStatusToStandardCode;
