import React, { useState } from 'react';
import { MAPPING_PAGE } from '../../../../constants/Admin/DivConf/adminDivConfElements';
import CommonButton from '../../../CommonButton/CommonButton';
import CommonAutoComplete from '../../../Inputs/CommonAutoComplete/CommonAutoComplete';
import CommonTextInput from '../../../Inputs/CommonTextInput/CommonTextInput';

const ARContainer = ({
  truckingCharge,
  fuelSurcharge,
  chassisRate,
  arProductCodeOption,
  div,
  postProductCodeMutation,
  patchProductCodeMutation,
  deleteProductCodeMutation,
  isLoadingARProductCode,
}) => {
  const [truckingChargeProductCode, setTruckingChargeProductCode] = useState(
    {},
  );
  const [fuelSurchargeProductCode, setFuelSurchargeProductCode] = useState({});
  const [chassisRateProductCode, setChassisRateProductCode] = useState({});
  const [deletedType, setDeletedType] = useState('');

  const handleSubmit = ({ type, code, buttonRef }) => {
    const mappingPage = MAPPING_PAGE[type];
    if (code) {
      if (truckingCharge?.mapping_page === mappingPage) {
        patchProductCodeMutation({
          seq: truckingCharge?.seq,
          productCode: code?.value,
          buttonRef,
        });
        return;
      }
      if (fuelSurcharge?.mapping_page === mappingPage) {
        patchProductCodeMutation({
          seq: fuelSurcharge?.seq,
          productCode: code?.value,
          buttonRef,
        });
        return;
      }
      if (chassisRate?.mapping_page === mappingPage) {
        patchProductCodeMutation({
          seq: chassisRate?.seq,
          productCode: code?.value,
          buttonRef,
        });
        return;
      }
      postProductCodeMutation({
        div,
        productCode: code?.value,
        mappingPage,
        buttonRef,
      });
      return;
    }
    setDeletedType(type);
    if (truckingCharge?.mapping_page === mappingPage) {
      deleteProductCodeMutation({
        seq: truckingCharge?.seq,
        productCode: truckingCharge?.product_code,
        buttonRef,
      });
    }
    if (fuelSurcharge?.mapping_page === mappingPage) {
      deleteProductCodeMutation({
        seq: fuelSurcharge?.seq,
        productCode: fuelSurcharge?.product_code,
        buttonRef,
      });
    }
    if (chassisRate?.mapping_page === mappingPage) {
      deleteProductCodeMutation({
        seq: chassisRate?.seq,
        productCode: chassisRate?.product_code,
        buttonRef,
      });
    }
  };

  return (
    <div className="flex ml-[119px] mt-[67px] align-center">
      <div className="flex flex-col">
        <div className="flex">
          <div className="flex flex-col">
            <div className="text-[15px] font-bold">AR</div>
            <div className="text-stone-600 text-xs font-medium mt-[29px] mb-[10px]">
              TRUCK CHARGE
            </div>
            <div className="mt-[-22px] mb-[-10px]">
              <CommonAutoComplete
                inputName="truckingCharge"
                placeholder="Service Code"
                width="w-[131px]"
                color="#264B9F"
                option={arProductCodeOption}
                defaultValue={truckingCharge?.product_code}
                onChangeHandler={({ onChange, selectProps }) => {
                  setDeletedType('');
                  onChange?.(selectProps?.label?.split(' / ')[0]);
                  setTruckingChargeProductCode(selectProps);
                }}
                isLoading={isLoadingARProductCode}
              />
            </div>
          </div>
          <div className="mt-[48px] ml-[7px]">
            <CommonTextInput
              inputName="truckingChargeName"
              placeholder="Service Code Name"
              width="w-[188px]"
              defaultValue={
                deletedType === 'TRUCKING_AR_RATE'
                  ? ''
                  : truckingChargeProductCode?.name ||
                    truckingCharge?.product_code_name
              }
              disabled
            />
          </div>
          <div className="w-full mt-[65px] ml-[7px]">
            <CommonButton
              category="complete"
              width="77px"
              preventDupClick
              onClick={({ buttonRef }) =>
                handleSubmit({
                  type: 'TRUCKING_AR_RATE',
                  code: truckingChargeProductCode,
                  buttonRef,
                })
              }
            >
              Apply
            </CommonButton>
          </div>
        </div>
        <div className="flex">
          <div className="flex flex-col">
            <div className="text-stone-600 text-xs font-medium mt-[20px] mb-[10px]">
              FUEL SURCHARGE
            </div>
            <div className="mt-[-22px] mb-[-10px]">
              <CommonAutoComplete
                inputName="fuelSurcharge"
                placeholder="Service Code"
                width="w-[131px]"
                color="#264B9F"
                option={arProductCodeOption}
                defaultValue={fuelSurcharge?.product_code}
                onChangeHandler={({ onChange, selectProps }) => {
                  setDeletedType('');
                  onChange?.(selectProps?.label?.split(' / ')[0]);
                  setFuelSurchargeProductCode(selectProps);
                }}
                isLoading={isLoadingARProductCode}
              />
            </div>
          </div>
          <div className="mt-[24px] ml-[7px]">
            <CommonTextInput
              inputName="fuelSurchargeName"
              placeholder="Service Code Name"
              width="w-[188px]"
              defaultValue={
                deletedType === 'FUEL_AR_RATE'
                  ? ''
                  : fuelSurchargeProductCode?.name ||
                    fuelSurcharge?.product_code_name
              }
              disabled
            />
          </div>
          <div className="w-full mt-[41px] ml-[7px]">
            <CommonButton
              category="complete"
              width="77px"
              preventDupClick
              onClick={({ buttonRef }) =>
                handleSubmit({
                  type: 'FUEL_AR_RATE',
                  code: fuelSurchargeProductCode,
                  buttonRef,
                })
              }
            >
              Apply
            </CommonButton>
          </div>
        </div>
        <div className="flex">
          <div className="flex flex-col">
            <div className="text-stone-600 text-xs font-medium mt-[20px] mb-[10px]">
              CHASSIS RATE
            </div>
            <div className="mt-[-22px] mb-[-10px]">
              <CommonAutoComplete
                inputName="chassisRate"
                placeholder="Service Code"
                width="w-[131px]"
                color="#264B9F"
                option={arProductCodeOption}
                defaultValue={chassisRate?.product_code}
                onChangeHandler={({ onChange, selectProps }) => {
                  setDeletedType('');
                  onChange?.(selectProps?.label?.split(' / ')[0]);
                  setChassisRateProductCode(selectProps);
                }}
                isLoading={isLoadingARProductCode}
              />
            </div>
          </div>
          <div className="mt-[24px] ml-[7px]">
            <CommonTextInput
              inputName="chassisRateName"
              placeholder="Service Code Name"
              width="w-[188px]"
              defaultValue={
                deletedType === 'CHASSIS_RATE'
                  ? ''
                  : chassisRateProductCode?.name ||
                    chassisRate?.product_code_name
              }
              disabled
            />
          </div>
          <div className="w-full mt-[41px] ml-[7px]">
            <CommonButton
              category="complete"
              width="77px"
              preventDupClick
              onClick={({ buttonRef }) =>
                handleSubmit({
                  type: 'CHASSIS_RATE',
                  code: chassisRateProductCode,
                  buttonRef,
                })
              }
            >
              Apply
            </CommonButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ARContainer;
