import { useQuery } from '@tanstack/react-query';
import getAcctCodeList from '../../../services/Master/AccountCode/Get/getAcctCodeList';
import masterKeys from '../keys';

const useGetAcctCode = () => {
  const result = useQuery({
    queryKey: masterKeys?.acctCodeList(),
    queryFn: getAcctCodeList,
    refetchOnWindowFocus: false,
  });
  return result;
};

export default useGetAcctCode;
