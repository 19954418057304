import { useMutation, useQueryClient } from '@tanstack/react-query';
import acctKeys from '../../keys';
import patchDriverPay from '../../../../services/Acct/QBAPAR/Patch/patchDriverPay';
import useControlToast from '../../../Common/Toast/useControlToast';
import { SUCCESS_UPDATE } from '../../../../constants/Common/successMessage';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';

const usePatchDriverPay = ({ errorModalOptions }) => {
  const { setIsSuccess, setErrorMessage, setIsErrorModalOpen } =
    errorModalOptions;
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  const mutate = useMutation({
    mutationFn: patchDriverPay,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: () => {
      queryClient?.invalidateQueries({
        queryKey: acctKeys?.all,
      });
      addToast({ message: SUCCESS_UPDATE });
    },
    onError: error => {
      setIsSuccess(false);
      setIsErrorModalOpen(true);
      setErrorMessage(error?.message);
    },
  });

  return { ...mutate };
};

export default usePatchDriverPay;
