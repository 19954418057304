const generateDefaultValue = ({ userInfo, divList }) => {
  if (!userInfo || Object?.keys?.(userInfo)?.length < 1) {
    return null;
  }

  /** When User Information Is Correct */
  const userObject = { ...userInfo };

  Object?.keys?.(userInfo?.userdivision_set)?.forEach(key => {
    userObject[key] = userInfo?.userdivision_set?.[key];
  });

  return userObject;
};

export default generateDefaultValue;
