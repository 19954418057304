import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import tmsReportKeys from '../keys';
import getStatisticsList from '../../../services/TmsReport/Statistics/Get/getStatisticsList';
import { STATISTICS_TABLE_TITLE_VALUE } from '../../../constants/TmsReport/Statistics/statisticsValues';

const useGetStatisticsList = ({
  cycle,
  dateQuery,
  setErrorMessage,
  setIsOpenErrorModal,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const [statisticsList, setStatisticsList] = useState({});

  /** Set up loading */
  useEffect(() => {
    setIsLoading(true);
  }, [cycle, dateQuery]);

  Object.keys(STATISTICS_TABLE_TITLE_VALUE).forEach(tableTitle => {
    const result = useQuery({
      queryKey: tmsReportKeys?.statisticsData({
        period: cycle,
        stat: tableTitle,
        dateQuery,
      }),
      queryFn: () =>
        getStatisticsList({
          period: cycle,
          stat: tableTitle,
          ...dateQuery,
        }),
      refetchOnWindowFocus: false,
    });

    useEffect(() => {
      /** Loading */
      setIsLoading(prev =>
        !prev ? prev : result?.isLoading && result?.isFetching,
      );

      /** Error */
      if (result?.isError) {
        setErrorMessage(result?.error?.message);
        setIsOpenErrorModal(true);
      }

      /** Success Fetching Data */
      if (!result?.isError && !result?.isLoading && !result?.isFetching) {
        const { ...tableData } = result?.data?.data;

        setStatisticsList(list => ({ ...list, [tableTitle]: tableData }));
      }
    }, [result?.isError, result?.isLoading, result?.isFetching]);
  });

  return {
    statisticsList,
    isLoading,
  };
};

export default useGetStatisticsList;
