const ediKeys = {
  all: ['EDI'],
  get204List: ({ div, from, to, sender }) => [
    ...ediKeys.all,
    'GET_204_LIST',
    div,
    from,
    to,
    sender,
  ],
  get204Detail: ({ id }) => [...ediKeys.all, 'GET_204_DETAIL', id],
  cneeList: () => [...ediKeys.all, 'GET_CNEE_LIST'],
  customerList: () => [...ediKeys.all, 'GET_CUSTOMER_LIST'],
  getMainList: ({ div, type, from, to, sender }) => [
    ...ediKeys.all,
    'GET_MAIN_LIST',
    div,
    type,
    from,
    to,
    sender,
  ],
  get322List: ({ div, from, to, receiver }) => [
    ...ediKeys.all,
    'GET_322_LIST',
    div,
    from,
    to,
    receiver,
  ],
  get204PDF: ({ id }) => [...ediKeys.all, 'GET_204_PDF', id],
};

export default ediKeys;
