import React from 'react';
import { Outlet } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import generateAddPagePermission from '../../utils/Order/Generator/generateAddPagePermission';
import generateFormDefaultValue from '../../utils/Order/Generator/generateFormDefaultValue';
import MainLayOut from '../../components/Layout/MainLayOut';
import SubMenuNavigator from '../../components/Common/SubMenuNavigator';
import { PAGE_ORDER } from '../../constants/Common/mapper';

const Order = () => {
  const subCategory = document.URL?.split('/')?.pop();
  const OrderFormController = useForm({
    shouldUnregister: true,
    defaultValues: generateFormDefaultValue({ subCategory }),
  });

  /**
   * @description
   * Permission setting of Order page
   */
  const tabsData = generateAddPagePermission();

  return (
    <MainLayOut tabsData={tabsData}>
      <div>
        <SubMenuNavigator page={PAGE_ORDER} />
        <FormProvider {...OrderFormController}>
          <form>
            <div className="ml-24">
              <Outlet />
            </div>
          </form>
        </FormProvider>
      </div>
    </MainLayOut>
  );
};

export default Order;
