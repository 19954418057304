import styled from 'styled-components';

const StyledBCDetailBillingInfoProfitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 114px;
  margin-top: 100px;
`;

export default StyledBCDetailBillingInfoProfitContainer;
