import React from 'react';
import CommonMessageModal from '../../../CommonMessageModal/CommonMessageModal';
import InUseCodeTable from './InUseCodeTable';
import CommonButton from '../../../CommonButton/CommonButton';
import StyledMasterCommonAlertMessageButton from '../../../../styles/Master/Common/AlertMessage/StyledMastercommonAlertMessageButton';
import StyledCommonErrorModalWrapper from '../../../../styles/Common/CommonErrorModal/StyledCommonErrorModalWrapper';

const InUseCodeAlertModal = ({
  isInUseModal,
  setIsInUseModal,
  getDeleteTarget,
  inUseWOList,
}) => {
  return (
    <CommonMessageModal
      setOpen={setIsInUseModal}
      open={isInUseModal}
      content={
        <div className="flex flex-col gap-y-[16px] w-[580px]">
          <div className="font-normal text-[#222222] text-[14px]">
            {getDeleteTarget?.product_code_name}
          </div>
          <StyledCommonErrorModalWrapper className="flex-col text-[#222222] leading-[14px]">
            <p>This service code is currently in use.</p>
            <p>Please remove from WO and try again.</p>
          </StyledCommonErrorModalWrapper>
          <InUseCodeTable usedContents={inUseWOList} />
        </div>
      }
      buttonContent={
        <StyledMasterCommonAlertMessageButton>
          <CommonButton onClick={() => setIsInUseModal(false)} category="gotIt">
            Got it
          </CommonButton>
        </StyledMasterCommonAlertMessageButton>
      }
      header={getDeleteTarget?.product_code}
    />
  );
};

export default InUseCodeAlertModal;
