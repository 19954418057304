import masterDefaultApi from '../../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

/**
 * Function that get Company list
 *
 * @returns server response
 */
const getCompanyList = async ({ queryObject }) => {
  try {
    const response = await masterDefaultApi.get('company', {
      params: { ...queryObject },
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getCompanyList;
