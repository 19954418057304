import masterDriverDefaultApi from '../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

/**
 * This function retrieves a list of drivers from the server.
 * @returns response
 */
const getSelectedDriver = async driverID => {
  try {
    const response = await masterDriverDefaultApi.get(`${driverID}`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getSelectedDriver;
