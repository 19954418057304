import React, { useEffect } from 'react';
import ScheduleMobileModalContentInfo from '../ScheduleMobileModalContentInfo';

const ScheduleMobileModalContent = ({ ...props }) => {
  /** Get data */
  const { mobileMemoData, memoContent, isEditable } = props;

  /** Get data setter */
  const { setMemoContent } = props;

  return (
    <div className="flex flex-col gap-[6px]">
      <div className="w-[643px]">
        {/* Info Container */}
        <ScheduleMobileModalContentInfo mobileMemoData={mobileMemoData} />
      </div>
      <div className="h-[220px] w-[643px] border-solid border-[1px] border-[#EEEEEE] flex flex-col">
        {/* Input Component */}
        <textarea
          className="w-full h-full outline-none resize-none flex-1 px-[12px] py-[9px] text-[12px] text-[#222]"
          placeholder="CONTENT"
          value={memoContent}
          onChange={e => setMemoContent(e.target.value)}
          maxLength={255}
          disabled={!isEditable}
        />
      </div>
    </div>
  );
};

export default ScheduleMobileModalContent;
