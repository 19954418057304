import React from 'react';
import dayjs from 'dayjs';

const ScheduleMobileModalContentInfoRow = ({ ...props }) => {
  /** Get data */
  const { rowData } = props;

  return (
    <div className="w-full h-[40px] flex flex-row gap-[3px]">
      <div className="w-[60px] h-full flex text-[#264B9F] text-[11px] font-medium justify-center items-center shadow drop-shadow-[0_0_20px_0_rgba(0,0,0,0.16)]">
        <div className="w-[7px] h-full bg-[#264B9F]" />
        <div className="flex-1 w-full h-full flex justify-center items-center">
          {rowData?.custom_type || '-'}
        </div>
      </div>
      <div className="w-[100px] h-full bg-[#F2F4F8] flex text-[#000] text-[11px] font-medium justify-center items-center shadow drop-shadow-[0_0_20px_0_rgba(0,0,0,0.16)]">
        {rowData?.company || '-'}
      </div>
      <div className="w-[90px] h-full bg-[#F2F4F8] flex text-[#000] text-[11px] font-medium justify-center items-center shadow drop-shadow-[0_0_20px_0_rgba(0,0,0,0.16)]">
        {rowData?.city || '-'}
      </div>
      <div className="w-[67px] h-full bg-[#F2F4F8] flex text-[#000] text-[11px] font-medium justify-center items-center shadow drop-shadow-[0_0_20px_0_rgba(0,0,0,0.16)]">
        {rowData?.state || '-'}
      </div>
      <div className="w-[114px] h-full bg-[#F2F4F8] flex text-[#000] text-[11px] font-medium justify-center items-center shadow drop-shadow-[0_0_20px_0_rgba(0,0,0,0.16)]">
        {dayjs(rowData?.sche_date)?.isValid()
          ? dayjs(rowData?.sche_date).format('MM/DD/YY')
          : '-'}
      </div>
      <div className="w-[86px] h-full bg-[#F2F4F8] flex text-[#000] text-[11px] font-medium justify-center items-center shadow drop-shadow-[0_0_20px_0_rgba(0,0,0,0.16)]">
        {dayjs(rowData?.sche_time, 'HH:mm:ss')?.isValid()
          ? dayjs(rowData?.sche_time, 'HH:mm:ss').format('HH:mm')
          : '-'}
      </div>
      <div className="w-[108px] h-full bg-[#F2F4F8] flex text-[#000] text-[11px] font-medium justify-center items-center shadow drop-shadow-[0_0_20px_0_rgba(0,0,0,0.16)]">
        {rowData?.driver || '-'}
      </div>
    </div>
  );
};

export default ScheduleMobileModalContentInfoRow;
