import React from 'react';
import CommonButton from '../../../../../../CommonButton/CommonButton';

const DualMoveModalButtonContent = ({ ...props }) => {
  const { setOpen } = props;

  return (
    <div className="w-full flex justify-center items-center">
      <CommonButton category="cancel" onClick={() => setOpen(prev => false)}>
        Close
      </CommonButton>
    </div>
  );
};

export default DualMoveModalButtonContent;
