const toggleVehicleType = ({
  division,
  vehicleType,
  setExpandedVehicleTypes,
}) => {
  setExpandedVehicleTypes(prev => ({
    ...prev,
    [`${division}-${vehicleType}`]: !prev[`${division}-${vehicleType}`],
  }));
};

export default toggleVehicleType;
