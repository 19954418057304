import FloatingFilterSelect from '../../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterSelect';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';
import { makePrettyPhoneNumber } from '../../../Common/Handler/phoneNumberHandler';
import { MASTER_COMMON_ACTIVE_OPTION } from '../../../../constants/Master/Common/masterCommonOption';
import {
  MASTER_COMPANY_TYPE,
  MASTER_COMPANY_TYPE_DETAIL,
} from '../../../../constants/Master/Company/masterCompanyStandardCodes';
import { CELL_TYPE_SWITCH } from '../../../../constants/Common/DataGrid/cellType';

const createCompanyColumnDefs = ({ categoryOption }) => {
  return [
    {
      headerName: 'CAT',
      field: 'category',
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: [{ label: 'All', value: '' }, ...categoryOption],
      },
      valueFormatter: params =>
        MASTER_COMPANY_TYPE?.[params?.data?.category] || null,
      valueGetter: params => params?.data?.category,
      width: 70,
      editable: false,
    },
    {
      headerName: 'TYPE',
      field: 'type',
      valueGetter: params =>
        MASTER_COMPANY_TYPE_DETAIL?.[params?.data?.type] || null,
      width: 90,
      editable: false,
    },
    {
      headerName: 'ID',
      field: 'company',
      width: 76,
      editable: false,
    },
    {
      headerName: 'NAME',
      field: 'business_name',
      valueGetter: params => params?.data?.business_name?.toUpperCase() || null,
      width: 150,
      editable: false,
    },
    {
      headerName: 'ADDRESS',
      field: 'address_01',
      width: 150,
      editable: false,
    },
    {
      headerName: 'CITY',
      field: 'city',
      width: 110,
      editable: false,
    },
    {
      headerName: 'ST',
      field: 'state',
      width: 35,
      editable: false,
    },
    {
      headerName: 'ZIP',
      field: 'zip',
      width: 45,
      editable: false,
    },
    {
      headerName: 'COUNTRY',
      field: 'country',
      width: 75,
      editable: false,
    },
    {
      headerName: 'CONTACT NAME',
      field: 'contact_name',
      width: 130,
      editable: false,
    },
    {
      headerName: 'CELL #',
      field: 'cell_no',
      valueGetter: gridParam =>
        makePrettyPhoneNumber({ phone: gridParam?.data?.cell_no }),
      width: 95,
      editable: false,
    },
    {
      headerName: 'OFFICE #',
      field: 'office_no',
      valueGetter: gridParam =>
        makePrettyPhoneNumber({ phone: gridParam?.data?.office_no }),
      width: 95,
      editable: false,
    },
    {
      headerName: 'EMAIL',
      field: 'email',
      width: 150,
      editable: false,
    },
    {
      headerName: 'FAX #',
      field: 'fax',
      valueGetter: gridParam =>
        makePrettyPhoneNumber({ phone: gridParam?.data?.fax }),
      width: 95,
      editable: false,
    },
    {
      headerName: 'QB ID',
      field: 'qb_id',
      width: 50,
      editable: false,
    },
    {
      headerName: 'TRACKING ID',
      field: 'auto_tracking',
      width: 95,
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: MASTER_COMMON_ACTIVE_OPTION,
      },
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_SWITCH,
          disabled: true,
        }),
      valueGetter: params => params.data.auto_tracking,
      editable: false,
    },
    {
      headerName: 'ACTIVE',
      field: 'active',
      width: 65,
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: MASTER_COMMON_ACTIVE_OPTION,
      },
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_SWITCH,
          disabled: true,
        }),
      valueGetter: params => params.data.active,
      editable: false,
    },
    {
      headerName: 'CREDIT HOLD',
      field: 'credit_hold',
      width: 97,
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: MASTER_COMMON_ACTIVE_OPTION,
      },
      cellRendererSelector: gridParam =>
        MASTER_COMPANY_TYPE?.[gridParam?.data?.category] === 'Customer' &&
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_SWITCH,
          disabled: true,
        }),
      valueGetter: params => params.data.credit_hold,
      editable: false,
    },
  ];
};

export default createCompanyColumnDefs;
