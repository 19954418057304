import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SUCCESS_SAVE } from '../../../../constants/Common/successMessage';
import postProductCode from '../../../../services/Admin/DivConf/Post/postProductCode';
import useControlToast from '../../../Common/Toast/useControlToast';
import adminKeys from '../../keys';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';

const usePostProductCodeMutation = ({
  setIsMessageOpen,
  setMessage,
  setIsSuccess,
  div,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  return useMutation({
    mutationFn: postProductCode,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: adminKeys?.productCodeList({ div }),
      });
      addToast({
        message: SUCCESS_SAVE,
      });
    },
    onError: error => {
      queryClient.refetchQueries({
        queryKey: adminKeys?.productCodeList({ div }),
      });
      setIsSuccess(false);
      setMessage(error.message);
      setIsMessageOpen(true);
    },
  });
};

export default usePostProductCodeMutation;
