import React from 'react';
import StyledAPModalTableContentHeaderWrapper from '../../../../styles/Dispatch/APModal/StyledAPModalTableContentHeaderWrapper';
import StyledAPModalTableContentHeaderDataWrapper from '../../../../styles/Dispatch/APModal/StyledAPModalTableContentHeaderDataWrapper';
import APModalTableContentHeaderCell from '../APModalTableContentHeaderCell';
import { PLUS_ICON } from '../../../../constants/Order/Common/path';

const APModalTableContentHeader = ({ ...props }) => {
  /** Get Data */
  const { apTableContent } = props;

  /** Data Setter */
  const { setAPTableContent } = props;
  return (
    <StyledAPModalTableContentHeaderWrapper>
      <StyledAPModalTableContentHeaderDataWrapper>
        <APModalTableContentHeaderCell
          customStyle={{
            width: '138px',
            borderRight: '1px solid #eeeeee',
          }}
        >
          Service Code
        </APModalTableContentHeaderCell>
        <APModalTableContentHeaderCell
          customStyle={{
            width: '212px',
            borderRight: '1px solid #eeeeee',
          }}
        >
          Service Code Name
        </APModalTableContentHeaderCell>
        <APModalTableContentHeaderCell
          customStyle={{
            width: '104px',
          }}
        >
          AMT ($)
        </APModalTableContentHeaderCell>
      </StyledAPModalTableContentHeaderDataWrapper>
      <APModalTableContentHeaderCell
        customStyle={{
          width: '48px',
          backgroundColor: '#fff',
        }}
      >
        {apTableContent && apTableContent?.length > 0 ? null : (
          <div
            className="cursor-pointer"
            onClick={() => {
              /** Add new empty ap row */
              setAPTableContent(prev => {
                return [
                  ...prev,
                  {
                    productCode: '',
                    productCodeName: '',
                    amount: 0,
                  },
                ];
              });
            }}
          >
            <img alt="apAddBtn" src={PLUS_ICON} />
          </div>
        )}
      </APModalTableContentHeaderCell>
    </StyledAPModalTableContentHeaderWrapper>
  );
};

export default APModalTableContentHeader;
