import mobileAdminDefaultApi from '../../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

const sendPODMessage = async ({ data }) => {
  try {
    const response = await mobileAdminDefaultApi.post(`pod-send-msg`, data);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default sendPODMessage;
