import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SUCCESS_UPDATE } from '../../../../constants/Common/successMessage';
import patchCompleteBooking from '../../../../services/Dispatch/Export/Booking/patch/patchCompleteBooking';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';
import useControlToast from '../../../Common/Toast/useControlToast';
import dispatchKeys from '../../../Dispatch2/keys';

const usePatchCompleteBooking = ({
  setIsMessageModalOpen,
  setMessageModalMessage,
  updateBookingController,
  setIsUpdateBookingModalOpen,
  setTabIndexOpen,
  setBookingSeq,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();
  const mutator = useMutation({
    mutationFn: patchCompleteBooking,
    refetchOnWindowFocus: false,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: (data, parameter) => {
      queryClient?.invalidateQueries({ queryKey: dispatchKeys?.list() });
      queryClient?.invalidateQueries({ queryKey: dispatchKeys?.count() });
      queryClient?.invalidateQueries({ queryKey: dispatchKeys?.bookingAll });
      addToast({ message: SUCCESS_UPDATE });
      setIsUpdateBookingModalOpen(false);
      setTabIndexOpen(0);
      setBookingSeq(null);
      updateBookingController.reset();
    },
    onError: error => {
      setIsMessageModalOpen(true);
      setMessageModalMessage(error.message);
    },
  });
  return mutator;
};

export default usePatchCompleteBooking;
