const handleScheduleAcctInputPrevent = ({
  scheduleData,
  isEditable,
  completed,
}) => {
  /** Get data */
  const { driver, sche_date: scheDate, sche_time: scheTime } = scheduleData;

  if (!isEditable || !!completed) return true;

  /** If driver is not assigned, acct-in or acct-out component should not be updated */
  if (!driver) return true;

  /** If schedule date and schedule time is not assigned, actual-in and actual-out should not be able to assigned */
  if (!scheDate || !scheTime) return true;

  return false;
};

export default handleScheduleAcctInputPrevent;
