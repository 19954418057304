import React, { useEffect, useState } from 'react';
import DualMoveModalContentFilter from './DualMoveModalContentFilter';
import viewDualMoveContentFilter from '../../../../../../../utils/BCDetail/DetailInfo/DualMove/Handler/viewDualMoveContentFilter';
import DualMoveModalContentJob from './DualMoveModalContentJob';
import DualMoveModalContentResult from './DualMoveModalContentResult';
import viewDualMoveContentResult from '../../../../../../../utils/BCDetail/DetailInfo/DualMove/Handler/viewDualMoveContentResult';
import useGetDualJobPullOut from '../../../../../../../hooks/BCDetail/DetailInfo/DualMove/useGetDualJobPullOut';
import useGetDualJob from '../../../../../../../hooks/BCDetail/DetailInfo/DualMove/useGetDualJob';
import CommonErrorModal from '../../../../../../CommonErrorModal/CommonErrorModal';
import DualMoveAPModal from '../../DualMoveAPModal';
import useHandleAPModal from '../../../../../../../hooks/BCDetail/DetailInfo/DualMove/useHandleAPModal';

const DualMoveModalContent = ({ ...props }) => {
  const { dualMoveRowData, completed } = props;

  /** Message modal data */
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [messageModalMessage, setMessageModalMessage] = useState('');

  const messageModalController = {
    setMessageModalOpen,
    setMessageModalMessage,
  };

  const { dualJob } = useGetDualJob({
    loc: dualMoveRowData?.seq,
    messageModalController,
  });
  const { dualJobPullOut, setFilter } = useGetDualJobPullOut({
    dualMoveRowData,
  });

  /** AP modal data */
  const apModalController = useHandleAPModal();
  const { apModalOpen, setAPModalOpen } = apModalController;

  return (
    <>
      {dualJob?.rtnJob && (
        <>
          <div className="w-full h-full flex justify-center items-center pl-[40px]">
            {viewDualMoveContentFilter({
              dualMoveRowData,
              dualJob,
              completed,
            }) && (
              <DualMoveModalContentFilter setFilter={setFilter} {...props} />
            )}
          </div>
          <div className="flex justify-center flex-col w-[884px] h-fit">
            {dualJob?.rtnJob && (
              <DualMoveModalContentJob
                job={dualJob?.rtnJob}
                {...messageModalController}
                {...props}
              />
            )}
          </div>
          <div className="flex justify-center flex-col w-[884px] h-fit">
            {dualJob?.poJob && (
              <DualMoveModalContentJob
                job={dualJob?.poJob}
                {...messageModalController}
                {...props}
              />
            )}
          </div>
          {viewDualMoveContentResult({
            dualMoveRowData,
            dualJob,
            completed,
          }) && (
            <div className="flex items-center flex-col w-[884px] max-h-[160px] overflow-y-auto mt-[6px] gap-[2px]">
              <DualMoveModalContentResult
                dualJobPullOut={dualJobPullOut}
                apModalController={apModalController}
                {...props}
              />
            </div>
          )}
          {apModalOpen && (
            <DualMoveAPModal
              open={apModalOpen}
              setOpen={setAPModalOpen}
              rtnJob={dualJob?.rtnJob}
              apModalController={apModalController}
              messageModalController={messageModalController}
            />
          )}
        </>
      )}
      {messageModalOpen && (
        <CommonErrorModal
          open={messageModalOpen}
          setIsOpen={setMessageModalOpen}
          message={messageModalMessage}
        />
      )}
    </>
  );
};

export default DualMoveModalContent;
