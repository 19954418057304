import { NOT_REPLACE_HEADER_COLUMNS_LIST } from '../../../../constants/Dispatch/Common/gridGroupHeaders';

const reconstructColumnList = ({ resultList }) => {
  const columnList = [];
  const notReplaceHeaderList = NOT_REPLACE_HEADER_COLUMNS_LIST || [];

  resultList.forEach(item => {
    if (typeof item === 'string') {
      columnList.push(item);
    } else if (typeof item === 'object' && item !== null) {
      const [header, childrenColumns] = Object.entries(item)[0];
      const isReplaceColumn = notReplaceHeaderList.includes(header);

      childrenColumns.forEach(childColumn => {
        let column;
        if (isReplaceColumn) {
          column = childColumn;
        } else {
          column = `${header}_${childColumn}`;
        }
        columnList.push(column);
      });
    }
  });

  return columnList;
};

export default reconstructColumnList;
