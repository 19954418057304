const iconColorGenerator = ({ selected, iconColor }) => {
  let color;
  if (selected) {
    color = '#264B9F';
  } else {
    color = iconColor;
  }
  return color;
};

export default iconColorGenerator;
