import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import defaultInputRules from '../../../utils/Common/Input/defaultInputRules';

const CommonSelect = ({ ...props }) => {
  /**
   * @description
   * Get react-hook-form object from useFormContext.
   * @control Must-have elements for getting values from a form.
   * @unregister Allows to unregister a single input or an array of inputs.
   * @setValue Setter value of input.
   * @formState Object containing information about the form.
   */
  const {
    control,
    unregister,
    setValue,
    formState: { errors: formErrors },
  } = useFormContext();

  /**
   * @textCenter {string} - Text align of input.
   * @textColor {string} - Text color of input.
   * @backgroundColor {string} - Background color of input.
   * @option {array} - Option of select.
   * @label {string} - Label of input.
   * @disabled {boolean} - Disable input.
   * @required {boolean} - Required input.
   */

  const [textCenter, setTextCenter] = useState(props?.textCenter || false);
  useEffect(() => {
    /**
     * If text center changed, component text center would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.textCenter !== undefined && props?.textCenter !== null) {
      setTextCenter(props?.textCenter);
    }
  }, [props?.textCenter]);

  const [textColor, setTextColor] = useState(props?.textColor || '#666');
  useEffect(() => {
    /**
     * If text color changed, component text color would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.textColor !== undefined && props?.textColor !== null) {
      setTextColor(props?.textColor);
    }
  }, [props?.textColor]);

  const [backgroundColor, setBackgroundColor] = useState(
    props?.backgroundColor || '#F2F4F8',
  );
  useEffect(() => {
    /**
     * If background color changed, component background color would be changed too.
     * Undefined, Null exception handling.
     */
    if (
      props?.backgroundColor !== undefined &&
      props?.backgroundColor !== null
    ) {
      setBackgroundColor(props?.backgroundColor);
    }
  }, [props?.backgroundColor]);

  const [option, setOption] = useState(props?.option || []);
  useEffect(() => {
    if (props?.option) {
      setOption(props?.option);
    }
  }, [props?.option]);

  const [label, setLabel] = useState(props?.label);

  // Set default input value as empty string ('').
  const [defaultValue, setDefaultValue] = useState(props?.defaultValue || '');
  useEffect(() => {
    /**
     * If default value changed, component default value would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.defaultValue !== undefined && props?.defaultValue !== null) {
      setDefaultValue(props?.defaultValue);
      setValue(props?.inputName, props?.defaultValue);
    }
  }, [props?.defaultValue]);

  // Set disabled for input. Default is false.
  const [disabled, setDisabled] = useState(props?.disabled || false);
  useEffect(() => {
    /**
     * If default disabled value changed, component disabled value would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.disabled !== undefined && props?.disabled !== null) {
      setDisabled(props?.disabled);
    }
  }, [props?.disabled]);

  // Set default required for inpt with false.
  const [required, setRequired] = useState(props?.required || false);
  useEffect(() => {
    /**
     * If default required value changed, component required value would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.required !== undefined && props?.required !== null) {
      setRequired(props?.required);
    }
  }, [props?.required]);

  useEffect(() => {
    /**
     * Unregister input when component unmount.
     * Unregister single input only.
     */
    return () => unregister(props?.inputName);
  }, []);

  const [disabledColor, setDisabledColor] = useState(
    props?.disabledColor || false,
  );
  useEffect(() => {
    /**
     * If default disabled color changed, component disabled color would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.disabledColor !== undefined && props?.disabledColor !== null) {
      setDisabledColor(props?.disabledColor);
    }
  }, [props?.disabledColor]);

  /**
   * Render select component.
   * @control - Must-have elements for getting values from a form.
   * @name - Name of input.
   * @defaultValue - Default value of input.
   * @rules - Rules of input.
   * @render - Render target select component.
   * - field : Object containing field props, like name, value, onChange, onBlur.
   * - fieldState : Object containing field state, like error, invalid, etc.
   */
  return (
    <Controller
      control={control}
      name={props?.inputName}
      defaultValue={defaultValue}
      rules={defaultInputRules({ required, ...props })}
      render={({
        field: { ref, onChange, value, ...field },
        fieldState: { invalid, error },
      }) => {
        // Package of handler props
        const handlerProps = {
          onChange,
          value,
          ...field,
          ...props,
        };

        // Render Control Input Component
        return (
          <div className={`${props?.width ? `${props?.width}` : 'w-full'}`}>
            <Select
              {...field}
              inputRef={ref}
              error={invalid}
              value={value}
              /**
               * If renderValue is true, render option's value.
               * If not, just render option's label
               * ex) <Select renderValue={true} />
               * */
              renderValue={selected =>
                props?.renderValue
                  ? selected
                  : option?.find(item => item?.value === selected)?.label ||
                    selected
              }
              /**
               * onChange Handler. Only handlers of parameters work.
               * To change value, should use Controller field's onChange changer.
               * ex)
               * (tempValue) => {
               *    onChange(tempValue);
               * }
               */
              onChange={(e, selectProps) =>
                props?.onChangeHandler?.({
                  e,
                  selectProps,
                  ...handlerProps,
                })
              }
              className={`border-solid border-[#D9D9D9] rounded-none h-[30px] text-[11px] w-full ${
                disabled ? '' : 'bg-white'
              }`}
              sx={{
                backgroundColor: disabled
                  ? disabledColor || '#DDE2EC'
                  : 'transparent',
                // Select Arrow Icon
                fontWeight: '500',
                '.MuiSvgIcon-root': {
                  fontSize: '16px',
                  color: !disabled && '#264B9F !important',
                  zIndex: '999',
                },
                '.MuiSelect-select': {
                  textAlign: `${textCenter ? 'center' : ''}`,
                  paddingRight: '15px',
                  borderRadius: '0px',
                  zIndex: '999',
                  color: `${textColor} !important`,
                  '&:focus': {
                    color: `black !important`,
                  },
                },
                '&.MuiInputBase-root': {
                  '&:not(.Mui-focused):not(.Mui-disabled):hover fieldset':
                    !formErrors?.[props?.inputName]?.message && {
                      border: '1px solid #8E9396 !important',
                    },
                  '&.Mui-focused fieldset': {
                    border: '1.2px solid #264B9F !important',
                    boxShadow: '0px 0px 4px #8BBCE9 !important',
                    backgroundColor: '#fff !important',
                  },
                  '&.Mui-error fieldset': {
                    border: '1.2px solid #C24D4D !important',
                    backgroundColor: '#fff !important',
                  },
                  '&.Mui-disabled fieldset': {
                    backgroundColor: 'unset !important',
                    borderRadius: '0px',
                  },
                  '&.Mui-disabled .MuiSelect-select': {
                    WebkitTextFillColor: '#222 !important',
                  },
                },
                fieldset: {
                  border: 'none !important',
                  borderRadius: '0px',
                  boxShadow: '0px 0.5px 1px rgba(0, 0, 0, 0.25)',
                  backgroundColor: `${backgroundColor} !important`,
                },
              }}
              defaultValue={props?.defaultValue || props?.option?.[0]?.value}
              disabled={disabled}
              // required={required}
              MenuProps={{
                disableScrollLock: true,
                style: { zIndex: 9999 },
              }}
              IconComponent={ExpandMoreIcon}
            >
              {option?.map((item, index) => {
                return (
                  <MenuItem
                    value={item?.value}
                    key={String(index + 1)}
                    className="text-xs"
                  >
                    {item?.label}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        );
      }}
    />
  );
};

export default CommonSelect;
