import React from 'react';
import CommonButton from '../../CommonButton/CommonButton';
import handleApplyingColumn from '../../../utils/Common/CustomColumnModal/Handler/handleApplyingColumn';

const CustomizeColsModalButtonContent = ({
  setOpenModal,
  appliedColumnList,
  onApplyClick,
  setErrorModalOpen,
  setErrorMessage,
}) => {
  return (
    <div className="flex justify-center mt-[-7px]">
      <div className="mr-[20px]">
        <CommonButton category="cancel" onClick={() => setOpenModal?.(false)}>
          Cancel
        </CommonButton>
      </div>
      <CommonButton
        type="button"
        category="approval"
        preventDupClick
        onClick={({ buttonRef }) =>
          handleApplyingColumn({
            appliedColumnList,
            onApplyClick,
            buttonRef,
            setErrorModalOpen,
            setErrorMessage,
          })
        }
      >
        Apply Columns
      </CommonButton>
    </div>
  );
};

export default CustomizeColsModalButtonContent;
