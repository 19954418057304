import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import divBasicDefaultApi from '../../divApi';

const patchTimeZone = async ({ div, timezone }) => {
  try {
    const response = await divBasicDefaultApi.patch(`${div}/timezone`, {
      timezone,
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchTimeZone;
