import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import woAPI from '../../woAPI';

const patchCompleteWO = async ({ wo }) => {
  try {
    const response = await woAPI.patch(`${wo}/complete`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchCompleteWO;
