import React from 'react';
import CommonButton from '../../../CommonButton/CommonButton';

const SavedListButton = ({ ...props }) => {
  /** Get props data */
  const { selectedList } = props;

  /** Data Setter */
  const { setOpen, setSelectedList } = props;

  return (
    <div className="w-full flex justify-center items-center gap-[20px]">
      <CommonButton category="cancel" onClick={() => setOpen(false)}>
        Cancel
      </CommonButton>
      <CommonButton
        category="approval"
        disabled={!selectedList}
        onClick={() => {
          setSelectedList(selectedList);
          setOpen(false);
        }}
      >
        Open List
      </CommonButton>
    </div>
  );
};

export default SavedListButton;
