import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import bookingDefaultAPI from '../../bookingAPI';

const patchUpdateBooking = async ({ bookingSeq, bookingForm }) => {
  try {
    const response = await bookingDefaultAPI.patch(
      `detail/save/${bookingSeq}`,
      bookingForm,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchUpdateBooking;
