import masterServiceCodeDefaultApi from '../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

const createServiceCode = async ({ data, division }) => {
  try {
    const response = await masterServiceCodeDefaultApi.post(
      `productcode?div=${division}`,
      data,
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default createServiceCode;
