const checkMobileJobInProgress = ({ ...props }) => {
  const { pageCategory, jobSet, jobSetIdx, locationSet } = props;

  if (
    jobSet &&
    jobSet?.[jobSetIdx] &&
    jobSet?.[jobSetIdx]?.status !== 'JS0001'
  ) {
    return true;
  }

  if (
    ['import', 'brokerage', 'bare-chassis'].includes(pageCategory) &&
    locationSet?.length === 1 &&
    jobSet &&
    jobSet?.[jobSet?.length - 1]?.status !== 'JS0001'
  ) {
    return true;
  }

  return false;
};

export default checkMobileJobInProgress;
