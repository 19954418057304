import { GRID_MOD_EDIT } from '../../../../constants/Common/DataGrid/setting';

const getModeType = ({ columnDefs, isEditable }) => {
  const editList = [];
  columnDefs.forEach(col => {
    if (col?.children) {
      return col?.children?.map(child => {
        return editList.push(child?.editable === undefined);
      });
    }
    return editList.push(col?.editable === undefined);
  });

  return editList.some(col => col) && isEditable ? GRID_MOD_EDIT : undefined;
};

export default getModeType;
