import styled from 'styled-components';

const StyledBCDetailBillingInfoCommonHeaderQBContainer = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c7d4c8;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
`;

export default StyledBCDetailBillingInfoCommonHeaderQBContainer;
