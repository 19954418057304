import { CELL_TYPE_BUTTON } from '../../Common/DataGrid/cellType';
import cellRendererGenerator from '../../../utils/Common/Datagrid/Generator/cellRendererGenerator';

const DOCUMENT_BUTTON_DEF = {
  cellRendererSelector: gridParam => {
    return cellRendererGenerator({
      gridParam,
      cellType: CELL_TYPE_BUTTON,
      customStyle: {
        size: 'small',
      },
      content: 'PDF',
      category: 'pdf',
      disabled: !gridParam.data.doc,
      onClickHandler: e => {
        window.open(gridParam.data.doc, '_blank');
      },
    });
  },
  valueGetter: params => {
    return params.data.doc ? 'Yes' : 'No';
  },
};

export default DOCUMENT_BUTTON_DEF;
