import {
  SUB_ORDER_TYPE_BROKERAGE,
  SUB_ORDER_TYPE_EXPREG,
  SUB_ORDER_TYPE_EXPSHUTTLE,
  SUB_ORDER_TYPE_IMPREG,
  SUB_ORDER_TYPE_IMPSHUTTLE,
  SUB_ORDER_TYPE_VAN,
} from '../../../constants/Order/Common/basic';
import {
  CHASSIS_PROPERTY,
  CONTAINER_PROPERTY,
  TRAILER_PROPERTY,
} from '../../../constants/Order/Form/formItem';

/**
 * @description
 * Function to generate equip property according to sub category
 * equip property = container(trailer, chassis) info box property
 * @param {string} subCategory
 * Sub category of order
 * @returns {list}
 * Equip property list
 */
const generateEquipProperty = ({ subCategory }) => {
  if (
    [
      SUB_ORDER_TYPE_IMPREG,
      SUB_ORDER_TYPE_IMPSHUTTLE,
      SUB_ORDER_TYPE_EXPREG,
      SUB_ORDER_TYPE_EXPSHUTTLE,
    ].includes(subCategory)
  )
    return CONTAINER_PROPERTY;
  if ([SUB_ORDER_TYPE_VAN, SUB_ORDER_TYPE_BROKERAGE].includes(subCategory))
    return TRAILER_PROPERTY;
  return CHASSIS_PROPERTY;
};

export default generateEquipProperty;
