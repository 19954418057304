import { useQuery } from '@tanstack/react-query';
import commonKeys from './keys';
import getAutoCompleteAddr from '../../services/Common/Common/getAutoCompleteAddr';

const useGetAutoCompleteAddr = addr => {
  const { data, isPending } = useQuery({
    queryKey: commonKeys?.addrInfo(),
    queryFn: () => getAutoCompleteAddr(addr),
  });

  return { data, isPending };
};

export default useGetAutoCompleteAddr;
