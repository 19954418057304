/**
 * @description
 * Constants for Dispatch main categories
 */
export const DISPATCH_TYPE_IMPORT = 'M';
export const DISPATCH_TYPE_EXPORT = 'X';
export const DISPATCH_TYPE_VAN = 'V';
export const DISPATCH_TYPE_BROKERAGE = 'B';
export const DISPATCH_TYPE_BOOKING = 'booking';

export const DISPATCH_TYPE_UNAVAILABLE = 'unava';
export const DISPATCH_TYPE_AVAILABLE = 'ava';
export const DISPATCH_TYPE_DELIVERY = 'dilv';
export const DISPATCH_TYPE_RETURN = 'rtn';

export const DISPATCH_PAGE_IMPORT_UNAVAILABLE = 'imp_unava';
export const DISPATCH_PAGE_IMPORT_AVAILABLE = 'imp_ava';
export const DISPATCH_PAGE_IMPORT_DELIVERY = 'imp_dilv';
export const DISPATCH_PAGE_IMPORT_RETURN = 'imp_rtn';

export const DISPATCH_PAGE_EXPORT_BOOKING = 'exp_booking';
export const DISPATCH_PAGE_EXPORT_UNAVAILABLE = 'exp_unava';
export const DISPATCH_PAGE_EXPORT_AVAILABLE = 'exp_ava';
export const DISPATCH_PAGE_EXPORT_DELIVERY = 'exp_dilv';
export const DISPATCH_PAGE_EXPORT_RETURN = 'exp_rtn';

export const DISPATCH_PAGE_VAN_AVAILABLE = 'van_ava';
export const DISPATCH_PAGE_VAN_DELIVERY = 'van_dilv';
export const DISPATCH_PAGE_VAN_RETURN = 'van_rtn';

export const DISPATCH_PAGE_BROKERAGE_AVAILABLE = 'bkg_ava';
export const DISPATCH_PAGE_BROKERAGE_DELIVERY = 'bkg_dilv';
export const DISPATCH_PAGE_BROKERAGE_RETURN = 'bkg_rtn';
