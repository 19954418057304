import commonDefaultAPI from '../../../Common/api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

const getCompanyList = async ({ category }) => {
  try {
    const response = await commonDefaultAPI.get(
      `company-list?category=${category}`,
    );
    return response?.data;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getCompanyList;
