import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import autoCompleteFilterOptionsHandler from '../../../utils/Common/Input/autoCompleteFilterOptionsHandler';
import defaultInputRules from '../../../utils/Common/Input/defaultInputRules';

const CommonAutoComplete = ({ ...props }) => {
  /**
   * @description
   * Get react-hook-form object from useFormContext.
   * @control Must-have elements for getting values from a form.
   * @unregister Allows to unregister a single input or an array of inputs.
   * @setValue Setter value of input.
   * @formState Object containing information about the form.
   */
  const {
    control,
    unregister,
    setValue,
    formState: { errors: formErrors },
  } = useFormContext();

  /**
   * @option {array} - Option of select.
   * @maxLength {number} - Max length of input.
   * @label {string} - Label of input.
   * @disabled {boolean} - Disable input.
   * @required {boolean} - Required input.
   */
  const [textCenter, setTextCenter] = useState(props?.textCenter || false);
  useEffect(() => {
    /**
     * If text center changed, component text center would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.textCenter !== undefined && props?.textCenter !== null) {
      setTextCenter(props?.textCenter);
    }
  }, [props?.textCenter]);

  const [backgroundColor, setBackgroundColor] = useState(
    props?.backgroundColor || '#F2F4F8',
  );
  useEffect(() => {
    /**
     * If background color changed, component background color would be changed too.
     * Undefined, Null exception handling.
     */
    if (
      props?.backgroundColor !== undefined &&
      props?.backgroundColor !== null
    ) {
      setBackgroundColor(props?.backgroundColor);
    }
  }, [props?.backgroundColor]);

  const [textColor, setTextColor] = useState(props?.textColor || '#666');
  useEffect(() => {
    /**
     * If text color changed, component text color would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.textColor !== undefined && props?.textColor !== null) {
      setTextColor(props?.textColor);
    }
  }, [props?.textColor]);

  // Select option of component. Default is empty list.
  const [option, setOption] = useState(props?.option || []);
  useEffect(() => {
    /**
     * If option changed, component options would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.option !== undefined && props?.option !== null) {
      setOption(props?.option);
    }
  }, [props?.option]);

  // Set default max length of input as 20.
  const [maxLength, setMaxLength] = useState(props?.maxLength || 20);

  const [label, setLabel] = useState(props?.label);

  // Set default input value as empty string ('').
  const [defaultValue, setDefaultValue] = useState(props?.defaultValue || '');
  useEffect(() => {
    /**
     * If default value changed, component default value would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.defaultValue !== undefined && props?.defaultValue !== null) {
      setDefaultValue(props?.defaultValue);
      setValue(props?.inputName, props?.defaultValue);
    }
  }, [props?.defaultValue]);

  // Set disabled for input. Default is false.
  const [disabled, setDisabled] = useState(props?.disabled || false);
  useEffect(() => {
    /**
     * If default disabled value changed, component disabled value would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.disabled !== undefined && props?.disabled !== null) {
      setDisabled(props?.disabled);
      if (props?.disabled) {
        setBackgroundColor(props?.disabledColor || '#DDE2EC');
      }
    }
  }, [props?.disabled]);

  // Set default required for inpt with false.
  const [required, setRequired] = useState(props?.required || false);
  useEffect(() => {
    /**
     * If default required value changed, component required value would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.required !== undefined && props?.required !== null) {
      setRequired(props?.required);
    }
  }, [props?.required]);

  useEffect(() => {
    // Unregister auto-complete when component unmount.
    return () => unregister(props?.inputName);
  }, []);

  const [disabledColor, setDisabledColor] = useState(
    props?.disabledColor || false,
  );

  useEffect(() => {
    /**
     * If default disabled color changed, component disabled color would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.disabledColor !== undefined && props?.disabledColor !== null) {
      setDisabledColor(props?.disabledColor);
      if (disabled) {
        setBackgroundColor(props?.disabledColor || '#DDE2EC');
      }
    }
  }, [props?.disabledColor]);

  // Set default loading for input. Default is false.
  const [isLoading, setIsLoading] = useState(props?.isLoading || false);
  useEffect(() => {
    /**
     * If default loading value changed, component loading value would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.isLoading !== undefined && props?.isLoading !== null) {
      setIsLoading(props?.isLoading);
    }
  }, [props?.isLoading]);

  /**
   * Render Auto-Complete component.
   * @control - Must-have elements for getting values from a form.
   * @name - Name of input.
   * @defaultValue - Default value of input.
   * @rules - Rules of input - Check constraints.
   * @render - Render target input component.
   * - field : Object containing field props, like name, value, onChange, onBlur.
   * - fieldState : Object containing field state, like error, invalid, etc.
   */
  return (
    <Controller
      control={control}
      name={props?.inputName}
      defaultValue={defaultValue}
      rules={defaultInputRules({
        maxLength,
        required,
        ...props,
      })}
      render={({
        field: { ref, onChange, value, ...field },
        fieldState: { invalid, error },
      }) => {
        // Package of handler props
        const handlerProps = {
          onChange,
          value,
          maxLength,
          ...field,
          ...props,
        };

        // Render Control Input Component
        return (
          <div className={`${props?.width ? `${props?.width}` : 'w-full'}`}>
            <Autocomplete
              {...field}
              forcePopupIcon={false}
              value={value}
              filterOptions={(options, params) =>
                autoCompleteFilterOptionsHandler({ options, params })
              }
              options={option || []}
              isOptionEqualToValue={(paramOption, paramValue) =>
                paramOption.value === paramValue.value ||
                paramOption.value === paramValue
              }
              noOptionsText={isLoading ? 'Loading...' : 'No options'}
              sx={{
                input: {
                  textAlign: `${textCenter ? 'center' : ''}`,
                  paddingRight: '15px',
                  borderRadius: '0px',
                  zIndex: 1,
                  color: `${textColor} !important`,
                  '&:focus': {
                    color: `black !important`,
                  },
                },
                '&.MuiAutocomplete-root': {
                  width: '100% !important',
                },
                '.MuiInputBase-root': {
                  padding: `${
                    textCenter ? '0 15px 0 0 !important' : `0 0 0 0 !important`
                  }`,
                  ':hover': {
                    padding: '0 15px 0 0 !important',
                  },
                  borderRadius: '5px',
                  fontWeight: '500',
                  '&:not(.Mui-focused):not(.Mui-disabled):hover fieldset':
                    !formErrors?.[props?.inputName]?.message && {
                      borderColor: '#8E9396 !important',
                    },
                  '&.Mui-focused fieldset': {
                    border: '1.2px solid #264B9F !important',
                    boxShadow: '0px 0px 4px 0px #8BBCE9 !important',
                  },
                  '&.Mui-focused': {
                    '.MuiAutocomplete-endAdornment': {
                      display: 'none',
                    },
                  },
                  '&.Mui-error fieldset': {
                    border: '1.2px solid #C24D4D !important',
                  },
                  '&.Mui-disabled input': {
                    WebkitTextFillColor: '#222 !important',
                  },
                },
                '.MuiInputBase-root > .MuiInputBase-input': {
                  padding: '0 0 0 14px',
                  height: '30px',
                  fontSize: '12px',
                },
                '.MuiSvgIcon-root': {
                  fontSize: '16px',
                  color: !disabled && '#264B9F !important',
                  zIndex: '999',
                },
                '&.MuiAutocomplete-popper': {
                  zIndex: 9999,
                },
                '.MuiAutocomplete-endAdornment': {
                  right: '0 !important',
                },
                fieldset: {
                  borderColor: '#D9D9D9 !important',
                  border: 'none !important',
                  borderRadius: '0px',
                  boxShadow: '0px 0.5px 1px rgba(0, 0, 0, 0.25)',
                  backgroundColor: `${backgroundColor} !important`,
                },
              }}
              /**
               * onChange Handler. Only handlers of parameters work.
               * To change value, should use Controller field's onChange changer.
               * ex)
               * (tempValue) => {
               *    onChange(tempValue);
               * }
               */
              onChange={(e, selectProps, reason) => {
                props?.onChangeHandler?.({
                  e,
                  selectProps,
                  ...handlerProps,
                });
                if (reason === 'clear') onChange?.('');
              }}
              /**
               * onInputChange Handler. Only handlers of parameters work.
               * Unlike the onChange handler, the value is entered even if there is no choice.
               * To change value, should use Controller field's onChange changer.
               * ex)
               * (tempValue) => {
               *    onChange(tempValue);
               * }
               */
              onInputChange={(e, selectProps, reason) => {
                props?.onInputChangeHandler?.({
                  e,
                  selectProps,
                  ...handlerProps,
                });
                if (reason === 'clear') onChange?.('');
              }}
              defaultValue={props?.defaultValue || defaultValue || ''}
              /**
               * Set readOnly property value with disabled value.
               * Need to discuss if separation is necessary.
               * disabled = readOnly.
               */
              disabled={disabled}
              readOnly={disabled}
              // required={required}
              renderInput={params => (
                <TextField
                  {...params}
                  inputRef={ref}
                  error={invalid}
                  sx={{
                    borderStyle: 'solid',
                    borderColor: '#D9D9D9',
                    borderRadius: '5px',
                    backgroundColor: disabled ? '#DDE2EC' : 'white',
                  }}
                  placeholder={props?.placeholder || ''}
                  inputProps={{ ...params.inputProps, maxLength }}
                />
              )}
              popupIcon={<ExpandMoreIcon />}
              componentsProps={{
                popper: {
                  style: { width: 'fit-content' },
                  placement: 'bottom-start',
                  sx: {
                    zIndex: 9999,
                    '.MuiAutocomplete-option': {
                      fontSize: '12px !important',
                    },
                    '.MuiAutocomplete-noOptions': {
                      fontSize: '12px !important',
                    },
                  },
                },
              }}
            />
          </div>
        );
      }}
    />
  );
};

export default CommonAutoComplete;
