import styled from 'styled-components';

const StyledAPModalTableContentRowDataWrapper = styled.div`
  width: 454px;
  height: 31px;
  background-color: white;
  border: 1px solid #eeeeee;
  border-top: none;
  display: flex;
  flex-direction: row;
`;

export default StyledAPModalTableContentRowDataWrapper;
