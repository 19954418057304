import { acctDefaultAPI } from '../../api';
import generateDuplicateError from '../../../../utils/Acct/Common/Generator/generateDuplicateError';

const postChassisInvoice = async ({ postData, duplicateCheckFields }) => {
  try {
    const response = await acctDefaultAPI.post('chassis-recon', postData);
    return response;
  } catch (error) {
    throw generateDuplicateError({ error, duplicateCheckFields });
  }
};

export default postChassisInvoice;
