import dayjs from 'dayjs';

const generateNavContent = ({ cycle, date }) => {
  const isValidDate = date && dayjs?.(date)?.isValid();

  const currentDate = isValidDate ? dayjs?.(date) : dayjs?.();

  if (cycle === 'weekly') {
    const prevDate = currentDate.subtract(1, 'week').format('YYYY-MM-DD');
    const nextDate = currentDate.add(1, 'week').format('YYYY-MM-DD');
    return {
      prevDate,
      nextDate,
      prevLabel: 'Prev Week',
      nextLabel: 'Next Week',
    };
  }

  if (cycle === 'monthly') {
    const prevDate = currentDate.subtract(1, 'month').format('YYYY-MM-DD');
    const nextDate = currentDate.add(1, 'month').format('YYYY-MM-DD');
    return {
      prevDate,
      nextDate,
      prevLabel: dayjs(prevDate).format('MMMM'),
      nextLabel: dayjs(nextDate).format('MMMM'),
    };
  }

  if (cycle === 'yearly') {
    const prevDate = currentDate.subtract(1, 'year').format('YYYY-MM-DD');
    const nextDate = currentDate.add(1, 'year').format('YYYY-MM-DD');
    return {
      prevDate,
      nextDate,
      prevLabel: dayjs(prevDate).format('YYYY'),
      nextLabel: dayjs(nextDate).format('YYYY'),
    };
  }

  return {};
};

export default generateNavContent;
