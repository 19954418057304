import React from 'react';
import { useFormContext } from 'react-hook-form';
import CommonButton from '../../../../../../CommonButton/CommonButton';
import handleScheduleAdd from '../../../../../../../utils/BCDetail/DetailInfo/Schedule/Handler/handleScheduleAdd';
import usePatchSchedule from '../../../../../../../hooks/BCDetail/DetailInfo/Schedule/usePatchSchedule';

const ScheduleAddModalButtonContent = ({ ...props }) => {
  /** Get form utilities */
  const { getValues } = useFormContext();

  /** Get data */
  const { jobSet, selectedRowData } = props;

  /** Get handler */
  const {
    setOpen,
    setMessageModalOpener,
    setMessageModalMessage,
    setLoadingOpener,
    setSelectedRowData,
  } = props;

  /** Custom hook to add new schedule */
  const { mutate: patchScheduleMutator } = usePatchSchedule({
    setAddModalOpener: setOpen,
    setLoadingOpener,
    setMessageModalOpener,
    setMessageModalMessage,
    setSelectedRowData,
  });

  return (
    <div className="w-full flex justify-center items-center gap-[20px]">
      <CommonButton
        category="cancel"
        onClick={() => {
          setOpen(false);
          setSelectedRowData(null);
        }}
      >
        Cancel
      </CommonButton>
      <CommonButton
        type="button"
        category="save"
        preventDupClick
        onClick={({ buttonRef }) => {
          handleScheduleAdd({
            buttonRef,
            jobSet,
            selectedRowData,
            patchScheduleMutator,
            getValues,
            setMessageModalOpener,
            setMessageModalMessage,
          });
        }}
      >
        Save
      </CommonButton>
    </div>
  );
};

export default ScheduleAddModalButtonContent;
