import React, { useEffect, useState } from 'react';
import ScheduleRow from '..';

const ScheduleRowLocation = ({ pageCategory, locationSet, ...props }) => {
  /** Get schedule location set */
  const [scheduleLocationSet, setScheduleLocationSet] = useState(
    locationSet || null,
  );
  useEffect(() => {
    setScheduleLocationSet(locationSet);
  }, [locationSet]);

  /** Render schedule row according to location-set */
  return scheduleLocationSet?.map((location, index) => {
    const { seq, ordering } = location;

    return (
      seq && (
        <div key={seq}>
          <ScheduleRow
            pageCategory={pageCategory}
            /** Location schedule doesn't have driver data */
            driver=""
            data={location}
            locationSet={locationSet}
            dataKey={`${seq}_location`}
            locationSetIdx={index}
            scheduleSeq={seq}
            rowSeq={seq}
            locationInfo="location"
            scheduleType="location"
            {...props}
          />
        </div>
      )
    );
  });
};

export default ScheduleRowLocation;
