import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import dailyReportKeys from '../keys';
import getReturnYard from '../../../services/TmsReport/Schedules/Get/getReturnYard';
import getURLSearchParams from '../../../utils/TmsReport/Schedule/Generator/getURLSearchParams';

const useGetReturnYard = ({
  setErrorMessage,
  setIsErrorModalOpen,
  ...scheduleParams
}) => {
  const params = getURLSearchParams(scheduleParams);

  const result = useQuery({
    queryKey: dailyReportKeys.returnYardList({
      yard: scheduleParams?.['ngl-yard'],
      category: scheduleParams?.category,
      div: scheduleParams?.div,
      'sche-type': scheduleParams?.['sche-type'],
      'status-color': scheduleParams?.['status-color'],
    }),
    queryFn: () => getReturnYard({ params }),
    refetchOnWindowFocus: false,
    enabled: !!scheduleParams?.['ngl-yard'],
  });

  useEffect(() => {
    if (result?.isError) {
      setIsErrorModalOpen(true);
      setErrorMessage(result?.error?.message);
    }
  }, [result?.isError]);

  return result;
};

export default useGetReturnYard;
