const interceptRequest = instance => {
  instance?.interceptors?.request?.use(
    config => {
      // Set current division in headers
      const currentDivision = sessionStorage.getItem('nowDiv');
      config.headers['Selected-Div'] = currentDivision;

      // Set CSRF token in headers
      const csrfToken = localStorage.getItem('csrfToken');
      if (csrfToken) {
        config.headers['X-CSRFToken'] = csrfToken;
      }

      return config;
    },
    error => {
      throw new Error(error);
    },
  );
};

export default interceptRequest;
