import handleQueryError from '../../../utils/Common/Handler/handleQueryError';
import ediDefaultAPI from '../api';

const get204PDF = async ({ id }) => {
  try {
    if (id) {
      const response = await ediDefaultAPI.get(`204/${id}/pdf`);
      return response;
    }
    return null;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default get204PDF;
