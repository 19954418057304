import React from 'react';
import {
  LIVE_SHARE_DETAIL_DRIVER,
  LIVE_SHARE_DETAIL_MAIL,
  LIVE_SHARE_DETAIL_PHONE,
  LIVE_SHARE_DETAIL_VEHICLE,
} from '../../../../../constants/LiveShare/path';

const DetailContent = ({ ...props }) => {
  /** Get props data */
  const { detailTitle, topData, bottomData } = props;

  return (
    <div
      className="w-[379px] h-[143px] rounded-[5px] shadow-[0_0_14px_rgba(0,0,0,0.25)] bg-white
                    pt-[17px] pl-[23px]"
    >
      {/* Detail Title */}
      <div className="pb-[19px]">
        <p className="text-[#000] text-[17px] font-bold">{detailTitle}</p>
      </div>
      {/* Detail Info */}
      <div className="flex gap-[10px] flex-col">
        <div className="flex items-center flex-row">
          <div className="flex justify-center items-center mr-[14px] w-[24px] h-[24px]">
            <img
              src={
                detailTitle === 'Details'
                  ? LIVE_SHARE_DETAIL_VEHICLE
                  : LIVE_SHARE_DETAIL_PHONE
              }
              alt="Live-Share Detail Content Icon"
              data-testid="detailFirstContentIcon"
            />
          </div>
          <div className="text-[#000] text-[16px] font-normal">
            {topData || '-'}
          </div>
        </div>
        <div className="flex items-center flex-row">
          <div className="flex justify-center items-center mr-[14px] w-[24px] h-[24px]">
            <img
              src={
                detailTitle === 'Details'
                  ? LIVE_SHARE_DETAIL_DRIVER
                  : LIVE_SHARE_DETAIL_MAIL
              }
              alt="Live-Share Detail Content Icon"
              data-testid="detailSecondContentIcon"
            />
          </div>
          <div className="text-[#000] text-[16px] font-normal">
            {bottomData || '-'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailContent;
