// Polygon options of geofence
const geofenceOptions = {
  fillColor: 'lightblue',
  fillOpacity: 0.5,
  strokeColor: 'blue',
  strokeOpacity: 1,
  strokeWeight: 2,
  clickable: false,
  draggable: false,
  editable: true,
  geodesic: false,
  zIndex: 1,
};

/**
 * Add geofence data in the geofenceList.
 *
 * @param company
 * Company of the geofence.
 * @param position
 * Position of the geofecne.
 * @param geofenceList
 * List of the geofence.
 * @param setGeofenceList
 * Set the geofence data in the list.
 */
export const addGeofence = (
  company,
  position,
  geofenceList,
  setGeofenceList,
) => {
  setGeofenceList([
    ...geofenceList,
    {
      key: company,
      paths: position,
      options: geofenceOptions,
    },
  ]);
};

/**
 * Remove geofence data in the geofenceList.
 *
 * @param key
 * Key value of the geofence component.
 * @param geofenceList
 * List of the geofence.
 * @param setGeofenceList
 * Set the geofence data in the list.
 */
export const delGeofence = (key, geofenceList, setGeofenceList) => {
  setGeofenceList(geofenceList.filter(geofence => geofence.key !== key));
};
