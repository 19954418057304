import styled from 'styled-components';

const StyledCommonSideModal = styled.div`
  width: ${props =>
    props?.width ? `${Number(props?.width.split('px')[0]) + 100}px` : '724px'};
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  background-color: #fff;
  padding: 0 50px;
`;

export default StyledCommonSideModal;
