import {
  PERM_ORDER_BARE_VIEW_EDIT,
  PERM_ORDER_EXP_VIEW_EDIT,
  PERM_ORDER_IMP_VIEW_EDIT,
  PERM_ORDER_VAN_VIEW_EDIT,
} from '../../../constants/Common/permission';

const generateAddPagePermission = () => {
  const tabsData = [];

  const permissionList = localStorage.getItem('permission')?.split(',');
  if (permissionList?.includes(PERM_ORDER_IMP_VIEW_EDIT))
    tabsData.push({
      label: 'IMPORT',
      to: '/order/impreg',
      category: 'IMPORT',
      pathValue: 0,
    });
  if (permissionList?.includes(PERM_ORDER_IMP_VIEW_EDIT))
    tabsData.push({
      label: 'IMP - SHUTTLE',
      to: '/order/impshuttle',
      category: 'IMPORT',
      pathValue: 1,
    });
  if (permissionList?.includes(PERM_ORDER_EXP_VIEW_EDIT))
    tabsData.push({
      label: 'EXPORT',
      to: '/order/expreg',
      category: 'EXPORT',
      pathValue: 2,
    });
  if (permissionList?.includes(PERM_ORDER_EXP_VIEW_EDIT))
    tabsData.push({
      label: 'EXP - SHUTTLE',
      to: '/order/expshuttle',
      category: 'EXPORT',
      pathValue: 3,
    });
  if (permissionList?.includes(PERM_ORDER_VAN_VIEW_EDIT))
    tabsData.push({
      label: 'VAN',
      to: '/order/van',
      category: 'VAN',
      pathValue: 4,
    });
  if (permissionList?.includes(PERM_ORDER_BARE_VIEW_EDIT)) {
    tabsData.push({
      label: 'BARE CHASSIS',
      to: '/order/barechassis',
      category: 'BARECHASSIS',
      pathValue: 6,
    });
  }

  return tabsData;
};

export default generateAddPagePermission;
