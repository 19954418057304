import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import commonKeys from './keys';
import getCompanyIDList from '../../services/Common/Common/getCompanyIDList';

/**
 * @description
 * Custom hook to get company ID list
 * include company id, business name, category, credit hold
 *
 * @param {number} category
 * @param {boolean} credit
 * Category: 1 = Customer, 2 = Vendor, 4 = CNEE, 8 = NGL
 * Credit: true = credit hold, false = not credit hold
 *
 * ex) ?category=6&credit=true => get vendor and cnee list with credit hold
 * ex) ?category=1&credit=false => get customer list without credit hold
 *
 * @returns {list} companyIDList
 */
const useGetCompanyIDList = ({ company, category, credit }) => {
  /** Company ID list */
  const [companyIDList, setCompanyIDList] = useState(null);

  /** URL params to be sent to API */
  const [urlParams, setUrlParams] = useState(null);
  useEffect(() => {
    let params = '';
    if (company) params += `/${company}`;
    if (category || credit) {
      params = '?';
      if (category) {
        params += `category=${category}`;
      }
      if (credit) {
        if (params.includes('category=')) params += '&';
        params += `credit=${credit}`;
      }
    }
    setUrlParams(params);
  }, [company, category, credit]);

  /** Execute query with url parameter */
  const query = useQuery({
    queryKey: commonKeys?.companyIDList({ urlParams }),
    queryFn: () => getCompanyIDList({ urlParams }),
    refetchOnWindowFocus: false,
    enabled: !!urlParams,
  });

  /** Set data if get data successfully */
  useEffect(() => {
    const getQueryResultData = query?.data?.data;
    if (getQueryResultData) {
      setCompanyIDList(getQueryResultData);
    }
  }, [query?.data]);

  return { companyIDList, ...query };
};

export default useGetCompanyIDList;
