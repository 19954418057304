const handleServiceCodeSave = (params, patchServiceCodeMutator) => {
  const division = params?.gridParam?.data?.div;

  const addData = { ...params?.gridParam?.data, division };
  patchServiceCodeMutator.mutate({
    pc: params?.gridParam?.data?.product_code,
    division,
    data: addData,
  });
};

export default handleServiceCodeSave;
