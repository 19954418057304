import { useMutation } from '@tanstack/react-query';
import { SUCCESS_UPDATE } from '../../../../constants/Common/successMessage';
import patchUserInfo from '../../../../services/Admin/User/Patch/patchUserInfo';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';
import updateGridData from '../../../../utils/Common/Handler/updateGridData';
import useControlToast from '../../../Common/Toast/useControlToast';

const usePatchUserInfo = ({
  gridRef,
  setIsOpenErrorModal,
  setErrorMessage,
  setIsSuccess,
  setIsOpenSideModal,
}) => {
  const { addToast } = useControlToast();

  const mutate = useMutation({
    mutationFn: patchUserInfo,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: (res, variables) => {
      const allDivSet = variables?.patchData?.userdivision_set;
      const allDiv = Object?.keys(allDivSet)
        ?.filter(key => allDivSet[key] === true)
        ?.join(',');
      localStorage.setItem('allDiv', allDiv);

      updateGridData({
        gridRef,
        newData: { id: variables?.id, ...variables?.patchData, ...res?.data },
        isUpdate: true,
        key: 'id',
      });

      addToast({ message: SUCCESS_UPDATE });
      setIsOpenSideModal(false);
    },
    onError: error => {
      setErrorMessage(error?.message);
      setIsOpenErrorModal(true);
      setIsSuccess(false);
    },
  });
  return { ...mutate };
};

export default usePatchUserInfo;
