import dayjs from 'dayjs';

/**
 * @description
 * Handler for manipulate add schedule data
 */
const manipulateAddScheduleData = ({ ...props }) => {
  /** Get data */
  const { addData } = props;
  const tempAddData = { ...addData };

  /** Convert date to string format */
  if (addData?.sche_date) {
    tempAddData.sche_date = dayjs(addData?.sche_date).format('YYYY-MM-DD');
  }
  if (addData?.actual_in_date) {
    tempAddData.actual_in_date = dayjs(addData?.actual_in_date).format(
      'YYYY-MM-DD',
    );
  }
  if (addData?.actual_out_date) {
    tempAddData.actual_out_date = dayjs(addData?.actual_out_date).format(
      'YYYY-MM-DD',
    );
  }

  /** Convert time to string format */
  if (addData?.sche_time) {
    tempAddData.sche_time = dayjs(addData?.sche_time).format('HH:mm:ss');
  }
  if (addData?.actual_in_time) {
    tempAddData.actual_in_time = dayjs(addData?.actual_in_time).format(
      'HH:mm:ss',
    );
  }
  if (addData?.actual_out_time) {
    tempAddData.actual_out_time = dayjs(addData?.actual_out_time).format(
      'HH:mm:ss',
    );
  }
  return tempAddData;
};

export default manipulateAddScheduleData;
