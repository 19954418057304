import React from 'react';
import StyledScheduleAPModalTableContentHeaderWrapper from '../../../../../../../styles/BCDetail/DetailInfo/Schedule/StyledScheduleAPModalTableContentHeaderWrapper';
import StyledScheduleAPModalTableContentHeaderCell from '../../../../../../../styles/BCDetail/DetailInfo/Schedule/StyledScheduleAPModalTableContentHeaderCell';
import ScheduleAPModalTableContentHeaderCell from '../ScheduleAPModalTableContentHeaderCell';
import StyledScheduleAPModalTableContentHeaderDataWrapper from '../../../../../../../styles/BCDetail/DetailInfo/Schedule/StyledScheduleAPModalTableContentHeaderDataWrapper';
import { PLUS_ICON } from '../../../../../../../constants/Order/Common/path';

const ScheduleAPModalTableContentHeader = ({ ...props }) => {
  /** Get Data */
  const { apTableContent } = props;

  /** Data Setter */
  const { setAPTableContent } = props;

  return (
    <StyledScheduleAPModalTableContentHeaderWrapper>
      <StyledScheduleAPModalTableContentHeaderDataWrapper>
        <ScheduleAPModalTableContentHeaderCell
          customStyle={{
            width: '138px',
            borderRight: '1px solid #eeeeee',
          }}
        >
          Service Code
        </ScheduleAPModalTableContentHeaderCell>
        <ScheduleAPModalTableContentHeaderCell
          customStyle={{
            width: '212px',
            borderRight: '1px solid #eeeeee',
          }}
        >
          Service Code Name
        </ScheduleAPModalTableContentHeaderCell>
        <ScheduleAPModalTableContentHeaderCell
          customStyle={{
            width: '104px',
          }}
        >
          AMT ($)
        </ScheduleAPModalTableContentHeaderCell>
      </StyledScheduleAPModalTableContentHeaderDataWrapper>
      <ScheduleAPModalTableContentHeaderCell
        customStyle={{
          width: '48px',
          backgroundColor: '#fff',
        }}
      >
        {apTableContent && apTableContent?.length > 0 ? null : (
          <div
            className="cursor-pointer"
            onClick={() => {
              /** Add new empty ap row */
              setAPTableContent(prev => {
                return [
                  ...prev,
                  {
                    productCode: '',
                    productCodeName: '',
                    amount: 0,
                  },
                ];
              });
            }}
          >
            <img alt="apAddBtn" src={PLUS_ICON} />
          </div>
        )}
      </ScheduleAPModalTableContentHeaderCell>
    </StyledScheduleAPModalTableContentHeaderWrapper>
  );
};

export default ScheduleAPModalTableContentHeader;
