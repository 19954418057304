import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import useGetEarlyWarningList from '../../../hooks/Admin/EarlyWarning/Get/useGetEarlyWarningList';
import CommonDataGrid from '../../../components/CommonDataGrid';
import createAdminEWColumnDefs from '../../../utils/Admin/EarlyWarning/Generator/createAdminEWColumnDefs';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import { GRID_MOD_EDIT } from '../../../constants/Common/DataGrid/setting';
import saveEarlyWarning from '../../../utils/Admin/EarlyWarning/Handler/saveEarlyWarning';
import usePatchEarlyWarning from '../../../hooks/Admin/EarlyWarning/Patch/usePatchEarlyWarning';
import handleDisabledField from '../../../utils/Admin/EarlyWarning/Handler/handleDisabledField';
import { DISABLED_FIELD_LIST } from '../../../constants/Admin/EarlyWarning/staticNames';
import useControlLoading from '../../../hooks/Admin/Common/Control/useControlLoading';

const EarlyWarning = () => {
  const gridRef = useRef(null);
  const division = useSelector(state => state.nowDiv);

  const [columnDefs, setColumnDefs] = useState(createAdminEWColumnDefs());

  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const { earlyWarningList, isLoading, isFetching } = useGetEarlyWarningList({
    division,
    setIsOpenErrorModal,
    setErrorMessage,
    setIsSuccess,
  });

  const { mutate: earlyWarningPatchMutator, isPendingEW } =
    usePatchEarlyWarning({
      gridRef,
      division,
      setIsOpenErrorModal,
      setErrorMessage,
      setIsSuccess,
    });

  const { isLoadingOpen } = useControlLoading({
    isLoadingList: [isLoading],
    isFetchingList: [isFetching],
    isPendingList: [isPendingEW],
  });

  return (
    <>
      <div className="pt-[30px] h-full flex flex-col">
        <div className="pr-[40px] flex-1">
          <CommonDataGrid
            gridRef={gridRef}
            columnDefs={columnDefs}
            data={earlyWarningList}
            modType={GRID_MOD_EDIT}
            undoRedoCellEditing
            undoRedoCellEditingLimit={1}
            onSaveClicked={props =>
              saveEarlyWarning({
                props,
                earlyWarningPatchMutator,
                setIsOpenErrorModal,
                setErrorMessage,
                setIsSuccess,
              })
            }
            onRowEditingStart={({ gridParam }) => {
              handleDisabledField({
                disabledFields: DISABLED_FIELD_LIST,
                ...gridParam,
              });
            }}
          />
        </div>
      </div>
      <CommonLoading open={isLoadingOpen} />
      {isOpenErrorModal && !isSuccess && (
        <CommonErrorModal
          open={isOpenErrorModal}
          setIsOpen={setIsOpenErrorModal}
          message={errorMessage}
          isSuccess={isSuccess}
        />
      )}
    </>
  );
};

export default EarlyWarning;
