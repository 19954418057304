import React from 'react';
import CommonMultipleInput from '../../../components/Inputs/CommonMultipleInput/CommonMultipleInput';

const getMultipleInputComponent = ({ ...props }) => {
  const commonProperty = {
    disabled: props?.disabledArr,
    required: props?.requiredArr,
    labelStyle: props?.labelStyle,
    onChangeHandler: [
      ({ e, onChange, ...handlerProps }) => {
        onChange(e?.target?.value);
      },
      ({ e, onChange, ...handlerProps }) => {
        onChange(e?.target?.value);
      },
      ({ e, onChange, ...handlerProps }) => {
        onChange(e?.target?.value);
      },
    ],
  };

  const inputProperty = [
    {
      key: 0,
      label: 'Multiple Input Label default',
      inputName: ['multipleInput00', 'multipleInput01', 'multipleInput02'],
      width: null,
      ...commonProperty,
    },
    {
      key: 1,
      label: 'Multiple Input Label 25%',
      inputName: ['multipleInput00', 'multipleInput01', 'multipleInput02'],
      width: 'w-1/4',
      ...commonProperty,
    },
    {
      key: 2,
      label: 'Multiple Input Label 50%',
      inputName: ['multipleInput00', 'multipleInput01', 'multipleInput02'],
      width: 'w-2/4',
      ...commonProperty,
    },
    {
      key: 3,
      label: 'Multiple Input Label 75%',
      inputName: ['multipleInput00', 'multipleInput01', 'multipleInput02'],
      width: 'w-3/4',
      ...commonProperty,
    },
    {
      key: 4,
      label: 'Multiple Input Label 100%',
      inputName: ['multipleInput00', 'multipleInput01', 'multipleInput02'],
      width: 'w-full',
      ...commonProperty,
    },
    {
      key: 5,
      label: 'Multiple Input Label Custom',
      inputName: ['multipleInput00', 'multipleInput01', 'multipleInput02'],
      width: props?.customWidth,
      ...commonProperty,
    },
  ];

  return inputProperty?.map(element => (
    <CommonMultipleInput
      key={element?.key}
      {...element}
      labelStyle={props?.labelStyle && 'row'}
    />
  ));
};
export default getMultipleInputComponent;
