import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';
import bookingDefaultAPI from '../../../bookingAPI';

const getBookingDetail = async ({ bookingSeq }) => {
  try {
    const response = await bookingDefaultAPI.get(`detail/${bookingSeq}`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getBookingDetail;
