import styled from 'styled-components';

const StyledWeeklyTableWrapper = styled.div`
  flex-grow: 1;
  display: flex;

  width: 100%;
  overflow-x: scroll;
  gap: 3px;
`;

export default StyledWeeklyTableWrapper;
