import { CELL_TYPE_TEXT } from '../../../../constants/Common/DataGrid/cellType';
import cellEditorGenerator from '../../../Common/Datagrid/Generator/cellEditorGenerator';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';

const getEditableEquipmentDef = () => {
  return {
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        cellType: CELL_TYPE_TEXT,
      }),
    cellEditorSelector: gridParam =>
      cellEditorGenerator({
        gridParam,
        cellType: CELL_TYPE_TEXT,
      }),
    valueSetter: params => {
      if (params?.data?.equipment !== params?.newValue) {
        const category = params?.data?.category;
        if (['WC0001', 'WC0002', 'WC0003', 'WC0004'].includes(category)) {
          params.data.cont_no = params.newValue;
        } else if (['WC0005', 'WC0006'].includes(category)) {
          params.data.trailer_no = params.newValue;
        }
        return true;
      }
      return false;
    },
    valueGetter: params => {
      const category = params?.data?.category;
      if (['WC0001', 'WC0002', 'WC0003', 'WC0004'].includes(category)) {
        return params?.data?.cont_no;
      }
      if (['WC0005', 'WC0006'].includes(category)) {
        return params?.data?.trailer_no;
      }
      return '';
    },
  };
};

export default getEditableEquipmentDef;
