import { useMutation, useQueryClient } from '@tanstack/react-query';
import patchUpdateRow from '../../../services/Dispatch/Common/patch/patchUpdateRow';
import dispatchKeys from '../../Dispatch2/keys';
import useControlToast from '../../Common/Toast/useControlToast';
import { SUCCESS_SAVE } from '../../../constants/Common/successMessage';

const usePatchRowData = ({
  setMessageModalMessage,
  setIsMessageModalOpen,
  focusedCell,
  restoreFocusedCell,
  gridRef,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();
  const mutator = useMutation({
    mutationFn: patchUpdateRow,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      queryClient?.invalidateQueries({ queryKey: dispatchKeys?.count() });
      queryClient?.invalidateQueries({ queryKey: dispatchKeys?.list() });
      addToast({ message: SUCCESS_SAVE });
      if (focusedCell?.rowIndex !== null && focusedCell?.column !== null) {
        restoreFocusedCell();
      }
    },
    onError: error => {
      gridRef?.current?.api?.undoCellEditing();
      gridRef?.current?.api?.startEditingCell({
        rowIndex: focusedCell?.rowIndex,
        colKey: focusedCell?.column,
      });
      setMessageModalMessage(error.message);
      setIsMessageModalOpen(true);
    },
  });
  return mutator;
};

export default usePatchRowData;
