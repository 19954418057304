import styled from 'styled-components';

const StyledCustomerReportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  width: 100%;
  height: 100%;
  padding-left: 98px;
  padding-right: 2.5rem !important;
`;

export default StyledCustomerReportWrapper;
