import { useMutation } from '@tanstack/react-query';
import getBookingList from '../../../../services/Dispatch/Export/Booking/get/getBookingList';

const useGetBookingOptionMutation = ({ setBookingOption }) => {
  const mutator = useMutation({
    mutationFn: ({ woNo, size, type, ssl }) =>
      getBookingList({ woNo, size, type, ssl }),
    onSuccess: response => {
      setBookingOption(
        response.data?.map(item => {
          return { label: item?.bk_no, value: item?.seq.toString() };
        }),
      );
    },
  });
  return mutator;
};
export default useGetBookingOptionMutation;
