import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import isoWeek from 'dayjs/plugin/isoWeek';

const changePeriod = periodType => {
  dayjs.extend(quarterOfYear);
  dayjs.extend(isoWeek);

  const today = dayjs();

  let startDate;
  let endDate;

  switch (periodType) {
    case 'today':
      startDate = today?.format('YYYY-MM-DD');
      endDate = today?.format('YYYY-MM-DD');
      break;
    case 'thisWeek':
      startDate = today?.startOf('week').format('YYYY-MM-DD');
      endDate = today?.endOf('week')?.format('YYYY-MM-DD');
      break;
    case 'lastWeek':
      startDate = today
        ?.subtract(1, 'week')
        ?.startOf('week')
        ?.format('YYYY-MM-DD');
      endDate = today?.subtract(1, 'week')?.endOf('week')?.format('YYYY-MM-DD');
      break;
    case 'thisQuarter':
      startDate = today?.startOf('quarter')?.format('YYYY-MM-DD');
      endDate = today?.endOf('quarter')?.format('YYYY-MM-DD');
      break;
    case 'lastQuarter':
      startDate = today
        ?.subtract(1, 'quarter')
        ?.startOf('quarter')
        ?.format('YYYY-MM-DD');
      endDate = today
        ?.subtract(1, 'quarter')
        ?.endOf('quarter')
        ?.format('YYYY-MM-DD');
      break;
    default:
      startDate = '';
      endDate = '';
      break;
  }

  const period = {
    fromDate: startDate,
    toDate: endDate,
  };

  return period;
};

export default changePeriod;
