import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import bcDetailKeys from '../keys';
import getTrackingList from '../../../services/BCDetail/Tracking/Get/getTrackingList';
import { handleNotFoundError } from '../../../utils/Common/Handler/errorStatusSpecificHandler';

const useGetTrackingList = ({
  woNo,
  setMessageModalOpen,
  setMessageContent,
  setOnOkClickHandler,
}) => {
  const result = useQuery({
    queryKey: bcDetailKeys?.trackingList({ woNo }),
    queryFn: () => getTrackingList({ woNo }),
    refetchOnWindowFocus: false,
    enabled: Boolean(woNo),
  });

  useEffect(() => {
    if (result?.isError) {
      setMessageModalOpen?.(prev => true);
      setMessageContent?.(prev => result?.error?.message);
      setOnOkClickHandler?.(prev => {
        if (
          result?.error?.message === handleNotFoundError() ||
          result?.error?.message === 'WO # has been deleted.'
        ) {
          return () => {
            window.close();
          };
        }
        return null;
      });
    } else {
      setMessageModalOpen?.(prev => false);
      setMessageContent?.(prev => '');
      setOnOkClickHandler?.(prev => null);
    }
  }, [result?.isError]);

  return result;
};

export default useGetTrackingList;
