import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import HeaderButtons from '../../../components/Master/Common/HeaderButtons';
import AdvancedSearchModal from '../../../components/Master/Driver/AdvancedSearchModal';
import CommonDataGrid from '../../../components/CommonDataGrid';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import CommonSideModal from '../../../components/CommonSideModal';
import SideModalContent from '../../../components/Master/Driver/SideModalContent';
import StyledMasterCommonDataGridContainer from '../../../styles/Master/Common/DataGrid/StyledMasterCommonDataGridContainer';
import useGetDriverData from '../../../hooks/Master/Driver/useGetDriverData';
import useGetSelectedDriver from '../../../hooks/Master/Driver/useGetSelectedDriver';
import createDriverColumnDefs from '../../../utils/Master/Driver/Generator/createDriverColumnDefs';
import convertListToObject from '../../../utils/Master/Driver/Generator/convertListToObject';
import { createGridSelectOption } from '../../../utils/Common/createFloatingFilterSelectOption';
import TotalBottomBar from '../../../components/Common/CommonTotalBottomBar';
import useControlTotalInfo from '../../../hooks/Common/TotalBottomRow/useControlTotalInfo';

const Driver = ({ isEditable }) => {
  const gridRef = useRef(null);

  const [rowData, setRowData] = useState();
  const [editMode, setEditMode] = useState(false);
  const [isCompany, setIsCompany] = useState(true);

  const [queryObject, setQueryObject] = useState({});

  const [advancedSearchOpen, setAdvancedSearchOpen] = useState(false);
  const [sideModalOpen, setSideModalOpen] = useState(false);

  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [messageContents, setMessageContent] = useState('');

  const modalProps = {
    setMessageModalOpen,
    setMessageContent,
  };

  const divList = useSelector(state => state?.common?.allDiv);
  const divListOption = createGridSelectOption(divList);
  const stateObject = convertListToObject(divList, 'div', 'state');

  const [columnDefs, setColumnDefs] = useState([]);

  useEffect(() => {
    if (divList) {
      setColumnDefs(createDriverColumnDefs({ divList }));
    }
  }, [divList]);

  const {
    data: driverList,
    isLoading: loadingList,
    isError,
  } = useGetDriverData({ queryObject });

  const { mutate: getSelectedDriverMutator } = useGetSelectedDriver({
    setRowData,
    setIsCompany,
    setEditMode,
    setSideModalOpen,
    ...modalProps,
  });

  const handleAddClick = () => {
    setRowData(null);
    setIsCompany(true);
    setEditMode(false);
    setSideModalOpen(true);
  };

  const { totalRows, setChangeFlag } = useControlTotalInfo({
    gridRef,
    queryData: driverList?.data,
  });

  return (
    <>
      {(loadingList || isError) && (
        <CommonLoading open={loadingList || isError} />
      )}
      <div className="flex flex-col h-full">
        {/* Driver Header */}
        <HeaderButtons
          gridRef={gridRef}
          setAdvancedSearchOpen={setAdvancedSearchOpen}
          onAddClickHandler={handleAddClick}
          isEditable={isEditable}
          fileName="DRIVER"
        />
        {/* Driver Contents */}
        <StyledMasterCommonDataGridContainer>
          <CommonDataGrid
            columnDefs={columnDefs}
            data={driverList?.data}
            gridRef={gridRef}
            rowSelection="multiple"
            onRowClicked={params =>
              getSelectedDriverMutator(params?.gridParam?.data?.driver)
            }
            onFilterChanged={() => {
              setChangeFlag(prev => !prev);
            }}
            onSelectionChanged={() => {
              setChangeFlag(prev => !prev);
            }}
          />
          <TotalBottomBar totalRows={totalRows} />
        </StyledMasterCommonDataGridContainer>
      </div>
      {/* ADD  / EDIT Modal */}
      <CommonSideModal
        open={sideModalOpen}
        setOpen={setSideModalOpen}
        title="Driver Detail"
        styles={{ width: '680px' }}
      >
        <SideModalContent
          isEditable={isEditable}
          editMode={editMode}
          isCompany={isCompany}
          setIsCompany={setIsCompany}
          data={editMode ? rowData : undefined}
          stateObject={stateObject}
          divListOption={divListOption}
          gridRef={gridRef}
          {...modalProps}
        />
      </CommonSideModal>
      <AdvancedSearchModal
        openModal={advancedSearchOpen}
        setOpenModal={setAdvancedSearchOpen}
        queryObject={queryObject}
        setQueryObject={setQueryObject}
        divListOption={divListOption}
      />
      {messageModalOpen && (
        <CommonErrorModal
          open={messageModalOpen}
          message={messageContents}
          setIsOpen={setMessageModalOpen}
        />
      )}
    </>
  );
};

export default Driver;
