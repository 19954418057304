import React from 'react';
import ScheduleRowWithPageCategory from '../../../../../components/BCDetail/DetailInfo/Schedule/ScheduleRow/ScheduleRowWithPageCategory';

/**
 * @description
 * Function that generate schedule row component.
 * Render table contents according to page-category.
 * Render schedule table content when page-category is export.
 * Render schedule table content when page-category is import, van, bare-chassis.
 * @returns
 * Schedule table row component for export page.
 */
const ScheduleRow = ({
  scheType,
  jobSet,
  locationSet,
  setDriverAPModalOpener,
  isCompanyIDListLoading,
  ...props
}) => {
  /** import / export / van / bare-chassis */
  const pageCategory =
    document?.URL?.split('/')?.[document?.URL?.split('/')?.length - 2];

  return (
    <ScheduleRowWithPageCategory
      key={`${jobSet?.length}_${locationSet?.length}`}
      pageCategory={pageCategory}
      scheType={scheType}
      jobSet={jobSet}
      locationSet={locationSet}
      driverListModalOpener={setDriverAPModalOpener}
      isCompanyIDListLoading={isCompanyIDListLoading}
      {...props}
    />
  );
};

export default ScheduleRow;
