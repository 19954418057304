export const STATUS_CHECKBOX_OPTIONS = [
  {
    optionLabel: 'ISSUE',
    defaultValue: true,
    inputName: 'issue',
  },
  {
    optionLabel: 'CONFIRM',
    defaultValue: false,
    inputName: 'confirm',
  },
  {
    optionLabel: 'SOLVED',
    defaultValue: false,
    inputName: 'solved',
  },
];

export const PERIOD_OPTIONS = [
  { label: 'Select', value: '' },
  { label: 'Today', value: 'today' },
  { label: 'This Week', value: 'thisWeek' },
  { label: 'Last Week', value: 'lastWeek' },
  { label: 'This Quarter', value: 'thisQuarter' },
  { label: 'Last Quarter', value: 'lastQuarter' },
];

export const WO_CATEGORY_OPTIONS = [
  { label: 'ALL', value: 'ALL' },
  { label: 'IMPORT', value: 'M' },
  { label: 'EXPORT', value: 'X' },
  { label: 'VAN', value: 'V' },
  { label: 'BROKERAGE', value: 'B' },
  { label: 'BARE CHASSIS', value: 'C' },
];

export const CATEGORY_OPTIONS = {
  ALL: [
    { label: 'WO #', value: 'wo-no' },
    { label: 'CONT #', value: 'cont-no' },
    { label: 'SSL', value: 'ssl' },
    { label: 'MBL #', value: 'mbl-no' },
    { label: 'BK #', value: 'bk-no' },
  ],
  OTHER: [
    { label: 'WO #', value: 'wo-no' },
    { label: 'CONT #', value: 'cont-no' },
    { label: 'SSL', value: 'ssl' },
    { label: 'MBL #', value: 'mbl-no' },
  ],
  EXPORT: [
    { label: 'WO #', value: 'wo-no' },
    { label: 'CONT #', value: 'cont-no' },
    { label: 'SSL', value: 'ssl' },
    { label: 'BK #', value: 'bk-no' },
  ],
};
