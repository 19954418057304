import React from 'react';
import WeeklyTableItem from '../WeeklyTableItem';
import StyledWeeklyTableContentWrapper from '../../../../styles/TmsReport/Schedules/StyledWeeklyTableContentWrapper';

const WeeklyTableContent = ({ pageType, weeklyData, day }) => {
  return (
    <StyledWeeklyTableContentWrapper>
      {weeklyData?.[day]?.data?.map(woData => {
        return (
          <WeeklyTableItem
            key={woData?.wo_no}
            wo={woData}
            weeklyData={weeklyData}
            pageType={pageType}
          />
        );
      })}
    </StyledWeeklyTableContentWrapper>
  );
};

export default WeeklyTableContent;
