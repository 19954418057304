import React, { useEffect, useState } from 'react';
import useGetCompanyIDList from '../../../../../../hooks/Common/useGetCompanyIDList';
import createCompanySelect from '../../../../../../utils/Common/Option/createCompanySelect';
import ScheduleTableContents from '../ScheduleTableContents';

const ScheduleTableWrapper = ({ detailInfoData, ...props }) => {
  /** Get Location ID (NGL, CNEE) */
  const { companyIDList, isLoading: isCompanyIDListLoading } =
    useGetCompanyIDList({
      category: 12,
      credit: false,
    });

  const [locationOption, setLocationOption] = useState(
    createCompanySelect({ companyData: companyIDList }),
  );

  useEffect(() => {
    setLocationOption(createCompanySelect({ companyData: companyIDList }));
  }, [companyIDList]);

  /** Get schedule data from API response */
  const {
    location_set: paramLocationSet,
    job_set: paramJobSet,
    sche_type: scheType,
  } = detailInfoData;

  /** State of schedule data */
  const [locationSet, setLocationSet] = useState(paramLocationSet || null);
  useEffect(() => {
    setLocationSet(paramLocationSet);
  }, [paramLocationSet]);
  const [jobSet, setJobSet] = useState(paramJobSet || null);
  useEffect(() => {
    setJobSet(paramJobSet);
  }, [paramJobSet]);

  return (
    <ScheduleTableContents
      scheType={scheType}
      scheduleData={{ locationSet, jobSet }}
      locationOption={locationOption}
      detailInfoData={detailInfoData}
      isCompanyIDListLoading={isCompanyIDListLoading}
      {...props}
    />
  );
};

export default ScheduleTableWrapper;
