import styled from 'styled-components';

const StyledSidebarBlurWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  z-index: 999;

  background-color: rgba(0, 0, 0, 0.5);

  transition-duration: 300ms;
`;

export default StyledSidebarBlurWrapper;
