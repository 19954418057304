import masterAccountCodeDefaultApi from '../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

const getAcctCodeList = async () => {
  try {
    const response = await masterAccountCodeDefaultApi.get(`acctcode`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getAcctCodeList;
