export const judgeBeforeDateRequired = ({ watcher }) => {
  return !!watcher;
};

export const judgeAfterDateRequired = ({ watcher }) => {
  if (
    !!watcher &&
    (watcher === 'po_sche_datetime' ||
      watcher === 'dilv_sche_datetime' ||
      watcher === 'pu_sche_datetime' ||
      watcher === 'rtn_sche_datetime' ||
      watcher === 'cutoff' ||
      watcher === 'lfd' ||
      watcher === 'eta')
  )
    return true;
  return false;
};
