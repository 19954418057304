/**
 * @description
 * Constants for Order sub categories
 */
export const SUB_ORDER_TYPE_IMPREG = 'impreg';
export const SUB_ORDER_TYPE_IMPSHUTTLE = 'impshuttle';
export const SUB_ORDER_TYPE_EXPREG = 'expreg';
export const SUB_ORDER_TYPE_EXPSHUTTLE = 'expshuttle';
export const SUB_ORDER_TYPE_VAN = 'van';
export const SUB_ORDER_TYPE_BROKERAGE = 'brokerage';
export const SUB_ORDER_TYPE_BARECHASSIS = 'barechassis';
