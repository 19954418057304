import {
  DISPATCH_PAGE_BROKERAGE_AVAILABLE,
  DISPATCH_PAGE_BROKERAGE_DELIVERY,
  DISPATCH_PAGE_BROKERAGE_RETURN,
} from '../../Common/category';
import {
  AVAILABLE_SUB_FILTERS_WITHOUT_YARD,
  DELIVERY_SUB_FILTERS_WITHOUT_YARD,
  RETURN_SUB_FILTERS_WITHOUT_YARD,
} from '../../Common/filterButtonSettings';

const BROKERAGE_FILTERS = [
  {
    header: 'AVAILABLE',
    key: 'available',
    status: DISPATCH_PAGE_BROKERAGE_AVAILABLE,
    to: '/dispatch/brokerage/available',
    children: AVAILABLE_SUB_FILTERS_WITHOUT_YARD,
  },
  {
    header: 'DELIVERY Ready',
    key: 'delivery',
    status: DISPATCH_PAGE_BROKERAGE_DELIVERY,
    to: '/dispatch/brokerage/delivery',
    children: DELIVERY_SUB_FILTERS_WITHOUT_YARD,
  },
  {
    header: 'RETURN Ready',
    key: 'return',
    status: DISPATCH_PAGE_BROKERAGE_RETURN,
    to: '/dispatch/brokerage/return',
    children: RETURN_SUB_FILTERS_WITHOUT_YARD,
  },
];

export default BROKERAGE_FILTERS;
