import dayjs from 'dayjs';

const changeBookingInfo = ({
  basicInfoData,
  bookingNumber,
  selectedValue,
  setValue,
  scheduleFormController,
}) => {
  const selectedSeq = selectedValue?.value;
  const filteredBookingNumber = bookingNumber?.find(
    item => String(item?.seq) === selectedSeq,
  );

  // Change vessel
  setValue?.('vessel', filteredBookingNumber?.vessel);

  // Change cut-off date
  setValue?.(
    'cutoff_date',
    filteredBookingNumber?.cutoff_date &&
      dayjs(filteredBookingNumber?.cutoff_date)?.isValid()
      ? dayjs(filteredBookingNumber?.cutoff_date)
      : null,
  );

  // Change cut-off time
  setValue?.(
    'cutoff_time',
    filteredBookingNumber?.cutoff &&
      dayjs(filteredBookingNumber?.cutoff)?.isValid()
      ? dayjs(filteredBookingNumber?.cutoff)
      : null,
  );

  // Change erd
  setValue?.(
    'erd',
    filteredBookingNumber?.erd && dayjs(filteredBookingNumber?.erd)?.isValid()
      ? dayjs(filteredBookingNumber?.erd)
      : null,
  );

  // Change return location
  const { setValue: scheduleSetValue } = scheduleFormController;
  const jobSet = basicInfoData?.job_set;
  const returnLocationJob = jobSet?.find(
    element =>
      element?.to_location?.type === 'RTN' &&
      element?.to_location?.ordering === 0,
  );
  const returnLocationKey = `${returnLocationJob?.seq}_${returnLocationJob?.to_location?.seq}_to`;
  scheduleSetValue?.(
    `company_${returnLocationKey}`,
    filteredBookingNumber?.rtn_location?.company,
  );
  scheduleSetValue?.(
    `city_${returnLocationKey}`,
    filteredBookingNumber?.rtn_location?.city,
  );
  scheduleSetValue?.(
    `state_${returnLocationKey}`,
    filteredBookingNumber?.rtn_location?.state,
  );
  scheduleSetValue?.(
    `timezone_${returnLocationKey}`,
    filteredBookingNumber?.rtn_location?.timezone,
  );
};

export default changeBookingInfo;
