import styled from 'styled-components';

const StyledBCDetailBillingInfoRateCFMModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 100%;
  padding: 20px;
  background-color: #f9f9f9;
  margin-top: 15px;
`;

export default StyledBCDetailBillingInfoRateCFMModalContainer;
