import React from 'react';
import { useFormContext } from 'react-hook-form';
import CommonPhoneNumber from '../../../../Inputs/CommonPhoneNumber';
import StyledCommonErrorModalWrapper from '../../../../../styles/Common/CommonErrorModal/StyledCommonErrorModalWrapper';
import defaultInputRules from '../../../../../utils/Common/Input/defaultInputRules';
import { splitPhoneNumber } from '../../../../../utils/Common/Handler/phoneNumberHandler';

const TextMessageContent = ({ cellNo }) => {
  const { register } = useFormContext();

  return (
    <>
      <StyledCommonErrorModalWrapper className="flex flex-col justify-start">
        <div className="w-full">RECIPIENT</div>
        <CommonPhoneNumber
          required
          inputName={['cell_no_country', 'cell_no']}
          defaultValue={splitPhoneNumber({ phone: cellNo })}
          placeholder="Write phone number to send"
        />
      </StyledCommonErrorModalWrapper>
      <textarea
        placeholder="CONTENT"
        {...register('message')}
        className="w-full h-[123px] my-0.5 pt-[10px] px-[18px] resize-none border-[1px] border-[#EEEEEE] 
                  text-[12px] font-[400] leading-[14px] bg-[#FFFFFF] focus:outline-none"
      />
    </>
  );
};

export default TextMessageContent;
