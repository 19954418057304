import {
  DISPATCH_TYPE_AVAILABLE,
  DISPATCH_TYPE_BOOKING,
  DISPATCH_TYPE_BROKERAGE,
  DISPATCH_TYPE_DELIVERY,
  DISPATCH_TYPE_EXPORT,
  DISPATCH_TYPE_IMPORT,
  DISPATCH_TYPE_RETURN,
  DISPATCH_TYPE_UNAVAILABLE,
  DISPATCH_TYPE_VAN,
} from '../../../../constants/Dispatch/Common/category';

const getQueryKeyStatus = ({ category, woStatus }) => {
  let categoryPart = '';

  if (category === DISPATCH_TYPE_IMPORT || category === 'import') {
    categoryPart = 'imp';
  }
  if (
    category === DISPATCH_TYPE_BOOKING ||
    category === DISPATCH_TYPE_EXPORT ||
    category === 'export'
  ) {
    categoryPart = 'exp';
  }
  if (category === DISPATCH_TYPE_VAN || category === 'van') {
    categoryPart = 'van';
  }
  if (category === DISPATCH_TYPE_BROKERAGE || category === 'brokerage') {
    categoryPart = 'bkg';
  }

  if (
    ![
      DISPATCH_TYPE_UNAVAILABLE,
      DISPATCH_TYPE_AVAILABLE,
      DISPATCH_TYPE_DELIVERY,
      DISPATCH_TYPE_RETURN,
      DISPATCH_TYPE_BOOKING,
    ].includes(woStatus)
  ) {
    if (woStatus === 'unavailable') {
      return `${categoryPart}_${DISPATCH_TYPE_UNAVAILABLE}`;
    }
    if (woStatus === 'available') {
      return `${categoryPart}_${DISPATCH_TYPE_AVAILABLE}`;
    }
    if (woStatus === 'delivery') {
      return `${categoryPart}_${DISPATCH_TYPE_DELIVERY}`;
    }
    if (woStatus === 'return') {
      return `${categoryPart}_${DISPATCH_TYPE_RETURN}`;
    }
    if (!woStatus) {
      if (category === DISPATCH_TYPE_IMPORT || category === 'import') {
        return `${categoryPart}_${DISPATCH_TYPE_UNAVAILABLE}`;
      }
      if (category === DISPATCH_TYPE_EXPORT || category === 'export') {
        return `${categoryPart}_${DISPATCH_TYPE_BOOKING}`;
      }
      if (
        category === DISPATCH_TYPE_VAN ||
        category === 'van' ||
        category === DISPATCH_TYPE_BROKERAGE ||
        category === 'brokerage'
      ) {
        return `${categoryPart}_${DISPATCH_TYPE_AVAILABLE}`;
      }
    }
  }
  return `${categoryPart}_${woStatus}`;
};

export default getQueryKeyStatus;
