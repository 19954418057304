const getBackgroundColor = vehicleType => {
  switch (vehicleType) {
    case 'Truck':
      return '#E3537E';
    case 'Chassis':
      return '#4462FF';
    case 'Trailer':
      return '#FF9D2A';
    default:
      return '#fff';
  }
};

export default getBackgroundColor;
