import styled from 'styled-components';

const StyledBCDetailBillingInfoCommonHeaderRateCfmContainer = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c9ccd3;
  .MuiCheckbox-root {
    margin-left: 25px;
  }
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
`;

export default StyledBCDetailBillingInfoCommonHeaderRateCfmContainer;
