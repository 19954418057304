import dayjs from 'dayjs';
import checkContainerNumberConstraint from '../../../../utils/Common/Handler/checkContainerNumberConstraint';
import emailFormatValidateHandler from '../../../../utils/Common/Handler/emailFormatValidateHandler';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';

const useUpdateDetailInfo = ({
  detailInfoData,
  basicInfoData,
  setMessage,
  setErrorModalOpen,
  buttonRef,
}) => {
  const formValues = basicInfoData;
  const contNoValidation = checkContainerNumberConstraint({
    containerNo: basicInfoData?.cont_no,
    containerSize: basicInfoData?.size,
    containerType: basicInfoData?.type,
  });
  const emailValidation = emailFormatValidateHandler({
    email: basicInfoData?.sendto,
  });

  /** Check validation */
  if (!contNoValidation) {
    setMessage?.('Container Number is not valid');
    setErrorModalOpen?.(true);
    updateButtonDisabled({ ref: buttonRef, disabled: false });
    return null;
  }
  if (!emailValidation) {
    setMessage?.('Email format is not valid');
    setErrorModalOpen?.(true);
    updateButtonDisabled({ ref: buttonRef, disabled: false });
    return null;
  }
  /** if cont # and Ch # is valid, update data
   * 1. if formValues[key] is an empty string, change it to null
   * 2. if formValues[key] is not an empty string, keep it
   * 3. send data to the server
   */

  const updatedData = {};
  Object.keys(formValues).forEach(key => {
    updatedData[key] = formValues[key] !== '' ? formValues[key] : null;
  });

  /** Convert Bill-to */
  updatedData.billto = formValues?.billto?.split('/')?.[0]?.trim();

  /** Convert sendto */
  updatedData.sendto = Array.isArray(formValues?.sendto)
    ? formValues?.sendto
    : formValues?.sendto
        ?.split(',')
        ?.map(item => item?.trim())
        ?.filter(item => item !== '');

  /** Convert booking number */
  updatedData.bk_no = parseInt(formValues?.bk_no?.value, 10);

  /** Convert date format */
  updatedData.eta =
    formValues?.eta && dayjs(formValues?.eta)?.isValid()
      ? dayjs(formValues?.eta).format('YYYY-MM-DD')
      : null;
  updatedData.lfd =
    formValues?.lfd && dayjs(formValues?.lfd)?.isValid()
      ? dayjs(formValues?.lfd).format('YYYY-MM-DD')
      : null;
  updatedData.rfd =
    formValues?.rfd && dayjs(formValues?.rfd)?.isValid()
      ? dayjs(formValues?.rfd).format('YYYY-MM-DD')
      : null;
  updatedData.erd =
    formValues?.erd && dayjs(formValues?.erd)?.isValid()
      ? dayjs(formValues?.erd).format('YYYY-MM-DD')
      : null;
  updatedData.cutoff =
    formValues?.cutoff_date &&
    formValues?.cutoff_time &&
    dayjs(formValues?.cutoff_date)?.isValid() &&
    dayjs(formValues?.cutoff_time)?.isValid()
      ? `${dayjs(formValues?.cutoff_date).format('YYYY-MM-DD')} ${dayjs(
          formValues?.cutoff_time,
        ).format('HH:mm:ss')}`
      : null;
  updatedData.st =
    formValues?.st && dayjs(formValues?.st)?.isValid()
      ? dayjs(formValues?.st).format('YYYY-MM-DD')
      : null;
  updatedData.ava_bo =
    formValues?.ava_bo && dayjs(formValues?.ava_bo)?.isValid()
      ? dayjs(formValues?.ava_bo).format('YYYY-MM-DD')
      : null;
  updatedData.ava_ca =
    formValues?.ava_ca && dayjs(formValues?.ava_ca)?.isValid()
      ? dayjs(formValues?.ava_ca).format('YYYY-MM-DD')
      : null;
  updatedData.ava_cus =
    formValues?.ava_cus && dayjs(formValues?.ava_cus)?.isValid()
      ? dayjs(formValues?.ava_cus).format('YYYY-MM-DD')
      : null;
  updatedData.ava_dsc =
    formValues?.ava_dsc && dayjs(formValues?.ava_dsc)?.isValid()
      ? dayjs(formValues?.ava_dsc).format('YYYY-MM-DD')
      : null;
  updatedData.ava_na =
    formValues?.ava_na && dayjs(formValues?.ava_na)?.isValid()
      ? dayjs(formValues?.ava_na).format('YYYY-MM-DD')
      : null;
  updatedData.ava_obl =
    formValues?.ava_obl && dayjs(formValues?.ava_obl)?.isValid()
      ? dayjs(formValues?.ava_obl).format('YYYY-MM-DD')
      : null;
  updatedData.ava_oth =
    formValues?.ava_oth && dayjs(formValues?.ava_oth)?.isValid()
      ? dayjs(formValues?.ava_oth).format('YYYY-MM-DD')
      : null;
  updatedData.ava_date =
    formValues?.ava_date && dayjs(formValues?.ava_date)?.isValid()
      ? dayjs(formValues?.ava_date).format('YYYY-MM-DD')
      : null;

  /** Additional constraint check */
  delete updatedData?.rfd;

  return updatedData;
};

export default useUpdateDetailInfo;
