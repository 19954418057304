import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import getDualJobPullOut from '../../../../services/BCDetail/DetailInfo/DualMove/Get/getDualJobPullOut';
import bcDetailKeys from '../../keys';
import viewDualMoveContentFilter from '../../../../utils/BCDetail/DetailInfo/DualMove/Handler/viewDualMoveContentFilter';

const useGetDualJobPullOut = ({ dualMoveRowData }) => {
  const [filter, setFilter] = useState({
    contNo: '',
    fromScheDate: null,
    rtnLoc: dualMoveRowData?.seq || null,
    toScheDate: null,
  });

  const [dualJobPullOut, setDualJobPullOut] = useState([]);

  const query = useQuery({
    queryKey: bcDetailKeys.dualJobPullOut({ ...filter }),
    queryFn: () => getDualJobPullOut({ ...filter }),
    refetchOnWindowFocus: false,
    enabled: Boolean(
      viewDualMoveContentFilter({ dualMoveRowData }) && filter?.rtnLoc,
    ),
  });

  useEffect(() => {
    if (query?.data) {
      setDualJobPullOut(prev => query?.data?.data);
    } else {
      setDualJobPullOut(prev => []);
    }
  }, [query?.data]);

  return { dualJobPullOut, filter, setFilter, ...query };
};

export default useGetDualJobPullOut;
