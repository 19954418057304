import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import StyledTraceSearchBarOption from '../../../styles/Trace/SearchBar/StyledTraceSearchBarOption';
import StyledTraceSearchBarShortInput from '../../../styles/Trace/SearchBar/StyledTraceSearchBarShortInput';
import StyledTraceSearchBarWithOptionWrapper from '../../../styles/Trace/SearchBar/StyledTraceSearchBarWithOptionWrapper';
import StyledTraceSearchBarSearchIconContainer from '../../../styles/Trace/SearchBar/StyledTraceSearchBarSearchIconContainer';

let searchValue = ' ';

const JobSearchBar = ({ setCategory, setTarget }) => (
  <StyledTraceSearchBarWithOptionWrapper>
    <StyledTraceSearchBarOption
      data-testid="search-bar-option"
      defaultValue="wo"
      onChange={e => {
        setCategory(e.target.value);
      }}
    >
      <option value="wo">WO#</option>
      <option value="driver">DRV ID</option>
    </StyledTraceSearchBarOption>
    <StyledTraceSearchBarShortInput
      data-testid="search-bar"
      placeholder="Search"
      onChange={e => {
        searchValue = e.target.value;
      }}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          setTarget(searchValue?.trim() || ' ');
          searchValue = ' ';
        }
      }}
    />
    <StyledTraceSearchBarSearchIconContainer>
      <SearchIcon
        data-testid="search-icon"
        fontSize="large"
        sx={{ fontSize: '16px', fill: '#aaa', cursor: 'pointer' }}
        onClick={() => {
          setTarget(searchValue?.trim() || ' ');
          searchValue = ' ';
        }}
      />
    </StyledTraceSearchBarSearchIconContainer>
  </StyledTraceSearchBarWithOptionWrapper>
);

export default JobSearchBar;
