import React from 'react';
import dayjs from 'dayjs';
import { STATISTICS_TABLE_TITLE_VALUE } from '../../../../../constants/TmsReport/Statistics/statisticsValues';

const StatTableHeader = ({ tableTitle, cycleUnits, cycle, dateQuery }) => {
  const renderDate = (header, index) => {
    const startOfWeek = dayjs(dateQuery?.date).startOf('week');

    return (
      header !== 'WEEK' &&
      header !== 'MONTH' && (
        <div className="text-[#222222]">
          {startOfWeek.add(index, 'day')?.isValid()
            ? startOfWeek.add(index, 'day').format('MM/DD/YY')
            : ''}
        </div>
      )
    );
  };

  return (
    <div className="header-row top-sticky">
      <div className="flex">
        <div className="header-title left-div-sticky">
          {STATISTICS_TABLE_TITLE_VALUE[tableTitle]}
        </div>
        {Object.values(cycleUnits)?.map((header, index) => {
          return (
            <div key={header} className="header-cell">
              {header}
              {cycle === 'weekly' && renderDate(header, index)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StatTableHeader;
