/** Constant of sub-menu mapper */
export const SUB_MENU_MAPPER = {
  order: 0,
  dispatch: 1,
  main: 2,
  'customer-report': 3,
  'tms-report': 4,
  'mobile-admin': 5,
  trace: 6,
  edi: 7,
  acct: 8,
  master: 9,
  admin: 10,
  labs: 11,
};

export const PAGE_NAME_MAPPER = {
  order: 'ORDER',
  dispatch: 'DISPATCH',
  main: 'MAIN',
  'customer-report': 'CUSTOMER REPORT',
  'tms-report': 'TMS REPORT',
  'mobile-admin': 'MOBILE ADMIN',
  trace: 'TRACE',
  edi: 'EDI',
  acct: 'ACCOUNTING',
  master: 'MASTER',
  admin: 'ADMIN',
  labs: 'HELLO LABS!',
};

export const PAGE_ORDER = 'order';
export const PAGE_DISPATCH = 'dispatch';
export const PAGE_MAIN = 'main';
export const PAGE_CUSTOMER_REPORT = 'customer-report';
export const PAGE_TMS_REPORT = 'tms-report';
export const PAGE_MOBILE_ADMIN = 'mobile-admin';
export const PAGE_TRACE = 'trace';
export const PAGE_EDI = 'edi';
export const PAGE_ACCT = 'acct';
export const PAGE_MASTER = 'master';
export const PAGE_ADMIN = 'admin';
export const PAGE_LABS = 'labs';
