import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import CommonAlertModal from '../../../components/Common/CommonAlertModal';
import CommonDataGrid from '../../../components/CommonDataGrid';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import ConfirmModal from '../../../components/MobileAdmin/BillConfirm/ConfirmModal';
import HeaderButtons from '../../../components/MobileAdmin/BillConfirm/HeaderButtons';
import useGetBillConfirmList from '../../../hooks/MobileAdmin/BillConfirm/useGetBillConfirmList';
import usePatchConfirmStatus from '../../../hooks/MobileAdmin/BillConfirm/usePatchConfirmStatus';
import defaultColumnDefsGenerator from '../../../utils/Common/Datagrid/Generator/defaultColumnDefsGenerator';
import createBillConfirmColumnDefs from '../../../utils/MobileAdmin/BillConfirm/Generator/createBillConfirmColumnDefs';
import handleCellClick from '../../../utils/MobileAdmin/Common/Handler/handleCellClick';
import useControlTotalInfo from '../../../hooks/Common/TotalBottomRow/useControlTotalInfo';
import TotalBottomBar from '../../../components/Common/CommonTotalBottomBar';

const BillConfirm = () => {
  const gridRef = useRef(null);
  const defaultColumnDefs = defaultColumnDefsGenerator();

  const division = useSelector(state => state.nowDiv);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmContent, setConfirmContent] = useState('');
  const [alertModalOpen, setAlertModalOpen] = useState(false);

  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [messageContents, setMessageContent] = useState('');

  const { isLoading, billConfirm, isError } = useGetBillConfirmList({
    division,
  });

  const patchStatusMutator = usePatchConfirmStatus({
    gridRef,
    setMessageModalOpen,
    setMessageContent,
    setConfirmOpen,
    division,
  });

  const [columnDefs, setColumnDefs] = useState(
    createBillConfirmColumnDefs({
      setConfirmOpen,
      setConfirmContent,
    }),
  );

  const { totalRows, setChangeFlag } = useControlTotalInfo({
    gridRef,
    queryData: billConfirm,
  });

  return (
    <>
      {(isLoading || isError) && <CommonLoading open={isLoading || isError} />}
      <div className="pr-[40px] h-full flex flex-col">
        <HeaderButtons
          gridRef={gridRef}
          setConfirmOpen={setConfirmOpen}
          setConfirmContent={setConfirmContent}
          fileName="BILL CONFIRM"
        />
        <div className="w-full flex-1">
          <CommonDataGrid
            gridRef={gridRef}
            data={billConfirm}
            columnDefs={columnDefs}
            defaultColDef={defaultColumnDefs}
            onCellClicked={handleCellClick}
            onFilterChanged={() => {
              setChangeFlag(prev => !prev);
            }}
            onSelectionChanged={() => {
              setChangeFlag(prev => !prev);
            }}
          />
        </div>
        <TotalBottomBar totalRows={totalRows} />

        {confirmOpen && (
          <ConfirmModal
            open={confirmOpen}
            setOpen={setConfirmOpen}
            gridRef={gridRef}
            division={division}
            confirmContent={confirmContent}
            patchStatusMutator={patchStatusMutator}
            setMessage={setMessageContent}
            setAlertModalOpen={setAlertModalOpen}
          />
        )}
        {alertModalOpen && (
          <CommonAlertModal
            open={alertModalOpen}
            setOpen={setAlertModalOpen}
            message={messageContents}
          />
        )}
        {messageModalOpen && (
          <CommonErrorModal
            open={messageModalOpen}
            message={messageContents}
            setIsOpen={setMessageModalOpen}
          />
        )}
      </div>
    </>
  );
};
export default BillConfirm;
