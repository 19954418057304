import styled from 'styled-components';
import { PATH_SIGN_IN_BACKGROUND } from '../../../constants/Common/path';

const StyledSignInBackgroundImageWrapper = styled.div`
  background-image: url(${PATH_SIGN_IN_BACKGROUND});
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 894px;
  height: 569px;
  background-size: cover;
`;

export default StyledSignInBackgroundImageWrapper;
