import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, FormControlLabel, FormHelperText } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import StyledCommonInputWrapper from '../../../styles/Common/CommonInput/StyledCommonInputWrapper';
import StyledCommonLabel from '../../../styles/Common/CommonInput/StyledCommonLabel';
import StyledCommonInputRequiredLabel from '../../../styles/Common/CommonInput/StyledCommonInputRequiredLabel';
import defaultInputRules from '../../../utils/Common/Input/defaultInputRules';

const CommonCheckbox = ({ options, ...props }) => {
  /**
   * @description
   * Get react-hook-form object from useFormContext.
   * @control Must-have elements for getting values from a form.
   * @unregister Allows to unregister a single input or an array of inputs.
   * @formState Object containing information about the form.
   */
  const {
    control,
    unregister,
    formState: { errors: formErrors },
  } = useFormContext();

  /**
   * @description
   * Apply error status and message for input.
   * If just one error occurred, Red border would be activated.
   * @formInvalid - Status of invalid.
   * @formErrorMessage - Error message.
   */
  let formInvalid = false;
  let formErrorMessage = null;
  // Loop all of check box element.
  for (let i = 0; i < options?.length; i++) {
    if (formErrors?.[options?.[i]?.inputName]) {
      // If error occurred, status would be changed to invalid and error message would be binded.
      formInvalid = true;
      formErrorMessage = formErrors?.[options?.[i]?.inputName]?.message;
      break;
    }
    formInvalid = false;
    formErrorMessage = null;
  }

  /**
   * @label {string} - Label of input.
   * @disabled {boolean} - Disable input.
   * @required {boolean} - Required input.
   */
  const [label, setLabel] = useState(props?.label);

  // Set disabled for input. Default is false.
  const [disabled, setDisabled] = useState(props?.disabled || false);
  useEffect(() => {
    /**
     * If default disabled value changed, component disabled value would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.disabled !== undefined && props?.disabled !== null) {
      setDisabled(props?.disabled);
    }
  }, [props?.disabled]);

  // Set required for input
  const [required, setRequired] = useState(props?.required || false);
  useEffect(() => {
    /**
     * If default required value changed, component required value would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.required !== undefined && props?.required !== null) {
      setRequired(props?.required);
    }
  }, [props?.required]);

  useEffect(() => {
    /**
     * Unregister input when component unmount
     * Unregister all of checkbox element.
     */
    return () => {
      options?.forEach(element => {
        unregister(element?.inputName);
      });
    };
  }, []);

  /**
   * @description
   * Render checkbox component.
   * @key - Looping component key of checkbox.
   * @control - Must-have elements for getting values from a form.
   * @name - Name of each checkbox.
   * @defaultValue - Default value of each checkbox. ( true or false )
   * @rules - Rules of each checkbox.
   * @render - Render target input component.
   * - field : Object containing field props, like name, value, onChange, onBlur.
   * - fieldState : Object containing field state, like error, invalid, etc.
   */
  return (
    <>
      <StyledCommonInputWrapper labelStyle={props?.labelStyle}>
        {(!!label || props.labelVisible !== false) && (
          <StyledCommonLabel
            labelStyle={props?.labelStyle}
            lbl-pd-right={props?.[`lbl-pd-right`]}
          >
            {!!label && (
              <>
                {!!required && (
                  <StyledCommonInputRequiredLabel>
                    *
                  </StyledCommonInputRequiredLabel>
                )}
                {label}
              </>
            )}
          </StyledCommonLabel>
        )}
        <div
          className={`${props?.width ? `${props?.width}` : 'w-full'} ${
            props?.borderVisible &&
            `border-[1px] ${
              formInvalid
                ? `!border-[1.2px] !border-[#C24D4D]`
                : `!border-[#D9D9D9] ${
                    !props?.disabled && `hover:border-[#8E9396]`
                  }`
            } rounded-[5px] h-[30px] flex justify-center items-center`
          } min-h-[30px]
          ${
            props?.borderVisible &&
            !props?.disabled &&
            `active:!border-[1.2px] active:border-solid active:border-[#264B9F] active:shadow-[0_0_4px_0_#8BBCE9]`
          }
          ${props?.customStyle?.bgColor && props?.customStyle?.bgColor}
          `}
        >
          <div
            className={`w-full flex 
          ${
            props?.alignCenter !== false && `justify-evenly items-center`
          } min-h-[30px]`}
          >
            {options?.map((item, index) => {
              return (
                <Controller
                  key={item?.inputName}
                  control={control}
                  name={item?.inputName}
                  defaultValue={item?.defaultValue}
                  rules={defaultInputRules({
                    required,
                    ...props,
                  })}
                  render={({
                    field: { ref, onChange, value, ...field },
                    fieldState: { invalid, error },
                  }) => {
                    // Package of handler props
                    const handlerProps = {
                      onChange,
                      value,
                      ...field,
                      ...props,
                    };

                    // Render Control Input Component
                    return (
                      <FormControlLabel
                        sx={{
                          margin: 0,
                          '.MuiTypography-root': {
                            fontSize: '12px',
                            color: '#383535',
                            fontWeight: '500',
                            marginLeft: '4px',
                          },
                        }}
                        control={
                          <Checkbox
                            {...field}
                            inputRef={ref}
                            // error={invalid}
                            icon={<CheckBoxOutlineBlankIcon />}
                            checkedIcon={<CheckBoxIcon />}
                            checked={!!value}
                            /**
                             * onClick Handler. Only handlers of parameters work.
                             * To change value, should use Controller field's onChange changer.
                             * ex)
                             * (tempValue) => {
                             *    onChange(tempValue);
                             * }
                             */
                            onChange={e => {
                              props?.onChangeHandler?.({
                                e,
                                onChange,
                                ...handlerProps,
                              });
                            }}
                            sx={{
                              border: 'unset',
                              color: '#C4C4C4',
                              width: '12px',
                              height: '12px',
                              '& .MuiSvgIcon-root': { fontSize: 16 },
                            }}
                            disabled={disabled}
                          />
                        }
                        label={item?.optionLabel}
                      />
                    );
                  }}
                />
              );
            })}
          </div>
        </div>
      </StyledCommonInputWrapper>
      {props?.helpTextVisible !== false && (
        <FormHelperText className="text-[#ff2424] text-[11px] font-normal min-h-[11px] h-[11px] mb-[3px] leading-none">
          {formErrorMessage}
        </FormHelperText>
      )}
    </>
  );
};

export default CommonCheckbox;
