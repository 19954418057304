const handleUserColsApply = ({
  mutate,
  appliedColumnList,
  category,
  status,
  buttonRef,
  isYard,
}) => {
  const patchData = {
    category,
    status,
    dsp_column: appliedColumnList,
  };

  mutate({ patchData, buttonRef, isYard });
};

export default handleUserColsApply;
