import { useQuery } from '@tanstack/react-query';
import getJobDetail from '../../../services/Trace/Job/Get/getJobDetail';
import traceKeys from '../keys';

const useGetTraceJobDetail = ({ seq }) => {
  const { data: traceJobDetail, isLoading: isTraceJobDetailLoading } = useQuery(
    {
      queryKey: traceKeys.jobDetail({ seq }),
      queryFn: () => getJobDetail({ seq }),
      refetchOnWindowFocus: false,
    },
  );

  return { traceJobDetail, isTraceJobDetailLoading };
};

export default useGetTraceJobDetail;
