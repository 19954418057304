import {
  PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
  PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
} from '../../../Common/path';

const EXPORT_COLORS = {
  exportColor1: {
    backgroundColor: '#FAD7D7',
    color: 'black',
    icon: null,
  },
  exportColor2: {
    backgroundColor: '#f5baba',
    color: 'black',
    icon: null,
  },
  exportColor3: {
    backgroundColor: '#F0A0A0',
    color: 'black',
    icon: null,
  },
  exportColor4: {
    backgroundColor: '#d96b6b',
    color: 'white',
    icon: {
      fold: PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
      unfold: PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
    },
  },
  exportColor5: {
    backgroundColor: '#cc4e4e',
    color: 'white',
    icon: {
      fold: PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
      unfold: PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
    },
  },
  exportColor6: {
    backgroundColor: '#a73232',
    color: 'white',
    icon: {
      fold: PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
      unfold: PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
    },
  },
  exportColor7: {
    backgroundColor: '#841818',
    color: 'white',
    icon: {
      fold: PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
      unfold: PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
    },
  },
  grey: {
    backgroundColor: '#979797',
    color: 'white',
    icon: {
      fold: PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
      unfold: PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
    },
  },
  darkGrey: {
    backgroundColor: '#646464',
    color: 'white',
    icon: {
      fold: PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
      unfold: PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
    },
  },
  yard: {
    backgroundColor: '#FFF4CF',
    color: 'black',
    icon: null,
  },
};

export { EXPORT_COLORS };
