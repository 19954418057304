import React from 'react';
import { useWatch } from 'react-hook-form';
import StyledHistoryLogWrapper from '../../../../../../styles/Dispatch/BookingModal/StyledHistoryLogWrapper';
import CommonRadioInput from '../../../../../Inputs/CommonRadioInput/CommonRadioInput';
import CommonTextInput from '../../../../../Inputs/CommonTextInput/CommonTextInput';
import CommonFile from '../../../../../Inputs/CommonFile/CommonFile';
import CommonMessageModalHeader from '../../../../../CommonMessageModal/CommonMessageModalHeader';

const HistoryLogSection = ({ title, field }) => {
  const reason = useWatch({ name: `${field}_category` });
  return (
    <div className="flex flex-col gap-y-[10px] py-[10px]">
      <CommonMessageModalHeader content={`Why do you change ${title}?`} />
      <StyledHistoryLogWrapper>
        <div className="flex items-center">
          <CommonRadioInput
            name={title}
            inputName={`${field}_category`}
            defaultValue={false}
            labelVisible={false}
            required
            onChangeHandler={({ e, onChange }) => {
              onChange?.(e?.target?.value);
            }}
            styles={{
              gap: 'gap-x-[25px]',
            }}
            options={[
              {
                label: 'Wrong Input',
                value: 'Wrong Input',
              },
              {
                label: 'Load Issue',
                value: 'Load Issue',
              },
              {
                label: 'Changed Schedule',
                value: 'Changed Schedule',
              },
              { label: 'Other', value: 'Other' },
            ]}
          />
          <div className="w-[150px] h-[30px] ml-[10px]">
            <CommonTextInput
              inputName={`${field}_reason`}
              disabled={reason !== 'Other'}
              onChangeHandler={({ e, onChange }) => {
                onChange?.(e?.target?.value);
              }}
              required={reason === 'Other'}
              labelVisible={false}
            />
          </div>
        </div>
        <div className="flex items-center gap-x-[10px]">
          <div>Evidence DOC</div>
          <div className="w-[210px]">
            <CommonFile inputName={`${field}_evidence`} />
          </div>
        </div>
      </StyledHistoryLogWrapper>
    </div>
  );
};

export default HistoryLogSection;
