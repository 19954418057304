import { useQuery } from '@tanstack/react-query';
import getAdminAuthList from '../../../services/Admin/Auth/getAuthList';
import adminKeys from '../keys';

const useGetAuthList = () => {
  const {
    isLoading: loadingAuthList,
    data,
    isRefetching: isRefetchingAuthList,
  } = useQuery({
    queryKey: adminKeys?.authList(),
    queryFn: getAdminAuthList,
    refetchOnWindowFocus: false,
  });

  return {
    loadingAuthList,
    data: data?.data,
    isRefetchingAuthList,
  };
};

export default useGetAuthList;
