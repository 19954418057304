import commonDefaultApi from '../../Common/api';

const getBookingNumber = async () => {
  try {
    const response = await commonDefaultApi.get('bk-list');
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export default getBookingNumber;
