import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import divBasicDefaultApi from '../../divApi';

const patchBGColorLBS = async ({ div, type, lbs }) => {
  try {
    const response = await divBasicDefaultApi.patch(`${div}/bg-color/lbs`, {
      type,
      lbs,
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchBGColorLBS;
