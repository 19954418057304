import React from 'react';
import dayjs from 'dayjs';
import CommonTextInput from '../../../SecondInputs/CommonTextInput/CommonTextInput';
import CommonDate from '../../../SecondInputs/CommonDate/CommonDate';
import CommonTime from '../../../SecondInputs/CommonTime/CommonTime';

const PullOut = ({
  basicInfoData,
  poScheduleDate,
  poScheduleTime,
  poActualInDate,
  poActualInTime,
  poActualOutDate,
  poActualOutTime,
  poDriver,
}) => {
  return (
    <div className="flex mt-[2px]">
      <div className="flex pl-[11px] items-center w-[72px] h-[30px] text-blue-800 text-[11px] font-bold shadow">
        P/O
      </div>
      <div className="ml-[2px] shadow">
        <CommonTextInput
          inputName="poLocationID"
          defaultValue={basicInfoData?.po_location?.company}
          width="w-[128px]"
          textCenter
          disabled
        />
      </div>
      <div className="ml-[2px] shadow">
        <CommonDate
          inputName="poScheduleDate"
          defaultValue={poScheduleDate}
          width="w-[140px]"
          textCenter
          disabled
        />
      </div>
      <div className="ml-[2px] shadow">
        <CommonTime
          inputName="poScheduleTime"
          defaultValue={
            poScheduleDate &&
            poScheduleTime &&
            dayjs(`${poScheduleDate} ${poScheduleTime}`)?.isValid()
              ? dayjs(`${poScheduleDate} ${poScheduleTime}`)
              : null
          }
          width="w-[110px]"
          textCenter
          disabled
        />
      </div>
      <div className="ml-[2px] shadow">
        <CommonDate
          inputName="poActualInDate"
          defaultValue={poActualInDate}
          width="w-[140px]"
          textCenter
          disabled
        />
      </div>
      <div className="ml-[2px] shadow">
        <CommonTime
          inputName="poActualInTime"
          defaultValue={
            poActualInDate &&
            poActualInTime &&
            dayjs(`${poActualInDate} ${poActualInTime}`)?.isValid()
              ? dayjs(`${poActualInDate} ${poActualInTime}`)
              : null
          }
          width="w-[110px]"
          textCenter
          disabled
        />
      </div>
      <div className="ml-[2px] shadow">
        <CommonDate
          inputName="poActualOutDate"
          defaultValue={poActualOutDate}
          width="w-[140px]"
          textCenter
          disabled
        />
      </div>
      <div className="ml-[2px] shadow">
        <CommonTime
          inputName="poActualOutTime"
          defaultValue={
            poActualOutDate &&
            poActualOutTime &&
            dayjs(`${poActualOutDate} ${poActualOutTime}`)?.isValid()
              ? dayjs(`${poActualOutDate} ${poActualOutTime}`)
              : null
          }
          width="w-[110px]"
          textCenter
          disabled
        />
      </div>
      <div className="ml-[2px] shadow">
        <CommonTextInput
          inputName="poDrvVen"
          defaultValue={poDriver}
          width="w-[116px]"
          textCenter
          disabled
        />
      </div>
    </div>
  );
};

export default PullOut;
