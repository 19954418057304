const handleVehicleCheckboxChange = ({
  division,
  vehicleType,
  vehicleName,
  checkedItems,
  setCheckedItems,
  traceList,
  setMarkerList,
}) => {
  const newCheckedItems = { ...checkedItems };

  if (!newCheckedItems[division]) {
    newCheckedItems[division] = { all: false, vehicleTypes: {} };
  }

  if (!newCheckedItems[division].vehicleTypes[vehicleType]) {
    newCheckedItems[division].vehicleTypes[vehicleType] = {
      all: false,
      vehicles: {},
    };
  }

  const isChecked =
    !newCheckedItems[division].vehicleTypes[vehicleType].vehicles[vehicleName];

  newCheckedItems[division].vehicleTypes[vehicleType].vehicles[vehicleName] =
    isChecked;

  setCheckedItems(newCheckedItems);

  const markerToUpdate = traceList[division][vehicleType].find(
    marker => marker.name === vehicleName,
  );

  if (!markerToUpdate) return;

  setMarkerList(prevMarkers => {
    if (isChecked) {
      const markerExists = prevMarkers.some(
        marker => marker.id === markerToUpdate.id,
      );
      if (markerExists) {
        return prevMarkers.map(marker =>
          marker.id === markerToUpdate.id
            ? { ...marker, ...markerToUpdate }
            : marker,
        );
      }
      return [...prevMarkers, markerToUpdate];
    }
    return prevMarkers.filter(marker => marker.id !== markerToUpdate.id);
  });
};

export default handleVehicleCheckboxChange;
