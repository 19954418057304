import React from 'react';
import ScheduleMobileModalContentInfoHeader from '../ScheduleMobileModalContentInfoHeader';
import ScheduleMobileModalContentInfoRow from '../ScheduleMobileModalContentInfoRow';

const ScheduleMobileModalContentInfo = ({ ...props }) => {
  /** Get selected data */
  const { mobileMemoData } = props;

  return (
    <div className="w-full">
      <ScheduleMobileModalContentInfoHeader />
      <div className="flex flex-col gap-[2px]">
        {/* Render from location row */}
        {mobileMemoData?.from_location && (
          <ScheduleMobileModalContentInfoRow
            rowData={mobileMemoData?.from_location}
          />
        )}
        {/* Render live dilv location row */}
        {mobileMemoData?.live_dilv_location && (
          <ScheduleMobileModalContentInfoRow
            rowData={mobileMemoData?.live_dilv_location}
          />
        )}
        {/* Render to location row */}
        {mobileMemoData?.to_location && (
          <ScheduleMobileModalContentInfoRow
            rowData={mobileMemoData?.to_location}
          />
        )}
      </div>
    </div>
  );
};

export default ScheduleMobileModalContentInfo;
