import axios from 'axios';
import interceptRequest from '../../utils/Common/Interceptor/interceptRequest';
/**
 * DIV_BASIC base url
 */
const DIV_BASIC_BASE_URL = `${process.env.REACT_APP_BASE_URL}/div-manager/`;

/**
 * Axios object with base url
 */
const divBasicDefaultApi = axios.create({
  baseURL: DIV_BASIC_BASE_URL,
  withCredentials: true,
  xsrfHeaderName: 'X-CSRFTOKEN',
  xsrfCookieName: 'XCSRF-TOKEN',
  headers: {
    common: {
      'X-CSRFToken': localStorage.getItem('csrfToken'),
    },
  },
});

/** Interceptors */
interceptRequest(divBasicDefaultApi);

export default divBasicDefaultApi;
