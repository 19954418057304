import dayjs from 'dayjs';
import { CELL_TYPE_DATE } from '../../../../constants/Common/DataGrid/cellType';
import cellEditorGenerator from '../../../Common/Datagrid/Generator/cellEditorGenerator';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';
import FloatingFilterInput from '../../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterInput';
import {
  getDateFontColor,
  getDateFontWeight,
} from '../../../TmsReport/Common/dateStyleGenerator';
import dateComparator from '../../../Common/Datagrid/Comparator/dateComparator';

const getEditableDateDef = ({ field }) => {
  return {
    cellClass: 'date',
    comparator: dateComparator,
    floatingFilterComponent: FloatingFilterInput,
    floatingFilterComponentParams: {
      placeholder: 'MM/DD/YY',
      inputType: 'date',
      maxLength: 8,
    },
    cellEditorSelector: gridParam =>
      cellEditorGenerator({
        gridParam,
        cellType: CELL_TYPE_DATE,
      }),
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        cellType: CELL_TYPE_DATE,
        customStyle: {
          color: getDateFontColor({ field, data: gridParam?.data }),
          fontWeight: getDateFontWeight({ field, data: gridParam?.data }),
        },
      }),
    valueSetter: params => {
      const input = params?.newValue
        ? dayjs(params?.newValue).format('YYYY-MM-DD')
        : null;
      if (params.data?.[field] !== input) {
        params.data[field] = input;
        return true;
      }
      return false;
    },
    valueGetter: params => {
      const dateValue = params.data?.[field];
      return dateValue ? dayjs(dateValue).format('MM/DD/YY') : null;
    },
  };
};

export default getEditableDateDef;
