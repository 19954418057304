const handleBookingSelectChange = ({
  e,
  subCategory,
  onChange,
  selectProps,
  bookingSelectOptionAccordingToType,
  dataKey,
  setContainerList,
  targetProperty,
  ...handlerProps
}) => {
  // Set current item value
  onChange?.(e?.target?.value);

  setContainerList?.(prev =>
    prev?.map(element =>
      element?.containerKey === dataKey
        ? { ...element, [targetProperty]: e?.target?.value }
        : element,
    ),
  );
};
export default handleBookingSelectChange;
