import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';
import bcDetailDefaultApi from '../../../api';

const getDriverList = async ({ woNo, driver }) => {
  try {
    const response = await bcDetailDefaultApi.get(
      `${woNo}/assign-driver/driver-list?page=bcdetail${driver}`,
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getDriverList;
