import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import bcDetailDefaultApi from '../../api';

const getTrackingInfo = async ({ woNo }) => {
  try {
    const response = await bcDetailDefaultApi.get(`${woNo}/tracking`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getTrackingInfo;
