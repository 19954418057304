import React from 'react';

const CommonAlertModalContent = ({ children, ...props }) => {
  return (
    <div className="w-full h-full bg-[#F9F9F9] p-[20px] text-[#222] text-[12px] flex justify-center items-center">
      {children}
    </div>
  );
};

export default CommonAlertModalContent;
