export const RECONCILE_STATUS = [
  { label: 'Waiting', value: 'RS0001' },
  { label: 'Review', value: 'RS0002' },
  { label: 'Disputed', value: 'RS0003' },
  { label: 'Completed', value: 'RS0004' },
  { label: 'Denied', value: 'RS0005' },
];

export const RECONCILE_STATUS_OPTION = [
  { label: 'Waiting', value: 'Waiting' },
  { label: 'Review', value: 'Review' },
  { label: 'Disputed', value: 'Disputed' },
  { label: 'Completed', value: 'Completed' },
  { label: 'Denied', value: 'Denied' },
];
