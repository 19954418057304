const handlePDFLocationColor = ({
  setValue,
  getValues,
  type,
  ordering,
  company,
}) => {
  const id = `${type}_${ordering}`;

  const puFrom = getValues('pu_from');
  const dilvTo = getValues('dilv_to');

  // Reset color
  if (puFrom && puFrom?.id === id) {
    setValue('pu_from', null);
    return;
  }
  if (dilvTo && dilvTo?.id === id) {
    setValue('dilv_to', null);
    return;
  }

  // Set color
  if (puFrom && dilvTo) return;

  if (!puFrom) {
    setValue('pu_from', { id, company });
  } else {
    setValue('dilv_to', { id, company });
  }
};

export default handlePDFLocationColor;
