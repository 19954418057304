import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SUCCESS_DELETE } from '../../../constants/Common/successMessage';
import deleteAPRate from '../../../services/Master/APRate/Delete/deleteAPRate';
import useControlToast from '../../Common/Toast/useControlToast';
import masterKeys from '../keys';

const useDeleteAPRateMutation = ({
  setIsMessageOpen,
  setMessage,
  div,
  setIsSuccess,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  return useMutation({
    mutationFn: deleteAPRate,
    onSuccess: res => {
      queryClient.refetchQueries({ queryKey: masterKeys?.apRateList({ div }) });
      addToast({
        message: SUCCESS_DELETE,
      });
    },
    onError: error => {
      queryClient.refetchQueries({ queryKey: masterKeys?.apRateList({ div }) });
      setIsSuccess(false);
      setMessage(error.message);
      setIsMessageOpen(true);
    },
  });
};

export default useDeleteAPRateMutation;
