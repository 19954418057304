import { useQuery } from '@tanstack/react-query';
import {
  DISPATCH_PAGE_EXPORT_BOOKING,
  DISPATCH_TYPE_BOOKING,
  DISPATCH_TYPE_EXPORT,
} from '../../../constants/Dispatch/Common/category';
import getBookingButtonCount from '../../../services/Dispatch/Common/get/getBookingButtonCount';
import generateInitAdvancedForm from '../../../utils/Dispatch/Common/Generator/generateInitAdvancedForm';
import getQueries from '../../../utils/Dispatch/Common/Generator/getQueries';
import dispatchKeys from '../../Dispatch2/keys';

const useGetBookingButtonCount = ({
  category,
  division,
  queryObject,
  status,
  filterStatus,
}) => {
  const paramQueries = getQueries({
    queryObject:
      status === DISPATCH_PAGE_EXPORT_BOOKING && status === filterStatus
        ? queryObject
        : generateInitAdvancedForm({
            category: DISPATCH_TYPE_BOOKING,
            woStatus: DISPATCH_TYPE_BOOKING,
          }),
  });
  const result = useQuery({
    queryKey: dispatchKeys.bookingCountDivQuery({ division, paramQueries }),
    queryFn: () => getBookingButtonCount({ paramDiv: division, paramQueries }),
    refetchOnWindowFocus: false,
    enabled:
      !!division &&
      [DISPATCH_TYPE_EXPORT, DISPATCH_TYPE_BOOKING]?.includes(category) &&
      !!paramQueries?.status,
  });
  return result;
};

export default useGetBookingButtonCount;
