import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import getCompanyList from '../../../../services/BCDetail/BillingInfo/AR/Get/getCompanyList';
import bcDetailKeys from '../../keys';

const useGetCompanyList = ({ setIsAlertOpen, setErrorMessage }) => {
  const [companyList, setCompanyList] = useState([]);

  const query = useQuery({
    queryKey: bcDetailKeys.billingInfoARDriverVendor(),
    queryFn: () => getCompanyList(),
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    if (query?.data?.data) {
      setCompanyList(query?.data?.data);
    }
  }, [query?.data]);

  useEffect(() => {
    if (query?.error) {
      setIsAlertOpen(true);
      setErrorMessage('Failed to get AR company list.');
    }
  }, [query?.error]);

  return { companyList, ...query };
};

export default useGetCompanyList;
