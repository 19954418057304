import { FormHelperText, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { Controller, useFormContext } from 'react-hook-form';
import defaultInputRules from '../../../../utils/Common/Input/defaultInputRules';
import { getPlaceDetails } from '../../../../utils/Master/Common/getPlaceDetails';

const AutoCompleteAddress = ({
  place,
  setPlace,
  onPlaceSelect,
  inputName,
  ...props
}) => {
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    useGoogle({
      apiKey: process.env.REACT_APP_GOOGLE_KEY,
      options: {
        language: 'en',
      },
    });

  const {
    control,
    setValue,
    formState: { errors: formErrors },
  } = useFormContext();

  const [defaultValue, setDefaultValue] = useState(props?.defaultValue || '');
  useEffect(() => {
    if (props?.defaultValue !== undefined && props?.defaultValue !== null) {
      setDefaultValue(props?.defaultValue);
      setValue(inputName, props?.defaultValue);
    }
  }, [props?.defaultValue]);

  const [disabled, setDisabled] = useState(props?.disabled || false);
  useEffect(() => {
    if (props?.disabled !== undefined && props?.disabled !== null) {
      setDisabled(props?.disabled);
    }
  }, [props?.disabled]);

  const [backgroundColor, setBackgroundColor] = useState(
    props?.backgroundColor || '#ffffff',
  );
  useEffect(() => {
    if (
      props?.backgroundColor !== undefined &&
      props?.backgroundColor !== null
    ) {
      setBackgroundColor(props?.backgroundColor);
    }
  }, [props?.backgroundColor]);

  const [chosen, setChosen] = useState('');
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = e => {
      if (wrapperRef?.current && !wrapperRef?.current?.contains(e?.target)) {
        setChosen('');
      }
    };
    document.body.addEventListener('click', handleOutsideClick);
    return () => {
      document.body.removeEventListener('click', handleOutsideClick);
    };
  }, [setChosen]);

  const handlePlaceClick = async item => {
    setChosen('');
    setPlace(item?.structured_formatting?.main_text);
    const placeDetails = await getPlaceDetails(item?.place_id);
    const zipCode = placeDetails?.address_components?.find(component =>
      component.types.includes('postal_code'),
    );
    const city = placeDetails?.address_components?.find(component =>
      component.types.includes('locality'),
    );
    const state = placeDetails?.address_components?.find(component =>
      component.types.includes('administrative_area_level_1'),
    );
    const country = placeDetails?.address_components?.find(component =>
      component.types.includes('country'),
    );
    const lat = placeDetails?.geometry?.location?.lat;
    const lng = placeDetails?.geometry?.location?.lng;
    onPlaceSelect({
      placeId: item?.place_id || '',
      address: item?.structured_formatting?.main_text || '',
      zipcode: zipCode?.short_name || '',
      city: city?.short_name || '',
      state: state?.short_name || '',
      country: country?.short_name || '',
      lat,
      lng,
      timezone: '',
    });
  };

  return (
    <Controller
      control={control}
      name={inputName}
      defaultValue={defaultValue}
      rules={defaultInputRules({ ...props })}
      render={({
        field: { ref, onChange, value, ...field },
        fieldState: { invalid, error },
      }) => {
        const handlerProps = {
          onChange,
          value,
          ...field,
          ...props,
        };

        return (
          <>
            <div ref={wrapperRef} className="relative flex flex-col w-full">
              <p className="text-[11px] font-[500] text-[#595656] mb-[3px]">
                <span className="text-[#FF2424]">*</span> ADDRESS 01
              </p>
              <TextField
                inputRef={ref}
                variant="outlined"
                error={invalid}
                className={`border-solid border-[#D9D9D9] h-[30px] rounded-[5px] text-xs text-black w-full ${
                  disabled ? 'bg-[#F9F9F9]' : 'bg-white'
                }
              hover:border-[#8E9396]`}
                sx={{
                  input: {
                    zIndex: '999',
                  },
                  '.MuiInputBase-root': {
                    height: '30px',
                    fontSize: '12px',
                    fontWeight: '500',
                    '&.Mui-focused fieldset': {
                      border: '1.2px solid #264B9F !important',
                      boxShadow: '0px 0px 4px 0px #8BBCE9 !important',
                    },
                    '&.Mui-error fieldset': {
                      border: '1.2px solid #C24D4D !important',
                    },
                    '&:not(.Mui-focused):not(.Mui-disabled):hover fieldset':
                      !formErrors?.[props?.inputName]?.message && {
                        borderColor: '#8E9396 !important',
                      },
                    '&.Mui-disabled fieldset': {
                      backgroundColor: 'unset !important',
                    },
                  },
                  fieldset: {
                    borderColor: '#D9D9D9 !important',
                    backgroundColor: `${backgroundColor} !important`,
                  },
                }}
                value={value}
                onChange={e => {
                  props?.onChangeHandler?.({
                    e,
                    ...handlerProps,
                  });
                  getPlacePredictions({ input: e?.target?.value });
                  setChosen(e?.target?.value);
                }}
                onClick={e => props?.onClickHandler?.({ e, ...handlerProps })}
                disabled={disabled}
                placeholder={props?.placeholder || ''}
                onBlur={e => {
                  if (e?.target?.value !== place) {
                    onChange(place || '');
                  }
                }}
                // required={required}
              />
              {chosen && (
                <div
                  role="listbox"
                  className="absolute h-[10rem] left-0 overflow-x-hidden flex whitespace-nowrap flex-col w-full my-1 bg-white border z-[9999] rounded top-[2.4em]"
                >
                  {!isPlacePredictionsLoading && (
                    <ul>
                      {placePredictions.map(item => (
                        <div
                          key={item?.place_id}
                          className="cursor-pointer text-[12px] py-2.5 hover:bg-[#D9D9D9] px-2 "
                          onClick={() => {
                            setChosen('');
                            setPlace(item?.structured_formatting?.main_text);
                            handlePlaceClick(item);
                          }}
                        >
                          {item?.description}
                        </div>
                      ))}
                    </ul>
                  )}
                </div>
              )}
            </div>
            <FormHelperText className="text-[#ff2424] text-[11px] font-normal min-h-[11px] h-[11px] mb-[3px] leading-none">
              {error?.message}
            </FormHelperText>
          </>
        );
      }}
    />
  );
};

export default AutoCompleteAddress;
