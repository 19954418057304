import React from 'react';
import getAutoCompleteComponent from './getAutoCompleteComponent';
import getCheckboxComponent from './getCheckboxComponent';
import getDateComponent from './getDateComponent';
import getFileComponent from './getFileComponent';
import getMultipleInputComponent from './getMultipleInputComponent';
import getSelectComponent from './getSelectComponent';
import getTextInputComponent from './getTextInputComponent';
import getTextSelectComponent from './getTextSelectComponent';
import getTimeComponent from './getTimeComponent';
import getToggleComponent from './getToggleComponent';
import getTextareaComponent from './getTextareaComponent';
import getRadioInputComponent from './getRadioInputComponent';

const getRenderComponent = ({ inputType, ...props }) => {
  if (inputType === 'TextInput') {
    return getTextInputComponent({ ...props });
  }
  if (inputType === 'TextInputAdornmentStartText') {
    return getTextInputComponent({
      ...props,
      adornment: {
        position: 'start',
        content: '$',
        styles: {
          fontSize: '12px',
          color: 'blue',
          fontWeight: '700',
        },
      },
    });
  }
  if (inputType === 'TextInputAdornmentStartTextOnClick') {
    return getTextInputComponent({
      ...props,
      adornment: {
        position: 'start',
        content: (
          <div
            className="text-[12px] font-bold text-blue-500 cursor-pointer"
            onClick={() => console.log('Start Text OnClick')}
          >
            $
          </div>
        ),
      },
    });
  }
  if (inputType === 'TextInputAdornmentStartIcon') {
    return getTextInputComponent({
      ...props,
      adornment: {
        position: 'start',
        content: (
          <img
            src="/assets/images/buttonIcon/redCrossOutlineUnselected.svg"
            alt="testIcon"
            className="min-w-[12px] w-[12px] min-h-[12px] h-[12px] aspect-none"
          />
        ),
      },
    });
  }
  if (inputType === 'TextInputAdornmentStartIconOnClick') {
    return getTextInputComponent({
      ...props,
      adornment: {
        position: 'start',
        content: (
          <div
            className='className="min-w-[12px] w-[12px] min-h-[12px] h-[12px] cursor-pointer'
            onClick={() => console.log('Start Icon OnClick')}
          >
            <img
              src="/assets/images/buttonIcon/redCrossOutlineUnselected.svg"
              alt="testIcon"
              className="min-w-[12px] w-[12px] min-h-[12px] h-[12px] aspect-none"
            />
          </div>
        ),
      },
    });
  }
  if (inputType === 'TextInputAdornmentEndText') {
    return getTextInputComponent({
      ...props,
      adornment: {
        position: 'end',
        content: '$',
        styles: {
          fontSize: '12px',
          color: 'blue',
          fontWeight: '700',
        },
      },
    });
  }
  if (inputType === 'TextInputAdornmentEndTextOnClick') {
    return getTextInputComponent({
      ...props,
      adornment: {
        position: 'end',
        content: (
          <div
            className="text-[12px] font-bold text-blue-500 cursor-pointer"
            onClick={() => console.log('Start Text OnClick')}
          >
            $
          </div>
        ),
      },
    });
  }
  if (inputType === 'TextInputAdornmentEndIcon') {
    return getTextInputComponent({
      ...props,
      adornment: {
        position: 'end',
        content: (
          <img
            src="/assets/images/buttonIcon/redCrossOutlineUnselected.svg"
            alt="testIcon"
            className="min-w-[12px] w-[12px] min-h-[12px] h-[12px] aspect-none"
          />
        ),
      },
    });
  }
  if (inputType === 'TextInputAdornmentEndIconOnClick') {
    return getTextInputComponent({
      ...props,
      adornment: {
        position: 'end',
        content: (
          <div
            className='className="min-w-[12px] w-[12px] min-h-[12px] h-[12px] cursor-pointer'
            onClick={() => console.log('End Icon OnClick')}
          >
            <img
              src="/assets/images/buttonIcon/redCrossOutlineUnselected.svg"
              alt="testIcon"
              className="min-w-[12px] w-[12px] min-h-[12px] h-[12px] aspect-none"
            />
          </div>
        ),
      },
    });
  }
  if (inputType === 'Select') {
    return getSelectComponent({ ...props });
  }
  if (inputType === 'AutoComplete') {
    return getAutoCompleteComponent({ ...props });
  }
  if (inputType === 'Checkbox') {
    return getCheckboxComponent({ ...props });
  }
  if (inputType === 'Toggle') {
    return getToggleComponent({ ...props });
  }
  if (inputType === 'MultipleInput') {
    return getMultipleInputComponent({ ...props });
  }
  if (inputType === 'Date') {
    return getDateComponent({ ...props });
  }
  if (inputType === 'Time') {
    return getTimeComponent({ ...props });
  }
  if (inputType === 'TextSelect') {
    return getTextSelectComponent({ ...props });
  }
  if (inputType === 'File') {
    return getFileComponent({ ...props });
  }
  if (inputType === 'Textarea') {
    return getTextareaComponent({ ...props });
  }
  if (inputType === 'Radio Button') {
    return getRadioInputComponent({ ...props });
  }
  return null;
};

export default getRenderComponent;
