import { useMutation } from '@tanstack/react-query';
import createServiceCode from '../../../services/Master/ServiceCode/Post/createServiceCode';
import masterKeys from '../keys';
import useControlToast from '../../Common/Toast/useControlToast';
import { SUCCESS_SAVE } from '../../../constants/Common/successMessage';

const usePostServiceCode = ({
  setMessageContent,
  setMessageModalOpen,
  queryClient,
  division,
  gridRef,
}) => {
  const { addToast } = useControlToast();

  const mutator = useMutation({
    mutationFn: createServiceCode,
    onSettled: (data, error, variables) => {
      variables?.setAddDisabled(false);
    },
    refetchOnWindowFocus: false,
    onSuccess: () => {
      const gridAPI = gridRef?.current?.api;
      const topRow = gridAPI.getPinnedTopRow(0);
      topRow.setData({});
      gridAPI?.redrawRows({ rowNodes: [topRow] });
      queryClient.invalidateQueries({
        queryKey: masterKeys.serviceCodeList({ division }),
      });
      addToast({ message: SUCCESS_SAVE });
    },
    onError: error => {
      setMessageContent(error.message);
      setMessageModalOpen(true);
    },
  });
  return mutator;
};

export default usePostServiceCode;
