import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import getMovingList from '../../../services/Trace/Map/Get/getMovingList';
import traceKeys from '../keys';

const useGetTraceMarkerList = () => {
  const queryClient = useQueryClient();

  const { data: traceMarkerList } = useQuery({
    queryKey: traceKeys.markerList(),
    queryFn: () => getMovingList({ target: '' }),
    refetchOnWindowFocus: false,
  });

  const refetchMapListPeriodically = async () => {
    await queryClient.refetchQueries({
      queryKey: traceKeys.markerList(),
    });
  };

  useEffect(() => {
    const intervalId = setInterval(refetchMapListPeriodically, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return { traceMarkerList };
};

export default useGetTraceMarkerList;
