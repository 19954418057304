import { useMutation } from '@tanstack/react-query';
import { SUCCESS_UPDATE } from '../../../constants/Common/successMessage';
import updateChassisRate from '../../../services/Master/ChassisRate/Patch/updateChassisRate';
import resetRow from '../../../utils/Common/Handler/resetRow';
import updateGridData from '../../../utils/Common/Handler/updateGridData';
import useControlToast from '../../Common/Toast/useControlToast';

const useUpdateChassisRateMutation = ({
  setIsMessageOpen,
  setMessage,
  div,
  setIsSuccess,
  gridRef,
}) => {
  const { addToast } = useControlToast();

  return useMutation({
    mutationFn: updateChassisRate,
    onSuccess: (res, variables) => {
      updateGridData({
        gridRef,
        newData: variables.data,
        isUpdate: true,
      });
      addToast({
        message: SUCCESS_UPDATE,
      });
    },
    onError: error => {
      resetRow({ gridRef });
      setIsSuccess(false);
      setMessage(error.message);
      setIsMessageOpen(true);
    },
  });
};

export default useUpdateChassisRateMutation;
