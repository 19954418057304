import { useMutation, useQueryClient } from '@tanstack/react-query';
import postAssignDriverAPInput from '../../../../services/BCDetail/DetailInfo/Schedule/Post/postAssignDriverAPInput';
import bcDetailKeys from '../../keys';
import useControlToast from '../../../Common/Toast/useControlToast';
import { SUCCESS_SAVE } from '../../../../constants/Common/successMessage';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';

const usePostDualAssignDriverAPInput = ({ ...props }) => {
  const { setOpen, setMessageModalOpen, setMessageModalMessage } = props;

  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  const mutation = useMutation({
    mutationFn: postAssignDriverAPInput,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: bcDetailKeys?.defaultDualJobPullOut(),
      });
      queryClient.invalidateQueries({
        queryKey: bcDetailKeys?.defaultDualJob(),
      });
      queryClient?.invalidateQueries({
        queryKey: bcDetailKeys?.defaultDetailInfo(),
      });
      addToast({ message: SUCCESS_SAVE });
      setOpen(prev => false);
    },
    onError: error => {
      setMessageModalMessage(error?.message);
      setMessageModalOpen(prev => true);
    },
  });

  return { ...mutation };
};

export default usePostDualAssignDriverAPInput;
