import React from 'react';
import CommonButton from '../../../../CommonButton/CommonButton';
import handleActionClick from '../../../../../utils/MobileAdmin/BillConfirm/Handler/handleActionClick';

const ConfirmCellRenderer = ({ ...props }) => {
  const disabled = props?.data?.confirm_status !== 'IN REVIEW';
  return (
    <div className="flex gap-x-[15px] justify-center items-center h-full">
      <CommonButton
        onClick={() =>
          handleActionClick({
            action: 'reject',
            seq: props?.data?.seq,
            ...props,
          })
        }
        disabled={disabled}
        type="button"
        category="cancel"
        size="small"
      >
        Reject
      </CommonButton>
      <CommonButton
        onClick={() =>
          handleActionClick({
            action: 'approve',
            seq: props?.data?.seq,
            ...props,
          })
        }
        disabled={disabled}
        type="button"
        category="approval"
        size="small"
      >
        Approval
      </CommonButton>
    </div>
  );
};

export default ConfirmCellRenderer;
