import React from 'react';
import dayjs from 'dayjs';
import CommonTextInput from '../../../SecondInputs/CommonTextInput/CommonTextInput';
import CommonDate from '../../../SecondInputs/CommonDate/CommonDate';
import CommonTime from '../../../SecondInputs/CommonTime/CommonTime';

const Delivery = ({
  basicInfoData,
  dilvScheduleDate,
  dilvScheduleTime,
  dilvActualInDate,
  dilvActualInTime,
  dilvActualOutDate,
  dilvActualOutTime,
  dilvDriver,
}) => {
  return (
    <div className="flex mt-[2px]">
      <div className="flex pl-[11px] items-center w-[72px] h-[30px] text-blue-800 text-[11px] font-bold shadow">
        DILV
      </div>
      <div className="ml-[2px] shadow">
        <CommonTextInput
          inputName="dilvLocationID"
          defaultValue={basicInfoData?.dilv_location?.company}
          width="w-[128px]"
          textCenter
          disabled
        />
      </div>
      <div className="ml-[2px] shadow">
        <CommonDate
          inputName="dilvScheduleDate"
          defaultValue={dilvScheduleDate}
          width="w-[140px]"
          textCenter
          disabled
        />
      </div>
      <div className="ml-[2px] shadow">
        <CommonTime
          inputName="dilvScheduleTime"
          defaultValue={
            dilvScheduleDate &&
            dilvScheduleTime &&
            dayjs(`${dilvScheduleDate} ${dilvScheduleTime}`)?.isValid()
              ? dayjs(`${dilvScheduleDate} ${dilvScheduleTime}`)
              : null
          }
          width="w-[110px]"
          textCenter
          disabled
        />
      </div>
      <div className="ml-[2px] shadow">
        <CommonDate
          inputName="dilvActualInDate"
          defaultValue={dilvActualInDate}
          width="w-[140px]"
          textCenter
          disabled
        />
      </div>
      <div className="ml-[2px] shadow">
        <CommonTime
          inputName="dilvActualInTime"
          defaultValue={
            dilvActualInDate &&
            dilvActualInTime &&
            dayjs(`${dilvActualInDate} ${dilvActualInTime}`)?.isValid()
              ? dayjs(`${dilvActualInDate} ${dilvActualInTime}`)
              : null
          }
          width="w-[110px]"
          textCenter
          disabled
        />
      </div>
      <div className="ml-[2px] shadow">
        <CommonDate
          inputName="dilvActualOutDate"
          defaultValue={dilvActualOutDate}
          width="w-[140px]"
          textCenter
          disabled
        />
      </div>
      <div className="ml-[2px] shadow">
        <CommonTime
          inputName="dilvActualOutTime"
          defaultValue={
            dilvActualOutDate &&
            dilvActualOutTime &&
            dayjs(`${dilvActualOutDate} ${dilvActualOutTime}`)?.isValid()
              ? dayjs(`${dilvActualOutDate} ${dilvActualOutTime}`)
              : null
          }
          width="w-[110px]"
          textCenter
          disabled
        />
      </div>
      <div className="ml-[2px] shadow">
        <CommonTextInput
          inputName="dilvDrvVen"
          defaultValue={dilvDriver}
          width="w-[116px]"
          textCenter
          disabled
        />
      </div>
    </div>
  );
};

export default Delivery;
