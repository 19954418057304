import convertQBPostData from '../../QBAPAR/Handler/convertQBPostData';
import { DRIVER_CATEGORY } from '../../../../constants/Acct/Common/acctElement';
import { ACCT_PAGE } from '../../../../constants/Acct/Common/staticNames';
import updateButtonDisabled from '../../../Common/Handler/updateButtonDisabled';

const checkCompanyDriver = data => {
  if (
    data?.category === 'DRIVER' &&
    data?.category_type === DRIVER_CATEGORY?.COMPANY
  ) {
    return true;
  }
  return false;
};

const handleQuickBook = ({
  buttonRef,
  page,
  gridRef,
  handlerPostQuickBooks,
  errorModalOptions,
}) => {
  const { setIsSuccess, setErrorMessage, setIsErrorModalOpen } =
    errorModalOptions;

  const selectedRows = gridRef?.current?.api
    ?.getSelectedNodes()
    ?.filter(row => row?.displayed)
    ?.map(row => convertQBPostData(row?.data));

  const notCompanyDriverRows = selectedRows?.filter(
    row => !checkCompanyDriver(row),
  );

  if (!selectedRows || selectedRows?.length === 0) {
    setIsSuccess(false);
    setErrorMessage('Please select at least one item.');
    setIsErrorModalOpen(true);
    updateButtonDisabled({ ref: buttonRef, disabled: false });
    return;
  }

  if (page === ACCT_PAGE?.qbAPAR) {
    if (selectedRows?.length !== notCompanyDriverRows?.length) {
      setIsSuccess(false);
      setErrorMessage('Please uncheck company driver.');
      setIsErrorModalOpen(true);
      updateButtonDisabled({ ref: buttonRef, disabled: false });
      return;
    }
  }

  if (notCompanyDriverRows?.length === 0 || !notCompanyDriverRows) {
    setIsSuccess(false);
    setErrorMessage('Please select at least one Quickbooks sent item.');
    setIsErrorModalOpen(true);
    updateButtonDisabled({ ref: buttonRef, disabled: false });
    return;
  }

  const submitData = notCompanyDriverRows?.map(row => ({
    ...row?.submitData,
    type: row?.type,
  }));

  handlerPostQuickBooks?.mutate({ submitData, buttonRef });
  gridRef?.current?.api?.deselectAll();
};

export default handleQuickBook;
