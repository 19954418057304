import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import bcDetailKeys from '../../keys';
import getProofPDF from '../../../../services/BCDetail/DetailInfo/Common/Get/getProofPDF';
import getBLPDF from '../../../../services/BCDetail/DetailInfo/Common/Get/getBLPDF';
import openPDFViewer from '../../../../utils/BCDetail/DetailInfo/Common/openPDFViewer';

const useGetPDF = ({
  woNo,
  pageCategory,
  from,
  to,
  setMessage,
  setErrorModalOpen,
}) => {
  const query = useQuery({
    queryKey: bcDetailKeys?.woPDFLink({ woNo, from, to }),
    queryFn: () => {
      if (pageCategory === 'van' || pageCategory === 'brokerage')
        return getBLPDF({ woNo, from: from?.company, to: to?.company });
      return getProofPDF({ woNo, from: from?.company, to: to?.company });
    },
    refetchOnWindowFocus: false,
    enabled: false,
    retry: false,
  });

  useEffect(() => {
    if (query?.data?.data && query?.isFetchedAfterMount) {
      openPDFViewer(query?.data?.data, setErrorModalOpen, setMessage);
    }
    if (query?.error) {
      setMessage(query?.error?.message);
      setErrorModalOpen(true);
    }
  }, [query?.data]);

  useEffect(() => {
    if (query?.error) {
      setMessage(query?.error?.message);
      setErrorModalOpen(true);
    }
  }, [query?.error]);

  return query;
};

export default useGetPDF;
