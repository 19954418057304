import updateButtonDisabled from '../../../Common/Handler/updateButtonDisabled';

const handleConfirmClick = ({
  patchStatusMutator,
  confirmContent,
  division,
  setOpen,
  setMessage,
  setAlertModalOpen,
  buttonRef,
}) => {
  const seqList = [];
  let isNotReview = false;

  if (confirmContent?.seq) {
    seqList.push(confirmContent?.seq);
  }

  if (confirmContent?.rows) {
    confirmContent?.rows.every(data => {
      if (data?.confirm_status !== 'IN REVIEW') {
        isNotReview = true;
        return false;
      }
      seqList.push(data?.seq);
      return true;
    });
  }

  if (isNotReview) {
    setMessage('Please select only row having "IN REVIEW" status.');
    setAlertModalOpen(true);
    updateButtonDisabled({ ref: buttonRef, disabled: false });
  } else {
    const patchData = {
      action: confirmContent?.action,
      seq_list: seqList,
    };

    patchStatusMutator.mutate({ data: patchData, division, buttonRef });
  }

  setOpen(false);
};

export default handleConfirmClick;
