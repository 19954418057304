import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import getARProductCode from '../../../../services/BCDetail/BillingInfo/AR/Get/getARProductCode';
import bcDetailKeys from '../../keys';

const useGetARProductCode = ({
  setIsAlertOpen,
  setErrorMessage,
  setIsDeletedWO,
}) => {
  const [productCode, setProductCode] = useState([]);

  const query = useQuery({
    queryKey: bcDetailKeys.billingInfoARProductCode(),
    queryFn: () => getARProductCode(),
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    if (query?.data?.data) {
      setProductCode(query?.data?.data);
    }
  }, [query?.data]);

  useEffect(() => {
    if (query?.error) {
      setIsAlertOpen(true);
      setErrorMessage(query?.error?.message);
      setIsDeletedWO(true);
    }
  }, [query?.error]);

  return { ...query, productCode };
};

export default useGetARProductCode;
