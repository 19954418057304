import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import arRateAPI from '../api';

/**
 * Deletes ar rate data in the backend for a given sequence number.
 * @param {string|number} seq - The sequence number of the ar rate data to be deleted.
 * @returns {boolean} - True if the deletion is successful, false on error.
 */
const deleteARRate = async ({ seq }) => {
  try {
    await arRateAPI.delete(`/${seq}`);
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default deleteARRate;
