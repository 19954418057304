import React, { memo, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import checkCurrentMenu from '../../../../../utils/Common/Sidebar/Handler/checkCurrentMenu';
import { closeSidebar } from '../../../../../utils/Common/Sidebar/Handler/handleSidebarVisible';
import StyledSidebarSubMenuItem from '../../../../../styles/Common/Sidebar/StyledSidebarSubMenuItem';

/**
 * @description
 * Sidebar sub menu item component.
 * @param {string} label
 * Label for the sub menu item.
 * @param {string} to
 * Path for the sub menu item.
 * @param {function} setOpen
 * Function to handle the sidebar open event.
 * @returns {React.ReactComponentElement}
 */
const SidebarSubMenuItem = memo(({ ...props }) => {
  const { label, to, setOpen } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isCurrentMenu, setIsCurrentMenu] = useState(false);

  useEffect(() => {
    if (checkCurrentMenu({ pathname, to, isSubMenu: true })) {
      setIsCurrentMenu(true);
    } else {
      setIsCurrentMenu(false);
    }
  }, [pathname]);

  return (
    <StyledSidebarSubMenuItem
      $isCurrentMenu={isCurrentMenu}
      onClick={e => {
        /** Prevent event bubbling */
        e?.preventDefault();
        e?.stopPropagation();
        navigate(to);
        closeSidebar({
          setIsSidebarOpen: setOpen,
          sidebarElement: document.getElementById('sidebarWrapper'),
        });
      }}
    >
      {label}
    </StyledSidebarSubMenuItem>
  );
});

export default SidebarSubMenuItem;
