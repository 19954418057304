import dayjs from 'dayjs';
import {
  STATUS_STANDARD_CODE_OBJECT,
  WARNING_ISSUE_CODE,
} from '../../../../constants/TmsReport/EarlyWarning/standardCode';

export const generateStatusValue = data => {
  const value = STATUS_STANDARD_CODE_OBJECT?.[data]?.value;
  return value;
};

export const generateDateValue = data => {
  const value =
    data && dayjs(data)?.isValid() ? dayjs(data)?.format('MM/DD/YY') : '';
  return value;
};

export const generateWarningIssueValue = data => {
  const value = WARNING_ISSUE_CODE?.[data];
  return value;
};
