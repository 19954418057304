import BUTTON_COL_LIST from '../../../../constants/DailyReport/Common/buttonColumList';

const handleEditingStart = ({
  gridRef,
  gridParam,
  setClickFocusedCell,
  setBeforeEditData,
  page,
}) => {
  const buttonInstance = gridParam?.api?.getCellRendererInstances({
    rowNodes: [gridParam?.node],
    columns: BUTTON_COL_LIST?.[page],
  });

  buttonInstance?.forEach(
    item => !item?.colDef?.suppressChangeDisabled && item?.setDisabled(true),
  );
  setClickFocusedCell(gridRef.current.api.getFocusedCell());
  setBeforeEditData({ ...gridParam.data });
};

export default handleEditingStart;
