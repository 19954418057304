const VAN_SCHEDULE_TYPE = 'LVAN';

const getScheduledTypeDef = () => {
  return {
    valueGetter: params => {
      return params.data?.sche_type === VAN_SCHEDULE_TYPE
        ? 'VAN'
        : params.data?.sche_type;
    },
  };
};

export default getScheduledTypeDef;
