import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';
import bookingDefaultAPI from '../../../bookingAPI';

const patchCompleteBookingList = async ({ bookingList }) => {
  try {
    const response = await bookingDefaultAPI.patch(`complete`, {
      bk_seq: bookingList,
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchCompleteBookingList;
