import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import memoDefaultAPi from '../api';

const getWOMemoList = async woNo => {
  try {
    const response = await memoDefaultAPi.get(`wo/${woNo}`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getWOMemoList;
