import React from 'react';
import APModalTableContent from '../APModalTableContent';
import APModalInfoContent from '../APModalInfoContent';

const APModalContent = ({
  apInputData,
  apTableContent,
  setAPTableContent,
  ...props
}) => {
  return (
    <div className="flex flex-col justify-center items-center">
      <APModalInfoContent
        driver={apInputData?.driver || '-'}
        fromLoc={apInputData?.from_location || '-'}
        toLoc={apInputData?.to_location || '-'}
        mileage={apInputData?.mileage || '-'}
      />
      <APModalTableContent
        apInputData={apInputData}
        apTableContent={apTableContent}
        setAPTableContent={setAPTableContent}
      />
    </div>
  );
};

export default APModalContent;
