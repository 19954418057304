import styled from 'styled-components';

const StyledSignInCommonAlertMessageWrapper = styled.div`
  width: fit-content;
  height: 40px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  font-size: 12px;
  font-weight: 400;
`;

export default StyledSignInCommonAlertMessageWrapper;
