import React from 'react';
import usePostRateCFM from '../../../hooks/BCDetail/BillingInfo/AP/usePostRateCFM';
import useGetPDFRateCFM from '../../../hooks/BCDetail/BillingInfo/AP/useGetPDFRateCFM';
import useGetRateCFM from '../../../hooks/BCDetail/BillingInfo/AP/useGetRateCFM';
import CommonMessageModal from '../../CommonMessageModal/CommonMessageModal';
import CommonButton from '../../CommonButton/CommonButton';
import StyledBCDetailBillingInfoRateCFMModalContainer from '../../../styles/BCDetail/BillingInfo/RateCFMModal/StyledBCDetailBillingInfoRateCFMModalContainer';
import StyledBCDetailBillingInfoRateCFMModalNameValueContainer from '../../../styles/BCDetail/BillingInfo/RateCFMModal/StyledBCDetailBillingInfoRateCFMModalNameValueContainer';
import StyledBCDetailBillingInfoRateCFMModalName from '../../../styles/BCDetail/BillingInfo/RateCFMModal/StyledBCDetailBillingInfoRateCFMModalName';
import StyledBCDetailBillingInfoRateCFMModalValue from '../../../styles/BCDetail/BillingInfo/RateCFMModal/StyledBCDetailBillingInfoRateCFMModalValue';
import StyledBCDetailBillingInfoRateCFMModalContent from '../../../styles/BCDetail/BillingInfo/RateCFMModal/StyledBCDetailBillingInfoRateCFMModalContent';
import StyledBCDetailBillingInfoRateCFMModalButton from '../../../styles/BCDetail/BillingInfo/RateCFMModal/StyledBCDetailBillingInfoRateCFMModalButton';

const RateCFMModal = ({
  selectedNo,
  isActiveClicked,
  setIsActiveClicked,
  setIsAlertOpen,
  setErrorMessage,
  setIsSuccess,
}) => {
  const { mutate: postEmailMutator } = usePostRateCFM({
    setIsAlertOpen,
    setErrorMessage,
    setIsSuccess,
    setIsActiveClicked,
  });
  const { refetch: pdfRateCFMRefetch } = useGetPDFRateCFM({
    seq: selectedNo,
    isActiveClicked,
    setIsActiveClicked,
    setErrorMessage,
  });
  const { rateCFMContents } = useGetRateCFM({ seq: selectedNo });

  return (
    isActiveClicked && (
      <CommonMessageModal
        open={isActiveClicked}
        setOpen={setIsActiveClicked}
        header="Rate Confirmation Email"
        content={
          <>
            <StyledBCDetailBillingInfoRateCFMModalContainer>
              <StyledBCDetailBillingInfoRateCFMModalNameValueContainer>
                <StyledBCDetailBillingInfoRateCFMModalName>
                  From
                </StyledBCDetailBillingInfoRateCFMModalName>
                <StyledBCDetailBillingInfoRateCFMModalValue>
                  {rateCFMContents?.from}
                </StyledBCDetailBillingInfoRateCFMModalValue>
              </StyledBCDetailBillingInfoRateCFMModalNameValueContainer>
              <StyledBCDetailBillingInfoRateCFMModalNameValueContainer>
                <StyledBCDetailBillingInfoRateCFMModalName>
                  To
                </StyledBCDetailBillingInfoRateCFMModalName>
                <StyledBCDetailBillingInfoRateCFMModalValue>
                  {rateCFMContents?.to}
                </StyledBCDetailBillingInfoRateCFMModalValue>
              </StyledBCDetailBillingInfoRateCFMModalNameValueContainer>
              <StyledBCDetailBillingInfoRateCFMModalNameValueContainer>
                <StyledBCDetailBillingInfoRateCFMModalName>
                  CC
                </StyledBCDetailBillingInfoRateCFMModalName>
                <StyledBCDetailBillingInfoRateCFMModalValue>
                  {rateCFMContents?.cc}
                </StyledBCDetailBillingInfoRateCFMModalValue>
              </StyledBCDetailBillingInfoRateCFMModalNameValueContainer>
            </StyledBCDetailBillingInfoRateCFMModalContainer>
            <StyledBCDetailBillingInfoRateCFMModalContainer>
              <StyledBCDetailBillingInfoRateCFMModalNameValueContainer>
                <StyledBCDetailBillingInfoRateCFMModalName>
                  Subject
                </StyledBCDetailBillingInfoRateCFMModalName>
                <StyledBCDetailBillingInfoRateCFMModalValue>
                  {rateCFMContents?.subject}
                </StyledBCDetailBillingInfoRateCFMModalValue>
              </StyledBCDetailBillingInfoRateCFMModalNameValueContainer>
              <StyledBCDetailBillingInfoRateCFMModalContent
                dangerouslySetInnerHTML={{ __html: rateCFMContents?.content }}
              />
            </StyledBCDetailBillingInfoRateCFMModalContainer>
          </>
        }
        buttonContent={
          <StyledBCDetailBillingInfoRateCFMModalButton>
            <CommonButton
              onClick={() => {
                pdfRateCFMRefetch();
              }}
              category="attached"
            >
              Attached File
            </CommonButton>
            <CommonButton
              preventDupClick
              onClick={({ buttonRef }) => {
                postEmailMutator({
                  seq: selectedNo,
                  data: {
                    subject: rateCFMContents?.subject,
                    content: rateCFMContents?.content,
                  },
                  buttonRef,
                });
              }}
              category="send"
            >
              Send
            </CommonButton>
          </StyledBCDetailBillingInfoRateCFMModalButton>
        }
      />
    )
  );
};

export default RateCFMModal;
