import { WO_CATEGORY_NAME } from '../../../constants/TmsReport/Common';

const handleWOClick = item => {
  let category;
  if (item.category === WO_CATEGORY_NAME?.IMPORT) {
    category = 'import';
  } else if (item.category === WO_CATEGORY_NAME?.EXPORT) {
    category = 'export';
  } else if (item.category === WO_CATEGORY_NAME?.VAN) {
    category = 'van';
  } else if (item.category === WO_CATEGORY_NAME?.BROKERAGE) {
    category = 'brokerage';
  } else if (item.category === WO_CATEGORY_NAME?.BARE_CHASSIS) {
    category = 'bare-chassis';
  }
  const bcDetailTab = window.open(
    `/bc-detail/detail-info/${category}/${item.wo_no}`,
    'bc-detail-tab',
  );
};

export default handleWOClick;
