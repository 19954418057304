import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import divBasicDefaultApi from '../../divApi';

const getAPProductCode = async () => {
  try {
    const response = await divBasicDefaultApi.get('product-code/ap');
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getAPProductCode;
