import BUTTON_COL_LIST from '../../../../constants/DailyReport/Common/buttonColumList';

const handleEditingStop = ({ gridParam, page }) => {
  const buttonInstance = gridParam?.api?.getCellRendererInstances({
    rowNodes: [gridParam?.node],
    columns: BUTTON_COL_LIST?.[page],
  });

  buttonInstance?.forEach(
    item => !item?.colDef?.suppressChangeDisabled && item?.setDisabled(false),
  );
};

export default handleEditingStop;
