const masterKeys = {
  all: ['MASTER'],
  apRateList: ({ div }) => [...masterKeys.all, 'AP_RATE_LIST', div],
  arRateList: ({ div }) => [...masterKeys.all, 'AR_RATE_LIST', div],
  chassisRateList: ({ div }) => [...masterKeys.all, 'CHASSIS_RATE_LIST', div],
  tierWeightList: ({ div }) => [...masterKeys.all, 'TIER_WEIGHT_LIST', div],
  driverList: () => [...masterKeys.all, 'DRIVER_LIST'],
  driverSearchList: ({ queryObject }) => [
    ...masterKeys.driverList(),
    queryObject,
  ],
  companyList: () => [...masterKeys.all, 'COMPANY_LIST'],
  companySearchList: ({ queryObject }) => [
    ...masterKeys.companyList(),
    queryObject,
  ],
  cneeList: () => [...masterKeys.all, 'CNEE_LIST'],
  nglCneeList: () => [...masterKeys.all, 'NGL_CNEE_LIST'],
  customerList: () => [...masterKeys.all, 'CUSTOMER_LIST'],
  acctCodeList: () => [...masterKeys.all, 'ACCOUNTCODE_LIST'],
  serviceCodeList: () => [...masterKeys.all, 'SERVICECODE_LIST'],
  termsList: () => [...masterKeys.all, 'TERMS_LIST'],
};

export default masterKeys;
