import React from 'react';
import PageNotFound from '../../../../pages/Error/PageNotFound';
import PageInternalServerError from '../../../../pages/Error/PageInternalServerError';
import PagePermissionDenied from '../../../../pages/Error/PagePermissionDenied';

const ERROR_CHILDREN_ROUTE = [
  {
    index: true,
    element: <PageNotFound />,
  },
  {
    path: '404',
    element: <PageNotFound />,
  },
  {
    path: '500',
    element: <PageInternalServerError />,
  },
  {
    path: '403',
    element: <PagePermissionDenied />,
  },
];

export default ERROR_CHILDREN_ROUTE;
