import dayjs from 'dayjs';

const getValidBookingData = data => {
  const submitData = { ...data };
  delete submitData.erd_evidence_file_name;
  delete submitData.cutoff_evidence_file_name;

  if (
    data.cutoff_date &&
    dayjs(data.cutoff_date).isValid &&
    data.cutoff_time &&
    dayjs(data.cutoff_time).isValid
  ) {
    submitData.cutoff = `${dayjs(data.cutoff_date).format(
      'YYYY-MM-DD',
    )} ${dayjs(data.cutoff_time).format('HH:mm:ss')}`;
    delete submitData.cutoff_date;
    delete submitData.cutoff_time;
  }
  const numberList = ['total_1', 'total_2', 'total_3'];

  numberList.forEach(item => {
    data?.[item] && (submitData[item] = Number(data[item]));
  });

  if (data?.cont_size_1 && data?.cont_type_1) {
    data.used_1 = data?.used_1 || 0;
    data.total_1 = data?.total_1 || 0;
    submitData.balance_1 = data?.balance_1 || data.total_1 - data.used_1;
  }

  if (data?.cont_size_2 && data?.cont_type_2) {
    data.used_2 = data?.used_2 || 0;
    data.total_2 = data?.total_2 || 0;
    submitData.balance_2 = data.balance_2 || data.total_2 - data.used_2;
  }

  if (data?.cont_size_3 && data?.cont_type_3) {
    data.used_3 = data?.used_3 || 0;
    data.total_3 = data?.total_3 || 0;
    submitData.balance_3 = data.balance_3 || data.total_3 - data.used_3;
  }

  if (!data?.total_1) {
    submitData.total_1 = 0;
  }

  if (!data?.used_1) {
    submitData.used_1 = 0;
  }

  if (!data?.balance_1) {
    submitData.balance_1 = 0;
  }

  if (!data?.total_2) {
    submitData.total_2 = 0;
  }

  if (!data?.used_2) {
    submitData.used_2 = 0;
  }

  if (!data?.balance_2) {
    submitData.balance_2 = 0;
  }

  if (!data?.total_3) {
    submitData.total_3 = 0;
  }

  if (!data?.used_3) {
    submitData.used_3 = 0;
  }

  if (!data?.balance_3) {
    submitData.balance_3 = 0;
  }

  return submitData;
};

export default getValidBookingData;
