import React from 'react';
import CommonButton from '../../../../../../CommonButton/CommonButton';

const ScheduleMobileModalButtonContent = ({ ...props }) => {
  /** Get data setter */
  const { setMemoContent, content, scheduleSeq } = props;

  /** Get mutate */
  const { patchMemoContentMutate } = props;

  return (
    <div className="w-full flex justify-center items-center">
      <div className="flex justify-center items-center flex-row gap-[20px]">
        <CommonButton
          category="clear"
          onClick={() => setMemoContent(prev => '')}
        >
          Reset
        </CommonButton>
        <CommonButton
          category="save"
          preventDupClick
          onClick={({ buttonRef }) =>
            patchMemoContentMutate({
              seq: scheduleSeq,
              content,
              buttonRef,
            })
          }
        >
          Save
        </CommonButton>
      </div>
    </div>
  );
};

export default ScheduleMobileModalButtonContent;
