import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CommonMessageModal from '../../CommonMessageModal/CommonMessageModal';
import AdvancedSearchContent from './AdvancedSearchContent';
import AdvancedSearchButton from './AdvancedSearchButton';

const AdvancedSearchModal = ({ ...props }) => {
  /** Get data from props */
  const { searchParams, setSearchParams } = props;

  /** Get modal opener and setter */
  const { open, setOpen } = props;

  /** Form Context */
  const advancedSearchFormController = useForm();

  return (
    <FormProvider {...advancedSearchFormController}>
      <form>
        <CommonMessageModal
          open={open}
          setOpen={setOpen}
          header="Advanced Search"
          content={<AdvancedSearchContent searchParams={searchParams} />}
          buttonContent={
            <AdvancedSearchButton
              setOpen={setOpen}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          }
        />
      </form>
    </FormProvider>
  );
};

export default AdvancedSearchModal;
