import React from 'react';
import CommonTable from '../../../../../../CommonTable';

const ScheduleDriverListModalContentTable = ({
  driverList,
  isPending,
  setSelectedScheduleSeq,
  setSelectedDriverData,
  setOpen,
  setAPModalOpen,
  getAPDataRefetch,
  ...props
}) => {
  return (
    <div className="w-full h-[330px]">
      <CommonTable
        data={driverList}
        createColumns={({ columnHelper, ...columnProps }) => [
          columnHelper.accessor('driver', {
            header: 'Driver ID',
            cell: info => info.getValue(),
            size: 60,
          }),
          columnHelper.accessor('name', {
            header: 'Name',
            cell: info => info.getValue()?.substring(0, 30),
            size: 228,
          }),
          columnHelper.accessor('category', {
            header: 'TYPE',
            cell: info => info.getValue(),
            size: 60,
          }),
          columnHelper.accessor('hazmat', {
            header: 'HAZ',
            cell: info => (!info.getValue() ? 'F' : 'T'),
            size: 20,
          }),
          columnHelper.accessor('twic_exp', {
            header: 'TWIC',
            cell: info => (!info.getValue() ? 'F' : 'T'),
            size: 20,
          }),
        ]}
        getRowId={row => row?.driver}
        onRowClickHandler={({ row, ...handlerProps }) => {
          setSelectedDriverData(row?.original);
        }}
        onRowDoubleClickHandler={async () => {
          const { isError: apIsError, isFetching: apIsFetching } =
            await getAPDataRefetch();
          if (!apIsError && !apIsFetching) {
            setOpen(false);
            setAPModalOpen(true);
          }
        }}
      />
    </div>
  );
};

export default ScheduleDriverListModalContentTable;
