import dayjs from 'dayjs';
import { DISPATCH_TYPE_BOOKING } from '../../../../constants/Dispatch/Common/category';

const today = dayjs().format('YYYY-MM-DD');
const threeMonthsAgo = dayjs().subtract(3, 'month').format('YYYY-MM-DD');

const generateInitAdvancedForm = ({ woStatus, category }) => {
  if (
    category === DISPATCH_TYPE_BOOKING &&
    woStatus === DISPATCH_TYPE_BOOKING
  ) {
    return {
      booking: '',
      status: 'active',
      date: 'create-',
      dateFrom: threeMonthsAgo,
      dateTo: today,
    };
  }

  return {
    date: 'create-',
    dateFrom: threeMonthsAgo,
    dateTo: today,
    category: '',
    categoryText: '',
  };
};

export default generateInitAdvancedForm;
