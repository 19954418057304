import mobileAdminDefaultUrl from '../../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

const patchConfirmStatus = async ({ data, division }) => {
  try {
    const response = await mobileAdminDefaultUrl.patch('bill-confirm', data, {
      params: { div: division },
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchConfirmStatus;
