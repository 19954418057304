import React from 'react';

const GoogleMapComponentJob = ({ mapRef }) => {
  return (
    <div
      data-testid="map"
      className="w-[1341px] h-[839px]"
      id="map_canvas"
      ref={mapRef}
    >
      Map
    </div>
  );
};

export default GoogleMapComponentJob;
