import cellEditorGenerator from '../../../utils/Common/Datagrid/Generator/cellEditorGenerator';
import cellRendererGenerator from '../../../utils/Common/Datagrid/Generator/cellRendererGenerator';
import getCellStyle from '../../../utils/Dispatch/Common/Generator/getCellStyle';
import { CELL_TYPE_TEXT } from '../../Common/DataGrid/cellType';

const EDITABLE_CHASSIS_DEF = {
  cellRendererSelector: gridParam =>
    cellRendererGenerator({
      gridParam,
      cellType: CELL_TYPE_TEXT,
      customStyle: getCellStyle({ ...gridParam }),
    }),
  cellEditorSelector: gridParam =>
    cellEditorGenerator({
      gridParam,
      cellType: CELL_TYPE_TEXT,
      maxLength: 12,
      onChangeHandler: ({ getChangedValue, ...props }) => {
        const instances = props.api.getCellEditorInstances();
        const pairInstance = Object.values(instances).find(
          instance => instance?.cellEditorParams?.column?.colId === 'pool',
        );

        if (
          getChangedValue?.toUpperCase()?.startsWith('NGLT') &&
          (!pairInstance?.value || pairInstance?.value === '')
        ) {
          pairInstance?.componentInstance?.setDataValue('NGL');
          pairInstance?.onValueChange('NGL');
        }
      },
    }),
  valueSetter: params => {
    params.data.ch_no = params?.newValue
      ? params?.newValue?.toUpperCase()
      : null;
    return true;
  },
};

export default EDITABLE_CHASSIS_DEF;
