import { IMPORT_COLORS } from '../Import/Common/theme';
import { EXPORT_COLORS } from '../Export/Common/theme';
import { VAN_COLORS } from '../Van/Common/theme';
import { BROKERAGE_COLORS } from '../Brokerage/Common/theme';

export const DISPATCH_CATEGORY_THEME = {
  import: IMPORT_COLORS,
  export: EXPORT_COLORS,
  van: VAN_COLORS,
  brokerage: BROKERAGE_COLORS,
};
