import styled from 'styled-components';

const StyledSubFilterButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 10px 9px;
  gap: 4px;
  box-shadow: 0px 0px 5px 0 #00000029;
  border: 1px solid ${props => (props.sub ? props.subColor : '#f9f9f9')};
  height: 47px;
  width: ${props => props.width || 'max'};
  color: ${props => {
    if (props.main) {
      return props.sub ? 'white' : props.subColor;
    }
    return 'inherit';
  }};
  background-color: ${props => (props.sub ? props.subColor : '#f9f9f9')};
  outline-width: 0px;

  &:hover {
    border: 1px solid ${props => props.subColor};
    color: ${props => (props.sub ? 'white' : props.subColor)};
  }

  &:focus {
    background-color: ${props => props.subColor};
    border: 1px solid ${props => props.subColor};
    color: white;
  }
`;

export default StyledSubFilterButton;
