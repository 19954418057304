import { useQuery } from '@tanstack/react-query';
import bcDetailKeys from '../keys';
import getWOMemoList from '../../../services/BCDetail/Memo/Get/getWOMemoList';

const useGetWOMemoList = ({ woNo }) => {
  const result = useQuery({
    queryKey: bcDetailKeys.woMemoList({ woNo }),
    queryFn: () => getWOMemoList(woNo),
  });

  return result;
};

export default useGetWOMemoList;
