const replaceSlashToUnderBar = (obj, searchValue, replaceValue) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  return Object.keys(obj).reduce((acc, key) => {
    const updatedKey = key.replace(searchValue, replaceValue);
    const value = obj[key];

    acc[updatedKey] = value;
    return acc;
  }, {});
};

export default replaceSlashToUnderBar;
