import {
  SUB_ORDER_TYPE_BROKERAGE,
  SUB_ORDER_TYPE_EXPREG,
  SUB_ORDER_TYPE_EXPSHUTTLE,
  SUB_ORDER_TYPE_IMPREG,
  SUB_ORDER_TYPE_IMPSHUTTLE,
  SUB_ORDER_TYPE_VAN,
} from '../../../constants/Order/Common/basic';

/**
 * @description
 * Function generate initialized import regular form according to sub category
 * @returns {object}
 * Initialized form
 */
export const getOrderImportRegular = () => {
  return {
    do: null,
    cf: null,
    bond_entry: null,
    billto: null,
    mbl_no: null,
    hbl_no: null,
    ref_no: null,
    purchase_no: null,
    dilv_no: null,
    ssl: null,
    vessel: null,
    eta: null,
    lfd: null,
    freedays: null,
    freedays_type: null,
    remarks: null,
    ava_dsc: null,
    ava_obl: null,
    ava_ca: null,
    ava_bo: null,
    ava_cus: null,
    ava_oth: null,
    ava_na: null,
    sendto: null,
    po_loc: null,
    po_date: null,
    po_time: null,
    dilv_loc: null,
    dilv_date: null,
    dilv_time: null,
    rtn_loc: null,
    rtn_date: null,
    rtn_time: null,
    over_height: null,
    over_weight: null,
    hazmat: null,
    hot: null,
    sche_type: null,
    other: null,
    equips: [],
  };
};

/**
 * @description
 * Function generate initialized import shuttle form according to sub category
 * @returns {object}
 * Initialized form
 */
export const getOrderImportShuttle = () => {
  return {
    do: null,
    cf: null,
    bond_entry: null,
    billto: null,
    mbl_no: null,
    hbl_no: null,
    ref_no: null,
    purchase_no: null,
    dilv_no: null,
    ssl: null,
    vessel: null,
    remarks: null,
    sendto: null,
    po_loc: null,
    po_date: null,
    po_time: null,
    dilv_loc: null,
    dilv_date: null,
    dilv_time: null,
    rtn_loc: null,
    rtn_date: null,
    rtn_time: null,
    over_height: null,
    over_weight: null,
    hazmat: null,
    hot: null,
    sche_type: null,
    other: null,
    equips: [],
  };
};

/**
 * @description
 * Function generate initialized export form according to sub category
 * @returns {object}
 * Initialized form
 */
export const getOrderExport = () => {
  return {
    do: null,
    bk_seq: null,
    cf: null,
    bond_entry: null,
    billto: null,
    bk_no: null,
    hbl_no: null,
    ref_no: null,
    purchase_no: null,
    dilv_no: null,
    ssl: null,
    vessel: null,
    erd: null,
    cutoff_date: null,
    cutoff_time: null,
    freedays: null,
    freedays_type: null,
    remarks: null,
    sendto: null,
    po_loc: null,
    po_date: null,
    po_time: null,
    dilv_loc: null,
    dilv_date: null,
    dilv_time: null,
    rtn_loc: null,
    rtn_date: null,
    rtn_time: null,
    over_height: null,
    over_weight: null,
    hazmat: null,
    hot: null,
    sche_type: null,
    other: null,
    equips: [],
  };
};

/**
 * @description
 * Function generate initialized van form according to sub category
 * @returns {object}
 * Initialized form
 */
export const getOrderVan = () => {
  return {
    do: null,
    cf: null,
    bond_entry: null,
    billto: null,
    mbl_no: null,
    hbl_no: null,
    ref_no: null,
    purchase_no: null,
    dilv_no: null,
    remarks: null,
    sendto: null,
    po_loc: null,
    po_date: null,
    po_time: null,
    dilv_loc: null,
    dilv_date: null,
    dilv_time: null,
    rtn_loc: null,
    rtn_date: null,
    rtn_time: null,
    over_height: null,
    over_weight: null,
    hazmat: null,
    hot: null,
    sche_type: null,
    other: null,
    equips: [],
  };
};

/**
 * @description
 * Function generate initialized brokerage form according to sub category
 * @returns {object}
 * Initialized form
 */
export const getOrderBrokerage = () => {
  return {
    do: null,
    cf: null,
    bond_entry: null,
    billto: null,
    mbl_no: null,
    hbl_no: null,
    ref_no: null,
    purchase_no: null,
    dilv_no: null,
    remarks: null,
    sendto: null,
    po_loc: null,
    po_date: null,
    po_time: null,
    dilv_loc: null,
    dilv_date: null,
    dilv_time: null,
    rtn_loc: null,
    rtn_date: null,
    rtn_time: null,
    over_height: null,
    over_weight: null,
    hazmat: null,
    hot: null,
    sche_type: null,
    other: null,
    equips: [],
  };
};

/**
 * @description
 * Function generate initialized bare chassis form according to sub category
 * @returns {object}
 * Initialized form
 */
export const getOrderBareChassis = () => {
  return {
    do: null,
    billto: null,
    ref_no: null,
    dilv_no: null,
    remarks: null,
    sendto: null,
    po_loc: null,
    po_date: null,
    po_time: null,
    dilv_loc: null,
    dilv_date: null,
    dilv_time: null,
    rtn_loc: null,
    rtn_date: null,
    rtn_time: null,
    hot: null,
    sche_type: null,
    other: null,
    equips: [],
  };
};

/**
 *
 * @description
 * Function of getting order post form according to sub-category of page.
 * @returns
 * Order post form.
 */
export const getOrderPostForm = ({ subCategory }) => {
  if (subCategory === SUB_ORDER_TYPE_IMPREG) return getOrderImportRegular();
  if (subCategory === SUB_ORDER_TYPE_IMPSHUTTLE) return getOrderImportShuttle();
  if ([SUB_ORDER_TYPE_EXPREG, SUB_ORDER_TYPE_EXPSHUTTLE].includes(subCategory))
    return getOrderExport();
  if (subCategory === SUB_ORDER_TYPE_VAN) return getOrderVan();
  if (subCategory === SUB_ORDER_TYPE_BROKERAGE) return getOrderBrokerage();
  return getOrderBareChassis();
};
