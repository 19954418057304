import React from 'react';
import { useWatch } from 'react-hook-form';
import {
  COMMON_DROP_LIVE_SELECT_OPTION,
  COMMON_VAN_DROP_LIVE_SELECT_OPTION,
} from '../../../../constants/Common/selectOption';
import {
  SUB_ORDER_TYPE_BARECHASSIS,
  SUB_ORDER_TYPE_BROKERAGE,
  SUB_ORDER_TYPE_EXPREG,
  SUB_ORDER_TYPE_EXPSHUTTLE,
  SUB_ORDER_TYPE_IMPREG,
  SUB_ORDER_TYPE_IMPSHUTTLE,
  SUB_ORDER_TYPE_VAN,
} from '../../../../constants/Order/Common/basic';
import StyledBottomInfo from '../../../../styles/Order/StyledBottomInfo';
import handleAutoCompleteChange from '../../../../utils/Order/Handler/InputHandler/handleAutoCompleteChange';
import handleCheckboxChange from '../../../../utils/Order/Handler/InputHandler/handleCheckboxChange';
import handleDateChange from '../../../../utils/Order/Handler/InputHandler/handleDateChange';
import handleSelectChange from '../../../../utils/Order/Handler/InputHandler/handleSelectChange';
import CommonAutoComplete from '../../../Inputs/CommonAutoComplete/CommonAutoComplete';
import CommonCheckbox from '../../../Inputs/CommonCheckbox/CommonCheckbox';
import CommonDate from '../../../Inputs/CommonDate/CommonDate';
import CommonFile from '../../../Inputs/CommonFile/CommonFile';
import CommonSelect from '../../../Inputs/CommonSelect/CommonSelect';
import CommonTextInput from '../../../Inputs/CommonTextInput/CommonTextInput';
import CommonTime from '../../../Inputs/CommonTime/CommonTime';

const OrderBottomInfo = ({
  subCategory,
  companyOption,
  isLoadingCompanyOption,
  ...props
}) => {
  return (
    <div className="py-[25px] px-[30px]">
      <StyledBottomInfo>
        <div>
          <CommonAutoComplete
            inputName="po_loc"
            label={
              subCategory === SUB_ORDER_TYPE_VAN ||
              subCategory === SUB_ORDER_TYPE_BROKERAGE
                ? 'PICK UP'
                : 'PULL OUT'
            }
            required={[
              SUB_ORDER_TYPE_IMPREG,
              SUB_ORDER_TYPE_IMPSHUTTLE,
              SUB_ORDER_TYPE_VAN,
              SUB_ORDER_TYPE_BROKERAGE,
              SUB_ORDER_TYPE_BARECHASSIS,
            ].includes(subCategory)}
            maxLength={9}
            onChangeHandler={handleAutoCompleteChange}
            option={companyOption?.other}
            isLoading={isLoadingCompanyOption}
          />
        </div>
        <div>
          <CommonTextInput inputName="po_timezone" disabled />
        </div>
        <div className="flex flex-row gap-[9px]">
          <div className="w-[115px]">
            <CommonDate
              inputName="po_date"
              onChangeHandler={handleDateChange}
              required={!!useWatch({ name: 'po_time' })}
            />
          </div>
          <div className="w-[92px]">
            <CommonTime
              inputName="po_time"
              onChangeHandler={handleDateChange}
              required={!!useWatch({ name: 'po_date' })}
            />
          </div>
        </div>
        <div>
          <CommonCheckbox
            onChangeHandler={handleCheckboxChange}
            options={
              subCategory === SUB_ORDER_TYPE_BARECHASSIS
                ? [
                    {
                      inputName: 'hot',
                      defaultValue: false,
                      optionLabel: 'HOT',
                    },
                  ]
                : [
                    {
                      inputName: 'over_height',
                      defaultValue: false,
                      optionLabel: 'O/H',
                    },
                    {
                      inputName: 'over_weight',
                      defaultValue: false,
                      optionLabel: 'O/W',
                    },
                    {
                      inputName: 'hazmat',
                      defaultValue: false,
                      optionLabel: 'HAZ',
                    },
                    {
                      inputName: 'hot',
                      defaultValue: false,
                      optionLabel: 'HOT',
                    },
                  ]
            }
            borderVisible
          />
        </div>
        <div>
          <CommonAutoComplete
            inputName="dilv_loc"
            label="DELIVERY"
            required
            maxLength={9}
            onChangeHandler={handleAutoCompleteChange}
            option={companyOption?.other}
            isLoading={isLoadingCompanyOption}
          />
        </div>
        <div>
          <CommonTextInput inputName="dilv_timezone" disabled />
        </div>
        <div className="flex flex-row gap-[9px]">
          <div className="w-[115px]">
            <CommonDate
              inputName="dilv_date"
              onChangeHandler={handleDateChange}
              required={!!useWatch({ name: 'dilv_time' })}
            />
          </div>
          <div className="w-[92px]">
            <CommonTime
              inputName="dilv_time"
              onChangeHandler={handleDateChange}
              required={!!useWatch({ name: 'dilv_date' })}
            />
          </div>
        </div>
        <div>
          {[SUB_ORDER_TYPE_VAN].includes(subCategory) ? (
            <CommonSelect
              inputName="sche_type"
              label="DROP/LIVE"
              required
              option={COMMON_VAN_DROP_LIVE_SELECT_OPTION}
              defaultValue="LVAN"
              onChangeHandler={handleSelectChange}
              disabled
            />
          ) : (
            <CommonSelect
              inputName="sche_type"
              label="DROP/LIVE"
              required
              option={COMMON_DROP_LIVE_SELECT_OPTION}
              defaultValue="DROP"
              onChangeHandler={handleSelectChange}
              disabled={false}
            />
          )}
        </div>
        <div>
          <CommonAutoComplete
            inputName="rtn_loc"
            label="RETURN"
            maxLength={9}
            required={[
              SUB_ORDER_TYPE_EXPREG,
              SUB_ORDER_TYPE_EXPSHUTTLE,
            ].includes(subCategory)}
            onChangeHandler={handleAutoCompleteChange}
            option={companyOption?.other}
            disabled={[
              SUB_ORDER_TYPE_EXPREG,
              SUB_ORDER_TYPE_EXPSHUTTLE,
            ].includes(subCategory)}
            isLoading={isLoadingCompanyOption}
          />
        </div>
        <div>
          <CommonTextInput inputName="rtn_timezone" disabled />
        </div>
        <div className="flex flex-row gap-[9px]">
          <div className="w-[115px]">
            <CommonDate
              inputName="rtn_date"
              onChangeHandler={handleDateChange}
              required={!!useWatch({ name: 'rtn_time' })}
            />
          </div>
          <div className="w-[92px]">
            <CommonTime
              inputName="rtn_time"
              onChangeHandler={handleDateChange}
              required={!!useWatch({ name: 'rtn_date' })}
            />
          </div>
        </div>
        <div>
          <CommonFile inputName="other" label="OTHER" />
        </div>
      </StyledBottomInfo>
    </div>
  );
};

export default OrderBottomInfo;
