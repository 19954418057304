import moveBCDetailPage from '../../Common/Handler/moveBCDetailPage';

const handleCellClick = ({ gridParam }) => {
  const getColId = gridParam?.column?.colId;

  switch (getColId) {
    case 'wo_from':
      moveBCDetailPage({ gridParam, columnID: getColId });
      break;
    case 'wo_to':
      moveBCDetailPage({ gridParam, columnID: getColId });
      break;
    default:
      break;
  }
};

export default handleCellClick;
