import dayjs from 'dayjs';

const generateDefaultPopUpStatusObject = ({ div }) => {
  return {
    issue: true,
    solved: false,
    confirm: false,
    order: 'level',
    div,
    'created-from': dayjs()?.subtract(3, 'month')?.format('YYYY-MM-DD'),
    'created-to': dayjs()?.format('YYYY-MM-DD'),
  };
};

export default generateDefaultPopUpStatusObject;
