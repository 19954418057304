import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import AdvancedSearchModal from '../../../components/TmsReport/Statistics/Modal/AdvancedSearchModal';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import HeaderButtons from '../../../components/TmsReport/Statistics/HeaderButtons';
import PeriodNavigator from '../../../components/TmsReport/Statistics/PeriodNavigator';
import StatTable from '../../../components/TmsReport/Statistics/StatTable';
import StyledStatAreaWrapper from '../../../styles/TmsReport/Statistics/StyledStatAreaWrapper';
import useGetStatisticsList from '../../../hooks/TmsReport/Statistics/useGetStatisticsList';
import generateInitOptions from '../../../utils/TmsReport/Statistics/Generator/generateInitOptions';
import {
  SORTED_DIVISION,
  STATISTICS_TABLE_TITLE_VALUE,
} from '../../../constants/TmsReport/Statistics/statisticsValues';
import useFilterStorage from '../../../hooks/Common/Filter/useFilterStorage';
import useControlStatisticsList from '../../../hooks/TmsReport/Statistics/useControlStatisticsList';

const Statistics = () => {
  /** Sorted Division */
  const divList = useSelector(state => state?.common?.allDiv);
  const [sortedDivList, setSortedDivList] = useState([]);

  /** Save Filter */
  const filterStorage = useFilterStorage({ storageName: 'statisticsFilter' });
  const { filter, setFilter } = filterStorage;

  /** Search */
  const [cycle, setCycle] = useState('weekly');
  const [dateQuery, setDateQuery] = useState({
    date: dayjs()?.format('YYYY-MM-DD'),
  });
  const [divQuery, setDivQuery] = useState({});

  /** Set up Division Checkbox */
  const [isOpenDivCheckbox, setIsOpenDivCheckbox] = useState(false);
  const [divCheckboxOption, setDivCheckboxOptions] = useState([]);

  /** Advanced Search Modal */
  const [isOpenAdvancedSearch, setIsOpenAdvancedSearch] = useState(false);

  /** Error Modal */
  const [errorMessage, setErrorMessage] = useState(null);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);

  /** Set Up Sorted Division List */
  useEffect(() => {
    const tempDivisionList = [
      ...SORTED_DIVISION?.filter(item => divList?.includes(item)),
      ...divList?.filter(item => !SORTED_DIVISION?.includes(item)),
    ];

    setSortedDivList(tempDivisionList);
  }, [divList]);

  useEffect(() => {
    if (sortedDivList?.length > 0) {
      /** Set up Division Query */
      const tempDivQuery = Object.fromEntries(
        sortedDivList?.map(div => [
          div,
          typeof filter?.div?.[div] === 'boolean' ? filter?.div?.[div] : true,
        ]),
      );

      setDivQuery(tempDivQuery);
      setFilter(prev => ({
        ...prev,
        div: tempDivQuery,
      }));

      /** Set Up Division Checkbox Option */
      const tempDivCheckboxOption = generateInitOptions({
        sortedDivList,
        setDivQuery,
        filterStorage,
      });

      setDivCheckboxOptions(tempDivCheckboxOption);
    }
  }, [sortedDivList]);

  const { statisticsList, isLoading } = useGetStatisticsList({
    cycle,
    dateQuery,
    setErrorMessage,
    setIsOpenErrorModal,
  });

  const { filteredStatisticsList } = useControlStatisticsList({
    statisticsList,
    divQuery,
  });

  const excelProps = {
    cycle,
    filteredStatisticsList,
    currentDate: dateQuery?.date,
  };

  return (
    <>
      <div className="flex flex-col pr-[40px] h-full">
        <HeaderButtons
          setIsOpenAdvancedSearch={setIsOpenAdvancedSearch}
          divCheckboxOption={divCheckboxOption}
          setDivQuery={setDivQuery}
          isOpenDivCheckbox={isOpenDivCheckbox}
          setIsOpenDivCheckbox={setIsOpenDivCheckbox}
          filterStorage={filterStorage}
          dateQuery={dateQuery}
          {...excelProps}
        />
        <PeriodNavigator
          cycle={cycle}
          dateQuery={dateQuery}
          setDateQuery={setDateQuery}
        />
        <StyledStatAreaWrapper>
          {Object?.keys(STATISTICS_TABLE_TITLE_VALUE)?.map(title => {
            const isShow = !(title === 'empty-load' && !divQuery?.PHX);
            const tableData = filteredStatisticsList?.[title] || {};
            return (
              isShow && (
                <StatTable
                  key={title}
                  tableTitle={title}
                  tableData={tableData}
                  cycle={cycle}
                  dateQuery={dateQuery}
                />
              )
            );
          })}
        </StyledStatAreaWrapper>
      </div>
      <CommonLoading open={isLoading} />
      <AdvancedSearchModal
        open={isOpenAdvancedSearch}
        setIsOpen={setIsOpenAdvancedSearch}
        cycle={cycle}
        setCycle={setCycle}
        dateQuery={dateQuery}
        setDateQuery={setDateQuery}
      />
      <CommonErrorModal
        open={isOpenErrorModal}
        setIsOpen={setIsOpenErrorModal}
        message={errorMessage}
      />
    </>
  );
};

export default Statistics;
