import dayjs from 'dayjs';

const calcDateTimeGenerator = ({ date, time }) => {
  return date && time
    ? new Date(
        `${dayjs(date).format('YYYY-MM-DD')} ${dayjs(time).format('HH:mm:ss')}`,
      )
    : null;
};

export default calcDateTimeGenerator;
