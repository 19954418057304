import React from 'react';

const AcctTableCell = ({ value, header, width }) => {
  const dollarFlag = header === 'rate' || header === 'amt';
  return (
    <div
      className={`h-[30px] text-[#666666] bg-[#F2F4F8] text-[11px] font-[500] flex items-center 
      ${width === 220 ? 'w-[220px] min-w-[220px]' : 'w-[110px] min-w-[110px]'}
      py-[11px] ${
        dollarFlag ? 'justify-between pl-[32px] pr-[34px]' : 'justify-center'
      }`}
    >
      {dollarFlag && <p>$</p>}
      <p>{value || '-'}</p>
    </div>
  );
};

export default AcctTableCell;
