import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import divBasicDefaultApi from '../../divApi';

const deleteWeightPerZIP = async ({ seq }) => {
  try {
    const response = await divBasicDefaultApi.delete(`weight-per-zip/${seq}`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default deleteWeightPerZIP;
