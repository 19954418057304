import React from 'react';

const ScheduleDeleteModalContent = ({ ...props }) => {
  return (
    <div className="w-full h-full bg-[#F9F9F9] p-[17px] flex justify-center items-center text-[#222] text-xs">
      {`Does it really delete? It can't be recovered.`}
    </div>
  );
};

export default ScheduleDeleteModalContent;
