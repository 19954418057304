import {
  IMPORT_UNAVAILABLE,
  IMPORT_AVAILABLE,
  IMPORT_DELIVERY,
  IMPORT_RETURN,
  EXPORT_UNAVAILABLE,
  EXPORT_AVAILABLE,
  EXPORT_DELIVERY,
  EXPORT_RETURN,
  VAN_AVAILABLE,
  VAN_DELIVERY,
  VAN_RETURN,
  BROKERAGE_AVAILABLE,
  BROKERAGE_DELIVERY,
  BROKERAGE_RETURN,
} from '../../Common/woStatus';

export const DISPATCH_STATUS_VALUE = {
  imp_unava: IMPORT_UNAVAILABLE,
  imp_ava: IMPORT_AVAILABLE,
  imp_ava_yard: IMPORT_AVAILABLE,
  imp_dilv: IMPORT_DELIVERY,
  imp_dilv_yard: IMPORT_DELIVERY,
  imp_rtn: IMPORT_RETURN,
  imp_rtn_yard: IMPORT_RETURN,
  exp_booking: 'BKG',
  exp_unava: EXPORT_UNAVAILABLE,
  exp_unava_yard: EXPORT_UNAVAILABLE,
  exp_ava: EXPORT_AVAILABLE,
  exp_ava_yard: EXPORT_AVAILABLE,
  exp_dilv: EXPORT_DELIVERY,
  exp_dilv_yard: EXPORT_DELIVERY,
  exp_rtn: EXPORT_RETURN,
  exp_rtn_yard: EXPORT_RETURN,
  van_ava: VAN_AVAILABLE,
  van_dilv: VAN_DELIVERY,
  van_rtn: VAN_RETURN,
  bkg_ava: BROKERAGE_AVAILABLE,
  bkg_dilv: BROKERAGE_DELIVERY,
  bkg_rtn: BROKERAGE_RETURN,
};
