import React from 'react';
import { Route, Routes, useLocation, Outlet } from 'react-router-dom';
import MainLayOut from '../../components/Layout/MainLayOut';
import { PAGE_MOBILE_ADMIN } from '../../constants/Common/mapper';
import SubMenuNavigator from '../../components/Common/SubMenuNavigator';

const MobileAdmin = () => {
  const location = useLocation();
  const path = location.pathname;
  const pathParts = path.split('/');

  return (
    <MainLayOut>
      <SubMenuNavigator page={PAGE_MOBILE_ADMIN} />
      <div className="w-full pl-[97px] h-full">
        <Outlet />
      </div>
    </MainLayOut>
  );
};

export default MobileAdmin;
