import { useEffect, useState } from 'react';

const useControlLoading = ({
  isLoadingList = [],
  isFetchingList = [],
  isPendingList = [],
}) => {
  const [isLoadingOpen, setIsLoadingOpen] = useState(false);

  useEffect(() => {
    const isLoading =
      isLoadingList?.includes(true) ||
      isFetchingList?.includes(true) ||
      isPendingList?.includes(true);
    setIsLoadingOpen(isLoading);
  }, [isLoadingList, isFetchingList]);

  return { isLoadingOpen, setIsLoadingOpen };
};

export default useControlLoading;
