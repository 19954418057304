import React from 'react';

const ScheduleMobileModalContentInfoHeader = ({ ...props }) => {
  return (
    <div className="w-full h-[48px] flex flex-row gap-x-[3px]">
      <div className="w-[60px] h-full flex justify-center items-center" />
      <div className="w-[100px] h-full flex justify-center items-center text-[#999] text-[11px] font-medium">
        LOCATION ID
      </div>
      <div className="w-[90px] h-full flex justify-center items-center text-[#999] text-[11px] font-medium">
        CITY
      </div>
      <div className="w-[67px] h-full flex justify-center items-center text-[#999] text-[11px] font-medium">
        ST
      </div>
      <div className="w-[114px] h-full flex justify-center items-center text-[#999] text-[11px] font-medium">
        SCHE DATE
      </div>
      <div className="w-[86px] h-full flex justify-center items-center text-[#999] text-[11px] font-medium">
        SCHE TIME
      </div>
      <div className="w-[108px] h-full flex justify-center items-center text-[#999] text-[11px] font-medium">
        DRIVER
      </div>
    </div>
  );
};

export default ScheduleMobileModalContentInfoHeader;
