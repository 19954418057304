import { useQuery } from '@tanstack/react-query';
import masterKeys from '../keys';
import { commonCompanyAPI } from '../../../services/Common/Company/commonCompanyAPI';

const useGetNglCneeList = () => {
  const {
    isLoading: loadingNglCneeList,
    data: nglCneeList,
    isError: errorNglCneeList,
  } = useQuery({
    queryKey: [masterKeys?.nglCneeList()],
    queryFn: () => commonCompanyAPI.getCommonCneeNglCompany(),
    staleTime: 0,
  });
  return { loadingNglCneeList, nglCneeList, errorNglCneeList };
};

export default useGetNglCneeList;
