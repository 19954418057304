import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import DispatchByStatus from '../../../components/Dispatch/DispatchByStatus';
import HeaderButtons from '../../../components/Dispatch/HeaderButtons';
import FilterButtons from '../../../components/Dispatch/FilterButtons';
import getQueryKeyStatus from '../../../utils/Dispatch/Common/Generator/getQueryKeyStatus';
import DispatchAdvancedSearchModal from '../../../components/Dispatch/DispatchAdvancedSearchModal';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import generateInitAdvancedForm from '../../../utils/Dispatch/Common/Generator/generateInitAdvancedForm';
import useGetYardList from '../../../hooks/Dispatch/Common/useGetYardList';
import { DISPATCH_TYPE_BOOKING } from '../../../constants/Dispatch/Common/category';
import DispatchBooking from '../../../components/Dispatch/DispatchBooking';
import usePatchCompleteBookingList from '../../../hooks/Dispatch/Export/Booking/usePatchCompleteBookingList';
import usePatchIncompleteBookingList from '../../../hooks/Dispatch/Export/Booking/usePatchIncompleteBookingList';
import useControlQueries from '../../../hooks/Dispatch/Common/useControlQueries';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import { LOCATION_TAB_FILTERS } from '../../../constants/Dispatch/Common/filterButtonSettings';

const DispatchByCategory = ({ isEditable, category, woStatus, ...props }) => {
  const gridRef = useRef(null);
  const path = useLocation().pathname;
  const division = useSelector(state => state.nowDiv);
  const status = getQueryKeyStatus({ category, woStatus });

  /* Modal Data */
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [messageModalMessage, setMessageModalMessage] = useState('');
  const [customizeCols, setCustomizeCols] = useState(false);
  const [isTabModalOpen, setIsTabModalOpen] = useState(false);
  const [tabIndexOpen, setTabIndexOpen] = useState(0);
  const [isLoadingOpen, setIsLoadingOpen] = useState(false);

  const bookingOpeners = {
    isTabModalOpen,
    setIsTabModalOpen,
    tabIndexOpen,
    setTabIndexOpen,
    setMessageModalMessage,
    isMessageModalOpen,
    setIsMessageModalOpen,
  };

  const [yardList, setYardList] = useState([]);

  const { allQueries, setQueryObject, subFilter, setSubFilter, setYardQuery } =
    useControlQueries({
      woStatus,
      category,
      division,
      status,
      path,
    });

  useGetYardList({ setYardQuery, setYardList, category });

  /* Yard Cnee tab setter */
  const [locStatus, setLocStatus] = useState(null);
  useEffect(() => {
    setLocStatus(
      LOCATION_TAB_FILTERS?.includes(subFilter)
        ? `${allQueries?.status}_yard`
        : null,
    );
  }, [allQueries?.status, subFilter]);

  /* Advanced Search Setting */
  const advancedSearchController = useForm({
    shouldUnregister: true,
    defaultValues: generateInitAdvancedForm({ woStatus, category }),
  });

  useEffect(() => {
    if (isAdvancedSearchOpen) {
      advancedSearchController.reset({ ...allQueries?.queryObject });
    }
  }, [isAdvancedSearchOpen]);

  /** Hooks for Booking */
  const messageModalSetters = {
    setIsMessageModalOpen,
    setMessageModalMessage,
    setIsLoadingOpen,
  };

  const { mutate: mutateBkListComplete } = usePatchCompleteBookingList({
    ...messageModalSetters,
  });

  const { mutate: mutateBkListIncomplete } = usePatchIncompleteBookingList({
    ...messageModalSetters,
  });

  return (
    <div className="w-full h-full flex flex-col">
      <FilterButtons
        gridRef={gridRef}
        status={allQueries?.status}
        category={category}
        mainFilter={path}
        subFilter={subFilter}
        setSubFilter={setSubFilter}
        allQueries={allQueries}
      />
      <HeaderButtons
        {...allQueries}
        setAdvancedSearchOpen={setIsAdvancedSearchOpen}
        setCustomizeCols={setCustomizeCols}
        subFilter={subFilter}
        gridRef={gridRef}
        yardList={yardList}
        category={category}
        setYardQuery={setYardQuery}
        setTabModalOpen={setIsTabModalOpen}
        setTabIndexOpen={setTabIndexOpen}
        handleBookingListComplete={mutateBkListComplete}
        handleBookingListIncomplete={mutateBkListIncomplete}
      />
      {category === DISPATCH_TYPE_BOOKING ? (
        <DispatchBooking
          {...allQueries}
          gridRef={gridRef}
          openers={bookingOpeners}
          {...messageModalSetters}
        />
      ) : (
        <DispatchByStatus
          {...allQueries}
          allQueries={allQueries}
          woStatus={woStatus}
          subFilter={subFilter}
          locStatus={locStatus}
          isEditable={isEditable}
          gridRef={gridRef}
          customizeCols={customizeCols}
          setCustomizeCols={setCustomizeCols}
          {...messageModalSetters}
          isLoadingOpen={isLoadingOpen}
        />
      )}
      <DispatchAdvancedSearchModal
        advancedSearchController={advancedSearchController}
        setQueryObject={setQueryObject}
        isOpen={isAdvancedSearchOpen}
        setIsOpen={setIsAdvancedSearchOpen}
        {...messageModalSetters}
        woType={category}
      />
      {isMessageModalOpen && (
        <CommonErrorModal
          open={isMessageModalOpen}
          setIsOpen={setIsMessageModalOpen}
          message={messageModalMessage}
        />
      )}
      <CommonLoading open={isLoadingOpen} />
    </div>
  );
};

export default DispatchByCategory;
