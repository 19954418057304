import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import getARData from '../../../../services/BCDetail/BillingInfo/AR/Get/getARData';
import bcDetailKeys from '../../keys';

const useGetARData = ({ setIsAlertOpen, setErrorMessage }) => {
  const [ARData, setARData] = useState(null);

  const woNo =
    document.URL?.split('/')?.[document?.URL?.split('/')?.length - 1];

  const query = useQuery({
    queryKey: bcDetailKeys.billingInfoAR({ woNo }),
    queryFn: () => getARData({ woNo }),
    refetchOnWindowFocus: false,
    enabled: !!woNo,
  });

  useEffect(() => {
    if (query?.data?.data) {
      setARData(query?.data?.data);
    }
  }, [query?.data]);

  useEffect(() => {
    if (query?.error) {
      setIsAlertOpen(true);
      setErrorMessage('Failed to get AR data.');
    }
  }, [query?.error]);

  const refetchARData = async () => {
    const oldData = ARData;
    const newData = await query.refetch()?.then(res => res?.data?.data);
    oldData?.forEach((oldItem, index) => {
      if (oldItem?.seq === newData?.[index]?.seq) {
        oldData[index] = newData[index];
      }
    });
    setARData(oldData);
  };

  return { ...query, ARData, refetchARData };
};

export default useGetARData;
