import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import { acctDefaultAPI } from '../../api';

export const getAPARReconcileList = async ({ queryParam }) => {
  try {
    const response = await acctDefaultAPI.get(`qbrecon`, {
      params: queryParam,
    });
    return response.data;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};
