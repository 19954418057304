import { useEffect, useState } from 'react';
import handleCheckboxChange from './handleCheckboxChange';
import { WO_CATEGORY_NAME } from '../../../constants/TmsReport/Common';

const getCheckboxOptions = ({
  scheduleTypeController,
  setScheduleTypeQuery,
  setDivisionQuery,
  setCategoryQuery,
  allQueries,
  divList,
  filterStorage,
}) => {
  const defaultDiv = localStorage.getItem('defaultDiv');
  const [scheduleTypeOptionList, setScheduleTypeOptionList] = useState([
    {
      label: 'LIVE',
      value: 'LIVE',
      defaultValue: filterStorage?.filter?.['sche-type']?.LIVE,
      onChange: ({ ...props }) =>
        handleCheckboxChange({
          ...props,
          setQuery: setScheduleTypeQuery,
          item: 'sche-type',
          filterStorage,
        }),
    },
    {
      label: 'DROP',
      value: 'DROP',
      defaultValue: filterStorage?.filter?.['sche-type']?.DROP,
      onChange: ({ ...props }) =>
        handleCheckboxChange({
          ...props,
          setQuery: setScheduleTypeQuery,
          item: 'sche-type',
          filterStorage,
        }),
    },
    {
      label: 'VAN',
      value: 'LVAN',
      defaultValue: filterStorage?.filter?.['sche-type']?.LVAN,
      onChange: ({ ...props }) =>
        handleCheckboxChange({
          ...props,
          setQuery: setScheduleTypeQuery,
          item: 'sche-type',
          filterStorage,
        }),
    },
  ]);

  useEffect(() => {
    const vanQueryValue = allQueries?.category?.[WO_CATEGORY_NAME?.VAN];
    if (_.isBoolean(vanQueryValue) && vanQueryValue === false) {
      scheduleTypeController?.setValue('LVAN', vanQueryValue);
    }
    setScheduleTypeOptionList(prev => [
      ...prev?.map(item => {
        if (item?.value === 'LVAN') {
          return {
            ...item,
            disabled: !vanQueryValue,
          };
        }
        return item;
      }),
    ]);
  }, [allQueries?.category?.[WO_CATEGORY_NAME?.VAN]]);

  const divisionOptionList = [
    ...divList?.map(div => {
      return {
        label: div,
        value: div,
        defaultValue:
          typeof filterStorage?.filter?.div?.[div] === 'boolean'
            ? filterStorage?.filter?.div?.[div]
            : div === defaultDiv,
        onChange: ({ ...props }) =>
          handleCheckboxChange({
            ...props,
            setQuery: setDivisionQuery,
            item: 'div',
            filterStorage,
          }),
      };
    }),
  ];

  const categoryOptionList = [
    {
      label: 'IMPORT',
      value: WO_CATEGORY_NAME?.IMPORT,
      defaultValue: filterStorage?.filter?.category?.[WO_CATEGORY_NAME?.IMPORT],
      onChange: ({ ...props }) =>
        handleCheckboxChange({
          ...props,
          setQuery: setCategoryQuery,
          item: 'category',
          filterStorage,
        }),
    },
    {
      label: 'EXPORT',
      value: WO_CATEGORY_NAME?.EXPORT,
      defaultValue: filterStorage?.filter?.category?.[WO_CATEGORY_NAME?.EXPORT],
      onChange: ({ ...props }) =>
        handleCheckboxChange({
          ...props,
          setQuery: setCategoryQuery,
          item: 'category',
          filterStorage,
        }),
    },
    {
      label: 'VAN',
      value: WO_CATEGORY_NAME?.VAN,
      defaultValue: filterStorage?.filter?.category?.[WO_CATEGORY_NAME?.VAN],
      onChange: ({ ...props }) =>
        handleCheckboxChange({
          ...props,
          setQuery: setCategoryQuery,
          item: 'category',
          filterStorage,
        }),
    },
    {
      label: 'BROKERAGE',
      value: WO_CATEGORY_NAME?.BROKERAGE,
      defaultValue:
        filterStorage?.filter?.category?.[WO_CATEGORY_NAME?.BROKERAGE],
      onChange: ({ ...props }) =>
        handleCheckboxChange({
          ...props,
          setQuery: setCategoryQuery,
          item: 'category',
          filterStorage,
        }),
    },
    {
      label: 'BARE CHASSIS',
      value: WO_CATEGORY_NAME?.BARE_CHASSIS,
      defaultValue:
        filterStorage?.filter?.category?.[WO_CATEGORY_NAME?.BARE_CHASSIS],
      onChange: ({ ...props }) =>
        handleCheckboxChange({
          ...props,
          setQuery: setCategoryQuery,
          item: 'category',
          filterStorage,
        }),
    },
  ];

  return { scheduleTypeOptionList, divisionOptionList, categoryOptionList };
};

export default getCheckboxOptions;
