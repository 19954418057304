import { useMutation, useQueryClient } from '@tanstack/react-query';
import mobileAdminKeys from '../keys';
import patchConfirmStatus from '../../../services/MobileAdmin/BillConfirm/Patch/patchConfirmStatus';
import useControlToast from '../../Common/Toast/useControlToast';
import { SUCCESS_UPDATE } from '../../../constants/Common/successMessage';
import updateButtonDisabled from '../../../utils/Common/Handler/updateButtonDisabled';
import updateGridData from '../../../utils/Common/Handler/updateGridData';

const usePatchConfirmStatus = ({
  gridRef,
  setMessageModalOpen,
  setMessageContent,
  setConfirmOpen,
  division,
}) => {
  const { addToast } = useControlToast();
  const queryClient = useQueryClient();
  const mutator = useMutation({
    mutationKey: mobileAdminKeys.billConfirm({ division }),
    mutationFn: patchConfirmStatus,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: (data, variables) => {
      const seqList = variables?.data?.seq_list;
      seqList?.forEach(seq => {
        updateGridData({
          gridRef,
          newData: data?.data?.find(item => item?.seq === seq),
          isUpdate: true,
        });
      });

      setConfirmOpen(false);
      addToast({ message: SUCCESS_UPDATE });
    },
    onError: error => {
      setMessageContent(error?.message);
      setMessageModalOpen(true);
    },
  });

  return mutator;
};

export default usePatchConfirmStatus;
