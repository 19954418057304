import React, { useState } from 'react';
import dayjs from 'dayjs';
import { useFormContext } from 'react-hook-form';
import CommonButton from '../../../../CommonButton/CommonButton';

const MemoTextField = ({ res, isEditable, buttonRef }) => {
  const { register, setValue } = useFormContext();
  const seq = res?.seq;
  const [length, setLength] = useState(res?.memo?.content?.length || 0);

  const handleClear = () => {
    setValue(`${seq}.content`, '');
    setLength(0);
  };

  return (
    <div className="mt-[13px] ml-[54px]">
      <div className="w-[1750px] bg-[#DDE2EC] h-[30px] px-[19px] pt-[9px] pb-[8px] text-[11px] font-[700] mb-[2.5px] flex justify-between shadow drop-shadow-[0_0.5px_1px_rgba(0,0,0,0.25)]">
        <p>MOBILE MEMO</p>
        <div className="flex items-center space-x-[20px] text-[#666] text-[12px] font-[500]">
          <p>
            {res?.memo?.updated_date
              ? dayjs(res?.memo?.updated_date).format('MM/DD/YY')
              : 'MM/DD/YY'}
          </p>
          <p>
            {res?.memo?.updated_date
              ? dayjs(res?.memo?.updated_date).format('HH:mm')
              : 'HH:MM'}
          </p>
          <p>{res?.memo?.updated_by ? res?.memo?.updated_by : 'UserID'}</p>
        </div>
      </div>
      <div className="relative">
        <textarea
          className="bg-[#F2F4F8] outline-none text-[11px] w-[1750px] h-[100px] resize-none shadow drop-shadow-[0_0.5px_1px_rgba(0,0,0,0.25)] pl-[19px] pt-[10px]
        focus:border-[#264B9F] focus:border-[1.5px] focus:shadow focus:drop-shadow-[0_0_4px_#8BBCE9] focus:bg-white "
          {...register(`${seq}.content`)}
          maxLength={300}
          defaultValue={res?.memo?.content}
          onChange={e => {
            setLength(e?.target?.value?.length);
          }}
          disabled={!isEditable}
        />
        <div className="flex absolute bottom-1 right-12 mx-[38px] my-[8px] space-x-[6px]">
          <div className="text-[#A6A6A6] text-[12px] font-[500] h-[18px] mr-[9px]">
            <p className="mt-1">
              {length}
              /300
            </p>
          </div>
          {isEditable && (
            <>
              <CommonButton onClick={handleClear} size="small" category="clear">
                Clear
              </CommonButton>
              <CommonButton
                ref={buttonRef}
                size="small"
                category="save"
                type="submit"
              >
                Save
              </CommonButton>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MemoTextField;
