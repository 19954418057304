import { useQuery } from '@tanstack/react-query';
import getDriverList from '../../../services/Master/Driver/Get/getDriverList';
import masterKeys from '../keys';

const useGetDriverData = ({ queryObject }) => {
  const result = useQuery({
    queryKey: masterKeys?.driverSearchList({ queryObject }),
    queryFn: () => getDriverList({ queryObject }),
  });
  return result;
};

export default useGetDriverData;
