import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';
import billingInfoDefaultAPI from '../../api';

const patchARInvoice = async ({ woNo, data }) => {
  try {
    const response = await billingInfoDefaultAPI.patch(
      `${woNo}/ar-inv-no`,
      data,
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchARInvoice;
