import dayjs from 'dayjs';
import { checkIsDateValid } from './dateConstraintChecker';

const searchFilter = ({ ...props }) => {
  const { handleSubmit, modalController, setFilter } = props;

  handleSubmit?.(data => {
    const {
      cont_no: contNo,
      from_sche_date: fromScheDate,
      to_sche_date: toScheDate,
    } = data;

    /** Check date constraint */
    if (
      !checkIsDateValid({
        from: fromScheDate,
        to: toScheDate,
        modalController,
      })
    )
      return false;

    setFilter(prev => {
      return {
        ...prev,
        contNo,
        fromScheDate:
          fromScheDate && dayjs(fromScheDate)?.isValid()
            ? dayjs(fromScheDate)?.format('YYYY-MM-DD')
            : null,
        toScheDate:
          toScheDate && dayjs(toScheDate)?.isValid()
            ? dayjs(toScheDate)?.format('YYYY-MM-DD')
            : null,
      };
    });

    return true;
  })();
};

export default searchFilter;
