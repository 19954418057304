import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import divBasicDefaultApi from '../../divApi';

const patchAPTableActivate = async ({ div, type }) => {
  try {
    const response = await divBasicDefaultApi.patch(
      `${div}/ap-table/activate`,
      {
        type,
      },
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchAPTableActivate;
