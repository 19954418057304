import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import divBasicDefaultApi from '../../divApi';

const postWeightPerZIP = async ({ div, zip, state, weight }) => {
  try {
    const response = await divBasicDefaultApi.post(`${div}/weight-per-zip`, {
      zip,
      state,
      weight,
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default postWeightPerZIP;
