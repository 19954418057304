import React from 'react';
import ScheduleRowJob from '../ScheduleRowJob';
import ScheduleRowLocation from '../ScheduleRowLocation';

/**
 * @description
 * Schedule table row component for export page.
 * @param {object} jobSet
 * Schedule jobSet data.
 * @param {object} locationSet
 * Schedule locationSet data.
 * @param {object} setDriverAPModalOpener
 * Set driver list modal opener.
 * @param {object} setSelectedScheduleSeq
 * Set selected schedule sequence.
 * @returns
 * Schedule table row component for export page.
 */
const ScheduleRowWithPageCategory = ({ ...props }) => {
  /** Get data */
  const { pageCategory } = props;

  return pageCategory === 'export' ? (
    <div className="flex flex-col gap-y-[30px]">
      <ScheduleRowLocation {...props} />
      <ScheduleRowJob {...props} />
    </div>
  ) : (
    <div className="flex flex-col gap-y-[30px]">
      <ScheduleRowJob {...props} />
      <ScheduleRowLocation {...props} />
    </div>
  );
};

export default ScheduleRowWithPageCategory;
