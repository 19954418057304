import { concatPhoneNumber } from '../../../Common/Handler/phoneNumberHandler';
import updateButtonDisabled from '../../../Common/Handler/updateButtonDisabled';

const handleTextSubmit = ({ submitData, postMessageMutator, buttonRef }) => {
  updateButtonDisabled({ ref: buttonRef, disabled: true });

  submitData.cell_no = concatPhoneNumber({
    country: submitData?.cell_no_country,
    phone: submitData?.cell_no,
    suppressISOCode: true,
  });

  delete submitData.cell_no_country;

  postMessageMutator.mutate({ data: submitData, buttonRef });
};

export default handleTextSubmit;
