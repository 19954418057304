import { useMutation, useQueryClient } from '@tanstack/react-query';
import editJobMemoList from '../../../services/BCDetail/Memo/Patch/editJobMemoList';
import bcDetailKeys from '../keys';
import useControlToast from '../../Common/Toast/useControlToast';
import { SUCCESS_UPDATE } from '../../../constants/Common/successMessage';
import updateButtonDisabled from '../../../utils/Common/Handler/updateButtonDisabled';

const usePatchMemoContent = ({
  woNo,
  setMobileModalOpener,
  setMessageModalOpen,
  setMessageContent,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  const mutator = useMutation({
    mutationFn: editJobMemoList,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: () => {
      addToast({ message: SUCCESS_UPDATE });
      if (woNo) {
        queryClient.invalidateQueries({
          queryKey: bcDetailKeys.mobileMemoList({ woNo }),
        });
      } else {
        setMobileModalOpener?.(false);
      }
    },
    onError: error => {
      setMessageContent(error?.message);
      setMessageModalOpen(true);
    },
  });

  return { ...mutator };
};

export default usePatchMemoContent;
