import { useQuery } from '@tanstack/react-query';
import { useState, useEffect } from 'react';
import adminKeys from '../../keys';
import getUserList from '../../../../services/Admin/User/Get/getUserList';

const useGetUserList = ({
  nowDiv,
  setIsOpenErrorModal,
  setErrorMessage,
  setIsSuccess,
}) => {
  const { data, isLoading, isFetching, isError, error } = useQuery({
    queryKey: adminKeys.userList({ nowDiv }),
    queryFn: () => getUserList(),
    staleTime: 0,
    refetchOnWindowFocus: false,
  });

  const [userList, setUserList] = useState([]);

  useEffect(() => {
    setUserList(data?.data);
  }, [data?.data]);

  useEffect(() => {
    if (isError) {
      setIsOpenErrorModal(true);
      setErrorMessage(error?.message);
      setIsSuccess(false);
    }
  }, [isError]);

  return { userList, isLoading, isFetching };
};

export default useGetUserList;
