import { handleDateValue } from '../../Common/Generator/editDataGridValueSetter';

const generatePatchData = ({ dataObject }) => {
  const patchData = {};
  Object?.entries(dataObject)?.forEach(([key, value]) => {
    switch (key) {
      case 'inv_date':
        patchData[key] = handleDateValue({ data: value });
        break;
      case 'inv_from':
        patchData[key] = handleDateValue({ data: value });
        break;
      case 'inv_to':
        patchData[key] = handleDateValue({ data: value });
        break;
      default:
        patchData[key] = !value && value !== 0 ? '' : value;
        break;
    }
  });
  return patchData;
};

export default generatePatchData;
