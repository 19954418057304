import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useGetUserDivisionList = () => {
  const originUserDivList = useSelector(state => state?.common?.allDiv);

  const [divList, setDivList] = useState([]);
  useEffect(() => {
    const tempDivList = originUserDivList?.map(div => ({ div }));
    setDivList(tempDivList);
  }, [originUserDivList]);

  return divList;
};

export default useGetUserDivisionList;
