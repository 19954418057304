import { useEffect, useState } from 'react';
import { INITIAL_INVOICE_LIST } from '../../../../constants/Acct/Common/initialInvoiceList';
import resolveErrorListGenerator from '../../../../utils/Acct/Common/Generator/resolveErrorListGenerator';
import { openWaringModal } from '../../../../utils/Acct/Common/Handler/warningModalHandler';
import useControlToast from '../../../Common/Toast/useControlToast';
import { SUCCESS_SAVE } from '../../../../constants/Common/successMessage';

const useControlWarningModal = ({
  setErrorModalOptions,
  refetchList,
  setOpen,
  setInvoiceList,
  ...props
}) => {
  const { setErrorModalOpen, setErrorMessage, setIsSuccess } =
    setErrorModalOptions;

  const { addToast } = useControlToast();

  const { scanController, vendorController } = props;

  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [warningModalIndex, setWarningModalIndex] = useState(0);
  const [warningModalData, setWarningModalData] = useState({});

  const [submitLength, setSubmitLength] = useState(0);
  const [progressLength, setProgressLength] = useState(0);
  const [submitData, setSubmitData] = useState({});

  const [duplicationErrorList, setDuplicationErrorList] = useState([]);
  const [resolveErrorList, setResolveErrorList] = useState([]);
  const [patchFormKey, setPatchFormKey] = useState(null);

  const [basicErrorMessage, setBasicErrorMessage] = useState(null);

  const [alwaysFlag, setAlwaysFlag] = useState(false);
  const [applyType, setApplyType] = useState(null);

  const [isAllSave, setIsAllSave] = useState(null);

  const [isCompletedPatchFlag, setIsCompletedPatchFlag] = useState(false);

  useEffect(() => {
    const isEndPost = submitLength !== 0 && submitLength === progressLength;
    const isExistDuplicateError = duplicationErrorList?.length > 0;
    const isExistBasicError = basicErrorMessage;

    if (isEndPost) {
      if (!isExistDuplicateError && !isExistBasicError) {
        /** Success Modal */
        addToast({ message: SUCCESS_SAVE });
        setDuplicationErrorList([]);
        setResolveErrorList([]);
        setWarningModalIndex(0);
        refetchList();

        /** All Save Success Logic */
        if (isAllSave) {
          setOpen(false);
          setInvoiceList(INITIAL_INVOICE_LIST);
          setAlwaysFlag(false);
          setApplyType(null);
          scanController.reset();
          vendorController.reset();
        }
      } else if (isExistDuplicateError) {
        /** Duplication Error */
        if (alwaysFlag) {
          /** Checked Always */
          const resolveItems = resolveErrorListGenerator({
            type: applyType,
            duplicationErrorList,
            startIndex: warningModalIndex,
          });
          setResolveErrorList(resolveItems);
        } else {
          /** UnChecked Always */
          openWaringModal({
            warningModalIndex,
            duplicationErrorList,
            setWarningModalIndex,
            setOpenWarningModal,
            setWarningModalData,
          });
        }
      } else if (isExistBasicError) {
        /** Only basic Error */
        setIsSuccess(false);
        setErrorModalOpen(true);
        setErrorMessage(isExistBasicError);
      }

      /** reset */
      setSubmitLength(0);
    }
  }, [progressLength]);

  useEffect(() => {
    if (isCompletedPatchFlag && basicErrorMessage) {
      setIsSuccess(false);
      setErrorModalOpen(true);
      setErrorMessage(basicErrorMessage);

      setIsCompletedPatchFlag(prev => false);
    }
  }, [basicErrorMessage]);

  return {
    openWarningModal,
    setOpenWarningModal,
    warningModalIndex,
    setWarningModalIndex,
    warningModalData,
    setWarningModalData,
    submitLength,
    setSubmitLength,
    progressLength,
    setProgressLength,
    submitData,
    setSubmitData,
    duplicationErrorList,
    setDuplicationErrorList,
    resolveErrorList,
    setResolveErrorList,
    patchFormKey,
    setPatchFormKey,
    basicErrorMessage,
    setBasicErrorMessage,
    alwaysFlag,
    setAlwaysFlag,
    applyType,
    setApplyType,
    isAllSave,
    setIsAllSave,
    isCompletedPatchFlag,
    setIsCompletedPatchFlag,
  };
};

export default useControlWarningModal;
