const handlePlaceSelect = ({ setValue, ...placeData }) => {
  setValue('address_01', placeData?.address);
  setValue('zip', placeData?.zipcode);
  setValue('city', placeData?.city);
  setValue('state', placeData?.state);
  setValue('country', placeData?.country);
  setValue('place_id', placeData?.placeId);
  setValue('lat', placeData?.lat);
  setValue('lng', placeData?.lng);
  setValue('timezone', placeData?.timezone);
};

export default handlePlaceSelect;
