export const CONTAINER_PROPERTY = [
  'cont_no',
  'size',
  'type',
  'wgt',
  'qty',
  'unit',
  'seal',
  'commodity',
  'ch_no',
  'pool',
  'ch_type',
  'wms',
];

export const TRAILER_PROPERTY = [
  'trailer_no',
  'size',
  'type',
  'wgt',
  'qty',
  'unit',
  'seal',
  'commodity',
  'wms',
  'empty_load_status', // For brokerage
  'ch_no', // For brokerage
  'pool', // For brokerage
  'ch_type', // For brokerage
];

export const CHASSIS_PROPERTY = ['ch_no', 'pool', 'ch_type', 'wms'];
