import generateScheDateField from './generateScheDateField';

const ETA_SORTING = { eta: { sort: 'asc' } };

const LFD_SORTING = { lfd: { sort: 'asc' }, lfd_date: { sort: 'asc' } };

const RFD_SORTING = { rfd: { sort: 'asc' }, rfd_date: { sort: 'asc' } };

const WO_NO_SORTING = { wo_no: { sort: 'desc' } };

const getSortingDefs = ({ status, subFilter }) => {
  if (['yard', 'schedule'].includes(subFilter)) {
    const colId = generateScheDateField(
      subFilter === 'yard' && status?.includes('ava')
        ? status?.replace('ava', 'dilv')
        : status,
    );
    return {
      [colId]: { sort: 'asc', sortIndex: 0 },
    };
  }
  if (subFilter === 'cnee') return WO_NO_SORTING;
  if (subFilter?.includes('booking')) return RFD_SORTING;
  if (subFilter === 'lfd') return LFD_SORTING;
  if (subFilter === 'no-lfd') return ETA_SORTING;
  if (subFilter === 'total') return WO_NO_SORTING;
  if (subFilter === 'no-schedule' && status?.includes('imp')) {
    return status?.includes('ava') ? LFD_SORTING : RFD_SORTING;
  }
  return WO_NO_SORTING;
};

export default getSortingDefs;
