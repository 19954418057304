import React from 'react';

const NotSelected = () => {
  return (
    <div className="flex-1 flex justify-center items-center h-full">
      <div className="text-center text-sky-950 text-[22px]">
        Please select the information you want to see
      </div>
    </div>
  );
};

export default NotSelected;
