import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';
import bcDetailDefaultApi from '../../../api';

const patchSchedule = async ({ data, ...props }) => {
  try {
    const woNo =
      document.URL?.split('/')?.[document?.URL?.split('/')?.length - 1];
    const response = await bcDetailDefaultApi.patch(`${woNo}`, data);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchSchedule;
