import { useMutation } from '@tanstack/react-query';
import patchCompanyData from '../../../services/Master/Company/Patch/patchCompanyData';
import handleCompanyData from '../../../utils/Master/Company/Handler/handleCompanyData';
import { QB_AUTH_CODE } from '../../../constants/Acct/Common/errors';
import useControlToast from '../../Common/Toast/useControlToast';
import { SUCCESS_UPDATE } from '../../../constants/Common/successMessage';
import updateButtonDisabled from '../../../utils/Common/Handler/updateButtonDisabled';
import updateGridData from '../../../utils/Common/Handler/updateGridData';

const useUpdateCompanyData = ({
  gridRef,
  setMessageContent,
  setMessageModalOpen,
}) => {
  const { addToast } = useControlToast();

  const mutator = useMutation({
    mutationFn: ({ submitData }) => {
      handleCompanyData(submitData);
      return patchCompanyData(submitData);
    },
    refetchOnWindowFocus: false,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: (data, variables) => {
      updateGridData({
        gridRef,
        newData: { ...variables?.submitData, ...data?.data },
        key: 'company',
        isUpdate: true,
      });
      addToast({ message: SUCCESS_UPDATE });
    },
    onError: error => {
      if (
        error?.cause?.response?.status === 302 &&
        error?.cause?.response?.data?.code === QB_AUTH_CODE
      ) {
        window.open(error?.cause?.response?.data?.url, '_blank');
      } else {
        setMessageContent(error.message);
        setMessageModalOpen(true);
      }
    },
  });
  return mutator;
};

export default useUpdateCompanyData;
