import { useMutation } from '@tanstack/react-query';
import { SUCCESS_SEND } from '../../../../constants/Common/successMessage';
import postRateCFM from '../../../../services/BCDetail/BillingInfo/AP/Post/postRateCFM';
import isServerResponseValid from '../../../../utils/Common/isServerResponseValid';
import useControlToast from '../../../Common/Toast/useControlToast';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';

const usePostRateCFM = ({
  setIsAlertOpen,
  setErrorMessage,
  setIsSuccess,
  setIsActiveClicked,
}) => {
  const { addToast } = useControlToast();

  const mutator = useMutation({
    mutationFn: postRateCFM,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: success => {
      if (isServerResponseValid(success)) {
        setIsActiveClicked(false);
        addToast({
          message: SUCCESS_SEND,
        });
      }
    },

    onError: error => {
      setIsActiveClicked(false);
      setIsSuccess(false);
      setIsAlertOpen(true);
      setErrorMessage(error?.message);
    },
  });

  return mutator;
};

export default usePostRateCFM;
