import generateEquipProperty from '../Generator/generateEquipProperty';
import { getOrderPostForm } from '../Generator/formGenerator';
import copyOrderDataEquipsToForm from './copyOrderDataEquipsToForm';

/**
 * @description
 * Function to copy order data to form
 * @param {string} subCategory
 * Sub category of order
 * @param {list} containerList
 * Container list
 * @param {object} orderInputData
 * Order input data
 * @returns {object}
 * Maniuplated order form
 */
const copyOrderDataToForm = ({
  subCategory,
  containerList,
  orderInputData,
}) => {
  // Create form of order post.
  const orderPostForm = getOrderPostForm({ subCategory });

  // Bind order input data to order post form.
  const orderPostFormKey = Object.keys(orderPostForm);
  orderPostFormKey.forEach(key => {
    orderPostForm[key] = orderInputData[key];
  });

  // Set order
  orderPostForm.equips = copyOrderDataEquipsToForm({
    containerFormProperty: generateEquipProperty({ subCategory }),
    orderInputData,
    containerList,
  });

  return orderPostForm;
};

export default copyOrderDataToForm;
