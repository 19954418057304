import styled from 'styled-components';

const StyledBCDetailBillingInfoRateCFMModalContent = styled.div`
  color: #666;
  font-size: 12px;
  font-weight: 400;
  margin-top: 17px;
  line-height: 150%;
`;

export default StyledBCDetailBillingInfoRateCFMModalContent;
