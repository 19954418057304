import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import divBasicDefaultApi from '../../divApi';

const patchWeightPerZIP = async ({ seq, zip, state, weight }) => {
  try {
    const response = await divBasicDefaultApi.patch(`weight-per-zip/${seq}`, {
      zip,
      state,
      weight,
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchWeightPerZIP;
