import React from 'react';
import { QueryClient } from '@tanstack/react-query';
import CommonButton from '../../../CommonButton/CommonButton';
import dailyReportKeys from '../../../../hooks/DailyReport/keys';

const DriverModalButtonContent = ({
  setWONo,
  setOpen,
  setAPModalOpen,
  getAPDataRefetch,
  selectedDriverData,
  setSelectedScheduleSeq,
  setSelectedDriverData,
  ...props
}) => {
  const queryClient = new QueryClient();

  return (
    <div className="w-full flex justify-center items-center">
      <div className="flex gap-[20px]">
        <CommonButton
          category="cancel"
          onClick={() => {
            setOpen(false);
            queryClient?.invalidateQueries({
              queryKey: dailyReportKeys?.driver(),
            });
            setWONo('');
            setSelectedScheduleSeq(null);
            setSelectedDriverData(null);
          }}
        >
          Cancel
        </CommonButton>
        <CommonButton
          category="assign"
          onClick={async () => {
            const { isError: apIsError, isFetching: apIsFetching } =
              await getAPDataRefetch();
            if (!apIsError && !apIsFetching) {
              setOpen(false);
              setAPModalOpen(true);
            }
          }}
          disabled={!selectedDriverData}
        >
          Assign
        </CommonButton>
      </div>
    </div>
  );
};

export default DriverModalButtonContent;
