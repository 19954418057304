import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { COMMON_FREEDAYS_SELECT_OPTION } from '../../../../../constants/Common/selectOption';
import useGetBasicInfo from '../../../../../hooks/BCDetail/DetailInfo/BasicInfo/useGetBasicInfo';
import setLFDRFDColor from '../../../../../utils/BCDetail/DetailInfo/BasicInfo/Handler/setLFDRFDColor';
import CommonAutoComplete from '../../../../Inputs/CommonAutoComplete/CommonAutoComplete';
import CommonDate from '../../../../Inputs/CommonDate/CommonDate';
import CommonTextSelect from '../../../../Inputs/CommonTextSelect/CommonTextSelect';
import CommonTime from '../../../../Inputs/CommonTime/CommonTime';
import Tooltip from '../Tooltip';

const DateInputForm = ({ ...props }) => {
  /** Get props data */
  const {
    bookingNumber,
    basicInfoData,
    sizeWatcher,
    typeWatcher,
    sslWatcher,
    isEditable,
    isLoadingBookingNumber,
  } = props;
  /** Get WO type */
  const woType =
    document.URL?.split('/')?.[document?.URL?.split('/')?.length - 2];
  const [bkList, setBkList] = useState([]);

  useEffect(() => {
    setBkList(prev => {
      const newBkList =
        bookingNumber?.map(item => {
          return {
            label: item?.bk_no,
            value: String(item?.seq),
          };
        }) || [];
      return [...newBkList];
    });
  }, [bookingNumber]);

  const pageTitle = location?.pathname?.split('/')[3];
  const { control, watch } = useFormContext();
  const basicInfo = useGetBasicInfo();

  const lfdWatch = useWatch({
    name: 'lfd',
    defaultValue: basicInfo?.basicInfoData?.lfd,
  });
  const rfdWatch = useWatch({
    name: 'rfd',
    defaultValue: basicInfo?.basicInfoData?.rfd,
  });

  const [lfdTextColor, setLfdTextColor] = useState('');
  const [rfdTextColor, setRfdTextColor] = useState('');

  useEffect(() => {
    if (!basicInfo?.basicInfoLoading) {
      if (!basicInfo?.basicInfoData?.po_location?.actual_in_date) {
        setLFDRFDColor('lfd', lfdWatch, setLfdTextColor);
      }

      if (!basicInfo?.basicInfoData?.rtn_location?.actual_in_date) {
        setLFDRFDColor('rfd', rfdWatch, setRfdTextColor);
      }
    }
  }, [basicInfo?.basicInfoLoading, lfdWatch, rfdWatch]);

  return (
    <div>
      {!basicInfo?.basicInfoLoading && !basicInfo?.isError && (
        <div
          className={`bg-[#F2F4F8] w-[428px] rounded-[5px] px-[15px] py-[33px] mb-[17px] ${
            pageTitle === 'import' ? 'h-[288px]' : ''
          } ${pageTitle === 'export' ? 'h-[227px]' : ''}
      `}
        >
          <div className="w-[398px] flex-wrap flex justify-between items-center">
            <div className="w-[189px]">
              <CommonDate
                width="w-full"
                label="ETA"
                inputName="eta"
                defaultValue={basicInfo?.basicInfoData?.eta}
                onChangeHandler={({ e, onChange, ...onChangeProps }) => {
                  onChange(
                    e && dayjs(e)?.isValid()
                      ? dayjs(e).format('YYYY-MM-DD')
                      : null,
                  );
                }}
                disabled={!isEditable || !!basicInfo?.basicInfoData?.completed}
              />
            </div>
            {pageTitle !== 'export' && (
              <div className="w-[189px]">
                <CommonDate
                  textColor={lfdTextColor}
                  label="LFD"
                  width="w-full"
                  inputName="lfd"
                  defaultValue={basicInfo?.basicInfoData?.lfd}
                  onChangeHandler={({ e, onChange }) => {
                    onChange(
                      e && dayjs(e)?.isValid()
                        ? dayjs(e).format('YYYY-MM-DD')
                        : null,
                    );
                  }}
                  disabled={
                    !isEditable || !!basicInfo?.basicInfoData?.completed
                  }
                />
              </div>
            )}
            <div className="w-[189px]">
              <CommonTextSelect
                label="FREEDAYS"
                required={[Boolean(true), Boolean(true)]}
                inputName={['freedays', 'freedays_type']}
                defaultValue={[
                  basicInfo?.basicInfoData?.freedays,
                  basicInfo?.basicInfoData?.freedays_type || '',
                ]}
                onChangeHandler={[
                  ({ e, onChange }) => {
                    onChange(Number(e?.target?.value));
                  },
                  ({ e, onChange }) => {
                    onChange(e?.target?.value);
                  },
                ]}
                option={COMMON_FREEDAYS_SELECT_OPTION}
                disabled={[
                  !isEditable || !!basicInfo?.basicInfoData?.completed,
                  !isEditable || !!basicInfo?.basicInfoData?.completed,
                ]}
              />
            </div>
            <div className="w-[189px]">
              <CommonDate
                textColor={rfdTextColor}
                label="RFD"
                width="w-full"
                inputName="rfd"
                defaultValue={basicInfo?.basicInfoData?.rfd}
                disabled
                onChangeHandler={({ e, onChange, ...onChangeProps }) => {
                  onChange(
                    e && dayjs(e)?.isValid()
                      ? dayjs(e).format('YYYY-MM-DD')
                      : null,
                  );
                }}
              />
            </div>

            <div className="w-[189px]">
              <CommonDate
                label="ERD"
                width="w-full"
                inputName="erd"
                defaultValue={basicInfo?.basicInfoData?.erd}
                onChangeHandler={({ e, onChange, ...onChangeProps }) => {
                  onChange(
                    e && dayjs(e)?.isValid()
                      ? dayjs(e).format('YYYY-MM-DD')
                      : null,
                  );
                }}
                disabled={
                  woType === 'export' ||
                  !isEditable ||
                  !!basicInfo?.basicInfoData?.completed
                }
              />
            </div>
            <div className="flex flex-row w-[189px]">
              <div className="w-[105px]">
                <CommonDate
                  label="CUT OFF"
                  inputName="cutoff_date"
                  defaultValue={basicInfo?.basicInfoData?.cutoff_date}
                  onChangeHandler={({ e, onChange }) => {
                    onChange(
                      e && dayjs(e)?.isValid()
                        ? dayjs(e).format('YYYY-MM-DD')
                        : null,
                    );
                  }}
                  disabled={
                    woType === 'export' ||
                    !isEditable ||
                    !!basicInfo?.basicInfoData?.completed
                  }
                />
              </div>
              <div className="w-[84px]">
                <CommonTime
                  label=""
                  inputName="cutoff_time"
                  defaultValue={
                    basicInfo?.basicInfoData?.cutoff_date &&
                    basicInfo?.basicInfoData?.cutoff_time &&
                    dayjs(
                      `${basicInfo?.basicInfoData?.cutoff_date} ${basicInfo?.basicInfoData?.cutoff_time}`,
                    )?.isValid()
                      ? dayjs(
                          `${basicInfo?.basicInfoData?.cutoff_date} ${basicInfo?.basicInfoData?.cutoff_time}`,
                        )
                      : null
                  }
                  onChangeHandler={({ e, onChange }) => {
                    onChange(e && dayjs(e)?.isValid() ? dayjs(e) : null);
                  }}
                  disabled={
                    woType === 'export' ||
                    !isEditable ||
                    !!basicInfo?.basicInfoData?.completed
                  }
                />
              </div>
            </div>
            <div className="w-[189px]">
              <CommonDate
                label="S/T"
                width="w-full"
                inputName="st"
                defaultValue={basicInfo?.basicInfoData?.st}
                onChangeHandler={({ e, onChange, ...onChangeProps }) => {
                  onChange(
                    e && dayjs(e)?.isValid()
                      ? dayjs(e).format('YYYY-MM-DD')
                      : null,
                  );
                }}
                disabled={!isEditable || !!basicInfo?.basicInfoData?.completed}
                required={woType === 'export' && basicInfoData?.related_wo}
              />
            </div>

            {pageTitle !== 'export' && (
              <div className="relative w-[189px] group/bk">
                <CommonAutoComplete
                  label="BK #"
                  defaultValue={
                    basicInfo?.basicInfoData?.bk_no
                      ? {
                          label: basicInfo?.basicInfoData?.bk_no?.bk_no,
                          value: String(basicInfo?.basicInfoData?.bk_no?.seq),
                        }
                      : null
                  }
                  option={bkList}
                  inputName="bk_no"
                  onChangeHandler={({ e, onChange, ...onChangeProps }) => {
                    onChange(onChangeProps?.selectProps);
                  }}
                  disabled={
                    !sizeWatcher ||
                    !typeWatcher ||
                    !sslWatcher ||
                    sizeWatcher === '' ||
                    typeWatcher === '' ||
                    sslWatcher === '' ||
                    !isEditable ||
                    !!basicInfo?.basicInfoData?.completed
                  }
                  isLoading={isLoadingBookingNumber}
                />
                <div className="absolute z-10 top-11 group-hover/bk:block hidden">
                  <Tooltip message={basicInfo?.basicInfoData?.bk_updated_by} />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DateInputForm;
