import styled from 'styled-components';

const StyledContainerInfoHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 34px;
  min-height: 34px;

  border-bottom: 1px solid #fff;
`;

export default StyledContainerInfoHeader;
