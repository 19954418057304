export const JOB_STANDARD_CODE = {
  SCHEDULED: 'JS0001',
  READY_TO_DRIVE: 'JS0002',
  ARRIVED_TO_PICK_UP: 'JS0003',
  PICKED_UP: 'JS0004',
  ARRIVED_TO_DROP: 'JS0005',
  DROP_OFF: 'JS0006',
  START_LOADING_UNLOADING: 'JS0007',
  FINISH_LOADING_UNLOADING: 'JS0008',
  RETURN_CONTAINER: 'JS0009',
};
