const useGetExcelStyles = () => {
  const excelStyles = [
    {
      id: 'date',
      dataType: 'DateTime',
      numberFormat: {
        format: 'MM/DD/YYYY',
      },
    },
    {
      id: 'datetime',
      dataType: 'DateTime',
      numberFormat: {
        format: 'MM/DD/YY HH:mm',
      },
    },

    /* Setting Excel cell background color */
    {
      id: 'blue1',
      interior: {
        pattern: 'Solid',
        color: '#CCD3E3',
      },
      font: {
        bold: true,
      },
    },
    {
      id: 'blue2',
      interior: {
        pattern: 'Solid',
        color: '#B3BCD9',
      },
      font: {
        bold: true,
      },
    },
    {
      id: 'blue3',
      interior: {
        pattern: 'Solid',
        color: '#919FC1',
      },
      font: {
        bold: true,
      },
    },
    {
      id: 'blue4',
      interior: {
        pattern: 'Solid',
        color: '#415688',
      },
      font: {
        color: '#ffffff',
        bold: true,
      },
    },
    {
      id: 'blue5',
      interior: {
        pattern: 'Solid',
        color: '#223C77',
      },
      font: {
        color: '#ffffff',
        bold: true,
      },
    },
    {
      id: 'blue6',
      interior: {
        pattern: 'Solid',
        color: '#002265',
      },
      font: {
        color: '#ffffff',
        bold: true,
      },
    },
    {
      id: 'blue7',
      interior: {
        pattern: 'Solid',
        color: '#1B335E',
      },
      font: {
        color: '#ffffff',
        bold: true,
      },
    },
    {
      id: 'blue8',
      interior: {
        pattern: 'Solid',
        color: '#979797',
      },
      font: {
        color: '#ffffff',
        bold: true,
      },
    },
    {
      id: 'blue9',
      interior: {
        pattern: 'Solid',
        color: '#646464',
      },
      font: {
        color: '#ffffff',
        bold: true,
      },
    },
    {
      id: 'yellow',
      interior: {
        pattern: 'Solid',
        color: '#FFF4CF',
      },
      font: {
        bold: true,
      },
    },

    /* Setting Excel detail cell background color */
    {
      id: 'detailHeader',
      interior: {
        pattern: 'Solid',
        color: '#DDE2EC',
      },
    },
  ];
  return { excelStyles };
};

export default useGetExcelStyles;
