import {
  AP_FIELDS,
  AR_FIELDS,
  DELIVERY_FIELDS,
  DOC_FIELDS,
  PICK_UP_FIELDS,
  PULL_OUT_FIELDS,
  RETURN_FIELDS,
} from '../../../constants/Main/Common/customColumnChildrenFields';

const getSelectedItems = ({ items, category }) => {
  const selectedItems = [];

  const addToGroup = ({ group, item }) => {
    const groupField = selectedItems?.find(
      selectedItem => typeof selectedItem === 'object' && group in selectedItem,
    );

    if (!groupField) {
      selectedItems?.push({ [group]: [item] });
    } else {
      groupField[group].push(item);
    }
  };

  items?.forEach(item => {
    if (PULL_OUT_FIELDS?.includes?.(item)) {
      addToGroup({ selectedItems, group: 'po_g', item });
    } else if (DELIVERY_FIELDS?.includes?.(item)) {
      addToGroup({ selectedItems, group: 'dilv_g', item });
    } else if (PICK_UP_FIELDS?.includes?.(item)) {
      addToGroup({ selectedItems, group: 'pu_g', item });
    } else if (RETURN_FIELDS?.includes?.(item)) {
      addToGroup({ selectedItems, group: 'rtn_g', item });
    } else if (AR_FIELDS?.includes?.(item)) {
      addToGroup({ selectedItems, group: 'ar_g', item });
    } else if (AP_FIELDS?.includes?.(item)) {
      addToGroup({ selectedItems, group: 'ap_g', item });
    } else if (DOC_FIELDS?.includes?.(item)) {
      addToGroup({ selectedItems, group: 'doc_g', item });
    } else {
      selectedItems?.push(item);
    }
  });

  ['po_g', 'dilv_g', 'pu_g', 'rtn_g', 'ar_g', 'ap_g', 'doc_g']?.forEach(
    (group, index) => {
      const groupField = selectedItems?.find(
        selectedItem =>
          typeof selectedItem === 'object' && group in selectedItem,
      );
      if (groupField?.[group]?.length === 0) {
        groupField[index] = '';
      }
    },
  );

  const returnSelectedItems = selectedItems?.filter(filter => filter);
  return returnSelectedItems;
};
export default getSelectedItems;
