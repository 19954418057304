import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';
import bcDetailDefaultApi from '../../../api';

const getDualJobPullOut = async ({ ...props }) => {
  const { contNo, fromScheDate, rtnLoc, toScheDate } = props;

  try {
    const response = await bcDetailDefaultApi.get(`dual-job/pull-out`, {
      params: {
        cont_no: contNo || null,
        from_sche_date: fromScheDate,
        rtn_loc: rtnLoc,
        to_sche_date: toScheDate,
      },
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getDualJobPullOut;
