import { useEffect, useState } from 'react';
import { useQueries } from '@tanstack/react-query';
import orderKeys from './keys';
import getCompanyList from '../../services/Order/Get/getCompanyList';
import createCompanySelect from '../../utils/Common/Option/createCompanySelect';

/**
 * @description
 * Get company select option
 * @returns {object}
 * Bill to option, other company select option, query result
 */
const useGetCompanyOption = ({ pageCategory }) => {
  // Url Params
  const BILLTO_URL_PARAMS = '?category=1';
  /** Execution statement for filtering credit hold */
  // if (!['customer-report'].includes(pageCategory))
  //   BILLTO_URL_PARAMS += `&credit=false`;
  const OTHER_URL_PARAMS = '?category=12';

  // Set state for company option
  const [billTo, setBillTo] = useState([]);
  const [other, setOther] = useState([]);

  const query = useQueries({
    queries: [
      // For Bill To
      {
        queryKey: orderKeys.billTo(),
        queryFn: () => getCompanyList({ urlParams: BILLTO_URL_PARAMS }),
        staleTime: Infinity,
        refetchOnWindowFocus: false,
      },
      // For Pull Out, Delivery, Return Location
      {
        queryKey: orderKeys.otherLoc(),
        queryFn: () => getCompanyList({ urlParams: OTHER_URL_PARAMS }),
        staleTime: Infinity,
        refetchOnWindowFocus: false,
      },
    ],
  });

  // Set Bill to data
  useEffect(() => {
    if (query?.[0]?.data?.data) {
      const billToData = query?.[0]?.data?.data;
      const procBillToData = createCompanySelect({ companyData: billToData });
      setBillTo(procBillToData);
    }
  }, [query?.[0]?.data]);

  // Set Other data
  useEffect(() => {
    if (query?.[1]?.data?.data) {
      const otherData = query?.[1]?.data?.data;
      const procOtherData = createCompanySelect({ companyData: otherData });
      setOther(procOtherData);
    }
  }, [query?.[1]?.data]);

  return { billTo, other, query };
};

export default useGetCompanyOption;
