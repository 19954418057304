import React, { useEffect, useState } from 'react';

const CommonOpenModal = ({ ...props }) => {
  const [content, setContent] = useState(
    props.content ? props.content : 'MOBILE',
  );
  const [width, setWidth] = useState(props?.width ? props?.width : 'w-[99px]');

  useEffect(() => {
    setContent(props.content ? props.content : 'MOBILE');
  }, [props.content]);

  return (
    <div {...props}>
      <div
        className={`border-[1px] rounded-[5px] border-[#CCD3E3] ${width} text-[11px] font-[500] pt-[8px] pb-[9px] 
        text-[#264B9F] bg-white text-center hover:bg-[#DFE9FF] hover:border-[#264B9F]
         active:bg-[#264B9F] active:text-white cursor-pointer
         `}
      >
        {content}
      </div>
    </div>
  );
};

export default CommonOpenModal;
