import React from 'react';
import CommonTextInput from '../../../components/Inputs/CommonTextInput/CommonTextInput';

const getTextInputComponent = ({ ...props }) => {
  const commonProperty = {
    disabled: props?.disabled,
    required: props?.required,
    labelStyle: props?.labelStyle,
    onChangeHandler: ({ e, onChange, ...handlerProps }) => {
      onChange(e?.target?.value);
    },
  };

  const inputProperty = [
    {
      key: 0,
      label: 'Plain Text Label default',
      inputName: 'plainText00',
      width: null,
      ...commonProperty,
    },
    {
      key: 1,
      label: 'Plain Text Label 25%',
      inputName: 'plainText00',
      width: 'w-1/4',
      ...commonProperty,
    },
    {
      key: 2,
      label: 'Plain Text Label 50%',
      inputName: 'plainText00',
      width: 'w-2/4',
      ...commonProperty,
    },
    {
      key: 3,
      label: 'Plain Text Label 75%',
      inputName: 'plainText00',
      width: 'w-3/4',
      ...commonProperty,
    },
    {
      key: 4,
      label: 'Plain Text Label 100%',
      inputName: 'plainText00',
      width: 'w-full',
      ...commonProperty,
    },
    {
      key: 5,
      label: 'Plain Text Label Custom',
      inputName: 'plainText00',
      width: props.customWidth,
      ...commonProperty,
    },
  ];

  return inputProperty?.map(element => (
    <CommonTextInput
      key={element?.key}
      {...element}
      labelStyle={props?.labelStyle && 'row'}
      adornment={{ ...props?.adornment }}
    />
  ));
};
export default getTextInputComponent;
