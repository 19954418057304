import { useMutation } from '@tanstack/react-query';
import postInvoiceAP from '../../../../services/Acct/ChassisReconcile/Post/postInvoiceAP';
import useControlToast from '../../../Common/Toast/useControlToast';
import { SUCCESS_SAVE } from '../../../../constants/Common/successMessage';
import updateGridData from '../../../../utils/Common/Handler/updateGridData';

const usePostInvoiceAP = ({ gridRef, setErrorModalOptions }) => {
  const { setErrorModalOpen, setErrorMessage, setIsSuccess } =
    setErrorModalOptions;

  const { addToast } = useControlToast();

  const mutate = useMutation({
    mutationFn: postInvoiceAP,
    onSuccess: (data, variables) => {
      updateGridData({
        gridRef,
        newData: { seq: variables?.postData?.seq, editable: false },
        isUpdate: true,
      });
      addToast({ message: SUCCESS_SAVE });
    },
    onError: error => {
      setIsSuccess(false);
      setErrorMessage(error.message);
      setErrorModalOpen(true);
    },
  });
  return { ...mutate };
};

export default usePostInvoiceAP;
