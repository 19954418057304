import { useMutation } from '@tanstack/react-query';
import { SUCCESS_UPDATE } from '../../../constants/Common/successMessage';
import patchAuthList from '../../../services/Admin/Auth/patchAuthList';
import useControlToast from '../../Common/Toast/useControlToast';
import updateGridData from '../../../utils/Common/Handler/updateGridData';
import resetRow from '../../../utils/Common/Handler/resetRow';

const usePatchAuthMutation = ({
  gridRef,
  setIsMessageOpen,
  setMessage,
  setIsSuccess,
}) => {
  const { addToast } = useControlToast();

  return useMutation({
    mutationFn: patchAuthList,
    onSuccess: (data, variables) => {
      updateGridData({
        gridRef,
        newData: { id: variables?.id, ...variables?.data },
        isUpdate: true,
        key: 'id',
      });
      addToast({
        message: SUCCESS_UPDATE,
      });
    },
    onError: error => {
      resetRow({ gridRef });
      setIsSuccess(false);
      setMessage(error.message);
      setIsMessageOpen(true);
    },
  });
};

export default usePatchAuthMutation;
