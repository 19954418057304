import React from 'react';
import CommonButton from '../../../../../../CommonButton/CommonButton';
import generateAPData from '../../../../../../../utils/BCDetail/DetailInfo/Schedule/Generator/generateAPData';

const DualMoveAPModalButton = ({ ...props }) => {
  const { setOpen, saveAP, apData, apTableContent, rtnJob, apModalController } =
    props;

  const { selectedJob } = apModalController;

  return (
    <div className="flex justify-center items-center gap-[20px]">
      <CommonButton category="cancel" onClick={() => setOpen(prev => false)}>
        Cancel
      </CommonButton>
      <CommonButton
        category="save"
        preventDupClick
        onClick={({ buttonRef }) =>
          saveAP?.({
            woNo: selectedJob?.wo_no,
            seq: selectedJob?.job_seq,
            driver: selectedJob?.driver || rtnJob?.driver,
            apData: generateAPData({
              apData: apTableContent,
              productCodes: apData?.product_codes,
            }),
            dual: Boolean(selectedJob?.driver),
            dualSeq: rtnJob?.job_seq,
            buttonRef,
          })
        }
      >
        Save
      </CommonButton>
    </div>
  );
};

export default DualMoveAPModalButton;
