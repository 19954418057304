import React, { useState } from 'react';
import { useLocation } from 'react-router';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import TrackingList from '../../../components/BCDetail/Tracking/TrackingList';
import TrackingHeader from '../../../components/BCDetail/Tracking/TrackingHeader';
import useGetTrackingList from '../../../hooks/BCDetail/Tracking/useGetTrackingList';
import CommonButton from '../../../components/CommonButton/CommonButton';
import usePostEmail from '../../../hooks/BCDetail/Common/usePostEmail';
import ScheduleTrackingModal from '../../../components/BCDetail/DetailInfo/Schedule/ScheduleTable/ScheduleTrackingModal';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import useGetTrackingInfo from '../../../hooks/BCDetail/Common/useGetTrackingInfo';

const Tracking = () => {
  const location = useLocation();
  const woNo = location?.pathname?.split('/')?.pop();

  // Tracking Modal controller
  const [trackingModalOpener, setTrackingModalOpener] = useState(false);
  const [onOkClickHandler, setOnOkClickHandler] = useState(null);

  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [messageContents, setMessageContent] = useState('');

  const modalProps = {
    setMessageModalOpen,
    setMessageContent,
  };

  const {
    isLoading,
    isError,
    data: queryData,
  } = useGetTrackingList({
    woNo,
    setOnOkClickHandler,
    ...modalProps,
  });

  /** Tracking modal API */
  const { trackingInfo } = useGetTrackingInfo();

  /** Post email mutator */
  const { mutate: mutatePostEmail } = usePostEmail({ ...modalProps });

  return (
    <>
      {isLoading && <CommonLoading open={isLoading} />}
      <div className="w-[1920px] min-h-[1080px] bg-white">
        {!isLoading && !isError && (
          <>
            <div className="pr-[70px] flex justify-end pt-[50px]">
              <CommonButton
                category="tracking"
                onClick={() => {
                  setTrackingModalOpener(true);
                }}
                disabled={!trackingInfo?.tracking_id}
              >
                Tracking
              </CommonButton>
            </div>
            <div className=" pt-[20px]">
              <TrackingHeader />
              <TrackingList trackingList={queryData?.data} />
            </div>
          </>
        )}
      </div>
      {/* Disable Tracking Modal */}
      {trackingModalOpener && (
        <ScheduleTrackingModal
          open={trackingModalOpener}
          setOpen={setTrackingModalOpener}
          trackingInfo={trackingInfo}
          setMessageModalOpener={setMessageModalOpen}
          setMessageModalMessage={setMessageContent}
          mutatePostEmail={mutatePostEmail}
        />
      )}
      {messageModalOpen && (
        <CommonErrorModal
          open={messageModalOpen}
          message={messageContents}
          setIsOpen={setMessageModalOpen}
          onOkClickHandler={onOkClickHandler}
        />
      )}
    </>
  );
};

export default Tracking;
