import React from 'react';
import { QueryClient } from '@tanstack/react-query';
import CommonMessageModal from '../../../../../CommonMessageModal/CommonMessageModal';
import ScheduleDriverListModalContent from './ScheduleDriverListModalContent';
import ScheduleDriverListModalButtonContent from './ScheduleDriverListModalButtonContent';
import bcDetailKeys from '../../../../../../hooks/BCDetail/keys';

const ScheduleDriverListModal = ({ ...props }) => {
  /** Query client */
  const queryClient = new QueryClient();

  /** Modal opener setter */
  const { driverAPModalOpener, setAPModalOpener, setDriverAPModalOpener } =
    props;

  /** Data setter */
  const { setSelectedScheduleSeq, setSelectedDriverData, getAPRefetcher } =
    props;

  /** API Status */
  const { getAPIsFetching, getAPIsError } = props;

  /** Data */
  const { driverList, selectedDriverData, driverListPending } = props;

  /** Render */
  return (
    driverList && (
      <CommonMessageModal
        open={driverAPModalOpener}
        setOpen={setDriverAPModalOpener}
        onCloseClick={() => {
          queryClient?.invalidateQueries({
            queryKey: bcDetailKeys?.driverListDefault(),
          });
          setSelectedScheduleSeq(null);
          setSelectedDriverData(null);
        }}
        width="600px"
        height="533px"
        header="Driver List"
        content={
          <ScheduleDriverListModalContent
            driverList={driverList}
            isPending={driverListPending}
            setSelectedScheduleSeq={setSelectedScheduleSeq}
            setSelectedDriverData={setSelectedDriverData}
            setOpen={setDriverAPModalOpener}
            setAPModalOpen={setAPModalOpener}
            getAPDataRefetch={getAPRefetcher}
          />
        }
        buttonContent={
          <ScheduleDriverListModalButtonContent
            setOpen={setDriverAPModalOpener}
            setAPModalOpen={setAPModalOpener}
            getAPDataRefetch={getAPRefetcher}
            selectedDriverData={selectedDriverData}
            setSelectedScheduleSeq={setSelectedScheduleSeq}
            setSelectedDriverData={setSelectedDriverData}
            getAPIsFetching={getAPIsFetching}
            getAPIsError={getAPIsError}
          />
        }
      />
    )
  );
};

export default ScheduleDriverListModal;
