import React, { useState } from 'react';
import useDeleteAssignDriver from '../../../../../../hooks/BCDetail/DetailInfo/Schedule/useDeleteAssignDriver';
import useGetAPInput from '../../../../../../hooks/BCDetail/DetailInfo/Schedule/useGetAPInput';
import useGetDriverList from '../../../../../../hooks/BCDetail/DetailInfo/Schedule/useGetDriverList';
import ScheduleRow from '../../../../../../utils/BCDetail/DetailInfo/Schedule/Generator/generateScheduleRow';
import CommonErrorModal from '../../../../../CommonErrorModal/CommonErrorModal';
import CommonLoading from '../../../../../CommonLoading/CommonLoading';
import DriverDeleteConfirmModal from '../DriverDeleteConfirmModal';
import DualMoveModal from '../DualMoveModal';
import ScheduleAddModal from '../ScheduleAddModal';
import ScheduleAPModal from '../ScheduleAPModal';
import ScheduleDeleteModal from '../ScheduleDeleteModal';
import ScheduleDriverListModal from '../ScheduleDriverListModal';
import ScheduleMobileModal from '../ScheduleMobileModal';

const ScheduleTableContents = ({
  scheType,
  scheduleData,
  locationOption,
  detailInfoData,
  isEditable,
  isCompanyIDListLoading,
  ...props
}) => {
  const { basicInfoFormController, scheduleFormController, updateDetailInfo } =
    props;

  /** Get schedule data */
  const { locationSet, jobSet } = scheduleData;

  /** Modal opener of assign Driver */
  const [driverAPModalOpener, setDriverAPModalOpener] = useState(false);

  /** Custom hook to get the driver list */
  const { driverList, isPending: driverListPending } = useGetDriverList({
    driverAPModalOpener,
  });

  /** Modal opener of assign AP for Selected Driver */
  const { addModalOpener, setAddModalOpener } = props;
  const [apModalOpener, setAPModalOpener] = useState(false);
  const [deleteModalOpener, setDeleteModalOpener] = useState(false);
  const [mobileModalOpener, setMobileModalOpener] = useState(false);

  /** Message modal opener */
  const [messageModalOpener, setMessageModalOpener] = useState(false);
  const [messageModalMessage, setMessageModalMessage] = useState('');
  const [messageModalType, setMessageModalType] = useState(null);

  /** Modal opener of confirm to delete Driver */
  const [deleteDriverModalOpener, setDeleteDriverModalOpener] = useState(false);

  /** Modal opener of dual move */
  const [dualMoveModalOpener, setDualMoveModalOpener] = useState(false);
  const [dualMoveRowData, setDualMoveRowData] = useState(null);

  /** Loading modal opener */
  const [loadingOpener, setLoadingOpener] = useState(false);

  /** Custom hook to get the AP list with driver */
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [selectedScheduleSeq, setSelectedScheduleSeq] = useState(null);
  const [selectedDriverData, setSelectedDriverData] = useState(null);

  const {
    apInputData,
    refetch: getAPRefetcher,
    isFetching: getAPIsFetching,
    isError: getAPIsError,
  } = useGetAPInput({
    scheduleSeq: selectedScheduleSeq,
    driver: selectedDriverData?.driver,
    setAPModalOpener,
    setMessageModalOpener,
    setMessageModalMessage,
    setMessageModalType,
    setLoadingOpener,
  });

  const deleteDriverMutator = useDeleteAssignDriver({
    setDeleteDriverModalOpener,
    setMessageModalOpener,
    setMessageModalMessage,
    setLoadingOpener,
  });

  const handleAlertClose = () => {
    if (messageModalType !== 'AP') {
      setSelectedScheduleSeq(null);
      setSelectedDriverData(null);
    }
    setMessageModalType(null);
  };

  /** Render component */
  return (
    <div>
      <ScheduleRow
        scheType={scheType}
        jobSet={jobSet}
        locationSet={locationSet}
        locationOption={locationOption}
        setDriverAPModalOpener={setDriverAPModalOpener}
        setSelectedScheduleSeq={setSelectedScheduleSeq}
        setSelectedRowData={setSelectedRowData}
        setAddModalOpener={setAddModalOpener}
        setDeleteModalOpener={setDeleteModalOpener}
        setMobileModalOpener={setMobileModalOpener}
        setDeleteDriverModalOpener={setDeleteDriverModalOpener}
        relatedWO={detailInfoData?.related_wo}
        deleteDriverMutator={deleteDriverMutator}
        isEditable={isEditable}
        setDualMoveModalOpener={setDualMoveModalOpener}
        setDualMoveRowData={setDualMoveRowData}
        completed={!!detailInfoData?.completed}
        isCompanyIDListLoading={isCompanyIDListLoading}
        basicInfoFormController={basicInfoFormController}
        scheduleFormController={scheduleFormController}
        updateDetailInfo={updateDetailInfo}
        detailInfoData={detailInfoData}
        setMessageModalOpener={setMessageModalOpener}
        setMessageModalMessage={setMessageModalMessage}
        setMessageModalType={setMessageModalType}
      />

      {/** Modal for add schedule */}
      {addModalOpener && (
        <ScheduleAddModal
          open={addModalOpener}
          setOpen={setAddModalOpener}
          locationOption={locationOption}
          jobSet={jobSet}
          selectedRowData={selectedRowData}
          setMessageModalOpener={setMessageModalOpener}
          setMessageModalMessage={setMessageModalMessage}
          setLoadingOpener={setLoadingOpener}
          setSelectedRowData={setSelectedRowData}
          isCompanyIDListLoading={isCompanyIDListLoading}
        />
      )}

      {/* Modal for assign Driver */}
      {driverAPModalOpener && (
        <ScheduleDriverListModal
          driverAPModalOpener={driverAPModalOpener}
          setDriverAPModalOpener={setDriverAPModalOpener}
          setAPModalOpener={setAPModalOpener}
          setSelectedScheduleSeq={setSelectedScheduleSeq}
          setSelectedDriverData={setSelectedDriverData}
          getAPRefetcher={getAPRefetcher}
          driverList={driverList}
          driverListPending={driverListPending}
          selectedDriverData={selectedDriverData}
          getAPIsFetching={getAPIsFetching}
          getAPIsError={getAPIsError}
        />
      )}

      {/* Modal for AP */}
      {apModalOpener && (
        <ScheduleAPModal
          apModalOpener={apModalOpener}
          setAPModalOpener={setAPModalOpener}
          setSelectedScheduleSeq={setSelectedScheduleSeq}
          setSelectedDriverData={setSelectedDriverData}
          getAPIsFetching={getAPIsFetching}
          getAPIsError={getAPIsError}
          apInputData={apInputData}
          selectedScheduleSeq={selectedScheduleSeq}
          setMessageModalOpener={setMessageModalOpener}
          setMessageModalMessage={setMessageModalMessage}
          setLoadingOpener={setLoadingOpener}
        />
      )}

      {/* Modal for delete schedule */}
      {deleteModalOpener && (
        <ScheduleDeleteModal
          deleteModalOpener={deleteModalOpener}
          setDeleteModalOpener={setDeleteModalOpener}
          selectedScheduleSeq={selectedScheduleSeq}
          setSelectedScheduleSeq={setSelectedScheduleSeq}
          setLoadingOpener={setLoadingOpener}
          setMessageModalOpener={setMessageModalOpener}
          setMessageModalMessage={setMessageModalMessage}
        />
      )}

      {/* Modal for schedule mobile */}
      {mobileModalOpener && (
        <ScheduleMobileModal
          open={mobileModalOpener}
          setOpen={setMobileModalOpener}
          selectedRowData={selectedRowData}
          setSelectedRowData={setSelectedRowData}
          isEditable={isEditable}
          setMessageModalOpen={setMessageModalOpener}
          setMessageContent={setMessageModalMessage}
        />
      )}

      {/* Modal for confirm to delete driver */}
      {deleteDriverModalOpener && (
        <DriverDeleteConfirmModal
          open={deleteDriverModalOpener}
          setOpen={setDeleteDriverModalOpener}
          selectedRowData={selectedRowData}
          deleteDriverMutator={deleteDriverMutator}
          setLoadingOpener={setLoadingOpener}
          setMessageModalOpener={setMessageModalOpener}
          setMessageModalMessage={setMessageModalMessage}
        />
      )}

      {/* Dual Modal */}
      {dualMoveModalOpener && (
        <DualMoveModal
          open={dualMoveModalOpener}
          setOpen={setDualMoveModalOpener}
          dualMoveRowData={dualMoveRowData}
          completed={!!detailInfoData?.completed}
        />
      )}

      {/* Loading */}
      {loadingOpener && (
        <CommonLoading open={loadingOpener} setOpen={setLoadingOpener} />
      )}

      {/* Message Modal */}
      {messageModalOpener && (
        <CommonErrorModal
          open={messageModalOpener}
          setIsOpen={setMessageModalOpener}
          onCloseClickHandler={handleAlertClose}
          onOkClickHandler={handleAlertClose}
          message={messageModalMessage}
        />
      )}
    </div>
  );
};

export default ScheduleTableContents;
