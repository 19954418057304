import { SCAN_FILE_INPUT_NAME } from '../../../../constants/Acct/Common/staticNames';

export const onChangeFile = ({ e, setValue }) => {
  let getDraggedFile = null;
  if (e?.type === 'drop') {
    getDraggedFile = e?.dataTransfer?.files?.[0];
  } else {
    getDraggedFile = e?.target?.files?.[0];
  }
  setValue?.(SCAN_FILE_INPUT_NAME, getDraggedFile);
  setValue?.(`${SCAN_FILE_INPUT_NAME}_file_name`, getDraggedFile?.name);
};

export const handleDragEnter = ({ e, setIsDragEnter }) => {
  e?.preventDefault();
  e?.stopPropagation();

  setIsDragEnter(true);
};

export const handleDragLeave = ({ e, setIsDragEnter }) => {
  e?.preventDefault();
  e?.stopPropagation();

  setIsDragEnter(false);
};

export const handleDragOver = ({ e, setIsDragEnter }) => {
  e?.preventDefault();
  e?.stopPropagation();

  setIsDragEnter(true);
};

export const handleDragDrop = ({ e, setIsDragEnter, setValue }) => {
  e?.preventDefault();
  e?.stopPropagation();

  onChangeFile({ e, setValue });
  setIsDragEnter(false);
};

export const initDragEvents = ({ ref, setIsDragEnter, setValue }) => {
  if (ref?.current !== null) {
    ref.current.addEventListener('dragenter', e =>
      handleDragEnter({ e, setIsDragEnter }),
    );
    ref.current.addEventListener('dragleave', e =>
      handleDragLeave({ e, setIsDragEnter }),
    );
    ref.current.addEventListener('dragover', e =>
      handleDragOver({ e, setIsDragEnter }),
    );
    ref.current.addEventListener('drop', e =>
      handleDragDrop({ e, setIsDragEnter, setValue }),
    );
  }
};

export const resetDragEvents = ({ ref }) => {
  if (ref?.current !== null) {
    ref.current.removeEventListener('dragenter', handleDragEnter);
    ref.current.removeEventListener('dragleave', handleDragLeave);
    ref.current.removeEventListener('dragover', handleDragLeave);
    ref.current.removeEventListener('drop', handleDragDrop);
  }
};
