import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import chassisRateAPI from '../api';

/**
 * Deletes chassis rate data in the backend for a given sequence number.
 * @param {string|number} seq - The sequence number of the chassis rate data to be deleted.
 * @returns {boolean} - True if the deletion is successful, false on error.
 */
const deleteChassisRate = async ({ seq }) => {
  try {
    await chassisRateAPI.delete(`/${seq}`);
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default deleteChassisRate;
