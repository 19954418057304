import dayjs from 'dayjs';
import FloatingFilterInput from '../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterInput';
import {
  CELL_TYPE_BUTTON,
  CELL_TYPE_DATE,
  CELL_TYPE_SELECT,
  CELL_TYPE_TEXT,
} from '../../../constants/Common/DataGrid/cellType';
import dateComparator from '../../Common/Datagrid/Comparator/dateComparator';
import cellRendererGenerator from '../../Common/Datagrid/Generator/cellRendererGenerator';

const create204ColumnDefs = ({
  setIsDecline204ModalOpen,
  accept204Mutation,
  isAcceptable,
  isDeclinable,
}) => {
  const columnDefs = [
    {
      headerName: 'STATUS',
      field: 'status',
      width: 70,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_SELECT,
        }),
      valueGetter: params => {
        const value = params?.data?.status;
        if (!value) return null;
        if (value === 'ED0001') return 'PENDING';
        if (value === 'ED0002') return 'ACCEPT';
        if (value === 'ED0003') return 'DECLINE';
        return 'Non-Type';
      },
    },
    {
      headerName: 'FLAG',
      field: '204_flag',
      width: 55,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
        }),
      valueGetter: params => {
        const value = params?.data?.['204_flag'];
        if (!value) return null;
        if (value === 'N') return 'NEW';
        if (value === 'C') return 'CANCEL';
        if (value === 'U') return 'UPDATE';
        return 'Non-Type';
      },
    },
    {
      headerName: 'SENDER',
      field: 'sender',
      width: 81,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
        }),
    },
    {
      headerName: 'DATE',
      field: 'created_date',
      width: 62,
      editable: false,
      comparator: dateComparator,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_DATE,
        }),
      valueGetter: params => {
        const dateValue = params.data?.created_date;
        return dateValue ? dayjs(dateValue).format('MM/DD/YY') : null;
      },
      floatingFilterComponent: FloatingFilterInput,
      floatingFilterComponentParams: {
        inputType: 'date',
        maxLength: 8,
        placeholder: 'MM/DD/YY',
      },
    },
    {
      headerName: 'BILL TO',
      field: 'billto',
      width: 72,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
        }),
    },
    {
      headerName: 'BILL TO REF',
      field: 'billto_ref',
      width: 110,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
        }),
    },
    {
      headerName: 'WO #',
      field: 'wo_no',
      width: 100,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
        }),
    },
    {
      headerName: 'MBL #',
      field: 'mbl_no',
      width: 125,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
        }),
    },
    {
      headerName: 'CONT #',
      field: 'cont_no',
      width: 92,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
        }),
    },
    {
      headerName: 'P/O',
      field: 'po_location',
      width: 72,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
        }),
    },
    {
      headerName: 'DELV',
      field: 'dilv_location',
      width: 72,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
        }),
    },
    {
      headerName: 'RTN',
      field: 'rtn_location',
      width: 72,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
        }),
    },
  ];
  if (isAcceptable)
    columnDefs.push({
      headerName: 'ACCEPT',
      width: 80,
      editable: false,
      floatingFilter: false,
      cellRendererSelector: gridParam => {
        return gridParam?.data?.status === 'ED0001'
          ? cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_BUTTON,
              content: 'Accept',
              category: 'accept',
              customStyle: {
                borderColor: '#3A825D',
                backgroundColor: 'white',
                size: 'small',
                textAlign: 'center',
              },
              onClickHandler: e => {
                accept204Mutation({
                  id: gridParam?.data?._id,
                });
              },
            })
          : null;
      },
    });
  if (isDeclinable)
    columnDefs.push({
      headerName: 'DECLINE',
      width: 83,
      editable: false,
      floatingFilter: false,
      cellRendererSelector: gridParam => {
        return gridParam?.data?.status === 'ED0001'
          ? cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_BUTTON,
              content: 'Decline',
              category: 'cancel',
              customStyle: {
                borderColor: '#B64646',
                backgroundColor: 'white',
                size: 'small',
                textAlign: 'center',
              },
              onClickHandler: e => {
                () => setIsDecline204ModalOpen(true);
              },
            })
          : null;
      },
    });
  columnDefs.push({
    headerName: 'FILE',
    width: 65,
    editable: false,
    floatingFilter: false,
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        cellType: CELL_TYPE_BUTTON,
        customStyle: {
          size: 'small',
          textAlign: 'center',
        },
        content: 'File',
        category: 'invoice',
        onClickHandler: e => {},
      }),
  });

  return columnDefs;
};

export default create204ColumnDefs;
