import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  beforeAfterOption,
  dateOption,
  statusOption,
} from '../../../../constants/CustomerReport/advancedSearchOption';
import useGetCompanyOption from '../../../../hooks/Order/useGetCompanyOption';
import StyledAdvancedSearchContentWrapper from '../../../../styles/CustomerReport/Common/StyledAdvancedSearchContentWrapper';
import {
  judgeAfterDateRequired,
  judgeBeforeDateRequired,
} from '../../../../utils/CustomerReport/Handler/advancedDateTypeHandler';
import getDateAfterDisableCondition from '../../../../utils/CustomerReport/Handler/getDateAfterDisableCondition';
import CommonAutoComplete from '../../../Inputs/CommonAutoComplete/CommonAutoComplete';
import CommonSelect from '../../../Inputs/CommonSelect/CommonSelect';

const AdvancedSearchContent = ({ ...props }) => {
  /** Get data from props */
  const { searchParams } = props;

  const { setValue } = useFormContext();

  const firstDateWatcher = useWatch({
    name: 'advanced.date_1',
    defaultValue: searchParams?.get('date_1'),
  });
  const secondDateWatcher = useWatch({
    name: 'advanced.date_2',
    defaultValue: searchParams?.get('date_2'),
  });
  const thirdDateWatcher = useWatch({
    name: 'advanced.date_3',
    defaultValue: searchParams?.get('date_3'),
  });

  const companyOption = useGetCompanyOption({
    pageCategory: 'customer-report',
  });

  return (
    <StyledAdvancedSearchContentWrapper>
      <div className="w-full flex flex-row gap-[20px]">
        <div className="w-[153px] h-full">
          <CommonAutoComplete
            inputName="advanced.billto"
            label="Bill To"
            defaultValue={searchParams?.get('billto') || ''}
            option={companyOption?.billTo}
            onChangeHandler={({ onChange, ...handlerProps }) => {
              onChange?.(handlerProps?.selectProps?.value || '');
            }}
            isLoading={
              companyOption?.query[0]?.isLoading ||
              companyOption?.query[1]?.isLoading
            }
          />
        </div>
        <div className="w-[116px] h-full">
          <CommonSelect
            inputName="advanced.status"
            label="Status"
            option={statusOption}
            defaultValue={searchParams?.get('status') || ''}
            onChangeHandler={({ e, onChange, ...handlerProps }) => {
              onChange(e?.target?.value);
            }}
          />
        </div>
      </div>
      <div className="w-full flex flex-row gap-[19px]">
        <div className="w-[130px] h-full">
          <CommonAutoComplete
            inputName="advanced.po_location"
            label="P/O Location"
            defaultValue={searchParams?.get('po_location') || ''}
            option={companyOption?.other}
            onChangeHandler={({ onChange, ...handlerProps }) => {
              onChange?.(handlerProps?.selectProps?.value || '');
            }}
            isLoading={
              companyOption?.query[0]?.isLoading ||
              companyOption?.query[1]?.isLoading
            }
          />
        </div>
        <div className="w-[130px] h-full">
          <CommonAutoComplete
            inputName="advanced.dilv_location"
            label="DILV Location"
            defaultValue={searchParams?.get('dilv_location') || ''}
            option={companyOption?.other}
            onChangeHandler={({ onChange, ...handlerProps }) => {
              onChange?.(handlerProps?.selectProps?.value || '');
            }}
            isLoading={
              companyOption?.query[0]?.isLoading ||
              companyOption?.query[1]?.isLoading
            }
          />
        </div>
        <div className="w-[130px] h-full">
          <CommonAutoComplete
            inputName="advanced.rtn_location"
            label="RTN Location"
            defaultValue={searchParams?.get('rtn_location') || ''}
            option={companyOption?.other}
            onChangeHandler={({ onChange, ...handlerProps }) => {
              onChange?.(handlerProps?.selectProps?.value || '');
            }}
            isLoading={
              companyOption?.query[0]?.isLoading ||
              companyOption?.query[1]?.isLoading
            }
          />
        </div>
      </div>
      {/* Date Row 1 */}
      <div className="w-full flex flex-row gap-[20px]">
        <div className="w-[153px] h-full">
          <CommonSelect
            inputName="advanced.date_1"
            label="Date"
            defaultValue={searchParams?.get('date_1') || ''}
            option={[
              { label: 'Select', value: '' },
              ...dateOption
                ?.filter(
                  option =>
                    option?.value !== secondDateWatcher &&
                    option?.value !== thirdDateWatcher,
                )
                ?.filter(option => option?.value !== ''),
            ]}
            onChangeHandler={({ e, onChange, ...handlerProps }) => {
              onChange(e?.target?.value);
              setValue('advanced.before_1', '');
              setValue('advanced.after_1', '');
            }}
            required
          />
        </div>
        <div className="h-full flex flex-column justify-center items-center gap-[5px]">
          <div className="w-[116px]">
            <CommonSelect
              inputName="advanced.before_1"
              defaultValue={searchParams?.get('before_1') || ''}
              disabled={!firstDateWatcher}
              option={beforeAfterOption}
              onChangeHandler={({ e, onChange, ...handlerProps }) => {
                onChange(e?.target?.value);
              }}
              required={judgeBeforeDateRequired({ watcher: firstDateWatcher })}
            />
          </div>
          <div className="w-[13px] h-[1px] bg-[#D9D9D9]" />
          <div className="w-[116px]">
            <CommonSelect
              inputName="advanced.after_1"
              defaultValue={searchParams?.get('after_1') || ''}
              disabled={
                !firstDateWatcher ||
                getDateAfterDisableCondition({ dateCategory: firstDateWatcher })
              }
              option={beforeAfterOption}
              onChangeHandler={({ e, onChange, ...handlerProps }) => {
                onChange(e?.target?.value);
              }}
              required={judgeAfterDateRequired({ watcher: firstDateWatcher })}
            />
          </div>
        </div>
      </div>
      {/* Date Row 2 */}
      <div className="w-full flex flex-row gap-[20px]">
        <div className="w-[153px] h-full">
          <CommonSelect
            inputName="advanced.date_2"
            label="Date"
            defaultValue={searchParams?.get('date_2') || ''}
            option={[
              { label: 'Select', value: '' },
              ...dateOption
                ?.filter(
                  option =>
                    option?.value !== firstDateWatcher &&
                    option?.value !== thirdDateWatcher,
                )
                ?.filter(option => option?.value !== ''),
            ]}
            onChangeHandler={({ e, onChange, ...handlerProps }) => {
              onChange(e?.target?.value);
              setValue('advanced.before_2', '');
              setValue('advanced.after_2', '');
            }}
          />
        </div>
        <div className="h-full flex flex-column justify-center items-center gap-[5px]">
          <div className="w-[116px]">
            <CommonSelect
              inputName="advanced.before_2"
              defaultValue={searchParams?.get('before_2') || ''}
              disabled={!secondDateWatcher}
              option={beforeAfterOption}
              onChangeHandler={({ e, onChange, ...handlerProps }) => {
                onChange(e?.target?.value);
              }}
              required={judgeBeforeDateRequired({ watcher: secondDateWatcher })}
            />
          </div>
          <div className="w-[13px] h-[1px] bg-[#D9D9D9]" />
          <div className="w-[116px]">
            <CommonSelect
              inputName="advanced.after_2"
              defaultValue={searchParams?.get('after_2') || ''}
              disabled={
                !secondDateWatcher ||
                getDateAfterDisableCondition({
                  dateCategory: secondDateWatcher,
                })
              }
              option={beforeAfterOption}
              onChangeHandler={({ e, onChange, ...handlerProps }) => {
                onChange(e?.target?.value);
              }}
              required={judgeAfterDateRequired({ watcher: secondDateWatcher })}
            />
          </div>
        </div>
      </div>
      {/* Date Row 3 */}
      <div className="w-full flex flex-row gap-[20px]">
        <div className="w-[153px] h-full">
          <CommonSelect
            inputName="advanced.date_3"
            label="Date"
            defaultValue={searchParams?.get('date_3') || ''}
            option={[
              { label: 'Select', value: '' },
              ...dateOption
                ?.filter(
                  option =>
                    option?.value !== firstDateWatcher &&
                    option?.value !== secondDateWatcher,
                )
                ?.filter(option => option?.value !== ''),
            ]}
            onChangeHandler={({ e, onChange, ...handlerProps }) => {
              onChange(e?.target?.value);
              setValue('advanced.before_3', '');
              setValue('advanced.after_3', '');
            }}
          />
        </div>
        <div className="h-full flex flex-column justify-center items-center gap-[5px]">
          <div className="w-[116px]">
            <CommonSelect
              inputName="advanced.before_3"
              defaultValue={searchParams?.get('before_3') || ''}
              disabled={!thirdDateWatcher}
              option={beforeAfterOption}
              onChangeHandler={({ e, onChange, ...handlerProps }) => {
                onChange(e?.target?.value);
              }}
              required={judgeBeforeDateRequired({ watcher: thirdDateWatcher })}
            />
          </div>
          <div className="w-[13px] h-[1px] bg-[#D9D9D9]" />
          <div className="w-[116px]">
            <CommonSelect
              inputName="advanced.after_3"
              defaultValue={searchParams?.get('after_3') || ''}
              disabled={
                !thirdDateWatcher ||
                getDateAfterDisableCondition({ dateCategory: thirdDateWatcher })
              }
              option={beforeAfterOption}
              onChangeHandler={({ e, onChange, ...handlerProps }) => {
                onChange(e?.target?.value);
              }}
              required={judgeAfterDateRequired({ watcher: thirdDateWatcher })}
            />
          </div>
        </div>
      </div>
    </StyledAdvancedSearchContentWrapper>
  );
};

export default AdvancedSearchContent;
