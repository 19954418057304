/** Error codes that should be handled specially */
export const ERROR_SPECIFIC_CODES = [
  'permission_denied',
  'not_found',
  'inactive_user',
  'dependent',
  'same_location',
  'billto_credit_hold',
  'job_started',
  'geofence_center_missing',
  'geofence_not_found',
  'button_not_active',
  'main_permission_missing',
  'permission_missing_for_first_page',
  'specific_location_required',
  'ap_location_assigned',
  'converted_wo_exists',
  'empty_schedule_datetime',
  'value_exists',
  'wo_not_found',
  'complete_wo_delete_error',
  'existing_ap_ar_delete_error',
  'edi_204_update_error',
  'group_has_user',
  'permission_first_page_mismatch',
  'not_supported_file_size',
  'qb_auth_code',
  'qb_stale_object',
  'qb_refresh_fail',
  'qb_not_found',
  'qb_invalid',
  'qb_unknown_error',
  'qb_unauthenticated',
  'qb_already_exist',
  'qb_invoice_not_found',
  'qb_work_order_not_found',
  'wo_deleted',
  'job_not_completed',
  'dual_job_exists',
  'division_has_wo',
  'bill_no_creation_failed',
  'cannot_be_past',
  'navigation_failed',
  'qb_locked',
  'address_invalid_error',
  'invalid_location',
  'qb_non_zero_balance',
  'dual_job_reassign',
  'converted_export_wo_delete',
  'return_location_not_found',
  'return_schedule_update_not_allowed',
  'driver_assigned',
  'qb_timeout',
  'route_not_searchable',
];

/** Error fields that should be handled specially */
export const ERROR_SPECIFIC_FIELDS = [];
