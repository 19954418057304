import React from 'react';
import CommonButton from '../../CommonButton/CommonButton';

const Add322Button = ({ setIsAdd322ModalOpen }) => {
  return (
    <CommonButton
      category="add"
      onClick={() => {
        setIsAdd322ModalOpen(true);
      }}
    >
      Add
    </CommonButton>
  );
};

export default Add322Button;
