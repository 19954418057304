import React, { useRef } from 'react';
import { FormProvider } from 'react-hook-form';
import CommonButton from '../../../CommonButton/CommonButton';
import TopBar from './TopBar';
import DocumentHeader from './DocumentHeader';
import DocumentAddRow from './DocumentAddRow';
import DocumentRow from './DocumentRow';
import handleAllSave from '../../../../utils/BCDetail/Document/Handler/handleAllSave';
import { PRESET_DOC } from '../../../../constants/BCDetail/Document/presetDocElement';

const DocumentTable = ({ documentController, ...props }) => {
  const { documentList, setDelType, isEditable, basicInfoData } = props;

  const buttonRef = useRef(null);

  return (
    <FormProvider {...documentController}>
      <div className="flex justify-between h-[25px] mb-[32px] w-full">
        <TopBar basicInfoData={basicInfoData} />
        {isEditable && (
          <form
            onSubmit={documentController?.handleSubmit(submitData =>
              handleAllSave({ submitData, buttonRef, ...props }),
            )}
          >
            <CommonButton ref={buttonRef} type="submit" category="save">
              Save
            </CommonButton>
          </form>
        )}
      </div>
      <DocumentHeader />
      <DocumentAddRow id={0} {...props} />
      {documentList &&
        documentList.map((row, index) => (
          <DocumentRow
            key={row.type}
            row={row}
            id={index + 1}
            preset={PRESET_DOC.includes(row.type)}
            setDelType={setDelType}
            {...props}
          />
        ))}
    </FormProvider>
  );
};

export default DocumentTable;
