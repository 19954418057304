import { DATE_CODE } from '../../../../constants/Admin/EarlyWarning/standardCodes';
import {
  WARNING_DATE_CODE,
  WARNING_ISSUE_CODE,
} from '../../../../constants/TmsReport/EarlyWarning/standardCode';

const generateIssueDescription = ({
  days,
  frequency,
  warningFrom,
  waringTo,
  warningIssue,
  level,
}) => {
  let issueDescription = '';
  const isOver = level > 4;
  const isFromCurrentDate = warningFrom === WARNING_DATE_CODE?.currentDateTime;
  const frequencyMessage = frequency === 'HOURLY' ? 'hours' : 'days';

  if (isFromCurrentDate) {
    issueDescription = isOver
      ? `More than ${days} ${frequencyMessage} has passed since ${DATE_CODE?.[waringTo]} but ${WARNING_ISSUE_CODE?.[warningIssue]}`
      : `Less than ${days} ${frequencyMessage} left until ${DATE_CODE?.[waringTo]} but ${WARNING_ISSUE_CODE?.[warningIssue]}`;
  } else {
    issueDescription = `More than ${days} ${frequencyMessage} has passed since ${DATE_CODE?.[warningFrom]} but ${WARNING_ISSUE_CODE?.[warningIssue]}`;
  }

  return issueDescription;
};

export default generateIssueDescription;
