import { useMutation, useQueryClient } from '@tanstack/react-query';
import patchDailySchedule from '../../../services/TmsReport/Schedules/Patch/patchDailySchedule';
import dailyReportKeys from '../keys';
import useControlToast from '../../Common/Toast/useControlToast';
import { SUCCESS_SAVE } from '../../../constants/Common/successMessage';

const usePatchDailySchedule = ({
  setErrorMessage,
  setIsErrorModalOpen,
  gridRef,
  focusedCell,
  restoreFocusedCell,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();
  const mutator = useMutation({
    mutationFn: patchDailySchedule,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      queryClient?.invalidateQueries({ queryKey: dailyReportKeys.all });
      addToast({ message: SUCCESS_SAVE });
      if (focusedCell?.rowIndex !== null && focusedCell?.column !== null) {
        restoreFocusedCell();
      }
    },
    onError: error => {
      gridRef?.current?.api?.undoCellEditing();
      gridRef?.current?.api?.startEditingCell({
        rowIndex: focusedCell?.rowIndex,
        colKey: focusedCell?.column,
      });
      setErrorMessage(error.message);
      setIsErrorModalOpen(true);
    },
  });

  return mutator;
};

export default usePatchDailySchedule;
