import { useEffect, useState } from 'react';
import {
  generateCompanyAutoCompleteOption,
  generateDriverAutoCompleteOption,
} from '../../../../utils/Acct/Common/Generator/autoCompleteOptionGenerator';

const useControlKeywordOptionList = ({ queryObject, optionList, watch }) => {
  const categoryValue = watch('category');
  const driverType = watch('driver-type', 'ALL');

  const { customerList, vendorList, driverList } = optionList;

  const [keywordOption, setKeywordOption] = useState([]);

  const [driverDataList, setDriverDataList] = useState([]);
  const [customerOptionList, setCustomerOptionList] = useState([]);
  const [vendorOptionList, setVendorOptionList] = useState([]);

  useEffect(() => {
    if (customerList !== undefined && customerList !== null) {
      const newOptionList = generateCompanyAutoCompleteOption(customerList);
      setCustomerOptionList(newOptionList);
    }
  }, [customerList]);

  useEffect(() => {
    if (vendorList !== undefined && vendorList !== null) {
      const newOptionList = generateCompanyAutoCompleteOption(vendorList);
      setVendorOptionList(newOptionList);
    }
  }, [vendorList]);

  useEffect(() => {
    if (driverList !== undefined && driverList !== null) {
      setDriverDataList(driverList);
    }
  }, [driverList]);

  useEffect(() => {
    if (categoryValue === 'customer') {
      setKeywordOption(customerOptionList);
    }

    if (categoryValue === 'vendor') {
      setKeywordOption(vendorOptionList);
    }

    if (categoryValue === 'driver') {
      const filterDriverList =
        driverType === 'ALL'
          ? driverDataList
          : driverDataList?.filter(driver => driver?.type === driverType);
      const newOptionList = generateDriverAutoCompleteOption(filterDriverList);
      setKeywordOption(newOptionList);
    }
  }, [categoryValue, customerOptionList, vendorOptionList, driverDataList]);

  useEffect(() => {
    const filterDriverList =
      driverType === 'ALL'
        ? driverDataList
        : driverDataList?.filter(driver => driver?.type === driverType);
    const newOptionList = generateDriverAutoCompleteOption(filterDriverList);

    if (categoryValue === 'driver') {
      setKeywordOption(newOptionList);
    }
  }, [driverType]);

  return {
    keywordOption,
    setKeywordOption,
  };
};

export default useControlKeywordOptionList;
