import { useQuery } from '@tanstack/react-query';
import masterKeys from '../keys';
import getTierWeightList from '../../../services/Master/TierWeight/Get/getTierWeightList';

const useGetTierWeightList = ({ div }) => {
  const {
    isLoading: loadingTierWeightList,
    data: tierWeightList,
    isRefetching,
    isError: errorTierWeightList,
  } = useQuery({
    queryKey: masterKeys?.tierWeightList({ div }),
    queryFn: () => getTierWeightList({ div }),
    refetchOnWindowFocus: false,
  });
  return {
    loadingTierWeightList,
    tierWeightList,
    isRefetching,
    errorTierWeightList,
  };
};

export default useGetTierWeightList;
