import { combineReducers, configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import {
  CommonReducer,
  OrderReducer,
  colValueSlice,
  AdminAuthReducer,
  MainReducer,
  BCDetailCommonReducer,
  BCDetailBasicInfoReducer,
  BCDetailBillingInfoReducer,
  BCDetailDocumentReducer,
  BCDetailMemoReducer,
  BCDetailTrackingReducer,
  DriverReducer,
  TmsReportReducer,
  AdminUserReducer,
  DemurrageReducer,
  PerdiemReducer,
  EarlyWarningReducer,
  AcctReducer,
  AdminEarlyWarningReducer,
} from '../features';
import nowDivSlice from '../features/NowDiv/index';
import companySlice from '../features/company/companySlice';
import cneeSlice from '../features/cnee/cneeSlice';
import customerCompanySlice from '../features/company/customerCompanySlice';
import cneeNglCompanySlice from '../features/company/cneeNglCompanySlice';

const reducer = combineReducers({
  common: CommonReducer,
  order: OrderReducer,
  colValue: colValueSlice,
  driver: DriverReducer,
  company: companySlice,
  cnee: cneeSlice,
  adminAuth: AdminAuthReducer,
  main: MainReducer,
  customerCompany: customerCompanySlice,
  cneeNglCompany: cneeNglCompanySlice,
  bcDetailCommon: BCDetailCommonReducer,
  bcDetailBasicInfo: BCDetailBasicInfoReducer,
  bcDetailBillingInfo: BCDetailBillingInfoReducer,
  bcDetailDocument: BCDetailDocumentReducer,
  bcDetailMemo: BCDetailMemoReducer,
  bcDetailTracking: BCDetailTrackingReducer,
  tmsReport: TmsReportReducer,
  adminUser: AdminUserReducer,
  demurrage: DemurrageReducer,
  perdiem: PerdiemReducer,
  nowDiv: nowDivSlice,
  earlyWarning: EarlyWarningReducer,
  acct: AcctReducer,
  adminEarlyWarning: AdminEarlyWarningReducer,
});

const store = configureStore({
  reducer,
  // middleware: getDefaultMiddleware => getDefaultMiddleware(),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(logger),
});

export default store;
