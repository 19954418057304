import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import divBasicDefaultApi from '../../divApi';

const patchBGColorActivate = async ({ div, type }) => {
  try {
    const response = await divBasicDefaultApi.patch(
      `${div}/bg-color/activate`,
      {
        type,
      },
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchBGColorActivate;
