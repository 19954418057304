import React from 'react';
import { REGEX_NUMBER_REGEX } from '../../../../../constants/Common/regex';
import { COMMON_STATE_SELECT_OPTION } from '../../../../../constants/Common/selectOption';
import defaultInputRules from '../../../../../utils/Common/Input/defaultInputRules';
import { handleInputChange } from '../../../../../utils/Master/Common/Handler/changeInputHandler';
import CommonSelect from '../../../../Inputs/CommonSelect/CommonSelect';
import CommonTextInput from '../../../../Inputs/CommonTextInput/CommonTextInput';

const DriverTruck = ({ isEditable, data, InputController, ...props }) => {
  return (
    <div>
      <div className="flex justify-between">
        <div className="w-[213px]">
          <CommonTextInput
            maxLength={20}
            label="TRUCK MAKER"
            defaultValue={data?.truck_maker}
            inputName="truck_maker"
            onChangeHandler={handleInputChange}
            disabled={!isEditable}
          />
        </div>
        <div className="w-[213px]">
          <CommonTextInput
            maxLength={20}
            label="TRUCK MODEL"
            defaultValue={data?.truck_model}
            inputName="truck_model"
            onChangeHandler={handleInputChange}
            disabled={!isEditable}
          />
        </div>
        <div className="w-[214px]">
          <CommonTextInput
            maxLength={4}
            minLength={4}
            label="YEAR"
            defaultValue={data?.truck_year}
            inputName="truck_year"
            onChangeHandler={handleInputChange}
            regex={REGEX_NUMBER_REGEX}
            placeholder="YYYY"
            disabled={!isEditable}
          />
        </div>
      </div>
      <div className="flex justify-between">
        <div className="w-[213px]">
          <CommonTextInput
            maxLength={10}
            label="PLATE #"
            defaultValue={data?.plate_no}
            inputName="plate_no"
            onChangeHandler={handleInputChange}
            disabled={!isEditable}
          />
        </div>
        <div className="w-[213px]">
          <CommonSelect
            inputName="truck_state"
            defaultValue={data?.truck_state || ''}
            label="STATE"
            onChangeHandler={handleInputChange}
            option={COMMON_STATE_SELECT_OPTION}
            disabled={!isEditable}
          />
        </div>
        <div className="w-[214px]">
          <CommonTextInput
            maxLength={20}
            label="VIN #"
            defaultValue={data?.vin_no}
            inputName="vin_no"
            onChangeHandler={handleInputChange}
            disabled={!isEditable}
          />
        </div>
      </div>
      {props.renderDivideLine()}
    </div>
  );
};

export default DriverTruck;
