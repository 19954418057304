import React from 'react';
import CommonButton from '../../../../../../CommonButton/CommonButton';
import checkPermission from '../../../../../../../utils/Common/Router/Handler/checkPermission';
import { PERM_BC_DETAIL_EMAIL_VIEW_EDIT } from '../../../../../../../constants/Common/permission';

const ScheduleTrackingModalButtonContent = ({ ...props }) => {
  const woNo =
    document.URL?.split('/')?.[document?.URL?.split('/')?.length - 1];

  const isEnable = checkPermission({
    permission: [PERM_BC_DETAIL_EMAIL_VIEW_EDIT],
  });

  /** Get props data */
  const { trackingInfo, mutatePostEmail } = props;

  return (
    isEnable && (
      <div className="flex justify-center items-center">
        <CommonButton
          category="blueTracking"
          disabled={!trackingInfo?.tracking_id}
          preventDupClick
          onClick={({ buttonRef }) =>
            mutatePostEmail({ woNo, name: 'tracking', buttonRef })
          }
        >
          Send Tracking ID
        </CommonButton>
      </div>
    )
  );
};

export default ScheduleTrackingModalButtonContent;
