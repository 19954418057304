import React, { useState } from 'react';
import { CATEGORY_204 } from '../../../../constants/EDI/options';
import useGet204List from '../../../../hooks/EDI/Get/useGet204List';
import useGet204PDFMutation from '../../../../hooks/EDI/Get/useGet204PDFMutation';
import create204ColumnDefs from '../../../../utils/EDI/Generator/create204ColumnDef';
import openBCDetail from '../../../../utils/EDI/Handler/openBCDetail';
import CommonDataGrid from '../../../CommonDataGrid/index';
import CommonLoading from '../../../CommonLoading/CommonLoading';

const DataGrid204 = ({
  setSelectedData,
  setIsDetail204ModalOpen,
  setIsDecline204ModalOpen,
  accept204Mutation,
  currDiv,
  searchFrom204,
  searchTo204,
  setIsSuccessAlert,
  setAlertMessage,
  setIsAlertOpen,
  isAcceptable,
  isDeclinable,
  selectedCategory,
}) => {
  const [columnDefs, setColumnDefs] = useState(
    create204ColumnDefs({
      setIsDecline204ModalOpen,
      accept204Mutation,
      isAcceptable,
      isDeclinable,
    }),
  );

  const { isLoading204List, list204Data, isRefetching204List } = useGet204List({
    div: currDiv,
    from: searchFrom204,
    to: searchTo204,
    sender: CATEGORY_204[selectedCategory],
  });

  const { mutate: get204PDFMutation } = useGet204PDFMutation({
    setIsSuccessAlert,
    setAlertMessage,
    setIsAlertOpen,
  });

  const handleDetail204ModalOpen = e => {
    if (e?.gridParam?.colDef?.headerName === 'DECLINE') {
      if (e?.gridParam?.data?.status === 'ED0001') {
        setSelectedData(e?.gridParam?.data?._id);
        setIsDecline204ModalOpen(true);
      }
      return;
    }
    if (e?.gridParam?.colDef?.headerName === 'ACCEPT') {
      if (e?.gridParam?.data?.status === 'ED0001') {
        setSelectedData(e?.gridParam?.data?._id);
      }
      return;
    }
    if (e?.gridParam?.colDef?.headerName === 'FILE') {
      get204PDFMutation({ id: e?.gridParam?.data?._id });
      return;
    }
    if (e?.gridParam?.colDef?.headerName === 'WO #') {
      if (e?.gridParam?.data?.wo_no) openBCDetail(e);
      return;
    }

    setSelectedData(e?.gridParam?.data?._id);
    setIsDetail204ModalOpen(true);
  };

  return (
    <>
      <CommonLoading open={isLoading204List || isRefetching204List} />
      <div className="w-full h-[calc(100vh-260px)] pb-8 mt-[30px]">
        <CommonDataGrid
          data={list204Data?.data || []}
          columnDefs={columnDefs}
          onCellClicked={e => handleDetail204ModalOpen(e)}
        />
      </div>
    </>
  );
};

export default DataGrid204;
