import React, { useState, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import CustomColumnItemMenuIcon from '../CustomColumnItemMenuIcon';
import iconColorGenerator from '../../../../utils/Common/CustomColumnModal/Generator/iconColorGenerator';
import itemTypes from '../../../../constants/Common/CustomColumnModal/itemTypes';

const SelectedItem = ({
  id,
  item,
  findBox,
  moveBox,
  iconColor,
  groupID,
  groupList,
  ...props
}) => {
  const originIdx = findBox(id, groupList)?.index;

  const [selected, setSelected] = useState(false);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: itemTypes.ORDERBOX,
      item: {
        id,
        originIdx,
        isGroup: true,
        groupID,
      },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id, originIdx, moveBox],
  );
  useEffect(() => {
    setSelected(isDragging);
  }, [isDragging]);

  const [, drop] = useDrop(
    () => ({
      accept: itemTypes.ORDERBOX,
      hover({ id: draggedID, isGroup, groupID: draggedGroupID }) {
        if (draggedID !== id && isGroup && groupID === draggedGroupID) {
          const { index: overIndex } = findBox(id, groupList);
          moveBox(draggedID, overIndex, groupList, isGroup, draggedGroupID);
        }
      },
    }),
    [findBox, moveBox],
  );

  return (
    <div
      ref={node => drag(drop(node))}
      className={`h-full flex flex-row gap-x-[5px] items-center ${
        item?.checked ? 'block' : 'hidden'
      }`}
    >
      <div>
        <CustomColumnItemMenuIcon
          size="small"
          iconColor={iconColorGenerator({
            selected,
            iconColor,
          })}
        />
      </div>
      <span className="text-[12px] font-[600]">{item.label}</span>
    </div>
  );
};

export default SelectedItem;
