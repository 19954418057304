import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import StyledEditCellRenderer from '../../../../../styles/Common/CommonDatagrid/EditCellRenderer/StyledEditCellRenderer';
import StyledDatetime from '../../../../../styles/Common/CommonDatagrid/GridCellRenderer/Controller/StyledDatetime';

const GridDatetimeCell = forwardRef((props, ref) => {
  const {
    setValue,
    onValueChange,
    onChangeHandler,
    isCancelBeforeStartHandler,
  } = props;

  /** Input inner component reference */
  const compRef = useRef(null);
  const compInputRef = useRef(null);

  /** Input component property */
  const [value, setDataValue] = useState(
    props?.value && dayjs(props?.value)?.isValid() ? dayjs(props?.value) : null,
  );
  const [disabled, setDisabled] = useState(props?.disabled || false);

  /** Component data setter */
  const compDataSetter = {
    setDataValue,
    setDisabled,
  };

  /**
   * Component Editor Lifecycle methods
   * @see https://www.ag-grid.com/react-data-grid/component-cell-editor/
   */
  useImperativeHandle(ref, () => {
    return {
      /** The final value to send to the grid, on completion of editing */
      getValue() {
        return value;
      },
      isCancelBeforeStart() {
        isCancelBeforeStartHandler?.({ ...props, ...compDataSetter });
        return false;
      },
      isCancelAfterEnd() {
        return false;
      },
      ref,
      compRef,
      compInputRef,
      ...compDataSetter,
      ...props,
    };
  });

  return (
    <StyledEditCellRenderer>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StyledDatetime
          ref={compRef}
          inputRef={compInputRef}
          value={value && dayjs(value)?.isValid() ? dayjs(value) : null}
          format="MM/DD/YY HH:mm"
          ampm={false}
          onChange={e => {
            const getChangedValue = e;
            if (getChangedValue && getChangedValue?.isValid()) {
              setValue?.(getChangedValue);
              setDataValue?.(getChangedValue);
              onValueChange?.(getChangedValue);
              onChangeHandler?.({ getChangedValue, ...props });
              return;
            }
            setValue?.(null);
            setDataValue?.(null);
            onValueChange?.(null);
            onChangeHandler?.({ getChangedValue, ...props });
          }}
          disabled={disabled}
          disableOpenPicker
        />
      </LocalizationProvider>
    </StyledEditCellRenderer>
  );
});

export default GridDatetimeCell;
