import { useMutation, useQueryClient } from '@tanstack/react-query';
import dailyReportKeys from '../keys';
import patchReturnYard from '../../../services/TmsReport/Schedules/Patch/patchReturnYard';
import useControlToast from '../../Common/Toast/useControlToast';
import { SUCCESS_SAVE } from '../../../constants/Common/successMessage';

const usePatchReturnYard = ({
  setErrorMessage,
  setIsErrorModalOpen,
  gridRef,
  focusedCell,
  restoreFocusedCell,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();
  const mutator = useMutation({
    mutationFn: patchReturnYard,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      queryClient?.invalidateQueries({
        queryKey: dailyReportKeys.returnYardAll,
      });
      addToast({ message: SUCCESS_SAVE });
      if (focusedCell?.rowIndex !== null && focusedCell?.column !== null) {
        restoreFocusedCell();
      }
    },
    onError: error => {
      gridRef?.current?.api?.undoCellEditing();
      gridRef?.current?.api?.startEditingCell({
        rowIndex: focusedCell?.rowIndex,
        colKey: focusedCell?.column,
      });
      setErrorMessage(error.message);
      setIsErrorModalOpen(true);
    },
  });

  return mutator;
};

export default usePatchReturnYard;
