import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import processData from '../../../../utils/Master/Common/processData';
import apRateAPI from '../api';

/**
 * Updates the ap rate data in the backend.
 * @param {Object} data - The data to be updated.
 */
const updateAPRate = async ({ data }) => {
  processData(data);
  try {
    const response = await apRateAPI.put(`/${data.seq}`, {
      div: data.div,
      category: data.category,
      from_location: data.from_location.split('/')[0],
      to_location: data.to_location.split('/')[0],
      rate: data.rate,
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default updateAPRate;
