import generateScheDateField from './generateScheDateField';

const getSubFilterCondition = ({ subFilter, status, node }) => {
  if (node.data) {
    switch (subFilter) {
      case 'no-lfd':
        return !node.data.lfd;
      case 'lfd':
        return !!node.data.lfd;
      case 'no-booking':
        return !node.data.bk_no;
      case 'booking':
        return !!node.data.bk_no;
      case 'no-schedule':
        return !node.data?.[generateScheDateField(status)];
      case 'schedule':
        return !!node.data?.[generateScheDateField(status)];
      default:
        return true;
    }
  }
  return true;
};

export default getSubFilterCondition;
