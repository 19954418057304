import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import getWOChassisToList from '../../../../services/Acct/ChassisReconcile/Get/getWOChassisToList';
import acctKeys from '../../keys';
import queryStringGenerator from '../../../../utils/Acct/Common/Generator/queryStringGenerator';
import {
  woOptionGenerator,
  woDateObjectListGenerator,
} from '../../../../utils/Acct/ChassisReconcile/Generator/woDateItemGenerator';

const useGetWOChassisToOptions = ({ queryObject, isFocused }) => {
  const [woToOptionList, setWOToOptionList] = useState([]);
  const [woToDateObjectList, setWOToDateObjectList] = useState([]);

  const queryString = queryStringGenerator({ queryObject });

  const { data: woData, isLoading: isLoadingWOTo } = useQuery({
    queryKey: acctKeys.chassisWOToList({ queryString }),
    queryFn: () => getWOChassisToList({ queryString }),
    staleTime: 0,
    enabled:
      !!queryString &&
      !!queryObject?.from_date &&
      !!queryObject?.to_date &&
      isFocused,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!queryObject?.from_date || !queryObject?.to_date) {
      setWOToOptionList([]);
      setWOToDateObjectList([]);
    }
  }, [queryObject?.from_date, queryObject?.to_date]);

  useEffect(() => {
    if (woData) {
      const optionList = woOptionGenerator({ woData });
      const objectList = woDateObjectListGenerator({ woData });

      setWOToOptionList(optionList);
      setWOToDateObjectList(objectList);
    }
  }, [woData]);

  return { woToOptionList, woToDateObjectList, isLoadingWOTo };
};

export default useGetWOChassisToOptions;
