import styled from 'styled-components';

const StyledTMSReportDetailModalNGLCUSInput = styled.input`
  color: #222;
  font-size: 12px;
  font-weight: 700;
  margin-left: 28px;
  width: 63px;
  height: 30px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  text-align: center;
`;

export default StyledTMSReportDetailModalNGLCUSInput;
