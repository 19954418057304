import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import memoDefaultAPi from '../api';

const editJobMemoList = async ({ seq, content }) => {
  try {
    const response = await memoDefaultAPi.patch(`job/${seq}`, {
      content,
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};
export default editJobMemoList;
