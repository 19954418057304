import React, { useEffect, useState } from 'react';
import { columnTypes } from '../../../../../constants/Dispatch/Common/dataGridOptions';
import StyledDispatchDataGrid from '../../../../../styles/Dispatch/Common/StyledDispatchDataGrid';
import getModeType from '../../../../../utils/Dispatch/Common/Generator/getModType';
import setResultColDef from '../../../../../utils/Dispatch/Common/Generator/setResultColDef';
import CommonDataGrid from '../../../../CommonDataGrid';
import useControlTotalInfo from '../../../../../hooks/Common/TotalBottomRow/useControlTotalInfo';
import TotalBottomBar from '../../../../Common/CommonTotalBottomBar';

const ExportUnavailable = ({
  gridRef,
  rowData,
  isEditable,
  status,
  isExternalFilterPresent,
  doesExternalFilterPass,
  clickCell,
  clickSave,
  handleRowEditingStart,
  handleRowEditingStop,
  editParam,
  subFilter,
  ...props
}) => {
  const [columnDefs, setColumnDefs] = useState(
    setResultColDef({
      isEditable,
      status,
      rowData,
      editParam,
      subFilter,
      ...props,
    }),
  );

  useEffect(() => {
    setColumnDefs(
      setResultColDef({
        isEditable,
        status,
        rowData,
        editParam,
        subFilter,
        ...props,
      }),
    );
  }, [rowData, editParam?.pool, editParam?.booking, status, clickSave]);

  const { totalRows, setChangeFlag } = useControlTotalInfo({
    gridRef,
    queryData: _?.isEmpty(columnDefs) ? [] : rowData?.result,
  });

  return (
    <StyledDispatchDataGrid>
      {!!columnDefs.length && (
        <>
          <CommonDataGrid
            gridRef={gridRef}
            isExternalFilterPresent={isExternalFilterPresent}
            doesExternalFilterPass={doesExternalFilterPass}
            data={rowData?.result}
            modType={getModeType({ columnDefs, isEditable })}
            columnDefs={columnDefs}
            columnTypes={columnTypes}
            onCellClicked={clickCell}
            onRowValueChanged={clickSave}
            undoRedoCellEditing
            undoRedoCellEditingLimit={1}
            onRowEditingStart={handleRowEditingStart}
            onRowEditingStop={handleRowEditingStop}
            onFilterChanged={() => {
              setChangeFlag(prev => !prev);
            }}
            onSelectionChanged={() => {
              setChangeFlag(prev => !prev);
            }}
          />
          <TotalBottomBar totalRows={totalRows} />
        </>
      )}
    </StyledDispatchDataGrid>
  );
};

export default ExportUnavailable;
