import BASIC_COL_DEF from '../../../../constants/Dispatch/Common/basicColDef';
import BUTTON_COL_DEF from '../../../../constants/Dispatch/Common/buttonColDef';
import { BUTTON_COLS } from '../../../../constants/Dispatch/Common/buttonColList';
import { DISPATCH_PAGE_EXPORT_BOOKING } from '../../../../constants/Dispatch/Common/category';
import { DISPATCH_CATEGORY_COLUMNS } from '../../../../constants/Dispatch/Common/columnList';
import { DISPATCH_COLUMN_GRID_FIELD } from '../../../../constants/Dispatch/Common/dataFieldKey';
import EDITABLE_COL_DEF from '../../../../constants/Dispatch/Common/editableColDef';
import { STATUS_EDITABLE_COLS } from '../../../../constants/Dispatch/Common/editableColList';
import FIELD_HEADER from '../../../../constants/Dispatch/Common/fieldsHeader';
import FIELD_WIDTH from '../../../../constants/Dispatch/Common/fieldsWidth';
import { DISPATCH_CATEGORY_THEME } from '../../../../constants/Dispatch/Common/themeValues';
import convertStatus from './convertStatus';
import getCellStyle from './getCellStyle';
import getDispatchGroupHeader from './getDispatchGroupHeader';
import getSortingDefs from './getSortingDefs';

const setResultColDef = ({
  status,
  rowData,
  editParam,
  isEditable,
  subFilter,
  ...props
}) => {
  /** Convert WO Category */
  const category = convertStatus({ status });

  /** Get Column/Color Definition per Dispatch Page */
  const columnDef = DISPATCH_CATEGORY_COLUMNS?.[category]?.[status];
  const colorDef = DISPATCH_CATEGORY_THEME?.[category];

  /** Get Custom Column List */
  const selectedItems = rowData?.dsp_column || [];

  /** Get Division Color */
  const divColorSet = rowData?.bg_color || {};

  /** Get Column Type (Editable, Button) */
  const buttonCols = BUTTON_COLS?.[status];
  const editableCols = isEditable ? STATUS_EDITABLE_COLS?.[status] : [];

  /** Mapping Field Name and Header Name */
  let fieldKey = { ...FIELD_HEADER };

  if (status === DISPATCH_PAGE_EXPORT_BOOKING) {
    fieldKey = { ...fieldKey, dilv_location: 'DELV', rtn_location: 'RETURN' };
  }

  if (status !== DISPATCH_PAGE_EXPORT_BOOKING && category === 'brokerage') {
    fieldKey = { ...fieldKey, trailer_no: 'EQUIPMENT' };
  }

  /** Mapping Group Field Name and Group Header Name */
  const groupKey = getDispatchGroupHeader(category);

  /** Set Column Sorting based on the Filter Conditions of the Count Button */
  const sortingDefs = getSortingDefs({ status, subFilter });

  /** Function to set the definition property for each field  */
  const setOneCol = field => {
    const basicDef = BASIC_COL_DEF?.[field];
    const editableDef = EDITABLE_COL_DEF?.[field];
    const buttonDef = BUTTON_COL_DEF?.[field];

    const basicSettings =
      basicDef instanceof Function
        ? basicDef?.(editParam?.[field])
        : basicDef || {};

    const editSettings =
      editableDef instanceof Function
        ? editableDef?.({ selectOption: editParam?.[field], divColorSet })
        : editableDef || {};

    const buttonSettings = buttonDef
      ? buttonDef?.({ onClickHandler: editParam?.[field], ...props })
      : {};

    const sortSettings = sortingDefs?.[field] || {};

    const settingDef = {
      field: DISPATCH_COLUMN_GRID_FIELD?.[field] || field,
      headerName: fieldKey[field],
      /** Field Width */
      ...(FIELD_WIDTH?.[field] && { width: FIELD_WIDTH[field] }),
      /** Sorting Setting */
      ...{ ...sortSettings },
      /** Column Definition for Each Column Type */
      ...(editableCols?.includes(field)
        ? { ...editSettings }
        : { ...basicSettings, editable: false }),
      ...(buttonCols?.includes(field) && { ...buttonSettings }),
      /** Cell Style */
      cellStyle: params => ({
        ...editSettings?.cellStyle,
        ...basicSettings?.cellStyle,
        ...getCellStyle({ ...params, divColorSet }),
      }),
    };

    return settingDef;
  };

  /** Set column items based on page permissions */
  const editSettingItemList = selectedItems?.filter(
    field => isEditable || (field !== 'export_btn' && field !== 'complete'),
  );

  const resultDefs = editSettingItemList?.map(field => {
    const isGroupedColumn = !!(field instanceof Object);

    /** No Group Column Logic */
    if (!isGroupedColumn) {
      return { ...setOneCol(field) };
    }

    /** Group Column Logic */
    const [headerField, childrenList] = Object.entries(field)[0];

    const groupHeader = groupKey?.[headerField];
    const groupDef = columnDef?.find(item => item?.header === groupHeader);

    let childCounter = 0;

    const newColumn = {
      headerName: groupDef?.header || headerField,
      headerClass: groupDef?.headerClass,
      isColumnGroup: true,
      openByDefault: true,
      ...colorDef?.[groupDef?.theme],
      children: childrenList.map(child => {
        const newChild = {
          ...setOneCol(child),
          ...(childCounter > 0 && { columnGroupShow: 'open' }),
        };
        childCounter += 1;
        return newChild;
      }),
    };
    return newColumn;
  });

  return resultDefs;
};

export default setResultColDef;
