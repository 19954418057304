import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import {
  PATH_NEXT_ARROW_ICON,
  PATH_PREVIOUS_ARROW_ICON,
} from '../../../../constants/Common/path';
import generateNavContent from '../../../../utils/TmsReport/Common/Generator/generateNavContent';

const PeriodNavigator = ({ cycle, dateQuery, setDateQuery }) => {
  /** Navigator Labels */
  const [navLabels, setNavLabels] = useState({
    prev: 'Prev Week',
    next: 'Next Week',
  });

  /** Navigator Date */
  const [navDates, setNavDates] = useState({
    prev: dayjs?.(dateQuery?.date)?.subtract(1, 'week')?.format('YYYY-MM-DD'),
    next: dayjs?.(dateQuery?.date)?.add(1, 'week')?.format('YYYY-MM-DD'),
  });

  /** Set Up Navigator Content */
  useEffect(() => {
    setNavLabels({
      prev: generateNavContent({ cycle, date: dateQuery?.date })?.prevLabel,
      next: generateNavContent({ cycle, date: dateQuery?.date })?.nextLabel,
    });

    setNavDates({
      prev: generateNavContent({ cycle, date: dateQuery?.date })?.prevDate,
      next: generateNavContent({ cycle, date: dateQuery?.date })?.nextDate,
    });
  }, [cycle, dateQuery?.date]);

  const handleNavClick = navDate => {
    setDateQuery(prev => ({
      ...prev,
      date: dayjs(navDate)?.format('YYYY-MM-DD'),
    }));
  };

  return (
    <div className="flex flex-row justify-between pb-[5px]">
      {navDates?.prev && (
        <button
          type="button"
          className="flex items-center cursor-pointer text-[13px] font-[500] text-[#222222]"
          onClick={() => handleNavClick(navDates?.prev)}
          disabled={!navDates?.prev}
        >
          <img src={PATH_PREVIOUS_ARROW_ICON} alt="Previous Arrow" />
          <div className="pt-[2px]">{navLabels?.prev}</div>
        </button>
      )}
      {navDates?.next && (
        <button
          type="button"
          className="flex items-center cursor-pointer text-[13px] font-[500] text-[#222222]"
          onClick={() => handleNavClick(navDates?.next)}
          disabled={!navDates?.next}
        >
          <div className="pt-[2px]">{navLabels?.next}</div>
          <img src={PATH_NEXT_ARROW_ICON} alt="Next Arrow" />
        </button>
      )}
    </div>
  );
};

export default PeriodNavigator;
