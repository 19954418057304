import cellEditorGenerator from '../../../Common/Datagrid/Generator/cellEditorGenerator';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';
import {
  CELL_TYPE_SELECT,
  CELL_TYPE_TEXT,
} from '../../../../constants/Common/DataGrid/cellType';
import {
  SELECT_ITEM_AE,
  SELECT_ITEM_ALL,
  SELECT_ITEM_VIEW,
} from '../../../../constants/Admin/Auth/adminAuthElements';
import theme from '../../../../constants/Admin/Auth/theme';

const createAuthColumnDefs = () => {
  const authColumnDefs = [
    {
      headerName: 'NAME',
      pinned: 'left',
      field: 'name',
      width: 120,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          maxLength: 150,
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          maxLength: 150,
        }),
    },
    {
      headerName: 'ORDER',
      isColumnGroup: true,
      backgroundColor: theme?.bgColor,
      color: theme?.textColor,
      openByDefault: true,
      children: [
        {
          headerName: 'IMP',
          field: 'order_imp',
          width: 55,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'EXP',
          field: 'order_exp',
          width: 55,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'VAN',
          field: 'order_van',
          width: 55,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'BRKG',
          field: 'order_brok',
          width: 65,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'BARE',
          field: 'order_bare',
          width: 65,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
      ],
    },
    {
      headerName: 'MAIN',
      isColumnGroup: true,
      backgroundColor: theme?.bgColor,
      color: theme?.textColor,
      openByDefault: true,
      children: [
        {
          headerName: 'IMP',
          field: 'main_imp',
          width: 55,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'EXP',
          field: 'main_exp',
          width: 55,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'VAN',
          field: 'main_van',
          width: 55,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'BRKG',
          field: 'main_brok',
          width: 65,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'BARE',
          field: 'main_bare',
          width: 65,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
      ],
    },
    {
      headerName: 'DISPATCH',
      isColumnGroup: true,
      backgroundColor: theme?.bgColor,
      color: theme?.textColor,
      openByDefault: true,
      children: [
        {
          headerName: 'IMP',
          field: 'dispatch_imp',
          width: 55,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'EXP-BK#',
          field: 'dispatch_bk',
          width: 80,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'EXP',
          field: 'dispatch_exp',
          width: 55,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'VAN',
          field: 'dispatch_van',
          width: 55,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'BRKG',
          field: 'dispatch_brok',
          width: 65,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
        },
      ],
    },
    {
      headerName: 'BC DETAIL',
      isColumnGroup: true,
      backgroundColor: theme?.bgColor,
      color: theme?.textColor,
      openByDefault: true,
      children: [
        {
          headerName: 'IMP',
          field: 'bcdetail_imp',
          width: 55,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'EXP',
          field: 'bcdetail_exp',
          width: 55,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'VAN',
          field: 'bcdetail_van',
          width: 55,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'BRKG',
          field: 'bcdetail_brok',
          width: 65,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'BARE',
          field: 'bcdetail_bare',
          width: 65,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'P/O CHASSIS',
          field: 'bcdetail_po_ch',
          width: 108,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'EMAIL BUTTON',
          field: 'bcdetail_email',
          width: 120,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
      ],
    },
    {
      headerName: 'WO',
      isColumnGroup: true,
      backgroundColor: theme?.bgColor,
      color: theme?.textColor,
      openByDefault: true,
      children: [
        {
          headerName: 'IMP, EXP BTN',
          field: 'wo_convert',
          width: 109,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'COMPLETE',
          field: 'wo_complete',
          width: 98,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'DELETE',
          field: 'wo_delete',
          width: 75,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
      ],
    },
    {
      headerName: 'BC DETAIL-B',
      isColumnGroup: true,
      backgroundColor: theme?.bgColor,
      color: theme?.textColor,
      openByDefault: true,
      children: [
        {
          headerName: 'AP',
          field: 'bcdetail_ap',
          width: 45,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'AR',
          field: 'bcdetail_ar',
          width: 45,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'DELETE',
          field: 'bcdetail_ap_ar_delete',
          width: 75,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
      ],
    },
    {
      headerName: 'QUICKBOOK',
      isColumnGroup: true,
      backgroundColor: theme?.bgColor,
      color: theme?.textColor,
      openByDefault: true,
      children: [
        {
          headerName: 'SEND',
          field: 'qb_send',
          width: 65,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'UPDATE',
          field: 'qb_update',
          width: 75,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'LOCKED UPDATE',
          field: 'qb_locked',
          width: 130,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'DELETE',
          field: 'qb_delete',
          width: 75,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
      ],
    },
    {
      headerName: 'TMS REPORT',
      isColumnGroup: true,
      backgroundColor: theme?.bgColor,
      color: theme?.textColor,
      openByDefault: true,
      children: [
        {
          headerName: 'P/O',
          field: 'tms_po',
          width: 50,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'DELV',
          field: 'tms_dilv',
          width: 65,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'RTN',
          field: 'tms_rtn',
          width: 55,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'DEMURRAGE',
          field: 'tms_dem',
          width: 105,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'PERDIEM',
          field: 'tms_per',
          width: 85,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'STATISTICS',
          field: 'tms_stat',
          width: 100,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_VIEW,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_VIEW,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'EW LIST',
          field: 'tms_ew',
          width: 77,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
        },
      ],
    },
    {
      headerName: 'CUSTOMER REPORT',
      isColumnGroup: true,
      backgroundColor: theme?.bgColor,
      color: theme?.textColor,
      openByDefault: true,
      children: [
        {
          headerName: 'REPORT MAIN',
          field: 'cus_main',
          width: 114,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'SETTING',
          field: 'cus_setting',
          width: 84,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
      ],
    },
    {
      headerName: 'TRACE',
      isColumnGroup: true,
      backgroundColor: theme?.bgColor,
      color: theme?.textColor,
      openByDefault: true,
      children: [
        {
          headerName: 'ASSET',
          field: 'trace_map',
          width: 70,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_VIEW,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_VIEW,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'JOB',
          field: 'trace_job',
          width: 55,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_VIEW,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_VIEW,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'GEOFENCE',
          field: 'trace_geofence',
          width: 93,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
        },
      ],
    },
    {
      headerName: 'EDI',
      isColumnGroup: true,
      backgroundColor: theme?.bgColor,
      color: theme?.textColor,
      openByDefault: true,
      children: [
        {
          headerName: 'EDI',
          field: 'edi_page',
          width: 50,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'ACCEPT',
          field: 'edi_accept',
          width: 78,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'DECLINE',
          field: 'edi_decline',
          width: 82,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
      ],
    },
    {
      headerName: 'MOBILE ADMIN',
      isColumnGroup: true,
      backgroundColor: theme?.bgColor,
      color: theme?.textColor,
      openByDefault: true,
      children: [
        {
          headerName: 'POD CK LIST',
          field: 'mobile_pod',
          width: 103,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'BILL CFM',
          field: 'mobile_bill',
          width: 85,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'MANIFEST',
          field: 'mobile_manifest',
          width: 92,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_VIEW,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_VIEW,
            }),
        },
      ],
    },
    {
      headerName: 'ACCT',
      isColumnGroup: true,
      backgroundColor: theme?.bgColor,
      color: theme?.textColor,
      openByDefault: true,
      children: [
        {
          headerName: 'UNINVOICED',
          field: 'acct_uninv',
          width: 105,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_VIEW,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_VIEW,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'QB AP/AR',
          field: 'acct_qb_ap_ar',
          width: 88,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'QB RECON.',
          field: 'acct_qb_recon',
          width: 95,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'DRIVER PAY',
          field: 'acct_drv_pay',
          width: 100,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'CHAS RECON.',
          field: 'acct_ch_recon',
          width: 110,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'PER RECON.',
          field: 'acct_per_recon',
          width: 99,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
      ],
    },
    {
      headerName: 'MASTER',
      isColumnGroup: true,
      backgroundColor: theme?.bgColor,
      color: theme?.textColor,
      openByDefault: true,
      children: [
        {
          headerName: 'DRIVER',
          field: 'master_drv',
          width: 73,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'COMPANY',
          field: 'master_comp',
          width: 90,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'COMP-CNEE',
          field: 'master_cnee',
          width: 103,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'ACCT CODE',
          field: 'master_acct_code',
          width: 98,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_VIEW,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_VIEW,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'PROD. CODE',
          field: 'master_prod_code',
          width: 100,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'AP RATE',
          field: 'master_ap_rate',
          width: 81,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'AR RATE',
          field: 'master_ar_rate',
          width: 81,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'CHAS RATE',
          field: 'master_ch_rate',
          width: 98,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'TIER WEIGHT',
          field: 'master_tier_weight',
          width: 108,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
      ],
    },
    {
      headerName: 'ADMIN',
      isColumnGroup: true,
      backgroundColor: theme?.bgColor,
      color: theme?.textColor,
      openByDefault: true,
      children: [
        {
          headerName: 'USER',
          field: 'admin_user',
          width: 65,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'AUTH',
          field: 'admin_auth',
          width: 65,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'CONF',
          field: 'admin_conf',
          width: 65,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'SETTINGS',
          field: 'admin_div_conf',
          width: 88,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'EW',
          field: 'admin_div_ew',
          width: 48,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
      ],
    },
  ];

  const valueGetterColumnDefs = [...authColumnDefs]?.map(authColumnDef => {
    const newAuthColumnDefs = { ...authColumnDef };
    const childColumnDefs = newAuthColumnDefs?.children;

    if (childColumnDefs) {
      const tempChildrenColumnDefs = [...childColumnDefs]?.map(
        childColumnDef => {
          return {
            ...childColumnDef,
            valueGetter: gridParam => {
              const isPinnedRow = gridParam?.node?.rowPinned;
              if (isPinnedRow) return '';

              const lastWord = gridParam?.data?.permissions?.[
                childColumnDef?.field
              ]
                ?.split('_')
                ?.pop();

              if (lastWord === 'rw') return 'Edit';
              if (lastWord === 'r') return 'View';
              return 'None';
            },
          };
        },
      );
      newAuthColumnDefs.children = tempChildrenColumnDefs;
    }

    return newAuthColumnDefs;
  });

  return valueGetterColumnDefs;
};

export default createAuthColumnDefs;
