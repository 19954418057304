import combineNumber from '../../Common/Generator/combineNumber';
import { concatPhoneNumber } from '../../../Common/Handler/phoneNumberHandler';

const handleCompanyData = data => {
  const deleteUndefinedProperties = obj => {
    Object.keys(obj).forEach(key => {
      if (
        obj[key] === undefined ||
        obj[key] === null ||
        obj[key] === '' ||
        obj[key] === 'undefinedundefinedundefined'
      ) {
        delete obj[key];
      }
    });
  };

  const phoneNumbers = [
    'cell_no',
    'office_no',
    'fax',
    'acct_cell_no',
    'acct_office_no',
    'acct_fax',
  ];

  phoneNumbers.forEach(field => {
    data[field] = concatPhoneNumber({
      country: data?.[`${field}_country`],
      phone: data?.[field],
    });

    delete data?.[`${field}_country`];
  });

  if (data?.ssn_1 || data?.ssn_2 || data?.ssn_3) {
    data.ssn = combineNumber(['ssn_1', 'ssn_2', 'ssn_3'], data);
  }

  if (data?.created_by) {
    delete data?.created_by;
  }

  if (data?.updated_by) {
    delete data?.updated_by;
  }

  data.geofence = {
    place_id: data.place_id,
    latitude: data.lat,
    longitude: data.lng,
  };

  deleteUndefinedProperties(data);
};

export default handleCompanyData;
