import React from 'react';
import CommonMessageModal from '../../../CommonMessageModal/CommonMessageModal';
import EmailConfirmModalContent from './EmailConfirmModalContent';
import EmailConfirmModalButtonContent from './EmailConfirmModalButtonContent';

const EmailConfirmModal = ({ ...props }) => {
  // Email is string. Not an array.
  const { open, setOpen, onSaveClick, email } = props;

  return (
    open && (
      <CommonMessageModal
        open={open}
        setOpen={setOpen}
        header="Email Confirm"
        content={
          <EmailConfirmModalContent
            email={email
              ?.split(',')
              ?.map(item => item?.trim())
              ?.filter(item => item !== '')}
          />
        }
        buttonContent={
          <EmailConfirmModalButtonContent
            setOpen={setOpen}
            onSaveClick={onSaveClick}
          />
        }
      />
    )
  );
};

export default EmailConfirmModal;
