import tmsReportAPI from '../../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

const getEarlyWarningList = async ({ queryObject }) => {
  try {
    const response = await tmsReportAPI.get(`/early-warning`, {
      params: queryObject,
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};
export default getEarlyWarningList;
