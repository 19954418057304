import commonDefaultApi from '../../Common/api';

const getCompanyList = async ({ urlParams }) => {
  try {
    const response = await commonDefaultApi.get(`company-list${urlParams}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export default getCompanyList;
