import { Box } from '@mui/system';
import React from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import MainLayOut from '../../components/Layout/MainLayOut';
import SubMenuNavigator from '../../components/Common/SubMenuNavigator';
import { PAGE_ACCT } from '../../constants/Common/mapper';

const Acct = () => {
  const location = useLocation();
  const path = location.pathname;
  const pathParts = path.split('/');

  return (
    <MainLayOut>
      <SubMenuNavigator page={PAGE_ACCT} />
      <div className="w-full pl-[97px] h-full">
        <Outlet />
      </div>
    </MainLayOut>
  );
};

export default Acct;
