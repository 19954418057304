import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import React from 'react';
import { PERM_TRACE_GEOFENCE_EDIT } from '../../../constants/Common/permission';
import checkPermission from '../../../utils/Common/Router/Handler/checkPermission';
import Geofence from './GeofenceModule/Geofence';
import GeofenceSaveBtn from './GeofenceModule/GeofenceSaveBtn';

// Libraries for polygon-related operations
const LIBRARIES = ['geometry'];

/**
 * Use Google Map API to display Google Maps on web
 *
 * @param mapSettings
 * Settings of the google maps.
 * @param markerList
 * List to store markers.
 * @param setMarkerList
 * Set state of the markerList.
 * @returns
 */
const GoogleMapComponentGeofence = ({ mapSettings, geofenceList, company }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
    libraries: LIBRARIES,
  });

  return isLoaded ? (
    <div>
      <div className="h-10 bg-white flex justify-end">
        {checkPermission({ permission: PERM_TRACE_GEOFENCE_EDIT }) && (
          <GeofenceSaveBtn geofenceList={geofenceList} company={company} />
        )}
      </div>
      <div data-testid="map">
        <GoogleMap
          mapContainerStyle={mapSettings.size}
          center={mapSettings.center}
          zoom={mapSettings.zoom}
        >
          {geofenceList?.map(geofence => (
            <Geofence key={geofence.key} geofence={geofence} />
          ))}
        </GoogleMap>
      </div>
    </div>
  ) : (
    <div>Loading...!</div>
  );
};

export default GoogleMapComponentGeofence;
