import generatePerDiemInputField from './generatePerDiemInputField';

const generateInvoiceList = ({ data }) => {
  const { Items, ...commonData } = data;

  const invoiceList = Items?.map((scanObject, index) => {
    const inputField = generatePerDiemInputField({
      scanObject: { ...scanObject, ...commonData },
    });
    return { key: index, data: inputField };
  });

  const invoiceKey = invoiceList?.length;
  return { invoiceList, invoiceKey };
};
export default generateInvoiceList;
