import React from 'react';
import CommonSelect from '../../../components/Inputs/CommonSelect/CommonSelect';
import CommonTextSelect from '../../../components/Inputs/CommonTextSelect/CommonTextSelect';

const getTextSelectComponent = ({ ...props }) => {
  const SELECT_OPTION = [
    {
      key: 0,
      label: 'My Name Is Super Handsome James',
      value: 'My Name Is Super Handsome James',
    },
    {
      key: 1,
      label: 'My Name Is Hwi',
      value: 'My Name Is Hwi',
    },
    {
      key: 2,
      label: 'My Name Is David',
      value: 'My Name Is David',
    },
    {
      key: 3,
      label: 'My Name Is Amy',
      value: 'My Name Is Amy',
    },
    {
      key: 4,
      label: 'This is Different Label and Value. Click Me',
      value: 'James Is Super Nice Guy',
    },
  ];

  const commonProperty = {
    disabled: props?.disabledArr,
    required: props?.requiredArr,
    labelStyle: props?.labelStyle,
    option: SELECT_OPTION,
    onChangeHandler: [
      ({ e, onChange, ...handlerProps }) => {
        onChange(e?.target?.value);
      },
      ({ e, onChange, ...handlerProps }) => {
        onChange(e?.target?.value);
      },
    ],
  };

  const inputProperty = [
    {
      key: 0,
      label: 'Text Select default',
      inputName: ['textInputSelect00', 'textSelect01'],
      width: null,
      ...commonProperty,
    },
    {
      key: 1,
      label: 'Text Select 25%',
      inputName: ['textInputSelect00', 'textSelect01'],
      width: 'w-1/4',
      ...commonProperty,
    },
    {
      key: 2,
      label: 'Text Select 50%',
      inputName: ['textInputSelect00', 'textSelect01'],
      width: 'w-2/4',
      ...commonProperty,
    },
    {
      key: 3,
      label: 'Text Select 75%',
      inputName: ['textInputSelect00', 'textSelect01'],
      width: 'w-3/4',
      ...commonProperty,
    },
    {
      key: 4,
      label: 'Text Select 100%',
      inputName: ['textInputSelect00', 'textSelect01'],
      width: 'w-full',
      ...commonProperty,
    },
    {
      key: 5,
      label: 'Text Select Custom',
      inputName: ['textInputSelect00', 'textSelect01'],
      width: props?.customWidth,
      ...commonProperty,
    },
  ];

  return inputProperty?.map(element => (
    <CommonTextSelect
      key={element?.key}
      {...element}
      labelStyle={props?.labelStyle && 'row'}
    />
  ));
};
export default getTextSelectComponent;
