import React, { useState } from 'react';
import { PATH_SEPARATE_LINE } from '../../../constants/Common/path';
import {
  ARROW_DOWN_BLUE,
  ARROW_DOWN_GREY,
  ARROW_UP_BLUE,
  ARROW_UP_GREY,
} from '../../../constants/EDI/imagePath';
import SearchBar from './SearchBar';

const Sidebar = ({
  selectedCategory,
  setSelectedCategory,
  selectedEDI,
  setSelectedEDI,
}) => {
  const [target, setTarget] = useState('');
  const [expandedItems, setExpandedItems] = useState({});

  const categories = {
    EXPEDITORS: ['204', 'MAIN'],
    MSC: ['204', 'MAIN'],
    'CMA/CGM': ['204', 'MAIN'],
    HYUNDAI: ['322'],
    EVERGREEN: ['322'],
  };

  const toggleExpand = item => {
    setExpandedItems(prevState => ({
      ...prevState,
      [item]: !prevState[item],
    }));
  };

  const getArrowSrc = category => {
    const isExpanded = expandedItems[category];
    const isSelectedCategoryWithSub =
      selectedCategory === category &&
      categories[category].includes(selectedEDI);
    if (isSelectedCategoryWithSub) {
      return isExpanded ? ARROW_UP_BLUE : ARROW_DOWN_BLUE;
    }
    return isExpanded ? ARROW_UP_GREY : ARROW_DOWN_GREY;
  };

  const filteredCategories = Object.keys(categories).reduce((acc, category) => {
    if (category.toLowerCase().includes(target.toLowerCase())) {
      acc[category] = categories[category];
    } else {
      const filteredSubCategories = categories[category].filter(subCategory =>
        subCategory.toLowerCase().includes(target.toLowerCase()),
      );
      if (filteredSubCategories.length > 0) {
        acc[category] = filteredSubCategories;
      }
    }
    return acc;
  }, {});

  return (
    <div className="w-[197px] bg-[#FFF] mr-[2px] mt-[30px] pt-[24px] flex flex-col items-center overflow-y-scroll">
      <SearchBar setTarget={setTarget} />
      <div className="w-[197px] mt-[40px]">
        {Object.keys(filteredCategories).map(category => (
          <div key={category}>
            <div
              className="mt-[7px] pl-[34px] pr-[29px]"
              onClick={() => toggleExpand(category)}
            >
              <div className="flex items-center justify-between cursor-pointer">
                <div
                  className={`${
                    selectedCategory === category
                      ? 'text-[#264B9F] font-extrabold'
                      : 'text-[#64696E] font-medium '
                  }`}
                >
                  {category}
                </div>
                <div>
                  <img src={getArrowSrc(category)} alt="arrow" />
                </div>
              </div>
            </div>
            <img
              src={PATH_SEPARATE_LINE}
              alt="separateLine"
              className="mt-[20px] ml-[8px]"
            />
            {expandedItems[category] &&
              categories[category].map(subCategory => (
                <div
                  key={subCategory}
                  className="mt-[8px] pl-[34px] pr-[29px] cursor-pointer"
                  onClick={() => {
                    setSelectedCategory(category);
                    setSelectedEDI(subCategory);
                  }}
                >
                  <div
                    className={` ${
                      selectedCategory === category &&
                      selectedEDI === subCategory
                        ? 'text-[#264B9F] font-extrabold'
                        : 'text-[#64696E] font-medium'
                    }`}
                  >
                    {subCategory}
                  </div>
                </div>
              ))}
            {expandedItems[category] && <div className="h-[27px]" />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
