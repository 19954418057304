import styled from 'styled-components';

const StyledGroupDragBoxContainer = styled.div`
  width: 208px;
  height: ${props => (props?.expanded ? 'fit-content' : '30px')};
  border-radius: 5px;
  margin-top: 5px;
  padding: ${props =>
    props?.expanded ? '8px 15px 8px 20px' : '5px 15px 5px 20px'};

  position: relative;

  background-color: ${props => props?.bgColor};
  display: ${props => (props?.checked ? 'block' : 'none')};
  border: ${props =>
    props?.selected ? '1.2px solid #264B9F' : '1px solid #D9D9D9'};
  box-shadow: ${props => (props?.selected ? '0 0 4px 0 #8BBCE9' : 'none')};

  .header {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .header-label {
    font-size: 12px;
    font-weight: 600;
    color: ${props => props?.fontColor};
  }

  .child-box {
    display: ${props => (props?.expanded ? 'block' : 'none')};
    padding: 11px 0px 2px 15px;
    display: flex;
    flex-direction: column;
    font-size: 11px;
    font-weight: 500;

    row-gap: 10px;
    color: ${props => props?.fontColor};
  }
`;

export default StyledGroupDragBoxContainer;
