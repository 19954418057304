import { useMutation } from '@tanstack/react-query';
import { SUCCESS_SAVE } from '../../../constants/Common/successMessage';
import patchServiceCode from '../../../services/Master/ServiceCode/Patch/patchServiceCode';
import resetRow from '../../../utils/Common/Handler/resetRow';
import updateGridData from '../../../utils/Common/Handler/updateGridData';
import useControlToast from '../../Common/Toast/useControlToast';

const usePatchServiceCode = ({
  division,
  setMessageContent,
  setMessageModalOpen,
  gridRef,
}) => {
  const { addToast } = useControlToast();

  const mutator = useMutation({
    mutationFn: patchServiceCode,
    refetchOnWindowFocus: false,

    onSuccess: res => {
      updateGridData({
        gridRef,
        newData: res?.data,
        isUpdate: true,
      });
      addToast({ message: SUCCESS_SAVE });
    },
    onError: error => {
      resetRow({ gridRef });
      setMessageContent(error.message);
      setMessageModalOpen(true);
    },
  });
  return mutator;
};

export default usePatchServiceCode;
