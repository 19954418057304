import React from 'react';
import CommonMessageModal from '../../CommonMessageModal/CommonMessageModal';
import CommonButton from '../../CommonButton/CommonButton';

const DeleteConfirmModal = ({
  isOpenDeleteConfirmModal,
  setIsOpenDeleteConfirmModal,
  deleteMutator,
}) => {
  return (
    <CommonMessageModal
      header="Confirmation"
      open={isOpenDeleteConfirmModal}
      setOpen={setIsOpenDeleteConfirmModal}
      width="400px"
      height="203px"
      content={
        <div className="w-full flex flex-col">
          <div className="w-full min-w-[300px] min-h-[61px] h-fit bg-[#F9F9F9] flex justify-center items-center flex-col">
            <div className="flex flex-col justify-center items-center px-[20px] leading-[14.06px] text-center">
              <p className="text-[12px] font-[500]">
                Are you sure you want to delete the data?
              </p>
            </div>
          </div>
          <div className="flex justify-center center-item mt-[22px] gap-x-[16px]">
            <CommonButton
              category="cancel"
              onClick={() => setIsOpenDeleteConfirmModal(false)}
            >
              Cancel
            </CommonButton>
            <CommonButton
              category="delete"
              preventDupClick
              onClick={({ buttonRef }) => {
                deleteMutator?.({ buttonRef });
                setIsOpenDeleteConfirmModal(false);
              }}
            >
              Delete
            </CommonButton>
          </div>
        </div>
      }
    />
  );
};

export default DeleteConfirmModal;
