import styled from 'styled-components';

const LabsComponentBox = styled.div`
  width: 500px;
  height: fit-content;
  // height: 500px;
  background-color: #fff;
  border: 2px solid black;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
`;

export default LabsComponentBox;
