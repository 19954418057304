const createEmptySelect = ({ selectOption, emptyValue }) => {
  const result = [
    {
      label: 'Select',
      value: emptyValue,
    },
    ...selectOption,
  ];

  return result;
};

export default createEmptySelect;
