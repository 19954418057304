import React from 'react';
import CommonButton from '../../CommonButton/CommonButton';

const AdvancedSearchButton = ({ setIsSearchModalOpen }) => {
  return (
    <CommonButton
      category="greySearch"
      border="0"
      backgroundColor="transparent"
      color="#666666"
      fontWeight="700"
      onClick={() => {
        setIsSearchModalOpen(true);
      }}
    >
      Search
    </CommonButton>
  );
};

export default AdvancedSearchButton;
