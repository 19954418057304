import React from 'react';
import CommonFile from '../../../components/Inputs/CommonFile/CommonFile';

const getFileComponent = ({ ...props }) => {
  const commonProperty = {
    disabled: props?.disabled,
    required: props?.required,
    labelStyle: props?.labelStyle,
  };

  const inputProperty = [
    {
      key: 0,
      label: 'File Label default',
      inputName: 'fileFiles',
      width: null,
      ...commonProperty,
    },
    {
      key: 1,
      label: 'File Label 25%',
      inputName: 'fileFiles',
      width: 'w-1/4',
      ...commonProperty,
    },
    {
      key: 2,
      label: 'File Label 50%',
      inputName: 'fileFiles',
      width: 'w-2/4',
      ...commonProperty,
    },
    {
      key: 3,
      label: 'File Label 75%',
      inputName: 'fileFiles',
      width: 'w-3/4',
      ...commonProperty,
    },
    {
      key: 4,
      label: 'File Label 100%',
      inputName: 'fileFiles',
      width: 'w-full',
      ...commonProperty,
    },
    {
      key: 5,
      label: 'File Label Custom',
      inputName: 'fileFiles',
      width: props?.customWidth,
      ...commonProperty,
    },
  ];

  return inputProperty.map(element => (
    <CommonFile
      key={element?.key}
      {...element}
      labelStyle={props?.labelStyle}
    />
  ));
};

export default getFileComponent;
