import { useMutation } from '@tanstack/react-query';
import { SUCCESS_DELETE } from '../../../../constants/Common/successMessage';
import deleteAPData from '../../../../services/BCDetail/BillingInfo/AP/Delete/deleteAPData';
import isServerResponseValid from '../../../../utils/Common/isServerResponseValid';
import useControlToast from '../../../Common/Toast/useControlToast';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';

const useDeleteAPData = ({
  setIsAlertOpen,
  setIsSuccess,
  setErrorMessage,
  refetchAPData,
}) => {
  const { addToast } = useControlToast();

  const mutator = useMutation({
    mutationFn: deleteAPData,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: success => {
      if (isServerResponseValid(success)) {
        refetchAPData();
        addToast({
          message: SUCCESS_DELETE,
        });
      }
    },

    onError: error => {
      refetchAPData();
      setIsSuccess(false);
      setIsAlertOpen(true);
      setErrorMessage(error.message);
    },
    refetchOnWindowFocus: false,
  });

  return mutator;
};

export default useDeleteAPData;
