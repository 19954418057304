import { CELL_TYPE_BUTTON } from '../../../../constants/Common/DataGrid/cellType';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';

const getCompleteButtonDef = ({ onClickHandler }) => {
  return {
    floatingFilter: false,
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        cellType: CELL_TYPE_BUTTON,
        customStyle: {
          size: 'small',
        },
        content: 'Complete',
        category: 'complete',
        onClickHandler,
      }),
  };
};

export default getCompleteButtonDef;
