import startRowEdit from '../../../Common/Datagrid/Handler/onRowEditClickHandler';
import getEditableData from '../Generator/getEditableData';

const handleSaveClick = ({
  beforeEditData,
  gridParam,
  editableCols,
  bookingOption,
  setMessageModalMessage,
  setIsMessageModalOpen,
  mutate,
  dispatchStatus,
  category,
}) => {
  const editedData = getEditableData({
    beforeEditData,
    gridParam,
    editableCols,
    bookingOption,
  });

  const handleEditRollBack = ({ message }) => {
    gridParam.api.undoCellEditing();
    setMessageModalMessage(message);
    setIsMessageModalOpen(true);
    startRowEdit({ gridParam });
  };

  const isPoolValid = () => {
    const isChassisEdited = Object.keys(editedData).includes('ch_no');
    const isPoolEdited = Object.keys(editedData).includes('pool');

    const chassis = isChassisEdited ? editedData?.ch_no : beforeEditData?.ch_no;
    const pool = isPoolEdited ? editedData?.pool : beforeEditData?.pool;

    if (!chassis) return true;
    if (pool && pool !== '') {
      if (isChassisEdited || isPoolEdited) editedData.pool = pool;
      return true;
    }
    return false;
  };

  if (!isPoolValid()) {
    handleEditRollBack({ message: 'Pool should be updated.' });
  } else if (!_.isEmpty(editedData)) {
    mutate({
      category,
      pk: gridParam?.data?.wo_no,
      pageStatus: dispatchStatus,
      data: editedData,
    });
  }
};

export default handleSaveClick;
