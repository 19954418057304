import React from 'react';
import dayjs from 'dayjs';
import CommonButton from '../../CommonButton/CommonButton';
import exportToExcel from '../../../utils/Common/Datagrid/Handler/exportToExcel';
import SearchDate from '../../TmsReport/Schedules/SearchDate';

const Header = ({ gridRef, date, setDate, page }) => {
  const pageName = page?.replace('_', ' ')?.toUpperCase();
  const headerName = `${pageName} : ${dayjs(date).format('MM/DD/YY')}`;

  return (
    <div className="flex flex-row justify-between pr-[40px]">
      <div className="whitespace-nowrap font-[800] text-[25px] pl-[5px] py-[24px]">
        {headerName}
      </div>
      <div className="flex flex-row place-items-center">
        <div className="text-[14px] font-[500] text-[#666666]">Date</div>
        <div className="w-[117px] mr-[13px] ml-[9px]">
          <SearchDate date={date} setDate={setDate} />
        </div>
        <div>
          <CommonButton
            category="excel"
            onClick={() => {
              exportToExcel({
                gridRef,
                fileName: `${pageName} ${dayjs(date).format('MMDDYY')}`,
                suppressCurrentDate: true,
              });
            }}
          >
            EXCEL
          </CommonButton>
        </div>
      </div>
    </div>
  );
};

export default Header;
