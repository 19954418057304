import React, { useEffect, useState } from 'react';
import { MenuItem, Select } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useController, useFormContext } from 'react-hook-form';
import defaultInputRules from '../../../../../../utils/Common/Input/defaultInputRules';

const TableSelect = ({ row, basicCellStyle, ...props }) => {
  const {
    control,
    unregister,
    setValue,
    formState: { errors: formErrors },
  } = useFormContext();

  const [option, setOption] = useState(row?.option || []);
  useEffect(() => {
    if (row?.option) {
      setOption(row?.option);
    }
  }, [row?.option]);

  const [defaultValue, setDefaultValue] = useState(
    row?.defaultValue || row?.option[0]?.value,
  );
  useEffect(() => {
    if (row?.defaultValue !== undefined && row?.defaultValue !== null) {
      setDefaultValue(row?.defaultValue);
      setValue(row?.inputName, row?.defaultValue);
    }
  }, [row?.defaultValue]);

  const [disabled, setDisabled] = useState(row?.disabled || false);
  useEffect(() => {
    if (row?.disabled !== undefined && row?.disabled !== null) {
      setDisabled(row?.disabled);
    }
  }, [row?.disabled]);

  const [required, setRequired] = useState(row?.required || false);
  useEffect(() => {
    if (row?.required !== undefined && row?.required !== null) {
      setRequired(row?.required);
    }
  }, [row?.required]);

  useEffect(() => {
    return () => unregister(row?.inputName);
  }, []);

  const {
    field: { ref, onChange, value, ...field },
    fieldState: { invalid, error },
  } = useController({
    name: row?.InputName,
    control,
    defaultValue,
    rules: defaultInputRules({ required, ...props }),
  });

  const handlerProps = {
    onChange,
    value,
    ...field,
    ...row,
    ...props,
  };

  return (
    <Select
      {...field}
      inputRef={ref}
      error={invalid}
      value={value || ''}
      renderValue={selected => {
        return row?.renderValue
          ? selected
          : option?.find(item => item?.value === selected)?.label;
      }}
      onChange={(e, selectProps) => {
        row?.onChangeHandler?.({
          e,
          selectProps,
          ...handlerProps,
        });
      }}
      className={`${basicCellStyle} rounded-[0px] basis-full
      } text-[12px] hover:border-[#8E9396] hover:border-[1px] ${
        row?.disabled ? 'bg-[#F9F9F9]' : 'bg-white'
      }`}
      sx={{
        // Select Arrow Icon
        fontWeight: '500',
        boxSizing: 'border-box',
        '.MuiSvgIcon-root': {
          fontSize: '16px',
          color: !row?.disabled && '#264B9F !important',
        },
        // Cell
        '.MuiSelect-select': {
          paddingTop: '7px',
          paddingBottom: '7px',
          paddingRight: '15px',
        },
        '&.MuiInputBase-root': {
          '&.Mui-focused fieldset': {
            border: '1.2px solid #264B9F !important',
            boxShadow: '0px 0px 4px 0px #8BBCE9 !important',
            zIndex: 499,
          },
          '&.Mui-error fieldset': {
            border: '1px solid #C24D4D !important',
          },
        },
        fieldset: {
          border: 0,
        },
      }}
      defaultValue={row?.defaultValue || row?.option[0]?.value}
      disabled={disabled}
      MenuProps={{
        disableScrollLock: true,
        style: { zIndex: 9999 },
      }}
      IconComponent={ExpandMoreIcon}
    >
      {row?.option?.map((item, index) => {
        return (
          <MenuItem
            value={item?.value}
            key={String(index + 1)}
            className="text-xs"
            {...item}
          >
            {item?.label}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default TableSelect;
