import axios from 'axios';
import interceptRequest from '../../../utils/Common/Interceptor/interceptRequest';

/**
 * Tier weight base url
 */
const TIER_WEIGHT_BASE_URL = `${process.env.REACT_APP_BASE_URL}/master/tierweight`;

/**
 * Axios object with base url
 */
const tierWeightAPI = axios.create({
  withCredentials: true,
  baseURL: TIER_WEIGHT_BASE_URL,
  xsrfHeaderName: 'X-CSRFTOKEN',
  xsrfCookieName: 'XCSRF-TOKEN',
  headers: {
    common: {
      'X-CSRFToken': localStorage.getItem('csrfToken'),
    },
  },
});

/** Interceptors */
interceptRequest(tierWeightAPI);

export default tierWeightAPI;
