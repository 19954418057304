import React from 'react';
import { LIVE_SHARE_TRACKING_BAR } from '../../../../constants/LiveShare/path';
import LocationContent from './LocationContent';

const LiveShareLocation = ({ ...props }) => {
  return (
    <div className="h-[368px] w-full flex flex-row">
      <div>
        <img
          src={LIVE_SHARE_TRACKING_BAR}
          alt="Live-Share tracking bar"
          data-testid="trackingBar"
        />
      </div>
      <LocationContent {...props} />
    </div>
  );
};

export default LiveShareLocation;
