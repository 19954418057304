import dayjs from 'dayjs';
import React from 'react';
import { MAIN_FILTER_STATUS } from '../../../constants/Main/Common/advancedSearchOptions';
import getSearchCategoryItems from '../../../utils/Main/Common/getSearchCategoryItems';
import getSearchDateItems from '../../../utils/Main/Common/getSearchDateItems';
import CommonDate from '../../Inputs/CommonDate/CommonDate';
import CommonSelect from '../../Inputs/CommonSelect/CommonSelect';
import CommonTextInput from '../../Inputs/CommonTextInput/CommonTextInput';

const AdvancedSearchContent = ({ category }) => {
  return (
    <div className="flex flex-col w-full h-fit bg-[#F9F9F9] px-[30px] py-[15px] justify-center space-y-[15px]">
      <div className="flex flex-row justify-center space-x-[20px]">
        <div className="w-[153px]">
          <CommonSelect
            label="STATUS"
            inputName="status"
            option={MAIN_FILTER_STATUS}
            onChangeHandler={({ e, onChange }) => {
              onChange?.(e?.target?.value);
            }}
          />
        </div>
        <div className="w-[116px]">
          <CommonSelect
            label="CATEGORY"
            inputName="category"
            option={getSearchDateItems(category)}
            onChangeHandler={({ e, onChange }) => {
              onChange?.(e?.target?.value);
            }}
          />
        </div>
        <div className="w-[116px]">
          <CommonTextInput
            label=""
            inputName="search"
            placeholder="Type here..."
            onChangeHandler={({ e, onChange }) => {
              onChange?.(e?.target?.value.replace(' ', ''));
            }}
          />
        </div>
      </div>
      <div className="flex flex-row justify-center space-x-[20px]">
        <div className="w-[153px]">
          <CommonSelect
            label="DATE"
            inputName="date"
            option={getSearchCategoryItems(category)}
            onChangeHandler={({ e, onChange }) => {
              onChange?.(e?.target?.value);
            }}
          />
        </div>
        <div className="w-[116px]">
          <CommonDate
            label=""
            inputName="from-date"
            required
            onChangeHandler={({ e, onChange, ...handlerProps }) => {
              if (dayjs(e).isValid()) onChange?.(dayjs(e).format('YYYY-MM-DD'));
              else onChange?.(null);
            }}
          />
        </div>
        <div className="w-[116px]">
          <CommonDate
            label=""
            inputName="to-date"
            required
            onChangeHandler={({ e, onChange, ...handlerProps }) => {
              if (dayjs(e).isValid()) onChange?.(dayjs(e).format('YYYY-MM-DD'));
              else onChange?.(null);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AdvancedSearchContent;
