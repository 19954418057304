import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import CommonButton from '../../../components/CommonButton/CommonButton';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import useGetUserList from '../../../hooks/Admin/User/Get/useGetUserList';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import CommonDataGrid from '../../../components/CommonDataGrid';
import createUserColumnDefs from '../../../utils/Admin/User/Generator/createUserColumnDefs';
import usePatchUserStatus from '../../../hooks/Admin/User/Patch/usePatchUserStatus';
import exportToExcel from '../../../utils/Common/Datagrid/Handler/exportToExcel';
import SideUserModal from '../../../components/Admin/User/SideUserModal';
import handleRowClick from '../../../utils/Admin/User/Handler/handleRowClick';
import useGetAuthList from '../../../hooks/Admin/User/Get/useGetAuthList';
import usePatchUserInfo from '../../../hooks/Admin/User/Patch/usePatchUserInfo';
import useControlLoading from '../../../hooks/Admin/Common/Control/useControlLoading';
import useGetUser from '../../../hooks/Admin/User/Get/useGetUser';
import generateDefaultValue from '../../../utils/Admin/User/Generator/generateDefaultValue';
import useCheckFormValidity from '../../../hooks/Common/Input/useCheckFormValidity';

const User = () => {
  const disabledRef = useRef({ disabled: false, disabledRow: null });
  const gridRef = useRef(null);

  const [isOpenSideModal, setIsOpenSideModal] = useState(false);
  const [userID, setUserID] = useState(null);

  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const divList = useSelector(state => state?.common?.div);
  const nowDiv = useSelector(state => state?.nowDiv);

  const { authList } = useGetAuthList({
    setIsSuccess,
    setIsOpenErrorModal,
    setErrorMessage,
  });

  const { userList, isLoading, isFetching } = useGetUserList({
    nowDiv,
    setIsOpenErrorModal,
    setErrorMessage,
    setIsSuccess,
  });

  const { userInfo } = useGetUser({
    id: userID,
    setIsOpenErrorModal,
    setErrorMessage,
    setIsSuccess,
  });

  const { mutate: userStatusPatchMutator } = usePatchUserStatus({
    gridRef,
    disabledRef,
    setIsOpenErrorModal,
    setErrorMessage,
    setIsSuccess,
  });

  const { mutate: userInfoPatchMutator } = usePatchUserInfo({
    gridRef,
    nowDiv,
    setIsOpenErrorModal,
    setErrorMessage,
    setIsSuccess,
    setIsOpenSideModal,
  });

  const [columnDefs, setColumnDefs] = useState([]);

  /** Form Default Value Setting */
  const [formDefaultValue, setFormDefaultValue] = useState(
    generateDefaultValue({ userInfo, divList }),
  );

  const userController = useForm({ defaultValues: formDefaultValue });
  const { isFormInvalid } = useCheckFormValidity({
    formStates: [userController?.formState],
  });

  useEffect(() => {
    const newDefaultValue = generateDefaultValue({ userInfo, divList });
    userController.reset(newDefaultValue);
    setFormDefaultValue(newDefaultValue);
  }, [userInfo, divList]);

  const { isLoadingOpen } = useControlLoading({
    isLoadingList: [isLoading],
    isFetchingList: [isFetching],
  });

  useEffect(() => {
    if (!isOpenSideModal) {
      setUserID(null);
    }
  }, [isOpenSideModal]);

  useEffect(() => {
    if (divList?.length > 0) {
      setColumnDefs(
        createUserColumnDefs({ disabledRef, userStatusPatchMutator, divList }),
      );
    }
  }, [divList]);

  return (
    <>
      <div className="pr-[40px] flex flex-col h-full">
        <div className="w-full my-[20px] flex justify-end items-center gap-x-[15px]">
          <CommonButton
            type="button"
            category="excel"
            fontWeight="700"
            onClick={() => {
              exportToExcel({ gridRef, fileName: 'USER' });
            }}
          >
            EXCEL
          </CommonButton>
        </div>
        <div className="w-full flex-1">
          <CommonDataGrid
            gridRef={gridRef}
            columnDefs={columnDefs}
            data={userList}
            onRowClicked={props =>
              handleRowClick({
                props,
                setUserID,
                setIsOpenSideModal,
              })
            }
          />
        </div>
      </div>
      {isOpenSideModal && (
        <SideUserModal
          userID={userID}
          divList={divList}
          authList={authList}
          isOpenSideModal={isOpenSideModal}
          setIsOpenSideModal={setIsOpenSideModal}
          userInfo={formDefaultValue}
          userInfoPatchMutator={userInfoPatchMutator}
          userController={userController}
          isFormInvalid={isFormInvalid}
        />
      )}
      <CommonLoading open={isLoadingOpen} />
      {isOpenErrorModal && !isSuccess && (
        <CommonErrorModal
          open={isOpenErrorModal}
          setIsOpen={setIsOpenErrorModal}
          isSuccess={isSuccess}
          message={errorMessage}
        />
      )}
    </>
  );
};

export default User;
