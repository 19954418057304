import cellEditorGenerator from '../../../Common/Datagrid/Generator/cellEditorGenerator';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';
import {
  CELL_TYPE_SELECT,
  CELL_TYPE_TEXT,
} from '../../../../constants/Common/DataGrid/cellType';
import { STATE_OPTION } from '../../../../constants/Admin/DivConf/adminDivConfElements';

const createSettingsColumnDefs = () => {
  return [
    {
      headerName: 'ST',
      field: 'state',
      width: 47,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_SELECT,
          option: STATE_OPTION,
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_SELECT,
          option: STATE_OPTION,
        }),
    },
    {
      headerName: 'ZIP',
      field: 'zip',
      width: 57,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
        }),
    },
    {
      headerName: 'WEIGHT (%)',
      field: 'weight',
      width: 100,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          value: gridParam.data.weight || '100',
          customStyle: {
            textAlign: 'right',
          },
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          customStyle: {
            textAlign: 'right',
          },
        }),
    },
  ];
};

export default createSettingsColumnDefs;
