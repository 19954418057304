import React from 'react';
import DualMoveAPModalExistAPInfoHeader from './DualMoveAPModalExistAPInfoHeader';
import DualMoveAPModalExistAPInfoRow from './DualMoveAPModalExistAPInfoRow';

const DualMoveAPModalExistAPInfo = ({ ...props }) => {
  const { apData } = props;

  return (
    <div className="w-[511px] pt-[15px] flex flex-col">
      <DualMoveAPModalExistAPInfoHeader apData={apData} />
      {apData?.ap_exists?.length > 0 && (
        <div className="h-fit max-h-[95px] overflow-y-scroll w-[511px]">
          {apData?.ap_exists?.map((ap, index) => {
            return (
              <DualMoveAPModalExistAPInfoRow key={String(index + 1)} ap={ap} />
            );
          })}
        </div>
      )}
      {apData?.ap_exists?.length === 0 && (
        <div className="h-[30px] w-full text-[14px] flex justify-center items-center font-bold">
          No AP exists before
        </div>
      )}
    </div>
  );
};

export default DualMoveAPModalExistAPInfo;
