import styled from 'styled-components';

const StyledControllerWrapper = styled.div`
  width: 100%;
  display: flex;

  flex-direction: column;
`;

export default StyledControllerWrapper;
