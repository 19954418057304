const tmsReportKeys = {
  all: ['TMS_REPORT'],
  perdiemList: ({ division, fromDate, toDate }) => [
    ...tmsReportKeys.all,
    'PERDIEM_LIST',
    division,
    fromDate,
    toDate,
  ],
  demurrageList: ({ division, fromDate, toDate }) => [
    ...tmsReportKeys.all,
    'DEMURRAGE_LIST',
    division,
    fromDate,
    toDate,
  ],
  earlyWarningList: ({ queryObject }) => [
    ...tmsReportKeys.all,
    'EARLY_WARNING_LIST',
    queryObject,
  ],
  earlyWarningModalList: ({ queryObject }) => [
    ...tmsReportKeys.all,
    'EARLY_WARNING_MODAL_LIST',
    queryObject,
  ],
  statisticsList: ({ period }) => [
    ...tmsReportKeys.all,
    'STATISTCS_LIST',
    period,
  ],
  statisticsData: ({ period, stat, dateQuery, divQuery }) => [
    ...tmsReportKeys.statisticsList({ period }),
    stat,
    dateQuery,
    divQuery,
  ],
};

export default tmsReportKeys;
