import React from 'react';
import StyledTableContainer from '../../../../../styles/Master/ServiceCode/StyledTableContainer';
import createCategory from '../../../../../utils/Master/ServiceCode/Generator/createCategory';

const InUseCodeTable = ({ usedContents, ...props }) => {
  const headers = ['CATEGORY', 'WO#'];
  return (
    <StyledTableContainer>
      <div className="row row-header">
        {headers.map(header => {
          return (
            <div key={header} className="cell">
              {header}
            </div>
          );
        })}
      </div>
      <div className="content">
        {usedContents.map((element, index) => {
          return (
            <div className="row row-content" key={`${index + 1}`}>
              <div className="cell">{createCategory(element)}</div>
              <div className="cell">{element}</div>
            </div>
          );
        })}
      </div>
    </StyledTableContainer>
  );
};

export default InUseCodeTable;
