import axios from 'axios';
import interceptRequest from '../../../utils/Common/Interceptor/interceptRequest';

const MEMO_BASE_URL = `${process.env.REACT_APP_BASE_URL}/memo/`;

/**
 * Axios object with base url
 */

const memoDefaultAPi = axios.create({
  withCredentials: true,
  baseURL: MEMO_BASE_URL,
  xsrfHeaderName: 'X-CSRFTOKEN',
  xsrfCookieName: 'XCSRF-TOKEN',
  headers: {
    common: {
      'X-CSRFToken': localStorage.getItem('csrfToken'),
    },
  },
});

/** Interceptors */
interceptRequest(memoDefaultAPi);

export default memoDefaultAPi;
