import React, { useRef, useState } from 'react';
import CommonDataGrid from '../../../components/CommonDataGrid';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import { GRID_MOD_BOTH } from '../../../constants/Common/DataGrid/setting';
import useDeleteAuthMutation from '../../../hooks/Admin/Auth/useDeleteAuthMutation';
import useGetAuthList from '../../../hooks/Admin/Auth/useGetAuthList';
import usePatchAuthMutation from '../../../hooks/Admin/Auth/usePatchAuthMutation';
import usePostAuthMutation from '../../../hooks/Admin/Auth/usePostAuthMutation';
import createAuthColumnDefs from '../../../utils/Admin/Auth/Generator/createAuthColumnDefs';
import handleAddClicked from '../../../utils/Admin/Auth/handleAddClicked';
import handleDeleteClicked from '../../../utils/Admin/Auth/handleDeleteClicked';
import handleSaveClicked from '../../../utils/Admin/Auth/handleSaveClicked';

const Auth = () => {
  const gridRef = useRef(null);
  const [columnDefs, setColumnDefs] = useState(createAuthColumnDefs());

  const [isMessageOpen, setIsMessageOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const { isLoadingAuthList, data } = useGetAuthList();

  const { mutate: patchAuthMutation } = usePatchAuthMutation({
    gridRef,
    setIsMessageOpen,
    setMessage,
    setIsSuccess,
  });

  const { mutate: postAuthMutation } = usePostAuthMutation({
    setIsMessageOpen,
    setMessage,
    setIsSuccess,
    gridRef,
  });

  const { mutate: deleteAuthMutation } = useDeleteAuthMutation({
    setIsMessageOpen,
    setMessage,
    setIsSuccess,
  });

  return (
    <>
      <CommonLoading open={isLoadingAuthList} />
      {!isSuccess && (
        <CommonErrorModal
          open={isMessageOpen}
          setIsOpen={setIsMessageOpen}
          message={message}
          isSuccess={isSuccess}
        />
      )}
      {data && (
        <div className="w-full h-full flex flex-col pr-[40px]">
          <div className="mt-8" />
          <div className="w-full flex-1">
            <CommonDataGrid
              addable
              pinnedCheckbox
              modType={GRID_MOD_BOTH}
              gridRef={gridRef}
              data={data}
              columnDefs={columnDefs}
              onSaveClicked={e => handleSaveClicked({ e, patchAuthMutation })}
              onAddClicked={e => handleAddClicked({ e, postAuthMutation })}
              onDeleteClicked={e =>
                handleDeleteClicked({ e, deleteAuthMutation })
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Auth;
