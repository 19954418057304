const traceKeys = {
  all: ['Trace'],
  mapList: ({ target }) => [...traceKeys.all, 'MAP_LIST', target],
  movingList: ({ target }) => [...traceKeys.all, 'MOVING_LIST', target],
  markerList: () => [...traceKeys.all, 'MARKER_LIST'],
  geofenceList: ({ company }) => [...traceKeys.all, 'GEOFENCE_LIST', company],
  jobList: ({ category, target }) => [
    ...traceKeys.all,
    'JOB_LIST',
    category,
    target,
  ],
  jobDetail: ({ seq }) => [...traceKeys.all, 'JOB_DETAIL', seq],
  nglCneeList: () => [...traceKeys.all, 'NGL_CNEE_LIST'],
};

export default traceKeys;
