import React, { useEffect, useState } from 'react';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import GoogleMapComponentMap from '../../../components/Trace/Module/GoogleMapComponentMap';
import TraceSidebarMap from '../../../components/TraceSidebarMap';
import useGetTraceMapList from '../../../hooks/Trace/Get/useGetTraceMapList';
import useGetTraceMarkerList from '../../../hooks/Trace/Get/useGetTraceMarkerList';
import useGetTraceMovingList from '../../../hooks/Trace/Get/useGetTraceMovingList';
import StyledTraceContainer from '../../../styles/Trace/Common/StyledTraceContainer';

const Map = () => {
  const [markerList, setMarkerList] = useState([]);
  const [mapSettings, setMapSettings] = useState({
    size: {
      width: '1358px',
      height: '844px',
    },
    center: {
      lat: 39.817293816682295,
      lng: -101.35897508374268,
    },
    zoom: 5,
  });
  const [target, setTarget] = useState('');

  const { loadingMapList, traceMapList } = useGetTraceMapList({ target });
  const { loadingMovingList, traceMovingList } = useGetTraceMovingList({
    target,
  });
  const { traceMarkerList } = useGetTraceMarkerList();

  useEffect(() => {
    const updatedMarkerList = markerList.map(marker => {
      if (
        traceMarkerList &&
        traceMarkerList[marker?.div] &&
        traceMarkerList[marker?.div][marker?.type]
      ) {
        const locationInfo = traceMarkerList[marker?.div][marker?.type]?.find(
          vehicle => vehicle?.name === marker?.name,
        );

        if (locationInfo) {
          return {
            ...marker,
            locations: {
              ...marker?.locations,
              time: locationInfo?.locations?.time,
              speed: locationInfo?.locations?.speed,
              location: locationInfo?.locations?.location,
              latitude: locationInfo?.locations?.latitude,
              longitude: locationInfo?.locations?.longitude,
            },
          };
        }
        return marker;
      }
      return marker;
    });

    setMarkerList(updatedMarkerList);
  }, [traceMarkerList]);

  return (
    <>
      <CommonLoading open={loadingMapList || loadingMovingList} />
      <StyledTraceContainer>
        <TraceSidebarMap
          markerList={markerList}
          setMarkerList={setMarkerList}
          setTarget={setTarget}
          traceMapList={traceMapList}
          traceMovingList={traceMovingList}
          loadingMapList={loadingMapList}
          loadingMovingList={loadingMovingList}
        />
        <GoogleMapComponentMap
          mapSettings={mapSettings}
          markerList={markerList}
          setMarkerList={setMarkerList}
        />
      </StyledTraceContainer>
    </>
  );
};

export default Map;
