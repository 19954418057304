/**
 * @description
 * Function that apply driver chain to schedule
 * @param {*} param0
 */
const handleScheduleDriverChainChange = ({ ...props }) => {
  /** Get RHF utils */
  const { setValue } = props;

  /** Get data */
  const {
    type,
    locationSet,
    jobSet,
    locationInfo,
    ordering,
    prevData,
    nextData,
    companyIDList,
    dataKey,
    scheType,
    locationSetIdx,
  } = props;

  /** Const WO type */
  const woType =
    document.URL.split('/')?.[document?.URL?.split('/')?.length - 2];

  /** Get data setter */
  const { setSelectedCompany } = props;

  /** Get Company Info */
  const companyInfo = companyIDList?.[0];

  /** Apply current row data */
  setValue(`city_${dataKey}_${locationInfo}`, companyInfo?.city);
  setValue(`state_${dataKey}_${locationInfo}`, companyInfo?.state);
  setValue(`timezone_${dataKey}_${locationInfo}`, companyInfo?.timezone);

  /** Location set case */
  if (locationInfo === 'location') {
    if (woType === 'export' && locationSetIdx === locationSet?.length - 1) {
      const jobSetFirstData = jobSet?.[0];
      if (jobSetFirstData) {
        setValue(
          `company_${jobSetFirstData?.seq}_${jobSetFirstData?.from_location.seq}_fr`,
          companyInfo?.company,
        );
        setValue(
          `city_${jobSetFirstData?.seq}_${jobSetFirstData?.from_location.seq}_fr`,
          companyInfo?.city,
        );
        setValue(
          `state_${jobSetFirstData?.seq}_${jobSetFirstData?.from_location.seq}_fr`,
          companyInfo?.state,
        );
        setValue(
          `timezone_${jobSetFirstData?.seq}_${jobSetFirstData?.from_location.seq}_fr`,
          companyInfo?.timezone,
        );
      }
    } else if (woType !== 'export' && locationSetIdx === 0) {
      const jobSetLastData = jobSet?.[jobSet?.length - 1];
      if (jobSetLastData) {
        setValue(
          `company_${jobSetLastData?.seq}_${jobSetLastData?.to_location.seq}_to`,
          companyInfo?.company,
        );
        setValue(
          `city_${jobSetLastData?.seq}_${jobSetLastData?.to_location.seq}_to`,
          companyInfo?.city,
        );
        setValue(
          `state_${jobSetLastData?.seq}_${jobSetLastData?.to_location.seq}_to`,
          companyInfo?.state,
        );
        setValue(
          `timezone_${jobSetLastData?.seq}_${jobSetLastData?.to_location.seq}_to`,
          companyInfo?.timezone,
        );
      }
      null;
    }
  }

  if (scheType === 'DROP' && type === 'DILV' && ordering === 0) {
    if (
      nextData &&
      nextData?.from_location?.type === 'P/U' &&
      nextData?.from_location?.ordering === 0
    ) {
      setValue(
        `company_${nextData?.seq}_${nextData?.from_location.seq}_fr`,
        companyInfo?.company,
      );
      setValue(
        `city_${nextData?.seq}_${nextData?.from_location.seq}_fr`,
        companyInfo?.city,
      );
      setValue(
        `state_${nextData?.seq}_${nextData?.from_location.seq}_fr`,
        companyInfo?.state,
      );
      setValue(
        `timezone_${nextData?.seq}_${nextData?.from_location.seq}_fr`,
        companyInfo?.timezone,
      );
    } else if (!nextData) {
      if (
        locationSet &&
        locationSet?.[0] &&
        locationSet?.[0]?.type === 'P/U' &&
        locationSet?.[0]?.ordering === 0
      ) {
        setValue(
          `company_${locationSet?.[0]?.seq}_location_location`,
          companyInfo?.company,
        );
        setValue(
          `city_${locationSet?.[0]?.seq}_location_location`,
          companyInfo?.city,
        );
        setValue(
          `state_${locationSet?.[0]?.seq}_location_location`,
          companyInfo?.state,
        );
        setValue(
          `timezone_${locationSet?.[0]?.seq}_location_location`,
          companyInfo?.timezone,
        );
      }
    }
  } else if (ordering !== null && ordering !== undefined && ordering !== 0) {
    /** If selected row is stop location */
    if (locationInfo === 'fr') {
      if (prevData) {
        setValue(
          `company_${prevData?.seq}_${prevData?.to_location.seq}_to`,
          companyInfo?.company,
        );
        setValue(
          `city_${prevData?.seq}_${prevData?.to_location.seq}_to`,
          companyInfo?.city,
        );
        setValue(
          `state_${prevData?.seq}_${prevData?.to_location.seq}_to`,
          companyInfo?.state,
        );
        setValue(
          `timezone_${prevData?.seq}_${prevData?.to_location.seq}_to`,
          companyInfo?.timezone,
        );
      } else if (!prevData && woType === 'export') {
        const lastLocationSetIdx = locationSet?.length - 1;
        /** Check location set data */
        if (locationSet && locationSet?.[lastLocationSetIdx]) {
          setValue(
            `company_${locationSet?.[lastLocationSetIdx]?.seq}_location_location`,
            companyInfo?.company,
          );
          setValue(
            `city_${locationSet?.[lastLocationSetIdx]?.seq}_location_location`,
            companyInfo?.city,
          );
          setValue(
            `state_${locationSet?.[lastLocationSetIdx]?.seq}_location_location`,
            companyInfo?.state,
          );
          setValue(
            `timezone_${locationSet?.[lastLocationSetIdx]?.seq}_location_location`,
            companyInfo?.timezone,
          );
        }
      }
    }
    if (locationInfo === 'to') {
      if (nextData) {
        setValue(
          `company_${nextData?.seq}_${nextData?.from_location.seq}_fr`,
          companyInfo?.company,
        );
        setValue(
          `city_${nextData?.seq}_${nextData?.from_location.seq}_fr`,
          companyInfo?.city,
        );
        setValue(
          `state_${nextData?.seq}_${nextData?.from_location.seq}_fr`,
          companyInfo?.state,
        );
        setValue(
          `timezone_${nextData?.seq}_${nextData?.from_location.seq}_fr`,
          companyInfo?.timezone,
        );
      } else if (!nextData && woType !== 'export') {
        /** Check location set data */
        if (locationSet && locationSet?.[0]) {
          setValue(
            `company_${locationSet?.[0]?.seq}_location_location`,
            companyInfo?.company,
          );
          setValue(
            `city_${locationSet?.[0]?.seq}_location_location`,
            companyInfo?.city,
          );
          setValue(
            `state_${locationSet?.[0]?.seq}_location_location`,
            companyInfo?.state,
          );
          setValue(
            `timezone_${locationSet?.[0]?.seq}_location_location`,
            companyInfo?.timezone,
          );
        }
      }
    }
  } else if (
    ordering !== null &&
    ordering !== undefined &&
    ordering === 0 &&
    woType === 'export'
  ) {
    if (locationInfo === 'location') {
      /** Check Export, DILV - P/U chaining scenario */
      if (
        jobSet &&
        jobSet?.[0] &&
        jobSet?.[0]?.from_location &&
        jobSet?.[0]?.from_location?.type === 'P/U' &&
        jobSet?.[0]?.from_location?.ordering === 0
      ) {
        setValue(
          `company_${jobSet?.[0]?.seq}_${jobSet?.[0]?.from_location?.seq}_fr`,
          companyInfo?.company,
        );
        setValue(
          `city_${jobSet?.[0]?.seq}_${jobSet?.[0]?.from_location?.seq}_fr`,
          companyInfo?.city,
        );
        setValue(
          `state_${jobSet?.[0]?.seq}_${jobSet?.[0]?.from_location?.seq}_fr`,
          companyInfo?.state,
        );
        setValue(
          `timezone_${jobSet?.[0]?.seq}_${jobSet?.[0]?.from_location?.seq}_fr`,
          companyInfo?.timezone,
        );
      }
    }
    if (locationInfo === 'fr' && type === 'P/U') {
      if (locationSet && locationSet?.[0]) {
        setValue(
          `company_${locationSet?.[0]?.seq}_location_location`,
          companyInfo?.company,
        );
        setValue(
          `city_${locationSet?.[0]?.seq}_location_location`,
          companyInfo?.city,
        );
        setValue(
          `state_${locationSet?.[0]?.seq}_location_location`,
          companyInfo?.state,
        );
        setValue(
          `timezone_${locationSet?.[0]?.seq}_location_location`,
          companyInfo?.timezone,
        );
      }
    }
  }
  /** Clear selected company data */
  setSelectedCompany?.(null);
};

export default handleScheduleDriverChainChange;
