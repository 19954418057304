import React from 'react';

const CustomColumnItemMenuIcon = ({ iconColor, size, ...props }) => {
  return (
    <svg
      width={`${size === 'small' ? '9' : '12'}`}
      height={`${size === 'small' ? '6' : '8'}`}
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0H12V1.33332H0V0Z" fill={iconColor} />
      <path d="M0 0H12V1.33332H0V0Z" fill={iconColor} />
      <path d="M0 3.33276H12V4.66608H0V3.33276Z" fill={iconColor} />
      <path d="M0 6.66675H12V8.00007H0V6.66675Z" fill={iconColor} />
    </svg>
  );
};

export default CustomColumnItemMenuIcon;
