import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';
import bookingDefaultAPI from '../../../bookingAPI';

const patchEvidence = async ({ bookingSeq, formData }) => {
  try {
    const response = await bookingDefaultAPI.patch(
      `detail/update-evidence/${bookingSeq}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchEvidence;
