import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import getTrackingInfo from '../../../services/BCDetail/Common/Get/getTrackingInfo';
import bcDetailKeys from '../keys';

const useGetTrackingInfo = () => {
  /** Get WO from URL */
  const woNo =
    document.URL?.split('/')?.[document?.URL?.split('/')?.length - 1];

  const [trackingInfo, setTrackingInfo] = useState(null);

  /** Query */
  const query = useQuery({
    queryKey: bcDetailKeys.trackingModal({ woNo }),
    queryFn: () => getTrackingInfo({ woNo }),
    refetchOnWindowFocus: false,
    enabled: !!woNo,
    retry: false,
  });

  useEffect(() => {
    if (query?.data) {
      setTrackingInfo(query?.data?.data);
    }
  }, [query?.data]);

  return { ...query, trackingInfo };
};

export default useGetTrackingInfo;
