import cellRendererWithCellTypeGenerator from './cellRendererWithCellTypeGenerator';

const normalCellRendererGenerator = ({
  gridParam,
  cellType,
  adornment,
  customStyle,
  ...props
}) => {
  const normalCellSettings = {
    component: cellRendererWithCellTypeGenerator({ cellType }),
    params: {
      gridParam,
      cellType,
      adornment,
      customStyle,
      ...props,
    },
  };

  return normalCellSettings;
};

export default normalCellRendererGenerator;
