import adminDefaultApi from '../../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

const patchConfData = async ({ title, seq, data }) => {
  try {
    const response = await adminDefaultApi.patch(`${title}/${seq}`, data);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchConfData;
