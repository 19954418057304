import styled from 'styled-components';

const StyledSaveItemNameInput = styled.input`
  width: 100%;
  height: 31px;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 12px;
  font-weight: 500;
  color: #000;
  outline: none !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  border-top-right-radius: ${props =>
    props?.inputType === 'email' ? '5px' : '0px'};
  border-bottom-right-radius: ${props =>
    props?.inputType === 'email' ? '5px' : '0px'};
`;

export default StyledSaveItemNameInput;
