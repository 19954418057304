import { useMutation } from '@tanstack/react-query';
import { SUCCESS_UPDATE } from '../../../../constants/Common/successMessage';
import patchWeightPerZIP from '../../../../services/Admin/DivConf/Patch/patchWeightPerZIP';
import useControlToast from '../../../Common/Toast/useControlToast';
import updateGridData from '../../../../utils/Common/Handler/updateGridData';
import resetRow from '../../../../utils/Common/Handler/resetRow';

const usePatchWeightPerZipMutation = ({
  gridRef,
  setIsMessageOpen,
  setMessage,
  setIsSuccess,
  div,
}) => {
  const { addToast } = useControlToast();

  return useMutation({
    mutationFn: patchWeightPerZIP,
    onSuccess: (data, variables) => {
      updateGridData({
        gridRef,
        newData: variables,
        isUpdate: true,
      });
      addToast({
        message: SUCCESS_UPDATE,
      });
    },
    onError: error => {
      resetRow({ gridRef });
      setIsSuccess(false);
      setMessage(error.message);
      setIsMessageOpen(true);
    },
  });
};

export default usePatchWeightPerZipMutation;
