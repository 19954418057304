import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SUCCESS_SAVE } from '../../../constants/Common/successMessage';
import postTierWeight from '../../../services/Master/TierWeight/Post/postTierWeight';
import useControlToast from '../../Common/Toast/useControlToast';
import masterKeys from '../keys';

const usePostTierWeightMutation = ({
  setIsMessageOpen,
  setMessage,
  gridRef,
  div,
  setIsSuccess,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  return useMutation({
    mutationFn: postTierWeight,
    onSettled: (data, error, variables) => {
      variables?.setAddDisabled(false);
    },
    onSuccess: res => {
      const topRow = gridRef.current.api.getPinnedTopRow(0);
      topRow.setData({});
      gridRef.current.api.redrawRows({ rowNodes: [topRow] });
      queryClient.refetchQueries({
        queryKey: masterKeys?.tierWeightList({ div }),
      });
      addToast({
        message: SUCCESS_SAVE,
      });
    },
    onError: error => {
      setIsSuccess(false);
      setMessage(error.message);
      setIsMessageOpen(true);
    },
  });
};

export default usePostTierWeightMutation;
