import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import mapAPI from '../../api';

const getMovingList = async ({ target }) => {
  try {
    const response = await mapAPI.get(`/moving?target=${target}`);
    return response.data;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getMovingList;
