import React from 'react';
import { PATH_SPEECH_BUBBLE_DATETIME_BLUE_ICON } from '../../../../constants/Common/path';

const Tooltip = ({ message }) => {
  return (
    <div className="relative flex items-center w-24 h-[22px] z-[9999]">
      <div>
        <img
          src={PATH_SPEECH_BUBBLE_DATETIME_BLUE_ICON}
          alt="speechBubbleDatetimeBlue"
        />
      </div>
      <div className="absolute left-0 w-full h-full flex items-center justify-center text-white text-[12px]  font-[500]">
        {message}
      </div>
    </div>
  );
};

export default Tooltip;
