import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CommonCheckboxList from '../../../Inputs/CommonCheckboxList';
import CommonButton from '../../../CommonButton/CommonButton';
import handleAllCheckboxChange from '../../../../utils/TmsReport/Common/handleAllCheckboxChange';

const YardCheckboxListButton = props => {
  const {
    yardList,
    yardQueryObject,
    setYardQuery,
    isYardOpen,
    setIsYardOpen,
    category,
  } = props;
  const yardController = useForm({ defaultValues: { ...yardQueryObject } });

  useEffect(() => {
    yardController.reset({ ...yardQueryObject });
  }, [category]);

  return (
    <FormProvider {...yardController}>
      <CommonCheckboxList
        isOpen={isYardOpen}
        options={yardList}
        onChangeAll={({ ...handleProps }) => {
          handleAllCheckboxChange({
            ...handleProps,
            setQuery: setYardQuery,
          });
        }}
        suppressIndeterminate
        button={
          <CommonButton
            category="yard"
            onClick={() => {
              setIsYardOpen(prev => !prev);
            }}
            border="0"
            backgroundColor="transparent"
            color="#666666"
          >
            Yard
          </CommonButton>
        }
      />
    </FormProvider>
  );
};

export default YardCheckboxListButton;
