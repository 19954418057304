import { useQuery } from '@tanstack/react-query';
import get204List from '../../../services/EDI/Get/get204List';
import ediKeys from '../keys';

const useGet204List = ({ div, from, to, sender }) => {
  const {
    isLoading: isLoading204List,
    data: list204Data,
    isRefetching: isRefetching204List,
  } = useQuery({
    queryKey: ediKeys?.get204List({ div, from, to, sender }),
    queryFn: () => get204List({ div, from, to, sender }),
    refetchOnWindowFocus: false,
  });

  return {
    isLoading204List,
    list204Data,
    isRefetching204List,
  };
};

export default useGet204List;
