export const handleRendererPairChange = ({ getChangedValue, ...props }) => {
  const instances = props.api.getCellRendererInstances();
  const selfInstance = instances.find(
    element => element?.column?.colId === props?.column?.colId,
  );

  const pairInstance = instances.find(
    element => element?.column?.colId === props?.pair,
  );

  const selectedValue = props.option.find(
    element => element.label === getChangedValue,
  );

  if (selectedValue) {
    const selfValue = selectedValue?.pair?.[props?.column?.colId];
    const pairValue = selectedValue?.pair?.[props?.pair];

    selfInstance.setInputValue(selfValue);
    pairInstance.setInputValue(pairValue);
    selfInstance.setValue(selfValue);
    pairInstance.setValue(pairValue);
    selfInstance.setDataValue(selfValue);
    pairInstance.setDataValue(pairValue);
  }
};

export const handleEditorPairChange = ({ getChangedValue, ...props }) => {
  const instances = props.api.getCellEditorInstances();

  const selfInstance = instances.find(
    element =>
      element?.cellEditorParams?.column?.colId === props?.column?.colId,
  );

  const pairInstance = instances.find(
    element => element?.cellEditorParams?.column?.colId === props?.pair,
  );

  const selectedValue = props.option.find(
    element => element.label === getChangedValue,
  );

  if (selectedValue) {
    const selfValue = selectedValue?.pair?.[props?.column?.colId];
    const pairValue = selectedValue?.pair?.[props?.pair];

    selfInstance?.componentInstance?.setInputValue(selfValue);
    pairInstance?.componentInstance?.setInputValue(pairValue);
    selfInstance?.componentInstance?.setDataValue(selfValue);
    pairInstance?.componentInstance?.setDataValue(pairValue);
    selfInstance?.onValueChange(selfValue);
    pairInstance?.onValueChange(pairValue);
  }
};
