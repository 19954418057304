import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthRouter from '../../../../../components/Common/AuthRouter';
import User from '../../../../../pages/Admin/User';
import {
  PERM_ADMIN_AUTH,
  PERM_ADMIN_CONF,
  PERM_ADMIN_DIV_CONF,
  PERM_ADMIN_DIV_EARLY_WARNING,
  PERM_ADMIN_USER,
} from '../../../permission';
import Auth from '../../../../../pages/Admin/Auth';
import Conf from '../../../../../pages/Admin/Conf';
import DivConf from '../../../../../pages/Admin/DivConf';
import EarlyWarning from '../../../../../pages/Admin/EarlyWarning';
import getAuthorizedMenu from '../../../../../utils/Common/Sidebar/Handler/getAuthorizedMenu';
import { SIDEBAR_SUB_MENU } from '../../../Option/Sidebar';

const ADMIN_CHILDREN_ROUTE = [
  {
    index: true,
    element: (
      <Navigate
        to={
          getAuthorizedMenu({
            allMenu: SIDEBAR_SUB_MENU?.[10],
          })?.[0]?.to
        }
      />
    ),
  },
  {
    path: 'user/*',
    element: (
      <AuthRouter permission={PERM_ADMIN_USER}>
        <User />
      </AuthRouter>
    ),
  },
  {
    path: 'auth/*',
    element: (
      <AuthRouter permission={PERM_ADMIN_AUTH}>
        <Auth />
      </AuthRouter>
    ),
  },
  {
    path: 'conf/*',
    element: (
      <AuthRouter permission={PERM_ADMIN_CONF}>
        <Conf />
      </AuthRouter>
    ),
  },
  {
    path: 'div-conf/*',
    element: (
      <AuthRouter permission={PERM_ADMIN_DIV_CONF}>
        <DivConf />
      </AuthRouter>
    ),
  },
  {
    path: 'early-warning/*',
    element: (
      <AuthRouter permission={PERM_ADMIN_DIV_EARLY_WARNING}>
        <EarlyWarning />
      </AuthRouter>
    ),
  },
];

export default ADMIN_CHILDREN_ROUTE;
