import updateButtonDisabled from '../../../Common/Handler/updateButtonDisabled';

const handleAllSave = ({
  submitData,
  woNo,
  documentMutator,
  buttonRef,
  ...props
}) => {
  if (buttonRef?.current)
    updateButtonDisabled({ ref: buttonRef, disabled: true });

  const docData = {};
  const newList = [];
  const fileData = submitData.file;

  Object.entries(submitData).forEach(([id, data]) => {
    if (id === '0') return;
    if (id === 'file') return;
    newList.push(data);
  });

  docData.doc = JSON.stringify(newList);

  Object.entries(fileData).forEach(([key, file]) => {
    const type = submitData[key].type;
    if (file[type]) docData[type] = file?.[type];
  });

  const doBinary = docData?.DO;
  const doURL = Object.values(submitData).filter(({ type }) => type === 'DO')[0]
    ?.file_url;

  if (doURL === '' && doBinary === undefined) {
    props?.setMessageModalOpen(true);
    props?.setMessageContent('Please upload DO.');
    updateButtonDisabled({ ref: buttonRef, disabled: false });
    return;
  }

  documentMutator({
    woNo,
    docData,
    buttonRef,
  });
};

export default handleAllSave;
