import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthRouter from '../../../../../components/Common/AuthRouter';
import {
  PERM_CUS_REPORT_MAIN,
  PERM_CUS_REPORT_SETTING,
} from '../../../permission';
import CustomerReportMain from '../../../../../pages/CustomerReport/CustomerReportMain';
import CustomerReportSetting from '../../../../../pages/CustomerReport/CustomerReportSetting';
import getAuthorizedMenu from '../../../../../utils/Common/Sidebar/Handler/getAuthorizedMenu';
import { SIDEBAR_SUB_MENU } from '../../../Option/Sidebar';

const CUSTOMER_REPORT_CHILDREN_ROUTE = [
  {
    index: true,
    element: (
      <Navigate
        to={
          getAuthorizedMenu({
            allMenu: SIDEBAR_SUB_MENU?.[3],
          })?.[0]?.to
        }
      />
    ),
  },
  {
    path: 'report-main',
    element: (
      <AuthRouter permission={PERM_CUS_REPORT_MAIN}>
        <CustomerReportMain subCategory="report-main" />
      </AuthRouter>
    ),
  },
  {
    path: 'report-setting',
    element: (
      <AuthRouter permission={PERM_CUS_REPORT_SETTING}>
        <CustomerReportSetting subCategory="report-setting" />
      </AuthRouter>
    ),
  },
];

export default CUSTOMER_REPORT_CHILDREN_ROUTE;
