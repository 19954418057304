import React from 'react';
import ToggleWithText from '../ToggleWithText';

const EmailButtonContainer = ({ emailBtn, div, patchEmailButtonMutation }) => {
  return (
    <div className="flex ml-[100px] mt-[65px] flex-col align-center">
      <div className="text-stone-600 text-xs font-medium mb-[10px]">
        EMAIL BUTTON
      </div>
      <div className="flex flex-col mt-[-8px] w-40 h-[215px] bg-white rounded-[5px] border border-zinc-300 gap-[7px]">
        <ToggleWithText
          name="P/OUT"
          inputName="P/OUT"
          isActive={emailBtn[0].active}
          div={div}
          mutation={patchEmailButtonMutation}
        />
        <ToggleWithText
          name="D/L"
          inputName="D/L"
          isActive={emailBtn[1].active}
          div={div}
          mutation={patchEmailButtonMutation}
        />
        <ToggleWithText
          name="POD/POL"
          inputName="POD/POL"
          isActive={emailBtn[2].active}
          div={div}
          mutation={patchEmailButtonMutation}
        />
        <ToggleWithText
          name="DEMUR"
          inputName="DEMUR"
          isActive={emailBtn[3].active}
          div={div}
          mutation={patchEmailButtonMutation}
        />
        <ToggleWithText
          name="WAIT"
          inputName="WAIT"
          isActive={emailBtn[4].active}
          div={div}
          mutation={patchEmailButtonMutation}
        />
        <ToggleWithText
          name="PER DIEM"
          inputName="PerDiem"
          isActive={emailBtn[5].active}
          div={div}
          mutation={patchEmailButtonMutation}
        />
      </div>
    </div>
  );
};

export default EmailButtonContainer;
