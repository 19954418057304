import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import ContainerInfoWrapper from './ContainerInfoWrapper';
import ContainerInfoAddButton from './ContainerInfoAddButton';
import generateAddTarget from '../../../utils/Order/Generator/generateAddTarget';
import { generateBookingEachOption } from '../../../utils/Order/Generator/optionGenerator';

const CommonContainerInfo = ({
  subCategory,
  container,
  containerInfo,
  containerInfoSetter,
  commonOption,
  bookingInfo,
  bookingSequence,
  ...props
}) => {
  // Reference for Controlling the Speed of Equipment Deletion
  const equipLockRef = useRef(false);

  const { containerKey, containerCount, containerList } = containerInfo;
  const { setContainerKey, setContainerCount, setContainerList } =
    containerInfoSetter;

  // Info of booking select option from API
  const { bookingInfoSelectOption } = bookingInfo;
  const [bookingSelectOption, setBookingSelectOption] = useState(
    bookingInfoSelectOption,
  );
  const [bookingOption, setBookingOption] = useState({
    sizeOption: null,
    typeOption: null,
  });

  useEffect(() => {
    // Divide Size and Type count.
    const sizeOption = generateBookingEachOption({
      items: bookingSelectOption,
      property: 'size',
      containerList,
    });
    const typeOption = generateBookingEachOption({
      items: bookingSelectOption,
      property: 'type',
      containerList,
    });

    if (sizeOption && sizeOption.length > 0) {
      setBookingOption(prev => ({ ...prev, sizeOption }));
    }
    if (typeOption && typeOption.length > 0) {
      setBookingOption(prev => ({ ...prev, typeOption }));
    }
  }, [bookingSelectOption, containerList]);

  useEffect(() => {
    setBookingSelectOption(bookingInfoSelectOption);
  }, [bookingInfoSelectOption]);

  const containerInfoDatas = containerList.map((cont, idx) => {
    return (
      <ContainerInfoWrapper
        key={`${String(idx)}_${cont?.containerKey}`}
        equipLockRef={equipLockRef}
        // Same as container key's value.
        dataKey={cont?.containerKey}
        idx={idx + 1}
        subCategory={subCategory}
        containerList={containerList}
        setContainerList={setContainerList}
        commonOption={commonOption}
        bookingOption={bookingOption}
        setBookingOption={setBookingOption}
        bookingSequence={bookingSequence}
        bookingSelectOption={bookingSelectOption}
      />
    );
  });

  return (
    <div className="w-[424px] min-w-[424px] h-[844px] min-h-[844px] bg-[#FFF] py-[35px] px-6 overflow-y-auto flex flex-col gap-y-[10px]">
      {containerInfoDatas}
      <ContainerInfoAddButton
        containerKey={containerKey}
        containerIdx={containerCount}
        setContainerCount={setContainerCount}
        setContainerKey={setContainerKey}
        setContainerList={setContainerList}
        addTarget={generateAddTarget({ subCategory })}
      />
    </div>
  );
};

export default CommonContainerInfo;
