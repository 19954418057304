import React from 'react';
import StyledUserInfoContainer from '../../../../../styles/Dispatch/BookingModal/StyledUserInfoContainer';
import generateFormattedDate from '../../../../../utils/Common/Generator/generateFormattedDate';

const UpdateInfoArea = ({ data, userID, today }) => {
  return (
    <StyledUserInfoContainer>
      <div className="info">
        <div className="info-title">CREATED BY</div>
        <div className="info-data">
          <div>{data?.created_by || userID}</div>
          <div className="info-separator" />
          <div>
            {data?.updated_date
              ? generateFormattedDate({ date: data?.updated_date })
              : today}
          </div>
        </div>
      </div>
      <div className="info">
        <div className="info-title">UPDATED BY</div>
        <div className="info-data">
          <div>{data?.updated_by || userID}</div>
          <div className="info-separator" />
          <div>
            {data?.created_date
              ? generateFormattedDate({ date: data?.created_date })
              : today}
          </div>
        </div>
      </div>
    </StyledUserInfoContainer>
  );
};
export default UpdateInfoArea;
