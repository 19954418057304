import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import CommonSelect from '../../../../Inputs/CommonSelect/CommonSelect';
import { ADMIN_CONF_ACTIVE_FILTER_OPTIONS } from '../../../../../constants/Admin/Conf/filterOptions';

const SearchArea = ({ active, setActive }) => {
  const statusController = useForm();

  const handleStatusChange = ({ e, onChange }) => {
    const activeValue = e?.target?.value;
    onChange(activeValue);
    if (activeValue === 'ALL') {
      setActive(null);
    } else {
      setActive(activeValue);
    }
  };

  return (
    <FormProvider {...statusController}>
      <form className="p-[12px]">
        <CommonSelect
          labelVisible={false}
          width="w-[120px]"
          defaultValue={active || 'ALL'}
          option={ADMIN_CONF_ACTIVE_FILTER_OPTIONS}
          inputName="active"
          onChangeHandler={handleStatusChange}
        />
      </form>
    </FormProvider>
  );
};

export default SearchArea;
