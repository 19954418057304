import React, { useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import dayjs from 'dayjs';
import CommonDate from '../../../../../../../Inputs/CommonDate/CommonDate';
import CommonButton from '../../../../../../../CommonButton/CommonButton';
import CommonTextInput from '../../../../../../../Inputs/CommonTextInput/CommonTextInput';
import generateFilterDefaultValue from '../../../../../../../../utils/BCDetail/DetailInfo/DualMove/Generator/generateFilterDefaultValue';
import searchFilter from '../../../../../../../../utils/BCDetail/DetailInfo/DualMove/Handler/searchFilter';
import useModalController from '../../../../../../../../hooks/BCDetail/DetailInfo/DualMove/useModalController';
import CommonErrorModal from '../../../../../../../CommonErrorModal/CommonErrorModal';

const DualMoveModalContentFilter = ({ ...props }) => {
  const { setFilter } = props;

  /** Form controller */
  const filterController = useForm({
    defaultValues: { ...generateFilterDefaultValue() },
  });
  const { handleSubmit, control } = filterController;

  /** Watcher */
  const fromDateWatcher = useWatch({ control, name: 'from_sche_date' });
  const toDateWatcher = useWatch({ control, name: 'to_sche_date' });

  /** Modal */
  const { ...modalController } = useModalController();

  return (
    <>
      <FormProvider {...filterController}>
        <form>
          <div className="w-full h-fit flex justify-center items-center gap-[14px]">
            <div className="w-[212px]">
              <CommonTextInput
                inputName="cont_no"
                label="CONT #"
                maxLength={11}
                onChangeHandler={({ e, onChange }) => {
                  onChange(e?.target?.value);
                }}
                onBlurHandler={({ e, onChange }) => {
                  onChange(e?.target?.value?.toUpperCase());
                }}
              />
            </div>
            <div className="flex justify-center items-center gap-[5px]">
              <div className="w-[212px]">
                <CommonDate
                  inputName="from_sche_date"
                  label="Date"
                  onChangeHandler={({ e, onChange }) => {
                    if (dayjs(e).isValid()) onChange?.(e);
                    else onChange?.(null);
                  }}
                  required={!!toDateWatcher}
                />
              </div>
              <div>-</div>
              <div className="w-[212px]">
                <CommonDate
                  inputName="to_sche_date"
                  label=""
                  onChangeHandler={({ e, onChange }) => {
                    if (dayjs(e).isValid()) onChange?.(e);
                    else onChange?.(null);
                  }}
                  required={!!fromDateWatcher}
                />
              </div>
            </div>
            <div className="flex justify-center items-center">
              <CommonButton
                category="search"
                onClick={() =>
                  searchFilter({ handleSubmit, modalController, setFilter })
                }
              >
                Search
              </CommonButton>
            </div>
          </div>
        </form>
      </FormProvider>
      {/* Message modal */}
      {modalController?.modalOpen && (
        <CommonErrorModal
          open={modalController?.modalOpen}
          setIsOpen={modalController?.setModalOpen}
          message={modalController?.modalMessage}
          isSuccess={modalController?.modalIsSuccess}
        />
      )}
    </>
  );
};

export default DualMoveModalContentFilter;
