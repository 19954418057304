import bcDetailWoDefaultApi from '../api';
import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';

const postConvertToExport = async ({ woNo }) => {
  try {
    const response = await bcDetailWoDefaultApi.post(`${woNo}/street-turn`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default postConvertToExport;
