import {
  WO_CATEGORY_BARE_CHASSIS,
  WO_CATEGORY_BROKERAGE,
  WO_CATEGORY_EXPORT_REGULAR,
  WO_CATEGORY_EXPORT_SHUTTLE,
  WO_CATEGORY_IMPORT_REGULAR,
  WO_CATEGORY_IMPORT_SHUTTLE,
  WO_CATEGORY_VAN,
} from '../../../constants/CustomerReport/standard';

/**
 * @description
 * Function that handles the category data in grid
 * @param {string} data
 * Category data to be handled
 * @returns {string | null}
 * Handled category data
 */
const handleGridCategory = ({ data }) => {
  if (data === WO_CATEGORY_IMPORT_REGULAR) return 'IMPORT';
  if (data === WO_CATEGORY_IMPORT_SHUTTLE) return 'IMP - SHTL';
  if (data === WO_CATEGORY_EXPORT_REGULAR) return 'EXPORT';
  if (data === WO_CATEGORY_EXPORT_SHUTTLE) return 'EXP - SHTL';
  if (data === WO_CATEGORY_VAN) return 'VAN';
  if (data === WO_CATEGORY_BROKERAGE) return 'BROKERAGE';
  if (data === WO_CATEGORY_BARE_CHASSIS) return 'BARE CHASSIS';
  return null;
};

export default handleGridCategory;
