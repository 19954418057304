import { useMutation } from '@tanstack/react-query';
import deleteSettingUserID from '../../../../services/CustomerReport/Delete/deleteSettingUserID';
import useControlToast from '../../../Common/Toast/useControlToast';
import { SUCCESS_DELETE } from '../../../../constants/Common/successMessage';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';

const useDeleteSettingUserID = ({
  setIsOpenErrorModal,
  setMessage,
  setIsSuccess,
  setEmailList,
}) => {
  const { addToast } = useControlToast();

  const mutate = useMutation({
    mutationFn: deleteSettingUserID,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: (data, variables) => {
      setEmailList(prev =>
        prev?.filter(email => email !== variables?.deleteData?.email),
      );
      addToast({ message: SUCCESS_DELETE });
    },
    onError: error => {
      setIsOpenErrorModal(true);
      setIsSuccess(false);
      setMessage(error?.message);
    },
  });

  return { ...mutate };
};

export default useDeleteSettingUserID;
