import updateButtonDisabled from '../../../Common/Handler/updateButtonDisabled';

const saveUserInfo = ({
  handleSubmit,
  userID,
  userInfoPatchMutator,
  divList,
  buttonRef,
  isFormInvalid,
}) => {
  if (isFormInvalid) {
    updateButtonDisabled({ ref: buttonRef, disabled: false });
  }

  handleSubmit(submitData => {
    const patchData = {
      first_page: submitData?.first_page,
      userdivision_set: {
        default_div: submitData?.default_div,
      },
    };

    patchData.group_name = submitData?.group_name || null;
    divList
      ?.map(div => div?.div)
      ?.forEach(div => {
        patchData.userdivision_set[div] = submitData?.[div];
      });

    userInfoPatchMutator({ id: userID, patchData, buttonRef });
  })();
};

export default saveUserInfo;
