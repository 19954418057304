import React from 'react';
import { QueryClient } from '@tanstack/react-query';
import CommonButton from '../../../../../../CommonButton/CommonButton';
import bcDetailKeys from '../../../../../../../hooks/BCDetail/keys';
import updateButtonDisabled from '../../../../../../../utils/Common/Handler/updateButtonDisabled';

const ScheduleDriverListModalButtonContent = ({
  setOpen,
  setAPModalOpen,
  getAPDataRefetch,
  selectedDriverData,
  setSelectedScheduleSeq,
  setSelectedDriverData,
  ...props
}) => {
  const queryClient = new QueryClient();

  return (
    <div className="w-full flex justify-center items-center">
      <div className="flex gap-[20px]">
        <CommonButton
          category="cancel"
          onClick={() => {
            setOpen(false);
            queryClient?.invalidateQueries({
              queryKey: bcDetailKeys?.driverListDefault(),
            });
            setSelectedScheduleSeq(null);
            setSelectedDriverData(null);
          }}
        >
          Cancel
        </CommonButton>
        <CommonButton
          category="assign"
          preventDupClick
          onClick={async ({ buttonRef }) => {
            const { isError: apIsError, isFetching: apIsFetching } =
              await getAPDataRefetch();
            updateButtonDisabled({ ref: buttonRef, disabled: false });
            if (!apIsError && !apIsFetching) {
              setOpen(false);
              setAPModalOpen(true);
            }
          }}
          disabled={!selectedDriverData}
        >
          Assign
        </CommonButton>
      </div>
    </div>
  );
};

export default ScheduleDriverListModalButtonContent;
