import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useGetLocationInfo from '../../../hooks/BCDetail/Common/useGetLocationInfo';
import BCDetailHeaderTab from './BCDetailHeaderTab';

import useGetYardList from '../../../hooks/BCDetail/DetailInfo/BasicInfo/useGetYardList';
import useGetCommonAutoComplete from '../../../hooks/Common/useGetCommonAutoComplete';
import getBCDetailRouterTabItem from '../../../utils/BCDetail/Common/Generator/bcDetailRouterGenerator';
import createPageType from '../../../utils/BCDetail/Common/Generator/createPageType';
import handleBCDetailPermission from '../../../utils/Common/Handler/handleBCDetailPermission';
import CommonButton from '../../CommonButton/CommonButton';
import CommonErrorModal from '../../CommonErrorModal/CommonErrorModal';
import CommonAutoComplete from '../../Inputs/CommonAutoComplete/CommonAutoComplete';
import BCDetailYardModal from './BCDeatilYardModal';

const BCDetailHeader = ({ ...props }) => {
  const yardModalRef = useRef();
  /** Get Current Location Info */
  const locationInfo = useGetLocationInfo();
  const bcDetailHeaderController = useForm({
    shouldUnregister: true,
    defaultValues: {
      cont_no: '',
      ch_no: '',
    },
  });

  /** Error Modal Opener */
  const [errorModalOpener, setErrorModalOpener] = useState(false);

  const [isYardModalOpen, setIsYardModalOpen] = useState(false);

  /** Get header auto complete option */
  const {
    autoCompleteItem,
    isLoading: isLoadingAutoCompleteItem,
    error: getAutoCompleteError,
  } = useGetCommonAutoComplete({
    setErrorModalOpener,
  });

  const { isLoadingYardList, data: yardList } = useGetYardList({
    isYardModalOpen,
  });

  const handleClickOutside = event => {
    if (yardModalRef.current && !yardModalRef.current.contains(event.target)) {
      setIsYardModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  /** Render */
  return (
    <>
      <div
        className="w-full min-w-[1920px] h-[42px] min-h-[42px] shrink-0 bg-[#F2F4F8] flex items-center justify-between
                  sticky top-0 z-[9999]"
      >
        <div className="flex flex-row">
          {getBCDetailRouterTabItem?.({ locationInfo })
            ?.filter((tabItem, index) =>
              handleBCDetailPermission({ woType: tabItem?.woType }),
            )
            ?.map((item, index) => (
              <BCDetailHeaderTab key={item?.to} {...item} {...locationInfo} />
            ))}
        </div>
        {isYardModalOpen && !isLoadingYardList && (
          <div ref={yardModalRef}>
            <BCDetailYardModal yardList={yardList} />
          </div>
        )}
        <FormProvider {...bcDetailHeaderController}>
          <form>
            <div className="flex flex-row gap-[35px] h-full">
              <div className="flex items-center">
                <CommonButton
                  type="button"
                  onClick={() => setIsYardModalOpen(true)}
                  category="blueYard"
                >
                  Yard
                </CommonButton>
              </div>
              <div className="w-[200px] min-w-[200px] h-full flex justify-between items-center">
                <div className="text-[#222] text-[12px] font-medium">
                  CONT #
                </div>
                <div className="w-[150px] min-w-[150px] h-[30px] min-h-[30px]">
                  <CommonAutoComplete
                    inputName="bc_detail_header_ac_first"
                    labelVisible={false}
                    option={autoCompleteItem
                      ?.filter(item => item?.cont_no || item?.trailer_no)
                      ?.map(item => {
                        return {
                          label: `${item?.cont_no || item?.trailer_no} / ${
                            item?.wo_no
                          }`,
                          value: item?.cont_no || item?.trailer_no,
                          wo: item?.wo_no,
                        };
                      })}
                    onChangeHandler={({ e, selectProps, onChange }) => {
                      const getContNo = selectProps?.value;
                      const getWoNo = selectProps?.wo;
                      onChange?.(getContNo);
                      if (getContNo) {
                        window.location.replace(
                          `${document.URL.split('/')
                            .slice(0, -2)
                            .join('/')}/${createPageType({
                            type: getWoNo?.[1],
                          })}/${getWoNo}`,
                        );
                      }
                    }}
                    isLoading={isLoadingAutoCompleteItem}
                  />
                </div>
              </div>
              <div className="w-[217px] min-w-[217px] mr-[71px] h-full flex justify-between items-center">
                <div className="text-[#222] text-[12px] font-medium">
                  CHASSIS #
                </div>
                <div className="w-[150px] min-w-[150px] h-[30px] min-h-[30px]">
                  <CommonAutoComplete
                    inputName="bc_detail_header_ac_second"
                    labelVisible={false}
                    option={autoCompleteItem
                      ?.filter(item => item?.ch_no)
                      ?.map(item => {
                        return {
                          label: `${item?.ch_no} / ${item?.wo_no}`,
                          value: item?.ch_no,
                          wo: item?.wo_no,
                        };
                      })}
                    onChangeHandler={({ e, selectProps, onChange }) => {
                      const getChassisNo = selectProps?.value;
                      const getWoNo = selectProps?.wo;
                      onChange?.(getChassisNo);
                      if (getChassisNo) {
                        window.location.replace(
                          `${document.URL.split('/')
                            .slice(0, -2)
                            .join('/')}/${createPageType({
                            type: getWoNo?.[1],
                          })}/${getWoNo}`,
                        );
                      }
                    }}
                    isLoading={isLoadingAutoCompleteItem}
                  />
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
      {errorModalOpener && (
        <CommonErrorModal
          open={errorModalOpener}
          setIsOpen={setErrorModalOpener}
          message={getAutoCompleteError?.message}
        />
      )}
    </>
  );
};

export default BCDetailHeader;
