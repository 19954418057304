import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import masterDefaultApi from '../../api';

const getDriverList = async ({ queryObject }) => {
  try {
    const response = await masterDefaultApi.get('driver', {
      params: { ...queryObject },
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getDriverList;
