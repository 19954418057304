import styled from 'styled-components';

const StyledBCDetailBillingInfoCommonHeaderRow = styled.div`
  margin-top: -15px;
  height: 35px;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
`;

export default StyledBCDetailBillingInfoCommonHeaderRow;
