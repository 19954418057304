const handleAllCheckboxChange = ({
  e,
  setQuery,
  item,
  filterStorage,
  ...handleProps
}) => {
  handleProps?.options?.forEach(checkbox => {
    setQuery(prev => {
      return { ...prev, [checkbox?.value]: e?.target?.checked };
    });
    handleProps?.setValue(checkbox?.value, e?.target?.checked);

    if (filterStorage) {
      const { setFilter } = filterStorage;
      setFilter(prev => {
        return {
          ...prev,
          [item]: {
            ...prev?.[item],
            [checkbox?.value]: e?.target?.checked,
          },
        };
      });
    }
  });
};

export default handleAllCheckboxChange;
