import React, { useEffect, useState } from 'react';

const CommonStatusBox = ({ ...props }) => {
  const [status, setStatus] = useState(
    props.status ? props.status : 'complete',
  );
  const [width, setWidth] = useState(props?.width ? props.width : 'w-[119px]');

  useEffect(() => {
    setStatus(props.status);
  }, [props.status]);

  const statusColor = {
    Complete: 'bg-[#5C9D7C]',
    Pending: 'bg-[#E79D68]',
    'In Transit': 'bg-[#86AFEC]',
    'GATE IN': 'bg-[#556DA1]',
    'GATE OUT': 'bg-[#BF5050]',
  };

  return (
    <div>
      <div
        className={`${width} pt-[8px] pb-[9px] text-center text-[11px] font-[500] h-[30px] text-[#fff] ${statusColor[status]}`}
      >
        {status.toUpperCase()}
      </div>
    </div>
  );
};

export default CommonStatusBox;
