import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material';
import LiveShareContent from '../../components/LiveShare/LiveShareContent';
import useGetLiveShareData from '../../hooks/LiveShare/useGetLiveShareData';
import CommonLoading from '../../components/CommonLoading/CommonLoading';
import CommonErrorModal from '../../components/CommonErrorModal/CommonErrorModal';
import GlobalStyle from '../../styles/Global/GlobalStyle';
import theme from '../../styles/Themes';

const LiveShare = () => {
  const [loadingOpener, setLoadingOpener] = useState(false);
  const [errorOpener, setErrorOpener] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const { liveShareData } = useGetLiveShareData({
    setLoadingOpener,
    setIsSuccess,
    setErrorMessage,
    setErrorOpener,
  });

  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <LiveShareContent
          liveShareData={liveShareData}
          setMessageModalOpener={setErrorOpener}
          setMessageModalMessage={setErrorMessage}
        />
        {/* Loading Openr */}
        {loadingOpener && (
          <CommonLoading open={loadingOpener} setOpen={setLoadingOpener} />
        )}
        {/* Error Opener */}
        {errorOpener && (
          <CommonErrorModal
            open={errorOpener}
            setIsOpen={setErrorOpener}
            message={errorMessage}
            isSuccess={isSuccess}
            onOkClickHandler={() => setErrorOpener(false)}
          />
        )}
      </ThemeProvider>
    </>
  );
};

export default LiveShare;
