import handleQueryError from '../../../utils/Common/Handler/handleQueryError';
import mainDefaultAPI from '../api';

const patchMainColumn = async ({ category, patchData, userID }) => {
  try {
    const response = await mainDefaultAPI.patch(
      `${category}/user-info/${userID}`,
      patchData,
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchMainColumn;
