import React from 'react';
import usePostDetailEmail from '../../../../../../hooks/BCDetail/DetailInfo/Common/usePostDetailEmail';
import CommonButton from '../../../../../CommonButton/CommonButton';

import { PERM_BC_DETAIL_EMAIL_VIEW_EDIT } from '../../../../../../constants/Common/permission';
import checkPermission from '../../../../../../utils/Common/Router/Handler/checkPermission';

const EmailButtonComponent = ({ woNo, ...props }) => {
  const pageTitle = location?.pathname?.split('/')[3];

  const authenticated = checkPermission({
    permission: [PERM_BC_DETAIL_EMAIL_VIEW_EDIT],
  });

  const emailQuery = usePostDetailEmail({
    woNo,
    ...props,
  });

  const onEmailBtnClickHandler = ({ emailCategory, buttonRef }) => {
    const data = {
      name: emailCategory,
    };
    emailQuery.mutate({ woNo, data, buttonRef });
  };

  return (
    <div className="container">
      {authenticated && (
        <>
          <div className="content">E-mail</div>
          <CommonButton
            className="button-email"
            disabled={!authenticated}
            preventDupClick
            onClick={({ buttonRef }) =>
              onEmailBtnClickHandler({ emailCategory: 'po_sche', buttonRef })
            }
          >
            {pageTitle === 'van' || pageTitle === 'brokerage' ? 'P/U' : 'P/O'}{' '}
            SCHE
          </CommonButton>
          <CommonButton
            className="button-email"
            disabled={!authenticated}
            preventDupClick
            onClick={({ buttonRef }) =>
              onEmailBtnClickHandler({ emailCategory: 'dilv_sche', buttonRef })
            }
          >
            DILV SCHE
          </CommonButton>
          <CommonButton
            className="button-email"
            disabled={!authenticated}
            preventDupClick
            onClick={({ buttonRef }) =>
              onEmailBtnClickHandler({ emailCategory: 'pod_pol', buttonRef })
            }
          >
            POD/POL
          </CommonButton>
          <CommonButton
            className="button-email"
            disabled={!authenticated}
            preventDupClick
            onClick={({ buttonRef }) =>
              onEmailBtnClickHandler({ emailCategory: 'demurrage', buttonRef })
            }
          >
            DEMURRAGE
          </CommonButton>
          <CommonButton
            className="button-email"
            disabled={!authenticated}
            preventDupClick
            onClick={({ buttonRef }) =>
              onEmailBtnClickHandler({ emailCategory: 'waiting', buttonRef })
            }
          >
            WAITING
          </CommonButton>
          <CommonButton
            className="button-email"
            disabled={!authenticated}
            preventDupClick
            onClick={({ buttonRef }) =>
              onEmailBtnClickHandler({ emailCategory: 'perdiem', buttonRef })
            }
          >
            PERDIEM
          </CommonButton>
        </>
      )}
    </div>
  );
};

export default EmailButtonComponent;
