import React from 'react';
import { useSelector } from 'react-redux';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import HeaderButtons from '../../../components/TmsReport/Schedules/HeaderButtons';
import WeeklyTable from '../../../components/TmsReport/Schedules/WeeklyTable';
import useGetSchedule from '../../../hooks/TmsReport/Schedules/useGetSchedule';
import useControlQueries from '../../../hooks/TmsReport/Schedules/useControlQueries';
import useFilterStorage from '../../../hooks/Common/Filter/useFilterStorage';
import useControlWeeklyData from '../../../hooks/TmsReport/Schedules/useControlWeeklyData';

const Delivery = () => {
  const divList = useSelector(state => state?.common?.allDiv);

  const filterStorage = useFilterStorage({
    storageName: 'deliveryFilter',
  });

  const {
    setScheduleTypeQuery,
    setDivisionQuery,
    setCategoryQuery,
    setDateQuery,
    allQueries,
    weeklyDateQuery,
  } = useControlQueries({ divList, filterStorage });

  const querySetters = {
    setScheduleTypeQuery,
    setDivisionQuery,
    setCategoryQuery,
    setDateQuery,
  };

  const {
    isLoading: isLoadingWeekly,
    isFetching: isFetchingWeekly,
    scheduleData,
  } = useGetSchedule({
    type: 'dilv',
    weeklyDateQuery,
    ...allQueries,
  });

  const { weeklyData } = useControlWeeklyData({
    weeklyDateQuery,
    scheduleData,
  });

  return (
    <>
      <div className="flex flex-col pr-[40px] pb-[20px] h-full w-full">
        <HeaderButtons
          allQueries={allQueries}
          divList={divList}
          filterStorage={filterStorage}
          {...querySetters}
        />
        {Object?.keys?.(weeklyData)?.length > 0 && (
          <WeeklyTable
            pageType="dilv"
            weeklyData={weeklyData}
            allQueries={allQueries}
          />
        )}
      </div>
      <CommonLoading open={isLoadingWeekly || isFetchingWeekly} />
    </>
  );
};

export default Delivery;
