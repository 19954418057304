import React from 'react';

const DocumentHeader = () => {
  return (
    <div className="flex h-[35px] ml-[2].5px] items-center gap-x-[2.5px] my-[2px]">
      <div className="w-[174px] text-center pt-[8px] pb-[9px] text-[#222] text-[11px] font-[500]">
        DATE
      </div>
      <div className="w-[155px] text-center pt-[8px] pb-[9px] text-[#222] text-[11px] font-[500]">
        TIME
      </div>
      <div className="w-[771px] text-center pt-[8px] pb-[9px] text-[#222] text-[11px] font-[500]">
        DOCUMENT
      </div>
      <div className="w-[155px] text-center pt-[8px] pb-[9px] text-[#222] text-[11px] font-[500]">
        UPDATED BY
      </div>
      <div className="w-[92px] text-center pt-[8px] pb-[9px] text-[#222] text-[11px] font-[500]">
        CK
      </div>
      <div className="w-[202px] text-center pt-[8px] pb-[9px] text-[#222] text-[11px] font-[500]">
        BROWSE
      </div>
      <div className="w-[108px] text-center pt-[8px] pb-[9px] text-[#222] text-[11px] font-[500]">
        PDF
      </div>
      <div className="w-[108px] text-center pt-[8px] pb-[9px] text-[#222] text-[11px] font-[500]">
        SAVE
      </div>
    </div>
  );
};

export default DocumentHeader;
