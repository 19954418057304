export const MASTER_COMPANY_TYPE = {
  CO0001: 'Customer',
  CO0002: 'Vendor',
  CO0003: 'CNEE',
  CO0004: 'NGL',
};

export const MASTER_COMPANY_TYPE_DETAIL = {
  COC001: 'FF',
  COC002: 'SSL',
  COC003: 'BCO',
  COC004: 'NGL',
  COV001: 'Service Vendor',
  COV002: 'Non-Service Vendor',
};
