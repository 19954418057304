import commonDefaultAPI from '../../../Common/api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

const getWOChassisToList = async ({ queryString }) => {
  try {
    const response = await commonDefaultAPI.get(`wo-ch-to-list?${queryString}`);
    return response?.data;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getWOChassisToList;
