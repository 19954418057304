const handleBookingChange = ({
  e,
  selectProps,
  onChange,
  setBookingSequence,
  setValue,
  ...handlerProps
}) => {
  onChange?.(selectProps?.value);
  setBookingSequence?.(selectProps?.seq || null);
  setValue?.('bk_seq', selectProps?.seq || null);
};

export default handleBookingChange;
