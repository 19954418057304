import mapAPI from '../../api';

const getAuthToken = async () => {
  try {
    const response = await mapAPI.get(`/fleet-token`);
    const data = response?.data;
    return {
      token: data?.token,
      expiresInSeconds: data?.expiration,
    };
  } catch (error) {
    throw new Error(error);
  }
};

export default getAuthToken;
