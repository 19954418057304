import { createDropdownOptions } from '../../../utils/Common/createDropdownOptions';

export const categoryDropdownOptions = createDropdownOptions([
  'IMPORT',
  'EXPORT',
]);
export const tierDropdownOptions = createDropdownOptions([
  'T1',
  'T2',
  'T3',
  'T4',
  'T5',
]);
