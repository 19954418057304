import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import bcDetailKeys from '../../keys';
import getMobileMemo from '../../../../services/BCDetail/DetailInfo/Schedule/Get/getMobileMemo';

const useGetMobileMemo = ({
  open,
  seq,
  setMobileModalOpener,
  setMessageModalOpen,
  setMessageContent,
  setSelectedRowData,
}) => {
  const [mobileMemoData, setMobileMemoData] = useState(null);

  const query = useQuery({
    queryKey: bcDetailKeys?.mobileMemo({ seq }),
    queryFn: () => getMobileMemo({ seq }),
    refetchOnWindowFocus: false,
    enabled: !!seq && !!open,
  });

  useEffect(() => {
    if (query?.data) {
      setMobileMemoData(prev => query?.data?.data);
    }
  }, [query?.data]);

  useEffect(() => {
    if (query?.isError) {
      setMessageContent(query?.error?.message);
      setMessageModalOpen(true);
      setMobileModalOpener(false);
      setSelectedRowData(null);
    }
  }, [query?.isError]);

  return { ...query, mobileMemoData };
};

export default useGetMobileMemo;
