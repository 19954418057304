import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import convertCategoryToStandardCode from '../../../../utils/Dispatch/Common/Generator/convertCategoryToStandardCode';
import getURLSearchParamsObject from '../../../../utils/Dispatch/Common/Generator/getURLSearchParamsObject';
import dispatchDefaultAPI from '../../api';

const getButtonCount = async ({ paramQueries, yardParamQueries, category }) => {
  const params = getURLSearchParamsObject({
    category: convertCategoryToStandardCode(category),
    ...paramQueries,
    ...yardParamQueries,
  });
  try {
    const response = await dispatchDefaultAPI.get(`count`, {
      params,
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getButtonCount;
