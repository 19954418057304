import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { FormProvider, useForm } from 'react-hook-form';
import CommonButton from '../../../CommonButton/CommonButton';
import CompanyBasic from './CompanyBasic';
import CompanyDetail from './CompanyDetail';
import CommonLoading from '../../../CommonLoading/CommonLoading';
import usePostCompanyData from '../../../../hooks/Master/Company/usePostCompanyData';
import useUpdateCompanyData from '../../../../hooks/Master/Company/useUpdateCompanyData';
import handleReset from '../../../../utils/Master/Common/Handler/handleReset';
import { MASTER_COMPANY_TYPE } from '../../../../constants/Master/Company/masterCompanyStandardCodes';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';

const SideModalContent = ({
  viewPerm,
  editPerm,
  companyData,
  categoryOption,
  termsOption,
  companyIDOption,
  editMode,
  gridRef,
  ...props
}) => {
  const companyDetail = useForm({
    shouldUnregister: true,
  });

  const buttonRef = useRef(null);

  const userID = localStorage.getItem('userId');

  const [isEditable, setIsEditable] = useState();

  const [type, setType] = useState(() => {
    if (editMode) return MASTER_COMPANY_TYPE?.[companyData?.category];
    if (editPerm?.compEdit) return 'Customer';
    return 'CNEE';
  });

  useEffect(() => {
    setIsEditable(prev =>
      type === 'Customer' || type === 'Vendor'
        ? editPerm?.compEdit
        : editPerm?.cneeEdit,
    );
  }, [type]);

  const { isPending: isPostPending, mutate: postCompanyMutate } =
    usePostCompanyData({ companyDetail, ...props });
  const { isPending: isUpdatePending, mutate: updateCompanyMutate } =
    useUpdateCompanyData({ gridRef, ...props });

  const handleSubmit = submitData => {
    if (buttonRef?.current)
      updateButtonDisabled({ ref: buttonRef, disabled: true });
    if (editMode) updateCompanyMutate({ submitData, buttonRef });
    else postCompanyMutate({ submitData, buttonRef });
  };

  const commonProps = {
    companyData,
    editMode,
    isEditable,
    companyIDOption,
  };

  return (
    <div>
      {(isPostPending || isUpdatePending) && (
        <CommonLoading open={isPostPending || isUpdatePending} />
      )}
      <div
        className="pb-5"
        onBlur={() => {
          const currentDate = dayjs().format('MM/DD/YY');
          const dateInfo = `${userID}, ${currentDate}`;
          if (!editMode) companyDetail?.setValue('created_by', dateInfo);
          companyDetail?.setValue('updated_by', dateInfo);
        }}
      >
        <FormProvider {...companyDetail}>
          <form onSubmit={companyDetail.handleSubmit(handleSubmit)}>
            <CompanyBasic
              type={type}
              setType={setType}
              categoryOption={categoryOption}
              {...commonProps}
            />
            <CompanyDetail
              termsOption={termsOption}
              companyType={companyDetail?.watch('category')}
              {...commonProps}
            />
            {isEditable && (
              <div className="flex justify-center mt-[45px] gap-x-[30px] 2xl:pb-0">
                <div>
                  <CommonButton
                    onClick={() => {
                      handleReset(companyDetail);
                    }}
                    category="clear"
                  >
                    Clear
                  </CommonButton>
                </div>
                <div>
                  <CommonButton ref={buttonRef} type="submit" category="save">
                    Save
                  </CommonButton>
                </div>
              </div>
            )}
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default SideModalContent;
