import { COMMON_COUNTRY_CODE_SELECT_OPTION } from '../../../constants/Common/selectOption';

export const concatPhoneNumber = ({ country, phone, suppressISOCode }) => {
  if (country && phone && country.length > 0 && phone.length > 0) {
    return `${suppressISOCode ? '' : country}+${
      COMMON_COUNTRY_CODE_SELECT_OPTION?.find(item => item?.code === country)
        ?.phone
    }.${phone}`;
  }
  return '';
};

export const splitPhoneNumber = ({ phone }) => {
  if (phone && phone.length > 0) {
    const [tempCountryCode, tempPhoneNumber] = phone.split('+');

    const countryCode = tempCountryCode?.replace('+', '');
    const phoneNumber = tempPhoneNumber?.split('.')?.[1];
    return { country: countryCode, phone: phoneNumber };
  }
  return { country: 'US', phone: '' };
};

export const makePrettyPhoneNumber = ({ phone }) => {
  if (phone && phone.length > 0) {
    const { country, phone: phoneNumber } = splitPhoneNumber({ phone });
    return `+${
      COMMON_COUNTRY_CODE_SELECT_OPTION?.find(item => item?.code === country)
        ?.phone
    } ${phoneNumber}`;
  }
  return '';
};
