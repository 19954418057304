import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthRouter from '../../../../../components/Common/AuthRouter';
import Delivery from '../../../../../pages/TmsReport/Delivery';
import Demurrage from '../../../../../pages/TmsReport/Demurrage';
import EarlyWarningList from '../../../../../pages/TmsReport/EarlyWarningList';
import Perdiem from '../../../../../pages/TmsReport/Perdiem';
import PullOut from '../../../../../pages/TmsReport/PullOut';
import Return from '../../../../../pages/TmsReport/Return';
import Statistics from '../../../../../pages/TmsReport/Statistics';
import getAuthorizedMenu from '../../../../../utils/Common/Sidebar/Handler/getAuthorizedMenu';
import { SIDEBAR_SUB_MENU } from '../../../Option/Sidebar';
import {
  PERM_TMS_REPORT_DEMURRAGE,
  PERM_TMS_REPORT_DILV,
  PERM_TMS_REPORT_EARLY_WARNING,
  PERM_TMS_REPORT_PERDIEM,
  PERM_TMS_REPORT_PO,
  PERM_TMS_REPORT_RTN,
  PERM_TMS_REPORT_STATISTICS,
} from '../../../permission';

const TMS_REPORT_CHILDREN_ROUTE = [
  {
    index: true,
    element: (
      <Navigate
        to={
          getAuthorizedMenu({
            allMenu: SIDEBAR_SUB_MENU?.[4],
          })?.[0]?.to
        }
      />
    ),
  },
  {
    path: 'pull-out/*',
    element: (
      <AuthRouter permission={PERM_TMS_REPORT_PO}>
        <PullOut />
      </AuthRouter>
    ),
  },
  {
    path: 'delivery/*',
    element: (
      <AuthRouter permission={PERM_TMS_REPORT_DILV}>
        <Delivery />
      </AuthRouter>
    ),
  },
  {
    path: 'return/*',
    element: (
      <AuthRouter permission={PERM_TMS_REPORT_RTN}>
        <Return />
      </AuthRouter>
    ),
  },
  {
    path: 'demurrage/*',
    element: (
      <AuthRouter permission={PERM_TMS_REPORT_DEMURRAGE}>
        <Demurrage />
      </AuthRouter>
    ),
  },
  {
    path: 'perdiem/*',
    element: (
      <AuthRouter permission={PERM_TMS_REPORT_PERDIEM}>
        <Perdiem />
      </AuthRouter>
    ),
  },
  {
    path: 'statistics/*',
    element: (
      <AuthRouter permission={PERM_TMS_REPORT_STATISTICS}>
        <Statistics />
      </AuthRouter>
    ),
  },
  {
    path: 'early-warning-list/*',
    element: (
      <AuthRouter permission={PERM_TMS_REPORT_EARLY_WARNING}>
        <EarlyWarningList />
      </AuthRouter>
    ),
  },
];

export default TMS_REPORT_CHILDREN_ROUTE;
