import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import divBasicDefaultApi from '../../divApi';

const patchBGColor = async ({ div, type, color }) => {
  try {
    const response = await divBasicDefaultApi.patch(`${div}/bg-color`, {
      type,
      color,
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchBGColor;
