import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';
import bookingDefaultAPI from '../../../bookingAPI';

const patchDeleteBooking = async ({ bookingSeq }) => {
  try {
    const response = await bookingDefaultAPI.patch(
      `detail/delete/${bookingSeq}`,
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchDeleteBooking;
