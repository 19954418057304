import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import CommonButton from '../../../components/CommonButton/CommonButton';
import CommonDataGrid from '../../../components/CommonDataGrid/index';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import { GRID_MOD_BOTH } from '../../../constants/Common/DataGrid/setting';
import useDeleteChassisRateMutation from '../../../hooks/Master/ChassisRate/useDeleteChassisRateMutation';
import useGetChassisRateList from '../../../hooks/Master/ChassisRate/useGetChassisRateList';
import usePostChassisRateMutation from '../../../hooks/Master/ChassisRate/usePostChassisRateMutation';
import useUpdateChassisRateMutation from '../../../hooks/Master/ChassisRate/useUpdateChassisRateMutation';
import useGetCustomerList from '../../../hooks/Master/Common/useGetCustomerList';
import useGetNglCneeList from '../../../hooks/Master/Common/useGetNglCneeList';
import StyledMasterCommonButtonContainer from '../../../styles/Master/Common/DataGrid/StyledMasterCommonButtonContainer';
import StyledMasterCommonDataGridContainer from '../../../styles/Master/Common/DataGrid/StyledMasterCommonDataGridContainer';
import exportToExcel from '../../../utils/Common/Datagrid/Handler/exportToExcel';
import createMasterChassisRateColDefs from '../../../utils/Master/ChassisRate/Generator/createMasterChassisRateColDefs';
import createCompanyDropdown from '../../../utils/Master/Common/createCompanyDropdown';
import mutationHandler from '../../../utils/Master/Common/mutationHandler';
import setResultData from '../../../utils/Master/Common/setResultData';
import TotalBottomBar from '../../../components/Common/CommonTotalBottomBar';
import useControlTotalInfo from '../../../hooks/Common/TotalBottomRow/useControlTotalInfo';

const ChassisRate = () => {
  const div = useSelector(state => state.nowDiv);
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isMessageOpen, setIsMessageOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [nglCneeDropdownList, setNglCneeDropdownList] = useState([]);
  const [customerDropdownList, setCustomerDropdownList] = useState([]);

  const gridRef = useRef(null);

  const {
    loadingChassisRateList,
    chassisRateList,
    isRefetching,
    errorChassisRateList,
  } = useGetChassisRateList({ div });
  const { loadingNglCneeList, nglCneeList, errorNglCneeList } =
    useGetNglCneeList();
  const { loadingCustomerList, customerList, errorCustomerList } =
    useGetCustomerList();

  const updateChassisRateMutation = useUpdateChassisRateMutation({
    setIsMessageOpen,
    setMessage,
    div,
    setIsSuccess,
    gridRef,
  });
  const postChassisRateMutation = usePostChassisRateMutation({
    setIsMessageOpen,
    setMessage,
    gridRef,
    div,
    setIsSuccess,
  });
  const deleteChassisRateMutation = useDeleteChassisRateMutation({
    setIsMessageOpen,
    setMessage,
    div,
    setIsSuccess,
  });

  const [columnDefs, setColumnDefs] = useState(
    createMasterChassisRateColDefs(nglCneeDropdownList, customerDropdownList),
  );

  const { totalRows, setChangeFlag } = useControlTotalInfo({
    gridRef,
    queryData: list,
  });

  useEffect(() => {
    setColumnDefs(
      createMasterChassisRateColDefs(nglCneeDropdownList, customerDropdownList),
    );
  }, [nglCneeDropdownList, customerDropdownList]);

  useEffect(() => {
    if (errorChassisRateList || errorNglCneeList || errorCustomerList) {
      setIsSuccess(false);
      setMessage('Error occurred while fetching data.');
      setIsMessageOpen(true);
    }

    if (
      !loadingChassisRateList &&
      !loadingNglCneeList &&
      !loadingCustomerList
    ) {
      const companyList = {};
      companyList.data = [...nglCneeList?.data, ...customerList?.data];
      setNglCneeDropdownList(createCompanyDropdown(nglCneeList));
      setCustomerDropdownList(createCompanyDropdown(customerList));
      setResultData(setList, setIsLoading, chassisRateList, companyList);
    }
  }, [
    loadingChassisRateList,
    loadingNglCneeList,
    loadingCustomerList,
    isRefetching,
  ]);

  return (
    <>
      <CommonLoading open={isLoading} />
      {!isSuccess && (
        <CommonErrorModal
          open={isMessageOpen}
          setIsOpen={setIsMessageOpen}
          message={message}
          isSuccess={isSuccess}
        />
      )}
      {!isLoading && (
        <div className="flex flex-col h-full">
          <StyledMasterCommonButtonContainer>
            <CommonButton
              onClick={() =>
                exportToExcel({ gridRef, fileName: 'CHASSIS RATE' })
              }
              type="button"
              category="excel"
            >
              EXCEL
            </CommonButton>
          </StyledMasterCommonButtonContainer>
          <StyledMasterCommonDataGridContainer>
            <CommonDataGrid
              addable
              modType={GRID_MOD_BOTH}
              gridRef={gridRef}
              data={list}
              columnDefs={columnDefs}
              onSaveClicked={props =>
                mutationHandler(updateChassisRateMutation, props, false)
              }
              onAddClicked={props =>
                mutationHandler(postChassisRateMutation, props, false)
              }
              onDeleteClicked={props =>
                mutationHandler(deleteChassisRateMutation, props, true)
              }
              onFilterChanged={() => {
                setChangeFlag(prev => !prev);
              }}
              onSelectionChanged={() => {
                setChangeFlag(prev => !prev);
              }}
            />
            <TotalBottomBar totalRows={totalRows} />
          </StyledMasterCommonDataGridContainer>
        </div>
      )}
    </>
  );
};

export default ChassisRate;
