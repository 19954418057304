import React from 'react';
import StyledScheduleAPModalTableContentRowCell from '../../../../../../../styles/BCDetail/DetailInfo/Schedule/StyledScheduleAPModalTableContentRowCell';

const ScheduleAPModalTableContentRowCell = ({
  children,
  customStyle,
  ...props
}) => {
  return (
    <StyledScheduleAPModalTableContentRowCell {...customStyle} {...props}>
      {children}
    </StyledScheduleAPModalTableContentRowCell>
  );
};

export default ScheduleAPModalTableContentRowCell;
