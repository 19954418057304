import { useQuery } from '@tanstack/react-query';
import { useState, useEffect } from 'react';
import adminKeys from '../../keys';
import getAuthList from '../../../../services/Admin/User/Get/getAuthList';

const useGetAuthList = ({
  setIsSuccess,
  setIsOpenErrorModal,
  setErrorMessage,
}) => {
  const { data, isError, error } = useQuery({
    queryKey: adminKeys.groupAuthList(),
    queryFn: () => getAuthList(),
    staleTime: 0,
    refetchOnWindowFocus: false,
  });

  const [authList, setAuthList] = useState([]);

  useEffect(() => {
    setAuthList(data?.data);
  }, [data?.data]);

  useEffect(() => {
    if (isError) {
      setIsSuccess(false);
      setIsOpenErrorModal(true);
      setErrorMessage(error?.message);
    }
  }, [isError]);

  return { authList };
};

export default useGetAuthList;
