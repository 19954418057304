import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';
import serviceDefaultApi from '../../../../api';

/**
 * @description
 * API that convert schedule type
 * LIVE -> DROP / DROP -> LIVE
 * @param {string} woNo
 * Target work order number
 * @param {string} scheType
 * Schedule type to be replaced.
 * If scheType is 'LIVE', schedule type would be replaced to 'LIVE'.
 * @returns
 */
const patchConvertScheduleType = async ({ woNo, scheType }) => {
  try {
    const response = await serviceDefaultApi.patch(
      `wo/${woNo}/schedule-type`,
      {
        sche_type: scheType,
      },
      {
        params: {
          page: 'bcdetail',
        },
      },
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchConvertScheduleType;
