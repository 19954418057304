import React, { useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import CommonButton from '../../../../CommonButton/CommonButton';
import CommonFile from '../../../../Inputs/CommonFile/CommonFile';
import CommonTextInput from '../../../../SecondInputs/CommonTextInput/CommonTextInput';
import handlePDFClick from '../../../../../utils/BCDetail/Document/Handler/handlePDFClick';
import handleRowAdd from '../../../../../utils/BCDetail/Document/Handler/handleRowAdd';
import CommonRowHeader from '../../../../SecondInputs/CommonRowHeader.jsx/CommonRowHeader';
import StyledDisabledField from '../../../../../styles/BCDetail/Document/StyledDisabledField';
import CommonCheckbox from '../../../../Inputs/CommonCheckbox/CommonCheckbox';

const DocumentAddRow = ({ id, isEditable, ...props }) => {
  const [fileURL, setFileURL] = useState('');

  const { handleSubmit, resetField, setValue } = useFormContext();

  const buttonRef = useRef(null);

  const watchType = useWatch({ name: `${id}.type` });
  const watchFile = useWatch({ name: `file.${id}.newRow` });

  useEffect(() => {
    if (watchFile) {
      setFileURL(URL.createObjectURL(watchFile));
    } else if (watchFile === null) {
      setValue(`${id}.file_url`, '');
      setFileURL('');
    }
  }, [watchFile]);

  return (
    <form
      onSubmit={handleSubmit(submitData =>
        handleRowAdd({ submitData, resetField, id, buttonRef, ...props }),
      )}
    >
      <div className="flex items-center gap-x-[2.5px] mb-[2px] w-full">
        {/* Date */}
        <div className="w-[174px] h-[30px]">
          <CommonRowHeader content="MM/DD/YY" disabledTheme />
        </div>
        {/* Time */}
        <div className="w-[155px] h-[30px]">
          <StyledDisabledField>hh:mm</StyledDisabledField>
        </div>
        {/* Document */}
        <div className="w-[771px] h-[30px]">
          <CommonTextInput
            backgroundColor="#DDE2EC"
            inputName={`${id}.type`}
            disabled={!isEditable}
            onChangeHandler={({ e, onChange }) => {
              onChange(e?.target?.value);
            }}
          />
        </div>
        {/* Updated by */}
        <div className="w-[155px] h-[30px] items-center">
          <StyledDisabledField className="bg-[#CCD3E3]" />
        </div>
        {/* CK */}
        <div className="w-[92px] h-[30px] bg-[#DDE2EC] shadow-[0_0.5px_1px_rgba(0,0,0,0.26)]">
          <CommonCheckbox
            inputName={`${id}.checkbox`}
            labelVisible={false}
            options={[
              {
                defaultValue: false,
                inputName: `${id}.checked`,
              },
            ]}
            onChangeHandler={({ e, onChange }) => {
              onChange(e?.target?.checked);
            }}
            disabled={!isEditable}
          />
        </div>
        {/* Browse */}
        <div className="w-[202px] h-[30px] button:text-[#264B9F]">
          <CommonFile
            inputName={`file.${id}.newRow`}
            labelVisible={false}
            disabled={!watchType}
          />
        </div>
        {/* PDF */}
        <div className="w-[108px] h-[30px]">
          <CommonButton
            category="pdf"
            disabled={!fileURL}
            className={`w-full h-full  flex items-center 
                          ${
                            fileURL
                              ? 'border-[#EAC5C5] hover:border-[#C24D4D]'
                              : ' border-[#DADADA] bg-[#DADADA]'
                          }
                          `}
            onClick={({ e }) => handlePDFClick(e, fileURL)}
          >
            <div className="pt-[1px] pr-[4px] w-full">PDF</div>
          </CommonButton>
        </div>
        {/* SAVE */}
        <div className="w-[108px] h-[30px]">
          <CommonButton
            ref={buttonRef}
            category="save"
            disabled={!watchType}
            type="submit"
            className={`w-full h-full  flex items-center 
                          ${!watchType && ' border-[#DADADA] bg-[#DADADA]'}`}
          >
            <div className="pt-[1px] pr-[4px] w-full">SAVE</div>
          </CommonButton>
        </div>
      </div>
    </form>
  );
};

export default DocumentAddRow;
