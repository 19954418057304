import React, { useEffect, useState } from 'react';
import { PATH_SIGN_IN_BUTTON } from '../../constants/Common/path';
import { REDIRECT_URI } from '../../constants/SignIn/elements';

const SignInButton = ({ setCode }) => {
  const [popup, setPopup] = useState(null);

  const handleLogin = () => {
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const redirectUri = REDIRECT_URI;
    const scope = 'email profile';
    const responseType = 'code';
    const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}`;

    const width = 500;
    const height = 600;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    const popupWindow = window.open(
      googleAuthUrl,
      'Google Login',
      `width=${width},height=${height},top=${top},left=${left},toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=no`,
    );

    setPopup(popupWindow);
  };

  useEffect(() => {
    if (!popup) return;

    const interval = setInterval(() => {
      try {
        if (popup?.location?.href?.includes('code=')) {
          const url = new URL(popup.location.href);
          const code = url.searchParams.get('code');
          setCode(code);
          clearInterval(interval);
          popup.close();
        }
      } catch (e) {
        // Ignoring cross-origin errors
      }
    }, 100);

    const cleanup = () => {
      clearInterval(interval);
    };

    popup.onbeforeunload = cleanup;
  }, [popup]);

  return (
    <button data-testid="googleLoginButton" type="button" onClick={handleLogin}>
      <img src={PATH_SIGN_IN_BUTTON} alt="Google Sign In Button" />
    </button>
  );
};

export default SignInButton;
