export const PATCH_COLUM_LIST = [
  'imp',
  'exp',
  'van',
  'brok',
  'bare',
  'left_level_1',
  'left_level_2',
  'left_level_3',
  'has_cur_level',
  'passed_level_1',
  'passed_level_2',
  'passed_level_3',
];

export const BOOLEAN_COLUMN_LIST = [
  'imp',
  'exp',
  'van',
  'brok',
  'bare',
  'has_cur_level',
];

export const NUMBER_COLUMN_LIST = [
  'left_level_1',
  'left_level_2',
  'left_level_3',
  'passed_level_1',
  'passed_level_2',
  'passed_level_3',
];

export const LEFT_LEVEL_COLUMN = [
  'left_level_1',
  'left_level_2',
  'left_level_3',
];

export const PASSED_LEVEL_COLUMN = [
  'passed_level_1',
  'passed_level_2',
  'passed_level_3',
];
