const handleDriverTypeGrouping = ({
  selectedRow,
  data,
  setValue,
  setIsQBDisabled,
  checkedItems,
}) => {
  let groupItemChecked = false;

  data.forEach(element => {
    const hasSameGroup =
      selectedRow?.group_id && element?.group_id === selectedRow?.group_id;
    const hasSameConditions =
      element?.category === selectedRow?.category &&
      element?.bill_date === selectedRow?.bill_date &&
      element?.driver === selectedRow?.driver;
    const isGroupItem =
      element?.group_id !== null && element?.group_id !== undefined;

    if (hasSameGroup || (!isGroupItem && hasSameConditions)) {
      // Check items with the same group_id or, if no group_id, items with the same category, bill_date, driver
      checkedItems?.add(element?.seq);
      setValue(`mod.checked_${element?.seq}`, true);
      if (isGroupItem) groupItemChecked = true;
    } else if (!selectedRow?.group_id && selectedRow?.seq === element?.seq) {
      // Individually check an item without a group_id
      checkedItems?.add(element?.seq);
      setValue(`mod.checked_${element?.seq}`, true);
    } else if (!hasSameConditions) {
      // Uncheck items that do not meet the hasSameConditions criteria
      checkedItems?.delete(element?.seq);
      setValue(`mod.checked_${element?.seq}`, false);
    } else if (isGroupItem && groupItemChecked) {
      // Uncheck other items with a group_id, keeping only one checked
      setValue(`mod.checked_${element?.seq}`, false);
    } else if (!checkedItems?.has(element?.seq)) {
      // Uncheck other items that are not already checked
      setValue(`mod.checked_${element?.seq}`, false);
    } else {
      // Maintain the state of previously checked items
      setValue(`mod.checked_${element?.seq}`, true);
    }
  });

  const hasItemWithoutBillNo = Array.from(checkedItems).some(
    seq => !data.find(item => item.seq === seq)?.bill_no,
  );

  setIsQBDisabled(hasItemWithoutBillNo);
};

const handleVendorTypeGrouping = ({
  selectedRow,
  data,
  setValue,
  setIsQBDisabled,
}) => {
  setIsQBDisabled(true);
  if (selectedRow?.bill_no) {
    setIsQBDisabled(false);
    data?.forEach(element => {
      if (
        element?.category === selectedRow?.category &&
        element?.vendor === selectedRow?.vendor &&
        element?.bill_no === selectedRow?.bill_no &&
        element?.group_id === selectedRow?.group_id
      ) {
        setValue(`mod.checked_${element?.seq}`, true);
      } else {
        setValue(`mod.checked_${element?.seq}`, false);
      }
    });
  }
};

const onAPGroupingHandler = ({
  selectedRow,
  data,
  setValue,
  apType,
  setIsQBDisabled,
  setIsCreateBillNoDisabled,
  checkedItems,
}) => {
  if (apType === 'DRIVER') {
    setIsCreateBillNoDisabled(false);
    handleDriverTypeGrouping({
      selectedRow,
      data,
      setValue,
      apType,
      setIsQBDisabled,
      checkedItems,
    });
  } else if (apType === 'VENDOR') {
    setIsCreateBillNoDisabled(true);
    handleVendorTypeGrouping({
      selectedRow,
      data,
      setValue,
      apType,
      setIsQBDisabled,
    });
  }
};

export default onAPGroupingHandler;
