export const CELL_CUSTOM_STYLE = {
  date: {
    textAlign: 'left',
  },
  time: {
    textAlign: 'left',
  },
  number: {
    textAlign: 'right',
  },
  button: {
    textAlign: 'center',
  },
  checkBox: {
    textAlign: 'center',
  },
};
