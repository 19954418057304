import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';
import billingInfoDefaultAPI from '../../api';

const getJobAssign = async ({ woNo, driverID }) => {
  try {
    const response = await billingInfoDefaultAPI.get(`${woNo}/job/${driverID}`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getJobAssign;
