import { ACCT_PAGE } from '../../../../constants/Acct/Common/staticNames';

const generateQueryParamObject = ({ queryObject, page }) => {
  const queryParam = { ...queryObject };

  /** From Date */
  if (queryParam?.from_date) {
    queryParam[`${queryParam?.dateType}_from`] = queryParam?.from_date;
  }

  /** To Date */
  if (queryParam?.to_date) {
    queryParam[`${queryParam?.dateType}_to`] = queryParam?.to_date;
  }

  /** Category */
  if (queryParam?.category && queryParam?.keyword) {
    queryParam[queryParam?.category] = queryParam?.keyword;
  }

  /** Status */
  if (page === ACCT_PAGE?.unInvoiced) {
    delete queryParam?.status;
  } else {
    queryParam.status = queryParam?.status === 'ALL' ? '' : queryParam?.status;
  }

  if (queryParam?.['driver-type'] === 'ALL') {
    queryParam['driver-type'] = '';
  }

  /** Remove Unnecessary Item */
  delete queryParam?.dateType;
  delete queryParam?.period;
  delete queryParam?.from_date;
  delete queryParam?.to_date;
  delete queryParam?.keyword;

  return queryParam;
};

export default generateQueryParamObject;
