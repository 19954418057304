import { useQuery } from '@tanstack/react-query';
import get322List from '../../../services/EDI/Get/get322List';
import ediKeys from '../keys';

const useGet322List = ({ div, from, to, receiver }) => {
  const {
    isLoading: isLoading322List,
    data: list322Data,
    isRefetching: isRefetching322List,
  } = useQuery({
    queryKey: ediKeys?.get322List({ div, from, to, receiver }),
    queryFn: () => get322List({ div, from, to, receiver }),
    refetchOnWindowFocus: false,
  });

  return {
    isLoading322List,
    list322Data,
    isRefetching322List,
  };
};

export default useGet322List;
