import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  memo,
} from 'react';
import { TextField } from '@mui/material';
import StyledEditCellRenderer from '../../../../../styles/Common/CommonDatagrid/EditCellRenderer/StyledEditCellRenderer';
import adornmentContentGenerator from '../../../../../utils/Common/Datagrid/Generator/adornmentContentGenerator';

/**
 * @description
 * Text modification cell renderer
 * @param {object} props
 * Grid props
 * @param {object} ref
 * Grid reference
 */
const ModTextCell = memo(
  forwardRef((props, ref) => {
    /** Get grid parameter */
    const {
      adornment,
      regex,
      maxLength: inputMaxLength,
      onChangeHandler,
    } = props;

    /** Input inner component reference */
    const compRef = useRef(null);

    /** Input component property */
    const [value, setValue] = useState(props?.value || '');
    const [maxLength, setMaxLength] = useState(inputMaxLength || 20);

    /** Component data setter */
    const compDataSetter = {
      setValue,
      setMaxLength,
    };

    /**
     * Component Editor Lifecycle methods
     * @see https://www.ag-grid.com/react-data-grid/component-cell-editor/
     */
    useImperativeHandle(ref, () => {
      return {
        /** The final value to send to the grid, on completion of editing */
        getValue() {
          return value;
        },
        isCancelBeforeStart() {
          return false;
        },
        isCancelAfterEnd() {
          return false;
        },
        ref,
        ...compDataSetter,
        ...props,
      };
    });

    /** Render component */
    return (
      <StyledEditCellRenderer>
        <TextField
          ref={compRef}
          value={value || ''}
          onChange={e => {
            if (e) {
              const getChangedValue = e?.target?.value;
              if (regex && !getChangedValue.match(regex)) return;
              setValue?.(getChangedValue);
              onChangeHandler?.({ getChangedValue, ...props });
            }
          }}
          InputProps={{
            startAdornment:
              adornment?.position === 'start' &&
              adornmentContentGenerator({ adornment }),
            endAdornment:
              adornment?.position === 'end' &&
              adornmentContentGenerator({ adornment }),
            inputProps: {
              maxLength,
              autoComplete: 'off',
            },
          }}
          /** Component Style */
          className={`border-solid border-[#D9D9D9] rounded-[5px] text-xs text-black w-[164px] h-[24px]
                    hover:border-[#8E9396]`}
          sx={{
            input: {
              zIndex: '999',
            },
            '.MuiInputBase-root': {
              height: '100%',
              fontSize: '12px',
              fontWeight: '500',
              '&.Mui-focused fieldset': {
                border: '1.2px solid #264B9F !important',
                boxShadow: '0px 0px 4px 0px #8BBCE9 !important',
              },
              '&.Mui-error fieldset': {
                border: '1.2px solid #C24D4D !important',
              },
              '&:not(.Mui-focused):not(.Mui-disabled):hover fieldset': {
                borderColor: '#8E9396 !important',
              },
              '&.Mui-disabled fieldset': {
                backgroundColor: 'unset !important',
              },
              '.MuiInputBase-input.MuiOutlinedInput-input': {
                border: 'none !important',
              },
            },
            '.MuiTypography-root': {
              fontSize: '12px',
            },
            fieldset: {
              borderColor: '#D9D9D9 !important',
              backgroundColor: `#fff !important`,
            },
          }}
        />
      </StyledEditCellRenderer>
    );
  }),
);

export default ModTextCell;
