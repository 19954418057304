import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormHelperText, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import StyledCommonInputRequiredLabel from '../../../styles/Common/CommonInput/StyledCommonInputRequiredLabel';
import StyledCommonInputWrapper from '../../../styles/Common/CommonInput/StyledCommonInputWrapper';
import StyledCommonLabel from '../../../styles/Common/CommonInput/StyledCommonLabel';
import defaultInputRules from '../../../utils/Common/Input/defaultInputRules';

const CommonSelect = ({ ...props }) => {
  /**
   * @description
   * Get react-hook-form object from useFormContext.
   * @control Must-have elements for getting values from a form.
   * @unregister Allows to unregister a single input or an array of inputs.
   * @setValue Setter value of input.
   * @formState Object containing information about the form.
   */
  const {
    control,
    unregister,
    setValue,
    formState: { errors: formErrors },
    clearErrors,
  } = useFormContext();

  /**
   * @option {array} - Option of select.
   * @label {string} - Label of input.
   * @disabled {boolean} - Disable input.
   * @required {boolean} - Required input.
   */

  // Select option of component. Default is empty list.
  const [option, setOption] = useState(props?.option || []);
  useEffect(() => {
    if (props?.option) {
      setOption(props?.option);
    }
  }, [props?.option]);

  const [label, setLabel] = useState(props?.label);
  useEffect(() => {
    setLabel(prev => props?.label);
  }, [props?.label]);

  // Set default input value as empty string ('').
  const [defaultValue, setDefaultValue] = useState(props?.defaultValue || '');
  useEffect(() => {
    /**
     * If default value changed, component default value would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.defaultValue !== undefined && props?.defaultValue !== null) {
      setDefaultValue(props?.defaultValue);
      setValue(props?.inputName, props?.defaultValue);
    }
  }, [props?.defaultValue]);

  // Set disabled for input. Default is false.
  const [disabled, setDisabled] = useState(props?.disabled || false);
  useEffect(() => {
    /**
     * If default disabled value changed, component disabled value would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.disabled !== undefined && props?.disabled !== null) {
      setDisabled(props?.disabled);
    }
  }, [props?.disabled]);

  // Set default required for inpt with false.
  const [required, setRequired] = useState(props?.required || false);
  useEffect(() => {
    /**
     * If default required value changed, component required value would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.required !== undefined && props?.required !== null) {
      setRequired(props?.required);
    }
  }, [props?.required]);

  const [backgroundColor, setBackgroundColor] = useState(
    props?.backgroundColor,
  );
  useEffect(() => {
    if (props?.backgroundColor) {
      setBackgroundColor(props?.backgroundColor);
    }
  }, [props?.backgroundColor]);

  useEffect(() => {
    /**
     * Unregister input when component unmount.
     * Unregister single input only.
     */
    return () => unregister(props?.inputName);
  }, []);

  // Clear error when required is false
  useEffect(() => {
    if (!required) {
      clearErrors(props?.inputName);
    }
  }, [required]);

  /**
   * Render select component.
   * @control - Must-have elements for getting values from a form.
   * @name - Name of input.
   * @defaultValue - Default value of input.
   * @rules - Rules of input.
   * @render - Render target select component.
   * - field : Object containing field props, like name, value, onChange, onBlur.
   * - fieldState : Object containing field state, like error, invalid, etc.
   */
  return (
    <Controller
      control={control}
      name={props?.inputName}
      defaultValue={defaultValue}
      rules={defaultInputRules({ required, ...props })}
      render={({
        field: { ref, onChange, value, ...field },
        fieldState: { invalid, error },
      }) => {
        // Package of handler props
        const handlerProps = {
          onChange,
          value,
          ...field,
          ...props,
        };

        // Render Control Input Component
        return (
          <>
            <StyledCommonInputWrapper labelStyle={props?.labelStyle}>
              {(!!label || props.labelVisible !== false) && (
                <StyledCommonLabel
                  labelStyle={props?.labelStyle}
                  lbl-pd-right={props?.[`lbl-pd-right`]}
                >
                  {!!label && (
                    <>
                      {!!required && (
                        <StyledCommonInputRequiredLabel>
                          *
                        </StyledCommonInputRequiredLabel>
                      )}
                      {label}
                    </>
                  )}
                </StyledCommonLabel>
              )}
              <div className={`${props?.width ? `${props?.width}` : 'w-full'}`}>
                <Select
                  {...field}
                  inputRef={ref}
                  error={invalid}
                  value={value}
                  /**
                   * If renderValue is true, render option's value.
                   * If not, just render option's label
                   * ex) <Select renderValue={true} />
                   * */
                  renderValue={selected =>
                    props?.renderValue
                      ? selected
                      : option?.find(item => item?.value === selected)?.label ||
                        selected
                  }
                  /**
                   * onChange Handler. Only handlers of parameters work.
                   * To change value, should use Controller field's onChange changer.
                   * ex)
                   * (tempValue) => {
                   *    onChange(tempValue);
                   * }
                   */
                  onChange={(e, selectProps) =>
                    props?.onChangeHandler?.({
                      e,
                      selectProps,
                      ...handlerProps,
                    })
                  }
                  className={`border-solid border-[#D9D9D9] h-[30px] rounded-[5px] text-xs text-black w-full ${
                    disabled ? 'bg-[#F9F9F9]' : 'bg-white'
                  }`}
                  sx={{
                    // Select Arrow Icon
                    fontWeight: '500',
                    '.MuiSvgIcon-root': {
                      fontSize: '16px',
                      color: !disabled && '#264B9F !important',
                    },
                    '.Mui-disabled': {
                      WebkitTextFillColor: '#222 !important',
                    },
                    '.MuiSelect-select': {
                      paddingRight: '15px',
                    },
                    '&.MuiInputBase-root': {
                      '&:not(.Mui-focused):not(.Mui-disabled):hover fieldset':
                        !formErrors?.[props?.inputName]?.message && {
                          borderColor: '#8E9396 !important',
                        },
                      '&.Mui-focused fieldset': {
                        border: '1.2px solid #264B9F !important',
                        boxShadow: '0px 0px 4px 0px #8BBCE9 !important',
                      },
                      '&.Mui-error fieldset': {
                        border: '1.2px solid #C24D4D !important',
                      },
                    },
                    fieldset: {
                      borderColor: '#D9D9D9 !important',
                    },
                    backgroundColor: `${backgroundColor} !important`,
                  }}
                  defaultValue={
                    props?.defaultValue || props?.option?.[0]?.value
                  }
                  disabled={disabled}
                  // required={required}
                  MenuProps={{
                    disableScrollLock: true,
                    style: { zIndex: 9999 },
                  }}
                  IconComponent={ExpandMoreIcon}
                >
                  {option?.map((item, index) => {
                    return (
                      <MenuItem
                        value={item?.value}
                        key={String(index + 1)}
                        className="text-xs"
                        sx={{
                          backgroundColor: `${item?.value} !important`,
                        }}
                        {...item}
                      >
                        {item?.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            </StyledCommonInputWrapper>
            <FormHelperText className="text-[#ff2424] text-[11px] font-normal min-h-[11px] h-[11px] mb-[3px] leading-none">
              {error?.message}
            </FormHelperText>
          </>
        );
      }}
    />
  );
};

export default CommonSelect;
