import dayjs from 'dayjs';
import React from 'react';
import { DATE_OPTIONS } from '../../../../../../constants/EDI/options';
import CommonDate from '../../../../../Inputs/CommonDate/CommonDate';
import CommonSelect from '../../../../../Inputs/CommonSelect/CommonSelect';

const ModalContentMain = ({ searchTypeMain, searchFromMain, searchToMain }) => {
  return (
    <div className="w-[488px] h-[87px] flex justify-center items-center bg-[#F9F9F9]">
      <div className="mr-[20px] mt-[10px]">
        <CommonSelect
          label="DATE"
          inputName="type"
          width="w-[153px]"
          option={DATE_OPTIONS}
          defaultValue={searchTypeMain}
          onChangeHandler={({ e, onChange }) => {
            onChange?.(e);
          }}
        />
      </div>
      <div className="mr-[5px] mt-[10px]">
        <CommonDate
          label="FROM"
          inputName="fromDate"
          width="w-[116px]"
          required
          defaultValue={searchFromMain}
          onChangeHandler={({ e, onChange }) => {
            const value = dayjs(e)?.isValid()
              ? dayjs(e)?.format('YYYY-MM-DD')
              : null;
            onChange?.(value);
          }}
        />
      </div>
      <div className="mt-[10px] flex items-center">
        <img
          src="/assets/images/common_icon/hyphenIcon.svg"
          alt="hyphen"
          width={13}
        />
      </div>
      <div className="ml-[5px] mt-[10px]">
        <CommonDate
          label="TO"
          inputName="toDate"
          width="w-[116px]"
          required
          defaultValue={searchToMain}
          onChangeHandler={({ e, onChange }) => {
            const value = dayjs(e)?.isValid()
              ? dayjs(e)?.format('YYYY-MM-DD')
              : null;
            onChange?.(value);
          }}
        />
      </div>
    </div>
  );
};

export default ModalContentMain;
