import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import CommonDataGrid from '../../../components/CommonDataGrid';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import HeaderButtons from '../../../components/Master/Common/HeaderButtons';
import DeleteCodeConfirmModal from '../../../components/Master/ServiceCode/DeleteCodeConfirmModal';
import InUseCodeAlertModal from '../../../components/Master/ServiceCode/InUseCodeAlertModal';
import { GRID_MOD_BOTH } from '../../../constants/Common/DataGrid/setting';
import useDeleteServiceCode from '../../../hooks/Master/ServiceCode/useDeleteServiceCode';
import useGetAcctCode from '../../../hooks/Master/ServiceCode/useGetAcctCode';
import useGetServiceCode from '../../../hooks/Master/ServiceCode/useGetServiceCode';
import usePatchServiceCode from '../../../hooks/Master/ServiceCode/usePatchServiceCode';
import usePostServiceCode from '../../../hooks/Master/ServiceCode/usePostServiceCode';
import StyledMasterCommonDataGridContainer from '../../../styles/Master/Common/DataGrid/StyledMasterCommonDataGridContainer';
import defaultColumnDefsGenerator from '../../../utils/Common/Datagrid/Generator/defaultColumnDefsGenerator';
import createServiceCodeColumnDefs from '../../../utils/Master/ServiceCode/Generator/createServiceCodeColumnDefs';
import handleServiceCodeAdd from '../../../utils/Master/ServiceCode/Handler/handleServiceCodeAdd';
import handleServiceCodeDelete from '../../../utils/Master/ServiceCode/Handler/handleServiceCodeDelete';
import handleServiceCodeSave from '../../../utils/Master/ServiceCode/Handler/handleServiceCodeSave';
import TotalBottomBar from '../../../components/Common/CommonTotalBottomBar';
import useControlTotalInfo from '../../../hooks/Common/TotalBottomRow/useControlTotalInfo';

const ServiceCode = ({ isEditable }) => {
  const gridRef = useRef(null);
  const queryClient = useQueryClient();
  const division = useSelector(state => state.nowDiv);
  const defaultColumnDefs = defaultColumnDefsGenerator();

  const [isLoading, setIsLoading] = useState(true);

  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isInUseModal, setIsInUseModal] = useState(false);
  const [inUseWOList, setInUseWOList] = useState([]);
  const [deleteTarget, setDeleteTarget] = useState('');

  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [messageContents, setMessageContent] = useState('');

  const modalProps = {
    setMessageModalOpen,
    setMessageContent,
    queryClient,
    division,
  };

  const [pairOption, setPairOption] = useState([]);

  const { serviceCodeLoading, serviceCodeError, serviceCodeList } =
    useGetServiceCode();

  const { isLoading: acctCodeLoading, isError: acctCodeError } = useGetAcctCode(
    { setPairOption },
  );

  const addServiceCodeMutator = usePostServiceCode({
    gridRef,
    ...modalProps,
  });

  const { mutate: deleteServiceCodeMutator } = useDeleteServiceCode({
    setIsDeleteModal,
    setIsInUseModal,
    ...modalProps,
  });

  const patchServiceCodeMutator = usePatchServiceCode({
    gridRef,
    ...modalProps,
  });

  const [columDefs, setColumnDefs] = useState(
    createServiceCodeColumnDefs({
      pairOption,
      isEditable,
    }),
  );

  const { totalRows, setChangeFlag } = useControlTotalInfo({
    gridRef,
    queryData: serviceCodeList,
  });

  useEffect(() => {
    setColumnDefs(
      createServiceCodeColumnDefs({
        pairOption,
        isEditable,
      }),
    );
  }, [pairOption, isEditable]);

  useEffect(() => {
    const instances = gridRef?.current?.api?.getCellEditorInstances();
    const acctCodeNameInstance = instances?.find(
      element => element?.cellEditorParams?.column?.colId === 'acct_code_name',
    );
    const acctCodeInstance = instances?.find(
      element => element?.cellEditorParams?.column?.colId === 'acct_code',
    );
    acctCodeNameInstance?.componentInstance?.setOption?.(pairOption);
    acctCodeInstance?.componentInstance?.setOption?.(pairOption);
    acctCodeNameInstance?.componentInstance?.setIsLoading?.(acctCodeLoading);
    acctCodeInstance?.componentInstance?.setIsLoading?.(acctCodeLoading);
  }, [pairOption, acctCodeLoading]);

  useEffect(() => {
    setIsLoading(serviceCodeLoading || serviceCodeError || acctCodeError);
  }, [serviceCodeLoading, serviceCodeError, acctCodeError]);

  return (
    <>
      <CommonLoading open={isLoading} />
      <div className="flex flex-col h-full">
        <HeaderButtons gridRef={gridRef} fileName="SERVICE CODE" />
        {!isLoading && (
          <StyledMasterCommonDataGridContainer>
            <CommonDataGrid
              gridRef={gridRef}
              sideBar={false}
              data={serviceCodeList}
              columnDefs={columDefs}
              defaultColDef={defaultColumnDefs}
              modType={isEditable && GRID_MOD_BOTH}
              addable={isEditable}
              onAddClicked={params => {
                handleServiceCodeAdd(params, addServiceCodeMutator);
              }}
              onDeleteClicked={params =>
                handleServiceCodeDelete(
                  params,
                  setDeleteTarget,
                  setIsDeleteModal,
                )
              }
              onSaveClicked={params => {
                handleServiceCodeSave(params, patchServiceCodeMutator);
              }}
              onFilterChanged={() => {
                setChangeFlag(prev => !prev);
              }}
              onSelectionChanged={() => {
                setChangeFlag(prev => !prev);
              }}
            />
            <TotalBottomBar totalRows={totalRows} />
          </StyledMasterCommonDataGridContainer>
        )}
      </div>
      <DeleteCodeConfirmModal
        isDeleteModal={isDeleteModal}
        setIsDeleteModal={setIsDeleteModal}
        getDeleteTarget={deleteTarget?.data}
        deleteServiceCodeMutator={deleteServiceCodeMutator}
        setInUseWOList={setInUseWOList}
        division={division}
      />
      <InUseCodeAlertModal
        isInUseModal={isInUseModal}
        setIsInUseModal={setIsInUseModal}
        getDeleteTarget={deleteTarget?.data}
        inUseWOList={inUseWOList}
      />
      {messageModalOpen && (
        <CommonErrorModal
          open={messageModalOpen}
          message={messageContents}
          setIsOpen={setMessageModalOpen}
        />
      )}
    </>
  );
};

export default ServiceCode;
