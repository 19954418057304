import { useMutation } from '@tanstack/react-query';
import { SUCCESS_SEND } from '../../../constants/Common/successMessage';
import postLiveShare from '../../../services/Trace/Map/Post/postLiveShare';
import useControlToast from '../../Common/Toast/useControlToast';
import updateButtonDisabled from '../../../utils/Common/Handler/updateButtonDisabled';

const usePostLiveShareMutation = ({
  setIsErrorModalOpen,
  setErrorMessage,
  setIsSuccess,
  setValue,
}) => {
  const { addToast } = useControlToast();

  return useMutation({
    mutationFn: postLiveShare,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: res => {
      addToast({ message: SUCCESS_SEND });
      setValue('liveSharingUrl', res?.data?.data?.liveSharingUrl);
    },
    onError: error => {
      setIsSuccess(false);
      setErrorMessage(error.message);
      setIsErrorModalOpen(true);
    },
  });
};

export default usePostLiveShareMutation;
