import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';
import serviceDefaultApi from '../../../../api';

const getCompanyList = async () => {
  try {
    const response = await serviceDefaultApi.get(
      `/common/company-list?category=1&credit=all`,
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getCompanyList;
