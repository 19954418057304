import React, { useEffect, useState } from 'react';
import StyledCommonSideModal from '../../styles/Common/CommonSideModal/StyledCommonSideModal';
import CommonBlur from '../CommonBlur/CommonBlur';
import CommonSideModalHeader from './CommonSideModalHeader';
import CommonSideModalContent from './CommonSideModalContent';
import StyledCommonSideModalWrapper from '../../styles/Common/CommonSideModal/StyledCommonSideModalWrapper';

const CommonSideModal = ({
  open,
  setOpen,
  title,
  styles,
  children,
  ...props
}) => {
  return (
    open && (
      <CommonBlur open={open}>
        <div className="w-full h-full flex justify-end">
          <StyledCommonSideModal {...styles} className="animate-fade-in-right">
            <StyledCommonSideModalWrapper>
              <CommonSideModalHeader
                content={title}
                onCloseClick={() => setOpen?.(false)}
              />
              <CommonSideModalContent {...styles}>
                {children}
              </CommonSideModalContent>
            </StyledCommonSideModalWrapper>
          </StyledCommonSideModal>
        </div>
      </CommonBlur>
    )
  );
};

export default CommonSideModal;
