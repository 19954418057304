import { NULL_PARAMETER_VALUE } from '../../../../constants/Acct/Common/staticNames';

const handleWOChange = ({
  e,
  onChange,
  woObjectList,
  changeFieldNameList,
  setValue,
  sslList,
  setWOQueryObject,
  ...props
}) => {
  const woNo = props?.selectProps?.value;

  let woData;
  changeFieldNameList?.forEach(changeFieldName => {
    switch (changeFieldName) {
      case 'from':
        woData = woObjectList?.find(
          woObject => woObject?.woNo === woNo,
        )?.woFromDate;
        break;
      case 'to':
        woData = woObjectList?.find(
          woObject => woObject?.woNo === woNo,
        )?.woToDate;
        break;
      case 'div':
        woData = woObjectList?.find(woObject => woObject?.woNo === woNo)?.woDiv;
        break;
      case 'bk_no':
        woData = woObjectList?.find(
          woObject => woObject?.woNo === woNo,
        )?.woBkNo;
        break;
      case 'st':
        woData = woObjectList?.find(woObject => woObject?.woNo === woNo)?.woSt;
        break;
      case 'ssl':
        woData = woObjectList?.find(woObject => woObject?.woNo === woNo)?.woSSL;
        woData = sslList?.find(ssl => ssl?.label === woData)?.value;
        break;
      case 'cont_no':
        woData = woObjectList?.find(
          woObject => woObject?.woNo === woNo,
        )?.woContNo;
        setWOQueryObject(prev => ({
          ...prev,
          cont_no: woData || NULL_PARAMETER_VALUE,
        }));
        break;
      default:
        break;
    }

    onChange?.(woNo);
    setValue(changeFieldName, woData);
  });
};

export default handleWOChange;
