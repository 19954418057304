import { useMutation, useQueryClient } from '@tanstack/react-query';
import deleteSetting from '../../../../services/CustomerReport/Delete/deleteSetting';
import useControlToast from '../../../Common/Toast/useControlToast';
import { SUCCESS_DELETE } from '../../../../constants/Common/successMessage';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';
import updateGridData from '../../../../utils/Common/Handler/updateGridData';

const useDeleteSetting = ({
  gridRef,
  setIsOpenErrorModal,
  setMessage,
  setIsSuccess,
}) => {
  const { addToast } = useControlToast();

  const mutate = useMutation({
    mutationFn: deleteSetting,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: (data, variables) => {
      const deletedSeqList = variables?.deleteData?.seq;
      const removeNodeDataList = gridRef?.current?.api
        .getModel()
        .rowsToDisplay?.map(row => row?.data)
        ?.filter(row => deletedSeqList?.includes(row?.seq));

      updateGridData({
        variables,
        isUpdate: false,
        removeNodeDataList,
      });

      addToast({ message: SUCCESS_DELETE });
    },
    onError: error => {
      setIsSuccess(false);
      setMessage(error?.message);
      setIsOpenErrorModal(true);
    },
  });

  return { ...mutate };
};

export default useDeleteSetting;
