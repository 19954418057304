import React from 'react';
import CommonToggle from '../../../components/Inputs/CommonToggle/CommonToggle';

const getToggleComponent = ({ ...props }) => {
  const commonProperty = {
    disabled: props?.disabled,
    required: props?.required,
    labelStyle: props?.labelStyle,
    borderVisible: props?.borderVisible,
    onChangeHandler: ({ e, onChange, selectProps, ...handlerProps }) => {
      console.log('E : ', e);
      onChange?.(e?.target?.checked);
    },
    options: [
      {
        key: 0,
        inputName: 'toggle00',
        optionLabel: 'TOGGLE',
        defaultValue: false,
      },
      {
        key: 1,
        inputName: 'toggle01',
        optionLabel: 'TOGGLE',
        defaultValue: false,
      },
      {
        key: 2,
        inputName: 'toggle02',
        optionLabel: 'TOGGLE',
        defaultValue: false,
      },
    ],
  };

  const inputProperty = [
    {
      key: 0,
      label: 'Toggle Label 100%',
      inputName: 'toggle00',
      width: 'w-full',
      ...commonProperty,
    },
  ];

  return inputProperty?.map(element => (
    <CommonToggle
      key={element?.key}
      {...element}
      labelStyle={props?.labelStyle && 'row'}
    />
  ));
};
export default getToggleComponent;
