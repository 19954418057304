import React from 'react';
import CommonButton from '../../../../../../CommonButton/CommonButton';

const DriverDeleteConfirmModalButtonContent = ({
  setOpen,
  seq,
  deleteDriverMutator,
}) => {
  const woNo = document.URL?.split('/')?.pop();

  return (
    <div className="w-full flex justify-center items-center">
      <div className="flex gap-[20px]">
        <CommonButton
          category="cancel"
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </CommonButton>
        <CommonButton
          category="delete"
          preventDupClick
          onClick={({ buttonRef }) =>
            deleteDriverMutator.mutate({ woNo, seq, buttonRef })
          }
        >
          Delete
        </CommonButton>
      </div>
    </div>
  );
};

export default DriverDeleteConfirmModalButtonContent;
