import dayjs from 'dayjs';

const generateInitAdvancedForm = () => {
  const today = dayjs().format('YYYY-MM-DD');
  const threeMonthsAgo = dayjs().subtract(3, 'month').format('YYYY-MM-DD');
  return {
    status: 'ALL',
    category: '',
    date: 'created_date',
    'from-date': threeMonthsAgo,
    'to-date': today,
    search: '',
  };
};

export default generateInitAdvancedForm;
