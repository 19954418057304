export const openWaringModal = ({
  warningModalIndex,
  duplicationErrorList,
  setWarningModalIndex,
  setOpenWarningModal,
  setWarningModalData,
}) => {
  if (warningModalIndex < duplicationErrorList?.length) {
    const modalData = duplicationErrorList[warningModalIndex];
    setWarningModalIndex(prev => prev + 1);

    setOpenWarningModal(true);
    setWarningModalData(modalData);
  }
};

export const closeWarningModal = ({
  setOpenWarningModal,
  warningModalIndex,
  duplicationErrorList,
  setWarningModalIndex,
  setWarningModalData,
}) => {
  setOpenWarningModal(false);
  openWaringModal({
    warningModalIndex,
    duplicationErrorList,
    setWarningModalIndex,
    setOpenWarningModal,
    setWarningModalData,
  });
};
