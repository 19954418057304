import FloatingFilterSelect from '../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterSelect';
import { MASTER_COMMON_CHECK_OPTION } from '../../Master/Common/masterCommonOption';

const CHECKBOX_DEF = {
  floatingFilterComponent: FloatingFilterSelect,
  floatingFilterComponentParams: {
    option: MASTER_COMMON_CHECK_OPTION,
  },
  cellRenderer: 'agCheckboxCellRenderer',
  cellRendererParams: {
    disabled: true,
  },
  valueFormatter: params => {
    return params.value ? 'Yes' : 'No';
  },
  sortable: false,
  cellStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
export default CHECKBOX_DEF;
