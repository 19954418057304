import dayjs from 'dayjs';
import React from 'react';
import { PATH_HYPHEN_ICON } from '../../../../../../constants/Common/path';
import CommonDate from '../../../../../Inputs/CommonDate/CommonDate';

const ModalContent204Or322 = ({
  selectedEDI,
  searchFrom204,
  searchTo204,
  searchFrom322,
  searchTo322,
}) => {
  return (
    <div className="w-[323px] h-[87px] flex justify-center items-center bg-[#F9F9F9]">
      <div className="mr-[5px] mt-[10px]">
        <CommonDate
          label="DATE"
          inputName="fromDate"
          width="w-[116px]"
          required
          defaultValue={selectedEDI === '204' ? searchFrom204 : searchFrom322}
          onChangeHandler={({ e, onChange }) => {
            const value = dayjs(e)?.isValid()
              ? dayjs(e)?.format('YYYY-MM-DD')
              : null;
            onChange?.(value);
          }}
        />
      </div>
      <div className="mt-[10px] flex items-center">
        <img src={PATH_HYPHEN_ICON} alt="hyphen" width={13} />
      </div>
      <div className="ml-[5px] mt-[10px]">
        <CommonDate
          inputName="toDate"
          width="w-[116px]"
          required
          defaultValue={selectedEDI === '204' ? searchTo204 : searchTo322}
          onChangeHandler={({ e, onChange }) => {
            const value = dayjs(e)?.isValid()
              ? dayjs(e)?.format('YYYY-MM-DD')
              : null;
            onChange?.(value);
          }}
        />
      </div>
    </div>
  );
};

export default ModalContent204Or322;
