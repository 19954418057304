import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';
import bcDetailDefaultApi from '../../../api';

const getAPInput = async ({ woNo, scheduleSeq, driver, dual }) => {
  try {
    const response = await bcDetailDefaultApi?.get(
      `${woNo}/assign-driver/ap-input/${scheduleSeq}`,
      {
        params: {
          page: 'bcdetail',
          driver,
          dual,
        },
      },
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getAPInput;
