import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import bcDetailKeys from '../../keys';
import getAPInput from '../../../../services/BCDetail/DetailInfo/Schedule/Get/getAPInput';

const useGetAPInput = ({
  scheduleSeq,
  driver,
  setMessageModalOpener,
  setMessageModalMessage,
  setMessageModalType,
  setLoadingOpener,
}) => {
  const [apInputData, setAPInputData] = useState(null);

  const woNo =
    document.URL?.split('/')?.[document?.URL?.split('/')?.length - 1];

  const query = useQuery({
    queryKey: bcDetailKeys.apInput({ woNo, scheduleSeq, driver }),
    queryFn: () => getAPInput({ woNo, scheduleSeq, driver }),
    refetchOnWindowFocus: false,
    enabled: false,
    retry: false,
  });

  useEffect(() => {
    const getAPInputData = query?.data?.data;
    if (query?.data?.data) {
      setAPInputData(getAPInputData);
    }
  }, [query?.data]);

  useEffect(() => {
    if (query?.isError && query?.isFetchedAfterMount) {
      /** If message modal type is 'AP', did not reset query key and data */
      setMessageModalType('AP');
      setMessageModalMessage(query?.error?.message);
      setMessageModalOpener(true);
    }
  }, [query?.isError]);

  useEffect(() => {
    if (query?.isFetching) {
      setLoadingOpener(true);
    } else {
      setLoadingOpener(false);
    }
  }, [query?.isFetching]);

  return { apInputData, ...query };
};

export default useGetAPInput;
