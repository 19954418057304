import { DATAGRID_COLORS } from '../../../../constants/Admin/EarlyWarning/theme';
import { CELL_TYPE_TEXT } from '../../../../constants/Common/DataGrid/cellType';
import cellEditorGenerator from '../../../Common/Datagrid/Generator/cellEditorGenerator';
import { REGEX_NUMBER_REGEX } from '../../../../constants/Common/regex';
import FloatingFilterSelect from '../../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterSelect/index';
import {
  generateBooleanOption,
  generateObjectToOption,
} from '../../Common/floatingFilterSelectOptionGenerator';
import {
  CATEGORY_CODE,
  CONDITION_CODE,
  DATE_CODE,
} from '../../../../constants/Admin/EarlyWarning/standardCodes';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';
import {
  PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
  PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
} from '../../../../constants/Common/path';

const createAdminEWColumnDefs = () => {
  const DIVISION_COLUMN_WIDTH = 65;
  const LEVEL_COLUMN_WIDTH = 57;
  const NUMBER_ALIGNMENT = 'right';
  const CHECKBOX_ALIGNMENT_STYLE = {
    cellStyle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };

  return [
    {
      headerName: 'IMP',
      width: DIVISION_COLUMN_WIDTH,
      field: 'imp',
      editable: true,
      cellRendererParams: {
        disabled: true,
      },
      ...CHECKBOX_ALIGNMENT_STYLE,
      cellEditor: 'agCheckboxCellEditor',
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: generateBooleanOption(),
      },
    },
    {
      headerName: 'EXP',
      width: DIVISION_COLUMN_WIDTH,
      field: 'exp',
      cellRendererParams: {
        disabled: true,
      },
      editable: true,
      cellEditor: 'agCheckboxCellEditor',
      ...CHECKBOX_ALIGNMENT_STYLE,
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: generateBooleanOption(),
      },
    },
    {
      headerName: 'VAN',
      width: DIVISION_COLUMN_WIDTH,
      field: 'van',
      editable: true,
      cellRendererParams: {
        disabled: true,
      },
      ...CHECKBOX_ALIGNMENT_STYLE,
      cellEditor: 'agCheckboxCellEditor',
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: generateBooleanOption(),
      },
    },
    {
      headerName: 'BRKG',
      width: DIVISION_COLUMN_WIDTH,
      field: 'brok',
      editable: true,
      cellRendererParams: {
        disabled: true,
      },
      ...CHECKBOX_ALIGNMENT_STYLE,
      cellEditor: 'agCheckboxCellEditor',
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: generateBooleanOption(),
      },
    },
    {
      headerName: 'BARE',
      width: DIVISION_COLUMN_WIDTH,
      field: 'bare',
      editable: true,
      cellRendererParams: {
        disabled: true,
      },
      ...CHECKBOX_ALIGNMENT_STYLE,
      cellEditor: 'agCheckboxCellEditor',
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: generateBooleanOption(),
      },
    },

    {
      headerName: 'CAT',
      width: 84,
      field: 'category',
      editable: false,
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: generateObjectToOption(CATEGORY_CODE),
      },
      valueGetter: gridParam => {
        const value = CATEGORY_CODE?.[gridParam?.data?.category];
        return value;
      },
    },
    {
      headerName: 'CONDITION',
      width: 119,
      field: 'condition',
      editable: false,
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: generateObjectToOption(CONDITION_CODE),
      },
      valueGetter: gridParam => {
        const value = CONDITION_CODE?.[gridParam?.data?.condition];
        return value;
      },
    },
    {
      headerName: 'FROM',
      width: 131,
      field: 'warn_from',
      editable: false,
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: generateObjectToOption(DATE_CODE),
      },
      valueGetter: gridParam => {
        const value = DATE_CODE?.[gridParam?.data?.warn_from];
        return value;
      },
    },
    {
      headerName: 'TO',
      width: 131,
      field: 'warn_to',
      editable: false,
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: generateObjectToOption(DATE_CODE),
      },
      valueGetter: gridParam => {
        const value = DATE_CODE?.[gridParam?.data?.warn_to];
        return value;
      },
    },
    {
      headerName: 'FREQ',
      width: 55,
      field: 'frequency',
      editable: false,
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: generateObjectToOption(['DAILY', 'HOURLY']),
      },
    },
    {
      headerName: 'LEFT',
      backgroundColor: DATAGRID_COLORS?.leftColor?.backgroundColor,
      color: DATAGRID_COLORS?.leftColor?.color,
      isColumnGroup: true,
      openByDefault: true,
      children: [
        {
          headerName: 'LV 1',
          width: LEVEL_COLUMN_WIDTH,
          field: 'left_level_1',
          editable: true,
          valueGetter: gridParam => {
            return Number(gridParam?.data?.left_level_1) || '';
          },
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              customStyle: {
                textAlign: NUMBER_ALIGNMENT,
              },
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              regex: REGEX_NUMBER_REGEX,
              customStyle: {
                textAlign: NUMBER_ALIGNMENT,
              },
            }),
        },
        {
          headerName: 'LV 2',
          width: LEVEL_COLUMN_WIDTH,
          field: 'left_level_2',
          editable: true,
          valueGetter: gridParam => {
            return Number(gridParam?.data?.left_level_2) || '';
          },
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              customStyle: {
                textAlign: NUMBER_ALIGNMENT,
              },
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              regex: REGEX_NUMBER_REGEX,
              customStyle: {
                textAlign: NUMBER_ALIGNMENT,
              },
            }),
          columnGroupShow: 'open',
        },
        {
          headerName: 'LV 3',
          width: LEVEL_COLUMN_WIDTH,
          field: 'left_level_3',
          editable: true,
          valueGetter: gridParam => {
            return Number(gridParam?.data?.left_level_3) || '';
          },
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              customStyle: {
                textAlign: NUMBER_ALIGNMENT,
              },
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              regex: REGEX_NUMBER_REGEX,
              customStyle: {
                textAlign: NUMBER_ALIGNMENT,
              },
            }),
          columnGroupShow: 'open',
        },
      ],
    },
    {
      headerName: 'CURR',
      backgroundColor: DATAGRID_COLORS?.curColor?.backgroundColor,
      color: DATAGRID_COLORS?.curColor?.color,
      isColumnGroup: true,
      children: [
        {
          headerName: 'LV 4',
          width: LEVEL_COLUMN_WIDTH,
          field: 'has_cur_level',
          editable: true,
          cellRendererParams: {
            disabled: true,
          },
          cellEditor: 'agCheckboxCellEditor',
          ...CHECKBOX_ALIGNMENT_STYLE,
        },
      ],
    },
    {
      headerName: 'PASSED',
      backgroundColor: DATAGRID_COLORS?.passedColor?.backgroundColor,
      color: DATAGRID_COLORS?.passedColor?.color,
      isColumnGroup: true,
      openByDefault: true,
      icon: {
        fold: PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
        unfold: PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
      },
      children: [
        {
          headerName: 'LV 5',
          width: LEVEL_COLUMN_WIDTH,
          field: 'passed_level_1',
          editable: true,
          valueGetter: gridParam => {
            return Number(gridParam?.data?.passed_level_1) || '';
          },
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              customStyle: {
                textAlign: NUMBER_ALIGNMENT,
              },
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              regex: REGEX_NUMBER_REGEX,
              customStyle: {
                textAlign: NUMBER_ALIGNMENT,
              },
            }),
        },
        {
          headerName: 'LV 6',
          width: LEVEL_COLUMN_WIDTH,
          field: 'passed_level_2',
          columnGroupShow: 'open',
          editable: true,
          valueGetter: gridParam => {
            return Number(gridParam?.data?.passed_level_2) || '';
          },
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              customStyle: {
                textAlign: NUMBER_ALIGNMENT,
              },
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              regex: REGEX_NUMBER_REGEX,
              customStyle: {
                textAlign: NUMBER_ALIGNMENT,
              },
            }),
        },
        {
          headerName: 'LV 7',
          width: LEVEL_COLUMN_WIDTH,
          field: 'passed_level_3',
          columnGroupShow: 'open',
          editable: true,
          valueGetter: gridParam => {
            return Number(gridParam?.data?.passed_level_3) || '';
          },
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              customStyle: {
                textAlign: NUMBER_ALIGNMENT,
              },
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              regex: REGEX_NUMBER_REGEX,
              customStyle: {
                textAlign: NUMBER_ALIGNMENT,
              },
            }),
        },
      ],
    },
  ];
};

export default createAdminEWColumnDefs;
