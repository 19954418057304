import { REGEX_EMAIL } from '../../../constants/Common/regex';
import { VALIDATE_FAILED_EMAIL } from '../../../constants/Order/Common/message';

/**
 * @description
 * Function to check send to constraint
 * @param {object} orderPostForm
 * Order post form to be checked
 * @param {object} modalMessageSetter
 * Setter to display in the modal
 * @returns {boolean}
 * True if send to constraint is valid
 */
const checkSendToConstraint = ({ orderPostForm, modalMessageSetter }) => {
  const { setMessageModalOpen, setModalMessage } = modalMessageSetter;
  /** @type {list} Key of order post form */
  const getOrderPostFormKey = Object.keys(orderPostForm);

  /** Split string with ',' */
  if (getOrderPostFormKey?.includes('sendto')) {
    const getSendToProperty = orderPostForm?.sendto;
    const splitSendToProperty = getSendToProperty
      ?.split(',')
      .map(item => item?.trim());

    /** Handle exception case */
    if (splitSendToProperty?.length === 1 && splitSendToProperty?.[0] === '')
      return true;

    /** Check email validation */
    if (splitSendToProperty && splitSendToProperty?.length > 0) {
      for (let i = 0; i < splitSendToProperty?.length; i++) {
        if (!splitSendToProperty?.[i].match(REGEX_EMAIL)) {
          setMessageModalOpen(true);
          setModalMessage(VALIDATE_FAILED_EMAIL);
          return false;
        }
      }
    }
  }

  return true;
};

export default checkSendToConstraint;
