import dayjs from 'dayjs';

/**
 * @description
 * Function to set order info with booking number
 * @param {object} setValue
 * React hook form setValue function
 * @param {object} bookingInfoData
 * Booking info data according to booking number
 * @param {list} containerList
 * Container list
 * @param {object} setContainerList
 * Container list setter
 */
export const setOrderInfoWithBookingNumber = ({
  setValue,
  bookingInfoData,
  containerList,
  setContainerList,
}) => {
  setValue('billto', bookingInfoData?.billto || '');
  setValue(
    'erd',
    bookingInfoData?.erd && dayjs(bookingInfoData?.erd)?.isValid()
      ? dayjs(bookingInfoData?.erd)
      : null,
  );
  setValue(
    'cutoff_date',
    bookingInfoData?.cutoff_date &&
      dayjs(bookingInfoData?.cutoff_date)?.isValid()
      ? dayjs(bookingInfoData?.cutoff_date)
      : null,
  );
  setValue(
    'cutoff_time',
    bookingInfoData?.cutoff_time &&
      bookingInfoData?.cutoff_date &&
      dayjs(bookingInfoData?.cutoff_date)?.isValid() &&
      dayjs(
        `${bookingInfoData?.cutoff_date} ${bookingInfoData?.cutoff_time}`,
      )?.isValid()
      ? dayjs(`${bookingInfoData?.cutoff_date} ${bookingInfoData?.cutoff_time}`)
      : null,
  );
  setValue('freedays', bookingInfoData?.freedays || '');
  setValue('freedays_type', bookingInfoData?.freedays_type || 'FD0001');
  setValue('vessel', bookingInfoData?.vessel || '');
  setValue('ssl', bookingInfoData?.ssl || '');
  // Commodity ...
  setValue('po_loc', bookingInfoData?.po_loc || '');
  setValue('dilv_loc', bookingInfoData?.dilv_loc || '');
  setValue('rtn_loc', bookingInfoData?.rtn_loc || '');
  // Reset container size and type
  containerList.forEach((container, idx) => {
    setValue(`size_${container?.containerKey}`, '');
    setValue(`type_${container?.containerKey}`, '');
  });
  setContainerList(prev =>
    prev?.map(element => ({ ...element, size: null, type: null })),
  );
};

/**
 * @description
 * Function to reset order info
 * @param {object} setValue
 * React hook form setValue function
 * @param {list} containerList
 * Container list
 * @param {object} setContainerList
 * Container list setter
 */
export const resetOrderInfoWithBookingNumber = ({
  setValue,
  containerList,
  setContainerList,
}) => {
  setValue('billto', '');
  setValue('erd', null);
  setValue('cutoff_date', null);
  setValue('cutoff_time', null);
  setValue('freedays', '');
  setValue('freedays_type', 'FD0001');
  setValue('vessel', '');
  setValue('ssl', '');

  setValue('po_loc', bookingInfoData?.po_loc || '');
  setValue('dilv_loc', bookingInfoData?.dilv_loc || '');
  setValue('rtn_loc', bookingInfoData?.rtn_loc || '');
};
