import dayjs from 'dayjs';

const createBookingTableCell = ({
  row,
  index,
  cell,
  bookingNo,
  bookingSeq,
  evidenceMutator,
}) => {
  if (cell.inputName === 'no') {
    return { data: index + 1, width: cell?.width };
  }
  if (cell.inputName === 'evidence') {
    return {
      data: !!row?.evidence,
      component: 'evidence',
      width: cell?.width,
      handleClick: () => {
        window.open(row.evidence, '_blank');
      },
    };
  }
  if (cell.inputName === 'upload') {
    return {
      width: cell?.width,
      component: 'upload',
      handleChange: ({ e, buttonRef }) => {
        evidenceMutator({
          bookingSeq,
          formData: {
            idx: row.idx,
            evidence: e.target.files?.[0],
            bk_no: bookingNo,
          },
          buttonRef,
        });
      },
    };
  }
  if (cell.inputName === 'new' || cell.inputName === 'old') {
    if (row.field === 'C/O' && dayjs(row?.[cell.inputName]).isValid()) {
      return {
        data: dayjs(row?.[cell.inputName]).format('MM/DD/YY HH:mm'),
        width: cell?.width,
      };
    }
    if (dayjs(row?.[cell.inputName]).isValid()) {
      return {
        data: dayjs(row?.[cell.inputName]).format('MM/DD/YY'),
        width: cell?.width,
      };
    }
    return {
      data: '',
      width: cell?.width,
    };
  }
  if (
    cell.inputName === 'created_date' &&
    dayjs(row?.[cell.inputName]).isValid()
  ) {
    return {
      data: dayjs(row?.[cell.inputName]).format('MM/DD/YY'),
      width: cell?.width,
    };
  }
  if (cell.inputName === 'created_time' && dayjs(row.created_date).isValid()) {
    return {
      data: dayjs(row.created_date).format('HH:mm'),
      width: cell?.width,
    };
  }
  return { data: row?.[cell.inputName] || '', width: cell?.width };
};

export default createBookingTableCell;
