import handleQueryError from '../../../utils/Common/Handler/handleQueryError';
import adminDefaultApi from '../api';

const getAuthList = async () => {
  try {
    const response = await adminDefaultApi.get('group');
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getAuthList;
