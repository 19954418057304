import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import commonDefaultAPI from '../../../Common/api';

const getProductList = async ({ type }) => {
  try {
    const response = await commonDefaultAPI.get(`product-list?type=${type}`);
    return response.data;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getProductList;
