import React, { useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CATEGORY_204 } from '../../../../constants/EDI/options';
import useDecline204Mutation from '../../../../hooks/EDI/Patch/useDecline204Mutation';
import CommonLoading from '../../../CommonLoading/CommonLoading';
import CommonMessageModal from '../../../CommonMessageModal/CommonMessageModal';
import ButtonContent from './ButtonContent';
import ModalContent from './ModalContent';
import ModalHeader from './ModalHeader';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';

const Decline204Modal = ({
  isDecline204ModalOpen,
  setIsDecline204ModalOpen,
  detail204Data,
  setIsAlertOpen,
  setIsSuccessAlert,
  setAlertMessage,
  currDiv,
  selectedData,
  searchFrom204,
  searchTo204,
  selectedCategory,
}) => {
  const declineController = useForm();

  const buttonRef = useRef(null);

  const { mutate: decline204Mutation, isPending: isDecline204Pending } =
    useDecline204Mutation({
      setIsAlertOpen,
      setIsSuccessAlert,
      setAlertMessage,
      div: currDiv,
      id: selectedData,
      from: searchFrom204,
      to: searchTo204,
      sender: CATEGORY_204[selectedCategory],
    });

  const handleDecline = e => {
    updateButtonDisabled({ ref: buttonRef, disabled: true });
    decline204Mutation({ id: detail204Data?._id, data: e, buttonRef });
    setIsDecline204ModalOpen(false);
  };

  return (
    <>
      <CommonLoading open={isDecline204Pending} />
      <FormProvider {...declineController}>
        <form>
          <CommonMessageModal
            open={isDecline204ModalOpen}
            setOpen={setIsDecline204ModalOpen}
            width="400px"
            height="330px"
            header={<ModalHeader id={detail204Data?.billto_ref} />}
            content={<ModalContent />}
            buttonContent={
              <ButtonContent
                handleDecline={handleDecline}
                setIsDecline204ModalOpen={setIsDecline204ModalOpen}
                buttonRef={buttonRef}
              />
            }
          />
        </form>
      </FormProvider>
    </>
  );
};

export default Decline204Modal;
