import { useMutation } from '@tanstack/react-query';
import get204PDF from '../../../services/EDI/Get/get204PDF';

const openPDFViewer = (
  url,
  setIsSuccessAlert,
  setAlertMessage,
  setIsAlertOpen,
) => {
  const blob = new Blob([url], { type: 'application/pdf' });
  const file = new File([blob], 'example.pdf');
  const blobs = new Blob([file], { type: 'application/pdf' });
  const pdfURL = URL.createObjectURL(blobs);

  const newWindow = window.open(pdfURL, '_blank');
  if (newWindow) {
    newWindow.focus();
  } else {
    setIsSuccessAlert(false);
    setAlertMessage('Popup blocked. Please allow popups for this site.');
    setIsAlertOpen(true);
  }
};

const useGet204PDFMutation = ({
  setIsSuccessAlert,
  setAlertMessage,
  setIsAlertOpen,
}) => {
  const query = useMutation({
    mutationFn: get204PDF,
    onSuccess: res => {
      openPDFViewer(
        res?.data,
        setIsSuccessAlert,
        setAlertMessage,
        setIsAlertOpen,
      );
    },
    onError: error => {
      setIsSuccessAlert(false);
      setAlertMessage(error.message);
      setIsAlertOpen(true);
    },
  });

  return query;
};

export default useGet204PDFMutation;
