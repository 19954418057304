const convertListToObject = (list, key, value) => {
  const result = {};

  list?.forEach(element => {
    result[element?.[key]] = element?.[value];
  });
  return result;
};

export default convertListToObject;
