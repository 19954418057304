import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import StyledCommonErrorPageWrapper from '../../styles/Common/CommonErrorPage/StyledCommonErrorPageWrapper';
import StyledCommonErrorPageContentsWrapper from '../../styles/Common/CommonErrorPage/StyledCommonErrorPageContentsWrapper';

const InternalServerError = () => {
  return (
    <StyledCommonErrorPageWrapper>
      <StyledCommonErrorPageContentsWrapper>
        <div className="h-fit text-[75px] text-[#00235D] font-semibold">
          OOPS!
        </div>
        <div className="h-fit text-[35px] text-[#00235D] font-semibold pt-[36px]">
          INTERNAL SERVER ERROR
        </div>
        <div className="text-[20px] text-[#00235D] flex justify-center items-center gap-[5px] flex-col pt-[30px]">
          <p>Please try again later or feel free to contact us.</p>
          <p>If you think something is broken, report a problem.</p>
        </div>
        <div className="text-[20px] text-[#5D0000] flex justify-center items-center gap-[5px] flex-col pt-[30px]">
          <p>Error code: 500</p>
        </div>
      </StyledCommonErrorPageContentsWrapper>
    </StyledCommonErrorPageWrapper>
  );
};

export default InternalServerError;
