const resolveErrorListGenerator = ({
  duplicationErrorList,
  startIndex,
  type,
}) => {
  const resolveErrorList = [...duplicationErrorList]
    ?.slice(startIndex)
    ?.map(error => {
      return {
        type,
        patchSeq: error?.seq,
        formKey: error?.submitData?.key,
        submitData: error?.submitData?.data,
      };
    });

  return resolveErrorList;
};

export default resolveErrorListGenerator;
