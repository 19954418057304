import generateChassisInputField from './generateChassisInputField';

const generateChassisInvoiceList = ({ data }) => {
  const { Items, ...commonData } = data;

  const invoiceList = Items?.map((scanObject, index) => {
    const inputField = generateChassisInputField({
      scanObject: { ...scanObject, ...commonData },
    });
    return { key: index, data: inputField };
  });

  const invoiceKey = invoiceList?.length;
  return { invoiceList, invoiceKey };
};

export default generateChassisInvoiceList;
