import {
  CELL_TYPE_AUTO_COMPLETE,
  CELL_TYPE_SELECT,
  CELL_TYPE_TEXT,
} from '../../../../constants/Common/DataGrid/cellType';
import { REGEX_MONTH, REGEX_YEAR } from '../../../../constants/Common/regex';
import {
  categoryDropdownOptions,
  tierDropdownOptions,
} from '../../../../constants/Master/ChassisRate/elements';
import cellEditorGenerator from '../../../Common/Datagrid/Generator/cellEditorGenerator';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';

const createMasterChassisRateColDefs = (nglCneeList, customerList) => {
  return [
    {
      headerName: 'EFFECTIVE',
      headerClass: 'blue1',
      isColumnGroup: true,
      backgroundColor: '#CCD3E3',
      children: [
        {
          headerName: 'MONTH',
          field: 'effective_month',
          width: 76,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              customStyle: {
                textAlign: 'right',
              },
              regex: REGEX_MONTH,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              customStyle: {
                textAlign: 'right',
              },
              regex: REGEX_MONTH,
            }),
          valueGetter: params => {
            if (!params.data?.effective_month) return '';
            return String(params.data.effective_month);
          },
        },
        {
          headerName: 'YEAR',
          field: 'effective_year',
          width: 62,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              customStyle: {
                textAlign: 'right',
              },
              regex: REGEX_YEAR,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              customStyle: {
                textAlign: 'right',
              },
              regex: REGEX_YEAR,
            }),
          valueGetter: params => {
            if (!params.data?.effective_year) return '';
            return String(params.data.effective_year);
          },
        },
      ],
    },
    {
      headerName: 'BILL TO',
      field: 'billto',
      width: 170,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_AUTO_COMPLETE,
          option: customerList,
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_AUTO_COMPLETE,
          option: customerList,
        }),
      valueGetter: params => {
        return !params.data.billto?.includes('/')
          ? customerList.find(
              company => company.value.split('/')[0] === params.data.billto,
            )?.label
          : params.data.billto;
      },
    },
    {
      headerName: 'DELV LOC',
      field: 'dilv_location',
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_AUTO_COMPLETE,
          option: nglCneeList,
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_AUTO_COMPLETE,
          option: nglCneeList,
        }),
      valueGetter: params => {
        return !params.data.dilv_location?.includes('/')
          ? nglCneeList.find(
              company =>
                company.value.split('/')[0] === params.data.dilv_location,
            )?.label
          : params.data.dilv_location;
      },
    },
    {
      headerName: 'CAT',
      field: 'category',
      width: 60,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_SELECT,
          option: categoryDropdownOptions,
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_SELECT,
          option: categoryDropdownOptions,
        }),
    },
    {
      headerName: 'TIER',
      field: 'tier',
      width: 57,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_SELECT,
          option: tierDropdownOptions,
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_SELECT,
          option: tierDropdownOptions,
        }),
    },
  ];
};

export default createMasterChassisRateColDefs;
