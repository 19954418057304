import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import masterDefaultApi from '../../api';

/**
 * Retrieves the list of AR rates from the backend.
 * Returns the data from the response if successful, otherwise handles the error.
 * @returns {Object[]|undefined} - The list of AR rates if successful, undefined on error.
 */
const getARRateList = async ({ div }) => {
  try {
    const response = await masterDefaultApi.get(`/arrate?div=${div}`);
    return response.data;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getARRateList;
