import React from 'react';
import { Outlet } from 'react-router-dom';
import pageTabWithPermissionGenerator from '../../utils/CustomerReport/Generator/pageTabWithPermissionGenerator';
import MainLayOut from '../../components/Layout/MainLayOut';
import { PAGE_CUSTOMER_REPORT } from '../../constants/Common/mapper';
import SubMenuNavigator from '../../components/Common/SubMenuNavigator';

const CustomerReport = ({ ...props }) => {
  /** Get customer-report page tab */
  const pageTabList = pageTabWithPermissionGenerator();

  /** Render page */
  return (
    <MainLayOut tabsData={pageTabList}>
      <SubMenuNavigator page={PAGE_CUSTOMER_REPORT} />
      <Outlet />
    </MainLayOut>
  );
};

export default CustomerReport;
