import styled from 'styled-components';

const StyledLabsButtonBoxWrapper = styled.div`
  border: 2px solid black;
  border-radius: 5px;
  width: 200px;
  height: 300px;
  background-color: white;
  margin: 10px;
  display: flex;
  flex-direction: column;
`;

export default StyledLabsButtonBoxWrapper;
