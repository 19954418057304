import React from 'react';
import { useFormContext } from 'react-hook-form';
import CommonButton from '../../../CommonButton/CommonButton';
import setAdvancedSearchQueryParameter from '../../../../utils/CustomerReport/Handler/setAdvancedSearchQueryParameter';

const AdvancedSearchButton = ({ ...props }) => {
  /** Modal opener */
  const { setOpen } = props;

  /** Get props data */
  const { searchParams, setSearchParams } = props;

  const { getValues, handleSubmit } = useFormContext();

  return (
    <div className="w-full flex flex-column gap-[18px] justify-center items-center">
      <CommonButton category="cancel" onClick={() => setOpen(false)}>
        Cancel
      </CommonButton>
      <CommonButton
        category="search"
        onClick={() =>
          handleSubmit(data => {
            setAdvancedSearchQueryParameter({
              searchParams,
              setSearchParams,
              advancedSearchData: getValues('advanced'),
              setOpen,
            });
          })()
        }
      >
        Search
      </CommonButton>
    </div>
  );
};

export default AdvancedSearchButton;
