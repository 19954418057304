import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import checkOrderPostFormConstraint from '../Constraint/checkOrderPostFormConstraint';
import generatePostSubCategory from '../Generator/generatePostSubCategory';
import copyOrderDataToForm from './copyOrderDataToForm';
import updateButtonDisabled from '../../Common/Handler/updateButtonDisabled';

/**
 * @description
 * Function of handling order save.
 * @param {object} e - Event object. From onClick event.
 * @param {object} handleSubmit - Function of handling submit. From useForm hook.
 * @param {list} containerList - Container list.
 * @param {object} modalMessageSetter - Function of setting modal message.
 * @param {object} orderPostMutator - Function of order post mutator.
 */
const saveOrder = ({
  subCategory,
  currDiv,
  handleSubmit,
  containerList,
  modalMessageSetter,
  orderPostMutator,
  buttonRef,
  isValid,
}) => {
  if (!isValid && buttonRef)
    updateButtonDisabled({ ref: buttonRef, disabled: false });
  handleSubmit(data => {
    /** Copy */
    const orderPostForm = copyOrderDataToForm({
      subCategory,
      orderInputData: data,
      containerList,
    });

    /** Validate order post form */
    const validateResultOrderForm = checkOrderPostFormConstraint({
      orderPostForm,
      modalMessageSetter,
    });
    if (!validateResultOrderForm) {
      if (buttonRef) updateButtonDisabled({ ref: buttonRef, disabled: false });
      return false;
    }

    /** Add additional value to order post form */
    orderPostForm.div = currDiv;
    orderPostForm.sendto = orderPostForm?.sendto
      ?.split(',')
      .map(item => item?.trim());

    /** Convert date property to string */
    orderPostForm.eta =
      orderPostForm?.eta && dayjs(orderPostForm?.eta)?.isValid()
        ? dayjs(orderPostForm?.eta)?.format('YYYY-MM-DD')
        : null;

    orderPostForm.lfd =
      orderPostForm?.lfd && dayjs(orderPostForm?.lfd)?.isValid()
        ? dayjs(orderPostForm?.lfd)?.format('YYYY-MM-DD')
        : null;

    orderPostForm.po_date =
      orderPostForm?.po_date && dayjs(orderPostForm?.po_date)?.isValid()
        ? dayjs(orderPostForm?.po_date)?.format('YYYY-MM-DD')
        : null;

    orderPostForm.po_time =
      orderPostForm?.po_time && dayjs(orderPostForm?.po_time)?.isValid()
        ? dayjs(orderPostForm?.po_time)?.format('HH:mm')
        : null;

    orderPostForm.dilv_date =
      orderPostForm?.dilv_date && dayjs(orderPostForm?.dilv_date)?.isValid()
        ? dayjs(orderPostForm?.dilv_date)?.format('YYYY-MM-DD')
        : null;

    orderPostForm.dilv_time =
      orderPostForm?.dilv_time && dayjs(orderPostForm?.dilv_time)?.isValid()
        ? dayjs(orderPostForm?.dilv_time)?.format('HH:mm')
        : null;

    orderPostForm.rtn_date =
      orderPostForm?.rtn_date && dayjs(orderPostForm?.rtn_date)?.isValid()
        ? dayjs(orderPostForm?.rtn_date)?.format('YYYY-MM-DD')
        : null;

    orderPostForm.rtn_time =
      orderPostForm?.rtn_time && dayjs(orderPostForm?.rtn_time)?.isValid()
        ? dayjs(orderPostForm?.rtn_time)?.format('HH:mm')
        : null;

    orderPostForm.erd =
      orderPostForm?.erd && dayjs(orderPostForm?.erd)?.isValid()
        ? dayjs(orderPostForm?.erd)?.format('YYYY-MM-DD')
        : null;

    orderPostForm.cutoff =
      orderPostForm?.cutoff_date &&
      orderPostForm?.cutoff_time &&
      dayjs(orderPostForm?.cutoff_date)?.isValid() &&
      dayjs(orderPostForm?.cutoff_time)?.isValid()
        ? `${dayjs(orderPostForm?.cutoff_date)?.format('YYYY-MM-DD')} ${dayjs(
            orderPostForm?.cutoff_time,
          )?.format('HH:mm:ss')}`
        : null;

    /** Change blank to null */
    Object.keys(orderPostForm).forEach(key => {
      if (orderPostForm[key] === '') {
        orderPostForm[key] = null;
      }
    });

    orderPostForm?.equips?.forEach(equip => {
      Object.keys(equip).forEach(key => {
        if (equip[key] === '') {
          equip[key] = null;
        }
      });
    });

    /** If all validation passed, progress post */
    orderPostMutator?.({
      orderPostForm,
      pageCategory: generatePostSubCategory({ subCategory }),
      buttonRef,
    });

    return true;
  })();
};

export default saveOrder;
