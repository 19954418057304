import React from 'react';
import CommonMessageModal from '../../CommonMessageModal/CommonMessageModal';
import CommonAlertModalButtonContent from './CommonAlertModalButtonContent';
import CommonAlertModalContent from './CommonAlertModalContent';

/**
 * @description
 * Common alert modal
 * @param {boolean} open
 * Open state of modal
 * @param {function} setOpen
 * Set open state of modal
 * @param {object | string} message
 * Message of modal
 * @param {string} header
 * Header of modal
 * @param {function} onOkClick
 * Function that will be triggered when ok button is clicked
 * @param {function} onCloseClick
 * Function that will be triggered when close button is clicked
 * @returns
 * Component of common alert modal
 */
const CommonAlertModal = ({
  open,
  setOpen,
  message,
  header,
  onOkClick,
  onCloseClick,
  ...props
}) => {
  return (
    open && (
      <CommonMessageModal
        open={open}
        setOpen={setOpen}
        header={header || 'Alert'}
        onCloseClick={onCloseClick || null}
        content={<CommonAlertModalContent>{message}</CommonAlertModalContent>}
        buttonContent={
          <CommonAlertModalButtonContent
            onOkClick={onOkClick}
            setOpen={setOpen}
          />
        }
      />
    )
  );
};

export default CommonAlertModal;
