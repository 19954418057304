import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import CommonCheckbox from '../../../Inputs/CommonCheckbox/CommonCheckbox';
import StyledChildCheckboxWrapper from '../../../../styles/Dispatch/Common/StyledChildCheckboxWrapper';

const MultiCheckboxGroup = ({ groupInfo, setChangeField }) => {
  const { setValue } = useFormContext();

  const groupName = groupInfo.value;
  const groupChildren = groupInfo?.children;

  const [checkedCounts, setCheckedCounts] = useState(
    groupChildren.filter(item => item.checked).length,
  );

  const checkHeaderItem = ({ e, onChange }) => {
    if (e?.target?.checked) setCheckedCounts(groupChildren.length);
    else setCheckedCounts(0);

    groupChildren?.forEach(item =>
      setValue(`${groupName}.${item.value}`, e?.target?.checked),
    );

    setChangeField({
      name: groupName,
      checked: e?.target?.checked,
      isHeaderChange: true,
    });

    onChange(e?.target?.checked);
  };

  const checkChildItem = ({ e, onChange, item }) => {
    if (e?.target?.checked) {
      setCheckedCounts(prev => {
        setChangeField({
          name: groupName,
          child: { value: item.value, checked: e?.target?.checked },
          headerChecked: true,
        });
        return prev + 1;
      });
    } else {
      setCheckedCounts(prev => {
        setChangeField({
          name: groupName,
          child: { value: item.value, checked: e?.target?.checked },
          headerChecked: !(prev - 1 === 0),
        });
        return prev - 1;
      });
    }
    onChange(e?.target?.checked);
  };

  useEffect(() => {
    if (checkedCounts) {
      setValue(`${groupName}.${groupName}`, true);
    } else {
      setValue(`${groupName}.${groupName}`, false);
    }
  }, [checkedCounts]);

  return (
    <div className="pb-[7px]">
      <div className="h-[20px] flex items-center">
        <CommonCheckbox
          alignCenter={false}
          helpTextVisible={false}
          labelVisible={false}
          inputName={`${groupName}.${groupName}`}
          onChangeHandler={checkHeaderItem}
          options={[
            {
              inputName: `${groupName}.${groupName}`,
              defaultValue: groupInfo?.checked,
              optionLabel: groupInfo?.label,
            },
          ]}
        />
      </div>
      <div className="pl-[20px]">
        {groupChildren?.map(item => (
          <StyledChildCheckboxWrapper
            key={`${groupName}_${item.value}`}
            className="h-[20px] flex items-center"
          >
            <CommonCheckbox
              alignCenter={false}
              helpTextVisible={false}
              labelVisible={false}
              inputName={`${groupName}.${item.value}`}
              onChangeHandler={({ e, onChange }) => {
                checkChildItem({ e, onChange, item });
              }}
              options={[
                {
                  inputName: `${groupName}.${item.value}`,
                  defaultValue: item.checked,
                  optionLabel: item.label,
                },
              ]}
            />
          </StyledChildCheckboxWrapper>
        ))}
      </div>
    </div>
  );
};

export default MultiCheckboxGroup;
