import handleDateTimeChainCheck from './handleDateTimeChainCheck';

/**
 * @description
 * Function that check updated schedule constraint
 * @param {list} updatedScheduleData
 * List of update schedule data
 * @returns {boolean}
 * Is update schedule constraint valie
 */
const handleUpdatedScheduleConstraintCheck = ({
  updatedScheduleData,
  setModalMessage,
}) => {
  if (!handleDateTimeChainCheck({ updatedScheduleData, setModalMessage }))
    return false;
  return true;
};

export default handleUpdatedScheduleConstraintCheck;
