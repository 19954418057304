const checkResponseStatus = response => {
  if (response && response.status) {
    return (
      response.status === 200 ||
      response.status === 201 ||
      response.status === 204
    );
  }
  return false;
};

const isServerResponseValid = response => {
  if (response) {
    return checkResponseStatus(response);
  }
  console.log('Failed to validation.');
  return false;
};

export default isServerResponseValid;
