/**
 *
 * @param {Array} optionList
 *  Array containing dropdown items.
 * @returns {Array} resultTag
 *  Array of conversion terms in a format that fits the antd 'Select'.
 */
export function createDropdownOptions(optionList) {
  const resultTag = [];

  optionList?.forEach(option => {
    resultTag.push({
      value: option,
      label: option,
    });
  });

  return resultTag;
}
