import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import mapAPI from '../../api';

const patchGeofence = async ({ company, position }) => {
  try {
    await mapAPI.patch(`/geofence/${company}`, {
      position,
    });
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchGeofence;
