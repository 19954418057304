import { useQuery } from '@tanstack/react-query';
import mobileAdminKeys from '../keys';
import getManifest from '../../../services/MobileAdmin/Mainfest/Get/getManifestList';

const useGetManifest = ({ division, queryObject }) => {
  const query = useQuery({
    queryKey: mobileAdminKeys.manifestQueryString({
      division,
      queryObject,
    }),
    queryFn: () => getManifest({ division, queryObject }),
  });

  return query;
};

export default useGetManifest;
