import DAILY_REPORT_BASIC_COLUMN_DEF from '../../../../constants/DailyReport/Common/basicColumnDef';
import BUTTON_COL_LIST from '../../../../constants/DailyReport/Common/buttonColumList';
import DAILY_REPORT_BASIC_STYLE from '../../../../constants/DailyReport/Common/colorDefs';
import DAILY_REPORT_COLUMN_HEADER from '../../../../constants/DailyReport/Common/columnHeader';
import DAILY_REPORT_COLUMNS from '../../../../constants/DailyReport/Common/columnList';
import DAILY_REPORT_BASIC_COLUMN_WIDTH from '../../../../constants/DailyReport/Common/columnWidth';
import DAILY_REPORT_EDITABLE_COLUMN_DEF from '../../../../constants/DailyReport/Common/editableColumnDef';
import DAILY_REPORT_EDITABLE_COLUMNS from '../../../../constants/DailyReport/Common/editableColumnList';
import DAILY_REPORT_GROUP from '../../../../constants/DailyReport/Common/groupList';
import BUTTON_COL_DEF from '../../../../constants/TmsReport/Common/buttonColDef';
import DAILY_REPORT_EXCEL_STYLE from '../../../../constants/DailyReport/Common/excelColorDef';

const getColumnDefs = ({ page, isEditable, editParam, ...props }) => {
  /** const for  page */
  const headerName = DAILY_REPORT_COLUMN_HEADER?.[page];
  const editableCols = isEditable ? DAILY_REPORT_EDITABLE_COLUMNS?.[page] : [];
  const groupList = DAILY_REPORT_GROUP?.[page];
  const buttonList = isEditable ? BUTTON_COL_LIST?.[page] : [];

  /** functions for one def */
  const getEditSetting = field => {
    if (DAILY_REPORT_EDITABLE_COLUMN_DEF?.[field] === undefined) {
      return {};
    }
    return {
      ...(DAILY_REPORT_EDITABLE_COLUMN_DEF[field] instanceof Function
        ? DAILY_REPORT_EDITABLE_COLUMN_DEF[field](editParam?.[field])
        : DAILY_REPORT_EDITABLE_COLUMN_DEF[field]),
    };
  };

  const getBasicSetting = field => {
    if (DAILY_REPORT_BASIC_COLUMN_DEF?.[field] === undefined) {
      return {};
    }
    return { ...DAILY_REPORT_BASIC_COLUMN_DEF?.[field] };
  };

  const getButtonSetting = field => {
    return buttonList.includes(field)
      ? BUTTON_COL_DEF?.[field]({ ...props })
      : {};
  };

  const getChildSetting = field => {
    const editSettings = getEditSetting(field);
    const basicSettings = getBasicSetting(field);
    const buttonSettings = getButtonSetting(field);

    return {
      headerName: headerName?.[field],
      field,
      width: DAILY_REPORT_BASIC_COLUMN_WIDTH?.[field] || 100,
      editable: !!editableCols?.includes(field),
      ...{
        ...(editableCols?.includes(field) ? editSettings : basicSettings),
      },
      ...buttonSettings,
    };
  };

  /** create Defs */
  const columnDefs = DAILY_REPORT_COLUMNS?.[page]?.map(field => {
    if (Object.keys(groupList)?.includes(field)) {
      let childCounter = 0;
      const headerClass = DAILY_REPORT_EXCEL_STYLE[headerName?.[field]] || '';

      const groupSetting = {
        headerName: headerName?.[field],
        headerClass,
        isColumnGroup: true,
        openByDefault: true,
        ...DAILY_REPORT_BASIC_STYLE?.[field],
        children: groupList[field]?.map(child => {
          const column = {
            ...getChildSetting(child),
            ...(childCounter > 0 && { columnGroupShow: 'open' }),
          };
          childCounter += 1;
          return column;
        }),
      };
      return groupSetting;
    }
    return getChildSetting(field);
  });

  return columnDefs || [];
};

export default getColumnDefs;
