import React from 'react';
import GlobalStyle from '../../styles/Global/GlobalStyle';

const Mobile = () => {
  return (
    <>
      <GlobalStyle />
      <div className="w-full h-full bg-[#DDDFE5] flex justify-center items-center flex-col gap-[10px]">
        <p className="text-[#556DA1] font-bold text-lg">
          Mobile access is unavailable.
        </p>
        <p className="text-[#7F90B6] font-medium text-lg">
          Please use PC to connect.
        </p>
      </div>
    </>
  );
};

export default Mobile;
