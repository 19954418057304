import FloatingFilterSelect from '../../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterSelect';
import { createFloatingFilterSelectOption } from '../../../Common/createFloatingFilterSelectOption';
import { ACCOUNT_CODE_TYPE_VALUE } from '../../../../constants/Master/AccountCode/masterAccountCodeTypeValue';

const createAcctCodeColumnDefs = () => {
  return [
    {
      headerName: 'TYPE',
      field: 'acct_code_type',
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: createFloatingFilterSelectOption(
          Object.values(ACCOUNT_CODE_TYPE_VALUE),
        ),
      },
      editable: false,
      width: 105,
      valueGetter: props => {
        const getCode = props?.data?.acct_code[0];
        return ACCOUNT_CODE_TYPE_VALUE?.[getCode] || null;
      },
    },
    {
      headerName: 'ACCT CODE',
      field: 'acct_code',
      editable: false,
      width: 90,
    },
    {
      headerName: 'ACCT CODE NAME',
      field: 'acct_code_name',
      editable: false,
      valueGetter: props => props?.data?.acct_code_name?.trim(),
    },
  ];
};

export default createAcctCodeColumnDefs;
