import React from 'react';

const NoticeHeader = () => (
  <div className="flex h-full gap-x-[5px] justify-center items-start">
    <img
      className="w-[15px] h-[15px]"
      alt="notice"
      src="/assets/images/common_icon/noticeIcon.svg"
    />
    <div className="w-fit h-full text-[#B74747] font-medium text-xs">
      NOTICE
    </div>
  </div>
);

export default NoticeHeader;
