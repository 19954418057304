import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import tmsReportAPI from '../../api';

const patchReturnYard = async ({ woNo, data }) => {
  try {
    const response = await tmsReportAPI.patch(`yard-schedule/${woNo}`, data);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchReturnYard;
