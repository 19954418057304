import React from 'react';
import { PATH_COMPANY_LOGO_V2 } from '../../../constants/Order/Common/path';

const CustomerHeader = () => {
  return (
    <div className="w-full h-[56px] bg-[#001E5F] flex items-center pl-[24px] fixed top-0 z-[1000]">
      <img src={PATH_COMPANY_LOGO_V2} alt="companyLogo" />
    </div>
  );
};

export default CustomerHeader;
