export const getPairValue = ({
  objList,
  comparisonField,
  getChangedValue,
  findField,
}) => {
  const pairChangeValue = objList?.find(
    obj => obj?.[comparisonField] === getChangedValue,
  )?.[findField];

  return pairChangeValue;
};

export const changePairValue = ({
  instances,
  name,
  objList,
  findField,
  comparisonField,
  getChangedValue,
}) => {
  const pairChangeValue = objList?.find(
    obj => obj?.[comparisonField] === getChangedValue,
  )?.[findField];

  const pairInstance = instances?.find(
    element => element?.cellEditorParams?.column?.colId === name,
  );

  pairInstance?.componentInstance?.setDataValue?.(pairChangeValue);
  pairInstance?.componentInstance?.onValueChange?.(pairChangeValue);
};

export const changePairList = ({ getChangedValue, pairList, ...props }) => {
  const instances = props.api.getCellEditorInstances();

  pairList?.forEach(pair => {
    changePairValue({
      instances,
      name: pair?.name,
      objList: pair?.objList,
      findField: pair?.findField,
      comparisonField: pair.comparisonField,
      getChangedValue,
    });
  });
};
