import React, { useEffect, useState } from 'react';
import useGetDualAPInput from '../../../../../../hooks/BCDetail/DetailInfo/DualMove/useGetDualAPInput';
import usePostDualAssignDriverAPInput from '../../../../../../hooks/BCDetail/DetailInfo/DualMove/usePostDualAssignDriverAPInput';
import CommonMessageModal from '../../../../../CommonMessageModal/CommonMessageModal';
import DualMoveAPModalButton from './DualMoveAPModalButton';
import DualMoveAPModalContent from './DualMoveAPModalContent';

const DualMoveAPModal = ({ ...props }) => {
  const { open, setOpen, apModalController, rtnJob, messageModalController } =
    props;

  const {
    apData,
    isSuccess,
    isLoading: isLoadingGetDualAPInput,
  } = useGetDualAPInput({
    open,
    apModalController,
    rtnJob,
    messageModalController,
  });

  const [apTableContent, setAPTableContent] = useState([
    {
      productCode: '',
      productCodeName: '',
      amount: 0,
    },
  ]);
  useEffect(() => {
    if (apData?.ap_recommendations && apData?.ap_recommendations?.length > 0) {
      setAPTableContent(prev => [
        apData?.ap_recommendations?.map(recommendation => {
          return {
            productCode: recommendation?.code?.product_code,
            productCodeName: recommendation?.code?.product_code_name,
            amount: recommendation?.amt,
          };
        }),
        ...prev,
      ]);
    }
  }, [apData?.ap_recommendations]);

  const { mutate } = usePostDualAssignDriverAPInput({
    setOpen,
    ...messageModalController,
  });

  return (
    open &&
    isSuccess && (
      <CommonMessageModal
        open={open}
        setOpen={setOpen}
        header="Driver AP Input - Dual Move"
        content={
          <DualMoveAPModalContent
            apData={apData}
            apTableContent={apTableContent}
            setAPTableContent={setAPTableContent}
            isLoadingGetDualAPInput={isLoadingGetDualAPInput}
          />
        }
        buttonContent={
          <DualMoveAPModalButton
            setOpen={setOpen}
            saveAP={mutate}
            rtnJob={rtnJob}
            apData={apData}
            apTableContent={apTableContent}
            apModalController={apModalController}
          />
        }
      />
    )
  );
};

export default DualMoveAPModal;
