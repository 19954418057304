import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SUCCESS_UPDATE } from '../../../../constants/Common/successMessage';
import patchTimeZone from '../../../../services/Admin/DivConf/Patch/patchTimeZone';
import useControlToast from '../../../Common/Toast/useControlToast';
import adminKeys from '../../keys';

const usePatchTimeZoneMutation = ({
  setIsMessageOpen,
  setMessage,
  setIsSuccess,
  div,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  return useMutation({
    mutationFn: patchTimeZone,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: adminKeys?.divInfo({ div }) });
      addToast({
        message: SUCCESS_UPDATE,
      });
    },
    onError: error => {
      queryClient.refetchQueries({ queryKey: adminKeys?.divInfo({ div }) });
      setIsSuccess(false);
      setMessage(error.message);
      setIsMessageOpen(true);
    },
  });
};

export default usePatchTimeZoneMutation;
