import handleQueryError from '../../../utils/Common/Handler/handleQueryError';
import cusReportDefaultAPI from '../api';

const postUserID = async ({ postData }) => {
  try {
    const response = await cusReportDefaultAPI.post(`user`, postData);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default postUserID;
