import React from 'react';
import CommonMessageModal from '../../../../../CommonMessageModal/CommonMessageModal';
import DriverDeleteConfirmModalContent from './DriverDeleteConfirmModalContent';
import DriverDeleteConfirmModalButtonContent from './DriverDeleteConfirmModalButtonContent';

const DriverDeleteConfirmModal = ({
  open,
  setOpen,
  selectedRowData,
  ...props
}) => {
  /** Render component */
  return (
    <CommonMessageModal
      open={open}
      setOpen={setOpen}
      header="Alert"
      content={
        <DriverDeleteConfirmModalContent status={selectedRowData?.status} />
      }
      buttonContent={
        <DriverDeleteConfirmModalButtonContent
          setOpen={setOpen}
          seq={selectedRowData?.seq}
          {...props}
        />
      }
    />
  );
};

export default DriverDeleteConfirmModal;
