import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import divBasicDefaultAPI from '../../divApi';

const getEarlyWarningList = async ({ division }) => {
  try {
    const response = await divBasicDefaultAPI.get(`${division}/early-warning`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getEarlyWarningList;
