import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import divBasicDefaultApi from '../../divApi';

const patchAPTableRate = async ({ div, distance, less, more }) => {
  try {
    const response = await divBasicDefaultApi.patch(`${div}/ap-table/rate`, {
      distance: distance?.toString(),
      less: parseFloat(less)?.toFixed(2),
      more: parseFloat(more)?.toFixed(2),
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchAPTableRate;
