import React, { useState } from 'react';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import AccordionDataGrid from '../../../components/Admin/Conf/AccordionDataGrid';
import StyledConfAccordionContainer from '../../../styles/Admin/Conf/StyledConfAccordionContainer';

const Conf = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [messageContents, setMessageContent] = useState('');

  const loadingProps = {
    setIsLoading,
    setIsError,
  };

  const modalProps = {
    setMessageContent,
    setMessageModalOpen,
  };

  return (
    <>
      <CommonLoading open={isLoading || isError} />
      <StyledConfAccordionContainer>
        <AccordionDataGrid
          title="division"
          domLayout="autoHeight"
          {...loadingProps}
          {...modalProps}
        />
        <div className="horizontal-layout">
          <AccordionDataGrid title="ssl" {...loadingProps} {...modalProps} />
          <AccordionDataGrid title="pool" {...loadingProps} {...modalProps} />
        </div>
        <div className="horizontal-layout">
          <AccordionDataGrid title="size" {...loadingProps} {...modalProps} />
          <AccordionDataGrid title="type" {...loadingProps} {...modalProps} />
        </div>
      </StyledConfAccordionContainer>
      {messageModalOpen && (
        <CommonErrorModal
          open={messageModalOpen}
          message={messageContents}
          setIsOpen={setMessageModalOpen}
        />
      )}
    </>
  );
};

export default Conf;
