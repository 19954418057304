import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';
import { CELL_TYPE_BUTTON } from '../../../../constants/Common/DataGrid/cellType';
import generateAssignRestrictMessage from './generateAssignRestrictMessage';
import { JOB_STANDARD_CODE } from '../../../../constants/Dispatch/Common/jobStatusCode';

const getAssignButtonDef = ({
  field,
  setMessageModalMessage,
  setIsMessageModalOpen,
  ...props
}) => ({
  editable: false,
  cellRendererSelector: gridParam =>
    cellRendererGenerator({
      gridParam,
      cellType: CELL_TYPE_BUTTON,
      content: gridParam?.data?.[field] || 'Assign',
      category: gridParam?.data?.[field] ? null : 'assign',
      customStyle: gridParam?.data?.[field]
        ? {
            borderColor: 'none',
            backgroundColor: 'transparent',
          }
        : {
            size: 'small',
          },
      onClickHandler: () => {
        const getColId = gridParam?.column?.colId;
        const schedule = getColId.split('_')[0];

        const restrictionField =
          gridParam?.data?.[`${schedule}_driver_restriction`];
        const jobStatusField = gridParam?.data?.[`${schedule}_job_status`];

        const isAvailableAssignDriver = !restrictionField;
        const isAvailableJobStatus =
          jobStatusField && jobStatusField === JOB_STANDARD_CODE?.SCHEDULED;

        /** Logic for when Driver Assignment is not available */
        if (!isAvailableAssignDriver) {
          const message = generateAssignRestrictMessage({
            restrictionCode: restrictionField,
            schedule,
          });
          setMessageModalMessage(message);
          setIsMessageModalOpen(true);
          return;
        }

        /** Logic for when Driver Assignment is available */
        const {
          setWONo,
          setSelectedScheduleSeq,
          setIsDriverModalOpen,
          setIsDriverChangeModalOpen,
        } = props;

        setWONo(gridParam?.data?.wo_no);
        setSelectedScheduleSeq(gridParam?.data?.[`${schedule}_job_seq`]);

        if (isAvailableJobStatus) {
          setIsDriverModalOpen(true);
          return;
        }

        if (!isAvailableJobStatus) {
          setIsDriverChangeModalOpen(true);
        }
      },
    }),
});

export default getAssignButtonDef;
