import styled from 'styled-components';

const StyledTraceMapSubList = styled.div`
  margin-left: 14px;
  margin-bottom: 32px;
  width: 364px;
  color: #fff;
  font-size: 15px;
`;

export default StyledTraceMapSubList;
