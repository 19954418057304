import styled from 'styled-components';

const StyledBCDetailBillingInfoCommonRowQBContainer = styled.div`
  width: 100%;
  height: ${props => props.height || '30px'};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.backgroundColor || '#e4e7e4'};
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
`;

export default StyledBCDetailBillingInfoCommonRowQBContainer;
