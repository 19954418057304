import React from 'react';
import CommonButton from '../../CommonButton/CommonButton';
import exportToExcel from '../../../utils/Common/Datagrid/Handler/exportToExcel';
import EXCEL_FILE_NAMES from '../../../constants/Main/Common/excelFileNames';

const HeaderButtons = ({
  openAdvancedSearch,
  setCustomizeCols,
  gridRef,
  category,
}) => {
  return (
    <div className="flex flex-row my-[20px] justify-end gap-x-[5px]">
      <CommonButton
        category="greySearch"
        onClick={() => {
          openAdvancedSearch();
        }}
        border="0"
        backgroundColor="transparent"
        color="#666666"
      >
        Search
      </CommonButton>
      <div className="flex h-[14px] border-l-[0.4px] border-[#00000099] self-center" />
      <CommonButton
        category="customizeColumn"
        onClick={() => {
          setCustomizeCols(true);
        }}
        border="0"
        backgroundColor="transparent"
        color="#666666"
      >
        Customize Columns
      </CommonButton>
      <CommonButton
        category="excel"
        onClick={() => {
          exportToExcel({ gridRef, fileName: EXCEL_FILE_NAMES?.[category] });
        }}
      >
        EXCEL
      </CommonButton>
    </div>
  );
};

export default HeaderButtons;
