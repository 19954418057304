/** Error Code */
export const QB_NOT_FOUND = 'qb_not_found';
export const QB_STALE_OBJECT = 'qb_stale_object';
export const QB_REFRESH_FAIL = 'qb_refresh_fail';
export const QB_INVALID = 'qb_invalid';
export const QB_UNKNOWN_ERROR = 'qb_unknown_error';
export const QB_UNAUTHENTICATED = 'qb_unauthenticated';
export const QB_AUTH_CODE = 'qb_auth_code';
export const QB_ALREADY_EXIST = 'qb_already_exist';
export const QB_INVOICE_NOT_FOUND = 'qb_invoice_not_found';
export const QB_WORK_ORDER_NOT_FOUND = 'qb_work_order_not_found';
