import React from 'react';
import { FormProvider } from 'react-hook-form';
import CommonMessageModal from '../../../CommonMessageModal/CommonMessageModal';
import AdvancedSearchModalContent from '../AdvancedSearchModalContent';

const AdvancedSearchModal = ({
  isOpenModal,
  setIsOpenModal,
  queryObject,
  setQueryObject,
  searchController,
  errorModalOptions,
}) => {
  return (
    <FormProvider {...searchController}>
      <form>
        <CommonMessageModal
          header="Advanced Search"
          open={isOpenModal}
          setOpen={setIsOpenModal}
          width="588px"
          height="397px"
          content={
            <AdvancedSearchModalContent
              queryObject={queryObject}
              setOpenModal={setIsOpenModal}
              setQueryObject={setQueryObject}
              errorModalOptions={errorModalOptions}
            />
          }
        />
      </form>
    </FormProvider>
  );
};

export default AdvancedSearchModal;
