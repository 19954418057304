const generateProductCodeSelectOption = ({ productCodes }) => {
  const productCodeSelectOption = productCodes.map(productCode => {
    return {
      label: `${productCode?.product_code} / ${productCode?.product_code_name}`,
      value: productCode?.product_code,
    };
  });
  return productCodeSelectOption;
};

export default generateProductCodeSelectOption;
