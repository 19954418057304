const getURLSearchParamsObject = params => {
  const urlSearchParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(item => {
        urlSearchParams.append(key, item);
      });
    } else {
      urlSearchParams.append(key, value);
    }
  });
  return urlSearchParams;
};

export default getURLSearchParamsObject;
