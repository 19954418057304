import axios from 'axios';
import interceptRequest from '../../utils/Common/Interceptor/interceptRequest';

const BC_DETAIL_BASE_URL = `${process.env.REACT_APP_BASE_URL}/bc-detail/`;
// const BC_DETAIL_BASE_URL = `http://192.168.50.111:3000/bc-detail/`;

/**
 * Axios object with base url
 */
const bcDetailDefaultAPI = axios.create({
  withCredentials: true,
  baseURL: BC_DETAIL_BASE_URL,
  xsrfHeaderName: 'X-CSRFTOKEN',
  xsrfCookieName: 'XCSRF-TOKEN',
  headers: {
    common: {
      'X-CSRFToken': localStorage.getItem('csrfToken'),
    },
  },
});

/** Interceptors */
interceptRequest(bcDetailDefaultAPI);

export default bcDetailDefaultAPI;
