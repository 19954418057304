import React, { useEffect, useState } from 'react';
import DriverModalContentSearch from '../DriverModalContentSearch';
import DriverModalContentTable from '../DriverModalContentTable';

const DriverModalContent = ({
  driverList,
  isPending,
  setSelectedScheduleSeq,
  setSelectedDriverData,
  setOpen,
  setAPModalOpen,
  getAPDataRefetch,
  ...props
}) => {
  const [searchedDriverInfo, setSearchedDriverInfo] = useState('');
  const [filteredDriverList, setFilteredDriverList] = useState(
    driverList || [],
  );

  useEffect(() => {
    setFilteredDriverList(
      prev =>
        driverList
          ?.filter(
            info =>
              info?.driver
                ?.toLowerCase()
                ?.includes(searchedDriverInfo?.toLowerCase()) ||
              info?.name
                ?.toLowerCase()
                ?.includes(searchedDriverInfo?.toLowerCase()),
          )
          ?.slice(0, 50) || [],
    );
  }, [searchedDriverInfo, driverList]);

  return (
    driverList && (
      <div className="flex flex-col justify-center items-center gap-[12.5px]">
        <DriverModalContentSearch
          driverList={driverList}
          setSearchedDriverInfo={setSearchedDriverInfo}
        />
        {driverList && filteredDriverList && (
          <DriverModalContentTable
            driverList={filteredDriverList}
            isPending={isPending}
            setSelectedScheduleSeq={setSelectedScheduleSeq}
            setSelectedDriverData={setSelectedDriverData}
            setOpen={setOpen}
            setAPModalOpen={setAPModalOpen}
            getAPDataRefetch={getAPDataRefetch}
          />
        )}
      </div>
    )
  );
};

export default DriverModalContent;
