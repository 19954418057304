import React from 'react';

const Tooltip = ({ message }) => {
  return (
    <div className="relative w-[76px] h-[25px] flex items-center">
      <div>
        <img
          src="/assets/images/common_icon/speechBubble.svg"
          width={76}
          height={25}
          alt="speechBubble"
        />
      </div>
      <div className="absolute top-0.5 left-0 w-full h-full flex items-center justify-center text-[12px] font-[500]">
        {message}
      </div>
    </div>
  );
};

export default Tooltip;
