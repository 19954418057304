import bcDetailDefaultApi from '../../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

const patchDetailInfo = async ({ woNo, data }) => {
  try {
    const response = await bcDetailDefaultApi.patch(`${woNo}`, data);
    return response;
  } catch (e) {
    throw new Error(handleQueryError({ error: e }));
  }
};

export default patchDetailInfo;
