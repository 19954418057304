import { isNumber } from '@mui/x-data-grid/internals';
import convertStatusToStandardCode from './convertStatusToStandardCode';

const setButtonCount = ({ main, sub, data }) => {
  const mainKey = convertStatusToStandardCode({ status: main?.status, data });
  if (isNumber(mainKey)) {
    return mainKey?.toString();
  }

  let subKey;
  if (sub?.key?.includes('all')) {
    subKey = 'total';
  } else if (sub?.key?.includes('no')) {
    subKey = 'non-existed';
  } else if (sub?.key?.includes('yard')) {
    subKey = 'yard';
  } else {
    subKey = 'existed';
  }

  return data?.[mainKey]?.[subKey]?.toString();
};

export default setButtonCount;
