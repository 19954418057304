import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';
import bookingDefaultAPI from '../../../bookingAPI';

const patchCompleteBooking = async ({ bookingSeq }) => {
  try {
    const response = await bookingDefaultAPI.patch(
      `detail/complete/${bookingSeq}`,
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchCompleteBooking;
