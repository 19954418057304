import bcDetailDefaultApi from '../../../api';
import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';

const getProofPDF = async ({ woNo, from, to }) => {
  // Create query parameter according to from, to location
  let queryParameter = '';
  if (from && to) {
    queryParameter = `?from=${from}&to=${to}`;
  } else if (from && !to) {
    queryParameter = `?from=${from}`;
  } else if (!from && to) {
    queryParameter = `?to=${to}`;
  }

  try {
    const response = await bcDetailDefaultApi.get(
      `${woNo}/pod-pol${queryParameter}`,
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getProofPDF;
