import dayjs from 'dayjs';
import { CATEGORY_OPTION } from '../../../../constants/Acct/Common/selectOptions';
import changePeriodHandle from '../../APARUninvoiced/Handler/changePeriodHandle';

/** Handle Changing AP/AR Type */
export const changeType = ({ watch, setValue, e, ...props }) => {
  const { onChange, setIsDisabledFactoring, setCategoryOption } = props;

  const typeValue = e?.target?.value;
  const categoryValue = watch('category');

  onChange?.(typeValue);

  /** Set Factoring Disabled */
  const enabledFactoring =
    (categoryValue === 'customer' && typeValue === 'ALL') || typeValue === 'AR';

  setIsDisabledFactoring(!enabledFactoring);
  setValue('factoring', enabledFactoring ? 'ALL' : '');

  /** Set Category Option by AP/AR Type */
  const tempOption = CATEGORY_OPTION?.[typeValue];
  setCategoryOption(tempOption || []);

  if (!tempOption?.map(option => option?.value)?.includes?.(categoryValue)) {
    setValue('category', '');
    setValue('keyword', undefined);
  }
};

/** Handle Changing Category */
export const changeCategory = ({
  watch,
  setValue,
  resetField,
  e,
  ...props
}) => {
  const {
    onChange,
    setIsDisabledKeyWord,
    setIsDisabledFactoring,
    setIsShowDriverType,
    optionList,
  } = props;

  const categoryValue = e?.target?.value;
  const typeValue = watch('type');

  const enabledFactoring =
    (categoryValue === 'customer' && typeValue === 'ALL') || typeValue === 'AR';

  setValue('keyword', '');

  onChange?.(categoryValue);

  setIsShowDriverType(categoryValue === 'driver');
  setIsDisabledFactoring(!enabledFactoring);
  setIsDisabledKeyWord(categoryValue === '');

  setValue('factoring', enabledFactoring ? 'ALL' : '');
};

export const changeDriverType = ({ watch, setValue, e, ...props }) => {
  const { onChange } = props;

  const driverType = e?.target?.value;

  setValue('keyword', '');
  onChange?.(driverType);
};

/** Set Period-Related fields */
export const changePeriod = ({ watch, setValue, e, ...props }) => {
  const { onChange, setLastDateRange } = props;

  const periodData = e?.target?.value;
  onChange(periodData);

  if (periodData) {
    const dateRange = changePeriodHandle(periodData);
    setValue('from_date', dateRange?.fromDate || '');
    setValue('to_date', dateRange?.toDate || '');
    setLastDateRange(dateRange);
  }
};

export const changeRangeDate = ({
  watch,
  setValue,
  type,
  lastDateRange,
  e,
  ...props
}) => {
  const { onChange } = props;
  const dateValue =
    e && dayjs(e)?.isValid() ? dayjs(e)?.format('YYYY-MM-DD') : null;
  onChange?.(dateValue);

  const compareKeyName = type === 'from' ? 'fromDate' : 'toDate';

  if (lastDateRange?.[compareKeyName] !== dateValue) {
    setValue('period', '');
  }
};
