import masterServiceCodeDefaultApi from '../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

const deleteServiceCode = async ({ pk, setInUseWOList, division }) => {
  try {
    const response = await masterServiceCodeDefaultApi.delete(
      `productcode/${encodeURIComponent(pk)}?div=${division}`,
    );
    return response;
  } catch (error) {
    if (error?.response?.data?.code === 'assigned') {
      setInUseWOList(error?.response?.data?.wo_list);
    }
    throw new Error(handleQueryError({ error }));
  }
};

export default deleteServiceCode;
