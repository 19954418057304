import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import getAcctCodeList from '../../../services/Common/Master/getAcctCodeList';
import masterKeys from '../keys';
import createPairOption from '../../../utils/Master/ServiceCode/Generator/createPairOption';

const useGetAcctCode = ({ setPairOption }) => {
  const query = useQuery({
    queryKey: masterKeys.acctCodeList(),
    queryFn: getAcctCodeList,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!query.isLoading && !query.isError) {
      setPairOption(
        createPairOption({
          option: query?.data?.data,
          pair: ['acct_code', 'acct_code_name'],
        }),
      );
    }
  }, [query.isLoading, query.isError]);

  return query;
};

export default useGetAcctCode;
