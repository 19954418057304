import { createDropdownOptions } from '../../../utils/Common/createDropdownOptions';

export const TIERWIEHT_STATUS_OBJECT = {
  AWAITING_EFFECT: 'TS0001',
  EFFECTIVE: 'TS0002',
  EXPIRED: 'TS0003',
};
export const TIERWIEHT_STATUS = [
  {
    value: 'AWAITING_EFFECT',
    label: 'AWAITING_EFFECT',
  },
  {
    value: 'EFFECTIVE',
    label: 'EFFECTIVE',
  },
  {
    value: 'EXPIRED',
    label: 'EXPIRED',
  },
];
export const tierDropdownOptions = createDropdownOptions([
  'T1',
  'T2',
  'T3',
  'T4',
  'T5',
]);
export const typeDropdownOptions = createDropdownOptions(['DAILY', 'FLAT']);
