import { Box, OutlinedInput } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const Input = ({ ...props }) => {
  const MaxLength = props.maxLength ? props.maxLength : 20;

  const [fieldSubject, setFieldSubject] = useState(props.fieldSubject);
  const [placeHolder, setPlaceHolder] = useState(props.placeHolder);
  const [lengthCheck, setLengthCheck] = useState(props.lengthCheck);
  const [disabled, setDisabled] = useState(props.disabled);
  const [required, setRequired] = useState(props.isRequired);
  const [text, setText] = useState(props?.defaultValue || '');
  const [textLength, setTextLength] = useState(0);
  const [maxTextLength, setMaxTextLength] = useState(MaxLength);
  const [visible, setVisible] = useState(props.isVisible);
  const [dollarSign, setSollarSign] = useState(props?.dollarSign);
  const [defaultValue, setDefaultValue] = useState(props?.defaultValue);
  const [textAlign, setTextAlign] = useState(props?.textAlign);

  const { control, unregister, getValues } = useFormContext();

  useEffect(() => {
    return () => unregister(props?.inputName);
  }, []);

  useEffect(() => {
    setDisabled(props?.disabled);
  }, [props?.disabled]);

  useEffect(() => {
    if (props?.defaultValue !== undefined && props?.defaultValue !== null) {
      setText(props?.defaultValue);
      setDefaultValue(props?.defaultValue);
    }
  }, [props?.defaultValue]);

  useEffect(() => {
    if (props?.textAlign !== undefined && props?.textAlign !== null) {
      setTextAlign(props?.textAlign);
    }
  }, [props?.textAlign]);

  useEffect(() => {
    if (getValues(props?.inputName) === '') {
      setText('');
    }
  }, [getValues(props?.inputName)]);

  return (
    <Box className="input_comp" sx={{ width: '100%' }}>
      {!!fieldSubject && (
        <Typography
          variant="h2"
          component="h2"
          sx={{
            fontSize: '11px',
            marginBottom: '5px',
            color: '#666',
            lineHeight: '1.14',
          }}
        >
          {!!required && (
            <Typography
              variant="span"
              component="span"
              sx={{ color: '#ff2424', fontSize: '12px', marginRight: '5px' }}
            >
              *
            </Typography>
          )}
          {fieldSubject}
        </Typography>
      )}
      <Controller
        control={control}
        name={props?.inputName}
        defaultValue={defaultValue}
        render={({ field: { onChange, ...field } }) => {
          return (
            <OutlinedInput
              {...field}
              value={text}
              onClick={e =>
                props?.onClickHandler?.({
                  e,
                  onChange,
                  setText,
                  maxTextLength,
                  isInt: props.isInt,
                  setTextLength,
                  ...props,
                })
              }
              onChange={e =>
                props?.onChangeHandler?.({
                  e,
                  onChange,
                  setText,
                  maxTextLength,
                  isInt: props.isInt,
                  setTextLength,
                  ...props,
                })
              }
              onKeyDown={e => props?.onKeyDownHandler?.({ e })}
              fullWidth
              size="small"
              sx={{
                padding: 0,
                borderRadius: 'unset',
                height: '24px',
                '& .MuiInputBase-input': {
                  height: 'auto',
                  padding: '5px 10px',
                  fontSize: '11px',
                  color: !disabled ? '#666' : '#A6A6A6',
                  fontWeight: 500,
                  WebkitTextFillColor: !disabled ? '#666' : '#A6A6A6',
                  textAlign: `${textAlign || 'left'} !important`,
                },
                '& .MuiInputBase-input.Mui-disabled': {
                  height: 'auto',
                  padding: '5px 10px',
                  fontSize: '11px',
                  color: !disabled ? '#666' : '#A6A6A6',
                  fontWeight: 500,
                  WebkitTextFillColor: !disabled ? '#666' : '#A6A6A6',
                },
                '& fieldset': {
                  border: '0px',
                  top: 0,
                  padding: 0,
                  height: '100%',
                  transition: '.4s',
                },
                '& legend': {
                  display: 'none',
                },
                '&.Mui-disabled input': {
                  WebkitTextFillColor: '#222 !important',
                },
                marginTop: '2px',
              }}
              startAdornment={
                !!dollarSign && (
                  <InputAdornment
                    position="start"
                    className="dollarSignClass"
                    sx={{
                      paddingLeft: '5px',
                      '.MuiTypography-root': {
                        fontSize: '11px',
                        color: !disabled ? '#666' : '#A6A6A6',
                        fontWeight: 500,
                      },
                    }}
                  >
                    $
                  </InputAdornment>
                )
              }
              endAdornment={
                <>
                  {!!lengthCheck && (
                    <InputAdornment position="end">
                      {textLength}/{maxTextLength}
                    </InputAdornment>
                  )}
                  {props?.activeClear && !props?.disabled && (
                    <InputAdornment
                      position="end"
                      style={{ cursor: 'pointer' }}
                      onClick={e => props?.onClearHandler?.({ e, onChange })}
                    >
                      X
                    </InputAdornment>
                  )}
                </>
              }
              inputProps={{ readOnly: props?.readOnly }}
              disabled={disabled}
              required={required}
              placeholder={disabled ? '' : placeHolder}
            />
          );
        }}
      />
    </Box>
  );
};

export default Input;
