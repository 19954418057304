import DAILY_REPORT_EDITABLE_COLUMNS from '../../../../constants/DailyReport/Common/editableColumnList';
import openBCDetail from '../../../Common/openBCDetail';

const handleClick = ({
  gridParam,
  clickFocusedCell,
  setClickFocusedCell,
  page,
  isEditable,
}) => {
  const editableCols = isEditable ? DAILY_REPORT_EDITABLE_COLUMNS?.[page] : [];
  if (!clickFocusedCell || clickFocusedCell?.rowIndex === gridParam?.rowIndex) {
    if (!editableCols?.includes(gridParam?.column?.colId))
      openBCDetail({ woNo: gridParam?.data?.wo_no });
  } else {
    setClickFocusedCell(null);
  }
};

export default handleClick;
