import { BARE_CHASSIS_DEFAULT_FIELDS } from '../BareChassis/bareChassisBasicFieldData';
import { BROKERAGE_DEFAULT_FIELDS } from '../Brokerage/brokerageBasicFieldData';
import { EXPORT_DEFAULT_FIELDS } from '../Export/exportBasicFieldData';
import { IMPORT_DEFAULT_FIELDS } from '../Import/importBasicFieldData';
import { VAN_DEFAULT_FIELDS } from '../Van/vanBasicFieldData';
import {
  MAIN_CATEGORY_BARE_CHASSIS,
  MAIN_CATEGORY_BROKERAGE,
  MAIN_CATEGORY_EXPORT,
  MAIN_CATEGORY_IMPORT,
  MAIN_CATEGORY_VAN,
} from './category';

const DEFAULT_DEFS = {
  [MAIN_CATEGORY_IMPORT]: IMPORT_DEFAULT_FIELDS,
  [MAIN_CATEGORY_EXPORT]: EXPORT_DEFAULT_FIELDS,
  [MAIN_CATEGORY_VAN]: VAN_DEFAULT_FIELDS,
  [MAIN_CATEGORY_BROKERAGE]: BROKERAGE_DEFAULT_FIELDS,
  [MAIN_CATEGORY_BARE_CHASSIS]: BARE_CHASSIS_DEFAULT_FIELDS,
};

export default DEFAULT_DEFS;
