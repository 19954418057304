import React from 'react';
import CommonToggle from '../../../Inputs/CommonToggle/CommonToggle';

const ToggleWithText = ({
  name,
  inputName,
  isBorder,
  isActive,
  div,
  mutation,
  seq,
}) => {
  return (
    <div
      className={`flex justify-between align-center w-[160px] h-[30px] ${
        isBorder ? 'rounded-[5px] border border-zinc-300 ' : ''
      }`}
    >
      <div className="text-black text-xs font-medium  mt-[6px] ml-[12px]">
        {name}
      </div>
      <div className="mt-[-15px] mr-[2px]">
        <CommonToggle
          isBetween
          options={[
            {
              defaultValue: isActive,
              inputName: `${inputName}`,
            },
          ]}
          onChangeHandler={({ e, onChange, setDisabled, ...handlerProps }) => {
            setDisabled(true);
            onChange(e?.target?.checked);
            mutation({ div, type: inputName, seq, setDisabled });
          }}
        />
      </div>
    </div>
  );
};

export default ToggleWithText;
