const handleCheckboxChange = ({
  e,
  setQuery,
  item,
  filterStorage,
  ...handleProps
}) => {
  setQuery(prev => {
    return { ...prev, [handleProps?.name]: e?.target?.checked };
  });
  handleProps?.onChange?.(e?.target?.checked);

  if (filterStorage) {
    const { setFilter } = filterStorage;
    setFilter(prev => {
      return {
        ...prev,
        [item]: {
          ...prev?.[item],
          [handleProps?.name]: e?.target?.checked,
        },
      };
    });
  }
};

export default handleCheckboxChange;
