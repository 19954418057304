import React from 'react';
import StyledScheduleAPModalTableContentHeaderWrapper from '../../../../../../../styles/BCDetail/DetailInfo/Schedule/StyledScheduleAPModalTableContentHeaderWrapper';
import StyledScheduleAPModalTableContentHeaderCell from '../../../../../../../styles/BCDetail/DetailInfo/Schedule/StyledScheduleAPModalTableContentHeaderCell';

const ScheduleAPModalTableContentHeaderCell = ({
  children,
  content,
  customStyle,
  ...props
}) => {
  return (
    <StyledScheduleAPModalTableContentHeaderCell {...customStyle} {...props}>
      {children}
    </StyledScheduleAPModalTableContentHeaderCell>
  );
};

export default ScheduleAPModalTableContentHeaderCell;
