import { useQuery } from '@tanstack/react-query';
import getAPProductCode from '../../../../services/Admin/DivConf/Get/getAPProductCode';
import adminKeys from '../../keys';

const useGetAPProductCode = () => {
  const { isLoading: isLoadingAPProductCode, data } = useQuery({
    queryKey: adminKeys?.apProductCode(),
    queryFn: () => getAPProductCode(),
    refetchOnWindowFocus: false,
  });

  return {
    isLoadingAPProductCode,
    data: data?.data,
  };
};

export default useGetAPProductCode;
