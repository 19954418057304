import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useGetARData from '../../../hooks/BCDetail/BillingInfo/AR/useGetARData';
import useGetARProductCode from '../../../hooks/BCDetail/BillingInfo/AR/useGetARProductCode';
import useGetCompanyList from '../../../hooks/BCDetail/BillingInfo/AR/useGetDriverVendorList';
import usePatchARInvoice from '../../../hooks/BCDetail/BillingInfo/AR/usePatchARInvoice';
import usePostQB from '../../../hooks/BCDetail/BillingInfo/Common/usePostQB';
import StyledBCDetailBillingInfoCommonApArFormContainer from '../../../styles/BCDetail/BillingInfo/Common/StyledBCDetailBillingInfoCommonApArFormContainer';
import StyledBCDetailBillingInfoCommonApArTable from '../../../styles/BCDetail/BillingInfo/Common/StyledBCDetailBillingInfoCommonApArTable';
import ARHeader from './Component/ARHeader';
import ARTableBody from './Component/ARTableBody';
import ARTableFooter from './Component/ARTableFooter';
import ARTableHeader from './Component/ARTableHeader';

const AR = ({
  setProfit,
  setIsAlertOpen,
  setIsSuccess,
  setErrorMessage,
  isAREditable,
  isDeletable,
  isQBAvailable,
  setIsDeletedWO,
  isSubmitting,
  setIsSubmitting,
}) => {
  const ARTableFormController = useForm();

  const [checkedRow, setCheckedRow] = useState([]);
  const [isCredit, setIsCredit] = useState(false);
  const [isCreditHoldCompany, setIsCreditHoldCompany] = useState(false);
  const [isQBDisabled, setIsQBDisabled] = useState(false);

  const ARQuery = useGetARData({
    setIsAlertOpen,
    setErrorMessage,
  });
  const ARProductCodeQuery = useGetARProductCode({
    setIsAlertOpen,
    setErrorMessage,
    setIsDeletedWO,
  });
  const ARCompanyList = useGetCompanyList({
    setIsAlertOpen,
    setErrorMessage,
  });
  const { mutate: arInvoiceMutator } = usePatchARInvoice({
    setIsAlertOpen,
    setErrorMessage,
    setIsSuccess,
    refetchARData: ARQuery?.refetchARData,
    setIsQBDisabled,
  });
  const { mutate: qbMutator } = usePostQB({
    setIsAlertOpen,
    setErrorMessage,
    setIsSuccess,
    refetchData: ARQuery?.refetchARData,
  });

  useEffect(() => {
    const sumAR = checkedRow.reduce(
      (acc, obj) =>
        acc + ARQuery.ARData?.find(element => element?.seq === obj)?.ar_total,
      0,
    );
    if (sumAR < 0) {
      setIsCredit(true);
    } else {
      setIsCredit(false);
    }
  }, [checkedRow]);

  return (
    <>
      <ARHeader
        ARQuery={ARQuery}
        arInvoiceMutator={arInvoiceMutator}
        qbMutator={qbMutator}
        ARTableFormController={ARTableFormController}
        isCredit={isCredit}
        setIsAlertOpen={setIsAlertOpen}
        setIsSuccess={setIsSuccess}
        setErrorMessage={setErrorMessage}
        isAREditable={isAREditable}
        isQBAvailable={isQBAvailable}
        isQBDisabled={isQBDisabled}
      />
      <StyledBCDetailBillingInfoCommonApArFormContainer>
        <FormProvider {...ARTableFormController}>
          <form>
            <StyledBCDetailBillingInfoCommonApArTable>
              <ARTableHeader
                ARProductCodeQuery={ARProductCodeQuery}
                ARCompanyList={ARCompanyList}
                checkedRow={checkedRow}
                setIsCreditHoldCompany={setIsCreditHoldCompany}
                setIsAlertOpen={setIsAlertOpen}
                setIsSuccess={setIsSuccess}
                setErrorMessage={setErrorMessage}
                isAREditable={isAREditable}
                isSubmitting={isSubmitting}
                setIsSubmitting={setIsSubmitting}
                refetchARData={ARQuery?.refetchARData}
              />
              <ARTableBody
                ARQuery={ARQuery}
                ARProductCodeQuery={ARProductCodeQuery}
                ARCompanyList={ARCompanyList}
                setCheckedRow={setCheckedRow}
                setIsCreditHoldCompany={setIsCreditHoldCompany}
                setIsAlertOpen={setIsAlertOpen}
                setIsSuccess={setIsSuccess}
                setErrorMessage={setErrorMessage}
                isAREditable={isAREditable}
                isDeletable={isDeletable}
                refetchARData={ARQuery?.refetchARData}
                setIsQBDisabled={setIsQBDisabled}
              />
              <ARTableFooter ARQuery={ARQuery} setProfit={setProfit} />
            </StyledBCDetailBillingInfoCommonApArTable>
          </form>
        </FormProvider>
      </StyledBCDetailBillingInfoCommonApArFormContainer>
    </>
  );
};

export default AR;
