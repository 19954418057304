import {
  DISPATCH_TYPE_AVAILABLE,
  DISPATCH_TYPE_DELIVERY,
  DISPATCH_TYPE_RETURN,
  DISPATCH_TYPE_UNAVAILABLE,
} from '../../../../constants/Dispatch/Common/category';

const getColumnCustomStatus = status => {
  if (status === DISPATCH_TYPE_UNAVAILABLE) {
    return 'Unavailable';
  }
  if (status === DISPATCH_TYPE_AVAILABLE) {
    return 'Available';
  }
  if (status === DISPATCH_TYPE_DELIVERY) {
    return 'Delivery';
  }
  if (status === DISPATCH_TYPE_RETURN) {
    return 'Return';
  }
  return '';
};

export default getColumnCustomStatus;
