import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { FormProvider, useForm } from 'react-hook-form';
import CommonTextInput from '../../../Inputs/CommonTextInput/CommonTextInput';
import CommonSelect from '../../../Inputs/CommonSelect/CommonSelect';
import CommonDate from '../../../Inputs/CommonDate/CommonDate';
import CommonButton from '../../../CommonButton/CommonButton';
import { REGEX_NUMBER_MINUS_DECIMAL } from '../../../../constants/Common/regex';
import { RECONCILE_STATUS } from '../../../../constants/Acct/PediemReconcile/staticNames';
import {
  changeDate,
  changeSelect,
  changeText,
} from '../../../../utils/Acct/Common/Handler/changedInputFieldHandler';
import { deleteForm } from '../../../../utils/Acct/Common/Handler/formListHandler';
import handleWOChange from '../../../../utils/Acct/PerdiemReconcile/Handler/handleWOChange';
import useGetWOList from '../../../../hooks/Acct/PerdiemReconcile/Get/useGetWOList';
import {
  NULL_PARAMETER_VALUE,
  SAVE_TYPE,
} from '../../../../constants/Acct/Common/staticNames';
import saveInvoiceForm from '../../../../utils/Acct/Common/Handler/saveInvoiceForm';
import { handleModalWOOptionChange } from '../../../../utils/Acct/Common/Handler/handleWOOptionChange';
import generateInvoiceDateRange from '../../../../utils/Acct/Common/Generator/generateInvoiceDateRange';
import CommonAutoComplete from '../../../Inputs/CommonAutoComplete/CommonAutoComplete';
import useCheckFormValidity from '../../../../hooks/Common/Input/useCheckFormValidity';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';

const InvoiceFormWrapper = ({
  index,
  formKey,
  invoice,
  sslList,
  scannedFile,
  setFormList,
  setInvoiceList,
  handleSubmitVendor,
  setSubmitLength,
  setSubmitData,
  postPerdiemMutator,
  duplicateCheckFields,
  setIsAllSave,
  formList,
  setAlwaysFlag,
  setProgressLength,
  setDuplicationErrorList,
  setResolveErrorList,
  setApplyType,
  setErrorModalOptions,
  setBasicErrorMessage,
  setIsCompletedPatchFlag,
}) => {
  const invoiceController = useForm();
  const { setValue, getValues } = invoiceController;

  /** Duplicate click preventer and Form validator */
  const saveRef = useRef(null);
  const formStates = [invoiceController?.formState];
  const { isFormInvalid } = useCheckFormValidity({ formStates });

  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    /* mount */
    setFormList(prevForm => [
      ...prevForm,
      { key: formKey, form: invoiceController, buttonRef: saveRef },
    ]);

    /* unmount */
    return () => {
      setFormList(prevForm => prevForm?.filter(form => form?.key !== formKey));
    };
  }, []);
  const [woQueryObject, setWOQueryObject] = useState({});

  const { woOptionList, woObjectList, isLoadingWO } = useGetWOList({
    queryObject: woQueryObject,
    sslList,
    isFocused,
  });

  useEffect(() => {
    handleModalWOOptionChange({
      woFieldName: 'wo_no',
      setValue,
      getValues,
      woOptionList,
      pairFieldList: ['bk_no', 'st', 'div', 'from', 'to', 'ssl'],
    });
  }, [woOptionList]);

  useEffect(() => {
    if (isFocused) {
      setWOQueryObject({
        ...generateInvoiceDateRange(getValues('inv_from')),
        cont_no: getValues('cont_no') || NULL_PARAMETER_VALUE,
      });
    }
  }, [isFocused]);

  return (
    <FormProvider {...invoiceController}>
      <form>
        <div
          className="w-[1025px] h-[201px] rounded-[5px] bg-[#F2F4F8] pl-[26px] pr-[22px] pt-[18px]"
          onFocus={() => {
            setIsFocused(true);
          }}
        >
          <div className="w-full flex flex-row justify-between pb-[11px]">
            <span className="font-[700] text-[13px] text-[#264B9F]">
              #{index + 1}
            </span>
            <div className="w-full flex flex-row justify-end gap-x-[9px]">
              <CommonButton
                type="button"
                category="greyDelete"
                size="small"
                borderColor="#666666"
                onClick={() => {
                  deleteForm({
                    formKey,
                    setInvoiceList,
                  });
                }}
              >
                Delete
              </CommonButton>
              <CommonButton
                ref={saveRef}
                type="button"
                category="save"
                size="small"
                preventDupClick
                onClick={() => {
                  isFormInvalid &&
                    updateButtonDisabled({ ref: saveRef, disabled: false });
                  saveInvoiceForm({
                    scannedFile,
                    handleSubmitVendor,
                    formList: [formList?.find(form => form?.key === formKey)],
                    setSubmitLength,
                    setSubmitData,
                    postMutator: postPerdiemMutator,
                    setAlwaysFlag,
                    duplicateCheckFields,
                    setIsAllSave,
                    setErrorModalOptions,
                    setProgressLength,
                    setDuplicationErrorList,
                    setResolveErrorList,
                    setApplyType,
                    saveType: SAVE_TYPE?.ONE,
                    setBasicErrorMessage,
                    setIsCompletedPatchFlag,
                    type: 'perDiem',
                  });
                }}
              >
                Save
              </CommonButton>
            </div>
          </div>
          <div className="w-full h-[1px] bg-[#FFFFFF] mb-[15px]" />
          <div
            className="px-[4px] h-[100px] flex flex-col justify-between"
            onFocus={() => {
              setIsFocused(true);
            }}
          >
            <div className="flex flex-row justify-start gap-x-[17px] items-center mt-[8px]">
              <div className="w-[140px]">
                <CommonSelect
                  label="STATUS"
                  option={RECONCILE_STATUS}
                  required
                  inputName="status"
                  defaultValue={invoice?.status || ''}
                  onChangeHandler={changeSelect}
                />
              </div>
              <div className="w-[160px]">
                <CommonTextInput
                  label="INV #"
                  required
                  inputName="inv_no"
                  defaultValue={invoice?.inv_no || ''}
                  onChangeHandler={changeText}
                  onBlurHandler={({ e, onChange }) => {
                    const value = e?.target?.value?.toUpperCase();
                    onChange(value);
                  }}
                />
              </div>
              <div className="w-[134px]">
                <CommonDate
                  required
                  defaultValue={invoice?.inv_date || ''}
                  label="INV DATE"
                  inputName="inv_date"
                  onChangeHandler={changeDate}
                />
              </div>
              <div className="w-[134px]">
                <CommonDate
                  inputName="inv_from"
                  defaultValue={invoice?.inv_from || ''}
                  required
                  label="FROM"
                  onChangeHandler={({ e, ...props }) => {
                    changeDate({ e, ...props });
                    const invDate =
                      e && dayjs(e)?.isValid()
                        ? dayjs(e)?.format('YYYY-MM-DD')
                        : '';
                    setWOQueryObject(prev => ({
                      ...prev,
                      ...generateInvoiceDateRange(invDate),
                    }));
                  }}
                />
              </div>
              <div className="w-[134px]">
                <CommonDate
                  defaultValue={invoice?.inv_to || ''}
                  inputName="inv_to"
                  required
                  label="TO"
                  onChangeHandler={changeDate}
                />
              </div>
              <div className="w-[134px]">
                <CommonTextInput
                  label="AMT"
                  inputName="inv_amt"
                  required
                  defaultValue={invoice?.inv_amt || ''}
                  onChangeHandler={changeText}
                  regex={REGEX_NUMBER_MINUS_DECIMAL}
                  adornment={{
                    position: 'start',
                    content: '$',
                    styles: {
                      fontSize: '12px',
                      color: 'black',
                      fontWeight: '700',
                    },
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row gap-x-[17px] justify-start">
              <div className="w-[69px]">
                <CommonTextInput
                  label="DIV"
                  inputName="div"
                  defaultValue={invoice?.div || ''}
                  disabled
                  required
                  onChangeHandler={({ e, onChange }) => {
                    onChange(e?.target?.value);
                  }}
                />
              </div>
              <div className="w-[114px]">
                <CommonTextInput
                  label="CONT #"
                  inputName="cont_no"
                  defaultValue={invoice?.cont_no || ''}
                  required
                  onChangeHandler={({ e, onChange }) => {
                    onChange(e?.target?.value);
                  }}
                  onBlurHandler={({ e, onChange }) => {
                    const value = e?.target?.value?.toUpperCase();
                    onChange(value);
                    setWOQueryObject(prev => ({
                      ...prev,
                      cont_no: value || NULL_PARAMETER_VALUE,
                    }));
                  }}
                />
              </div>
              <div className="w-[112px]">
                <CommonSelect
                  label="SSL"
                  option={sslList}
                  inputName="ssl"
                  defaultValue={invoice?.ssl || ''}
                  required
                  disabled
                  onChangeHandler={changeSelect}
                />
              </div>
              <div className="w-[137px]">
                <CommonAutoComplete
                  label="WO #"
                  inputName="wo_no"
                  option={woOptionList || []}
                  required
                  defaultValue={invoice?.wo_no || ''}
                  isLoading={isLoadingWO}
                  onChangeHandler={handleWOChange}
                  woObjectList={woObjectList}
                  changeFieldNameList={[
                    'from',
                    'div',
                    'to',
                    'st',
                    'bk_no',
                    'ssl',
                    'cont_no',
                  ]}
                  setValue={setValue}
                  sslList={sslList}
                  setWOQueryObject={setWOQueryObject}
                />
              </div>
              <div className="w-[110px]">
                <CommonTextInput
                  label="MBL/BK #"
                  inputName="bk_no"
                  disabled
                  defaultValue={invoice?.bk_no || ''}
                  onChangeHandler={changeText}
                />
              </div>
              <div className="w-[106px]">
                <CommonDate
                  inputName="st"
                  label="S/T"
                  disabled
                  onChangeHandler={changeDate}
                />
              </div>
              <div className="w-[106px]">
                <CommonDate
                  inputName="from"
                  label="FROM"
                  disabled
                  onChangeHandler={changeDate}
                />
              </div>
              <div className="w-[106px]">
                <CommonDate
                  inputName="to"
                  label="TO"
                  disabled
                  onChangeHandler={changeDate}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default InvoiceFormWrapper;
