import tmsReportAPI from '../../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import { SCHEDULE_PAGE_TYPE } from '../../../../constants/TmsReport/Schedules';

const getSchedule = async ({ type, params }) => {
  try {
    const response = await tmsReportAPI.get(
      `/daily-schedule/${SCHEDULE_PAGE_TYPE?.[type]}`,
      {
        params,
      },
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getSchedule;
