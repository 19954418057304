const IMPORT_UNA_EDITABLE_COLS = [
  'ch_no',
  'pool',
  'lfd',
  'ava_dsc',
  'ava_obl',
  'ava_cus',
  'po_schedule',
  'dilv_schedule',
  'over_height',
  'over_weight',
  'hot',
  'hazmat',
  'remarks',
];
const IMPORT_AVA_EDITABLE_COLS = [
  'ch_no',
  'pool',
  'lfd',
  'lfd_date',
  'po_schedule',
  'dilv_schedule',
  'over_height',
  'over_weight',
  'hot',
  'hazmat',
  'remarks',
];
const IMPORT_DILV_EDITABLE_COLS = [
  'ch_no',
  'pool',
  'dilv_schedule',
  'pu_schedule',
  'empty_load_cfm',
  'dilv_empty_cfm',
  'over_height',
  'over_weight',
  'hot',
  'hazmat',
  'remarks',
];
const IMPORT_RTN_EDITABLE_COLS = [
  'ch_no',
  'pool',
  'pu_schedule',
  'rtn_schedule',
  'over_height',
  'over_weight',
  'hot',
  'hazmat',
  'remarks',
];

const EXPORT_UNA_EDITABLE_COLS = ['bk_seq', 'bk_no', 'st', 'remarks'];

const EXPORT_AVA_EDITABLE_COLS = [
  'ch_no',
  'pool',
  'bk_seq',
  'bk_no',
  'st',
  'po_schedule',
  'dilv_schedule',
  'over_height',
  'over_weight',
  'hot',
  'hazmat',
  'remarks',
];
const EXPORT_DILV_EDITABLE_COLS = [
  'ch_no',
  'pool',
  'dilv_schedule',
  'pu_schedule',
  'empty_load_cfm',
  'dilv_load_cfm',
  'over_height',
  'over_weight',
  'hot',
  'hazmat',
  'remarks',
];
const EXPORT_RTN_EDITABLE_COLS = [
  'ch_no',
  'pool',
  'pu_schedule',
  'rtn_schedule',
  'over_height',
  'over_weight',
  'hot',
  'hazmat',
  'remarks',
];

const VAN_AVA_EDITABLE_COLS = [
  'po_schedule',
  'dilv_schedule',
  'over_height',
  'over_weight',
  'hot',
  'hazmat',
  'remarks',
];
const VAN_DILV_EDITABLE_COLS = [
  'dilv_schedule',
  'pu_schedule',
  'over_height',
  'over_weight',
  'hot',
  'hazmat',
  'remarks',
];
const VAN_RTN_EDITABLE_COLS = [
  'pu_schedule',
  'rtn_schedule',
  'over_height',
  'over_weight',
  'hot',
  'hazmat',
  'remarks',
];

export const STATUS_EDITABLE_COLS = {
  imp_unava: IMPORT_UNA_EDITABLE_COLS,
  imp_ava: IMPORT_AVA_EDITABLE_COLS,
  imp_ava_yard: IMPORT_AVA_EDITABLE_COLS,
  imp_dilv: IMPORT_DILV_EDITABLE_COLS,
  imp_dilv_yard: IMPORT_DILV_EDITABLE_COLS,
  imp_rtn: IMPORT_RTN_EDITABLE_COLS,
  imp_rtn_yard: IMPORT_RTN_EDITABLE_COLS,
  exp_unava: EXPORT_UNA_EDITABLE_COLS,
  exp_unava_yard: EXPORT_UNA_EDITABLE_COLS,
  exp_ava: EXPORT_AVA_EDITABLE_COLS,
  exp_ava_yard: EXPORT_AVA_EDITABLE_COLS,
  exp_dilv: EXPORT_DILV_EDITABLE_COLS,
  exp_dilv_yard: EXPORT_DILV_EDITABLE_COLS,
  exp_rtn: EXPORT_RTN_EDITABLE_COLS,
  exp_rtn_yard: EXPORT_RTN_EDITABLE_COLS,
  van_ava: VAN_AVA_EDITABLE_COLS,
  van_dilv: VAN_DILV_EDITABLE_COLS,
  van_rtn: VAN_RTN_EDITABLE_COLS,
  bkg_ava: VAN_AVA_EDITABLE_COLS,
  bkg_dilv: VAN_DILV_EDITABLE_COLS,
  bkg_rtn: VAN_RTN_EDITABLE_COLS,
};
