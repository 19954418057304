import dayjs from 'dayjs';
import { RECONCILE_STATUS } from '../../../../constants/Acct/Common/acctElement';

export const generateDateValue = date => {
  const value =
    date && dayjs(date)?.isValid() ? dayjs(date).format('MM/DD/YY') : null;

  return value;
};

export const generateQBStatusValue = (data, statusList) => {
  const value = statusList?.find(status => status.value === data)?.label;
  return value || '-';
};

export const generateReconcileStatusValue = statusData => {
  const status = RECONCILE_STATUS?.filter(
    item => item.value === statusData,
  )?.[0]?.label;
  return status;
};

export const generatePoolValue = (poolData, poolList) => {
  const pool = poolList?.filter(item => item.value === poolData)?.[0]?.label;
  return pool;
};

export const generateSSLValue = (sslData, sslOption) => {
  let value = '';
  if (sslOption) {
    value = sslOption?.find(ssl => ssl?.value === sslData)?.label;
  }
  return value;
};
