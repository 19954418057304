import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import postMain from '../../services/CustomerReport/Post/postMain';
import useControlToast from '../Common/Toast/useControlToast';
import { SUCCESS_SAVE } from '../../constants/Common/successMessage';
import updateButtonDisabled from '../../utils/Common/Handler/updateButtonDisabled';

const usePostMain = ({
  setIsSuccess,
  setModalMessage,
  setMessageOpener,
  setOpen,
}) => {
  const { addToast } = useControlToast();

  const mutator = useMutation({
    mutationFn: postMain,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: () => {
      setOpen(false);
      addToast({ message: SUCCESS_SAVE });
    },
    onError: error => {
      setIsSuccess(false);
      setModalMessage(error?.message);
      setMessageOpener(true);
    },
  });

  return { ...mutator };
};

export default usePostMain;
