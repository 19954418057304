import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';
import { CELL_TYPE_TEXT } from '../../../../constants/Common/DataGrid/cellType';

const getNumberDef = field => {
  return {
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        cellType: CELL_TYPE_TEXT,
        customStyle: {
          textAlign: 'right',
        },
      }),
  };
};

export default getNumberDef;
