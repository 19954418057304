import React from 'react';
import CommonMessageModal from '../../../../../CommonMessageModal/CommonMessageModal';
import DriverChangeConfirmModalContent from './DriverChangeConfirmModalContent';
import DriverChangeConfirmModalButton from './DriverChangeConfirmModalButton';

const DriverChangeConfirmModal = ({ open, setOpen, onConfirm, ...props }) => {
  return (
    <CommonMessageModal
      open={open}
      setOpen={setOpen}
      header="Warning"
      content={<DriverChangeConfirmModalContent />}
      buttonContent={
        <DriverChangeConfirmModalButton
          setOpen={setOpen}
          onConfirm={onConfirm}
        />
      }
    />
  );
};

export default DriverChangeConfirmModal;
