import { useMutation, useQueryClient } from '@tanstack/react-query';
import patchMainColumn from '../../../services/Main/Patch/patchMainColumn';
import useControlToast from '../../Common/Toast/useControlToast';
import { SUCCESS_UPDATE } from '../../../constants/Common/successMessage';
import mainKeys from '../keys';
import updateButtonDisabled from '../../../utils/Common/Handler/updateButtonDisabled';

const usePatchMainColumn = ({
  setIsOpenAlertModal,
  setErrorMessage,
  setCustomizeCols,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();
  const mutate = useMutation({
    mutationFn: patchMainColumn,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: () => {
      addToast({ message: SUCCESS_UPDATE });
      setCustomizeCols(false);
      queryClient.invalidateQueries({ queryKey: mainKeys.all });
    },
    onError: error => {
      setIsOpenAlertModal(true);
      setErrorMessage(error?.message);
    },
  });

  return { ...mutate };
};

export default usePatchMainColumn;
