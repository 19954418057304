/**
 * @description
 * Handler for checking schedule constraint
 * @param {object} addData
 * Add schedule data
 * @return {boolean}
 * Is valid or not
 */
const handleAddScheduleConstraintCheck = ({
  addData,
  setMessageModalOpener,
  setMessageModalMessage,
}) => {
  /** Check company is entered */
  if (addData.company === '' || !addData?.company) {
    setMessageModalMessage('Please enter location ID');
    setMessageModalOpener(true);
    return false;
  }

  /** Check type is entered */
  if (addData.type === '' || !addData?.type) {
    setMessageModalMessage('Please enter schedule type');
    setMessageModalOpener(true);
    return false;
  }

  /** Check date and time */
  if (
    addData?.sche_date &&
    (!addData?.sche_time || addData?.sche_time === '')
  ) {
    setMessageModalMessage('Please enter schedule time');
    setMessageModalOpener(true);
    return false;
  }

  /** Check date and time */
  if (
    addData?.sche_time &&
    (!addData?.sche_date || addData?.sche_date === '')
  ) {
    setMessageModalMessage('Please enter schedule date');
    setMessageModalOpener(true);
    return false;
  }

  /** Pass verification */
  return true;
};

export default handleAddScheduleConstraintCheck;
