import React from 'react';
import useGetBasicInfo from '../../../../../hooks/BCDetail/DetailInfo/BasicInfo/useGetBasicInfo';

const AddressForm = () => {
  const pageTitle = location?.pathname?.split('/')[3];
  const basicInfo = useGetBasicInfo();

  return (
    <div className="space-y-[24px]">
      <div className="w-[300px] h-[141px] px-[28px] py-[21px] bg-[#556DA1] bg-opacity-10 rounded-[5px]">
        <div>
          <p className="text-[12px] font-[700]">
            {pageTitle === 'van' || pageTitle === 'brokerage'
              ? 'PICKUP'
              : 'PULLOUT'}
          </p>
        </div>
        <hr className="border-white mt-[10px] mb-[20px]" />
        <div>
          <p className="text-[#264B9F] font-500 text-[12px]">
            {basicInfo?.basicInfoData?.po_location?.company &&
            basicInfo?.basicInfoData?.po_location?.business_name
              ? `${basicInfo?.basicInfoData?.po_location?.company} / ${basicInfo?.basicInfoData?.po_location?.business_name}`
              : ''}
          </p>
        </div>
        <div className="mt-[10px] space-y-[3px]">
          <p className="text-[12px]">
            {basicInfo?.basicInfoData?.po_location?.address_01}
          </p>
          <p className="text-[12px]">
            {basicInfo?.basicInfoData?.po_location?.city
              ? `${basicInfo?.basicInfoData?.po_location?.city}, `
              : ''}
            {basicInfo?.basicInfoData?.po_location?.state
              ? `${basicInfo?.basicInfoData?.po_location?.state}, `
              : ''}
            {basicInfo?.basicInfoData?.po_location?.zip
              ? `${basicInfo?.basicInfoData?.po_location?.zip}`
              : ''}
          </p>
        </div>
      </div>
      <div className="w-[300px] h-[141px] px-[28px] py-[21px] bg-[#556DA1] bg-opacity-20 rounded-[5px]">
        <div>
          <p className="text-[12px] font-[700]">DELIVERY</p>
        </div>
        <hr className="border-white mt-[10px] mb-[20px]" />
        <div>
          <p className="text-[#264B9F] font-500 text-[12px]">
            {basicInfo?.basicInfoData?.dilv_location?.company &&
            basicInfo?.basicInfoData?.dilv_location?.business_name
              ? `${basicInfo?.basicInfoData?.dilv_location?.company} / ${basicInfo?.basicInfoData?.dilv_location?.business_name}`
              : ''}
          </p>
        </div>

        <div className="mt-[10px] space-y-[3px]">
          <p className="text-[12px]">
            {basicInfo?.basicInfoData?.dilv_location?.address_01}
          </p>
          <p className="text-[12px]">
            {basicInfo?.basicInfoData?.dilv_location?.city
              ? `${basicInfo?.basicInfoData?.dilv_location?.city}, `
              : ''}
            {basicInfo?.basicInfoData?.dilv_location?.state
              ? `${basicInfo?.basicInfoData?.dilv_location?.state}, `
              : ''}
            {basicInfo?.basicInfoData?.dilv_location?.zip
              ? `${basicInfo?.basicInfoData?.dilv_location?.zip}`
              : ''}
          </p>
        </div>
      </div>
      <div className="w-[300px] h-[141px] px-[28px] py-[21px] bg-[#556DA1] bg-opacity-30 rounded-[5px]">
        <div>
          <p className="text-[12px] font-[700]">RETURN</p>
        </div>
        <hr className="border-white mt-[10px] mb-[20px]" />
        <div>
          <p className="text-[#264B9F] font-500 text-[12px]">
            {basicInfo?.basicInfoData?.rtn_location?.company &&
            basicInfo?.basicInfoData?.rtn_location?.business_name
              ? `${basicInfo?.basicInfoData?.rtn_location?.company} / ${basicInfo?.basicInfoData?.rtn_location?.business_name}`
              : ''}
          </p>
        </div>
        <div className="mt-[10px] space-y-[3px]">
          <p className="text-[12px]">
            {basicInfo?.basicInfoData?.rtn_location?.address_01}
          </p>
          <p className="text-[12px]">
            {basicInfo?.basicInfoData?.rtn_location?.city
              ? `${basicInfo?.basicInfoData?.rtn_location?.city}, `
              : ''}
            {basicInfo?.basicInfoData?.rtn_location?.state
              ? `${basicInfo?.basicInfoData?.rtn_location?.state}, `
              : ''}
            {basicInfo?.basicInfoData?.rtn_location?.zip
              ? `${basicInfo?.basicInfoData?.rtn_location?.zip}`
              : ''}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AddressForm;
