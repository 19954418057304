import React, { useState, useRef, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  initDragEvents,
  resetDragEvents,
} from '../../../../utils/Acct/Common/Handler/dragAndDropHandler';
import ScannerViewer from '../ScannerViewer';
import { SCAN_FILE_INPUT_NAME } from '../../../../constants/Acct/Common/staticNames';
import ScannerReady from '../ScannerReady';

const ScannerContainer = ({
  type,
  scanMutator,
  scanStatus,
  setInvoiceList,
  resetScan,
  resetVendor,
  invoiceKey,
  setInvoiceKey,
}) => {
  const { setValue } = useFormContext();

  /* scan File */
  const scannedFile = useWatch({ name: SCAN_FILE_INPUT_NAME });

  /* Drag and Drop */
  const dragRef = useRef(null);
  const [isDragEnter, setIsDragEnter] = useState(false);

  /* Drag and Drop Event Handler */
  useEffect(() => {
    initDragEvents({ ref: dragRef, setIsDragEnter, setValue });
    return () => resetDragEvents({ ref: dragRef });
  }, [initDragEvents, resetDragEvents]);

  /** OCR Function */
  useEffect(() => {
    setInvoiceList([{ key: invoiceKey, data: {} }]);
    setInvoiceKey(prev => prev + 1);
  }, [scannedFile]);

  return (
    <div
      ref={dragRef}
      className={`h-full w-[680px] bg-[#DDDFE5] rounded-tl-[20px] rounded-bl-[20px]  flex flex-col justify-start cursor-pointer ${
        isDragEnter ? `blur-sm` : ``
      }`}
      onClick={() => {
        const inputElement =
          document.getElementsByName(SCAN_FILE_INPUT_NAME)[0];
        inputElement?.click();
        inputElement.value = null;
      }}
    >
      {scannedFile ? (
        <ScannerViewer
          data={scannedFile}
          scanStatus={scanStatus}
          setInvoiceList={setInvoiceList}
          resetScan={resetScan}
          resetVendor={resetVendor}
          scanMutator={scanMutator}
          invoiceKey={invoiceKey}
          setInvoiceKey={setInvoiceKey}
        />
      ) : (
        <ScannerReady />
      )}
    </div>
  );
};

export default ScannerContainer;
