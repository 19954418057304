import React, { useState } from 'react';
import { CATEGORY_204 } from '../../../../constants/EDI/options';
import useGetMainList from '../../../../hooks/EDI/Get/useGetMainList';
import createMainColumnDef from '../../../../utils/EDI/Generator/createMainColumnDef';
import openBCDetail from '../../../../utils/EDI/Handler/openBCDetail';
import CommonDataGrid from '../../../CommonDataGrid';
import CommonLoading from '../../../CommonLoading/CommonLoading';

const DataGridMain = ({
  currDiv,
  searchTypeMain,
  searchFromMain,
  searchToMain,
  selectedCategory,
}) => {
  const [columnDefs, setColumnDefs] = useState(createMainColumnDef());

  const { isLoadingMainList, listMainData, isRefetchingMainList } =
    useGetMainList({
      div: currDiv,
      type: searchTypeMain,
      from: searchFromMain,
      to: searchToMain,
      sender: CATEGORY_204[selectedCategory],
    });

  return (
    <>
      <CommonLoading open={isLoadingMainList || isRefetchingMainList} />
      <CommonDataGrid
        data={listMainData?.data || []}
        columnDefs={columnDefs}
        onCellClicked={e => openBCDetail(e)}
      />
    </>
  );
};

export default DataGridMain;
