import React from 'react';
import { useWatch } from 'react-hook-form';
import useGetBookingNumber from '../../../../hooks/BCDetail/DetailInfo/BasicInfo/useGetBookingNumber';
import CommonTextarea from '../../../Inputs/CommonTextarea/CommonTextarea';
import AddressForm from './AddressForm';
import BasicInfoDataField from './BasicInfoDataField';
import Caution from './Caution';
import DateInputForm from './DateInputForm';

const BasicInfo = ({ ...props }) => {
  const pageTitle = location?.pathname?.split('/')[3];
  const woNo =
    document.URL?.split('/')?.[document?.URL?.split('/')?.length - 1];

  const sizeWatcher = useWatch({
    name: 'size',
    defaultValue: props?.basicInfoData?.size,
  });
  const typeWatcher = useWatch({
    name: 'type',
    defaultValue: props?.basicInfoData?.type,
  });
  const sslWatcher = useWatch({
    name: 'ssl',
    defaultValue: props?.basicInfoData?.ssl,
  });

  const { isEditable, basicInfoData } = props;

  const { bookingNumber, isLoading: isLoadingBookingNumber } =
    useGetBookingNumber({
      woNo,
      size: sizeWatcher,
      type: typeWatcher,
      ssl: sslWatcher,
      pageTitle,
    });

  return (
    <div>
      <div className="mb-[27px] w-full flex justify-between flex-row">
        <p className="text-[15px] font-bold">WO # {woNo}</p>
        <div className="flex flex-col justify-between items-end gap-[5px]">
          <p className="text-[15px] font-bold w-fit">
            CREATED BY {basicInfoData?.created_by || 'Unknown'}
          </p>
          {basicInfoData?.completed_by && (
            <p className="text-[15px] font-bold w-fit">
              {basicInfoData?.completed ? 'COMPLETED' : 'INCOMPLETED'} BY{' '}
              {basicInfoData?.completed_by || 'Unknown'}
            </p>
          )}
        </div>
      </div>
      <div className="flex gap-x-[30px]">
        <BasicInfoDataField
          basicInfoData={props?.basicInfoData}
          bookingNumber={bookingNumber}
          sizeWatcher={sizeWatcher}
          typeWatcher={typeWatcher}
          sslWatcher={sslWatcher}
          isEditable={isEditable}
          isLoadingBookingNumber={isLoadingBookingNumber}
          scheduleFormController={props?.scheduleFormController}
        />
        <div className="w-[428px] h-[516px]">
          {!['van', 'brokerage', 'bare-chassis'].includes(pageTitle) && (
            <DateInputForm
              bookingNumber={bookingNumber}
              basicInfoData={props?.basicInfoData}
              sizeWatcher={sizeWatcher}
              typeWatcher={typeWatcher}
              sslWatcher={sslWatcher}
              isEditable={isEditable}
              isLoadingBookingNumber={isLoadingBookingNumber}
            />
          )}
          <div className="w-[428px] mb-0 ">
            <CommonTextarea
              inputName="remarks"
              label="REMARKS"
              height="h-[30px]"
              defaultValue={props?.basicInfoData?.remarks}
              maxLength={512}
              onChangeHandler={({ e, onChange }) => {
                onChange(e);
              }}
              disabled={!isEditable || !!props?.basicInfoData?.completed}
            />
          </div>
          <div>
            <Caution />
          </div>
        </div>

        <div>
          <AddressForm />
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
