export const PATH_COMPANY_LOGO = '/assets/images/l.png';
// export const PATH_COMPANY_LOGO = '../../assets/images/logo-non-glossy.png';
export const PATH_COMPANY_LOGO_V2 = '/assets/images/menu_icon/logo/logo_v2.svg';
export const HAMBUGER_ICON = '/assets/images/menu_icon/normal/hambuger.svg';
export const WARNING_ICON = '/assets/images/menu_icon/logo/warning.svg';
export const NOTIFICATION_ICON =
  '/assets/images/menu_icon/logo/notification.svg';
export const DOWN_ARROW = '/assets/images/menu_icon/logo/down_arrow.svg';
export const SAVE_ICON = '/assets/images/common_icon/saveIcon.svg';
export const DELETE_ICON = '/assets/images/common_icon/DeleteIcon.svg';
export const EXCEL_DOWN = '/assets/images/common_icon/excel.svg';
export const ADD_ICON = '/assets/images/common_icon/add.svg';
export const SYNC_ICON = '/assets/images/common_icon/sync.svg';
export const COMPLETE_ICON = '/assets/images/common_icon/complete.svg';
export const CLOSE_ICON = '/assets/images/common_icon/close.svg';
export const APPROVE_ICON = '/assets/images/common_icon/approveIcon.svg';
export const DOWN_ICON = '/assets/images/common_icon/DownArrow.svg';
export const CONTAINER_CLOSE_ICON =
  '/assets/images/common_icon/containerCloseIcon.svg';
export const CONTAINER_SCANNER_ICON =
  '/assets/images/common_icon/containerScannerIcon.svg';
export const LIST_ICON = '/assets/images/common_icon/listIcon.svg';
export const PLUS_ICON = '/assets/images/common_icon/plusIcon.svg';
export const MINUS_ICON = '/assets/images/common_icon/minusIcon.svg';
