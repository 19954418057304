import {
  DISPATCH_PAGE_BROKERAGE_AVAILABLE,
  DISPATCH_PAGE_BROKERAGE_DELIVERY,
  DISPATCH_PAGE_BROKERAGE_RETURN,
  DISPATCH_PAGE_EXPORT_AVAILABLE,
  DISPATCH_PAGE_EXPORT_DELIVERY,
  DISPATCH_PAGE_EXPORT_RETURN,
  DISPATCH_PAGE_EXPORT_UNAVAILABLE,
  DISPATCH_PAGE_IMPORT_AVAILABLE,
  DISPATCH_PAGE_IMPORT_DELIVERY,
  DISPATCH_PAGE_IMPORT_RETURN,
  DISPATCH_PAGE_IMPORT_UNAVAILABLE,
  DISPATCH_PAGE_VAN_AVAILABLE,
  DISPATCH_PAGE_VAN_DELIVERY,
  DISPATCH_PAGE_VAN_RETURN,
} from './category';

const EXCEL_FILE_NAMES = {
  [DISPATCH_PAGE_IMPORT_UNAVAILABLE]: 'IMPORT_UNAVAILABLE',
  [DISPATCH_PAGE_IMPORT_AVAILABLE]: 'IMPORT_AVAILABLE',
  [DISPATCH_PAGE_IMPORT_DELIVERY]: 'IMPORT_DELIVERY',
  [DISPATCH_PAGE_IMPORT_RETURN]: 'IMPORT_RETURN',
  [DISPATCH_PAGE_EXPORT_UNAVAILABLE]: 'EXPORT_UNAVAILABLE',
  [DISPATCH_PAGE_EXPORT_AVAILABLE]: 'EXPORT_AVAILABLE',
  [DISPATCH_PAGE_EXPORT_DELIVERY]: 'EXPORT_DELIVERY',
  [DISPATCH_PAGE_EXPORT_RETURN]: 'EXPORT_RETURN',
  [DISPATCH_PAGE_VAN_AVAILABLE]: 'VAN_AVAILABLE',
  [DISPATCH_PAGE_VAN_DELIVERY]: 'VAN_DELIVERY',
  [DISPATCH_PAGE_VAN_RETURN]: 'VAN_RETURN',
  [DISPATCH_PAGE_BROKERAGE_AVAILABLE]: 'BROKERAGE_AVAILABLE',
  [DISPATCH_PAGE_BROKERAGE_DELIVERY]: 'BROKERAGE_DELIVERY',
  [DISPATCH_PAGE_BROKERAGE_RETURN]: 'BROKERAGE_RETURN',
};

export default EXCEL_FILE_NAMES;
