import React from 'react';
import { LIST_ICON } from '../../../../../../constants/Order/Common/path';
import generateScheduleTypeLabel from '../../../../../../utils/BCDetail/DetailInfo/Schedule/Generator/generateScheduleTypeLabel';
import { SCHE_TYPE } from '../../../../../../constants/Common/standard';

const ScheduleTypeSelect = ({ openSetter, typeSetter, ...props }) => {
  const { setOpen } = openSetter;
  const { type } = typeSetter;

  const isVan = [SCHE_TYPE.VAN_DROP, SCHE_TYPE.VAN_LIVE].includes(type);

  return (
    <div
      onClick={() => {
        !isVan && setOpen?.(prev => !prev);
      }}
      className={`flex items-center space-x-[12px] mt-[26px] text-[#264B9F] font-[700] text-[14px] ${
        !isVan && `cursor-pointer`
      }`}
    >
      {[SCHE_TYPE.LIVE, SCHE_TYPE.DROP].includes(type) && (
        <img src={LIST_ICON} alt="list-icon" />
      )}
      <p className="w-[37px]" data-testid="scheduleTypeSelect">
        {generateScheduleTypeLabel({ type })}
      </p>
    </div>
  );
};

export default ScheduleTypeSelect;
