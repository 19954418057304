import React from 'react';
import CommonMessageModal from '../../../../../CommonMessageModal/CommonMessageModal';
import ScheduleAddModalButtonContent from './ScheduleAddModalButtonContent';
import ScheduleAddModalContent from './ScheduleAddModalContent';

const ScheduleAddModal = ({
  open,
  setOpen,
  locationOption,
  jobSet,
  selectedRowData,
  setMessageModalOpener,
  setMessageModalMessage,
  setLoadingOpener,
  setSelectedRowData,
  isCompanyIDListLoading,
}) => {
  return (
    <CommonMessageModal
      open={open}
      setOpen={setOpen}
      width="718px"
      height="282px"
      header="Add Schedule"
      onCloseClick={() => {
        setSelectedRowData?.(null);
      }}
      content={
        <ScheduleAddModalContent
          locationOption={locationOption}
          selectedRowData={selectedRowData}
          isCompanyIDListLoading={isCompanyIDListLoading}
        />
      }
      buttonContent={
        <ScheduleAddModalButtonContent
          jobSet={jobSet}
          selectedRowData={selectedRowData}
          setOpen={setOpen}
          setMessageModalOpener={setMessageModalOpener}
          setMessageModalMessage={setMessageModalMessage}
          setLoadingOpener={setLoadingOpener}
          setSelectedRowData={setSelectedRowData}
        />
      }
    />
  );
};

export default ScheduleAddModal;
