import React, { useRef } from 'react';
import { FormProvider } from 'react-hook-form';
import {
  SAVE_TYPE,
  SCAN_FILE_INPUT_NAME,
} from '../../../../constants/Acct/Common/staticNames';
import { PATH_MODAL_CLOSE_ICON } from '../../../../constants/Common/path';
import useGetCompanyList from '../../../../hooks/Acct/Common/Get/useGetCompanyList';
import { changeAutoComplete } from '../../../../utils/Acct/Common/Handler/changedInputFieldHandler';
import resetFormModal from '../../../../utils/Acct/Common/Handler/resetFormModal';
import saveInvoiceForm from '../../../../utils/Acct/Common/Handler/saveInvoiceForm';
import CommonButton from '../../../CommonButton/CommonButton';
import CommonAutoComplete from '../../../Inputs/CommonAutoComplete/CommonAutoComplete';
import CommonFile from '../../../Inputs/CommonFile/CommonFile';
import useCheckFormValidity from '../../../../hooks/Common/Input/useCheckFormValidity';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';

const UploadInvoiceModalHeaderButtons = ({
  setOpen,
  setVendor,
  setInvoiceList,
  resetScan,
  scannedFile,
  handleSubmitVendor,
  formList,
  setSubmitLength,
  setSubmitData,
  postMutator,
  setAlwaysFlag,
  duplicateCheckFields,
  setIsAllSave,
  setProgressLength,
  setErrorModalOptions,
  setDuplicationErrorList,
  setResolveErrorList,
  setApplyType,
  setBasicErrorMessage,
  setIsCompletedPatchFlag,
  type,
  vendorController,
  scanController,
  invoiceKey,
  setInvoiceKey,
}) => {
  /** Duplicate click preventer and Form validator */
  const saveRef = useRef(null);

  const formStates = [
    vendorController?.formState,
    scanController?.formState,
    ...formList?.map(item => item?.form?.formState),
  ];

  const { isFormInvalid } = useCheckFormValidity({ formStates });

  const { vendorOptionList, isLoadingCompanyList } = useGetCompanyList({
    category: 2,
  });
  const { reset: resetVendor } = vendorController;

  return (
    <div className="pb-[10px]">
      <div className="w-full flex justify-end gap-x-[20px] pb-[23px]">
        <div className="w-full flex justify-end items-center">
          <div
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
            }}
          >
            <img
              src={PATH_MODAL_CLOSE_ICON}
              width={12}
              height={12}
              alt="Modal Close Icon"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center mb-[-10px] mt-[5px]">
        <div className="flex items-center gap-x-[16px]">
          <FormProvider {...vendorController}>
            <form>
              <div className="items-center">
                <CommonAutoComplete
                  labelStyle="row"
                  label="VENDOR"
                  inputName="vendor"
                  width="w-[173px]"
                  required
                  option={vendorOptionList}
                  onChangeHandler={({ e, onChange, selectProps }) => {
                    onChange?.(selectProps);
                    setVendor?.(selectProps?.value);
                  }}
                  onInputChangeHandler={changeAutoComplete}
                  isLoading={isLoadingCompanyList}
                />
              </div>
            </form>
          </FormProvider>
          <FormProvider {...scanController}>
            <form>
              <div className="w-[308px]">
                <CommonFile
                  required
                  label="INVOICE"
                  labelStyle="row"
                  inputName={SCAN_FILE_INPUT_NAME}
                />
              </div>
            </form>
          </FormProvider>
        </div>
        <div className="flex flex-row justify-end gap-x-[20px] items-center mt-[-11px]">
          <CommonButton
            type="button"
            category="clear"
            width="74px"
            height="24px"
            onClick={() =>
              resetFormModal({
                setInvoiceList,
                resetScan,
                resetVendor,
                invoiceKey,
                setInvoiceKey,
              })
            }
          >
            Clear
          </CommonButton>
          <CommonButton
            ref={saveRef}
            type="button"
            category="save"
            width="74px"
            height="24px"
            preventDupClick
            onClick={({ buttonRef }) => {
              isFormInvalid &&
                updateButtonDisabled({ ref: buttonRef, disabled: false });
              saveInvoiceForm({
                buttonRef,
                scannedFile,
                handleSubmitVendor,
                formList,
                setSubmitLength,
                setSubmitData,
                postMutator,
                setAlwaysFlag,
                duplicateCheckFields,
                setIsAllSave,
                setProgressLength,
                setErrorModalOptions,
                setDuplicationErrorList,
                setResolveErrorList,
                setApplyType,
                saveType: SAVE_TYPE?.ALL,
                setBasicErrorMessage,
                setIsCompletedPatchFlag,
                type,
              });
            }}
          >
            Save
          </CommonButton>
        </div>
      </div>
    </div>
  );
};

export default UploadInvoiceModalHeaderButtons;
