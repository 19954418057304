import dayjs from 'dayjs';
import React, {
  forwardRef,
  memo,
  useRef,
  useState,
  useImperativeHandle,
} from 'react';
import {
  LocalizationProvider,
  TimePicker,
  renderTimeViewClock,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import StyledEditCellRenderer from '../../../../../styles/Common/CommonDatagrid/EditCellRenderer/StyledEditCellRenderer';

const GridTimeCell = forwardRef((props, ref) => {
  const {
    setValue,
    onValueChange,
    onChangeHandler,
    isCancelBeforeStartHandler,
  } = props;

  /** Input inner component reference */
  const compRef = useRef(null);
  const compInputRef = useRef(null);

  /** Input component property */
  const [value, setDataValue] = useState(
    props?.value && dayjs(props?.value)?.isValid() ? dayjs(props?.value) : '',
  );
  const [disabled, setDisabled] = useState(props?.disabled || false);

  /** Component data setter */
  const compDataSetter = {
    setDataValue,
    setDisabled,
  };

  /**
   * Component Editor Lifecycle methods
   * @see https://www.ag-grid.com/react-data-grid/component-cell-editor/
   */
  useImperativeHandle(ref, () => {
    return {
      /** The final value to send to the grid, on completion of editing */
      getValue() {
        return value;
      },
      isCancelBeforeStart() {
        isCancelBeforeStartHandler?.({ ...props, ...compDataSetter });
        return false;
      },
      isCancelAfterEnd() {
        return false;
      },
      ref,
      compRef,
      compInputRef,
      ...compDataSetter,
      ...props,
    };
  });

  /** Render component */
  return (
    <StyledEditCellRenderer>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          ref={compRef}
          inputRef={compInputRef}
          value={value && dayjs(value)?.isValid() ? dayjs(value) : null}
          onChange={e => {
            const getChangedValue = e;
            if (getChangedValue && getChangedValue?.isValid()) {
              setValue?.(getChangedValue);
              setDataValue?.(getChangedValue);
              onValueChange?.(getChangedValue);
              onChangeHandler?.({ getChangedValue, ...props });
              return;
            }
            setValue?.(null);
            setDataValue?.(null);
            onValueChange?.(null);
            onChangeHandler?.({ getChangedValue, ...props });
          }}
          format="HH:mm"
          disabled={disabled}
          className={`bg-white rounded-[5px] h-[24px] w-[160px] ${
            disabled ? `bg-[#F9F9F9]` : `bg-white`
          }`}
          disableOpenPicker
          slotProps={{
            textField: {
              sx: {
                svg: { color: '#264B9F !important' },
                '&.MuiFormControl-root': {
                  width: '100%',
                },
                '&.MuiTextField-root': {
                  width: '100% !important',
                },
                '.MuiInputBase-root': {
                  color: `#000000 !important`,
                  height: '100%',
                  width: '100% !important',
                  borderRadius: '5px',
                  fontSize: '12px',
                  fontWeight: '500',
                  '&:not(.Mui-focused):not(.Mui-disabled):hover fieldset': {
                    borderColor: '#8E9396 !important',
                  },
                  '&.Mui-focused fieldset': {
                    border: '1.2px solid #264B9F !important',
                    boxShadow: '0px 0px 4px 0px #8BBCE9 !important',
                  },
                  '&.Mui-error fieldset': {
                    border: '1.2px solid #C24D4D !important',
                  },
                  '.MuiInputBase-input.MuiOutlinedInput-input': {
                    border: 'none !important',
                  },
                  input: {
                    paddingLeft: '2px !important',
                    paddingRight: '2px !important',
                  },
                },
                '.MuiSvgIcon-root': {
                  fontSize: '16px',
                },
                fieldset: {
                  borderColor: '#D9D9D9 !important',
                },
              },
            },
          }}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
          }}
        />
      </LocalizationProvider>
    </StyledEditCellRenderer>
  );
});

export default GridTimeCell;
