import React, { useEffect } from 'react';
import handleScheduleRowButtonVisibility from '../../../../../../utils/BCDetail/DetailInfo/Schedule/Handler/handleScheduleRowButtonVisibility';
import {
  MINUS_ICON,
  PLUS_ICON,
} from '../../../../../../constants/Order/Common/path';
import handleDetailInfoUpdate from '../../../../../../utils/BCDetail/DetailInfo/Common/Handler/handleDetailInfoUpdate';
import { UPDATE_TYPE } from '../../../../../../constants/BCDetail/Common/type';
import checkMobileJobInProgress from '../../../../../../utils/BCDetail/DetailInfo/Schedule/Handler/checkMobileJobInProgress';

const ScheduleRowButtonGroup = ({
  pageCategory,
  scheType,
  jobSetIdx,
  rowIdx,
  jobSet,
  locationSetIdx,
  locationSet,
  scheduleData,
  setSelectedRowData,
  setAddModalOpener,
  scheduleType,
  setDeleteModalOpener,
  setSelectedScheduleSeq,
  locationInfo,
  updateProps,
  basicInfoData,
  scheduleFormData,
  basicInfoFormController,
  scheduleFormController,
  setDeleteScheduleWarningModalOpen,
  ...props
}) => {
  return (
    <div className="flex items-center space-x-[8px] py-[8px]">
      <div className="w-[14px] h-[14px]">
        {handleScheduleRowButtonVisibility({
          pageCategory,
          scheType,
          scheduleData,
          jobSet,
          locationSet,
          jobSetIdx,
          locationSetIdx,
          scheduleType,
          locationInfo,
          buttonType: 'ADD_ROW',
        }) && (
          <div
            className="w-full h-full cursor-pointer"
            onClick={() => {
              handleDetailInfoUpdate({
                ...updateProps,
                basicInfoData: basicInfoFormController?.getValues(),
                scheduleData: scheduleFormController?.getValues(),
                setAddModalOpener,
                setSelectedRowData,
                selectedScheduleData: scheduleData,
                groupIdx: jobSetIdx,
                rowIdx,
                scheduleType,
                updateType: UPDATE_TYPE.ADD_SCHEDULE,
              });
            }}
          >
            <img src={PLUS_ICON} alt="plus-icon" className="w-full h-full" />
          </div>
        )}
      </div>
      <div className="w-[14px] h-[14px]">
        {handleScheduleRowButtonVisibility({
          scheType,
          scheduleData,
          jobSet,
          jobSetIdx,
          locationSet,
          locationSetIdx,
          locationInfo,
          buttonType: 'DELETE_ROW',
        }) && (
          <div
            className="w-full h-full cursor-pointer"
            onClick={() => {
              // Check job status to prevent deleting mobile-in-progress job
              if (
                checkMobileJobInProgress({
                  pageCategory,
                  jobSet,
                  jobSetIdx,
                  locationSet,
                })
              ) {
                setDeleteScheduleWarningModalOpen(true);
                return;
              }

              // Progress to delete schedule
              handleDetailInfoUpdate({
                ...updateProps,
                basicInfoData: basicInfoFormController?.getValues(),
                scheduleData: scheduleFormController?.getValues(),
                selectedScheduleData: scheduleData,
                setDeleteModalOpener,
                setSelectedScheduleSeq,
                updateType: UPDATE_TYPE.DELETE_SCHEDULE,
              });
            }}
          >
            <img src={MINUS_ICON} alt="minus-icon" className="w-full h-full" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ScheduleRowButtonGroup;
