import mapAPI from '../../api';

const getGeofence = async company => {
  try {
    const response = await mapAPI.get(`/geofence/${company}`);
    return response.data;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getGeofence;
