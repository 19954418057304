const DAILY_REPORT_BASIC_COLUMN_WIDTH = {
  no: 50,
  div: 38,
  category: 45,
  billto: 72,
  mbl_booking: 130,
  cont_no: 92,
  equipment: 100,
  ssl: 50,
  size: 46,
  type: 57,
  wgt: 60,
  ch_no: 86,
  pool: 66,

  lfd: 62,
  rfd: 62,
  erd: 62,
  cutoff: 97,
  empty_load_cfm: 80,

  po_location: 72,
  po_schedule: 97,
  po_driver: 72,
  po_actual_out: 120,

  dilv_location: 72,
  dilv_city: 98,
  dilv_state: 35,
  dilv_schedule: 97,
  dilv_driver: 72,

  rtn_location: 72,
  rtn_schedule: 97,
  rtn_driver: 72,

  sche_type: 42,
  hazmat: 40,
  remarks: 200,
};

export default DAILY_REPORT_BASIC_COLUMN_WIDTH;
