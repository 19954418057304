import cellEditorRendererWithCellTypeGenerator from './cellEditorRendererWithCellTypeGenerator';

/**
 * @description
 * Cell editor generator
 * @param {object} gridParam
 * Parameter that contains the grid object(API).
 * @param {string} cellType
 * Grid cell type.
 * @returns
 * Cell editor component
 */
const cellEditorGenerator = ({
  gridParam,
  cellType,
  adornment,
  regex,
  ...props
}) => {
  const editorCellSettings = {
    component: cellEditorRendererWithCellTypeGenerator({ cellType }),
    params: {
      maxLength: props?.maxLength || 20,
      option: props?.option || [],
      cellType,
      adornment,
      regex,
      ...props,
    },
  };
  return editorCellSettings;
};

export default cellEditorGenerator;
