import React from 'react';
import CommonButton from '../../../CommonButton/CommonButton';

const ButtonContent = ({ setIsLiveShareModalOpen }) => {
  return (
    <div className="flex justify-center">
      <CommonButton
        category="cancel"
        onClick={() => setIsLiveShareModalOpen(false)}
      >
        Close
      </CommonButton>
    </div>
  );
};

export default ButtonContent;
