import React, { useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import CommonBlur from '../../../CommonBlur/CommonBlur';
import ScannerContainer from '../ScannerContainer';
import UploadInvoiceModalHeaderButtons from '../UploadInvoiceModalHeaderButtons';
import ChassisInvoiceFormWrapper from '../../ChassisReconcile/InvoiceFormWrapper';
import PerdiemInvoiceFormWrapper from '../../PerdiemReconcile/InvoiceFormWrapper';
import AddFormButton from '../AddFormButton';
import { INITIAL_INVOICE_LIST } from '../../../../constants/Acct/Common/initialInvoiceList';
import WarningModal from '../WarningModal';
import handleDuplicationErrorPatch from '../../../../utils/Acct/Common/Handler/handleDuplicationErrorPatch';
import { SCAN_FILE_INPUT_NAME } from '../../../../constants/Acct/Common/staticNames';
import useControlWarningModal from '../../../../hooks/Acct/Common/Control/useControlWarningModal';

const UploadInvoiceModal = ({
  open,
  type,
  setOpen,
  duplicateCheckFields,
  usePostScan,
  usePatchWarningModal,
  usePostInvoice,
  refetchList,
  setErrorModalOptions,
  ...props
}) => {
  /* UseForm */
  const scanController = useForm({ shouldUnregister: true });
  const vendorController = useForm({ shouldUnregister: true });

  const scannedFile = useWatch({
    name: SCAN_FILE_INPUT_NAME,
    control: scanController.control,
  });

  const [formList, setFormList] = useState([]);
  const [invoiceKey, setInvoiceKey] = useState(1);
  const [invoiceList, setInvoiceList] = useState(INITIAL_INVOICE_LIST);

  const [vendor, setVendor] = useState('');

  /* Duplication Warning Modal useHook */
  const {
    openWarningModal,
    setOpenWarningModal,
    warningModalIndex,
    setWarningModalIndex,
    warningModalData,
    setWarningModalData,
    setSubmitLength,
    setProgressLength,
    submitData,
    setSubmitData,
    duplicationErrorList,
    setDuplicationErrorList,
    resolveErrorList,
    setResolveErrorList,
    patchFormKey,
    setPatchFormKey,
    setBasicErrorMessage,
    alwaysFlag,
    setAlwaysFlag,
    setApplyType,
    setIsAllSave,
    setIsCompletedPatchFlag,
  } = useControlWarningModal({
    setErrorModalOptions,
    refetchList,
    setOpen,
    setInvoiceList,
    scanController,
    vendorController,
  });

  const {
    mutate: scanMutator,
    isError,
    isPending,
  } = usePostScan({
    setInvoiceList,
    setInvoiceKey,
    setErrorModalOptions,
  });

  const { mutate: postMutator } = usePostInvoice({
    submitData,
    setProgressLength,
    setDuplicationErrorList,
    setInvoiceList,
    setBasicErrorMessage,
  });

  const { mutate: overwritePatchMutator } = usePatchWarningModal({
    patchFormKey,
    setInvoiceList,
    setBasicErrorMessage,
    refetchList,
  });

  useEffect(() => {
    if (invoiceList?.length === 0) {
      setInvoiceList([{ key: invoiceKey, data: {} }]);
      setInvoiceKey(prev => prev + 1);
    }
  }, [invoiceList]);

  useEffect(() => {
    const isExistDuplicationError = duplicationErrorList.length > 0;
    const isReadyPatch =
      duplicationErrorList.length === resolveErrorList?.length;

    if (isExistDuplicationError && isReadyPatch) {
      handleDuplicationErrorPatch({
        resolveErrorList,
        overwritePatchMutator,
        setPatchFormKey,
        setDuplicationErrorList,
        setResolveErrorList,
        setWarningModalIndex,
        setIsCompletedPatchFlag,
      });
    }
  }, [resolveErrorList]);

  return (
    <CommonBlur open={open}>
      <div className="flex justify-center items-center w-full h-full">
        <div className="w-[1752px] h-[800px] bg-[#FFFFFF] rounded-[20px] flex flex-row">
          <FormProvider {...scanController}>
            <form>
              <ScannerContainer
                type={type}
                scanMutator={scanMutator}
                scanStatus={{ isError, isPending }}
                setInvoiceList={setInvoiceList}
                resetScan={scanController.reset}
                resetVendor={vendorController.reset}
                invoiceKey={invoiceKey}
                setInvoiceKey={setInvoiceKey}
              />
            </form>
          </FormProvider>
          <div className="w-[1072px] flex flex-col py-[27px] px-[24px]">
            <UploadInvoiceModalHeaderButtons
              setOpen={setOpen}
              setVendor={setVendor}
              setInvoiceList={setInvoiceList}
              resetScan={scanController.reset}
              scannedFile={scannedFile}
              handleSubmitVendor={vendorController?.handleSubmit}
              formList={formList}
              setSubmitLength={setSubmitLength}
              setSubmitData={setSubmitData}
              postMutator={postMutator}
              setAlwaysFlag={setAlwaysFlag}
              duplicateCheckFields={duplicateCheckFields}
              setIsAllSave={setIsAllSave}
              setErrorModalOptions={setErrorModalOptions}
              setProgressLength={setProgressLength}
              setDuplicationErrorList={setDuplicationErrorList}
              setResolveErrorList={setResolveErrorList}
              setApplyType={setApplyType}
              setBasicErrorMessage={setBasicErrorMessage}
              setIsCompletedPatchFlag={setIsCompletedPatchFlag}
              type={type}
              vendorController={vendorController}
              scanController={scanController}
              invoiceKey={invoiceKey}
              setInvoiceKey={setInvoiceKey}
            />
            <div className="flex flex-col w-[1040px] h-full overflow-auto space-y-[15px]">
              {invoiceList?.map((invoice, index) =>
                type === 'chassis' ? (
                  <ChassisInvoiceFormWrapper
                    key={invoice?.key}
                    index={index}
                    formKey={invoice?.key}
                    invoice={invoice?.data}
                    setFormList={setFormList}
                    invoiceList={invoiceList}
                    setInvoiceList={setInvoiceList}
                    setSubmitLength={setSubmitLength}
                    setSubmitData={setSubmitData}
                    scannedFile={scannedFile}
                    handleSubmitVendor={vendorController?.handleSubmit}
                    postChassisMutator={postMutator}
                    duplicateCheckFields={duplicateCheckFields}
                    poolList={props?.poolList}
                    setIsAllSave={setIsAllSave}
                    formList={formList}
                    setAlwaysFlag={setAlwaysFlag}
                    setProgressLength={setProgressLength}
                    setDuplicationErrorList={setDuplicationErrorList}
                    setResolveErrorList={setResolveErrorList}
                    setApplyType={setApplyType}
                    setErrorModalOptions={setErrorModalOptions}
                    setBasicErrorMessage={setBasicErrorMessage}
                    setIsCompletedPatchFlag={setIsCompletedPatchFlag}
                  />
                ) : (
                  <PerdiemInvoiceFormWrapper
                    key={invoice?.key}
                    index={index}
                    formKey={invoice?.key}
                    invoice={invoice?.data}
                    setFormList={setFormList}
                    invoiceList={invoiceList}
                    setInvoiceList={setInvoiceList}
                    scannedFile={scannedFile}
                    vendor={vendor}
                    handleSubmitVendor={vendorController.handleSubmit}
                    setSubmitLength={setSubmitLength}
                    setSubmitData={setSubmitData}
                    postPerdiemMutator={postMutator}
                    duplicateCheckFields={duplicateCheckFields}
                    sslList={props?.sslList}
                    setIsAllSave={setIsAllSave}
                    formList={formList}
                    setAlwaysFlag={setAlwaysFlag}
                    setProgressLength={setProgressLength}
                    setDuplicationErrorList={setDuplicationErrorList}
                    setResolveErrorList={setResolveErrorList}
                    setApplyType={setApplyType}
                    setErrorModalOptions={setErrorModalOptions}
                    setBasicErrorMessage={setBasicErrorMessage}
                    setIsCompletedPatchFlag={setIsCompletedPatchFlag}
                  />
                ),
              )}
              {scannedFile && !isPending && (
                <AddFormButton
                  type={type}
                  invoiceKey={invoiceKey}
                  setInvoiceKey={setInvoiceKey}
                  setInvoiceList={setInvoiceList}
                />
              )}
            </div>
          </div>
          {openWarningModal && (
            <WarningModal
              key={warningModalIndex}
              open={openWarningModal}
              setOpen={setOpenWarningModal}
              data={warningModalData}
              setResolveErrorList={setResolveErrorList}
              warningModalIndex={warningModalIndex}
              duplicationErrorList={duplicationErrorList}
              setWarningModalIndex={setWarningModalIndex}
              setWarningModalData={setWarningModalData}
              setAlwaysFlag={setAlwaysFlag}
              setApplyType={setApplyType}
              alwaysFlag={alwaysFlag}
              duplicateCheckFields={duplicateCheckFields}
            />
          )}
        </div>
      </div>
    </CommonBlur>
  );
};

export default UploadInvoiceModal;
