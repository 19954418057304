import {
  BC_DETAIL_BILLING_INFO_PATH,
  BC_DETAIL_DETAIL_INFO_PATH,
  BC_DETAIL_DOCUMENT_PATH,
  BC_DETAIL_MEMO_PATH,
  BC_DETAIL_PATH,
  BC_DETAIL_TRACKING_PATH,
} from '../../../../constants/BCDetail/Common/router';

/** BC-Detail router item */
const getBCDetailRouterTabItem = ({ locationInfo }) => {
  const { woNo, woType } = locationInfo;

  return [
    {
      label: 'Detail Info',
      to: `/${BC_DETAIL_PATH}/${BC_DETAIL_DETAIL_INFO_PATH}/${woType}/${woNo}`,
      tabType: BC_DETAIL_DETAIL_INFO_PATH,
      woType,
    },
    {
      label: 'Billing Info',
      to: `/${BC_DETAIL_PATH}/${BC_DETAIL_BILLING_INFO_PATH}/${woType}/${woNo}`,
      tabType: BC_DETAIL_BILLING_INFO_PATH,
      woType,
    },
    {
      label: 'Document',
      to: `/${BC_DETAIL_PATH}/${BC_DETAIL_DOCUMENT_PATH}/${woType}/${woNo}`,
      tabType: BC_DETAIL_DOCUMENT_PATH,
      woType,
    },
    {
      label: 'Memo',
      to: `/${BC_DETAIL_PATH}/${BC_DETAIL_MEMO_PATH}/${woType}/${woNo}`,
      tabType: BC_DETAIL_MEMO_PATH,
      woType,
    },
    {
      label: 'Tracking',
      to: `/${BC_DETAIL_PATH}/${BC_DETAIL_TRACKING_PATH}/${woType}/${woNo}`,
      tabType: BC_DETAIL_TRACKING_PATH,
      woType,
    },
  ];
};

export default getBCDetailRouterTabItem;
