const deleteReportSetting = ({
  gridRef,
  deleteSettingMutate,
  confirmDeleteOptions,
  alertModalOptions,
}) => {
  const { setMessage, setIsSuccess, setIsOpenErrorModal } = alertModalOptions;
  const { setIsOpenDeleteConfirmModal, setDeleteMutator } =
    confirmDeleteOptions;

  const selectedSeqList = gridRef?.current?.api
    ?.getSelectedRows()
    ?.map(row => row?.seq);

  if (!selectedSeqList || selectedSeqList?.length === 0) {
    setIsSuccess(false);
    setMessage('Please select at least one item.');
    setIsOpenErrorModal(true);
    return;
  }

  const newMutator = () =>
    deleteSettingMutate({
      deleteData: { seq: selectedSeqList },
      gridParam: gridRef?.current,
    });
  setDeleteMutator(() => newMutator);
  setIsOpenDeleteConfirmModal(true);
};

export default deleteReportSetting;
