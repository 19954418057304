const handleWOConvert = async ({
  woNo,
  converter,
  convertToExport,
  rollbackToImport,
  buttonRef,
}) => {
  if (converter === 'Export') {
    convertToExport.mutate({ woNo, buttonRef });
  } else if (converter === 'Import') {
    rollbackToImport.mutate({ woNo, buttonRef });
  }
};

export default handleWOConvert;
