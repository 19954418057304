import orderAPI from '../api';

const getBookingInfo = async ({ bookingSequence }) => {
  try {
    const response = await orderAPI?.get(`booking/${bookingSequence}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export default getBookingInfo;
