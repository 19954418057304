import { CATEGORY_NAMES } from '../../../../constants/TmsReport/EarlyWarning';

const defaultOption = [
  {
    label: 'All',
    value: '',
  },
];

export const generateLevelOption = () => {
  const option = [...defaultOption];
  for (let i = 1; i <= 7; i++) {
    option?.push({ label: i, value: i });
  }

  return option;
};

export const generateCategoryOption = () => {
  const option = [...defaultOption];
  const categories = [...Object?.values?.(CATEGORY_NAMES)];

  categories?.forEach(category => {
    option?.push({ label: category, value: category });
  });

  return option;
};
