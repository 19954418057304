import { WARNING_DATE_CODE } from '../../../../constants/TmsReport/EarlyWarning/standardCode';

const handleDisabledField = ({ disabledFields, ...props }) => {
  const warnTo = props?.data?.warn_to;
  if (warnTo === WARNING_DATE_CODE?.currentDateTime) {
    const instances = props?.api?.getCellEditorInstances();

    disabledFields?.forEach(field => {
      const selfInstance = instances?.find(
        element => element?.cellEditorParams?.column?.colId === field,
      );

      selfInstance?.componentInstance?.setDisabled?.(true);
    });
  }
};

export default handleDisabledField;
