import React from 'react';
import dayjs from 'dayjs';
import CommonTime from '../../../components/SecondInputs/CommonTime/CommonTime';

const getTimeComponent = ({ ...props }) => {
  const commonProperty = {
    disabled: props?.disabled,
    required: props?.required,
    labelStyle: props?.labelStyle,
    onChangeHandler: ({ e, onChange, ...handlerProps }) => {
      onChange(dayjs(e)?.isValid() ? dayjs(e) : null);
    },
  };

  const inputProperty = [
    {
      key: 0,
      label: 'Time Label default',
      inputName: 'time00',
      width: null,
      ...commonProperty,
    },
    {
      key: 1,
      label: 'Time Label 25%',
      inputName: 'time00',
      width: 'w-1/4',
      ...commonProperty,
    },
    {
      key: 2,
      label: 'Time Label 50%',
      inputName: 'time00',
      width: 'w-2/4',
      ...commonProperty,
    },
    {
      key: 3,
      label: 'Time Label 75%',
      inputName: 'time00',
      width: 'w-3/4',
      ...commonProperty,
    },
    {
      key: 4,
      label: 'Time Label 100%',
      inputName: 'time00',
      width: 'w-full',
      ...commonProperty,
    },
    {
      key: 5,
      label: 'Time Label Custom',
      inputName: 'time00',
      width: props?.customWidth,
      ...commonProperty,
    },
  ];

  return inputProperty?.map(element => (
    <div className="mb-4">
      <CommonTime
        key={element?.key}
        {...element}
        labelStyle={props?.labelStyle && 'row'}
      />
    </div>
  ));
};
export default getTimeComponent;
