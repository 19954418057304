const createBookingNumberSelect = ({ bookingNumberData, currDiv }) => {
  const filteredBookingNumber = bookingNumberData?.filter(
    paramBookingNumber => paramBookingNumber?.div === currDiv,
  );
  const result = filteredBookingNumber?.map(paramBookingNumber => {
    return {
      label: paramBookingNumber?.bk_no,
      value: paramBookingNumber?.bk_no,
      seq: paramBookingNumber?.seq,
    };
  });

  return result;
};

export default createBookingNumberSelect;
