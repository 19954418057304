import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import CommonButton from '../../../components/CommonButton/CommonButton';
import CommonDataGrid from '../../../components/CommonDataGrid/index';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import AdvancedSearchModal from '../../../components/TmsReport/EarlyWarning/AdvancedSearchModal';
import SearchStatusCheckBox from '../../../components/TmsReport/EarlyWarning/SearchStatusCheckBox';
import { GRID_MOD_EDIT } from '../../../constants/Common/DataGrid/setting';
import { STATUS_STANDARD_CODE } from '../../../constants/TmsReport/EarlyWarning/standardCode';
import useControlLoading from '../../../hooks/TmsReport/EarlyWarning/Control/useControlLoading';
import useGetEarlyWarningList from '../../../hooks/TmsReport/EarlyWarning/Get/useGetEarlyWarningList';
import usePatchStatus from '../../../hooks/TmsReport/EarlyWarning/Patch/usePatchStatus';
import exportToExcel from '../../../utils/Common/Datagrid/Handler/exportToExcel';
import createEWColumnDefs from '../../../utils/TmsReport/EarlyWarning/Generator/createEWColumnDefs';
import getDataGridData from '../../../utils/TmsReport/EarlyWarning/Generator/generateDataGridRows';
import handleRowClick from '../../../utils/TmsReport/EarlyWarning/Handler/handleRowClick';
import useControlTotalInfo from '../../../hooks/Common/TotalBottomRow/useControlTotalInfo';
import TotalBottomBar from '../../../components/Common/CommonTotalBottomBar';

const EarlyWarningList = () => {
  const ref = useRef(null);

  const searchController = useForm();

  const nowDiv = useSelector(state => state?.nowDiv);

  const earlyWarningPatchFlag = useSelector(state => state?.earlyWarning?.flag);
  const [isChangeFlag, setIsChangeFlag] = useState(false);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [queryObject, setQueryObject] = useState({
    order: 'time',
    div: nowDiv,
    cat: 'ALL',
    issue: true,
    solved: false,
    confirm: false,
    searchedCategory: 'wo-no',
    'created-from': dayjs()?.subtract(3, 'month')?.format('YYYY-MM-DD'),
    'created-to': dayjs()?.format('YYYY-MM-DD'),
  });

  useEffect(() => {
    setQueryObject(prev => ({ ...prev, div: nowDiv }));
  }, [nowDiv]);

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const errorModalOptions = {
    setIsErrorModalOpen,
    setErrorMessage,
    setIsSuccess,
  };

  const [dataGridData, setDataGridData] = useState([]);
  const [columnDefs, _] = useState(createEWColumnDefs());

  const { earlyWarningList, isLoading, isFetching, refetchEarlyWarningList } =
    useGetEarlyWarningList({ queryObject });

  const { mutate: patchStatusMutate, isPending: isPendingPatchStatus } =
    usePatchStatus({
      setErrorMessage,
      setIsErrorModalOpen,
      setIsSuccess,
      ref,
      queryObject,
    });

  useEffect(() => {
    setDataGridData(getDataGridData({ dataGridData: earlyWarningList }));
  }, [earlyWarningList]);

  const onSaveClicked = ({ gridParam }) => {
    patchStatusMutate({ gridParam });
  };

  useEffect(() => {
    setIsChangeFlag(prev => true);
    if (isChangeFlag && (queryObject?.issue || queryObject?.confirm)) {
      refetchEarlyWarningList();
    }
  }, [earlyWarningPatchFlag]);

  const { isLoadingOpen } = useControlLoading({
    isLoadingList: [isLoading],
    isFetchingList: [isFetching],
    isPendingList: [isPendingPatchStatus],
  });

  const { totalRows, setChangeFlag } = useControlTotalInfo({
    gridRef: ref,
    queryData: dataGridData,
  });

  return (
    <>
      <div className="flex flex-col pr-[40px] h-full">
        <div className="w-full flex flex-row justify-end items-center gap-x-[15px] mb-[25px]">
          <SearchStatusCheckBox
            searchController={searchController}
            setQueryObject={setQueryObject}
          />
          <div className="text-[#000000] text-opacity-60 mt-[12px]">|</div>
          <div className="mt-[14px]">
            <CommonButton
              type="button"
              category="greySearch"
              border="0"
              backgroundColor="transparent"
              color="#666666"
              fontWeight="700"
              onClick={() => {
                setIsOpenModal(true);
              }}
            >
              Search
            </CommonButton>
          </div>
          <div className="mt-[14px]">
            <CommonButton
              type="button"
              category="excel"
              fontWeight="700"
              onClick={() => {
                exportToExcel({ gridRef: ref, fileName: 'EARLY WARNING LIST' });
              }}
            >
              EXCEL
            </CommonButton>
          </div>
        </div>
        <div className="w-full flex-1">
          <CommonDataGrid
            gridRef={ref}
            columnDefs={columnDefs}
            data={dataGridData}
            onRowClicked={handleRowClick}
            setEditDisabled={({ gridParam }) => {
              return gridParam?.data?.status === STATUS_STANDARD_CODE?.SOLVED;
            }}
            modType={GRID_MOD_EDIT}
            onSaveClicked={onSaveClicked}
            onRowEditingStart={({ gridParam }) => {
              if (gridParam?.data?.status === STATUS_STANDARD_CODE?.SOLVED) {
                gridParam?.api?.stopEditing(true);
                setIsSuccess(false);
                setErrorMessage('"SOLVED" data cannot be changed.');
                setIsErrorModalOpen(prev => true);
              }
            }}
            onFilterChanged={params => {
              setChangeFlag(prev => !prev);
            }}
            onSelectionChanged={() => {
              setChangeFlag(prev => !prev);
            }}
          />
          <TotalBottomBar totalRows={totalRows} />
        </div>
      </div>
      <AdvancedSearchModal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        queryObject={queryObject}
        setQueryObject={setQueryObject}
        searchController={searchController}
        errorModalOptions={errorModalOptions}
      />
      <CommonLoading open={isLoadingOpen} />
      {isErrorModalOpen && !isSuccess && (
        <CommonErrorModal
          open={isErrorModalOpen}
          setIsOpen={setIsErrorModalOpen}
          message={errorMessage}
          isSuccess={isSuccess}
        />
      )}
    </>
  );
};

export default EarlyWarningList;
