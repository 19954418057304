import React from 'react';
import StyledCommonSideModalContent from '../../../styles/Common/CommonSideModal/StyledCommonSideModalContent';

const CommonSideModalContent = ({ children, ...props }) => {
  return (
    <StyledCommonSideModalContent {...props}>
      {children}
    </StyledCommonSideModalContent>
  );
};

export default CommonSideModalContent;
