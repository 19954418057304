import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import tmsReportAPI from '../../api';

export const patchEarlyWarning = async ({ seq, gridParam }) => {
  const ewSeq = seq || gridParam?.data?.seq;
  try {
    const response = await tmsReportAPI.patch(`early-warning/${ewSeq}`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};
