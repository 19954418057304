const mainKeys = {
  all: ['MAIN'],
  list: ({ category }) => [...mainKeys.all, category],
  listDivision: ({ category, selectedDiv }) => [
    ...mainKeys.list({ category }),
    selectedDiv,
  ],
  listDivisionQueryString: ({ category, selectedDiv, queryString }) => [
    ...mainKeys.listDivision({ category, selectedDiv }),
    queryString,
  ],
};

export default mainKeys;
