import React, { useEffect } from 'react';

const RowCustomDetailExample = ({ ...props }) => {
  return (
    <div className="flex justify-center items-center w-full h-[500px] bg-yellow-200">
      <img
        src="https://ngltrans.com/wp-content/uploads/2021/01/open-graphic-kakao-image.png"
        alt="hyua"
        width={700}
        height={500}
      />
    </div>
  );
};

export default RowCustomDetailExample;
