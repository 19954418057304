import React from 'react';
import ScheduleAPModalTableContent from '../../../ScheduleAPModal/ScheduleAPModalTableContent';

const DualMoveAPModalAdditionalAP = ({ ...props }) => {
  const { apData, apTableContent, setAPTableContent, isLoadingGetDualAPInput } =
    props;

  return (
    <div className="w-[511px] pt-[15px] flex flex-col">
      <div className="w-full h-[16px] min-h-[16px] text-[14px] font-bold flex items-center">
        Dual Move AP
      </div>
      <ScheduleAPModalTableContent
        apInputData={apData}
        apTableContent={apTableContent}
        setAPTableContent={setAPTableContent}
        isLoadingGetDualAPInput={isLoadingGetDualAPInput}
      />
    </div>
  );
};

export default DualMoveAPModalAdditionalAP;
