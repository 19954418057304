import React from 'react';
import CommonButton from '../../../CommonButton/CommonButton';
import deleteReportSetting from '../../../../utils/CustomerReport/Handler/deleteReportSetting';

const SettingUtilityBar = ({ ...props }) => {
  const {
    gridRef,
    deleteSettingMutate,
    confirmDeleteOptions,
    alertModalOptions,
  } = props;

  return (
    <div>
      <CommonButton
        category="delete"
        width="80px"
        height="24px"
        onClick={() => {
          deleteReportSetting({
            gridRef,
            deleteSettingMutate,
            confirmDeleteOptions,
            alertModalOptions,
          });
        }}
      >
        Delete
      </CommonButton>
    </div>
  );
};

export default SettingUtilityBar;
