/**
 * @description
 * onChangeHandler function of text change.
 * Reflects incoming text without the need for conditional statements.
 */
const handleTextInputChange = ({ e, onChange, ...handlerProps }) => {
  onChange?.(e?.target?.value);
};

export default handleTextInputChange;
