import React from 'react';
import getAuthorizedMenu from '../../../utils/Common/Sidebar/Handler/getAuthorizedMenu';
import { SIDEBAR_SUB_MENU } from '../../../constants/Common/Option/Sidebar';
import {
  PAGE_NAME_MAPPER,
  SUB_MENU_MAPPER,
} from '../../../constants/Common/mapper';
import StyledSubMenuNavigatorWrapper from '../../../styles/Common/SubMenuNavigator/StyledSubMenuNavigatorWrapper';
import StyledSubMenuNavigatorHeader from '../../../styles/Common/SubMenuNavigator/StyledSubMenuNavigatorHeader';
import StyledSubMenuNavigatorContentsWrapper from '../../../styles/Common/SubMenuNavigator/StyledSubMenuNavigatorContentsWrapper';
import SubMenuItem from './SubMenuItem';

const SubMenuNavigator = ({ page }) => {
  const router = getAuthorizedMenu({
    allMenu: SIDEBAR_SUB_MENU?.[SUB_MENU_MAPPER[page]],
  });

  return (
    <StyledSubMenuNavigatorWrapper>
      <StyledSubMenuNavigatorHeader>
        {PAGE_NAME_MAPPER?.[page]}
      </StyledSubMenuNavigatorHeader>
      <StyledSubMenuNavigatorContentsWrapper>
        {router?.map(element => (
          <SubMenuItem key={element?.seq} {...element} />
        ))}
      </StyledSubMenuNavigatorContentsWrapper>
    </StyledSubMenuNavigatorWrapper>
  );
};

export default SubMenuNavigator;
