import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';
import bcDetailDefaultApi from '../../../api';

const deleteAssignDriver = async ({ woNo, seq, ...props }) => {
  const { isDual } = props;

  try {
    const response = await bcDetailDefaultApi.delete(
      `${woNo}/assign-driver/delete/${seq}?page=bcdetail${
        isDual ? '&dual=true' : ''
      }`,
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default deleteAssignDriver;
