import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CommonMessageModal from '../../../../CommonMessageModal/CommonMessageModal';
import AdvancedSearchModalContent from './ModalContent';
import AdvancedSearchButtonContent from './ModalButton';

const AdvancedSearchModal = ({
  open,
  setIsOpen,
  setCycle,
  setDateQuery,
  ...props
}) => {
  const searchController = useForm();

  const handleSearchSubmit = submitData => {
    setDateQuery({ date: submitData?.date });
    setCycle(submitData?.period);
    setIsOpen(false);
    searchController.reset();
  };

  return (
    <FormProvider {...searchController}>
      <form onSubmit={searchController.handleSubmit(handleSearchSubmit)}>
        <CommonMessageModal
          open={open}
          setOpen={setIsOpen}
          header="Advanced Search"
          content={<AdvancedSearchModalContent {...props} />}
          buttonContent={<AdvancedSearchButtonContent setIsOpen={setIsOpen} />}
        />
      </form>
    </FormProvider>
  );
};

export default AdvancedSearchModal;
