import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthRouter from '../../../../../components/Common/AuthRouter';
import PODCheckList from '../../../../../pages/MobileAdmin/PODCheckList';
import {
  PERM_MOBILE_ADMIN_BILL_CONFIRM,
  PERM_MOBILE_ADMIN_MANIFEST,
  PERM_MOBILE_ADMIN_POD_CHECKLIST,
} from '../../../permission';
import BillConfirm from '../../../../../pages/MobileAdmin/BillConfirm';
import Manifest from '../../../../../pages/MobileAdmin/Manifest';
import getAuthorizedMenu from '../../../../../utils/Common/Sidebar/Handler/getAuthorizedMenu';
import { SIDEBAR_SUB_MENU } from '../../../Option/Sidebar';

const MOBILE_ADMIN_CHILDREN_ROUTE = [
  {
    index: true,
    element: (
      <Navigate
        to={
          getAuthorizedMenu({
            allMenu: SIDEBAR_SUB_MENU?.[5],
          })?.[0]?.to
        }
      />
    ),
  },
  {
    path: 'pod-check-list/*',
    element: (
      <AuthRouter permission={PERM_MOBILE_ADMIN_POD_CHECKLIST}>
        <PODCheckList />
      </AuthRouter>
    ),
  },
  {
    path: 'bill-confirm/*',
    element: (
      <AuthRouter permission={PERM_MOBILE_ADMIN_BILL_CONFIRM}>
        <BillConfirm />
      </AuthRouter>
    ),
  },
  {
    path: 'manifest/*',
    element: (
      <AuthRouter permission={PERM_MOBILE_ADMIN_MANIFEST}>
        <Manifest />
      </AuthRouter>
    ),
  },
];

export default MOBILE_ADMIN_CHILDREN_ROUTE;
