export const woDateObjectListGenerator = ({ woData }) => {
  const objectList = woData?.map(wo => {
    return {
      woNo: wo?.wo_no,
      woDate: wo?.date,
      woDiv: wo?.div,
      woPool: wo?.pool,
      woContNo: wo?.cont_no,
      woChassisNo: wo?.ch_no,
    };
  });

  return objectList;
};

export const woOptionGenerator = ({ woData }) => {
  const optionList = woData?.map(wo => {
    return { label: wo?.wo_no, value: wo?.wo_no };
  });

  return optionList;
};
