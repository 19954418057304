import React from 'react';
import { PATH_SCANNING_ICON } from '../../../../constants/Common/path';
import CommonButton from '../../../CommonButton/CommonButton';
import { RED_FILE_UPLOAD_ICON } from '../../../../constants/Acct/Common/iconPath';
import uploadOtherFile from '../../../../utils/Acct/Common/Handler/uploadOtherFile';

const ScannerViewer = ({
  data,
  scanStatus,
  setInvoiceList,
  resetScan,
  resetVendor,
  scanMutator,
  invoiceKey,
  setInvoiceKey,
}) => {
  const { isPending } = scanStatus;
  return (
    <div
      className={`w-full h-full relative rounded-tl-[20px] rounded-bl-[20px] cursor-auto ${
        data?.type === 'application/pdf' ? 'bg-[#474A4D]' : 'bg-[#DDDFE5]'
      }`}
      onClick={e => e?.stopPropagation()}
    >
      <div className={`${isPending ? `blur-sm` : ''} w-full h-full absolute`}>
        <div className="w-full h-full rounded-tl-[20px] rounded-bl-[20px] flex justify-center items-center">
          {data?.type === 'application/pdf' ? (
            <div className="w-10/12 h-5/6 flex">
              <iframe
                title="reconcileViewer"
                src={data && `${URL.createObjectURL(data)}#toolbar=0`}
                className="w-full h-full"
              />
            </div>
          ) : (
            <div className="w-full h-full flex justify-center items-center flex-col gap-[10px]">
              <p className="text-[#556DA1] font-bold text-[18px]">
                Viewer and Scan are only supported with PDF file
              </p>
              <p className="text-[#7F90B6] font-medium text-[18px]">
                Upload is available for all types
              </p>
            </div>
          )}
        </div>
        <div className="flex w-full gap-x-[11px] mt-[-50px] justify-center">
          <CommonButton
            type="button"
            category="upload"
            icon={
              <img src={RED_FILE_UPLOAD_ICON} alt="hyphen-icon" width={13} />
            }
            fontWeight="700"
            borderColor="#B74747"
            onClick={({ e }) => {
              uploadOtherFile({
                e,
                setInvoiceList,
                resetScan,
                resetVendor,
                invoiceKey,
                setInvoiceKey,
              });
            }}
          >
            Upload Other File
          </CommonButton>
          <CommonButton
            category="areaScan"
            preventDupClick
            onClick={({ buttonRef }) => {
              scanMutator({ file: data, buttonRef });
            }}
            disabled={data?.type !== 'application/pdf'}
          >
            Scan
          </CommonButton>
        </div>
      </div>
      {isPending && (
        <div className="absolute flex justify-center items-center w-full h-full">
          <img
            src={PATH_SCANNING_ICON}
            alt="scanning"
            className="w-[235px] h-[135px] ml-2"
          />
        </div>
      )}
    </div>
  );
};

export default ScannerViewer;
