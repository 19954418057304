import bcDetailWoDefaultApi from '../api';
import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';

const patchDeleteWO = async ({ woNo }) => {
  try {
    const response = await bcDetailWoDefaultApi.patch(`${woNo}/delete`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchDeleteWO;
