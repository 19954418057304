import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SUCCESS_UPDATE } from '../../../../constants/Common/successMessage';
import putColumnOrder from '../../../../services/Dispatch2/Common/Put/putColumnOrder';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';
import useControlToast from '../../../Common/Toast/useControlToast';
import dispatchKeys from '../../keys';

const usePutColumnOrder = ({
  setIsMessageModalOpen,
  setMessageModalMessage,
  setCustomizeCols,
  setColumnList,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();
  const mutator = useMutation({
    mutationFn: putColumnOrder,
    refetchOnWindowFocus: false,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: data => {
      setCustomizeCols(false);
      setColumnList(data?.data);
      queryClient?.invalidateQueries({ queryKey: dispatchKeys?.list() });
      addToast({ message: SUCCESS_UPDATE });
    },
    onError: error => {
      setCustomizeCols(false);
      setMessageModalMessage(error.message);
      setIsMessageModalOpen(true);
    },
  });
  return mutator;
};

export default usePutColumnOrder;
