import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SUCCESS_SAVE } from '../../../constants/Common/successMessage';
import postAuthList from '../../../services/Admin/Auth/postAuthList';
import useControlToast from '../../Common/Toast/useControlToast';
import adminKeys from '../keys';

const usePostAuthMutation = ({
  setIsMessageOpen,
  setMessage,
  setIsSuccess,
  gridRef,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  return useMutation({
    mutationFn: postAuthList,
    onSettled: (data, error, variables) => {
      variables?.setAddDisabled(false);
    },
    onSuccess: () => {
      const topRow = gridRef.current.api.getPinnedTopRow(0);
      topRow.setData({});
      gridRef.current.api.redrawRows({ rowNodes: [topRow] });
      queryClient.refetchQueries({ queryKey: adminKeys?.authList() });
      addToast({
        message: SUCCESS_SAVE,
      });
    },
    onError: error => {
      setIsSuccess(false);
      setMessage(error.message);
      setIsMessageOpen(true);
    },
  });
};

export default usePostAuthMutation;
