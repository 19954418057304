import { useQuery } from '@tanstack/react-query';
import { commonCompanyAPI } from '../../../services/Common/Company/commonCompanyAPI';
import ediKeys from '../keys';

const useGetCustomerList = () => {
  const {
    isLoading: isLoadingCustomerList,
    data,
    isRefetching: isRefetchingCustomerList,
  } = useQuery({
    queryKey: ediKeys.customerList(),
    queryFn: () => commonCompanyAPI.getCommonCustomerCompany(),
    refetchOnWindowFocus: false,
  });

  return {
    isLoadingCustomerList,
    customerList: data?.data,
    isRefetchingCustomerList,
  };
};

export default useGetCustomerList;
