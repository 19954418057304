import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { FormProvider, useForm } from 'react-hook-form';
import CommonTextInput from '../../../Inputs/CommonTextInput/CommonTextInput';
import CommonSelect from '../../../Inputs/CommonSelect/CommonSelect';
import CommonDate from '../../../Inputs/CommonDate/CommonDate';
import CommonButton from '../../../CommonButton/CommonButton';
import { REGEX_NUMBER_MINUS_DECIMAL } from '../../../../constants/Common/regex';
import { RECONCILE_STATUS } from '../../../../constants/Acct/Common/acctElement';
import {
  changeText,
  changeDate,
  changeSelect,
} from '../../../../utils/Acct/Common/Handler/changedInputFieldHandler';
import useGetWOChassisToOptions from '../../../../hooks/Acct/ChassisReconcile/Get/useGetWOChassisToOptions';
import useGetWOChassisFromOptions from '../../../../hooks/Acct/ChassisReconcile/Get/useGetWOChassisFromOptions';
import handleWOChange from '../../../../utils/Acct/ChassisReconcile/Handler/handleWOChange';
import { deleteForm } from '../../../../utils/Acct/Common/Handler/formListHandler';
import {
  NULL_PARAMETER_VALUE,
  SAVE_TYPE,
} from '../../../../constants/Acct/Common/staticNames';
import saveInvoiceForm from '../../../../utils/Acct/Common/Handler/saveInvoiceForm';
import { handleModalWOOptionChange } from '../../../../utils/Acct/Common/Handler/handleWOOptionChange';
import generateInvoiceDateRange from '../../../../utils/Acct/Common/Generator/generateInvoiceDateRange';
import CommonAutoComplete from '../../../Inputs/CommonAutoComplete/CommonAutoComplete';
import useCheckFormValidity from '../../../../hooks/Common/Input/useCheckFormValidity';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';

const InvoiceFormWrapper = ({
  index,
  formKey,
  invoice,
  poolList,
  setFormList,
  setInvoiceList,
  setSubmitLength,
  setSubmitData,
  scannedFile,
  postChassisMutator,
  duplicateCheckFields,
  setIsAllSave,
  handleSubmitVendor,
  formList,
  setAlwaysFlag,
  setProgressLength,
  setDuplicationErrorList,
  setResolveErrorList,
  setApplyType,
  setErrorModalOptions,
  setBasicErrorMessage,
  setIsCompletedPatchFlag,
}) => {
  const invoiceController = useForm();
  const { setValue, getValues } = invoiceController;

  /** Duplicate click preventer and Form validator */
  const saveRef = useRef(null);
  const formStates = [invoiceController?.formState];
  const { isFormInvalid } = useCheckFormValidity({ formStates });

  useEffect(() => {
    /* mount */
    setFormList(prevForm => [
      ...prevForm,
      { key: formKey, form: invoiceController, buttonRef: saveRef },
    ]);

    /* unmount */
    return () => {
      setFormList(prevForm => prevForm?.filter(form => form?.key !== formKey));
    };
  }, []);

  const [isChangePool, setIsChangePool] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const [woFromQueryObject, setWOFromQueryObject] = useState({});
  const [woToQueryObject, setWOToQueryObject] = useState({});

  const { woFromOptionList, woFromDateObjectList, isLoadingFromWO } =
    useGetWOChassisFromOptions({ queryObject: woFromQueryObject, isFocused });
  const { woToOptionList, woToDateObjectList, isLoadingWOTo } =
    useGetWOChassisToOptions({
      queryObject: woToQueryObject,
      isFocused,
    });

  useEffect(() => {
    handleModalWOOptionChange({
      woFieldName: 'wo_from',
      setValue,
      getValues,
      woOptionList: woFromOptionList,
      pairFieldList: ['from', 'div'],
    });
  }, [woFromOptionList]);

  useEffect(() => {
    handleModalWOOptionChange({
      woFieldName: 'wo_to',
      setValue,
      getValues,
      woOptionList: woToOptionList,
      pairFieldList: ['to'],
    });
  }, [woToOptionList]);

  useEffect(() => {
    if (isFocused) {
      setWOFromQueryObject(prev => ({
        ch_no: getValues('ch_no') || NULL_PARAMETER_VALUE,
        cont_no: getValues('cont_from') || NULL_PARAMETER_VALUE,
        ...generateInvoiceDateRange(getValues('inv_from')),
      }));
      setWOToQueryObject(prev => ({
        ch_no: getValues('ch_no') || NULL_PARAMETER_VALUE,
        cont_no: getValues('cont_to') || NULL_PARAMETER_VALUE,
        ...generateInvoiceDateRange(getValues('inv_to')),
      }));
    }
  }, [isFocused]);

  return (
    <FormProvider {...invoiceController}>
      <form>
        <div className="w-[1025px] h-[201px] rounded-[5px] bg-[#F2F4F8] pl-[26px] pr-[22px] pt-[18px] pb-[34px]">
          <div className="w-full flex flex-row justify-between pb-[11px]">
            <span className="font-[700] text-[13px] text-[#264B9F]">
              #{index + 1}
            </span>
            <div className="w-full flex flex-row justify-end gap-x-[9px]">
              <CommonButton
                type="button"
                category="greyDelete"
                borderColor="#666666"
                size="small"
                onClick={() => {
                  deleteForm({
                    formKey,
                    setInvoiceList,
                  });
                }}
              >
                Delete
              </CommonButton>
              <CommonButton
                ref={saveRef}
                type="button"
                category="save"
                size="small"
                preventDupClick
                onClick={() => {
                  isFormInvalid &&
                    updateButtonDisabled({ ref: saveRef, disabled: false });
                  saveInvoiceForm({
                    scannedFile,
                    handleSubmitVendor,
                    formList: [formList?.find(form => form?.key === formKey)],
                    setSubmitLength,
                    setSubmitData,
                    postMutator: postChassisMutator,
                    setAlwaysFlag,
                    duplicateCheckFields,
                    setIsAllSave,
                    setErrorModalOptions,
                    setProgressLength,
                    setDuplicationErrorList,
                    setResolveErrorList,
                    setApplyType,
                    saveType: SAVE_TYPE?.ONE,
                    setBasicErrorMessage,
                    setIsCompletedPatchFlag,
                    type: 'chassis',
                  });
                }}
              >
                Save
              </CommonButton>
            </div>
          </div>
          <div className="w-full h-[1px] bg-[#FFFFFF] mb-[15px]" />
          <div
            className="px-[4px] h-[100px] flex flex-col justify-between"
            onFocus={() => {
              setIsFocused(true);
            }}
          >
            <div className="flex flex-row gap-x-[17px]">
              <div className="w-[91px]">
                <CommonSelect
                  label="STATUS"
                  option={RECONCILE_STATUS}
                  required
                  inputName="status"
                  defaultValue={invoice?.status || ''}
                  onChangeHandler={changeSelect}
                />
              </div>
              <div className="w-[117px]">
                <CommonTextInput
                  label="INV #"
                  required
                  inputName="inv_no"
                  defaultValue={invoice?.inv_no || ''}
                  onChangeHandler={changeText}
                  onBlurHandler={({ e, onChange }) => {
                    const value = e?.target?.value?.toUpperCase();
                    onChange(value);
                  }}
                />
              </div>
              <div className="w-[106px]">
                <CommonDate
                  required
                  defaultValue={invoice?.inv_date || ''}
                  label="INV DATE"
                  inputName="inv_date"
                  onChangeHandler={changeDate}
                />
              </div>
              <div className="w-[112px]">
                <CommonTextInput
                  label="P/O CONT #"
                  inputName="cont_from"
                  defaultValue={invoice?.from_cont_no || ''}
                  onChangeHandler={({ e, onChange }) => {
                    const value = e?.target?.value;
                    onChange(value);
                  }}
                  onBlurHandler={({ e, onChange }) => {
                    const value = e?.target?.value?.toUpperCase();
                    onChange(value);
                    setWOFromQueryObject(prev => ({
                      ...prev,
                      cont_no: value || NULL_PARAMETER_VALUE,
                    }));
                  }}
                />
              </div>
              <div className="w-[112px]">
                <CommonTextInput
                  label="RTN CONT #"
                  defaultValue={invoice?.to_cont_no || ''}
                  inputName="cont_to"
                  onChangeHandler={({ e, onChange }) => {
                    const value = e?.target?.value;
                    onChange(value);
                  }}
                  onBlurHandler={({ e, onChange }) => {
                    const value = e?.target?.value?.toUpperCase();
                    onChange(value);
                    setWOToQueryObject(prev => ({
                      ...prev,
                      cont_no: value || NULL_PARAMETER_VALUE,
                    }));
                  }}
                />
              </div>
              <div className="w-[106px]">
                <CommonDate
                  inputName="inv_from"
                  defaultValue={invoice?.inv_from || ''}
                  label="FROM"
                  required
                  onChangeHandler={({ e, ...props }) => {
                    changeDate({ e, ...props });
                    const invDate =
                      e && dayjs(e)?.isValid()
                        ? dayjs(e)?.format('YYYY-MM-DD')
                        : '';
                    setWOFromQueryObject(prev => ({
                      ...prev,
                      ...generateInvoiceDateRange(invDate),
                    }));
                  }}
                />
              </div>
              <div className="w-[106px]">
                <CommonDate
                  defaultValue={invoice?.inv_to || ''}
                  inputName="inv_to"
                  label="TO"
                  required
                  onChangeHandler={({ e, ...props }) => {
                    changeDate({ e, ...props });

                    const invDate =
                      e && dayjs(e)?.isValid()
                        ? dayjs(e)?.format('YYYY-MM-DD')
                        : '';
                    setWOToQueryObject(prev => ({
                      ...prev,
                      ...generateInvoiceDateRange(invDate),
                    }));
                  }}
                />
              </div>
              <div className="w-[105px]">
                <CommonTextInput
                  label="AMT"
                  inputName="inv_amt"
                  required
                  defaultValue={invoice?.inv_amt || ''}
                  onChangeHandler={changeText}
                  regex={REGEX_NUMBER_MINUS_DECIMAL}
                  adornment={{
                    position: 'start',
                    content: '$',
                    styles: {
                      fontSize: '12px',
                      color: 'black',
                      fontWeight: '700',
                    },
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row gap-x-[17px] ">
              <div className="w-[91px]">
                <CommonTextInput
                  label="DIV"
                  inputName="div"
                  defaultValue={invoice?.div || ''}
                  disabled
                  required
                  onChangeHandler={changeText}
                />
              </div>
              <div className="w-[128px]">
                <CommonTextInput
                  label="CHASSIS #"
                  inputName="ch_no"
                  defaultValue={invoice?.ch_no || ''}
                  maxLength={12}
                  required
                  onChangeHandler={({ e, onChange }) => {
                    onChange(e?.target?.value);
                    const value = e?.target?.value?.toUpperCase();
                    if (value?.startsWith('NGLT') && !isChangePool) {
                      setValue('pool', 'NGL');
                      setIsChangePool(true);
                    }
                  }}
                  onBlurHandler={({ e, onChange }) => {
                    const value = e?.target?.value?.toUpperCase();
                    onChange(value);
                    setWOFromQueryObject(prev => ({
                      ...prev,
                      ch_no: value || NULL_PARAMETER_VALUE,
                    }));
                    setWOToQueryObject(prev => ({
                      ...prev,
                      ch_no: value || NULL_PARAMETER_VALUE,
                    }));
                  }}
                />
              </div>
              <div className="w-[132px]">
                <CommonSelect
                  label="POOL"
                  option={poolList}
                  inputName="pool"
                  defaultValue={invoice?.pool || ''}
                  required
                  onChangeHandler={changeSelect}
                />
              </div>
              <div className="w-[155px]">
                <CommonAutoComplete
                  label="WO(FROM)"
                  inputName="wo_from"
                  required
                  defaultValue={invoice?.wo_from || ''}
                  option={woFromOptionList || []}
                  isLoading={isLoadingFromWO}
                  onChangeHandler={handleWOChange}
                  changeFieldNameList={[
                    'from',
                    'div',
                    'pool',
                    'ch_no',
                    'cont_from',
                  ]}
                  setValue={setValue}
                  woObjectList={woFromDateObjectList}
                  setWOFromQueryObject={setWOFromQueryObject}
                  setWOToQueryObject={setWOToQueryObject}
                />
              </div>

              <div className="w-[106px]">
                <CommonDate
                  inputName="from"
                  label="FROM"
                  disabled
                  onChangeHandler={changeDate}
                />
              </div>
              <div className="w-[155px]">
                <CommonAutoComplete
                  label="WO(TO)"
                  inputName="wo_to"
                  required
                  defaultValue={invoice?.wo_to || ''}
                  option={woToOptionList || []}
                  isLoading={isLoadingWOTo}
                  onChangeHandler={handleWOChange}
                  changeFieldNameList={['to', 'ch_no', 'cont_to']}
                  setValue={setValue}
                  woObjectList={woToDateObjectList}
                  setWOFromQueryObject={setWOFromQueryObject}
                  setWOToQueryObject={setWOToQueryObject}
                />
              </div>
              <div className="w-[106px]">
                <CommonDate
                  inputName="to"
                  label="TO"
                  disabled
                  onChangeHandler={changeDate}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default InvoiceFormWrapper;
