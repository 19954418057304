import React from 'react';
import { useFormContext } from 'react-hook-form';
import CommonButton from '../../../../CommonButton/CommonButton';
import resetForm from '../../../../../utils/Order/Handler/resetForm';

const ClearModalButtonContent = ({
  setClearModal,
  setContainerList,
  setContainerCount,
  setBookingSequence,
  ...props
}) => {
  const subCategory = document.URL?.split('/')?.pop();

  const { reset } = useFormContext();

  return (
    <div className="flex justify-center items-center gap-[16px]">
      <div>
        <CommonButton
          category="cancel"
          onClick={() => {
            setClearModal?.(false);
          }}
        >
          Cancel
        </CommonButton>
      </div>
      <div>
        <CommonButton
          category="clearBlue"
          borderColor="#556DA1"
          onClick={() => {
            resetForm({
              reset,
              setContainerCount,
              setContainerList,
              subCategory,
            });
            setBookingSequence?.(null);
            setClearModal?.(false);
          }}
        >
          Clear
        </CommonButton>
      </div>
    </div>
  );
};

export default ClearModalButtonContent;
