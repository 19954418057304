import React from 'react';
import StyledBCDetailBillingInfoProfitContainer from '../../../styles/BCDetail/BillingInfo/Profit/StyledBCDetailBillingInfoProfitContainer';
import StyledBCDetailBillingInfoProfitDollarSign from '../../../styles/BCDetail/BillingInfo/Profit/StyledBCDetailBillingInfoProfitDollarSign';
import StyledBCDetailBillingInfoProfitText from '../../../styles/BCDetail/BillingInfo/Profit/StyledBCDetailBillingInfoProfitText';
import StyledBCDetailBillingInfoProfitValue from '../../../styles/BCDetail/BillingInfo/Profit/StyledBCDetailBillingInfoProfitValue';
import formatNumber from '../../../utils/BCDetail/BillingInfo/Common/formatNumber';

const Profit = ({ profit }) => {
  return (
    <StyledBCDetailBillingInfoProfitContainer>
      <StyledBCDetailBillingInfoProfitText>
        TOTAL PROFIT
      </StyledBCDetailBillingInfoProfitText>
      <StyledBCDetailBillingInfoProfitValue profit={profit}>
        <StyledBCDetailBillingInfoProfitDollarSign>
          $
        </StyledBCDetailBillingInfoProfitDollarSign>
        {formatNumber(profit)}
      </StyledBCDetailBillingInfoProfitValue>
    </StyledBCDetailBillingInfoProfitContainer>
  );
};

export default Profit;
