import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import React, { useState } from 'react';

import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const ControlMuiCheckBox = props => {
  const [checked, setChecked] = useState(props?.defaultValue || false);
  const [label, setLabel] = useState(props.label);
  const [disabled, setDisabled] = useState(props.disabled);

  // const onCheckHandler = (e, onChange) => {
  //   setChecked(!checked);
  //   onChange(!checked);
  // };

  const { control } = useFormContext();

  return (
    <FormControlLabel
      labelPlacement={props.labelPlacement}
      label={
        label && (
          <Typography
            variant="h3"
            component="h3"
            sx={{
              fontSize: '12px',
              color: '#222',
              lineHeight: '1.14',
              marginRight: '5px',
            }}
          >
            {label}
          </Typography>
        )
      }
      control={
        <Controller
          control={control}
          name={props?.inputName}
          defaultValue={props?.defaultValue || false}
          render={({ field: { onChange, value, ...field } }) => (
            <Checkbox
              // Bind react hook form register
              icon={<CheckBoxOutlineBlankIcon />}
              checkedIcon={<CheckBoxIcon />}
              checked={!!value}
              disabled={disabled}
              onChange={e => {
                props?.onChangeHandler?.({ e, onChange, ...props });
              }}
              sx={{
                width: '12px',
                height: '12px',
                color: '#aaa',
                border: 'unset',
                '& .MuiSvgIcon-root': { fontSize: 16 },
              }}
            />
          )}
        />
      }
    />
  );
};

export default ControlMuiCheckBox;
