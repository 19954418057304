import normalCellRendererGenerator from './normalCellRendererGenerator';
import rowPinnedCellRendererGenerator from './rowPinnedCellRendererGenerator';

/**
 * @description
 * Function that generate cell renderer. Used in cell render selector in column definition.
 * @param {object} gridParam
 * Parameter that contains the grid object(API).
 * @returns
 * Cell renderer.
 */
const cellRendererGenerator = ({
  gridParam,
  cellType,
  adornment,
  customStyle,
  ...props
}) => {
  /** Generate pinned row generator */
  if (gridParam?.node?.rowPinned) {
    return rowPinnedCellRendererGenerator({
      gridParam,
      cellType,
      adornment,
      customStyle,
      ...props,
    });
  }
  /** Normal cell renderer */
  return normalCellRendererGenerator({
    gridParam,
    cellType,
    adornment,
    customStyle,
    ...props,
  });
};

export default cellRendererGenerator;
