/**
 * @description
 * Path of icon used in the sidebar component.
 */
const PATH_SIDEBAR_ICON_NORMAL = '/assets/images/menu_icon/normal';
const PATH_SIDEBAR_ICON_COLOR = '/assets/images/menu_icon/color';

export const PATH_SIDEBAR_MENU_ICON = {
  order: {
    default: `${PATH_SIDEBAR_ICON_NORMAL}/order.svg`,
    active: `${PATH_SIDEBAR_ICON_COLOR}/order.svg`,
  },
  dispatch: {
    default: `${PATH_SIDEBAR_ICON_NORMAL}/dispatch.svg`,
    active: `${PATH_SIDEBAR_ICON_COLOR}/dispatch.svg`,
  },
  main: {
    default: `${PATH_SIDEBAR_ICON_NORMAL}/main.svg`,
    active: `${PATH_SIDEBAR_ICON_COLOR}/main.svg`,
  },
  'customer-report': {
    default: `${PATH_SIDEBAR_ICON_NORMAL}/cus_report.svg`,
    active: `${PATH_SIDEBAR_ICON_COLOR}/cus_report.svg`,
  },
  'tms-report': {
    default: `${PATH_SIDEBAR_ICON_NORMAL}/tms_report.svg`,
    active: `${PATH_SIDEBAR_ICON_COLOR}/tms_report.svg`,
  },
  'mobile-admin': {
    default: `${PATH_SIDEBAR_ICON_NORMAL}/mobile_admin.svg`,
    active: `${PATH_SIDEBAR_ICON_COLOR}/mobile_admin.svg`,
  },
  trace: {
    default: `${PATH_SIDEBAR_ICON_NORMAL}/trace.svg`,
    active: `${PATH_SIDEBAR_ICON_COLOR}/trace.svg`,
  },
  edi: {
    default: `${PATH_SIDEBAR_ICON_NORMAL}/edi.svg`,
    active: `${PATH_SIDEBAR_ICON_COLOR}/edi.svg`,
  },
  acct: {
    default: `${PATH_SIDEBAR_ICON_NORMAL}/acct.svg`,
    active: `${PATH_SIDEBAR_ICON_COLOR}/acct.svg`,
  },
  master: {
    default: `${PATH_SIDEBAR_ICON_NORMAL}/master.svg`,
    active: `${PATH_SIDEBAR_ICON_COLOR}/master.svg`,
  },
  admin: {
    default: `${PATH_SIDEBAR_ICON_NORMAL}/admin.svg`,
    active: `${PATH_SIDEBAR_ICON_COLOR}/admin.svg`,
  },
};
