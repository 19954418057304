const viewDualMoveContentJobRowDelete = ({
  dualMoveRowData,
  job,
  isModifiable,
}) => {
  const { type } = dualMoveRowData;
  const { type: jobType, status } = job;

  return (
    type === 'RTN' && jobType !== 'RTN' && isModifiable && status === 'Pending'
  );
};

export default viewDualMoveContentJobRowDelete;
