import React from 'react';
import CommonButton from '../../../../../../CommonButton/CommonButton';

const CompleteConfirmModalButton = ({ ...props }) => {
  const { setOpen, mutator, woNo } = props;

  return (
    <div className="flex justify-center items-center gap-[16px]">
      <div>
        <CommonButton
          category="cancel"
          onClick={() => {
            setOpen(prev => false);
          }}
        >
          Cancel
        </CommonButton>
      </div>
      <div>
        <CommonButton
          category="gotIt"
          borderColor="#556DA1"
          preventDupClick
          onClick={() => {
            mutator?.({ woNo });
            setOpen(prev => false);
          }}
        >
          Got it
        </CommonButton>
      </div>
    </div>
  );
};

export default CompleteConfirmModalButton;
