import axios from 'axios';
import interceptRequest from '../../../utils/Common/Interceptor/interceptRequest';

/**
 * Ar rate base url
 */
const AR_RATE_BASE_URL = `${process.env.REACT_APP_BASE_URL}/master/arrate`;

/**
 * Axios object with base url
 */
const arRateAPI = axios.create({
  withCredentials: true,
  baseURL: AR_RATE_BASE_URL,
  xsrfHeaderName: 'X-CSRFTOKEN',
  xsrfCookieName: 'XCSRF-TOKEN',
  headers: {
    common: {
      'X-CSRFToken': localStorage.getItem('csrfToken'),
    },
  },
});

/** Interceptors */
interceptRequest(arRateAPI);

export default arRateAPI;
