import tmsReportAPI from '../../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

const getReturnYard = async ({ params }) => {
  try {
    const response = await tmsReportAPI.get('/yard-schedule', {
      params,
    });
    return response?.data;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getReturnYard;
