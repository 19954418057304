import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import useGetCompanyData from '../../../hooks/Master/Company/useGetCompanyData';
// import defaultColumnDefsGenerator from '../../../utils/Labs/DataGrid/Generator/defaultColumnDefsGenerator';
import CommonDataGrid from '../../../components/CommonDataGrid';

import {
  GRID_MOD_BOTH,
  GRID_MOD_DELETE,
  GRID_MOD_EDIT,
} from '../../../constants/Common/DataGrid/setting';
import columnDefsGenerator from '../../../utils/Labs/DataGrid/Generator/columnDefsGenerator';
import defaultColumnDefsGenerator from '../../../utils/Common/Datagrid/Generator/defaultColumnDefsGenerator';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import RowCustomDetail from './RowCustomDetailExample';

const LabsGridAG = () => {
  /** Get grid data */
  const queryResult = [
    {
      company: 'LAUFER01',
      category: 'CO0001',
      type: 'COC003',
      group_id: null,
      billto: null,
      active: true,
      business_name: 'Laufer Group International LTD.',
      legal_name: null,
      address_01: '20 Vesey Street',
      address_02: 'Suite 601',
      city: 'New York',
      state: 'NY',
      zip: '10007',
      country: 'US',
      timezone: 'TZ',
      contact_name: null,
      email: 'jiwoong.w@nglinnovation.net',
      office_no: null,
      ext_no: null,
      cell_no: null,
      fax: null,
      remarks: null,
      acct_name: null,
      acct_phone: null,
      acct_fax: null,
      acct_email: 'LAUFER01@nglinnovation.net',
      credit_hold: false,
      terms: 'CT0002',
      credit_limit: null,
      factoring: false,
      credit_app_filed: false,
      credit_app_file: null,
      w9: null,
      w9_file: null,
      ein_no: null,
      ssn: null,
      ssn_file: null,
      vendor_1099: null,
      bank: null,
      bank_type: null,
      bank_file: null,
      void_check: null,
      routing_no: null,
      acct_no: null,
      auto_tracking: false,
      qb_id: '295',
      created_by: 'SANGWY01',
      created_date: '2024-01-17T20:32:00',
      updated_by: 'SANGWY01',
      updated_date: '2024-02-05T20:05:00',
      toggle: true,
    },
  ];
  // useEffect(() => {
  //   console.log('Query result : ', queryResult);
  // }, [queryResult]);

  /** Get column definition */
  const poolRedux = useSelector(state => state?.common?.pool);
  const [poolList, setPoolList] = useState([]);
  useEffect(() => {
    setPoolList(
      poolRedux?.map(item => {
        return {
          label: item?.name,
          value: item?.pool,
        };
      }),
    );
  }, [poolRedux]);

  const getTestColumnDefs = columnDefsGenerator({ poolList });

  const getTestDefaultColumnDefs = defaultColumnDefsGenerator();

  /** Custom grid reference */
  const gridRef = useRef(null);

  /**
   * Click handler
   * @handler onRowClicked
   * Handler for row click
   * @handler onCellClicked
   * Handler for cell click
   * @handler onSaveClicked
   * Handler for save button click
   * @handler onDeleteClicked
   * Handler for delete button click
   * @handler onAddClicked
   * Handler for add button click
   */
  const onRowClicked = ({ gridParam, ...props }) => {
    const getColId = gridParam?.api?.getFocusedCell().column.getColId();
    if (!['exportBtn', 'completeBtn'].includes(getColId)) {
      console.log(
        'gridParam row click : ',
        gridParam,
        props,
        gridParam?.api?.getFocusedCell().column.getColId(),
      );
    }
  };

  const onCellClicked = ({ gridParam, ...props }) => {
    if (!['exportBtn', 'completeBtn'].includes(gridParam?.column?.colId)) {
      console.log('gridParam cell click : ', gridParam, props);
    }
  };

  const onSaveClicked = ({ gridParam, ...props }) => {
    console.log('Save clicked grid param : ', gridParam, props);
  };

  const onDeleteClicked = ({ gridParam, ...props }) => {
    console.log('Delete clicked grid param : ', gridParam, props);
  };

  const onAddClicked = ({ gridParam, ...props }) => {
    console.log('Add clicked grid param : ', gridParam, props);
  };

  const onRowEditingStart = ({ gridParam, ...props }) => {
    console.log('Row editing started grid param : ', gridParam, props);
  };

  const onRowEditingStop = ({ gridParam, ...props }) => {
    console.log('Row editing stopped grid param : ', gridParam, props);
  };

  /** Context menu */
  const createContextMenuItems = () => [
    {
      name: 'James is handsome',
      action: () => {
        window.alert('James is handsome: Fact');
      },
    },
    {
      name: 'Hwi is handsome',
      action: () => {
        window.alert('James is more handsome: Fact');
      },
    },
    {
      name: 'David is Hmm...',
      action: () => {
        window.alert('James is more more handsome: Fact');
      },
    },
  ];

  /** Column type */
  const createColumnTypes = {
    nglTextType: {
      filter: 'agTextColumnFilter',
    },
    nglDateType: {
      filter: 'agDateColumnFilter',
    },
  };

  return (
    <>
      <div className="w-full h-[830px] mt-[30px] bg-yellow-200">
        {poolList?.length > 0 && (
          <CommonDataGrid
            gridRef={gridRef}
            // data={queryResult?.companyList?.data}
            data={queryResult}
            columnDefs={getTestColumnDefs || []}
            defaultColDef={getTestDefaultColumnDefs}
            columnTypes={createColumnTypes}
            onRowClicked={onRowClicked}
            onCellClicked={onCellClicked}
            onSaveClicked={onSaveClicked}
            onDeleteClicked={onDeleteClicked}
            onAddClicked={onAddClicked}
            onRowEditingStart={onRowEditingStart}
            onRowEditingStop={onRowEditingStop}
            getContextMenuItems={createContextMenuItems}
            rowSelection="multiple"
            modType={GRID_MOD_BOTH}
            addable
            foldable
            foldableContent={RowCustomDetail}
            enableCellClickColumn={['company', 'Dollar']}
          />
        )}
      </div>
      <CommonLoading open={false} />
    </>
  );
};

export default LabsGridAG;
