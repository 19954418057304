const checkJobSetCompleted = ({ jobSet, jobIdxFlag, scheduleData, isLast }) => {
  if (jobSet[jobIdxFlag]) {
    const getSpecJob = jobSet?.[jobIdxFlag];
    const getFromJobSet = getSpecJob?.from_location;
    const getLiveDilvJobSet = getSpecJob?.live_dilv_location;
    const getToJobSet = getSpecJob?.to_location;

    /** Check target schedule is not in job */
    if (
      scheduleData?.seq !== getFromJobSet?.seq &&
      scheduleData?.seq !== getLiveDilvJobSet?.seq &&
      scheduleData?.seq !== getToJobSet?.seq
    ) {
      /** Check from, ld, to */
      if (
        (getFromJobSet && getFromJobSet?.status !== 'Pending') ||
        (getLiveDilvJobSet && getLiveDilvJobSet?.status !== 'Pending') ||
        (getToJobSet && getToJobSet?.status !== 'Pending')
      ) {
        return false;
      }
    } else {
      /** Check target schedule is in job */
      /** Check specific schedule */
      if (scheduleData?.seq === getFromJobSet?.seq) {
        if (
          (getLiveDilvJobSet && getLiveDilvJobSet?.status !== 'Pending') ||
          (getToJobSet && getToJobSet?.status !== 'Pending')
        ) {
          return false;
        }
      }
      if (scheduleData?.seq === getLiveDilvJobSet?.seq) {
        if (getToJobSet && getToJobSet?.status !== 'Pending') {
          return false;
        }
      }
    }
  }
  return true;
};

/**
 * @description
 * Function that check completed schedule exist or not
 * @param {string} scheduleType
 * Schedule type - job or location
 * @param {array} jobSet
 * Job set
 * @param {array} locationSet
 * Location set
 * @param {number} jobSetIdx
 * Job set index
 * @param {number} locationSetIdx
 * Location set index
 * @param {string} locationInfo
 * Location info - from, ld, to
 * @param {object} scheduleData
 * Target schedule data
 * @returns
 */
const handleCompletedScheduleExistCheck = ({
  pageCategory,
  scheduleType,
  jobSet,
  locationSet,
  jobSetIdx,
  locationSetIdx,
  locationInfo,
  scheduleData,
}) => {
  if (pageCategory !== 'export') {
    /**
     * Check jobSet first and check locationSet after
     * Check jobSet first
     */
    for (
      let jobIdxFlag = jobSetIdx;
      jobIdxFlag < jobSet?.length;
      jobIdxFlag++
    ) {
      if (
        !checkJobSetCompleted({
          jobSet,
          jobIdxFlag,
          locationInfo,
          scheduleData,
          isLast: jobIdxFlag === jobSet?.length - 1,
        })
      )
        return false;
    }
    /** Check locationSet after */
    for (
      let locationIdxFlag = 0;
      locationIdxFlag < locationSet?.length;
      locationIdxFlag++
    ) {
      if (locationSet[locationIdxFlag]) {
        if (locationSet[locationIdxFlag]?.status !== 'Pending') {
          return false;
        }
      }
    }
    return true;
  }
  if (pageCategory === 'export') {
    /**
     * Check locationSet first and check jobSet after
     * Check locationSet first
     */
    for (
      let locationIdxFlag = locationSetIdx;
      locationIdxFlag < locationSet?.length;
      locationIdxFlag++
    ) {
      if (locationSet[locationIdxFlag]) {
        if (locationSet[locationIdxFlag]?.status !== 'Pending') return false;
      }
    }
    /** Check jobSet after */
    for (
      let jobIdxFlag = jobSetIdx;
      jobIdxFlag < jobSet?.length;
      jobIdxFlag++
    ) {
      if (
        !checkJobSetCompleted({
          jobSet,
          jobIdxFlag,
          locationInfo,
          scheduleData,
        })
      ) {
        return false;
      }
    }
    return true;
  }
  return false;
};

export default handleCompletedScheduleExistCheck;
