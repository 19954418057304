import React from 'react';
import {
  REGEX_DECIMAL_9_2,
  REGEX_ONLY_NUMBER,
} from '../../../../constants/Common/regex';
import CommonTextInput from '../../../Inputs/CommonTextInput/CommonTextInput';
import ToggleWithText from '../ToggleWithText';

const APTableContainer = ({
  apTable,
  div,
  patchAPTAbleActivateMutation,
  patchAPTAbleRateMutation,
  setIsMessageOpen,
  setMessage,
  setIsSuccess,
}) => {
  return (
    <div className="flex flex-col">
      <div className="mt-[50px] text-black text-[15px] font-bold font-['Roboto']">
        AP TABLE
      </div>
      <div className="flex mt-[20px] gap-[20px]">
        <ToggleWithText
          name="IMPORT"
          inputName="import"
          isBorder
          isActive={apTable.import}
          div={div}
          mutation={patchAPTAbleActivateMutation}
        />
        <ToggleWithText
          name="EXPORT"
          inputName="export"
          isBorder
          isActive={apTable.export}
          div={div}
          mutation={patchAPTAbleActivateMutation}
        />
        <ToggleWithText
          name="VAN"
          inputName="van"
          isBorder
          isActive={apTable.van}
          div={div}
          mutation={patchAPTAbleActivateMutation}
        />
        <ToggleWithText
          name="BROKERAGE"
          inputName="brokerage"
          isBorder
          isActive={apTable.brokerage}
          div={div}
          mutation={patchAPTAbleActivateMutation}
        />
        <ToggleWithText
          name="BARE CHASSIS"
          inputName="bare_chassis"
          isBorder
          isActive={apTable.bare_chassis}
          div={div}
          mutation={patchAPTAbleActivateMutation}
        />
      </div>
      <div className="flex w-[540px] mt-[20px]">
        <CommonTextInput
          label="MILE"
          inputName="distance"
          defaultValue={apTable?.distance}
          width="w-[160px]"
          adornment={{
            content: 'Mile',
            position: 'end',
          }}
          onChangeHandler={({ e, onChange }) => {
            if (REGEX_ONLY_NUMBER.test(e?.target?.value)) {
              onChange(e?.target?.value);
            }
          }}
          onKeyDownHandler={({ e, setDisabled }) => {
            if (e.key === 'Enter') {
              setDisabled(true);
              patchAPTAbleRateMutation({
                div,
                distance: e.target.value || 0,
                less: apTable?.less,
                more: apTable?.more,
                setDisabled,
              });
            }
          }}
          onBlurHandler={({ e, setDisabled }) => {
            if (e?.target?.value !== apTable?.distance?.toString()) {
              setDisabled(true);
              patchAPTAbleRateMutation({
                div,
                distance: e.target.value || 0,
                less: apTable?.less,
                more: apTable?.more,
                setDisabled,
              });
            }
          }}
        />
        <CommonTextInput
          label="LESS"
          inputName="less"
          defaultValue={apTable?.less}
          width="w-[160px]"
          adornment={{
            content: '$',
            position: 'start',
          }}
          onChangeHandler={({ e, onChange, ...handlerProps }) => {
            if (
              REGEX_DECIMAL_9_2.test(e?.target?.value) ||
              e?.target?.value === ''
            ) {
              onChange(e?.target?.value);
            }
          }}
          onKeyDownHandler={({ e, setDisabled }) => {
            if (e.key === 'Enter') {
              setDisabled(true);
              patchAPTAbleRateMutation({
                div,
                distance: apTable?.distance,
                less: e.target.value || 0,
                more: apTable?.more,
                setDisabled,
              });
            }
          }}
          onBlurHandler={({ e, setDisabled }) => {
            if (e?.target?.value !== apTable?.less?.toString()) {
              setDisabled(true);
              patchAPTAbleRateMutation({
                div,
                distance: apTable?.distance,
                less: e.target.value || 0,
                more: apTable?.more,
                setDisabled,
              });
            }
          }}
        />
        <CommonTextInput
          label="MORE"
          inputName="more"
          defaultValue={apTable?.more}
          width="w-[160px]"
          adornment={{
            content: '$',
            position: 'start',
          }}
          onChangeHandler={({ e, onChange, ...handlerProps }) => {
            if (
              REGEX_DECIMAL_9_2.test(e?.target?.value) ||
              e?.target?.value === ''
            ) {
              onChange(e?.target?.value);
            }
          }}
          onKeyDownHandler={({ e, setDisabled }) => {
            if (e.key === 'Enter') {
              setDisabled(true);
              patchAPTAbleRateMutation({
                div,
                distance: apTable?.distance,
                less: apTable?.less,
                more: e.target.value || 0,
                setDisabled,
              });
            }
          }}
          onBlurHandler={({ e, setDisabled }) => {
            if (e?.target?.value !== apTable?.more?.toString()) {
              setDisabled(true);
              patchAPTAbleRateMutation({
                div,
                distance: apTable?.distance,
                less: apTable?.less,
                more: e.target.value || 0,
                setDisabled,
              });
            }
          }}
        />
      </div>
    </div>
  );
};

export default APTableContainer;
