import { closeSidebar, openSidebar } from './handleSidebarVisible';

/**
 * @description
 * Function to handle the sidebar open and close.
 * @param {function} setIsSidebarOpen
 * Function to handle the sidebar open state.
 */
const handleSidebarOpen = ({ ...props }) => {
  const { setIsSidebarOpen } = props;

  const sidebarElement = document.getElementById('sidebarWrapper');

  /** Expand or collapse sidebar width according to current sidebar width */
  if (sidebarElement?.offsetWidth > 67) {
    closeSidebar({ setIsSidebarOpen, sidebarElement });
  } else if (sidebarElement?.offsetWidth <= 67) {
    openSidebar({ setIsSidebarOpen, sidebarElement });
  }
};

export default handleSidebarOpen;
