import React from 'react';
import DualMoveAPModalAPInfo from './DualMoveAPModalAPInfo';
import DualMoveAPModalExistAPInfo from './DualMoveAPModalExistAPInfo';
import DualMoveAPModalAdditionalAP from './DualMoveAPModalAdditionalAP';

const DualMoveAPModalContent = ({ ...props }) => {
  return (
    <>
      <DualMoveAPModalAPInfo {...props} />
      <DualMoveAPModalExistAPInfo {...props} />
      <DualMoveAPModalAdditionalAP {...props} />
    </>
  );
};

export default DualMoveAPModalContent;
