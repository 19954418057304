import React from 'react';
import { addForm } from '../../../../utils/Acct/Common/Handler/formListHandler';

const AddFormButton = ({ type, invoiceKey, setInvoiceKey, setInvoiceList }) => {
  return (
    <div
      className="w-[1025px] h-[50px] min-h-[50px] bg-[#F2F4F8] rounded-[5px]
  flex items-center cursor-pointer text-[#556DA1] text-[12px] font-[700] text-center"
      onClick={() => {
        addForm({
          invoiceKey,
          setInvoiceKey,
          setInvoiceList,
        });
      }}
    >
      <p className="w-full cursor-pointer">
        + add {type === 'chassis' ? 'chassis' : 'container'}
      </p>
    </div>
  );
};

export default AddFormButton;
