import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import autoCompleteFilterOptionsHandler from '../../../../../../../../utils/Common/Input/autoCompleteFilterOptionsHandler';

const APModalAutoComplete = ({ ...props }) => {
  const [defaultValue, setDefaultValue] = useState(props?.defaultValue || '');
  const [textCenter, setTextCenter] = useState(props?.textCenter || true);
  const [textColor, setTextColor] = useState(props?.textColor || '#222');
  const [backgroundColor, setBackgroundColor] = useState(
    props?.backgroundColor || '#FFF',
  );
  const [option, setOption] = useState(props?.option || []);

  // Set default loading for input. Default is false.
  const [isLoading, setIsLoading] = useState(props?.isLoading || false);
  useEffect(() => {
    /**
     * If default loading value changed, component loading value would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.isLoading !== undefined && props?.isLoading !== null) {
      setIsLoading(props?.isLoading);
    }
  }, [props?.isLoading]);

  return (
    <div className="w-full h-[30px]">
      <Autocomplete
        forcePopupIcon={false}
        defaultValue={defaultValue || option?.[0]?.value}
        value={props?.value}
        filterOptions={(options, params) =>
          autoCompleteFilterOptionsHandler({ options, params })
        }
        options={option || []}
        isOptionEqualToValue={(paramOption, paramValue) =>
          paramOption.value === paramValue.value ||
          paramOption.value === paramValue
        }
        noOptionsText={isLoading ? 'Loading...' : 'No options'}
        onChange={(e, selectProps) =>
          props?.onChangeHandler?.({
            e,
            selectProps,
          })
        }
        onInputChange={(e, selectProps) =>
          props?.onInputChangeHandler?.({
            e,
            selectProps,
            ...handlerProps,
          })
        }
        renderInput={params => (
          <TextField
            {...params}
            sx={{
              borderStyle: 'solid',
              borderColor: '#D9D9D9',
              borderRadius: '5px',
            }}
            placeholder={props?.placeholder || ''}
            inputProps={{ ...params.inputProps }}
          />
        )}
        className="border-solid border-[#D9D9D9] rounded-none h-[30px] text-[11px] w-full bg-white"
        sx={{
          input: {
            textAlign: `${textCenter ? 'center' : ''}`,
            paddingRight: '15px',
            borderRadius: '0px',
            zIndex: '999',
            color: `${textColor} !important`,
            '&:focus': {
              color: `black !important`,
            },
          },
          '&.MuiAutocomplete-root': {
            width: '100% !important',
          },
          '.MuiInputBase-root': {
            padding: '0 40px 0 0 !important',
            borderRadius: '5px',
            fontWeight: '500',
            '&:not(.Mui-focused):not(.Mui-disabled):hover fieldset': {
              borderColor: '#8E9396 !important',
            },
            '&.Mui-focused fieldset': {
              border: '1.2px solid #264B9F !important',
              boxShadow: '0px 0px 4px 0px #8BBCE9 !important',
            },
            '&.Mui-error fieldset': {
              border: '1.2px solid #C24D4D !important',
            },
          },
          '.MuiInputBase-root > .MuiInputBase-input': {
            padding: '0 0 0 14px',
            height: '30px',
            fontSize: '12px',
          },
          '.MuiSvgIcon-root': {
            fontSize: '16px',
            color: '#264B9F !important',
            zIndex: '999',
          },
          '&.MuiAutocomplete-popper': {
            zIndex: 9999,
          },
          fieldset: {
            borderColor: '#D9D9D9 !important',
            border: 'none !important',
            borderRadius: '0px',
            backgroundColor: `${backgroundColor} !important`,
          },
        }}
        popupIcon={<ExpandMoreIcon />}
        componentsProps={{
          popper: {
            style: { width: 'fit-content' },
            sx: {
              zIndex: 9999,
              '.MuiAutocomplete-option': {
                fontSize: '12px !important',
              },
              '.MuiAutocomplete-noOptions': {
                fontSize: '12px !important',
              },
            },
          },
        }}
      />
    </div>
  );
};

export default APModalAutoComplete;
