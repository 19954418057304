import calcDateTimeGenerator from '../../Common/Generator/calcDateTimeGenerator';

/**
 * @description
 * Function to check schedule constraint
 * @param {object} orderPostForm
 * Order post form to be checked
 * @param {object} modalMessageSetter
 * Setter to display in the modal
 * @returns {boolean}
 * True if schedule constraint is valid
 */
const checkScheduleConstraint = ({ orderPostForm, modalMessageSetter }) => {
  // Get each date and time from order post form
  const { po_date: poDate, po_time: poTime } = orderPostForm;
  const { dilv_date: dilvDate, dilv_time: dilvTime } = orderPostForm;
  const { rtn_date: rtnDate, rtn_time: rtnTime } = orderPostForm;
  const { setMessageModalOpen, setModalMessage } = modalMessageSetter;

  // Calculate Date and Time
  const calcPoDateTime = calcDateTimeGenerator({ date: poDate, time: poTime });
  const calcDilvDateTime = calcDateTimeGenerator({
    date: dilvDate,
    time: dilvTime,
  });
  const calcRtnDateTime = calcDateTimeGenerator({
    date: rtnDate,
    time: rtnTime,
  });

  /**
   * Set modal error message if PO Date/Time is later than Delivery Date/Time
   */
  if (calcPoDateTime && calcDilvDateTime && calcPoDateTime > calcDilvDateTime) {
    setMessageModalOpen(true);
    setModalMessage('PO Date/Time should be earlier than Delivery Date/Time');
    return false;
  }

  /**
   * Set modal error message if PO Date/Time is later than Return Date/Time
   */
  if (calcPoDateTime && calcRtnDateTime && calcPoDateTime > calcRtnDateTime) {
    setMessageModalOpen(true);
    setModalMessage('PO Date/Time should be earlier than Return Date/Time');
    return false;
  }

  /**
   * Set modal error message if Delivery Date/Time is later than Return Date/Time
   */
  if (
    calcDilvDateTime &&
    calcRtnDateTime &&
    calcDilvDateTime > calcRtnDateTime
  ) {
    setMessageModalOpen(true);
    setModalMessage(
      'Delivery Date/Time should be earlier than Return Date/Time',
    );
    return false;
  }

  return true;
};

export default checkScheduleConstraint;
