import { useQuery } from '@tanstack/react-query';
import { getUninvoicedList } from '../../../../services/Acct/Uninvoiced/Get/getUninvoicedList';
import acctKeys from '../../keys';
import generateQueryParamObject from '../../../../utils/Acct/Common/Generator/generateQueryParamObject';
import { ACCT_PAGE } from '../../../../constants/Acct/Common/staticNames';

const useGetUninvoicedList = ({ queryObject }) => {
  const queryParam = generateQueryParamObject({
    queryObject,
    page: ACCT_PAGE?.unInvoiced,
  });

  const {
    data: unInvoicedData,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: acctKeys.uninvoicedList({ queryParam }),
    queryFn: () => getUninvoicedList({ queryParam }),
    staleTime: 0,
    refetchOnWindowFocus: false,
  });

  return { unInvoicedData, isLoading, isFetching };
};

export default useGetUninvoicedList;
