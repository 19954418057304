import React from 'react';
import { useFormContext } from 'react-hook-form';
import CommonRadioInput from '../../../../../components/Inputs/CommonRadioInput/CommonRadioInput';

const generateTypeField = ({ selectedRowData, scheduleType }) => {
  const { setValue, getValues } = useFormContext();

  const stopOrder = getValues('add.ordering');

  const pageCategory =
    document?.URL?.split('/')?.[document?.URL?.split('/')?.length - 2];

  const typeOptions = [
    { label: 'STOP', value: scheduleType },
    { label: 'RTN', value: 'RTN' },
  ];

  const handleTypeChange = ({ e, onChange }) => {
    if (e?.target?.value === 'RTN') setValue('add.ordering', 0);
    else setValue('add.ordering', stopOrder);
    onChange?.(e?.target?.value);
  };

  const isRTNOptionNeeded =
    pageCategory !== 'export' &&
    selectedRowData?.scheduleType === 'location' &&
    (scheduleType === 'P/U' || scheduleType === 'DILV');

  /** When selected row is location set and has P/U or DILV type, RTN also can be selected */
  if (isRTNOptionNeeded)
    return (
      <div className="w-[150px] pt-[17px]">
        <CommonRadioInput
          inputName="add.type"
          labelVisible={false}
          required
          onChangeHandler={handleTypeChange}
          options={typeOptions}
        />
      </div>
    );

  /** When selected row has data, content type should be STOP */
  if (selectedRowData) {
    if (pageCategory === 'van' && scheduleType === 'DILV') {
      setValue('add.ordering', 0);
      return 'RTN';
    }
    return 'STOP';
  }

  /** When selected row has no data, this case would be adding P/O */
  return 'P/O';
};

export default generateTypeField;
