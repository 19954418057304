import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import getPDFRateCFM from '../../../../services/BCDetail/BillingInfo/AP/Get/getPDFRateCFM';
import bcDetailKeys from '../../keys';

const openPDFViewer = (url, isActiveClicked, setErrorMessage) => {
  if (isActiveClicked) {
    const blob = new Blob([url], { type: 'application/pdf' });
    const file = new File([blob], 'example.pdf');
    const blobs = new Blob([file], { type: 'application/pdf' });
    const pdfURL = URL.createObjectURL(blobs);

    const newWindow = window.open(pdfURL, '_blank');
    if (newWindow) {
      newWindow.focus();
    } else {
      setErrorMessage('Popup blocked. Please allow popups for this site');
    }
  }
};

const useGetPDFRateCFM = ({
  seq,
  isActiveClicked,
  setIsActiveClicked,
  setErrorMessage,
}) => {
  const query = useQuery({
    queryKey: bcDetailKeys?.billingInfoAPPDFRateCFM({ seq }),
    queryFn: () => getPDFRateCFM({ seq }),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  useEffect(() => {
    if (query?.data?.data) {
      openPDFViewer(query?.data?.data, isActiveClicked, setErrorMessage);
    }
    if (query?.error) {
      setErrorMessage('Failed to get AP pdf rate cfm.');
    }
    setIsActiveClicked(false);
  }, [query?.data]);

  return query;
};

export default useGetPDFRateCFM;
