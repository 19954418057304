/**
 * @description
 * All permission category and permission code
 * Check reference here: https://docs.google.com/spreadsheets/d/16yJT4OciW19A5Qs8mPt_M6nc4rdZdwp2/edit#gid=597098693
 */
/** Order permission */
export const PERM_ORDER_IMP_VIEW_EDIT = 'order_imp_rw';
export const PERM_ORDER_EXP_VIEW_EDIT = 'order_exp_rw';
export const PERM_ORDER_VAN_VIEW_EDIT = 'order_van_rw';
export const PERM_ORDER_BROK_VIEW_EDIT = 'order_brok_rw';
export const PERM_ORDER_BARE_VIEW_EDIT = 'order_bare_rw';

/** Main permission */
export const PERM_MAIN_IMP_VIEW_EDIT = 'main_imp_rw';
export const PERM_MAIN_EXP_VIEW_EDIT = 'main_exp_rw';
export const PERM_MAIN_VAN_VIEW_EDIT = 'main_van_rw';
export const PERM_MAIN_BROK_VIEW_EDIT = 'main_brok_rw';
export const PERM_MAIN_BARE_VIEW_EDIT = 'main_bare_rw';

/** Dispatch permission */
export const PERM_DISPATCH_IMP_VIEW = 'dispatch_imp_r';
export const PERM_DISPATCH_IMP_EDIT = 'dispatch_imp_rw';

export const PERM_DISPATCH_BK_VIEW_EDIT = 'dispatch_bk_rw';

export const PERM_DISPATCH_EXP_VIEW = 'dispatch_exp_r';
export const PERM_DISPATCH_EXP_EDIT = 'dispatch_exp_rw';

export const PERM_DISPATCH_VAN_VIEW = 'dispatch_van_r';
export const PERM_DISPATCH_VAN_EDIT = 'dispatch_van_rw';

export const PERM_DISPATCH_BROK_VIEW = 'dispatch_brok_r';
export const PERM_DISPATCH_BROK_EDIT = 'dispatch_brok_rw';

/** BC-Detail permission */
export const PERM_BC_DETAIL_IMP_VIEW = 'bcdetail_imp_r';
export const PERM_BC_DETAIL_IMP_EDIT = 'bcdetail_imp_rw';

export const PERM_BC_DETAIL_EXP_VIEW = 'bcdetail_exp_r';
export const PERM_BC_DETAIL_EXP_EDIT = 'bcdetail_exp_rw';

export const PERM_BC_DETAIL_VAN_VIEW = 'bcdetail_van_r';
export const PERM_BC_DETAIL_VAN_EDIT = 'bcdetail_van_rw';

export const PERM_BC_DETAIL_BROK_VIEW = 'bcdetail_brok_r';
export const PERM_BC_DETAIL_BROK_EDIT = 'bcdetail_brok_rw';

export const PERM_BC_DETAIL_BARE_VIEW = 'bcdetail_bare_r';
export const PERM_BC_DETAIL_BARE_EDIT = 'bcdetail_bare_rw';

export const PERM_BC_DETAIL_PO_CHASSIS_VIEW_EDIT = 'bcdetail_po_ch_rw';
export const PERM_BC_DETAIL_EMAIL_VIEW_EDIT = 'bcdetail_email_rw';

export const PERM_BC_DETAIL_AP_VIEW = 'bcdetail_ap_r';
export const PERM_BC_DETAIL_AP_EDIT = 'bcdetail_ap_rw';

export const PERM_BC_DETAIL_AR_VIEW = 'bcdetail_ar_r';
export const PERM_BC_DETAIL_AR_EDIT = 'bcdetail_ar_rw';

export const PERM_BC_DETAIL_AP_AR_DELETE_VIEW_EDIT = 'bcdetail_ap_ar_delete_rw';

/** WO handling permission */
export const PERM_WO_CONVERT_VIEW_EDIT = 'wo_convert_rw';
export const PERM_WO_COMPLETE_VIEW_EDIT = 'wo_complete_rw';
export const PERM_WO_DELETE_VIEW_EDIT = 'wo_delete_rw';

/** Quickbook permission */
export const PERM_QB_SEND_VIEW_EDIT = 'qb_send_rw';
export const PERM_QB_UPDATE_VIEW_EDIT = 'qb_update_rw';
export const PERM_QB_LOCKED_VIEW_EDIT = 'qb_locked_rw';
export const PERM_QB_DELETE_VIEW_EDIT = 'qb_delete_rw';

/** TMS Report permission */
export const PERM_TMS_REPORT_PO_VIEW = 'tms_po_r';
export const PERM_TMS_REPORT_PO_VIEW_EDIT = 'tms_po_rw';
export const PERM_TMS_REPORT_DILV_VIEW = 'tms_dilv_r';
export const PERM_TMS_REPORT_DILV_VIEW_EDIT = 'tms_dilv_rw';
export const PERM_TMS_REPORT_RTN_VIEW = 'tms_rtn_r';
export const PERM_TMS_REPORT_RTN_VIEW_EDIT = 'tms_rtn_rw';
export const PERM_TMS_REPORT_DEMURRAGE_VIEW_EDIT = 'tms_dem_rw';
export const PERM_TMS_REPORT_PERDIEM_VIEW_EDIT = 'tms_per_rw';
export const PERM_TMS_REPORT_STATISTICS_VIEW = 'tms_stat_r';
export const PERM_TMS_REPORT_EARLY_WARNING_VIEW = 'tms_ew_r';
export const PERM_TMS_REPORT_EARLY_WARNING_EDIT = 'tms_ew_rw';

/** Customer Report permission */
export const PERM_CUS_REPORT_MAIN_VIEW_EDIT = 'cus_main_rw';
export const PERM_CUS_REPORT_SETTING_VIEW_EDIT = 'cus_setting_rw';

/** Trace permission */
export const PERM_TRACE_MAP_VIEW = 'trace_map_r';
export const PERM_TRACE_JOB_VIEW = 'trace_job_r';

export const PERM_TRACE_GEOFENCE_VIEW = 'trace_geofence_r';
export const PERM_TRACE_GEOFENCE_EDIT = 'trace_geofence_rw';

/** EDI permission */
export const PERM_EDI_PAGE_VIEW_EDIT = 'edi_page_rw';
export const PERM_EDI_ACCEPT_VIEW_EDIT = 'edi_accept_rw';
export const PERM_EDI_DECLINE_VIEW_EDIT = 'edi_decline_rw';

/** Mobile Admin permission */
export const PERM_MOBILE_ADMIN_POD_CHECKLIST_VIEW_EDIT = 'mobile_pod_rw';
export const PERM_MOBILE_ADMIN_BILL_CONFIRM_VIEW_EDIT = 'mobile_bill_rw';
export const PERM_MOBILE_MANIFEST_VIEW = 'mobile_manifest_r';

/** ACCT permission */
export const PERM_ACCT_UNINVOICED_VIEW = 'acct_uninv_r';
export const PERM_ACCT_QB_AP_AR_VIEW_EDIT = 'acct_qb_ap_ar_rw';
export const PERM_ACCT_QB_RECON_VIEW_EDIT = 'acct_qb_recon_rw';
export const PERM_ACCT_CHASSIS_RECON_VIEW_EDIT = 'acct_ch_recon_rw';
export const PERM_ACCT_PERDIEM_RECON_VIEW_EDIT = 'acct_per_recon_rw';

/** Master permission */
export const PERM_MASTER_DRIVER_VIEW = 'master_drv_r';
export const PERM_MASTER_DRIVER_EDIT = 'master_drv_rw';

export const PERM_MASTER_COMPANY_VIEW = 'master_comp_r';
export const PERM_MASTER_COMPANY_EDIT = 'master_comp_rw';

export const PERM_MASTER_CNEE_VIEW_EDIT = 'master_cnee_rw';
export const PERM_MASTER_ACCT_CODE_VIEW = 'master_acct_code_r';

export const PERM_MASTER_PRODUCT_CODE_VIEW = 'master_prod_code_r';
export const PERM_MASTER_PRODUCT_CODE_EDIT = 'master_prod_code_rw';

export const PERM_MASTER_AP_RATE_VIEW_EDIT = 'master_ap_rate_rw';
export const PERM_MASTER_AR_RATE_VIEW_EDIT = 'master_ar_rate_rw';
export const PERM_MASTER_CHASSIS_RATE_VIEW_EDIT = 'master_ch_rate_rw';
export const PERM_MASTER_TIER_WEIGHT_VIEW_EDIT = 'master_tier_weight_rw';

/** Admin permission */
export const PERM_ADMIN_USER_VIEW_EDIT = 'admin_user_rw';
export const PERM_ADMIN_AUTH_VIEW_EDIT = 'admin_auth_rw';
export const PERM_ADMIN_CONF_VIEW_EDIT = 'admin_conf_rw';
export const PERM_ADMIN_DIV_CONF_VIEW_EDIT = 'admin_div_conf_rw';
export const PERM_ADMIN_DIV_EARLY_WARNING_VIEW_EDIT = 'admin_div_ew_rw';

/** Additional permission */
export const PERM_ACCT_DRIVER_PAY_VIEW_EDIT = 'acct_drv_pay_rw';

/** Mapping permission w/ page */

// Order
export const PERM_ORDER_IMP_REG_SHU = [PERM_ORDER_IMP_VIEW_EDIT];
export const PERM_ORDER_EXP_REG_SHU = [PERM_ORDER_EXP_VIEW_EDIT];
export const PERM_ORDER_VAN = [PERM_ORDER_VAN_VIEW_EDIT];
export const PERM_ORDER_BROK = [PERM_ORDER_BROK_VIEW_EDIT];
export const PERM_ORDER_BARE = [PERM_ORDER_BARE_VIEW_EDIT];

export const PERM_ORDER = [
  ...PERM_ORDER_IMP_REG_SHU,
  ...PERM_ORDER_EXP_REG_SHU,
  ...PERM_ORDER_VAN,
  ...PERM_ORDER_BROK,
  ...PERM_ORDER_BARE,
];

// Main
export const PERM_MAIN_IMP = [PERM_MAIN_IMP_VIEW_EDIT];
export const PERM_MAIN_EXP = [PERM_MAIN_EXP_VIEW_EDIT];
export const PERM_MAIN_VAN = [PERM_MAIN_VAN_VIEW_EDIT];
export const PERM_MAIN_BROK = [PERM_MAIN_BROK_VIEW_EDIT];
export const PERM_MAIN_BARE = [PERM_MAIN_BARE_VIEW_EDIT];

export const PERM_MAIN = [
  ...PERM_MAIN_IMP,
  ...PERM_MAIN_EXP,
  ...PERM_MAIN_VAN,
  ...PERM_MAIN_BROK,
  ...PERM_MAIN_BARE,
];

// Dispatch
export const PERM_DISPATCH_IMP = [
  PERM_DISPATCH_IMP_VIEW,
  PERM_DISPATCH_IMP_EDIT,
];

export const PERM_DISPATCH_BK = [PERM_DISPATCH_BK_VIEW_EDIT];

export const PERM_DISPATCH_EXP = [
  PERM_DISPATCH_EXP_VIEW,
  PERM_DISPATCH_EXP_EDIT,
];

export const PERM_DISPATCH_VAN = [
  PERM_DISPATCH_VAN_VIEW,
  PERM_DISPATCH_VAN_EDIT,
];

export const PERM_DISPATCH_BROK = [
  PERM_DISPATCH_BROK_VIEW,
  PERM_DISPATCH_BROK_EDIT,
];

export const PERM_DISPATCH = [
  ...PERM_DISPATCH_IMP,
  ...PERM_DISPATCH_BK,
  ...PERM_DISPATCH_EXP,
  ...PERM_DISPATCH_VAN,
  ...PERM_DISPATCH_BROK,
];

// BC-Detail
export const PERM_BC_DETAIL_IMP = [
  PERM_BC_DETAIL_IMP_VIEW,
  PERM_BC_DETAIL_IMP_EDIT,
];

export const PERM_BC_DETAIL_EXP = [
  PERM_BC_DETAIL_EXP_VIEW,
  PERM_BC_DETAIL_EXP_EDIT,
];

export const PERM_BC_DETAIL_VAN = [
  PERM_BC_DETAIL_VAN_VIEW,
  PERM_BC_DETAIL_VAN_EDIT,
];

export const PERM_BC_DETAIL_BROK = [
  PERM_BC_DETAIL_BROK_VIEW,
  PERM_BC_DETAIL_BROK_EDIT,
];

export const PERM_BC_DETAIL_BARE = [
  PERM_BC_DETAIL_BARE_VIEW,
  PERM_BC_DETAIL_BARE_EDIT,
];

export const PERM_BC_DETAIL_PO_CHASSIS = [PERM_BC_DETAIL_PO_CHASSIS_VIEW_EDIT];
export const PERM_BC_DETAIL_EMAIL = [PERM_BC_DETAIL_EMAIL_VIEW_EDIT];

export const PERM_BC_DETAIL_AP = [
  PERM_BC_DETAIL_AP_VIEW,
  PERM_BC_DETAIL_AP_EDIT,
];

export const PERM_BC_DETAIL_AR = [
  PERM_BC_DETAIL_AR_VIEW,
  PERM_BC_DETAIL_AR_EDIT,
];

export const PERM_BC_DETAIL_AP_AR_DELETE = [
  PERM_BC_DETAIL_AP_AR_DELETE_VIEW_EDIT,
];
export const PERM_BC_DETAIL = [
  ...PERM_BC_DETAIL_IMP,
  ...PERM_BC_DETAIL_EXP,
  ...PERM_BC_DETAIL_VAN,
  ...PERM_BC_DETAIL_BROK,
  ...PERM_BC_DETAIL_BARE,
  ...PERM_BC_DETAIL_AP,
  ...PERM_BC_DETAIL_AR,
];

// TMS Report
export const PERM_TMS_REPORT_PO = [
  PERM_TMS_REPORT_PO_VIEW,
  PERM_TMS_REPORT_PO_VIEW_EDIT,
];

export const PERM_TMS_REPORT_DILV = [
  PERM_TMS_REPORT_DILV_VIEW,
  PERM_TMS_REPORT_DILV_VIEW_EDIT,
];

export const PERM_TMS_REPORT_RTN = [
  PERM_TMS_REPORT_RTN_VIEW,
  PERM_TMS_REPORT_RTN_VIEW_EDIT,
];

export const PERM_TMS_REPORT_DEMURRAGE = [PERM_TMS_REPORT_DEMURRAGE_VIEW_EDIT];

export const PERM_TMS_REPORT_PERDIEM = [PERM_TMS_REPORT_PERDIEM_VIEW_EDIT];

export const PERM_TMS_REPORT_STATISTICS = [PERM_TMS_REPORT_STATISTICS_VIEW];

export const PERM_TMS_REPORT_EARLY_WARNING = [
  PERM_TMS_REPORT_EARLY_WARNING_VIEW,
  PERM_TMS_REPORT_EARLY_WARNING_EDIT,
];

export const PERM_TMS_REPORT = [
  ...PERM_TMS_REPORT_PO,
  ...PERM_TMS_REPORT_DILV,
  ...PERM_TMS_REPORT_RTN,
  ...PERM_TMS_REPORT_DEMURRAGE,
  ...PERM_TMS_REPORT_PERDIEM,
  ...PERM_TMS_REPORT_STATISTICS,
  ...PERM_TMS_REPORT_EARLY_WARNING,
];

// Customer Report
export const PERM_CUS_REPORT_MAIN = [PERM_CUS_REPORT_MAIN_VIEW_EDIT];

export const PERM_CUS_REPORT_SETTING = [PERM_CUS_REPORT_SETTING_VIEW_EDIT];

export const PERM_CUS_REPORT = [
  ...PERM_CUS_REPORT_MAIN,
  ...PERM_CUS_REPORT_SETTING,
];

// Trace
export const PERM_TRACE_MAP = [PERM_TRACE_MAP_VIEW];

export const PERM_TRACE_JOB = [PERM_TRACE_JOB_VIEW];

export const PERM_TRACE_GEOFENCE = [
  PERM_TRACE_GEOFENCE_VIEW,
  PERM_TRACE_GEOFENCE_EDIT,
];

export const PERM_TRACE = [
  ...PERM_TRACE_MAP,
  ...PERM_TRACE_JOB,
  ...PERM_TRACE_GEOFENCE,
];

// EDI
export const PERM_EDI_PAGE = [PERM_EDI_PAGE_VIEW_EDIT];

export const PERM_EDI_ACCEPT = [PERM_EDI_ACCEPT_VIEW_EDIT];

export const PERM_EDI_DECLINE = [PERM_EDI_DECLINE_VIEW_EDIT];

export const PERM_EDI = [
  ...PERM_EDI_PAGE,
  ...PERM_EDI_ACCEPT,
  ...PERM_EDI_DECLINE,
];

// Mobile Admin
export const PERM_MOBILE_ADMIN_POD_CHECKLIST = [
  PERM_MOBILE_ADMIN_POD_CHECKLIST_VIEW_EDIT,
];

export const PERM_MOBILE_ADMIN_BILL_CONFIRM = [
  PERM_MOBILE_ADMIN_BILL_CONFIRM_VIEW_EDIT,
];

export const PERM_MOBILE_ADMIN_MANIFEST = [PERM_MOBILE_MANIFEST_VIEW];

export const PERM_MOBILE_ADMIN = [
  ...PERM_MOBILE_ADMIN_POD_CHECKLIST,
  ...PERM_MOBILE_ADMIN_BILL_CONFIRM,
  ...PERM_MOBILE_ADMIN_MANIFEST,
];

// ACCT
export const PERM_ACCT_UNINVOICED = [PERM_ACCT_UNINVOICED_VIEW];

export const PERM_ACCT_QB_AP_AR = [PERM_ACCT_QB_AP_AR_VIEW_EDIT];

export const PERM_ACCT_QB_RECON = [PERM_ACCT_QB_RECON_VIEW_EDIT];

export const PERM_ACCT_CHASSIS_RECON = [PERM_ACCT_CHASSIS_RECON_VIEW_EDIT];

export const PERM_ACCT_PERDIEM_RECON = [PERM_ACCT_PERDIEM_RECON_VIEW_EDIT];

export const PERM_ACCT = [
  ...PERM_ACCT_UNINVOICED,
  ...PERM_ACCT_QB_AP_AR,
  ...PERM_ACCT_QB_RECON,
  ...PERM_ACCT_CHASSIS_RECON,
  ...PERM_ACCT_PERDIEM_RECON,
];

// Master
export const PERM_MASTER_DRIVER = [
  PERM_MASTER_DRIVER_VIEW,
  PERM_MASTER_DRIVER_EDIT,
];

export const PERM_MASTER_COMPANY = [
  PERM_MASTER_COMPANY_VIEW,
  PERM_MASTER_COMPANY_EDIT,
];

export const PERM_MASTER_CNEE = [PERM_MASTER_CNEE_VIEW_EDIT];

export const PERM_MASTER_ACCT_CODE = [PERM_MASTER_ACCT_CODE_VIEW];

export const PERM_MASTER_PRODUCT_CODE = [
  PERM_MASTER_PRODUCT_CODE_VIEW,
  PERM_MASTER_PRODUCT_CODE_EDIT,
];

export const PERM_MASTER_AP_RATE = [PERM_MASTER_AP_RATE_VIEW_EDIT];

export const PERM_MASTER_AR_RATE = [PERM_MASTER_AR_RATE_VIEW_EDIT];

export const PERM_MASTER_CHASSIS_RATE = [PERM_MASTER_CHASSIS_RATE_VIEW_EDIT];

export const PERM_MASTER_TIER_WEIGHT = [PERM_MASTER_TIER_WEIGHT_VIEW_EDIT];

export const PERM_MASTER = [
  ...PERM_MASTER_DRIVER,
  ...PERM_MASTER_COMPANY,
  ...PERM_MASTER_ACCT_CODE,
  ...PERM_MASTER_PRODUCT_CODE,
  ...PERM_MASTER_AP_RATE,
  ...PERM_MASTER_AR_RATE,
  ...PERM_MASTER_CHASSIS_RATE,
  ...PERM_MASTER_TIER_WEIGHT,
];

// Admin
export const PERM_ADMIN_USER = [PERM_ADMIN_USER_VIEW_EDIT];

export const PERM_ADMIN_AUTH = [PERM_ADMIN_AUTH_VIEW_EDIT];

export const PERM_ADMIN_CONF = [PERM_ADMIN_CONF_VIEW_EDIT];

export const PERM_ADMIN_DIV_CONF = [PERM_ADMIN_DIV_CONF_VIEW_EDIT];

export const PERM_ADMIN_DIV_EARLY_WARNING = [
  PERM_ADMIN_DIV_EARLY_WARNING_VIEW_EDIT,
];

export const PERM_ADMIN = [
  ...PERM_ADMIN_USER,
  ...PERM_ADMIN_AUTH,
  ...PERM_ADMIN_CONF,
  ...PERM_ADMIN_DIV_CONF,
  ...PERM_ADMIN_DIV_EARLY_WARNING,
];

// All permission
export const PERM_ALL = [
  ...PERM_ORDER,
  ...PERM_MAIN,
  ...PERM_DISPATCH,
  ...PERM_BC_DETAIL,
  ...PERM_TMS_REPORT,
  ...PERM_CUS_REPORT,
  ...PERM_TRACE,
  ...PERM_EDI,
  ...PERM_MOBILE_ADMIN,
  ...PERM_ACCT,
  ...PERM_MASTER,
  ...PERM_ADMIN,
];
