import React from 'react';
import AuthRouter from '../../../../../components/Common/AuthRouter';
import {
  PERM_TMS_REPORT_DILV,
  PERM_TMS_REPORT_DILV_VIEW_EDIT,
  PERM_TMS_REPORT_PO,
  PERM_TMS_REPORT_PO_VIEW_EDIT,
  PERM_TMS_REPORT_RTN,
  PERM_TMS_REPORT_RTN_VIEW_EDIT,
} from '../../../permission';
import checkPermission from '../../../../../utils/Common/Router/Handler/checkPermission';
import ReturnYard from '../../../../../pages/DailyReport/ReturnYard';
import DailyReport from '../../../../../pages/DailyReport/DailyReport';
import {
  DAILY_REPORT_DELIVERY,
  DAILY_REPORT_PULL_OUT,
  DAILY_REPORT_RETURN,
} from '../../../../DailyReport/pageType';

const DAILY_REPORT_CHILDREN_ROUTE = [
  {
    path: 'pull-out',
    element: (
      <AuthRouter permission={PERM_TMS_REPORT_PO}>
        <DailyReport
          page={DAILY_REPORT_PULL_OUT}
          isEditable={checkPermission({
            permission: PERM_TMS_REPORT_PO_VIEW_EDIT,
          })}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'delivery',
    element: (
      <AuthRouter permission={PERM_TMS_REPORT_DILV}>
        <DailyReport
          page={DAILY_REPORT_DELIVERY}
          isEditable={checkPermission({
            permission: PERM_TMS_REPORT_DILV_VIEW_EDIT,
          })}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'return',
    element: (
      <AuthRouter permission={PERM_TMS_REPORT_RTN}>
        <DailyReport
          page={DAILY_REPORT_RETURN}
          isEditable={checkPermission({
            permission: PERM_TMS_REPORT_RTN_VIEW_EDIT,
          })}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'return-yard',
    element: (
      <AuthRouter permission={PERM_TMS_REPORT_RTN}>
        <ReturnYard
          isEditable={checkPermission({
            permission: PERM_TMS_REPORT_RTN_VIEW_EDIT,
          })}
        />
      </AuthRouter>
    ),
  },
];

export default DAILY_REPORT_CHILDREN_ROUTE;
