import React from 'react';
import { JOB_STANDARD_CODE } from '../../../../../../../constants/BCDetail/DetailInfo/Schedule/scheduleStandardCode';

const DriverDeleteConfirmModalContent = ({ status }) => {
  const isReadyToDrive = status === JOB_STANDARD_CODE.READY_TO_DRIVE;
  return (
    <div className="w-full h-full bg-[#F9F9F9] p-[17px] flex justify-center items-center text-[#222] text-xs font-normal flex flex-col">
      {isReadyToDrive && (
        <p>
          This driver may be during the job. You should contact the driver
          first.
        </p>
      )}
      <p className={`${isReadyToDrive && 'font-bold'}`}>
        Are you sure to delete this driver?
      </p>
    </div>
  );
};

export default DriverDeleteConfirmModalContent;
