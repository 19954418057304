import React from 'react';
import ScheduleAddModalContentHeader from '../ScheduleAddModalContentHeader';
import ScheduleAddModalContentRow from '../ScheduleAddModalContentRow';
import ScheduleAddModalContentType from '../ScheduleAddModalContentType';

const ScheduleAddModalContent = ({ ...props }) => {
  /** Get data from props */
  const { locationOption, selectedRowData, isCompanyIDListLoading } = props;

  return (
    <div className="flex flex-col justify-center items-center h-full gap-[26px]">
      <ScheduleAddModalContentType
        selectedRowData={selectedRowData}
        scheduleType={selectedRowData?.type}
      />
      <div className="w-full h-[62px] bg-[#fff] border-solid border-[1px] border-[#EEE]">
        <ScheduleAddModalContentHeader />
        <ScheduleAddModalContentRow
          locationOption={locationOption}
          selectedRowData={selectedRowData}
          isCompanyIDListLoading={isCompanyIDListLoading}
        />
      </div>
    </div>
  );
};

export default ScheduleAddModalContent;
