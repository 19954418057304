import styled from 'styled-components';

const StyledScheduleAPModalTableContentHeaderDataWrapper = styled.div`
  width: ${props => props?.customStyle?.width || '454px'};
  height: 31px;
  background-color: #f9f9f9;
  border: 1px solid #eeeeee;
  display: flex;
  flex-direction: row;
`;

export default StyledScheduleAPModalTableContentHeaderDataWrapper;
