const handleAdvancedSearchFilter = ({ getValues, setAdvancedFilter }) => {
  const advancedFilterData = getValues('advanced');
  setAdvancedFilter(prev => {
    return {
      dateType: advancedFilterData?.dateType,
      before: advancedFilterData?.before,
      after: advancedFilterData?.after,
    };
  });
};

export default handleAdvancedSearchFilter;
