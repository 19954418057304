import { REGEX_NUMBER_REGEX } from '../../../../constants/Common/regex';

const generateCell = (text = '', styleId = '') => {
  return {
    styleId,
    data: {
      type: REGEX_NUMBER_REGEX.test(text) ? 'Number' : 'String',
      value: text ? String(text) : '',
    },
  };
};

const getRows = params => {
  const headerCells = [
    {
      outlineLevel: 1,
      cells: [
        generateCell(''),
        generateCell(''),
        generateCell('ACCT CODE', 'detailHeader'),
        generateCell('ACCT CODE NAME', 'detailHeader'),
        generateCell('RATE', 'detailHeader'),
        generateCell('QTY', 'detailHeader'),
        generateCell('AMT', 'detailHeader'),
      ],
    },
  ];

  const dataRows =
    params.node.data?.detail.flatMap(detail => ({
      outlineLevel: 1,
      cells: [
        generateCell(''),
        generateCell(''),
        generateCell(detail.product_code, 'detailBody'),
        generateCell(detail.product_code_name, 'detailBody'),
        generateCell(detail.rate, 'detailBody'),
        generateCell(detail.qty, 'detailBody'),
        generateCell(detail.amt, 'detailBody'),
      ],
    })) || [];

  const rows = [...headerCells, ...dataRows];
  return rows;
};

export default getRows;
