import handleQueryError from '../../../utils/Common/Handler/handleQueryError';
import cusReportDefaultAPI from '../api';

const deleteSettingUserID = async ({ deleteData }) => {
  try {
    const response = await cusReportDefaultAPI.post(`user-delete`, deleteData);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default deleteSettingUserID;
