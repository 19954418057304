import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import tmsReportAPI from '../../api';

const patchDailySchedule = async ({ woNo, data }) => {
  const pageCategory = document.URL.split('/')?.pop()?.split('?')?.[0];
  try {
    const response = await tmsReportAPI.patch(
      `/daily-schedule/${pageCategory}/${woNo}`,
      data,
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchDailySchedule;
