import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CommonButton from '../../CommonButton/CommonButton';
import CommonTextInput from '../../Inputs/CommonTextInput/CommonTextInput';
import handleUserIDButton from '../../../utils/CustomerReport/Handler/handleUserIDButton';
import useCheckFormValidity from '../../../hooks/Common/Input/useCheckFormValidity';

const SettingTableInputRow = ({
  listSeq,
  setIsOpenErrorModal,
  setMessage,
  setIsSuccess,
  postUserIDMutate,
  setEmailList,
}) => {
  /** useForm */
  const emailController = useForm();

  const { handleSubmit, formState, reset } = emailController;
  const { isFormInvalid } = useCheckFormValidity({ formStates: [formState] });

  /** Input Email */
  const [email, setEmail] = useState('');

  return (
    <FormProvider {...emailController}>
      <form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <div className="flex flex-row gap-x-[2px] mt-[2px] items-center">
          <div className="w-[478px] h-[30px] min-w-[478px]">
            <CommonTextInput
              inputName="email"
              required
              maxLength={128}
              labelVisible={false}
              placeholder="send email"
              onChangeHandler={({ e, onChange }) => {
                if (!e?.target?.value) {
                  reset();
                }
                onChange(e?.target?.value);
                setEmail(e?.target?.value);
              }}
            />
          </div>
          <CommonButton
            category="add"
            width="90px"
            height="30px"
            border="1px solid #CCD3E3"
            color="#264B9F"
            type="button"
            preventDupClick
            onClick={({ buttonRef }) => {
              handleUserIDButton({
                setIsOpenErrorModal,
                setMessage,
                email,
                listSeq,
                postUserIDMutate,
                handleSubmit,
                setIsSuccess,
                isFormInvalid,
                buttonRef,
                setEmailList,
                reset,
              });
            }}
          >
            Add
          </CommonButton>
        </div>
      </form>
    </FormProvider>
  );
};

export default SettingTableInputRow;
