import React, { useState } from 'react';
import CommonButton from '../../../../../../CommonButton/CommonButton';
import usePatchDeleteSchedule from '../../../../../../../hooks/BCDetail/DetailInfo/Schedule/usePatchDeleteSchedule';
import handleScheduleDelete from '../../../../../../../utils/BCDetail/DetailInfo/Schedule/Handler/handleScheduleDelete';

const ScheduleDeleteModalButtonContent = ({ ...props }) => {
  /** Delete modal opener */
  const { setDeleteModalOpener, setLoadingOpener, setMessageModalOpener } =
    props;

  /** Data */
  const { selectedScheduleSeq } = props;

  /** Data setter */
  const { setSelectedScheduleSeq, setMessageModalMessage } = props;

  /** Delete mutator */
  const { mutate: deleteMutator } = usePatchDeleteSchedule({
    setDeleteModalOpener,
    setLoadingOpener,
    setMessageModalOpener,
    setMessageModalMessage,
    setSelectedScheduleSeq,
  });

  return (
    <div className="w-full flex justify-center items-center">
      <div className="flex gap-[20px]">
        <CommonButton
          category="cancel"
          onClick={() => {
            setSelectedScheduleSeq(null);
            setDeleteModalOpener(false);
          }}
        >
          Cancel
        </CommonButton>
        <CommonButton
          category="delete"
          preventDupClick
          onClick={({ buttonRef }) => {
            handleScheduleDelete({
              scheduleSeq: selectedScheduleSeq,
              deleteMutator,
              buttonRef,
            });
          }}
        >
          Delete
        </CommonButton>
      </div>
    </div>
  );
};

export default ScheduleDeleteModalButtonContent;
