import { useMutation } from '@tanstack/react-query';
import { useFormContext } from 'react-hook-form';
import postOrderForm from '../../services/Order/Post/postOrderForm';
import messageModalOpener from '../../utils/Common/Handler/messageModalOpener';
import resetForm from '../../utils/Order/Handler/resetForm';
import useControlToast from '../Common/Toast/useControlToast';
import { SUCCESS_CREATE } from '../../constants/Common/successMessage';
import updateButtonDisabled from '../../utils/Common/Handler/updateButtonDisabled';

/**
 * @description
 * Mutation hook for post order form
 * @param {object} modalMessageSetter
 * Setter to display in the modal
 * @returns {object}
 * Mutator object
 */
const usePostOrderForm = ({
  modalMessageSetter,
  setContainerCount,
  setContainerList,
}) => {
  const { addToast } = useControlToast();

  const subCategory = document.URL?.split('/')?.pop();

  /**
   * @type {object} RHF form property
   */
  const { reset } = useFormContext();

  /**
   * @type {object} setMessageModalOpen
   * Setter to open message modal
   */
  const { setMessageModalOpen, setToastOpen, setModalMessage } =
    modalMessageSetter;

  // Mutation query
  const mutator = useMutation({
    mutationFn: postOrderForm,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: () => {
      // Display toast message
      addToast({ message: SUCCESS_CREATE });
      // Reset all of form data
      resetForm({ reset, setContainerCount, setContainerList, subCategory });
    },
    onError: error => {
      messageModalOpener({
        opener: setMessageModalOpen,
        messageSetter: setModalMessage,
        message: error?.message,
      });
    },
  });

  return { ...mutator };
};

export default usePostOrderForm;
