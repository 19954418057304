import React, { memo, useEffect, useRef } from 'react';

const MapComponent = memo(
  ({ googleTrackingID, ...props }) => {
    const mapRef = useRef(null);

    return googleTrackingID ? (
      <div className="w-full h-full" id="liveShareMap" ref={mapRef}>
        Map
      </div>
    ) : (
      <div className="w-[437px] h-[300px] flex justify-center items-center">
        Tracking ID is not exist.
      </div>
    );
  },
  (prev, next) => {
    prev.googleTrackingID === next.googleTrackingID;
  },
);

export default MapComponent;
