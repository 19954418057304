import {
  DUPLICATE_ERROR,
  NOT_EDITABLE_ERROR,
} from '../../../../constants/Acct/Common/staticNames';
import handleQueryError from '../../../Common/Handler/handleQueryError';

const generateDuplicateError = ({ error, duplicateCheckFields }) => {
  const duplicateError = new Error(handleQueryError({ error }));
  if (
    error?.response?.data?.code === 'unique' &&
    error?.response?.data?.fields?.some(item =>
      duplicateCheckFields.includes(item),
    )
  ) {
    duplicateError.name = DUPLICATE_ERROR;
    duplicateError.responseData = error?.response?.data;
  } else if (error?.response?.data?.code === NOT_EDITABLE_ERROR) {
    duplicateError.name = NOT_EDITABLE_ERROR;
    duplicateError.message = 'This data cannot be editable ';
  }
  return duplicateError;
};

export default generateDuplicateError;
