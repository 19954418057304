import { useQuery } from '@tanstack/react-query';
import { commonCompanyAPI } from '../../../services/Common/Company/commonCompanyAPI';
import traceKeys from '../keys';

const useGetNGLCneeList = () => {
  const { isLoading: isLoadingNGLCneeList, data } = useQuery({
    queryKey: traceKeys.nglCneeList(),
    queryFn: () => commonCompanyAPI.getCommonCneeNglCompany(),
    refetchOnWindowFocus: false,
  });

  return {
    isLoadingNGLCneeList,
    nglCneeList: data?.data,
  };
};

export default useGetNGLCneeList;
