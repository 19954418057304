import { acctDefaultAPI } from '../../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

const patchChassisStatus = async ({ seq, patchData }) => {
  try {
    const response = await acctDefaultAPI.patch(
      `chassis-recon/${seq}/status`,
      patchData,
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};
export default patchChassisStatus;
