export const GROUP_HEADER_COLUMN_LIST = [
  'equip',
  'info',
  'ava',
  'po',
  'dilv',
  'pu',
  'geofence',
  'cont_1',
  'cont_2',
  'cont_3',
  'booking',
  'yard',
  'rtn',
  'rfd',
  'lfd',
];

export const NOT_REPLACE_HEADER_COLUMNS_LIST = [
  'po',
  'dilv',
  'ava',
  'rfd',
  'pu',
  'yard',
  'rtn',
  'lfd',
];
