const updateOrderListChecks = ({ checkOrderList, changeField }) => {
  const currentOrderList = [...checkOrderList];

  const updatedOrderList = currentOrderList.map(item => {
    if (item.value === changeField.name) {
      if (changeField.isHeaderChange) {
        item.children.forEach(child => {
          child.checked = changeField.checked;
        });
        return {
          ...item,
          checked: changeField.checked,
        };
      }

      if (changeField.child) {
        item.children.forEach(child => {
          if (child.value === changeField.child.value)
            child.checked = changeField.child.checked;
        });
        return {
          ...item,
          checked: changeField.headerChecked,
        };
      }

      return { ...item, checked: changeField.checked };
    }

    return item;
  });

  return updatedOrderList;
};

export default updateOrderListChecks;
