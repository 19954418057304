import dayjs from 'dayjs';

const fillActualInOutSchedule = ({
  dataKey,
  locationInfo,
  setValue,
  getValues,
  scheduleType,
}) => {
  const scheDate = getValues(`sche_date_${dataKey}_${locationInfo}`);
  const scheTime = getValues(`sche_time_${dataKey}_${locationInfo}`);

  // Create schedule adta from scheDate and scheTime
  const scheduleData =
    scheDate &&
    scheTime &&
    dayjs(
      `${scheDate?.format('YYYY-MM-DD')} ${scheTime?.format('HH:mm:ss')}`,
    )?.isValid()
      ? dayjs(
          `${scheDate?.format('YYYY-MM-DD')} ${scheTime?.format('HH:mm:ss')}`,
        )
      : null;

  // Fill actual in/out data when schedule data is exists
  if (scheduleData) {
    const actualInDate = getValues(`actual_in_date_${dataKey}_${locationInfo}`);
    const actualInTime = getValues(`actual_in_time_${dataKey}_${locationInfo}`);
    const actualOutDate = getValues(
      `actual_out_date_${dataKey}_${locationInfo}`,
    );
    const actualOutTime = getValues(
      `actual_out_time_${dataKey}_${locationInfo}`,
    );

    if (scheduleType === 'actual_in' && !actualInDate && !actualInTime) {
      // Fill actual-in date and time
      setValue(`actual_in_date_${dataKey}_${locationInfo}`, scheduleData);
      setValue(`actual_in_time_${dataKey}_${locationInfo}`, scheduleData);
    }

    if (scheduleType === 'actual_out' && !actualOutDate && !actualOutTime) {
      // Fill actual-out date and time
      setValue(
        `actual_out_date_${dataKey}_${locationInfo}`,
        scheduleData.add(1, 'minute'),
      );
      setValue(
        `actual_out_time_${dataKey}_${locationInfo}`,
        scheduleData.add(1, 'minute'),
      );
    }
  }
};

export default fillActualInOutSchedule;
