import getEditableDateDef from '../../../utils/DailyReport/Common/Generator/getEditableDateDef';
import getEditableDatetimeDef from '../../../utils/DailyReport/Common/Generator/getEditableDatetimeDef';
import getEditableEquipmentDef from '../../../utils/DailyReport/Common/Generator/getEditableEquipmentDef';
import getEditableTextDef from '../../../utils/DailyReport/Common/Generator/getEditableTextDef';
import getEditableSelectDef from '../../../utils/Dispatch/Common/Generator/getEditableSelectDef';
import EDITABLE_CHASSIS_DEF from '../../Dispatch/Common/editableChassisDef';

const DAILY_REPORT_EDITABLE_COLUMN_DEF = {
  equipment: getEditableEquipmentDef(),

  ch_no: EDITABLE_CHASSIS_DEF,
  pool: selectOption => getEditableSelectDef(selectOption),

  lfd: getEditableDateDef({ field: 'lfd' }),
  rfd: getEditableDateDef({ field: 'rfd' }),

  po_schedule: getEditableDatetimeDef({ field: 'po_schedule' }),
  dilv_schedule: getEditableDatetimeDef({ field: 'dilv_schedule' }),
  rtn_schedule: getEditableDatetimeDef({ field: 'rtn_schedule' }),

  remarks: getEditableTextDef({ field: 'remarks' }),
};

export default DAILY_REPORT_EDITABLE_COLUMN_DEF;
