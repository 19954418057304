import masterCompanyDefaultApi from '../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

/**
 *
 * API that update company data
 *
 * @param {*} data Form Data
 * @returns Server response
 */
const patchCompanyData = async data => {
  try {
    const response = await masterCompanyDefaultApi.patch(
      `${data?.company}`,
      data,
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }), { cause: error });
  }
};

export default patchCompanyData;
