import React from 'react';
import StyledCommonSideModalHeader from '../../../styles/Common/CommonSideModal/StyledCommonSideModalHeader';
import { PATH_MODAL_CLOSE_ICON } from '../../../constants/Common/path';
import CommonSideModalCloseIcon from '../CommonSideModalCloseIcon';

const CommonSideModalHeader = ({ ...props }) => {
  return (
    <StyledCommonSideModalHeader>
      <div style={{ gridArea: 'left' }} />
      <div
        className="flex justify-center items-center text-[#001e5f] text-[14px] font-bold"
        style={{ gridArea: 'center' }}
      >
        {props?.content}
      </div>
      <div
        className="flex justify-end items-center"
        style={{ gridArea: 'right' }}
      >
        <CommonSideModalCloseIcon onCloseClick={props?.onCloseClick} />
      </div>
    </StyledCommonSideModalHeader>
  );
};

export default CommonSideModalHeader;
