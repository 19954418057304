import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import divBasicDefaultApi from '../../divApi';

const patchEmailButton = async ({ div, type }) => {
  try {
    const response = await divBasicDefaultApi.patch(`${div}/email-btn`, {
      type,
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchEmailButton;
