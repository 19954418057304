import React from 'react';
import ManifestDetailHeader from './ManifestDetailHeader';
import ManifestDetailTable from './ManifestDetailTable';
import StyledManifestDetails from '../../../../styles/MobileAdmin/Manifest/StyledManifestDetails';
import { MANIFEST_DETAIL_HEADERS } from '../../../../constants/MobileAdmin/Manifest/manifestDetailValues';

const ManifestDetails = props => {
  return (
    <div className="w-full overflow-auto bg-[#DDE2EC]">
      <StyledManifestDetails>
        <ManifestDetailHeader headers={MANIFEST_DETAIL_HEADERS} />
        <ManifestDetailTable rowData={props?.data} />
      </StyledManifestDetails>
    </div>
  );
};

export default ManifestDetails;
