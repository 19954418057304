import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import memoDefaultAPi from '../api';

const editWOMemoList = async ({ woNo, content, action, id }) => {
  try {
    const response = await memoDefaultAPi.patch(`wo/${woNo}`, {
      content,
      action,
      id,
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};
export default editWOMemoList;
