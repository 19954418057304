import styled from 'styled-components';

const StyledTraceSearchBarOption = styled.select`
  color: #808487;
  font-size: 15px;
  font-weight: 500;
  width: 113px;
  background-color: #f9f9f9;
  margin-right: 10px;
`;

export default StyledTraceSearchBarOption;
