const tmsReportScheduleKeys = {
  all: ['TMS_REPORT_SCHEDULE'],
  list: ({ type, date, category, div, 'sche-type': sche }) => [
    ...tmsReportScheduleKeys.all,
    type,
    date,
    category,
    div,
    sche,
  ],
};

export default tmsReportScheduleKeys;
