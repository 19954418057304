import React from 'react';
import { MASTER_COMMON_ACTIVE_SELECT_OPTION } from '../../../../../constants/Master/Common/masterCommonOption';
import { MASTER_COMPANY_SEARCH_TYPE_OPTION } from '../../../../../constants/Master/Company/masterCompanyOption';
import CommonSelect from '../../../../Inputs/CommonSelect/CommonSelect';
import CommonTextInput from '../../../../Inputs/CommonTextInput/CommonTextInput';

const AdvancedSearchModalContent = ({
  queryObject,
  categoryOption,
  viewPerm,
}) => {
  return (
    <div className="bg-[#F9F9F9] w-full h-fit px-[30px] pt-[15px] flex flex-col justify-between">
      <div className="w-full flex gap-x-[20px]">
        <div className="w-[129px]">
          <CommonSelect
            label="CATEGORY"
            inputName="category"
            option={[{ label: 'Select', value: '' }, ...categoryOption]}
            defaultValue={queryObject?.category || ''}
            onChangeHandler={({ e, onChange }) => {
              onChange(e?.target?.value);
            }}
          />
        </div>
        {viewPerm?.compView && (
          <div className="w-[129px]">
            <CommonSelect
              label="TYPE"
              inputName="type"
              option={MASTER_COMPANY_SEARCH_TYPE_OPTION}
              defaultValue={queryObject?.type || ''}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
        )}
        <div className="w-[129px]">
          <CommonSelect
            label="ACTIVE"
            inputName="active"
            option={MASTER_COMMON_ACTIVE_SELECT_OPTION}
            defaultValue={queryObject?.active || ''}
            onChangeHandler={({ e, onChange }) => {
              onChange(e?.target?.value);
            }}
          />
        </div>
      </div>
      <div className="w-full flex">
        <div className="w-[427px]">
          <CommonTextInput
            label="COMPANY"
            inputName="company"
            defaultValue={queryObject?.company || null}
            onChangeHandler={({ e, onChange }) => {
              onChange(e?.target?.value);
            }}
            placeholder="Company ID / Company Name"
          />
        </div>
      </div>
    </div>
  );
};
export default AdvancedSearchModalContent;
