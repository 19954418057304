import React, { useEffect, useState } from 'react';
import CommonCheckbox from '../../Inputs/CommonCheckbox/CommonCheckbox';
import MultiCheckboxGroup from './MultiCheckboxGroup';
import updateOrderListChecks from '../../../utils/Dispatch/Common/Generator/updateOrderListChecks';

const AvailableCheckboxArea = ({ checkOrderList, setCheckOrderList }) => {
  const [changeField, setChangeField] = useState({});

  useEffect(() => {
    const updatedOrderList = updateOrderListChecks({
      checkOrderList,
      changeField,
    });
    setCheckOrderList(updatedOrderList);
  }, [changeField]);

  return (
    <>
      {checkOrderList.map(item =>
        item?.children ? (
          <MultiCheckboxGroup
            key={`${item?.value}`}
            groupInfo={item}
            setChangeField={setChangeField}
          />
        ) : (
          <div key={`${item?.value}`} className="h-[20px] flex items-center">
            <CommonCheckbox
              key={`${item?.value}`}
              alignCenter={false}
              helpTextVisible={false}
              labelVisible={false}
              inputName={item?.value}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.checked);
                setChangeField({
                  name: item?.value,
                  checked: e?.target?.checked,
                });
              }}
              options={[
                {
                  inputName: item?.value,
                  defaultValue: item?.checked,
                  optionLabel: item?.label,
                },
              ]}
            />
          </div>
        ),
      )}
    </>
  );
};

export default AvailableCheckboxArea;
