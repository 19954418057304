import handleQueryError from '../../../utils/Common/Handler/handleQueryError';
import cusReportDefaultUrl from '../api';

const getSavedList = async ({ div }) => {
  try {
    const response = await cusReportDefaultUrl.get('saved', {
      params: {
        div,
      },
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getSavedList;
