import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SUCCESS_SAVE } from '../../../../constants/Common/successMessage';
import postWeightPerZIP from '../../../../services/Admin/DivConf/Post/postWeightPerZIP';
import useControlToast from '../../../Common/Toast/useControlToast';
import adminKeys from '../../keys';

const usePostWeightPerZipMutation = ({
  setIsMessageOpen,
  setMessage,
  setIsSuccess,
  div,
  gridRef,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  return useMutation({
    mutationFn: postWeightPerZIP,
    onSettled: (data, error, variables) => {
      variables?.setAddDisabled(false);
    },
    onSuccess: () => {
      const topRow = gridRef.current.api.getPinnedTopRow(0);
      topRow.setData({});
      gridRef.current.api.redrawRows({ rowNodes: [topRow] });
      queryClient.refetchQueries({
        queryKey: adminKeys?.weightPerZIP({ div }),
      });
      addToast({
        message: SUCCESS_SAVE,
      });
    },
    onError: error => {
      setIsSuccess(false);
      setMessage(error.message);
      setIsMessageOpen(true);
    },
  });
};

export default usePostWeightPerZipMutation;
