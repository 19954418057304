import handleCheckboxChange from '../../Common/handleCheckboxChange';

const generateInitOptions = ({ sortedDivList, setDivQuery, filterStorage }) => {
  const initOptions = sortedDivList?.map(div => ({
    label: div,
    value: div,
    defaultValue: filterStorage?.filter?.div?.[div],
    onChange: ({ ...props }) =>
      handleCheckboxChange({
        ...props,
        setQuery: setDivQuery,
        filterStorage,
        item: 'div',
      }),
  }));

  return initOptions;
};

export default generateInitOptions;
