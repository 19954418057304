import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';
import billingInfoDefaultAPI from '../../api';

const postARData = async ({ woNo, data }) => {
  try {
    const response = await billingInfoDefaultAPI.post(`${woNo}/ar`, {
      company: data?.company,
      product_code: data?.product_code,
      rate: data?.rate,
      qty: data?.qty,
      // wave: data?.wave,
      description: data?.description === '' ? null : data?.description,
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default postARData;
