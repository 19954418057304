import { useQuery } from '@tanstack/react-query';
import { commonCompanyAPI } from '../../../services/Common/Company/commonCompanyAPI';
import ediKeys from '../keys';

const useGetCneeList = () => {
  const {
    isLoading: isLoadingCneeList,
    data,
    isRefetching: isRefetchingCneeList,
  } = useQuery({
    queryKey: ediKeys.cneeList(),
    queryFn: () => commonCompanyAPI.getCommonCneeNglCompany(),
    refetchOnWindowFocus: false,
  });

  return {
    isLoadingCneeList,
    cneeList: data?.data,
    isRefetchingCneeList,
  };
};

export default useGetCneeList;
