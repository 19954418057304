import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import bcDetailDefaultApi from '../../api';

const postEmail = async ({ woNo, name }) => {
  try {
    const response = await bcDetailDefaultApi.post(`${woNo}/email`, {
      name,
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default postEmail;
