import dayjs from 'dayjs';

export const changeText = ({ e, ...props }) => {
  const { onChange, regex } = props;
  if (regex && !regex.test(e?.target?.value)) {
    return;
  }
  onChange?.(e?.target?.value);
};

export const changeSelect = ({ e, ...props }) => {
  const { onChange } = props;
  onChange?.(e?.target?.value);
};

export const changeDate = ({ e, ...props }) => {
  const { onChange } = props;
  const dateValue =
    e && dayjs(e)?.isValid() ? dayjs(e)?.format('YYYY-MM-DD') : null;
  onChange?.(dateValue);
};

export const changeAutoComplete = ({ e, ...props }) => {
  const { onChange, selectProps } = props;
  onChange?.(selectProps?.value || '');
};

export const changeAutoCompleteWithLabel = ({ e, ...props }) => {
  const { onChange, selectProps } = props;
  onChange?.(selectProps || '');
};

export const changeCheckBox = ({ e, ...props }) => {
  const { onChange } = props;
  onChange?.(e?.target?.checked);
};
