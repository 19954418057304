import styled from 'styled-components';

const StyledBCDetailBillingInfoProfitValue = styled.div`
  display: flex;
  width: 163px;
  height: 30px;
  background-color: ${props => (props.profit > 0 ? '#264B9F' : '#892B2B')};
  margin-left: 2px;
  align-items: center;
  justify-content: left;
  color: #fff;
  font-size: 11px;
  font-weight: 500;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
`;

export default StyledBCDetailBillingInfoProfitValue;
