import {
  PERM_QB_SEND_VIEW_EDIT,
  PERM_QB_UPDATE_VIEW_EDIT,
  PERM_QB_LOCKED_VIEW_EDIT,
  PERM_ACCT_DRIVER_PAY_VIEW_EDIT,
} from '../../../../constants/Common/permission';
import checkPermission from '../../../Common/Router/Handler/checkPermission';

const getPermissionList = () => {
  const isSendable = checkPermission({ permission: PERM_QB_SEND_VIEW_EDIT });
  const isUpdatable = checkPermission({ permission: PERM_QB_UPDATE_VIEW_EDIT });
  const isLockedUpdatable = checkPermission({
    permission: PERM_QB_LOCKED_VIEW_EDIT,
  });
  const isDriverPayable = checkPermission({
    permission: PERM_ACCT_DRIVER_PAY_VIEW_EDIT,
  });

  return {
    isSendable,
    isUpdatable,
    isLockedUpdatable,
    isDriverPayable,
  };
};

export default getPermissionList;
