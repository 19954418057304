import { acctDefaultAPI } from '../../api';

import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

export const getQBAPARList = async ({ queryParam }) => {
  try {
    const response = await acctDefaultAPI.get(`qbapar`, { params: queryParam });
    return response.data;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};
