import styled from 'styled-components';

const StyledBCDetailBillingInfoPaymentModalCell = styled.div`
  width: ${props => props.width || '100px'};
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #eee;
  white-space: nowrap;
  font-size: 12px;
`;

export default StyledBCDetailBillingInfoPaymentModalCell;
