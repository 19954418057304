import React from 'react';
import DualMoveModalContentJobColumn from './DualMoveModalContentJobColumn';
import DualMoveModalContentJobRow from './DualMoveModalContentJobRow';
import DualMoveModalContentMemo from './DualMoveModalContentMemo';
import checkPermission from '../../../../../../../../utils/Common/Router/Handler/checkPermission';
import {
  PERM_BC_DETAIL_EXP_EDIT,
  PERM_BC_DETAIL_IMP_EDIT,
} from '../../../../../../../../constants/Common/permission';

const DualMoveModalContentJob = ({ ...props }) => {
  const isModifiable =
    props?.job?.wo_no?.[1] === 'M'
      ? checkPermission({ permission: PERM_BC_DETAIL_IMP_EDIT })
      : checkPermission({ permission: PERM_BC_DETAIL_EXP_EDIT });

  return (
    <>
      <DualMoveModalContentJobColumn />
      <DualMoveModalContentJobRow isModifiable={isModifiable} {...props} />
      <DualMoveModalContentMemo isModifiable={isModifiable} {...props} />
    </>
  );
};

export default DualMoveModalContentJob;
