import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';
import billingInfoDefaultAPI from '../../api';

const getARProductCode = async () => {
  try {
    const response = await billingInfoDefaultAPI.get(`product-code/ar`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getARProductCode;
