import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import useGetJobAssign from '../../../hooks/BCDetail/BillingInfo/Common/useGetJobAssign';
import StyledBCDetailBillingInfoPaymentModalButton from '../../../styles/BCDetail/BillingInfo/PaymentModal/StyledBCDetailBillingInfoPaymentModalButton';
import StyledBCDetailBillingInfoPaymentModalCell from '../../../styles/BCDetail/BillingInfo/PaymentModal/StyledBCDetailBillingInfoPaymentModalCell';
import StyledBCDetailBillingInfoPaymentModalContainer from '../../../styles/BCDetail/BillingInfo/PaymentModal/StyledBCDetailBillingInfoPaymentModalContainer';
import StyledBCDetailBillingInfoPaymentModalHeader from '../../../styles/BCDetail/BillingInfo/PaymentModal/StyledBCDetailBillingInfoPaymentModalHeader';
import StyledBCDetailBillingInfoPaymentModalName from '../../../styles/BCDetail/BillingInfo/PaymentModal/StyledBCDetailBillingInfoPaymentModalName';
import StyledBCDetailBillingInfoPaymentModalNameValueContainer from '../../../styles/BCDetail/BillingInfo/PaymentModal/StyledBCDetailBillingInfoPaymentModalNameValueContainer';
import StyledBCDetailBillingInfoPaymentModalRow from '../../../styles/BCDetail/BillingInfo/PaymentModal/StyledBCDetailBillingInfoPaymentModalRow';
import StyledBCDetailBillingInfoPaymentModalTable from '../../../styles/BCDetail/BillingInfo/PaymentModal/StyledBCDetailBillingInfoPaymentModalTable';
import StyledBCDetailBillingInfoPaymentModalTypeCell from '../../../styles/BCDetail/BillingInfo/PaymentModal/StyledBCDetailBillingInfoPaymentModalTypeCell';
import StyledBCDetailBillingInfoPaymentModalTypeContainer from '../../../styles/BCDetail/BillingInfo/PaymentModal/StyledBCDetailBillingInfoPaymentModalTypeContainer';
import StyledBCDetailBillingInfoPaymentModalValue from '../../../styles/BCDetail/BillingInfo/PaymentModal/StyledBCDetailBillingInfoPaymentModalValue';
import updateButtonDisabled from '../../../utils/Common/Handler/updateButtonDisabled';
import CommonButton from '../../CommonButton/CommonButton';
import CommonMessageModal from '../../CommonMessageModal/CommonMessageModal';

const PaymentModal = ({
  driverID,
  APTableFormController,
  setSelectedDriver,
  setPaymentModal,
  isActiveClicked,
  setIsActiveClicked,
  setIsAlertOpen,
  setErrorMessage,
}) => {
  const buttonRef = useRef(null);

  const jobAssignQuery = useGetJobAssign({
    driverID,
    setIsAlertOpen,
    setErrorMessage,
  });
  const [selectedRowJobIdx, setSelectedRowJobIdx] = useState(null);

  const rowClickHandler = ({ seq }) => {
    setSelectedRowJobIdx(seq);
    updateButtonDisabled(buttonRef, true);
  };

  useEffect(() => {
    if (jobAssignQuery.isSuccess && !jobAssignQuery.data.data[0]) {
      setIsActiveClicked(false);
    }
  }, [jobAssignQuery]);

  return (
    jobAssignQuery.isSuccess &&
    isActiveClicked && (
      <CommonMessageModal
        open={isActiveClicked}
        setOpen={setIsActiveClicked}
        header="Choose the right job for the payment."
        content={
          <>
            <StyledBCDetailBillingInfoPaymentModalContainer>
              <StyledBCDetailBillingInfoPaymentModalNameValueContainer>
                <StyledBCDetailBillingInfoPaymentModalName>
                  Driver ID
                </StyledBCDetailBillingInfoPaymentModalName>
                <StyledBCDetailBillingInfoPaymentModalValue>
                  {driverID}
                </StyledBCDetailBillingInfoPaymentModalValue>
              </StyledBCDetailBillingInfoPaymentModalNameValueContainer>
            </StyledBCDetailBillingInfoPaymentModalContainer>
            <StyledBCDetailBillingInfoPaymentModalTable>
              <StyledBCDetailBillingInfoPaymentModalTypeContainer>
                <StyledBCDetailBillingInfoPaymentModalTypeCell backgroundColor="#DDE2EC">
                  {' '}
                  FROM
                </StyledBCDetailBillingInfoPaymentModalTypeCell>
                <StyledBCDetailBillingInfoPaymentModalTypeCell backgroundColor="#FAD7D7">
                  {' '}
                  TO
                </StyledBCDetailBillingInfoPaymentModalTypeCell>
              </StyledBCDetailBillingInfoPaymentModalTypeContainer>
              <StyledBCDetailBillingInfoPaymentModalHeader>
                <StyledBCDetailBillingInfoPaymentModalCell width="32px" />
                <StyledBCDetailBillingInfoPaymentModalCell width="49px">
                  Job
                </StyledBCDetailBillingInfoPaymentModalCell>
                <StyledBCDetailBillingInfoPaymentModalCell width="85px">
                  LOC ID
                </StyledBCDetailBillingInfoPaymentModalCell>
                <StyledBCDetailBillingInfoPaymentModalCell width="73px">
                  Date
                </StyledBCDetailBillingInfoPaymentModalCell>
                <StyledBCDetailBillingInfoPaymentModalCell width="63px">
                  Time
                </StyledBCDetailBillingInfoPaymentModalCell>
                <StyledBCDetailBillingInfoPaymentModalCell width="49px">
                  Job
                </StyledBCDetailBillingInfoPaymentModalCell>
                <StyledBCDetailBillingInfoPaymentModalCell width="85px">
                  LOC ID
                </StyledBCDetailBillingInfoPaymentModalCell>
                <StyledBCDetailBillingInfoPaymentModalCell width="73px">
                  Date
                </StyledBCDetailBillingInfoPaymentModalCell>
                <StyledBCDetailBillingInfoPaymentModalCell width="63px">
                  Time
                </StyledBCDetailBillingInfoPaymentModalCell>
              </StyledBCDetailBillingInfoPaymentModalHeader>
              {jobAssignQuery.jobAssignData.map((job, index) => (
                <StyledBCDetailBillingInfoPaymentModalRow key={job.seq}>
                  <StyledBCDetailBillingInfoPaymentModalCell width="32px">
                    <input
                      type="radio"
                      name="job"
                      value={job.seq}
                      checked={selectedRowJobIdx === job.seq}
                      onChange={() => {
                        rowClickHandler({ seq: job.seq });
                      }}
                    />
                  </StyledBCDetailBillingInfoPaymentModalCell>
                  <StyledBCDetailBillingInfoPaymentModalCell width="49px">
                    {job.from_location.custom_type}
                  </StyledBCDetailBillingInfoPaymentModalCell>
                  <StyledBCDetailBillingInfoPaymentModalCell width="85px">
                    {job.from_location.company}
                  </StyledBCDetailBillingInfoPaymentModalCell>
                  <StyledBCDetailBillingInfoPaymentModalCell width="73px">
                    {job.from_location.sche_date
                      ? dayjs(job.from_location.sche_date).format('MM/DD/YY')
                      : ''}
                  </StyledBCDetailBillingInfoPaymentModalCell>
                  <StyledBCDetailBillingInfoPaymentModalCell width="63px">
                    {job.from_location.sche_time
                      ? `${job.from_location.sche_time.split(':')[0]}:${
                          job.from_location.sche_time.split(':')[1]
                        }`
                      : ''}
                  </StyledBCDetailBillingInfoPaymentModalCell>
                  <StyledBCDetailBillingInfoPaymentModalCell width="49px" l>
                    {job.to_location.custom_type}
                  </StyledBCDetailBillingInfoPaymentModalCell>
                  <StyledBCDetailBillingInfoPaymentModalCell width="85px">
                    {job.to_location.company}
                  </StyledBCDetailBillingInfoPaymentModalCell>
                  <StyledBCDetailBillingInfoPaymentModalCell width="73px">
                    {job.to_location.sche_date
                      ? dayjs(job.to_location.sche_date).format('MM/DD/YY')
                      : ''}
                  </StyledBCDetailBillingInfoPaymentModalCell>
                  <StyledBCDetailBillingInfoPaymentModalCell width="63px">
                    {job.to_location.sche_time
                      ? `${job.to_location.sche_time.split(':')[0]}:${
                          job.to_location.sche_time.split(':')[1]
                        }`
                      : ''}
                  </StyledBCDetailBillingInfoPaymentModalCell>
                </StyledBCDetailBillingInfoPaymentModalRow>
              ))}
            </StyledBCDetailBillingInfoPaymentModalTable>
          </>
        }
        buttonContent={
          <StyledBCDetailBillingInfoPaymentModalButton>
            <CommonButton
              onClick={() => {
                APTableFormController?.setValue(
                  'add.job_idx',
                  selectedRowJobIdx,
                );
                setSelectedDriver(null);
                setPaymentModal(false);
              }}
              category="assign"
              disabled={!selectedRowJobIdx}
            >
              Assign
            </CommonButton>
            <CommonButton
              onClick={() => {
                APTableFormController?.setValue(null, null);
                setSelectedDriver(null);
                setPaymentModal(false);
              }}
              category="skip"
            >
              Skip
            </CommonButton>
          </StyledBCDetailBillingInfoPaymentModalButton>
        }
      />
    )
  );
};

export default PaymentModal;
