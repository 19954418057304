import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import postAssignDriverAPInput from '../../../../services/BCDetail/DetailInfo/Schedule/Post/postAssignDriverAPInput';
import bcDetailKeys from '../../keys';
import useControlToast from '../../../Common/Toast/useControlToast';
import { SUCCESS_ASSIGN } from '../../../../constants/Common/successMessage';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';

const usePostAssignDriverAPInput = ({
  setAPModalOpener,
  setModalOpener,
  setModalMessage,
  setLoadingOpener,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  const mutator = useMutation({
    mutationFn: postAssignDriverAPInput,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: () => {
      queryClient?.invalidateQueries({ queryKey: bcDetailKeys?.all });
      setAPModalOpener(false);
      addToast({ message: SUCCESS_ASSIGN });
    },
    onError: error => {
      setModalOpener(true);
      setModalMessage(error?.message);
    },
  });

  useEffect(() => {
    if (mutator.isPending) {
      setLoadingOpener(true);
    } else if (!mutator.isPending) {
      setLoadingOpener(false);
    }
  }, [mutator.isPending]);

  return { ...mutator };
};

export default usePostAssignDriverAPInput;
