import styled from 'styled-components';

const StyledBCDetailBillingInfoPaymentModalHeader = styled.div`
  display: flex;
  background-color: #f9f9f9;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
`;

export default StyledBCDetailBillingInfoPaymentModalHeader;
