import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';
import bookingDefaultAPI from '../../../bookingAPI';

const patchIncompleteBookingList = async ({ bookingList }) => {
  try {
    const response = await bookingDefaultAPI.patch(`incomplete`, {
      bk_seq: bookingList,
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchIncompleteBookingList;
