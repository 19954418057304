import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';
import { CELL_TYPE_BUTTON } from '../../../../constants/Common/DataGrid/cellType';

const createManifestColumnDefs = () => [
  {
    headerName: 'DRIVER',
    field: 'driver',
    width: 170,
    editable: false,
  },
  {
    headerName: 'WO #',
    field: 'wo_no',
    width: 100,
    editable: false,
  },
  {
    headerName: 'EQUIPMENT',
    field: 'equip_no',
    width: 90,
    editable: false,
  },
  {
    headerName: 'LOC 01',
    field: 'from_location',
    width: 72,
    editable: false,
  },
  {
    headerName: 'LOC 02',
    field: 'location_2',
    valueGetter: params => {
      return params?.data?.live_dilv_location
        ? params?.data?.live_dilv_location
        : params?.data?.to_location;
    },
    width: 72,
    editable: false,
  },
  {
    headerName: 'LOC 03',
    field: 'location_3',
    valueGetter: params => {
      return params?.data?.live_dilv_location
        ? params?.data?.to_location
        : null;
    },
    width: 72,
    editable: false,
  },
  {
    headerName: 'POD FILE',
    field: 'file_url',
    floatingFilter: false,
    sortable: false,
    maxWidth: 75,
    minWidth: 75,
    editable: false,
    cellRendererSelector: gridParam => {
      if (gridParam?.data?.file_url) {
        return cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_BUTTON,
          content: 'PDF',
          category: 'pdf',
          customStyle: {
            size: 'small',
          },
          onClickHandler: () => {
            window.open(gridParam?.data?.file_url, '_blank');
          },
        });
      }
      return null;
    },
    preventClickCell: true,
  },
];

export default createManifestColumnDefs;
