import { useMutation } from '@tanstack/react-query';
import postPerdiemInvoice from '../../../../services/Acct/PerdiemReconcile/Post/postPerdiemInvoice';
import { DUPLICATE_ERROR } from '../../../../constants/Acct/Common/staticNames';
import { deleteForm } from '../../../../utils/Acct/Common/Handler/formListHandler';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';

const usePostPerdiemInvoice = ({
  submitData,
  setProgressLength,
  setDuplicationErrorList,
  setInvoiceList,
  setBasicErrorMessage,
}) => {
  const mutate = useMutation({
    mutationFn: postPerdiemInvoice,
    onSuccess: () => {
      deleteForm({ formKey: submitData?.key, setInvoiceList });
    },
    onError: error => {
      if (error?.name === DUPLICATE_ERROR) {
        setDuplicationErrorList(prev => [
          ...prev,
          { seq: error?.responseData?.seq, submitData },
        ]);
      } else {
        setBasicErrorMessage(prev => {
          return error?.message;
        });
      }
    },
    onSettled: (data, error, variables) => {
      setProgressLength(prev => prev + 1);
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
  });

  return { ...mutate };
};

export default usePostPerdiemInvoice;
