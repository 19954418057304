import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { INIT_AP_TABLE_CONTENT } from '../../../constants/Dispatch/Common/initAPTableContent';
import dispatchKeys from '../../../hooks/Dispatch2/keys';
import CommonMessageModal from '../../CommonMessageModal/CommonMessageModal';
import APModalButtonContent from './APModalButtonContent';
import APModalContent from './APModalContent';

const APModal = ({ ...props }) => {
  /** Query Client */
  const queryClient = useQueryClient();

  /** Modal Opener & Setter */
  const { apModalOpener, setAPModalOpener } = props;

  /** API State */
  const { getAPIsFetching, getAPIsError } = props;

  /** Data Setter */
  const {
    setWONo,
    setSelectedScheduleSeq,
    setSelectedDriverData,
    setMessageModalOpener,
    setMessageModalMessage,
    setLoadingOpener,
  } = props;

  /** Data */
  const { woNo, apInputData, selectedScheduleSeq } = props;

  /** AP Table content. Max 5 contents */
  const [apTableContent, setAPTableContent] = useState([
    {
      productCode: '',
      productCodeName: '',
      amount: 0,
    },
  ]);
  useEffect(() => {
    if (
      apInputData?.ap_recommendations &&
      apInputData?.ap_recommendations?.length > 0
    ) {
      setAPTableContent(prev => [
        ...apInputData?.ap_recommendations?.map(recommendation => {
          return {
            productCode: recommendation?.code?.product_code,
            productCodeName: recommendation?.code?.product_code_name,
            amount: recommendation?.amt,
          };
        }),
        ...prev,
      ]);
    }
  }, [apInputData?.ap_recommendations]);

  return (
    <CommonMessageModal
      open={apModalOpener && !getAPIsFetching && !getAPIsError}
      setOpen={setAPModalOpener}
      onCloseClick={() => {
        queryClient?.removeQueries({ queryKey: dispatchKeys?.driver() });
        setWONo('');
        setSelectedScheduleSeq(null);
        setSelectedDriverData(null);
        setAPTableContent(INIT_AP_TABLE_CONTENT);
      }}
      width="602px"
      header="Driver AP Input"
      content={
        <APModalContent
          apInputData={apInputData}
          apTableContent={apTableContent}
          setAPTableContent={setAPTableContent}
        />
      }
      buttonContent={
        <APModalButtonContent
          woNo={woNo}
          setWONo={setWONo}
          apInputData={apInputData}
          selectedDriver={apInputData?.driver}
          apTableContent={apTableContent}
          setAPTableContent={setAPTableContent}
          setAPModalOpener={setAPModalOpener}
          setSelectedScheduleSeq={setSelectedScheduleSeq}
          setSelectedDriverData={setSelectedDriverData}
          selectedScheduleSeq={selectedScheduleSeq}
          setMessageModalOpener={setMessageModalOpener}
          setMessageModalMessage={setMessageModalMessage}
          setLoadingOpener={setLoadingOpener}
        />
      }
    />
  );
};

export default APModal;
