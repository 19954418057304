const handleActionClick = ({
  setConfirmContent,
  setConfirmOpen,
  action,
  rows,
  seq,
}) => {
  if (action === 'reject')
    setConfirmContent({
      action,
      message: ['Are you sure that you want to reject it?'],
      seq,
    });

  if (action === 'approve')
    setConfirmContent({
      action,
      message: ['This item will go to AP.', 'Are you sure?'],
      seq,
    });

  if (action === 'rejectItems')
    setConfirmContent({
      action: 'reject',
      message: ['Are you sure that you want to reject them?'],
      rows,
    });

  if (action === 'approveItems')
    setConfirmContent({
      action: 'approve',
      message: [`These "${rows.length}" items will go to AP.`, 'Are you sure?'],
      rows,
    });

  setConfirmOpen(true);
};

export default handleActionClick;
