import openBCDetail from '../../../Common/openBCDetail';

const handleCellClick = ({ gridParam, gridRef, editableCols, buttonCols }) => {
  const getColId = gridParam?.column?.colId;

  if (![...editableCols, ...buttonCols].includes(getColId))
    openBCDetail({ woNo: gridParam?.data?.wo_no });
};

export default handleCellClick;
