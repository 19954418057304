import {
  AP_GROUP,
  AR_GROUP,
  DELIVERY_GROUP,
  DOCUMENT_GROUP,
  PULL_OUT_GROUP,
  RETURN_GROUP,
} from '../Common/columnGroups';

const BROKERAGE_DEFAULT_FIELDS = {
  wms: {},
  created_date: {},
  wo_no: {},
  billto: {},
  trailer_no: {},
  type: {},
  ch_no: {},
  pool: {},
  ch_type: {},
  ref_no: {},
  purchase_no: {},
  mbl_no: {},
  wgt: {},
  qty: {},
  over_height: {},
  over_weight: {},
  hazmat: {},
  hot: {},
  po_g: { children: PULL_OUT_GROUP },
  dilv_g: { children: DELIVERY_GROUP },
  pu_g: { children: PULL_OUT_GROUP },
  rtn_g: { children: RETURN_GROUP },
  empty_load_cfm: {},
  empty_load_status: {},
  ar_g: { children: AR_GROUP },
  ap_g: { children: AP_GROUP },
  profit: {},
  inv: {},
  doc_g: { children: DOCUMENT_GROUP },
  sendto: {},
  remarks: {},
};

export { BROKERAGE_DEFAULT_FIELDS };
