import React from 'react';
import ScheduleAPModalInfoContent from '../ScheduleAPModalInfoContent';
import ScheduleAPModalTableContent from '../ScheduleAPModalTableContent';

const ScheduleAPModalContent = ({
  apInputData,
  apTableContent,
  setAPTableContent,
  ...props
}) => {
  return (
    <div className="flex flex-col justify-center items-center">
      <ScheduleAPModalInfoContent
        driver={apInputData?.driver || '-'}
        fromLoc={apInputData?.from_location || '-'}
        toLoc={apInputData?.to_location || '-'}
        mileage={apInputData?.mileage || '-'}
      />
      <ScheduleAPModalTableContent
        apInputData={apInputData}
        apTableContent={apTableContent}
        setAPTableContent={setAPTableContent}
      />
    </div>
  );
};

export default ScheduleAPModalContent;
