/**
 * @description
 * Function that judge drop live switch button visibility
 * @param {*} param0
 * @returns
 */
const handleScheduleDropLiveButtonVisibility = ({ ...props }) => {
  /** Get data */
  const {
    scheType,
    locationSet,
    jobSet,
    pickUpActualInDate,
    pickUpActualInTime,
    pickUpActualOutDate,
    pickUpActualOutTime,
    isEditable,
    completed,
  } = props;

  if (!isEditable || !!completed) return false;

  /** Check DROP schedule constraint */
  if (scheType === 'DROP') {
    if (
      pickUpActualInDate ||
      pickUpActualInTime ||
      pickUpActualOutDate ||
      pickUpActualOutTime
    )
      return false;
  }

  let getDilvLoc = null;

  /** Find type DILV and ordering 0 location in location_set data */
  getDilvLoc = locationSet?.find(
    loc => loc?.type === 'DILV' && loc?.ordering === 0,
  );

  /** If not found in location_set data, find in job_set data */
  if (!getDilvLoc) {
    getDilvLoc = jobSet?.find(
      job =>
        (job?.from_location?.type === 'DILV' &&
          job?.from_location?.ordering === 0) ||
        (job?.live_dilv_location?.type === 'DILV' &&
          job?.live_dilv_location?.ordering === 0) ||
        (job?.to_location?.type === 'DILV' && job?.to_location?.ordering === 0),
    );
  }

  /** If not found any dilv button, should not be visible */
  if (!getDilvLoc) return false;

  /** Find DILV ordering 0 in from, live_dilv, to location */
  let getDilvBaseLoc = null;

  if (
    getDilvLoc?.from_location?.type === 'DILV' &&
    getDilvLoc?.from_location?.ordering === 0
  ) {
    getDilvBaseLoc = getDilvLoc?.from_location;
  } else if (
    getDilvLoc?.live_dilv_location?.type === 'DILV' &&
    getDilvLoc?.live_dilv_location?.ordering === 0
  ) {
    getDilvBaseLoc = getDilvLoc?.live_dilv_location;
  } else if (
    getDilvLoc?.to_location?.type === 'DILV' &&
    getDilvLoc?.to_location?.ordering === 0
  ) {
    getDilvBaseLoc = getDilvLoc?.to_location;
  } else if (getDilvLoc?.type === 'DILV' && getDilvLoc?.ordering === 0) {
    getDilvBaseLoc = getDilvLoc;
  }

  /** If dilv type schedule have actual-in data, switch button should not be visible */
  if (getDilvBaseLoc)
    return !(
      getDilvBaseLoc?.actual_out_date || getDilvBaseLoc?.actual_out_time
    );

  /** If not found any dilv button, should not be visible */
  return false;
};

export default handleScheduleDropLiveButtonVisibility;
