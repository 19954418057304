import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';
import billingInfoDefaultAPI from '../../api';

const postRateCFM = async ({ seq, data }) => {
  try {
    const response = await billingInfoDefaultAPI.post(`/rate-cfm/${seq}`, data);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default postRateCFM;
