import React from 'react';
import { MASTER_COMMON_ACTIVE_SELECT_OPTION } from '../../../../../constants/Master/Common/masterCommonOption';
import { DRIVER_POSITION_OPTION } from '../../../../../constants/Master/Driver/masterDriverOption';
import CommonSelect from '../../../../Inputs/CommonSelect/CommonSelect';
import CommonTextInput from '../../../../Inputs/CommonTextInput/CommonTextInput';

const AdvancedSearchModalContent = ({ divListOption, queryObject }) => {
  return (
    <div className="bg-[#F9F9F9] w-full h-fit px-[30px] pt-[15px] flex flex-col justify-between">
      <div className="w-full flex gap-x-[20px]">
        <div className="w-[129px]">
          <CommonSelect
            label="DIV"
            inputName="div"
            option={divListOption}
            defaultValue={queryObject?.div || ''}
            onChangeHandler={({ e, onChange }) => {
              onChange(e?.target?.value);
            }}
          />
        </div>
        <div className="w-[129px]">
          <CommonSelect
            label="POSITION"
            inputName="category"
            option={DRIVER_POSITION_OPTION}
            defaultValue={queryObject?.category || ''}
            onChangeHandler={({ e, onChange }) => {
              onChange(e?.target?.value);
            }}
          />
        </div>
        <div className="w-[129px]">
          <CommonSelect
            label="ACTIVE"
            inputName="active"
            option={MASTER_COMMON_ACTIVE_SELECT_OPTION}
            defaultValue={queryObject?.active || ''}
            onChangeHandler={({ e, onChange }) => {
              onChange(e?.target?.value);
            }}
          />
        </div>
      </div>
      <div className="w-full flex">
        <div className="w-[427px]">
          <CommonTextInput
            label="DRIVER"
            inputName="driver"
            defaultValue={queryObject?.driver || null}
            onChangeHandler={({ e, onChange }) => {
              onChange(e?.target?.value);
            }}
            placeholder="DRIVER ID / Driver Name"
          />
        </div>
      </div>
    </div>
  );
};
export default AdvancedSearchModalContent;
