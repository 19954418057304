const getDispatchGroupHeader = category => {
  const poHeaderName = ['van', 'brokerage']?.includes(category)
    ? 'PICK UP'
    : 'PULL OUT';

  const puHeaderName = ['van', 'brokerage']?.includes(category)
    ? 'L/E PICK UP'
    : 'PICK UP';

  return {
    equip: 'EQUIPMENT',
    info: 'INFORMATION',
    sche: 'SCHEDULE',
    geofence: 'GEOFENCE',
    ava: 'AVAILABILITY',
    cont_1: '1ST CONT',
    cont_2: '2ND CONT',
    cont_3: '3RD CONT',
    booking: 'BOOKING',
    yard: 'YARD',
    po: poHeaderName,
    dilv: 'DELIVERY',
    pu: puHeaderName,
    rtn: 'RETURN',
    lfd: 'LFD',
    rfd: 'RFD',
  };
};

export default getDispatchGroupHeader;
