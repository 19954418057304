import getFilterButtonData from './getFilterButtonData';

const isPathValid = ({ path, category }) => {
  const validPath = getFilterButtonData({ category });

  const validPathList = [
    validPath?.to,
    ...validPath?.children?.map(mainButton => mainButton.to),
  ];

  validPath?.children?.forEach(mainButton =>
    mainButton.children?.forEach(subButton => {
      validPathList?.push(
        `${mainButton?.to}/${subButton.header
          ?.toLowerCase()
          ?.replace(' #', '')
          ?.replace(' ', '-')}`,
      );
    }),
  );
  return validPathList?.includes(path);
};

export default isPathValid;
