export const generateCompanyAutoCompleteOption = itemList => {
  const option = [];

  itemList.forEach((item, idx) => {
    option?.push({
      key: idx,
      label: `${item?.value}/${item?.label}`,
      value: item?.value,
    });
  });

  return option;
};

export const generateDriverAutoCompleteOption = itemList => {
  const option = [];

  itemList.forEach((item, idx) => {
    option?.push({
      key: idx,
      label: `${item?.id}/${item?.name}`,
      value: item?.id,
    });
  });

  return option;
};
