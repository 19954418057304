import createCategory from '../../../../Master/ServiceCode/Generator/createCategory';

const generateWOConvert = ({ woNo, realtedWo }) => {
  const category = createCategory(woNo);

  if (category === 'IMPORT') {
    return { isEnabled: true, converter: 'Export' };
  }
  if (category === 'EXPORT' && realtedWo) {
    return { isEnabled: true, converter: 'Import' };
  }
  return { isEnabled: false, converter: null };
};

export default generateWOConvert;
