import styled from '@emotion/styled';

const StyledAdvancedSearchContentWrapper = styled.div`
  width: 488px;
  height: 325px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 15px;
`;

export default StyledAdvancedSearchContentWrapper;
