import { useQuery } from '@tanstack/react-query';
import getARProductCode from '../../../../services/Admin/DivConf/Get/getARProductCode';
import adminKeys from '../../keys';

const useGetARProductCode = () => {
  const { isLoading: isLoadingARProductCode, data } = useQuery({
    queryKey: adminKeys?.arProductCode(),
    queryFn: () => getARProductCode(),
    refetchOnWindowFocus: false,
  });

  return {
    isLoadingARProductCode,
    data: data?.data,
  };
};

export default useGetARProductCode;
