import { Box } from '@mui/material';
import styled from 'styled-components';

const StyledChildCheckboxWrapper = styled(Box)`
  && {
    .MuiTypography-root {
      font-size: 11px;
    }

    .MuiSvgIcon-root {
      font-size: 14px;
    }

    .MuiCheckbox-root {
      width: 10px;
      height: 10px;
    }
  }
`;

export default StyledChildCheckboxWrapper;
