import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import customerReportKeys from './keys';
import getMainData from '../../services/CustomerReport/Get/getMainData';

const useGetMainData = ({ ...props }) => {
  /** Get query key instances */
  const { div, searchParams } = props;
  const [searchParamsState, setSearchParamsState] = useState(null);
  useEffect(() => {
    const searchParamsItem = {};
    searchParams?.forEach((value, key) => {
      searchParamsItem[key] = value;
    });

    setSearchParamsState(prev => searchParamsItem);
  }, [searchParams]);

  /** Main data */
  const [mainData, setMainData] = useState([]);

  /** Query */
  const query = useQuery({
    queryKey: customerReportKeys?.main({ div, ...searchParamsState }),
    queryFn: () => getMainData({ div, ...searchParamsState }),
    refetchOnWindowFocus: false,
    enabled: !!searchParamsState,
  });

  useEffect(() => {
    if (query?.data) {
      setMainData?.(query?.data?.data);
    }
  }, [query?.data]);

  return { mainData, ...query };
};

export default useGetMainData;
