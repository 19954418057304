import React from 'react';
import StyledLabsActiveWrapper from '../../../styles/Labs/Active/StyledLabsActiveWrapper';
import StyledLabsActiveBox from '../../../styles/Labs/Active/StyledLabsActiveBox';
import LabsActiveLoading from '../../../components/Labs/Active/LabsActiveLoading';

const LabsActive = () => {
  const activeComponents = [
    {
      key: 0,
      label: 'Loading',
      component: <LabsActiveLoading />,
    },
  ];

  return (
    <div className="flex flex-col flex-wrap">
      <StyledLabsActiveWrapper>
        {activeComponents?.map(element => (
          <StyledLabsActiveBox>
            <div className="w-full bg-[#EEE] h-[50px] border-b-[3px] border-black flex justify-center items-center font-bold">
              {element?.label}
            </div>
            <div className="w-full bg-[#FFF] h-full flex justify-center items-center">
              {element?.component}
            </div>
          </StyledLabsActiveBox>
        ))}
      </StyledLabsActiveWrapper>
    </div>
  );
};

export default LabsActive;
