import React from 'react';
import styled from 'styled-components';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const StyledTraceGeofenceAccordionDetails = styled(MuiAccordionDetails)(
  ({ theme }) => ({
    padding: 0,
    paddingLeft: '20px',
    paddingRight: '20px',
  }),
);

export default StyledTraceGeofenceAccordionDetails;
