import dayjs from 'dayjs';

const onDateInputDefaultChangeHandler = props => {
  if (props) {
    if (props.e && dayjs(props.e).isValid()) {
      props.onChange?.(dayjs(props.e.$d).format('YYYY-MM-DD'));
      return;
    }

    props.onChange?.(null);
  }
};

export default onDateInputDefaultChangeHandler;
