import React from 'react';
import { Link } from 'react-router-dom';
import useGetBookingButtonCount from '../../../../hooks/Dispatch/Common/useGetBookingButtonCount';
import useGetButtonCount from '../../../../hooks/Dispatch2/Common/Get/useGetButtonCount';
import StyledMainFilterButton from '../../../../styles/Dispatch/Common/StyledMainFilterButton';
import StyledSubFilterButton from '../../../../styles/Dispatch/Common/StyledSubFilterButton';
import getQueryKeyStatus from '../../../../utils/Dispatch/Common/Generator/getQueryKeyStatus';
import setButtonCount from '../../../../utils/Dispatch/Common/Generator/setButtonCount';

const MainFilterButton = ({ allQueries, ...props }) => {
  const { settings, main, mainFilter, subFilter, onClick } = props;
  const filterStatus = getQueryKeyStatus({
    category: allQueries?.category,
    woStatus: main?.key,
  });

  const { data: buttonCount } = useGetButtonCount({
    ...allQueries,
    filterStatus,
    yardQueryObject: allQueries?.yardQueryObject,
  });

  const { data: bookingButtonCount } = useGetBookingButtonCount({
    ...allQueries,
    filterStatus,
  });

  return (
    <StyledMainFilterButton
      mainColor={settings?.mainColor}
      subColor={settings?.subColor}
      active={mainFilter?.includes(main?.to)}
      key={main?.header}
    >
      {main?.header}
      <div className="flex flex-row gap-[10px]">
        {main?.children?.map(sub => {
          const toSubFilter = sub?.header
            ?.toLowerCase()
            ?.replace(' #', '')
            ?.replace(' ', '-');
          return (
            <Link
              to={`${main?.to}/${toSubFilter}`}
              onClick={e => {
                e.stopPropagation();
                onClick(toSubFilter);
              }}
              key={`${main?.header}_${sub?.header}`}
            >
              <StyledSubFilterButton
                mainColor={settings?.mainColor}
                subColor={settings?.subColor}
                width={sub?.width}
                main={mainFilter?.includes(main?.to)}
                sub={
                  mainFilter?.includes(main?.to) && toSubFilter === subFilter
                }
                key={`${main?.header}_${sub?.header}`}
              >
                {sub?.header}
                <div className="flex">
                  {setButtonCount({
                    main,
                    sub,
                    data: {
                      booking: { all: bookingButtonCount?.data?.booking },
                      ...buttonCount?.data,
                    },
                  }) || 'Loading...'}
                </div>
              </StyledSubFilterButton>
            </Link>
          );
        })}
      </div>
    </StyledMainFilterButton>
  );
};

export default MainFilterButton;
