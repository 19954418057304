import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import postScanDO from '../../services/Order/Post/postScanDO';
import messageModalOpener from '../../utils/Common/Handler/messageModalOpener';
import handleScanDOResult from '../../utils/Order/Handler/handleScanDOResult';

/**
 * @description
 * Mutation hook for post scan DO
 * @param {object} modalMessageSetter
 * Setter to display in the modal
 * @param {object} setValue
 * Setter of RHF form
 * @param {object} reset
 * Reset RHF form
 * @param {number} containerCount
 * Count of whole containers
 * @param {number} containerKey
 * Key of container
 * @param {object} setContainerCount
 * Setter of container count
 * @param {object} setContainerKey
 * Setter of container key
 * @param {object} setContainerList
 * Setter of container list
 * @param {object} companyOption
 * Company option from API
 * @returns {object}
 * Mutator object
 */
const usePostScanDO = ({
  modalMessageSetter,
  setValue,
  getValues,
  reset,
  containerCount,
  containerKey,
  setContainerCount,
  setContainerKey,
  setContainerList,
  companyOption,
  setDODisabled,
}) => {
  // Modal Open Handler
  const { setMessageModalOpen, setModalMessage } = modalMessageSetter;

  const mutator = useMutation({
    mutationFn: postScanDO,
    onSuccess: ({ data }, variables) => {
      handleScanDOResult({
        result: data,
        setValue,
        getValues,
        reset,
        containerCount,
        containerKey,
        setContainerCount,
        setContainerKey,
        setContainerList,
        companyOption,
        variables,
      });
    },
    onError: error => {
      messageModalOpener({
        opener: setMessageModalOpen,
        messageSetter: setModalMessage,
        message: error?.message,
      });
    },
  });

  useEffect(() => {
    if (mutator?.isPending) setDODisabled(true);
    else setDODisabled(false);
  }, [mutator?.isPending]);

  return { ...mutator };
};

export default usePostScanDO;
