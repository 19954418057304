import { useMutation, useQueryClient } from '@tanstack/react-query';
import acctKeys from '../../keys';
import postMultipleQB from '../../../../services/Acct/Common/Post/postMultipleQB';
import { QB_AUTH_CODE } from '../../../../constants/Acct/Common/errors';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import useControlToast from '../../../Common/Toast/useControlToast';
import { SUCCESS_CONNECT } from '../../../../constants/Common/successMessage';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';

const usePostMultipleQB = ({ errorModalOptions }) => {
  const { setIsSuccess, setErrorMessage, setIsErrorModalOpen } =
    errorModalOptions;

  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  const mutator = useMutation({
    mutationFn: ({ submitData }) => postMultipleQB({ submitData }),
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: response => {
      const errorResponse = response?.data?.find(
        detailResponse => detailResponse?.code,
      );

      const isError = !!errorResponse;

      if (!isError) {
        addToast({ message: SUCCESS_CONNECT });
      } else {
        setIsSuccess(false);
        setIsErrorModalOpen(true);
        setErrorMessage(
          handleQueryError({
            error: { response: { data: errorResponse } },
          }),
        );
        if (errorResponse?.code === QB_AUTH_CODE) {
          window.open(detailResponse?.url, '_blank');
        }
      }
      queryClient?.invalidateQueries({ queryKey: acctKeys?.all });
    },
    onError: error => {
      setIsSuccess(false);
      setIsErrorModalOpen(true);
      setErrorMessage(error?.message);
    },
  });
  return mutator;
};

export default usePostMultipleQB;
