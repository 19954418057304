import React from 'react';
import styled from 'styled-components';
import MuiAccordion from '@mui/material/Accordion';

const StyledTraceGeofenceAccordion = styled(props => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  marginLeft: '20px',
  width: '386px',
  borderLeft: 0,
  borderRight: 0,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

export default StyledTraceGeofenceAccordion;
