import axios from 'axios';
import interceptRequest from '../../../../utils/Common/Interceptor/interceptRequest';

const BCDETAIL_WO_BASE_URL = `${process.env.REACT_APP_BASE_URL}/wo/`;

const bcDetailWoDefaultApi = axios.create({
  withCredentials: true,
  baseURL: BCDETAIL_WO_BASE_URL,
  xsrfHeaderName: 'X-CSRFTOKEN',
  xsrfCookieName: 'XCSRF-TOKEN',
  headers: {
    common: {
      'X-CSRFToken': localStorage.getItem('csrfToken'),
    },
  },
});

/** Interceptors */
interceptRequest(bcDetailWoDefaultApi);

export default bcDetailWoDefaultApi;
