import documentDefaultApi from '../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

const getDocumentList = async ({ woNo }) => {
  try {
    const response = await documentDefaultApi.get(`${woNo}`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getDocumentList;
