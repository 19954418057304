import createCategory from '../../../Master/ServiceCode/Generator/createCategory';

const handleRowDisable = ({ woNo, type }) => {
  const category = createCategory(woNo);
  if (category === 'EXPORT' && type === 'POD') return true;
  if (category !== 'EXPORT' && type === 'POL') return true;
  return false;
};

export default handleRowDisable;
