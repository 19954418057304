import styled from 'styled-components';

const StyledWeeklyTableContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: white;
  padding: 15px 10px;
  overflow-y: scroll;
  gap: 5px;
`;

export default StyledWeeklyTableContentWrapper;
