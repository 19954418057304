import createCustomerReportColDefs from './createCustomerReportColDefs';

/**
 * @description
 * Function that generate customized column
 * @param {array} customizedColumn
 * Customized column from the saved list modal
 * @param {function} setColumnDefs
 * Function that set column definition to the grid
 */
const generateCustomizedColumn = ({ customizedColumn, setColumnDefs }) => {
  const newColumn = customizedColumn?.map(column => {
    // Grouped
    if (column instanceof Object) {
      const groupedColumn = createCustomerReportColDefs?.find(
        item => item?.field === Object?.keys(column)?.[0],
      );
      return {
        ...groupedColumn,
        children: groupedColumn?.children?.filter(child => {
          return column?.[Object?.keys(column)?.[0]]?.includes(child?.field);
        }),
      };
    }

    // Flatted
    return createCustomerReportColDefs?.find(item => item?.field === column);
  });

  setColumnDefs(prev => [...newColumn]);
};

export default generateCustomizedColumn;
