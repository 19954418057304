import dateTimeBothEnteredHandler from '../../../../Common/Handler/dateTimeBothEnteredHandler';
import handleScheduleDateTimeSizeCheck from './handleScheduleDateTimeSizeCheck';

/**
 * @description
 * Function that handles when both date and time are entered
 * @param {object} scheduleData
 * Target schedule data
 * @returns {boolean}
 * Is both date and time entered
 */
const handleScheduleDateTimeBothEnteredCheck = ({
  scheduleData,
  setModalMessage,
}) => {
  /** Get data from schedule data */
  const {
    sche_date: scheDate,
    sche_time: scheTime,
    actual_in_date: acctInDate,
    actual_in_time: acctInTime,
    actual_out_date: acctOutDate,
    actual_out_time: acctOutTime,
  } = scheduleData;

  /** Check Schedule date and time entered */
  if (
    !dateTimeBothEnteredHandler({
      date: scheDate,
      time: scheTime,
    })
  ) {
    setModalMessage('Please input schedule date and time correctly.');
    return false;
  }

  /** Check Actual In date and time entered */
  if (
    !dateTimeBothEnteredHandler({
      date: acctInDate,
      time: acctInTime,
    })
  ) {
    setModalMessage('Please input actual in date and time correctly.');
    return false;
  }

  /** Check Actual Out date and time entered */
  if (
    !dateTimeBothEnteredHandler({
      date: acctOutDate,
      time: acctOutTime,
    })
  ) {
    setModalMessage('Please input actual out date and time correctly.');
    return false;
  }
  return true;
};

export default handleScheduleDateTimeBothEnteredCheck;
