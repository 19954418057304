import { Button } from '@mui/material';
import React, { useState } from 'react';
import CommonBlur from '../../CommonBlur/CommonBlur';
import CommonLoading from '../../CommonLoading/CommonLoading';

const LabsActiveLoading = ({ ...props }) => {
  const [open, setOpen] = useState(false);

  const onButtonClickHandler = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 10000);
  };

  return (
    <>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={onButtonClickHandler}
        >
          Enable Loading
        </Button>
      </div>
      <CommonLoading open={open} />
    </>
  );
};

export default LabsActiveLoading;
