import dayjs from 'dayjs';

const TEXT_COLOR_RED = '#ff0000';
const TEXT_COLOR_PURPLE = '#b000FF';

const FREEDAYS = ['lfd', 'rfd'];
const CHECKLIST = ['hot', 'hazmat', 'over_weight', 'over_height'];

const getCellStyle = params => {
  const fieldName = params?.colDef?.field;
  const fieldValue = params?.value;

  /** Condition Flags */
  const isFreeDays = FREEDAYS.includes(fieldName) && !!fieldValue;
  const isCheckBoxField = CHECKLIST.includes(fieldName);

  if (isFreeDays) {
    const currentDate = dayjs();
    const fieldDate = dayjs(fieldValue);
    const dayDifference = fieldDate.diff(currentDate, 'day', true);

    if (dayDifference < 0) {
      return { color: TEXT_COLOR_RED, fontWeight: 'bold' };
    }
    if (dayDifference <= 1) {
      return { color: TEXT_COLOR_PURPLE, fontWeight: 'bold' };
    }
    return null;
  }

  if (isCheckBoxField) {
    const styledField = params?.colDef?.field?.replace('_', '');
    const fieldColorSet = params?.divColorSet?.[styledField];

    const isActiveColor = fieldColorSet?.active && !!fieldColorSet?.color;

    if (isActiveColor && fieldValue === true) {
      return {
        backgroundColor: fieldColorSet?.color,
      };
    }
    return { backgroundColor: 'transparent' };
  }

  return null;
};

export default getCellStyle;
