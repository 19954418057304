import React from 'react';
import CommonCheckbox from '../../../components/Inputs/CommonCheckbox/CommonCheckbox';

const getCheckboxComponent = ({ ...props }) => {
  const commonProperty = {
    disabled: props?.disabled,
    required: props?.required,
    labelStyle: props?.labelStyle,
    borderVisible: props?.borderVisible,
    onChangeHandler: ({ e, onChange, ...handlerProps }) => {
      onChange(e?.target?.checked);
    },
    options: [
      {
        key: 0,
        inputName: 'checkbox00',
        optionLabel: 'HAZ',
        defaultValue: false,
      },
      {
        key: 1,
        inputName: 'checkbox01',
        optionLabel: 'HAZ',
        defaultValue: false,
      },
      {
        key: 2,
        inputName: 'checkbox02',
        optionLabel: 'HAZ',
        defaultValue: false,
      },
      {
        key: 3,
        inputName: 'checkbox03',
        optionLabel: 'HAZ',
        defaultValue: false,
      },
      {
        key: 4,
        inputName: 'checkbox04',
        optionLabel: 'HAZ',
        defaultValue: false,
      },
    ],
  };

  const inputProperty = [
    {
      key: 0,
      label: 'Checkbox Label 100%',
      inputName: 'checkbox00',
      width: 'w-full',
      ...commonProperty,
    },
  ];

  return inputProperty?.map(element => (
    <CommonCheckbox
      key={element?.key}
      {...element}
      labelStyle={props?.labelStyle && 'row'}
      alignCenter
      // helpTextVisible={false}
    />
  ));
};
export default getCheckboxComponent;
