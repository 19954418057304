import styled from 'styled-components';

const StyledTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 330px;
  border: 1px solid #eeeeee;
  border-collapse: collapse;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-color: #222222;

  .content {
    flex-direction: column;
    width: 580px;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #ffffff;
  }

  .row {
    display: flex;
    width: 580px;
    height: 30px;
  }

  .row-header {
    background-color: #f9f9f9;
    border-bottom: 1px solid #eeeeee;
  }

  .row-content {
    border-bottom: 1px solid #eeeeee;
    &:last-child {
      border-bottom: none;
    }
  }

  .cell {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 30px;
    &:first-child {
      width: 240px;
      border-right: 1px solid #eeeeee;
    }
    &:last-child {
      width: 340px;
    }
  }
`;

export default StyledTableContainer;
