import adminConfKeys from '../../../hooks/Admin/Conf/keys';
import createDIVColumnDefs from '../../../utils/Admin/Conf/Generator/createDIVColumnDefs';
import createPoolColumnDefs from '../../../utils/Admin/Conf/Generator/createPoolColumnDefs';
import createSizeColumnDefs from '../../../utils/Admin/Conf/Generator/createSizeColumnDefs';
import createSSLColumnDefs from '../../../utils/Admin/Conf/Generator/createSSLColumnDefs';
import createTypeColumnDefs from '../../../utils/Admin/Conf/Generator/createTypeColumnDefs';

export const ADMIN_CONF_KEY_VALUES = {
  division: adminConfKeys?.confDIV,
  ssl: adminConfKeys?.confSSL,
  pool: adminConfKeys?.confPool,
  size: adminConfKeys?.confSize,
  type: adminConfKeys.confType,
};

export const ADMIN_CONF_ACTIVE_KEY_VALUES = {
  division: adminConfKeys?.confDIVList,
  ssl: adminConfKeys?.confSSLList,
  pool: adminConfKeys?.confPoolList,
  size: adminConfKeys?.confSizeList,
  type: adminConfKeys.confTypeList,
};

export const ADMIN_CONF_COLUMN_DEFINITION_VALUES = {
  division: createDIVColumnDefs,
  ssl: createSSLColumnDefs,
  pool: createPoolColumnDefs,
  size: createSizeColumnDefs,
  type: createTypeColumnDefs,
};
