import dayjs from 'dayjs';
import ConfirmCellRenderer from '../../../../components/MobileAdmin/BillConfirm/CellRenderer/ConfirmCellRenderer';
import StatusCellRenderer from '../../../../components/MobileAdmin/BillConfirm/CellRenderer/StatusCellRenderer';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';
import { CELL_TYPE_BUTTON } from '../../../../constants/Common/DataGrid/cellType';
import FloatingFilterSelect from '../../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterSelect';
import { BILL_CONFIRM_STATUS_OPTION } from '../../../../constants/MobileAdmin/BillConfirm/floatingFilterSelectOptions';
import FloatingFilterInput from '../../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterInput';
import dateComparator from '../../../Common/Datagrid/Comparator/dateComparator';

const createBillConfirmColumnDefs = ({ ...props }) => {
  return [
    {
      headerName: 'WO #',
      field: 'wo_no',
      width: 100,
      editable: false,
    },
    {
      headerName: 'EQUIPMENT',
      field: 'equip_no',
      width: 90,
      editable: false,
    },
    {
      headerName: 'DRIVER',
      field: 'driver',
      width: 72,
      editable: false,
    },
    {
      headerName: 'ORIG',
      field: 'from_location',
      width: 72,
      editable: false,
    },
    {
      headerName: 'DEST',
      field: 'destination',
      valueGetter: params => {
        return params?.data?.live_dilv_location
          ? params?.data?.live_dilv_location
          : params?.data?.to_location;
      },
      width: 72,
      editable: false,
    },
    {
      headerName: 'RTN',
      field: 'return',
      valueGetter: params => {
        return params?.data?.live_dilv_location
          ? params?.data?.to_location
          : null;
      },
      width: 72,
      editable: false,
    },
    {
      headerName: 'ACCT CODE',
      field: 'acct_code',
      width: 90,
      editable: false,
    },
    {
      headerName: 'ACCT CODE NAME',
      field: 'acct_code_name',
      width: 140,
      editable: false,
    },
    {
      headerName: 'AMT',
      field: 'amt',
      width: 85,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          customStyle: {
            textAlign: 'right',
          },
          adornment: {
            position: 'start',
            content: '$',
          },
        }),
    },
    {
      headerName: 'DESC',
      field: 'description',
      width: 120,
      editable: false,
    },
    {
      headerName: 'POD FILE',
      field: 'file_url',
      floatingFilter: false,
      sortable: false,
      editable: false,
      width: 75,
      cellRendererSelector: gridParam => {
        if (gridParam?.data?.file_url) {
          return cellRendererGenerator({
            gridParam,
            cellType: CELL_TYPE_BUTTON,
            content: 'PDF',
            category: 'pdf',
            customStyle: {
              size: 'small',
            },
            onClickHandler: () => {
              window.open(gridParam?.data?.file_url, '_blank');
            },
          });
        }
        return null;
      },
      preventClickCell: true,
    },
    {
      headerName: 'STATUS',
      field: 'confirm_status',
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: BILL_CONFIRM_STATUS_OPTION,
      },
      cellRenderer: StatusCellRenderer,
      width: 75,
      editable: false,
    },
    {
      headerName: 'DATE',
      width: 62,
      cellClass: 'date',
      editable: false,
      field: 'confirm_date',
      comparator: dateComparator,
      valueGetter: param =>
        param?.data?.confirm_date
          ? dayjs(param?.data?.confirm_date).format('MM/DD/YY')
          : null,
      floatingFilterComponent: FloatingFilterInput,
      floatingFilterComponentParams: {
        inputType: 'date',
        maxLength: 8,
        placeholder: 'MM/DD/YY',
      },
    },
    {
      headerName: 'CONFIRM',
      field: 'confirm',
      editable: false,
      width: 175,
      cellRenderer: ConfirmCellRenderer,
      cellRendererParams: {
        ...props,
      },
      floatingFilter: false,
      sortable: false,
      resizable: false,
      suppressMovable: true,
      preventClickCell: true,
    },
  ];
};

export default createBillConfirmColumnDefs;
