import checkDateDiff from '../../../Common/Handler/checkDateDiff';

const searchQBContent = ({
  handleSubmit,
  errorModalOptions,
  setQueryObject,
  setIsOpenModal,
}) => {
  const { setIsSuccess, setErrorMessage, setIsErrorModalOpen } =
    errorModalOptions;

  handleSubmit(submitData => {
    if (
      !checkDateDiff({
        fromDate: submitData?.from_date,
        toDate: submitData?.to_date,
      })
    ) {
      setIsSuccess(false);
      setErrorMessage(
        'The date range cannot exceed one year. Please try again.',
      );
      setIsErrorModalOpen(true);
      return;
    }

    const filteredSubmitData = {};

    Object?.keys?.(submitData)?.forEach(key => {
      if (submitData[key]) {
        filteredSubmitData[key] = submitData[key];
      }
    });

    setQueryObject(filteredSubmitData);
    setIsOpenModal(false);
  })();
};

export default searchQBContent;
