import handleQueryError from '../../../utils/Common/Handler/handleQueryError';
import cusReportDefaultUrl from '../api';

/**
 * @description
 * API that gets the main data for the customer report
 * @param {string} division
 * Current division
 * @returns {object}
 * Response data
 */
const getMainData = async ({ div, ...params }) => {
  try {
    const response = await cusReportDefaultUrl.get(`main`, {
      params: {
        div,
        ...params,
      },
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getMainData;
