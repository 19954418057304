import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  memo,
  useEffect,
} from 'react';
import { TextField } from '@mui/material';
import StyledEditCellRenderer from '../../../../../styles/Common/CommonDatagrid/EditCellRenderer/StyledEditCellRenderer';
import adornmentContentGenerator from '../../../../../utils/Common/Datagrid/Generator/adornmentContentGenerator';

/**
 * @description
 * Text modification cell renderer
 * @param {object} props
 * Grid props
 * @param {object} ref
 * Grid reference
 */
const GridTextCell = forwardRef((props, ref) => {
  /** Get grid parameter */
  const {
    setValue,
    adornment,
    regex,
    maxLength: inputMaxLength,
    onChangeHandler,
    isCancelBeforeStartHandler,
    onValueChange,
    customStyle,
  } = props;

  /** Input inner component reference */
  const compRef = useRef(null);
  const compInputRef = useRef(null);

  /** Input component property */
  const [value, setDataValue] = useState(props?.value || '');
  const [maxLength, setMaxLength] = useState(inputMaxLength || 20);
  const [disabled, setDisabled] = useState(props?.disabled || false);

  /** Component data setter */
  const compDataSetter = {
    setDataValue,
    setMaxLength,
    setDisabled,
  };

  /**
   * Component Editor Lifecycle methods
   * @see https://www.ag-grid.com/react-data-grid/component-cell-editor/
   */
  useImperativeHandle(ref, () => {
    return {
      /** The final value to send to the grid, on completion of editing */
      getValue() {
        return value;
      },
      isCancelBeforeStart() {
        isCancelBeforeStartHandler?.({ ...props, ...compDataSetter });
        return false;
      },
      isCancelAfterEnd() {
        return false;
      },
      ref,
      compRef,
      compInputRef,
      ...compDataSetter,
      ...props,
    };
  });

  /** Render component */
  return (
    <StyledEditCellRenderer>
      <TextField
        ref={compRef}
        inputRef={compInputRef}
        value={value || ''}
        onChange={e => {
          if (e) {
            const getChangedValue = e?.target?.value;
            if (regex && !getChangedValue.match(regex)) return;
            setValue?.(getChangedValue);
            setDataValue?.(getChangedValue);
            onValueChange?.(getChangedValue);
            onChangeHandler?.({ getChangedValue, ...props });
          }
        }}
        InputProps={{
          startAdornment:
            adornment?.position === 'start' &&
            adornmentContentGenerator({ adornment }),
          endAdornment:
            adornment?.position === 'end' &&
            adornmentContentGenerator({ adornment }),
          inputProps: {
            maxLength,
            autoComplete: 'off',
            placeholder: props?.placeHolder || '',
          },
        }}
        disabled={disabled}
        /** Component Style */
        className={`border-solid border-[#D9D9D9] rounded-[5px] text-xs text-black w-[164px] h-[24px]
                    hover:border-[#8E9396] ${
                      disabled ? `bg-[#F9F9F9]` : `bg-white`
                    }`}
        sx={{
          input: {
            zIndex: '999',
            paddingLeft: '2px !important',
            paddingRight: '2px !important',
            textAlign: customStyle?.textAlign || 'none',
          },
          '&.MuiTextField-root': {
            width: '100% !important',
          },
          '.MuiInputBase-root': {
            lineHeight: 'normal !important',
            height: '100%',
            fontSize: '12px',
            fontWeight: '500',
            '&.Mui-focused fieldset': {
              border: '1.2px solid #264B9F !important',
              boxShadow: '0px 0px 4px 0px #8BBCE9 !important',
            },
            '&.Mui-error fieldset': {
              border: '1.2px solid #C24D4D !important',
            },
            '&:not(.Mui-focused):not(.Mui-disabled):hover fieldset': {
              borderColor: '#8E9396 !important',
            },
            '&.Mui-disabled fieldset': {
              backgroundColor: 'unset !important',
            },
            '.MuiInputBase-input.MuiOutlinedInput-input': {
              border: 'none !important',
            },
            '&.MuiInputBase-adornedStart': {
              paddingLeft: '2px !important',
            },
            '&.MuiInputBase-adornedEnd': {
              paddingRight: '2px !important',
            },
            '.MuiTypography-root': {
              lineHeight: 'normal !important',
            },
          },
          '.MuiTypography-root': {
            fontSize: '12px',
          },
          fieldset: {
            borderColor: '#D9D9D9 !important',
            backgroundColor: `#fff !important`,
          },
        }}
      />
    </StyledEditCellRenderer>
  );
});

export default GridTextCell;
