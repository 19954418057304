const convertBoolToString = obj => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (typeof value === 'boolean') {
      acc[key] = value ? 'Y' : 'N';
    } else {
      acc[key] = value;
    }
    return acc;
  }, {});
};

export default convertBoolToString;
