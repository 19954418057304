export const WO_CATEGORY_CODE = {
  WC0001: 'IMPORT',
  WC0003: 'EXPORT',
  WC0005: 'VAN',
  WC0006: 'BROKERAGE',
  WC0007: 'BARE CHASSIS',
};

export const WO_CATEGORY_NAME = {
  IMPORT: 'WC0001',
  EXPORT: 'WC0003',
  VAN: 'WC0005',
  BROKERAGE: 'WC0006',
  BARE_CHASSIS: 'WC0007',
};
