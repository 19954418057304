import { useFormContext, useWatch } from 'react-hook-form';

/**
 * @description
 * Check driver assign is enable or not
 * @param {object} scheduleData
 * Schedule data for this row
 * @returns {boolean}
 * Return true if driver assign is disable
 */
const handleDriverAssignDisable = ({ ...props }) => {
  const { getValues } = useFormContext();

  /** Get data */
  const {
    scheduleData,
    scheduleType,
    locationInfo,
    jobData,
    frDataKey,
    ldDataKey,
    toDataKey,
  } = props;

  /** Get schedule data */
  const {
    sche_date: scheDate,
    sche_time: scheTime,
    type,
    ordering,
    status,
  } = scheduleData;

  /** If location info is not from(fr), driver should not be able to assigned */
  if (locationInfo !== 'fr') return true;

  /** Disable assign when schedule date or schedule time is not entered in job_set data */
  if (locationInfo !== 'location') {
    const {
      from_location: tempFrLoc,
      live_dilv_location: tempLdLoc,
      to_location: tempToLoc,
      driver,
    } = jobData;

    const frLoc = { ...tempFrLoc, location_info: 'fr' };
    const ldLoc = { ...tempLdLoc, location_info: 'ld' };
    const toLoc = { ...tempToLoc, location_info: 'to' };

    const frScheDate = useWatch({
      name: `sche_date_${frDataKey}`,
      defaultValue: frLoc?.sche_date,
    });
    const frScheTime = useWatch({
      name: `sche_time_${frDataKey}`,
      defaultValue: frLoc?.sche_time,
    });

    const ldScheDate = useWatch({
      name: `sche_date_${ldDataKey}`,
      defaultValue: ldLoc?.sche_date,
    });
    const ldScheTime = useWatch({
      name: `sche_time_${ldDataKey}`,
      defaultValue: ldLoc?.sche_time,
    });

    const toScheDate = useWatch({
      name: `sche_date_${toDataKey}`,
      defaultValue: toLoc?.sche_date,
    });
    const toScheTime = useWatch({
      name: `sche_time_${toDataKey}`,
      defaultValue: toLoc?.sche_time,
    });

    const baseOrder = [frLoc, ldLoc, toLoc]?.filter(loc => loc?.ordering === 0);

    /** When base location is exist just one */
    if (baseOrder?.length === 1) {
      const baseLoc = [frLoc, ldLoc, toLoc]?.find(loc => loc?.ordering === 0);
      if (baseLoc) {
        const baseLocLocationInfo = baseLoc?.location_info;

        if (
          baseLocLocationInfo === 'fr' &&
          !driver &&
          (!frScheDate || !frScheTime)
        )
          return true;
        if (
          baseLocLocationInfo === 'ld' &&
          !driver &&
          (!ldScheDate || !ldScheTime)
        )
          return true;
        if (
          baseLocLocationInfo === 'to' &&
          !driver &&
          (!toScheDate || !toScheTime)
        )
          return true;
      }
    }

    /** When base location is exist more than one */
    if (baseOrder?.length > 1 || baseOrder?.length === 0) {
      let isBaseLocScheDateTimeExist = false;

      [frLoc, ldLoc, toLoc]?.forEach(loc => {
        if (loc) {
          if (
            loc?.location_info === 'fr' &&
            (driver || (frScheDate && frScheTime))
          )
            isBaseLocScheDateTimeExist = true;
          if (
            loc?.location_info === 'ld' &&
            (driver || (ldScheDate && ldScheTime))
          )
            isBaseLocScheDateTimeExist = true;
          if (
            loc?.location_info === 'to' &&
            (driver || (toScheDate && toScheTime))
          )
            isBaseLocScheDateTimeExist = true;
        }
      });

      if (!isBaseLocScheDateTimeExist) return true;
    }
  }

  /** Disable assign when type is DILV or RTN and ordering 0 */
  if ((type === 'DILV' || type === 'RTN') && ordering === 0) {
    return true;
  }

  /** Disable assign when status is Complete */
  if (status === 'Complete') {
    return true;
  }

  /** Disable assign when schedule type is location and type is P/U or DILV */
  if (scheduleType === 'location' && (type === 'P/U' || type === 'DILV')) {
    return true;
  }

  /** Enable assign driver */
  return false;
};

export default handleDriverAssignDisable;
