import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import saveOrder from '../../../utils/Order/Handler/saveOrder';
import CommonBlur from '../../CommonBlur/CommonBlur';
import CommonButton from '../../CommonButton/CommonButton';
import CommonMessageModal from '../../CommonMessageModal/CommonMessageModal';
import ClearModalButtonContent from '../CommonOrderModal/ClearModal/ClearModalButtonContent';
import ClearModalContent from '../CommonOrderModal/ClearModal/ClearModalContent';
import OrderBottomInfo from './OrderBottomInfo';
import OrderMiddleInfo from './OrderMiddleInfo';
import OrderTopInfo from './OrderTopInfo';

const CommonOrderInfo = ({
  subCategory,
  currDiv,
  setContainerList,
  setContainerCount,
  commonOption,
  companyOption,
  bookingNumber,
  containerList,
  modalMessageSetter,
  orderPostMutator,
  setBookingSequence,
  doDisabled,
  isLoadingBookingNumber,
  isLoadingCompanyOption,
  ...props
}) => {
  const {
    handleSubmit,
    formState: { isValid },
  } = useFormContext();
  const [clearModal, setClearModal] = useState(false);

  return (
    <>
      <div className="w-[706px] h-[844px] min-h-[844px] bg-[#FFF] relative">
        <OrderTopInfo
          subCategory={subCategory}
          commonOption={commonOption}
          companyOption={companyOption}
          bookingNumber={bookingNumber}
          currDiv={currDiv}
          setBookingSequence={setBookingSequence}
          doDisabled={doDisabled}
          isLoadingBookingNumber={isLoadingBookingNumber}
          isLoadingCompanyOption={isLoadingCompanyOption}
        />
        {/* Parallel Lines */}
        <div className="w-[706px] min-w-[706px] h-[1px] min-h-[1px] bg-[#878d9bcc]" />
        <OrderMiddleInfo subCategory={subCategory} />
        {/* Parallel Lines */}
        <div className="w-[706px] min-w-[706px] h-[1px] min-h-[1px] bg-[#878d9bcc]" />
        <OrderBottomInfo
          subCategory={subCategory}
          companyOption={companyOption}
          isLoadingCompanyOption={isLoadingCompanyOption}
        />
        {/* Button area */}
        <div className="flex justify-center items-center w-[706px] h-[85px] min-h-[85px] absolute bottom-0">
          <div className="flex justify-center items-center gap-[20px]">
            <CommonButton
              category="clear"
              onClick={() => {
                setClearModal(true);
              }}
            >
              Clear
            </CommonButton>
            <CommonButton
              type="button"
              category="save"
              preventDupClick
              onClick={({ e, buttonRef }) => {
                saveOrder({
                  e,
                  subCategory,
                  currDiv,
                  handleSubmit,
                  containerList,
                  modalMessageSetter,
                  orderPostMutator,
                  buttonRef,
                  isValid,
                });
              }}
            >
              Save
            </CommonButton>
          </div>
        </div>
      </div>
      {/* Modal of Clear contents */}
      <CommonBlur open={clearModal}>
        <CommonMessageModal
          open={clearModal}
          setOpen={setClearModal}
          header="Confirmation"
          content={<ClearModalContent />}
          buttonContent={
            <ClearModalButtonContent
              setClearModal={setClearModal}
              setContainerList={setContainerList}
              setContainerCount={setContainerCount}
              setBookingSequence={setBookingSequence}
            />
          }
        />
      </CommonBlur>
    </>
  );
};

export default CommonOrderInfo;
