import React from 'react';
import CommonButton from '../../../CommonButton/CommonButton';
import saveItem from '../../../../utils/CustomerReport/Handler/saveItem';

const SaveItemButton = ({ ...props }) => {
  /** Data */
  const {
    listName,
    email,
    div,
    searchParams,
    columnDefs,
    isSaveEnable,
    postMainMutate,
  } = props;

  /** Data Setter */
  const {
    setOpen,
    setMessageOpener,
    setIsSuccess,
    setModalMessage,
    setEmailConfirmationOpener,
  } = props;

  return (
    <div className="w-full flex flex-row gap-[20px] justify-center items-center">
      <CommonButton category="cancel" onClick={() => setOpen(false)}>
        Cancel
      </CommonButton>
      <CommonButton
        category="save"
        onClick={() =>
          saveItem({
            listName,
            email,
            setMessageOpener,
            setIsSuccess,
            setModalMessage,
            div,
            searchParams,
            columnDefs,
            postMainMutate,
            setEmailConfirmationOpener,
          })
        }
        disabled={!isSaveEnable}
      >
        Save
      </CommonButton>
    </div>
  );
};

export default SaveItemButton;
