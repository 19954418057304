import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import getSavedList from '../../services/CustomerReport/Get/getSavedList';
import customerReportKeys from './keys';

/**
 * @description
 * Custom hook to get saved list
 * @param {string} div
 * Division
 * @returns {object}
 * Query result and saved list data
 */
const useGetSavedList = ({ div }) => {
  /** Saved list data */
  const [savedList, setSavedList] = useState(null);

  /** Query */
  const query = useQuery({
    queryKey: customerReportKeys.savedList({ div }),
    queryFn: () => getSavedList({ div }),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (query?.data?.data) {
      setSavedList(query?.data?.data);
    }
  }, [query?.data?.data]);

  return { ...query, savedList };
};

export default useGetSavedList;
