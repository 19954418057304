const mobileAdminKeys = {
  all: ['MOBILE_ADMIN'],
  podCheckList: ({ division }) => [
    ...mobileAdminKeys.all,
    'POD_CHECK_LIST',
    division,
  ],
  billConfirm: ({ division }) => [
    ...mobileAdminKeys.all,
    'BILL_CONFIRM',
    division,
  ],
  manifest: ({ division }) => [...mobileAdminKeys.all, 'MANIFEST', division],
  manifestQueryString: ({ division, queryObject }) => [
    ...mobileAdminKeys.manifest({ division }),
    queryObject,
  ],
};

export default mobileAdminKeys;
