import React, { useEffect, useState } from 'react';
import CommonMessageModal from '../../../../../CommonMessageModal/CommonMessageModal';
import ScheduleMobileModalContent from './ScheduleMobileModalContent';
import ScheduleMobileModalButtonContent from './ScheduleMobileModalButtonContent';
import useGetMobileMemo from '../../../../../../hooks/BCDetail/DetailInfo/Schedule/useGetMobileMemo';
import CommonLoading from '../../../../../CommonLoading/CommonLoading';
import usePatchMemoContent from '../../../../../../hooks/BCDetail/Memo/usePatchMemoContent';

const ScheduleMobileModal = ({ ...props }) => {
  /** Get modal opener */
  const { open, setOpen } = props;
  /** Error modal opener */
  const { setMessageModalOpen, setMessageContent } = props;
  const modalProps = {
    setMessageModalOpen,
    setMessageContent,
  };
  /** Get data */
  const { selectedRowData, isEditable } = props;
  /** Get data setter */
  const { setSelectedRowData } = props;

  /** Get mobile memo data */
  const { mobileMemoData, isFetching: isGetMobileMemoFetching } =
    useGetMobileMemo({
      open,
      seq: selectedRowData?.scheduleSeq,
      setMobileModalOpener: setOpen,
      setSelectedRowData,
      ...modalProps,
    });

  /** Patch mobile memo data */
  const { mutate: patchMemoContentMutate } = usePatchMemoContent({
    setMobileModalOpener: setOpen,
    ...modalProps,
  });

  /** Memo content */
  const [memoContent, setMemoContent] = useState(
    mobileMemoData?.memo?.content || '',
  );
  useEffect(() => {
    setMemoContent(prev => mobileMemoData?.memo?.content || '');
  }, [mobileMemoData?.memo?.content]);

  /** Set memo content to empty when modal closed */
  useEffect(() => {
    return () => {
      setMemoContent('');
    };
  }, []);

  /** Display loading component when memo data fetching */
  if (isGetMobileMemoFetching)
    return <CommonLoading open={isGetMobileMemoFetching} />;

  /** Render mobile memo modal */
  return (
    <CommonMessageModal
      open={open}
      setOpen={setOpen}
      header="MEMO(MOBILE)"
      width="743px"
      content={
        <ScheduleMobileModalContent
          mobileMemoData={mobileMemoData}
          memoContent={memoContent}
          setMemoContent={setMemoContent}
          isEditable={isEditable}
        />
      }
      buttonContent={
        isEditable && (
          <ScheduleMobileModalButtonContent
            setOpen={setOpen}
            setMemoContent={setMemoContent}
            patchMemoContentMutate={patchMemoContentMutate}
            content={memoContent}
            scheduleSeq={selectedRowData?.scheduleSeq}
          />
        )
      }
    />
  );
};

export default ScheduleMobileModal;
