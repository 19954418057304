import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import getAPInput from '../../../../services/BCDetail/DetailInfo/Schedule/Get/getAPInput';
import bcDetailKeys from '../../keys';

const useGetDualAPInput = ({ ...props }) => {
  const { open, apModalController, rtnJob, messageModalController } = props;
  const { selectedJob, setAPModalOpen } = apModalController;
  const { setMessageModalOpen, setMessageModalMessage } =
    messageModalController;

  const [apData, setAPData] = useState(null);

  const query = useQuery({
    queryKey: bcDetailKeys?.dualAPInput({
      woNo: selectedJob?.wo_no,
      scheduleSeq: selectedJob?.job_seq,
      driver: selectedJob?.driver || rtnJob?.driver || null,
    }),
    queryFn: () =>
      getAPInput({
        woNo: selectedJob?.wo_no,
        scheduleSeq: selectedJob?.job_seq,
        driver: selectedJob?.driver || rtnJob?.driver || null,
        dual: Boolean(selectedJob?.driver),
      }),
    refetchOnWindowFocus: false,
    enabled: !!open,
    retry: false,
  });

  useEffect(() => {
    if (query?.data) {
      setAPData(prev => query?.data?.data);
    } else {
      setAPData(prev => null);
    }
  }, [query?.data]);

  useEffect(() => {
    if (query?.isError) {
      setAPModalOpen(prev => false);
      setMessageModalMessage(prev => query?.error?.message);
      setMessageModalOpen(prev => true);
    }
  }, [query?.isError]);

  return { apData, ...query };
};

export default useGetDualAPInput;
