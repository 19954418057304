import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CommonErrorModal from '../CommonErrorModal/CommonErrorModal';
import CommonMessageModal from '../CommonMessageModal/CommonMessageModal';
import CustomizeColsModalButtonContent from './CustomizeColsModalButtonContent';
import CustomizeColsModalContent from './CustomizeColsModalContent';
import generateInitOrderList from '../../utils/Dispatch/Common/Generator/generateInitOrderList';
import generateAppliedColumnList from '../../utils/Dispatch/Common/Generator/generateAppliedColumnList';

const CommonCustomColumnModal = ({
  openModal,
  setOpenModal,
  status,
  columnItems,
  selectedItems,
  onApplyClick,
  ...props
}) => {
  const columnController = useForm();

  /** Ordering list handler */
  const [checkOrderList, setCheckOrderList] = useState([]);

  /** Data patcher */
  const [appliedColumnList, setAppliedColumnList] = useState([]);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const initOrderList = generateInitOrderList({
      selectedItems: selectedItems || [],
      columnItems: columnItems || [],
    });
    setCheckOrderList(initOrderList);
  }, [selectedItems, columnItems]);

  useEffect(() => {
    setAppliedColumnList(generateAppliedColumnList({ checkOrderList }));
  }, [checkOrderList]);

  return (
    <>
      <FormProvider {...columnController}>
        <form>
          <CommonMessageModal
            open={openModal}
            setOpen={setOpenModal}
            header="Customize Columns"
            width="664px"
            height="560px"
            content={
              <CustomizeColsModalContent
                status={status}
                checkOrderList={checkOrderList}
                setCheckOrderList={setCheckOrderList}
              />
            }
            buttonContent={
              <CustomizeColsModalButtonContent
                setOpenModal={setOpenModal}
                onApplyClick={onApplyClick}
                appliedColumnList={appliedColumnList}
                setErrorModalOpen={setErrorModalOpen}
                setErrorMessage={setErrorMessage}
              />
            }
          />
        </form>
      </FormProvider>
      <CommonErrorModal
        open={errorModalOpen}
        setIsOpen={setErrorModalOpen}
        message={errorMessage}
      />
    </>
  );
};

export default CommonCustomColumnModal;
