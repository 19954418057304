import {
  CELL_TYPE_TEXT,
  CELL_TYPE_AUTO_COMPLETE,
} from '../../../../constants/Common/DataGrid/cellType';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';
import cellEditorGenerator from '../../../Common/Datagrid/Generator/cellEditorGenerator';
import {
  handleRendererPairChange,
  handleEditorPairChange,
} from '../Handler/pairChangeHandler';

const createServiceCodeColumnDefs = ({ pairOption, isEditable }) => {
  return [
    {
      headerName: 'RELATE ACCT CODE',
      field: 'acct_code',
      valueGetter: props => props?.data?.acct_code?.toString(),
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_AUTO_COMPLETE,
          option: pairOption,
          onChangeHandler: handleRendererPairChange,
          pair: 'acct_code_name',
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_AUTO_COMPLETE,
          option: pairOption,
          onChangeHandler: handleEditorPairChange,
          pair: 'acct_code_name',
        }),
      width: 150,
      editable: isEditable,
    },
    {
      headerName: 'RELATE ACCT CODE NAME',
      field: 'acct_code_name',
      width: 210,
      valueGetter: props => props?.data?.acct_code_name?.trim(),
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_AUTO_COMPLETE,
          option: pairOption,
          onChangeHandler: handleRendererPairChange,
          pair: 'acct_code',
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_AUTO_COMPLETE,
          option: pairOption,
          onChangeHandler: handleEditorPairChange,
          pair: 'acct_code',
        }),
      editable: isEditable,
    },
    {
      headerName: 'SERVICE CODE',
      field: 'product_code',
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          maxLength: 10,
        }),
      width: 110,
      editable: false,
    },
    {
      headerName: 'SERVICE CODE NAME',
      field: 'product_code_name',
      cellRendererSelector: gridParam =>
        cellRendererGenerator({ gridParam, cellType: CELL_TYPE_TEXT }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
        }),
      editable: isEditable,
    },
  ];
};

export default createServiceCodeColumnDefs;
