export const QB_STATUS_OBJECT_LIST = [
  { label: 'Not Sent', value: 'QS0001' },
  { label: 'Accepted', value: 'QS0002' },
  { label: 'Error', value: 'QS0003' },
  { label: 'Locked', value: 'QS0004' },
];

export const QB_AP_AR_OPTION_LIST = [
  { label: 'Not Sent', value: 'QS0001' },
  { label: 'Accepted', value: 'QS0002' },
  { label: 'Error', value: 'QS0003' },
];

export const AP_AR_RECONCILE_OPTION_LIST = [
  { label: 'Accepted', value: 'QS0002' },
  { label: 'Locked', value: 'QS0004' },
];

export const QB_STATUS_OBJECT = {
  'Not Sent': 'QS0001',
  Accepted: 'QS0002',
  Error: 'QS0003',
  Locked: 'QS0004',
};

export const QB_STATUS = [
  { value: 'QS0001', label: 'QS0001' },
  { value: 'QS0002', label: 'QS0002' },
  { value: 'QS0003', label: 'QS0003' },
  { value: 'QS0004', label: 'QS0004' },
];

export const CATEGORY = ['CUSTOMER', 'VENDOR', 'DRIVER'];

export const TYPE = ['AP', 'AR'];

export const RECONCILE_STATUS = [
  { label: 'Waiting', value: 'RS0001' },
  { label: 'Review', value: 'RS0002' },
  { label: 'Disputed', value: 'RS0003' },
  { label: 'Completed', value: 'RS0004' },
  { label: 'Denied', value: 'RS0005' },
];

export const DATAGRID_RECONCILE_STATUS = [
  { label: 'Waiting', value: 'Waiting' },
  { label: 'Review', value: 'Review' },
  { label: 'Disputed', value: 'Disputed' },
  { label: 'Completed', value: 'Completed' },
  { label: 'Denied', value: 'Denied' },
];

export const DRIVER_CATEGORY = {
  COMPANY: 'DR0001',
  OWNER: 'DR0002',
  CARRIER: 'DR0003',
};
