/**
 * @description
 * Function that handles when both date and time are entered
 * @param {string | object} date
 * Date data
 * @param {string | object} time
 * Time data
 */
const dateTimeBothEnteredHandler = ({ date, time }) => {
  if ((date && !time) || (!date && time)) return false;
  return true;
};

export default dateTimeBothEnteredHandler;
