import React from 'react';
import AcctTableCell from '../AcctTableCell';

const QBAPARDetailTableCell = ({ ...props }) => {
  const { rowData, headers } = props;

  return (
    <div>
      {rowData?.detail?.map((item, index) => (
        <div
          key={String(index + 1)}
          className="flex items-center gap-x-[2px] py-[1px]"
        >
          {headers?.map((header, headerIndex) => (
            <AcctTableCell
              key={String(headerIndex + 1)}
              header={header?.field}
              value={item?.[header?.field]}
              width={header?.width}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default QBAPARDetailTableCell;
