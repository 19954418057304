import FloatingFilterSelect from '../../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterSelect';
import {
  CATEGORY,
  QB_AP_AR_OPTION_LIST,
  QB_STATUS_OBJECT_LIST,
  TYPE,
} from '../../../../constants/Acct/Common/acctElement';
import { CELL_TYPE_TEXT } from '../../../../constants/Common/DataGrid/cellType';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';
import {
  generateConstantOptionToFloatingOption,
  generateListToOption,
} from '../../Common/Generator/floatingFilterSelectOptionGenerator';
import {
  generateDateValue,
  generateQBStatusValue,
} from '../../Common/Generator/dataGridValueGetter';
import FloatingFilterInput from '../../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterInput';
import { CELL_CUSTOM_STYLE } from '../../../../styles/Acct/columnStyles';
import dateComparator from '../../../Common/Datagrid/Comparator/dateComparator';

const createQBAPARColumnDefs = ({ divList }) => {
  return [
    {
      headerName: 'DIV',
      field: 'div',
      width: 38,
      editable: false,
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: generateListToOption(divList?.map(div => div?.div)),
      },
    },
    {
      headerName: 'TYPE',
      field: 'type',
      width: 50,
      editable: false,
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: generateListToOption(TYPE),
      },
    },
    {
      headerName: 'ID',
      field: 'business_id',
      editable: false,
      width: 72,
    },
    {
      headerName: 'NAME',
      field: 'name',
      editable: false,
      width: 160,
    },
    {
      headerName: 'STATUS',
      field: 'status',
      editable: false,
      width: 64,
      valueGetter: param => {
        return generateQBStatusValue(
          param?.data?.status,
          QB_STATUS_OBJECT_LIST,
        );
      },
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: generateConstantOptionToFloatingOption(QB_AP_AR_OPTION_LIST),
      },
    },
    {
      headerName: 'WO #',
      field: 'wo_no',
      width: 100,
      editable: false,
    },
    {
      headerName: 'EQUIPMENT',
      field: 'equip_no',
      width: 90,
      editable: false,
    },
    {
      headerName: 'DATE',
      field: 'inv_date',
      editable: false,
      width: 62,
      cellClass: 'date',
      comparator: dateComparator,
      valueGetter: param => {
        return generateDateValue(param?.data?.inv_date);
      },
      floatingFilterComponent: FloatingFilterInput,
      floatingFilterComponentParams: {
        inputType: 'date',
        maxLength: 8,
        placeholder: 'MM/DD/YY',
      },
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          customStyle: {
            ...CELL_CUSTOM_STYLE.date,
          },
        }),
    },
    {
      headerName: 'CAT',
      field: 'category',
      width: 73,
      editable: false,
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: generateListToOption(CATEGORY),
      },
    },

    {
      headerName: 'TOTAL AMT',
      field: 'total_amt',
      editable: false,
      width: 85,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          customStyle: {
            ...CELL_CUSTOM_STYLE.number,
          },
          adornment: {
            position: 'start',
            content: '$',
          },
        }),
      cellStyle: params => {
        if (params.data?.total_amt !== params?.data?.inv_amt) {
          return { color: '#B74747' };
        }
        return null;
      },
    },
    {
      headerName: 'INV #',
      field: 'inv_no',
      editable: false,
      width: 100,
    },
    {
      headerName: 'QB ID',
      field: 'qb_id',
      editable: false,
      width: 50,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          customStyle: {
            ...CELL_CUSTOM_STYLE.number,
          },
        }),
    },
    {
      headerName: 'INV AMT',
      field: 'inv_amt',
      editable: false,
      width: 85,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          customStyle: {
            ...CELL_CUSTOM_STYLE.number,
          },
          adornment: {
            position: 'start',
            content: '$',
          },
        }),
      cellStyle: params => {
        if (params.data?.total_amt !== params?.data?.inv_amt) {
          return { color: '#B74747' };
        }
        return null;
      },
    },

    {
      headerName: 'QB DATE',
      field: 'qb_date',
      cellClass: 'date',
      editable: false,
      valueGetter: param => {
        return generateDateValue(param?.data?.qb_date);
      },
      width: 75,
      comparator: dateComparator,
      floatingFilterComponent: FloatingFilterInput,
      floatingFilterComponentParams: {
        inputType: 'date',
        maxLength: 8,
        placeholder: 'MM/DD/YY',
      },
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          customStyle: {
            ...CELL_CUSTOM_STYLE.date,
          },
        }),
    },
  ];
};

export default createQBAPARColumnDefs;
