import adminDefaultApi from '../../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

const getGeoInfo = async ({ zip }) => {
  try {
    const response = await adminDefaultApi.get(`geo-info/${zip}`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getGeoInfo;
