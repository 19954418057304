import { acctDefaultAPI } from '../../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

const postInvoiceAP = async ({ postData }) => {
  try {
    const response = await acctDefaultAPI.post('invoice-perdiem', postData);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default postInvoiceAP;
