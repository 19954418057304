import { acctDefaultAPI } from '../../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

const getPerdiemReconcileList = async ({ queryString }) => {
  try {
    const response = await acctDefaultAPI.get(`perdiem-recon?${queryString}`);
    return response?.data;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getPerdiemReconcileList;
