import styled from 'styled-components';

const StyledAPModalTableContentRowCell = styled.div`
  width: ${props => props?.width || '100%'};
  height: ${props => props?.height || '100%'};
  border-right: ${props => props?.borderRight || '1px solid #EEEEEE'};
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #222222;
  font-size: 12px;
  font-weight: 400;
  gap: ${props => props?.gap || 'none'};
`;

export default StyledAPModalTableContentRowCell;
