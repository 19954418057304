import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import getRateCFM from '../../../../services/BCDetail/BillingInfo/AP/Get/getRateCFM';
import bcDetailKeys from '../../keys';

const useGetRateCFM = ({ seq }) => {
  const [rateCFMContents, setRateCFMContents] = useState(null);

  const query = useQuery({
    queryKey: bcDetailKeys.billingInfoAPRateCFM({ seq }),
    queryFn: () => getRateCFM({ seq }),
    refetchOnWindowFocus: false,
    enabled: !!seq,
  });

  useEffect(() => {
    if (query?.data?.data) {
      setRateCFMContents(query?.data?.data);
    }
  }, [query?.data]);

  return { ...query, rateCFMContents };
};

export default useGetRateCFM;
