import React from 'react';
import dayjs from 'dayjs';
import {
  MANIFEST_STATUS,
  MANIFEST_DETAIL_CELL_WIDTH,
} from '../../../../../constants/MobileAdmin/Manifest/manifestDetailValues';

const GroupedRow = ({ data, status }) => {
  return (
    <div className="group-row">
      <div className={`status ${MANIFEST_STATUS[status]}`}>{status}</div>
      <div className="group-col">
        {data?.map((group, idx) => (
          <div key={`${String(idx)}${status}`} className="group-row">
            <div className={`cell ${MANIFEST_DETAIL_CELL_WIDTH.bill_date}`}>
              {group?.data[0]?.bill_date &&
                dayjs(group?.data[0]?.bill_date).format('MM/DD/YY')}
            </div>
            <div className={`cell ${MANIFEST_DETAIL_CELL_WIDTH.bill_no}`}>
              {group?.data[0]?.bill_no}
            </div>
            <div className="group-col">
              {group?.data?.map((cell, index) => (
                <div key={`${String(index)}${cell?.bill_no}`} className="row">
                  <div
                    className={`cell ${MANIFEST_DETAIL_CELL_WIDTH.acct_code} h-[35px]`}
                  >
                    {cell?.acct_code}
                  </div>
                  <div
                    className={`cell ${MANIFEST_DETAIL_CELL_WIDTH.acct_code_name} h-[35px]`}
                  >
                    {cell?.acct_code_name}
                  </div>
                  <div
                    className={`cell cell-dollar ${MANIFEST_DETAIL_CELL_WIDTH.amt} h-[35px] px-[13px]`}
                  >
                    {cell?.amt && <div>$</div>}
                    {cell?.amt}
                  </div>
                </div>
              ))}
            </div>
            <div
              className={`cell cell-dollar ${MANIFEST_DETAIL_CELL_WIDTH.bill_amt}`}
            >
              {group?.data[0]?.bill_amt && <div>$</div>}
              {group?.data[0]?.bill_amt}
            </div>
            <div
              className={`cell cell-dollar ${MANIFEST_DETAIL_CELL_WIDTH.paid_amt}`}
            >
              {group?.data[0]?.paid_amt && <div>$</div>}
              {group?.data[0]?.paid_amt}
            </div>
            <div className="group-col">
              {group?.data?.map((cell, index) => (
                <div
                  key={`${String(index)}desc`}
                  className={`cell ${MANIFEST_DETAIL_CELL_WIDTH.description} h-[35px]`}
                >
                  {cell?.description}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GroupedRow;
