import React from 'react';
import CommonStatusBox from '../../../SecondInputs/CommonStatusBox/CommonStatusBox';

const TrackingList = ({ trackingList }) => {
  return (
    <div className="ml-[70px]">
      {trackingList?.map((row, idx) => (
        <div className="flex space-x-[2px]">
          <div className="text-[11px] bg-white font-[700] text-[#264B9F] w-[256px] h-[30px] text-start shadow drop-shadow-[0_0.5px_0_rgba(0,0,0,0.25)] mr-[2px] mb-[2px]">
            <div className="w-[7px] bg-[#264B9F] h-[30px] absolute" />
            <div>
              <p className="pl-[19px] py-[8px]">{row?.date}</p>
            </div>
          </div>

          <div className="w-[252px] h-[30px] text-[11px] font-[500] py-[8px] bg-[#F2F4F8] shadow drop-shadow-[0_0.5px_0_rgba(0,0,0,0.25)]">
            <p className="text-center">{row?.time}</p>
          </div>
          <div className="w-[252px] h-[30px] text-[11px] font-[500] py-[8px] bg-[#F2F4F8] shadow drop-shadow-[0_0.5px_0_rgba(0,0,0,0.25)]">
            <p className="text-center">{row?.location}</p>
          </div>
          <div className="w-[252px] h-[30px] text-[11px] font-[500] py-[8px] bg-[#F2F4F8] shadow drop-shadow-[0_0.5px_0_rgba(0,0,0,0.25)]">
            <p className="text-center">{row?.mobile}</p>
          </div>
          <div className="w-[252px] h-[30px] text-[11px] font-[500] py-[8px] bg-[#F2F4F8] shadow drop-shadow-[0_0.5px_0_rgba(0,0,0,0.25)]">
            <p className="text-center">{row?.vehicle}</p>
          </div>
          <div className="w-[252px] h-[30px] text-[11px] font-[500] py-[8px] bg-[#F2F4F8] shadow drop-shadow-[0_0.5px_0_rgba(0,0,0,0.25)]">
            <p className="text-center">{row?.driver}</p>
          </div>
          <div>
            <CommonStatusBox
              width="w-[252px]"
              height={30}
              status={row?.status}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default TrackingList;
