import styled from 'styled-components';

const StyledBCDetailBillingInfoRateCFMModalName = styled.div`
  color: #222;
  font-size: 12px;
  font-weight: 600;
  width: 30px;
`;

export default StyledBCDetailBillingInfoRateCFMModalName;
