const combineNumber = (inputArray, data) => {
  const numberArr = [];

  inputArray?.forEach(field => {
    numberArr.push(data[field]);
    delete data[field];
  });

  const combineNum = numberArr.join('');

  return combineNum !== '' ? combineNum : null;
};

export default combineNumber;
