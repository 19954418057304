/**
 * @description
 * Handler for autocomplete filter options
 * @param {list} options
 * Auto complete options
 * @param {object} params
 * Auto complete input parameter
 * @returns {list}
 * Filtered options
 */
const autoCompleteFilterOptionsHandler = ({ options, params }) => {
  /** Get input value */
  const { inputValue } = params;

  if (!inputValue || inputValue === '')
    return options ? options.slice(0, 10) : [];

  const filteredOptions = options?.filter(
    option =>
      option?.label?.toLowerCase().includes(inputValue?.toLowerCase()) ||
      option?.value?.toLowerCase().includes(inputValue?.toLowerCase()) ||
      option === inputValue,
  );

  return filteredOptions
    .sort((a, b) => {
      const getMatchIndex = option => {
        const labelIndex = option.label
          .toLowerCase()
          .indexOf(inputValue.toLowerCase());
        const valueIndex = option.value
          .toLowerCase()
          .indexOf(inputValue.toLowerCase());
        return Math.min(
          labelIndex === -1 ? Infinity : labelIndex,
          valueIndex === -1 ? Infinity : valueIndex,
        );
      };

      const aIndex = getMatchIndex(a);
      const bIndex = getMatchIndex(b);

      if (aIndex !== bIndex) return aIndex - bIndex;

      if (a.label === inputValue || a.value === inputValue) return -1;
      if (b.label === inputValue || b.value === inputValue) return 1;

      return a.label.localeCompare(b.label);
    })
    ?.slice(0, 200);
};

export default autoCompleteFilterOptionsHandler;
