import React from 'react';
import { useFormContext } from 'react-hook-form';
import CommonButton from '../../../CommonButton/CommonButton';
import handleAdvancedSearchFilter from '../../../../utils/Customer/Handler/handleAdvancedSearchFilter';

const ButtonContent = ({ ...props }) => {
  const { getValues } = useFormContext();

  /** Get props data */
  const { open, setOpen, setAdvancedFilter } = props;

  return (
    <div className="flex justify-center items-center flex-row gap-[20px]">
      <CommonButton category="cancel" onClick={() => setOpen(false)}>
        Cancel
      </CommonButton>
      <CommonButton
        category="search"
        onClick={() => {
          handleAdvancedSearchFilter({ getValues, setAdvancedFilter });
          setOpen(false);
        }}
      >
        Search
      </CommonButton>
    </div>
  );
};

export default ButtonContent;
