const changeDataGridEditSelectOption = ({
  gridRef,
  editColName,
  changeOptionList,
}) => {
  const instances = gridRef?.current?.api?.getCellEditorInstances();
  const pairInstance = instances?.find(
    element => element?.cellEditorParams?.column?.colId === editColName,
  );

  pairInstance?.componentInstance?.setOption?.(changeOptionList);
};

export default changeDataGridEditSelectOption;
