import {
  DRIVER_POSITION,
  DRIVER_POSITION_OBJECT,
} from '../../../constants/Master/APRate/elements';
import {
  TIERWIEHT_STATUS,
  TIERWIEHT_STATUS_OBJECT,
} from '../../../constants/Master/TierWeight/elements';
import convertStandardCodeToText from '../../Common/convertStandardCodeToText';

const setResultData = (setList, setIsLoading, resultData, companyList) => {
  resultData?.forEach((data, index) => {
    resultData[index].category =
      convertStandardCodeToText(
        data.category,
        DRIVER_POSITION,
        DRIVER_POSITION_OBJECT,
      ) || data.category;
    resultData[index].status =
      convertStandardCodeToText(
        data.status,
        TIERWIEHT_STATUS,
        TIERWIEHT_STATUS_OBJECT,
      ) || data.status;
    resultData[index].from_location = companyList
      ? `${resultData[index].from_location}/${
          companyList?.data.find(
            company => company.company === data.from_location,
          )?.business_name
        }`
      : data.from_location;
    resultData[index].to_location = companyList
      ? `${resultData[index].to_location}/${
          companyList?.data.find(
            company => company.company === data.to_location,
          )?.business_name
        }`
      : data.to_location;
    resultData[index].po_location = companyList
      ? `${resultData[index].po_location}/${
          companyList?.data.find(
            company => company.company === data.po_location,
          )?.business_name
        }`
      : data.po_location;
    resultData[index].dilv_location = companyList
      ? `${resultData[index].dilv_location}/${
          companyList?.data.find(
            company => company.company === data.dilv_location,
          )?.business_name
        }`
      : data.dilv_location;
    resultData[index].billto = companyList
      ? `${resultData[index].billto}/${
          companyList?.data.find(company => company.company === data.billto)
            ?.business_name
        }`
      : data.billto;
  });
  setList(resultData);
  setTimeout(() => {
    setIsLoading(false);
  }, 100);
};

export default setResultData;
