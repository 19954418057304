import React, { useEffect } from 'react';

const ScheduleTrackingModalContentHeader = ({ ...props }) => {
  const { trackingInfo } = props;

  return (
    <div className="w-[437px] h-[90px] bg-[#F9F9F9] flex justify-center items-center">
      <div className="w-[400px] h-[56px]">
        <div className="w-full h-[28px] flex flex-row items-center gap-[20px]">
          <div className="w-[48px] h-full text-[#222] text-[12px] font-semibold flex items-center">
            From
          </div>
          <div className="w-[71px] h-full text-[#222] text-[12px] flex items-center">
            {trackingInfo?.from_location?.company}
          </div>
          <div className="w-[223px] h-full text-[#666] text-[12px] flex items-center">
            <p className="text-ellipsis overflow-hidden">
              {trackingInfo?.from_location?.address}
            </p>
          </div>
        </div>
        <div className="w-full h-[28px] flex flex-row items-center gap-[20px]">
          <div className="w-[48px] h-full text-[#222] text-[12px] font-semibold flex items-center">
            To
          </div>
          <div className="w-[71px] h-full text-[#222] text-[12px] flex items-center">
            {trackingInfo?.to_location?.company}
          </div>
          <div className="w-[223px] h-full text-[#666] text-[12px] flex items-center">
            <p className="text-ellipsis overflow-hidden">
              {trackingInfo?.to_location?.address}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleTrackingModalContentHeader;
