import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';
import billingInfoDefaultAPI from '../../api';

const getARData = async ({ woNo }) => {
  try {
    const response = await billingInfoDefaultAPI.get(`${woNo}/ar`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getARData;
