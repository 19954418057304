import styled from 'styled-components';

const LabsPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 97px;
  display: flex;
  flex-wrap: wrap;
`;

export default LabsPageWrapper;
