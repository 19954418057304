const onARGroupRowsByGroupIdHandler = rows => {
  return rows.reduce((acc, row) => {
    if (row.inv_no !== null && row.inv_no !== undefined) {
      const groupId = row.inv_no;
      if (!acc[groupId]) {
        acc[groupId] = [];
      }
      acc[groupId].push(row);
    }
    return acc;
  }, {});
};

export default onARGroupRowsByGroupIdHandler;
