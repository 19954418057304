import { useEffect, useState } from 'react';
import {
  createGridSelectOption,
  createMandatorySelectOption,
} from '../../../../utils/Common/createFloatingFilterSelectOption';
import generateAuthInfo from '../../../../utils/Admin/User/Generator/generateAuthInfo';
import { generateFirstPageOption } from '../../../../utils/Admin/User/Generator/optionGenerator';

const useControlAuthValue = ({
  isWorkingUser,
  authList,
  watch,
  userInfo,
  setValue,
}) => {
  const [isChangeAuth, setIsChangeAuth] = useState(false);
  const [authOptionList, setAuthOptionList] = useState([]);
  const [firstPageOptionList, setFirstPageOptionList] = useState([]);
  const [selectedAuth, setSelectedAuth] = useState(
    generateAuthInfo({ authList, selectedAuth: userInfo?.group_name }),
  );

  useEffect(() => {
    const tempAuthOptionList = isWorkingUser
      ? createMandatorySelectOption(authList?.map(auth => auth?.name))
      : createGridSelectOption(authList?.map(auth => auth?.name));

    setAuthOptionList(tempAuthOptionList);
  }, [isWorkingUser, authList]);

  useEffect(() => {
    const isValidFirstPage = !!firstPageOptionList?.find(
      option => option?.value === watch('first_page'),
    );

    if (!isValidFirstPage) {
      setValue('first_page', !isChangeAuth ? watch('first_page') : '');
    }
  }, [firstPageOptionList]);

  useEffect(() => {
    setFirstPageOptionList(generateFirstPageOption({ selectedAuth }));
  }, [selectedAuth]);

  return {
    isChangeAuth,
    setIsChangeAuth,
    authOptionList,
    setAuthOptionList,
    firstPageOptionList,
    setFirstPageOptionList,
    selectedAuth,
    setSelectedAuth,
  };
};

export default useControlAuthValue;
