import React from 'react';

const AcctTableHeader = ({ ...props }) => {
  const { headers } = props;
  return (
    <div data-testid="detailTable" className="flex items-center gap-x-[2px]">
      {headers?.map((header, index) => (
        <div
          key={String(index + 1)}
          className={`h-[35px] bg-[#fff] text-[11px] font-[500] flex justify-center 
          ${
            header?.width === 220
              ? 'w-[220px] min-w-[220px]'
              : 'w-[110px] min-w-[110px]'
          }
          py-[11px]`}
        >
          <p>{header?.name}</p>
        </div>
      ))}
    </div>
  );
};

export default AcctTableHeader;
