import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import tmsReportScheduleKeys from './keys';
import getSchedule from '../../../services/TmsReport/Schedules/Get/getSchedule';
import getURLSearchParams from '../../../utils/TmsReport/Schedule/Generator/getURLSearchParams';

const useGetSchedule = ({ type, weeklyDateQuery, ...scheduleParams }) => {
  const [scheduleData, setScheduleData] = useState({});

  /** Set up Parameter */
  const searchParams = {
    ...scheduleParams,
    date: weeklyDateQuery,
  };
  const params = getURLSearchParams({ ...searchParams });

  const { data, isLoading, isFetching } = useQuery({
    queryKey: tmsReportScheduleKeys.list({ type, ...searchParams }),
    queryFn: () => getSchedule({ type, params }),
    refetchOnWindowFocus: false,
    enabled: weeklyDateQuery && Object?.keys?.(weeklyDateQuery)?.length > 0,
  });

  useEffect(() => {
    setScheduleData(data?.data || {});
  }, [data?.data]);

  return { isLoading, isFetching, scheduleData };
};

export default useGetSchedule;
