import handleQueryError from '../../../utils/Common/Handler/handleQueryError';
import serviceDefaultApi from '../../api';

const getLiveShareData = async ({ trackingID }) => {
  try {
    const response = await serviceDefaultApi.get(
      `tracking/live-share/${trackingID}`,
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getLiveShareData;
