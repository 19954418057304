import tmsReportAPI from '../../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

const getDailySchedule = async ({ params, pageCategory }) => {
  try {
    const response = await tmsReportAPI.get(
      `/daily-schedule${pageCategory ? `/${pageCategory}` : ''}`,
      {
        params,
      },
    );
    return response?.data;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getDailySchedule;
