export const STATUS_CHECKBOX_LIST_STYLE = {
  White: {
    backgroundColor: 'unset',
  },
  Yellow: {
    backgroundColor: '#FFD70090',
  },
  Green: {
    backgroundColor: '#A2E47F',
  },
  Blue: {
    backgroundColor: '#CDE5FF',
  },
};
