import styled from 'styled-components';

const StyledWeeklyItemContentTextRow = styled.span`
  min-height: 14px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default StyledWeeklyItemContentTextRow;
