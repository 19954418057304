import { useMutation } from '@tanstack/react-query';
import postDetailEmail from '../../../../services/BCDetail/DetailInfo/Common/Post/postDetailEmail';
import useControlToast from '../../../Common/Toast/useControlToast';
import { SUCCESS_SEND } from '../../../../constants/Common/successMessage';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';

const usePostDetailEmail = ({
  setMessage,
  setErrorModalOpen,
  setAlertModalOpen,
}) => {
  const { addToast } = useControlToast();

  const mutator = useMutation({
    mutationFn: postDetailEmail,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: false,
    cacheTime: 0,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: success => {
      addToast({ message: SUCCESS_SEND });
    },
    onError: error => {
      setMessage(error.message);
      setErrorModalOpen(true);
    },
  });

  return mutator;
};

export default usePostDetailEmail;
