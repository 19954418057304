/**
 * @description
 * Function that generate pick-up type base schedule row key
 * @param {object} schedule
 * Schedule that pick-up type base schedule
 * @returns {string}
 * Return pick-up type base schedule row key
 */
const generatePickUpBaseScheduleKey = ({ schedule }) => {
  const getScheduleObjectKey = Object.keys(schedule);

  /** Is job set */
  if (
    getScheduleObjectKey?.includes('from_location') &&
    getScheduleObjectKey?.includes('live_dilv_location') &&
    getScheduleObjectKey?.includes('to_location')
  ) {
    const getJobSetSeq = schedule?.seq;
    let getScheduleSeq = null;
    let getScheduleType = null;

    const getFromLocation = schedule?.from_location;
    const getLiveDilvLocation = schedule?.live_dilv_location;
    const getToLocation = schedule?.to_location;

    if (
      getFromLocation &&
      getFromLocation?.type === 'P/U' &&
      getFromLocation?.ordering === 0
    ) {
      getScheduleSeq = getFromLocation?.seq;
      getScheduleType = 'fr';
    } else if (
      getLiveDilvLocation &&
      getLiveDilvLocation?.type === 'P/U' &&
      getLiveDilvLocation?.ordering === 0
    ) {
      getScheduleSeq = getLiveDilvLocation?.seq;
      getScheduleType = 'ld';
    } else if (
      getToLocation &&
      getToLocation?.type === 'P/U' &&
      getToLocation?.ordering === 0
    ) {
      getScheduleSeq = getToLocation?.seq;
      getScheduleType = 'to';
    }

    /** If jobSet sequence and schedule sequence and schedule type exist return row key */
    if (getJobSetSeq && getScheduleSeq && getScheduleType)
      return `${getJobSetSeq}_${getScheduleSeq}_${getScheduleType}`;
  }

  /** Is location set */
  return `${schedule?.seq}_location_location`;
};

export default generatePickUpBaseScheduleKey;
