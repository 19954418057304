import axios from 'axios';
import interceptRequest from '../../utils/Common/Interceptor/interceptRequest';

/**
 * Order base url
 */
const ORDER_BASE_URL = `${process.env.REACT_APP_BASE_URL}/order/`;

/**
 * Axios object with base url
 */
const orderAPI = axios.create({
  withCredentials: true,
  baseURL: ORDER_BASE_URL,
  xsrfHeaderName: 'X-CSRFTOKEN',
  xsrfCookieName: 'XCSRF-TOKEN',
  headers: {
    'Content-Type': 'multipart/form-data',
    common: {
      'X-CSRFToken': localStorage.getItem('csrfToken'),
    },
  },
});

/** Interceptors */
interceptRequest(orderAPI);

export default orderAPI;
