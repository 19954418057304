export const CATEGORY_CODE = {
  EWC001: 'WO',
  EWC002: 'ETA',
  EWC003: 'Pull Out',
  EWC004: 'Delivery',
  EWC005: 'Pick Up',
  EWC006: 'Return',
  EWC007: 'Per Diem',
  EWC008: 'Demurrage',
};

export const CONDITION_CODE = {
  EWN001: 'No Schedule',
  EWN002: 'No P/O Schedule',
  EWN003: 'No P/O Actual In',
  EWN004: 'No P/O Driver',
  EWN005: 'No DILV Schedule',
  EWN006: 'No DILV Actual In',
  EWN007: 'No DILV Driver',
  EWN008: 'No P/U Schedule',
  EWN009: 'No P/U Actual In',
  EWN010: 'No P/U Driver',
  EWN011: 'No RTN Schedule',
  EWN012: 'No RTN Actual In',
  EWN013: 'No RTN Driver',
  EWN014: 'No POD',
  EWN015: 'No POL',
};

export const DATE_CODE = {
  EWD001: 'Current Date&Time',
  EWD002: 'P/O Schedule',
  EWD003: 'P/O Actual In',
  EWD004: 'DILV Schedule',
  EWD005: 'DILV Actual In',
  EWD006: 'P/U Schedule',
  EWD007: 'P/U Actual In',
  EWD008: 'RTN Schedule',
  EWD009: 'RTN Actual In',
  EWD010: 'ETA',
  EWD011: 'LFD',
  EWD012: 'RFD',
  EWD013: 'WO# Create',
  EWD014: 'IMP DILV Actual Out',
  EWD015: 'EXP P/U Actual Out',
  EWD016: 'MTY/LOAD Confirm Date',
};
