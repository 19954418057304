import styled from 'styled-components';

const StyledTMSReportCommonButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 40px 20px 0;
  column-gap: 15px;
`;

export default StyledTMSReportCommonButtonContainer;
