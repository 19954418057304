import styled from 'styled-components';

const StyledManifestDetails = styled.div`
  padding-bottom: 10px;
  margin-left: 54px;
  margin-right: 10px;
  width: 1631px;

  .status {
    width: 90px;
    color: #ffffff;
    font-size: 11px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .row {
    display: flex;
    align-items: center;
    gap: 0px 2px;
  }

  .cell {
    color: #222222;
    background-color: #fff;
    font-size: 11px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cell-dollar {
    justify-content: space-between;
    padding: 0px 20px;
  }

  .group-col {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
  }

  .group-row {
    display: flex;
    column-gap: 2px;
  }
`;

export default StyledManifestDetails;
