import styled from 'styled-components';

const StyledBCDetailBillingInfoCommonRowRateCfmButton = styled.button`
  width: 84px;
  height: 26px;
  color: #264b9f;
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  border: 1px solid #ccd3e3;
  border-radius: 5px;
  background-color: #fff;
  z-index: 2;
`;

export default StyledBCDetailBillingInfoCommonRowRateCfmButton;
