import React from 'react';
import {
  DISPATCH_BOOKING_STATUS_ACTIVE,
  DISPATCH_BOOKING_STATUS_ADD,
  DISPATCH_BOOKING_STATUS_INACTIVE,
} from '../../../../../constants/Dispatch/Export/Common/bookingModalStatus';
import { DISPATCH_BOOKING_TAB_HISTORY } from '../../../../../constants/Dispatch/Export/Common/bookingModalTab';
import updateButtonDisabled from '../../../../../utils/Common/Handler/updateButtonDisabled';
import CommonButton from '../../../../CommonButton/CommonButton';

const BookingModalButtonContent = ({
  tab,
  existWO,
  status,
  bkCompleteMutator,
  handleDeleteBooking,
  setModalOpen,
  setTabIndexOpen,
  setBookingSeq,
  bookingSeq,
  saveFormID,
  setIsMessageModalOpen,
  setMessageModalMessage,
  saveButtonRef,
  ...props
}) => {
  return (
    <div className="flex justify-center space-x-[18px]">
      {status === DISPATCH_BOOKING_STATUS_ACTIVE && (
        <div>
          <CommonButton
            category="complete"
            preventDupClick
            onClick={({ buttonRef }) => {
              if (existWO) {
                updateButtonDisabled({ ref: buttonRef, disabled: false });
                setTabIndexOpen(2);
                setMessageModalMessage(
                  'This BK# is currently in use. Please complete all WOs and try again.',
                );
                setIsMessageModalOpen(true);
              } else {
                bkCompleteMutator({ bookingSeq, buttonRef });
              }
            }}
          >
            Complete
          </CommonButton>
        </div>
      )}
      {status === DISPATCH_BOOKING_STATUS_INACTIVE && (
        <div>
          <CommonButton
            category="inactive"
            borderColor="#B74747"
            preventDupClick
            onClick={({ buttonRef }) => {
              bkCompleteMutator({ bookingSeq, buttonRef });
            }}
          >
            Incomplete
          </CommonButton>
        </div>
      )}
      {status !== DISPATCH_BOOKING_STATUS_ADD && (
        <div>
          <CommonButton
            category="greyDelete"
            borderColor="#666666"
            preventDupClick
            onClick={({ buttonRef }) => {
              if (existWO) {
                updateButtonDisabled({ ref: buttonRef, disabled: false });
                setTabIndexOpen(2);
                setMessageModalMessage(
                  'This BK# is currently in use. Please complete all WOs and try again.',
                );
                setIsMessageModalOpen(true);
              } else {
                handleDeleteBooking();
              }
            }}
          >
            Delete
          </CommonButton>
        </div>
      )}
      {tab !== DISPATCH_BOOKING_TAB_HISTORY && (
        <div>
          <CommonButton
            ref={saveButtonRef}
            category="save"
            form={saveFormID || ''}
            type="submit"
          >
            Save
          </CommonButton>
        </div>
      )}
    </div>
  );
};

export default BookingModalButtonContent;
