import styled from 'styled-components';

const StyledWeeklyItemContentText = styled.p`
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 12px;
  font-weight: ${props => props?.$fontWeight || 500};
`;

export default StyledWeeklyItemContentText;
