export const createViewOption = ({ viewPerm, editPerm }) => [
  ...(viewPerm?.compView
    ? [
        { key: 1, label: 'Customer', value: 'CO0001' },
        { key: 2, label: 'Vendor', value: 'CO0002' },
      ]
    : []),
  ...(editPerm?.cneeEdit
    ? [
        { key: 3, label: 'CNEE', value: 'CO0003' },
        { key: 4, label: 'NGL', value: 'CO0004' },
      ]
    : []),
];

export const createEditOption = ({ editPerm }) => [
  ...(editPerm?.compEdit
    ? [
        { key: 1, label: 'Customer', value: 'CO0001' },
        { key: 2, label: 'Vendor', value: 'CO0002' },
      ]
    : []),
  ...(editPerm?.cneeEdit
    ? [
        { key: 3, label: 'CNEE', value: 'CO0003' },
        { key: 4, label: 'NGL', value: 'CO0004' },
      ]
    : []),
];
