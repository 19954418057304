const getURLSearchParams = params => {
  const urlSearchParams = new URLSearchParams();

  const paramList = Object?.fromEntries(
    Object?.entries?.(params)?.map(([key, value]) => {
      if (key === 'date') {
        const isObjectDate = value && typeof value === 'object';
        if (isObjectDate) {
          return [key, [...Object.values(value)]];
        }
        return [key, value];
      }

      if (
        ['category', 'div', 'sche-type', 'ngl-yard', 'status-color']?.includes(
          key,
        )
      ) {
        return [
          key,
          [...Object.keys(value).filter(keyValue => value[keyValue])],
        ];
      }

      return [key, value];
    }),
  );

  /** Convert to URLSearchParams */
  Object.entries(paramList).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(item => {
        urlSearchParams.append(key, item);
      });
    } else {
      urlSearchParams.append(key, value);
    }
  });

  return urlSearchParams;
};

export default getURLSearchParams;
