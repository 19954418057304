/**
 * @description
 * Function to generate booking info option(info)
 * @param {object} bookingInfo
 * Booking info data according to booking number
 * @returns {list}
 * Booking info option list
 */
const generateBookingInfoOption = ({ bookingInfo }) => {
  const result = [];

  for (let idx = 0; idx < 3; idx++) {
    const eachBalance = bookingInfo?.[`balance_${idx + 1}`];
    const eachSize = bookingInfo?.[`size_${idx + 1}`];
    const eachType = bookingInfo?.[`type_${idx + 1}`];

    if (eachBalance !== null && eachBalance >= 0 && eachSize && eachType) {
      if (
        result?.find(
          element => element?.size === eachSize && element?.type === eachType,
        )
      ) {
        const findTarget = result.find(
          element => element?.size === eachSize && element?.type === eachType,
        );
        const foundIndex = result?.indexOf(findTarget);
        result[foundIndex].balance += eachBalance;
      } else {
        result.push({
          size: eachSize,
          type: eachType,
          balance: eachBalance,
        });
      }
    }
  }

  return result;
};

export default generateBookingInfoOption;
