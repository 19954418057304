import checkDateDiff from '../../../Common/Handler/checkDateDiff';

const searchContent = ({
  handleSubmit,
  errorModalOptions,
  setQueryObject,
  setOpenModal,
}) => {
  const { setIsSuccess, setErrorMessage, setIsErrorModalOpen } =
    errorModalOptions;

  handleSubmit(submitData => {
    if (
      !checkDateDiff({
        fromDate: submitData?.['created-from'],
        toDate: submitData?.['created-to'],
      })
    ) {
      setIsSuccess(false);
      setErrorMessage(
        'The date range cannot exceed one year. Please try again.',
      );
      setIsErrorModalOpen(true);
      return;
    }
    setQueryObject(prev => ({
      ...prev,
      ...submitData,
    }));
    setOpenModal(false);
  })();
};

export default searchContent;
