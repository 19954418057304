import styled, { css } from 'styled-components';

const StyledNormalCellAdornmentRenderer = styled.div`
  width: fit-content;
  height: ${props => props?.height || '100%'};
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => {
    if (props?.adornmentPosition === 'start') {
      return css`
        padding-right: 12px;
      `;
    }
    if (props?.adornmentPosition === 'end') {
      return css`
        padding-left: 12px;
      `;
    }
    return null;
  }}
`;

export default StyledNormalCellAdornmentRenderer;
