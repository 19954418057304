import React from 'react';
import CommonButton from '../../../CommonButton/CommonButton';
import exportToExcel from '../../../../utils/Common/Datagrid/Handler/exportToExcel';
import handleActionClick from '../../../../utils/MobileAdmin/BillConfirm/Handler/handleActionClick';

const HeaderButtons = ({ gridRef, fileName, ...props }) => {
  return (
    <div className="flex items-center mt-[20px] my-[20px] justify-end gap-x-[15px]">
      <CommonButton
        onClick={() => exportToExcel({ gridRef, fileName })}
        type="button"
        category="excel"
      >
        EXCEL
      </CommonButton>
      <CommonButton
        onClick={() =>
          handleActionClick({
            action: 'rejectItems',
            rows: gridRef?.current?.api?.getSelectedRows(),
            ...props,
          })
        }
        type="button"
        category="cancel"
      >
        Reject
      </CommonButton>
      <CommonButton
        onClick={() =>
          handleActionClick({
            action: 'approveItems',
            rows: gridRef?.current?.api?.getSelectedRows(),
            ...props,
          })
        }
        type="button"
        category="approval"
      >
        Approval
      </CommonButton>
    </div>
  );
};

export default HeaderButtons;
