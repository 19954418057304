import { DateTimePicker } from '@mui/x-date-pickers';
import styled, { css } from 'styled-components';

const StyledDatetime = styled(DateTimePicker)`
  svg {
    color: #264b9f !important;
  }
  &.MuiFormControl-root {
    width: 100%;
  }
  &.MuiTextField-root {
    width: 100% !important;
  }
  .MuiInputBase-root {
    color: #000000 !important;
    height: 100%;
    width: 100% !important;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 500;
    background-color: white !important;
    &:not(.Mui-focused):not(.Mui-disabled):hover fieldset {
      border-color: #8e9396 !important;
    }
    &.Mui-focused fieldset {
      border: 1.2px solid #264b9f !important;
      box-shadow: 0px 0px 4px 0px #8bbce9 !important;
    }
    &.Mui-error fieldset {
      border: 1.2px solid #c24d4d !important;
    }
    &.Mui-disabled fieldset {
      background-color: #f9f9f9 !important;
    }
    .MuiInputBase-input.MuiOutlinedInput-input {
      border: none !important;
    }
    input {
      padding-left: 2px !important;
      padding-right: 2px !important;
    }
  }
  .MuiSvgIcon-root {
    font-size: 16px;
  }
  fieldset {
    border-color: #d9d9d9 !important;
  }
`;

export default StyledDatetime;
