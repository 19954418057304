const createCompanyDropdown = ({ companyData }) => {
  const result = companyData?.map(company => {
    return {
      label: `${company?.company} / ${company?.business_name} / ${company?.address_01}, ${company?.city}, ${company?.state} ${company?.zip}`,
      value: company?.company,
    };
  });

  return result;
};

export default createCompanyDropdown;
