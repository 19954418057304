import { useEffect, useState } from 'react';
import { ACCT_PAGE } from '../../../../constants/Acct/Common/staticNames';
import {
  AP_AR_RECONCILE_OPTION,
  CATEGORY_OPTION,
  DATE_OPTION,
  DATE_UNINVOICE_OPTION,
  QB_AP_AR_OPTION,
  QB_RECONCILE_DATE_OPTION,
} from '../../../../constants/Acct/Common/selectOptions';
import { QB_STATUS_OBJECT } from '../../../../constants/Acct/Common/acctElement';

const useControlAdvancedSearch = ({
  page,
  queryObject,
  optionList,
  isOpenModal,
  ...props
}) => {
  const { watch } = props;

  /** Show Field */
  const [isShowStatus, setIsShowStatus] = useState(null);
  const [isShowDriverType, setIsShowDriverType] = useState(false);

  /** Width */
  const [apARTypeWidth, setAPARWidth] = useState('');
  const [factoringWidth, setFactoringWidth] = useState('');
  const [keyWordWidth, setKeywordWidth] = useState('');

  /** Disabled */
  const [isDisabledFactoring, setIsDisabledFactoring] = useState(false);
  const [isDisabledKeyWord, setIsDisabledKeyWord] = useState(false);

  /** Default Value */
  const [statusDefaultValue, setStatusDefaultValue] = useState('');
  const [factoringDefaultValue, setFactoringDefaultValue] = useState('');

  /** Option */
  const [statusOption, setStatusOption] = useState([]);
  const [categoryOption, setCategoryOption] = useState([]);
  const [dateOption, setDateOption] = useState([]);

  /** date Data */
  const [lastDateRange, setLastDateRange] = useState({});

  useEffect(() => {
    const categoryValue = queryObject?.category;
    const typeValue = queryObject?.type;
    const factoringValue = queryObject?.factoring;
    const statusValue = queryObject?.status;

    const enabledFactoring =
      (categoryValue === 'customer' && typeValue === 'ALL') ||
      typeValue === 'AR';

    /** Set  Option */
    setCategoryOption(CATEGORY_OPTION?.[typeValue]);

    /** Hide/Show Component */
    setIsShowDriverType(categoryValue === 'driver');

    /** Set Field Disabled */
    setIsDisabledFactoring(!enabledFactoring);
    setIsDisabledKeyWord(!categoryValue);

    /** Set factoring Default Value */
    setFactoringDefaultValue(enabledFactoring ? factoringValue || 'ALL' : '');

    /** Default Setting By Page */
    if (page === ACCT_PAGE?.unInvoiced) {
      setIsShowStatus(false);
      setStatusOption([]);
      setAPARWidth('w-[202px]');
      setFactoringWidth('w-[202px]');
      setDateOption(DATE_UNINVOICE_OPTION);
    }

    if (page === ACCT_PAGE?.qbAPAR) {
      setIsShowStatus(true);
      setStatusOption(QB_AP_AR_OPTION);
      setAPARWidth('w-[153px]');
      setFactoringWidth('w-[116px]');
      setStatusDefaultValue(statusValue || QB_STATUS_OBJECT?.['Not Sent']);
      setDateOption(DATE_OPTION);
    }

    if (page === ACCT_PAGE?.qbReconcile) {
      setIsShowStatus(true);
      setStatusOption(AP_AR_RECONCILE_OPTION);
      setAPARWidth('w-[153px]');
      setFactoringWidth('w-[116px]');
      setStatusDefaultValue(statusValue || 'ALL');
      setDateOption(QB_RECONCILE_DATE_OPTION);
    }
  }, []);

  useEffect(() => {
    const categoryValue = watch('category');

    setIsDisabledKeyWord(!categoryValue);
    setIsShowDriverType(categoryValue === 'driver');
  }, [watch('category')]);

  useEffect(() => {
    setKeywordWidth(isShowDriverType ? 'w-[161px]' : 'w-[255px]');
  }, [isShowDriverType]);

  return {
    isShowStatus,
    setIsShowStatus,
    isShowDriverType,
    setIsShowDriverType,

    apARTypeWidth,
    setAPARWidth,
    factoringWidth,
    setFactoringWidth,
    keyWordWidth,
    setKeywordWidth,

    isDisabledFactoring,
    setIsDisabledFactoring,
    isDisabledKeyWord,
    setIsDisabledKeyWord,

    lastDateRange,
    setLastDateRange,

    statusDefaultValue,
    setStatusDefaultValue,
    factoringDefaultValue,
    setFactoringDefaultValue,

    statusOption,
    setStatusOption,
    categoryOption,
    setCategoryOption,
    dateOption,
    setDateOption,
  };
};

export default useControlAdvancedSearch;
