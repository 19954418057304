import React from 'react';
import { useFormContext } from 'react-hook-form';
import StyledCommonErrorModalWrapper from '../../../../../../styles/Common/CommonErrorModal/StyledCommonErrorModalWrapper';
import CommonRadioInput from '../../../../../Inputs/CommonRadioInput/CommonRadioInput';
import CommonTextInput from '../../../../../Inputs/CommonTextInput/CommonTextInput';
import { WO_DELETE_REASON } from '../../../../../../constants/BCDetail/DetailInfo/BasicInfo/deleteOption';

const DeleteContents = () => {
  const { watch } = useFormContext();
  const otherSelect = watch('reason');

  return (
    <StyledCommonErrorModalWrapper className="h-[110px]">
      <div className="flex flex-col  w-[340px] gap-x-[10px] mt-[11px]">
        <div className="flex justify-between">
          <div className="w-fit">
            <CommonRadioInput
              inputName="reason"
              defaultValue={false}
              labelVisible={false}
              required
              onChangeHandler={({ e, onChange }) => {
                onChange?.(e?.target?.value);
              }}
              options={WO_DELETE_REASON.LAD001}
            />
          </div>
          <div className="w-fit">
            <CommonRadioInput
              inputName="reason"
              defaultValue={false}
              labelVisible={false}
              required
              onChangeHandler={({ e, onChange }) => {
                onChange?.(e?.target?.value);
              }}
              options={WO_DELETE_REASON.LAD002}
            />
          </div>
          <div className="w-fit">
            <CommonRadioInput
              inputName="reason"
              defaultValue={false}
              labelVisible={false}
              required
              onChangeHandler={({ e, onChange }) => {
                onChange?.(e?.target?.value);
              }}
              options={WO_DELETE_REASON.LAD003}
            />
          </div>
        </div>
        <div className="flex justify-between">
          <div className="w-fit">
            <CommonRadioInput
              inputName="reason"
              defaultValue={false}
              labelVisible={false}
              required
              onChangeHandler={({ e, onChange }) => {
                onChange?.(e?.target?.value);
              }}
              options={WO_DELETE_REASON.LAD004}
            />
          </div>
          <div className="w-[280px] h-[30px]">
            <CommonTextInput
              inputName="other"
              disabled={otherSelect !== 'LAD004'}
              labelVisible={false}
              onChangeHandler={({ e, onChange }) => {
                onChange?.(e?.target?.value);
              }}
            />
          </div>
        </div>
      </div>
    </StyledCommonErrorModalWrapper>
  );
};

export default DeleteContents;
