import React from 'react';
import { PATH_MODAL_CLOSE_ICON } from '../../../../constants/Common/path';

const SideModalHeader = ({ setIsOpenSideModal }) => {
  return (
    <div className="w-full mt-[45px] mb-[40px] flex items-center">
      <div className="ml-[74px] font-[700] text-[14px] text-[#001E5F]">
        Update User Information
      </div>
      <div
        className="flex justify-center items-center cursor-pointer ml-[95px]"
        onClick={() => {
          setIsOpenSideModal?.(false);
        }}
      >
        <img
          src={PATH_MODAL_CLOSE_ICON}
          width={12}
          height={12}
          alt="Modal Close Icon"
        />
      </div>
    </div>
  );
};

export default SideModalHeader;
