import dayjs from 'dayjs';
import generateAuthInfo from '../Generator/generateAuthInfo';

export const changeText = ({ e, ...props }) => {
  const { onChange, regex } = props;
  if (regex && !regex.test(e?.target?.value)) {
    return;
  }
  onChange?.(e?.target?.value);
};

export const changeSelect = ({ e, ...props }) => {
  const { onChange } = props;
  onChange?.(e?.target?.value);
};

export const changeAuth = ({
  e,
  authList,
  setSelectedAuth,
  setIsChangeAuth,
  ...props
}) => {
  setIsChangeAuth(true);
  changeSelect({ e, ...props });
  setSelectedAuth(
    generateAuthInfo({ authList, selectedAuth: e?.target?.value }),
  );
};

export const changeDivisions = ({ e, divList, selectedDivList, ...props }) => {
  const { setIsChangeDivision, setValue, setSelectedDivList, onChange } = props;

  setIsChangeDivision(true);
  const changeValue = e?.target?.checked;
  onChange(changeValue);

  if (props?.name === 'ALL') {
    divList
      ?.map(div => div?.div)
      ?.forEach(div => {
        setValue(div, changeValue);
      });

    const newSelectedDivList = [...selectedDivList].map(div => {
      return { ...div, value: changeValue };
    });

    setSelectedDivList(newSelectedDivList);
  }
};
