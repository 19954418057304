const convertDatesToMMDDYY = obj => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (
      typeof value === 'string' &&
      (/^\d{4}-\d{2}-\d{2}$/.test(value) ||
        /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/.test(value))
    ) {
      const [year, month, day] = value.split('-');
      acc[key] = `${month}/${day}/${year.slice(-2)}`;
    } else {
      acc[key] = value;
    }

    return acc;
  }, {});
};

export default convertDatesToMMDDYY;
