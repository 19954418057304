import React from 'react';
import AcctTableHeader from './AcctTableHeader';
import QBAPARDetailTableCell from './QBAPARDetailTableCell';
import { DETAIL_TABLE_HEADERS } from '../../../../constants/Acct/Common/qpDetailTableHeader';

const QBAPARDetailTable = ({ ...props }) => {
  return (
    <div className="w-full bg-[#DDE2EC]">
      <div className="py-[10px] px-[69px]">
        <AcctTableHeader headers={DETAIL_TABLE_HEADERS} />
        <QBAPARDetailTableCell
          rowData={props?.data}
          headers={DETAIL_TABLE_HEADERS}
        />
      </div>
    </div>
  );
};

export default QBAPARDetailTable;
