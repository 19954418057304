import React from 'react';
import CommonMessageModal from '../../../../../CommonMessageModal/CommonMessageModal';
import ScheduleDeleteModalContent from './ScheduleDeleteModalContent';
import ScheduleDeleteModalButtonContent from './ScheduleDeleteModalButtonContent';

const ScheduleDeleteModal = ({ ...props }) => {
  /** Modal opener */
  const {
    deleteModalOpener,
    setDeleteModalOpener,
    setLoadingOpener,
    setMessageModalOpener,
  } = props;

  /** Data */
  const { selectedScheduleSeq } = props;

  /** Data setter */
  const { setSelectedScheduleSeq, setMessageModalMessage } = props;

  /** Render component */
  return (
    <CommonMessageModal
      open={deleteModalOpener}
      setOpen={setDeleteModalOpener}
      header="Alert"
      content={<ScheduleDeleteModalContent />}
      buttonContent={
        <ScheduleDeleteModalButtonContent
          selectedScheduleSeq={selectedScheduleSeq}
          setDeleteModalOpener={setDeleteModalOpener}
          setSelectedScheduleSeq={setSelectedScheduleSeq}
          setLoadingOpener={setLoadingOpener}
          setMessageModalOpener={setMessageModalOpener}
          setMessageModalMessage={setMessageModalMessage}
        />
      }
    />
  );
};

export default ScheduleDeleteModal;
