import dayjs from 'dayjs';
import changePeriodHandler from '../../Common/changePeriod';

export const changeText = ({ e, ...props }) => {
  const { onChange, regex } = props;
  if (regex && !regex.test(e?.target?.value)) {
    return false;
  }
  onChange?.(e?.target?.value);
  return true;
};

export const changeSelect = ({ e, ...props }) => {
  const { onChange } = props;
  onChange?.(e?.target?.value);
  return true;
};

export const changePeriod = ({ watch, setValue, e, ...props }) => {
  const { onChange, setLastDateRange } = props;

  const periodData = e?.target?.value;
  onChange(periodData);

  if (periodData) {
    const dateRange = changePeriodHandler(periodData);
    setValue('created-from', dateRange?.fromDate || '');
    setValue('created-to', dateRange?.toDate || '');
    setLastDateRange(dateRange);
  }
};

export const changeRangeDate = ({
  watch,
  setValue,
  type,
  lastDateRange,
  e,
  ...props
}) => {
  const { onChange } = props;
  const dateValue =
    e && dayjs(e)?.isValid() ? dayjs(e)?.format('YYYY-MM-DD') : null;
  onChange?.(dateValue);

  const compareKeyName = type === 'from' ? 'created-from' : 'created-to';

  if (lastDateRange?.[compareKeyName] !== dateValue) {
    setValue('period', '');
  }
};
