import React from 'react';
import CommonMessageModal from '../../../../../CommonMessageModal/CommonMessageModal';
import ConfirmMobileJobStatusModalContent from './ConfirmMobileJobStatusModalContent';
import ConfirmMobileJobStatusModalButton from './ConfirmMobileJobStatusModalButton';

const ConfirmMobileJobStatusModal = ({
  open,
  setOpen,
  onConfirm,
  ...props
}) => {
  return (
    <CommonMessageModal
      open={open}
      setOpen={setOpen}
      header="Warning"
      content={<ConfirmMobileJobStatusModalContent />}
      buttonContent={
        <ConfirmMobileJobStatusModalButton
          setOpen={setOpen}
          onConfirm={onConfirm}
        />
      }
    />
  );
};

export default ConfirmMobileJobStatusModal;
