const COLUMN_ITEM_PAIR = {
  do: 'DO',
  cf: 'CF',
  bond_entry: 'Bond Entry #',
  billto: 'Bill To',
  mbl_no: 'MBL #',
  hbl_no: 'HBL #',
  ref_no: 'REF #',
  purchase_no: 'PO #',
  dilv_no: 'Delivery #',
  ch_no: 'Chassis #',
  pool: 'Pool',
  wms: 'WMS',
  ssl: 'SSL',
  vessel: 'Vessel',
  eta: 'ETA',
  lfd: 'LFD',
  freedays: 'Freedays',
  freedays_type: 'Freedays Type',
  remarks: 'Remarks',
  ava_dsc: 'DSC',
  ava_obl: 'OBL',
  ava_ca: 'CA',
  ava_bo: 'BO',
  ava_cus: 'CUS',
  ava_oth: 'OTH',
  ava_na: 'NA',
  sendto: 'Send to',
  sendfrom: 'Send from',
  po_loc: 'Pull Out Location',
  po_location: 'Pull Out Location',
  po_date: 'Pull Out Date',
  po_time: 'Pull Out Time',
  dilv_loc: 'Delivery Location',
  dilv_location: 'Delivery Location',
  dilv_date: 'Delivery Date',
  dilv_time: 'Delivery Time',
  rtn_loc: 'Return Location',
  rtn_location: 'Return Location',
  rtn_date: 'Return Date',
  rtn_time: 'Return Time',
  over_height: 'O/H',
  over_weight: 'O/W',
  hazmat: 'HAZ',
  hot: 'HOT',
  sche_type: 'DROP/LIVE',
  other: 'Other',
  erd: 'ERD',
  cutoff: 'Cutoff',
  bk_no: 'Booking #',
  bk_seq: 'Booking Sequence',
  cont_no: 'Container #',
  trailer_no: 'Trailer #',
  equip_no: 'Equip #',
  size: 'Size',
  type: 'Type',
  wgt: 'WGT',
  qty: 'QTY',
  unit: 'Unit',
  seal: 'Seal',
  commodity: 'Commodity',
  category: 'Position',
  to_location: 'To Location',
  from_location: 'From Location',
  rate: 'Rate',
  driver: 'Driver',
  country: 'Country',
  address_01: 'Address_01',
  address_02: 'Address_02',
  state: 'State',
  zip: 'Zip',
  city: 'City',
  qb_id: 'QB ID',
  office_no: 'Office #',
  cell_no: 'Cell #',
  div: 'Division',
  email: 'Email',
  content: 'Content',
  seq: 'Sequence',
  trucking_charge: 'Trucking Charge',
  effective_date: 'Effective Date',
  tier: 'Tier',
  effective_month: 'Effective Month',
  effective_year: 'Effective Year',
  status: 'Status',
  po_ch_no: 'P/OUT Chassis #',
  po_pool: 'P/OUT Pool',
  wave: 'Wave',
  rfd: 'RFD',
  empty_load_cfm: 'Empty Load Confirmation',
  location: 'Location',
  sche_date: 'Schedule Date',
  sche_time: 'Schedule Time',
  actual_in_date: 'Actual in Date',
  actual_in_time: 'Actual in Time',
  completed: 'Completed',
  related_wo: 'Related WO',
  st: 'Street Turn',
  deleted: 'Deleted',
  ap: 'AP',
  ar: 'AR',
  contract_date: 'Contract Date',
  terminate_date: 'Terminate Date',
  active: 'Active',
  driver_1099: '1099 Driver',
  business_name: 'Business Name',
  legal_name: 'Legal Name',
  ein_no: 'EIN #',
  mc_no: 'MC #',
  dot_no: 'DOT #',
  first_name: 'First Name',
  mid_name: 'Mid Name',
  last_name: 'Last Name',
  gender: 'Gender',
  dob: 'DOB',
  photo_file: 'Photo File',
  home_no: 'Home #',
  emergency_contact: 'Emergency Contact',
  emergency_phone_no: 'Emergency Phone #',
  truck_no: 'Truck #',
  truck_maker: 'Truck Maker',
  truck_model: 'Truck Model',
  truck_year: 'Truck Year',
  plate_no: 'Plate #',
  truck_state: 'Truck State',
  vin_no: 'VIN #',
  bank: 'Bank',
  bank_type: 'Bank Type',
  bank_file: 'Bank File',
  void_check: 'Void Check',
  routing_no: 'Routing #',
  acct_no: 'Account #',
  dl_no: 'DL #',
  dl_exp: 'DL Exp',
  dl_file: 'DL File',
  medical_no: 'Medical #',
  medical_exp: 'Medical Exp',
  medical_file: 'Medical File',
  ssn: 'SSN',
  ssn_file: 'SSN File',
  twic_exp: 'TWIC Exp',
  twic_file: 'TWIC File',
  insurance_no: 'Insurance #',
  insurance_exp: 'Insurance Exp',
  insurance_company: 'Insurance Company',
  insurance_file: 'Insurance File',
  application: 'Application',
  w9: 'W9',
  cloud_id: 'Cloud ID',
  adp_id: 'ADP ID',
  hire_date: 'Hire Date',
  rehire_date: 'Rehire Date',
  marital_status: 'Marital Status',
  tobacco: 'Tobacco',
  race: 'Race',
  legal_status: 'Legal Status',
  nationality: 'Nationality',
  legal_exp: 'Legal Exp',
  per_hour: 'Per Hour',
  per_load: 'Per Load',
  mvr: 'MVR',
  mvr_file: 'MVR File',
  road_test: 'Road Test',
  road_test_file: 'Road Test File',
  drug_test: 'Drug Test',
  drug_test_file: 'Drug Test File',
  yrs_10: 'Yrs 10',
  tax_form: 'Tax Form',
  w4: 'W4',
  i9: 'I9',
  annual_violation: 'Annual Violation',
  acct_code: 'Account Code',
  acct_code_name: 'Account Code Name',
  product_code: 'Service Code',
  product_code_name: 'Service Code Name',
  list_name: 'List Name',
  seq_list: 'Sequence List',
  action: 'Action',
  confirm_status: 'Confirm Status',
  message: 'Message',
  group_id: 'Group ID',
  timezone: 'Time Zone',
  contact_name: 'Contact Name',
  ext_no: 'EXT #',
  fax: 'FAX',
  acct_name: 'ACCT Name',
  acct_phone: 'ACCT Phone',
  acct_fax: 'ACCT Fax',
  acct_email: 'ACCT Email',
  credit_hold: 'Credit Hold',
  terms: 'Terms',
  credit_limit: 'Credit Limit',
  factoring: 'Factoring',
  credit_app_filed: 'Credit Application Field',
  auto_tracking: 'Automatically Tracking',
  vendor_1099: '1099 Vendor',
  wo_from: 'From WO #',
  wo_from_date: 'From Wo # Date',
  wo_to: 'To WO #',
  wo_to_date: 'To Wo # Date',
  ngl_used: 'NGl Used',
  inv_file: 'Invoice File',
  inv_date: 'Invoice Date',
  inv_from: 'Invoice From Date',
  inv_to: 'Invoice To Date',
  inv_days: 'Invoice Days',
  inv_amt: 'AMT',
  vendor: 'Vendor',
  name: 'Name',
  stat: 'Stat',
  period: 'Period',
  'div-all': 'Div all',
  pod: 'POD',
  pol: 'POL',
  live_dilv_location: 'Delivery Location',
  wo: 'WO',
  user: 'User',
  div_name: 'Division Name',
  wo_rule: 'WO Rule',
  po_sche: 'Pull Out Schedule',
  po_schedule: 'Pull Out Schedule',
  po_actual_in: 'Pull Out Actual In',
  po_actual_out: 'Pull Out Actual Out',
  dilv_sche: 'Delivery Schedule',
  dilv_schedule: 'Delivery Schedule',
  dilv_actual_in: 'Delivery Actual In',
  dilv_actual_out: 'Delivery Actual Out',
  pu_location: 'Pick Up Location',
  pu_sche: 'Pick Up Schedule',
  pu_schedule: 'Pick Up Schedule',
  pu_actual_in: 'Pick Up Actual In',
  pu_actual_out: 'Pick Up Actual Out',
  rtn_sche: 'Return Schedule',
  rtn_schedule: 'Return Schedule',
  rtn_actual_in: 'Return Actual In',
  rtn_actual_out: 'Return Actual Out',
  dilv_prev_location: 'Previous Location of Delivery',
  dilv_prev_actual_in: 'Actual In of Previous Location of Delivery',
  dilv_prev_actual_out: 'Actual Out of Previous Location of Delivery',
  pu_next_location: 'Next Location of Pick Up',
  pu_next_sche_date: 'Schedule of Next Location of Pick Up',
  before: 'before',
  after: 'after',
  export: 'Export',
  group_name: 'Group Name',
  first_page: 'First Page',
  delete: 'Delete',
  convert: 'Convert',
  switch_schedule_type: 'Switch schedule type of',
  job: 'Job',
  fsc_percent: 'FSC %',
  fsc_amt: 'FSC AMT',
  phone: 'Phone number',
  acct_cell_no: 'ACCT Cell #',
  acct_office_no: 'ACCT Office #',
  created_by: 'Created By',
  expire_date: 'Expire Date',
  expire_time: 'Expire Time',
  description: 'Description',
  equipment: 'Equipment',
  bill_no: 'Bill #',
  edi_key: 'EDI Key',
  edi_value: 'EDI Value',
  sender: 'Sender',
};

export default COLUMN_ITEM_PAIR;
