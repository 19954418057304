import { Button, Checkbox } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  addGeofence,
  delGeofence,
} from '../../../../utils/Trace/updateGeofence';
import getGeofence from '../../../../services/Trace/Geofence/Get/getGeofence';
import StyledTraceGeofenceCheckboxWrapper from '../../../../styles/Trace/Geofence/Checkbox/StyledTraceGeofenceCheckboxWrapper';

/**
 * If click checkbox, you can see the geofence which company refers to.
 * You can edit geofence too.
 * The button is used to move the camera with a checked geofence.
 *
 * @param company
 * Company ID separating check boxes
 * @param geofenceList
 * The list which stores the geofence data.
 * @param setGeofenceList
 * Set the data of the geofenceList.
 * @param mapSettings
 * Settings of the Google Map.
 * @param setMapSettings
 * Set the Google Map settings.
 * @returns
 * Checkbox and Button component
 */
const GeofenceCheckbox = ({
  company,
  geofenceList,
  setGeofenceList,
  mapSettings,
  setMapSettings,
}) => {
  // State of checkbox and button
  const [isChecked, setChecked] = useState(false);

  useEffect(() => {
    geofenceList.forEach(geofence => {
      if (geofence.key === company) {
        setChecked(true);
      }
    });
  }, [geofenceList]);

  /**
   * Action to take when the check box is clicked.
   *
   * @param e
   * Checkbox component.
   * @returns
   * Checked geofence list.
   */
  const handleCheck = async e => {
    setChecked(e.target.checked);

    const geofence = await getGeofence(company);

    if (e.target.checked === true) {
      addGeofence(company, geofence.position, geofenceList, setGeofenceList);

      // Zoom geofence
      setMapSettings({ ...mapSettings, center: geofence.center, zoom: 15 });
    } else if (e.target.checked === false) {
      delGeofence(company, geofenceList, setGeofenceList);
    }
  };

  /**
   * Click the button next to the checked check box
   * to go to the corresponding geofence.
   */
  const handleButtonClick = async () => {
    if (isChecked) {
      const geofence = await getGeofence(company);

      // Zoom geofence
      setMapSettings({ ...mapSettings, center: geofence.center, zoom: 15 });
    }
  };

  return (
    <StyledTraceGeofenceCheckboxWrapper>
      <input type="checkbox" onChange={handleCheck} checked={isChecked} />
      <Button
        sx={{
          fontSize: '15px',
          color: '#64696F',
          marginTop: '2px',
        }}
        onClick={handleButtonClick}
      >
        {company}
      </Button>
    </StyledTraceGeofenceCheckboxWrapper>
  );
};

export default GeofenceCheckbox;
