import React, { useState } from 'react';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import MobileMemoArea from '../../../components/BCDetail/Memo/MobileMemoArea';
import WoMemoArea from '../../../components/BCDetail/Common/WoMemoArea';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import useGetMobileMemoList from '../../../hooks/BCDetail/Memo/useGetMobileMemoList';

const Memo = ({ ...props }) => {
  const woNo = document.URL?.split('/')?.pop();

  const [onOkClickHandler, setOnOkClickHandler] = useState(null);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [messageContents, setMessageContent] = useState('');
  const [isModalSuccess, setModalSuccess] = useState(true);

  const modalProps = {
    setOnOkClickHandler,
    setMessageModalOpen,
    setMessageContent,
    setModalSuccess,
  };

  const {
    data: mobileMemoList,
    isLoading: mobileLoading,
    isError: mobileError,
  } = useGetMobileMemoList({
    woNo,
    ...modalProps,
  });

  return (
    <>
      {mobileLoading && <CommonLoading open={mobileLoading} />}
      {!mobileError && !mobileLoading && (
        <div className="bg-white pt-[50px] w-[1920px]">
          <div className="pl-[48px]">
            <MobileMemoArea
              mobileMemoList={mobileMemoList}
              woNo={woNo}
              {...modalProps}
              {...props}
            />
          </div>
          <hr className="my-[50px] bg-[#878D9B]" />
          <div className="pl-[71px] w-[1777px]">
            <WoMemoArea {...modalProps} {...props} />
          </div>
        </div>
      )}
      {messageModalOpen && !isModalSuccess && (
        <CommonErrorModal
          open={messageModalOpen}
          message={messageContents}
          setIsOpen={setMessageModalOpen}
          onOkClickHandler={onOkClickHandler}
          isSuccess={isModalSuccess}
        />
      )}
    </>
  );
};

export default Memo;
