const handleScheduleInputPrevent = ({
  scheduleData,
  component,
  scheType,
  ordering,
  relatedWO,
  isEditable,
  completed,
}) => {
  const woType =
    document.URL?.split('/')?.[document?.URL?.split('/')?.length - 2];

  const {
    type,
    actual_in_date: acctInDate,
    actual_in_time: acctInTime,
    actual_out_date: acctOutDate,
    actual_out_time: acctOutTime,
  } = scheduleData;

  if (!isEditable || !!completed) return true;

  /** Prevent enter data in specific component */
  if (component === 'company') {
    if (scheType === 'DROP' && type === 'P/U' && ordering === 0) return true;
  }

  /** Prevent enter data when acct-in or acct-out data exist */
  if (acctInDate || acctInTime || acctOutDate || acctOutTime) return true;

  /** Prevent Export converted WO */
  if (type === 'P/O' && ordering === 0 && relatedWO) return true;

  return false;
};

export default handleScheduleInputPrevent;
