import mapAPI from '../../api';

const getGeofenceList = async ({ company }) => {
  try {
    const response = await mapAPI.get(`/geofence?company=${company}`);
    return response.data;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getGeofenceList;
