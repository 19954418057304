import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import masterServiceCodeDefaultApi from '../api';

const getServiceCodeList = async () => {
  try {
    const response = await masterServiceCodeDefaultApi.get(`productcode`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getServiceCodeList;
