import React, { useEffect, useState } from 'react';
import { FormControlLabel, FormGroup, Switch, TextField } from '@mui/material';
import LabsInputBoxWrapper from '../../../components/Labs/Input/LabsInputBoxWrapper';

const LabsInput = () => {
  const boxElements = [
    {
      key: 0,
      inputName: 'Plain Text',
      inputType: 'TextInput',
    },
    {
      key: 1,
      inputName: 'Plain Text (Adornment Start Text)',
      inputType: 'TextInputAdornmentStartText',
    },
    {
      key: 2,
      inputName: 'Plain Text (Adornment Start Text w/ OnClick)',
      inputType: 'TextInputAdornmentStartTextOnClick',
    },
    {
      key: 3,
      inputName: 'Plain Text (Adornment Start Icon)',
      inputType: 'TextInputAdornmentStartIcon',
    },
    {
      key: 4,
      inputName: 'Plain Text (Adornment Start Icon w/ OnClick)',
      inputType: 'TextInputAdornmentStartIconOnClick',
    },
    {
      key: 5,
      inputName: 'Plain Text (Adornment End Text)',
      inputType: 'TextInputAdornmentEndText',
    },
    {
      key: 6,
      inputName: 'Plain Text (Adornment End Text w/ OnClick)',
      inputType: 'TextInputAdornmentEndTextOnClick',
    },
    {
      key: 7,
      inputName: 'Plain Text (Adornment End Icon)',
      inputType: 'TextInputAdornmentEndIcon',
    },
    {
      key: 8,
      inputName: 'Plain Text (Adornment End Icon w/ OnClick)',
      inputType: 'TextInputAdornmentEndIconOnClick',
    },
    {
      key: 9,
      inputName: 'Select',
      inputType: 'Select',
    },
    {
      key: 10,
      inputName: 'Auto Complete',
      inputType: 'AutoComplete',
    },
    {
      key: 11,
      inputName: 'Check Box',
      inputType: 'Checkbox',
    },
    {
      key: 12,
      inputName: 'Toggle',
      inputType: 'Toggle',
    },
    {
      key: 13,
      inputName: '3 in 1 Input',
      inputType: 'MultipleInput',
    },
    {
      key: 14,
      inputName: 'Date',
      inputType: 'Date',
    },
    {
      key: 15,
      inputName: 'Time',
      inputType: 'Time',
    },
    {
      key: 16,
      inputName: 'Text/Select',
      inputType: 'TextSelect',
    },
    {
      key: 17,
      inputName: 'File',
      inputType: 'File',
    },
    {
      key: 18,
      inputName: 'Textarea',
      inputType: 'Textarea',
    },
    {
      key: 19,
      inputName: 'Radio Button',
      inputType: 'Radio Button',
    },
  ];

  const [controlState, setControlState] = useState({
    required: false,
    requiredArr: [false, false, false],
    disabled: false,
    disabledArr: [false, false, false],
    borderVisible: false,
    customWidth: '',
    customHeight: '',
    maxLength: 1000,
    labelStyle: false,
  });

  const controlElement = [
    {
      key: 0,
      label: 'Required',
      onClick: () => {
        setControlState({
          ...controlState,
          required: !controlState?.required,
        });
      },
    },
    {
      key: 1,
      label: 'Required Multiple',
      onClick: () => {
        setControlState({
          ...controlState,
          requiredArr: controlState?.requiredArr?.[0]
            ? [false, false, false]
            : [true, true, true],
        });
      },
    },
    {
      key: 2,
      label: 'Disabled',
      onClick: () => {
        setControlState({
          ...controlState,
          disabled: !controlState?.disabled,
        });
      },
    },
    {
      key: 3,
      label: 'Disabled Multiple',
      onClick: () => {
        setControlState({
          ...controlState,
          disabledArr: controlState?.disabledArr?.[0]
            ? [false, false, false]
            : [true, true, true],
        });
      },
    },
    {
      key: 4,
      label: 'Border Visible',
      onClick: () => {
        setControlState({
          ...controlState,
          borderVisible: !controlState?.borderVisible,
        });
      },
    },
    {
      key: 5,
      label: 'Label Style',
      onClick: () => {
        setControlState({
          ...controlState,
          labelStyle: !controlState?.labelStyle,
        });
      },
    },
  ];

  const controlInputElement = [
    {
      key: 0,
      label: 'Custom Width',
      onChange: e => {
        setControlState({
          ...controlState,
          customWidth: e?.target?.value,
        });
      },
    },
  ];

  useEffect(() => {
    console.log(controlState?.customWidth);
  }, [controlState?.customWidth]);

  return (
    <div className="flex flex-col flex-wrap">
      <div className="flex w-full bg-[#FFF] p-[10px] mt-[10px] border-2 border-black flex-wrap">
        <FormGroup className="flex flex-row">
          {controlElement?.map(element => (
            <FormControlLabel
              style={{ pointerEvents: 'none' }}
              key={element?.key}
              control={<Switch style={{ pointerEvents: 'auto' }} />}
              {...element}
            />
          ))}
        </FormGroup>
      </div>
      <div className="flex w-full bg-[#FFF] p-[10px] mt-[10px] border-2 border-black flex-wrap">
        <FormGroup className="flex flex-row">
          {controlInputElement?.map(element => (
            <FormControlLabel
              key={element?.key}
              control={
                <TextField
                  label={element?.label}
                  variant="outlined"
                  size="small"
                  sx={{
                    marginLeft: '10px',
                  }}
                />
              }
              onChange={element?.onChange}
              label="Now I Testing...."
            />
          ))}
        </FormGroup>
      </div>
      <div className="flex flex-wrap">
        {boxElements.map(element => (
          <LabsInputBoxWrapper
            key={element?.key}
            {...element}
            {...controlState}
          />
        ))}
      </div>
    </div>
  );
};

export default LabsInput;
