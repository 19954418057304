import React from 'react';
import StyledSidebarBlurWrapper from '../../../styles/Common/Sidebar/StyledSidebarBlurWrapper';
import handleSidebarOpen from '../../../utils/Common/Sidebar/Handler/handleSidebarOpen';

/**
 * @description
 * Sidebar blur component.
 * @param {function} setOpen
 * Function to handle the sidebar open event.
 * @returns {React.ReactElement}
 */
const SidebarBlur = ({ ...props }) => {
  const { setOpen } = props;

  return (
    <StyledSidebarBlurWrapper
      onClick={() => handleSidebarOpen({ setIsSidebarOpen: setOpen })}
      // Delete because of performance issue
      // className="backdrop-blur-sm"
    />
  );
};

export default SidebarBlur;
