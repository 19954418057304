import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import masterServiceCodeDefaultApi from '../api';

const patchServiceCode = async ({ pc, division, data }) => {
  try {
    const response = await masterServiceCodeDefaultApi.patch(
      `productcode/${encodeURIComponent(pc)}?div=${division}`,
      data,
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchServiceCode;
