import { useMutation, useQueryClient } from '@tanstack/react-query';
import patchDetailInfo from '../../../services/BCDetail/DetailInfo/Patch/patchDetailInfo';
import bcDetailKeys from '../keys';
import useControlToast from '../../Common/Toast/useControlToast';
import { SUCCESS_UPDATE } from '../../../constants/Common/successMessage';
import updateButtonDisabled from '../../../utils/Common/Handler/updateButtonDisabled';

const usePatchDetailInfo = ({
  setModalOpen,
  setModalMessage,
  setModalSuccess,
}) => {
  const { addToast } = useControlToast();

  const queryClient = useQueryClient();
  const mutator = useMutation({
    mutationFn: patchDetailInfo,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: bcDetailKeys.detailInfo(variables?.woNo),
      });
      // Toast message
      addToast({ message: SUCCESS_UPDATE });
    },
    onError: error => {
      setModalMessage?.(error?.message);
      setModalSuccess(prev => false);
      setModalOpen?.(true);
    },
  });

  return { ...mutator };
};

export default usePatchDetailInfo;
