import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SUCCESS_UPDATE } from '../../../constants/Common/successMessage';
import patch204 from '../../../services/EDI/Patch/patch204';
import useControlToast from '../../Common/Toast/useControlToast';
import ediKeys from '../keys';
import updateButtonDisabled from '../../../utils/Common/Handler/updateButtonDisabled';

const usePatch204Mutation = ({
  setIsAlertOpen,
  setIsSuccessAlert,
  setAlertMessage,
  div,
  id,
  from,
  to,
  sender,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  return useMutation({
    mutationFn: patch204,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ediKeys?.get204List({ div, from, to, sender }),
      });
      queryClient.refetchQueries({ queryKey: ediKeys?.get204Detail({ id }) });
      addToast({
        message: SUCCESS_UPDATE,
      });
    },
    onError: error => {
      queryClient.refetchQueries({
        queryKey: ediKeys?.get204List({ div, from, to, sender }),
      });
      setIsSuccessAlert(false);
      setAlertMessage(error.message);
      setIsAlertOpen(true);
    },
  });
};

export default usePatch204Mutation;
