import React from 'react';
import CommonSelect from '../../../Inputs/CommonSelect/CommonSelect';

const TimeZoneContainer = ({
  timeZoneOption,
  timeZone,
  div,
  patchTimeZoneMutation,
}) => {
  return (
    <>
      <div className="text-stone-600 text-xs font-medium">TIME ZONE</div>
      <CommonSelect
        width="w-[330px] mt-[-12px]"
        inputName="timezone"
        placeholder="Select"
        defaultValue={timeZone}
        option={timeZoneOption}
        disabled
        onChangeHandler={({ e, onChange }) => {
          onChange(e?.target?.value);
          patchTimeZoneMutation({
            div,
            timezone: e?.target?.value?.split(' ')[0],
          });
        }}
      />
    </>
  );
};

export default TimeZoneContainer;
