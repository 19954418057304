import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import defaultInputRules from '../../../utils/Common/Input/defaultInputRules';

const CommonModalDate = ({ ...props }) => {
  /**
   * @description
   * Get react-hook-form object from useFormContext.
   * @control Must-have elements for getting values from a form.
   * @unregister Allows to unregister a single input or an array of inputs.
   * @setValue Setter value of input.
   * @formState Object containing information about the form.
   */
  const {
    control,
    unregister,
    setValue,
    formState: { errors: formErrors },
  } = useFormContext();

  /**
   * @textCenter {string} - Text align of input.
   * @backgroundColor {string} - Background color of input.
   * @textColor {string} - Text color of input.
   * @label {string} - Label of input.
   * @required {boolean} - Required input.
   * @disabled {boolean} - Disable input.
   * @defaultValue {any} - Default value of input.
   */

  const [textCenter, setTextCenter] = useState(props?.textCenter || false);

  const [backgroundColor, setBackgroundColor] = useState(
    props?.backgroundColor || '#FFF',
  );

  const [textColor, setTextColor] = useState(props?.textColor || '#666');

  const [label, setLabel] = useState(props?.label);

  // Set default required as false
  const [required, setRequired] = useState(props?.required || false);
  useEffect(() => {
    /**
     * If default required value changed, component required value would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.required !== undefined && props?.required !== null) {
      setRequired(props?.required);
    }
  }, [props?.required]);

  // Set default disabled as false
  const [disabled, setDisabled] = useState(props?.disabled || false);
  useEffect(() => {
    /**
     * If default disabled value changed, component disabled value would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.disabled !== undefined && props?.disabled !== null) {
      setDisabled(props?.disabled);
    }
  }, [props?.disabled]);

  // Set default input value as null.
  const [defaultValue, setDefaultValue] = useState(props?.defaultValue || null);
  useEffect(() => {
    /**
     * If default value changed, component default value would be changed too.
     * Undefined exception handling. Null allowed value.
     */
    if (props?.defaultValue !== undefined) {
      setDefaultValue(props?.defaultValue);
      setValue(props?.inputName, props?.defaultValue);
    }
  }, [props?.defaultValue]);

  useEffect(() => {
    /**
     * Unregister input when component unmount.
     * Unregister single input only.
     */
    return () => unregister(props?.inputName);
  }, []);

  /**
   * @description
   * Render Date input component.
   * @control - Must-have elements for getting values from a form.
   * @name - Name of input
   * @defaultValue - Default value of input.
   * @rules - Rules of input.
   * @render - Render target input component.
   * - field : Object containing field props, like name, value, onChange, onBlur.
   * - fieldState : Object containing field state, like error, invalid, etc.
   */
  return (
    <Controller
      control={control}
      name={props?.inputName}
      defaultValue={
        defaultValue && dayjs(defaultValue).isValid()
          ? dayjs(defaultValue)
          : null
      }
      rules={defaultInputRules({
        required,
        ...props,
      })}
      render={({
        field: { ref, onChange, value, ...field },
        fieldState: { invalid, error },
      }) => {
        // Package of handler props
        const handlerProps = {
          onChange,
          value,
          ...field,
          ...props,
        };
        // Render Control Input Component
        return (
          <div>
            <div
              className={`${props?.width ? `${props?.width}` : 'w-full'} 
              `}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  {...field}
                  inputRef={ref}
                  error={invalid}
                  value={value && dayjs(value)?.isValid() ? dayjs(value) : null}
                  /**
                   * onChange Handler. Only handlers of parameters work.
                   * To change value, should use Controller field's onChange changer.
                   * ex)
                   * (tempValue) => {
                   *    onChange(tempValue);
                   * }
                   */
                  onChange={e =>
                    props?.onChangeHandler?.({ e, ...handlerProps })
                  }
                  disabled={disabled}
                  format="MM/DD/YY"
                  className={`${
                    disabled ? 'bg-[#DDE2EC]' : 'bg-white'
                  } rounded-none`}
                  slotProps={{
                    popper: {
                      sx: {
                        '&.MuiPickersPopper-root': {
                          zIndex: '10000 !important',
                        },
                      },
                    },
                    textField: {
                      error: invalid,
                      sx: {
                        input: {
                          textAlign: `${textCenter ? 'center' : ''}`,
                          color: `${textColor} !important`,
                          zIndex: '999',
                          ':focus': {
                            color: 'black',
                          },
                        },
                        svg: {
                          zIndex: '999',
                          color: !disabled && '#264B9F !important',
                        },
                        '&.MuiFormControl-root': {
                          width: '100%',
                        },
                        '.MuiInputBase-root': {
                          height: '30px',
                          fontSize: '12px',
                          fontWeight: '500',
                          borderRadius: '0px',
                          '&:not(.Mui-focused):not(.Mui-disabled):hover fieldset':
                            !formErrors?.[props?.inputName]?.message && {
                              border: '1px solid #8E9396 !important',
                            },
                          '&.Mui-focused fieldset': {
                            border: '1.2px solid #264B9F !important',
                            boxShadow: '0px 0px 4px 0px #8BBCE9 !important',
                            backgroundColor: '#fff !important',
                          },
                          '&.Mui-error fieldset': {
                            border: '1.2px solid #C24D4D !important',
                            backgroundColor: '#fff !important',
                          },
                          '&.Mui-disabled fieldset': {
                            backgroundColor: 'unset !important',
                            borderRadius: '0px',
                          },
                        },
                        '.MuiSvgIcon-root': {
                          fontSize: '16px',
                        },
                        fieldset: {
                          border: 'none',
                          backgroundColor: `${backgroundColor}`,
                          boxShadow: '0px 0.5px 1px rgba(0, 0, 0, 0.25)',
                        },
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            </div>
          </div>
        );
      }}
    />
  );
};

export default CommonModalDate;
