import adminDefaultAPI from '../../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

const patchUserInfo = async ({ id, patchData }) => {
  try {
    const response = await adminDefaultAPI.patch(`user/${id}`, patchData);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchUserInfo;
