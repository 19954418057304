import styled from 'styled-components';
import getBackgroundColor from '../../../utils/Trace/Map/getBackGroundColor';

const StyledTraceMapVehicleTypeLabel = styled.div`
  display: flex;
  align-items: center;
  height: 33px;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: ${({ vehicleType }) => getBackgroundColor(vehicleType)};
`;

export default StyledTraceMapVehicleTypeLabel;
