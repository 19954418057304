import { useQuery } from '@tanstack/react-query';
import mainKeys from '../keys';
import { getMainList } from '../../../services/Main/Get/getMainList';

const useGetMainList = ({ category, selectedDiv, queryString }) => {
  const queryParams = Object.fromEntries(
    Object.entries(queryString).filter(([key, value]) => value !== ''),
  );
  const queryGetter = useQuery({
    queryKey: mainKeys.listDivisionQueryString({
      category,
      selectedDiv,
      queryString,
    }),
    queryFn: () => getMainList({ category, selectedDiv, queryParams }),
    enabled: !!selectedDiv && !!category,
    refetchOnWindowFocus: false,
    staleTime: 0,
  });

  return queryGetter;
};

export default useGetMainList;
