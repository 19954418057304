import React, { useRef, useState } from 'react';
import StyledSaveItemContentWrapper from '../../../../styles/CustomerReport/Common/StyledSaveItemContentWrapper';
import StyledSaveItemLabel from '../../../../styles/CustomerReport/SaveItemModal/StyledSaveItemLabel';
import StyledSaveItemNameInput from '../../../../styles/CustomerReport/SaveItemModal/StyledSaveItemNameInput';
import StyledSaveItemVerifyButton from '../../../../styles/CustomerReport/SaveItemModal/StyledSaveItemVerifyButton';
import { REGEX_ALPHABET_NUMBER_SPACE } from '../../../../constants/Common/regex';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';

const SaveItemContent = ({ ...props }) => {
  /** Get props data */
  const { div, listName, email, customerLink, postSavedMutate, isSaveEnable } =
    props;
  /** Data setter */
  const { setListName, setEmail, setIsSaveEnable, setCustomerLink } = props;

  const buttonRef = useRef(null);

  return (
    <StyledSaveItemContentWrapper>
      <div className="flex flex-col">
        <StyledSaveItemLabel>List Name</StyledSaveItemLabel>
        <div
          className={`flex flex-row ${isSaveEnable ? 'pb-[5px]' : 'pb-[14px]'}`}
        >
          <div className="w-[343px]">
            <StyledSaveItemNameInput
              type="text"
              placeholder="Write your List Name"
              value={listName}
              onChange={e => {
                if (e?.target?.value.match(REGEX_ALPHABET_NUMBER_SPACE)) {
                  setListName(e.target.value);
                  setIsSaveEnable(false);
                  setCustomerLink(null);
                }
              }}
            />
          </div>
          <StyledSaveItemVerifyButton
            ref={buttonRef}
            onClick={() => {
              updateButtonDisabled({ ref: buttonRef, disabled: true });
              postSavedMutate?.({ div, listName: listName?.trim(), buttonRef });
              setCustomerLink(
                `${process.env.REACT_APP_TMS_URL}/customer/${listName
                  ?.trim()
                  ?.replace(/ /g, '%20')}`,
              );
            }}
            disabled={!listName || listName === '' || listName?.trim() === ''}
          >
            Verify
          </StyledSaveItemVerifyButton>
        </div>
        {isSaveEnable && (
          <div className="flex pb-[16px] pl-[15px] text-[12px] font-medium text-[#264B9F]">
            {customerLink}
          </div>
        )}
        <StyledSaveItemLabel>Email</StyledSaveItemLabel>
        <StyledSaveItemNameInput
          type="text"
          inputType="email"
          placeholder="Write Email to send"
          value={email}
          onChange={e => {
            setEmail(e.target.value);
          }}
        />
      </div>
    </StyledSaveItemContentWrapper>
  );
};

export default SaveItemContent;
