import React, { useEffect, useState } from 'react';
import CommonMessageModal from '../../CommonMessageModal/CommonMessageModal';
import SaveItemContent from './SaveItemContent';
import SaveItemButton from './SaveItemButton';
import EmailConfirmModal from '../Common/EmailConfirmModal';
import generateSaveItemData from '../../../utils/CustomerReport/Generator/generateSaveItemData';

const SaveItemModal = ({ ...props }) => {
  /** Get props data */
  const { open, postSavedMutate, postMainMutate, isSaveEnable } = props;
  /** Modal Opener */
  const { setOpen, setMessageOpener } = props;
  /** Data Setter */
  const {
    setIsSuccess,
    setModalMessage,
    div,
    searchParams,
    columnDefs,
    setIsSaveEnable,
  } = props;

  /** Data */
  const [listName, setListName] = useState('');
  const [email, setEmail] = useState('');
  const [customerLink, setCustomerLink] = useState(null);
  const [emailConfirmationOpener, setEmailConfirmationOpener] = useState(false);

  useEffect(() => {
    return () => {
      setListName('');
      setIsSaveEnable(false);
    };
  }, []);

  return (
    <>
      <CommonMessageModal
        open={open}
        setOpen={setOpen}
        header="Save Item"
        content={
          <SaveItemContent
            div={div}
            listName={listName}
            email={email}
            customerLink={customerLink}
            setListName={setListName}
            setEmail={setEmail}
            setCustomerLink={setCustomerLink}
            postSavedMutate={postSavedMutate}
            isSaveEnable={isSaveEnable}
            setIsSaveEnable={setIsSaveEnable}
          />
        }
        buttonContent={
          <SaveItemButton
            setOpen={setOpen}
            setMessageOpener={setMessageOpener}
            setIsSuccess={setIsSuccess}
            setModalMessage={setModalMessage}
            listName={listName}
            email={email}
            div={div}
            searchParams={searchParams}
            columnDefs={columnDefs}
            isSaveEnable={isSaveEnable}
            postMainMutate={postMainMutate}
            setEmailConfirmationOpener={setEmailConfirmationOpener}
          />
        }
      />
      {/* Email Confirmation Modal */}
      {emailConfirmationOpener && (
        <EmailConfirmModal
          open={emailConfirmationOpener}
          setOpen={setEmailConfirmationOpener}
          email={email}
          onSaveClick={({ buttonRef }) => {
            setEmailConfirmationOpener(prev => false);
            const manipulatedPostData = generateSaveItemData({
              listName,
              email,
              div,
              searchParams,
              columnDefs,
            });

            postMainMutate?.({ data: manipulatedPostData, buttonRef });
          }}
        />
      )}
    </>
  );
};

export default SaveItemModal;
