/**
 * @description
 * The onChangeHandler used when enter according to regex
 */
const handleRegexChange = ({ e, onChange, regex, ...handlerProps }) => {
  if (regex) {
    if (e?.target?.value?.match(regex)) {
      onChange?.(e?.target?.value);
      return;
    }
    return;
  }
  onChange?.(e?.target?.value);
};

export default handleRegexChange;
