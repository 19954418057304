const validateCurrentWO = ({ currentWO, woOptionList }) => {
  const isValidWO = !!woOptionList?.find(option => option?.value === currentWO);
  return isValidWO;
};

export const handleModalWOOptionChange = ({
  woFieldName,
  setValue,
  getValues,
  woOptionList,
  pairFieldList,
}) => {
  const isValidWO = validateCurrentWO({
    currentWO: getValues(woFieldName),
    woOptionList,
  });

  if (!isValidWO) {
    setValue(woFieldName, '');

    pairFieldList?.forEach(pairField => setValue?.(pairField, ''));
  }
};

export const handleDataGridWOOptionChange = ({
  gridRef,
  woFieldName,
  woOptionList,
  pairFieldList,
}) => {
  const editorInstance = gridRef?.current?.api?.getCellEditorInstances();
  const woInstance = editorInstance?.find(
    element => element?.cellEditorParams?.column?.colId === woFieldName,
  );

  const isValidWO = validateCurrentWO({
    currentWO: woInstance?.value,
    woOptionList,
  });

  if (!isValidWO) {
    woInstance?.componentInstance?.setDataValue('');
    woInstance?.componentInstance?.onValueChange('');

    pairFieldList?.forEach(pairField => {
      const pairInstance = editorInstance?.find(
        element => element?.cellEditorParams?.column?.colId === pairField,
      );

      pairInstance?.componentInstance?.setDataValue('');
      pairInstance?.componentInstance?.onValueChange('');
    });
  }
};
