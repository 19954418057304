import { useQuery } from '@tanstack/react-query';
import getProductList from '../../../../services/Acct/Common/Get/getProductList';
import { generateProductOption } from '../../../../utils/Acct/Common/Generator/optionGenerator';
import acctKeys from '../../keys';

const useGetProductList = ({ type }) => {
  const { data: productList, isLoading: isProductListLoading } = useQuery({
    queryKey: acctKeys.productList({ type }),
    queryFn: () => getProductList({ type }),
    staleTime: 0,
    refetchOnWindowFocus: false,
  });

  const productOptionsList = generateProductOption({ productList });

  return { productOptionsList, productList, isProductListLoading };
};

export default useGetProductList;
