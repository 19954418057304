import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CommonCheckboxList from '../../../Inputs/CommonCheckboxList';
import CommonButton from '../../../CommonButton/CommonButton';
import handleExcelClick from '../../../../utils/TmsReport/Statistics/Handler/handleExcelClick';
import handleAllCheckboxChange from '../../../../utils/TmsReport/Common/handleAllCheckboxChange';

const HeaderButtons = ({
  setIsOpenAdvancedSearch,
  divCheckboxOption,
  setDivQuery,
  isOpenDivCheckbox,
  setIsOpenDivCheckbox,
  setFilter,
  filterStorage,
  dateQuery,
  ...props
}) => {
  const divisionController = useForm();

  return (
    <div className="flex justify-between my-[20px]">
      <div className="flex justify-center items-center text-[12px] font-[500] text-[#666]">
        Search date: {dateQuery?.date}
      </div>
      <div className="flex gap-x-[5px] justify-end items-center">
        <CommonButton
          category="greySearch"
          onClick={() => {
            setIsOpenAdvancedSearch(true);
          }}
          border="0"
          backgroundColor="transparent"
          color="#666666"
        >
          Search
        </CommonButton>
        <div className="flex h-[14px] border-l-[0.4px] border-[#00000099] self-center" />
        <FormProvider {...divisionController}>
          <CommonCheckboxList
            isOpen={isOpenDivCheckbox}
            options={divCheckboxOption}
            onChangeAll={({ ...handleProps }) => {
              handleAllCheckboxChange({
                ...handleProps,
                setQuery: setDivQuery,
                filterStorage,
                item: 'div',
              });
            }}
            suppressIndeterminate
            button={
              <CommonButton
                category="customizeColumn"
                onClick={() => {
                  setIsOpenDivCheckbox(prev => !prev);
                }}
                border="0"
                backgroundColor="transparent"
                color="#666666"
              >
                Division
              </CommonButton>
            }
          />
        </FormProvider>
        <CommonButton
          category="excel"
          onClick={() => handleExcelClick({ ...props })}
        >
          EXCEL
        </CommonButton>
      </div>
    </div>
  );
};

export default HeaderButtons;
