import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import getDocumentList from '../../../services/BCDetail/Document/Get/getDocumentList';
import bcDetailKeys from '../keys';
import { handleNotFoundError } from '../../../utils/Common/Handler/errorStatusSpecificHandler';

const useGetDocumentList = ({
  woNo,
  setMessageModalOpen,
  setMessageContent,
  setOnOkClickHandler,
}) => {
  const result = useQuery({
    queryKey: bcDetailKeys.document({ woNo }),
    queryFn: () => getDocumentList({ woNo }),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (result?.isError) {
      setMessageModalOpen?.(prev => true);
      setMessageContent?.(prev => result?.error?.message);
      setOnOkClickHandler?.(prev => {
        if (
          result?.error?.message === handleNotFoundError() ||
          result?.error?.message === 'WO # has been deleted.'
        ) {
          return () => {
            window.close();
          };
        }
        return null;
      });
    } else {
      setMessageModalOpen?.(prev => false);
      setMessageContent?.(prev => '');
      setOnOkClickHandler?.(prev => null);
    }
  }, [result?.isError]);

  return {
    documentList: result?.data?.data?.doc,
    docListRefech: result?.refetch,
    ...result,
  };
};

export default useGetDocumentList;
