import styled from 'styled-components';

const StyledCloseContainerInfoButton = styled.img`
  width: 8px;
  height: 8px;

  fill: #264b9f;
  stroke-width: 0.3px;
  stroke: #264b9f;
  cursor: pointer;
`;

export default StyledCloseContainerInfoButton;
