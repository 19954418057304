import React from 'react';
import CommonButton from '../../../CommonButton/CommonButton';
import exportToExcel from '../../../../utils/Common/Datagrid/Handler/exportToExcel';

const HeaderButtons = ({ setAdvancedSearchOpen, gridRef, fileName }) => {
  return (
    <div className="flex my-[20px] justify-end gap-x-[5px]">
      <CommonButton
        category="greySearch"
        onClick={() => {
          setAdvancedSearchOpen(true);
        }}
        border="0"
        backgroundColor="transparent"
        color="#666666"
      >
        Search
      </CommonButton>
      <CommonButton
        category="excel"
        onClick={() => {
          exportToExcel({ gridRef, fileName });
        }}
      >
        EXCEL
      </CommonButton>
    </div>
  );
};

export default HeaderButtons;
