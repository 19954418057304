import React from 'react';
import CommonMessageModal from '../../../CommonMessageModal/CommonMessageModal';
import CommonButton from '../../../CommonButton/CommonButton';
import StyledMasterCommonAlertMessageButton from '../../../../styles/Master/Common/AlertMessage/StyledMastercommonAlertMessageButton';
import StyledCommonErrorModalWrapper from '../../../../styles/Common/CommonErrorModal/StyledCommonErrorModalWrapper';

const DeleteCodeConfirmModal = ({
  isDeleteModal,
  setIsDeleteModal,
  getDeleteTarget,
  deleteServiceCodeMutator,
  setInUseWOList,
  division,
  ...props
}) => {
  return (
    <CommonMessageModal
      setOpen={setIsDeleteModal}
      open={isDeleteModal}
      content={
        <StyledCommonErrorModalWrapper className="flex-col">
          <p>
            {`The code "${getDeleteTarget?.product_code}" will be deleted.`}
          </p>
          <p>Are you sure?</p>
        </StyledCommonErrorModalWrapper>
      }
      buttonContent={
        <StyledMasterCommonAlertMessageButton className="gap-x-[15px]">
          <CommonButton onClick={() => setIsDeleteModal(false)} category="no">
            No
          </CommonButton>
          <CommonButton
            preventDupClick
            onClick={({ buttonRef }) => {
              deleteServiceCodeMutator?.({
                pk: getDeleteTarget?.product_code,
                setInUseWOList,
                division,
                buttonRef,
              });
            }}
            category="yes"
          >
            Yes
          </CommonButton>
        </StyledMasterCommonAlertMessageButton>
      }
      header="Alert Message"
    />
  );
};

export default DeleteCodeConfirmModal;
