import mobileAdminDefaultApi from '../../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

const getPODCheckList = async ({ division }) => {
  try {
    const response = await mobileAdminDefaultApi.get('pod', {
      params: { div: division },
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getPODCheckList;
