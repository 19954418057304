import { useQuery } from '@tanstack/react-query';
import getDriverList from '../../../../services/Acct/Common/Get/getDriverList';
import acctKeys from '../../keys';

const useGetDriverList = () => {
  const { data, isLoading } = useQuery({
    queryKey: acctKeys.driverList(),
    queryFn: () => getDriverList(),
    staleTime: 0,
    refetchOnWindowFocus: false,
  });

  return { driverList: data?.data, isLoadingDriverList: isLoading };
};

export default useGetDriverList;
