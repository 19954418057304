import { useMutation } from '@tanstack/react-query';
import { SUCCESS_SEND } from '../../../../constants/Common/successMessage';
import postQuickBooks from '../../../../services/QuickBooks/Post/postQuickBooks';
import useControlToast from '../../../Common/Toast/useControlToast';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';

const usePostQB = ({
  setIsAlertOpen,
  setIsSuccess,
  setErrorMessage,
  refetchData,
}) => {
  const { addToast } = useControlToast();

  const mutator = useMutation({
    mutationFn: postQuickBooks,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: success => {
      refetchData();
      addToast({
        message: SUCCESS_SEND,
      });
    },
    onError: error => {
      refetchData();
      setIsSuccess(false);
      setIsAlertOpen(true);
      setErrorMessage(error?.message);
    },
    refetchOnWindowFocus: false,
  });

  return mutator;
};

export default usePostQB;
