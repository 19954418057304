import React from 'react';
import APModalTableContentRowCell from '../APModalTableContentRowCell';
import { REGEX_NUMBER_ONLY_POSITIVE_DECIMAL_POINT_TWO } from '../../../../constants/Common/regex';
import APModalTextInput from '../APModalTextInput';
import APModalAutoComplete from '../APModalAutoComplete';
import StyledAPModalTableContentRowWrapper from '../../../../styles/Dispatch/APModal/StyledAPModalTableContentRowWrapper';
import StyledAPModalTableContentRowDataWrapper from '../../../../styles/Dispatch/APModal/StyledAPModalTableContentRowDataWrapper';
import { MINUS_ICON, PLUS_ICON } from '../../../../constants/Order/Common/path';
import generateProductCodeSelectOption from '../../../../utils/Dispatch/Common/Generator/generateProductCodeSelectOption';

const APModalTableContentRow = ({
  data,
  productCodes,
  isLastRow,
  index,
  apTableContent,
  setAPTableContent,
  ...props
}) => {
  /** AP row data */
  const { amount, productCode, productCodeName } = data;

  return (
    <StyledAPModalTableContentRowWrapper>
      <StyledAPModalTableContentRowDataWrapper>
        {/* Select component */}
        <APModalTableContentRowCell
          customStyle={{
            width: '138px',
          }}
        >
          <APModalAutoComplete
            defaultValue={productCode}
            option={generateProductCodeSelectOption({ productCodes })}
            value={productCode}
            renderValue
            onChangeHandler={({ e, selectProps, ...handlerProps }) => {
              /** Set AP table service code content */
              setAPTableContent(prev => {
                return prev.map((content, contentIndex) => {
                  if (contentIndex === index) {
                    return {
                      ...content,
                      productCode: selectProps?.value || '',
                      productCodeName:
                        productCodes?.find(
                          element =>
                            element?.product_code === selectProps?.value,
                        )?.product_code_name || '',
                    };
                  }
                  return content;
                });
              });
            }}
          />
        </APModalTableContentRowCell>

        {/* Representation as Pure Text based on service code selection */}
        <APModalTableContentRowCell
          customStyle={{
            width: '212px',
          }}
        >
          {
            productCodes?.find(element => element?.product_code === productCode)
              ?.product_code_name
          }
        </APModalTableContentRowCell>

        {/* Input component */}
        <APModalTableContentRowCell
          customStyle={{
            width: '104px',
            borderRight: 'none',
          }}
        >
          <APModalTextInput
            value={amount}
            onChangeHandler={({ e }) => {
              setAPTableContent(prev => {
                if (
                  e?.target?.value?.match(
                    REGEX_NUMBER_ONLY_POSITIVE_DECIMAL_POINT_TWO,
                  )
                ) {
                  return prev.map((content, contentIndex) => {
                    if (contentIndex === index) {
                      return {
                        ...content,
                        amount: e?.target?.value,
                      };
                    }
                    return content;
                  });
                }
                return prev;
              });
            }}
          />
        </APModalTableContentRowCell>
      </StyledAPModalTableContentRowDataWrapper>
      <APModalTableContentRowCell
        customStyle={{
          width: '104px',
          borderRight: 'none',
          gap: '10px',
        }}
      >
        {isLastRow && apTableContent?.length < 5 && (
          <div
            className="cursor-pointer"
            onClick={() => {
              /** Add new empty ap row */
              setAPTableContent(prev => {
                return [
                  ...prev,
                  {
                    productCode: '',
                    productCodeName: '',
                    amount: 0,
                  },
                ];
              });
            }}
          >
            <img alt="apAddBtn" src={PLUS_ICON} />
          </div>
        )}
        <div
          className="cursor-pointer"
          onClick={() => {
            /** Delete AP row */
            setAPTableContent(prev => {
              return prev.filter((_, idx) => idx !== index);
            });
          }}
        >
          <img alt="apDelBtn" src={MINUS_ICON} />
        </div>
      </APModalTableContentRowCell>
    </StyledAPModalTableContentRowWrapper>
  );
};

export default APModalTableContentRow;
