import {
  getFormattedDateGenerator,
  getFormattedTimeGenerator,
} from '../../../../Common/Generator/formattedDateTimeGenerator';
import {
  checkJobScheduleChanged,
  checkLocationScheduleChanged,
} from '../Handler/checkScheduleChanged';

/**
 * @description
 * Function that generates updated row item
 * @param {number} seq
 * Sequence of schedule data
 * @param {string} dataKey
 * Key of schedule data
 * @param {string} locationInfo
 * Location information
 * @param {object} chunkData
 * Data of schedule controller
 * @returns {list}
 * Updated row item
 */
const scheduleUpdatedRowItemGenerator = ({
  seq,
  dataKey,
  locationInfo,
  chunkData,
}) => {
  const updatedItem = {};

  updatedItem.method = 'UPDATE';
  updatedItem.seq = seq;
  updatedItem.company = chunkData?.[`company_${dataKey}_${locationInfo}`];
  updatedItem.sche_date = getFormattedDateGenerator({
    date: chunkData?.[`sche_date_${dataKey}_${locationInfo}`],
  });
  updatedItem.sche_time = getFormattedTimeGenerator({
    date: chunkData?.[`sche_date_${dataKey}_${locationInfo}`],
    time: chunkData?.[`sche_time_${dataKey}_${locationInfo}`],
  });
  updatedItem.actual_in_date = getFormattedDateGenerator({
    date: chunkData?.[`actual_in_date_${dataKey}_${locationInfo}`],
  });
  updatedItem.actual_in_time = getFormattedTimeGenerator({
    date: chunkData?.[`actual_in_date_${dataKey}_${locationInfo}`],
    time: chunkData?.[`actual_in_time_${dataKey}_${locationInfo}`],
  });
  updatedItem.actual_out_date = getFormattedDateGenerator({
    date: chunkData?.[`actual_out_date_${dataKey}_${locationInfo}`],
  });
  updatedItem.actual_out_time = getFormattedTimeGenerator({
    date: chunkData?.[`actual_out_date_${dataKey}_${locationInfo}`],
    time: chunkData?.[`actual_out_time_${dataKey}_${locationInfo}`],
  });

  return updatedItem;
};

/**
 * @description
 * Function that generates updated job_set data
 * @param {list} jobSetData
 * Data of job_set from API
 * @param {object} chunkData
 * Data of schedule controller
 * @returns {list}
 * Updated job_set data list
 */
const jobSetUpdatedItemGenerator = ({ jobSetData, chunkData }) => {
  const updatedJobSetData = [];

  jobSetData?.forEach(jobSet => {
    const getJobSetSeq = jobSet?.seq;
    const getFromSeq = jobSet?.from_location?.seq;
    const getLiveDilvSeq = jobSet?.live_dilv_location?.seq;
    const getToSeq = jobSet?.to_location?.seq;

    if (
      jobSet?.from_location &&
      checkJobScheduleChanged({
        jobSetSeq: getJobSetSeq,
        locationSeq: getFromSeq,
        locationInfo: 'fr',
        jobSetData,
        inputData: chunkData,
      })
    ) {
      updatedJobSetData?.push(
        scheduleUpdatedRowItemGenerator({
          seq: getFromSeq,
          scheduleData: jobSet?.from_location,
          dataKey: `${getJobSetSeq}_${getFromSeq}`,
          locationInfo: 'fr',
          chunkData,
        }),
      );
    }
    if (
      jobSet?.live_dilv_location &&
      checkJobScheduleChanged({
        jobSetSeq: getJobSetSeq,
        locationSeq: getLiveDilvSeq,
        locationInfo: 'ld',
        jobSetData,
        inputData: chunkData,
      })
    ) {
      updatedJobSetData?.push(
        scheduleUpdatedRowItemGenerator({
          seq: getLiveDilvSeq,
          scheduleData: jobSet?.live_dilv_location,
          dataKey: `${getJobSetSeq}_${getLiveDilvSeq}`,
          locationInfo: 'ld',
          chunkData,
        }),
      );
    }
    if (
      jobSet?.to_location &&
      checkJobScheduleChanged({
        jobSetSeq: getJobSetSeq,
        locationSeq: getToSeq,
        locationInfo: 'to',
        jobSetData,
        inputData: chunkData,
      })
    ) {
      updatedJobSetData?.push(
        scheduleUpdatedRowItemGenerator({
          seq: getToSeq,
          scheduleData: jobSet?.to_location,
          dataKey: `${getJobSetSeq}_${getToSeq}`,
          locationInfo: 'to',
          chunkData,
        }),
      );
    }
  });

  return updatedJobSetData;
};

/**
 * @description
 * Function that generates updated location_set data
 * @param {list} locationSetData
 * Data of location_set from API
 * @param {object} chunkData
 * Data of schedule controller
 * @returns {list}
 * Updated location_set data list
 */
const generateLocationSetUpdatedItem = ({ locationSetData, chunkData }) => {
  const updatedLocationSetData = [];

  locationSetData?.forEach(locationSet => {
    const getLocationSetSeq = locationSet?.seq;

    if (
      locationSet &&
      checkLocationScheduleChanged({
        locationSetSeq: getLocationSetSeq,
        locationInfo: 'location',
        locationSetData,
        inputData: chunkData,
      })
    ) {
      updatedLocationSetData?.push(
        scheduleUpdatedRowItemGenerator({
          seq: getLocationSetSeq,
          scheduleData: locationSet,
          dataKey: `${getLocationSetSeq}_location`,
          locationInfo: 'location',
          chunkData,
        }),
      );
    }
  });

  return updatedLocationSetData;
};

/**
 * @description
 * Function that generates updated schedule data
 * @param {list} scheduleData
 * Data of job_set or location_set from API
 * @param {string} type
 * Schedule type
 * @param {object} chunkData
 * Data of schedule controller
 * @returns {list}
 * Updated schedule data list
 */
const generateUpdatedScheduleItem = ({ scheduleData, type, chunkData }) => {
  const updatedScheduleData = [];

  if (type === 'job') {
    updatedScheduleData?.push(
      ...jobSetUpdatedItemGenerator({ jobSetData: scheduleData, chunkData }),
    );
  } else if (type === 'location') {
    updatedScheduleData?.push(
      ...generateLocationSetUpdatedItem({
        locationSetData: scheduleData,
        chunkData,
      }),
    );
  }

  return updatedScheduleData;
};

export default generateUpdatedScheduleItem;
