import dayjs from 'dayjs';

const dateFields = ['lfd', 'rfd', 'st'];

const datetimeFields = [
  'po_schedule',
  'dilv_schedule',
  'pu_schedule',
  'rtn_schedule',
];

const getEditableData = ({
  beforeEditData,
  gridParam,
  editableCols,
  bookingOption,
}) => {
  const allEditableData = Object.fromEntries(
    Object.entries(gridParam.data)
      ?.filter(item => editableCols.includes(item[0]))
      ?.map(item => {
        if (item[0] === 'bk_seq') {
          const newBooking = bookingOption?.find(booking => {
            return booking.value === gridParam?.data?.bk_seq?.toString();
          }) || { label: '', value: null };
          if (!newBooking?.value) return [];
          return ['bk_seq', newBooking?.value];
        }
        return [item[0], item[1]];
      }),
  );

  const editedData = Object.fromEntries(
    Object.entries(allEditableData)?.filter(item => {
      if ([...dateFields, datetimeFields].includes(item[0])) {
        return (
          dayjs(item[1])?.format('YYYY-MM-DD HH:mm') !==
          dayjs(beforeEditData?.[item[0]])?.format('YYYY-MM-DD HH:mm')
        );
      }
      return item[1] !== beforeEditData?.[item[0]] && item[0] !== 'bk_no';
    }),
  );

  return editedData;
};

export default getEditableData;
