import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { memo, useEffect, useState } from 'react';
import CommonTableContent from './CommonTableContent';

const CommonTable = ({
  data,
  createColumns,
  onRowClickHandler,
  onRowDoubleClickHandler,
  getRowId,
  ...props
}) => {
  /** Table data */
  const [tableData, setTableData] = useState(() => [...data] || []);
  useEffect(() => {
    setTableData(() => [...data]);
  }, [data]);

  /** Selected row */
  const [selectedRowID, setSelectedRowID] = useState(null);

  /** Related with column */
  const columnHelper = createColumnHelper();
  const columns = () => createColumns?.({ columnHelper, data, props });

  /** Create table object */
  const table = useReactTable({
    data: tableData,
    columns: columns(),
    getCoreRowModel: getCoreRowModel(),
    getRowId: getRowId || (row => row?.id),
    enableRowSelection: true,
  });

  return (
    <div className="w-full h-full border-solid border-[1px] border-[#EEE] bg-[#fff] overflow-scroll">
      {table?.getHeaderGroups().map(headerGroup => (
        <div
          key={headerGroup?.id}
          className="flex flex-row w-full h-[31px] border-b-[1px] border-b-[#EEE] border-solid"
        >
          {headerGroup?.headers.map((header, idx) => {
            return (
              <div
                {...{
                  key: header?.id,
                  style: {
                    width: header?.getSize(),
                  },
                }}
                className={`flex justify-center items-center bg-[#F9F9F9] text-[12px] text-[#222] grow ${
                  idx !== headerGroup?.headers?.length - 1 &&
                  `border-r-[1px] border-r-[#EEE]`
                } `}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext(),
                )}
              </div>
            );
          })}
        </div>
      ))}
      <CommonTableContent
        data={data}
        table={table}
        selectedRowID={selectedRowID}
        setSelectedRowID={setSelectedRowID}
        onRowClickHandler={onRowClickHandler}
        onRowDoubleClickHandler={onRowDoubleClickHandler}
      />
    </div>
  );
};
export default CommonTable;
