import React from 'react';
import { CONTAINER_SCANNER_ICON } from '../../../../constants/Order/Common/path';

const ScannerReady = () => {
  return (
    <div className="h-full w-full flex flex-col justify-center items-center">
      <div className="flex justify-center items-center mb-[33px]">
        <img
          src={CONTAINER_SCANNER_ICON}
          width="76px"
          height="96px"
          alt="containerScannerIcon"
        />
      </div>
      <div className="flex flex-col justify-center items-center">
        <p className="mb-[10px] text-[#556DA1] font-[700] text-[18px]">
          INVOICE FILE
        </p>
        <p className="text-[#7F90B6] font-[500] text-[16px]">
          or Drag and Drop
        </p>
      </div>
    </div>
  );
};

export default ScannerReady;
