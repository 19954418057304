const isGroupItem = item => {
  return item instanceof Object;
};

const generateGroupItem = ({ group, selectedGroup, columnItems }) => {
  const [headerValue, selectedChildren] = Object.entries(group)[0];

  const foundItem = columnItems?.find(item => item.value === headerValue);

  if (foundItem) {
    selectedGroup.push(headerValue);

    const childrenOrderList = [];
    const columnChildren = foundItem?.children;

    selectedChildren?.forEach(field => {
      const childItem = columnChildren?.find(item => item.value === field);
      if (childItem) childrenOrderList.push({ ...childItem, checked: true });
    });

    columnChildren?.forEach(childItem => {
      const isChecked = selectedChildren?.includes(childItem.value);
      if (!isChecked) childrenOrderList.push({ ...childItem, checked: false });
    });

    return {
      ...foundItem,
      children: childrenOrderList,
    };
  }

  return null;
};

/**
 * @description
 * Generate Initial Order List for Customize Columns modal.
 * @selectedItems {array} User customized column item data from BE.
 * @columnItems {array} Column item has value which is the same as selectedItems's field.
 */
const generateInitOrderList = ({ selectedItems, columnItems }) => {
  const initOrderList = [];
  const selectedGroup = [];

  selectedItems?.forEach(field => {
    let foundItem;

    if (isGroupItem(field)) {
      foundItem = generateGroupItem({
        group: field,
        selectedGroup,
        columnItems,
      });
    } else {
      foundItem = columnItems?.find(item => item.value === field);
    }

    if (foundItem) initOrderList.push({ ...foundItem, checked: true });
  });

  columnItems?.forEach(item => {
    let isChecked;

    if (item?.children) {
      isChecked = selectedGroup?.includes(item.value);
    } else {
      isChecked = selectedItems?.includes(item.value);
    }

    if (!isChecked) initOrderList.push({ ...item, checked: false });
  });

  return initOrderList;
};

export default generateInitOrderList;
