import styled from 'styled-components';

const StyledAPModalTableContentHeaderCell = styled.div`
  width: ${props => props?.width || '100%'};
  height: 100%;
  background-color: ${props => props?.backgroundColor || '#F9F9F9'};
  border-top: ${props => props?.borderTop || 'none'};
  border-bottom: ${props => props?.borderBottom || 'none'};
  border-left: ${props => props?.borderLeft || 'none'};
  border-right: ${props => props?.borderRight || 'none'};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: #222222;
`;

export default StyledAPModalTableContentHeaderCell;
