import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import bcDetailKeys from '../keys';
import getMobileMemoList from '../../../services/BCDetail/Memo/Get/getMobileMemoList';
import { handleNotFoundError } from '../../../utils/Common/Handler/errorStatusSpecificHandler';

const useGetMobileMemoList = ({
  woNo,
  setMessageModalOpen,
  setMessageContent,
  setOnOkClickHandler,
}) => {
  const result = useQuery({
    queryKey: bcDetailKeys.mobileMemoList({ woNo }),
    queryFn: () => getMobileMemoList(woNo),
  });

  useEffect(() => {
    if (result?.isError) {
      setMessageModalOpen?.(prev => true);
      setMessageContent?.(prev => result?.error?.message);
      setOnOkClickHandler?.(prev => {
        if (
          result?.error?.message === handleNotFoundError() ||
          result?.error?.message === 'WO # has been deleted.'
        ) {
          return () => {
            window.close();
          };
        }
        return null;
      });
    } else {
      setMessageModalOpen?.(prev => false);
      setMessageContent?.(prev => '');
      setOnOkClickHandler?.(prev => null);
    }
  }, [result?.isError]);

  return result;
};

export default useGetMobileMemoList;
