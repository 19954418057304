import { useMutation } from '@tanstack/react-query';
import patchDeleteWO from '../../../../services/BCDetail/DetailInfo/Common/Patch/patchDeleteWO';
import useControlToast from '../../../Common/Toast/useControlToast';
import { SUCCESS_DELETE } from '../../../../constants/Common/successMessage';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';

const usePatchDeleteWO = ({
  setMessage,
  setErrorModalOpen,
  setDeleteOpen,
  infoRefetch,
}) => {
  const { addToast } = useControlToast();

  const mutator = useMutation({
    mutationFn: patchDeleteWO,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: success => {
      infoRefetch();
      setDeleteOpen(false);
      addToast({
        message: SUCCESS_DELETE,
      });
      setTimeout(() => {
        window.close();
      }, 3000);
    },
    onError: error => {
      setDeleteOpen(false);
      setMessage(error.message);
      setErrorModalOpen(true);
    },
  });
  return mutator;
};

export default usePatchDeleteWO;
