import { PATH_SIDEBAR_MENU_ICON } from '../../Sidebar/path';
import {
  PERM_ACCT,
  PERM_ACCT_CHASSIS_RECON,
  PERM_ACCT_PERDIEM_RECON,
  PERM_ACCT_QB_AP_AR,
  PERM_ACCT_QB_RECON,
  PERM_ACCT_UNINVOICED,
  PERM_ADMIN,
  PERM_ADMIN_AUTH,
  PERM_ADMIN_CONF,
  PERM_ADMIN_DIV_CONF,
  PERM_ADMIN_DIV_EARLY_WARNING,
  PERM_ADMIN_USER,
  PERM_CUS_REPORT,
  PERM_CUS_REPORT_MAIN,
  PERM_CUS_REPORT_SETTING,
  PERM_DISPATCH,
  PERM_DISPATCH_BROK,
  PERM_DISPATCH_EXP,
  PERM_DISPATCH_IMP,
  PERM_DISPATCH_VAN,
  PERM_EDI,
  PERM_MAIN,
  PERM_MAIN_BARE,
  PERM_MAIN_BROK,
  PERM_MAIN_EXP,
  PERM_MAIN_IMP,
  PERM_MAIN_VAN,
  PERM_MASTER,
  PERM_MASTER_ACCT_CODE,
  PERM_MASTER_AP_RATE,
  PERM_MASTER_AR_RATE,
  PERM_MASTER_CHASSIS_RATE,
  PERM_MASTER_CNEE,
  PERM_MASTER_COMPANY,
  PERM_MASTER_DRIVER,
  PERM_MASTER_PRODUCT_CODE,
  PERM_MASTER_TIER_WEIGHT,
  PERM_MOBILE_ADMIN,
  PERM_MOBILE_ADMIN_BILL_CONFIRM,
  PERM_MOBILE_ADMIN_MANIFEST,
  PERM_MOBILE_ADMIN_POD_CHECKLIST,
  PERM_ORDER,
  PERM_ORDER_BARE,
  PERM_ORDER_BROK,
  PERM_ORDER_EXP_REG_SHU,
  PERM_ORDER_IMP_REG_SHU,
  PERM_ORDER_VAN,
  PERM_TMS_REPORT,
  PERM_TMS_REPORT_DEMURRAGE,
  PERM_TMS_REPORT_DILV,
  PERM_TMS_REPORT_EARLY_WARNING,
  PERM_TMS_REPORT_PERDIEM,
  PERM_TMS_REPORT_PO,
  PERM_TMS_REPORT_RTN,
  PERM_TMS_REPORT_STATISTICS,
  PERM_TRACE,
  PERM_TRACE_GEOFENCE,
  PERM_TRACE_JOB,
  PERM_TRACE_MAP,
} from '../../permission';

/** Constant of sidebar menu */
export const SIDEBAR_MENU = [
  {
    seq: 0,
    label: 'ORDER',
    to: '/order/impreg',
    iconSet: PATH_SIDEBAR_MENU_ICON.order,
    permission: PERM_ORDER,
  },
  {
    seq: 1,
    label: 'DISPATCH',
    to: '/dispatch/import',
    iconSet: PATH_SIDEBAR_MENU_ICON.dispatch,
    permission: PERM_DISPATCH,
  },
  {
    seq: 2,
    label: 'MAIN',
    to: '/main/imp',
    iconSet: PATH_SIDEBAR_MENU_ICON.main,
    permission: PERM_MAIN,
  },
  {
    seq: 3,
    label: 'CUSTOMER REPORT',
    to: '/customer-report/report-main',
    iconSet: PATH_SIDEBAR_MENU_ICON['customer-report'],
    permission: PERM_CUS_REPORT,
  },
  {
    seq: 4,
    label: 'TMS REPORT',
    to: '/tms-report/pull-out',
    iconSet: PATH_SIDEBAR_MENU_ICON['tms-report'],
    permission: PERM_TMS_REPORT,
  },
  {
    seq: 5,
    label: 'MOBILE ADMIN',
    to: '/mobile-admin/pod-check-list',
    iconSet: PATH_SIDEBAR_MENU_ICON['mobile-admin'],
    permission: PERM_MOBILE_ADMIN,
  },
  {
    seq: 6,
    label: 'TRACE',
    to: '/trace/asset',
    iconSet: PATH_SIDEBAR_MENU_ICON.trace,
    permission: PERM_TRACE,
  },
  {
    seq: 7,
    label: 'EDI',
    to: '/edi',
    iconSet: PATH_SIDEBAR_MENU_ICON.edi,
    permission: PERM_EDI,
  },
  {
    seq: 8,
    label: 'ACCOUNTING',
    to: '/acct/ap-ar-uninvoiced',
    iconSet: PATH_SIDEBAR_MENU_ICON.acct,
    permission: PERM_ACCT,
  },
  {
    seq: 9,
    label: 'MASTER',
    to: '/master/driver',
    iconSet: PATH_SIDEBAR_MENU_ICON.master,
    permission: PERM_MASTER,
  },
  {
    seq: 10,
    label: 'ADMIN',
    to: '/admin/user',
    iconSet: PATH_SIDEBAR_MENU_ICON.admin,
    permission: PERM_ADMIN,
  },
];

/** Constant of sub-menu in each sidebar menu */
export const SIDEBAR_SUB_MENU = {
  /** Order */
  0: [
    {
      seq: 0,
      label: 'IMPORT',
      to: '/order/impreg',
      permission: PERM_ORDER_IMP_REG_SHU,
    },
    // {
    //   seq: 1,
    //   label: 'IMP - SHUTTLE',
    //   to: '/order/impshuttle',
    //   permission: PERM_ORDER_IMP_REG_SHU,
    // },
    {
      seq: 2,
      label: 'EXPORT',
      to: '/order/expreg',
      permission: PERM_ORDER_EXP_REG_SHU,
    },
    // {
    //   seq: 3,
    //   label: 'EXP - SHUTTLE',
    //   to: '/order/expshuttle',
    //   permission: PERM_ORDER_EXP_REG_SHU,
    // },
    {
      seq: 4,
      label: 'VAN',
      to: '/order/van',
      permission: PERM_ORDER_VAN,
    },
    {
      seq: 5,
      label: 'BROKERAGE',
      to: '/order/brokerage',
      permission: PERM_ORDER_BROK,
    },
    {
      seq: 6,
      label: 'BARE CHASSIS',
      to: '/order/barechassis',
      permission: PERM_ORDER_BARE,
    },
  ],
  /** Dispatch */
  1: [
    {
      seq: 0,
      label: 'IMPORT',
      to: '/dispatch/import',
      permission: PERM_DISPATCH_IMP,
    },
    {
      seq: 1,
      label: 'EXPORT',
      to: '/dispatch/export',
      permission: PERM_DISPATCH_EXP,
    },
    {
      seq: 2,
      label: 'VAN',
      to: '/dispatch/van',
      permission: PERM_DISPATCH_VAN,
    },
    {
      seq: 3,
      label: 'BROKERAGE',
      to: '/dispatch/brokerage',
      permission: PERM_DISPATCH_BROK,
    },
  ],
  /** Main */
  2: [
    {
      seq: 0,
      label: 'IMPORT',
      to: '/main/imp',
      permission: PERM_MAIN_IMP,
    },
    {
      seq: 1,
      label: 'EXPORT',
      to: '/main/exp',
      permission: PERM_MAIN_EXP,
    },
    {
      seq: 2,
      label: 'VAN',
      to: '/main/van',
      permission: PERM_MAIN_VAN,
    },
    {
      seq: 3,
      label: 'BROKERAGE',
      to: '/main/brokerage',
      permission: PERM_MAIN_BROK,
    },
    {
      seq: 4,
      label: 'BARE CHASSIS',
      to: '/main/barechassis',
      permission: PERM_MAIN_BARE,
    },
  ],
  /** Customer Report */
  3: [
    {
      seq: 0,
      label: 'REPORT MAIN',
      to: '/customer-report/report-main',
      permission: PERM_CUS_REPORT_MAIN,
    },
    {
      seq: 1,
      label: 'REPORT SETTING',
      to: '/customer-report/report-setting',
      permission: PERM_CUS_REPORT_SETTING,
    },
  ],
  /** TMS Report */
  4: [
    {
      seq: 0,
      label: 'PULL OUT',
      to: '/tms-report/pull-out',
      permission: PERM_TMS_REPORT_PO,
    },
    {
      seq: 1,
      label: 'DELIVERY',
      to: '/tms-report/delivery',
      permission: PERM_TMS_REPORT_DILV,
    },
    {
      seq: 2,
      label: 'RETURN',
      to: '/tms-report/return',
      permission: PERM_TMS_REPORT_RTN,
    },
    {
      seq: 3,
      label: 'DEMURRAGE',
      to: '/tms-report/demurrage',
      permission: PERM_TMS_REPORT_DEMURRAGE,
    },
    {
      seq: 4,
      label: 'PERDIEM',
      to: '/tms-report/perdiem',
      permission: PERM_TMS_REPORT_PERDIEM,
    },
    {
      seq: 5,
      label: 'STATISTICS',
      to: '/tms-report/statistics',
      permission: PERM_TMS_REPORT_STATISTICS,
    },
    {
      seq: 6,
      label: 'EARLY WARNING LIST',
      to: '/tms-report/early-warning-list',
      permission: PERM_TMS_REPORT_EARLY_WARNING,
    },
  ],
  /** Mobile Admin */
  5: [
    {
      seq: 0,
      label: 'POD CHECK LIST',
      to: '/mobile-admin/pod-check-list',
      permission: PERM_MOBILE_ADMIN_POD_CHECKLIST,
    },
    {
      seq: 1,
      label: 'BILL CONFIRM',
      to: '/mobile-admin/bill-confirm',
      permission: PERM_MOBILE_ADMIN_BILL_CONFIRM,
    },
    {
      seq: 2,
      label: 'MANIFEST',
      to: '/mobile-admin/manifest',
      permission: PERM_MOBILE_ADMIN_MANIFEST,
    },
  ],
  /** Trace */
  6: [
    {
      seq: 0,
      label: 'ASSET',
      to: '/trace/asset',
      permission: PERM_TRACE_MAP,
    },
    {
      seq: 1,
      label: 'JOB',
      to: '/trace/job',
      permission: PERM_TRACE_JOB,
    },
    {
      seq: 2,
      label: 'GEOFENCE',
      to: '/trace/geofence',
      permission: PERM_TRACE_GEOFENCE,
    },
  ],
  /** Accounting */
  8: [
    {
      seq: 0,
      label: 'AP/AR UNINVOICED',
      to: '/acct/ap-ar-uninvoiced',
      permission: PERM_ACCT_UNINVOICED,
    },
    {
      seq: 1,
      label: 'QB AP/AR',
      to: '/acct/qb-ap-ar',
      permission: PERM_ACCT_QB_AP_AR,
    },
    {
      seq: 2,
      label: 'AP/AR RECONCILE',
      to: '/acct/ap-ar-reconcile',
      permission: PERM_ACCT_QB_RECON,
    },
    {
      seq: 3,
      label: 'CHASSIS RECONCILE',
      to: '/acct/chassis-reconcile',
      permission: PERM_ACCT_CHASSIS_RECON,
    },
    {
      seq: 4,
      label: 'PERDIEM RECONCILE',
      to: '/acct/perdiem-reconcile',
      permission: PERM_ACCT_PERDIEM_RECON,
    },
  ],
  /** Master */
  9: [
    {
      seq: 0,
      label: 'DRIVER',
      to: '/master/driver',
      permission: PERM_MASTER_DRIVER,
    },
    {
      seq: 1,
      label: 'COMPANY',
      to: '/master/company',
      permission: [...PERM_MASTER_COMPANY, ...PERM_MASTER_CNEE],
    },
    {
      seq: 2,
      label: 'ACCOUNT CODE',
      to: '/master/accountcode',
      permission: PERM_MASTER_ACCT_CODE,
    },
    {
      seq: 3,
      label: 'SERVICE CODE',
      to: '/master/servicecode',
      permission: PERM_MASTER_PRODUCT_CODE,
    },
    {
      seq: 4,
      label: 'AP RATE',
      to: '/master/aprate',
      permission: PERM_MASTER_AP_RATE,
    },
    {
      seq: 5,
      label: 'AR RATE',
      to: '/master/arrate',
      permission: PERM_MASTER_AR_RATE,
    },
    {
      seq: 6,
      label: 'CHASSIS RATE',
      to: '/master/chassisrate',
      permission: PERM_MASTER_CHASSIS_RATE,
    },
    {
      seq: 7,
      label: 'TIER WEIGHT',
      to: '/master/tierweight',
      permission: PERM_MASTER_TIER_WEIGHT,
    },
  ],
  /** Admin */
  10: [
    {
      seq: 0,
      label: 'USER',
      to: '/admin/user',
      permission: PERM_ADMIN_USER,
    },
    {
      seq: 1,
      label: 'AUTH',
      to: '/admin/auth',
      permission: PERM_ADMIN_AUTH,
    },
    {
      seq: 2,
      label: 'CONFIGURATION',
      to: '/admin/conf',
      permission: PERM_ADMIN_CONF,
    },
    {
      seq: 3,
      label: 'SETTINGS',
      to: '/admin/div-conf',
      permission: PERM_ADMIN_DIV_CONF,
    },
    {
      seq: 4,
      label: 'EARLY WARNING',
      to: '/admin/early-warning',
      permission: PERM_ADMIN_DIV_EARLY_WARNING,
    },
  ],
};
