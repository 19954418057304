import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import divBasicDefaultApi from '../../divApi';

const postProductCode = async ({ div, productCode, mappingPage }) => {
  try {
    const response = await divBasicDefaultApi.post(`${div}/product-code-map`, {
      product_code: productCode,
      mapping_page: mappingPage,
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default postProductCode;
