import { FormControlLabel, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';

const SwitchCellRenderer = ({ ...props }) => {
  /** Get props data */
  const { onChangeHandler } = props;

  const [checked, setChecked] = useState(props?.value || false);
  useEffect(() => {
    setChecked?.(props?.value);
  }, [props?.value]);

  const [disabled, setDisabled] = useState(props?.disabled || false);
  useEffect(() => {
    setDisabled?.(props?.disabled);
  }, [props?.disabled]);

  return (
    <div className="flex justify-center items-center h-full w-full">
      <FormControlLabel
        sx={{
          margin: 0,
          '.MuiTypography-root': {
            fontSize: '12px',
            color: '#595656',
            fontWeight: '500',
          },
        }}
        control={
          <Switch
            checked={checked}
            disabled={disabled}
            onChange={e => onChangeHandler?.({ e, setChecked })}
            sx={{
              '&.MuiSwitch-root': {
                '.Mui-checked': {
                  color: '#14357D',
                },
                '.Mui-checked+.MuiSwitch-track': {
                  backgroundColor: '#556DA1',
                  opacity: '0.7',
                },
              },
            }}
          />
        }
      />
    </div>
  );
};

export default SwitchCellRenderer;
