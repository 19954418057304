import FloatingFilterSelect from '../../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterSelect';
import { MAIN_COMMON_CHECK_OPTION } from '../../../../constants/Main/Common/mainCommonOptions';

const getCheckBoxDef = field => {
  return {
    floatingFilterComponent: FloatingFilterSelect,
    floatingFilterComponentParams: {
      option: MAIN_COMMON_CHECK_OPTION,
    },
  };
};

export default getCheckBoxDef;
