import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import woAPI from '../../woAPI';

const postConvertToExport = async ({ woNo }) => {
  try {
    const response = await woAPI.post(`${woNo}/street-turn`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default postConvertToExport;
