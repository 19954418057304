import { useMutation } from '@tanstack/react-query';
import { SUCCESS_UPDATE } from '../../../../constants/Common/successMessage';
import patchAPInvoice from '../../../../services/BCDetail/BillingInfo/AP/Patch/patchAPInvoice';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';
import isServerResponseValid from '../../../../utils/Common/isServerResponseValid';
import useControlToast from '../../../Common/Toast/useControlToast';

const usePatchAPInvoice = ({
  setIsAlertOpen,
  setErrorMessage,
  setIsSuccess,
  refetchAPData,
  setIsQBDisabled,
}) => {
  const { addToast } = useControlToast();

  const mutator = useMutation({
    mutationFn: patchAPInvoice,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: success => {
      if (isServerResponseValid(success)) {
        refetchAPData();
        addToast({
          message: SUCCESS_UPDATE,
        });
        setIsQBDisabled(false);
      }
    },

    onError: error => {
      refetchAPData();
      setIsSuccess(false);
      setIsAlertOpen(true);
      setErrorMessage(error?.message);
    },
    refetchOnWindowFocus: false,
  });

  return mutator;
};

export default usePatchAPInvoice;
