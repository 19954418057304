import {
  EARLY_WARNING_SUCCEEDED_ICON,
  EARLY_WARNING_CONFIRM_ICON,
  EARLY_WARNING_FAILED_ICON,
} from './path';

export const ICON_MAPPING = {
  confirm: EARLY_WARNING_CONFIRM_ICON,
  succeeded: EARLY_WARNING_SUCCEEDED_ICON,
  failed: EARLY_WARNING_FAILED_ICON,
};

export const STATUS_TEXT_MAPPING = {
  failed: 'Failed',
  succeeded: 'Succeeded',
  confirm: 'Confirm...',
};
