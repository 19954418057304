import { flexRender } from '@tanstack/react-table';
import React, { useState } from 'react';

const CommonTableContent = ({
  data,
  table,
  selectedRowID,
  onRowClickHandler,
  onRowDoubleClickHandler,
  setSelectedRowID,
  ...props
}) => {
  return table.getRowModel().rows.map(row => {
    return (
      <div
        key={row?.id}
        className={`flex flex-row w-full h-[31px] border-b-[1px] border-b-[#EEE] border-b-solid cursor-pointer
        hover:bg-[#ececec] ${selectedRowID === row?.id && `bg-[#bdbdbd]`}`}
        onClick={() => {
          setSelectedRowID(row?.id);
          onRowClickHandler?.({ row, ...props });
        }}
        onDoubleClick={() => {
          setSelectedRowID(row?.id);
          onRowDoubleClickHandler?.({ row, ...props });
        }}
      >
        {row?.getVisibleCells().map((cell, idx) => (
          <div
            {...{
              key: cell?.id,
              style: {
                width: cell?.column?.getSize(),
              },
            }}
            className={`flex justify-center items-center text-[12px] text-[#222] grow 
            ${
              idx !== row?.getVisibleCells()?.length - 1
                ? `border-r-[1px] border-r-[#EEE]`
                : ''
            } text-ellipsis overflow-hidden truncate ...`}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </div>
        ))}
      </div>
    );
  });
};

export default CommonTableContent;
