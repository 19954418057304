import React, { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import CommonMessageModal from '../../CommonMessageModal/CommonMessageModal';
import APModalContent from './APModalContent';
import APModalButtonContent from './APModalButtonContent';
import dailyReportKeys from '../../../hooks/DailyReport/keys';

const APModal = ({ ...props }) => {
  /** Query Client */
  const queryClient = useQueryClient();

  /** Modal Open & Setter */
  const { isAPModalOpen, setAPModalOpen } = props;

  /** API State */
  const { isFetchingGetAP, isErrorGetAP } = props;

  /** Data Setter */
  const {
    setWONo,
    setSelectedScheduleSeq,
    setSelectedDriverData,
    setMessageModalOpen,
    setMessageModalMessage,
    setLoadingOpen,
  } = props;

  /** Data */
  const { woNo, apInputData, selectedScheduleSeq } = props;

  /** AP Table content. Max 5 contents */
  const [apTableContent, setAPTableContent] = useState([
    {
      productCode: '',
      productCodeName: '',
      amount: 0,
    },
  ]);
  useEffect(() => {
    if (
      apInputData?.ap_recommendations &&
      apInputData?.ap_recommendations?.length > 0
    ) {
      setAPTableContent(prev => [
        apInputData?.ap_recommendations?.map(recommendation => {
          return {
            productCode: recommendation?.code?.product_code,
            productCodeName: recommendation?.code?.product_code_name,
            amount: recommendation?.amt,
          };
        }),
        ...prev,
      ]);
    }
  }, [apInputData?.ap_recommendations]);

  return (
    <CommonMessageModal
      open={isAPModalOpen && !isFetchingGetAP && !isErrorGetAP}
      setOpen={setAPModalOpen}
      onCloseClick={() => {
        queryClient?.removeQueries({ queryKey: dailyReportKeys?.driver() });
        setWONo('');
        setSelectedScheduleSeq(null);
        setSelectedDriverData(null);
      }}
      width="602px"
      header="Driver AP Input"
      content={
        <APModalContent
          apInputData={apInputData}
          apTableContent={apTableContent}
          setAPTableContent={setAPTableContent}
        />
      }
      buttonContent={
        <APModalButtonContent
          woNo={woNo}
          setWONo={setWONo}
          apInputData={apInputData}
          selectedDriver={apInputData?.driver}
          apTableContent={apTableContent}
          setAPModalOpen={setAPModalOpen}
          setSelectedScheduleSeq={setSelectedScheduleSeq}
          setSelectedDriverData={setSelectedDriverData}
          selectedScheduleSeq={selectedScheduleSeq}
          setMessageModalOpen={setMessageModalOpen}
          setMessageModalMessage={setMessageModalMessage}
          setLoadingOpen={setLoadingOpen}
          page={props?.page}
        />
      }
    />
  );
};

export default APModal;
