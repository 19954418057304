import React from 'react';
import ARTableRow from '../ARTableRow';

const ARTableBody = ({
  ARQuery,
  ARProductCodeQuery,
  ARCompanyList,
  setCheckedRow,
  setIsCreditHoldCompany,
  setIsAlertOpen,
  setIsSuccess,
  setErrorMessage,
  isAREditable,
  isDeletable,
  refetchARData,
  setIsQBDisabled,
}) => {
  const ARData = ARQuery?.ARData;

  return (
    ARData &&
    ARData?.map(arDataRow => (
      <ARTableRow
        key={arDataRow?.seq}
        row={arDataRow}
        ARData={ARData}
        ARProductCodeQuery={ARProductCodeQuery}
        ARCompanyList={ARCompanyList}
        setCheckedRow={setCheckedRow}
        setIsCreditHoldCompany={setIsCreditHoldCompany}
        setIsAlertOpen={setIsAlertOpen}
        setIsSuccess={setIsSuccess}
        setErrorMessage={setErrorMessage}
        isAREditable={isAREditable}
        isDeletable={isDeletable}
        refetchARData={refetchARData}
        setIsQBDisabled={setIsQBDisabled}
      />
    ))
  );
};

export default ARTableBody;
