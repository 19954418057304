import ButtonCellRenderer from '../../../../components/CommonDataGrid/Component/ButtonCellRenderer';
import NormalCellRenderer from '../../../../components/CommonDataGrid/Component/NormalCellRenderer';
import SwitchCellRenderer from '../../../../components/CommonDataGrid/Component/SwitchCellRenderer';
import {
  CELL_TYPE_BUTTON,
  CELL_TYPE_GROUP,
  CELL_TYPE_SWITCH,
} from '../../../../constants/Common/DataGrid/cellType';

const cellRendererWithCellTypeGenerator = ({ cellType, ...props }) => {
  if (cellType === CELL_TYPE_BUTTON) return ButtonCellRenderer;
  if (cellType === CELL_TYPE_SWITCH) return SwitchCellRenderer;
  if (cellType === CELL_TYPE_GROUP) return 'agGroupCellRenderer';
  return NormalCellRenderer;
};

export default cellRendererWithCellTypeGenerator;
