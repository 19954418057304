const handleModalEmailButton = ({
  postEmailListMutate,
  setIsSendEmailModal,
  buttonRef,
  sendedEmailInfo,
}) => {
  setIsSendEmailModal(false);
  postEmailListMutate({ postData: sendedEmailInfo, buttonRef });
};

export default handleModalEmailButton;
