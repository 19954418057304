import { InfoWindow, Marker } from '@react-google-maps/api';
import React from 'react';
import CommonButton from '../../../CommonButton/CommonButton';
import '../../traceStyle.css';

/**
 * Component of Marker and InfoWindow.
 *
 * @param marker
 * Google Map Marker Component.
 * @param setMarkerList
 * Set state of the markerList.
 * @returns
 */
const MarkerComponent = ({
  marker,
  setMarkerList,
  setIsLiveShareModalOpen,
  setSelectedMarker,
}) => {
  let markerIconOpen;
  let markerIconClose;
  let infoHeader;
  let labelStyle;
  /**
   * When click the marker, show the info window of the clicked marker.
   * Also, when click the close button of the info window,
   * disable the info window of the marker.
   *
   * @param mark
   * Clicked Marker.
   * @param boolean
   * Set status of info window.
   */
  const handleMarker = (mark, boolean) => {
    setMarkerList(prevState =>
      prevState.map(m => {
        if (m.name === mark.name) {
          return { ...m, isOpen: boolean };
        }
        return m;
      }),
    );
  };

  if (marker.type === 'Truck') {
    markerIconOpen = {
      url: '/assets/images/traceIcon/truck50.svg',
    };
    markerIconClose = {
      url: '/assets/images/traceIcon/truck30.svg',
    };
    infoHeader = 'InfoHeaderTruck';
    labelStyle = 'MakerLabelTruck';
  } else if (marker.type === 'Chassis') {
    markerIconOpen = {
      url: '/assets/images/traceIcon/chassis50.svg',
    };
    markerIconClose = {
      url: '/assets/images/traceIcon/chassis30.svg',
    };
    infoHeader = 'InfoHeaderChassis';
    labelStyle = 'MakerLabelChassis';
  } else if (marker.type === 'Trailer') {
    markerIconOpen = {
      url: '/assets/images/traceIcon/trailer50.svg',
    };
    markerIconClose = {
      url: '/assets/images/traceIcon/trailer30.svg',
    };
    infoHeader = 'InfoHeaderTrailer';
    labelStyle = 'MakerLabelTrailer';
  }

  return (
    <div data-testid="marker">
      {(marker.isOpen && (
        <Marker
          key={marker.name}
          position={{
            lat: marker.locations.latitude,
            lng: marker.locations.longitude,
          }}
          onClick={() => handleMarker(marker, true)}
          icon={marker.isOpen ? markerIconOpen : markerIconClose}
        >
          <InfoWindow
            key={marker.name}
            position={{
              lat: marker.locations.latitude,
              lng: marker.locations.longitude,
            }}
            onCloseClick={() => handleMarker(marker, false)}
          >
            <div className="flex flex-col items-center">
              <p className={infoHeader}>
                <p className="InfoHeaderText">
                  {marker.name} ({marker.type})
                </p>
              </p>
              <div className="InfoBody">
                <div className="InfoBodyTitle">
                  <p>Timestamp</p>
                  <p>Speed</p>
                  <p>Location</p>
                  <p>GPS Coordinates</p>
                </div>
                <div className="InfoBodyContent">
                  <p className="TimeStampContent">{marker.locations.time}</p>
                  <p>{marker.locations.speed.toFixed(4)} MPH</p>
                  <p>{marker.locations.location}</p>
                  <p>
                    {marker.locations.latitude}, {marker.locations.longitude}
                  </p>
                </div>
              </div>
              <div className="mb-[15px] ">
                <CommonButton
                  category="liveShare"
                  onClick={() => {
                    setSelectedMarker(marker);
                    setIsLiveShareModalOpen(true);
                  }}
                >
                  Live Share
                </CommonButton>
              </div>
            </div>
          </InfoWindow>
        </Marker>
      )) || (
        <Marker
          key={marker.name}
          position={{
            lat: marker.locations.latitude,
            lng: marker.locations.longitude,
          }}
          label={{
            text: marker.name,
            className: labelStyle,
          }}
          onClick={() => handleMarker(marker, true)}
          icon={marker.isOpen ? markerIconOpen : markerIconClose}
        />
      )}
    </div>
  );
};

export default MarkerComponent;
