import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import SubTitle from '../../SubTitle';
import CustomerUtilityBar from '../CustomerUtilityBar';
import useGetCustomerData from '../../../hooks/CustomerReport/useGetCustomerData';
import CommonLoading from '../../CommonLoading/CommonLoading';
import CommonErrorModal from '../../CommonErrorModal/CommonErrorModal';
import ReportDataGrid from '../../CustomerReport/ReportDataGrid';
import generateCustomerColumn from '../../../utils/CustomerReport/Generator/generateCustomerColumn';
import CustomerHeader from '../CustomerHeader';
import AdvancedSearch from '../AdvancedSearch';
import handleGridDataFilter from '../../../utils/Customer/Handler/handleGridDataFilter';
import GlobalStyle from '../../../styles/Global/GlobalStyle';
import theme from '../../../styles/Themes';

const CustomerWrapper = () => {
  const gridRef = useRef(null);
  /** Modal Opener */
  const [loadingOpener, setLoadingOpener] = useState(false);
  const [advancedOpener, setAdvancedOpener] = useState(false);
  const [errorOpener, setErrorOpener] = useState(false);
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const { listName } = useParams();

  const tabsData = [
    {
      label: listName,
      to: `/customer/${listName}`,
      pathValue: 0,
    },
  ];

  const { customerData } = useGetCustomerData({
    setLoadingOpener,
    setErrorOpener,
    setMessage,
    setIsSuccess,
  });

  const [gridCustomerData, setGridCustomerData] = useState(
    customerData?.result || [],
  );
  useEffect(() => {
    setGridCustomerData(customerData?.result);
  }, [customerData?.result]);

  const [advancedFilter, setAdvancedFilter] = useState({
    dateType: '',
    before: null,
    after: null,
  });

  useEffect(() => {
    setGridCustomerData?.(prev =>
      handleGridDataFilter({ advancedFilter, gridCustomerData, customerData }),
    );
  }, [advancedFilter]);

  const [columnDefs, setColumnDefs] = useState(
    generateCustomerColumn?.({
      customerColumn: customerData?.cus_column,
    }) || [],
  );
  useEffect(() => {
    setColumnDefs(
      prev =>
        generateCustomerColumn?.({
          customerColumn: customerData?.cus_column,
        }) || [],
    );
  }, [customerData?.cus_column]);

  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <CustomerHeader />
        <div className="pl-[40px] pb-8 h-full flex flex-col">
          <SubTitle tabsData={tabsData} MainTitle="CUSTOMER REPORT" />
          {customerData && (
            <div className="flex flex-col h-full flex-1">
              <CustomerUtilityBar
                gridRef={gridRef}
                setAdvancedOpener={setAdvancedOpener}
              />
              <div className="pr-[40px] flex-1">
                <ReportDataGrid
                  gridRef={gridRef}
                  columnDefs={columnDefs}
                  data={gridCustomerData}
                />
              </div>
            </div>
          )}
        </div>
        {loadingOpener && (
          <CommonLoading open={loadingOpener} setOpen={setLoadingOpener} />
        )}
        {errorOpener && (
          <CommonErrorModal
            open={errorOpener}
            setIsOpen={setErrorOpener}
            message={message}
            isSuccess={isSuccess}
          />
        )}
        {advancedOpener && customerData && (
          <AdvancedSearch
            open={advancedOpener}
            setOpen={setAdvancedOpener}
            setAdvancedFilter={setAdvancedFilter}
            columnDefs={
              generateCustomerColumn?.({
                customerColumn: customerData?.cus_column,
              }) || []
            }
          />
        )}
      </ThemeProvider>
    </>
  );
};

export default CustomerWrapper;
