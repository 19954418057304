export const MASTER_COMMON_ACTIVE_OPTION = [
  { key: 0, label: 'All', value: '' },
  { key: 1, label: 'Active', value: 'true' },
  { key: 2, label: 'Inactive', value: 'false' },
];

export const MASTER_COMMON_ACTIVE_SELECT_OPTION = [
  { key: 0, label: 'Select', value: '' },
  { key: 1, label: 'Active', value: 'true' },
  { key: 2, label: 'Inactive', value: 'false' },
];

export const MASTER_COMMON_CHECK_OPTION = [
  { key: 0, label: 'All', value: '' },
  { key: 1, label: 'Y', value: 'true' },
  { key: 2, label: 'N', value: 'false' },
];

export const MASTER_COMMON_CHECK_SELECT_OPTION = [
  { key: 0, label: 'Select', value: '' },
  { key: 1, label: 'Y', value: 'true' },
  { key: 2, label: 'N', value: 'false' },
];
