/**
 * @description
 * Function that delete all of query parameters
 * @param {object} searchParams
 * Search params from the URL
 * @param {function} setSearchParams
 * Set search params function
 */
const deleteQueryParameter = ({ searchParams, setSearchParams }) => {
  searchParams?.forEach((value, key) => {
    searchParams?.delete(key);
  });
  setSearchParams(searchParams);
};

export default deleteQueryParameter;
