import React from 'react';
import { useFormContext } from 'react-hook-form';
import updateButtonDisabled from '../../../../../utils/Common/Handler/updateButtonDisabled';
import CommonButton from '../../../../CommonButton/CommonButton';

const ButtonContent = ({
  setIsDetail204ModalOpen,
  detail204Data,
  accept204Mutation,
  patch204Mutation,
  isAcceptable,
  isDataChanged,
  setIsDataChanged,
  setIsAlertOpen,
  setIsSuccessAlert,
  setAlertMessage,
  setIsPOChanged,
  setIsDelvChanged,
  setIsRtnChanged,
}) => {
  const {
    handleSubmit,
    formState: { isValid },
  } = useFormContext();

  const handleAccept = ({ buttonRef }) => {
    if (isDataChanged) {
      if (buttonRef) updateButtonDisabled({ ref: buttonRef, disabled: false });
      setIsAlertOpen(true);
      setIsSuccessAlert(false);
      setAlertMessage('Please save your data before accepting.');
      return;
    }
    accept204Mutation({
      id: detail204Data?._id,
      buttonRef,
    });
    setIsDataChanged(false);
  };

  const handleSave = ({ buttonRef, ...e }) => {
    patch204Mutation({ id: detail204Data?._id, data: e, buttonRef });
    setIsPOChanged(false);
    setIsDelvChanged(false);
    setIsRtnChanged(false);
    setIsDataChanged(false);
  };

  const handleClose = () => {
    setIsDetail204ModalOpen(false);
    setIsDataChanged(false);
  };

  return (
    <div className="flex justify-end mt-[20px] pr-[13px]">
      <div className="mr-[18px]">
        <CommonButton category="cancel" onClick={handleClose}>
          Cancel
        </CommonButton>
      </div>
      {isAcceptable && (
        <div className="mr-[18px]">
          <CommonButton
            category="accept"
            color="#3A825D"
            borderColor="#3A825D"
            disabled={detail204Data?.status !== 'ED0001'}
            preventDupClick
            onClick={({ buttonRef }) => {
              if (!isValid && buttonRef)
                updateButtonDisabled({ ref: buttonRef, disabled: false });
              handleSubmit(data => handleAccept({ ...data, buttonRef }))();
            }}
          >
            Accept
          </CommonButton>
        </div>
      )}
      <CommonButton
        type="button"
        category="save"
        preventDupClick
        onClick={({ buttonRef }) => {
          if (!isValid && buttonRef)
            updateButtonDisabled({ ref: buttonRef, disabled: false });
          handleSubmit(data => handleSave({ ...data, buttonRef }))();
        }}
      >
        Save
      </CommonButton>
    </div>
  );
};

export default ButtonContent;
