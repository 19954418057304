import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import App from '../../../App';
import AuthRouter from '../../../components/Common/AuthRouter';
import ARAging from '../../../pages/Acct/ARAging';
import Customer from '../../../pages/Customer';
import PageNotFound from '../../../pages/Error/PageNotFound';
import Labs from '../../../pages/Labs';
import LiveShare from '../../../pages/LiveShare';
import SignIn from '../../../pages/SignIn';
import { PERM_ACCT_QB_RECON } from '../permission';
import APP_CHILDREN_ROUTE from './App';
import ERROR_CHILDREN_ROUTE from './Error';

const ROUTER = createBrowserRouter([
  {
    path: '/',
    element: (
      <AuthRouter>
        <App />
      </AuthRouter>
    ),
    children: [...APP_CHILDREN_ROUTE],
  },
  {
    path: 'sign-in',
    element: <SignIn />,
  },
  {
    path: 'labs',
    element: (
      <AuthRouter>
        <Labs />
      </AuthRouter>
    ),
  },
  {
    path: 'live-share/:trackingID',
    element: <LiveShare />,
  },
  {
    path: 'customer/:listName',
    element: <Customer />,
  },
  {
    path: 'error',
    children: [...ERROR_CHILDREN_ROUTE],
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
  {
    path: 'acct/ar-aging',
    element: (
      <AuthRouter permission={PERM_ACCT_QB_RECON}>
        <ARAging />
      </AuthRouter>
    ),
  },
]);

export default ROUTER;
