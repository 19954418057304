export const STATUS_STANDARD_CODE_LIST = [
  { label: 'CONFIRM', value: 'EWS001' },
  { label: 'ISSUE', value: 'EWS002' },
  { label: 'SOLVED', value: 'EWS003' },
];

export const STATUS_STANDARD_CODE_OBJECT = {
  EWS001: { label: 'CONFIRM', value: true },
  EWS002: { label: 'ISSUE', value: false },
  EWS003: { label: 'SOLVED', value: 2 },
};

export const STATUS_STANDARD_CODE = {
  CONFIRM: 'EWS001',
  ISSUE: 'EWS002',
  SOLVED: 'EWS003',
};

export const WARNING_ISSUE_CODE = {
  EWN001: 'No Schedule',
  EWN002: 'No P/O Schedule',
  EWN003: 'No P/O Actual In',
  EWN004: 'No P/O Driver',
  EWN005: 'No DILV Schedule',
  EWN006: 'No DILV Acutal In',
  EWN007: 'No DILV Driver',
  EWN008: 'No P/U Schedule',
  EWN009: 'No P/U Actual In',
  EWN010: 'No P/U Driver',
  EWN011: 'No RTN Schedule',
  EWN012: 'No RTN Actual In',
  EWN013: 'No RtN Driver',
  EWN014: 'No POD',
  EWN015: 'No POL',
};

export const WARNING_DATE_CODE = {
  currentDateTime: 'EWD001',
  poSchedule: 'EWD002',
  poActualIn: 'EWD003',
  dilvSchedule: 'EWD004',
  dilvActualIn: 'EWD005',
  puSchedule: 'EWD006',
  puActualIn: 'EWD007',
  rtnSchedule: 'EWD008',
  rtnActualIn: 'EWD009',
  eta: 'EWD010',
  lfd: 'EWD011',
  rfd: 'EWD012',
  woCreate: 'EWD013',
  impDilvActualOut: 'EWD014',
  expPuActualOut: 'EWD015',
  mtyLoadConfirmDate: 'EWD016',
};
