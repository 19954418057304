const getQueries = ({ queryObject }) => {
  const queryParams = {};

  if (queryObject?.date && queryObject?.dateFrom) {
    queryParams[`${queryObject?.date}from`] = queryObject?.dateFrom;
  }
  if (queryObject?.date && queryObject?.dateTo) {
    queryParams[`${queryObject?.date}to`] = queryObject?.dateTo;
  }
  if (queryObject?.category && queryObject?.categoryText) {
    queryParams[queryObject?.category] = queryObject?.categoryText;
  }
  if (queryObject?.booking) {
    queryParams.booking = queryObject?.booking;
  }
  if (queryObject?.status) {
    queryParams.status = queryObject?.status;
  }

  return queryParams;
};

export default getQueries;
