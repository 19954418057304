import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';
import serviceDefaultApi from '../../../../api';

const getDriverList = async ({ driverType, div }) => {
  try {
    let type = 15;
    if (driverType === 'DRIVER') type = 7;
    else if (driverType === 'VENDOR') type = 8;

    /** division */
    let divisionQuery = '';
    if (['PHX', 'LA']?.includes(div)) divisionQuery = 'div=LA&div=PHX';
    else if (['SAV', 'MOB']?.includes(div)) divisionQuery = 'div=SAV&div=MOB';
    else divisionQuery = `div=${div}`;

    const response = await serviceDefaultApi.get(
      `/common/driver-list?type=${type}&${divisionQuery}`,
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getDriverList;
