import { useMutation } from '@tanstack/react-query';
import postEmail from '../../../services/BCDetail/Common/Post/postEmail';
import useControlToast from '../../Common/Toast/useControlToast';
import { SUCCESS_SEND } from '../../../constants/Common/successMessage';
import updateButtonDisabled from '../../../utils/Common/Handler/updateButtonDisabled';

const usePostEmail = ({ setMessageModalOpen, setMessageContent }) => {
  const { addToast } = useControlToast();

  const mutator = useMutation({
    mutationFn: postEmail,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: () => {
      addToast({ message: SUCCESS_SEND });
    },
    onError: error => {
      setMessageContent(error?.message);
      setMessageModalOpen(true);
    },
  });

  return { ...mutator };
};

export default usePostEmail;
