import React, { useState } from 'react';

const AccordionBox = props => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <div className="accordion">
      <div className="accordion-bar" onClick={() => setIsOpen(prev => !prev)}>
        {props.title.toUpperCase()}
      </div>
      {isOpen && props.children}
    </div>
  );
};

export default AccordionBox;
