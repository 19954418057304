import {
  PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
  PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
} from '../../../Common/path';

const BROKERAGE_COLORS = {
  brokerageColor1: {
    backgroundColor: '#f3e7fe',
    color: 'black',
    icon: null,
  },
  brokerageColor2: {
    backgroundColor: '#dec3fa',
    color: 'black',
    icon: null,
  },
  brokerageColor3: {
    backgroundColor: '#c6a3f2',
    color: 'black',
    icon: null,
  },
  brokerageColor4: {
    backgroundColor: '#9F76C8',
    color: 'white',
    icon: {
      fold: PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
      unfold: PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
    },
  },
  brokerageColor5: {
    backgroundColor: '#663f8d',
    color: 'white',
    icon: {
      fold: PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
      unfold: PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
    },
  },
  brokerageColor6: {
    backgroundColor: '#360b62',
    color: 'white',
    icon: {
      fold: PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
      unfold: PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
    },
  },
  grey: {
    backgroundColor: '#979797',
    color: 'white',
    icon: {
      fold: PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
      unfold: PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
    },
  },
  darkGrey: {
    backgroundColor: '#646464',
    color: 'white',
    icon: {
      fold: PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
      unfold: PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
    },
  },
  yard: {
    backgroundColor: '#FFF4CF',
    color: 'black',
    icon: null,
  },
};

export { BROKERAGE_COLORS };
