import styled from 'styled-components';

const StyledBCDetailBillingInfoPaymentModalTypeCell = styled.div`
  width: 270px;
  height: 20px;
  background-color: ${props => props.backgroundColor};
  font-size: 10px;
  font-weight: 700;
  color: #222;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 11px;
`;

export default StyledBCDetailBillingInfoPaymentModalTypeCell;
