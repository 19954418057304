import handleQueryError from '../../../utils/Common/Handler/handleQueryError';
import ediDefaultAPI from '../api';

const get322List = async ({ div, from, to, receiver }) => {
  try {
    const response = await ediDefaultAPI.get('322', {
      params: {
        div,
        'created-from': from,
        'created-to': to,
        receiver,
      },
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default get322List;
