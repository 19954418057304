import styled from 'styled-components';

const StyledTraceSearchBarWithOptionWrapper = styled.div`
  height: 30px;
  margin-left: 23px;
  margin-top: 23px;
  display: flex;
`;

export default StyledTraceSearchBarWithOptionWrapper;
