import onAPSubmit from './onAPSubmit';

const onAPSubmitHandler = async ({
  handleSubmit,
  submitType,
  apMutator,
  seq,
  APProductCodeQuery,
  setIsAlertOpen,
  setErrorMessage,
  setIsSuccess,
  buttonRef,
}) => {
  await handleSubmit(data => {
    if (submitType === 'ADD') {
      onAPSubmit({
        data,
        apMutator,
        submitType,
        APProductCodeQuery,
        setIsAlertOpen,
        setErrorMessage,
        setIsSuccess,
        buttonRef,
      });
    } else if (submitType === 'MOD') {
      onAPSubmit({
        data,
        apMutator,
        submitType,
        APProductCodeQuery,
        seq,
        setIsAlertOpen,
        setErrorMessage,
        setIsSuccess,
        buttonRef,
      });
    } else if (submitType === 'DEL') {
      onAPSubmit({
        data,
        apMutator,
        submitType,
        APProductCodeQuery,
        seq,
        setIsSuccess,
        buttonRef,
      });
    }
  })();
};

export default onAPSubmitHandler;
