import { useQuery } from '@tanstack/react-query';
import getWeightPerZIP from '../../../../services/Admin/DivConf/Get/getWeightPerZIP';
import adminKeys from '../../keys';

const useGetWeightPerZIP = ({ div }) => {
  const { isLoading: isLoadingWeightPerZIP, data } = useQuery({
    queryKey: adminKeys?.weightPerZIP({ div }),
    queryFn: () => getWeightPerZIP({ div }),
    refetchOnWindowFocus: false,
  });

  return {
    isLoadingWeightPerZIP,
    data: data?.data,
  };
};

export default useGetWeightPerZIP;
