import React from 'react';
import CommonButton from '../../CommonButton/CommonButton';

const CommonAlertModalButtonContent = ({ onOkClick, setOpen, ...props }) => {
  return (
    <div className="w-full flex justify-center items-center">
      <CommonButton
        onClick={() => {
          setOpen(false);
          onOkClick?.();
        }}
        category="approval"
      >
        OK
      </CommonButton>
    </div>
  );
};

export default CommonAlertModalButtonContent;
