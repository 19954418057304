import { useQuery } from '@tanstack/react-query';
import acctKeys from '../../keys';
import { getAPARReconcileList } from '../../../../services/Acct/APARReconcile/Get/getAPARReconcileList';
import { ACCT_PAGE } from '../../../../constants/Acct/Common/staticNames';
import generateQueryParamObject from '../../../../utils/Acct/Common/Generator/generateQueryParamObject';

const useGetAPARReconcile = ({ queryObject }) => {
  const queryParam = generateQueryParamObject({
    queryObject,
    page: ACCT_PAGE?.qbReconcile,
  });
  const {
    data: apARReconcile,
    isLoading: isLoadingAPARReconcile,
    isFetching: isFetchingAPARReconcile,
  } = useQuery({
    queryKey: acctKeys.apARReconcile({ queryParam }),
    queryFn: () => getAPARReconcileList({ queryParam }),
    staleTime: 0,
    refetchOnWindowFocus: false,
  });

  return { apARReconcile, isLoadingAPARReconcile, isFetchingAPARReconcile };
};

export default useGetAPARReconcile;
