import React, { useState } from 'react';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import LabsButtonBoxWrapper from '../../../components/Labs/Button/LabsButtonBoxWrapper';

const LabsButton = () => {
  const buttonElements = [
    {
      key: 0,
      title: 'Excel',
      category: 'excel',
      contents: 'EXCEL',
    },
    {
      key: 1,
      title: 'PDF',
      category: 'pdf',
      contents: 'PDF',
    },
    {
      key: 3,
      title: 'Export',
      category: 'export',
      contents: 'Export',
    },
    {
      key: 4,
      title: 'Import',
      category: 'import',
      contents: 'Import',
    },
    {
      key: 5,
      title: 'Upload',
      category: 'upload',
      contents: 'Upload',
    },
    {
      key: 6,
      title: 'Sync',
      category: 'sync',
      contents: 'Sync',
    },
    {
      key: 7,
      title: 'Delete',
      category: 'delete',
      contents: 'Delete',
    },
    {
      key: 8,
      title: 'Approval',
      category: 'approval',
      contents: 'Approval',
    },
    {
      key: 9,
      title: 'Complete',
      category: 'complete',
      contents: 'Complete',
    },
    {
      key: 10,
      title: 'Incomplete',
      category: 'incomplete',
      contents: 'Incomplete',
    },
    {
      key: 11,
      title: 'Edit',
      category: 'edit',
      contents: 'Edit',
    },
    {
      key: 12,
      title: 'Clear',
      category: 'clear',
      contents: 'Clear',
    },
    {
      key: 13,
      title: 'Leave',
      category: 'leave',
      contents: 'Leave',
    },
    {
      key: 14,
      title: 'Cancel',
      category: 'cancel',
      contents: 'Cancel',
    },
    {
      key: 15,
      title: 'Yes',
      category: 'yes',
      contents: 'Yes',
    },
    {
      key: 16,
      title: 'No',
      category: 'no',
      contents: 'No',
    },
    {
      key: 17,
      title: 'Got it',
      category: 'gotIt',
      contents: 'Got it',
    },
    {
      key: 18,
      title: 'Switch to DROP',
      category: 'switchToDROP',
      contents: 'Switch to DROP',
    },
    {
      key: 19,
      title: 'Switch to LIVE',
      category: 'switchToLIVE',
      contents: 'Switch to LIVE',
    },
    {
      key: 20,
      title: 'Tracking',
      category: 'tracking',
      contents: 'Tracking',
    },
    {
      key: 21,
      title: 'Add',
      category: 'add',
      contents: 'Add',
    },
    {
      key: 22,
      title: 'Save',
      category: 'save',
      contents: 'Save',
    },
    {
      key: 23,
      title: 'Custom',
      contents: 'P/OUT SCHE',
      borderColor: '#666666',
    },
    {
      key: 24,
      title: 'Custom Style',
      contents: 'Custom Style',
      borderColor: '#666666',
      customStyle: {
        // border: 'none',
        color: 'blue',
        backgroundColor: 'none',
        borderColor: 'yellow',
      },
    },
  ];

  const [controlState, setControlState] = useState({
    small: false,
  });

  const controlElement = [
    {
      key: 0,
      label: 'Small',
      onClick: () => {
        setControlState({ ...controlState, small: !controlState?.small });
      },
    },
  ];

  return (
    <div className="flex flex-col w-full flex-wrap">
      <div className="border-2 border-black bg-white flex items-center flex-wrap p-[10px] mt-[10px]">
        <FormGroup className="flex flex-row">
          {controlElement?.map(element => (
            <FormControlLabel
              style={{ pointerEvents: 'none' }}
              key={element?.key}
              control={<Switch style={{ pointerEvents: 'auto' }} />}
              {...element}
            />
          ))}
        </FormGroup>
      </div>
      <div className="flex flex-row flex-wrap">
        {buttonElements.map(buttonElement => {
          return (
            <LabsButtonBoxWrapper
              key={buttonElement?.key}
              {...buttonElement}
              {...controlState}
            />
          );
        })}
      </div>
    </div>
  );
};

export default LabsButton;
