import React, { useEffect } from 'react';
import DualMoveModalContentResultRow from './DualMoveModalContentResultRow';

const DualMoveModalContentResult = ({ ...props }) => {
  const { dualJobPullOut, apModalController } = props;

  if (dualJobPullOut && dualJobPullOut.length)
    return dualJobPullOut?.map((row, index) => (
      <DualMoveModalContentResultRow
        key={`${row?.wo_no}_${String(index)}`}
        row={row}
        apModalController={apModalController}
      />
    ));

  return (
    <div className="flex items-center justify-center w-full h-full py-[30px]">
      <div className="text-[14px] text-[#222]">No Pull-Out data</div>
    </div>
  );
};

export default DualMoveModalContentResult;
