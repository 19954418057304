import React, { memo } from 'react';
import StyledSidebarMenuIcon from '../../../../styles/Common/Sidebar/StyledSidebarMenuIcon';
import StyledSidebarMenuIconContent from '../../../../styles/Common/Sidebar/StyledSidebarMenuIconContent';

/**
 * @description
 * Sidebar menu icon component.
 * @param {boolean} isCurrentMenu
 * Flag to indicate the current menu.
 * @returns {React.ReactElement}
 */
const SidebarMenuIcon = memo(({ ...props }) => {
  const { isCurrentMenu, iconSet, label } = props;
  return (
    <StyledSidebarMenuIcon className="group/sidebarMenuIcon">
      <div
        className={`w-[4px] h-full ${
          isCurrentMenu ? `bg-[#001E5F]` : `bg-white`
        } duration-500`}
      />
      <div className="flex-1 flex justify-center items-center ml-[-4px]">
        {isCurrentMenu ? (
          <StyledSidebarMenuIconContent
            src={iconSet?.active}
            alt="Sidebar active icon"
          />
        ) : (
          <StyledSidebarMenuIconContent
            src={iconSet?.default}
            alt="Sidebar default icon"
          />
        )}
      </div>
      <div
        className={`absolute left-[50%] bg-[#656A6F] text-[#fff] translate-x-[-50%]
                    text-[9px] rounded-[3px] px-[4px] py-[2px] z-[100] text-center hidden group-hover/sidebarMenuIcon:block 
                    ${
                      label?.includes(' ')
                        ? `bottom-[0px] translate-y-[50%]`
                        : `bottom-[-3px]`
                    }`}
      >
        {label}
      </div>
    </StyledSidebarMenuIcon>
  );
}, null);

export default SidebarMenuIcon;
