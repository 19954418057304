import React from 'react';

const ModalHeader = ({ id }) => {
  return (
    <div className="flex">
      <div className="text-pink-700 text-sm font-bold">DECLINE</div>
      <div className="ml-[8px] text-black text-sm font-bold">{id}</div>
    </div>
  );
};

export default ModalHeader;
