import handleQueryError from '../../../utils/Common/Handler/handleQueryError';
import ediDefaultAPI from '../api';

const patch204 = async ({ id, data }) => {
  try {
    const response = await ediDefaultAPI.patch(`204/${id}`, {
      type: data?.type,
      size: data?.size,
      ssl: data?.ssl,
      billto: data?.billto,
      po_location: data?.po_location,
      dilv_location: data?.dilv_location,
      rtn_location: data?.rtn_location,
      unit: data?.unit,
      freedays: data?.freedays,
      freedays_type: data?.freedays_type,
      remarks: data?.remarks,
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patch204;
