import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import postAssignDriverAPInput from '../../../services/DailyReport/Post/postAssignDriverAPInput';
import dailyReportKeys from '../keys';
import useControlToast from '../../Common/Toast/useControlToast';
import { SUCCESS_ASSIGN } from '../../../constants/Common/successMessage';
import updateButtonDisabled from '../../../utils/Common/Handler/updateButtonDisabled';

const usePostAssignDriverAPInput = ({
  setAPModalOpen,
  setModalOpen,
  setModalMessage,
  setLoadingOpen,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();
  const mutator = useMutation({
    mutationFn: postAssignDriverAPInput,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: () => {
      queryClient?.invalidateQueries({ queryKey: dailyReportKeys?.all });
      queryClient?.invalidateQueries({ queryKey: dailyReportKeys?.driver() });
      setAPModalOpen(false);
      addToast({ message: SUCCESS_ASSIGN });
    },
    onError: error => {
      setModalOpen(true);
      setModalMessage(error?.message);
    },
  });

  useEffect(() => {
    if (mutator.isPending) {
      setLoadingOpen(true);
    } else if (!mutator.isPending) {
      setLoadingOpen(false);
    }
  }, [mutator.isPending]);

  return { ...mutator };
};

export default usePostAssignDriverAPInput;
