import React from 'react';
import { Navigate } from 'react-router-dom';
import {
  DISPATCH_PAGE_EXPORT_BOOKING,
  DISPATCH_TYPE_AVAILABLE,
  DISPATCH_TYPE_BOOKING,
  DISPATCH_TYPE_BROKERAGE,
  DISPATCH_TYPE_DELIVERY,
  DISPATCH_TYPE_EXPORT,
  DISPATCH_TYPE_IMPORT,
  DISPATCH_TYPE_RETURN,
  DISPATCH_TYPE_UNAVAILABLE,
  DISPATCH_TYPE_VAN,
} from '../../../../Dispatch/Common/category';
import {
  PERM_DISPATCH_BK,
  PERM_DISPATCH_BROK,
  PERM_DISPATCH_BROK_EDIT,
  PERM_DISPATCH_EXP,
  PERM_DISPATCH_EXP_EDIT,
  PERM_DISPATCH_IMP,
  PERM_DISPATCH_IMP_EDIT,
  PERM_DISPATCH_VAN,
  PERM_DISPATCH_VAN_EDIT,
} from '../../../permission';
import AuthRouter from '../../../../../components/Common/AuthRouter';
import getAuthorizedMenu from '../../../../../utils/Common/Sidebar/Handler/getAuthorizedMenu';
import { SIDEBAR_SUB_MENU } from '../../../Option/Sidebar';
import checkPermission from '../../../../../utils/Common/Router/Handler/checkPermission';
import DispatchByCategory from '../../../../../pages/Dispatch/DispatchByCategory';

const DISPATCH_CHILDREN_ROUTE = [
  {
    index: true,
    element: (
      <Navigate
        to={
          getAuthorizedMenu({
            allMenu: SIDEBAR_SUB_MENU?.[1],
          })?.[0]?.to
        }
      />
    ),
  },
  {
    path: 'import/*',
    element: (
      <AuthRouter permission={PERM_DISPATCH_IMP}>
        <DispatchByCategory
          isEditable={checkPermission({ permission: PERM_DISPATCH_IMP_EDIT })}
          category={DISPATCH_TYPE_IMPORT}
          woStatus={DISPATCH_TYPE_UNAVAILABLE}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'import/unavailable/*',
    element: (
      <AuthRouter permission={PERM_DISPATCH_IMP}>
        <DispatchByCategory
          isEditable={checkPermission({ permission: PERM_DISPATCH_IMP_EDIT })}
          category={DISPATCH_TYPE_IMPORT}
          woStatus={DISPATCH_TYPE_UNAVAILABLE}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'import/available/*',
    element: (
      <AuthRouter permission={PERM_DISPATCH_IMP}>
        <DispatchByCategory
          isEditable={checkPermission({ permission: PERM_DISPATCH_IMP_EDIT })}
          category={DISPATCH_TYPE_IMPORT}
          woStatus={DISPATCH_TYPE_AVAILABLE}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'import/delivery/*',
    element: (
      <AuthRouter permission={PERM_DISPATCH_IMP}>
        <DispatchByCategory
          isEditable={checkPermission({ permission: PERM_DISPATCH_IMP_EDIT })}
          category={DISPATCH_TYPE_IMPORT}
          woStatus={DISPATCH_TYPE_DELIVERY}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'import/return/*',
    element: (
      <AuthRouter permission={PERM_DISPATCH_IMP}>
        <DispatchByCategory
          isEditable={checkPermission({ permission: PERM_DISPATCH_IMP_EDIT })}
          category={DISPATCH_TYPE_IMPORT}
          woStatus={DISPATCH_TYPE_RETURN}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'export/*',
    element: (
      <AuthRouter permission={[...PERM_DISPATCH_BK, ...PERM_DISPATCH_EXP]}>
        <DispatchByCategory
          isEditable={checkPermission({ permission: PERM_DISPATCH_EXP_EDIT })}
          category={DISPATCH_TYPE_EXPORT}
          woStatus={DISPATCH_TYPE_UNAVAILABLE}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'export/booking/*',
    element: (
      <AuthRouter permission={[...PERM_DISPATCH_BK, ...PERM_DISPATCH_EXP]}>
        <DispatchByCategory
          isEditable={checkPermission({ permission: PERM_DISPATCH_BK })}
          category={DISPATCH_TYPE_BOOKING}
          woStatus={DISPATCH_TYPE_BOOKING}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'export/unavailable/*',
    element: (
      <AuthRouter permission={[...PERM_DISPATCH_BK, ...PERM_DISPATCH_EXP]}>
        <DispatchByCategory
          isEditable={checkPermission({ permission: PERM_DISPATCH_EXP_EDIT })}
          category={DISPATCH_TYPE_EXPORT}
          woStatus={DISPATCH_TYPE_UNAVAILABLE}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'export/available/*',
    element: (
      <AuthRouter permission={[...PERM_DISPATCH_BK, ...PERM_DISPATCH_EXP]}>
        <DispatchByCategory
          isEditable={checkPermission({ permission: PERM_DISPATCH_EXP_EDIT })}
          category={DISPATCH_TYPE_EXPORT}
          woStatus={DISPATCH_TYPE_AVAILABLE}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'export/delivery/*',
    element: (
      <AuthRouter permission={[...PERM_DISPATCH_BK, ...PERM_DISPATCH_EXP]}>
        <DispatchByCategory
          isEditable={checkPermission({ permission: PERM_DISPATCH_EXP_EDIT })}
          category={DISPATCH_TYPE_EXPORT}
          woStatus={DISPATCH_TYPE_DELIVERY}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'export/return/*',
    element: (
      <AuthRouter permission={[...PERM_DISPATCH_BK, ...PERM_DISPATCH_EXP]}>
        <DispatchByCategory
          isEditable={checkPermission({ permission: PERM_DISPATCH_EXP_EDIT })}
          category={DISPATCH_TYPE_EXPORT}
          woStatus={DISPATCH_TYPE_RETURN}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'van/*',
    element: (
      <AuthRouter permission={PERM_DISPATCH_VAN}>
        <DispatchByCategory
          isEditable={checkPermission({ permission: PERM_DISPATCH_VAN_EDIT })}
          category={DISPATCH_TYPE_VAN}
          woStatus={DISPATCH_TYPE_AVAILABLE}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'van/available/*',
    element: (
      <AuthRouter permission={PERM_DISPATCH_VAN}>
        <DispatchByCategory
          isEditable={checkPermission({ permission: PERM_DISPATCH_VAN_EDIT })}
          category={DISPATCH_TYPE_VAN}
          woStatus={DISPATCH_TYPE_AVAILABLE}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'van/delivery/*',
    element: (
      <AuthRouter permission={PERM_DISPATCH_VAN}>
        <DispatchByCategory
          isEditable={checkPermission({ permission: PERM_DISPATCH_VAN_EDIT })}
          category={DISPATCH_TYPE_VAN}
          woStatus={DISPATCH_TYPE_DELIVERY}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'van/return/*',
    element: (
      <AuthRouter permission={PERM_DISPATCH_VAN}>
        <DispatchByCategory
          isEditable={checkPermission({ permission: PERM_DISPATCH_VAN_EDIT })}
          category={DISPATCH_TYPE_VAN}
          woStatus={DISPATCH_TYPE_RETURN}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'brokerage/*',
    element: (
      <AuthRouter permission={PERM_DISPATCH_BROK}>
        <DispatchByCategory
          isEditable={checkPermission({ permission: PERM_DISPATCH_BROK_EDIT })}
          category={DISPATCH_TYPE_BROKERAGE}
          woStatus={DISPATCH_TYPE_AVAILABLE}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'brokerage/available/*',
    element: (
      <AuthRouter permission={PERM_DISPATCH_BROK}>
        <DispatchByCategory
          isEditable={checkPermission({ permission: PERM_DISPATCH_BROK_EDIT })}
          category={DISPATCH_TYPE_BROKERAGE}
          woStatus={DISPATCH_TYPE_AVAILABLE}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'brokerage/delivery/*',
    element: (
      <AuthRouter permission={PERM_DISPATCH_BROK}>
        <DispatchByCategory
          isEditable={checkPermission({ permission: PERM_DISPATCH_BROK_EDIT })}
          category={DISPATCH_TYPE_BROKERAGE}
          woStatus={DISPATCH_TYPE_DELIVERY}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'brokerage/return/*',
    element: (
      <AuthRouter permission={PERM_DISPATCH_BROK}>
        <DispatchByCategory
          isEditable={checkPermission({ permission: PERM_DISPATCH_BROK_EDIT })}
          category={DISPATCH_TYPE_BROKERAGE}
          woStatus={DISPATCH_TYPE_RETURN}
        />
      </AuthRouter>
    ),
  },
];

export default DISPATCH_CHILDREN_ROUTE;
