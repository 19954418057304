import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { FormHelperText } from '@mui/material';
import styled from 'styled-components';
import StyledCommonInputWrapper from '../../../../styles/Common/CommonInput/StyledCommonInputWrapper';
import StyledCommonLabel from '../../../../styles/Common/CommonInput/StyledCommonLabel';
import StyledCommonInputRequiredLabel from '../../../../styles/Common/CommonInput/StyledCommonInputRequiredLabel';
import defaultInputRules from '../../../../utils/Common/Input/defaultInputRules';

const APHeaderDate = ({ ...props }) => {
  /**
   * @description
   * Get react-hook-form object from useFormContext.
   * @control Must-have elements for getting values from a form.
   * @unregister Allows to unregister a single input or an array of inputs.
   * @setValue Setter value of input.
   * @formState Object containing information about the form.
   */
  const {
    control,
    unregister,
    setValue,
    formState: { errors: formErrors },
  } = useFormContext();

  /**
   * @label {string} - Label of input.
   * @required {boolean} - Required input.
   * @disabled {boolean} - Disable input.
   * @defaultValue {any} - Default value of input.
   */
  const [label, setLabel] = useState(props?.label);

  // Set default required as false
  const [required, setRequired] = useState(props?.required || false);
  useEffect(() => {
    /**
     * If default required value changed, component required value would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.required !== undefined && props?.required !== null) {
      setRequired(props?.required);
    }
  }, [props?.required]);

  // Set default disabled as false
  const [disabled, setDisabled] = useState(props?.disabled || false);
  useEffect(() => {
    /**
     * If default disabled value changed, component disabled value would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.disabled !== undefined && props?.disabled !== null) {
      setDisabled(props?.disabled);
    }
  }, [props?.disabled]);

  // Set default input value as null.
  const [defaultValue, setDefaultValue] = useState(props?.defaultValue || null);
  useEffect(() => {
    /**
     * If default value changed, component default value would be changed too.
     * Undefined exception handling. Null allowed value.
     */
    if (props?.defaultValue !== undefined) {
      setDefaultValue(props?.defaultValue);
      setValue(props?.inputName, props?.defaultValue);
    }
  }, [props?.defaultValue]);

  useEffect(() => {
    /**
     * Unregister input when component unmount.
     * Unregister single input only.
     */
    return () => unregister(props?.inputName);
  }, []);

  /**
   * @description
   * Render Date input component.
   * @control - Must-have elements for getting values from a form.
   * @name - Name of input
   * @defaultValue - Default value of input.
   * @rules - Rules of input.
   * @render - Render target input component.
   * - field : Object containing field props, like name, value, onChange, onBlur.
   * - fieldState : Object containing field state, like error, invalid, etc.
   */
  return (
    <Controller
      control={control}
      name={props?.inputName}
      defaultValue={
        defaultValue && dayjs(defaultValue).isValid()
          ? dayjs(defaultValue)
          : null
      }
      rules={defaultInputRules({
        required,
        ...props,
      })}
      render={({
        field: { ref, onChange, value, ...field },
        fieldState: { invalid, error },
      }) => {
        // Package of handler props
        const handlerProps = {
          onChange,
          value,
          ...field,
          ...props,
        };
        // Render Control Input Component
        return (
          <>
            <StyledCommonInputWrapper labelStyle={props?.labelStyle}>
              <StyledCommonLabel
                labelStyle={props?.labelStyle}
                lbl-pd-right={props?.[`lbl-pd-right`]}
              >
                {!!label && (
                  <>
                    {!!required && (
                      <StyledCommonInputRequiredLabel>
                        *
                      </StyledCommonInputRequiredLabel>
                    )}
                    {label}
                  </>
                )}
              </StyledCommonLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  {...field}
                  inputRef={ref}
                  error={invalid}
                  value={value && dayjs(value)?.isValid() ? dayjs(value) : null}
                  /**
                   * onChange Handler. Only handlers of parameters work.
                   * To change value, should use Controller field's onChange changer.
                   * ex)
                   * (tempValue) => {
                   *    onChange(tempValue);
                   * }
                   */
                  onChange={e =>
                    props?.onChangeHandler?.({ e, ...handlerProps })
                  }
                  disabled={disabled}
                  format="MM/DD/YY"
                  slotProps={{
                    textField: {
                      error: invalid,
                      sx: {
                        borderLeft: '7px solid #ccd3e3',
                        marginBottom: '13px',
                        svg: { color: !disabled && '#264B9F !important' },
                        '&.MuiFormControl-root': {
                          width: '100%',
                        },
                        '.MuiInputBase-root': {
                          height: '30px',
                          fontSize: '11px',
                          fontWeight: '500',
                          color: '#A6A6A6',
                        },
                        '.MuiSvgIcon-root': {
                          fontSize: '16px',
                        },
                        '.MuiButtonBase-root': {
                          width: '14px',
                          height: '14px',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          border: 'none',
                          cursor: 'pointer',
                          outline: 'none',
                          marginLeft: '-10px',
                        },
                        fieldset: {
                          borderColor: '#dde2ec !important',
                        },
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            </StyledCommonInputWrapper>
            <FormHelperText className="text-[#ff2424] text-[11px] font-normal min-h-[11px] h-[11px] mb-[3px] leading-none">
              {error?.message}
            </FormHelperText>
          </>
        );
      }}
    />
  );
};

export default APHeaderDate;
