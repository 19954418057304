import { useMutation } from '@tanstack/react-query';
import postUserID from '../../../../services/CustomerReport/Post/postUserID';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';

const usePostUserID = ({
  listSeq,
  setIsSendEmailModal,
  setIsOpenErrorModal,
  setMessage,
  setIsSuccess,
  setEmailList,
  setSendedEmailInfo,
}) => {
  const mutate = useMutation({
    mutationFn: postUserID,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: (response, variables) => {
      variables?.reset();

      const newEmailList = response?.data;
      setIsSendEmailModal(true);
      setEmailList(prev => [...prev, ...newEmailList]);
      setSendedEmailInfo({
        list_seq: listSeq,
        email: [...newEmailList],
      });
    },
    onError: error => {
      setIsOpenErrorModal(true);
      setIsSuccess(false);
      setMessage(error?.message);
    },
  });

  return { ...mutate };
};

export default usePostUserID;
