import dayjs from 'dayjs';
import onRowEditClickHandler from '../../../Common/Datagrid/Handler/onRowEditClickHandler';
import checkContainerNumberConstraint from '../../../Common/Handler/checkContainerNumberConstraint';
import DAILY_REPORT_EDITABLE_COLUMNS from '../../../../constants/DailyReport/Common/editableColumnList';

const handleSave = ({
  mutate,
  gridParam,
  beforeEditData,
  page,
  isEditable,
  setFocusedCell,
  setErrorMessage,
  setIsErrorModalOpen,
}) => {
  const editableCols = isEditable ? DAILY_REPORT_EDITABLE_COLUMNS?.[page] : [];
  setFocusedCell({
    rowIndex: gridParam?.rowIndex,
    column: gridParam?.api.getFocusedCell()?.column.getId(),
  });

  const editedData = Object.fromEntries(
    Object.entries(gridParam?.data || {})
      ?.filter(item => editableCols?.includes(item[0]))
      ?.filter(item => {
        if (['rtn_schedule', 'lfd', 'rfd'].includes(item[0])) {
          return (
            dayjs(item[1])?.format('YYYY-MM-DD HH:mm') !==
            dayjs(beforeEditData?.[item[0]])?.format('YYYY-MM-DD HH:mm')
          );
        }
        if (['cont_no', 'trailer_no'].includes(item[0])) {
          return item[1] !== beforeEditData?.equipment;
        }
        return item[1] !== beforeEditData?.[item[0]];
      }),
  );

  const category = gridParam?.data?.category;
  if (['WC0001', 'WC0002', 'WC0003', 'WC0004'].includes(category)) {
    delete editedData.trailer_no;
  } else if (['WC0005', 'WC0006'].includes(category)) {
    delete editedData.cont_no;
  }

  const isPoolValid = () => {
    const isChassisEdited = Object.keys(editedData).includes('ch_no');
    const isPoolEdited = Object.keys(editedData).includes('pool');

    const chassis = isChassisEdited ? editedData?.ch_no : beforeEditData?.ch_no;
    const pool = isPoolEdited ? editedData?.pool : beforeEditData?.pool;

    if (!chassis) return true;
    if (pool && pool !== '') {
      if (isChassisEdited || isPoolEdited) editedData.pool = pool;
      return true;
    }
    return false;
  };

  if (
    !!editedData?.cont_no &&
    !checkContainerNumberConstraint({
      containerNo: editedData?.cont_no,
      containerSize: gridParam?.data?.size,
      containerType: gridParam?.data?.type,
    })
  ) {
    setErrorMessage('Container Number is not valid');
    setIsErrorModalOpen(true);
    gridParam.api.undoCellEditing();
    onRowEditClickHandler({ gridParam });
  } else if (!isPoolValid()) {
    setErrorMessage('Pool should be updated.');
    setIsErrorModalOpen(true);
    gridParam.api.undoCellEditing();
    onRowEditClickHandler({ gridParam });
  } else if (!_.isEmpty(editedData)) {
    mutate({ woNo: gridParam?.data?.wo_no, data: editedData });
  }
};

export default handleSave;
