import { useQuery } from '@tanstack/react-query';
import getMainList from '../../../services/EDI/Get/getMainList';
import ediKeys from '../keys';

const useGetMainList = ({ div, type, from, to, sender }) => {
  const {
    isLoading: isLoadingMainList,
    data: listMainData,
    isRefetching: isRefetchingMainList,
  } = useQuery({
    queryKey: ediKeys?.getMainList({ div, type, from, to, sender }),
    queryFn: () => getMainList({ div, type, from, to, sender }),
    refetchOnWindowFocus: false,
  });

  return {
    isLoadingMainList,
    listMainData,
    isRefetchingMainList,
  };
};

export default useGetMainList;
