import axios from 'axios';
import interceptRequest from '../../utils/Common/Interceptor/interceptRequest';

const BOOKING_BASE_URL = `${process.env.REACT_APP_BASE_URL}/booking/`;

/**
 * Axios object with base url
 */
const bookingDefaultAPI = axios.create({
  withCredentials: true,
  baseURL: BOOKING_BASE_URL,
  xsrfHeaderName: 'X-CSRFTOKEN',
  xsrfCookieName: 'XCSRF-TOKEN',
  headers: {
    common: {
      'X-CSRFToken': localStorage.getItem('csrfToken'),
    },
  },
});

/** Interceptors */
interceptRequest(bookingDefaultAPI);

export default bookingDefaultAPI;
