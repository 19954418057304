import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { SUCCESS_SAVE } from '../../../constants/Common/successMessage';
import { INIT_AP_TABLE_CONTENT } from '../../../constants/Dispatch/Common/initAPTableContent';
import postAssignDriverAPInput from '../../../services/Dispatch/Common/post/postAssignDriverAPInput';
import updateButtonDisabled from '../../../utils/Common/Handler/updateButtonDisabled';
import useControlToast from '../../Common/Toast/useControlToast';
import dispatchKeys from '../../Dispatch2/keys';

const usePostAssignDriverAPInput = ({
  setAPModalOpener,
  setModalOpener,
  setModalMessage,
  setLoadingOpener,
  setAPTableContent,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();
  const mutator = useMutation({
    mutationFn: postAssignDriverAPInput,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: () => {
      queryClient?.invalidateQueries({ queryKey: dispatchKeys?.count() });
      queryClient?.invalidateQueries({ queryKey: dispatchKeys?.list() });
      queryClient?.invalidateQueries({ queryKey: dispatchKeys?.driver() });
      setAPTableContent(INIT_AP_TABLE_CONTENT);
      addToast({ message: SUCCESS_SAVE });
      setAPModalOpener(false);
    },
    onError: error => {
      setModalOpener(true);
      setModalMessage(error?.message);
    },
  });

  useEffect(() => {
    if (mutator.isPending) {
      setLoadingOpener(true);
    } else if (!mutator.isPending) {
      setLoadingOpener(false);
    }
  }, [mutator.isPending]);

  return { ...mutator };
};

export default usePostAssignDriverAPInput;
