import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import getAPData from '../../../../services/BCDetail/BillingInfo/AP/Get/getAPData';
import bcDetailKeys from '../../keys';

const useGetAPData = ({ setIsAlertOpen, setErrorMessage }) => {
  const [APData, setAPData] = useState(null);

  const woNo =
    document.URL?.split('/')?.[document?.URL?.split('/')?.length - 1];

  const query = useQuery({
    queryKey: bcDetailKeys?.billingInfoAP({ woNo }),
    queryFn: () => getAPData({ woNo }),
    enabled: !!woNo,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (query?.data?.data) {
      setAPData(query?.data?.data);
    }
  }, [query?.data]);

  useEffect(() => {
    if (query?.error) {
      setIsAlertOpen(true);
      setErrorMessage('Failed to get AP data.');
    }
  }, [query?.error]);

  const refetchAPData = async () => {
    const oldData = APData;
    const newData = await query.refetch()?.then(res => res?.data?.data);
    oldData?.forEach((oldItem, index) => {
      if (oldItem?.seq === newData?.[index]?.seq) {
        oldData[index] = newData[index];
      }
    });
    setAPData(oldData);
  };

  return { ...query, APData, refetchAPData };
};

export default useGetAPData;
