import React, { useState, useEffect } from 'react';

const useCheckFormValidity = ({ formStates }) => {
  const [isFormInvalid, setIsFormInvalid] = useState(false);

  useEffect(() => {
    setIsFormInvalid(
      formStates.some(
        formState => _.isBoolean(formState?.isValid) && !formState.isValid,
      ),
    );
  }, formStates);

  return { isFormInvalid };
};

export default useCheckFormValidity;
