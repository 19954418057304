const handleSaveClicked = ({ e, patchWeightPerZIPMutation }) => {
  patchWeightPerZIPMutation({
    seq: e.gridParam.data.seq,
    zip: e.gridParam.data.zip,
    state: e.gridParam.data.state,
    weight: e.gridParam.data.weight,
  });
};

export default handleSaveClicked;
