import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import bookingDefaultAPI from '../../bookingAPI';

const getBookingButtonCount = async ({ paramDiv, paramQueries }) => {
  try {
    const response = await bookingDefaultAPI.get(`count`, {
      params: { div: paramDiv, ...paramQueries },
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getBookingButtonCount;
