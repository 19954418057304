import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import MainLayOut from '../../components/Layout/MainLayOut';
import SubMenuNavigator from '../../components/Common/SubMenuNavigator';
import { PAGE_MAIN } from '../../constants/Common/mapper';

const Main = () => {
  return (
    <MainLayOut>
      <SubMenuNavigator page={PAGE_MAIN} />
      <div className="w-full pl-[97px] h-full">
        <Outlet />
      </div>
    </MainLayOut>
  );
};

export default Main;
