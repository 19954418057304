import { useEffect } from 'react';

const useGetLiveShareMap = ({
  projectId,
  tripId,
  elementId,
  authTokenFetcher,
  authTokenFetcherParam,
  setMessageModalOpener,
  setMessageModalMessage,
}) => {
  /** Function that initialize map */
  let locationProvider = null;
  let mapView = null;
  const initMap = () => {
    function anticipatedRoutePolylineSetup({
      defaultPolylineOptions,
      defaultVisible,
    }) {
      // If this function is not specified, the
      // PolylineOptions object contained in
      // defaultPolylineOptions is used to render the
      // anticipated route polyline. visible property sets the
      // polyline's visibility. Modify this object and
      // pass it back to customize the style of the map.
      defaultPolylineOptions.strokeOpacity = 0.5;
      defaultPolylineOptions.strokeColor = 'red';
      return {
        polylineOptions: defaultPolylineOptions,
        visible: true,
      };
    }

    /** Location Provider */
    locationProvider =
      new google.maps.journeySharing.FleetEngineShipmentLocationProvider({
        projectId,
        authTokenFetcher: () => authTokenFetcher({ ...authTokenFetcherParam }),
        trackingId: tripId,
      });

    /** Map View Object */
    mapView = new google.maps.journeySharing.JourneySharingMapView({
      element: document.getElementById(elementId),
      locationProviders: [locationProvider],
      anticipatedRoutePolylineSetup,
      mapOptions: {
        center: { lat: 37.2, lng: -121.9 },
        zoom: 14,
        styles: [
          {
            featureType: 'road.arterial',
            elementType: 'geometry',
            stylers: [{ color: '#CCFFFF' }],
          },
        ],
      },
    });

    /** Error handler */
    locationProvider?.addListener('error', e => {
      setMessageModalOpener(true);
      setMessageModalMessage('No valid task found for tracking.');
    });
  };

  useEffect(() => {
    initMap();
  }, []);

  useEffect(() => {
    return () => {
      locationProvider.trackingId = '';
      mapView?.removeLocationProvider(locationProvider);
    };
  }, []);

  return { initMap, locationProvider, mapView };
};

export default useGetLiveShareMap;
