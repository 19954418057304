import {
  DISPATCH_TYPE_BOOKING,
  DISPATCH_TYPE_BROKERAGE,
  DISPATCH_TYPE_EXPORT,
  DISPATCH_TYPE_IMPORT,
  DISPATCH_TYPE_VAN,
} from '../../../../constants/Dispatch/Common/category';
import EXPORT_FILTERS from '../../../../constants/Dispatch/Export/Filter/ExportFilters';
import IMPORT_FILTERS from '../../../../constants/Dispatch/Import/Filter/ImportFilters';
import VAN_FILTERS from '../../../../constants/Dispatch/Van/Filter/VanFilters';
import BROKERAGE_FILTERS from '../../../../constants/Dispatch/Brokerage/Filter/BrokerageFilters';

const getFilterButtonData = ({ category, ...props }) => {
  if (category === DISPATCH_TYPE_IMPORT) {
    return {
      children: IMPORT_FILTERS,
      to: '/dispatch/import',
      mainColor: '#DEE6F8',
      subColor: '#001E5F',
    };
  }
  if (category === DISPATCH_TYPE_EXPORT || category === DISPATCH_TYPE_BOOKING) {
    return {
      children: EXPORT_FILTERS,
      to: '/dispatch/export',
      mainColor: '#F4E5E5',
      subColor: '#951E39',
    };
  }
  if (category === DISPATCH_TYPE_VAN) {
    return {
      children: VAN_FILTERS,
      to: '/dispatch/van',
      mainColor: '#D8E6EB',
      subColor: '#0B6283',
    };
  }
  if (category === DISPATCH_TYPE_BROKERAGE) {
    return {
      children: BROKERAGE_FILTERS,
      to: '/dispatch/brokerage',
      mainColor: '#E8E0EA',
      subColor: '#5E246B',
    };
  }
  return {};
};

export default getFilterButtonData;
