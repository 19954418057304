import React from 'react';
import CommonButton from '../../../CommonButton/CommonButton';

const DriverChangeConfirmModalButton = ({ setOpen, onConfirm }) => {
  return (
    <div className="w-full flex justify-center items-center">
      <div className="flex gap-[20px]">
        <CommonButton
          category="cancel"
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </CommonButton>
        <CommonButton
          category="gotIt"
          preventDupClick
          onClick={({ buttonRef }) => {
            onConfirm?.();
            setOpen(prev => false);
          }}
        >
          Confirm
        </CommonButton>
      </div>
    </div>
  );
};

export default DriverChangeConfirmModalButton;
