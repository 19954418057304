import React from 'react';
import generateTypeField from '../../../../../../../utils/BCDetail/DetailInfo/Schedule/Generator/generateTypeField';

const ScheduleAddModalContentType = ({ ...props }) => {
  return (
    <div className="w-full h-[52px] bg-[#F9F9F9] flex flex-row">
      <div
        className="h-full flex justify-center items-center p-[17px]
                text-[12px] text-[#222] font-semibold"
      >
        Schedule Type
      </div>
      <div
        className="h-full flex items-center pl-[3px]
              text-[12px] text-[#666] font-normal"
      >
        {generateTypeField({ ...props })}
      </div>
    </div>
  );
};

export default ScheduleAddModalContentType;
