import React, { useEffect, useState } from 'react';
import CommonButton from '../../../CommonButton/CommonButton';

const BillButtonCellRender = ({ ...props }) => {
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setDisabled(!props?.data?.editable);
  }, [props?.data?.editable]);
  return (
    <CommonButton
      onClick={() => props?.handleClickInvoice?.({ rowData: props?.data })}
      disabled={disabled}
      type="button"
      category="invoice"
      size="small"
    >
      Bill
    </CommonButton>
  );
};

export default BillButtonCellRender;
