import styled from 'styled-components';

const SyltedLiveShareContentWrapper = styled.div`
  width: calc(100vw - 18px);
  min-width: 1920px;
  height: calc(100vh - 18px);
  min-height: 1080px;
  background-color: white;
  margin-top: 9px;
  margin-left: 9px;
  display: flex;
  flex-direction: row;
  padding-top: 44px;
  padding-right: 38px;
  padding-bottom: 40px;
  padding-left: 65px;
`;

export default SyltedLiveShareContentWrapper;
