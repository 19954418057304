import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import deleteAssignDriver from '../../../../services/BCDetail/DetailInfo/Schedule/Delete/deleteAssignDriver';
import bcDetailKeys from '../../keys';
import useControlToast from '../../../Common/Toast/useControlToast';
import { SUCCESS_DELETE } from '../../../../constants/Common/successMessage';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';

const useDeleteAssignDriver = ({
  setMessageModalOpener,
  setMessageModalMessage,
  setDeleteDriverModalOpener,
  setLoadingOpener,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  const mutator = useMutation({
    mutationFn: deleteAssignDriver,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
      setDeleteDriverModalOpener(false);
    },
    onSuccess: () => {
      queryClient?.invalidateQueries({ queryKey: bcDetailKeys?.all });
      addToast({ message: SUCCESS_DELETE });
    },
    onError: error => {
      setMessageModalOpener(true);
      setMessageModalMessage(error?.message);
    },
  });

  useEffect(() => {
    if (mutator.isPending) {
      setLoadingOpener(true);
    } else if (!mutator.isPending) {
      setLoadingOpener(false);
    }
  }, [mutator.isPending]);

  return { ...mutator };
};

export default useDeleteAssignDriver;
