export const DISPATCH_COLUMN_GRID_FIELD = {
  lfd_date: 'lfd',
  rfd_date: 'rfd',
  orig: 'orig_loc',
  curr: 'curr_loc',
  dest: 'dest_loc',
  dilv_empty_cfm: 'empty_load_cfm',
  dilv_load_cfm: 'empty_load_cfm',
  dilv_empty_load_cfm: 'empty_load_cfm',
  load_empty: 'empty_load_status',
};
