import React, { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import styled from 'styled-components';
import useGetBasicInfo from '../../../../../hooks/BCDetail/DetailInfo/BasicInfo/useGetBasicInfo';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3px;
`;

const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  width: 82px;
  height: 30px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  color: #d9d9d9;
  background-color: transparent;

  ${props =>
    props.active &&
    `
    background-color: ${props.color};
    color: #383535;
  `}
`;

const Caution = () => {
  const { basicInfoData, basicInfoLoading } = useGetBasicInfo();

  const [hasEmptyCFFile, setHasEmptyCFFile] = useState(false);
  const [hasEmptyCFSFile, setHasEmptyCFSFile] = useState(false);

  const overHeight = useWatch({ name: 'over_height' });
  const overWeight = useWatch({ name: 'over_weight' });
  const hazmat = useWatch({ name: 'hazmat' });
  const hot = useWatch({ name: 'hot' });

  useEffect(() => {
    const hasEmptyCF = basicInfoData?.doc.some(
      item => item.type === 'CF' && item.file_url === '',
    );
    const hasEmptyCFS = basicInfoData?.doc.some(
      item => item.type === 'CFS' && item.file_url === '',
    );

    setHasEmptyCFFile(hasEmptyCF);
    setHasEmptyCFSFile(hasEmptyCFS);
  }, [basicInfoLoading]);

  return (
    <Container>
      <p className="text-left text-[11px] font-[500] text-[#595656] mb-1">
        CAUTION
      </p>
      <div className="flex gap-x-6 gap-y-3 flex-wrap p-[11px] w-[428px] h-[97px] rounded-[5px] border-[1px] border-[#D9D9D9] ">
        <Card
          active={basicInfoData?.div?.bg_color[0]?.active && !hasEmptyCFFile}
          color={basicInfoData?.div?.bg_color[0]?.color}
        >
          CF
        </Card>
        <Card
          active={basicInfoData?.div?.bg_color[1]?.active && !hasEmptyCFSFile}
          color={basicInfoData?.div?.bg_color[1]?.color}
        >
          CFS
        </Card>
        <Card
          active={
            basicInfoData?.div?.bg_color[2]?.active &&
            (basicInfoData?.hazmat || hazmat)
          }
          color={basicInfoData?.div?.bg_color[2]?.color}
        >
          HAZMAT
        </Card>
        <Card
          active={
            basicInfoData?.div?.bg_color[3]?.active &&
            (basicInfoData?.hot || hot)
          }
          color={basicInfoData?.div?.bg_color[3]?.color}
        >
          HOT
        </Card>
        <Card
          active={
            basicInfoData?.div?.bg_color[4]?.active &&
            (basicInfoData?.wgt > basicInfoData?.div?.bg_color[4]?.lbs ||
              basicInfoData?.over_weight ||
              overWeight)
          }
          color={basicInfoData?.div?.bg_color[4]?.color}
        >
          OVERWEIGHT
        </Card>
        <Card
          active={
            basicInfoData?.div?.bg_color[5]?.active &&
            (basicInfoData?.over_height || overHeight)
          }
          color={basicInfoData?.div?.bg_color[5]?.color}
        >
          OVERHEIGHT
        </Card>
        <Card
          active={
            basicInfoData?.div?.bg_color[6]?.active &&
            basicInfoData?.tare > basicInfoData?.div?.bg_color[6]?.lbs
          }
          color={basicInfoData?.div?.bg_color[6]?.color}
        >
          CHASSIS TW
        </Card>
      </div>
    </Container>
  );
};

export default Caution;
