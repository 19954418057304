import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { REGEX_ONLY_NOT_NUMBER } from '../../../constants/Common/regex';
import { COMMON_COUNTRY_CODE_SELECT_OPTION } from '../../../constants/Common/selectOption';
import StyledControllerWrapper from '../../../styles/Common/Controller/Common/StyledControllerWrapper';
import defaultInputRules from '../../../utils/Common/Input/defaultInputRules';
import ControllerError from '../../Common/Controller/ControllerError';
import ControllerLabel from '../../Common/Controller/ControllerLabel';

const CommonPhoneNumber = ({ ...props }) => {
  /** Form context */
  const {
    control,
    unregister,
    setValue,
    formState: { errors },
  } = useFormContext();

  /** Component state */
  const [label, setLabel] = useState(props?.label || '');
  useEffect(() => {
    setLabel(prev => props?.label);
  }, [props?.label]);
  const [required, setRequired] = useState(props?.required || false);
  useEffect(() => {
    setRequired(prev => props?.required);
  }, [props?.required]);
  const [disabled, setDisabled] = useState(props?.disabled || false);
  useEffect(() => {
    setDisabled(prev => props?.disabled);
  }, [props?.disabled]);
  const [option, setOption] = useState(
    props?.option ||
      COMMON_COUNTRY_CODE_SELECT_OPTION?.map(item => {
        return { ...item, value: item?.code };
      }),
  );
  const [defaultValue, setDefaultValue] = useState(
    props?.defaultValue || {
      country: 'US',
      phone: '',
    },
  );
  const [maxLength, setMaxLength] = useState(props?.maxLength || 10);
  useEffect(() => {
    if (props?.maxLength) setMaxLength(prev => props?.maxLength);
    else setMaxLength(prev => 10);
  }, [props?.maxLength]);
  /** Input Name should be array type */
  const [inputName, setInputName] = useState(props?.inputName);
  const [suppressLabelVisible, setSuppressLabelVisible] = useState(
    props?.suppressLabelVisible || false,
  );
  const [suppressLabelContentVisible, setSuppressLabelContentVisible] =
    useState(props?.suppressLabelContentVisible || false);
  const [suppressErrorVisible, setSuppressErrorVisible] = useState(
    props?.suppressErrorVisible || false,
  );
  const [suppressErrorContentVisible, setSuppressErrorContentVisible] =
    useState(props?.suppressErrorContentVisible || false);

  /** Eject */
  useEffect(() => {
    return () => {
      unregister(inputName?.[0]);
      unregister(inputName?.[1]);
    };
  }, []);

  /** Component rendering */
  return (
    <StyledControllerWrapper>
      <ControllerLabel
        label={label}
        required={required}
        suppressLabelVisible={suppressLabelVisible}
        suppressLabelContentVisible={suppressLabelContentVisible}
      />
      <div className="flex flex-row">
        <div className="w-[105px]">
          {/* Country Code */}
          <Controller
            control={control}
            name={inputName?.[0]}
            defaultValue={defaultValue?.country}
            render={({
              field: { ref, onChange, value, ...field },
              fieldState: { invalid, error },
            }) => {
              return (
                <Select
                  {...field}
                  inputRef={ref}
                  value={value}
                  error={invalid}
                  renderValue={selected => (
                    <div className="flex flex-row gap-[4px]">
                      <img
                        width="24"
                        height="18"
                        srcSet={`https://flagcdn.com/48x36/${COMMON_COUNTRY_CODE_SELECT_OPTION?.find(
                          item => item?.code === selected,
                        )?.code.toLowerCase()}.png 2x,
                        https://flagcdn.com/72x54/${COMMON_COUNTRY_CODE_SELECT_OPTION?.find(
                          item => item?.code === selected,
                        )?.code.toLowerCase()}.png 3x
                        `}
                        src={`https://flagcdn.com/24x18/${COMMON_COUNTRY_CODE_SELECT_OPTION?.find(
                          item => item?.code === selected,
                        )?.code.toLowerCase()}.png`}
                        alt="Country Code Flag"
                      />
                      <p>
                        +
                        {
                          COMMON_COUNTRY_CODE_SELECT_OPTION?.find(
                            item => item?.code === selected,
                          )?.phone
                        }
                      </p>
                    </div>
                  )}
                  onChange={(e, selectProps) =>
                    onChange?.(selectProps?.props?.code)
                  }
                  disabled={disabled}
                  required={required}
                  defaultValue={defaultValue?.country}
                  MenuProps={{
                    disableScrollLock: true,
                    style: { zIndex: 9999 },
                  }}
                  IconComponent={ExpandMoreIcon}
                  className={`border-solid border-[#D9D9D9] h-[30px] rounded-l-[5px] text-xs text-black w-full ${
                    disabled ? 'bg-[#F9F9F9]' : 'bg-white'
                  }`}
                  sx={{
                    fontWeight: '500',
                    '.MuiSvgIcon-root': {
                      fontSize: '16px',
                      color: !disabled && '#264B9F !important',
                    },
                    '.Mui-disabled': {
                      WebkitTextFillColor: '#222 !important',
                    },
                    '&.MuiInputBase-root': {
                      borderRadius: '5px 0 0 5px',
                      '&.Mui-focused fieldset': {
                        border: '1.2px solid #264B9F !important',
                        boxShadow: '0px 0px 4px 0px #8BBCE9 !important',
                      },
                      '&.Mui-error fieldset': {
                        border: '1.2px solid #C24D4D !important',
                      },
                    },
                    fieldset: {
                      borderColor: '#D9D9D9 !important',
                    },
                  }}
                >
                  {option?.map((item, index) => {
                    return (
                      <MenuItem
                        value={item?.value}
                        key={String(index + 1)}
                        className="text-xs"
                        sx={{
                          backgroundColor: `${item?.value} !important`,
                        }}
                        {...item}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          srcSet={`https://flagcdn.com/w40/${item.code.toLowerCase()}.png 2x`}
                          src={`https://flagcdn.com/w20/${item.code.toLowerCase()}.png`}
                          alt="Country Code Flag"
                        />{' '}
                        {item?.label} ({item?.code}) +{item?.phone}
                      </MenuItem>
                    );
                  })}
                </Select>
              );
            }}
          />
        </div>
        {/* Phone Number */}
        <div className="flex-1">
          <Controller
            control={control}
            rules={defaultInputRules({ ...props })}
            name={inputName?.[1]}
            defaultValue={defaultValue?.phone}
            render={({
              field: { ref, onChange, value, ...field },
              fieldState: { invalid, error },
            }) => {
              return (
                <TextField
                  {...field}
                  variant="outlined"
                  inputRef={ref}
                  value={value}
                  onChange={e => {
                    const numericValue = e.target.value.replace(
                      REGEX_ONLY_NOT_NUMBER,
                      '',
                    );
                    onChange?.(numericValue);
                  }}
                  disabled={disabled}
                  error={invalid}
                  className={`border-solid border-[#D9D9D9] h-[30px] text-xs text-black w-full ${
                    disabled ? 'bg-[#F9F9F9]' : 'bg-white'
                  }
                    hover:border-[#8E9396]`}
                  sx={{
                    input: {
                      border: 'unset !important',
                      zIndex: '999',
                      WebkitTextFillColor: disabled && '#222 !important',
                    },
                    '.MuiInputBase-root': {
                      borderRadius: '0 5px 5px 0',
                      height: '30px',
                      fontSize: '12px',
                      fontWeight: '500',
                      '&.Mui-focused fieldset': {
                        border: '1.2px solid #264B9F !important',
                        boxShadow: '0px 0px 4px 0px #8BBCE9 !important',
                      },
                      '&.Mui-error fieldset': {
                        border: '1.2px solid #C24D4D !important',
                      },
                      '&.Mui-disabled fieldset': {
                        backgroundColor: 'unset !important',
                      },
                    },
                    fieldset: {
                      borderColor: '#D9D9D9 !important',
                    },
                  }}
                  InputProps={{
                    inputProps: {
                      maxLength,
                    },
                  }}
                />
              );
            }}
          />
        </div>
      </div>
      <ControllerError
        errors={errors}
        inputName={inputName?.[1]}
        suppressErrorVisible={suppressErrorVisible}
        suppressErrorContentVisible={suppressErrorContentVisible}
      />
    </StyledControllerWrapper>
  );
};

export default CommonPhoneNumber;
