import axios from 'axios';
import interceptRequest from '../../utils/Common/Interceptor/interceptRequest';

export const MAIN_BASE_URL = `${process.env.REACT_APP_BASE_URL}/main/`;

const mainDefaultApi = axios.create({
  withCredentials: true,
  baseURL: MAIN_BASE_URL,
  xsrfHeaderName: 'X-CSRFTOKEN',
  xsrfCookieName: 'XCSRF-TOKEN',
  headers: {
    common: {
      'X-CSRFToken': localStorage.getItem('csrfToken'),
    },
  },
});

/** Interceptors */
interceptRequest(mainDefaultApi);

export default mainDefaultApi;
