import handleScheduleDateTimeBothEnteredCheck from './handleScheduleDateTimeBothEnteredCheck';

const getScheduleWholeDateTime = ({ controllerData, dataKey }) => {
  return {
    sche_date: controllerData?.[`sche_date_${dataKey}`],
    sche_time: controllerData?.[`sche_time_${dataKey}`],
    actual_in_date: controllerData?.[`actual_in_date_${dataKey}`],
    actual_in_time: controllerData?.[`actual_in_time_${dataKey}`],
    actual_out_date: controllerData?.[`actual_out_date_${dataKey}`],
    actual_out_time: controllerData?.[`actual_out_time_${dataKey}`],
  };
};

const checkDateTimeConstraintBeforeUpdate = ({ ...props }) => {
  const { jobSet, locationSet, controllerData, setModalMessage } = props;

  const scheduleData = [];

  /** Process for job-set */
  jobSet?.forEach(job => {
    /** Sequence */
    const jobSetSeq = job?.seq;
    const frSeq = job?.from_location?.seq;
    const ldSeq = job?.live_dilv_location?.seq;
    const toSeq = job?.to_location?.seq;

    /** Location info for data key */
    const frDataKey = `${jobSetSeq}_${frSeq}_fr`;
    const ldDataKey = `${jobSetSeq}_${ldSeq}_ld`;
    const toDataKey = `${jobSetSeq}_${toSeq}_to`;

    /** Get location */
    const frLoc = job?.from_location;
    const ldLoc = job?.live_dilv_location;
    const toLoc = job?.to_location;

    /** From location */
    if (frLoc) {
      scheduleData?.push({
        ...getScheduleWholeDateTime({ controllerData, dataKey: frDataKey }),
      });
    }

    /** Live Delivery location */
    if (ldLoc) {
      scheduleData?.push({
        ...getScheduleWholeDateTime({ controllerData, dataKey: ldDataKey }),
      });
    }

    /** To location */
    if (toLoc) {
      scheduleData?.push({
        ...getScheduleWholeDateTime({ controllerData, dataKey: toDataKey }),
      });
    }
  });

  /** Process for location-set */
  locationSet?.forEach(location => {
    const locationSeq = location?.seq;
    const dataKey = `${locationSeq}_location_location`;

    if (location) {
      scheduleData?.push({
        ...getScheduleWholeDateTime({ controllerData, dataKey }),
      });
    }
  });

  for (let idx = 0; idx < scheduleData.length; idx++) {
    if (
      !handleScheduleDateTimeBothEnteredCheck({
        scheduleData: scheduleData?.[idx],
        setModalMessage,
      })
    ) {
      return false;
    }
  }

  return true;
};

export default checkDateTimeConstraintBeforeUpdate;
