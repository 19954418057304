import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import getWOChassisFromList from '../../../../services/Acct/ChassisReconcile/Get/getWOChassisFromList';
import acctKeys from '../../keys';
import {
  woOptionGenerator,
  woDateObjectListGenerator,
} from '../../../../utils/Acct/ChassisReconcile/Generator/woDateItemGenerator';

import queryStringGenerator from '../../../../utils/Acct/Common/Generator/queryStringGenerator';

const useGetWOChassisFromOptions = ({ queryObject, isFocused }) => {
  const [woFromOptionList, setWOFromOptionList] = useState([]);
  const [woFromDateObjectList, setWOFromDateObjectList] = useState([]);

  const queryString = queryStringGenerator({ queryObject });

  const { data: woData, isLoading: isLoadingFromWO } = useQuery({
    queryKey: acctKeys.chassisWOFromList({ queryString }),
    queryFn: () => getWOChassisFromList({ queryString }),
    staleTime: 0,
    enabled:
      !!queryString &&
      !!queryObject?.from_date &&
      !!queryObject?.to_date &&
      isFocused,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!queryObject?.from_date || !queryObject?.to_date) {
      setWOFromOptionList([]);
      setWOFromDateObjectList([]);
    }
  }, [queryObject?.from_date, queryObject?.to_date]);

  useEffect(() => {
    if (woData) {
      const optionList = woOptionGenerator({ woData });
      const objectList = woDateObjectListGenerator({ woData });

      setWOFromOptionList(optionList);
      setWOFromDateObjectList(objectList);
    }
  }, [woData]);

  return { woFromOptionList, woFromDateObjectList, isLoadingFromWO };
};

export default useGetWOChassisFromOptions;
