import { useQuery } from '@tanstack/react-query';
import { useState, useEffect } from 'react';
import queryStringGenerator from '../../../../utils/Acct/Common/Generator/queryStringGenerator';
import acctKeys from '../../keys';
import generateWOObjectList from '../../../../utils/Acct/PerdiemReconcile/Generator/generateWOObjectList';
import { generateWOOption } from '../../../../utils/Acct/PerdiemReconcile/Generator/optionListGenerator';
import getWOList from '../../../../services/Acct/PerdiemReconcile/Get/getWOList';

const useGetWOList = ({ queryObject, sslList, isFocused }) => {
  const [woOptionList, setWOOptionList] = useState([]);
  const [woObjectList, setWOObjectList] = useState([]);

  const queryString = queryStringGenerator({ queryObject });
  const { data: woData, isLoading: isLoadingWO } = useQuery({
    queryKey: acctKeys.woList({ queryString }),
    queryFn: () => getWOList({ queryString }),
    staleTime: 0,
    enabled:
      !!queryString &&
      !!queryObject?.from_date &&
      !!queryObject?.to_date &&
      isFocused,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!queryObject?.from_date || !queryObject?.to_date) {
      setWOOptionList([]);
      setWOObjectList([]);
    }
  }, [queryObject?.from_date, queryObject?.to_date]);

  useEffect(() => {
    if (woData) {
      const optionList = generateWOOption({ woData });
      const objectList = generateWOObjectList({ woData, sslList });

      setWOOptionList(optionList);
      setWOObjectList(objectList);
    }
  }, [woData]);

  return { woOptionList, woObjectList, isLoadingWO };
};

export default useGetWOList;
