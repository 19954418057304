import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SUCCESS_UPDATE } from '../../../constants/Common/successMessage';
import patchGeofence from '../../../services/Trace/Geofence/Patch/patchGeofence';
import useControlToast from '../../Common/Toast/useControlToast';
import traceKeys from '../keys';
import updateButtonDisabled from '../../../utils/Common/Handler/updateButtonDisabled';

const usePatchGeofenceMutation = ({
  setIsAlertOpen,
  setIsSuccessAlert,
  setAlertMessage,
  company,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  return useMutation({
    mutationFn: patchGeofence,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: traceKeys.geofenceList,
      });
      addToast({ message: SUCCESS_UPDATE });
    },
    onError: error => {
      queryClient.refetchQueries({
        queryKey: traceKeys.geofenceList({ company }),
      });
      setIsSuccessAlert(false);
      setAlertMessage(error.message);
      setIsAlertOpen(true);
    },
  });
};

export default usePatchGeofenceMutation;
