import { useMutation, useQueryClient } from '@tanstack/react-query';
import deleteAssignDriver from '../../../../services/BCDetail/DetailInfo/Schedule/Delete/deleteAssignDriver';
import bcDetailKeys from '../../keys';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';

const useDeleteDualJobDriver = ({ ...props }) => {
  const { setMessageModalOpen, setMessageModalMessage } = props;

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: deleteAssignDriver,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: () => {
      queryClient?.invalidateQueries({
        queryKey: bcDetailKeys?.defaultDualJob(),
      });
      queryClient?.invalidateQueries({
        queryKey: bcDetailKeys?.defaultDetailInfo(),
      });
    },
    onError: error => {
      setMessageModalMessage?.(prev => error?.message);
      setMessageModalOpen?.(prev => true);
    },
  });

  return { ...mutation };
};

export default useDeleteDualJobDriver;
