export const DATAGRID_COLORS = {
  leftColor: {
    backgroundColor: '#CCD3E3',
    color: '#222222',
  },
  curColor: {
    backgroundColor: '#909FC1',
    color: '#222222',
  },
  passedColor: {
    backgroundColor: '#415688',
    color: '#FFFFFF',
  },
};
