import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import customerReportKeys from '../../keys';
import getSettingData from '../../../../services/CustomerReport/Get/getSettingData';

const useGetSettingData = ({ div }) => {
  const [settingData, setSettingData] = useState([]);

  const query = useQuery({
    queryKey: customerReportKeys?.setting({ div }),
    queryFn: () => getSettingData({ div }),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (query?.data) {
      setSettingData?.(query?.data?.data);
    }
  }, [query?.data]);

  return { settingData, ...query };
};

export default useGetSettingData;
