import styled from 'styled-components';

const StyledBCDetailBillingInfoCommonRowCell = styled.div`
  color: #222;
  text-align: center;
  align-items: center;
  font-size: 11px;
  font-weight: 500;
  width: ${props => props.width};
  display: flex;
  justify-content: center;
  margin-right: 2px;
  margin-top: ${props => props.marginTop || '0px'};
`;

export default StyledBCDetailBillingInfoCommonRowCell;
