import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, FormControlLabel } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import defaultInputRules from '../../../utils/Common/Input/defaultInputRules';
import StyledCommonCheckboxList from '../../../styles/Common/CommonCheckboxList/StyledCommonCheckboxList';
import {
  ALL_CHECKBOX_SX,
  ALL_LABEL_SX,
  OPTIONS_CHECKBOX_SX,
  OPTIONS_LABEL_SX,
} from '../../../styles/Common/CommonCheckboxList/style';

const CommonCheckboxList = ({
  button,
  isOpen,
  suppressAll,
  suppressIndeterminate,
  onChangeAll,
  style,
  styleAll,
  ...props
}) => {
  /**
   * @button Button component to open the checkboxList.
   * @isOpen State to check if the checkboxList is open or not.
   * @suppressAll State to check if the ALL checkbox is suppressed or not.
   * @suppressIndeterminate State to check if the indeterminate is suppressed or not.
   * @onChangeAll Function to handle onChange event of ALL checkbox.
   * @style Object, Style of each checkbox. Key is value of option.
   * - { [options.value]: { backgroundColor: 'custom', textColor: 'custom', iconColor: 'custom' } }
   * @styleAll Object, Style of ALL checkbox.
   * - { backgroundColor: 'custom', textColor: 'custom', iconColor: 'custom' }
   */

  /**
   * @description
   * Get react-hook-form object from useFormContext.
   * @control Must-have elements for getting values from a form.
   * @unregister Allows to unregister a single input or an array of inputs.
   * @getValues Get values of all inputs.
   * @setValue Setter value of input.
   */
  const { control, getValues, setValue } = useFormContext();

  /**
   * @options {array} - Array of checkbox props.
   *
   * @Description
   * Props of each checkbox
   * @label {string} - Label of checkbox.
   * @value {string} - Name and key of checkbox.
   * @defaultValue {boolean} - Default value of checkbox.
   * - if not provided, 'true' will be used.
   * @onChange {function} - function to handle onChange event.
   * - If not provided, default onChange event will be used.
   * @disabled {boolean}- Disable checkbox.
   * - if not provided, 'false' will be used.
   */
  const [options, setOptions] = useState(
    Array.isArray(props?.options) ? props?.options : [],
  );
  useEffect(() => {
    /**
     * If options value changed, component would be changed too.
     */
    setOptions(Array.isArray(props?.options) ? props?.options : []);
  }, [props?.options]);

  /**
   * @description
   * Render checkbox component.
   * @key - Looping component key of checkbox.
   * @control - Must-have elements for getting values from a form.
   * @name - Name of each checkbox.
   * @defaultValue - Default value of each checkbox. ( true or false )
   * @rules - Rules of each checkbox.
   * @render - Render target input component.
   * - field : Object containing field props, like name, value, onChange, onBlur.
   */
  return (
    <div className="relative">
      {button}
      {isOpen && (
        <StyledCommonCheckboxList $isOpen={isOpen} {...props}>
          {!suppressAll && Array.isArray(options) && options.length > 0 && (
            <FormControlLabel
              sx={{
                ...ALL_LABEL_SX,
                background:
                  styleAll?.backgroundColor || ALL_LABEL_SX?.background,
                '.MuiTypography-root': {
                  ...ALL_LABEL_SX?.['.MuiTypography-root'],
                  color:
                    styleAll?.textColor ||
                    ALL_LABEL_SX?.['.MuiTypography-root']?.color,
                },
              }}
              label="ALL"
              control={
                <Checkbox
                  sx={{
                    ...ALL_CHECKBOX_SX,
                    '& .MuiSvgIcon-root': {
                      ...ALL_CHECKBOX_SX?.['& .MuiSvgIcon-root'],
                      color:
                        styleAll?.iconColor ||
                        ALL_CHECKBOX_SX?.['.MuiTypography-root']?.color,
                    },
                  }}
                  checked={Object.values(getValues())?.every(
                    checked => !!checked,
                  )}
                  indeterminate={
                    !suppressIndeterminate &&
                    Object.values(getValues())?.includes(false) &&
                    Object.values(getValues())?.includes(true)
                  }
                  onChange={e =>
                    onChangeAll instanceof Function
                      ? onChangeAll?.({
                          e,
                          getValues,
                          setValue,
                          options,
                          ...props,
                        })
                      : options?.map(checkbox =>
                          setValue(checkbox?.value, e?.target?.checked),
                        )
                  }
                />
              }
            />
          )}
          {Array.isArray(options) && options.length > 0 ? (
            options?.map(item => {
              const optionLabelStyle = {
                ...OPTIONS_LABEL_SX,
                background:
                  style?.[item?.value]?.backgroundColor ||
                  OPTIONS_LABEL_SX?.background,
                '.MuiTypography-root': {
                  ...OPTIONS_LABEL_SX?.['.MuiTypography-root'],
                  color:
                    style?.[item?.value]?.textColor ||
                    OPTIONS_LABEL_SX?.['.MuiTypography-root']?.color,
                },
              };
              const optionCheckboxStyle = {
                ...OPTIONS_CHECKBOX_SX,
                '& .MuiSvgIcon-root': {
                  ...OPTIONS_CHECKBOX_SX?.['& .MuiSvgIcon-root'],
                  color:
                    style?.[item?.value]?.iconColor ||
                    OPTIONS_CHECKBOX_SX?.['.MuiTypography-root']?.color,
                },
              };
              return (
                <div key={`${item?.value}${item?.label}Wrapper`}>
                  <div
                    key={`${item?.value}${item?.label}Divider`}
                    className="w-full border-b-[0.4px] border-[#D9D9D9]"
                  />
                  <Controller
                    key={item?.value}
                    control={control}
                    name={item?.value}
                    defaultValue={item?.defaultValue !== false}
                    rules={defaultInputRules({ ...props })}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <FormControlLabel
                        sx={optionLabelStyle}
                        control={
                          <Checkbox
                            {...field}
                            inputRef={ref}
                            icon={<CheckBoxOutlineBlankIcon />}
                            sx={optionCheckboxStyle}
                            checked={!!value}
                            disabled={item?.disabled || false}
                            onChange={e =>
                              item?.onChange instanceof Function
                                ? item?.onChange?.({
                                    e,
                                    value,
                                    ...field,
                                    ...item,
                                    onChange,
                                    ...props,
                                  })
                                : onChange(e)
                            }
                          />
                        }
                        label={item?.label || ''}
                      />
                    )}
                  />
                </div>
              );
            })
          ) : (
            <div className="whitespace-nowrap bg-grey-300 pl-[11px] pt-[8px] pb-[6px] pr-[12px]">
              {props?.noOptionMessage || 'No options'}
            </div>
          )}
        </StyledCommonCheckboxList>
      )}
    </div>
  );
};

export default CommonCheckboxList;
