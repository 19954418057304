import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import mapAPI from '../../api';

const getJobList = async ({ category, target }) => {
  try {
    const response = await mapAPI.get(`/job?${category}=${target}`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getJobList;
