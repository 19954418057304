import { SAVE_TYPE } from '../../../../constants/Acct/Common/staticNames';
import updateButtonDisabled from '../../../Common/Handler/updateButtonDisabled';
import {
  validateChassisForm,
  validatePerDiemForm,
} from './formValidationHandler';

const submitNextForm = async ({
  buttonRef,
  formIndex,
  formList,
  scannedFile,
  vendor,
  duplicateCheckFields,
  postMutator,
  setSubmitData,
}) => {
  const postData = {};
  let newIndex = formIndex;
  const form = formList?.[formIndex];

  await form?.form?.handleSubmit(submitData => {
    /** Set user-entered form data */
    Object.entries(submitData)?.forEach(([key, value]) => {
      if (value) {
        postData[key] = value;
      }
    });

    /** Set Invoice File / Vendor */
    postData.inv_file = scannedFile;
    postData.vendor = vendor;
  })();

  /** Set Current Posted Form Data */
  setSubmitData({ key: form?.key, data: postData });

  /** Mutate */
  await postMutator({
    postData,
    duplicateCheckFields,
    buttonRef,
  });

  newIndex++;

  /** Check for "submitNextForm" Function calls */
  if (newIndex < formList.length) {
    setTimeout(
      () =>
        submitNextForm({
          buttonRef,
          formIndex: newIndex,
          formList,
          scannedFile,
          vendor,
          duplicateCheckFields,
          postMutator,
          setSubmitData,
        }),
      200,
    );
  }
};

const handleSavingAll = async ({
  buttonRef,
  scannedFile,
  handleSubmitVendor,
  formList,
  setSubmitLength,
  setSubmitData,
  postMutator,
  setAlwaysFlag,
  duplicateCheckFields,
  setIsAllSave,
  setProgressLength,
  setErrorModalOptions,
  type,
  ...props
}) => {
  const {
    setDuplicationErrorList,
    setResolveErrorList,
    setApplyType,
    saveType,
    setBasicErrorMessage,
    setIsCompletedPatchFlag,
  } = props;

  const { setErrorModalOpen, setErrorMessage, setIsSuccess } =
    setErrorModalOptions;

  /** reset */
  if (saveType === SAVE_TYPE?.ALL) {
    setIsAllSave(true);
  } else {
    setIsAllSave(false);
  }

  setAlwaysFlag(false);
  setApplyType(null);

  setSubmitLength(formList?.length);
  setProgressLength(0);

  setDuplicationErrorList([]);
  setResolveErrorList([]);
  setBasicErrorMessage(null);

  setIsCompletedPatchFlag(false);

  let vendor = '';
  const formIndex = 0;
  /** Scanned File Null Validation */
  if (!scannedFile) {
    setIsSuccess(false);
    setErrorModalOpen(true);
    setErrorMessage('Please upload invoice file.');
    updateButtonDisabled({ ref: buttonRef, disabled: false });
    return;
  }

  /** Set Vendor Data */
  await handleSubmitVendor(vendorData => {
    vendor = vendorData?.vendor?.value;
  })();

  if (vendor === undefined || vendor === null || vendor === '') {
    return;
  }

  /** Validate All Forms in Form List */
  formList?.forEach(form => {
    form?.form?.handleSubmit()();
  });

  const isValidForm = !formList?.some(form => {
    const isValidationResult =
      type === 'chassis'
        ? !validateChassisForm({
            buttonRef: form?.buttonRef,
            submitData: form?.form.getValues(),
            setErrorModalOpen,
            setErrorMessage,
            setIsSuccess,
          })
        : !validatePerDiemForm({
            buttonRef: form?.buttonRef,
            submitData: form?.form.getValues(),
            setErrorModalOpen,
            setErrorMessage,
            setIsSuccess,
          });
    return !!isValidationResult;
  });

  if (!isValidForm) {
    return;
  }

  /** Call SubmitNextForm Function */
  await submitNextForm({
    buttonRef,
    formIndex,
    formList,
    scannedFile,
    vendor,
    duplicateCheckFields,
    postMutator,
    setSubmitData,
  });
};

export default handleSavingAll;
