import getDateDef from '../../../utils/Dispatch/Common/Generator/getDateDef';
import getDateTimeDef from '../../../utils/Dispatch/Common/Generator/getDateTimeDef';
import getScheduledTypeDef from '../../../utils/Dispatch/Common/Generator/getScheduleTypeDef';
import CATEGORY_DEF from './categoryDef';
import CHECKBOX_DEF from './checkBoxDef';
import DOCUMENT_BUTTON_DEF from './documentButtonDef';
import NUMBER_FIELD_DEF from './numberFieldDef';

const BASIC_COL_DEF = {
  category: CATEGORY_DEF,
  eta: getDateDef('eta'),
  lfd: getDateDef('lfd'),
  lfd_date: getDateDef('lfd'),
  rfd: getDateDef('rfd'),
  rfd_date: getDateDef('rfd'),
  st: getDateDef('st'),
  erd: getDateDef('erd'),
  cutoff: getDateTimeDef('cutoff'),

  equipment_no: {
    valueGetter: params => {
      return params.data?.trailer_no;
    },
  },

  ava_dsc: CHECKBOX_DEF,
  ava_obl: CHECKBOX_DEF,
  ava_cus: CHECKBOX_DEF,

  po_schedule: getDateTimeDef('po_schedule'),
  dilv_schedule: getDateTimeDef('dilv_schedule'),
  pu_schedule: getDateTimeDef('pu_schedule'),
  rtn_schedule: getDateTimeDef('rtn_schedule'),

  po_actual_out: getDateTimeDef('po_actual_out'),
  dilv_actual_out: getDateTimeDef('dilv_actual_out'),
  pu_actual_out: getDateTimeDef('pu_actual_out'),
  rtn_actual_out: getDateTimeDef('rtn_actual_out'),

  po_actual_in: getDateTimeDef('po_actual_in'),
  dilv_actual_in: getDateTimeDef('dilv_actual_in'),
  pu_actual_in: getDateTimeDef('pu_actual_in'),
  rtn_actual_in: getDateTimeDef('rtn_actual_in'),

  yard_actual_out: getDateTimeDef('yard_actual_out'),
  yard_actual_in: getDateTimeDef('yard_actual_in'),

  sche_type: getScheduledTypeDef(),

  dilv_empty_cfm: getDateDef('empty_load_cfm'),
  dilv_load_cfm: getDateDef('empty_load_cfm'),
  dilv_empty_load_cfm: getDateDef('empty_load_cfm'),

  over_height: CHECKBOX_DEF,
  over_weight: CHECKBOX_DEF,
  hazmat: CHECKBOX_DEF,
  hot: CHECKBOX_DEF,
  doc: DOCUMENT_BUTTON_DEF,

  wgt: NUMBER_FIELD_DEF,
  lfd_day: NUMBER_FIELD_DEF,
  rfd_day: NUMBER_FIELD_DEF,
  total_1: NUMBER_FIELD_DEF,
  balance_1: NUMBER_FIELD_DEF,
  total_2: NUMBER_FIELD_DEF,
  balance_2: NUMBER_FIELD_DEF,
  total_3: NUMBER_FIELD_DEF,
  balance_3: NUMBER_FIELD_DEF,
};

export default BASIC_COL_DEF;
