import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import generateColumnList from '../../../../utils/Dispatch/Common/Generator/generateColumnList';

const useControlDispatchList = ({ rowList, colors, columnList }) => {
  /** Define Dispatch List */
  const [controlDispatchList, setControlDispatchList] = useState({
    result: [],
    bg_color: {},
    dsp_column: [],
  });

  /** Grid Row */
  useEffect(() => {
    if (rowList && !isEqual(rowList, controlDispatchList.result)) {
      setControlDispatchList(prev => ({ ...prev, result: rowList }));
    }
  }, [rowList]);

  /** Color Information */
  useEffect(() => {
    if (colors) {
      /** LowerCase */
      const tempBGColor = Object?.keys?.(colors)?.reduce((acc, key) => {
        acc[key?.toLowerCase()] = colors?.[key];
        return acc;
      }, {});

      setControlDispatchList(prev => ({ ...prev, bg_color: tempBGColor }));
    }
  }, [colors]);

  /** User Column List */
  useEffect(() => {
    if (columnList) {
      /** Grouping Logic */
      const dispatchColumn = generateColumnList({ columnList });
      setControlDispatchList(prev => ({ ...prev, dsp_column: dispatchColumn }));
    }
  }, [columnList]);

  return {
    controlDispatchList,
  };
};

export default useControlDispatchList;
