import dayjs from 'dayjs';

const generateInvoiceDateRange = date => {
  if (!date || !dayjs(date)?.isValid()) {
    return { from_date: '', to_date: '' };
  }

  const invDate = dayjs(date);
  const fromDate = invDate?.subtract(7, 'day')?.format('YYYY-MM-DD');
  const toDate = invDate?.add(7, 'day')?.format('YYYY-MM-DD');

  return {
    from_date: fromDate,
    to_date: toDate,
  };
};

export default generateInvoiceDateRange;
