import React from 'react';
import StyledAPModalTableContentRowCell from '../../../../styles/Dispatch/APModal/StyledAPModalTableContentRowCell';

const APModalTableContentRowCell = ({ children, customStyle, ...props }) => {
  return (
    <StyledAPModalTableContentRowCell {...customStyle} {...props}>
      {children}
    </StyledAPModalTableContentRowCell>
  );
};

export default APModalTableContentRowCell;
