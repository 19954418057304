import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CATEGORY_322 } from '../../../../constants/EDI/options';
import usePost322Mutation from '../../../../hooks/EDI/Post/usePost322Mutation';
import CommonLoading from '../../../CommonLoading/CommonLoading';
import CommonMessageModal from '../../../CommonMessageModal/CommonMessageModal';
import ButtonContent from './ButtonContent';
import ModalContent from './ModalContent';

const Add322Modal = ({
  isAdd322ModalOpen,
  setIsAdd322ModalOpen,
  div,
  selectedCategory,
  searchFrom322,
  searchTo322,
  setIsAlertOpen,
  setIsSuccessAlert,
  setAlertMessage,
}) => {
  const searchController = useForm();

  const { mutate: post322Mutation, isPending: isPost322Pending } =
    usePost322Mutation({
      setIsAlertOpen,
      setIsSuccessAlert,
      setAlertMessage,
      div,
      from: searchFrom322,
      to: searchTo322,
      receiver: CATEGORY_322[selectedCategory],
    });

  const handleSave = ({ buttonRef, ...e }) => {
    post322Mutation({
      data: { ...e, div, receiver: CATEGORY_322[selectedCategory] },
      buttonRef,
    });
    setIsAdd322ModalOpen(false);
  };

  return (
    <>
      <CommonLoading open={isPost322Pending} />
      <FormProvider {...searchController}>
        <form>
          <CommonMessageModal
            header="Add 322"
            open={isAdd322ModalOpen}
            setOpen={setIsAdd322ModalOpen}
            width="770px"
            height="546px"
            content={<ModalContent />}
            buttonContent={<ButtonContent handleSave={handleSave} />}
          />
        </form>
      </FormProvider>
    </>
  );
};

export default Add322Modal;
