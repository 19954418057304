import styled from 'styled-components';

const StyledMasterCommonButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 40px 20px 0;
  column-gar: 15px;
`;

export default StyledMasterCommonButtonContainer;
