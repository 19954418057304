import generatePatchData from '../Generator/generatePatchData';
import { validateChassisDataGrid } from '../../Common/Handler/formValidationHandler';

const handleSaveClick = async ({
  props,
  chassisInvoicePatchMutator,
  statusPatchMutator,
  setErrorModalOptions,
}) => {
  const { seq, editable, ...submitData } = props?.gridParam?.data;
  if (editable) {
    const patchData = generatePatchData({ dataObject: submitData });
    const isValidPatchData = validateChassisDataGrid({
      gridParam: props?.gridParam,
      submitData: patchData,
      setErrorModalOptions,
    });
    if (isValidPatchData) {
      await chassisInvoicePatchMutator({
        seq,
        patchData,
        gridParam: props?.gridParam,
      });
    }
  } else {
    const patchData = {
      status: submitData?.status,
    };
    await statusPatchMutator({ seq, patchData, gridParam: props?.gridParam });
  }
};

export default handleSaveClick;
