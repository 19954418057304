import React from 'react';
import CommonTextarea from '../../../../../../../../../Inputs/CommonTextarea/CommonTextarea';
import CommonButton from '../../../../../../../../../CommonButton/CommonButton';

const DualMoveModalContentMemoContent = ({ memo, setMemo, ...props }) => {
  const { jobSeq, saveMutate, isModifiable, job } = props;

  return (
    <div>
      <div className="flex items-center gap-[2px]">
        <div className="flex justify-end items-center h-full w-[80px] pr-[10px]" />
        <div className="flex flex-col w-[762px] h-[88px]">
          <CommonTextarea
            inputName={`dual_move_memo_${jobSeq}_${job?.type}`}
            labelVisible={false}
            placeholder="Type here..."
            height="h-[88px]"
            suppressHelperText
            onChangeHandler={({ e, onChange }) => {
              onChange(e?.target?.value);
              setMemo(prev => e?.target?.value);
            }}
            defaultValue={memo}
            disabled={!isModifiable}
          />
        </div>
      </div>
      <div className="flex items-center gap-[2px] pt-[6px]">
        <div className="flex justify-end items-center h-full w-[80px] pr-[10px]" />
        <div className="flex flex-col w-[762px] h-full items-end">
          {isModifiable && (
            <CommonButton
              category="save"
              preventDupClick
              onClick={({ buttonRef }) =>
                saveMutate({ seq: jobSeq, content: memo, buttonRef })
              }
            >
              Save
            </CommonButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default DualMoveModalContentMemoContent;
