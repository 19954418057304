import { useMutation, useQueryClient } from '@tanstack/react-query';
import postConfData from '../../../services/Admin/Conf/Post/postConfData';
import { ADMIN_CONF_KEY_VALUES } from '../../../constants/Admin/Conf/keyValues';
import useControlToast from '../../Common/Toast/useControlToast';
import { SUCCESS_SAVE } from '../../../constants/Common/successMessage';

const usePostConfData = ({
  title,
  gridRef,
  setMessageContent,
  setMessageModalOpen,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  const mutator = useMutation({
    mutationFn: postConfData,
    onSettled: (data, error, variables) => {
      variables?.setAddDisabled(false);
    },
    onSuccess: () => {
      const topRow = gridRef.current.api.getPinnedTopRow(0);
      topRow.setData({});
      gridRef.current.api.redrawRows({ rowNodes: [topRow] });
      queryClient.refetchQueries({
        queryKey: ADMIN_CONF_KEY_VALUES?.[title]?.(),
      });
      addToast({ message: SUCCESS_SAVE });
    },
    onError: error => {
      setMessageContent(error?.message);
      setMessageModalOpen(true);
    },
  });
  return mutator;
};

export default usePostConfData;
