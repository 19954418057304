import React from 'react';
import { useFormContext } from 'react-hook-form';
import CommonButton from '../../../../CommonButton/CommonButton';

const ButtonContent = ({
  handleDecline,
  setIsDecline204ModalOpen,
  buttonRef,
}) => {
  const { handleSubmit } = useFormContext();

  return (
    <div className="flex justify-center">
      <div className="mr-[18px]">
        <CommonButton
          category="cancel"
          onClick={() => setIsDecline204ModalOpen(false)}
        >
          Cancel
        </CommonButton>
      </div>
      <CommonButton
        type="button"
        category="save"
        ref={buttonRef}
        onClick={handleSubmit(handleDecline)}
      >
        Save
      </CommonButton>
    </div>
  );
};

export default ButtonContent;
