import { useQuery } from '@tanstack/react-query';
import masterKeys from '../keys';
import { commonCompanyAPI } from '../../../services/Common/Company/commonCompanyAPI';

const useGetCustomerList = () => {
  const {
    isLoading: loadingCustomerList,
    data: customerList,
    isError: errorCustomerList,
  } = useQuery({
    queryKey: [masterKeys?.customerList()],
    queryFn: () => commonCompanyAPI.getCommonCustomerCompany(),
    staleTime: 0,
  });
  return { loadingCustomerList, customerList, errorCustomerList };
};

export default useGetCustomerList;
