import {
  PERM_BC_DETAIL_BARE_EDIT,
  PERM_BC_DETAIL_BARE_VIEW,
  PERM_BC_DETAIL_BROK_EDIT,
  PERM_BC_DETAIL_BROK_VIEW,
  PERM_BC_DETAIL_EXP_EDIT,
  PERM_BC_DETAIL_EXP_VIEW,
  PERM_BC_DETAIL_IMP_EDIT,
  PERM_BC_DETAIL_IMP_VIEW,
  PERM_BC_DETAIL_VAN_EDIT,
  PERM_BC_DETAIL_VAN_VIEW,
} from '../../../constants/Common/permission';

const handleBCDetailPermission = ({ woType }) => {
  const getPermissionList = localStorage?.getItem?.('permission')?.split(',');

  if (!getPermissionList) return false;

  if (woType === 'import') {
    return (
      getPermissionList?.includes(PERM_BC_DETAIL_IMP_VIEW) ||
      getPermissionList?.includes(PERM_BC_DETAIL_IMP_EDIT)
    );
  }
  if (woType === 'export') {
    return (
      getPermissionList?.includes(PERM_BC_DETAIL_EXP_VIEW) ||
      getPermissionList?.includes(PERM_BC_DETAIL_EXP_EDIT)
    );
  }
  if (woType === 'van') {
    return (
      getPermissionList?.includes(PERM_BC_DETAIL_VAN_VIEW) ||
      getPermissionList?.includes(PERM_BC_DETAIL_VAN_EDIT)
    );
  }
  if (woType === 'brokerage') {
    return (
      getPermissionList?.includes(PERM_BC_DETAIL_BROK_VIEW) ||
      getPermissionList?.includes(PERM_BC_DETAIL_BROK_EDIT)
    );
  }
  if (woType === 'bare-chassis') {
    return (
      getPermissionList?.includes(PERM_BC_DETAIL_BARE_VIEW) ||
      getPermissionList?.includes(PERM_BC_DETAIL_BARE_EDIT)
    );
  }
  return false;
};

export default handleBCDetailPermission;
