import styled from 'styled-components';

const StyledWeeklyTableHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 7px;
`;

export default StyledWeeklyTableHeader;
