import styled from 'styled-components';

const StyledTMSReportDetailModalNGLCUSContainer = styled.div`
  display: flex;
  width: 300px;
  height: 40px;
  background-color: #f9f9f9;
  align-items: center;
  margin-bottom: 10px;
`;

export default StyledTMSReportDetailModalNGLCUSContainer;
