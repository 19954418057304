const createDropdownOptions = (data, key) => {
  const emptyOption = { value: '', label: 'Select' };

  const options = data?.map(item => ({
    value: item[key],
    label: item[key],
  }));

  return [emptyOption, ...options];
};

export default createDropdownOptions;
