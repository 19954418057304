import handleQueryError from '../../../utils/Common/Handler/handleQueryError';
import commonDefaultApi from '../api';

/**
 * @description
 * Get company list with id, business name, category, credit hold
 * @param {string} urlParams
 * category {number}: 1 = Customer, 2 = Vendor, 4 = CNEE, 8 = NGL
 * credit {boolean}: true = credit hold, false = not credit hold
 *
 * ex) ?category=6&credit=true => get vendor and cnee list with credit hold
 * ex) ?category=1&credit=false => get customer list without credit hold
 * @returns
 */
const getCompanyIDList = async ({ urlParams }) => {
  try {
    const response = await commonDefaultApi.get(`company-list${urlParams}`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getCompanyIDList;
