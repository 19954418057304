import {
  PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
  PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
} from '../../constants/Common/path';
import {
  MAIN_CATEGORY_BARE_CHASSIS,
  MAIN_CATEGORY_BROKERAGE,
  MAIN_CATEGORY_EXPORT,
  MAIN_CATEGORY_IMPORT,
  MAIN_CATEGORY_VAN,
} from '../../constants/Main/Common/category';

const WHITE_ICON_STYLE = {
  color: 'white',
  icon: {
    fold: PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
    unfold: PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
  },
};

export const CUSTOM_COLUMN_COLORS = {
  [MAIN_CATEGORY_IMPORT]: {
    po_g: { backgroundColor: '#CCD3E3', color: 'black' },
    dilv_g: { backgroundColor: '#B3BCD9', color: 'black' },
    pu_g: { backgroundColor: '#919FC1', color: 'black' },
    rtn_g: { backgroundColor: '#6A7EAE', ...WHITE_ICON_STYLE },
    ar_g: { backgroundColor: '#415688', ...WHITE_ICON_STYLE },
    ap_g: { backgroundColor: '#223C77', ...WHITE_ICON_STYLE },
    doc_g: { backgroundColor: '#002265', ...WHITE_ICON_STYLE },
    doc_g_exp: { backgroundColor: '#002265', ...WHITE_ICON_STYLE },
  },
  [MAIN_CATEGORY_EXPORT]: {
    po_g: { backgroundColor: '#FAD7D7', color: 'black' },
    dilv_g: { backgroundColor: '#F5BABA', color: 'black' },
    pu_g: { backgroundColor: '#F0A0A0', color: 'black' },
    rtn_g: { backgroundColor: '#D96B6B', ...WHITE_ICON_STYLE },
    ar_g: { backgroundColor: '#CC4E4E', ...WHITE_ICON_STYLE },
    ap_g: { backgroundColor: '#A73232', ...WHITE_ICON_STYLE },
    doc_g: { backgroundColor: '#841818', ...WHITE_ICON_STYLE },
    doc_g_exp: { backgroundColor: '#841818', ...WHITE_ICON_STYLE },
  },
  [MAIN_CATEGORY_VAN]: {
    po_g: { backgroundColor: '#D7FAEE', color: 'black' },
    dilv_g: { backgroundColor: '#BBFAE5', color: 'black' },
    rtn_g: { backgroundColor: '#95E2C8', ...WHITE_ICON_STYLE },
    ar_g: { backgroundColor: '#65B59A', ...WHITE_ICON_STYLE },
    ap_g: { backgroundColor: '#427966', ...WHITE_ICON_STYLE },
    doc_g: { backgroundColor: '#143C2E', ...WHITE_ICON_STYLE },
  },
  [MAIN_CATEGORY_BROKERAGE]: {
    po_g: { backgroundColor: '#F3E7FE', color: 'black' },
    dilv_g: { backgroundColor: '#DEC3FA', color: 'black' },
    pu_g: { backgroundColor: '#C6A3F2', color: 'black' },
    rtn_g: { backgroundColor: '#9F76C8', ...WHITE_ICON_STYLE },
    ar_g: { backgroundColor: '#7E53AA', ...WHITE_ICON_STYLE },
    ap_g: { backgroundColor: '#663F8D', ...WHITE_ICON_STYLE },
    doc_g: { backgroundColor: '#360B62', ...WHITE_ICON_STYLE },
  },
  [MAIN_CATEGORY_BARE_CHASSIS]: {
    po_g: { backgroundColor: '#FFF9E1', color: 'black' },
    dilv_g: { backgroundColor: '#FFF2C4', color: 'black' },
    pu_g: { backgroundColor: '#FBE69C', color: 'black' },
    rtn_g: { backgroundColor: '#F3D877', color: 'black' },
    ar_g: { backgroundColor: '#E0C152', ...WHITE_ICON_STYLE },
    ap_g: { backgroundColor: '#B59628', ...WHITE_ICON_STYLE },
    doc_g: { backgroundColor: '#8B6E04', ...WHITE_ICON_STYLE },
  },
};
