import handleQueryError from '../../../utils/Common/Handler/handleQueryError';
import adminDefaultApi from '../api';

const postAuthList = async ({ data }) => {
  try {
    const response = await adminDefaultApi.post(`group`, data);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default postAuthList;
