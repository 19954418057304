import { CELL_TYPE_TEXT } from '../../../../constants/Common/DataGrid/cellType';
import cellEditorGenerator from '../../../Common/Datagrid/Generator/cellEditorGenerator';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';

const getEditableTextDef = ({ field }) => {
  return {
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        cellType: CELL_TYPE_TEXT,
      }),
    cellEditorSelector: gridParam =>
      cellEditorGenerator({
        gridParam,
        cellType: CELL_TYPE_TEXT,
        maxLength: field === 'remarks' ? 512 : null,
      }),
  };
};

export default getEditableTextDef;
