import generateCustomerColumn from '../Generator/generateCustomerColumn';

/**
 * @description
 * Function that filter column definition and reapply it to the grid
 * @param {object} selectedList
 * Selected list from the saved list modal
 * @returns {array} filterColumnDefs
 * Filtered column definition
 */
const filterColumnDefinition = ({ selectedList }) => {
  const selectedColumnList = selectedList?.cus_column || [];
  const filterColumnDefs = generateCustomerColumn({
    customerColumn: selectedColumnList,
  });

  return filterColumnDefs;
};

export default filterColumnDefinition;
