import React, { useEffect, useState } from 'react';
import StyledTraceCommonSidebarContainer from '../../styles/Trace/Common/StyledTraceCommonSidebarContainer';
import StyledTraceMapAccordionWrapper from '../../styles/Trace/Map/StyledTraceMapAccordionWrapper';
import StyledTraceMapCheckbox from '../../styles/Trace/Map/StyledTraceMapCheckbox';
import StyledTraceMapCheckboxLabel from '../../styles/Trace/Map/StyledTraceMapCheckboxLabel';
import StyledTraceMapSubList from '../../styles/Trace/Map/StyledTraceMapSubList';
import StyledTraceMapTitle from '../../styles/Trace/Map/StyledTraceMapTitle';
import StyledTraceMapTitleWithIcon from '../../styles/Trace/Map/StyledTraceMapTitleWithIcon';
import StyledTraceMapVehicleName from '../../styles/Trace/Map/StyledTraceMapVehicleName';
import StyledTraceMapVehicleTypeContainer from '../../styles/Trace/Map/StyledTraceMapVehicleTypeContainer';
import StyledTraceMapVehicleTypeLabel from '../../styles/Trace/Map/StyledTraceMapVehicleTypeLabel';
import handleVehicleCheckboxChange from '../../utils/Trace/Map/handleVehicleCheckboxChange';
import handleVehicleTypeCheckboxChange from '../../utils/Trace/Map/handleVehicleTypeCheckboxChange';
import toggleDivision from '../../utils/Trace/Map/toggleDivision';
import toggleVehicleType from '../../utils/Trace/Map/toggleVehicleType';
import SearchBar from '../Trace/Module/SearchBar';

const TraceSidebarMap = ({
  markerList,
  setMarkerList,
  setTarget,
  traceMapList,
  traceMovingList,
  loadingMapList,
  loadingMovingList,
}) => {
  const [checkedItems, setCheckedItems] = useState({});
  const [expandedDivisions, setExpandedDivisions] = useState({});
  const [expandedVehicleTypes, setExpandedVehicleTypes] = useState({});
  const allDiv = localStorage.getItem('allDiv');
  const [traceList, setTraceList] = useState();

  useEffect(() => {
    const mergeTraceLists = (list1, list2) => {
      const mergedList = {};

      const merge = sourceList => {
        Object.keys(sourceList)?.forEach(division => {
          if (!mergedList[division]) {
            mergedList[division] = {};
          }

          Object.keys(sourceList[division])?.forEach(vehicleType => {
            if (!mergedList[division][vehicleType]) {
              mergedList[division][vehicleType] = [];
            }

            mergedList[division][vehicleType] = [
              ...mergedList[division][vehicleType],
              ...sourceList[division][vehicleType],
            ];
          });
        });
      };

      merge(list1);
      merge(list2);

      return mergedList;
    };

    if (!traceMapList || !traceMovingList) {
      return;
    }

    const mergedList = mergeTraceLists(traceMapList, traceMovingList);
    setTraceList(mergedList);
  }, [traceMapList, traceMovingList]);

  useEffect(() => {
    if (traceList) {
      const updatedCheckedItems = {};

      Object.keys(traceList).forEach(division => {
        updatedCheckedItems[division] = updatedCheckedItems[division] || {
          all: false,
          vehicleTypes: {},
        };

        Object.keys(traceList[division]).forEach(vehicleType => {
          updatedCheckedItems[division].vehicleTypes[vehicleType] =
            updatedCheckedItems[division].vehicleTypes[vehicleType] || {
              all: false,
              vehicles: {},
            };

          let isAnyVehicleCheckedInType = false;

          traceList[division][vehicleType].forEach(vehicle => {
            const isVehicleChecked = markerList.some(
              marker => marker.id === vehicle.id,
            );
            updatedCheckedItems[division].vehicleTypes[vehicleType].vehicles[
              vehicle.name
            ] = isVehicleChecked;

            if (isVehicleChecked) {
              isAnyVehicleCheckedInType = true;
            }
          });

          updatedCheckedItems[division].vehicleTypes[vehicleType].all =
            isAnyVehicleCheckedInType;
        });
      });

      setCheckedItems(updatedCheckedItems);
    }
  }, [traceList, markerList]);

  if (loadingMapList || loadingMovingList) {
    return (
      <StyledTraceCommonSidebarContainer>
        <SearchBar />
      </StyledTraceCommonSidebarContainer>
    );
  }

  return (
    <StyledTraceCommonSidebarContainer>
      <SearchBar setTarget={setTarget} />
      <StyledTraceMapAccordionWrapper>
        {traceList &&
          Object.entries(traceList).map(
            ([division, vehicleTypes]) =>
              allDiv.includes(division) && (
                <>
                  <div key={division}>
                    <StyledTraceMapTitleWithIcon
                      onClick={() =>
                        toggleDivision({ division, setExpandedDivisions })
                      }
                    >
                      <StyledTraceMapTitle>{division}</StyledTraceMapTitle>
                      <div>
                        {expandedDivisions[division] ? (
                          <img
                            src="/assets/images/traceIcon/arrowOpenedBlack.svg"
                            alt="arrowOpenedBlack"
                          />
                        ) : (
                          <img
                            src="/assets/images/traceIcon/arrowClosedBlack.svg"
                            alt="arrowClosedBlack"
                          />
                        )}
                      </div>
                    </StyledTraceMapTitleWithIcon>
                    {expandedDivisions[division] && (
                      <StyledTraceMapSubList>
                        {Object.keys(vehicleTypes).map(vehicleType => (
                          <div key={vehicleType}>
                            <StyledTraceMapVehicleTypeLabel
                              vehicleType={vehicleType}
                            >
                              <StyledTraceMapCheckbox
                                data-testid="checkbox"
                                type="checkbox"
                                checked={
                                  checkedItems[division]?.vehicleTypes[
                                    vehicleType
                                  ]?.all
                                }
                                onChange={() =>
                                  handleVehicleTypeCheckboxChange({
                                    division,
                                    vehicleType,
                                    checkedItems,
                                    setCheckedItems,
                                    traceList,
                                    markerList,
                                    setMarkerList,
                                  })
                                }
                              />
                              <StyledTraceMapVehicleTypeContainer
                                onClick={() =>
                                  toggleVehicleType({
                                    division,
                                    vehicleType,
                                    setExpandedVehicleTypes,
                                  })
                                }
                              >
                                <span>{vehicleType}</span>
                                <div>
                                  {expandedVehicleTypes[
                                    `${division}-${vehicleType}`
                                  ] ? (
                                    <img
                                      src="/assets/images/traceIcon/arrowOpenedWhite.svg"
                                      alt="arrowOpenedWhite"
                                    />
                                  ) : (
                                    <img
                                      src="/assets/images/traceIcon/arrowClosedWhite.svg"
                                      alt="arrowClosedWhite"
                                    />
                                  )}
                                </div>
                              </StyledTraceMapVehicleTypeContainer>
                            </StyledTraceMapVehicleTypeLabel>
                            {expandedVehicleTypes[
                              `${division}-${vehicleType}`
                            ] && (
                              <StyledTraceMapSubList>
                                {vehicleTypes[vehicleType].map(vehicle => (
                                  <div key={`${vehicle.name}-${vehicle.id}`}>
                                    <StyledTraceMapCheckboxLabel>
                                      <StyledTraceMapCheckbox
                                        type="checkbox"
                                        checked={
                                          checkedItems[division]?.vehicleTypes[
                                            vehicleType
                                          ]?.vehicles[vehicle.name]
                                        }
                                        onChange={() =>
                                          handleVehicleCheckboxChange({
                                            division,
                                            vehicleType,
                                            vehicleName: vehicle.name,
                                            checkedItems,
                                            setCheckedItems,
                                            traceList,
                                            setMarkerList,
                                          })
                                        }
                                      />
                                      <StyledTraceMapVehicleName>
                                        {vehicle.name}
                                      </StyledTraceMapVehicleName>
                                    </StyledTraceMapCheckboxLabel>
                                  </div>
                                ))}
                              </StyledTraceMapSubList>
                            )}
                          </div>
                        ))}
                      </StyledTraceMapSubList>
                    )}
                  </div>
                  <img
                    src="/assets/images/traceIcon/separateLine.svg"
                    alt="separateLine"
                  />
                </>
              ),
          )}
      </StyledTraceMapAccordionWrapper>
    </StyledTraceCommonSidebarContainer>
  );
};

export default TraceSidebarMap;
