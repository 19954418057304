export const STATUS_CODE_OPTION = [
  { label: 'WAITING', value: 'US0001' },
  { label: 'WORKING', value: 'US0002' },
];

export const STATUS_CODE = {
  US0001: { label: 'WAITING', value: 2 },
  US0002: { label: 'WORKING', value: 1 },
  US0003: { label: 'RETIRED', value: 0 },
};

export const STATUS_VALUE = {
  WAITING: 'US0001',
  WORKING: 'US0002',
  RETIRED: 'US0003',
};
