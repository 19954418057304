import styled from 'styled-components';

const StyledCommonSideModalHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 9fr 1fr;
  grid-template-areas: 'left center right';
  height: 110px;
  min-height: 110px;
`;

export default StyledCommonSideModalHeader;
