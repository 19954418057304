import axios from 'axios';
import interceptRequest from '../../utils/Common/Interceptor/interceptRequest';

const OCR_BASE_URL = `${process.env.REACT_APP_BASE_URL}/ocr/`;

/**
 * Axios object with base url
 */
const ocrDefaultApi = axios.create({
  withCredentials: true,
  baseURL: OCR_BASE_URL,
  xsrfHeaderName: 'X-CSRFTOKEN',
  xsrfCookieName: 'XCSRF-TOKEN',
  headers: {
    'Content-Type': 'multipart/form-data',
    common: {
      'X-CSRFToken': localStorage.getItem('csrfToken'),
    },
  },
});

/** Interceptors */
interceptRequest(ocrDefaultApi);

export default ocrDefaultApi;
