import React from 'react';
import dayjs from 'dayjs';
import {
  MANIFEST_STATUS,
  MANIFEST_DETAIL_CELL_WIDTH,
} from '../../../../../constants/MobileAdmin/Manifest/manifestDetailValues';

const SingleRow = ({ data, status }) => {
  return (
    <div className="group-row">
      <div className={`status ${MANIFEST_STATUS?.[status]}`}>{status}</div>
      <div className="group-col">
        {data?.map((group, index) => (
          <div key={`${String(index)}${status}`} className="group-row h-[35px]">
            <div className={`cell ${MANIFEST_DETAIL_CELL_WIDTH.bill_date}`}>
              {group?.bill_date && dayjs(group?.bill_date).format('MM/DD/YY')}
            </div>
            <div className={`cell ${MANIFEST_DETAIL_CELL_WIDTH.bill_no}`}>
              {group?.bill_no}
            </div>
            <div className={`cell ${MANIFEST_DETAIL_CELL_WIDTH.acct_code}`}>
              {group?.acct_code}
            </div>
            <div
              className={`cell ${MANIFEST_DETAIL_CELL_WIDTH.acct_code_name}`}
            >
              {group?.acct_code_name}
            </div>
            <div
              className={`cell cell-dollar ${MANIFEST_DETAIL_CELL_WIDTH.amt} px-[13px]`}
            >
              {group?.amt && <div>$</div>}
              {group?.amt}
            </div>
            <div
              className={`cell cell-dollar ${MANIFEST_DETAIL_CELL_WIDTH.bill_amt}`}
            >
              {group?.bill_amt && <div>$</div>}
              {group?.bill_amt}
            </div>
            <div
              className={`cell cell-dollar ${MANIFEST_DETAIL_CELL_WIDTH.paid_amt}`}
            >
              {group?.paid_amt && <div>$</div>}
              {group?.paid_amt}
            </div>
            <div className={`cell ${MANIFEST_DETAIL_CELL_WIDTH.description}`}>
              {group?.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SingleRow;
