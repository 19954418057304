import dayjs from 'dayjs';

/**
 * @description
 * Function that convert date to formatted date
 * @param {string | object} date
 * Date to be converted
 * @param {boolean} returnNull
 * Flag if the function should return null
 * @returns {string | null}
 * Formatted date
 */
const generateFormattedDate = ({ date, returnEmptyString }) => {
  if (date && dayjs(date)?.isValid()) {
    return dayjs(date)?.format('MM/DD/YY');
  }
  return returnEmptyString ? '' : null;
};

export default generateFormattedDate;
