import React from 'react';
import { useWatch } from 'react-hook-form';
import StyledMiddleInfo from '../../../../styles/Order/StyledMiddleInfo';
import {
  SUB_ORDER_TYPE_BARECHASSIS,
  SUB_ORDER_TYPE_BROKERAGE,
  SUB_ORDER_TYPE_EXPREG,
  SUB_ORDER_TYPE_EXPSHUTTLE,
  SUB_ORDER_TYPE_IMPREG,
  SUB_ORDER_TYPE_IMPSHUTTLE,
  SUB_ORDER_TYPE_VAN,
} from '../../../../constants/Order/Common/basic';
import CommonTextInput from '../../../Inputs/CommonTextInput/CommonTextInput';
import CommonTextarea from '../../../Inputs/CommonTextarea/CommonTextarea';
import CommonDate from '../../../Inputs/CommonDate/CommonDate';
import CommonTextSelect from '../../../Inputs/CommonTextSelect/CommonTextSelect';
import CommonCheckbox from '../../../Inputs/CommonCheckbox/CommonCheckbox';
import handleCheckboxChange from '../../../../utils/Order/Handler/InputHandler/handleCheckboxChange';
import handleTextInputChange from '../../../../utils/Order/Handler/InputHandler/handleTextInputChange';
import { COMMON_FREEDAYS_SELECT_OPTION } from '../../../../constants/Common/selectOption';
import { REGEX_NUMBER_REGEX } from '../../../../constants/Common/regex';
import handleRegexChange from '../../../../utils/Order/Handler/InputHandler/handleRegexChange';
import handleSelectChange from '../../../../utils/Order/Handler/InputHandler/handleSelectChange';
import handleDateChange from '../../../../utils/Order/Handler/InputHandler/handleDateChange';
import CommonTime from '../../../Inputs/CommonTime/CommonTime';
import validateEmail from '../../../../utils/Common/Input/Validator/validateEmail';

const OrderMiddleInfo = ({ subCategory, ...props }) => {
  const cutOffDateWatcher = !!useWatch({ name: 'cutoff_date' });
  const cutOffTimeWatcher = !!useWatch({ name: 'cutoff_time' });
  const isSendToRequired = [
    SUB_ORDER_TYPE_IMPREG,
    SUB_ORDER_TYPE_EXPREG,
  ].includes(subCategory);

  return (
    <div
      className={`${
        subCategory === SUB_ORDER_TYPE_BARECHASSIS
          ? `pt-[42px] pb-[28px]`
          : `pt-[25px] pb-[11px]`
      } px-[30px]`}
    >
      <StyledMiddleInfo subCategory={subCategory}>
        <div>
          {/* Input Section 01. */}
          {/* Impreg */}
          {[SUB_ORDER_TYPE_IMPREG].includes(subCategory) && (
            <CommonDate
              inputName="eta"
              label="ETA"
              onChangeHandler={handleDateChange}
            />
          )}
          {/* ImpShuttle, Van, BareChassis */}
          {[
            SUB_ORDER_TYPE_IMPSHUTTLE,
            SUB_ORDER_TYPE_VAN,
            SUB_ORDER_TYPE_BROKERAGE,
            SUB_ORDER_TYPE_BARECHASSIS,
          ].includes(subCategory) && (
            <CommonTextarea
              inputName="remarks"
              label="REMARKS"
              height="h-[30px]"
              placeholder="Type here..."
              onChangeHandler={handleTextInputChange}
              maxLength={512}
            />
          )}
          {/* Expreg, ExpShuttle */}
          {[SUB_ORDER_TYPE_EXPREG, SUB_ORDER_TYPE_EXPSHUTTLE].includes(
            subCategory,
          ) && (
            <CommonDate
              inputName="erd"
              label="ERD"
              onChangeHandler={handleDateChange}
              disabled={[
                SUB_ORDER_TYPE_EXPREG,
                SUB_ORDER_TYPE_EXPSHUTTLE,
              ].includes(subCategory)}
            />
          )}
        </div>
        <div>
          {/* Input Section 02. */}
          {/* ImpShuttle, Van, BareChassis */}
          {[
            SUB_ORDER_TYPE_IMPSHUTTLE,
            SUB_ORDER_TYPE_VAN,
            SUB_ORDER_TYPE_BROKERAGE,
            SUB_ORDER_TYPE_BARECHASSIS,
          ].includes(subCategory) && (
            <CommonTextInput
              inputName="sendto"
              label="DO SENDER"
              required={isSendToRequired}
              maxLength={256}
              placeholder="Email address"
              onChangeHandler={handleTextInputChange}
              validate={{
                custom: {
                  ...validateEmail(),
                },
              }}
            />
          )}
          {/* ImpReg */}
          {[SUB_ORDER_TYPE_IMPREG].includes(subCategory) && (
            <CommonDate
              inputName="lfd"
              label="LFD"
              onChangeHandler={handleDateChange}
            />
          )}
          {/* ExpReg, ExpShuttle */}
          {[SUB_ORDER_TYPE_EXPREG, SUB_ORDER_TYPE_EXPSHUTTLE].includes(
            subCategory,
          ) && (
            <div className="flex flex-row gap-[3px]">
              <div className="w-[110px]">
                <CommonDate
                  inputName="cutoff_date"
                  label="CUT OFF"
                  required={cutOffTimeWatcher}
                  onChangeHandler={handleDateChange}
                  disabled={[
                    SUB_ORDER_TYPE_EXPREG,
                    SUB_ORDER_TYPE_EXPSHUTTLE,
                  ].includes(subCategory)}
                />
              </div>
              <div className="w-[90px]">
                <CommonTime
                  inputName="cutoff_time"
                  label=""
                  required={cutOffDateWatcher}
                  onChangeHandler={handleDateChange}
                  disabled={[
                    SUB_ORDER_TYPE_EXPREG,
                    SUB_ORDER_TYPE_EXPSHUTTLE,
                  ].includes(subCategory)}
                />
              </div>
            </div>
          )}
        </div>
        {![
          SUB_ORDER_TYPE_IMPSHUTTLE,
          SUB_ORDER_TYPE_VAN,
          SUB_ORDER_TYPE_BROKERAGE,
          SUB_ORDER_TYPE_BARECHASSIS,
        ].includes(subCategory) && (
          <>
            <div>
              {/* Input Section 03. */}
              <CommonTextSelect
                inputName={['freedays', 'freedays_type']}
                label="FREEDAYS"
                required={[true, true]}
                maxLength={[2, 100]}
                option={COMMON_FREEDAYS_SELECT_OPTION}
                onChangeHandler={[handleRegexChange, handleSelectChange]}
                regex={REGEX_NUMBER_REGEX}
                defaultValue={['', 'FD0001']}
              />
            </div>
            {subCategory === SUB_ORDER_TYPE_IMPREG && (
              <div>
                {/* Input Section 04. */}
                <CommonTextarea
                  inputName="remarks"
                  label="REMARKS"
                  placeholder="Type here..."
                  height="h-[97px]"
                  maxLength={512}
                  onChangeHandler={handleTextInputChange}
                />
              </div>
            )}
            <div
              className={`${
                subCategory === SUB_ORDER_TYPE_IMPREG
                  ? `flex flex-row gap-[10px]`
                  : ''
              }`}
            >
              {/* Input Section 05. */}
              {/* ImpReg */}
              {subCategory === SUB_ORDER_TYPE_IMPREG && (
                <>
                  <div className="w-[242px]">
                    <CommonCheckbox
                      label="AVAILABILITY"
                      onChangeHandler={handleCheckboxChange}
                      options={[
                        {
                          inputName: 'ava_dsc',
                          defaultValue: false,
                          optionLabel: 'DSC',
                        },
                        {
                          inputName: 'ava_obl',
                          defaultValue: false,
                          optionLabel: 'OBL',
                        },
                        {
                          inputName: 'ava_cus',
                          defaultValue: false,
                          optionLabel: 'CUS',
                        },
                      ]}
                      borderVisible
                    />
                  </div>
                  <div className="w-[190px]">
                    <CommonTextInput
                      inputName="sendto"
                      label="DO SENDER"
                      required={isSendToRequired}
                      placeholder="Email address"
                      onChangeHandler={handleTextInputChange}
                      maxLength={256}
                      validate={{
                        custom: {
                          ...validateEmail(),
                        },
                      }}
                    />
                  </div>
                </>
              )}
              {/* ExpReg, ExpShuttle */}
              {[SUB_ORDER_TYPE_EXPREG, SUB_ORDER_TYPE_EXPSHUTTLE].includes(
                subCategory,
              ) && (
                <CommonTextarea
                  inputName="remarks"
                  label="REMARKS"
                  height="h-[30px]"
                  placeholder="Type here..."
                  maxLength={512}
                  onChangeHandler={handleTextInputChange}
                />
              )}
            </div>
            {![SUB_ORDER_TYPE_IMPREG].includes(subCategory) && (
              <div>
                {/* Input Section 06. */}
                <CommonTextInput
                  inputName="sendto"
                  label="DO SENDER"
                  required={isSendToRequired}
                  placeholder="Email address"
                  onChangeHandler={handleTextInputChange}
                  maxLength={256}
                  validate={{
                    custom: {
                      ...validateEmail(),
                    },
                  }}
                />
              </div>
            )}
          </>
        )}
      </StyledMiddleInfo>
    </div>
  );
};

export default OrderMiddleInfo;
