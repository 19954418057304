/**
 * @description
 * Function to set equips to order form
 * @param {list} containerFormProperty
 * Container form property list
 * @returns
 * equip list
 */
const copyOrderDataEquipsToForm = ({
  containerFormProperty,
  orderInputData,
  containerList,
}) => {
  const equip = [];
  const containerKeySet = containerList?.map(
    container => container?.containerKey,
  );

  containerKeySet?.forEach((containerKey, i) => {
    const equipItem = {};

    containerFormProperty.forEach(property => {
      equipItem[property] = orderInputData[`${property}_${containerKey}`];
    });

    equip.push(equipItem);
  });

  return equip;
};

export default copyOrderDataEquipsToForm;
