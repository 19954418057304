import React from 'react';
import '../../traceStyle.css';
import StyledTraceJobInfoWindowContainer from '../../../../styles/Trace/Job/StyledTraceJobInfoWindowContainer';

const JobInfoWindow = ({ data }) => {
  return (
    data && (
      <StyledTraceJobInfoWindowContainer>
        <div className="InfoHeaderTruck">
          <p className="InfoHeaderText">
            {data?.wo_no || '-'} / {data?.driver || '-'} ({data?.div || '-'})
          </p>
        </div>
        <div className="InfoBody">
          <div className="InfoBodyTitle">
            <p>Timestamp</p>
            <p>Container #</p>
            <p>Chassis #</p>
            <p>Current Location</p>
            <p>Destination</p>
            <p>ETA</p>
          </div>
          <div className="InfoBodyContent">
            <p className="TimeStampContent">{data?.timestamp || '-'}</p>
            <p>{data?.cont_no || '-'}</p>
            <p>{data?.ch_no || '-'}</p>
            <p>
              {data?.current_location?.lat}, {data?.current_location?.lng}
            </p>
            <p>
              {data?.destination?.lat}, {data?.destination?.lng}
            </p>
            <p className="TimeStampContent">{data?.eta || '-'}</p>
          </div>
        </div>
      </StyledTraceJobInfoWindowContainer>
    )
  );
};

export default JobInfoWindow;
