import { useMutation } from '@tanstack/react-query';
import patchSwitchComplete from '../../../../services/BCDetail/DetailInfo/Common/Patch/patchSwitchComplete';
import useControlToast from '../../../Common/Toast/useControlToast';
import { SUCCESS_SWITCH } from '../../../../constants/Common/successMessage';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';

const usePatchSwitchComplete = ({
  setMessage,
  setErrorModalOpen,
  setAlertModalOpen,
  infoRefetch,
}) => {
  const { addToast } = useControlToast();

  const mutator = useMutation({
    mutationFn: patchSwitchComplete,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: success => {
      infoRefetch();
      if (success?.data?.completed) {
        addToast({
          message: SUCCESS_SWITCH,
        });
      } else {
        addToast({
          message: SUCCESS_SWITCH,
        });
      }
      setAlertModalOpen(true);
    },
    onError: error => {
      setMessage(error.message);
      setErrorModalOpen(true);
    },
  });
  return mutator;
};

export default usePatchSwitchComplete;
