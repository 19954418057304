import axios from 'axios';
import interceptRequest from '../../utils/Common/Interceptor/interceptRequest';

/**
 * TMS Report base url
 */
const TMS_REPORT_BASE_URL = `${process.env.REACT_APP_BASE_URL}/tms-report`;

/**
 * Axios object with base url
 */
const tmsReportAPI = axios.create({
  withCredentials: true,
  baseURL: TMS_REPORT_BASE_URL,
  xsrfHeaderName: 'X-CSRFTOKEN',
  xsrfCookieName: 'XCSRF-TOKEN',
  headers: {
    common: {
      'X-CSRFToken': localStorage.getItem('csrfToken'),
    },
  },
});

/** Interceptors */
interceptRequest(tmsReportAPI);

export default tmsReportAPI;
