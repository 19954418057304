import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import processData from '../../../../utils/Master/Common/processData';
import chassisRateAPI from '../api';

/**
 * Updates the chassis rate data in the backend.
 * @param {Object} data - The data to be updated.
 */
const updateChassisRate = async ({ data }) => {
  processData(data);
  try {
    const response = await chassisRateAPI.put(`/${data.seq}`, {
      div: data.div,
      billto: data.billto,
      dilv_location: data.dilv_location,
      tier: data.tier,
      effective_month: data.effective_month,
      effective_year: data.effective_year,
      category: data.category,
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default updateChassisRate;
