import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CommonMessageModal from '../../../CommonMessageModal/CommonMessageModal';
import AdvancedSearchModalContent from './AdvancedSearchModalContent';
import AdvancedSearchButtonContent from '../../Common/AdvancedSearchModal/AdvancedSearchButtonContent';
import handleSearchSubmit from '../../../../utils/Master/Common/Handler/handleSearchSubmit';

const AdvancedSearchModal = ({
  openModal,
  setOpenModal,
  setQueryObject,
  ...props
}) => {
  const searchController = useForm();

  return (
    <FormProvider {...searchController}>
      <form
        onSubmit={searchController.handleSubmit(submitData =>
          handleSearchSubmit({ submitData, setQueryObject, setOpenModal }),
        )}
      >
        <CommonMessageModal
          open={openModal}
          setOpen={setOpenModal}
          header="Advanced Search"
          content={<AdvancedSearchModalContent {...props} />}
          buttonContent={<AdvancedSearchButtonContent setOpen={setOpenModal} />}
        />
      </form>
    </FormProvider>
  );
};

export default AdvancedSearchModal;
