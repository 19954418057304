import updateButtonDisabled from '../../../../Common/Handler/updateButtonDisabled';

const onCreateInvHandler = ({
  originData,
  mutator,
  getValues,
  setIsAlertOpen,
  setIsSuccess,
  setErrorMessage,
  buttonRef,
}) => {
  const woNo =
    document.URL?.split('/')?.[document?.URL?.split('/')?.length - 1];
  const checkList = [];

  originData?.forEach(row => {
    if (getValues?.(`mod.checked_${row?.seq}`)) {
      checkList.push(row?.seq);
    }
  });

  if (checkList?.length === 0) {
    setIsAlertOpen(true);
    setIsSuccess(false);
    setErrorMessage('Please select AP data to create invoice.');
    updateButtonDisabled({ ref: buttonRef, disabled: false });
    return;
  }
  mutator?.({ woNo, data: { check_list: checkList }, buttonRef });
};

export default onCreateInvHandler;
