import React from 'react';
import CommonMessageModal from '../../../../../CommonMessageModal/CommonMessageModal';
import RollbackConfirmModalContent from './RollbackConfirmModalContent';
import RollbackConfirmModalButton from './RollbackConfirmModalButton';

const RollbackConfirmModal = ({ ...props }) => {
  const { open, setOpen, convertProps } = props;

  return (
    open && (
      <CommonMessageModal
        open={open}
        setOpen={setOpen}
        header="Confirmation"
        content={<RollbackConfirmModalContent />}
        buttonContent={
          <RollbackConfirmModalButton
            setOpen={setOpen}
            convertProps={convertProps}
          />
        }
      />
    )
  );
};

export default RollbackConfirmModal;
