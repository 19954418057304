import { useMutation } from '@tanstack/react-query';
import { logout } from '../../services/SignIn/Get/logout';

const useLogoutMutation = ({ setIsMessageOpen, setMessage }) =>
  useMutation({
    mutationFn: logout,
    onSuccess: res => {},
    onError: error => {
      setMessage(
        error.message === 'undefined <' ? 'Logout Failed' : error.message,
      );
      setIsMessageOpen(true);
    },
  });

export default useLogoutMutation;
