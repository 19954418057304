import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import commonDefaultApi from '../../api';

const getAutoComplete = async () => {
  try {
    const response = await commonDefaultApi.get(`autocomplete`, {
      params: {
        category: 'all',
      },
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getAutoComplete;
