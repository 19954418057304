import getGeoInfo from '../../../../services/Admin/Conf/Get/getGeoInfo';

const setTextFieldValue = (instance, value) => {
  instance?.setValue?.(value);
  instance?.onValueChange?.(value);
  instance?.setDataValue?.(value);
};

const handleZIPChange = async ({
  setMessageContent,
  setMessageModalOpen,
  setIsSuccess,
  ...props
}) => {
  const instances = props?.node?.rowPinned
    ? props?.api?.getCellRendererInstances()
    : props?.api
        ?.getCellEditorInstances()
        ?.map(proxy => proxy.componentInstance);

  const stateInstance = instances?.find(
    element => element?.column?.colId === 'state',
  );
  const timezoneInstance = instances?.find(
    element => element?.column?.colId === 'timezone',
  );

  if (props?.getChangedValue?.length === 5) {
    await getGeoInfo({ zip: props?.getChangedValue })
      .then(result => {
        setTextFieldValue(stateInstance, result?.data?.state);
        setTextFieldValue(timezoneInstance, result?.data?.timezone);
      })
      .catch(error => {
        setIsSuccess(false);
        setMessageContent(error?.message);
        setMessageModalOpen(true);
        setTextFieldValue(stateInstance, '');
        setTextFieldValue(timezoneInstance, '');
      });
  } else {
    setTextFieldValue(stateInstance, '');
    setTextFieldValue(timezoneInstance, '');
  }
};

export default handleZIPChange;
