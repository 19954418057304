export const DRIVER_POSITION_VALUE = {
  DR0001: 'Company',
  DR0002: 'Owner',
  DR0003: 'Carrier',
};

export const DRIVER_LEGAL_STATUS_VALUE = {
  Citizenship: 'DRL001',
  'Permanent Resident': 'DRL002',
  'Work Permit': 'DRL003',
};
