import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthRouter from '../../../../components/Common/AuthRouter';
import Main from '../../../../pages/Main';
import {
  PERM_ACCT,
  PERM_ADMIN,
  PERM_BC_DETAIL,
  PERM_CUS_REPORT,
  PERM_DISPATCH,
  PERM_EDI,
  PERM_MAIN,
  PERM_MASTER,
  PERM_MOBILE_ADMIN,
  PERM_ORDER,
  PERM_TMS_REPORT,
  PERM_TRACE,
} from '../../permission';
import Order from '../../../../pages/Order';
import Dispatch from '../../../../pages/Dispatch';
import CustomerReport from '../../../../pages/CustomerReport';
import TmsReport from '../../../../pages/TmsReport';
import MobileAdmin from '../../../../pages/MobileAdmin';
import Trace from '../../../../pages/Trace';
import Acct from '../../../../pages/Acct';
import Master from '../../../../pages/Master';
import Admin from '../../../../pages/Admin';
import ORDER_CHILDREN_ROUTE from './Order';
import MAIN_CHILDREN_ROUTE from './Main';
import DISPATCH_CHILDREN_ROUTE from './Dispatch';
import CUSTOMER_REPORT_CHILDREN_ROUTE from './CustomerReport';
import TMS_REPORT_CHILDREN_ROUTE from './TMSReport';
import MOBILE_ADMIN_CHILDREN_ROUTE from './MobileAdmin';
import TRACE_CHILDREN_ROUTE from './Trace';
import ACCT_CHILDREN_ROUTE from './Account';
import MASTER_CHILDREN_ROUTE from './Master';
import ADMIN_CHILDREN_ROUTE from './Admin';
import EDI from '../../../../pages/EDI';
import BCDetail from '../../../../pages/BCDetail';
import DailyReport from '../../../../pages/DailyReport';
import BC_DETAIL_CHILDREN_ROUTE from './BCDetail';
import LiveShare from '../../../../pages/LiveShare';
import Customer from '../../../../pages/Customer';
import getAuthorizedMenu from '../../../../utils/Common/Sidebar/Handler/getAuthorizedMenu';
import { SIDEBAR_SUB_MENU } from '../../Option/Sidebar';
import EDI_CHILDREN_ROUTE from './EDI';
import DAILY_REPORT_CHILDREN_ROUTE from './DailyReport';

const APP_CHILDREN_ROUTE = [
  {
    index: true,
    element: (
      <Navigate
        to={
          getAuthorizedMenu({
            // allMenu: SIDEBAR_SUB_MENU?.[2],
            allMenu: SIDEBAR_SUB_MENU?.[0],
          })?.[0]?.to
        }
      />
    ),
  },
  {
    path: '/',
    element: (
      <AuthRouter permission={PERM_MAIN}>
        {/* <Main /> */}
        <Order />
      </AuthRouter>
    ),
  },
  {
    path: 'order',
    element: (
      <AuthRouter permission={PERM_ORDER}>
        <Order />
      </AuthRouter>
    ),
    children: [...ORDER_CHILDREN_ROUTE],
  },
  {
    path: 'dispatch',
    element: (
      <AuthRouter permission={PERM_DISPATCH}>
        <Dispatch />
      </AuthRouter>
    ),
    children: [...DISPATCH_CHILDREN_ROUTE],
  },
  {
    path: 'main',
    element: (
      <AuthRouter permission={PERM_MAIN}>
        <Main />
      </AuthRouter>
    ),
    children: [...MAIN_CHILDREN_ROUTE],
  },
  {
    path: 'customer-report',
    element: (
      <AuthRouter permission={PERM_CUS_REPORT}>
        <CustomerReport />
      </AuthRouter>
    ),
    children: [...CUSTOMER_REPORT_CHILDREN_ROUTE],
  },
  {
    path: 'tms-report',
    element: (
      <AuthRouter permission={PERM_TMS_REPORT}>
        <TmsReport />
      </AuthRouter>
    ),
    children: [...TMS_REPORT_CHILDREN_ROUTE],
  },
  {
    path: 'daily-report',
    element: (
      <AuthRouter permission={PERM_TMS_REPORT}>
        <DailyReport />
      </AuthRouter>
    ),
    children: [...DAILY_REPORT_CHILDREN_ROUTE],
  },
  {
    path: 'mobile-admin',
    element: (
      <AuthRouter permission={PERM_MOBILE_ADMIN}>
        <MobileAdmin />
      </AuthRouter>
    ),
    children: [...MOBILE_ADMIN_CHILDREN_ROUTE],
  },
  {
    path: 'trace',
    element: (
      <AuthRouter permission={PERM_TRACE}>
        <Trace />
      </AuthRouter>
    ),
    children: [...TRACE_CHILDREN_ROUTE],
  },
  {
    path: 'edi',
    element: (
      <AuthRouter permission={PERM_EDI}>
        <EDI />
      </AuthRouter>
    ),
    children: [...EDI_CHILDREN_ROUTE],
  },
  {
    path: 'acct',
    element: (
      <AuthRouter permission={PERM_ACCT}>
        <Acct />
      </AuthRouter>
    ),
    children: [...ACCT_CHILDREN_ROUTE],
  },
  {
    path: 'master',
    element: (
      <AuthRouter permission={PERM_MASTER}>
        <Master />
      </AuthRouter>
    ),
    children: [...MASTER_CHILDREN_ROUTE],
  },
  {
    path: 'admin',
    element: (
      <AuthRouter permission={PERM_ADMIN}>
        <Admin />
      </AuthRouter>
    ),
    children: [...ADMIN_CHILDREN_ROUTE],
  },
  {
    path: 'bc-detail',
    element: (
      <AuthRouter permission={PERM_BC_DETAIL}>
        <BCDetail />
      </AuthRouter>
    ),
    children: [...BC_DETAIL_CHILDREN_ROUTE],
  },
];

export default APP_CHILDREN_ROUTE;
