const handleVehicleTypeCheckboxChange = ({
  division,
  vehicleType,
  checkedItems,
  setCheckedItems,
  traceList,
  markerList,
  setMarkerList,
}) => {
  const newCheckedItems = { ...checkedItems };

  if (!newCheckedItems[division]) {
    newCheckedItems[division] = { vehicleTypes: {} };
  }
  if (!newCheckedItems[division].vehicleTypes[vehicleType]) {
    newCheckedItems[division].vehicleTypes[vehicleType] = {
      all: false,
      vehicles: {},
    };
  }

  const isVehicleTypeChecked =
    !newCheckedItems[division].vehicleTypes[vehicleType].all;

  newCheckedItems[division].vehicleTypes[vehicleType].all =
    isVehicleTypeChecked;

  Object.keys(
    newCheckedItems[division].vehicleTypes[vehicleType].vehicles,
  ).forEach(vehicleName => {
    newCheckedItems[division].vehicleTypes[vehicleType].vehicles[vehicleName] =
      isVehicleTypeChecked;
  });

  setCheckedItems(newCheckedItems);

  let updatedMarkerList = [...markerList];

  if (isVehicleTypeChecked) {
    const markersToAdd = traceList[division][vehicleType].filter(
      vehicle => !updatedMarkerList.some(marker => marker.id === vehicle.id),
    );
    updatedMarkerList = [...updatedMarkerList, ...markersToAdd];
  } else {
    const markersToRemove = traceList[division][vehicleType].filter(vehicle =>
      updatedMarkerList.some(marker => marker.id === vehicle.id),
    );
    updatedMarkerList = updatedMarkerList.filter(
      marker => !markersToRemove.some(vehicle => vehicle.id === marker.id),
    );
  }

  setMarkerList(updatedMarkerList);
};

export default handleVehicleTypeCheckboxChange;
