import styled from 'styled-components';

const StyledTraceMapTitleWithIcon = styled.div`
  margin-top: -10px;
  padding: 0 20px;
  width: 380px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export default StyledTraceMapTitleWithIcon;
