import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import TopBar from './TopBar';
import PullOut from './PullOut';
import Delivery from './Delivery';
import PickUp from './PickUp';
import Return from './Return';

const Schedule = ({ basicInfoData }) => {
  const scheduleController = useForm();
  const [poScheduleDate, setPOScheduleDate] = useState();
  const [poScheduleTime, setPOScheduleTime] = useState();
  const [poActualInDate, setPOActualInDate] = useState();
  const [poActualInTime, setPOActualInTime] = useState();
  const [poActualOutDate, setPOActualOutDate] = useState();
  const [poActualOutTime, setPOActualOutTime] = useState();
  const [poDriver, setPODriver] = useState();
  const [dilvScheduleDate, setDILVScheduleDate] = useState();
  const [dilvScheduleTime, setDILVScheduleTime] = useState();
  const [dilvActualInDate, setDILVActualInDate] = useState();
  const [dilvActualInTime, setDILVActualInTime] = useState();
  const [dilvActualOutDate, setDILVActualOutDate] = useState();
  const [dilvActualOutTime, setDILVActualOutTime] = useState();
  const [dilvDriver, setDILVDriver] = useState();
  const [puScheduleDate, setPUScheduleDate] = useState();
  const [puScheduleTime, setPUScheduleTime] = useState();
  const [puActualInDate, setPUActualInDate] = useState();
  const [puActualInTime, setPUActualInTime] = useState();
  const [puActualOutDate, setPUActualOutDate] = useState();
  const [puActualOutTime, setPUActualOutTime] = useState();
  const [puDriver, setPUDriver] = useState();
  const [rtnScheduleDate, setRTNScheduleDate] = useState();
  const [rtnScheduleTime, setRTNScheduleTime] = useState();
  const [rtnActualInDate, setRTNActualInDate] = useState();
  const [rtnActualInTime, setRTNActualInTime] = useState();
  const [rtnActualOutDate, setRTNActualOutDate] = useState();
  const [rtnActualOutTime, setRTNActualOutTime] = useState();
  const [rtnDriver, setRTNDriver] = useState();

  useEffect(() => {
    basicInfoData?.job_set?.forEach(item => {
      if (item?.from_location?.ordering === 0) {
        item?.from_location?.type === 'P/O' &&
          (setPOScheduleDate(item?.from_location?.sche_date),
          setPOScheduleTime(item?.from_location?.sche_time),
          setPOActualInDate(item?.from_location?.actual_in_date),
          setPOActualInTime(item?.from_location?.actual_in_time),
          setPOActualOutDate(item?.from_location?.actual_out_date),
          setPOActualOutTime(item?.from_location?.actual_out_time),
          setPODriver(item?.driver));
        item?.from_location?.type === 'P/U' &&
          (setPUScheduleDate(item?.from_location?.sche_date),
          setPUScheduleTime(item?.from_location?.sche_time),
          setPUActualInDate(item?.from_location?.actual_in_date),
          setPUActualInTime(item?.from_location?.actual_in_time),
          setPUActualOutDate(item?.from_location?.actual_out_date),
          setPUActualOutTime(item?.from_location?.actual_out_time),
          setPUDriver(item?.driver));
      }
      if (item?.live_dilv_location) {
        setDILVScheduleDate(item?.live_dilv_location?.sche_date);
        setDILVScheduleTime(item?.live_dilv_location?.sche_time);
        setDILVActualInDate(item?.live_dilv_location?.actual_in_date);
        setDILVActualInTime(item?.live_dilv_location?.actual_in_time);
        setDILVActualOutDate(item?.live_dilv_location?.actual_out_date);
        setDILVActualOutTime(item?.live_dilv_location?.actual_out_time);
        setDILVDriver(item?.driver);
      }
      if (item?.to_location?.ordering === 0) {
        item?.to_location?.type === 'DILV' &&
          (setDILVScheduleDate(item?.to_location?.sche_date),
          setDILVScheduleTime(item?.to_location?.sche_time),
          setDILVActualInDate(item?.to_location?.actual_in_date),
          setDILVActualInTime(item?.to_location?.actual_in_time),
          setDILVActualOutDate(item?.to_location?.actual_out_date),
          setDILVActualOutTime(item?.to_location?.actual_out_time),
          setDILVDriver(item?.driver));
        item?.to_location?.type === 'RTN' &&
          (setRTNScheduleDate(item?.to_location?.sche_date),
          setRTNScheduleTime(item?.to_location?.sche_time),
          setRTNActualInDate(item?.to_location?.actual_in_date),
          setRTNActualInTime(item?.to_location?.actual_in_time),
          setRTNActualOutDate(item?.to_location?.actual_out_date),
          setRTNActualOutTime(item?.to_location?.actual_out_time),
          setRTNDriver(item?.driver));
      }
    });
  }, [basicInfoData]);

  return (
    <FormProvider {...scheduleController}>
      <form>
        <TopBar basicInfoData={basicInfoData} />
        <div className="flex flex-col mt-[42px]">
          <div className="flex">
            <div className="w-[72px] h-[28px]" />
            <div className="ml-[2px] w-[128px] h-[28px] text-center text-neutral-800 text-[11px] font-medium">
              LOCATION ID
            </div>
            <div className="ml-[2px] w-[252px] h-[28px] text-center text-neutral-800 text-[11px] font-medium">
              SCHEDULE
            </div>
            <div className="ml-[2px] w-[252px] h-[28px] text-center text-neutral-800 text-[11px] font-medium">
              ACTUAL IN
            </div>
            <div className="ml-[2px] w-[252px] h-[28px] text-center text-neutral-800 text-[11px] font-medium">
              ACTUAL OUT
            </div>
            <div className="ml-[2px] w-[116px] h-[28px] text-center text-neutral-800 text-[11px] font-medium">
              DRV/VEN
            </div>
          </div>
          <PullOut
            basicInfoData={basicInfoData}
            poScheduleDate={poScheduleDate}
            poScheduleTime={poScheduleTime}
            poActualInDate={poActualInDate}
            poActualInTime={poActualInTime}
            poActualOutDate={poActualOutDate}
            poActualOutTime={poActualOutTime}
            poDriver={poDriver}
          />
          <Delivery
            basicInfoData={basicInfoData}
            dilvScheduleDate={dilvScheduleDate}
            dilvScheduleTime={dilvScheduleTime}
            dilvActualInDate={dilvActualInDate}
            dilvActualInTime={dilvActualInTime}
            dilvActualOutDate={dilvActualOutDate}
            dilvActualOutTime={dilvActualOutTime}
            dilvDriver={dilvDriver}
          />
          <PickUp
            basicInfoData={basicInfoData}
            puScheduleDate={puScheduleDate}
            puScheduleTime={puScheduleTime}
            puActualInDate={puActualInDate}
            puActualInTime={puActualInTime}
            puActualOutDate={puActualOutDate}
            puActualOutTime={puActualOutTime}
            puDriver={puDriver}
          />
          <Return
            basicInfoData={basicInfoData}
            rtnScheduleDate={rtnScheduleDate}
            rtnScheduleTime={rtnScheduleTime}
            rtnActualInDate={rtnActualInDate}
            rtnActualInTime={rtnActualInTime}
            rtnActualOutDate={rtnActualOutDate}
            rtnActualOutTime={rtnActualOutTime}
            rtnDriver={rtnDriver}
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default Schedule;
