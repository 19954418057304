import React, { useState } from 'react';
import dayjs from 'dayjs';
import { useFormContext } from 'react-hook-form';
import CommonSelect from '../../../../Inputs/CommonSelect/CommonSelect';
import CommonDate from '../../../../Inputs/CommonDate/CommonDate';
import {
  MANIFEST_FILTER_STATUS,
  MANIFEST_FILTER_DATE,
  MANIFEST_FILTER_PERIOD,
} from '../../../../../constants/MobileAdmin/Manifest/advancedSearchOptions';
import generatePeriod from '../../../../../utils/MobileAdmin/Manifest/Generator/generatePeriod';

const AdvancedSearchContent = ({ queryObject }) => {
  const { setValue } = useFormContext();
  const [dateRange, setDateRange] = useState('');

  const handlePeriodChange = ({ e, selectProps, onChange }) => {
    onChange?.(e?.target?.value);
    if (e?.target?.value) {
      const period = generatePeriod(selectProps?.props?.period);
      setValue('start-date', period?.startDate);
      setValue('end-date', period?.endDate);
      setDateRange(period);
    } else {
      setValue('start-date', null);
      setValue('end-date', null);
      setDateRange('');
    }
  };

  const handleDateChange = ({ e, onChange, date }) => {
    if (date !== e?.target?.value) {
      setValue('period', '');
    }
    const value = dayjs(e)?.isValid() ? dayjs(e)?.format('YYYY-MM-DD') : null;
    onChange?.(value);
  };

  return (
    <div className="flex flex-col w-full h-fit bg-[#F9F9F9] px-[30px] py-[20px] justify-center">
      <CommonSelect
        label="STATUS"
        inputName="status"
        width="w-[153px]"
        option={MANIFEST_FILTER_STATUS}
        defaultValue={queryObject?.status || ''}
        onChangeHandler={({ e, onChange }) => {
          onChange?.(e?.target?.value);
        }}
      />
      <CommonSelect
        label="DATE"
        inputName="date"
        width="w-[153px]"
        option={MANIFEST_FILTER_DATE}
        disabled
        defaultValue="Delivery Date"
        onChangeHandler={({ e, onChange }) => {
          onChange?.(e?.target?.value);
        }}
      />
      <div className="flex justify-center items-center gap-x-[10px] mb-[10px]">
        <CommonSelect
          label="PERIOD"
          inputName="period"
          width="w-[153px]"
          option={MANIFEST_FILTER_PERIOD}
          defaultValue={queryObject?.period || ''}
          onChangeHandler={handlePeriodChange}
        />
        <div className="flex items-center mt-[14px]">
          <CommonDate
            labelVisible={false}
            inputName="start-date"
            width="w-[116px]"
            defaultValue={queryObject?.['start-date'] || null}
            onChangeHandler={({ e, onChange }) =>
              handleDateChange({ e, onChange, date: dateRange?.startDate })
            }
          />
          <div className="w-[13px] min-w-[13px] border-t-[1px] mx-[5px] border-[#D9D9D9]" />
          <CommonDate
            labelVisible={false}
            inputName="end-date"
            width="w-[116px]"
            defaultValue={queryObject?.['end-date'] || null}
            onChangeHandler={({ e, onChange }) =>
              handleDateChange({ e, onChange, date: dateRange?.endDate })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default AdvancedSearchContent;
