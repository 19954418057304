import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import divBasicDefaultApi from '../../divApi';

const patchFunctions = async ({ div }) => {
  try {
    const response = await divBasicDefaultApi.patch(`${div}/tracking-func`, {});
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchFunctions;
