import { APPLY_TYPE } from '../../../../constants/Acct/ChassisReconcile/staticNames';

const handleDuplicationErrorPatch = ({
  resolveErrorList,
  overwritePatchMutator,
  setPatchFormKey,
  setDuplicationErrorList,
  setWarningModalIndex,
  setIsCompletedPatchFlag,
}) => {
  let errorIndex = 0;
  const patchNextForm = async () => {
    const resolveError = resolveErrorList?.[errorIndex];

    if (resolveError?.type === APPLY_TYPE?.OVERWRITE) {
      setPatchFormKey(resolveError?.formKey);
      overwritePatchMutator({
        seq: resolveError?.patchSeq,
        patchData: resolveError?.submitData,
      });
    }
    errorIndex++;

    if (errorIndex < resolveErrorList.length) {
      setTimeout(patchNextForm, 200);
    } else {
      setDuplicationErrorList([]);
      setWarningModalIndex(0);
      setIsCompletedPatchFlag(prev => true);
    }
  };

  patchNextForm();
};

export default handleDuplicationErrorPatch;
