import axios from 'axios';
import interceptRequest from '../../utils/Common/Interceptor/interceptRequest';

/**
 * Base URL of BC-Detail
 */
const BCDETAIL_BASE_URL = `${process.env.REACT_APP_BASE_URL}/bc-detail/`;

/**
 * Axios object with base url
 */
const bcDetailDefaultApi = axios.create({
  withCredentials: true,
  baseURL: BCDETAIL_BASE_URL,
  xsrfHeaderName: 'X-CSRFTOKEN',
  xsrfCookieName: 'XCSRF-TOKEN',
  headers: {
    common: {
      'X-CSRFToken': localStorage.getItem('csrfToken'),
    },
  },
});

/** Interceptors */
interceptRequest(bcDetailDefaultApi);

export default bcDetailDefaultApi;
