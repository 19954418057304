import { useMutation } from '@tanstack/react-query';
import getSelectedDriver from '../../../services/Master/Driver/Get/getSelectedDriver';

const useGetSelectedDriver = ({
  setRowData,
  setMessageContent,
  setMessageModalOpen,
  setIsCompany,
  setEditMode,
  setSideModalOpen,
}) =>
  useMutation({
    mutationFn: getSelectedDriver,
    onSuccess: result => {
      setRowData(result?.data);

      if (result?.data?.category === 'DR0001') setIsCompany(true);
      else setIsCompany(false);

      setEditMode(true);
      setSideModalOpen(true);
    },
    onError: error => {
      setMessageContent(error.message);
      setMessageModalOpen(true);
    },
  });

export default useGetSelectedDriver;
