import { DRIVER_POSITION_OBJECT } from '../../../constants/Master/APRate/elements';
import { TIERWIEHT_STATUS_OBJECT } from '../../../constants/Master/TierWeight/elements';

const processData = data => {
  data.category = DRIVER_POSITION_OBJECT[data.category] || data.category;
  data.from_location = data.from_location?.split('/')[0];
  data.to_location = data.to_location?.split('/')[0];
  data.div = sessionStorage.getItem('nowDiv');
  data.po_location = data.po_location?.split('/')[0];
  data.dilv_location = data.dilv_location?.split('/')[0];
  data.billto = data.billto?.split('/')[0];
  if (data.effective_date) {
    const date = new Date(data.effective_date);
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    data.effective_date = new Date(date.getTime() - userTimezoneOffset)
      .toISOString()
      .split('T')[0];
  }
  data.updated_by = localStorage.getItem('userId') || null;
  data.status = TIERWIEHT_STATUS_OBJECT[data.status] || null;
  if (data.fsc_percent && data.fsc_amt) {
    data.fsc_amt = null;
  }
  return data;
};

export default processData;
