import dayjs from 'dayjs';
import { getConvertDateTimeGenerator } from '../../../../Common/Generator/formattedDateTimeGenerator';

const handleScheduleDateTimeSizeCheck = ({ scheduleData, setModalMessage }) => {
  const {
    sche_date: scheDate,
    sche_time: scheTime,
    actual_in_date: acctInDate,
    actual_in_time: acctInTime,
    actual_out_date: acctOutDate,
    actual_out_time: acctOutTime,
  } = scheduleData;

  /** Check date time chain */
  const scheDateTime = getConvertDateTimeGenerator({
    date: scheDate,
    time: scheTime,
  });
  const acctInDateTime = getConvertDateTimeGenerator({
    date: acctInDate,
    time: acctInTime,
  });
  const acctOutDateTime = getConvertDateTimeGenerator({
    date: acctOutDate,
    time: acctOutTime,
  });

  /** Check actual in date time is later than actual out date time */
  if (
    acctInDateTime &&
    acctOutDateTime &&
    (dayjs(acctInDateTime).isAfter(acctOutDateTime) ||
      dayjs(acctInDateTime).isSame(acctOutDateTime))
  ) {
    setModalMessage(
      'Actual Out date and time should be later than Actual In date and time.',
    );
    return false;
  }

  return true;
};

export default handleScheduleDateTimeSizeCheck;
