import { useQuery } from '@tanstack/react-query';
import adminKeys from '../../keys';
import getTimeZoneList from '../../../../services/Admin/DivConf/Get/getTimeZoneList';

const useGetTimeZoneList = () => {
  const { isLoading: isLoadingTimeZoneList, data } = useQuery({
    queryKey: adminKeys?.timeZoneList(),
    queryFn: getTimeZoneList,
    refetchOnWindowFocus: false,
  });

  return {
    isLoadingTimeZoneList,
    data: data?.data,
  };
};

export default useGetTimeZoneList;
