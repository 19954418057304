import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, FormHelperText, Switch } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import StyledCommonInputWrapper from '../../../styles/Common/CommonInput/StyledCommonInputWrapper';
import StyledCommonLabel from '../../../styles/Common/CommonInput/StyledCommonLabel';
import StyledCommonInputRequiredLabel from '../../../styles/Common/CommonInput/StyledCommonInputRequiredLabel';
import defaultInputRules from '../../../utils/Common/Input/defaultInputRules';

const CommonTextarea = ({ ...props }) => {
  // Get react-hook-form object from useFormContext
  const { control, unregister, setValue, getValues } = useFormContext();

  const [label, setLabel] = useState(props?.label);

  const [defaultValue, setDefaultValue] = useState(props?.defaultValue || '');
  const [maxLength, setMaxLength] = useState(props?.maxLength || 1000);
  useEffect(() => {
    if (props?.defaultValue !== undefined && props?.defaultValue !== null) {
      setDefaultValue(props?.defaultValue);
      setValue(props?.inputName, props?.defaultValue);
    }
  }, [props?.defaultValue]);

  // Set disabled for input
  const [disabled, setDisabled] = useState(props?.disabled || false);
  useEffect(() => {
    setDisabled(props?.disabled);
  }, [props?.disabled]);

  // Set required for input
  const [required, setRequired] = useState(props?.required || false);
  useEffect(() => {
    setRequired(props?.required);
  }, [props?.required]);

  useEffect(() => {
    // Unregister input when component unmount
    return () => unregister(props?.inputName);
  }, []);

  /**
   * @count State to count the number of characters currently entered
   */
  const [count, setCount] = useState(0);
  useEffect(() => {
    setCount(defaultValue?.length);
  }, [defaultValue]);

  // Render Component
  return (
    <Controller
      control={control}
      name={props?.inputName}
      defaultValue={defaultValue}
      rules={defaultInputRules({
        required,
        ...props,
      })}
      render={({
        field: { ref, onChange, value, ...field },
        fieldState: { invalid, error },
      }) => {
        // Package of handler props
        const handlerProps = {
          onChange,
          value,
          ...field,
          ...props,
        };

        // Render Control Input Component
        return (
          <>
            <StyledCommonInputWrapper labelStyle={props?.labelStyle}>
              {(!!label || props.labelVisible !== false) && (
                <StyledCommonLabel
                  labelStyle={props?.labelStyle}
                  lbl-pd-right={props?.[`lbl-pd-right`]}
                >
                  {!!label && (
                    <>
                      {!!required && (
                        <StyledCommonInputRequiredLabel>
                          *
                        </StyledCommonInputRequiredLabel>
                      )}
                      {label}
                    </>
                  )}
                </StyledCommonLabel>
              )}
              <div
                className={`${props?.width ? `${props?.width}` : 'w-full'} ${
                  props?.height ? `${props?.height}` : 'h-full'
                }`}
              >
                <textarea
                  disabled={disabled}
                  maxLength={maxLength}
                  id="textarea"
                  onChange={e => {
                    setCount(e?.target?.value?.length);
                    props?.onChangeHandler?.({
                      e,
                      ...handlerProps,
                    });
                  }}
                  value={value}
                  placeholder={props?.placeholder || ''}
                  className={`resize-none outline-none w-full h-full border-[1px] border-[#D9D9D9]
                   rounded-[5px] px-3 py-2 text-[12px] font-[#D9D9D9] font-[500]
                   ${
                     required && error?.message
                       ? 'border-[1px] border-red-700'
                       : 'focus:border-[#264B9F] focus:shadow-[0_0_4px_0_#8BBCE9] hover:border-[#8E9396]'
                   }
                   ${disabled ? 'hover:border-[#D9D9D9] text-[#222]' : ''}
                   `}
                />
              </div>
            </StyledCommonInputWrapper>
            {!props?.suppressHelperText && (
              <div className="flex justify-between mt-0">
                {!props?.suppressErrorMessage && (
                  <FormHelperText className="text-[#ff2424] text-[11px] font-normal min-h-[11px] h-[11px] mb-[3px] leading-none">
                    {error?.message}
                  </FormHelperText>
                )}
                {!props?.suppressWordCount && (
                  <div
                    className={`text-[12px] my-[5px] ${
                      required && error?.message ? 'text-[#FF2424]' : ''
                    }`}
                  >{` ${count} / ${maxLength}`}</div>
                )}
              </div>
            )}
          </>
        );
      }}
    />
  );
};

export default CommonTextarea;
