import styled from 'styled-components';

const StyledWeeklyTableHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 35px;
  height: 35px;
`;

export default StyledWeeklyTableHeaderWrapper;
