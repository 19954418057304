export const boxGenerator = ({ id, checkOrderList }) => {
  const box = checkOrderList.filter(orderBox => orderBox.id === id)[0];
  return {
    box,
    index: checkOrderList.indexOf(box),
  };
};

export const newCheckOrderListGenerator = ({
  checkOrderList,
  index,
  atIndex,
  box,
}) => {
  const newCheckOrderList = [...checkOrderList];

  newCheckOrderList.splice(index, 1)[0];
  newCheckOrderList.splice(atIndex, 0, box);

  return newCheckOrderList;
};
