import React from 'react';

const LocationContent = ({ ...props }) => {
  /** Get props data */
  const { liveShareData } = props;

  return (
    <div>
      {/* From Location */}
      <div className="flex gap-[7px] flex-col pb-[113px]">
        <p className="text-[#000] text-[16px] font-bold">From</p>
        <p className="text-[#000] text-[16px] font-normal">
          {liveShareData?.from_location || 'Cannot find location'}
        </p>
      </div>

      {/* Current Location */}
      <div className="flex gap-[10px] flex-col pb-[122px]">
        <p className="text-[#4DA93D] text-[16px] font-bold">Current Location</p>
        <p className="text-[#4DA93D] text-[16px] font-normal">
          {liveShareData?.current_location || 'Cannot find location'}
        </p>
      </div>

      {/* To Location */}
      <div className="flex gap-[7px] flex-col">
        <p className="text-[#000] text-[16px] font-bold">To</p>
        <p className="text-[#000] text-[16px] font-normal">
          {liveShareData?.to_location || 'Cannot find location'}
        </p>
      </div>
    </div>
  );
};

export default LocationContent;
