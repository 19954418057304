import React from 'react';
import { useSelector } from 'react-redux';
import { REGEX_NUMBER_ONLY_POSITIVE_DECIMAL } from '../../../../../constants/Common/regex';
import {
  COMMON_CONTAINER_UNIT_SELECT_OPTION,
  COMMON_FREEDAYS_SELECT_OPTION,
} from '../../../../../constants/Common/selectOption';
import createCompanySelect from '../../../../../utils/Common/Option/createCompanySelect';
import createDropdownOptions from '../../../../../utils/EDI/Generator/createDropdownOptions';
import CommonAutoComplete from '../../../../Inputs/CommonAutoComplete/CommonAutoComplete';
import CommonDate from '../../../../Inputs/CommonDate/CommonDate';
import CommonSelect from '../../../../Inputs/CommonSelect/CommonSelect';
import CommonTextInput from '../../../../Inputs/CommonTextInput/CommonTextInput';
import CommonTextSelect from '../../../../Inputs/CommonTextSelect/CommonTextSelect';
import CommonTextarea from '../../../../Inputs/CommonTextarea/CommonTextarea';

const ModalContent = ({
  detail204Data,
  cneeList,
  customerList,
  isLoadingCneeList,
  isLoadingCustomerList,
  setIsDataChanged,
  isPOChanged,
  setIsPOChanged,
  isDelvChanged,
  setIsDelvChanged,
  isRtnChanged,
  setIsRtnChanged,
}) => {
  const selectSize = useSelector(state => state.common.size);
  const selectType = useSelector(state => state.common.type);
  const selectSSL = useSelector(state => state.common.ssl);

  return (
    <div className="flex w-[1182px] h-[537px] pt-[10px]">
      <div className="flex flex-col">
        <div className="text-lg font-bold">EDI 204</div>
        <div className="flex mt-[45px]">
          <div>
            <CommonAutoComplete
              inputName="billto"
              label="BILL TO"
              defaultValue={detail204Data?.billto}
              width="w-[118px]"
              required
              option={createCompanySelect({ companyData: customerList })}
              onChangeHandler={({ e, onChange, selectProps }) => {
                onChange?.(selectProps?.value);
                setIsDataChanged(true);
              }}
              isLoading={isLoadingCustomerList}
            />
          </div>
          <div className="ml-[4px]">
            <CommonTextInput
              inputName="billto_business_name"
              width="w-[157px]"
              disabled
              defaultValue={detail204Data?.billto_business_name}
            />
          </div>
          <div className="ml-[20px]">
            <CommonTextInput
              inputName="mbl_no"
              label="MBL #"
              width="w-[290px]"
              required
              disabled
              defaultValue={detail204Data?.mbl_no}
            />
          </div>
          <div className="ml-[20px]">
            <CommonTextInput
              inputName="hbl_no"
              label="HBL #"
              width="w-[290px]"
              disabled
              defaultValue={detail204Data?.hbl_no}
            />
          </div>
        </div>
        <div className="flex mt-[21px]">
          <div>
            <CommonTextInput
              inputName="ref_no"
              label="REF #"
              width="w-[290px]"
              required
              disabled
              defaultValue={detail204Data?.ref_no}
            />
          </div>
          <div className="ml-[20px]">
            <CommonTextInput
              inputName="purchase_no"
              label="PO #"
              maxLength={50}
              width="w-[290px]"
              disabled
              defaultValue={detail204Data?.purchase_no}
            />
          </div>
          <div className="ml-[20px]">
            <CommonTextInput
              inputName="dilv_no"
              label="DELIVERY #"
              width="w-[290px]"
              disabled
              defaultValue={detail204Data?.dilv_no}
            />
          </div>
        </div>
        <div className="flex mt-[21px]">
          <div>
            <CommonTextInput
              inputName="cont_no"
              label="CONT #"
              width="w-[290px]"
              disabled
              defaultValue={detail204Data?.cont_no}
            />
          </div>
          <div className="ml-[20px]">
            <CommonSelect
              inputName="size"
              label="SIZE / TYPE"
              width="w-[135px]"
              defaultValue={detail204Data?.size}
              required
              option={createDropdownOptions(selectSize, 'size')}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
                setIsDataChanged(true);
              }}
            />
          </div>
          <div className="ml-[5px]">
            <CommonSelect
              inputName="type"
              width="w-[135px]"
              defaultValue={detail204Data?.type}
              required
              option={createDropdownOptions(selectType, 'type')}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
                setIsDataChanged(true);
              }}
            />
          </div>
          <div className="ml-[7px]">
            <CommonTextInput
              inputName="iso"
              width="w-[318px]"
              disabled
              defaultValue={detail204Data?.iso}
            />
          </div>
        </div>
        <div className="flex mt-[21px]">
          <div>
            <CommonSelect
              inputName="ssl"
              label="SSL"
              width="w-[135px]"
              defaultValue={detail204Data?.ssl}
              required
              option={createDropdownOptions(selectSSL, 'ssl')}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
                setIsDataChanged(true);
              }}
            />
          </div>
          <div className="ml-[4px]">
            <CommonTextInput
              inputName="ssl_business_name"
              width="w-[304px]"
              disabled
              defaultValue={detail204Data?.ssl_business_name}
            />
          </div>
          <div className="ml-[20px]">
            <CommonTextInput
              inputName="vessel"
              label="VESSEL"
              maxLength={30}
              width="w-[212px]"
              disabled
              defaultValue={detail204Data?.vessel}
            />
          </div>
          <div className="ml-[18px]">
            <CommonTextInput
              inputName="seal"
              label="SEAL"
              width="w-[212px]"
              disabled
              defaultValue={detail204Data?.seal}
            />
          </div>
        </div>
        <div className="flex mt-[21px]">
          <div>
            <CommonTextInput
              inputName="wgt"
              label="WGT"
              width="w-[134px]"
              disabled
              defaultValue={detail204Data?.wgt}
            />
          </div>
          <div className="ml-[20px]">
            <CommonTextInput
              inputName="qty"
              label="QTY"
              width="w-[134px]"
              disabled
              defaultValue={detail204Data?.qty}
            />
          </div>
          <div className="ml-[20px]">
            <CommonSelect
              inputName="unit"
              label="UNIT"
              width="w-[134px]"
              defaultValue={detail204Data?.unit}
              option={COMMON_CONTAINER_UNIT_SELECT_OPTION}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
                setIsDataChanged(true);
              }}
            />
          </div>
          <div className="ml-[20px]">
            <CommonTextInput
              inputName="commodity"
              label="COMMODITY"
              width="w-[212px]"
              disabled
              defaultValue={detail204Data?.commodity}
            />
          </div>
          <div className="ml-[20px]">
            <CommonTextInput
              inputName="sendto"
              label="SENT TO"
              width="w-[212px]"
              disabled
              defaultValue={detail204Data?.sendto}
            />
          </div>
        </div>
        <div className="flex mt-[21px]">
          <div>
            <CommonTextInput
              inputName="chassisNo"
              label="CHASSIS #"
              width="w-[290px]"
              disabled
              defaultValue={detail204Data?.chassisNo}
            />
          </div>
          <div className="ml-[20px]">
            <CommonTextInput
              inputName="pool"
              label="POOL"
              width="w-[290px]"
              disabled
              defaultValue={detail204Data?.pool}
            />
          </div>
          <div className="ml-[20px]">
            <CommonTextInput
              inputName="tare"
              label="TARE (lbs)"
              width="w-[290px]"
              disabled
              defaultValue={detail204Data?.tare}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col h-full ml-[32px] mt-[23px]">
        <div className="flex flex-col items-center justify-center w-[225px] h-[267px] bg-[#F2F4F8] rounded">
          <div>
            <CommonDate
              inputName="eta"
              label="ETA"
              width="w-[180px]"
              disabled
              defaultValue={detail204Data?.eta}
            />
          </div>
          <div className="mt-[12px]">
            <CommonDate
              inputName="lfd"
              label="LFD"
              width="w-[180px]"
              disabled
              defaultValue={detail204Data?.lfd}
            />
          </div>
          <div className="mt-[12px]">
            <CommonTextSelect
              inputName={['freedays', 'freedays_type']}
              label="FREEDAYS"
              width="w-[180px]"
              defaultValue={[
                detail204Data?.freedays,
                detail204Data?.freedays_type,
              ]}
              required={[true, true]}
              option={COMMON_FREEDAYS_SELECT_OPTION}
              regex={REGEX_NUMBER_ONLY_POSITIVE_DECIMAL}
              onChangeHandler={[
                ({ e, onChange, regex }) => {
                  if (e?.target?.value?.match(regex)) {
                    onChange?.(e?.target?.value);
                    setIsDataChanged(true);
                  }
                },
                ({ e, onChange }) => {
                  onChange?.(e?.target?.value);
                  setIsDataChanged(true);
                },
              ]}
            />
          </div>
        </div>
        <div className="mt-[16px]">
          <CommonTextarea
            inputName="remarks"
            label="REMARKS"
            width="w-[225px]"
            height="h-[205px]"
            maxLength={512}
            defaultValue={detail204Data?.remarks}
            onChangeHandler={({ e, onChange }) => {
              onChange(e?.target?.value);
              setIsDataChanged(true);
            }}
          />
        </div>
      </div>

      <div className="flex flex-col h-full ml-[32px] mt-[23px] space-y-[11px]">
        <div className="w-[273px] h-[165px] px-[28px] py-[21px] bg-[#556DA1] bg-opacity-10 rounded-[5px]">
          <div>
            <div className="text-[12px] font-[700]">PULLOUT</div>
          </div>
          <hr className="border-white mt-[10px] mb-[20px]" />
          <div className="flex">
            <div className="mt-[-25px] ml-[-2px]">
              <CommonAutoComplete
                inputName="po_location"
                defaultValue={detail204Data?.po_location}
                width="w-[118px]"
                option={createCompanySelect({ companyData: cneeList })}
                onChangeHandler={({ e, onChange, selectProps }) => {
                  onChange?.(selectProps?.value);
                  setIsPOChanged(true);
                  setIsDataChanged(true);
                }}
                isLoading={isLoadingCneeList}
              />
            </div>
          </div>
          <div className="mt-[-9px] space-y-[3px] text-[12px]">
            <div className="text-[#707070]">
              {detail204Data?.po_business_name}
            </div>
            {!isPOChanged && (
              <>
                <div>{detail204Data?.po_address}</div>
                <div>{detail204Data?.po_city}</div>
                <div>
                  {detail204Data?.po_state} {detail204Data?.po_zip}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="w-[273px] h-[165px] px-[28px] py-[21px] bg-[#556DA1] bg-opacity-20 rounded-[5px]">
          <div>
            <div className="text-[12px] font-[700]">DELIVERY</div>
          </div>
          <hr className="border-white mt-[10px] mb-[20px]" />
          <div className="flex">
            <div className="mt-[-25px] ml-[-2px]">
              <CommonAutoComplete
                inputName="dilv_location"
                defaultValue={detail204Data?.dilv_location}
                width="w-[118px]"
                option={createCompanySelect({ companyData: cneeList })}
                onChangeHandler={({ e, onChange, selectProps }) => {
                  onChange?.(selectProps?.value);
                  setIsDelvChanged(true);
                  setIsDataChanged(true);
                }}
                isLoading={isLoadingCneeList}
              />
            </div>
          </div>
          <div className="mt-[-9px] space-y-[3px] text-[12px]">
            <div className="text-[#707070]">
              {detail204Data?.dilv_business_name}
            </div>
            {!isDelvChanged && (
              <>
                <div>{detail204Data?.dilv_address}</div>
                <div>{detail204Data?.dilv_city}</div>
                <div>
                  {detail204Data?.dilv_state} {detail204Data?.dilv_zip}
                </div>
              </>
            )}
          </div>
        </div>{' '}
        <div className="w-[273px] h-[165px] px-[28px] py-[21px] bg-[#556DA1] bg-opacity-30 rounded-[5px]">
          <div>
            <div className="text-[12px] font-[700]">RETURN</div>
          </div>
          <hr className="border-white mt-[10px] mb-[20px]" />
          <div className="flex">
            <div className="mt-[-25px] ml-[-2px]">
              <CommonAutoComplete
                inputName="rtn_location"
                defaultValue={detail204Data?.rtn_location}
                width="w-[118px]"
                option={createCompanySelect({ companyData: cneeList })}
                onChangeHandler={({ e, onChange, selectProps }) => {
                  onChange?.(selectProps?.value);
                  setIsRtnChanged(true);
                  setIsDataChanged(true);
                }}
                isLoading={isLoadingCneeList}
              />
            </div>
          </div>
          <div className="mt-[-9px] space-y-[3px] text-[12px]">
            <div className="text-[#707070]">
              {detail204Data?.rtn_business_name}
            </div>
            {!isRtnChanged && (
              <>
                <div>{detail204Data?.rtn_address}</div>
                <div>{detail204Data?.rtn_city}</div>
                <div>
                  {detail204Data?.rtn_state} {detail204Data?.rtn_zip}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalContent;
