import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthRouter from '../../../../../components/Common/AuthRouter';
import APARReconcile from '../../../../../pages/Acct/APARReconcile';
import APARUninvoiced from '../../../../../pages/Acct/APARUninvoiced';
import ChassisReconcile from '../../../../../pages/Acct/ChassisReconcile';
import PerdiemReconcile from '../../../../../pages/Acct/PerdiemReconcile';
import QBAPAR from '../../../../../pages/Acct/QBAPAR';
import getAuthorizedMenu from '../../../../../utils/Common/Sidebar/Handler/getAuthorizedMenu';
import { SIDEBAR_SUB_MENU } from '../../../Option/Sidebar';
import {
  PERM_ACCT_CHASSIS_RECON,
  PERM_ACCT_PERDIEM_RECON,
  PERM_ACCT_QB_AP_AR,
  PERM_ACCT_QB_RECON,
  PERM_ACCT_UNINVOICED,
} from '../../../permission';

const ACCT_CHILDREN_ROUTE = [
  {
    index: true,
    element: (
      <Navigate
        to={
          getAuthorizedMenu({
            allMenu: SIDEBAR_SUB_MENU?.[8],
          })?.[0]?.to
        }
      />
    ),
  },
  {
    path: 'ap-ar-uninvoiced/*',
    element: (
      <AuthRouter permission={PERM_ACCT_UNINVOICED}>
        <APARUninvoiced />
      </AuthRouter>
    ),
  },
  {
    path: 'qb-ap-ar/*',
    element: (
      <AuthRouter permission={PERM_ACCT_QB_AP_AR}>
        <QBAPAR />
      </AuthRouter>
    ),
  },
  {
    path: 'ap-ar-reconcile/*',
    element: (
      <AuthRouter permission={PERM_ACCT_QB_RECON}>
        <APARReconcile />
      </AuthRouter>
    ),
  },
  {
    path: 'chassis-reconcile/*',
    element: (
      <AuthRouter permission={PERM_ACCT_CHASSIS_RECON}>
        <ChassisReconcile />
      </AuthRouter>
    ),
  },
  {
    path: 'perdiem-reconcile/*',
    element: (
      <AuthRouter permission={PERM_ACCT_PERDIEM_RECON}>
        <PerdiemReconcile />
      </AuthRouter>
    ),
  },
];

export default ACCT_CHILDREN_ROUTE;
