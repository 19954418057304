import dayjs from 'dayjs';

export const handleInputChange = ({ e, onChange, regex, ...handlerProps }) => {
  if (regex) {
    if (e?.target?.value?.match(regex)) {
      onChange(e?.target?.value);
      return;
    }
    return;
  }
  onChange?.(e?.target?.value);
};

export const handleUpperCaseInputChange = ({
  e,
  onChange,
  regex,
  ...handlerProps
}) => {
  if (regex) {
    if (e?.target?.value?.match(regex)) {
      onChange(e?.target?.value?.toUpperCase());
      return;
    }
    return;
  }
  onChange?.(e?.target?.value?.toUpperCase());
};

export const handleCheckChange = ({ e, onChange, ...handlerProps }) => {
  onChange?.(e?.target?.checked);
};
export const handleAutoCompleteChange = ({
  e,
  onChange,
  selectProps,
  ...handlerProps
}) => {
  onChange?.(selectProps?.value);
};

export const handleDayChange = ({ e, onChange }) => {
  onChange?.(dayjs(e)?.isValid() ? dayjs(e) : null);
};
