import React, { useEffect, useState } from 'react';
import { LOCATION_TAB_FILTERS } from '../../../constants/Dispatch/Common/filterButtonSettings';
import { DISPATCH_STATUS_VALUE } from '../../../constants/Dispatch/Common/statusValue';
import useControlDispatchList from '../../../hooks/Dispatch/Common/Control/useControlDispatchList';
import useGetDispatchList from '../../../hooks/Dispatch2/Common/Get/useGetDispatchList';
import useGetDivisionColor from '../../../hooks/Dispatch2/Common/Get/useGetDivisionColor';
import useGetUserColumn from '../../../hooks/Dispatch2/Common/Get/useGetUserColumn';
import usePutColumnOrder from '../../../hooks/Dispatch2/Common/Put/usePutColumnOrder';
import convertCategoryToStandardCode from '../../../utils/Dispatch/Common/Generator/convertCategoryToStandardCode';
import convertStatusToStandardCode from '../../../utils/Dispatch/Common/Generator/convertStatusToStandardCode';
import generateColumnItems from '../../../utils/Dispatch/Common/Generator/generateColumnItems';
import getColumnCustomStatus from '../../../utils/Dispatch/Common/Generator/getColCustomStatus';
import handleUserColsApply from '../../../utils/Dispatch/Common/Handler/handleUserColsApply';
import CommonCustomColumnModal from '../../CommonCustomColumnModal';
import DispatchGrid from '../DispatchGrid';

const DispatchByStatus = ({
  status,
  woStatus,
  category,
  locStatus,
  subFilter,
  allQueries,
  isEditable,
  gridRef,
  customizeCols,
  setCustomizeCols,
  setIsMessageModalOpen,
  setMessageModalMessage,
  setIsLoadingOpen,
}) => {
  const [columnList, setColumnList] = useState([]);

  /** Get Division Color */
  const { colors, isLoadingColors } = useGetDivisionColor();

  /** Get User Column */
  const { isLoadingUserColumn } = useGetUserColumn({
    category: convertCategoryToStandardCode(category),
    isYard: subFilter === 'yard' || subFilter === 'cnee',
    status: DISPATCH_STATUS_VALUE?.[status],
    setColumnList,
  });

  /** Get Row Data for DataGrid */
  const { dispatchList, getDispatchListLoading } = useGetDispatchList({
    ...allQueries,
    subFilter,
  });

  /** Dispatch List */
  const { controlDispatchList } = useControlDispatchList({
    rowList: dispatchList,
    colors,
    columnList,
  });

  const [getListLoading, setGetListLoading] = useState(false);

  useEffect(() => {
    setGetListLoading(getDispatchListLoading || isLoadingColors);
  }, [getDispatchListLoading, isLoadingColors]);

  /* Hook for col custom */
  const { mutate: mutateCustomizeCols } = usePutColumnOrder({
    setCustomizeCols,
    setMessageModalMessage,
    setIsMessageModalOpen,
    setColumnList,
  });

  const waitingList = [getListLoading];
  useEffect(() => {
    setIsLoadingOpen(![...waitingList].every(value => value === false));
  }, [...waitingList]);

  return (
    <>
      <DispatchGrid
        gridRef={gridRef}
        subFilter={subFilter}
        status={status}
        locStatus={locStatus}
        category={category}
        rowData={controlDispatchList}
        isEditable={isEditable}
        setMessageModalMessage={setMessageModalMessage}
        setIsMessageModalOpen={setIsMessageModalOpen}
        setIsLoadingOpen={setIsLoadingOpen}
      />
      <CommonCustomColumnModal
        openModal={customizeCols}
        setOpenModal={setCustomizeCols}
        status={getColumnCustomStatus(woStatus)}
        selectedItems={
          controlDispatchList?.dsp_column?.map(item => {
            if (item.equip) {
              return {
                ...item,
                equip: item?.equip?.map(equipItem =>
                  equipItem === 'load_empty' ? 'empty_load_status' : equipItem,
                ),
              };
            }
            return item;
          }) || []
        }
        columnItems={generateColumnItems(
          LOCATION_TAB_FILTERS?.includes(subFilter) ? locStatus : status,
        )}
        onApplyClick={({ buttonRef, appliedColumnList }) => {
          handleUserColsApply({
            mutate: mutateCustomizeCols,
            appliedColumnList,
            category: convertCategoryToStandardCode(category),
            status: convertStatusToStandardCode({
              status: LOCATION_TAB_FILTERS?.includes(subFilter)
                ? locStatus
                : status,
            }),
            buttonRef,
            isYard: subFilter === 'yard' || subFilter === 'cnee',
          });
        }}
      />
    </>
  );
};

export default DispatchByStatus;
