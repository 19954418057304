import commonDefaultAPI from '../../commonAPI';

const getCompanyList = async ({ urlParams }) => {
  try {
    const response = await commonDefaultAPI.get(`company-list`, {
      params: { ...urlParams },
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getCompanyList;
