import dayjs from 'dayjs';

const generateDate = (value, format) => {
  if (value) {
    return dayjs(value).format(format);
  }
  return '';
};

export default generateDate;
