import { closeWarningModal } from './warningModalHandler';
import resolveErrorListGenerator from '../Generator/resolveErrorListGenerator';

const applyProductCode = ({
  data,
  setOpenWarningModal,
  type,
  setResolveErrorList,
  warningModalIndex,
  duplicationErrorList,
  setWarningModalIndex,
  setWarningModalData,
  setApplyType,
  alwaysFlag,
}) => {
  setApplyType(type);
  if (alwaysFlag) {
    const nextItems = resolveErrorListGenerator({
      type,
      duplicationErrorList,
      startIndex: warningModalIndex - 1,
    });
    setResolveErrorList(prev => prev?.concat(nextItems));
    setOpenWarningModal(false);
  } else {
    setResolveErrorList(prev => [
      ...prev,
      {
        type,
        patchSeq: data?.seq,
        formKey: data?.submitData?.key,
        submitData: data?.submitData?.data,
      },
    ]);
    closeWarningModal({
      setOpenWarningModal,
      warningModalIndex,
      duplicationErrorList,
      setWarningModalIndex,
      setWarningModalData,
    });
  }
};

export default applyProductCode;
