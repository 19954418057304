import apRateAPI from '../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import processData from '../../../../utils/Master/Common/processData';
import deepCloneObject from '../../../../utils/Master/Common/deepCloneObject';

/**
 * Posts data to the AP rate API endpoint.
 * @param {Object} data - The data to be posted to the API.
 */
const postAPRate = async ({ data }) => {
  const dataToPost = deepCloneObject(data);
  processData(dataToPost);
  try {
    await apRateAPI.post('', dataToPost);
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default postAPRate;
