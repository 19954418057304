import React, { useRef } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import MemoTextField from './MemoTextField';
import LocationHeader from './LocationHeader';
import LocationRow from './LocationRow';
import usePatchMemoContent from '../../../../hooks/BCDetail/Memo/usePatchMemoContent';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';

const MobileMemoArea = ({ mobileMemoList, woNo, setModalOpen, ...props }) => {
  const patchMemoMutator = usePatchMemoContent({
    woNo,
    setErrorModalOpener: setModalOpen,
    ...props,
  });

  const mobileMemoController = useForm();

  const handleMemoSubmit = ({ submitData, seq, buttonRef }) => {
    if (buttonRef?.current)
      updateButtonDisabled({ ref: buttonRef, disabled: true });

    patchMemoMutator.mutate({
      seq,
      content: submitData[seq].content,
      buttonRef,
    });
  };

  return (
    <div>
      <FormProvider {...mobileMemoController}>
        {mobileMemoList?.data?.map((res, index) => {
          const buttonRef = useRef(null);

          return (
            <form
              onSubmit={mobileMemoController.handleSubmit(submitData =>
                handleMemoSubmit({ submitData, seq: res?.seq, buttonRef }),
              )}
              key={`schedule${index + 1}`}
            >
              <LocationHeader index={index} />
              <div className="ml-[54px]">
                <LocationRow location={res?.from_location} />
                <LocationRow location={res?.live_dilv_location} />
                <LocationRow location={res?.to_location} />
              </div>
              <MemoTextField buttonRef={buttonRef} res={res} {...props} />
            </form>
          );
        })}
      </FormProvider>
    </div>
  );
};

export default MobileMemoArea;
