import dayjs from 'dayjs';

const generatePeriod = periodType => {
  const today = dayjs();

  if (periodType?.unit === 'week') {
    return {
      startDate: today?.startOf('week')?.format('YYYY-MM-DD'),
      endDate: today?.endOf('week')?.format('YYYY-MM-DD'),
    };
  }
  return {
    startDate: today
      ?.subtract(periodType?.count, periodType?.unit)
      .format('YYYY-MM-DD'),
    endDate: today?.format('YYYY-MM-DD'),
  };
};

export default generatePeriod;
