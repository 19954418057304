import React, { useEffect, useState } from 'react';
import StyledBCDetailBillingInfoCommonHeaderButtonContainer from '../../../../../styles/BCDetail/BillingInfo/Common/Header/StyledBCDetailBillingInfoCommonHeaderButtonContainer';
import StyledBCDetailBillingInfoCommonHeaderContainer from '../../../../../styles/BCDetail/BillingInfo/Common/Header/StyledBCDetailBillingInfoCommonHeaderContainer';
import StyledBCDetailBillingInfoCommonHeaderTitleText from '../../../../../styles/BCDetail/BillingInfo/Common/Header/StyledBCDetailBillingInfoCommonHeaderTitleText';
import onCreateInvHandler from '../../../../../utils/BCDetail/BillingInfo/AR/Handler/onCreateInvHandler';
import handlePostQB from '../../../../../utils/BCDetail/BillingInfo/Common/handlePostQB';
import CommonButton from '../../../../CommonButton/CommonButton';

const ARHeader = ({
  ARQuery,
  arInvoiceMutator,
  qbMutator,
  ARTableFormController,
  isCredit,
  setIsAlertOpen,
  setIsSuccess,
  setErrorMessage,
  isAREditable,
  isQBAvailable,
  isQBDisabled,
}) => {
  const [isDisabledQB, setIsDisabledQB] = useState(false);

  useEffect(() => {
    setIsDisabledQB(isQBDisabled);
  }, [isQBDisabled]);

  return (
    <StyledBCDetailBillingInfoCommonHeaderContainer>
      <StyledBCDetailBillingInfoCommonHeaderTitleText color="#A45D5D">
        A/R
      </StyledBCDetailBillingInfoCommonHeaderTitleText>
      <StyledBCDetailBillingInfoCommonHeaderButtonContainer>
        {isAREditable && (
          <CommonButton
            type="button"
            borderColor="#556DA1"
            category="createInvNo"
            preventDupClick
            onClick={({ buttonRef }) =>
              onCreateInvHandler({
                originData: ARQuery?.ARData,
                mutator: arInvoiceMutator,
                getValues: ARTableFormController?.getValues,
                setIsAlertOpen,
                setIsSuccess,
                setErrorMessage,
                buttonRef,
              })
            }
          >
            {isCredit ? 'Create Credit #' : 'Create INV #'}
          </CommonButton>
        )}
        {isQBAvailable && (
          <CommonButton
            type="button"
            borderColor="#3A825D"
            category="quickBooks"
            preventDupClick
            onClick={({ buttonRef }) =>
              handlePostQB({
                originData: ARQuery?.ARData,
                type: 'AR',
                mutator: qbMutator,
                getValues: ARTableFormController?.getValues,
                setIsAlertOpen,
                setIsSuccess,
                setErrorMessage,
                buttonRef,
              })
            }
            disabled={isDisabledQB}
          >
            QuickBooks
          </CommonButton>
        )}
      </StyledBCDetailBillingInfoCommonHeaderButtonContainer>
    </StyledBCDetailBillingInfoCommonHeaderContainer>
  );
};

export default ARHeader;
