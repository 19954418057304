const handleBookingChange = ({ getChangedValue, ...props }) => {
  const bookingOption = localStorage.getItem('bookingOption')
    ? JSON.parse(localStorage.getItem('bookingOption'))
    : [];

  const changedValue = bookingOption.find(
    element => element.value === getChangedValue,
  );

  const instances = props.api.getCellEditorInstances();

  const selfInstance = instances.find(
    element =>
      element?.cellEditorParams?.column?.colId === props?.column?.colId,
  );

  selfInstance?.componentInstance?.setInputValue(changedValue?.label);
  selfInstance?.componentInstance?.setDataValue(changedValue?.label);
  selfInstance?.onValueChange(changedValue?.value);
};

export default handleBookingChange;
