import React from 'react';
import { useFormContext } from 'react-hook-form';
import CommonButton from '../../../CommonButton/CommonButton';
import saveUserInfo from '../../../../utils/Admin/User/Handler/saveUserInfo';

const SideModalButtonArea = ({
  userID,
  divList,
  userInfoPatchMutator,
  isFormInvalid,
}) => {
  const { handleSubmit, reset } = useFormContext();

  return (
    <div className="flex ml-[64px] gap-x-[30px] mt-[28px]">
      <CommonButton
        category="clear"
        width="72px"
        height="24px"
        onClick={() => reset()}
      >
        Clear
      </CommonButton>
      <CommonButton
        category="save"
        width="72px"
        height="24px"
        preventDupClick
        onClick={({ buttonRef }) =>
          saveUserInfo({
            handleSubmit,
            userID,
            userInfoPatchMutator,
            divList,
            buttonRef,
            isFormInvalid,
          })
        }
      >
        Save
      </CommonButton>
    </div>
  );
};

export default SideModalButtonArea;
