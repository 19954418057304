import updateButtonDisabled from '../../../../Common/Handler/updateButtonDisabled';
import checkDateTimeConstraintBeforeUpdate from '../Handler/checkDateTimeConstraintBeforeUpdate';
import handleUpdatedScheduleConstraintCheck from '../Handler/handleUpdatedScheduleConstraintCheck';
import generateUpdatedScheduleItem from './generateUpdatedScheduleItem';

const generateUpdatedSchedule = ({
  scheduleControllerData,
  detailInfoData,
  setModalOpener,
  setModalMessage,
  buttonRef,
}) => {
  /** Get job_set and location_set data from API */
  const getJobSetData = detailInfoData?.job_set;
  const getLocationSetData = detailInfoData?.location_set;

  /** Check Date, Time constraint before update */
  if (
    !checkDateTimeConstraintBeforeUpdate({
      jobSet: getJobSetData,
      locationSet: getLocationSetData,
      controllerData: scheduleControllerData,
      setModalMessage,
    })
  ) {
    updateButtonDisabled({ ref: buttonRef, disabled: false });
    setModalOpener(true);
    return null;
  }

  /** Updated data container */
  const updatedScheduleData = [];

  /** Push updated job_set data */
  updatedScheduleData.push(
    ...generateUpdatedScheduleItem({
      chunkData: scheduleControllerData,
      scheduleData: getJobSetData,
      type: 'job',
    }),
  );
  /** Push updated location_set data */
  updatedScheduleData.push(
    ...generateUpdatedScheduleItem({
      chunkData: scheduleControllerData,
      scheduleData: getLocationSetData,
      type: 'location',
    }),
  );

  /** Need to check schedule constraint */
  if (
    !handleUpdatedScheduleConstraintCheck({
      updatedScheduleData,
      setModalMessage,
    })
  ) {
    updateButtonDisabled({ ref: buttonRef, disabled: false });
    setModalOpener(true);
    return null;
  }

  return updatedScheduleData;
};

export default generateUpdatedSchedule;
