import { INITIAL_INVOICE_LIST } from '../../../../constants/Acct/Common/initialInvoiceList';

const resetFormModal = ({
  setInvoiceList,
  resetScan,
  resetVendor,
  invoiceKey,
  setInvoiceKey,
}) => {
  setInvoiceList([{ key: invoiceKey, data: {} }]);
  resetScan();
  resetVendor();
  setInvoiceKey(prev => prev + 1);
};

export default resetFormModal;
