import React from 'react';

const headers = ['TIME', 'LOCATION', 'MOBILE', 'VEHICLE', 'DRIVER', 'STATUS'];

const TrackingHeader = () => {
  return (
    <div className="flex space-x-[2px] ml-[330px]">
      {headers.map((header, index) => (
        <div
          key={header}
          className="w-[252px] h-[35px] flex items-center justify-center text-[11px] text-[#222] font-[500] bg-[#fff] shadow drop-shadow-[0_0.5px_0_rgba(0,0,0,0.25)]"
        >
          <h1>{header}</h1>
        </div>
      ))}
    </div>
  );
};

export default TrackingHeader;
