import { CONF_DEFAULT_DATA } from '../../../../constants/Admin/Conf/defaultData';

const createDefaultRowSetting = ({ title }) => ({
  onRowClicked: ({ gridParam }) =>
    gridParam?.api?.setGridOption(
      'suppressClickEdit',
      CONF_DEFAULT_DATA?.[title]?.includes(gridParam?.data?.seq),
    ),
  onRowEditingStart: ({ gridParam }) =>
    CONF_DEFAULT_DATA?.[title]?.includes(gridParam?.data?.seq) &&
    gridParam?.api?.stopEditing(true),
  getRowStyle: params =>
    CONF_DEFAULT_DATA?.[title]?.includes(params?.data?.seq)
      ? { background: '#f2f4f8' }
      : null,
  setEditDisabled: ({ gridParam }) =>
    CONF_DEFAULT_DATA?.[title]?.includes(gridParam?.data?.seq),
});

export default createDefaultRowSetting;
