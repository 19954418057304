import React from 'react';

const ScheduleAddModalContentHeader = ({ ...props }) => {
  return (
    <div className="w-full h-[31px] border-solid border-b-[1px] border-b-[#EEE] bg-[#F9F9F9] flex flex-row">
      <div
        className="w-[120px] h-full flex justify-center items-center
            text-[#222] text-[12px]
            border-solid border-r-[1px] border-[#EEE]"
      >
        LOCATION ID
      </div>
      <div
        className="w-[95px] h-full flex justify-center items-center
            text-[#222] text-[12px]
            border-solid border-r-[1px] border-[#EEE]"
      >
        CITY
      </div>
      <div
        className="w-[58px] h-full flex justify-center items-center
            text-[#222] text-[12px]
            border-solid border-r-[1px] border-[#EEE]"
      >
        STATE
      </div>
      <div
        className="w-[85px] h-full flex justify-center items-center
            text-[#222] text-[12px]
            border-solid border-r-[1px] border-[#EEE]"
      >
        TIME ZONE
      </div>
      <div
        className="w-[130px] h-full flex justify-center items-center
            text-[#222] text-[12px]
            border-solid border-r-[1px] border-[#EEE]"
      >
        SCHEDULE DATE
      </div>
      <div
        className="w-[130px] h-full flex justify-center items-center
            text-[#222] text-[12px]
            "
      >
        SCHEDULE TIME
      </div>
    </div>
  );
};

export default ScheduleAddModalContentHeader;
