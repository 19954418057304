import { TextField } from '@mui/material';
import React, { useState } from 'react';

const APModalTextInput = ({ ...props }) => {
  const [textCenter, setTextCenter] = useState(props?.textCenter || true);
  const [textColor, setTextColor] = useState(props?.textColor || '#222');
  const [backgroundColor, setBackgroundColor] = useState(
    props?.backgroundColor || '#FFF',
  );

  return (
    <div className="w-full">
      <TextField
        variant="outlined"
        value={props?.value}
        onChange={e => props?.onChangeHandler?.({ e })}
        sx={{
          input: {
            textAlign: `${textCenter ? 'center' : ''}`,
            color: `${textColor}`,
            zIndex: '999',
            ':focus': {
              color: '#000 !important',
            },
          },
          '.MuiInputBase-root': {
            height: '30px',
            fontSize: '12px',
            fontWeight: '500',

            '&.Mui-focused fieldset': {
              border: '1.2px solid #264B9F !important',
              boxShadow: '0px 0px 4px #8BBCE9 !important',
              backgroundColor: '#fff !important',
            },
            '&.Mui-error fieldset': {
              border: '1.2px solid #C24D4D !important',
              backgroundColor: '#fff !important',
            },
            '&:not(.Mui-focused):not(.Mui-disabled):hover fieldset': {
              border: '0px solid #8E9396 !important',
            },
            '&.Mui-disabled fieldset': {
              backgroundColor: 'unset !important',
            },
          },
          fieldset: {
            border: 'none',
            borderRadius: '0px',
            backgroundColor: `${backgroundColor}`,
          },
        }}
      />
    </div>
  );
};

export default APModalTextInput;
