const createCompanySelect = ({ companyData }) => {
  const result = companyData?.map(company => {
    return {
      label: `${company?.company} / ${company?.business_name} / ${
        company?.address_01
      }
      ${company?.city && company?.city !== '' ? ` , ${company?.city}` : ''}
      ${company?.state && company?.state !== '' ? ` , ${company?.state}` : ''}
      ${company?.zip && company?.zip !== '' ? ` ${company?.zip}` : ''}`,
      value: company?.company,
    };
  });

  return result;
};

export default createCompanySelect;
