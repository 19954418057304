import { useMutation } from '@tanstack/react-query';
import deleteServiceCode from '../../../services/Master/ServiceCode/Delete/deleteServiceCode';
import masterKeys from '../keys';
import useControlToast from '../../Common/Toast/useControlToast';
import { SUCCESS_DELETE } from '../../../constants/Common/successMessage';
import updateButtonDisabled from '../../../utils/Common/Handler/updateButtonDisabled';

const useDeleteServiceCode = ({
  setMessageModalOpen,
  setMessageContent,
  setIsDeleteModal,
  setIsInUseModal,
  queryClient,
  division,
}) => {
  const { addToast } = useControlToast();

  const mutator = useMutation({
    mutationFn: deleteServiceCode,
    refetchOnWindowFocus: false,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: () => {
      setIsDeleteModal(false);
      queryClient.invalidateQueries({
        queryKey: masterKeys.serviceCodeList({ division }),
      });
      addToast({ message: SUCCESS_DELETE });
    },
    onError: error => {
      setIsDeleteModal(false);
      if (error.message.includes('assigned')) {
        setIsInUseModal(true);
      } else {
        setMessageContent(error.message);
        setMessageModalOpen(true);
      }
    },
  });
  return mutator;
};

export default useDeleteServiceCode;
