export const createFloatingFilterSelectOption = list => {
  const result = [{ key: 0, label: 'All', value: '' }];

  list?.forEach((element, idx) => {
    result.push({ key: idx + 1, label: element, value: element });
  });
  return result;
};

export const createGridSelectOption = list => {
  const result = [{ key: 0, label: 'Select', value: '' }];

  list?.forEach((element, idx) => {
    element && result.push({ key: idx + 1, label: element, value: element });
  });
  return result;
};

export const createMandatorySelectOption = list => {
  const result = [];

  list?.forEach((element, idx) => {
    result?.push({ key: idx, label: element, value: element });
  });
  return result;
};
