import {
  NUMBER_COLUMN_LIST,
  LEFT_LEVEL_COLUMN,
  PASSED_LEVEL_COLUMN,
} from '../../../../constants/Admin/EarlyWarning/columns';

export const validateZeroValue = ({ patchData }) => {
  let isZeroValueValid = true;

  isZeroValueValid = !NUMBER_COLUMN_LIST?.some?.(
    key => patchData?.[key] !== undefined && patchData?.[key] === 0,
  );

  return isZeroValueValid;
};

export const validateDuplicateNumber = ({ patchData, targetFieldList }) => {
  let isDuplicateNumberValid = true;

  const targetNumberList = targetFieldList
    ?.map(key => patchData?.[key])
    ?.filter(value => value !== undefined && value !== null);

  const targetNumberListLength = targetNumberList?.length;
  if (targetNumberListLength > 0) {
    const targetNumberSetLength = new Set(targetNumberList)?.size;

    isDuplicateNumberValid = targetNumberListLength === targetNumberSetLength;
  }

  return isDuplicateNumberValid;
};

export const validateLeftValueComparison = ({ patchData }) => {
  let isComparisonLeftValueValid = true;

  const leftValueList = LEFT_LEVEL_COLUMN?.map(key => patchData?.[key])?.filter(
    value => value !== undefined && value !== null,
  );

  if (leftValueList?.length > 1) {
    isComparisonLeftValueValid = !leftValueList.some(
      (value, index) => index > 0 && value >= leftValueList[index - 1],
    );
  }

  return isComparisonLeftValueValid;
};

export const validatePassedValueComparison = ({ patchData }) => {
  let isComparisonPassedValueValid = true;
  const passedValueList = PASSED_LEVEL_COLUMN?.map(
    key => patchData?.[key],
  )?.filter(value => value !== undefined && value !== null);

  if (passedValueList?.length > 1) {
    isComparisonPassedValueValid = !passedValueList.some(
      (value, index) => index > 0 && value <= passedValueList[index - 1],
    );
  }

  return isComparisonPassedValueValid;
};

export const validateNumberValueRange = ({ patchData }) => {
  let isNumberRangeValid = true;

  isNumberRangeValid = !NUMBER_COLUMN_LIST?.some?.(
    key => patchData?.[key] !== undefined && patchData?.[key] > 32767,
  );

  return isNumberRangeValid;
};
