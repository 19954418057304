import React from 'react';
import { LIST_ICON } from '../../../../../constants/Order/Common/path';

const LocationHeader = ({ index }) => {
  return (
    <div className="flex items-center">
      <div className="flex w-[173px] h-[30px]  items-center gap-x-[12px] ">
        <div>
          <img src={LIST_ICON} width={16} height={16} alt="listIcon" />
        </div>
        <div className="text-[#264B9F] text-[14px] font-[700] ">
          <p>Schedule {index + 1}</p>
        </div>
      </div>
      <div className="flex items-center gap-x-[2px] ml-[58px] mt-[13px]">
        <div className="text-[11px] font-[500] w-[173px] h-[30px] py-[8px] flex justify-center ">
          <p>LOCATION ID</p>
        </div>
        <div className="text-[11px] font-[500]  w-[173px] h-[30px] py-[8px] flex justify-center">
          <p>CITY</p>
        </div>
        <div className="text-[11px] font-[500]  w-[173px] h-[30px] py-[8px] flex justify-center">
          <p>ST</p>
        </div>
        <div className="text-[11px] font-[500]  w-[173px] h-[30px] py-[8px] flex justify-center">
          <p>TIME ZONE</p>
        </div>
        <div className="text-[11px] font-[500]  w-[173px] h-[30px] py-[8px] flex justify-center">
          <p>SCHEDULE DATE</p>
        </div>
        <div className="text-[11px] font-[500]  w-[173px] h-[30px] py-[8px] flex justify-center">
          <p>TIME</p>
        </div>
        <div className="text-[11px] font-[500]  w-[173px] h-[30px] py-[8px] flex justify-center">
          <p>ACTUAL DATE</p>
        </div>
        <div className="text-[11px] font-[500]  w-[173px] h-[30px] py-[8px] flex justify-center">
          <p>TIME</p>
        </div>
        <div className="text-[11px] font-[500]  w-[173px] h-[30px] py-[8px] flex justify-center">
          <p>DRIVER ID</p>
        </div>
      </div>
    </div>
  );
};

export default LocationHeader;
