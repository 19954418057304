import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';
import billingInfoDefaultAPI from '../../api';

const patchAPData = async ({ seq, data }) => {
  try {
    const response = await billingInfoDefaultAPI.patch(`/ap/${seq}`, {
      bill_date: data?.bill_date,
      driver: data?.driver,
      vendor: data?.vendor,
      product_code: data?.product_code,
      amt: data?.amt,
      description: data?.description === '' ? null : data?.description,
      bill_no: data?.bill_no === '' ? null : data?.bill_no,
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchAPData;
