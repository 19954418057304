import { useMutation } from '@tanstack/react-query';
import getSelectedCompany from '../../../services/Master/Company/Get/getSelectedCompany';

const useGetSelectedCompany = ({
  setRowData,
  setMessageContent,
  setMessageModalOpen,
  setEditMode,
  setSideModalOpen,
}) =>
  useMutation({
    mutationFn: getSelectedCompany,
    onSuccess: result => {
      setRowData(result?.data);
      setEditMode(true);
      setSideModalOpen(true);
    },
    onError: error => {
      setMessageContent(error.message);
      setMessageModalOpen(true);
    },
  });

export default useGetSelectedCompany;
