import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SUCCESS_SEND } from '../../../constants/Common/successMessage';
import postSendEmail from '../../../services/TmsReport/Demurrage/Post/postSendEmail';
import useControlToast from '../../Common/Toast/useControlToast';
import tmsReportKeys from '../keys';
import updateButtonDisabled from '../../../utils/Common/Handler/updateButtonDisabled';

const usePostSendEmailMutation = ({
  setIsMessageOpen,
  setMessage,
  division,
  fromDate,
  toDate,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  return useMutation({
    mutationFn: postSendEmail,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: tmsReportKeys?.demurrageList({ division, fromDate, toDate }),
      });
      addToast({
        message: SUCCESS_SEND,
      });
    },
    onError: error => {
      queryClient.refetchQueries({
        queryKey: tmsReportKeys?.demurrageList({ division, fromDate, toDate }),
      });
      setMessage(error.message);
      setIsSuccess(false);
      setIsMessageOpen(true);
    },
  });
};

export default usePostSendEmailMutation;
