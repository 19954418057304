const DAILY_REPORT_BASIC_COLUMN_HEADER = {
  div: 'DIV',
  no: 'NO',
  category: 'CAT',
  billto: 'BILL TO',
  mbl_booking: 'BOOKING #',
  bk_no: 'BOOKING #',
  mbl_no: 'MBL/BOOKING #',
  cont_no: 'EQUIPMENT',
  equipment: 'EQUIPMENT',
  ssl: 'SSL',
  size: 'SIZE',
  type: 'TYPE',
  wgt: 'WGT',
  ch_no: 'CHASSIS',
  pool: 'POOL',
  lfd: 'LFD',
  rfd: 'RFD',
  erd: 'ERD',
  cutoff: 'CUT OFF',
  empty_load_cfm: 'L/E DATE',

  po_group: 'PULL OUT',
  po_loc: 'LOC',
  po_schedule: 'SCHEDULE',
  po_driver: 'DRIVER',

  dilv_group: 'DELIVERY',
  dilv_loc: 'LOC',
  dilv_city: 'CITY',
  dilv_state: 'ST',
  dilv_schedule: 'SCHEDULE',
  dilv_driver: 'DRIVER',

  rtn_group: 'RETURN',
  rtn_loc: 'LOC',
  rtn_schedule: 'SCHEDULE',
  rtn_driver: 'DRIVER',

  sche_type: 'LIVE',
  hazmat: 'HAZ',
  remarks: 'REMARKS',
};

const DAILY_REPORT_COLUMN_HEADER = {
  pull_out: DAILY_REPORT_BASIC_COLUMN_HEADER,
  delivery: DAILY_REPORT_BASIC_COLUMN_HEADER,
  return: { ...DAILY_REPORT_BASIC_COLUMN_HEADER, po_loc: 'P/O LOC' },
  return_yard: { ...DAILY_REPORT_BASIC_COLUMN_HEADER, po_loc: 'P/O LOC' },
};

export default DAILY_REPORT_COLUMN_HEADER;
