import React from 'react';
import CommonButton from '../../CommonButton/CommonButton';

const SettingTableContentRow = ({
  email,
  listSeq,
  deleteSettingUserIDMutate,
  setIsOpenEmailConfirmModal,
  setConfirmEmail,
  confirmDeleteOptions,
  setConfirmEmailListSeq,
}) => {
  const { setIsOpenDeleteConfirmModal, setDeleteMutator } =
    confirmDeleteOptions;

  return (
    <div className="flex flex-row gap-x-[2px] mt-[2px]">
      <div className="flex w-[478px] min-w-[478px] h-[30px] bg-[#F2F4F8] items-center pl-[12px] font-[500] text-[12px]">
        {email}
      </div>

      <CommonButton
        category="email"
        width="90px"
        height="30px"
        border="1px solid #CCD3E3"
        color="#264B9F"
        onClick={() => {
          setIsOpenEmailConfirmModal(true);
          setConfirmEmail(email);
          setConfirmEmailListSeq(listSeq);
        }}
      >
        Email
      </CommonButton>

      <CommonButton
        category="redDelete"
        width="90px"
        height="30px"
        border="1px solid #EAC5C5"
        color="#C24D4D"
        onClick={() => {
          const deleteData = {
            list_seq: listSeq,
            email,
          };
          const newDeleteMutator = () =>
            deleteSettingUserIDMutate({ deleteData });
          setDeleteMutator(() => newDeleteMutator);
          setIsOpenDeleteConfirmModal(true);
        }}
      >
        Delete
      </CommonButton>
    </div>
  );
};

export default SettingTableContentRow;
