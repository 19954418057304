import React from 'react';

import StyledCommonTabModalWrapper from '../../styles/Common/CommonTabModal/StyledCommonTabModalWrapper';
import CommonTabModalCloseButton from './CommonTabModalCloseButton';
import CommonTabModalHeader from './CommonTabModalHeader';

/**
 *
 * @modalOpen
 * State of modal open
 * @setModalOpen
 * Set modal open function
 * @tabIndexOpen
 * Index of the content you want to open
 * @setTabIndexOpen
 * Set tab function
 * @contents
 * all content of modal. A list of objects with headerName, content and buttons
 * @returns
 * Component of common tab modal
 */

const CommonTabModal = ({
  modalOpen,
  setModalOpen,
  tabIndexOpen,
  setTabIndexOpen,
  contents,
  ...props
}) => {
  return (
    modalOpen && (
      <div className="flex justify-center items-center w-full h-full">
        <StyledCommonTabModalWrapper {...props}>
          <div className="bg-[#F9F9F9] rounded-t-[10px] h-[40px] flex flex-row justify-between">
            <div className="flex flex-row">
              {contents.map((item, idx) => (
                <CommonTabModalHeader
                  setTabIndexOpen={setTabIndexOpen}
                  key={item?.headerName}
                  idx={idx}
                  open={tabIndexOpen === idx}
                  header={item?.headerName}
                />
              ))}
            </div>
            <div className="pl-[20px] pr-[21px] pt-[16px] pb-[12px]">
              <CommonTabModalCloseButton
                setModalOpen={setModalOpen}
                setTabIndexOpen={setTabIndexOpen}
              />
            </div>
          </div>
          <div className="flex gap-y-[30px] flex-col pt-[30px] pb-[24px] px-[26px]">
            <div>{contents[tabIndexOpen]?.content}</div>
            <div className="w-full">{contents[tabIndexOpen].buttonContent}</div>
          </div>
        </StyledCommonTabModalWrapper>
      </div>
    )
  );
};

export default CommonTabModal;
