import React from 'react';
import { useFormContext } from 'react-hook-form';
import CommonMessageModal from '../../../../../CommonMessageModal/CommonMessageModal';
import CommonButton from '../../../../../CommonButton/CommonButton';
import handleWODelete from '../../../../../../utils/BCDetail/DetailInfo/Common/Handler/handleWODelete';
import StyledCommonErrorModalButton from '../../../../../../styles/Common/CommonErrorModal/StyledCommonErrorModalButton';
import DeleteContents from '../DeleteContents';

const WODeleteConfirmModal = ({ setDeleteOpen, deleteOpen, ...props }) => {
  const { reset } = useFormContext();

  return (
    <CommonMessageModal
      setOpen={setDeleteOpen}
      open={deleteOpen}
      content={<DeleteContents />}
      header="Why do you Delete?"
      buttonContent={
        <StyledCommonErrorModalButton className="gap-x-[15px]">
          <CommonButton category="clear" onClick={reset}>
            Reset
          </CommonButton>
          <CommonButton
            category="save"
            preventDupClick
            onClick={({ buttonRef }) => handleWODelete({ buttonRef, ...props })}
          >
            Save
          </CommonButton>
        </StyledCommonErrorModalButton>
      }
    />
  );
};

export default WODeleteConfirmModal;
