import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFormContext } from 'react-hook-form';
import patchConvertScheduleType from '../../../../services/BCDetail/DetailInfo/Schedule/Patch/patchConvertScheduleType';
import bcDetailKeys from '../../keys';

/**
 * @description
 * Custom hook for API convert schedule type
 * @returns {object} mutate object
 */
const usePatchConvertScheduleType = ({ setMessageModalOpen, setMessage }) => {
  const { reset } = useFormContext();

  const queryClient = useQueryClient();

  const mutator = useMutation({
    mutationFn: patchConvertScheduleType,
    onSuccess: () => {
      /** Reset dirty form when success to convert schedule type */
      reset();
      queryClient?.invalidateQueries({ queryKey: bcDetailKeys.all });
    },
    onError: error => {
      setMessage(error?.message);
      setMessageModalOpen(true);
    },
  });

  return { ...mutator };
};

export default usePatchConvertScheduleType;
