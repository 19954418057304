import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import StyledTraceSearchBarWrapper from '../../../styles/Trace/SearchBar/StyledTraceSearchBarWrapper';
import StyledTraceSearchBarInput from '../../../styles/Trace/SearchBar/StyledTraceSearchBarInput';

let searchValue = ' ';

const SearchBar = ({ setTarget }) => (
  <StyledTraceSearchBarWrapper>
    <StyledTraceSearchBarInput
      data-testid="search-bar"
      placeholder="Search"
      onChange={e => {
        searchValue = e.target.value;
      }}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          setTarget(searchValue?.trim() || ' ');
          searchValue = ' ';
        }
      }}
    />
    <SearchIcon
      data-testid="search-icon"
      fontSize="large"
      sx={{ fontSize: '16px', fill: '#aaa', cursor: 'pointer' }}
      onClick={() => {
        setTarget(searchValue?.trim() || ' ');
        searchValue = ' ';
      }}
    />
  </StyledTraceSearchBarWrapper>
);

export default SearchBar;
