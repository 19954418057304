const onInputDefaultChangeHandler = ({
  e,
  onChange,
  setText,
  setTextLength,
  maxTextLength,
  ...props
}) => {
  const inputText = e?.target?.value;
  if (props.isInt) {
    if (isNaN(Number(e?.target?.value))) return;
  }

  if (inputText.length <= maxTextLength) {
    setText(inputText);
    onChange(inputText);
    setTextLength(inputText.length);
  }
};

export default onInputDefaultChangeHandler;
