const generateWOObjectList = ({ woData, sslList }) => {
  return woData?.map(wo => {
    return {
      woNo: wo?.wo_no,
      woDiv: wo?.div,
      woBkNo: wo?.bk_no || wo?.mbl_no || '',
      woSt: wo?.st,
      woFromDate: wo?.wo_from_date,
      woToDate: wo?.wo_to_date,
      woSSL: sslList?.find(ssl => ssl?.value === wo?.ssl)?.label,
      woContNo: wo?.cont_no,
    };
  });
};

export default generateWOObjectList;
