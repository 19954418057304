export const DRIVER_POSITION_OBJECT = {
  COMPANY: 'DR0001',
  OWNER: 'DR0002',
  CARRIER: 'DR0003',
};
export const DRIVER_POSITION = [
  {
    value: 'COMPANY',
    label: 'COMPANY',
  },
  {
    value: 'OWNER',
    label: 'OWNER',
  },
  {
    value: 'CARRIER',
    label: 'CARRIER',
  },
];
export const POSITION_DROPDOWN = ['All', 'COMPANY', 'CARRIER', 'OWNER'];
