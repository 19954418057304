import commonDefaultApi from '../api';

/**
 * API that link with root api
 */
export const commonCompanyAPI = {
  getCommonCompanyIdName: () => commonDefaultApi.get('company-list'),
  getCommonCustomerCompany: () =>
    commonDefaultApi.get('company-list?category=1'),
  getCommonCneeNglCompany: () =>
    commonDefaultApi.get('company-list?category=12'),
  getCommonCneeCompany: () => commonDefaultApi.get('company-list?category=4'),
  getCommonTermsList: () => commonDefaultApi.get('terms-list'),
};
