import React, { useState } from 'react';
import ScheduleTitle from './ScheduleTitle';
import ScheduleHeader from './ScheduleHeader';
import ScheduleTableColumn from './ScheduleTableColumn';
import ScheduleTableWrapper from './ScheduleTableWrapper';
import ScheduleTrackingModal from './ScheduleTrackingModal';
import useGetTrackingInfo from '../../../../../hooks/BCDetail/Common/useGetTrackingInfo';
import CommonErrorModal from '../../../../CommonErrorModal/CommonErrorModal';
import usePostEmail from '../../../../../hooks/BCDetail/Common/usePostEmail';

const ScheduleTable = ({ detailInfoData, isEditable, ...props }) => {
  const { basicInfoFormController, scheduleFormController, updateDetailInfo } =
    props;

  const [trackingModalOpener, setTrackingModalOpener] = useState(false);
  const [addModalOpener, setAddModalOpener] = useState(false);
  const [errorModalOpener, setErrorModalOpener] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');

  /** Tracking modal API */
  const { trackingInfo } = useGetTrackingInfo();

  /** Post email mutator */
  const { mutate: mutatePostEmail } = usePostEmail({
    setMessageModalOpen: setErrorModalOpener,
    setMessageContent: setErrorModalMessage,
  });

  return (
    <>
      <div className="mt-[50px]">
        {/** Schedule Header  */}
        <ScheduleTitle
          detailInfoData={detailInfoData}
          trackingInfo={trackingInfo}
          setTrackingModalOpener={setTrackingModalOpener}
          isEditable={isEditable}
        />

        <div>
          {/** Schedule Table Header */}
          <ScheduleHeader
            detailInfoData={detailInfoData}
            isEditable={isEditable}
          />
          <ScheduleTableColumn
            detailInfoData={detailInfoData}
            setAddModalOpener={setAddModalOpener}
          />

          {/** Table Field */}
          <ScheduleTableWrapper
            detailInfoData={detailInfoData}
            addModalOpener={addModalOpener}
            setAddModalOpener={setAddModalOpener}
            isEditable={isEditable}
            basicInfoFormController={basicInfoFormController}
            scheduleFormController={scheduleFormController}
            updateDetailInfo={updateDetailInfo}
          />
        </div>
      </div>

      {/* Disable Tracking Modal */}
      {trackingModalOpener && (
        <ScheduleTrackingModal
          open={trackingModalOpener}
          setOpen={setTrackingModalOpener}
          trackingInfo={trackingInfo}
          setMessageModalOpener={setErrorModalOpener}
          setMessageModalMessage={setErrorModalMessage}
          mutatePostEmail={mutatePostEmail}
        />
      )}
      {/* Error modal */}
      {errorModalOpener && (
        <CommonErrorModal
          open={errorModalOpener}
          setIsOpen={setErrorModalOpener}
          message={errorModalMessage}
        />
      )}
    </>
  );
};

export default ScheduleTable;
