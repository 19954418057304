const handleRowClick = ({ props, setUserID, setIsOpenSideModal }) => {
  const columnID = props?.gridParam?.api?.getFocusedCell()?.column?.getColId();
  if (columnID !== 'tms_status') {
    const userID = props?.gridParam?.data?.id;
    setUserID(userID);
    setIsOpenSideModal(true);
  }
};

export default handleRowClick;
