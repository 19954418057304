import styled from 'styled-components';

const StyledBCDetailBillingInfoCommonFooterContainer = styled.tfoot`
  margin-top: -2px;
  display: flex;
  height: 30px;
  width: 1800px;
  align-items: center;
  background-color: #fff;
`;

export default StyledBCDetailBillingInfoCommonFooterContainer;
