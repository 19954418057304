import handleQueryError from '../../../utils/Common/Handler/handleQueryError';
import ediDefaultAPI from '../api';

const getMainList = async ({ div, type, from, to, sender }) => {
  try {
    const response = await ediDefaultAPI.get('204-main', {
      params: {
        div,
        [`${type}-from`]: from,
        [`${type}-to`]: to,
        sender,
      },
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getMainList;
