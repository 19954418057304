const getDailyScheduleFilter = () => {
  const splitedURL = document.URL.split('/')?.pop();
  const pageCategory = splitedURL?.split('?')?.[0];

  if (pageCategory === 'pull-out')
    return JSON.parse(localStorage?.getItem('pullOutFilter'));
  if (pageCategory === 'delivery')
    return JSON.parse(localStorage?.getItem('deliveryFilter'));
  if (pageCategory === 'return')
    return JSON.parse(localStorage?.getItem('returnFilter'));
  return null;
};

export default getDailyScheduleFilter;
