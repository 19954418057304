import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import apRateAPI from '../api';

/**
 * Deletes ap rate data in the backend for a given sequence number.
 * @param {string|number} seq - The sequence number of the ap rate data to be deleted.
 * @returns {boolean} - True if the deletion is successful, false on error.
 */
const deleteAPRate = async ({ seq }) => {
  try {
    await apRateAPI.delete(`/${seq}`);
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default deleteAPRate;
