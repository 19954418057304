import styled from 'styled-components';

const StyledSearchBarWrapper = styled.div`
  border-radius: 3px;
  width: 165px;
  height: 30px;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  padding: 0px 9px 0px 20px;
`;

export default StyledSearchBarWrapper;
