import handleQueryError from '../../../utils/Common/Handler/handleQueryError';
import ocrDefaultApi from '../ocrAPI';

const postScanDO = async ({ file, category }) => {
  try {
    const response = await ocrDefaultApi.post('do', { file, category });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default postScanDO;
