import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import orderKeys from './keys';
import getBookingInfo from '../../services/Order/Get/getBookingInfo';
import { setOrderInfoWithBookingNumber } from '../../utils/Order/Handler/setOrderInfoWithBookingNumber';
import generateBookingInfoOption from '../../utils/Order/Generator/generateBookingInfoOption';

/**
 * @description
 * Get booking info according to Bookig number
 * @param {number} bookingSequence
 * Booking sequence of booking number
 * @param {list} containerList
 * List of containers added to date
 * @param {object} setContainerList,
 * Setter of container list
 * @returns {object}
 * bookingInfo, query, bookingInfoSelectOption
 */
const useGetBookingInfo = ({
  bookingSequence,
  containerList,
  setContainerList,
}) => {
  // Query client for remove query
  const queryClient = useQueryClient();

  // RHF form property
  const { setValue } = useFormContext();

  // Initial booking info from API result
  const [bookingInfo, setBookingInfo] = useState(null);
  useEffect(() => {
    if (!bookingSequence) {
      queryClient?.removeQueries({
        queryKey: orderKeys?.bookingInfo(),
      });
      setBookingInfo(null);
    }
  }, [bookingSequence]);

  /**
   * Create Select option according to booking info
   */
  const [bookingInfoSelectOption, setBookingInfoSelectOption] = useState(null);
  useEffect(() => {
    if (bookingInfo) {
      setBookingInfoSelectOption(generateBookingInfoOption({ bookingInfo }));
    } else {
      setBookingInfoSelectOption(null);
    }
  }, [bookingInfo]);

  /**
   * Query to get booking info
   */
  const query = useQuery({
    queryKey: orderKeys?.bookingInfoWithKey({ bookingSequence }),
    queryFn: () => getBookingInfo({ bookingSequence }),
    refetchOnWindowFocus: false,
    enabled: !!bookingSequence,
  });

  /**
   * If booking data exist, data will be set to order info
   */
  useEffect(() => {
    if (query?.data?.data) {
      const bookingInfoData = query?.data?.data;
      if (bookingInfoData !== bookingInfo) {
        setBookingInfo(bookingInfoData);
        setOrderInfoWithBookingNumber({
          setValue,
          bookingInfoData,
          containerList,
          setContainerList,
        });
      }
    }
  }, [query]);

  return { bookingInfo, query, bookingInfoSelectOption };
};

export default useGetBookingInfo;
