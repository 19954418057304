import { useMutation } from '@tanstack/react-query';
import postScanChassis from '../../../../services/Acct/ChassisReconcile/Post/postScanChassis';
import generateChassisInvoiceList from '../../../../utils/Acct/ChassisReconcile/Generator/generateChassisInvoiceList';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';

const usePostScanChassisInvoice = ({
  setInvoiceList,
  setInvoiceKey,
  setErrorModalOptions,
}) => {
  const { setErrorModalOpen, setErrorMessage, setIsSuccess } =
    setErrorModalOptions;

  const mutate = useMutation({
    mutationFn: postScanChassis,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: ({ data }) => {
      const { invoiceList, invoiceKey } = generateChassisInvoiceList({
        data,
      });
      setInvoiceList(prev => invoiceList);
      setInvoiceKey(invoiceKey);
    },
    onError: error => {
      setIsSuccess(false);
      setErrorMessage(error?.message);
      setErrorModalOpen(true);
    },
  });
  return { ...mutate };
};

export default usePostScanChassisInvoice;
