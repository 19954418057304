import { STATUS_CHECK_BOX_OPTION } from '../../../../constants/Acct/Common/selectOptions';

const checkIncludingStatus = ({ queryObject, status }) => {
  const statusInputName = STATUS_CHECK_BOX_OPTION?.find(
    item => item?.code === status,
  )?.inputName;

  const isIncludeStatus = queryObject?.[statusInputName];

  return isIncludeStatus;
};

export default checkIncludingStatus;
