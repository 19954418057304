const defaultOption = [
  {
    label: 'All',
    value: '',
  },
];

export const generateObjectToOption = (objList, itemName) => {
  const option = [...defaultOption];

  Object?.values?.(objList)?.forEach(item => {
    option?.push({
      label: item || itemName || '',
      value: item || itemName || '',
    });
  });

  return option;
};

export const generateListToOption = itemList => {
  const option = [...defaultOption];
  itemList?.forEach(item => {
    option?.push({ label: item, value: item });
  });

  return option;
};

export const generateBooleanOption = () => {
  const option = [...defaultOption];
  const booleans = [true, false];

  booleans?.forEach(boolean => {
    option?.push({
      label: boolean ? 'Y' : 'N',
      value: String(boolean),
    });
  });

  return option;
};
