import { useState } from 'react';

const useModalController = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalIsSuccess, setModalIsSuccess] = useState(false);

  const setFilterModalStatus = ({ message, isSuccess, isOpen }) => {
    setModalMessage(prev => message);
    setModalIsSuccess(prev => isSuccess);
    setModalOpen(prev => isOpen);
  };

  return {
    modalOpen,
    setModalOpen,
    modalMessage,
    setModalMessage,
    modalIsSuccess,
    setModalIsSuccess,
    setFilterModalStatus,
  };
};

export default useModalController;
