import styled from 'styled-components';

const StyledTraceSearchBarShortInput = styled.input`
  color: #808487;
  font-size: 15px;
  font-weight: 500;
  width: 262px;
  background-color: #f9f9f9;
  padding-left: 16px;
`;

export default StyledTraceSearchBarShortInput;
