export const WO_TYPE_VALUES = {
  M: {
    category: 'IMPORT',
    url: 'import',
  },
  X: {
    category: 'EXPORT',
    url: 'export',
  },
  V: {
    category: 'VAN',
    url: 'van',
  },
  B: {
    category: 'BROKERAGE',
    url: 'brokerage',
  },
  C: {
    category: 'BARE CHASSIS',
    url: 'bare-chassis',
  },
};
