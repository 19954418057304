import getAssignButtonDef from '../../../utils/Dispatch/Common/Generator/getAssignButtonDef';
import getCompleteButtonDef from '../../../utils/Dispatch/Common/Generator/getCompleteButtonDef';
import getExportButtonDef from '../../../utils/Dispatch/Common/Generator/getExportButtonDef';

const BUTTON_COL_DEF = {
  po_driver: props => getAssignButtonDef({ field: 'po_driver', ...props }),
  dilv_driver: props => getAssignButtonDef({ field: 'dilv_driver', ...props }),
  pu_driver: props => getAssignButtonDef({ field: 'pu_driver', ...props }),
  rtn_driver: props => getAssignButtonDef({ field: 'rtn_driver', ...props }),

  complete: ({ onClickHandler }) => getCompleteButtonDef({ onClickHandler }),
  export_btn: ({ onClickHandler }) => getExportButtonDef({ onClickHandler }),
};

export default BUTTON_COL_DEF;
