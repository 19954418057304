import React from 'react';
import StyledControllerLabelWrapper from '../../../../styles/Common/Controller/Common/StyledControllerLabelWrapper';
import StyledControllerLabelRequiredWrapper from '../../../../styles/Common/Controller/Common/StyledControllerLabelReuiqredWrapper';

const ControllerLabel = ({
  label,
  required,
  suppressLabelVisible,
  suppressLabelContentVisible,
}) => {
  return (
    !suppressLabelVisible && (
      <StyledControllerLabelWrapper>
        {!suppressLabelContentVisible && (
          <>
            {required && (
              <StyledControllerLabelRequiredWrapper>
                *
              </StyledControllerLabelRequiredWrapper>
            )}
            {label}
          </>
        )}
      </StyledControllerLabelWrapper>
    )
  );
};

export default ControllerLabel;
