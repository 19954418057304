import axios from 'axios';
import interceptRequest from '../../utils/Common/Interceptor/interceptRequest';
/**
 * Admin base url
 */
const ADMIN_BASE_URL = `${process.env.REACT_APP_BASE_URL}/super-admin/`;

/**
 * Axios object with base url
 */
const adminDefaultApi = axios.create({
  withCredentials: true,
  baseURL: ADMIN_BASE_URL,
  xsrfHeaderName: 'X-CSRFTOKEN',
  xsrfCookieName: 'XCSRF-TOKEN',
  headers: {
    common: {
      'X-CSRFToken': localStorage.getItem('csrfToken'),
    },
  },
  redirect: 'follow',
});

/** Interceptors */
interceptRequest(adminDefaultApi);

export default adminDefaultApi;
