import {
  EXPORT_WORKORDER_CATEGORY,
  EXPORT_WORKORDER_CATEGORY_OBJECT,
  IMPORT_WORKORDER_CATEGORY,
  IMPORT_WORKORDER_CATEGORY_OBJECT,
} from '../../../constants/Main/Common/mainElement';
import convertStandardCodeToText from '../../Common/convertStandardCodeToText';
import convertBoolToString from './convertBoolToString';
import convertDatesToMMDDYY from './convertDatesToMMDDYY';
import replaceSlashToUnderBar from './replaceSlashToUnderbar';

const setResultData = resultData => {
  return !Array.isArray(resultData)
    ? []
    : resultData?.map((data, index) => {
        if (
          Object.values(IMPORT_WORKORDER_CATEGORY_OBJECT).includes(
            data.category,
          )
        ) {
          resultData[index].category = convertStandardCodeToText(
            data.category,
            IMPORT_WORKORDER_CATEGORY,
            IMPORT_WORKORDER_CATEGORY_OBJECT,
          );
        }
        if (
          Object.values(EXPORT_WORKORDER_CATEGORY_OBJECT).includes(
            data.category,
          )
        ) {
          resultData[index].category = convertStandardCodeToText(
            data.category,
            EXPORT_WORKORDER_CATEGORY,
            EXPORT_WORKORDER_CATEGORY_OBJECT,
          );
        }
        resultData[index] = replaceSlashToUnderBar(data, '/', '_');
        resultData[index] = convertDatesToMMDDYY(resultData[index]);
        resultData[index] = convertBoolToString(resultData[index]);
        if (resultData[index].wgt)
          resultData[index].wgt = Math.ceil(resultData[index].wgt);
        return resultData[index];
      });
};

export default setResultData;
