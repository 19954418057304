import styled, { css } from 'styled-components';
import {
  SUB_ORDER_TYPE_EXPREG,
  SUB_ORDER_TYPE_EXPSHUTTLE,
  SUB_ORDER_TYPE_IMPREG,
} from '../../constants/Order/Common/basic';

const StyledMiddleInfo = styled.div`
  display: grid;

  ${props => {
    if (
      [
        SUB_ORDER_TYPE_IMPREG,
        SUB_ORDER_TYPE_EXPREG,
        SUB_ORDER_TYPE_EXPSHUTTLE,
      ].includes(props?.subCategory)
    ) {
      if (props?.subCategory === SUB_ORDER_TYPE_IMPREG) {
        return css`
          grid-template-rows: ${props?.subCategory === SUB_ORDER_TYPE_IMPREG
            ? `repeat(2, 1fr)`
            : `repeat(3, 1fr)`};
          /* grid-template-columns: repeat(9, 1fr); */
          grid-template-columns: 116px 116px 190px 192px;

          column-gap: 10px;

          > :nth-child(4) {
            grid-column: 4 / span 1;
            grid-row: 1 / span 2;
          }
          > :nth-child(5) {
            grid-column: 1 / span 3;
          }
          > :nth-child(6) {
            grid-column: 1 / span 4;
          }
        `;
      }
      return css`
        grid-template-rows: repeat(3, 60px);
        grid-template-columns: repeat(3, 1fr);

        column-gap: 15px;

        > :nth-child(4) {
          grid-column: 1 / span 3;
        }
        > :nth-child(5) {
          grid-column: 1 / span 3;
        }
      `;
    }
    return css`
      grid-template-rows: repeat(2, 1fr);
      grid-template-columns: 1fr;
    `;
  }}
`;

export default StyledMiddleInfo;
