import { useQuery } from '@tanstack/react-query';
import mobileAdminKeys from '../keys';
import getPODCheckList from '../../../services/MobileAdmin/PODCheckList/Get/getPODCheckList';

const useGetPODCheckList = ({ division }) => {
  const result = useQuery({
    queryKey: mobileAdminKeys.podCheckList({ division }),
    queryFn: () => getPODCheckList({ division }),
  });

  return result;
};

export default useGetPODCheckList;
