import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { setFlag } from '../../../../redux/features/TmsReport/EarlyWarning';
import { patchEarlyWarning } from '../../../../services/TmsReport/EarlyWarning/Patch/patchEarlyWarning';

const usePatchEarlyWarning = ({
  refetchEarlyWarningList,
  setErrorMessage,
  setErrorModalOpen,
  setIsSuccess,
  setIsShowToast,
  setConfirmStatus,
}) => {
  const dispatch = useDispatch();
  const currentPathName = window?.location?.pathname?.split('/')?.[2];

  const mutate = useMutation({
    mutationFn: patchEarlyWarning,
    onSuccess: () => {
      if (currentPathName === 'early-warning-list') {
        dispatch(setFlag());
      }
      refetchEarlyWarningList();
      setIsShowToast(true);
      setConfirmStatus('succeeded');
    },
    onError: error => {
      setIsShowToast(true);
      setConfirmStatus('failed');
      setErrorMessage(error.message);
      setErrorModalOpen(true);
      setIsSuccess(false);
    },
  });
  return { ...mutate };
};

export default usePatchEarlyWarning;
