import React from 'react';
import { Route, Routes, useLocation, Outlet } from 'react-router-dom';

import MainLayOut from '../../components/Layout/MainLayOut';
import SubMenuNavigator from '../../components/Common/SubMenuNavigator';
import { PAGE_TMS_REPORT } from '../../constants/Common/mapper';

const TmsReport = () => {
  const location = useLocation();
  const path = location.pathname;
  const pathParts = path.split('/');

  return (
    <MainLayOut>
      <SubMenuNavigator page={PAGE_TMS_REPORT} />
      <div className="w-full box-border pl-[97px] h-full">
        <Outlet />
      </div>
    </MainLayOut>
  );
};

export default TmsReport;
