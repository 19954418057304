export const SUCCESS_SAVE = 'Saved successfully.';
export const SUCCESS_DELETE = 'Deleted successfully.';
export const SUCCESS_SEND = 'Sent successfully.';
export const SUCCESS_UPDATE = 'Updated successfully.';
export const SUCCESS_CREATE = 'Created successfully.';
export const SUCCESS_CONNECT = 'Connected successfully.';
export const SUCCESS_SWITCH = 'Switched successfully.';
export const SUCCESS_ASSIGN = 'Assigned successfully.';
export const SUCCESS_CONVERT = 'Converted successfully.';
export const SUCCESS_ROLLBACK = 'Rolled back successfully.';
export const SUCCESS_ACCEPT = 'Accepted successfully.';
export const SUCCESS_DECLINE = 'Declined successfully.';
export const SUCCESS_COPY = 'Copied successfully.';
