import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useGetAPData from '../../../hooks/BCDetail/BillingInfo/AP/useGetAPData';
import useGetAPProductCode from '../../../hooks/BCDetail/BillingInfo/AP/useGetAPProductCode';
import useGetDriverVendorList from '../../../hooks/BCDetail/BillingInfo/AP/useGetDriverVendorList';
import usePatchAPInvoice from '../../../hooks/BCDetail/BillingInfo/AP/usePatchAPInvoice';
import usePostQB from '../../../hooks/BCDetail/BillingInfo/Common/usePostQB';
import StyledBCDetailBillingInfoCommonApArFormContainer from '../../../styles/BCDetail/BillingInfo/Common/StyledBCDetailBillingInfoCommonApArFormContainer';
import StyledBCDetailBillingInfoCommonApArTable from '../../../styles/BCDetail/BillingInfo/Common/StyledBCDetailBillingInfoCommonApArTable';
import PaymentModal from '../../BCDetail/PaymentModal';
import RateCFMModal from '../../BCDetail/RateCFMModal';
import APHeader from './Component/APHeader';
import APTableBody from './Component/APTableBody';
import APTableFooter from './Component/APTableFooter';
import APTableHeader from './Component/APTableHeader';

const AP = ({
  setProfit,
  setIsAlertOpen,
  setIsSuccess,
  setErrorMessage,
  isAPEditable,
  isDeletable,
  isQBAvailable,
  setIsDeletedWO,
  isSubmitting,
  setIsSubmitting,
  basicInfoData,
}) => {
  const APTableFormController = useForm();

  const [paymentModal, setPaymentModal] = useState(false);
  const [rateCFMModal, setRateCFMModal] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [isCompanyDriver, setIsCompanyDriver] = useState(false);
  const [isQBDisabled, setIsQBDisabled] = useState(false);
  const [isCreateBillNoDisabled, setIsCreateBillNoDisabled] = useState(false);

  const APQuery = useGetAPData({
    setIsAlertOpen,
    setErrorMessage,
  });
  const APProductCodeQuery = useGetAPProductCode({
    setIsAlertOpen,
    setErrorMessage,
    setIsDeletedWO,
  });
  const APDriverVendorList = useGetDriverVendorList({
    basicInfoData,
    setIsAlertOpen,
    setErrorMessage,
  });
  const { mutate: apInvoiceMutator } = usePatchAPInvoice({
    setIsAlertOpen,
    setErrorMessage,
    setIsSuccess,
    refetchAPData: APQuery?.refetchAPData,
    setIsQBDisabled,
  });
  const { mutate: qbMutator } = usePostQB({
    setIsAlertOpen,
    setErrorMessage,
    setIsSuccess,
    refetchData: APQuery?.refetchAPData,
  });

  return (
    <>
      <APHeader
        APQuery={APQuery}
        apInvoiceMutator={apInvoiceMutator}
        qbMutator={qbMutator}
        APTableFormController={APTableFormController}
        setIsAlertOpen={setIsAlertOpen}
        setIsSuccess={setIsSuccess}
        setErrorMessage={setErrorMessage}
        isCompanyDriver={isCompanyDriver}
        isQBDisabled={isQBDisabled}
        isCreateBillNoDisabled={isCreateBillNoDisabled}
        isAPEditable={isAPEditable}
        isQBAvailable={isQBAvailable}
      />
      <StyledBCDetailBillingInfoCommonApArFormContainer>
        <FormProvider {...APTableFormController}>
          <form>
            <StyledBCDetailBillingInfoCommonApArTable>
              <APTableHeader
                APProductCodeQuery={APProductCodeQuery}
                APDriverVendorList={APDriverVendorList}
                setPaymentModal={setPaymentModal}
                setSelectedDriver={setSelectedDriver}
                setIsAlertOpen={setIsAlertOpen}
                setIsSuccess={setIsSuccess}
                setErrorMessage={setErrorMessage}
                isAPEditable={isAPEditable}
                isSubmitting={isSubmitting}
                setIsSubmitting={setIsSubmitting}
                refetchAPData={APQuery?.refetchAPData}
                basicInfoData={basicInfoData}
              />
              <APTableBody
                APQuery={APQuery}
                APProductCodeQuery={APProductCodeQuery}
                APDriverVendorList={APDriverVendorList}
                setPaymentModal={setPaymentModal}
                setSelectedDriver={setSelectedDriver}
                setRateCFMModal={setRateCFMModal}
                setIsAlertOpen={setIsAlertOpen}
                setIsSuccess={setIsSuccess}
                setErrorMessage={setErrorMessage}
                setIsCompanyDriver={setIsCompanyDriver}
                setIsQBDisabled={setIsQBDisabled}
                setIsCreateBillNoDisabled={setIsCreateBillNoDisabled}
                isAPEditable={isAPEditable}
                isDeletable={isDeletable}
                refetchAPData={APQuery?.refetchAPData}
              />
              <APTableFooter APQuery={APQuery} setProfit={setProfit} />
            </StyledBCDetailBillingInfoCommonApArTable>
          </form>
        </FormProvider>
      </StyledBCDetailBillingInfoCommonApArFormContainer>
      <PaymentModal
        driverID={selectedDriver}
        APTableFormController={APTableFormController}
        setSelectedDriver={setSelectedDriver}
        setPaymentModal={setPaymentModal}
        isActiveClicked={paymentModal}
        setIsActiveClicked={setPaymentModal}
        setIsAlertOpen={setIsAlertOpen}
        setErrorMessage={setErrorMessage}
      />
      <RateCFMModal
        selectedNo={selectedDriver}
        isActiveClicked={rateCFMModal}
        setIsActiveClicked={setRateCFMModal}
        setIsAlertOpen={setIsAlertOpen}
        setIsSuccess={setIsSuccess}
        setErrorMessage={setErrorMessage}
      />
    </>
  );
};

export default AP;
