import handleScheduleDateTimeBothEnteredCheck from './handleScheduleDateTimeBothEnteredCheck';
import handleScheduleDateTimeSizeCheck from './handleScheduleDateTimeSizeCheck';

const handleDateTimeChainCheck = ({ updatedScheduleData, setModalMessage }) => {
  for (let i = 0; i < updatedScheduleData.length; i++) {
    const getTargetScheduleData = updatedScheduleData?.[i];

    /** Check both date and time are entered correctly */
    if (
      !handleScheduleDateTimeBothEnteredCheck({
        scheduleData: getTargetScheduleData,
        setModalMessage,
      })
    ) {
      return false;
    }

    if (
      !handleScheduleDateTimeSizeCheck({
        scheduleData: getTargetScheduleData,
        setModalMessage,
      })
    ) {
      return false;
    }
  }

  return true;
};

export default handleDateTimeChainCheck;
