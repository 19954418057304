import React from 'react';
import SingleRow from '../SingleRow';
import GroupedRow from '../GroupedRow';
import generateStatusData from '../../../../../utils/MobileAdmin/Manifest/Generator/generateStatusData';

const ManifestDetailTable = ({ rowData }) => {
  const {
    paidData,
    partialData,
    invoicedData,
    inProcessData,
    inReviewData,
    rejectedData,
  } = generateStatusData({ rowData });

  return (
    <div className="group-col">
      {paidData && <GroupedRow data={paidData} status="PAID" />}
      {partialData && <GroupedRow data={partialData} status="PARTIAL PAID" />}
      {invoicedData && <GroupedRow data={invoicedData} status="INVOICED" />}
      {inProcessData && <SingleRow data={inProcessData} status="IN PROCESS" />}
      {inReviewData && <SingleRow data={inReviewData} status="IN REVIEW" />}
      {rejectedData && <SingleRow data={rejectedData} status="REJECTED" />}
    </div>
  );
};

export default ManifestDetailTable;
