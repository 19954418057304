import { useQuery } from '@tanstack/react-query';
import getYardList from '../../../../services/BCDetail/BasicInfo/Get/getYardList';
import bcDetailKeys from '../../keys';

const useGetYardList = ({ isYardModalOpen }) => {
  const woNo =
    document.URL?.split('/')?.[document?.URL?.split('/')?.length - 1];

  const { isLoading: isLoadingYardList, data } = useQuery({
    queryKey: bcDetailKeys?.yardList({ woNo }),
    queryFn: () => getYardList({ woNo }),
    refetchOnWindowFocus: false,
    enabled: isYardModalOpen,
  });

  return {
    isLoadingYardList,
    data: data?.data,
  };
};

export default useGetYardList;
