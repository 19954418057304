import FloatingFilterSelect from '../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterSelect';

const CATEGORY_DEF = {
  floatingFilterComponent: FloatingFilterSelect,
  floatingFilterComponentParams: {
    option: [
      { key: 0, label: 'All', value: '' },
      {
        key: 1,
        label: 'REG',
        value: 'REG',
      },
      {
        key: 2,
        label: 'SHTL',
        value: 'SHTL',
      },
    ],
  },
  valueGetter: params => {
    if (['WC0001', 'WC0003'].includes(params.data.category)) return 'REG';
    if (['WC0002', 'WC0004'].includes(params.data.category)) return 'SHTL';
    return '';
  },
};

export default CATEGORY_DEF;
