import styled from 'styled-components';

const StyledBCDetailBillingInfoPaymentModalName = styled.div`
  color: #222;
  font-size: 12px;
  font-weight: 600;
  width: 47px;
`;

export default StyledBCDetailBillingInfoPaymentModalName;
