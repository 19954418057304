import { acctDefaultAPI } from '../../api';
import generateDuplicateError from '../../../../utils/Acct/Common/Generator/generateDuplicateError';

const patchPerdiemReconcile = async ({ seq, patchData }) => {
  try {
    const response = await acctDefaultAPI.patch(
      `perdiem-recon/${seq}`,
      patchData,
    );
    return response;
  } catch (error) {
    throw generateDuplicateError({ error, duplicateCheckFields: [] });
  }
};
export default patchPerdiemReconcile;
