export const handleFSCOnChangePair = ({ getChangedValue, ...props }) => {
  const instances = props?.api?.getCellRendererInstances();

  const pairInstance = instances?.find(
    element => element?.column?.colId === props?.pair,
  );

  pairInstance?.setValue(null);

  if (getChangedValue === '') {
    pairInstance?.setDisabled(false);
  } else {
    pairInstance?.setDisabled(true);
  }
};

export const handleFSCOnModChangePair = ({ getChangedValue, ...props }) => {
  const instances = props.api.getCellEditorInstances();

  const pairInstance = instances.find(
    element => element?.cellEditorParams?.column?.colId === props?.pair,
  )?.componentInstance;

  pairInstance?.setDataValue(null);
  pairInstance?.onValueChange(null);

  if (getChangedValue === '') {
    pairInstance?.setDisabled(false);
  } else {
    pairInstance?.setDisabled(true);
  }
};
