import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import commonKeys from './keys';
import getBookingNumber from '../../services/Order/Get/getBookingNumber';

/**
 * @description
 * Get Booking Number
 * @return {object}
 * Booking number and query result
 */
const useGetBookingNumber = () => {
  // Booking number
  const [bookingNumber, setBookingNumber] = useState(null);

  // Query
  const query = useQuery({
    queryKey: commonKeys?.bookingNumber(),
    queryFn: () => getBookingNumber(),
    refetchOnWindowFocus: false,
  });

  // Bind booking number value
  useEffect(() => {
    if (query?.data?.data) {
      const queryBookingNumber = query?.data?.data;
      setBookingNumber(queryBookingNumber);
    }
  }, [query]);

  // return
  return { bookingNumber, query };
};

export default useGetBookingNumber;
