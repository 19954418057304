const generateChassisInputField = ({ scanObject }) => {
  const inputField = {};
  Object.entries(scanObject)?.forEach(([key, value]) => {
    if (value) {
      inputField[key] = value;
    }
  });

  return inputField;
};

export default generateChassisInputField;
