import React from 'react';
import StyledAPModalTableContentHeaderCell from '../../../../styles/Dispatch/APModal/StyledAPModalTableContentHeaderCell';

const APModalTableContentHeaderCell = ({
  children,
  content,
  customStyle,
  ...props
}) => {
  return (
    <StyledAPModalTableContentHeaderCell {...customStyle} {...props}>
      {children}
    </StyledAPModalTableContentHeaderCell>
  );
};

export default APModalTableContentHeaderCell;
