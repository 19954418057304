import React from 'react';
import { SCHE_TYPE } from '../../../../../../constants/Common/standard';

const ScheduleTypeOption = ({
  openSetter,
  typeSetter,
  convertScheduleTypeMutator,
  ...props
}) => {
  const woNo = document.URL?.split('/')?.pop();
  const { open, setOpen } = openSetter;
  const { type, setType } = typeSetter;

  return (
    <div
      className={`${
        open ? 'block' : 'hidden'
      } w-[64px] text-[12px] z-10 font-[400] space-y-[2px] py-[6px] bg-white shadow drop-shadow-[0_0_4px_0_rgba(0,0,0,0.25)] text-center absolute top-[4em] left-[2.4em]`}
    >
      <div
        onClick={() => {
          setOpen(false);
          convertScheduleTypeMutator({
            woNo,
            scheType: SCHE_TYPE?.LIVE,
          });
        }}
        className={`h-[21px] pt-[3px] pb-[4px] cursor-pointer ${
          type === SCHE_TYPE?.LIVE ? 'bg-[#E2EBFF] font-bold ' : ''
        } hover:bg-[#E2EBFF]`}
      >
        <p data-testid="scheduleTypeOptionLive">LIVE</p>
      </div>
      <div
        onClick={() => {
          setOpen(false);
          convertScheduleTypeMutator({
            woNo,
            scheType: SCHE_TYPE?.DROP,
          });
        }}
        className={`h-[21px] pt-[3px] pb-[4px] cursor-pointer ${
          type === SCHE_TYPE?.DROP ? 'bg-[#E2EBFF] font-bold ' : ''
        } hover:bg-[#E2EBFF]`}
      >
        <p data-testid="scheduleTypeOptionDrop">DROP</p>
      </div>
    </div>
  );
};

export default ScheduleTypeOption;
