import { MAIN_CATEGORY_EXPORT } from '../../../constants/Main/Common/category';
import {
  AP_FIELDS,
  AR_FIELDS,
  DELIVERY_FIELDS,
  DOC_FIELDS,
  PICK_UP_FIELDS,
  PULL_OUT_FIELDS,
  RETURN_FIELDS,
} from '../../../constants/Main/Common/customColumnChildrenFields';
import getDef from './Generator/getDef';
import getHeader from './Generator/getFieldsHeader';
import getWidth from './Generator/getFieldsWidth';
import { CUSTOM_COLUMN_COLORS } from '../../../styles/Main/colorTheme';
import { GROUP_DEFS } from '../../../constants/Main/Common/columnGroups';

const setResultColDef = ({ setting, category }) => {
  const colWithChildren = [];
  const groupDefs = GROUP_DEFS;

  const addToGroup = ({ groupName, item }) => {
    const { headerName } = groupDefs?.[groupName];

    const groupCol = colWithChildren?.find(
      col => col?.headerName === headerName,
    );

    if (!groupCol) {
      const def = {
        headerName,
        headerClass: groupDefs[groupName].headerClass,
        isColumnGroup: true,
        ...CUSTOM_COLUMN_COLORS?.[category]?.[groupName],
        children: [
          {
            field: item,
            headerName: getHeader({ item, category }),
            ...getWidth(item),
            ...getDef(item),
            editable: false,
          },
        ],
      };
      colWithChildren?.push(def);
    } else {
      groupCol?.children?.push({
        field: item,
        headerName: getHeader({ item, category }),
        ...getWidth(item),
        ...getDef(item),
        columnGroupShow: 'open',
        editable: false,
      });
    }
  };

  setting?.forEach(item => {
    if (PULL_OUT_FIELDS?.includes?.(item)) {
      addToGroup({ groupName: 'po_g', item });
    } else if (DELIVERY_FIELDS?.includes?.(item)) {
      addToGroup({ groupName: 'dilv_g', item });
    } else if (PICK_UP_FIELDS?.includes?.(item)) {
      addToGroup({ groupName: 'pu_g', item });
    } else if (RETURN_FIELDS?.includes?.(item)) {
      addToGroup({ groupName: 'rtn_g', item });
    } else if (AR_FIELDS?.includes?.(item)) {
      addToGroup({ groupName: 'ar_g', item });
    } else if (AP_FIELDS?.includes?.(item)) {
      addToGroup({ groupName: 'ap_g', item });
    } else if (
      category === MAIN_CATEGORY_EXPORT &&
      DOC_FIELDS?.includes?.(item)
    ) {
      addToGroup({ groupName: 'doc_g_exp', item });
    } else if (DOC_FIELDS?.includes?.(item)) {
      addToGroup({ groupName: 'doc_g', item });
    } else {
      const def = {
        field: item,
        headerName: getHeader({ item, category }),
        ...getWidth(item),
        ...getDef(item),
        editable: false,
      };

      colWithChildren?.push(def);
    }
  });

  const returnColumnDefinition = colWithChildren?.filter(filter => filter);

  return returnColumnDefinition;
};

export default setResultColDef;
