import styled from 'styled-components';

const StyledBCDetailBillingInfoCommonFooterTotalHeader = styled.div`
  font-size: 11px;
  color: #222;
  font-weight: 500;
  width: 1011px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  background-color: ${props => props.backgroundColor};
  margin-right: 2px;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
`;

export default StyledBCDetailBillingInfoCommonFooterTotalHeader;
