import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import bcDetailDefaultAPI from '../../bcDetailAPI';

const getAPInput = async ({ woNo, scheduleSeq, driver }) => {
  try {
    const response = await bcDetailDefaultAPI?.get(
      `${woNo}/assign-driver/ap-input/${scheduleSeq}`,
      {
        params: {
          driver,
          page: 'dispatch',
        },
      },
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getAPInput;
