import React, { useRef, useCallback } from 'react';
import { Polygon } from '@react-google-maps/api';

/**
 * Component of the geofence.
 *
 * @param geofence
 * One of geofence data in the geofenceList.
 * @returns
 */
const Geofence = ({ geofence }) => {
  // It refers to the recently selected polygon.
  const polygonRef = useRef(null);

  /**
   * It runs when the polygon is edited
   * and set the new paths when the modification is finished.
   */
  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const newPaths = polygonRef.current
        .getPath()
        .getArray()
        .map(latLng => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      geofence.paths = newPaths;
    }
  }, [geofence]);

  /**
   * It runs when the polygon is loaded
   * and stores the path of the polygon.
   */
  const onLoad = useCallback(
    polygon => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      path.addListener('set_at', onEdit);
      path.addListener('insert_at', onEdit);
      path.addListener('remove_at', onEdit);
    },
    [onEdit],
  );

  return (
    <Polygon
      key={geofence.key}
      paths={geofence.paths}
      options={geofence.options}
      onLoad={onLoad}
      onDragEnd={onEdit}
    />
  );
};

export default Geofence;
