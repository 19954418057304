import React, { memo, useEffect, useState } from 'react';
import CommonMessageModal from '../../CommonMessageModal/CommonMessageModal';
import SavedListButton from './SavedListButton';
import useGetSavedList from '../../../hooks/CustomerReport/useGetSavedList';
import SavedListContent from './SavedListContent';

const SavedListModal = ({ ...props }) => {
  /** Get data from props */
  const { div, selectedList } = props;

  /** Data Setter */
  const { setSelectedList } = props;

  /** Modal Opener */
  const { open, setOpen } = props;

  /** Get Saved List Data */
  const { savedList } = useGetSavedList({ div });

  const [innerSelectedList, setInnerSelectedList] = useState(selectedList);

  return (
    <CommonMessageModal
      header="Saved List"
      open={open}
      setOpen={setOpen}
      content={
        <SavedListContent
          savedList={savedList}
          selectedList={innerSelectedList}
          setSelectedList={setInnerSelectedList}
        />
      }
      buttonContent={
        <SavedListButton
          setOpen={setOpen}
          selectedList={innerSelectedList}
          setSelectedList={setSelectedList}
        />
      }
    />
  );
};
export default SavedListModal;
