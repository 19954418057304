import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import reconstructColumnList from '../../../../utils/Dispatch/Common/Generator/reconstructColumnList';
import dispatchDefaultAPI from '../../api';

const putColumnOrder = async ({ patchData, isYard }) => {
  try {
    const url = `user-column/${patchData.category}/${patchData.status}${
      isYard ? '/yard' : ''
    }`;
    const response = await dispatchDefaultAPI.put(
      url,
      reconstructColumnList({ resultList: patchData.dsp_column }),
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default putColumnOrder;
