import { useEffect, useState } from 'react';
import checkIsDefaultYard from '../../../utils/Common/Handler/checkIsDefaultYard';

const useControlReturnYardQueries = ({ yardList, divList, filterStorage }) => {
  const { filter } = filterStorage;

  const defaultDiv = localStorage.getItem('defaultDiv');

  const [scheduleTypeQuery, setScheduleTypeQuery] = useState({
    DROP:
      typeof filter?.['sche-type']?.DROP === 'boolean'
        ? filter?.['sche-type']?.DROP
        : true,
    LIVE:
      typeof filter?.['sche-type']?.LIVE === 'boolean'
        ? filter?.['sche-type']?.LIVE
        : true,
  });

  const [divisionQuery, setDivisionQuery] = useState({
    ...Object.fromEntries(
      divList?.map(div => [
        div,
        typeof filter?.div?.[div] === 'boolean'
          ? filter?.div?.[div]
          : div === defaultDiv,
      ]),
    ),
  });

  const [categoryQuery, setCategoryQuery] = useState({
    WC0001:
      typeof filter?.category?.WC0001 === 'boolean'
        ? filter?.category?.WC0001
        : true,
    WC0003:
      typeof filter?.category?.WC0003 === 'boolean'
        ? filter?.category?.WC0003
        : true,
  });

  const [yardQuery, setYardQuery] = useState({});
  useEffect(() => {
    if (!_.isEmpty(yardList)) {
      setYardQuery(prev => {
        return {
          ...Object.fromEntries(
            yardList
              ?.map(yard => [
                yard,
                typeof filter?.['ngl-yard']?.[yard] === 'boolean'
                  ? filter?.['ngl-yard']?.[yard]
                  : checkIsDefaultYard({ yard, defaultDiv }),
              ])
              ?.filter(item => typeof item[0] === 'string'),
          ),
        };
      });
    }
  }, [yardList]);

  const [statusQuery, setStatusQuery] = useState({
    White:
      typeof filter?.['status-color']?.White === 'boolean'
        ? filter?.['status-color']?.White
        : true,
    Blue:
      typeof filter?.['status-color']?.Blue === 'boolean'
        ? filter?.['status-color']?.Blue
        : true,
    Green:
      typeof filter?.['status-color']?.Green === 'boolean'
        ? filter?.['status-color']?.Green
        : true,
    Yellow:
      typeof filter?.['status-color']?.Yellow === 'boolean'
        ? filter?.['status-color']?.Yellow
        : true,
  });

  const [allQueries, setAllQueries] = useState({});
  const [isStorageFilterExist, setIsStorageFilterExist] = useState(
    !!filterStorage?.filter,
  );

  useEffect(() => {
    // If allQueries is have key not {}
    if (
      filterStorage &&
      !isStorageFilterExist &&
      Object.keys(allQueries).length > 0
    ) {
      const { setFilter } = filterStorage;
      Object.keys(allQueries).forEach(key => {
        setFilter(prev => {
          return {
            ...prev,
            [key]: {
              ...allQueries[key],
            },
          };
        });
      });
      if (
        Object.keys(allQueries).includes('sche-type') &&
        Object.keys(allQueries).includes('category') &&
        Object.keys(allQueries).includes('div') &&
        Object.keys(allQueries).includes('status-color') &&
        Object.keys(allQueries).includes('ngl-yard')
      )
        setIsStorageFilterExist(prev => true);
    }
  }, [allQueries]);

  useEffect(() => {
    setAllQueries(prev => ({
      ...prev,
      'sche-type': { ...scheduleTypeQuery },
      div: { ...divisionQuery },
      category: { ...categoryQuery },
      'status-color': { ...statusQuery },
    }));
    if (!_.isEmpty(yardList)) {
      setAllQueries(prev => ({
        ...prev,
        'ngl-yard': { ...yardQuery },
      }));
    }
  }, [scheduleTypeQuery, divisionQuery, categoryQuery, yardQuery, statusQuery]);

  return {
    setScheduleTypeQuery,
    setDivisionQuery,
    setCategoryQuery,
    setYardQuery,
    setStatusQuery,
    allQueries,
  };
};

export default useControlReturnYardQueries;
