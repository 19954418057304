import React from 'react';

const ContainerInfoAddButton = ({
  containerKey,
  containerIdx,
  setContainerCount,
  setContainerKey,
  setContainerList,
  addTarget,
  ...props
}) => {
  return (
    <div
      className="w-[375px] min-w-[375px] h-[34px] min-h-[34px] bg-[#556da11a] rounded-[5px]
                    flex items-center
                    pl-[15px] cursor-pointer text-[#556DA1] text-[12px] font-bold"
      onClick={() => {
        setContainerCount(prev => prev + 1);
        setContainerKey(prev => prev + 1);
        setContainerList(prev => [
          ...prev,
          {
            containerKey: containerKey + 1,
            containerIdx: containerIdx + 1,
          },
        ]);
      }}
    >
      + Add {addTarget}
    </div>
  );
};

export default ContainerInfoAddButton;
