import React from 'react';
import { Box } from '@mui/material';
import { Route, Routes, Outlet, useLocation } from 'react-router-dom';
import MainLayOut from '../../components/Layout/MainLayOut';
import Job from './Job';
import { PAGE_TRACE } from '../../constants/Common/mapper';
import SubMenuNavigator from '../../components/Common/SubMenuNavigator';

const Trace = () => {
  const location = useLocation();
  const path = location.pathname;
  const pathParts = path.split('/');

  return (
    <MainLayOut>
      <SubMenuNavigator page={PAGE_TRACE} />
      <Box
        id="grid_top_container"
        sx={{
          width: '100%',
          minWidth: '1720px',
          height: '100vh',
          boxSizing: 'border-box',
          paddingLeft: '97px',
          paddingTop: '20px',
        }}
      >
        <Outlet />
      </Box>
    </MainLayOut>
  );
};

export default Trace;
