import { useQuery } from '@tanstack/react-query';
import get204Detail from '../../../services/EDI/Get/get204Detail';
import ediKeys from '../keys';

const useGet204Detail = ({ id }) => {
  const {
    isLoading: isLoading204Detail,
    data: detail204Data,
    isRefetching: isRefetching204Detail,
  } = useQuery({
    queryKey: ediKeys?.get204Detail({ id }),
    queryFn: () => get204Detail({ id }),
    refetchOnWindowFocus: false,
  });

  return {
    isLoading204Detail,
    detail204Data: detail204Data?.data,
    isRefetching204Detail,
  };
};

export default useGet204Detail;
