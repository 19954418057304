import React from 'react';
import AuthRouter from '../../../../../components/Common/AuthRouter';
import BillingInfo from '../../../../../pages/BCDetail/BillingInfo';
import DetailInfo from '../../../../../pages/BCDetail/DetailInfo';
import Document from '../../../../../pages/BCDetail/Document';
import Memo from '../../../../../pages/BCDetail/Memo';
import Tracking from '../../../../../pages/BCDetail/Tracking';
import {
  PERM_BC_DETAIL_AP,
  PERM_BC_DETAIL_AR,
  PERM_BC_DETAIL_BARE,
  PERM_BC_DETAIL_BROK,
  PERM_BC_DETAIL_EXP,
  PERM_BC_DETAIL_IMP,
  PERM_BC_DETAIL_VAN,
  PERM_BC_DETAIL_IMP_EDIT,
  PERM_BC_DETAIL_EXP_EDIT,
  PERM_BC_DETAIL_VAN_EDIT,
  PERM_BC_DETAIL_BROK_EDIT,
  PERM_BC_DETAIL_BARE_EDIT,
} from '../../../permission';
import checkPermission from '../../../../../utils/Common/Router/Handler/checkPermission';

const BC_DETAIL_CHILDREN_ROUTE = [
  // Detail Info
  {
    path: 'detail-info/import/:woNo',
    element: (
      <AuthRouter permission={PERM_BC_DETAIL_IMP}>
        <DetailInfo />
      </AuthRouter>
    ),
  },
  {
    path: 'detail-info/export/:woNo',
    element: (
      <AuthRouter permission={PERM_BC_DETAIL_EXP}>
        <DetailInfo />
      </AuthRouter>
    ),
  },
  {
    path: 'detail-info/van/:woNo',
    element: (
      <AuthRouter permission={PERM_BC_DETAIL_VAN}>
        <DetailInfo />
      </AuthRouter>
    ),
  },
  {
    path: 'detail-info/brokerage/:woNo',
    element: (
      <AuthRouter permission={PERM_BC_DETAIL_BROK}>
        <DetailInfo />
      </AuthRouter>
    ),
  },
  {
    path: 'detail-info/bare-chassis/:woNo',
    element: (
      <AuthRouter permission={PERM_BC_DETAIL_BARE}>
        <DetailInfo />
      </AuthRouter>
    ),
  },
  // Billing Info
  {
    path: 'billing-info/import/:woNo',
    element: (
      <AuthRouter permission={[...PERM_BC_DETAIL_AP, ...PERM_BC_DETAIL_AR]}>
        <BillingInfo />
      </AuthRouter>
    ),
  },
  {
    path: 'billing-info/export/:woNo',
    element: (
      <AuthRouter permission={PERM_BC_DETAIL_EXP}>
        <BillingInfo />
      </AuthRouter>
    ),
  },
  {
    path: 'billing-info/van/:woNo',
    element: (
      <AuthRouter permission={PERM_BC_DETAIL_VAN}>
        <BillingInfo />
      </AuthRouter>
    ),
  },
  {
    path: 'billing-info/brokerage/:woNo',
    element: (
      <AuthRouter permission={PERM_BC_DETAIL_BROK}>
        <BillingInfo />
      </AuthRouter>
    ),
  },
  {
    path: 'billing-info/bare-chassis/:woNo',
    element: (
      <AuthRouter permission={PERM_BC_DETAIL_BARE}>
        <BillingInfo />
      </AuthRouter>
    ),
  },
  // Document
  {
    path: 'document/import/:woNo',
    element: (
      <AuthRouter permission={PERM_BC_DETAIL_IMP}>
        <Document
          isEditable={checkPermission({ permission: PERM_BC_DETAIL_IMP_EDIT })}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'document/export/:woNo',
    element: (
      <AuthRouter permission={PERM_BC_DETAIL_EXP}>
        <Document
          isEditable={checkPermission({ permission: PERM_BC_DETAIL_EXP_EDIT })}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'document/van/:woNo',
    element: (
      <AuthRouter permission={PERM_BC_DETAIL_VAN}>
        <Document
          isEditable={checkPermission({ permission: PERM_BC_DETAIL_VAN_EDIT })}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'document/brokerage/:woNo',
    element: (
      <AuthRouter permission={PERM_BC_DETAIL_BROK}>
        <Document
          isEditable={checkPermission({ permission: PERM_BC_DETAIL_BROK_EDIT })}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'document/bare-chassis/:woNo',
    element: (
      <AuthRouter permission={PERM_BC_DETAIL_BARE}>
        <Document
          isEditable={checkPermission({ permission: PERM_BC_DETAIL_BARE_EDIT })}
        />
      </AuthRouter>
    ),
  },
  // Memo
  {
    path: 'memo/import/:woNo',
    element: (
      <AuthRouter permission={PERM_BC_DETAIL_IMP}>
        <Memo
          isEditable={checkPermission({ permission: PERM_BC_DETAIL_IMP_EDIT })}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'memo/export/:woNo',
    element: (
      <AuthRouter permission={PERM_BC_DETAIL_EXP}>
        <Memo
          isEditable={checkPermission({ permission: PERM_BC_DETAIL_EXP_EDIT })}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'memo/van/:woNo',
    element: (
      <AuthRouter permission={PERM_BC_DETAIL_VAN}>
        <Memo
          isEditable={checkPermission({ permission: PERM_BC_DETAIL_VAN_EDIT })}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'memo/brokerage/:woNo',
    element: (
      <AuthRouter permission={PERM_BC_DETAIL_BROK}>
        <Memo
          isEditable={checkPermission({ permission: PERM_BC_DETAIL_BROK_EDIT })}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'memo/bare-chassis/:woNo',
    element: (
      <AuthRouter permission={PERM_BC_DETAIL_BARE}>
        <Memo
          isEditable={checkPermission({ permission: PERM_BC_DETAIL_BARE_EDIT })}
        />
      </AuthRouter>
    ),
  },
  // Tracking
  {
    path: 'tracking/import/:woNo',
    element: (
      <AuthRouter permission={PERM_BC_DETAIL_IMP}>
        <Tracking />
      </AuthRouter>
    ),
  },
  {
    path: 'tracking/export/:woNo',
    element: (
      <AuthRouter permission={PERM_BC_DETAIL_EXP}>
        <Tracking />
      </AuthRouter>
    ),
  },
  {
    path: 'tracking/van/:woNo',
    element: (
      <AuthRouter permission={PERM_BC_DETAIL_VAN}>
        <Tracking />
      </AuthRouter>
    ),
  },
  {
    path: 'tracking/brokerage/:woNo',
    element: (
      <AuthRouter permission={PERM_BC_DETAIL_BROK}>
        <Tracking />
      </AuthRouter>
    ),
  },
  {
    path: 'tracking/bare-chassis/:woNo',
    element: (
      <AuthRouter permission={PERM_BC_DETAIL_BARE}>
        <Tracking />
      </AuthRouter>
    ),
  },
];
export default BC_DETAIL_CHILDREN_ROUTE;
