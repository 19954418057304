import React from 'react';
import { useFormContext } from 'react-hook-form';
import CommonTextInput from '../../../Inputs/CommonTextInput/CommonTextInput';
import CommonSelect from '../../../Inputs/CommonSelect/CommonSelect';
import CommonCheckbox from '../../../Inputs/CommonCheckbox/CommonCheckbox';
import { STATUS_VALUE } from '../../../../constants/Admin/User/standardCodes';
import useControlAuthValue from '../../../../hooks/Admin/User/Control/useControlAuthValue';
import useControlDivisionOptions from '../../../../hooks/Admin/User/Control/useControlDivisionOptions';
import {
  changeAuth,
  changeDivisions,
  changeSelect,
  changeText,
} from '../../../../utils/Admin/User/Handler/changedInputFieldHandler';

const SideModalContent = ({ userInfo, divList, authList }) => {
  const { setValue, watch, control, getValues } = useFormContext();

  const isWorkingUser = userInfo?.tms_status === STATUS_VALUE?.WORKING;

  const {
    authOptionList,
    firstPageOptionList,
    setSelectedAuth,
    setIsChangeAuth,
  } = useControlAuthValue({
    isWorkingUser,
    authList,
    watch,
    userInfo,
    setValue,
  });

  const {
    setIsChangeDivision,
    divCheckboxOption,
    defaultDivOptionList,
    selectedDivList,
    setSelectedDivList,
  } = useControlDivisionOptions({
    getValues,
    setValue,
    divList,
    userInfo,
    control,
  });

  return (
    <div className="gap-y-[9px]">
      <div className="w-[302px]">
        <CommonTextInput
          inputName="userID"
          label="USER ID"
          disabled
          defaultValue={userInfo?.username}
          onChangeHandler={changeText}
        />
      </div>
      <div className="flex gap-x-[20px]">
        <div className="w-[141px]">
          <CommonTextInput
            inputName="firstName"
            label="FIRST NAME"
            disabled
            defaultValue={userInfo?.first_name}
            onChangeHandler={changeText}
          />
        </div>
        <div className="w-[141px]">
          <CommonTextInput
            inputName="lastName"
            label="LAST NAME"
            disabled
            defaultValue={userInfo?.last_name}
            onChangeHandler={changeText}
          />
        </div>
      </div>
      <div className="w-[302px]">
        <CommonTextInput
          inputName="email"
          label="EMAIL"
          disabled
          defaultValue={userInfo?.email}
          onChangeHandler={changeText}
        />
      </div>
      <div className="flex w-full justify-start gap-x-[20px]">
        <div className="w-[141px]">
          <CommonSelect
            label="AUTH"
            required={isWorkingUser}
            defaultValue={isWorkingUser ? userInfo?.group_name : ''}
            option={authOptionList}
            inputName="group_name"
            onChangeHandler={({ e, ...props }) => {
              changeAuth({
                e,
                authList,
                setSelectedAuth,
                setIsChangeAuth,
                ...props,
              });
            }}
          />
        </div>
        <div className="w-[141px]">
          <CommonSelect
            inputName="first_page"
            label="FIRST PAGE"
            defaultValue={watch('group_name') ? userInfo?.first_page : ''}
            disabled={!watch('group_name')}
            required={watch('group_name')}
            option={firstPageOptionList}
            onChangeHandler={changeSelect}
          />
        </div>
      </div>
      <div className="w-[302px]">
        <CommonCheckbox
          inputName="checkbox"
          label="DIV"
          borderVisible
          options={divCheckboxOption}
          onChangeHandler={({ e, ...props }) => {
            changeDivisions({
              e,
              divList,
              selectedDivList,
              setIsChangeDivision,
              setValue,
              setSelectedDivList,
              ...props,
            });
          }}
        />
      </div>
      <div className="w-[141px]">
        <CommonSelect
          label="DEFAULT DIV"
          defaultValue={userInfo?.userdivision_set?.default_div}
          option={defaultDivOptionList}
          required
          inputName="default_div"
          onChangeHandler={changeSelect}
        />
      </div>
    </div>
  );
};

export default SideModalContent;
