import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import bcDetailDefaultApi from '../../api';

const getTrackingList = async ({ woNo }) => {
  try {
    const response = await bcDetailDefaultApi.get(`${woNo}/tracking-detail`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getTrackingList;
