import dayjs from 'dayjs';

const handleScanDOResult = ({
  result,
  setValue,
  getValues,
  reset,
  containerCount,
  containerKey,
  setContainerCount,
  setContainerKey,
  setContainerList,
  companyOption,
  variables,
}) => {
  const doFile = getValues('do');
  /** Get company option */
  const { billTo: companyBillTo, other: companyOther } = companyOption;

  // Reset Order form and adjust form
  reset?.();
  setContainerCount(1);
  setContainerList?.([
    {
      containerKey: 1,
      containerIdx: 1,
    },
  ]);

  // Create empty container form
  setContainerCount(prev => result?.equips?.length);
  let getCurrContainerKey = containerKey;
  setContainerKey(prev => prev + result?.equips?.length);
  if (result?.equips?.length > 0) {
    setContainerList?.(prev => {
      const tempContainerList = [
        ...result?.equips?.map((_, idx) => ({
          containerKey: getCurrContainerKey++,
          containerIdx: idx + 2,
        })),
      ];
      return tempContainerList;
    });
  }

  // Get Scanned item
  const {
    billto: billTo,
    dilv_date: dilvDate,
    dilv_loc: dilvLoc,
    dilv_no: dilvNo,
    dilv_time: dilvTime,
    equips,
    eta,
    freedays,
    hbl_no: hblNo,
    mbl_no: mblNo,
    po_date: poDate,
    po_loc: poLoc,
    po_time: poTime,
    purchase_no: purchaseNo,
    ref_no: refNo,
    rtn_date: rtnDate,
    rtn_loc: rtnLoc,
    rtn_time: rtnTime,
    sendto,
    ssl,
  } = result;

  // Set Scanned item to Order form
  setValue('do_file_name', doFile?.name);
  setValue('do', doFile);
  setValue('billto', billTo);
  setValue(
    'dilv_date',
    dilvDate && dayjs(dilvDate)?.isValid() ? dayjs(dilvDate) : null,
  );
  setValue(
    'dilv_loc',
    companyOther?.find(element => element?.value === dilvLoc) ? dilvLoc : '',
  );
  setValue('dilv_no', dilvNo);
  setValue(
    'dilv_time',
    dilvTime && dayjs(dilvTime)?.isValid() ? dayjs(dilvTime) : null,
  );
  setValue('eta', eta && dayjs(eta)?.isValid() ? dayjs(eta) : null);
  setValue('freedays', freedays);
  setValue('hbl_no', hblNo);
  setValue('mbl_no', mblNo);
  setValue(
    'po_date',
    poDate && dayjs(poDate)?.isValid() ? dayjs(poDate) : null,
  );
  setValue(
    'po_loc',
    companyOther?.find(element => element?.value === poLoc) ? poLoc : '',
  );
  setValue(
    'po_time',
    poTime && dayjs(poTime)?.isValid() ? dayjs(poTime) : null,
  );
  setValue('purchase_no', purchaseNo);
  setValue('ref_no', refNo);
  setValue(
    'rtn_date',
    rtnDate && dayjs(rtnDate)?.isValid() ? dayjs(rtnDate) : null,
  );
  setValue(
    'rtn_loc',
    companyOther?.find(element => element?.value === rtnLoc) ? rtnLoc : '',
  );
  setValue(
    'rtn_time',
    rtnTime && dayjs(rtnTime)?.isValid() ? dayjs(rtnTime) : null,
  );
  setValue('ssl', ssl);

  // Set Send To Value
  // setValue('sendto', sendto?.join(','));

  let initKeyValue = getCurrContainerKey;
  equips?.forEach((eachEquip, idx) => {
    setValue(`size_${initKeyValue}`, eachEquip?.size);
    setValue(`type_${initKeyValue}`, eachEquip?.type);
    setValue(`cont_no_${initKeyValue}`, eachEquip?.cont_no);
    setValue(`trailer_no_${initKeyValue}`, eachEquip?.trailer_no);
    setValue(`ch_no_${initKeyValue}`, eachEquip?.ch_no);
    setValue(`pool_${initKeyValue}`, eachEquip?.pool);
    setValue(`seal_${initKeyValue}`, eachEquip?.seal);
    setValue(`commodity_${initKeyValue}`, eachEquip?.commodity);
    setValue(`wgt_${initKeyValue}`, eachEquip?.wgt);
    setValue(`qty_${initKeyValue}`, eachEquip?.qty);
    setValue(`unit_${initKeyValue}`, eachEquip?.unit);
    initKeyValue++;
  });
};

export default handleScanDOResult;
