import ocrDefaultApi from '../../../Order/ocrAPI';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

const postScanPerdiem = async ({ file }) => {
  try {
    const response = await ocrDefaultApi.post('perdiem', { file });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default postScanPerdiem;
