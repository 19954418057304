export const generateDivisionCheckboxOption = ({ divList }) => {
  const divisionCheckboxOption = divList
    ?.map(div => div?.div)
    ?.map(div => {
      return {
        optionLabel: div,
        inputName: div,
      };
    });

  divisionCheckboxOption?.unshift({
    optionLabel: 'ALL',
    inputName: 'ALL',
  });

  return divisionCheckboxOption;
};

export const generateFirstPageOption = ({ selectedAuth }) => {
  const firstPageOption = [];

  if (selectedAuth?.has_imp_perm) {
    firstPageOption.push({ label: 'Import', value: 'IMP' });
  }
  if (selectedAuth?.has_exp_perm) {
    firstPageOption.push({ label: 'Export', value: 'EXP' });
  }
  if (selectedAuth?.has_van_perm) {
    firstPageOption.push({ label: 'Van', value: 'VAN' });
  }
  if (selectedAuth?.has_brok_perm) {
    firstPageOption.push({ label: 'Brokerage', value: 'BROK' });
  }
  if (selectedAuth?.has_bare_perm) {
    firstPageOption.push({ label: 'Bare Chassis', value: 'BARE' });
  }

  return firstPageOption;
};
