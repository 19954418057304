import { useQuery } from '@tanstack/react-query';
import tmsReportKeys from '../../keys';
import getEarlyWarningList from '../../../../services/TmsReport/EarlyWarning/Get/getEarlyWarningList';
import generateQueryParams from '../../../../utils/TmsReport/EarlyWarning/Generator/generateQueryParams';

const useGetEarlyWarningList = ({ queryObject }) => {
  const params = generateQueryParams({ queryObject });

  const {
    data: earlyWarningList,
    isLoading,
    isFetching,
    refetch: refetchEarlyWarningList,
  } = useQuery({
    queryKey:
      params?.order === 'time'
        ? tmsReportKeys.earlyWarningList({ queryObject: params })
        : tmsReportKeys.earlyWarningModalList({ queryObject: params }),
    queryFn: () => getEarlyWarningList({ queryObject: params }),
    enabled: !!queryObject?.div,
    staleTime: 0,
    refetchOnWindowFocus: false,
  });

  return {
    earlyWarningList,
    isLoading,
    isFetching,
    refetchEarlyWarningList,
  };
};

export default useGetEarlyWarningList;
