import { UPDATE_TYPE } from '../../../../../constants/BCDetail/Common/type';
import useUpdateDetailInfo from '../../../../../hooks/BCDetail/DetailInfo/BasicInfo/useUpdateDetailInfo';
import { getFormattedDateGenerator } from '../../../../Common/Generator/formattedDateTimeGenerator';
import updateButtonDisabled from '../../../../Common/Handler/updateButtonDisabled';
import generateUpdatedSchedule from '../../Schedule/Generator/generateUpdatedSchedule';

const handleDetailInfoUpdate = async ({
  woNo,
  basicInfoData,
  scheduleData,
  detailInfoData,
  updateDetailInfo,
  buttonRef,
  isFormInvalid,
  ...props
}) => {
  const {
    updateType,
    // For assign driver
    basicInfoFormController,
    driverListModalOpener,
    setSelectedScheduleSeq,
    scheduleSeq,
    // For delete driver
    setSelectedRowData,
    jobData,
    setDeleteDriverModalOpener,
    // For Add, Delete schedule
    setAddModalOpener,
    selectedScheduleData,
    groupIdx,
    rowIdx,
    scheduleType,
    setDeleteModalOpener,
  } = props;

  const { handleSubmit: handleBasicInfoSubmit } = basicInfoFormController;

  if (isFormInvalid) {
    updateButtonDisabled({ ref: buttonRef, disabled: false });
  }

  await handleBasicInfoSubmit(data => {
    /** Basic info data */
    const updatedBasicInfoData = useUpdateDetailInfo({
      detailInfoData,
      basicInfoData,
      buttonRef,
      ...props,
    });

    /** Schedule data */
    const updatedScheduleData = generateUpdatedSchedule({
      scheduleControllerData: scheduleData,
      detailInfoData,
      setModalOpener: props?.setErrorModalOpen,
      setModalMessage: props?.setMessage,
      buttonRef,
      ...props,
    });

    /** Trigger update */
    if (updatedBasicInfoData !== null && updatedScheduleData) {
      /** Manipulate Update data */
      const manipulatedUpdateData = {
        ...updatedBasicInfoData,
        schedule: [...updatedScheduleData],
        empty_load_cfm: getFormattedDateGenerator({
          date: scheduleData?.empty_load_cfm,
        }),
      };

      if (updateType === UPDATE_TYPE.ASSIGN_DRIVER) {
        driverListModalOpener?.(prev => true);
      }
      setSelectedScheduleSeq?.(prev => scheduleSeq);
      updateDetailInfo.mutate(
        {
          woNo,
          data: manipulatedUpdateData,
          buttonRef,
        },
        {
          onSuccess: () => {
            // For delete driver
            if (updateType === UPDATE_TYPE.DELETE_DRIVER) {
              setSelectedRowData?.({
                ...jobData,
              });
              setDeleteDriverModalOpener?.(prev => true);
            }
            // For Add schedule
            if (updateType === UPDATE_TYPE.ADD_SCHEDULE) {
              setAddModalOpener?.(prev => true);
              setSelectedRowData?.({
                ...selectedScheduleData,
                groupIdx,
                rowIdx,
                scheduleType,
              });
            }
            // For Delete schedule
            if (updateType === UPDATE_TYPE.DELETE_SCHEDULE) {
              setSelectedScheduleSeq?.(selectedScheduleData?.seq);
              setDeleteModalOpener?.(prev => true);
            }
          },
          onError: () => {
            // For assign driver
            if (updateType === UPDATE_TYPE.ASSIGN_DRIVER) {
              driverListModalOpener?.(prev => false);
              setSelectedScheduleSeq?.(prev => null);
            }
          },
        },
      );
    }
  })();
};

export default handleDetailInfoUpdate;
