import React, { useEffect, useRef, useState } from 'react';
import { ADMIN_CONF_COLUMN_DEFINITION_VALUES } from '../../../../constants/Admin/Conf/keyValues';
import { GRID_MOD_EDIT } from '../../../../constants/Common/DataGrid/setting';
import useGetConfList from '../../../../hooks/Admin/Conf/useGetConfList';
import usePatchConfActivate from '../../../../hooks/Admin/Conf/usePatchConfActivate';
import usePatchConfData from '../../../../hooks/Admin/Conf/usePatchConfData';
import usePostConfData from '../../../../hooks/Admin/Conf/usePostConfData';
import defaultColumnDefsGenerator from '../../../../utils/Common/Datagrid/Generator/defaultColumnDefsGenerator';
import CommonDataGrid from '../../../CommonDataGrid';
import AccordionBox from './AccordionBox';
import SearchArea from './SearchArea';
import createDefaultRowSetting from '../../../../utils/Admin/Conf/Generator/createDefaultRowSetting';

const AccordionDataGrid = ({ title, setIsLoading, setIsError, ...props }) => {
  const gridRef = useRef(null);
  const disabledRef = useRef({ disabled: false, disabledRow: null });

  const [defaultRowSetting, setDefaultRowSetting] = useState({});

  useEffect(() => {
    setDefaultRowSetting(createDefaultRowSetting?.({ title }));
  }, [title]);

  const [active, setActive] = useState('True');

  const { data: confData } = useGetConfList({
    title,
    active,
    setIsLoading,
    setIsError,
  });
  const addRowMutator = usePostConfData({
    title,
    gridRef,
    ...props,
  });
  const patchRowMutator = usePatchConfData({ gridRef, ...props });
  const patchActivateMutator = usePatchConfActivate({
    active,
    disabledRef,
    ...props,
  });

  const defaultColDef = {
    ...defaultColumnDefsGenerator(),
    floatingFilter: false,
  };

  const [columnDefs, setColumnDefs] = useState([]);
  useEffect(() => {
    setColumnDefs(
      ADMIN_CONF_COLUMN_DEFINITION_VALUES?.[title]?.({
        title,
        patchActivateMutator,
        active,
        disabledRef,
        ...props,
      }),
    );
  }, [title, active]);

  const handleRowSave = ({ gridParam }) => {
    patchRowMutator.mutate({
      title,
      seq: gridParam?.data?.seq,
      data: gridParam?.data,
    });
  };

  const handleRowAdd = ({ gridParam, setAddDisabled }) => {
    setAddDisabled?.(true);
    addRowMutator.mutate({
      title,
      data: gridParam?.data,
      setAddDisabled,
    });
  };

  return (
    <AccordionBox title={title}>
      <div className="content">
        <SearchArea setActive={setActive} active={active} />
        <div className={`w-full ${title !== 'division' && 'h-[327px]'}`}>
          <CommonDataGrid
            gridRef={gridRef}
            data={confData?.data}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            addable
            modType={GRID_MOD_EDIT}
            onAddClicked={handleRowAdd}
            onSaveClicked={handleRowSave}
            {...defaultRowSetting}
            {...props}
          />
        </div>
      </div>
    </AccordionBox>
  );
};

export default AccordionDataGrid;
