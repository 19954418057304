import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import { Provider } from 'react-redux';
import axios from 'axios';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import store from './redux/store';
import ROUTER from './constants/Common/Router';
import Mobile from './pages/Mobile';
import checkIsMobile from './utils/Common/Handler/checkIsMobile';

axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'XCSRF-TOKEN';

axios.defaults.headers.common['X-CSRFToken'] =
  localStorage.getItem('csrfToken');

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();

// if (checkIsMobile()) {
//   root.render(<Mobile />);
// } else {
root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      {/* <React.StrictMode> */}
      {/* <App /> */}
      <RouterProvider router={ROUTER} />
    </QueryClientProvider>
    {/* </React.StrictMode> */}
  </Provider>,
);
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
