import {
  CELL_TYPE_SWITCH,
  CELL_TYPE_TEXT,
} from '../../../../constants/Common/DataGrid/cellType';
import { REGEX_NUMBER_ONLY_POSITIVE_DECIMAL } from '../../../../constants/Common/regex';
import cellEditorGenerator from '../../../Common/Datagrid/Generator/cellEditorGenerator';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';
import { makePrettyPhoneNumber } from '../../../Common/Handler/phoneNumberHandler';
import handleActiveSwitchClick from '../Handler/handleActiveSwitchClick';
import handleZIPChange from '../Handler/handleZIPChange';

const createDIVColumnDefs = ({
  disabledRef,
  patchActivateMutator,
  title,
  active,
  ...modalProps
}) => {
  return [
    {
      headerName: 'ZIP CODE',
      field: 'zip',
      width: 130,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          maxLength: 5,
          regex: REGEX_NUMBER_ONLY_POSITIVE_DECIMAL,
          onChangeHandler: ({ ...props }) =>
            handleZIPChange({ ...props, ...modalProps }),
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          maxLength: 5,
          regex: REGEX_NUMBER_ONLY_POSITIVE_DECIMAL,
          onChangeHandler: ({ ...props }) =>
            handleZIPChange({ ...props, ...modalProps }),
        }),
    },
    {
      headerName: 'TIME ZONE',
      field: 'timezone',
      width: 190,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          disabled: true,
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          regex: gridParam?.data?.timezone,
          disabled: true,
        }),
    },
    {
      headerName: 'STATE',
      field: 'state',
      width: 120,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          disabled: true,
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          maxLength: 2,
          disabled: true,
        }),
    },
    {
      headerName: 'DIV',
      field: 'div_name',
      width: 150,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          maxLength: 10,
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          maxLength: 10,
        }),
    },
    {
      headerName: 'ABBR',
      field: 'div',
      width: 130,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          maxLength: 3,
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          maxLength: 3,
        }),
    },
    {
      headerName: 'WO RULE',
      field: 'wo_rule',
      width: 140,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          maxLength: 2,
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          maxLength: 2,
        }),
    },
    {
      headerName: 'EMAIL',
      field: 'email',
      width: 210,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          maxLength: 100,
        }),
      editable: false,
    },
    {
      headerName: 'PHONE',
      field: 'phone',
      width: 170,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          placeHolder: '+1-000 0123456789',
        }),
      valueGetter: gridParam =>
        makePrettyPhoneNumber({ phone: gridParam?.data?.phone }),
      editable: false,
    },
    {
      headerName: '',
      field: 'active',
      width: 100,
      sortable: false,
      resizable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_SWITCH,
          onChangeHandler: ({ ...props }) => {
            handleActiveSwitchClick({
              disabledRef,
              title,
              gridParam,
              mutator: patchActivateMutator,
              active,
              ...props,
            });
          },
          value: gridParam?.node?.rowPinned ? true : gridParam?.data?.active,
        }),
      editable: false,
    },
  ];
};

export default createDIVColumnDefs;
