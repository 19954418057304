import dayjs from 'dayjs';
import { RECONCILE_STATUS } from '../../../../constants/Acct/Common/acctElement';

export const handleStatusValue = ({ data }) => {
  return RECONCILE_STATUS?.find(status => status?.label === data)?.value;
};

export const handlePoolValue = ({ data, poolList }) => {
  return poolList?.find(pool => pool?.label === data)?.value;
};

export const handleDateValue = ({ data }) => {
  const value =
    data && dayjs(data)?.isValid() ? dayjs(data)?.format('YYYY-MM-DD') : null;
  return value;
};

export const handleSSLValue = ({ data, sslList }) => {
  const value = sslList?.find(ssl => ssl?.label === data)?.value;
  return value;
};
