import React from 'react';

const CustomColumnHalfSection = ({ title, content, ...props }) => {
  return (
    <div className="w-1/2">
      <div className="h-[350px] w-full pt-[21px] pl-[30px]">
        <div className="text-[12px] w-full h-[14px] font-[700] leading-[14px] mb-[15px]">
          {title}
        </div>
        <div className="w-full h-[350px] overflow-y-auto">{content}</div>
      </div>
    </div>
  );
};

export default CustomColumnHalfSection;
