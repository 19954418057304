import styled from 'styled-components';

const StyledCommonSideModalWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

export default StyledCommonSideModalWrapper;
