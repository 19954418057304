import React from 'react';
import dayjs from 'dayjs';
import CommonTextInput from '../../../SecondInputs/CommonTextInput/CommonTextInput';
import CommonDate from '../../../SecondInputs/CommonDate/CommonDate';
import CommonTime from '../../../SecondInputs/CommonTime/CommonTime';

const Return = ({
  basicInfoData,
  rtnScheduleDate,
  rtnScheduleTime,
  rtnActualInDate,
  rtnActualInTime,
  rtnActualOutDate,
  rtnActualOutTime,
  rtnDriver,
}) => {
  return (
    <div className="flex mt-[2px]">
      <div className="flex pl-[11px] items-center w-[72px] h-[30px] text-blue-800 text-[11px] font-bold shadow">
        RTN
      </div>
      <div className="ml-[2px] shadow">
        <CommonTextInput
          inputName="rtnLocationID"
          defaultValue={basicInfoData?.rtn_location?.company}
          width="w-[128px]"
          textCenter
          disabled
        />
      </div>
      <div className="ml-[2px] shadow">
        <CommonDate
          inputName="rtnScheduleDate"
          defaultValue={rtnScheduleDate}
          width="w-[140px]"
          textCenter
          disabled
        />
      </div>
      <div className="ml-[2px] shadow">
        <CommonTime
          inputName="rtnScheduleTime"
          defaultValue={
            rtnScheduleDate &&
            rtnScheduleTime &&
            dayjs(`${rtnScheduleDate} ${rtnScheduleTime}`)?.isValid()
              ? dayjs(`${rtnScheduleDate} ${rtnScheduleTime}`)
              : null
          }
          width="w-[110px]"
          textCenter
          disabled
        />
      </div>
      <div className="ml-[2px] shadow">
        <CommonDate
          inputName="rtnActualInDate"
          defaultValue={rtnActualInDate}
          width="w-[140px]"
          textCenter
          disabled
        />
      </div>
      <div className="ml-[2px] shadow">
        <CommonTime
          inputName="rtnActualInTime"
          defaultValue={
            rtnActualInDate &&
            rtnActualInTime &&
            dayjs(`${rtnActualInDate} ${rtnActualInTime}`)?.isValid()
              ? dayjs(`${rtnActualInDate} ${rtnActualInTime}`)
              : null
          }
          width="w-[110px]"
          textCenter
          disabled
        />
      </div>
      <div className="ml-[2px] shadow">
        <CommonDate
          inputName="rtnActualOutDate"
          defaultValue={rtnActualOutDate}
          width="w-[140px]"
          textCenter
          disabled
        />
      </div>
      <div className="ml-[2px] shadow">
        <CommonTime
          inputName="rtnActualOutTime"
          defaultValue={
            rtnActualOutDate &&
            rtnActualOutTime &&
            dayjs(`${rtnActualOutDate} ${rtnActualOutTime}`)?.isValid()
              ? dayjs(`${rtnActualOutDate} ${rtnActualOutTime}`)
              : null
          }
          width="w-[110px]"
          textCenter
          disabled
        />
      </div>
      <div className="ml-[2px] shadow">
        <CommonTextInput
          inputName="rtnDrvVen"
          defaultValue={rtnDriver}
          width="w-[116px]"
          textCenter
          disabled
        />
      </div>
    </div>
  );
};

export default Return;
