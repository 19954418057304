import { useMutation } from '@tanstack/react-query';
import postSaved from '../../services/CustomerReport/Post/postSaved';
import useControlToast from '../Common/Toast/useControlToast';
import updateButtonDisabled from '../../utils/Common/Handler/updateButtonDisabled';

const usePostSaved = ({ ...props }) => {
  const { addToast } = useControlToast();

  /** Data Setter */
  const { setIsSuccess, setModalMessage, setIsSaveEnable } = props;

  /** Modal Opener */
  const { setMessageOpener } = props;

  const mutator = useMutation({
    mutationFn: postSaved,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: () => {
      addToast({ message: 'Available list name.' });
      setIsSaveEnable(true);
    },
    onError: error => {
      setIsSuccess(false);
      setModalMessage(error?.message);
      setMessageOpener(true);
    },
  });

  return { ...mutator };
};

export default usePostSaved;
