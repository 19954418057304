import commonDefaultApi from '../api';

const getAutoCompleteAddr = async addr => {
  try {
    const response = await commonDefaultApi.get(`addr-validator/${addr}`);
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};

export default getAutoCompleteAddr;
