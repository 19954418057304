import { useQuery } from '@tanstack/react-query';
import { useState, useEffect } from 'react';
import adminKeys from '../../keys';
import getUser from '../../../../services/Admin/User/Get/getUser';

const useGetUser = ({
  id,
  setIsOpenErrorModal,
  setErrorMessage,
  setIsSuccess,
}) => {
  const { data, isError, error, isLoading } = useQuery({
    queryKey: adminKeys.user({ id }),
    queryFn: () => getUser({ id }),
    staleTime: 0,
    enabled: !!id,
    refetchOnWindowFocus: false,
  });

  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    setUserInfo(data?.data);
  }, [data?.data]);

  useEffect(() => {
    if (isError) {
      setIsOpenErrorModal(true);
      setErrorMessage(error?.message);
      setIsSuccess(false);
    }
  }, [isError]);

  return { userInfo, isLoading };
};

export default useGetUser;
