const createPageType = ({ type }) => {
  if (type === 'M') return 'import';
  if (type === 'X') return 'export';
  if (type === 'V') return 'van';
  if (type === 'B') return 'brokerage';
  if (type === 'C') return 'bare-chassis';
  return 'import';
};

export default createPageType;
