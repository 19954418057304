import dayjs from 'dayjs';

export const checkIsDateForward = ({ from, to }) => {
  if (from && to && dayjs(from).isValid() && dayjs(to).isValid()) {
    return dayjs(from).isBefore(to) || dayjs(from).isSame(to);
  }
  return true;
};

export const checkIsDateWithinOneMonth = ({ from, to }) => {
  if (from && to && dayjs(from).isValid() && dayjs(to).isValid()) {
    return (
      dayjs(to).diff(from, 'month', true) <= 1 &&
      dayjs(to).diff(from, 'month', true) >= 0
    );
  }
  return true;
};

export const checkIsDateValid = ({ from, to, modalController }) => {
  const { setFilterModalStatus } = modalController;

  if (!checkIsDateForward({ from, to })) {
    setFilterModalStatus({
      message: `The date range you entered is invalid. The 'To date' must be later than the 'From date'. Please enter the dates again.`,
      isSuccess: false,
      isOpen: true,
    });
    return false;
  }

  if (!checkIsDateWithinOneMonth({ from, to })) {
    setFilterModalStatus({
      message: `The date range you entered is invalid. The date range must be within 1 month. Please enter the dates again.`,
      isSuccess: false,
      isOpen: true,
    });
    return false;
  }
  return true;
};
