import { DISPATCH_CATEGORY_COLUMNS } from '../../../../constants/Dispatch/Common/columnList';
import FIELD_HEADER from '../../../../constants/Dispatch/Common/fieldsHeader';
import { DISPATCH_CATEGORY_THEME } from '../../../../constants/Dispatch/Common/themeValues';
import convertStatus from './convertStatus';

const generateColumnItems = status => {
  const category = convertStatus({ status });
  const gridCols = DISPATCH_CATEGORY_COLUMNS?.[category]?.[status];

  const columnItems = gridCols?.map((field, index) => {
    if (field instanceof Object) {
      return {
        id: index,
        label: field?.header,
        value: field?.value,
        theme: DISPATCH_CATEGORY_THEME?.[category]?.[field?.theme],
        children: field?.children.map((childField, childIndex) => {
          if (category === 'brokerage' && childField === 'trailer_no') {
            return {
              id: childIndex,
              label: FIELD_HEADER?.equipment_no,
              value: 'trailer_no',
              checked: false,
            };
          }
          return {
            id: childIndex,
            label: FIELD_HEADER?.[childField],
            value: childField,
            checked: false,
          };
        }),
      };
    }

    return {
      id: index,
      label: FIELD_HEADER?.[field],
      value: field,
    };
  });

  return columnItems;
};

export default generateColumnItems;
