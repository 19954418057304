import { useMutation } from '@tanstack/react-query';
import patchChassisReconcile from '../../../../services/Acct/ChassisReconcile/Patch/patchChassisReconcile';
import { deleteForm } from '../../../../utils/Acct/Common/Handler/formListHandler';

const usePatchWarningModalChassis = ({
  patchFormKey,
  setInvoiceList,
  setBasicErrorMessage,
  refetchList,
}) => {
  const mutate = useMutation({
    mutationFn: patchChassisReconcile,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      deleteForm({ formKey: patchFormKey, setInvoiceList });
      refetchList();
    },
    onError: error => {
      setBasicErrorMessage(prev => error?.message);
    },
  });
  return { ...mutate };
};

export default usePatchWarningModalChassis;
