import React, { useEffect } from 'react';

const DriverModalContentSearch = ({
  driverList,
  setSearchedDriverInfo,
  ...props
}) => {
  useEffect(() => {
    document.getElementById('driverID')?.focus();
  }, []);

  return (
    <div className="w-full h-[30px]">
      <div className="w-full h-full flex items-center">
        <div className="w-full h-full">
          <input
            id="driverID"
            className="w-full h-full outline-none rounded-[5px] border-solid border-[1px] border-[#D9D9D9]
                          pl-[12px] text-[12px]
                          focus:border-[#264B9F] focus:border-[1.2px] focus:shadow-[0_0_4px_0_#8BBCE9]"
            placeholder="Driver ID or Name"
            disabled={!driverList}
            onChange={e => setSearchedDriverInfo(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default DriverModalContentSearch;
