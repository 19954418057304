import { useMutation } from '@tanstack/react-query';
import postScanPerdiem from '../../../../services/Acct/PerdiemReconcile/Post/postScanPerdiem';
import generateInvoiceList from '../../../../utils/Acct/PerdiemReconcile/Generator/generateInvoiceList';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';

const usePostScanPerdiem = ({
  setInvoiceList,
  setInvoiceKey,
  setErrorModalOptions,
}) => {
  const { setErrorModalOpen, setErrorMessage, setIsSuccess } =
    setErrorModalOptions;

  const mutate = useMutation({
    mutationFn: postScanPerdiem,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: ({ data }) => {
      const { invoiceList, invoiceKey } = generateInvoiceList({
        data,
      });
      setInvoiceList(invoiceList);
      setInvoiceKey(invoiceKey);
    },
    onError: error => {
      setIsSuccess(false);
      setErrorMessage(error?.message);
      setErrorModalOpen(true);
    },
  });
  return { ...mutate };
};

export default usePostScanPerdiem;
