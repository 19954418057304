import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';
import { CELL_TYPE_BUTTON } from '../../../../constants/Common/DataGrid/cellType';
import { specificCodeErrorMessageGenerator } from '../../../Common/Generator/specificErrorMessageGenerator';

const isDriverModalOpen = ({ schedule, gridParam }) => {
  return (
    (schedule === 'pu' ? gridParam?.data?.sche_type === 'DROP' : true) &&
    !!gridParam?.data?.[`${schedule}_schedule`] &&
    !gridParam?.data?.[`${schedule}_actual_in`] &&
    !!gridParam?.data?.[`${schedule}_loc`] &&
    !!gridParam?.data?.[`${schedule}_job_seq`]
  );
};

const handleDriverModalOpen = ({
  schedule,
  gridParam,
  setIsDriverModalOpen,
  setWONo,
  setSelectedScheduleSeq,
}) => {
  setIsDriverModalOpen(true);
  setWONo(gridParam?.data?.wo_no);
  setSelectedScheduleSeq(gridParam?.data?.[`${schedule}_job_seq`]);
};

const disableAssignModal = ({ schedule, gridParam }) => {
  if (schedule === 'pu' && gridParam?.data?.sche_type === 'LIVE') {
    return 'This job is LIVE. Assign Delivery driver instead.';
  }
  if (gridParam?.data?.[`${schedule}_actual_in`]) {
    return 'This job is already started.';
  }
  if (!gridParam?.data?.[`${schedule}_schedule`]) {
    return specificCodeErrorMessageGenerator({
      code: 'empty_schedule_datetime',
      fields: [`${schedule}_schedule`],
    });
  }
  if (!gridParam?.data?.[`${schedule}_loc`]) {
    return 'Location is required.';
  }
  return 'Unable to assign driver. Please check BC Detail';
};

const getAssignButtonDef = ({
  field,
  setMessageModalMessage,
  setIsMessageModalOpen,
  ...props
}) => ({
  cellRendererSelector: gridParam =>
    cellRendererGenerator({
      gridParam,
      cellType: CELL_TYPE_BUTTON,
      content: gridParam?.data?.[field] || 'Assign',
      category: gridParam?.data?.[field] ? null : 'assign',
      customStyle: gridParam?.data?.[field]
        ? {
            borderColor: 'none',
            backgroundColor: 'transparent',
          }
        : {
            size: 'small',
          },
      onClickHandler: () => {
        const getColId = gridParam?.column?.colId;
        const schedule = getColId.split('_')[0];
        if (isDriverModalOpen({ schedule, gridParam })) {
          handleDriverModalOpen({
            schedule,
            gridParam,
            ...props,
          });
        } else {
          const message = disableAssignModal({ schedule, gridParam });
          setMessageModalMessage(message);
          setIsMessageModalOpen(true);
        }
      },
    }),
  editable: false,
});

export default getAssignButtonDef;
