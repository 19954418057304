import { useMutation, useQueryClient } from '@tanstack/react-query';
import patchConfData from '../../../services/Admin/Conf/Patch/patchConfData';
import useControlToast from '../../Common/Toast/useControlToast';
import { SUCCESS_UPDATE } from '../../../constants/Common/successMessage';
import updateGridData from '../../../utils/Common/Handler/updateGridData';
import resetRow from '../../../utils/Common/Handler/resetRow';

const usePatchConfData = ({
  gridRef,
  setMessageContent,
  setMessageModalOpen,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  const mutator = useMutation({
    mutationFn: patchConfData,
    onSuccess: (data, variables) => {
      updateGridData({
        gridRef,
        newData: variables?.data,
        isUpdate: true,
      });
      addToast({ message: SUCCESS_UPDATE });
    },
    onError: error => {
      resetRow({ gridRef });
      setMessageContent(error?.message);
      setMessageModalOpen(true);
    },
  });
  return mutator;
};

export default usePatchConfData;
