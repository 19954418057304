import React from 'react';
import { PATH_MODAL_CLOSE_ICON } from '../../constants/Common/path';

const CommonTabModalCloseButton = ({ setModalOpen, setTabIndexOpen }) => {
  return (
    <div
      className="flex cursor-pointer"
      onClick={() => {
        setModalOpen(false);
        setTabIndexOpen(0);
      }}
    >
      <img
        src={PATH_MODAL_CLOSE_ICON}
        width={12}
        height={12}
        alt="Modal Close Icon"
      />
    </div>
  );
};

export default CommonTabModalCloseButton;
