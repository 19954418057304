import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CommonMessageModal from '../../../../../CommonMessageModal/CommonMessageModal';
import PDFModalButton from './PDFModalButton';
import PDFModalContent from './PDFModalContent';

const PDFModal = ({ ...props }) => {
  const { open, setOpen, detailInfoData } = props;

  const pdfModalController = useForm();

  // import / export / van / brokerage
  const pageCategory = location?.pathname?.split('/')?.[3];
  // Set PDF modal title
  const setPDFModalTitle = () => {
    if (pageCategory === 'van' || pageCategory === 'brokerage') return 'B/L';
    if (pageCategory === 'import') return 'POD';
    if (pageCategory === 'export') return 'POL';
    return '';
  };

  return (
    open && (
      <FormProvider {...pdfModalController}>
        <form className="contents">
          <CommonMessageModal
            open={open}
            setOpen={setOpen}
            header={setPDFModalTitle()}
            content={<PDFModalContent detailInfoData={detailInfoData} />}
            buttonContent={<PDFModalButton {...props} />}
          />
        </form>
      </FormProvider>
    )
  );
};

export default PDFModal;
