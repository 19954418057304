import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CommonMessageModal from '../../../CommonMessageModal/CommonMessageModal';
import SearchModalContent from '../SearchModalContent';

const AdvancedSearchModal = ({
  openModal,
  setOpenModal,
  queryObject,
  setQueryObject,
  sslList,
  setErrorModalOptions,
}) => {
  const searchController = useForm();

  return (
    <FormProvider {...searchController}>
      <form>
        <CommonMessageModal
          header="Advanced Search"
          open={openModal}
          setOpen={setOpenModal}
          width="588px"
          height="360px"
          content={
            <SearchModalContent
              setOpenModal={setOpenModal}
              queryObject={queryObject}
              setQueryObject={setQueryObject}
              sslList={sslList}
              setErrorModalOptions={setErrorModalOptions}
            />
          }
        />
      </form>
    </FormProvider>
  );
};

export default AdvancedSearchModal;
