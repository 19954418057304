const dailyReportKeys = {
  all: ['DAILY_REPORT'],
  list: ({ ...props }) => {
    const { category, div, date, type, 'sche-type': scheType } = props;
    return [...dailyReportKeys.all, category, div, date, type, scheType];
  },

  yardAll: ['DAILY_REPORT_YARD_LIST'],

  returnYardAll: ['DAILY_REPORT_RETURN_YARD'],
  returnYardList: ({ ...props }) => {
    const {
      yard,
      category,
      div,
      'sche-type': scheType,
      'status-color': statusColor,
    } = props;
    return [
      ...dailyReportKeys.returnYardAll,
      yard,
      category,
      div,
      scheType,
      statusColor,
    ];
  },

  driver: () => [...dailyReportKeys.all, 'DRIVER'],
  driverList: ({ woNo, driver, page }) => [
    ...dailyReportKeys.driver(),
    woNo,
    driver,
    page,
  ],
  driverAP: ({ woNo, scheduleSeq, driver, page }) => [
    ...dailyReportKeys.driverList({ woNo, driver, page }),
    scheduleSeq,
  ],
};

export default dailyReportKeys;
