import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  PERM_EDI_ACCEPT,
  PERM_EDI_DECLINE,
} from '../../../constants/Common/permission';
import { CATEGORY_204 } from '../../../constants/EDI/options';
import useGet204Detail from '../../../hooks/EDI/Get/useGet204Detail';
import useAccept204Mutation from '../../../hooks/EDI/Patch/useAccept204Mutation';
import checkPermission from '../../../utils/Common/Router/Handler/checkPermission';
import CommonErrorModal from '../../CommonErrorModal/CommonErrorModal';
import CommonLoading from '../../CommonLoading/CommonLoading';
import Add322Button from '../Add322Button';
import AdvancedSearchButton from '../AdvancedSearchButton';
import DataGrid204 from '../DataGrid/DataGrid204';
import DataGrid322 from '../DataGrid/DataGrid322';
import DataGridMain from '../DataGrid/DataGridMain';
import NotSelected from '../DataGrid/NotSelected';
import Add322Modal from '../Modal/Add322Modal';
import Decline204Modal from '../Modal/Decline204Modal';
import Detail204Modal from '../Modal/Detail204Modal';
import SearchModal from '../Modal/SearchModal';
import Sidebar from '../Sidebar';

const EDIInterface = () => {
  const location = useLocation();
  const currDiv = useSelector(state => state?.nowDiv);
  const storedCategory = localStorage.getItem('selectedCategory');
  const storedEDI = localStorage.getItem('selectedEDI');
  const isAcceptable = checkPermission({ permission: PERM_EDI_ACCEPT });
  const isDeclinable = checkPermission({ permission: PERM_EDI_DECLINE });

  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedEDI, setSelectedEDI] = useState();
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [isAdd322ModalOpen, setIsAdd322ModalOpen] = useState(false);
  const [isDetail204ModalOpen, setIsDetail204ModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isSuccessAlert, setIsSuccessAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [searchFrom204, setSearchFrom204] = useState(
    dayjs().subtract(3, 'month').format('YYYY-MM-DD'),
  );
  const [searchTo204, setSearchTo204] = useState(dayjs().format('YYYY-MM-DD'));
  const [searchTypeMain, setSearchTypeMain] = useState('created');
  const [searchFromMain, setSearchFromMain] = useState(
    dayjs().subtract(3, 'month').format('YYYY-MM-DD'),
  );
  const [searchToMain, setSearchToMain] = useState(
    dayjs().format('YYYY-MM-DD'),
  );
  const [searchFrom322, setSearchFrom322] = useState(
    dayjs().subtract(3, 'month').format('YYYY-MM-DD'),
  );
  const [searchTo322, setSearchTo322] = useState(dayjs().format('YYYY-MM-DD'));
  const [isDecline204ModalOpen, setIsDecline204ModalOpen] = useState(false);

  const { isLoading204Detail, detail204Data, isRefetching204Detail } =
    useGet204Detail({
      id: selectedData,
    });

  const { mutate: accept204Mutation, isPending: isAccept204Pending } =
    useAccept204Mutation({
      setIsAlertOpen,
      setIsSuccessAlert,
      setAlertMessage,
      div: currDiv,
      id: selectedData,
      from: searchFrom204,
      to: searchTo204,
      sender: CATEGORY_204[selectedCategory],
      setIsDetail204ModalOpen,
    });

  useEffect(() => {
    if (storedCategory && storedEDI && !selectedCategory && !selectedEDI) {
      setSelectedCategory(storedCategory);
      setSelectedEDI(storedEDI);
    } else {
      localStorage.setItem('selectedCategory', selectedCategory);
      localStorage.setItem('selectedEDI', selectedEDI);
    }
    setSearchFrom204(dayjs().subtract(3, 'month').format('YYYY-MM-DD'));
    setSearchTo204(dayjs().format('YYYY-MM-DD'));
    setSearchTypeMain('created');
    setSearchFromMain(dayjs().subtract(3, 'month').format('YYYY-MM-DD'));
    setSearchToMain(dayjs().format('YYYY-MM-DD'));
    setSearchFrom322(dayjs().subtract(3, 'month').format('YYYY-MM-DD'));
    setSearchTo322(dayjs().format('YYYY-MM-DD'));
  }, [selectedCategory, selectedEDI]);

  useEffect(() => {
    return () => {
      localStorage.removeItem('selectedCategory');
      localStorage.removeItem('selectedEDI');
    };
  }, [location]);

  return (
    <>
      {detail204Data && (
        <>
          <Detail204Modal
            isDetail204ModalOpen={isDetail204ModalOpen}
            setIsDetail204ModalOpen={setIsDetail204ModalOpen}
            detail204Data={detail204Data}
            accept204Mutation={accept204Mutation}
            setIsAlertOpen={setIsAlertOpen}
            setIsSuccessAlert={setIsSuccessAlert}
            setAlertMessage={setAlertMessage}
            selectedData={selectedData}
            currDiv={currDiv}
            searchFrom204={searchFrom204}
            searchTo204={searchTo204}
            isAcceptable={isAcceptable}
            selectedCategory={selectedCategory}
          />
          <Decline204Modal
            isDecline204ModalOpen={isDecline204ModalOpen}
            setIsDecline204ModalOpen={setIsDecline204ModalOpen}
            detail204Data={detail204Data}
            setIsAlertOpen={setIsAlertOpen}
            setIsSuccessAlert={setIsSuccessAlert}
            setAlertMessage={setAlertMessage}
            selectedData={selectedData}
            currDiv={currDiv}
            searchFrom204={searchFrom204}
            searchTo204={searchTo204}
            selectedCategory={selectedCategory}
          />
        </>
      )}
      <Add322Modal
        isAdd322ModalOpen={isAdd322ModalOpen}
        setIsAdd322ModalOpen={setIsAdd322ModalOpen}
        div={currDiv}
        selectedCategory={selectedCategory}
        searchFrom322={searchFrom322}
        searchTo322={searchTo322}
        setIsAlertOpen={setIsAlertOpen}
        setIsSuccessAlert={setIsSuccessAlert}
        setAlertMessage={setAlertMessage}
      />
      <SearchModal
        isSearchModalOpen={isSearchModalOpen}
        setIsSearchModalOpen={setIsSearchModalOpen}
        selectedEDI={selectedEDI}
        setSearchFrom204={setSearchFrom204}
        setSearchTo204={setSearchTo204}
        setSearchTypeMain={setSearchTypeMain}
        setSearchFromMain={setSearchFromMain}
        setSearchToMain={setSearchToMain}
        setSearchFrom322={setSearchFrom322}
        setSearchTo322={setSearchTo322}
        searchFrom204={searchFrom204}
        searchTo204={searchTo204}
        searchTypeMain={searchTypeMain}
        searchFromMain={searchFromMain}
        searchToMain={searchToMain}
        searchFrom322={searchFrom322}
        searchTo322={searchTo322}
        setIsAlertOpen={setIsAlertOpen}
        setIsSuccessAlert={setIsSuccessAlert}
        setAlertMessage={setAlertMessage}
      />
      {!isSuccessAlert && (
        <CommonErrorModal
          open={isAlertOpen}
          setIsOpen={setIsAlertOpen}
          isSuccess={isSuccessAlert}
          message={alertMessage}
        />
      )}
      <CommonLoading
        open={isLoading204Detail || isRefetching204Detail || isAccept204Pending}
      />
      <div className="pr-[40px]">
        <div className="flex justify-end pt-[20px] pb-[20px]">
          {selectedEDI === '322' && (
            <Add322Button setIsAdd322ModalOpen={setIsAdd322ModalOpen} />
          )}
          {selectedEDI && (
            <AdvancedSearchButton setIsSearchModalOpen={setIsSearchModalOpen} />
          )}
          {!selectedEDI && <div className="h-[25px]" />}
        </div>
        <div className="flex h-[calc(100vh-230px)] pb-8">
          <Sidebar
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            selectedEDI={selectedEDI}
            setSelectedEDI={setSelectedEDI}
          />
          {!selectedEDI && <NotSelected />}
          {selectedEDI && (
            <div className="flex-1">
              {selectedEDI === '204' && (
                <DataGrid204
                  setSelectedData={setSelectedData}
                  setIsDetail204ModalOpen={setIsDetail204ModalOpen}
                  setIsDecline204ModalOpen={setIsDecline204ModalOpen}
                  accept204Mutation={accept204Mutation}
                  currDiv={currDiv}
                  searchFrom204={searchFrom204}
                  searchTo204={searchTo204}
                  setIsSuccessAlert={setIsSuccessAlert}
                  setAlertMessage={setAlertMessage}
                  setIsAlertOpen={setIsAlertOpen}
                  isAcceptable={isAcceptable}
                  isDeclinable={isDeclinable}
                  selectedCategory={selectedCategory}
                />
              )}
              {selectedEDI === '322' && (
                <DataGrid322
                  currDiv={currDiv}
                  searchFrom322={searchFrom322}
                  searchTo322={searchTo322}
                  selectedCategory={selectedCategory}
                />
              )}
              {selectedEDI === 'MAIN' && (
                <DataGridMain
                  currDiv={currDiv}
                  searchTypeMain={searchTypeMain}
                  searchFromMain={searchFromMain}
                  searchToMain={searchToMain}
                  selectedCategory={selectedCategory}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EDIInterface;
