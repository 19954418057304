import React, { useEffect, useState } from 'react';
import { FormHelperText } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import StyledCommonInputWrapper from '../../../styles/Common/CommonInput/StyledCommonInputWrapper';
import StyledCommonLabel from '../../../styles/Common/CommonInput/StyledCommonLabel';
import StyledCommonInputRequiredLabel from '../../../styles/Common/CommonInput/StyledCommonInputRequiredLabel';
import defaultInputRules from '../../../utils/Common/Input/defaultInputRules';

const CommonMultipleInput = ({ ...props }) => {
  const {
    control,
    unregister,
    setValue,
    formState: { errors: formErrors },
  } = useFormContext();
  /**
   * @label {string} - Label of input
   * @maxLength {number} - Max length of input
   * @required {boolean} - Required input
   * @disabled {boolean} - Disable input
   * @defaultValue {string} - Default value of input
   * @width {string} - Width of input
   * @inputName {string} - Name of input
   */
  const [label, setLabel] = useState(props?.label);

  const [maxLength, setMaxLength] = useState(props?.maxLength || [20, 20, 20]);
  useEffect(() => {
    setMaxLength(props?.maxLength);
  }, [props?.maxLength]);

  const [required, setRequired] = useState(
    props?.required || [false, false, false],
  );
  useEffect(() => {
    setRequired(props?.required);
  }, [props?.required]);

  const [disabled, setDisabled] = useState(
    props?.disabled || [false, false, false],
  );
  useEffect(() => {
    setDisabled(props?.disabled);
  }, [props?.disabled]);

  const [defaultValue, setDefaultValue] = useState(
    props?.defaultValue || ['', '', ''],
  );
  useEffect(() => {
    setDefaultValue(props?.defaultValue);
  }, [props?.defaultValue]);

  const [inputName, setInputName] = useState(
    props?.inputName || ['multi_input_1', 'multi_input_2', 'multi_input_3'],
  );

  return (
    <div>
      <div>
        {(!!label || props.labelVisible !== false) && (
          <StyledCommonLabel
            labelStyle={props?.labelStyle}
            lbl-pd-right={props?.[`lbl-pd-right`]}
          >
            {!!label && (
              <>
                {!!required?.includes(true) && (
                  <StyledCommonInputRequiredLabel>
                    *
                  </StyledCommonInputRequiredLabel>
                )}
                {label}
              </>
            )}
          </StyledCommonLabel>
        )}
      </div>
      <div className={`${props?.width ? `${props?.width}` : 'w-full'} flex`}>
        <div>
          <Controller
            control={control}
            name={inputName?.[0]}
            defaultValue={defaultValue?.[0]}
            rules={defaultInputRules({
              ...props,
              required: props?.required?.[0],
              maxLength: props?.maxLength?.[0],
              minLength: props?.maxLength?.[0],
              validate: props?.validate?.[0],
            })}
            render={({
              field: { ref, onChange, value, ...field },
              fieldState: { invalid, error },
            }) => {
              // Package of handler props
              const handlerProps = {
                onChange,
                value,
                maxLength,
                ...field,
                ...props,
              };

              // Render Control Input Component
              return (
                <div>
                  <StyledCommonInputWrapper labelStyle={props?.labelStyle}>
                    <div>
                      <input
                        type="text"
                        ref={ref}
                        maxLength={maxLength?.[0]}
                        value={value}
                        disabled={disabled?.[0]}
                        onClick={e =>
                          props?.onClickHandler?.({ e, ...handlerProps })
                        }
                        onKeyDown={e =>
                          props?.onKeyDownHandler?.({ e, ...handlerProps })
                        }
                        onChange={e =>
                          props?.onChangeHandler
                            ? props?.onChangeHandler[0]({ e, ...handlerProps })
                            : null
                        }
                        onBlur={e =>
                          props?.onBlurHandler
                            ? props?.onBlurHandler[0]({ e, ...handlerProps })
                            : null
                        }
                        placeholder={props?.placeholder?.[0] || ''}
                        className={`
                    ${
                      (formErrors?.[inputName?.[0]]?.message ||
                        formErrors?.[inputName?.[1]]?.message ||
                        formErrors?.[inputName?.[2]]?.message) &&
                      (required?.[0] || required?.[1] || required?.[2])
                        ? 'border-red-700'
                        : 'hover:border-[#8E9396]  focus:border-[1px] focus:border-[#264B9F] focus:shadow-[0_0_4px_rgba(139,188,233,1)]'
                    }
                  box-border
                  px-3 py-2 text-[12px] font-[500]
                  border-[#D9D9D9] border-[1px] w-full h-[30px]
                  rounded-l-[5px]  outline-none 
                 ${
                   disabled?.[0] || disabled?.[1] || disabled?.[2]
                     ? 'hover:border-[#D9D9D9]  text-[#D9D9D9] '
                     : ' '
                 }
                 ${disabled?.[0] && `text-[#222]`}
                  `}
                      />
                    </div>
                  </StyledCommonInputWrapper>
                </div>
              );
            }}
          />
        </div>
        <div>
          <Controller
            control={control}
            name={inputName?.[1]}
            defaultValue={defaultValue?.[1]}
            rules={defaultInputRules({
              ...props,
              required: props?.required?.[1],
              maxLength: props?.maxLength?.[1],
              minLength: props?.maxLength?.[1],
              validate: props?.validate?.[1],
            })}
            render={({
              field: { ref, onChange, value, ...field },
              fieldState: { invalid, error },
            }) => {
              // Package of handler props
              const handlerProps = {
                onChange,
                value,
                maxLength,
                ...field,
                ...props,
              };

              // Render Control Input Component
              return (
                <div>
                  <StyledCommonInputWrapper labelStyle={props?.labelStyle}>
                    <div>
                      <input
                        type="text"
                        ref={ref}
                        maxLength={maxLength?.[1]}
                        value={value}
                        disabled={disabled?.[1]}
                        onClick={e =>
                          props?.onClickHandler?.({ e, ...handlerProps })
                        }
                        onKeyDown={e =>
                          props?.onKeyDownHandler?.({ e, ...handlerProps })
                        }
                        onChange={e =>
                          props?.onChangeHandler
                            ? props?.onChangeHandler[1]({ e, ...handlerProps })
                            : null
                        }
                        onBlur={e =>
                          props?.onBlurHandler
                            ? props?.onBlurHandler[1]({ e, ...handlerProps })
                            : null
                        }
                        placeholder={props?.placeholder?.[1] || ''}
                        className={`
                ${
                  (formErrors?.[inputName?.[0]]?.message ||
                    formErrors?.[inputName?.[1]]?.message ||
                    formErrors?.[inputName?.[2]]?.message) &&
                  (required?.[0] || required?.[1] || required?.[2])
                    ? 'border-red-700'
                    : ' hover:border-[#8E9396] hover:border-[1px] focus:border-[#264B9F] focus:shadow-[0_0_4px_rgba(139,188,233,1)]'
                }
                  box-border
                  text-[12px] font-[500]
                  px-3 py-2 border-[#D9D9D9] border-[1px] w-full h-[30px] border-x-transparent
                  outline-none
                       ${
                         disabled?.[0] || disabled?.[1] || disabled?.[2]
                           ? 'hover:border-[#D9D9D9] hover:border-x-transparent text-[#D9D9D9] '
                           : ' '
                       }
                  ${disabled?.[1] && `text-[#222]`}
                  `}
                      />
                    </div>
                  </StyledCommonInputWrapper>
                </div>
              );
            }}
          />
        </div>
        <div>
          <Controller
            control={control}
            name={inputName?.[2]}
            defaultValue={defaultValue?.[2]}
            rules={defaultInputRules({
              ...props,
              required: props?.required?.[2],
              maxLength: props?.maxLength?.[2],
              minLength: props?.maxLength?.[2],
              validate: props?.validate?.[2],
            })}
            render={({
              field: { ref, onChange, value, ...field },
              fieldState: { invalid, error },
            }) => {
              // Package of handler props
              const handlerProps = {
                onChange,
                value,
                maxLength,
                ...field,
                ...props,
              };

              // Render Control Input Component
              return (
                <div>
                  <StyledCommonInputWrapper labelStyle={props?.labelStyle}>
                    <div>
                      <input
                        type="text"
                        ref={ref}
                        maxLength={maxLength?.[2]}
                        value={value}
                        disabled={disabled?.[2]}
                        onClick={e =>
                          props?.onClickHandler?.({ e, ...handlerProps })
                        }
                        onKeyDown={e =>
                          props?.onKeyDownHandler?.({ e, ...handlerProps })
                        }
                        onChange={e =>
                          props?.onChangeHandler
                            ? props?.onChangeHandler[2]({ e, ...handlerProps })
                            : null
                        }
                        onBlur={e =>
                          props?.onBlurHandler
                            ? props?.onBlurHandler[2]({ e, ...handlerProps })
                            : null
                        }
                        placeholder={props?.placeholder?.[2] || ''}
                        className={`
                    ${
                      (formErrors?.[inputName?.[0]]?.message ||
                        formErrors?.[inputName?.[1]]?.message ||
                        formErrors?.[inputName?.[2]]?.message) &&
                      (required?.[0] || required?.[1] || required?.[2])
                        ? 'border-red-700'
                        : ' focus:border-[1px] focus:border-[#264B9F] focus:shadow-[0_0_4px_rgba(139,188,233,1)] hover:border-[#8E9396]'
                    }
                  box-border
                  px-3 py-2 text-[12px] font-[500]
                  border-[#D9D9D9] border-[1px] w-full h-[30px]
                  rounded-r-[5px] outline-none ${
                    disabled?.[0] || disabled?.[1] || disabled?.[2]
                      ? 'hover:border-[#D9D9D9]  text-[#D9D9D9] '
                      : ' '
                  }
                  ${disabled?.[2] && `text-[#222]`}
                  `}
                      />
                    </div>
                  </StyledCommonInputWrapper>
                </div>
              );
            }}
          />
        </div>
      </div>
      <div
        className={`${
          required?.[0] || required?.[1] || required?.[2]
            ? 'block'
            : 'invisible'
        }`}
      >
        <FormHelperText className="text-[#ff2424] text-[11px] font-normal min-h-[11px] h-[11px] mb-[3px] leading-none">
          {formErrors?.[inputName?.[0]]?.message ||
            formErrors?.[inputName?.[1]]?.message ||
            formErrors?.[inputName?.[2]]?.message}
        </FormHelperText>
      </div>
    </div>
  );
};

export default CommonMultipleInput;
