import dayjs from 'dayjs';
import {
  NOT_NULL_FIELDS_CHASSIS_RECONCILE,
  NOT_NULL_FIELDS_PER_DIEM_RECONCILE,
} from '../../../../constants/Acct/Common/staticNames';
import checkContainerNumberConstraint from '../../../Common/Handler/checkContainerNumberConstraint';
import { VALIDATE_FAILED_CONTAINER_NUMBER } from '../../../../constants/Order/Common/message';
import { VALIDATE_FAILED_INVOICE_DATE } from '../../../../constants/Acct/Common/messages';
import updateButtonDisabled from '../../../Common/Handler/updateButtonDisabled';

const validateNullValue = ({ submitData, notNullFieldList }) => {
  const isValidNullValue = !Object?.entries?.(submitData)?.some(
    ([key, value]) => {
      return [...notNullFieldList]?.includes(key) && !value;
    },
  );

  return isValidNullValue;
};

const validateInvoiceDate = ({ fromDate, toDate }) => {
  const isValidInvoiceDate = !dayjs?.(fromDate)?.isAfter(dayjs?.(toDate));
  return isValidInvoiceDate;
};

export const validatePerDiemForm = ({
  buttonRef,
  submitData,
  setErrorModalOpen,
  setErrorMessage,
  setIsSuccess,
}) => {
  /** Null Value Validation */
  const isValidNullValue = validateNullValue({
    submitData,
    notNullFieldList: NOT_NULL_FIELDS_PER_DIEM_RECONCILE,
  });

  if (!isValidNullValue) {
    return false;
  }

  /** Invoice Date Validation */
  const isValidInvoiceDate = validateInvoiceDate({
    fromDate: submitData?.inv_from,
    toDate: submitData?.inv_to,
  });

  if (!isValidInvoiceDate) {
    setIsSuccess(false);
    setErrorMessage(VALIDATE_FAILED_INVOICE_DATE);
    setErrorModalOpen(true);
    updateButtonDisabled({ ref: buttonRef, disabled: false });
    return false;
  }

  /** Container # Validation */
  const isValidContNo = checkContainerNumberConstraint({
    containerNo: submitData?.cont_no,
  });

  if (!isValidContNo) {
    setIsSuccess(false);
    setErrorModalOpen(true);
    setErrorMessage(VALIDATE_FAILED_CONTAINER_NUMBER);
    updateButtonDisabled({ ref: buttonRef, disabled: false });
    return false;
  }

  return true;
};

export const validateChassisForm = ({
  buttonRef,
  submitData,
  setErrorModalOpen,
  setErrorMessage,
  setIsSuccess,
}) => {
  /** Null Value Validation */
  const isValidNullValue = validateNullValue({
    submitData,
    notNullFieldList: NOT_NULL_FIELDS_CHASSIS_RECONCILE,
  });

  if (!isValidNullValue) {
    return false;
  }

  /** Invoice Date Validation */
  const isValidInvoiceDate = validateInvoiceDate({
    fromDate: submitData?.inv_from,
    toDate: submitData?.inv_to,
  });

  if (!isValidInvoiceDate) {
    setIsSuccess(false);
    setErrorMessage(VALIDATE_FAILED_INVOICE_DATE);
    setErrorModalOpen(true);
    updateButtonDisabled({ ref: buttonRef, disabled: false });
    return false;
  }

  /** Container # Validation */
  const isValidContNo =
    checkContainerNumberConstraint({
      containerNo: submitData?.cont_from,
      containerType: 'DRY',
    }) &&
    checkContainerNumberConstraint({
      containerNo: submitData?.cont_to,
      containerType: 'DRY',
    });

  if (!isValidContNo) {
    setIsSuccess(false);
    setErrorMessage(VALIDATE_FAILED_CONTAINER_NUMBER);
    setErrorModalOpen(true);
    updateButtonDisabled({ ref: buttonRef, disabled: false });
    return false;
  }

  return true;
};

export const validateChassisDataGrid = ({
  // buttonRef,
  gridParam,
  submitData,
  setErrorModalOptions,
}) => {
  const { setErrorModalOpen, setErrorMessage, setIsSuccess } =
    setErrorModalOptions;

  const handleInvalidData = message => {
    gridParam?.api?.undoCellEditing();
    setIsSuccess(false);
    setErrorMessage(message);
    setErrorModalOpen(true);
    // updateButtonDisabled({ ref: buttonRef, disabled: false });
  };

  /** Invoice Date Validation */
  const isValidInvoiceDate = validateInvoiceDate({
    fromDate: submitData?.inv_from,
    toDate: submitData?.inv_to,
  });

  if (!isValidInvoiceDate) {
    handleInvalidData(VALIDATE_FAILED_INVOICE_DATE);
    return false;
  }

  /** Container # Validation */
  const isValidContNo =
    checkContainerNumberConstraint({
      containerNo: submitData?.cont_from,
      containerType: 'DRY',
    }) &&
    checkContainerNumberConstraint({
      containerNo: submitData?.cont_to,
      containerType: 'DRY',
    });

  if (!isValidContNo) {
    handleInvalidData(VALIDATE_FAILED_CONTAINER_NUMBER);
    return false;
  }

  return true;
};

export const validatePerDiemDataGrid = ({
  // buttonRef,
  gridParam,
  submitData,
  setErrorModalOptions,
}) => {
  const { setErrorModalOpen, setErrorMessage, setIsSuccess } =
    setErrorModalOptions;

  const handleInvalidData = message => {
    gridParam?.api?.undoCellEditing();
    setIsSuccess(false);
    setErrorMessage(message);
    setErrorModalOpen(true);
    // updateButtonDisabled({ ref: buttonRef, disabled: false });
  };

  /** Invoice Date Validation */
  const isValidInvoiceDate = validateInvoiceDate({
    fromDate: submitData?.inv_from,
    toDate: submitData?.inv_to,
  });

  if (!isValidInvoiceDate) {
    handleInvalidData(VALIDATE_FAILED_INVOICE_DATE);
    return false;
  }

  /** Container # Validation */
  const isValidContNo = checkContainerNumberConstraint({
    containerNo: submitData?.cont_no,
  });

  if (!isValidContNo) {
    handleInvalidData(VALIDATE_FAILED_CONTAINER_NUMBER);
    return false;
  }

  return true;
};
