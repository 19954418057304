import { useMutation, useQueryClient } from '@tanstack/react-query';
import patchUserStatus from '../../../../services/Admin/User/Patch/patchUserStatus';
import adminKeys from '../../keys';
import useControlToast from '../../../Common/Toast/useControlToast';
import { SUCCESS_UPDATE } from '../../../../constants/Common/successMessage';
import updateGridData from '../../../../utils/Common/Handler/updateGridData';

const usePatchUserStatus = ({
  gridRef,
  disabledRef,
  setIsOpenErrorModal,
  setErrorMessage,
  setIsSuccess,
}) => {
  const { addToast } = useControlToast();

  const mutate = useMutation({
    mutationFn: patchUserStatus,
    onSuccess: (data, variables) => {
      updateGridData({
        gridRef,
        newData: {
          id: variables?.id,
          tms_is_active: data?.data?.tms_is_active,
        },
        key: 'id',
        isUpdate: true,
      });
      addToast({ message: SUCCESS_UPDATE });
    },
    onError: (error, variables) => {
      variables?.setChecked(checked => !checked);
      setErrorMessage(error?.message);
      setIsOpenErrorModal(true);
      setIsSuccess(false);
    },
    onSettled: () => {
      disabledRef.current.disabled = false;
      disabledRef.current.disabledRow = null;
    },
  });
  return { ...mutate };
};

export default usePatchUserStatus;
