import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SUCCESS_DELETE } from '../../../constants/Common/successMessage';
import deleteAuthList from '../../../services/Admin/Auth/deleteAuthList';
import useControlToast from '../../Common/Toast/useControlToast';
import adminKeys from '../keys';

const useDeleteAuthMutation = ({
  setIsMessageOpen,
  setMessage,
  setIsSuccess,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  return useMutation({
    mutationFn: deleteAuthList,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: adminKeys?.authList() });
      addToast({
        message: SUCCESS_DELETE,
      });
    },
    onError: error => {
      queryClient.refetchQueries({ queryKey: adminKeys?.authList() });
      setIsSuccess(false);
      setMessage(error.message);
      setIsMessageOpen(true);
    },
  });
};

export default useDeleteAuthMutation;
