import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import mapAPI from '../../api';

const postLiveShare = async ({ data }) => {
  try {
    const response = await mapAPI.post(`/samsara-live-share`, data);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default postLiveShare;
