import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';
import billingInfoDefaultAPI from '../../api';

const getPDFRateCFM = async ({ seq }) => {
  try {
    const response = await billingInfoDefaultAPI.get(`/pdf-rate-cfm/${seq}`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getPDFRateCFM;
