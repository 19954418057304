const handleWOChange = ({
  e,
  onChange,
  woObjectList,
  changeFieldNameList,
  setValue,
  setWOFromQueryObject,
  setWOToQueryObject,
  ...props
}) => {
  const woNo = props?.selectProps?.value;
  let woData;

  changeFieldNameList?.forEach(changeFieldName => {
    switch (changeFieldName) {
      case 'from':
        woData = woObjectList?.find(
          woObject => woObject?.woNo === woNo,
        )?.woDate;
        break;
      case 'to':
        woData = woObjectList?.find(
          woObject => woObject?.woNo === woNo,
        )?.woDate;
        break;
      case 'div':
        woData = woObjectList?.find(woObject => woObject?.woNo === woNo)?.woDiv;
        break;
      case 'pool':
        woData = woObjectList?.find(
          woObject => woObject?.woNo === woNo,
        )?.woPool;
        break;
      case 'ch_no': {
        const chassisNo = woObjectList?.find(
          woObject => woObject?.woNo === woNo,
        )?.woChassisNo;
        woData = chassisNo;
        setWOFromQueryObject(prev => ({ ...prev, ch_no: chassisNo }));
        setWOToQueryObject(prev => ({ ...prev, ch_no: chassisNo }));
        break;
      }
      case 'cont_from':
        woData = woObjectList?.find(
          woObject => woObject?.woNo === woNo,
        )?.woContNo;
        setWOFromQueryObject(prev => ({ ...prev, cont_no: woData }));
        break;
      case 'cont_to':
        woData = woObjectList?.find(
          woObject => woObject?.woNo === woNo,
        )?.woContNo;
        setWOToQueryObject(prev => ({ ...prev, cont_no: woData }));
        break;
      default:
        break;
    }

    onChange?.(woNo);
    setValue(changeFieldName, woData);
  });
};

export default handleWOChange;
