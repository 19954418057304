import React from 'react';

const DualMoveAPModalAPInfo = ({ ...props }) => {
  const { apData } = props;
  const {
    driver,
    from_location: fromLoc,
    to_location: toLoc,
    mileage,
  } = apData || {};

  return (
    <div className="w-[502px] h-[106px] bg-[#F9F9F9] flex flex-col justify-center pl-[20px]">
      <div className="flex flex-row">
        <div className="w-[100px] text-[#222] text-[12px] font-semibold leading-[18px]">
          Driver ID
        </div>
        <div className="text-[#666] text-[12px] font-normal leading-[18px]">
          {driver || '-'}
        </div>
      </div>
      <div className="flex flex-row">
        <div className="w-[100px] text-[#222] text-[12px] font-semibold leading-[18px]">
          From
        </div>
        <div className="text-[#666] text-[12px] font-normal leading-[18px]">
          {fromLoc || '-'}
        </div>
      </div>
      <div className="flex flex-row">
        <div className="w-[100px] text-[#222] text-[12px] font-semibold leading-[18px]">
          To
        </div>
        <div className="text-[#666] text-[12px] font-normal leading-[18px]">
          {toLoc || '-'}
        </div>
      </div>
      <div className="flex flex-row">
        <div className="w-[100px] text-[#222] text-[12px] font-semibold leading-[18px]">
          Mileage
        </div>
        <div className="text-[#666] text-[12px] font-normal leading-[18px]">
          {mileage || '-'}
        </div>
      </div>
    </div>
  );
};

export default DualMoveAPModalAPInfo;
