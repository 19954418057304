import { useQuery } from '@tanstack/react-query';
import getCompanyList from '../../../../services/Acct/Common/Get/getCompanyList';
import { generateVendorOption } from '../../../../utils/Acct/Common/Generator/optionGenerator';
import acctKeys from '../../keys';

const useGetCompanyList = ({ category }) => {
  const { data: companyList, isLoading: isLoadingCompanyList } = useQuery({
    queryKey: acctKeys.companyList({ category }),
    queryFn: () => getCompanyList({ category }),
    staleTime: 0,
    refetchOnWindowFocus: false,
  });

  const vendorOptionList = generateVendorOption({ companyList });

  return { vendorOptionList, isLoadingCompanyList };
};

export default useGetCompanyList;
