const adminKeys = {
  all: ['ADMIN'],
  authList: () => [...adminKeys.all, 'AUTH_LIST'],
  earlyWarningList: ({ division }) => [
    ...adminKeys?.all,
    'EARLY_WARNING_LIST',
    division,
  ],
  divInfo: ({ div }) => [...adminKeys?.all, 'DIV_INFO', div],
  timeZoneList: () => [...adminKeys?.all, 'TIME_ZONE_LIST'],
  weightPerZIP: ({ div }) => [...adminKeys?.all, 'WEIGHT_PER_ZIP', div],
  userList: ({ nowDiv }) => [...adminKeys?.all, 'USER_LIST', nowDiv],
  user: ({ id }) => [...adminKeys?.all, 'USER', id],
  groupAuthList: () => [...adminKeys?.all, 'GROUP_AUTH_LIST'],
  productCodeList: ({ div }) => [...adminKeys?.all, 'PRODUCT_CODE_LIST', div],
  apProductCode: () => [...adminKeys?.all, 'AP_PRODUCT_CODE'],
  arProductCode: () => [...adminKeys?.all, 'AR_PRODUCT_CODE'],
};

export default adminKeys;
