import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import CommonButton from '../../../CommonButton/CommonButton';
import generateAPData from '../../../../utils/Dispatch/Common/Generator/generateAPData';
import dailyReportKeys from '../../../../hooks/DailyReport/keys';
import usePostAssignDriverAPInput from '../../../../hooks/DailyReport/Post/usePostAssignDriverAPInput';

const APModalButtonContent = ({
  woNo,
  setWONo,
  apInputData,
  selectedDriver,
  apTableContent,
  setAPModalOpen,
  setSelectedScheduleSeq,
  setSelectedDriverData,
  selectedScheduleSeq,
  setMessageModalOpen,
  setMessageModalMessage,
  setLoadingOpen,
  page,
  ...props
}) => {
  const queryClient = useQueryClient();
  const { mutate: apPostMutator } = usePostAssignDriverAPInput({
    setAPModalOpen,
    setModalOpen: setMessageModalOpen,
    setModalMessage: setMessageModalMessage,
    setLoadingOpen,
  });

  const { product_codes: productCodes } = apInputData;

  return (
    <div className="w-full flex justify-center items-center">
      <div className="flex gap-[20px]">
        <CommonButton
          category="cancel"
          onClick={() => {
            queryClient?.removeQueries({ queryKey: dailyReportKeys?.driver() });
            setWONo('');
            setSelectedScheduleSeq(null);
            setSelectedDriverData(null);
            setAPModalOpen(false);
          }}
        >
          Cancel
        </CommonButton>
        <CommonButton
          category="save"
          preventDupClick
          onClick={({ buttonRef }) => {
            const tempAPData = generateAPData({
              apData: apTableContent,
              productCodes,
            });
            apPostMutator({
              woNo,
              seq: selectedScheduleSeq,
              driver: selectedDriver,
              apData: tempAPData,
              page,
              buttonRef,
            });
          }}
        >
          Save
        </CommonButton>
      </div>
    </div>
  );
};

export default APModalButtonContent;
