import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CATEGORY_204 } from '../../../../constants/EDI/options';
import useGetCneeList from '../../../../hooks/EDI/Get/useGetCneeList';
import useGetCustomerList from '../../../../hooks/EDI/Get/useGetCustomerList';
import usePatch204Mutation from '../../../../hooks/EDI/Patch/usePatch204Mutation';
import CommonMessageModal from '../../../CommonMessageModal/CommonMessageModal';
import ButtonContent from './ButtonContent';
import ModalContent from './ModalContent';

const Detail204Modal = ({
  isDetail204ModalOpen,
  setIsDetail204ModalOpen,
  detail204Data,
  accept204Mutation,
  setIsAlertOpen,
  setIsSuccessAlert,
  setAlertMessage,
  currDiv,
  selectedData,
  searchFrom204,
  searchTo204,
  isAcceptable,
  selectedCategory,
}) => {
  const detailController = useForm();
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [isPOChanged, setIsPOChanged] = useState(false);
  const [isDelvChanged, setIsDelvChanged] = useState(false);
  const [isRtnChanged, setIsRtnChanged] = useState(false);

  const { isLoadingCneeList, cneeList } = useGetCneeList();

  const { isLoadingCustomerList, customerList } = useGetCustomerList();

  const { mutate: patch204Mutation } = usePatch204Mutation({
    setIsAlertOpen,
    setIsSuccessAlert,
    setAlertMessage,
    div: currDiv,
    id: selectedData,
    from: searchFrom204,
    to: searchTo204,
    sender: CATEGORY_204[selectedCategory],
  });

  return (
    <FormProvider {...detailController}>
      <form>
        <CommonMessageModal
          open={isDetail204ModalOpen}
          setOpen={setIsDetail204ModalOpen}
          onCloseClick={() => setIsDataChanged(false)}
          width="1585px"
          height="724px"
          content={
            <ModalContent
              detail204Data={detail204Data}
              cneeList={cneeList}
              customerList={customerList}
              isLoadingCneeList={isLoadingCneeList}
              isLoadingCustomerList={isLoadingCustomerList}
              setIsDataChanged={setIsDataChanged}
              isPOChanged={isPOChanged}
              setIsPOChanged={setIsPOChanged}
              isDelvChanged={isDelvChanged}
              setIsDelvChanged={setIsDelvChanged}
              isRtnChanged={isRtnChanged}
              setIsRtnChanged={setIsRtnChanged}
            />
          }
          buttonContent={
            <ButtonContent
              setIsDetail204ModalOpen={setIsDetail204ModalOpen}
              detail204Data={detail204Data}
              accept204Mutation={accept204Mutation}
              patch204Mutation={patch204Mutation}
              isAcceptable={isAcceptable}
              isDataChanged={isDataChanged}
              setIsDataChanged={setIsDataChanged}
              setIsAlertOpen={setIsAlertOpen}
              setAlertMessage={setAlertMessage}
              setIsSuccessAlert={setIsSuccessAlert}
              setIsPOChanged={setIsPOChanged}
              setIsDelvChanged={setIsDelvChanged}
              setIsRtnChanged={setIsRtnChanged}
            />
          }
        />
      </form>
    </FormProvider>
  );
};

export default Detail204Modal;
