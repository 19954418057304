import styled from 'styled-components';

const StyledSubMenuNavigatorItemActivator = styled.div`
  width: 100%;
  height: 2px;

  background-color: ${props =>
    props?.$isCurrentMenu ? '#001E5F' : 'transparent'};

  transition-duration: 300ms;
`;

export default StyledSubMenuNavigatorItemActivator;
