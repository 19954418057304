import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import commonDefaultAPI from '../../../Common/api';

const getDriverList = async () => {
  try {
    const response = await commonDefaultAPI.get(`driver-list`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getDriverList;
