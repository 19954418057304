import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import processData from '../../../../utils/Master/Common/processData';
import arRateAPI from '../api';

/**
 * Updates the AR rate data in the backend.
 * @param {Object} data - The data to be updated.
 */
const updateARRate = async ({ data }) => {
  processData(data);
  try {
    const response = await arRateAPI.put(`/${data.seq}`, {
      div: data.div,
      billto: data.billto,
      po_location: data.po_location,
      dilv_location: data.dilv_location,
      trucking_charge: data.trucking_charge,
      fsc_percent: data.fsc_percent || null,
      fsc_amt: data.fsc_amt,
      effective_date: data.effective_date,
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default updateARRate;
