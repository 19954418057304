import handleQueryError from '../../../utils/Common/Handler/handleQueryError';
import cusReportDefaultUrl from '../api';

const postMain = async ({ data }) => {
  try {
    const response = await cusReportDefaultUrl.post('main', data);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default postMain;
