import React, { useState } from 'react';
import { MAPPING_PAGE } from '../../../../constants/Admin/DivConf/adminDivConfElements';
import CommonButton from '../../../CommonButton/CommonButton';
import CommonAutoComplete from '../../../Inputs/CommonAutoComplete/CommonAutoComplete';
import CommonTextInput from '../../../Inputs/CommonTextInput/CommonTextInput';

const APTableRateContainer = ({
  apTableRate,
  apProductCodeOption,
  div,
  postProductCodeMutation,
  patchProductCodeMutation,
  deleteProductCodeMutation,
  isLoadingAPProductCode,
}) => {
  const [productCode, setProductCode] = useState({});
  const [isDeleted, setIsDeleted] = useState(false);

  const handleSubmit = ({ type, code, buttonRef }) => {
    const mappingPage = MAPPING_PAGE[type];
    if (code) {
      if (apTableRate?.mapping_page === mappingPage) {
        patchProductCodeMutation({
          seq: apTableRate?.seq,
          productCode: code?.value,
          buttonRef,
        });
        return;
      }
      postProductCodeMutation({
        div,
        productCode: code?.value,
        mappingPage,
        buttonRef,
      });
      return;
    }
    setIsDeleted(true);
    deleteProductCodeMutation({
      seq: apTableRate?.seq,
      productCode: apTableRate?.product_code,
      buttonRef,
    });
  };

  return (
    <div className="flex ml-[178px] mt-[67px] align-center">
      <div className="flex flex-col">
        <div className="text-stone-600 text-xs font-medium mb-[10px]">RATE</div>
        <div className="mt-[-22px] mb-[-10px]">
          <CommonAutoComplete
            inputName="apTableProductCode"
            placeholder="Service Code"
            width="w-[131px]"
            color="#264B9F"
            option={apProductCodeOption}
            defaultValue={apTableRate?.product_code}
            onChangeHandler={({ onChange, selectProps }) => {
              setIsDeleted(false);
              onChange?.(selectProps?.label?.split(' / ')[0]);
              setProductCode(selectProps);
            }}
            isLoading={isLoadingAPProductCode}
          />
        </div>
      </div>
      <div className="mt-[4px] ml-[7px]">
        <CommonTextInput
          inputName="apTableProductCodeName"
          placeholder="Service Code Name"
          width="w-[188px]"
          defaultValue={
            isDeleted ? '' : productCode?.name || apTableRate?.product_code_name
          }
          disabled
        />
      </div>
      <div className="w-full mt-[20px] ml-[7px]">
        <CommonButton
          category="complete"
          width="77px"
          preventDupClick
          onClick={({ buttonRef }) =>
            handleSubmit({ type: 'DIV_AP_TABLE', code: productCode, buttonRef })
          }
        >
          Apply
        </CommonButton>
      </div>
    </div>
  );
};

export default APTableRateContainer;
