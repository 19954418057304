import handleQueryError from '../../../utils/Common/Handler/handleQueryError';
import ediDefaultAPI from '../api';

const patch322 = async ({ data }) => {
  try {
    const response = await ediDefaultAPI.post(`322`, data);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patch322;
