import React from 'react';

const CompleteConfirmModalContent = ({ ...props }) => {
  const { isCompleted } = props;

  return (
    <div className="w-full min-w-[300px] min-h-[61px] h-fit bg-[#F9F9F9] flex justify-center items-center flex-col">
      <div className="flex justify-center items-center flex-col px-[20px]">
        <div className="text-[#222] text-[12px] leading-normal">
          Are you sure to <b>{isCompleted ? 'Incomplete' : 'Complete'}</b> this
          WO?
        </div>
      </div>
    </div>
  );
};

export default CompleteConfirmModalContent;
