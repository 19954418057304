import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import WEEK_DAY_LIST from '../../../constants/TmsReport/Schedules/weeklyDayList';
import { WO_CATEGORY_NAME } from '../../../constants/TmsReport/Common';

const useControlQueries = ({ divList, filterStorage }) => {
  /** Save Filter */
  const { filter, setFilter } = filterStorage;
  const [isStorageFilterExist, setIsStorageFilterExist] = useState(!!filter);

  /** Date Query */
  const [dateQuery, setDateQuery] = useState(
    filter?.date && dayjs?.(filter?.date)?.isValid()
      ? dayjs?.(filter?.date)?.format('YYYY-MM-DD')
      : dayjs().format('YYYY-MM-DD'),
  );

  /** Weekly Date Query */
  const [weeklyDateQuery, setWeeklyDateQuery] = useState({});
  useEffect(() => {
    const currentFilterDate =
      dateQuery && dayjs?.(dateQuery)?.isValid() ? dayjs?.(dateQuery) : '';

    if (currentFilterDate) {
      /** Set the first day of the week to Sunday */
      const startOfWeek = currentFilterDate?.startOf('week');

      const tempWeeklyDate = WEEK_DAY_LIST.reduce((acc, day, index) => {
        acc[day] = startOfWeek.add(index, 'day').format('YYYY-MM-DD');
        return acc;
      }, {});

      setWeeklyDateQuery(tempWeeklyDate);
    }
  }, [dateQuery]);

  /** Schedule Type Query */
  const [scheduleTypeQuery, setScheduleTypeQuery] = useState({
    DROP:
      typeof filter?.['sche-type']?.DROP === 'boolean'
        ? filter?.['sche-type']?.DROP
        : true,
    LIVE:
      typeof filter?.['sche-type']?.LIVE === 'boolean'
        ? filter?.['sche-type']?.LIVE
        : true,
    LVAN:
      typeof filter?.['sche-type']?.LVAN === 'boolean'
        ? filter?.['sche-type']?.LVAN
        : true,
  });

  /** Division Query */
  const defaultDiv = localStorage.getItem('defaultDiv');

  const [divisionQuery, setDivisionQuery] = useState({
    ...Object.fromEntries(
      divList?.map(div => [
        div,
        typeof filter?.div?.[div] === 'boolean'
          ? filter?.div?.[div]
          : div === defaultDiv,
      ]),
    ),
  });

  /** WO Category Query */
  const [categoryQuery, setCategoryQuery] = useState({
    [WO_CATEGORY_NAME?.IMPORT]:
      typeof filter?.category?.[WO_CATEGORY_NAME?.IMPORT] === 'boolean'
        ? filter?.category?.[WO_CATEGORY_NAME?.IMPORT]
        : true,
    [WO_CATEGORY_NAME?.EXPORT]:
      typeof filter?.category?.[WO_CATEGORY_NAME?.EXPORT] === 'boolean'
        ? filter?.category?.[WO_CATEGORY_NAME?.EXPORT]
        : true,
    [WO_CATEGORY_NAME?.VAN]:
      typeof filter?.category?.[WO_CATEGORY_NAME?.VAN] === 'boolean'
        ? filter?.category?.[WO_CATEGORY_NAME?.VAN]
        : true,
    [WO_CATEGORY_NAME?.BROKERAGE]:
      typeof filter?.category?.[WO_CATEGORY_NAME?.BROKERAGE] === 'boolean'
        ? filter?.category?.[WO_CATEGORY_NAME?.BROKERAGE]
        : true,
    [WO_CATEGORY_NAME?.BARE_CHASSIS]:
      typeof filter?.category?.[WO_CATEGORY_NAME?.BARE_CHASSIS] === 'boolean'
        ? filter?.category?.[WO_CATEGORY_NAME?.BARE_CHASSIS]
        : true,
  });

  /** Set All Query */
  const [allQueries, setAllQueries] = useState({
    'sche-type': scheduleTypeQuery,
    div: divisionQuery,
    category: categoryQuery,
    date: dateQuery,
  });

  useEffect(() => {
    setAllQueries(prev => ({ ...prev, date: dateQuery }));
  }, [dateQuery]);

  useEffect(() => {
    setAllQueries(prev => ({
      ...prev,
      'sche-type': { ...scheduleTypeQuery },
      div: { ...divisionQuery },
    }));
  }, [scheduleTypeQuery, divisionQuery]);

  useEffect(() => {
    setAllQueries(prev => ({
      ...prev,
      'sche-type': {
        ...scheduleTypeQuery,
        LVAN: categoryQuery?.V === false ? false : prev?.['sche-type']?.LVAN,
      },
      category: { ...categoryQuery },
    }));
  }, [categoryQuery]);

  useEffect(() => {
    const isExistFilterStorage = filterStorage && !isStorageFilterExist;
    const isExistSearchQueries = Object.keys(allQueries).length > 0;

    if (isExistFilterStorage && isExistSearchQueries) {
      Object.keys(allQueries).forEach(key => {
        setFilter(prev => {
          const isStringQueryKey = typeof allQueries?.[key] === 'string';

          return {
            ...prev,
            [key]: isStringQueryKey
              ? allQueries?.[key]
              : {
                  ...allQueries[key],
                },
          };
        });
      });
      if (
        Object.keys(allQueries).includes('sche-type') &&
        Object.keys(allQueries).includes('category') &&
        Object.keys(allQueries).includes('div') &&
        Object.keys(allQueries).includes('date')
      )
        setIsStorageFilterExist(prev => true);
    }
  }, [allQueries]);

  return {
    setScheduleTypeQuery,
    setDivisionQuery,
    setCategoryQuery,
    setDateQuery,
    weeklyDateQuery,
    allQueries,
  };
};

export default useControlQueries;
