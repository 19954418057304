import createCustomerReportColDefs from './createCustomerReportColDefs';

const generateCustomerColumn = ({ customerColumn }) => {
  const newColumn = [];

  customerColumn
    ?.forEach(column => {
      // Find in flat
      const flatColumn = createCustomerReportColDefs?.find(
        item => item?.field === column,
      );
      if (flatColumn) newColumn?.push(flatColumn);

      // Find in Group
      const groupedColumn = createCustomerReportColDefs?.filter(
        item => item?.isColumnGroup,
      );

      if (groupedColumn) {
        groupedColumn?.forEach(group => {
          const childColumn = group?.children?.find(
            child => child?.field === column,
          );
          if (childColumn) {
            if (newColumn?.find(item => item?.field === group?.field)) {
              const existingGroup = newColumn?.find(
                item => item?.field === group?.field,
              );
              existingGroup?.children?.push(childColumn);
            } else {
              newColumn?.push({
                ...group,
                children: [childColumn],
              });
            }
          }
        });
      }
    })
    ?.filter(column => column);

  return newColumn;
};

export default generateCustomerColumn;
