import bcDetailDefaultApi from '../../../api';
import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';

const postDetailEmail = async ({ woNo, data }) => {
  try {
    const response = await bcDetailDefaultApi.post(`${woNo}/email`, data);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default postDetailEmail;
