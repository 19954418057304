export const DRIVER_POSITION_OPTION = [
  { label: 'Select', value: '' },
  { label: 'Company', value: 'DR0001' },
  { label: 'Owner', value: 'DR0002' },
  { label: 'Carrier', value: 'DR0003' },
];

export const DRIVER_LEGAL_STATUS_OPTION = [
  { label: 'Citizenship', value: 'DRL001' },
  { label: 'Permanent Resident', value: 'DRL002' },
  { label: 'Work Permit', value: 'DRL003' },
];

export const DRIVER_ONR_CRR_TYPE_OPTION = [
  { label: 'IND/SOLE', value: 'DRO001' },
  { label: 'C CORP', value: 'DRO002' },
  { label: 'S CORP', value: 'DRO003' },
  { label: 'PART', value: 'DRO004' },
  { label: 'TRUST', value: 'DRO005' },
  { label: 'LLC', value: 'DRO006' },
];

export const DRIVER_GENDER_OPTION = [
  { label: 'Female', value: 'DG0001' },
  { label: 'Male', value: 'DG0002' },
  { label: 'Other', value: 'DG0003' },
  { label: 'Declined to provide', value: 'DG0004' },
];

export const DRIVER_MARITAL_STATUS_OPTION = [
  { label: 'Single', value: 'DM0001' },
  { label: 'Married', value: 'DM0002' },
  { label: 'Other', value: 'DM0003' },
  { label: 'Declined to provide', value: 'DM0004' },
];

export const DRIVER_RACE_OPTION = [
  { label: 'African', value: 'RC0003' },
  { label: 'Asian', value: 'RC0001' },
  { label: 'Caucasion', value: 'RC0002' },
  { label: 'Hispanic/Latino', value: 'RC0004' },
  { label: 'Native American', value: 'RC0005' },
  { label: 'Pacific Islander', value: 'RC0006' },
  { label: 'Other', value: 'RC0007' },
  { label: 'Declined to provide', value: 'RC0008' },
];
