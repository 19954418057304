import React from 'react';
import CommonMessageModal from '../CommonMessageModal/CommonMessageModal';
import CommonButton from '../CommonButton/CommonButton';
import StyledSignInCommonAlertMessageWrapper from '../../styles/SignIn/AlertMessage/StyledSignInCommonAlertMessageWrapper';
import StyledSignInCommonAlertMessageButton from '../../styles/SignIn/AlertMessage/StyledSignInCommonAlertMessageButton';

const AlertMessage = ({ isOpen, setIsOpen, message }) => {
  return (
    <CommonMessageModal
      header="Alert Message"
      content={
        <StyledSignInCommonAlertMessageWrapper>
          {message}
        </StyledSignInCommonAlertMessageWrapper>
      }
      buttonContent={
        <StyledSignInCommonAlertMessageButton>
          <CommonButton
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            category="gotIt"
          >
            OK
          </CommonButton>
        </StyledSignInCommonAlertMessageButton>
      }
      open={isOpen}
      setOpen={setIsOpen}
    />
  );
};

export default AlertMessage;
