const DAILY_REPORT_PULL_OUT_FIELDS = [
  'no',
  'category',
  'billto',
  'mbl_no',
  'equipment',
  'ssl',
  'size',
  'type',
  'wgt',
  'ch_no',
  'pool',
  'lfd',
  'po_group',
  'dilv_group',
  'hazmat',
  'remarks',
];

const DAILY_REPORT_DELIVERY_FIELDS = [
  'no',
  'category',
  'billto',
  'mbl_no',
  'equipment',
  'ssl',
  'size',
  'type',
  'wgt',
  'ch_no',
  'pool',
  'po_group',
  'dilv_group',
  'sche_type',
  'hazmat',
  'remarks',
];

const DAILY_REPORT_RETURN_FIELDS = [
  'no',
  'category',
  'billto',
  'mbl_no',
  'equipment',
  'ssl',
  'size',
  'wgt',
  'ch_no',
  'pool',
  'rfd',
  'erd',
  'cutoff',
  'empty_load_cfm',
  'po_loc',
  'rtn_group',
  'hazmat',
  'remarks',
];

const DAILY_REPORT_RETURN_YARD_FIELDS = [
  'no',
  'div',
  'category',
  'billto',
  'bk_no',
  'cont_no',
  'ssl',
  'size',
  'wgt',
  'ch_no',
  'pool',
  'rfd',
  'erd',
  'cutoff',
  'empty_load_cfm',
  'po_loc',
  'rtn_group',
  'hazmat',
  'remarks',
];

const DAILY_REPORT_COLUMNS = {
  pull_out: DAILY_REPORT_PULL_OUT_FIELDS,
  delivery: DAILY_REPORT_DELIVERY_FIELDS,
  return: DAILY_REPORT_RETURN_FIELDS,
  return_yard: DAILY_REPORT_RETURN_YARD_FIELDS,
};

export default DAILY_REPORT_COLUMNS;
