import signInAPI from '../api';
import handleQueryError from '../../../utils/Common/Handler/handleQueryError';

export const logout = async () => {
  try {
    const response = await signInAPI.get('logout');
    return response.data;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};
