import React from 'react';

const LocationRowHeader = ({ ...props }) => {
  return (
    <div className="flex justify-center items-center flex-col gap-[10px]">
      <div className="w-full flex justify-end items-end flex-row">
        <div
          className="flex justify-center flex-col
                      text-[12px] font-normal gap-[2px]"
        >
          <div className="flex items-center flex-row w-full gap-[5px]">
            <div className="h-[10px] w-[10px] bg-[#D0EACE]" />
            <div>P/U FROM</div>
          </div>
          <div className="flex items-center flex-row w-full gap-[5px]">
            <div className="h-[10px] w-[10px] bg-[#F6C6AD]" />
            <div>DILV TO</div>
          </div>
        </div>
      </div>
      <div
        className="flex justify-center items-center flex-row h-[28px]
                    text-[11px] text-[#222] font-medium gap-[2px]"
      >
        <div className="w-[80px] h-full" />
        <div className="w-[99px] h-full flex justify-center items-center">
          LOCATION ID
        </div>
        <div className="w-[99px] h-full flex justify-center items-center">
          CITY
        </div>
        <div className="w-[74px] h-full flex justify-center items-center">
          STATE
        </div>
        <div className="w-[74px] h-full flex justify-center items-center">
          TIME ZONE
        </div>
        <div className="w-[112px] h-full flex justify-center items-center">
          SCHEDULE DATE
        </div>
      </div>
    </div>
  );
};

export default LocationRowHeader;
