const handleScheduleDelete = ({ scheduleSeq, deleteMutator, buttonRef }) => {
  const scheduleData = {
    schedule: [
      {
        method: 'DELETE',
        seq: scheduleSeq,
      },
    ],
  };

  deleteMutator({ data: scheduleData, buttonRef });
};

export default handleScheduleDelete;
