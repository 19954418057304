import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import getCompanyIDList from '../../../services/Common/Common/getCompanyIDList';
import dailyReportKeys from '../keys';

const useGetYardList = ({
  setYardList,
  setErrorMessage,
  setIsErrorModalOpen,
}) => {
  const result = useQuery({
    queryKey: dailyReportKeys.yardAll,
    queryFn: () => getCompanyIDList({ urlParams: '?category=8' }),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!result?.isError && result?.isSuccess)
      setYardList(result?.data?.data?.map(company => company?.company) || []);
  }, [result?.isError, result?.isSuccess]);

  useEffect(() => {
    if (result?.isError) {
      setIsErrorModalOpen(true);
      setErrorMessage(result?.error?.message);
    }
  }, [result?.isError]);

  return result;
};

export default useGetYardList;
