import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useGetLocationInfo = () => {
  /** Get current URL using hook */
  const location = useLocation();

  /** Location info */
  const [locationInfo, setLocationInfo] = useState({
    woNo: null,
    woType: null,
    detailType: null,
  });

  /** Apply location info according to URL */
  useEffect(() => {
    if (location?.pathname) {
      const splitPathName = location?.pathname?.split('/');
      setLocationInfo(prev => ({
        ...prev,
        woNo: splitPathName[splitPathName?.length - 1],
        woType: splitPathName[splitPathName?.length - 2],
        detailType: splitPathName[splitPathName?.length - 3],
      }));
    }
  }, [location]);

  return { ...locationInfo };
};

export default useGetLocationInfo;
