import React, { useEffect } from 'react';
import StyledNormalCellRenderer from '../../../../styles/Common/CommonDatagrid/NormalCellRenderer/StyledNormalCellRenderer';
import StyledNormalCellValueRenderer from '../../../../styles/Common/CommonDatagrid/NormalCellRenderer/StyledNormalCellValueRenderer';
import StyledNormalCellAdornmentRenderer from '../../../../styles/Common/CommonDatagrid/NormalCellRenderer/StyledNormalCellAdornmentRenderer';

/**
 * @description
 * Normal cell renderer
 * @param {*} param0
 * @returns
 * Normal cell renderer
 */
const NormalCellRenderer = ({ customStyle, adornment, ...props }) => {
  /** Get cell props */
  const { value: cellValue, gridParam } = props;

  return (
    <StyledNormalCellRenderer {...customStyle} {...gridParam}>
      {adornment?.position === 'start' && (
        <StyledNormalCellAdornmentRenderer
          adornmentPosition={adornment?.position}
          {...customStyle}
        >
          {adornment?.content}
        </StyledNormalCellAdornmentRenderer>
      )}
      <StyledNormalCellValueRenderer {...customStyle}>
        {cellValue}
      </StyledNormalCellValueRenderer>
      {adornment?.position === 'end' && (
        <StyledNormalCellAdornmentRenderer
          adornmentPosition={adornment?.position}
          {...customStyle}
        >
          {adornment?.content}
        </StyledNormalCellAdornmentRenderer>
      )}
    </StyledNormalCellRenderer>
  );
};

export default NormalCellRenderer;
