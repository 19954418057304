import React from 'react';

const StatusCellRenderer = ({ ...props }) => {
  const status = props?.data?.confirm_status;
  if (status === 'APPROVED') {
    return <div className="font-extrabold text-[#002265]">APPROVED</div>;
  }
  if (status === 'IN REVIEW') {
    return <div className="font-extrabold text-[#404040]">IN REVIEW</div>;
  }
  if (status === 'REJECTED') {
    return <div className="font-extrabold text-[#841818]">REJECTED</div>;
  }
  return null;
};

export default StatusCellRenderer;
