const getCurrentDateWithTimezone = ({ timezone }) => {
  const options = {
    timeZone: timezone,
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  };

  const formatter = new Intl.DateTimeFormat('en-US', options);
  const parts = formatter.formatToParts(new Date());

  const month = parts.find(part => part.type === 'month').value;
  const day = parts.find(part => part.type === 'day').value;
  const year = parts.find(part => part.type === 'year').value;

  return `${month}/${day}/${year}`;
};

export default getCurrentDateWithTimezone;
