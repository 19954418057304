const generateQueryParams = ({ queryObject }) => {
  const params = { ...queryObject };

  if (!params?.order) {
    params.order = 'time';
  }

  if (params?.searchedCategory) {
    params[params?.searchedCategory] = params?.keyword || '';
  }

  ['searchedCategory', 'keyword', 'period']?.forEach(key => {
    params[key] = undefined;
  });

  return params;
};

export default generateQueryParams;
