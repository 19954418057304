import { createSlice } from '@reduxjs/toolkit';

const getInitialState = () => {
  const savedNowDiv =
    sessionStorage.getItem('nowDiv') || localStorage.getItem('defaultDiv');
  return savedNowDiv !== null ? savedNowDiv : '';
};

const nowDivSlice = createSlice({
  name: 'nowDiv',
  initialState: getInitialState(),
  reducers: {
    saveNowDiv: (state, action) => {
      const newState = action.payload;
      sessionStorage.setItem('nowDiv', newState);
      return newState;
    },
  },
});

export const { saveNowDiv } = nowDivSlice.actions;
export default nowDivSlice.reducer;
