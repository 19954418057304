import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import useDeleteAPData from '../../../../../hooks/BCDetail/BillingInfo/AP/useDeleteAPData';
import usePatchAPData from '../../../../../hooks/BCDetail/BillingInfo/AP/usePatchAPData';
import StyledBCDetailBillingInfoCommonRowCell from '../../../../../styles/BCDetail/BillingInfo/Common/TableRow/StyledBCDetailBillingInfoCommonRowCell';
import StyledBCDetailBillingInfoCommonRowContainer from '../../../../../styles/BCDetail/BillingInfo/Common/TableRow/StyledBCDetailBillingInfoCommonRowContainer';
import StyledBCDetailBillingInfoCommonRowInputContainer from '../../../../../styles/BCDetail/BillingInfo/Common/TableRow/StyledBCDetailBillingInfoCommonRowInputContainer';
import StyledBCDetailBillingInfoCommonRowQBContainer from '../../../../../styles/BCDetail/BillingInfo/Common/TableRow/StyledBCDetailBillingInfoCommonRowQBContainer';
import StyledBCDetailBillingInfoCommonRowRateCfmButton from '../../../../../styles/BCDetail/BillingInfo/Common/TableRow/StyledBCDetailBillingInfoCommonRowRateCfmButton';
import judgeCheckboxRender from '../../../../../utils/BCDetail/BillingInfo/AP/Handler/judgeCheckboxRender';
import onAPGroupRowsByGroupIdHandler from '../../../../../utils/BCDetail/BillingInfo/AP/Handler/onAPGroupRowsByGroupIdHandler';
import onAPSubmitHandler from '../../../../../utils/BCDetail/BillingInfo/AP/Handler/onAPSubmitHandler';
import formatNumber from '../../../../../utils/BCDetail/BillingInfo/Common/formatNumber';
import onDateInputDefaultChangeHandler from '../../../../../utils/BCDetail/Common/Handler/onDateInputDefaultChangeHandler';
import onInputDefaultChangeHandler from '../../../../../utils/BCDetail/Common/Handler/onInputDefaultChangeHandler';
import onRowCheckboxGroupingHandler from '../../../../../utils/BCDetail/Common/Handler/onRowCheckboxGroupingHandler';
import {
  createCompanySelectOption,
  createDriverSelectOption,
  createProductCodeSelectOption,
} from '../../../../../utils/Common/createCodeSelectOption';
import updateButtonDisabled from '../../../../../utils/Common/Handler/updateButtonDisabled';
import APRowDate from '../../../../BCDetail/BillingInfo/APRowDate';
import Input from '../../../../BCDetail/BillingInfo/Input';
import Tooltip from '../../../../BCDetail/BillingInfo/Tooltip';
import ControlMuiCheckBox from '../../../../BCDetail/Inputs/ControlMuiCheckBox';
import CommonButton from '../../../../CommonButton/CommonButton';
import CommonAutoComplete from '../../../../SecondInputs/CommonAutoComplete/CommonAutoComplete';
import CommonSelect from '../../../../SecondInputs/CommonSelect/CommonSelect';

const APTableRow = ({
  row,
  APData,
  APProductCodeQuery,
  APDriverVendorList,
  setPaymentModal,
  setSelectedDriver,
  setRateCFMModal,
  setIsAlertOpen,
  setIsSuccess,
  setErrorMessage,
  setIsCompanyDriver,
  setIsQBDisabled,
  setIsCreateBillNoDisabled,
  isAPEditable,
  isDeletable,
  refetchAPData,
}) => {
  const { mutate: apDelMutator } = useDeleteAPData({
    setIsAlertOpen,
    setIsSuccess,
    setErrorMessage,
    refetchAPData,
  });
  const { mutate: apModMutator } = usePatchAPData({
    setIsAlertOpen,
    setIsSuccess,
    setErrorMessage,
    refetchAPData,
  });
  const [modCategory, setModCategory] = useState(row?.category);
  const { setValue, handleSubmit } = useFormContext();
  const [driverName, setDriverName] = useState(
    modCategory === 'DRIVER' ? row?.driver_name : row?.vendor_name,
  );
  const [acctCodeName, setAcctCodeName] = useState(row?.product_code_name);

  const saveRef = useRef(null);

  const groupedRows = onAPGroupRowsByGroupIdHandler(APData);

  const isFirstInGroup =
    groupedRows[row.group_id] && groupedRows[row.group_id][0] === row;

  const singleRowHeight = 30;
  const gapBetweenRows = 2;

  const groupHeight =
    groupedRows[row.group_id]?.length * singleRowHeight +
    (groupedRows[row.group_id]?.length - 1) * gapBetweenRows;

  const groupMargin =
    groupedRows[row.group_id]?.length > 1
      ? (groupedRows[row.group_id]?.length - 1) *
        (singleRowHeight + gapBetweenRows)
      : 0;

  const [isTotalDiff, setIsTotalDiff] = useState(false);

  useEffect(() => {
    if (row?.qb_id) {
      if (formatNumber(row?.ap_total) !== formatNumber(row?.bill_amt))
        setIsTotalDiff(true);
      else setIsTotalDiff(false);
    }
  }, [row?.qb_id, row?.ap_total, row?.bill_amt]);

  const onAPBodySubmitHandler = ({ buttonRef }) => {
    updateButtonDisabled({ ref: buttonRef, disabled: true });
    onAPSubmitHandler({
      handleSubmit,
      submitType: 'MOD',
      apMutator: apModMutator,
      seq: row?.seq,
      APProductCodeQuery,
      setIsAlertOpen,
      setErrorMessage,
      setIsSuccess,
      buttonRef,
    });
  };

  const onAPBodyDeleteHandler = ({ buttonRef }) => {
    onAPSubmitHandler({
      handleSubmit,
      submitType: 'DEL',
      apMutator: apDelMutator,
      seq: row?.seq,
      setIsSuccess,
      buttonRef,
    });
  };

  return (
    <StyledBCDetailBillingInfoCommonRowContainer>
      <StyledBCDetailBillingInfoCommonRowCell width="108px">
        <StyledBCDetailBillingInfoCommonRowInputContainer>
          <APRowDate
            placeholder="MM/DD/YY"
            inputName={`mod.inv_date_${row?.seq}`}
            defaultValue={row?.bill_date}
            disabled={!isAPEditable}
            onChangeHandler={onDateInputDefaultChangeHandler}
          />
        </StyledBCDetailBillingInfoCommonRowInputContainer>
      </StyledBCDetailBillingInfoCommonRowCell>
      <StyledBCDetailBillingInfoCommonRowCell width="85px">
        <CommonSelect
          inputName={`mod.category_${row?.seq}`}
          backgroundColor="#F2F4F8"
          defaultValue={row?.category}
          option={[
            {
              value: 'VENDOR',
              label: 'VENDOR',
            },
            {
              value: 'DRIVER',
              label: 'DRIVER',
            },
          ]}
          onChangeHandler={({ e, onChange, ...props }) => {
            onChange?.(e);
            setModCategory(e.target?.value);
          }}
          disabled
          disabledColor="#E9EAEB"
        />
      </StyledBCDetailBillingInfoCommonRowCell>
      <StyledBCDetailBillingInfoCommonRowCell width="88px">
        <CommonAutoComplete
          inputName={
            modCategory === 'DRIVER'
              ? `mod.driver_${row?.seq}`
              : `mod.ven_${row?.seq}`
          }
          backgroundColor="#F2F4F8"
          defaultValue={modCategory === 'DRIVER' ? row?.driver : row?.vendor}
          option={
            modCategory === 'DRIVER'
              ? createDriverSelectOption(APDriverVendorList?.driverList)
              : createCompanySelectOption(APDriverVendorList?.vendorList)
          }
          onChangeHandler={({ e, onChange, ...props }) => {
            setSelectedDriver?.(props.selectProps?.value);
            onChange?.(props.selectProps?.value);
            const getOptions =
              modCategory === 'DRIVER'
                ? APDriverVendorList?.driverList
                : APDriverVendorList?.vendorList;
            const getDriverName =
              modCategory === 'DRIVER'
                ? getOptions.find(
                    element => element?.id === props.selectProps?.value,
                  )?.name
                : getOptions.find(
                    element => element?.company === props.selectProps?.value,
                  )?.business_name;
            setValue(`mod.driver_name_${row?.seq}`, getDriverName);
            setDriverName(getDriverName);
            if (modCategory === 'DRIVER' && props.selectProps?.value)
              setPaymentModal(true);
          }}
          disabled={!!row?.bill_no || !isAPEditable}
          disabledColor="#DDE2EC"
          isLoading={
            modCategory === 'DRIVER'
              ? APDriverVendorList?.driverQuery?.isLoading
              : APDriverVendorList?.vendorQuery?.isLoading
          }
        />
      </StyledBCDetailBillingInfoCommonRowCell>
      <StyledBCDetailBillingInfoCommonRowCell width="143px">
        <StyledBCDetailBillingInfoCommonRowInputContainer backgroundColor="#E9EAEB">
          <Input
            inputName={`mod.driver_name_${row?.seq}`}
            defaultValue={driverName}
            disabled
          />
        </StyledBCDetailBillingInfoCommonRowInputContainer>
      </StyledBCDetailBillingInfoCommonRowCell>
      <StyledBCDetailBillingInfoCommonRowCell width="30px">
        <StyledBCDetailBillingInfoCommonRowInputContainer backgroundColor="#E9EAEB">
          <div className="relative w-[130px] group/driverConfirmDate">
            <ControlMuiCheckBox
              inputName={`mod.driver_confirm_date${row?.seq}`}
              defaultValue={row?.driver_confirm_date}
              disabled
            />
            <div className="absolute z-10 top-6 left-1 group-hover/driverConfirmDate:block hidden">
              <Tooltip
                message={
                  row?.driver_confirm_date
                    ? dayjs(row?.driver_confirm_date).format('MM/DD/YY HH:mm')
                    : ''
                }
              />
            </div>
          </div>
        </StyledBCDetailBillingInfoCommonRowInputContainer>
      </StyledBCDetailBillingInfoCommonRowCell>
      <StyledBCDetailBillingInfoCommonRowCell width="85px">
        <CommonAutoComplete
          inputName={`mod.product_code_${row?.seq}`}
          backgroundColor="#F2F4F8"
          defaultValue={row?.product_code}
          option={createProductCodeSelectOption(
            APProductCodeQuery?.productCode,
          )}
          disabled={!isAPEditable}
          onChangeHandler={({ e, onChange, ...props }) => {
            onChange?.(props.selectProps?.value);
            const getProductCodeName = APProductCodeQuery?.productCode?.find(
              element => element?.product_code === props.selectProps?.value,
            )?.product_code_name;
            setValue(`mod.product_code_name_${row?.seq}`, getProductCodeName);
            setAcctCodeName(getProductCodeName);
          }}
          isLoading={APProductCodeQuery?.isLoading}
        />
      </StyledBCDetailBillingInfoCommonRowCell>
      <StyledBCDetailBillingInfoCommonRowCell width="130px">
        <StyledBCDetailBillingInfoCommonRowInputContainer backgroundColor="#E9EAEB">
          <Input
            inputName={`mod.product_code_name_${row?.seq}`}
            defaultValue={acctCodeName}
            disabled
          />
        </StyledBCDetailBillingInfoCommonRowInputContainer>
      </StyledBCDetailBillingInfoCommonRowCell>

      {((isFirstInGroup || row?.group_id === null) && (
        <StyledBCDetailBillingInfoCommonRowCell
          width="94px"
          marginTop={`${groupMargin}px`}
        >
          <StyledBCDetailBillingInfoCommonRowInputContainer
            backgroundColor="#F2F4F8"
            height={`${groupHeight || 30}px`}
          >
            <StyledBCDetailBillingInfoCommonRowRateCfmButton
              type="button"
              name="RATE CFM"
              disabled={!isAPEditable}
              onClick={() => {
                setRateCFMModal(true);
                setSelectedDriver(row?.seq);
              }}
            >
              RATE CFM
            </StyledBCDetailBillingInfoCommonRowRateCfmButton>
          </StyledBCDetailBillingInfoCommonRowInputContainer>
        </StyledBCDetailBillingInfoCommonRowCell>
      )) || <StyledBCDetailBillingInfoCommonRowCell width="94px" />}
      <StyledBCDetailBillingInfoCommonRowCell width="90px">
        <StyledBCDetailBillingInfoCommonRowInputContainer backgroundColor="#F2F4F8">
          <Input
            inputName={`mod.amt_${row?.seq}`}
            defaultValue={row?.amt}
            dollarSign
            textAlign="center"
            maxLength={12}
            isInt
            disabled={!isAPEditable}
            onChangeHandler={({
              e,
              onChange,
              setText,
              setTextLength,
              maxTextLength,
              ...props
            }) => {
              const inputText = e?.target?.value;
              if (/^-?\d*(\.\d{0,2})?$/.test(inputText)) {
                if (inputText.length <= maxTextLength) {
                  setText(inputText);
                  onChange(inputText);
                  setTextLength(inputText.length);
                }
              }
            }}
            onKeyDownHandler={e => {
              if (e?.e?.key === 'Enter')
                onAPBodySubmitHandler({ buttonRef: saveRef });
            }}
          />
        </StyledBCDetailBillingInfoCommonRowInputContainer>
      </StyledBCDetailBillingInfoCommonRowCell>
      <StyledBCDetailBillingInfoCommonRowCell width="140px">
        <StyledBCDetailBillingInfoCommonRowInputContainer backgroundColor="#F2F4F8">
          <Input
            inputName={`mod.desc_${row?.seq}`}
            defaultValue={row?.description}
            maxLength={128}
            disabled={!isAPEditable}
            onChangeHandler={onInputDefaultChangeHandler}
            onKeyDownHandler={e => {
              if (e?.e?.key === 'Enter')
                onAPBodySubmitHandler({ buttonRef: saveRef });
            }}
          />
        </StyledBCDetailBillingInfoCommonRowInputContainer>
      </StyledBCDetailBillingInfoCommonRowCell>
      {((isFirstInGroup || row?.group_id === null) && (
        <StyledBCDetailBillingInfoCommonRowCell
          width="90px"
          marginTop={`${groupMargin}px`}
        >
          <StyledBCDetailBillingInfoCommonRowInputContainer
            backgroundColor={isTotalDiff ? '#FF9292' : '#E9EAEB'}
            height={`${groupHeight || 30}px`}
          >
            <Input
              inputName={`ap.total_${row?.ap_total}`}
              defaultValue={formatNumber(row?.ap_total)}
              dollarSign
              textAlign="center"
              disabled
            />
          </StyledBCDetailBillingInfoCommonRowInputContainer>
        </StyledBCDetailBillingInfoCommonRowCell>
      )) || <StyledBCDetailBillingInfoCommonRowCell width="90px" />}
      <StyledBCDetailBillingInfoCommonRowCell width="90px">
        {modCategory === 'VENDOR' && !row?.bill_no ? (
          <StyledBCDetailBillingInfoCommonRowInputContainer backgroundColor="#F2F4F8">
            <Input
              inputName={`mod.bill_no_${row?.seq}`}
              defaultValue={row?.bill_no}
              onChangeHandler={onInputDefaultChangeHandler}
              disabled={!!row?.group_id || !isAPEditable}
              onKeyDownHandler={e => {
                if (e?.e?.key === 'Enter')
                  onAPBodySubmitHandler({ buttonRef: saveRef });
              }}
            />
          </StyledBCDetailBillingInfoCommonRowInputContainer>
        ) : (
          <StyledBCDetailBillingInfoCommonRowInputContainer backgroundColor="#E9EAEB">
            <Input
              inputName={`mod.bill_no_${row?.seq}`}
              defaultValue={row?.bill_no}
              disabled
            />
          </StyledBCDetailBillingInfoCommonRowInputContainer>
        )}
      </StyledBCDetailBillingInfoCommonRowCell>
      <StyledBCDetailBillingInfoCommonRowCell width="30px">
        <StyledBCDetailBillingInfoCommonRowInputContainer backgroundColor="#F2F4F8">
          {judgeCheckboxRender({ row }) && (
            <ControlMuiCheckBox
              inputName={`mod.checked_${row?.seq}`}
              disabled={
                !isAPEditable ||
                (row?.driver_category === 'DR0001' && row?.qb_date)
              }
              onChangeHandler={({ e, onChange, ...props }) => {
                onRowCheckboxGroupingHandler({
                  event: e,
                  data: APData,
                  selectedRow: row,
                  apType: row?.category,
                  billingType: 'AP',
                  setValue,
                  setIsQBDisabled,
                  setIsCreateBillNoDisabled,
                  setIsCompanyDriver,
                });
                onChange?.(e?.target?.checked);
                if (e?.target?.checked && row?.driver_category === 'DR0001')
                  setIsCompanyDriver(true);
                else setIsCompanyDriver(false);
              }}
            />
          )}
        </StyledBCDetailBillingInfoCommonRowInputContainer>
      </StyledBCDetailBillingInfoCommonRowCell>
      {((isFirstInGroup || row?.group_id === null) && (
        <>
          <StyledBCDetailBillingInfoCommonRowCell
            width="90px"
            marginTop={`${groupMargin}px`}
          >
            <StyledBCDetailBillingInfoCommonRowQBContainer
              height={`${groupHeight || 30}px`}
            >
              <Input
                inputName={`mod.qb_${row?.seq}`}
                defaultValue={row?.qb_id}
                textAlign="center"
                disabled
              />
            </StyledBCDetailBillingInfoCommonRowQBContainer>
          </StyledBCDetailBillingInfoCommonRowCell>
          <StyledBCDetailBillingInfoCommonRowCell
            width="90px"
            marginTop={`${groupMargin}px`}
          >
            <StyledBCDetailBillingInfoCommonRowQBContainer
              height={`${groupHeight || 30}px`}
              backgroundColor={isTotalDiff && '#FF9292'}
            >
              <Input
                inputName={`mod.bill_amt_${row?.seq}`}
                defaultValue={row?.bill_amt}
                dollarSign
                textAlign="center"
                disabled
              />
            </StyledBCDetailBillingInfoCommonRowQBContainer>
          </StyledBCDetailBillingInfoCommonRowCell>
          <StyledBCDetailBillingInfoCommonRowCell
            width="90px"
            marginTop={`${groupMargin}px`}
          >
            <StyledBCDetailBillingInfoCommonRowQBContainer
              height={`${groupHeight || 30}px`}
            >
              <Input
                inputName={`mod.paid_amt_${row?.seq}`}
                defaultValue={row?.paid_amt}
                dollarSign
                textAlign="center"
                disabled
              />
            </StyledBCDetailBillingInfoCommonRowQBContainer>
          </StyledBCDetailBillingInfoCommonRowCell>
          <StyledBCDetailBillingInfoCommonRowCell
            width="90px"
            marginTop={`${groupMargin}px`}
          >
            <StyledBCDetailBillingInfoCommonRowQBContainer
              height={`${groupHeight || 30}px`}
            >
              <Input
                inputName={`mod.qb_date_${row?.seq}`}
                defaultValue={
                  row?.qb_date ? dayjs(row?.qb_date).format('MM/DD/YY') : ''
                }
                textAlign="center"
                disabled
              />
            </StyledBCDetailBillingInfoCommonRowQBContainer>
          </StyledBCDetailBillingInfoCommonRowCell>
        </>
      )) || (
        <>
          <StyledBCDetailBillingInfoCommonRowCell width="90px" />
          <StyledBCDetailBillingInfoCommonRowCell width="90px" />
          <StyledBCDetailBillingInfoCommonRowCell width="90px" />
          <StyledBCDetailBillingInfoCommonRowCell width="90px" />
        </>
      )}
      <StyledBCDetailBillingInfoCommonRowCell width="88px">
        <StyledBCDetailBillingInfoCommonRowInputContainer backgroundColor="#E9EAEB">
          <Input
            inputName={`mod.updated_by_${row?.seq}`}
            defaultValue={row?.updated_by}
            textAlign="center"
            disabled
          />
        </StyledBCDetailBillingInfoCommonRowInputContainer>
      </StyledBCDetailBillingInfoCommonRowCell>
      {isAPEditable && (
        <StyledBCDetailBillingInfoCommonRowCell width="79px">
          <CommonButton
            ref={saveRef}
            type="button"
            borderColor="#556DA1"
            category="save"
            onClick={onAPBodySubmitHandler}
          >
            Save
          </CommonButton>
        </StyledBCDetailBillingInfoCommonRowCell>
      )}
      {isDeletable && (
        <StyledBCDetailBillingInfoCommonRowCell width="24px">
          <CommonButton
            category="redDelete"
            customStyle={{
              width: '24px !important',
              height: '24px !important',
              padding: '3px',
            }}
            preventDupClick
            onClick={onAPBodyDeleteHandler}
          />
        </StyledBCDetailBillingInfoCommonRowCell>
      )}
    </StyledBCDetailBillingInfoCommonRowContainer>
  );
};

export default APTableRow;
