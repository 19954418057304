import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import processData from '../../../../utils/Master/Common/processData';
import tierWeightAPI from '../api';

/**
 * Updates the tier weight data in the backend.
 * @param {Object} data - The data to be updated.
 */
const updateTierWeight = async ({ data }) => {
  processData(data);
  try {
    const response = await tierWeightAPI.put(`/${data.seq}`, {
      div: data.div,
      tier: data.tier,
      type: data.type,
      rate: data.rate,
      effective_date: data.effective_date,
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default updateTierWeight;
