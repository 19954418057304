import styled from 'styled-components';

const StyledBCDetailBillingInfoCommonFooterTotalValueContainer = styled.div`
  width: 672px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: left;
  background-color: ${props => props.backgroundColor};
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
`;

export default StyledBCDetailBillingInfoCommonFooterTotalValueContainer;
