import React, { useEffect, useState } from 'react';

const ExpandGroupIcon = ({ expanded, iconColor, ...props }) => {
  return expanded ? (
    <svg
      width="8"
      height="4"
      viewBox="0 0 8 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 3.49691e-07L8 4L0 4L4 3.49691e-07Z" fill={iconColor} />
    </svg>
  ) : (
    <svg
      width="8"
      height="4"
      viewBox="0 0 8 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 4L0 0H8L4 4Z" fill={iconColor} />
    </svg>
  );
};

export default ExpandGroupIcon;
