import { useQuery } from '@tanstack/react-query';
import getMovingList from '../../../services/Trace/Map/Get/getMovingList';
import traceKeys from '../keys';

const useGetTraceMovingList = ({ target }) => {
  const { isLoading: loadingMovingList, data: traceMovingList } = useQuery({
    queryKey: traceKeys.movingList({ target }),
    queryFn: () => getMovingList({ target }),
    refetchOnWindowFocus: false,
  });

  return { loadingMovingList, traceMovingList };
};

export default useGetTraceMovingList;
