import {
  NUMBER_COLUMN_LIST,
  LEFT_LEVEL_COLUMN,
  PASSED_LEVEL_COLUMN,
  BOOLEAN_COLUMN_LIST,
} from '../../../../constants/Admin/EarlyWarning/columns';
import {
  validateZeroValue,
  validateDuplicateNumber,
  validateLeftValueComparison,
  validatePassedValueComparison,
  validateNumberValueRange,
} from '../Generator/valueValidator';
import startRowEdit from '../../../Common/Datagrid/Handler/onRowEditClickHandler';

const saveEarlyWarning = async ({
  props,
  earlyWarningPatchMutator,
  setIsOpenErrorModal,
  setErrorMessage,
  setIsSuccess,
}) => {
  const { gridParam } = props;
  const { seq, ...editData } = gridParam?.data;
  const patchData = {};

  const handleInvalid = errorMessage => {
    gridParam.api.undoCellEditing();
    setIsSuccess(false);
    setIsOpenErrorModal(true);
    setErrorMessage(errorMessage);
    startRowEdit({ gridParam });
  };

  Object?.entries?.(editData)?.forEach(([key, value]) => {
    if (BOOLEAN_COLUMN_LIST.includes(key)) {
      if (value !== '' && value !== undefined && value !== null) {
        patchData[key] = value;
      }
    } else if (NUMBER_COLUMN_LIST.includes(key)) {
      patchData[key] = value ? Number(value) : null;
    }
  });

  /* validate */
  const isZeroValueValid = validateZeroValue({ patchData });
  const isNumberRangeValid = validateNumberValueRange({ patchData });
  const isDuplicateLeftNumberValid = validateDuplicateNumber({
    patchData,
    targetFieldList: LEFT_LEVEL_COLUMN,
  });
  const isDuplicatePassedNumberValid = validateDuplicateNumber({
    patchData,
    targetFieldList: PASSED_LEVEL_COLUMN,
  });
  const isComparisonLeftValueValid = validateLeftValueComparison({ patchData });
  const isComparisonPassedValueValid = validatePassedValueComparison({
    patchData,
  });

  if (!isZeroValueValid) {
    handleInvalid('0(zero) cannot be entered');
    return;
  }
  if (!isNumberRangeValid) {
    handleInvalid('Number value is less than or equal to 32767.');
    return;
  }
  if (!isDuplicateLeftNumberValid) {
    handleInvalid('The same number cannot be entered in the LEFT area');
    return;
  }
  if (!isDuplicatePassedNumberValid) {
    handleInvalid('The same number cannot be entered in the PASSED area');
    return;
  }
  if (!isComparisonLeftValueValid) {
    handleInvalid('Please enter a valid number');
    return;
  }
  if (!isComparisonPassedValueValid) {
    handleInvalid('Please enter a valid number');
    return;
  }

  await earlyWarningPatchMutator({ seq, patchData });
};

export default saveEarlyWarning;
