import createCategory from '../../../../Master/ServiceCode/Generator/createCategory';
import {
  PERM_BC_DETAIL_IMP_EDIT,
  PERM_BC_DETAIL_EXP_EDIT,
  PERM_BC_DETAIL_VAN_EDIT,
  PERM_BC_DETAIL_BROK_EDIT,
  PERM_BC_DETAIL_BARE_EDIT,
} from '../../../../../constants/Common/permission';

const generateSavePermission = ({ woNo }) => {
  const category = createCategory(woNo);

  if (category === 'IMPORT') {
    return PERM_BC_DETAIL_IMP_EDIT;
  }
  if (category === 'EXPORT') {
    return PERM_BC_DETAIL_EXP_EDIT;
  }
  if (category === 'VAN') {
    return PERM_BC_DETAIL_VAN_EDIT;
  }
  if (category === 'BROKERAGE') {
    return PERM_BC_DETAIL_BROK_EDIT;
  }
  if (category === 'BARE CHASSIS') {
    return PERM_BC_DETAIL_BARE_EDIT;
  }
  return null;
};

export default generateSavePermission;
