const openPDFViewer = (url, setErrorModalOpen, setMessage) => {
  const blob = new Blob([url], { type: 'application/pdf' });
  const file = new File([blob], 'example.pdf');
  const blobs = new Blob([file], { type: 'application/pdf' });
  const pdfURL = URL.createObjectURL(blobs);

  const newWindow = window.open(pdfURL, '_blank');
  if (newWindow) {
    newWindow.focus();
  } else {
    setMessage('Popup blocked. Please allow popups for this site');
    setErrorModalOpen(true);
  }
};

export default openPDFViewer;
