import React from 'react';
import CommonButton from '../../../CommonButton/CommonButton';
import {
  APPLY_TYPE,
  DUPLICATE_FIELD_LIST,
  MODAL_STYLES,
} from '../../../../constants/Acct/Common/staticNames';
import applyProductCode from '../../../../utils/Acct/Common/Handler/applyProductCode';
import CommonBlur from '../../../CommonBlur/CommonBlur';
import StyledCommonMessageModalWrapper from '../../../../styles/Common/CommonMessageModal/StyledCommonMessageModalWrapper';
import CommonMessageModalHeader from '../../../CommonMessageModal/CommonMessageModalHeader';
import CommonMessageModalContent from '../../../CommonMessageModal/CommonMessageModalContent';

const WarningModal = ({
  open,
  setOpen,
  data,
  setResolveErrorList,
  warningModalIndex,
  duplicationErrorList,
  setWarningModalIndex,
  setWarningModalData,
  setAlwaysFlag,
  setApplyType,
  alwaysFlag,
  duplicateCheckFields,
}) => {
  const duplicateFieldObjectList = DUPLICATE_FIELD_LIST?.filter(({ value }) =>
    duplicateCheckFields?.includes(value),
  );

  return (
    <CommonBlur open={open}>
      <div className="flex justify-center items-center w-full h-full">
        <StyledCommonMessageModalWrapper {...MODAL_STYLES}>
          <div className="relative -top-[9px] w-[full] h-[full] flex justify-end items-center" />
          <div className="flex gap-y-[30px] flex-col">
            <div className="flex gap-y-[12px] flex-col">
              <CommonMessageModalHeader content="Warning Message" />
              <CommonMessageModalContent
                content={
                  <div className="w-full flex flex-col">
                    <div className="w-full min-w-[300px] min-h-[61px] h-fit bg-[#F9F9F9] flex justify-center items-center flex-col">
                      <div className="flex flex-col justify-center items-center px-[50px] leading-[14.06px]">
                        <p className="text-[12px] font-[400]">
                          {duplicateFieldObjectList?.map(
                            (duplicateFieldObject, index) => {
                              return (
                                <>
                                  <span
                                    className="text-[12px] font-[600]"
                                    key={duplicateFieldObject?.value}
                                  >
                                    {duplicateFieldObject?.label}(
                                    {
                                      data?.submitData?.data?.[
                                        duplicateFieldObject?.value
                                      ]
                                    }
                                    )
                                  </span>
                                  {index < duplicateFieldObjectList?.length && (
                                    <> and </>
                                  )}
                                </>
                              );
                            },
                          )}
                          is Already exists in the list
                        </p>
                      </div>
                    </div>
                    <div className="flex justify-center center-item mt-[22px] gap-x-[16px]">
                      <CommonButton
                        category="overWrite"
                        onClick={() =>
                          applyProductCode({
                            data,
                            setResolveErrorList,
                            type: APPLY_TYPE?.OVERWRITE,
                            warningModalIndex,
                            duplicationErrorList,
                            setWarningModalIndex,
                            setWarningModalData,
                            setOpenWarningModal: setOpen,
                            setApplyType,
                            alwaysFlag,
                          })
                        }
                      >
                        Overwrite
                      </CommonButton>
                      <CommonButton
                        category="skip"
                        onClick={() =>
                          applyProductCode({
                            data,
                            setResolveErrorList,
                            type: APPLY_TYPE?.SKIP,
                            warningModalIndex,
                            duplicationErrorList,
                            setWarningModalIndex,
                            setWarningModalData,
                            setOpenWarningModal: setOpen,
                            setApplyType,
                            alwaysFlag,
                          })
                        }
                      >
                        Skip
                      </CommonButton>
                    </div>
                    <div className="w-full text-center mt-[12px]">
                      <label
                        htmlFor="checkAlwaysApply"
                        className="flex flex-row justify-center items-center text-[10px] font-[400] text-[#666666]"
                      >
                        <input
                          type="checkbox"
                          id="checkAlwaysApply"
                          className="bg-blue-500 border-2 border-red-500 rounded-md"
                          onChange={e => setAlwaysFlag(e?.target?.checked)}
                        />
                        &nbsp; Apply this setting to others
                      </label>
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        </StyledCommonMessageModalWrapper>
      </div>
    </CommonBlur>
  );
};

export default WarningModal;
