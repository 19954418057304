const HISTORY_TABLE_HEADER = [
  { data: 'NO', header: true, width: 'w-[35px]', inputName: 'no' },
  {
    data: 'DATE',
    header: true,
    width: 'w-[81px]',
    inputName: 'created_date',
  },
  {
    data: 'TIME',
    header: true,
    width: 'w-[71px]',
    inputName: 'created_time',
  },
  { data: 'FIELD', header: true, width: 'w-[81px]', inputName: 'field' },
  {
    data: 'CATEGORY',
    header: true,
    width: 'w-[132px]',
    inputName: 'category',
  },
  { data: 'OLD', header: true, width: 'w-[116px]', inputName: 'old' },
  { data: 'NEW', header: true, width: 'w-[116px]', inputName: 'new' },
  {
    data: 'USER ID',
    header: true,
    width: 'w-[120px]',
    inputName: 'created_by',
  },
  {
    data: 'VIEWER',
    header: true,
    width: 'w-[77px]',
    inputName: 'evidence',
  },
  {
    data: 'EVIDENCE',
    header: true,
    width: 'w-[91px]',
    inputName: 'upload',
  },
];

export default HISTORY_TABLE_HEADER;
