import React from 'react';

const DriverChangeConfirmModalContent = () => {
  return (
    <div className="w-full h-full bg-[#F9F9F9] p-[17px] flex justify-center items-center text-[#222] text-xs font-normal flex flex-col">
      <p>
        You are attempting to modify an <b>in-progress job</b>.
      </p>
      <p>
        Please review the changes carefully and{' '}
        <b>contact the assigned driver if needed</b>.
      </p>
      <p className="text-red-500">
        <b>You will be responsible for any arising issues.</b>
      </p>
    </div>
  );
};

export default DriverChangeConfirmModalContent;
