const setLocationBackgroundColor = ({ puFrom, dilvTo, id }) => {
  if (puFrom && puFrom.id === id) {
    return `bg-[#D0EACE]`;
  }
  if (dilvTo && dilvTo.id === id) {
    return `bg-[#F6C6AD]`;
  }
  return '';
};

export default setLocationBackgroundColor;
