import React from 'react';
import dayjs from 'dayjs';
import CommonTextInput from '../../../SecondInputs/CommonTextInput/CommonTextInput';
import CommonDate from '../../../SecondInputs/CommonDate/CommonDate';
import CommonTime from '../../../SecondInputs/CommonTime/CommonTime';

const PickUp = ({
  basicInfoData,
  puScheduleDate,
  puScheduleTime,
  puActualInDate,
  puActualInTime,
  puActualOutDate,
  puActualOutTime,
  puDriver,
}) => {
  return (
    <div className="flex mt-[2px]">
      <div className="flex pl-[11px] items-center w-[72px] h-[30px] text-blue-800 text-[11px] font-bold shadow">
        P/U
      </div>
      <div className="ml-[2px] shadow">
        <CommonTextInput
          inputName="puLocationID"
          defaultValue={basicInfoData?.pu_location?.company}
          width="w-[128px]"
          textCenter
          disabled
        />
      </div>
      <div className="ml-[2px] shadow">
        <CommonDate
          inputName="puScheduleDate"
          defaultValue={puScheduleDate}
          width="w-[140px]"
          textCenter
          disabled
        />
      </div>
      <div className="ml-[2px] shadow">
        <CommonTime
          inputName="puScheduleTime"
          defaultValue={
            puScheduleDate &&
            puScheduleTime &&
            dayjs(`${puScheduleDate} ${puScheduleTime}`)?.isValid()
              ? dayjs(`${puScheduleDate} ${puScheduleTime}`)
              : null
          }
          width="w-[110px]"
          textCenter
          disabled
        />
      </div>
      <div className="ml-[2px] shadow">
        <CommonDate
          inputName="puActualInDate"
          defaultValue={puActualInDate}
          width="w-[140px]"
          textCenter
          disabled
        />
      </div>
      <div className="ml-[2px] shadow">
        <CommonTime
          inputName="puActualInTime"
          defaultValue={
            puActualInDate &&
            puActualInTime &&
            dayjs(`${puActualInDate} ${puActualInTime}`)?.isValid()
              ? dayjs(`${puActualInDate} ${puActualInTime}`)
              : null
          }
          width="w-[110px]"
          textCenter
          disabled
        />
      </div>
      <div className="ml-[2px] shadow">
        <CommonDate
          inputName="puActualOutDate"
          defaultValue={puActualOutDate}
          width="w-[140px]"
          textCenter
          disabled
        />
      </div>
      <div className="ml-[2px] shadow">
        <CommonTime
          inputName="puActualOutTime"
          defaultValue={
            puActualOutDate &&
            puActualOutTime &&
            dayjs(`${puActualOutDate} ${puActualOutTime}`)?.isValid()
              ? dayjs(`${puActualOutDate} ${puActualOutTime}`)
              : null
          }
          width="w-[110px]"
          textCenter
          disabled
        />
      </div>
      <div className="ml-[2px] shadow">
        <CommonTextInput
          inputName="puDrvVen"
          defaultValue={puDriver}
          width="w-[116px]"
          textCenter
          disabled
        />
      </div>
    </div>
  );
};

export default PickUp;
