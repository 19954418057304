import React, { useEffect, useState } from 'react';
import AP from '../../../components/BillingInfo/AP';
import AR from '../../../components/BillingInfo/AR';
import Profit from '../../../components/BillingInfo/Profit';
import Schedule from '../../../components/BillingInfo/Schedule';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import {
  PERM_BC_DETAIL_AP,
  PERM_BC_DETAIL_AP_AR_DELETE,
  PERM_BC_DETAIL_AP_EDIT,
  PERM_BC_DETAIL_AR,
  PERM_BC_DETAIL_AR_EDIT,
  PERM_QB_SEND_VIEW_EDIT,
  PERM_QB_UPDATE_VIEW_EDIT,
} from '../../../constants/Common/permission';
import useGetBasicInfo from '../../../hooks/BCDetail/DetailInfo/BasicInfo/useGetBasicInfo';
import StyledBCDetailBillingInfoCommonApArContainer from '../../../styles/BCDetail/BillingInfo/Common/StyledBCDetailBillingInfoCommonApArContainer';
import StyledBCDetailBillingInfoCommonContainer from '../../../styles/BCDetail/BillingInfo/Common/StyledBCDetailBillingInfoCommonContainer';
import StyledBCDetailBillingInfoCommonSeparateLine from '../../../styles/BCDetail/BillingInfo/Common/StyledBCDetailBillingInfoCommonSeparateLine';
import handleOnOkClick from '../../../utils/BCDetail/BillingInfo/Common/handleOnOkClick';
import checkPermission from '../../../utils/Common/Router/Handler/checkPermission';

const BillingInfo = () => {
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [profit, setProfit] = useState({
    ap: 0,
    ar: 0,
    profit: 0,
  });
  const [isDeletedWO, setIsDeletedWO] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isAPViewable = checkPermission({ permission: [...PERM_BC_DETAIL_AP] });
  const isAPEditable = checkPermission({ permission: PERM_BC_DETAIL_AP_EDIT });
  const isARViewable = checkPermission({ permission: [...PERM_BC_DETAIL_AR] });
  const isAREditable = checkPermission({ permission: PERM_BC_DETAIL_AR_EDIT });
  const isDeletable = checkPermission({
    permission: [...PERM_BC_DETAIL_AP_AR_DELETE],
  });
  const isQBAvailable =
    checkPermission({ permission: PERM_QB_SEND_VIEW_EDIT }) ||
    checkPermission({ permission: PERM_QB_UPDATE_VIEW_EDIT }) ||
    checkPermission({ permission: PERM_QB_UPDATE_VIEW_EDIT });

  const { basicInfoData, basicInfoLoading } = useGetBasicInfo();

  useEffect(() => {
    setProfit(prev => {
      return {
        ...prev,
        profit: profit?.ar - profit?.ap,
      };
    });
  }, [profit?.ap, profit?.ar]);

  return (
    <>
      <CommonLoading open={basicInfoLoading} />
      <StyledBCDetailBillingInfoCommonContainer>
        <Schedule basicInfoData={basicInfoData} />
        {isAPViewable && (
          <>
            <StyledBCDetailBillingInfoCommonSeparateLine />
            <StyledBCDetailBillingInfoCommonApArContainer>
              <AP
                setProfit={setProfit}
                setIsAlertOpen={setIsAlertOpen}
                setIsSuccess={setIsSuccess}
                setErrorMessage={setErrorMessage}
                isAPEditable={isAPEditable}
                isDeletable={isDeletable}
                isQBAvailable={isQBAvailable}
                setIsDeletedWO={setIsDeletedWO}
                isSubmitting={isSubmitting}
                setIsSubmitting={setIsSubmitting}
                basicInfoData={basicInfoData}
              />
            </StyledBCDetailBillingInfoCommonApArContainer>
          </>
        )}
        {isARViewable && (
          <>
            <StyledBCDetailBillingInfoCommonSeparateLine />
            <StyledBCDetailBillingInfoCommonApArContainer>
              <AR
                setProfit={setProfit}
                setIsAlertOpen={setIsAlertOpen}
                setIsSuccess={setIsSuccess}
                setErrorMessage={setErrorMessage}
                isAREditable={isAREditable}
                isDeletable={isDeletable}
                isQBAvailable={isQBAvailable}
                setIsDeletedWO={setIsDeletedWO}
                isSubmitting={isSubmitting}
                setIsSubmitting={setIsSubmitting}
              />
            </StyledBCDetailBillingInfoCommonApArContainer>
          </>
        )}
        <StyledBCDetailBillingInfoCommonSeparateLine />
        <Profit profit={profit?.profit} />
      </StyledBCDetailBillingInfoCommonContainer>
      {!isSuccess && (
        <CommonErrorModal
          open={isAlertOpen}
          setIsOpen={setIsAlertOpen}
          isSuccess={isSuccess}
          message={errorMessage}
          onOkClickHandler={() =>
            handleOnOkClick({ isDeletedWO, setIsSubmitting })
          }
        />
      )}
    </>
  );
};

export default BillingInfo;
