import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import bcDetailDefaultAPI from '../../bcDetailAPI';

const getDriverList = async ({ woNo, driver }) => {
  try {
    const response = await bcDetailDefaultAPI.get(
      `${woNo}/assign-driver/driver-list?`,
      {
        params: {
          driver,
          page: 'dispatch',
        },
      },
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getDriverList;
