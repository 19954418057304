const handleEmptyInvNoClicked = ({
  data,
  selectedRow,
  setValue,
  checkDataKey,
  setCheckedRow,
}) => {
  const selectedRowUniqueValue = selectedRow?.[checkDataKey];

  data?.forEach(element => {
    if (
      element?.[checkDataKey] === selectedRowUniqueValue &&
      (!element?.inv_no || element?.inv_no === '')
    ) {
      setValue(`mod.checked_${element?.seq}`, true);
      setCheckedRow(prev => {
        // Check if element?.no is already in the array
        if (!prev.includes(element?.seq)) {
          return [...prev, element?.seq]; // Add element?.no if it's not already in the array
        }
        return prev; // Return the same array if element?.no is already present
      });
    } else {
      setValue(`mod.checked_${element?.seq}`, false);
      setCheckedRow(prev => {
        return prev.filter(item => item !== element?.seq);
      });
    }
  });
};

const handleFilledInvNoClicked = ({
  data,
  selectedRow,
  setValue,
  checkDataKey,
  setCheckedRow,
}) => {
  const selectedRowUniqueValue = selectedRow?.[checkDataKey];

  data?.forEach(element => {
    if (element?.[checkDataKey] === selectedRowUniqueValue) {
      if (
        element?.inv_no === selectedRow?.inv_no ||
        !element?.inv_no ||
        element?.inv_no === ''
      ) {
        setValue(`mod.checked_${element?.seq}`, true);
        setCheckedRow?.(prev => {
          // Check if element?.no is already in the array
          if (!prev.includes(element?.seq)) {
            return [...prev, element?.seq]; // Add element?.no if it's not already in the array
          }
          return prev; // Return the same array if element?.no is already present
        });
      } else {
        setValue(`mod.checked_${element?.seq}`, false);
        setCheckedRow?.(prev => {
          return prev.filter(item => item !== element?.seq);
        });
      }
    } else {
      setValue(`mod.checked_${element?.seq}`, false);
      setCheckedRow?.(prev => {
        return prev.filter(item => item !== element?.seq);
      });
    }
  });
};

const onARGroupingHandler = ({
  selectedRow,
  data,
  setValue,
  checkDataKey,
  setCheckedRow,
}) => {
  if (!selectedRow?.inv_no || selectedRow?.inv_no === '') {
    handleEmptyInvNoClicked({
      data,
      selectedRow,
      setValue,
      checkDataKey,
      setCheckedRow,
    });
    return;
  }
  handleFilledInvNoClicked({
    data,
    selectedRow,
    setValue,
    checkDataKey,
    setCheckedRow,
  });
};

export default onARGroupingHandler;
