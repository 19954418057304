import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import useDeleteARData from '../../../../../hooks/BCDetail/BillingInfo/AR/useDeleteARData';
import usePatchARData from '../../../../../hooks/BCDetail/BillingInfo/AR/usePatchARData';
import StyledBCDetailBillingInfoCommonRowCell from '../../../../../styles/BCDetail/BillingInfo/Common/TableRow/StyledBCDetailBillingInfoCommonRowCell';
import StyledBCDetailBillingInfoCommonRowContainer from '../../../../../styles/BCDetail/BillingInfo/Common/TableRow/StyledBCDetailBillingInfoCommonRowContainer';
import StyledBCDetailBillingInfoCommonRowInputContainer from '../../../../../styles/BCDetail/BillingInfo/Common/TableRow/StyledBCDetailBillingInfoCommonRowInputContainer';
import StyledBCDetailBillingInfoCommonRowQBContainer from '../../../../../styles/BCDetail/BillingInfo/Common/TableRow/StyledBCDetailBillingInfoCommonRowQBContainer';
import onARGroupRowsByGroupIdHandler from '../../../../../utils/BCDetail/BillingInfo/AR/Handler/onARGroupRowsByGroupIdHandler';
import onARSubmitHandler from '../../../../../utils/BCDetail/BillingInfo/AR/Handler/onARSubmitHandler';
import formatNumber from '../../../../../utils/BCDetail/BillingInfo/Common/formatNumber';
import onDateInputDefaultChangeHandler from '../../../../../utils/BCDetail/Common/Handler/onDateInputDefaultChangeHandler';
import onInputDefaultChangeHandler from '../../../../../utils/BCDetail/Common/Handler/onInputDefaultChangeHandler';
import onRowCheckboxGroupingHandler from '../../../../../utils/BCDetail/Common/Handler/onRowCheckboxGroupingHandler';
import {
  createCompanySelectOption,
  createProductCodeSelectOption,
} from '../../../../../utils/Common/createCodeSelectOption';
import updateButtonDisabled from '../../../../../utils/Common/Handler/updateButtonDisabled';
import ARRowDate from '../../../../BCDetail/BillingInfo/ARRowDate';
import Input from '../../../../BCDetail/BillingInfo/Input';
import ControlMuiCheckBox from '../../../../BCDetail/Inputs/ControlMuiCheckBox';
import CommonButton from '../../../../CommonButton/CommonButton';
import CommonAutoComplete from '../../../../SecondInputs/CommonAutoComplete/CommonAutoComplete';

const ARTableRow = ({
  row,
  ARData,
  ARProductCodeQuery,
  ARCompanyList,
  setIsCreditHoldCompany,
  setCheckedRow,
  setIsAlertOpen,
  setIsSuccess,
  setErrorMessage,
  isAREditable,
  isDeletable,
  refetchARData,
  setIsQBDisabled,
}) => {
  const { mutate: arModMutator } = usePatchARData({
    setIsAlertOpen,
    setIsSuccess,
    setErrorMessage,
    refetchARData,
  });
  const { mutate: arDelMutator } = useDeleteARData({
    setIsAlertOpen,
    setIsSuccess,
    setErrorMessage,
    refetchARData,
  });

  const saveRef = useRef(null);

  const { setValue, handleSubmit } = useFormContext();
  const [companyName, setCompanyName] = useState(row?.company_name);
  const [acctCodeName, setAcctCodeName] = useState(row?.acct_code_name);
  const [isMinus, setIsMinus] = useState(false);
  const [isTotalDiff, setIsTotalDiff] = useState(false);

  useEffect(() => {
    if (row?.ar_total < 0 && row?.inv_no) setIsMinus(true);
    else setIsMinus(false);
    if (row?.qb_id) {
      if (formatNumber(row?.ar_total) !== formatNumber(row?.inv_amt))
        setIsTotalDiff(true);
      else setIsTotalDiff(false);
    }
  }, [row?.qb_id, row?.ar_total, row?.inv_amt]);

  const onARBodySubmitHandler = ({ buttonRef }) => {
    updateButtonDisabled({ ref: buttonRef, disabled: true });
    onARSubmitHandler({
      handleSubmit,
      submitType: 'MOD',
      arMutator: arModMutator,
      seq: row?.seq,
      ARProductCodeQuery,
      setIsAlertOpen,
      setErrorMessage,
      setIsSuccess,
      buttonRef,
    });
  };

  const onARBodyDeleteHandler = ({ buttonRef }) => {
    onARSubmitHandler({
      handleSubmit,
      submitType: 'DEL',
      arMutator: arDelMutator,
      seq: row?.seq,
      setIsSuccess,
      buttonRef,
    });
  };

  const groupedRows = onARGroupRowsByGroupIdHandler(ARData);

  const isFirstInGroup =
    groupedRows[row.inv_no] && groupedRows[row.inv_no][0] === row;

  const singleRowHeight = 30;
  const gapBetweenRows = 2;

  const groupHeight =
    groupedRows[row.inv_no]?.length * singleRowHeight +
    (groupedRows[row.inv_no]?.length - 1) * gapBetweenRows;

  const groupMargin =
    groupedRows[row.inv_no]?.length > 1
      ? (groupedRows[row.inv_no]?.length - 1) *
        (singleRowHeight + gapBetweenRows)
      : 0;

  return (
    <StyledBCDetailBillingInfoCommonRowContainer>
      <StyledBCDetailBillingInfoCommonRowCell width="108px">
        <StyledBCDetailBillingInfoCommonRowInputContainer>
          <ARRowDate
            placeholder="MM/DD/YY"
            inputName={`mod.inv_date_${row?.seq}`}
            defaultValue={row?.inv_date}
            disabled
            onChangeHandler={onDateInputDefaultChangeHandler}
            backgroundColor={isMinus && '#FF9292'}
          />
        </StyledBCDetailBillingInfoCommonRowInputContainer>
      </StyledBCDetailBillingInfoCommonRowCell>
      <StyledBCDetailBillingInfoCommonRowCell width="88px">
        <CommonAutoComplete
          inputName={`mod.company_${row?.seq}`}
          backgroundColor={isMinus ? '#FF9292' : '#F8F2F2'}
          defaultValue={row?.company}
          option={createCompanySelectOption(ARCompanyList?.companyList)}
          onChangeHandler={({ e, onChange, ...props }) => {
            if (props.selectProps?.credit === true) {
              setIsCreditHoldCompany(true);
              setValue(`mod.company_${row?.seq}`, null);
              setValue(`mod.company_name_${row?.seq}`, '');
              return;
            }
            onChange?.(props.selectProps?.value);
            const getOptions = ARCompanyList?.companyList;
            const getCompanyName = getOptions?.find(
              element =>
                element?.company === props.selectProps?.value &&
                element?.credit_hold !== true,
            )?.business_name;
            setValue(`mod.company_name_${row?.seq}`, getCompanyName);
            setCompanyName(getCompanyName);
          }}
          disabled={!!row?.inv_no || !isAREditable}
          disabledColor="#EAE3E3"
          isLoading={ARCompanyList?.isLoading}
        />
      </StyledBCDetailBillingInfoCommonRowCell>
      <StyledBCDetailBillingInfoCommonRowCell width="161px">
        <StyledBCDetailBillingInfoCommonRowInputContainer
          backgroundColor={isMinus ? '#FF9292' : '#EAE3E3'}
        >
          <Input
            inputName={`mod.company_name_${row?.seq}`}
            defaultValue={companyName}
            disabled
          />
        </StyledBCDetailBillingInfoCommonRowInputContainer>
      </StyledBCDetailBillingInfoCommonRowCell>
      <StyledBCDetailBillingInfoCommonRowCell width="85px">
        <CommonAutoComplete
          inputName={`mod.product_code_${row?.seq}`}
          backgroundColor={isMinus ? '#FF9292' : '#F8F2F2'}
          option={createProductCodeSelectOption(
            ARProductCodeQuery?.productCode,
          )}
          defaultValue={row?.product_code}
          disabled={!isAREditable}
          onChangeHandler={({ e, onChange, ...props }) => {
            onChange?.(props.selectProps?.value);
            const getProductCodeName = ARProductCodeQuery?.productCode?.find(
              element => element?.product_code === props.selectProps?.value,
            )?.product_code_name;
            setValue(`mod.product_code_name_${row?.seq}`, getProductCodeName);
            const getAcctCodeName = ARProductCodeQuery?.productCode?.find(
              element => element?.product_code === props.selectProps?.value,
            )?.acct_code_name;
            setValue(`mod.acct_code_name_${row?.seq}`, getAcctCodeName);
            setAcctCodeName(getAcctCodeName);
          }}
          isLoading={ARProductCodeQuery?.isLoading}
        />
      </StyledBCDetailBillingInfoCommonRowCell>
      <StyledBCDetailBillingInfoCommonRowCell width="143px">
        <StyledBCDetailBillingInfoCommonRowInputContainer
          backgroundColor={isMinus ? '#FF9292' : '#EAE3E3'}
        >
          <Input
            inputName={`mod.product_code_name_${row?.seq}`}
            defaultValue={acctCodeName}
            disabled
          />
        </StyledBCDetailBillingInfoCommonRowInputContainer>
      </StyledBCDetailBillingInfoCommonRowCell>
      <StyledBCDetailBillingInfoCommonRowCell width="90px">
        <StyledBCDetailBillingInfoCommonRowInputContainer
          backgroundColor={isMinus ? '#FF9292' : '#F8F2F2'}
        >
          <Input
            inputName={`mod.rate_${row?.seq}`}
            defaultValue={row?.rate}
            textAlign="center"
            dollarSign
            maxLength={14}
            isInt
            disabled={!isAREditable}
            onChangeHandler={({
              e,
              onChange,
              setText,
              setTextLength,
              maxTextLength,
              ...props
            }) => {
              const inputText = e?.target?.value;
              if (/^-?\d*(\.\d{0,2})?$/.test(inputText)) {
                if (inputText.length <= maxTextLength) {
                  setText(inputText);
                  onChange(inputText);
                  setTextLength(inputText.length);
                }
              }
            }}
            onKeyDownHandler={e => {
              if (e?.e?.key === 'Enter')
                onARBodySubmitHandler({ buttonRef: saveRef });
            }}
          />
        </StyledBCDetailBillingInfoCommonRowInputContainer>
      </StyledBCDetailBillingInfoCommonRowCell>
      <StyledBCDetailBillingInfoCommonRowCell width="90px">
        <StyledBCDetailBillingInfoCommonRowInputContainer
          backgroundColor={isMinus ? '#FF9292' : '#F8F2F2'}
        >
          <Input
            inputName={`mod.qty_${row?.seq}`}
            defaultValue={row?.qty}
            maxLength={10}
            isInt
            disabled={!isAREditable}
            onChangeHandler={({
              e,
              onChange,
              setText,
              setTextLength,
              maxTextLength,
              ...props
            }) => {
              const inputText = e?.target?.value;
              if (/^-?\d*(\.\d{0,2})?$/.test(inputText)) {
                if (inputText.length <= maxTextLength) {
                  setText(inputText);
                  onChange(inputText);
                  setTextLength(inputText.length);
                }
              }
            }}
            onKeyDownHandler={e => {
              if (e?.e?.key === 'Enter')
                onARBodySubmitHandler({ buttonRef: saveRef });
            }}
          />
        </StyledBCDetailBillingInfoCommonRowInputContainer>
      </StyledBCDetailBillingInfoCommonRowCell>
      {/* <StyledBCDetailBillingInfoCommonRowCell width="44px">
        {acctCodeName?.toLowerCase()?.includes('chassis') ? (
          <StyledBCDetailBillingInfoCommonRowInputContainer
            backgroundColor={isMinus ? '#FF9292' : '#F8F2F2'}
          >
            <Input
              inputName={`mod.wave_${row?.seq}`}
              defaultValue={row?.wave}
              maxLength={7}
              isInt
              disabled={!isAREditable}
              onChangeHandler={({
                e,
                onChange,
                setText,
                setTextLength,
                maxTextLength,
                ...props
              }) => {
                const inputText = e?.target?.value;
                if (/^-?\d*(\.\d{0,2})?$/.test(inputText)) {
                  if (inputText.length <= maxTextLength) {
                    setText(inputText);
                    onChange(inputText);
                    setTextLength(inputText.length);
                  }
                }
              }}
              onKeyDownHandler={e => {
                if (e?.e?.key === 'Enter') onARBodySubmitHandler();
              }}
            />
          </StyledBCDetailBillingInfoCommonRowInputContainer>
        ) : (
          <StyledBCDetailBillingInfoCommonRowInputContainer
            backgroundColor={isMinus ? '#FF9292' : '#EAE3E3'}
          >
            <Input
              inputName={`mod.wave_${row?.seq}`}
              defaultValue={row?.wave}
              disabled
            />
          </StyledBCDetailBillingInfoCommonRowInputContainer>
        )}
      </StyledBCDetailBillingInfoCommonRowCell> */}
      <StyledBCDetailBillingInfoCommonRowCell width="90px">
        <StyledBCDetailBillingInfoCommonRowInputContainer
          backgroundColor={isMinus ? '#FF9292' : '#EAE3E3'}
        >
          <Input
            inputName={`mod.amt_${row?.seq}`}
            defaultValue={row?.amt}
            textAlign="center"
            dollarSign
            disabled
          />
        </StyledBCDetailBillingInfoCommonRowInputContainer>
      </StyledBCDetailBillingInfoCommonRowCell>
      <StyledBCDetailBillingInfoCommonRowCell width="140px">
        <StyledBCDetailBillingInfoCommonRowInputContainer
          backgroundColor={isMinus ? '#FF9292' : '#F8F2F2'}
        >
          <Input
            inputName={`mod.desc_${row?.seq}`}
            defaultValue={row?.description}
            maxLength={128}
            disabled={!isAREditable}
            onChangeHandler={onInputDefaultChangeHandler}
            onKeyDownHandler={e => {
              if (e?.e?.key === 'Enter')
                onARBodySubmitHandler({ buttonRef: saveRef });
            }}
          />
        </StyledBCDetailBillingInfoCommonRowInputContainer>
      </StyledBCDetailBillingInfoCommonRowCell>
      {((isFirstInGroup || !row?.inv_no) && (
        <StyledBCDetailBillingInfoCommonRowCell
          width="90px"
          marginTop={`${groupMargin}px`}
        >
          <StyledBCDetailBillingInfoCommonRowInputContainer
            backgroundColor={isMinus || isTotalDiff ? '#FF9292' : '#EAE3E3'}
            height={`${groupHeight || 30}px`}
          >
            <Input
              inputName={`ar.total_${row?.ar_total}`}
              defaultValue={formatNumber(row?.ar_total)}
              textAlign="center"
              dollarSign
              disabled
            />
          </StyledBCDetailBillingInfoCommonRowInputContainer>
        </StyledBCDetailBillingInfoCommonRowCell>
      )) || <StyledBCDetailBillingInfoCommonRowCell width="90px" />}
      <StyledBCDetailBillingInfoCommonRowCell width="90px">
        <StyledBCDetailBillingInfoCommonRowInputContainer
          backgroundColor={isMinus ? '#FF9292' : '#EAE3E3'}
        >
          <Input
            inputName={`mod.inv_no_${row?.seq}`}
            defaultValue={row?.inv_no}
            disabled
          />
        </StyledBCDetailBillingInfoCommonRowInputContainer>
      </StyledBCDetailBillingInfoCommonRowCell>
      <StyledBCDetailBillingInfoCommonRowCell width="30px">
        <StyledBCDetailBillingInfoCommonRowInputContainer
          backgroundColor={isMinus ? '#FF9292' : '#F8F2F2'}
        >
          <ControlMuiCheckBox
            inputName={`mod.checked_${row?.seq}`}
            disabled={!isAREditable}
            onChangeHandler={({ e, onChange, ...props }) => {
              if (e?.target?.checked) {
                setIsQBDisabled(!row?.inv_no);
              } else {
                setIsQBDisabled(false);
              }
              onRowCheckboxGroupingHandler({
                event: e,
                data: ARData,
                selectedRow: row,
                billingType: 'AR',
                setValue,
                setCheckedRow,
              });
              onChange?.(e?.target?.checked);
            }}
          />
        </StyledBCDetailBillingInfoCommonRowInputContainer>
      </StyledBCDetailBillingInfoCommonRowCell>
      {((isFirstInGroup || !row?.inv_no) && (
        <>
          <StyledBCDetailBillingInfoCommonRowCell
            width="90px"
            marginTop={`${groupMargin}px`}
          >
            <StyledBCDetailBillingInfoCommonRowQBContainer
              height={`${groupHeight || 30}px`}
              backgroundColor={isMinus && '#FF9292'}
            >
              <Input
                inputName={`mod.qb_id_${row?.seq}`}
                defaultValue={row?.qb_id}
                textAlign="center"
                disabled
              />
            </StyledBCDetailBillingInfoCommonRowQBContainer>
          </StyledBCDetailBillingInfoCommonRowCell>
          <StyledBCDetailBillingInfoCommonRowCell
            width="90px"
            marginTop={`${groupMargin}px`}
          >
            <StyledBCDetailBillingInfoCommonRowQBContainer
              height={`${groupHeight || 30}px`}
              backgroundColor={(isMinus || isTotalDiff) && '#FF9292'}
            >
              <Input
                inputName={`mod.inv_amt_${row?.seq}`}
                defaultValue={row?.inv_amt}
                textAlign="center"
                dollarSign
                disabled
              />
            </StyledBCDetailBillingInfoCommonRowQBContainer>
          </StyledBCDetailBillingInfoCommonRowCell>
          <StyledBCDetailBillingInfoCommonRowCell
            width="90px"
            marginTop={`${groupMargin}px`}
          >
            <StyledBCDetailBillingInfoCommonRowQBContainer
              height={`${groupHeight || 30}px`}
              backgroundColor={isMinus && '#FF9292'}
            >
              <Input
                inputName={`mod.rcvd_amt_${row?.seq}`}
                defaultValue={row?.rcvd_amt}
                textAlign="center"
                dollarSign
                disabled
              />
            </StyledBCDetailBillingInfoCommonRowQBContainer>
          </StyledBCDetailBillingInfoCommonRowCell>
          <StyledBCDetailBillingInfoCommonRowCell
            width="90px"
            marginTop={`${groupMargin}px`}
          >
            <StyledBCDetailBillingInfoCommonRowQBContainer
              height={`${groupHeight || 30}px`}
              backgroundColor={isMinus && '#FF9292'}
            >
              <Input
                inputName={`mod.qb_date_${row?.seq}`}
                defaultValue={
                  row?.qb_date ? dayjs(row?.qb_date).format('MM/DD/YY') : ''
                }
                textAlign="center"
                disabled
              />
            </StyledBCDetailBillingInfoCommonRowQBContainer>
          </StyledBCDetailBillingInfoCommonRowCell>
        </>
      )) || (
        <>
          <StyledBCDetailBillingInfoCommonRowCell width="90px" />
          <StyledBCDetailBillingInfoCommonRowCell width="90px" />
          <StyledBCDetailBillingInfoCommonRowCell width="90px" />
          <StyledBCDetailBillingInfoCommonRowCell width="90px" />
        </>
      )}
      <StyledBCDetailBillingInfoCommonRowCell width="88px">
        <StyledBCDetailBillingInfoCommonRowInputContainer
          backgroundColor={isMinus ? '#FF9292' : '#ECDDDD'}
        >
          <Input
            inputName={`mod.updated_by_${row?.seq}`}
            defaultValue={row?.updated_by}
            textAlign="center"
            disabled
          />
        </StyledBCDetailBillingInfoCommonRowInputContainer>
      </StyledBCDetailBillingInfoCommonRowCell>
      {isAREditable && (
        <StyledBCDetailBillingInfoCommonRowCell width="79px">
          <CommonButton
            ref={saveRef}
            type="button"
            borderColor="#556DA1"
            category="save"
            onClick={onARBodySubmitHandler}
          >
            Save
          </CommonButton>
        </StyledBCDetailBillingInfoCommonRowCell>
      )}
      {isDeletable && (
        <StyledBCDetailBillingInfoCommonRowCell width="24px">
          <CommonButton
            category="redDelete"
            customStyle={{
              width: '24px !important',
              height: '24px !important',
              padding: '3px',
            }}
            preventDupClick
            onClick={onARBodyDeleteHandler}
          />
        </StyledBCDetailBillingInfoCommonRowCell>
      )}
    </StyledBCDetailBillingInfoCommonRowContainer>
  );
};

export default ARTableRow;
