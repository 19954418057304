import React from 'react';
import dayjs from 'dayjs';

const LocationRow = ({ location }) => {
  if (location) {
    return (
      <div className="flex items-center gap-x-[2px]">
        <div className="text-[11px] font-[700] text-[#264B9F] w-[173px] h-[30px] text-start shadow drop-shadow-[0_0.5px_0_rgba(0,0,0,0.25)] mr-[2px] mb-[2px]">
          <div className="w-[7px] bg-[#264B9F] h-[30px] absolute" />
          <div>
            <p className="pl-[19px] py-[8px]">{location?.custom_type}</p>
          </div>
        </div>
        <div className="w-[173px] h-[30px] text-[11px] font-[500] py-[8px] bg-[#F2F4F8] shadow drop-shadow-[0_0.5px_0_rgba(0,0,0,0.25)]">
          <p className="text-center">{location?.company}</p>
        </div>
        <div className="w-[173px] h-[30px] text-[11px] font-[500] py-[8px] bg-[#F2F4F8] shadow drop-shadow-[0_0.5px_0_rgba(0,0,0,0.25)]">
          <p className="text-center">{location?.city}</p>
        </div>
        <div className="w-[173px] h-[30px] text-[11px] font-[500] py-[8px] bg-[#F2F4F8] shadow drop-shadow-[0_0.5px_0_rgba(0,0,0,0.25)]">
          <p className="text-center">{location?.state}</p>
        </div>
        <div className="w-[173px] h-[30px] text-[11px] font-[500] py-[8px] bg-[#F2F4F8] shadow drop-shadow-[0_0.5px_0_rgba(0,0,0,0.25)]">
          <p className="text-center">{location?.timezone}</p>
        </div>
        <div className="w-[173px] h-[30px] text-[11px] font-[500] py-[8px] bg-[#F2F4F8] shadow drop-shadow-[0_0.5px_0_rgba(0,0,0,0.25)]">
          <p className="text-center">
            {dayjs(location?.sche_date)?.isValid()
              ? dayjs(location?.sche_date).format('MM/DD/YY')
              : null}
          </p>
        </div>
        <div className="w-[173px] h-[30px] text-[11px] font-[500] py-[8px] bg-[#F2F4F8] shadow drop-shadow-[0_0.5px_0_rgba(0,0,0,0.25)]">
          <p className="text-center">
            {dayjs(location?.sche_time, 'HH:mm:ss')?.isValid()
              ? dayjs(location?.sche_time, 'HH:mm:ss').format('HH:mm')
              : null}
          </p>
        </div>
        <div className="w-[173px] h-[30px] text-[11px] font-[500] py-[8px] bg-[#F2F4F8] shadow drop-shadow-[0_0.5px_0_rgba(0,0,0,0.25)]">
          <p className="text-center">
            {dayjs(location?.actual_out_date)?.isValid()
              ? dayjs(location?.actual_out_date).format('MM/DD/YY')
              : null}
          </p>
        </div>
        <div className="w-[173px] h-[30px] text-[11px] font-[500] py-[8px] bg-[#F2F4F8] shadow drop-shadow-[0_0.5px_0_rgba(0,0,0,0.25)]">
          <p className="text-center">
            {dayjs(location?.actual_out_time, 'HH:mm:ss')?.isValid()
              ? dayjs(location?.actual_out_time, 'HH:mm:ss').format('HH:mm')
              : null}
          </p>
        </div>
        <div className="w-[173px] h-[30px] text-[11px] font-[500] py-[8px] bg-[#F2F4F8] shadow drop-shadow-[0_0.5px_0_rgba(0,0,0,0.25)]">
          <p className="text-center">{location?.driver}</p>
        </div>
      </div>
    );
  }
  return null;
};

export default LocationRow;
