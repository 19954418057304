import handleQueryError from '../../../utils/Common/Handler/handleQueryError';
import mainDefaultApi from '../api';

export const getMainList = async ({ category, selectedDiv, queryParams }) => {
  try {
    const response = await mainDefaultApi.get(`${category}`, {
      params: {
        div: selectedDiv,
        ...queryParams,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};
