import handleQueryError from '../../../utils/Common/Handler/handleQueryError';
import signInAPI from '../api';

/**
 * The function `signIn` sends a POST request to a login endpoint with user information and returns the
 * response data or an empty object if there is an error.
 * @param email - The email address of the user trying to sign in.
 * @param sub - The "sub" parameter is typically used as a unique identifier for a user in an
 * authentication system. It stands for "subject" and is often a UUID or other unique identifier.
 * @param givenName - givenName is a parameter that represents the first name of the user who is trying
 * to sign in. It is used in the signIn function to send the user's first name to the server as part of
 * the login request.
 * @param familyName - The `familyName` parameter is a string representing the last name or surname of
 * the user who is signing in. It is used as part of the user's profile information when they log in.
 * @returns the `data` property of the `response` object if the request is successful. If there is an
 * error, it will log the error message and the detail of the error response, and return an empty
 * object.
 */
export const signIn = async data => {
  try {
    const response = await signInAPI.post(`login`, data);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};
