const createColumnTypes = {
  nglTextType: {
    filter: 'agTextColumnFilter',
  },
  nglDateType: {
    filter: 'agDateColumnFilter',
  },
};

export { createColumnTypes };
