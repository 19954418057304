const generateExcelExportParams = ({ getCustomContentBelowRow } = {}) => {
  return {
    allColumns: true,
    getCustomContentBelowRow,
    suppressColumnOutline: true,
    columnWidth: params => {
      const { cellClass } = params.column.getColDef();
      const actualWidth = params.column.getActualWidth();

      if (cellClass === 'date' && actualWidth <= 80) {
        return 80;
      }

      if (cellClass === 'datetime' && actualWidth > 80 && actualWidth <= 105) {
        return 105;
      }

      return actualWidth;
    },
  };
};

export default generateExcelExportParams;
