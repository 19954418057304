import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  AP_AR_TYPE_OPTION,
  DRIVER_TYPE_OPTION,
  FACTORING_OPTION,
  PERIOD_OPTION,
} from '../../../../constants/Acct/Common/selectOptions';
import CommonSelect from '../../../Inputs/CommonSelect/CommonSelect';

import {
  changeAutoComplete,
  changeSelect,
} from '../../../../utils/Acct/Common/Handler/changedInputFieldHandler';

import {
  changeCategory,
  changeDriverType,
  changePeriod,
  changeRangeDate,
  changeType,
} from '../../../../utils/Acct/Common/Handler/changedAdvancedSearchFieldHandler';
import { HYPEN_ICON } from '../../../../constants/Acct/Common/iconPath';
import useControlAdvancedSearch from '../../../../hooks/Acct/Common/Control/useControlAdvancedSearch';
import CommonAutoComplete from '../../../Inputs/CommonAutoComplete/CommonAutoComplete';
import CommonDate from '../../../Inputs/CommonDate/CommonDate';
import useControlKeywordOptionList from '../../../../hooks/Acct/Common/Control/useControlKeywordOptionList';

const AdvancedSearchModalContent = ({
  page,
  queryObject,
  optionList,
  isOpenModal,
  isLoadingCustomerList,
  isLoadingVendorList,
  isLoadingDriverList,
}) => {
  const { watch, setValue, resetField } = useFormContext();
  const {
    isShowStatus,
    isShowDriverType,
    setIsShowDriverType,
    apARTypeWidth,
    factoringWidth,
    keyWordWidth,
    setKeywordWidth,
    isDisabledFactoring,
    setIsDisabledFactoring,
    isDisabledKeyWord,
    setIsDisabledKeyWord,
    lastDateRange,
    setLastDateRange,
    statusDefaultValue,
    factoringDefaultValue,
    statusOption,
    categoryOption,
    setCategoryOption,
    dateOption,
  } = useControlAdvancedSearch({
    page,
    queryObject,
    watch,
    setValue,
    isOpenModal,
    optionList,
  });

  const { keywordOption, setKeywordOption } = useControlKeywordOptionList({
    optionList,
    watch,
  });

  return (
    <div className="w-[488px] h-[262px] bg-[#F9F9F9] px-[30px] py-[15px]">
      {/* First Row */}
      <div className="flex gap-x-[23px]">
        <div className={`${apARTypeWidth}`}>
          <CommonSelect
            label="AP / AR"
            inputName="type"
            option={AP_AR_TYPE_OPTION}
            defaultValue={queryObject?.type || 'ALL'}
            onChangeHandler={props =>
              changeType({
                watch,
                setValue,
                setIsDisabledFactoring,
                setCategoryOption,
                ...props,
              })
            }
          />
        </div>
        <div className={`${factoringWidth}`}>
          <CommonSelect
            label="FACTORING"
            inputName="factoring"
            option={FACTORING_OPTION}
            defaultValue={factoringDefaultValue}
            disabled={isDisabledFactoring}
            onChangeHandler={changeSelect}
          />
        </div>
        {isShowStatus && (
          <div className="w-[116px]">
            <CommonSelect
              label="STATUS"
              inputName="status"
              option={statusOption}
              defaultValue={statusDefaultValue}
              onChangeHandler={changeSelect}
            />
          </div>
        )}
      </div>

      {/* Second Row */}
      <div className="flex gap-x-[10px]">
        <div className="w-[153px]">
          <CommonSelect
            label="CATEGORY"
            defaultValue={queryObject?.category || ''}
            option={categoryOption}
            inputName="category"
            onChangeHandler={props =>
              changeCategory({
                watch,
                setValue,
                resetField,
                setIsDisabledKeyWord,
                setIsDisabledFactoring,
                setKeywordOption,
                setIsShowDriverType,
                setKeywordWidth,
                optionList,
                ...props,
              })
            }
          />
        </div>
        {isShowDriverType && (
          <div className="w-[116px]">
            <CommonSelect
              inputName="driver-type"
              defaultValue={queryObject?.['driver-type'] || 'ALL'}
              option={DRIVER_TYPE_OPTION}
              onChangeHandler={props =>
                changeDriverType({
                  watch,
                  setValue,
                  setKeywordOption,
                  optionList,
                  ...props,
                })
              }
            />
          </div>
        )}
        <div className={`${keyWordWidth} ${!isShowDriverType && 'ml-[10px]'}`}>
          <CommonAutoComplete
            inputName="keyword"
            disabled={isDisabledKeyWord}
            option={keywordOption}
            defaultValue={queryObject?.keyword || ''}
            onChangeHandler={changeAutoComplete}
            isLoading={
              isLoadingCustomerList ||
              isLoadingVendorList ||
              isLoadingDriverList
            }
          />
        </div>
      </div>

      {/* Third Row */}
      <div className="flex gap-x-[10px]">
        <div className="w-[153px]">
          <CommonSelect
            label="DATE"
            defaultValue={queryObject?.dateType || 'inv'}
            option={dateOption}
            inputName="dateType"
            onChangeHandler={changeSelect}
          />
        </div>
      </div>

      {/* Fourth Row */}
      <div className="flex">
        <div className="mr-[20px] w-[153px]">
          <CommonSelect
            label="PERIOD"
            inputName="period"
            defaultValue={queryObject?.period}
            option={PERIOD_OPTION}
            onChangeHandler={props =>
              changePeriod({ watch, setValue, setLastDateRange, ...props })
            }
          />
        </div>
        <div className="mr-[5px] w-[116px]">
          <CommonDate
            inputName="from_date"
            required
            defaultValue={queryObject?.from_date}
            onChangeHandler={props =>
              changeRangeDate({
                watch,
                setValue,
                type: 'from',
                lastDateRange,
                ...props,
              })
            }
          />
        </div>
        <div className="mr-[5px] flex items-center">
          <img src={HYPEN_ICON} alt="hyphen-icon" width={13} />
        </div>
        <div className="w-[116px]">
          <CommonDate
            inputName="to_date"
            required
            defaultValue={queryObject?.to_date}
            width="w-[116px]"
            onChangeHandler={props =>
              changeRangeDate({
                watch,
                setValue,
                type: 'to',
                lastDateRange,
                ...props,
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default AdvancedSearchModalContent;
