const mutationHandler = (mutation, props, isDelete) => {
  props?.setAddDisabled?.(true);

  if (isDelete) {
    mutation.mutate({ seq: props.gridParam.data.seq });
  } else {
    mutation.mutate({
      data: props.gridParam.data,
      setAddDisabled: props?.setAddDisabled,
    });
  }
};

export default mutationHandler;
