const generateSaveItemData = ({
  listName,
  email,
  div,
  searchParams,
  columnDefs,
}) => {
  let customerColumn = [
    ...columnDefs?.flatMap(item => {
      if (item?.children) return item?.children?.map(child => child?.field);
      return item?.field;
    }),
  ];

  if (columnDefs?.find(item => item?.field === 'cont_no')) {
    customerColumn = [...customerColumn, 'trailer_no'];
  }
  if (columnDefs?.find(item => item?.field === 'empty_cfm')) {
    customerColumn = [...customerColumn, 'load_cfm'];
  }

  return {
    div,
    email,
    list_name: listName,
    cus_column: customerColumn,
    billto: searchParams?.get('billto'),
    status: searchParams?.get('status'),
    po_location: searchParams?.get('po_location'),
    dilv_location: searchParams?.get('dilv_location'),
    rtn_location: searchParams?.get('rtn_location'),
    date_1: searchParams?.get('date_1'),
    before_1: searchParams?.get('before_1'),
    after_1: searchParams?.get('after_1'),
    date_2: searchParams?.get('date_2'),
    before_2: searchParams?.get('before_2'),
    after_2: searchParams?.get('after_2'),
    date_3: searchParams?.get('date_3'),
    before_3: searchParams?.get('before_3'),
    after_3: searchParams?.get('after_3'),
  };
};

export default generateSaveItemData;
