import { useMutation } from '@tanstack/react-query';
import patchEarlyWarning from '../../../../services/Admin/EarlyWarning/Patch/patchEarlyWarning';
import useControlToast from '../../../Common/Toast/useControlToast';
import { SUCCESS_UPDATE } from '../../../../constants/Common/successMessage';
import updateGridData from '../../../../utils/Common/Handler/updateGridData';
import resetRow from '../../../../utils/Common/Handler/resetRow';

const usePatchEarlyWarning = ({
  gridRef,
  setIsOpenErrorModal,
  setErrorMessage,
  setIsSuccess,
}) => {
  const { addToast } = useControlToast();

  const mutate = useMutation({
    mutationFn: patchEarlyWarning,
    onSuccess: (data, variables) => {
      addToast({ message: SUCCESS_UPDATE });
      updateGridData({
        newData: { seq: variables?.seq, ...variables?.patchData },
        isUpdate: true,
      });
    },
    onError: error => {
      resetRow({ gridRef });
      setIsOpenErrorModal(true);
      setErrorMessage(error?.message);
      setIsSuccess(false);
    },
  });

  return { ...mutate };
};

export default usePatchEarlyWarning;
