import React from 'react';
import { Outlet } from 'react-router-dom';
import MainLayOut from '../../components/Layout/MainLayOut';
import { PAGE_DISPATCH } from '../../constants/Common/mapper';
import SubMenuNavigator from '../../components/Common/SubMenuNavigator';

const Dispatch = () => {
  return (
    <MainLayOut>
      <SubMenuNavigator page={PAGE_DISPATCH} />
      <div className="w-full h-full min-w-[1720px] box-border pl-[97px]">
        <Outlet />
      </div>
    </MainLayOut>
  );
};

export default Dispatch;
