import React from 'react';

const WeightPerZipCodeContainer = () => {
  return (
    <div className="flex mt-[50px]">
      <img src="/assets/images/common_icon/apArrowIcon.svg" alt="arrow" />
      <div className="ml-[12px] text-blue-800 text-sm font-bold font-['Roboto']">
        WEIGHT PER ZIP CODE (TARGET)
      </div>
    </div>
  );
};

export default WeightPerZipCodeContainer;
