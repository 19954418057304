export const generateVendorOption = ({ companyList }) => {
  const vendorOptionList = companyList?.map?.((vendor, index) => {
    return {
      key: index,
      label: `${vendor?.company} / ${vendor?.business_name}`,
      value: vendor?.company,
    };
  });

  return vendorOptionList || [];
};

export const generateProductOption = ({ productList }) => {
  const productOptionsList = productList?.map((product, index) => ({
    key: index,
    label: `${product?.product_code_name} /  ${product?.product_code}`,
    value: product?.product_code,
  }));

  return productOptionsList;
};

export const generateStatusOption = ({ statusOptions, queryObject }) => {
  const updateOptions = statusOptions?.map(item => {
    const updatedValue = queryObject?.[item?.inputName];
    if (updatedValue !== undefined) {
      return { ...item, defaultValue: updatedValue };
    }
    return item;
  });

  return updateOptions;
};
