import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import getBookingNumber from '../../../../services/BCDetail/BasicInfo/Get/getBookingNumber';
import bcDetailKeys from '../../keys';

/**
 * @description
 * Custom hook that get booking number according to size, type, ssl
 * @param {string} size
 * @param {string} type
 * @param {string} ssl
 * @returns {object}
 * Booking number data and query function
 */
const useGetBookingNumber = ({ woNo, size, type, ssl, pageTitle }) => {
  /** Booking number data */
  const [bookingNumber, setBookingNumber] = useState(null);

  /** Query */
  const query = useQuery({
    queryKey: bcDetailKeys?.bookingNumber({ woNo, size, type, ssl }),
    queryFn: () => getBookingNumber({ woNo, size, type, ssl }),
    refetchOnWindowFocus: false,
  });

  /** Bind new data if new data is assigned */
  useEffect(() => {
    if (query?.data?.data) {
      setBookingNumber(query?.data?.data);
    }
  }, [query?.data]);

  return { ...query, bookingNumber };
};

export default useGetBookingNumber;
