import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { useFormContext, useWatch } from 'react-hook-form';
import convertScheduleType from '../../../../../../../../../utils/BCDetail/DetailInfo/Schedule/Handler/convertScheduleType';
import handlePDFLocationColor from '../../../../../../../../../utils/BCDetail/DetailInfo/Common/Handler/handlePDFLocationColor';
import setLocationBackgroundColor from '../../../../../../../../../utils/BCDetail/DetailInfo/Common/Handler/setLocationBackgroundColor';

const LocationRowContent = ({ ...props }) => {
  const { scheduleData } = props;
  const { seq, type, company, sche_date: scheDate, ordering } = scheduleData;
  const { control, setValue, getValues } = useFormContext();

  const puFrom = useWatch({ control, name: 'pu_from' });
  const dilvTo = useWatch({ control, name: 'dilv_to' });

  return (
    <div
      className={`flex justify-center items-center flex-row h-[30px]
                text-[11px] text-[#666] font-medium gap-[2px] cursor-pointer`}
      onClick={() => {
        handlePDFLocationColor({
          setValue,
          getValues,
          type,
          ordering,
          company: seq,
        });
      }}
    >
      <div
        className={`w-[80px] h-full flex justify-center items-center flex-row ${setLocationBackgroundColor(
          { puFrom, dilvTo, id: `${type}_${ordering}` },
        )}`}
      >
        <div className="w-[7px] h-full bg-[#264B9F]" />
        <div
          className="flex justify-center items-center flex-1
                      font-bold text-[#264B9F]"
        >
          {ordering !== 0
            ? 'STOP'
            : convertScheduleType({ data: scheduleData })}
        </div>
      </div>
      <div
        className="w-[99px] h-full flex justify-center items-center bg-[#F2F4F8]
                    drop-shadow-[0_0.6px_0px_rgba(0,0,0,0.25)]"
      >
        {company?.company}
      </div>
      <div
        className="w-[99px] h-full flex justify-center items-center bg-[#F2F4F8]
                    drop-shadow-[0_0.6px_0px_rgba(0,0,0,0.25)]"
      >
        {company?.city}
      </div>
      <div
        className="w-[74px] h-full flex justify-center items-center bg-[#F2F4F8]
                    drop-shadow-[0_0.6px_0px_rgba(0,0,0,0.25)]"
      >
        {company?.state}
      </div>
      <div
        className="w-[74px] h-full flex justify-center items-center bg-[#DDE2EC]
                    drop-shadow-[0_0.6px_0px_rgba(0,0,0,0.25)]"
      >
        {company?.timezone}
      </div>
      <div
        className="w-[112px] h-full flex justify-center items-center bg-[#F2F4F8]
                    drop-shadow-[0_0.6px_0px_rgba(0,0,0,0.25)]"
      >
        {scheDate && dayjs(scheDate)?.isValid()
          ? dayjs(scheDate).format('MM/DD/YY')
          : ''}
      </div>
    </div>
  );
};

export default LocationRowContent;
