import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import getLiveShareData from '../../services/LiveShare/Get/getLiveShareData';
import liveShareKeys from './keys';

const useGetLiveShareData = ({
  setLoadingOpener,
  setIsSuccess,
  setErrorMessage,
  setErrorOpener,
}) => {
  const [liveShareData, setLiveShareData] = useState(null);
  const { trackingID } = useParams();

  const query = useQuery({
    queryKey: liveShareKeys?.liveShare({ trackingID }),
    queryFn: () => getLiveShareData({ trackingID }),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (query?.data) {
      setLiveShareData(query?.data?.data);
    }
  }, [query?.data]);

  /** Set loading opeenr */
  useEffect(() => {
    if (query?.isLoading || query?.isFetching) setLoadingOpener(true);
    if (!query?.isLoading && !query?.isFetching) setLoadingOpener(false);
  }, [query?.isLoading, query?.isFetching]);

  /** Set error */
  useEffect(() => {
    if (query?.isError) {
      setErrorMessage?.(query?.error?.message);
      setIsSuccess(false);
      setErrorOpener(true);
    }
  }, [query?.isError]);

  return { ...query, liveShareData };
};

export default useGetLiveShareData;
