import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CommonBlur from '../../../CommonBlur/CommonBlur';
import SideModalHeader from '../SideModalHeader';
import SideModalContent from '../SideModalContent';
import SideModalButtonArea from '../SideModalButtonArea';

const SideUserModal = ({
  userID,
  divList,
  authList,
  isOpenSideModal,
  setIsOpenSideModal,
  userInfo,
  userInfoPatchMutator,
  userController,
  isFormInvalid,
}) => {
  return (
    <FormProvider {...userController}>
      <form>
        <CommonBlur open={isOpenSideModal}>
          <div className="w-full h-full flex justify-end">
            <div className="bg-white min-w-[471px] w-[471px] h-full animate-fade-in-right pl-[84px]">
              {!!userInfo && (
                <>
                  <SideModalHeader setIsOpenSideModal={setIsOpenSideModal} />
                  <SideModalContent
                    userInfo={userInfo}
                    divList={divList}
                    authList={authList}
                  />
                  <SideModalButtonArea
                    userID={userID}
                    divList={divList}
                    userInfoPatchMutator={userInfoPatchMutator}
                    isFormInvalid={isFormInvalid}
                  />
                </>
              )}
            </div>
          </div>
        </CommonBlur>
      </form>
    </FormProvider>
  );
};

export default SideUserModal;
