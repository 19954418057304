import React, { useEffect, useState } from 'react';
import StyledBCDetailBillingInfoCommonFooterContainer from '../../../../../styles/BCDetail/BillingInfo/Common/TableFooter/StyledBCDetailBillingInfoCommonFooterContainer';
import StyledBCDetailBillingInfoCommonFooterDollarSign from '../../../../../styles/BCDetail/BillingInfo/Common/TableFooter/StyledBCDetailBillingInfoCommonFooterDollarSign';
import StyledBCDetailBillingInfoCommonFooterTotalHeader from '../../../../../styles/BCDetail/BillingInfo/Common/TableFooter/StyledBCDetailBillingInfoCommonFooterTotalHeader';
import StyledBCDetailBillingInfoCommonFooterTotalValue from '../../../../../styles/BCDetail/BillingInfo/Common/TableFooter/StyledBCDetailBillingInfoCommonFooterTotalValue';
import StyledBCDetailBillingInfoCommonFooterTotalValueContainer from '../../../../../styles/BCDetail/BillingInfo/Common/TableFooter/StyledBCDetailBillingInfoCommonFooterTotalValueContainer';
import formatNumber from '../../../../../utils/BCDetail/BillingInfo/Common/formatNumber';

const APTableFooter = ({ APQuery, setProfit }) => {
  const [APTotal, setAPTotal] = useState(
    APQuery?.APData?.reduce((total, obj) => total + parseFloat(obj.amt), 0),
  );

  useEffect(() => {
    if (APQuery?.APData) {
      const calcAPTotal = APQuery?.APData?.reduce(
        (total, obj) => total + parseFloat(obj.amt),
        0,
      );
      setAPTotal(calcAPTotal);
      setProfit(prev => {
        return { ...prev, ap: calcAPTotal };
      });
    }
  }, [APQuery?.APData]);

  return (
    !APQuery?.isLoading && (
      <StyledBCDetailBillingInfoCommonFooterContainer>
        <StyledBCDetailBillingInfoCommonFooterTotalHeader backgroundColor="#CCD3E3">
          WO AP TOTAL
        </StyledBCDetailBillingInfoCommonFooterTotalHeader>
        <StyledBCDetailBillingInfoCommonFooterTotalValueContainer backgroundColor="#CCD3E3">
          <StyledBCDetailBillingInfoCommonFooterTotalValue>
            <StyledBCDetailBillingInfoCommonFooterDollarSign>
              $
            </StyledBCDetailBillingInfoCommonFooterDollarSign>
            {formatNumber(APTotal)}
          </StyledBCDetailBillingInfoCommonFooterTotalValue>
        </StyledBCDetailBillingInfoCommonFooterTotalValueContainer>
      </StyledBCDetailBillingInfoCommonFooterContainer>
    )
  );
};

export default APTableFooter;
