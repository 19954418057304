import { useEffect, useState } from 'react';

const useHandleAPModal = () => {
  const [apModalOpen, setAPModalOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);

  return { apModalOpen, setAPModalOpen, selectedJob, setSelectedJob };
};

export default useHandleAPModal;
