import handleQueryError from '../../../utils/Common/Handler/handleQueryError';
import cusReportDefaultUrl from '../api';

const postSaved = async ({ div, listName }) => {
  try {
    const response = await cusReportDefaultUrl.post(`saved`, {
      div,
      list_name: listName,
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default postSaved;
