import updateButtonDisabled from '../../../Common/Handler/updateButtonDisabled';

const handleRowSave = ({
  submitData,
  id,
  type,
  woNo,
  documentList,
  documentMutator,
  buttonRef,
  ...props
}) => {
  if (buttonRef?.current)
    updateButtonDisabled({ ref: buttonRef, disabled: true });

  const docData = {};

  const newList = [...documentList];
  newList[id - 1] = submitData[id];

  docData.doc = JSON.stringify(newList);
  docData[type] = submitData?.file?.[id]?.[type];

  if (type === 'DO' && docData.DO === null) {
    props?.setMessageModalOpen(true);
    props?.setMessageContent('Please upload DO.');
    updateButtonDisabled({ ref: buttonRef, disabled: false });
    return;
  }

  documentMutator({
    woNo,
    docData,
    buttonRef,
  });
};

export default handleRowSave;
