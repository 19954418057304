import { CELL_TYPE_AUTO_COMPLETE } from '../../../../constants/Common/DataGrid/cellType';
import cellEditorGenerator from '../../../Common/Datagrid/Generator/cellEditorGenerator';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';
import handleBookingChange from '../Handler/handleBookingChange';
import getCellStyle from './getCellStyle';

const getEditableBookingDef = () => {
  return {
    cellEditorSelector: gridParam =>
      cellEditorGenerator({
        gridParam,
        cellType: CELL_TYPE_AUTO_COMPLETE,
        onChangeHandler: handleBookingChange,
      }),
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        cellType: CELL_TYPE_AUTO_COMPLETE,
        customStyle: getCellStyle({ ...gridParam }),
      }),
    valueSetter: params => {
      params.data.bk_seq = params?.newValue;
      return true;
    },
    valueGetter: params => {
      return params.data.bk_no ? params.data.bk_no : '';
    },
  };
};

export default getEditableBookingDef;
