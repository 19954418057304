export const POOL_DEFAULT_DATA_SEQ = [1];

export const TYPE_DEFAULT_DATA_SEQ = [1];

export const SIZE_DEFAULT_DATA_SEQ = [1, 2, 3, 4, 5, 6];

export const CONF_DEFAULT_DATA = {
  pool: POOL_DEFAULT_DATA_SEQ,
  type: TYPE_DEFAULT_DATA_SEQ,
  size: SIZE_DEFAULT_DATA_SEQ,
};
