import React, { useEffect, useState } from 'react';

const TopBar = ({ basicInfoData }) => {
  const woType = location.pathname.split('/')[3];
  const [equipmentType, setEquipmentType] = useState('');
  const [equipmentValue, setEquipmentValue] = useState(null);

  useEffect(() => {
    if (woType === 'import' || woType === 'export') {
      setEquipmentType('CONT #');
      setEquipmentValue(basicInfoData?.cont_no);
    } else if (woType === 'van') {
      setEquipmentType('TRAILER #');
      setEquipmentValue(basicInfoData?.trailer_no);
    } else if (woType === 'brokerage') {
      setEquipmentType('EQUIPMENT');
      setEquipmentValue(basicInfoData?.trailer_no);
    } else if (woType === 'bare-chassis') {
      setEquipmentType('CHASSIS #');
      setEquipmentValue(basicInfoData?.ch_no);
    }
  }, [woType, basicInfoData]);

  return (
    <div className="flex">
      <div className="flex justify-center items-center w-[73px] h-[30px] bg-[#264B9F] shadow text-[11px] text-white font-bold">
        BILLTO
      </div>
      <div className="flex justify-center items-center ml-[2px] w-[168px] h-[30px] bg-[#F2F4F8] shadow text-stone-500 text-[11px] font-medium ">
        {basicInfoData?.billto?.company}
      </div>
      <div className="flex justify-center items-center ml-[7px] w-[72px] h-[30px] bg-[#264B9F] shadow text-[11px] text-white font-bold">
        {equipmentType}
      </div>
      <div className="flex justify-center items-center ml-[2px] w-[168px] h-[30px] bg-[#F2F4F8] shadow text-stone-500 text-[11px] font-medium">
        {equipmentValue}
      </div>
      <div className="flex justify-center items-center w-[73px] h-[30px] bg-[#264B9F] shadow text-[11px] text-white font-bold">
        CHASSIS #
      </div>
      <div className="flex justify-center items-center ml-[2px] w-[168px] h-[30px] bg-[#F2F4F8] shadow text-stone-500 text-[11px] font-medium ">
        {basicInfoData?.ch_no}
      </div>
    </div>
  );
};

export default TopBar;
