import { useQuery } from '@tanstack/react-query';
import masterKeys from '../keys';
import getAPRateList from '../../../services/Master/APRate/Get/getAPRateList';

const useGetAPRateList = ({ div }) => {
  const {
    isLoading: loadingAPList,
    data: apRateList,
    isRefetching,
    isError: errorAPRateList,
  } = useQuery({
    queryKey: masterKeys?.apRateList({ div }),
    queryFn: () => getAPRateList({ div }),
    refetchOnWindowFocus: false,
  });
  return { loadingAPList, apRateList, isRefetching, errorAPRateList };
};

export default useGetAPRateList;
