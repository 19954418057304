import React, { useEffect, useState } from 'react';
import StyledBCDetailBillingInfoScheduleHazContainer from '../../../../styles/BCDetail/BillingInfo/Common/Schedule/StyledBCDetailBillingInfoScheduleHazContainer';

const TopBar = ({ basicInfoData }) => {
  const woType = location.pathname.split('/')[3];
  const [equipmentType, setEquipmentType] = useState('');
  const [equipmentValue, setEquipmentValue] = useState(null);

  useEffect(() => {
    if (woType === 'import' || woType === 'export') {
      setEquipmentType('CONT #');
      setEquipmentValue(basicInfoData?.cont_no);
    } else if (woType === 'van') {
      setEquipmentType('TRAILER #');
      setEquipmentValue(basicInfoData?.trailer_no);
    } else if (woType === 'brokerage') {
      setEquipmentType('EQUIPMENT');
      setEquipmentValue(basicInfoData?.trailer_no);
    } else if (woType === 'bare-chassis') {
      setEquipmentType('CHASSIS #');
      setEquipmentValue(basicInfoData?.ch_no);
    }
  }, [woType, basicInfoData]);

  const bondBGColor = basicInfoData?.bond_entry
    ? 'bg-[#FF9292]'
    : 'bg-[#FFFFFF]';

  const hazBGColor = basicInfoData?.hazmat
    ? basicInfoData?.div?.bg_color?.find(item => item?.type === 'Hazmat')?.color
    : 'bg-[#FFFFFF]';

  return (
    <div className="flex mt-[51px]">
      <div className="flex justify-center items-center w-[73px] h-[30px] bg-[#264B9F] shadow text-[11px] text-white font-bold">
        BILLTO
      </div>
      <div className="flex justify-center items-center ml-[2px] w-[168px] h-[30px] bg-[#F2F4F8] shadow text-stone-500 text-[11px] font-medium">
        {basicInfoData?.billto?.company}
      </div>
      <div className="flex justify-center items-center ml-[7px] w-[72px] h-[30px] bg-[#264B9F] shadow text-[11px] text-white font-bold">
        {equipmentType}
      </div>
      <div className="flex justify-center items-center ml-[2px] w-[168px] h-[30px] bg-[#F2F4F8] shadow text-stone-500 text-[11px] font-medium">
        {equipmentValue}
      </div>
      <div className="flex justify-center items-center ml-[7px] w-[49px] h-[30px] bg-[#264B9F] shadow text-[11px] text-white font-bold">
        SZ
      </div>
      <div className="flex justify-center items-center ml-[2px] w-[59px] h-[30px] bg-[#F2F4F8] shadow text-stone-500 text-[11px] font-medium">
        {basicInfoData?.size}
      </div>
      <div className="flex justify-center items-center ml-[7px] w-[60px] h-[30px] bg-[#264B9F] shadow text-[11px] text-white font-bold">
        WGT
      </div>
      <div className="flex justify-center items-center ml-[2px] w-[76px] h-[30px] bg-[#F2F4F8] shadow text-stone-500 text-[11px] font-medium">
        {basicInfoData?.wgt}
      </div>
      <div className="flex justify-center items-center ml-[7px] w-[60px] h-[30px] bg-[#264B9F] shadow text-[11px] text-white font-bold">
        QTY
      </div>
      <div className="flex justify-center items-center ml-[2px] w-[76px] h-[30px] bg-[#F2F4F8] shadow text-stone-500 text-[11px] font-medium">
        {basicInfoData?.qty}
      </div>
      <div
        className={`flex justify-center items-center ml-[7px] w-[86px] h-[30px] ${bondBGColor} border-[1px] border-[#CCD3E3] rounded text-[11px] text-[#264B9F] font-medium`}
      >
        BOND
      </div>
      <StyledBCDetailBillingInfoScheduleHazContainer
        backgroundColor={hazBGColor}
        className="flex justify-center items-center ml-[4px] w-[86px] h-[30px] border-[1px] border-[#CCD3E3] rounded text-[11px] text-[#264B9F] font-medium"
      >
        HAZ
      </StyledBCDetailBillingInfoScheduleHazContainer>
    </div>
  );
};

export default TopBar;
