import { quickBooksDefaultApi } from '../api';
import handleQueryError from '../../../utils/Common/Handler/handleQueryError';

const postQuickBooks = async ({ type, submitData }) => {
  try {
    const response = await quickBooksDefaultApi.post(
      `${type?.toLowerCase()}`,
      submitData,
    );
    return response;
  } catch (e) {
    if (e?.response?.data?.code === 'qb_auth_code')
      window.open(e?.response?.data?.url, '_blank');
    throw new Error(handleQueryError({ error: e }), { cause: e });
  }
};

export default postQuickBooks;
