import styled from 'styled-components';

const StyledSaveItemVerifyButton = styled.button`
  width: 91px;
  height: 31px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-left: none;
  color: #264b9f;
  font-size: 12px;
  font-weight: 500;
  outline: none !important;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  :enabled:hover {
    background-color: #264b9f;
    color: #fff;
    transition: 0.1s ease-out;
  }

  :disabled {
    color: #fff;
    background-color: #a6a6a6;
    cursor: not-allowed;
  }
`;

export default StyledSaveItemVerifyButton;
