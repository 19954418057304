const generateSettingList = ({ settingData }) => {
  const dataList = settingData?.map(({ list_name: name, ...rest }) => ({
    name,
    url: name
      ? `${process.env.REACT_APP_TMS_URL}/customer/${name
          ?.trim()
          ?.replace(/ /g, '%20')}`
      : '-',
    ...rest,
  }));
  return dataList;
};

export default generateSettingList;
