const sliceNumber = (number, length) => {
  let start = 0;
  let end = 0;

  if (number && length) {
    number = number?.split('.').pop();

    const result = length?.map(item => {
      start = end;
      end += item;
      return number?.slice(start, end);
    });

    return result;
  }

  return ['', '', ''];
};

export default sliceNumber;
