import { CELL_TYPE_SELECT } from '../../../../constants/Common/DataGrid/cellType';
import cellEditorGenerator from '../../../Common/Datagrid/Generator/cellEditorGenerator';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';
import getCellStyle from './getCellStyle';

const getEditableSelectDef = selectOption => {
  return {
    cellEditorSelector: gridParam =>
      cellEditorGenerator({
        gridParam,
        cellType: CELL_TYPE_SELECT,
        option: selectOption,
      }),
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        cellType: CELL_TYPE_SELECT,
        option: selectOption,
        customStyle: getCellStyle({ ...gridParam }),
      }),
  };
};

export default getEditableSelectDef;
