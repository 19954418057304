import React from 'react';
import { PATH_MODAL_CLOSE_ICON } from '../../../constants/Common/path';

const CommonSideModalCloseIcon = ({ ...props }) => {
  return (
    <div data-testid="closeIcon" onClick={props?.onCloseClick}>
      <img
        className="cursor-pointer"
        src={PATH_MODAL_CLOSE_ICON}
        alt="modalCloseIcon"
        width={12}
        height={12}
      />
    </div>
  );
};

export default CommonSideModalCloseIcon;
