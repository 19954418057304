import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import dailyReportKeys from '../keys';
import getDailySchedule from '../../../services/TmsReport/Schedules/Get/getDailySchedule';
import getURLSearchParams from '../../../utils/TmsReport/Schedule/Generator/getURLSearchParams';
import getDailyScheduleFilter from '../../../utils/DailyReport/DailyReport/Generator/getDailyScheduleFilter';

const useGetDailySchedule = ({
  date,
  type,
  setErrorMessage,
  setIsErrorModalOpen,
}) => {
  const dailyScheduleFilter = getDailyScheduleFilter();
  const params = getURLSearchParams({ ...dailyScheduleFilter, date });
  const pageCategory = document.URL.split('/')?.pop()?.split('?')?.[0];
  const result = useQuery({
    queryKey: dailyReportKeys.list({ ...dailyScheduleFilter, date, type }),
    queryFn: () => getDailySchedule({ params, pageCategory }),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (result?.isError) {
      setIsErrorModalOpen(true);
      setErrorMessage(result?.error?.message);
    }
  }, [result?.isError]);

  return result;
};

export default useGetDailySchedule;
