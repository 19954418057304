import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';
import { REGEX_NUMBER_MINUS_DECIMAL } from '../../../../constants/Common/regex';
import {
  CELL_TYPE_AUTO_COMPLETE,
  CELL_TYPE_BUTTON,
  CELL_TYPE_DATE,
  CELL_TYPE_SELECT,
  CELL_TYPE_TEXT,
  CELL_TYPE_TIME,
  CELL_TYPE_SWITCH,
} from '../../../../constants/Common/DataGrid/cellType';
import ModTextCell from '../../../../components/CommonDataGrid/Component/ModificationCell/ModTextCell';
import cellEditorGenerator from '../../../Common/Datagrid/Generator/cellEditorGenerator';
import modOnChangeHandler from '../Handler/modOnChangeHandler';
import { PATH_BUTTON_GRID_EXPORT_UNSELECTED_ICON } from '../../../../constants/Common/path';

const columnDefsGenerator = ({ poolList }) => {
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: 'Add Component Test',
      backgroundColor: '#FAD7D7',
      color: 'red',
      icon: {
        unfold: '/assets/images/common_icon/check.svg',
        fold: '/assets/images/common_icon/clock.svg',
      },
      children: [
        {
          headerName: 'Pure Text',
          field: 'company',
          type: 'nglTextType',
          unSortIcon: true,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
            }),
        },
        {
          field: 'Select',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: [
                {
                  label: 'Test1',
                  value: 'Test1',
                },
                {
                  label: 'Test2',
                  value: 'Test2',
                },
                {
                  label: 'Test3',
                  value: 'Test3',
                },
              ],
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: [
                {
                  label: 'Test1',
                  value: 'Test1',
                },
                {
                  label: 'Test2',
                  value: 'Test2',
                },
                {
                  label: 'Test3',
                  value: 'Test3',
                },
              ],
            }),
        },
        {
          field: 'Auto Complete',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_AUTO_COMPLETE,
              option: [
                {
                  label: 'James handsome',
                  value: 'James handsome',
                },
                {
                  label: 'David handsome',
                  value: 'David handsome',
                },
                {
                  label: 'Hwi handsome',
                  value: 'Hwi handsome',
                },
              ],
            }),

          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_AUTO_COMPLETE,
              option: [
                {
                  label: 'James handsome',
                  value: 'James handsome',
                },
                {
                  label: 'David handsome',
                  value: 'David handsome',
                },
                {
                  label: 'Hwi handsome',
                  value: 'Hwi handsome',
                },
              ],
            }),
        },
        {
          field: 'Date',
          type: 'nglDateType',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_DATE,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_DATE,
            }),
          valueGetter: params => {
            return params.data?.Date && dayjs(params.data?.Date)?.isValid()
              ? dayjs(params.data?.Date)?.format('MM/DD/YY')
              : null;
          },
        },
        {
          field: 'Time',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_TIME,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_TIME,
            }),
          valueGetter: params => {
            return params.data?.Time && dayjs(params.data?.Time)?.isValid()
              ? dayjs(params.data?.Time)?.format('HH:mm')
              : null;
          },
        },
      ],
    },
    {
      headerName: 'Currency Component Test',
      backgroundColor: '#FAD7D7',
      color: '#fff',
      children: [
        {
          headerName: 'Dollar [ Only Number and Dot ]',
          field: 'Dollar',
          type: 'nglTextType',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              adornment: {
                position: 'start',
                content: '$',
              },
              regex: REGEX_NUMBER_MINUS_DECIMAL,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              adornment: {
                position: 'start',
                content: '$',
              },
              regex: REGEX_NUMBER_MINUS_DECIMAL,
            }),
        },
        {
          headerName: 'Dollar [ Only Number and Dot ]',
          field: 'Percent',
          columnGroupShow: 'open',
          type: 'nglTextType',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              adornment: {
                position: 'end',
                content: '%',
              },
              regex: REGEX_NUMBER_MINUS_DECIMAL,
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              adornment: {
                position: 'end',
                content: '%',
              },
              regex: REGEX_NUMBER_MINUS_DECIMAL,
            }),
        },
        {
          headerName: 'David',
          field: 'david',
          columnGroupShow: 'open',
          type: 'nglTextType',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              adornment: {
                position: 'start',
                content: 'David is ',
              },
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              adornment: {
                position: 'start',
                content: 'James is ',
              },
            }),
        },
      ],
    },
    {
      headerName: 'Cell Renderer Component',
      backgroundColor: '#FAD7D7',
      children: [
        {
          headerName: 'Basic Type',
          field: 'company',
          unSortIcon: true,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({ gridParam, cellType: CELL_TYPE_TEXT }),
        },
        {
          headerName: 'Center Align',
          columnGroupShow: 'open',
          field: 'company',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              customStyle: {
                textAlign: 'center',
              },
            }),
        },
        {
          headerName: 'Right Align',
          columnGroupShow: 'open',
          field: 'company',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              customStyle: {
                textAlign: 'right',
              },
            }),
        },
        {
          headerName: 'Basic Type / Adornment Start',
          columnGroupShow: 'open',
          field: 'company',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              adornment: {
                position: 'start',
                content: '$',
              },
            }),
        },
        {
          headerName: 'Center Align / Adornment Start',
          columnGroupShow: 'open',
          field: 'company',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              customStyle: {
                textAlign: 'center',
              },
              adornment: {
                position: 'start',
                content: '$',
              },
            }),
        },
        {
          headerName: 'Right Align / Adornment Start',
          columnGroupShow: 'open',
          field: 'company',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              customStyle: {
                textAlign: 'right',
              },
              adornment: {
                position: 'start',
                content: '$',
              },
            }),
        },
        {
          headerName: 'Basic Type / Adornment End',
          columnGroupShow: 'open',
          field: 'company',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              adornment: {
                position: 'end',
                content: '%',
              },
            }),
        },
        {
          headerName: 'Center Align / Adornment End',
          columnGroupShow: 'open',
          field: 'company',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              customStyle: {
                textAlign: 'center',
              },
              adornment: {
                position: 'end',
                content: '%',
              },
            }),
        },
        {
          headerName: 'Right Align / Adornment End',
          columnGroupShow: 'open',
          field: 'company',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              customStyle: {
                textAlign: 'right',
              },
              adornment: {
                position: 'end',
                content: '%',
              },
            }),
        },
      ],
    },
    {
      headerName: 'Company Info',
      backgroundColor: '#F0A0A0',
      children: [
        {
          field: 'company',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({ gridParam, cellType: 'text' }),
          cellEditor: ModTextCell,
        },
        {
          field: 'category',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({ gridParam, cellType: 'text' }),
        },
        {
          field: 'type',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({ gridParam }),
        },
        {
          field: 'group_id',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({ gridParam }),
        },
      ],
    },
    {
      headerName: 'billto',
      field: 'bilto',
      cellRendererSelector: gridParam => cellRendererGenerator({ gridParam }),
    },
    {
      headerName: 'address_01',
      field: 'address_01',
      cellRendererSelector: gridParam => cellRendererGenerator({ gridParam }),
    },
    {
      headerName: 'Country Info',
      backgroundColor: '#CC4E4E',
      children: [
        {
          field: 'city',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({ gridParam }),
        },
        {
          field: 'country',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({ gridParam }),
        },
        {
          field: 'zip',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({ gridParam }),
        },
      ],
    },
    {
      headerName: 'Pair Auto Complete Test',
      backgroundColor: '#CC4E4E',
      children: [
        {
          field: 'test1',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_AUTO_COMPLETE,
              option: [
                {
                  label: 'James handsome',
                  value: 'James handsome',
                },
                {
                  label: 'David handsome',
                  value: 'David handsome',
                },
                {
                  label: 'Hwi handsome',
                  value: 'Hwi handsome',
                },
              ],
              onChangeHandler: modOnChangeHandler,
              pair: 'test2',
            }),

          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_AUTO_COMPLETE,
              option: [
                {
                  label: 'James handsome',
                  value: 'James handsome',
                },
                {
                  label: 'David handsome',
                  value: 'David handsome',
                },
                {
                  label: 'Hwi handsome',
                  value: 'Hwi handsome',
                },
              ],
              onChangeHandler: modOnChangeHandler,
              changeInputValue: (newValue, setInputValue) => {
                setInputValue(newValue);
              },
              pair: 'test2',
            }),
        },
        {
          field: 'test2',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: 'cell_test',
              option: [
                {
                  label: 'James handsome',
                  value: 'James handsome',
                },
                {
                  label: 'David handsome',
                  value: 'David handsome',
                },
                {
                  label: 'Hwi handsome',
                  value: 'Hwi handsome',
                },
              ],
              onChangeHandler: modOnChangeHandler,
              pair: 'test1',
            }),

          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: 'cell_test',
              option: [
                {
                  label: 'James handsome',
                  value: 'James handsome',
                },
                {
                  label: 'David handsome',
                  value: 'David handsome',
                },
                {
                  label: 'Hwi handsome',
                  value: 'Hwi handsome',
                },
              ],
              onChangeHandler: modOnChangeHandler,
              changeInputValue: (newValue, setInputValue) => {
                setInputValue(newValue);
              },
              pair: 'test1',
            }),
        },
      ],
    },
    {
      headerName: 'Button Test',
      backgroundColor: '#800000',
      children: [
        {
          field: 'completeBtn',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_BUTTON,
              content: 'Complete',
              category: 'complete',
              onClickHandler: ({ ...props }) => {
                console.log('This is complete handler ... : ', props);
              },
            }),
        },
        {
          field: 'exportBtn',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_BUTTON,
              content: 'Export',
              icon: PATH_BUTTON_GRID_EXPORT_UNSELECTED_ICON,
              onClickHandler: ({ ...props }) => {
                console.log('This is export handler ... : ', props);
              },
            }),
        },
      ],
    },
    {
      headerName: 'Pair Disabled Test',
      backgroundColor: '#800000',
      children: [
        {
          field: 'disabled01Text',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              pair: 'disabled02Text',
              onChangeHandler: ({ ...props }) => {
                const getPairCellInstance =
                  props?.api?.getCellRendererInstances({
                    rowNodes: [props?.node],
                    columns: [props?.pair],
                  })?.[0];

                if (props?.getChangedValue && props?.getChangedValue !== '') {
                  getPairCellInstance?.setDisabled(true);
                } else {
                  getPairCellInstance?.setDisabled(false);
                }
              },
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              pair: 'disabled02Text',
              onChangeHandler: ({ ...props }) => {
                const getPairCellInstance = props?.api?.getCellEditorInstances({
                  rowNodes: [props?.node],
                  columns: [props?.pair],
                })?.[0];

                if (props?.getChangedValue && props?.getChangedValue !== '') {
                  getPairCellInstance?.setDisabled(true);
                } else {
                  getPairCellInstance?.setDisabled(false);
                }
              },
            }),
        },
        {
          field: 'disabled02Text',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              pair: 'disabled01Text',
              onChangeHandler: ({ ...props }) => {
                const getPairCellInstance =
                  props?.api?.getCellRendererInstances({
                    rowNodes: [props?.node],
                    columns: [props?.pair],
                  })?.[0];

                if (props?.getChangedValue && props?.getChangedValue !== '') {
                  getPairCellInstance?.setDisabled(true);
                } else {
                  getPairCellInstance?.setDisabled(false);
                }
              },
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              pair: 'disabled01Text',
              onChangeHandler: ({ ...props }) => {
                const getPairCellInstance = props?.api?.getCellEditorInstances({
                  rowNodes: [props?.node],
                  columns: [props?.pair],
                })?.[0];

                if (props?.getChangedValue && props?.getChangedValue !== '') {
                  getPairCellInstance?.setDisabled(true);
                } else {
                  getPairCellInstance?.setDisabled(false);
                }
              },
            }),
        },
        /** Text  */
        {
          field: 'disabled01Hmm',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              pair: 'disabled02Hmm',
              onChangeHandler: ({ ...props }) => {
                const getPairCellInstance =
                  props?.api?.getCellRendererInstances({
                    rowNodes: [props?.node],
                    columns: [props?.pair],
                  })?.[0];

                if (props?.getChangedValue && props?.getChangedValue !== '') {
                  getPairCellInstance?.setDisabled(true);
                } else {
                  getPairCellInstance?.setDisabled(false);
                }
              },
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              pair: 'disabled02Hmm',

              onChangeHandler: ({ ...props }) => {
                const getPairCellInstance = props?.api?.getCellEditorInstances({
                  rowNodes: [props?.node],
                  columns: [props?.pair],
                })?.[0];

                if (props?.getChangedValue && props?.getChangedValue !== '') {
                  getPairCellInstance?.setDisabled(true);
                } else {
                  getPairCellInstance?.setDisabled(false);
                }
              },
            }),
        },
        /** Select */
        {
          field: 'disabled01Select',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: [
                { label: 'Test1', value: 'Test1' },
                { label: 'Test2', value: 'Test2' },
              ],
              pair: 'disabled02Select',
              onChangeHandler: ({ ...props }) => {
                const getPairCellInstance =
                  props?.api?.getCellRendererInstances({
                    rowNodes: [props?.node],
                    columns: [props?.pair],
                  })?.[0];

                if (props?.getChangedValue && props?.getChangedValue !== '') {
                  getPairCellInstance?.setDisabled(true);
                } else {
                  getPairCellInstance?.setDisabled(false);
                }
              },
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: [
                { label: 'Test1', value: 'Test1' },
                { label: 'Test2', value: 'Test2' },
              ],
              pair: 'disabled02Select',
              onChangeHandler: ({ ...props }) => {
                const getPairCellInstance = props?.api?.getCellEditorInstances({
                  rowNodes: [props?.node],
                  columns: [props?.pair],
                })?.[0];

                if (props?.getChangedValue && props?.getChangedValue !== '') {
                  getPairCellInstance?.setDisabled(true);
                } else {
                  getPairCellInstance?.setDisabled(false);
                }
              },
            }),
        },
        {
          field: 'disabled02Select',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: [
                { label: 'Test1', value: 'Test1' },
                { label: 'Test2', value: 'Test2' },
              ],
              pair: 'disabled01Select',
              onChangeHandler: ({ ...props }) => {
                const getPairCellInstance =
                  props?.api?.getCellRendererInstances({
                    rowNodes: [props?.node],
                    columns: [props?.pair],
                  })?.[0];

                if (props?.getChangedValue && props?.getChangedValue !== '') {
                  getPairCellInstance?.setDisabled(true);
                } else {
                  getPairCellInstance?.setDisabled(false);
                }
              },
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: [
                { label: 'Test1', value: 'Test1' },
                { label: 'Test2', value: 'Test2' },
              ],
              pair: 'disabled01Select',
              onChangeHandler: ({ ...props }) => {
                const getPairCellInstance = props?.api?.getCellEditorInstances({
                  rowNodes: [props?.node],
                  columns: [props?.pair],
                })?.[0];

                if (props?.getChangedValue && props?.getChangedValue !== '') {
                  getPairCellInstance?.setDisabled(true);
                } else {
                  getPairCellInstance?.setDisabled(false);
                }
              },
            }),
        },
        /** Auto-Complete */
        {
          field: 'disabled01AutoComplete',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_AUTO_COMPLETE,
              option: [
                { label: 'Test1', value: 'Test1' },
                { label: 'Test2', value: 'Test2' },
              ],
              pair: 'disabled02AutoComplete',
              onChangeHandler: ({ ...props }) => {
                const getPairCellInstance =
                  props?.api?.getCellRendererInstances({
                    rowNodes: [props?.node],
                    columns: [props?.pair],
                  })?.[0];

                if (props?.getChangedValue && props?.getChangedValue !== '') {
                  getPairCellInstance?.setDisabled(true);
                } else {
                  getPairCellInstance?.setDisabled(false);
                }
              },
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_AUTO_COMPLETE,
              option: [
                { label: 'Test1', value: 'Test1' },
                { label: 'Test2', value: 'Test2' },
              ],
              pair: 'disabled02AutoComplete',
              onChangeHandler: ({ ...props }) => {
                const getPairCellInstance = props?.api?.getCellEditorInstances({
                  rowNodes: [props?.node],
                  columns: [props?.pair],
                })?.[0];

                if (props?.getChangedValue && props?.getChangedValue !== '') {
                  getPairCellInstance?.setDisabled(true);
                } else {
                  getPairCellInstance?.setDisabled(false);
                }
              },
            }),
        },
        {
          field: 'disabled02AutoComplete',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_AUTO_COMPLETE,
              option: [
                { label: 'Test1', value: 'Test1' },
                { label: 'Test2', value: 'Test2' },
              ],
              pair: 'disabled01AutoComplete',
              onChangeHandler: ({ ...props }) => {
                const getPairCellInstance =
                  props?.api?.getCellRendererInstances({
                    rowNodes: [props?.node],
                    columns: [props?.pair],
                  })?.[0];

                if (props?.getChangedValue && props?.getChangedValue !== '') {
                  getPairCellInstance?.setDisabled(true);
                } else {
                  getPairCellInstance?.setDisabled(false);
                }
              },
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_AUTO_COMPLETE,
              option: [
                { label: 'Test1', value: 'Test1' },
                { label: 'Test2', value: 'Test2' },
              ],
              pair: 'disabled01AutoComplete',
              onChangeHandler: ({ ...props }) => {
                const getPairCellInstance = props?.api?.getCellEditorInstances({
                  rowNodes: [props?.node],
                  columns: [props?.pair],
                })?.[0];

                if (props?.getChangedValue && props?.getChangedValue !== '') {
                  getPairCellInstance?.setDisabled(true);
                } else {
                  getPairCellInstance?.setDisabled(false);
                }
              },
            }),
        },
        /** Date */
        {
          field: 'disabled01Date',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_DATE,
              pair: 'disabled02Date',
              onChangeHandler: ({ ...props }) => {
                const getPairCellInstance =
                  props?.api?.getCellRendererInstances({
                    rowNodes: [props?.node],
                    columns: [props?.pair],
                  })?.[0];

                if (props?.getChangedValue && props?.getChangedValue !== '') {
                  getPairCellInstance?.setDisabled(true);
                } else {
                  getPairCellInstance?.setDisabled(false);
                }
              },
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_DATE,
              pair: 'disabled02Date',
              onChangeHandler: ({ ...props }) => {
                const getPairCellInstance = props?.api?.getCellEditorInstances({
                  rowNodes: [props?.node],
                  columns: [props?.pair],
                })?.[0];

                if (props?.getChangedValue && props?.getChangedValue !== '') {
                  getPairCellInstance?.setDisabled(true);
                } else {
                  getPairCellInstance?.setDisabled(false);
                }
              },
            }),
        },
        {
          field: 'disabled02Date',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_DATE,
              pair: 'disabled01Date',
              onChangeHandler: ({ ...props }) => {
                const getPairCellInstance =
                  props?.api?.getCellRendererInstances({
                    rowNodes: [props?.node],
                    columns: [props?.pair],
                  })?.[0];

                if (props?.getChangedValue && props?.getChangedValue !== '') {
                  getPairCellInstance?.setDisabled(true);
                } else {
                  getPairCellInstance?.setDisabled(false);
                }
              },
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_DATE,
              pair: 'disabled01Date',
              onChangeHandler: ({ ...props }) => {
                const getPairCellInstance = props?.api?.getCellEditorInstances({
                  rowNodes: [props?.node],
                  columns: [props?.pair],
                })?.[0];

                if (props?.getChangedValue && props?.getChangedValue !== '') {
                  getPairCellInstance?.setDisabled(true);
                } else {
                  getPairCellInstance?.setDisabled(false);
                }
              },
            }),
        },
        /** Time */
        {
          field: 'disabled01Time',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_TIME,
              pair: 'disabled02Time',
              onChangeHandler: ({ ...props }) => {
                const getPairCellInstance =
                  props?.api?.getCellRendererInstances({
                    rowNodes: [props?.node],
                    columns: [props?.pair],
                  })?.[0];

                if (
                  props?.getChangedValue &&
                  dayjs(props?.getChangedValue).isValid()
                ) {
                  getPairCellInstance?.setDisabled(true);
                } else {
                  getPairCellInstance?.setDisabled(false);
                }
              },
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_TIME,
              pair: 'disabled02Time',
              onChangeHandler: ({ ...props }) => {
                const getPairCellInstance = props?.api?.getCellEditorInstances({
                  rowNodes: [props?.node],
                  columns: [props?.pair],
                })?.[0];

                if (
                  props?.getChangedValue &&
                  dayjs(props?.getChangedValue).isValid()
                ) {
                  getPairCellInstance?.setDisabled(true);
                } else {
                  getPairCellInstance?.setDisabled(false);
                }
              },
            }),
        },
        {
          field: 'disabled02Time',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_TIME,
              pair: 'disabled01Time',
              onChangeHandler: ({ ...props }) => {
                const getPairCellInstance =
                  props?.api?.getCellRendererInstances({
                    rowNodes: [props?.node],
                    columns: [props?.pair],
                  })?.[0];
                console.log(
                  'Props?.getChanged vAalue : ',
                  props?.getChangedValue,
                );

                if (
                  props?.getChangedValue &&
                  dayjs(props?.getChangedValue).isValid()
                ) {
                  getPairCellInstance?.setDisabled(true);
                } else {
                  getPairCellInstance?.setDisabled(false);
                }
              },
            }),
          cellEditorSelector: gridParam =>
            cellEditorGenerator({
              gridParam,
              cellType: CELL_TYPE_TIME,
              pair: 'disabled01Time',
              onChangeHandler: ({ ...props }) => {
                const getPairCellInstance = props?.api?.getCellEditorInstances({
                  rowNodes: [props?.node],
                  columns: [props?.pair],
                })?.[0];

                if (
                  props?.getChangedValue &&
                  dayjs(props?.getChangedValue).isValid()
                ) {
                  getPairCellInstance?.setDisabled(true);
                } else {
                  getPairCellInstance?.setDisabled(false);
                }
              },
            }),
        },
      ],
    },
    {
      field: 'Text Option Test',
      columnGroupShow: 'open',
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_SELECT,
          option: poolList,
          onChangeHandler: ({ ...props }) => {},
        }),
      cellRendererParams: { option: poolList },
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_SELECT,
          option: poolList,
          onChangeHandler: ({ ...props }) => {},
        }),
    },
  ]);

  useEffect(() => {
    setColumnDefs([
      {
        headerName: 'Add Component Test',
        backgroundColor: '#FAD7D7',
        color: 'red',
        icon: {
          unfold: '/assets/images/common_icon/check.svg',
          fold: '/assets/images/common_icon/clock.svg',
        },
        children: [
          {
            headerName: 'Pure Text',
            field: 'company',
            type: 'nglTextType',
            unSortIcon: true,
            cellRendererSelector: gridParam =>
              cellRendererGenerator({
                gridParam,
                cellType: CELL_TYPE_TEXT,
              }),
            cellEditorSelector: gridParam =>
              cellEditorGenerator({
                gridParam,
                cellType: CELL_TYPE_TEXT,
              }),
          },
          {
            field: 'Select',
            columnGroupShow: 'open',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({
                gridParam,
                cellType: CELL_TYPE_SELECT,
                option: [
                  {
                    label: 'Test1',
                    value: 'Test1',
                  },
                  {
                    label: 'Test2',
                    value: 'Test2',
                  },
                  {
                    label: 'Test3',
                    value: 'Test3',
                  },
                ],
              }),
            cellEditorSelector: gridParam =>
              cellEditorGenerator({
                gridParam,
                cellType: CELL_TYPE_SELECT,
                option: [
                  {
                    label: 'Test1',
                    value: 'Test1',
                  },
                  {
                    label: 'Test2',
                    value: 'Test2',
                  },
                  {
                    label: 'Test3',
                    value: 'Test3',
                  },
                ],
              }),
          },
          {
            field: 'Auto Complete',
            columnGroupShow: 'open',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({
                gridParam,
                cellType: CELL_TYPE_AUTO_COMPLETE,
                option: [
                  {
                    label: 'James handsome',
                    value: 'James handsome',
                  },
                  {
                    label: 'David handsome',
                    value: 'David handsome',
                  },
                  {
                    label: 'Hwi handsome',
                    value: 'Hwi handsome',
                  },
                ],
              }),

            cellEditorSelector: gridParam =>
              cellEditorGenerator({
                gridParam,
                cellType: CELL_TYPE_AUTO_COMPLETE,
                option: [
                  {
                    label: 'James handsome',
                    value: 'James handsome',
                  },
                  {
                    label: 'David handsome',
                    value: 'David handsome',
                  },
                  {
                    label: 'Hwi handsome',
                    value: 'Hwi handsome',
                  },
                ],
              }),
          },
          {
            field: 'Date',
            type: 'nglDateType',
            columnGroupShow: 'open',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({
                gridParam,
                cellType: CELL_TYPE_DATE,
              }),
            cellEditorSelector: gridParam =>
              cellEditorGenerator({
                gridParam,
                cellType: CELL_TYPE_DATE,
              }),
            valueGetter: params => {
              return params.data?.Date && dayjs(params.data?.Date)?.isValid()
                ? dayjs(params.data?.Date)?.format('MM/DD/YY')
                : null;
            },
          },
          {
            field: 'Time',
            columnGroupShow: 'open',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({
                gridParam,
                cellType: CELL_TYPE_TIME,
              }),
            cellEditorSelector: gridParam =>
              cellEditorGenerator({
                gridParam,
                cellType: CELL_TYPE_TIME,
              }),
            valueGetter: params => {
              return params.data?.Time && dayjs(params.data?.Time)?.isValid()
                ? dayjs(params.data?.Time)?.format('HH:mm')
                : null;
            },
          },
        ],
      },
      {
        headerName: 'Currency Component Test',
        backgroundColor: '#FAD7D7',
        color: '#fff',
        children: [
          {
            headerName: 'Dollar [ Only Number and Dot ]',
            field: 'Dollar',
            type: 'nglTextType',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({
                gridParam,
                cellType: CELL_TYPE_TEXT,
                adornment: {
                  position: 'start',
                  content: '$',
                },
                regex: REGEX_NUMBER_MINUS_DECIMAL,
              }),
            cellEditorSelector: gridParam =>
              cellEditorGenerator({
                gridParam,
                cellType: CELL_TYPE_TEXT,
                adornment: {
                  position: 'start',
                  content: '$',
                },
                regex: REGEX_NUMBER_MINUS_DECIMAL,
              }),
          },
          {
            headerName: 'Dollar [ Only Number and Dot ]',
            field: 'Percent',
            columnGroupShow: 'open',
            type: 'nglTextType',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({
                gridParam,
                cellType: CELL_TYPE_TEXT,
                adornment: {
                  position: 'end',
                  content: '%',
                },
                regex: REGEX_NUMBER_MINUS_DECIMAL,
              }),
            cellEditorSelector: gridParam =>
              cellEditorGenerator({
                gridParam,
                cellType: CELL_TYPE_TEXT,
                adornment: {
                  position: 'end',
                  content: '%',
                },
                regex: REGEX_NUMBER_MINUS_DECIMAL,
              }),
          },
          {
            headerName: 'David',
            field: 'david',
            columnGroupShow: 'open',
            type: 'nglTextType',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({
                gridParam,
                cellType: CELL_TYPE_TEXT,
                adornment: {
                  position: 'start',
                  content: 'David is ',
                },
              }),
            cellEditorSelector: gridParam =>
              cellEditorGenerator({
                gridParam,
                cellType: CELL_TYPE_TEXT,
                adornment: {
                  position: 'start',
                  content: 'James is ',
                },
              }),
          },
        ],
      },
      {
        headerName: 'Cell Renderer Component',
        backgroundColor: '#FAD7D7',
        children: [
          {
            headerName: 'Basic Type',
            field: 'company',
            unSortIcon: true,
            cellRendererSelector: gridParam =>
              cellRendererGenerator({ gridParam, cellType: CELL_TYPE_TEXT }),
          },
          {
            headerName: 'Center Align',
            columnGroupShow: 'open',
            field: 'company',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({
                gridParam,
                cellType: CELL_TYPE_TEXT,
                customStyle: {
                  textAlign: 'center',
                },
              }),
          },
          {
            headerName: 'Right Align',
            columnGroupShow: 'open',
            field: 'company',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({
                gridParam,
                cellType: CELL_TYPE_TEXT,
                customStyle: {
                  textAlign: 'right',
                },
              }),
          },
          {
            headerName: 'Basic Type / Adornment Start',
            columnGroupShow: 'open',
            field: 'company',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({
                gridParam,
                cellType: CELL_TYPE_TEXT,
                adornment: {
                  position: 'start',
                  content: '$',
                },
              }),
          },
          {
            headerName: 'Center Align / Adornment Start',
            columnGroupShow: 'open',
            field: 'company',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({
                gridParam,
                cellType: CELL_TYPE_TEXT,
                customStyle: {
                  textAlign: 'center',
                },
                adornment: {
                  position: 'start',
                  content: '$',
                },
              }),
          },
          {
            headerName: 'Right Align / Adornment Start',
            columnGroupShow: 'open',
            field: 'company',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({
                gridParam,
                cellType: CELL_TYPE_TEXT,
                customStyle: {
                  textAlign: 'right',
                },
                adornment: {
                  position: 'start',
                  content: '$',
                },
              }),
          },
          {
            headerName: 'Basic Type / Adornment End',
            columnGroupShow: 'open',
            field: 'company',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({
                gridParam,
                cellType: CELL_TYPE_TEXT,
                adornment: {
                  position: 'end',
                  content: '%',
                },
              }),
          },
          {
            headerName: 'Center Align / Adornment End',
            columnGroupShow: 'open',
            field: 'company',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({
                gridParam,
                cellType: CELL_TYPE_TEXT,
                customStyle: {
                  textAlign: 'center',
                },
                adornment: {
                  position: 'end',
                  content: '%',
                },
              }),
          },
          {
            headerName: 'Right Align / Adornment End',
            columnGroupShow: 'open',
            field: 'company',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({
                gridParam,
                cellType: CELL_TYPE_TEXT,
                customStyle: {
                  textAlign: 'right',
                },
                adornment: {
                  position: 'end',
                  content: '%',
                },
              }),
          },
        ],
      },
      {
        headerName: 'Company Info',
        backgroundColor: '#F0A0A0',
        children: [
          {
            field: 'company',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({ gridParam, cellType: 'text' }),
            cellEditor: ModTextCell,
          },
          {
            field: 'category',
            columnGroupShow: 'open',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({ gridParam, cellType: 'text' }),
          },
          {
            field: 'type',
            columnGroupShow: 'open',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({ gridParam }),
          },
          {
            field: 'group_id',
            columnGroupShow: 'open',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({ gridParam }),
          },
        ],
      },
      {
        headerName: 'billto',
        field: 'bilto',
        cellRendererSelector: gridParam => cellRendererGenerator({ gridParam }),
      },
      {
        headerName: 'address_01',
        field: 'address_01',
        cellRendererSelector: gridParam => cellRendererGenerator({ gridParam }),
      },
      {
        headerName: 'Country Info',
        backgroundColor: '#CC4E4E',
        children: [
          {
            field: 'city',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({ gridParam }),
          },
          {
            field: 'country',
            columnGroupShow: 'open',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({ gridParam }),
          },
          {
            field: 'zip',
            columnGroupShow: 'open',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({ gridParam }),
          },
        ],
      },
      {
        headerName: 'Pair Auto Complete Test',
        backgroundColor: '#CC4E4E',
        children: [
          {
            field: 'test1',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({
                gridParam,
                cellType: CELL_TYPE_AUTO_COMPLETE,
                option: [
                  {
                    label: 'James handsome',
                    value: 'James handsome',
                  },
                  {
                    label: 'David handsome',
                    value: 'David handsome',
                  },
                  {
                    label: 'Hwi handsome',
                    value: 'Hwi handsome',
                  },
                ],
                onChangeHandler: modOnChangeHandler,
                pair: 'test2',
              }),

            cellEditorSelector: gridParam =>
              cellEditorGenerator({
                gridParam,
                cellType: CELL_TYPE_AUTO_COMPLETE,
                option: [
                  {
                    label: 'James handsome',
                    value: 'James handsome',
                  },
                  {
                    label: 'David handsome',
                    value: 'David handsome',
                  },
                  {
                    label: 'Hwi handsome',
                    value: 'Hwi handsome',
                  },
                ],
                onChangeHandler: modOnChangeHandler,
                changeInputValue: (newValue, setInputValue) => {
                  setInputValue(newValue);
                },
                pair: 'test2',
              }),
          },
          {
            field: 'test2',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({
                gridParam,
                cellType: 'cell_test',
                option: [
                  {
                    label: 'James handsome',
                    value: 'James handsome',
                  },
                  {
                    label: 'David handsome',
                    value: 'David handsome',
                  },
                  {
                    label: 'Hwi handsome',
                    value: 'Hwi handsome',
                  },
                ],
                onChangeHandler: modOnChangeHandler,
                pair: 'test1',
              }),

            cellEditorSelector: gridParam =>
              cellEditorGenerator({
                gridParam,
                cellType: 'cell_test',
                option: [
                  {
                    label: 'James handsome',
                    value: 'James handsome',
                  },
                  {
                    label: 'David handsome',
                    value: 'David handsome',
                  },
                  {
                    label: 'Hwi handsome',
                    value: 'Hwi handsome',
                  },
                ],
                onChangeHandler: modOnChangeHandler,
                changeInputValue: (newValue, setInputValue) => {
                  setInputValue(newValue);
                },
                pair: 'test1',
              }),
          },
        ],
      },
      {
        headerName: 'Button Test',
        backgroundColor: '#800000',
        children: [
          {
            field: 'completeBtn',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({
                gridParam,
                cellType: CELL_TYPE_BUTTON,
                content: 'Complete',
                category: 'complete',
                onClickHandler: ({ ...props }) => {
                  console.log('This is complete handler ... : ', props);
                },
              }),
          },
          {
            field: 'exportBtn',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({
                gridParam,
                cellType: CELL_TYPE_BUTTON,
                content: 'Export',
                icon: PATH_BUTTON_GRID_EXPORT_UNSELECTED_ICON,
                onClickHandler: ({ ...props }) => {
                  console.log('This is export handler ... : ', props);
                },
              }),
          },
        ],
      },
      {
        headerName: 'Pair Disabled Test',
        backgroundColor: '#800000',
        children: [
          {
            field: 'disabled01Text',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({
                gridParam,
                cellType: CELL_TYPE_TEXT,
                pair: 'disabled02Text',
                onChangeHandler: ({ ...props }) => {
                  const getPairCellInstance =
                    props?.api?.getCellRendererInstances({
                      rowNodes: [props?.node],
                      columns: [props?.pair],
                    })?.[0];

                  if (props?.getChangedValue && props?.getChangedValue !== '') {
                    getPairCellInstance?.setDisabled(true);
                  } else {
                    getPairCellInstance?.setDisabled(false);
                  }
                },
              }),
            cellEditorSelector: gridParam =>
              cellEditorGenerator({
                gridParam,
                cellType: CELL_TYPE_TEXT,
                pair: 'disabled02Text',
                onChangeHandler: ({ ...props }) => {
                  const getPairCellInstance =
                    props?.api?.getCellEditorInstances({
                      rowNodes: [props?.node],
                      columns: [props?.pair],
                    })?.[0];

                  if (props?.getChangedValue && props?.getChangedValue !== '') {
                    getPairCellInstance?.setDisabled(true);
                  } else {
                    getPairCellInstance?.setDisabled(false);
                  }
                },
              }),
          },
          {
            field: 'disabled02Text',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({
                gridParam,
                cellType: CELL_TYPE_TEXT,
                pair: 'disabled01Text',
                onChangeHandler: ({ ...props }) => {
                  const getPairCellInstance =
                    props?.api?.getCellRendererInstances({
                      rowNodes: [props?.node],
                      columns: [props?.pair],
                    })?.[0];

                  if (props?.getChangedValue && props?.getChangedValue !== '') {
                    getPairCellInstance?.setDisabled(true);
                  } else {
                    getPairCellInstance?.setDisabled(false);
                  }
                },
              }),
            cellEditorSelector: gridParam =>
              cellEditorGenerator({
                gridParam,
                cellType: CELL_TYPE_TEXT,
                pair: 'disabled01Text',
                onChangeHandler: ({ ...props }) => {
                  const getPairCellInstance =
                    props?.api?.getCellEditorInstances({
                      rowNodes: [props?.node],
                      columns: [props?.pair],
                    })?.[0];

                  if (props?.getChangedValue && props?.getChangedValue !== '') {
                    getPairCellInstance?.setDisabled(true);
                  } else {
                    getPairCellInstance?.setDisabled(false);
                  }
                },
              }),
          },
          /** Text  */
          {
            field: 'disabled01Hmm',
            columnGroupShow: 'open',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({
                gridParam,
                cellType: CELL_TYPE_TEXT,
                pair: 'disabled02Hmm',
                onChangeHandler: ({ ...props }) => {
                  const getPairCellInstance =
                    props?.api?.getCellRendererInstances({
                      rowNodes: [props?.node],
                      columns: [props?.pair],
                    })?.[0];

                  if (props?.getChangedValue && props?.getChangedValue !== '') {
                    getPairCellInstance?.setDisabled(true);
                  } else {
                    getPairCellInstance?.setDisabled(false);
                  }
                },
              }),
            cellEditorSelector: gridParam =>
              cellEditorGenerator({
                gridParam,
                cellType: CELL_TYPE_TEXT,
                pair: 'disabled02Hmm',

                onChangeHandler: ({ ...props }) => {
                  const getPairCellInstance =
                    props?.api?.getCellEditorInstances({
                      rowNodes: [props?.node],
                      columns: [props?.pair],
                    })?.[0];

                  if (props?.getChangedValue && props?.getChangedValue !== '') {
                    getPairCellInstance?.setDisabled(true);
                  } else {
                    getPairCellInstance?.setDisabled(false);
                  }
                },
              }),
          },
          /** Select */
          {
            field: 'disabled01Select',
            columnGroupShow: 'open',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({
                gridParam,
                cellType: CELL_TYPE_SELECT,
                option: [
                  { label: 'Test1', value: 'Test1' },
                  { label: 'Test2', value: 'Test2' },
                ],
                pair: 'disabled02Select',
                onChangeHandler: ({ ...props }) => {
                  const getPairCellInstance =
                    props?.api?.getCellRendererInstances({
                      rowNodes: [props?.node],
                      columns: [props?.pair],
                    })?.[0];

                  if (props?.getChangedValue && props?.getChangedValue !== '') {
                    getPairCellInstance?.setDisabled(true);
                  } else {
                    getPairCellInstance?.setDisabled(false);
                  }
                },
              }),
            cellEditorSelector: gridParam =>
              cellEditorGenerator({
                gridParam,
                cellType: CELL_TYPE_SELECT,
                option: [
                  { label: 'Test1', value: 'Test1' },
                  { label: 'Test2', value: 'Test2' },
                ],
                pair: 'disabled02Select',
                onChangeHandler: ({ ...props }) => {
                  const getPairCellInstance =
                    props?.api?.getCellEditorInstances({
                      rowNodes: [props?.node],
                      columns: [props?.pair],
                    })?.[0];

                  if (props?.getChangedValue && props?.getChangedValue !== '') {
                    getPairCellInstance?.setDisabled(true);
                  } else {
                    getPairCellInstance?.setDisabled(false);
                  }
                },
              }),
          },
          {
            field: 'disabled02Select',
            columnGroupShow: 'open',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({
                gridParam,
                cellType: CELL_TYPE_SELECT,
                option: [
                  { label: 'Test1', value: 'Test1' },
                  { label: 'Test2', value: 'Test2' },
                ],
                pair: 'disabled01Select',
                onChangeHandler: ({ ...props }) => {
                  const getPairCellInstance =
                    props?.api?.getCellRendererInstances({
                      rowNodes: [props?.node],
                      columns: [props?.pair],
                    })?.[0];

                  if (props?.getChangedValue && props?.getChangedValue !== '') {
                    getPairCellInstance?.setDisabled(true);
                  } else {
                    getPairCellInstance?.setDisabled(false);
                  }
                },
              }),
            cellEditorSelector: gridParam =>
              cellEditorGenerator({
                gridParam,
                cellType: CELL_TYPE_SELECT,
                option: [
                  { label: 'Test1', value: 'Test1' },
                  { label: 'Test2', value: 'Test2' },
                ],
                pair: 'disabled01Select',
                onChangeHandler: ({ ...props }) => {
                  const getPairCellInstance =
                    props?.api?.getCellEditorInstances({
                      rowNodes: [props?.node],
                      columns: [props?.pair],
                    })?.[0];

                  if (props?.getChangedValue && props?.getChangedValue !== '') {
                    getPairCellInstance?.setDisabled(true);
                  } else {
                    getPairCellInstance?.setDisabled(false);
                  }
                },
              }),
          },
          /** Auto-Complete */
          {
            field: 'disabled01AutoComplete',
            columnGroupShow: 'open',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({
                gridParam,
                cellType: CELL_TYPE_AUTO_COMPLETE,
                option: [
                  { label: 'Test1', value: 'Test1' },
                  { label: 'Test2', value: 'Test2' },
                ],
                pair: 'disabled02AutoComplete',
                onChangeHandler: ({ ...props }) => {
                  const getPairCellInstance =
                    props?.api?.getCellRendererInstances({
                      rowNodes: [props?.node],
                      columns: [props?.pair],
                    })?.[0];

                  if (props?.getChangedValue && props?.getChangedValue !== '') {
                    getPairCellInstance?.setDisabled(true);
                  } else {
                    getPairCellInstance?.setDisabled(false);
                  }
                },
              }),
            cellEditorSelector: gridParam =>
              cellEditorGenerator({
                gridParam,
                cellType: CELL_TYPE_AUTO_COMPLETE,
                option: [
                  { label: 'Test1', value: 'Test1' },
                  { label: 'Test2', value: 'Test2' },
                ],
                pair: 'disabled02AutoComplete',
                onChangeHandler: ({ ...props }) => {
                  const getPairCellInstance =
                    props?.api?.getCellEditorInstances({
                      rowNodes: [props?.node],
                      columns: [props?.pair],
                    })?.[0];

                  if (props?.getChangedValue && props?.getChangedValue !== '') {
                    getPairCellInstance?.setDisabled(true);
                  } else {
                    getPairCellInstance?.setDisabled(false);
                  }
                },
              }),
          },
          {
            field: 'disabled02AutoComplete',
            columnGroupShow: 'open',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({
                gridParam,
                cellType: CELL_TYPE_AUTO_COMPLETE,
                option: [
                  { label: 'Test1', value: 'Test1' },
                  { label: 'Test2', value: 'Test2' },
                ],
                pair: 'disabled01AutoComplete',
                onChangeHandler: ({ ...props }) => {
                  const getPairCellInstance =
                    props?.api?.getCellRendererInstances({
                      rowNodes: [props?.node],
                      columns: [props?.pair],
                    })?.[0];

                  if (props?.getChangedValue && props?.getChangedValue !== '') {
                    getPairCellInstance?.setDisabled(true);
                  } else {
                    getPairCellInstance?.setDisabled(false);
                  }
                },
              }),
            cellEditorSelector: gridParam =>
              cellEditorGenerator({
                gridParam,
                cellType: CELL_TYPE_AUTO_COMPLETE,
                option: [
                  { label: 'Test1', value: 'Test1' },
                  { label: 'Test2', value: 'Test2' },
                ],
                pair: 'disabled01AutoComplete',
                onChangeHandler: ({ ...props }) => {
                  const getPairCellInstance =
                    props?.api?.getCellEditorInstances({
                      rowNodes: [props?.node],
                      columns: [props?.pair],
                    })?.[0];

                  if (props?.getChangedValue && props?.getChangedValue !== '') {
                    getPairCellInstance?.setDisabled(true);
                  } else {
                    getPairCellInstance?.setDisabled(false);
                  }
                },
              }),
          },
          /** Date */
          {
            field: 'disabled01Date',
            columnGroupShow: 'open',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({
                gridParam,
                cellType: CELL_TYPE_DATE,
                pair: 'disabled02Date',
                onChangeHandler: ({ ...props }) => {
                  const getPairCellInstance =
                    props?.api?.getCellRendererInstances({
                      rowNodes: [props?.node],
                      columns: [props?.pair],
                    })?.[0];

                  if (props?.getChangedValue && props?.getChangedValue !== '') {
                    getPairCellInstance?.setDisabled(true);
                  } else {
                    getPairCellInstance?.setDisabled(false);
                  }
                },
              }),
            cellEditorSelector: gridParam =>
              cellEditorGenerator({
                gridParam,
                cellType: CELL_TYPE_DATE,
                pair: 'disabled02Date',
                onChangeHandler: ({ ...props }) => {
                  const getPairCellInstance =
                    props?.api?.getCellEditorInstances({
                      rowNodes: [props?.node],
                      columns: [props?.pair],
                    })?.[0];

                  if (props?.getChangedValue && props?.getChangedValue !== '') {
                    getPairCellInstance?.setDisabled(true);
                  } else {
                    getPairCellInstance?.setDisabled(false);
                  }
                },
              }),
          },
          {
            field: 'disabled02Date',
            columnGroupShow: 'open',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({
                gridParam,
                cellType: CELL_TYPE_DATE,
                pair: 'disabled01Date',
                onChangeHandler: ({ ...props }) => {
                  const getPairCellInstance =
                    props?.api?.getCellRendererInstances({
                      rowNodes: [props?.node],
                      columns: [props?.pair],
                    })?.[0];

                  if (props?.getChangedValue && props?.getChangedValue !== '') {
                    getPairCellInstance?.setDisabled(true);
                  } else {
                    getPairCellInstance?.setDisabled(false);
                  }
                },
              }),
            cellEditorSelector: gridParam =>
              cellEditorGenerator({
                gridParam,
                cellType: CELL_TYPE_DATE,
                pair: 'disabled01Date',
                onChangeHandler: ({ ...props }) => {
                  const getPairCellInstance =
                    props?.api?.getCellEditorInstances({
                      rowNodes: [props?.node],
                      columns: [props?.pair],
                    })?.[0];

                  if (props?.getChangedValue && props?.getChangedValue !== '') {
                    getPairCellInstance?.setDisabled(true);
                  } else {
                    getPairCellInstance?.setDisabled(false);
                  }
                },
              }),
          },
          /** Time */
          {
            field: 'disabled01Time',
            columnGroupShow: 'open',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({
                gridParam,
                cellType: CELL_TYPE_TIME,
                pair: 'disabled02Time',
                onChangeHandler: ({ ...props }) => {
                  const getPairCellInstance =
                    props?.api?.getCellRendererInstances({
                      rowNodes: [props?.node],
                      columns: [props?.pair],
                    })?.[0];

                  if (
                    props?.getChangedValue &&
                    dayjs(props?.getChangedValue).isValid()
                  ) {
                    getPairCellInstance?.setDisabled(true);
                  } else {
                    getPairCellInstance?.setDisabled(false);
                  }
                },
              }),
            cellEditorSelector: gridParam =>
              cellEditorGenerator({
                gridParam,
                cellType: CELL_TYPE_TIME,
                pair: 'disabled02Time',
                onChangeHandler: ({ ...props }) => {
                  const getPairCellInstance =
                    props?.api?.getCellEditorInstances({
                      rowNodes: [props?.node],
                      columns: [props?.pair],
                    })?.[0];

                  if (
                    props?.getChangedValue &&
                    dayjs(props?.getChangedValue).isValid()
                  ) {
                    getPairCellInstance?.setDisabled(true);
                  } else {
                    getPairCellInstance?.setDisabled(false);
                  }
                },
              }),
          },
          {
            field: 'disabled02Time',
            columnGroupShow: 'open',
            cellRendererSelector: gridParam =>
              cellRendererGenerator({
                gridParam,
                cellType: CELL_TYPE_TIME,
                pair: 'disabled01Time',
                onChangeHandler: ({ ...props }) => {
                  const getPairCellInstance =
                    props?.api?.getCellRendererInstances({
                      rowNodes: [props?.node],
                      columns: [props?.pair],
                    })?.[0];
                  console.log(
                    'Props?.getChanged vAalue : ',
                    props?.getChangedValue,
                  );

                  if (
                    props?.getChangedValue &&
                    dayjs(props?.getChangedValue).isValid()
                  ) {
                    getPairCellInstance?.setDisabled(true);
                  } else {
                    getPairCellInstance?.setDisabled(false);
                  }
                },
              }),
            cellEditorSelector: gridParam =>
              cellEditorGenerator({
                gridParam,
                cellType: CELL_TYPE_TIME,
                pair: 'disabled01Time',
                onChangeHandler: ({ ...props }) => {
                  const getPairCellInstance =
                    props?.api?.getCellEditorInstances({
                      rowNodes: [props?.node],
                      columns: [props?.pair],
                    })?.[0];

                  if (
                    props?.getChangedValue &&
                    dayjs(props?.getChangedValue).isValid()
                  ) {
                    getPairCellInstance?.setDisabled(true);
                  } else {
                    getPairCellInstance?.setDisabled(false);
                  }
                },
              }),
          },
        ],
      },
      {
        field: 'What is the matter',
        columnGroupShow: 'open',
        cellRendererSelector: gridParam =>
          cellRendererGenerator({
            gridParam,
            cellType: CELL_TYPE_SELECT,
            option: poolList,
            onChangeHandler: ({ ...props }) => {},
          }),
        cellRendererParams: { option: poolList },
        cellEditorSelector: gridParam =>
          cellEditorGenerator({
            gridParam,
            cellType: CELL_TYPE_SELECT,
            option: poolList,
            onChangeHandler: ({ ...props }) => {},
          }),
      },
      {
        headerName: 'Toggle',
        field: 'toggle',
        backgroundColor: 'yellow',
        color: 'red',
        cellRendererSelector: gridParam =>
          cellRendererGenerator({
            gridParam,
            cellType: CELL_TYPE_SWITCH,
            onChangeHandler: ({ ...props }) => {
              const { e, setSelected } = props;
              setSelected(!!e?.target?.checked);
            },
          }),
        cellRendererParams: { option: poolList },
        cellEditorSelector: gridParam =>
          cellEditorGenerator({
            gridParam,
            cellType: CELL_TYPE_SWITCH,
            onChangeHandler: ({ ...props }) => {
              props?.setValue(!!props?.e?.target?.checked);
            },
          }),
      },
    ]);
  }, [poolList]);

  return columnDefs;
};

export default columnDefsGenerator;
