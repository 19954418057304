const DISPATCH_VAN_FILTER_DATE = [
  {
    value: 'create-',
    label: 'WO CREATE DATE',
  },
  {
    value: 'po-schedule-',
    label: 'P/O S DATE',
  },
  {
    value: 'po-actual-out-',
    label: 'P/O A OUT DATE',
  },
  {
    value: 'dilv-schedule-',
    label: 'DILV S DATE',
  },
  {
    value: 'dilv-actual-out-',
    label: 'DILV A OUT DATE',
  },
  {
    value: 'rtn-schedule-',
    label: 'RTN S DATE',
  },
  {
    value: 'rtn-actual-out-',
    label: 'RTN A OUT DATE',
  },
];

const DISPATCH_VAN_FILTER_CATEGORY = [
  {
    value: '',
    label: 'Select',
  },
  {
    value: 'trailer-no',
    label: 'TRAILER #',
  },
  {
    value: 'wo-no',
    label: 'WO #',
  },
  {
    value: 'ref-no',
    label: 'REF #',
  },
  {
    value: 'purchase-no',
    label: 'PO #',
  },
  {
    value: 'mbl-no',
    label: 'MBL #',
  },
  {
    value: 'billto',
    label: 'BILL TO',
  },
  {
    value: 'po-loc',
    label: 'P/O LOC',
  },
  {
    value: 'dilv-loc',
    label: 'DILV LOC',
  },
  {
    value: 'rtn-loc',
    label: 'RTN LOC',
  },
];

export { DISPATCH_VAN_FILTER_CATEGORY, DISPATCH_VAN_FILTER_DATE };
