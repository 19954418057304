import handleQueryError from '../../../utils/Common/Handler/handleQueryError';
import cusReportDefaultAPI from '../api';

const deleteSetting = async ({ deleteData }) => {
  try {
    const response = await cusReportDefaultAPI.post(
      `setting/delete`,
      deleteData,
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default deleteSetting;
