import React from 'react';
import CommonRadioInput from '../../../components/Inputs/CommonRadioInput/CommonRadioInput';

const getRadioInputComponent = ({ ...props }) => {
  const commonProperty = {
    disabled: props?.disabledArr,
    required: props?.requiredArr,
    labelStyle: props?.labelStyle,
    borderVisible: props?.borderVisible,
    onChangeHandler: ({ e, onChange, ...handlerProps }) => {
      onChange(e?.target?.value);
    },
  };

  const inputProperty = [
    {
      key: 0,
      label: 'RadioInput Label 100%',
      inputName: 'radioInput00',
      width: 'w-full',
      ...commonProperty,
    },
  ];

  return inputProperty?.map(element => (
    <CommonRadioInput
      key={element?.key}
      {...element}
      labelStyle={props?.labelStyle && 'row'}
    />
  ));
};
export default getRadioInputComponent;
