import React from 'react';
import { LIVE_SHARE_LOGO_PATH } from '../../../../constants/LiveShare/path';

const LiveShareInfoLogo = () => {
  return (
    <div className="w-full">
      <img
        src={LIVE_SHARE_LOGO_PATH}
        alt="Live-Share logo"
        data-testid="liveShareNGLLogo"
      />
    </div>
  );
};

export default LiveShareInfoLogo;
