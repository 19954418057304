import handleCheckboxChange from '../../../TmsReport/Common/handleCheckboxChange';

const getCheckboxOptions = ({
  setScheduleTypeQuery,
  setDivisionQuery,
  setCategoryQuery,
  setYardQuery,
  setStatusQuery,
  allQueries,
  yardList,
  divList,
  filterStorage,
}) => {
  const defaultDiv = localStorage.getItem('defaultDiv');
  const scheduleTypeOptionList = [
    {
      label: 'LIVE',
      value: 'LIVE',
      defaultValue: filterStorage?.filter?.['sche-type']?.LIVE,
      onChange: ({ ...props }) =>
        handleCheckboxChange({
          ...props,
          setQuery: setScheduleTypeQuery,
          item: 'sche-type',
          filterStorage,
        }),
    },
    {
      label: 'DROP',
      value: 'DROP',
      defaultValue: filterStorage?.filter?.['sche-type']?.DROP,
      onChange: ({ ...props }) =>
        handleCheckboxChange({
          ...props,
          setQuery: setScheduleTypeQuery,
          item: 'sche-type',
          filterStorage,
        }),
    },
  ];

  const divisionOptionList = [
    ...divList?.map(div => {
      return {
        label: div,
        value: div,
        defaultValue:
          typeof filterStorage?.filter?.div?.[div] === 'boolean'
            ? filterStorage?.filter?.div?.[div]
            : div === defaultDiv,
        onChange: ({ ...props }) =>
          handleCheckboxChange({
            ...props,
            setQuery: setDivisionQuery,
            item: 'div',
            filterStorage,
          }),
      };
    }),
  ];

  const categoryOptionList = [
    {
      label: 'IMPORT',
      value: 'WC0001',
      defaultValue: filterStorage?.filter?.category?.WC0001,
      onChange: ({ ...props }) =>
        handleCheckboxChange({
          ...props,
          setQuery: setCategoryQuery,
          item: 'category',
          filterStorage,
        }),
    },
    {
      label: 'EXPORT',
      value: 'WC0003',
      defaultValue: filterStorage?.filter?.category?.WC0003,
      onChange: ({ ...props }) =>
        handleCheckboxChange({
          ...props,
          setQuery: setCategoryQuery,
          item: 'category',
          filterStorage,
        }),
    },
  ];

  const yardOptionList = [
    ...yardList?.map(yard => ({
      label: yard,
      value: yard,
      defaultValue: allQueries?.['ngl-yard']?.[yard] || false,
      onChange: ({ ...props }) =>
        handleCheckboxChange({
          ...props,
          setQuery: setYardQuery,
          item: 'ngl-yard',
          filterStorage,
        }),
    })),
  ];

  const statusOptionList = [
    {
      label: 'IN YARD',
      value: 'White',
      defaultValue: filterStorage?.filter?.['status-color']?.White,
      onChange: ({ ...props }) =>
        handleCheckboxChange({
          ...props,
          setQuery: setStatusQuery,
          item: 'status-color',
          filterStorage,
        }),
    },
    {
      label: 'TO YARD',
      value: 'Yellow',
      defaultValue: filterStorage?.filter?.['status-color']?.Yellow,
      onChange: ({ ...props }) =>
        handleCheckboxChange({
          ...props,
          setQuery: setStatusQuery,
          item: 'status-color',
          filterStorage,
        }),
    },
    {
      label: 'IN RTN',
      value: 'Blue',
      defaultValue: filterStorage?.filter?.['status-color']?.Blue,
      onChange: ({ ...props }) =>
        handleCheckboxChange({
          ...props,
          setQuery: setStatusQuery,
          item: 'status-color',
          filterStorage,
        }),
    },
    {
      label: 'TO RTN',
      value: 'Green',
      defaultValue: filterStorage?.filter?.['status-color']?.Green,
      onChange: ({ ...props }) =>
        handleCheckboxChange({
          ...props,
          setQuery: setStatusQuery,
          item: 'status-color',
          filterStorage,
        }),
    },
  ];

  return {
    scheduleTypeOptionList,
    divisionOptionList,
    categoryOptionList,
    yardOptionList,
    statusOptionList,
  };
};

export default getCheckboxOptions;
