import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CommonMessageModal from '../../CommonMessageModal/CommonMessageModal';
import Content from './Content';
import ButtonContent from './ButtonContent';

const AdvancedSearch = ({ ...props }) => {
  /** Get modal opener */
  const { open, setOpen, setAdvancedFilter, columnDefs } = props;

  const advancedSearchController = useForm();

  return (
    <FormProvider {...advancedSearchController}>
      <form>
        <CommonMessageModal
          open={open}
          setOpen={setOpen}
          header="Advanced Search"
          content={<Content columnDefs={columnDefs} />}
          buttonContent={
            <ButtonContent
              open={open}
              setOpen={setOpen}
              setAdvancedFilter={setAdvancedFilter}
            />
          }
        />
      </form>
    </FormProvider>
  );
};

export default AdvancedSearch;
