import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useGetBasicInfo from '../../../hooks/BCDetail/DetailInfo/BasicInfo/useGetBasicInfo';
import ScheduleTable from '../../../components/BCDetail/DetailInfo/Schedule/ScheduleTable';
import BasicInfo from '../../../components/BCDetail/DetailInfo/BasicInfo';
import WoMemoArea from '../../../components/BCDetail/Common/WoMemoArea';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import BottomBar from '../../../components/BCDetail/DetailInfo/BottomBar';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import checkPermission from '../../../utils/Common/Router/Handler/checkPermission';
import {
  PERM_BC_DETAIL_BARE_EDIT,
  PERM_BC_DETAIL_BROK_EDIT,
  PERM_BC_DETAIL_EXP_EDIT,
  PERM_BC_DETAIL_IMP_EDIT,
  PERM_BC_DETAIL_VAN_EDIT,
} from '../../../constants/Common/permission';
import usePatchDetailInfo from '../../../hooks/BCDetail/DetailInfo/usePatchDetailInfo';
import useCheckFormValidity from '../../../hooks/Common/Input/useCheckFormValidity';

const DetailInfo = () => {
  const pageTitle = location?.pathname?.split('/')[3];

  /** Message modal controller */
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isModalSuccess, setModalSuccess] = useState(true);
  const [onOkClickHandler, setOnOkClickHandler] = useState(null);

  const useGetBasicInfoData = useGetBasicInfo({
    setModalOpen,
    setModalMessage,
    setModalSuccess,
    setOnOkClickHandler,
  });
  const basicInfoFormController = useForm();
  const { isFormInvalid } = useCheckFormValidity({
    formStates: [basicInfoFormController?.formState],
  });

  const scheduleFormController = useForm({
    shouldUnregister: true,
    defaultValues: {},
  });

  const updateDetailInfo = usePatchDetailInfo({
    setModalOpen,
    setModalMessage,
    setModalSuccess,
  });

  const isEditable = () => {
    if (pageTitle === 'import')
      return checkPermission({ permission: [PERM_BC_DETAIL_IMP_EDIT] });
    if (pageTitle === 'export')
      return checkPermission({ permission: [PERM_BC_DETAIL_EXP_EDIT] });
    if (pageTitle === 'van')
      return checkPermission({ permission: [PERM_BC_DETAIL_VAN_EDIT] });
    if (pageTitle === 'brokerage')
      return checkPermission({ permission: [PERM_BC_DETAIL_BROK_EDIT] });
    if (pageTitle === 'bare-chassis')
      return checkPermission({ permission: [PERM_BC_DETAIL_BARE_EDIT] });
    return false;
  };

  return (
    <>
      {useGetBasicInfoData?.basicInfoLoading && (
        <CommonLoading open={useGetBasicInfoData?.basicInfoLoading} />
      )}
      {!useGetBasicInfoData?.basicInfoLoading &&
        !useGetBasicInfoData?.isError && (
          <div className="w-[1920px] h-auto bg-white">
            <div className="px-[71px] pt-[50px]">
              <FormProvider {...basicInfoFormController}>
                <form>
                  <BasicInfo
                    basicInfoData={useGetBasicInfoData?.basicInfoData}
                    isEditable={isEditable()}
                    scheduleFormController={scheduleFormController}
                  />
                </form>
              </FormProvider>
              <hr className="bg-black mt-[40px]" />
              <FormProvider {...scheduleFormController}>
                <form>
                  <ScheduleTable
                    detailInfoData={useGetBasicInfoData?.basicInfoData}
                    isEditable={isEditable()}
                    infoRefetch={useGetBasicInfoData?.refetch}
                    basicInfoFormController={basicInfoFormController}
                    scheduleFormController={scheduleFormController}
                    updateDetailInfo={updateDetailInfo}
                  />
                </form>
              </FormProvider>
              <hr className="bg-black mt-[40px] mb-[71px]" />
              <WoMemoArea
                isEditable={isEditable()}
                setMessageModalOpen={setModalOpen}
                setMessageContent={setModalMessage}
                setIsSuccess={setModalSuccess}
              />
            </div>
            <div className="sticky bottom-0 z-[1000] bg-[#F2F4F8] w-[1920px] h-[41px] py-[9px]">
              <BottomBar
                detailInfoData={useGetBasicInfoData?.basicInfoData}
                infoRefetch={useGetBasicInfoData?.refetch}
                basicInfoFormController={basicInfoFormController}
                scheduleFormController={scheduleFormController}
                isEditable={isEditable()}
                updateDetailInfo={updateDetailInfo}
                isFormInvalid={isFormInvalid}
              />
            </div>
          </div>
        )}
      {modalOpen && (
        <CommonErrorModal
          open={modalOpen}
          setIsOpen={setModalOpen}
          message={modalMessage}
          isSuccess={isModalSuccess}
          onOkClickHandler={onOkClickHandler}
        />
      )}
    </>
  );
};

export default DetailInfo;
