import React, { useEffect, useState } from 'react';
import SettingTableContentRow from '../SettingTableContentRow';
import SettingTableInputRow from '../SettingTableInputRow';
import usePostUserID from '../../../hooks/CustomerReport/CustomerReportSetting/Post/usePostUserID';
import useDeleteSettingUserID from '../../../hooks/CustomerReport/CustomerReportSetting/Delete/useDeleteSettingUserID';

const SettingDetailTable = ({
  setIsOpenErrorModal,
  setMessage,
  setIsOpenEmailConfirmModal,
  setConfirmEmail,
  setIsSuccess,
  confirmDeleteOptions,
  setIsSendEmailModal,
  setSendedEmailInfo,
  setConfirmEmailListSeq,
  ...props
}) => {
  const { seq: listSeq } = props?.data;

  const [emailList, setEmailList] = useState([]);
  useEffect(() => {
    const tempEmailList = Object?.values?.(props?.data?.user_id);
    setEmailList(tempEmailList);
  }, [props?.data?.user_id]);

  /** Post User Email(ID) */
  const { mutate: postUserIDMutate } = usePostUserID({
    setIsSendEmailModal,
    setIsOpenErrorModal,
    setMessage,
    setIsSuccess,
    setEmailList,
    setSendedEmailInfo,
    listSeq,
  });

  /** Delete User Email(ID) */
  const { mutate: deleteSettingUserIDMutate } = useDeleteSettingUserID({
    setIsOpenErrorModal,
    setMessage,
    setIsSuccess,
    setEmailList,
  });

  return (
    <div className="w-full bg-[#DDE2EC] pl-[65px] pt-[8px] pb-[11px]">
      <SettingTableInputRow
        listSeq={listSeq}
        setIsOpenErrorModal={setIsOpenErrorModal}
        setMessage={setMessage}
        setIsSuccess={setIsSuccess}
        postUserIDMutate={postUserIDMutate}
        setEmailList={setEmailList}
      />
      {emailList.map((email, index) => (
        <SettingTableContentRow
          key={String(index + 1)}
          email={email}
          listSeq={listSeq}
          deleteSettingUserIDMutate={deleteSettingUserIDMutate}
          setIsOpenEmailConfirmModal={setIsOpenEmailConfirmModal}
          setConfirmEmail={setConfirmEmail}
          confirmDeleteOptions={confirmDeleteOptions}
          setConfirmEmailListSeq={setConfirmEmailListSeq}
        />
      ))}
    </div>
  );
};

export default SettingDetailTable;
