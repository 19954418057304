export const onChangeFile = ({ e, setFileList, setValue }) => {
  let getDraggedFile = null;
  if (e?.type === 'drop') {
    getDraggedFile = e?.dataTransfer?.files?.[0];
  } else {
    getDraggedFile = e?.target?.files?.[0];
  }
  // Set DO value
  setValue?.('do_file_name', getDraggedFile?.name);
  setValue?.('do', getDraggedFile);
  // setFileList(getDraggedFile);
};

export const handleDragEnter = ({ e, setIsDragEnter }) => {
  e?.preventDefault();
  e?.stopPropagation();

  setIsDragEnter(true);
};

export const handleDragLeave = ({ e, setIsDragEnter }) => {
  e?.preventDefault();
  e?.stopPropagation();

  setIsDragEnter(false);
};

export const handleDragOver = ({ e, setIsDragEnter }) => {
  e?.preventDefault();
  e?.stopPropagation();

  setIsDragEnter(true);
};

export const handleDragDrop = ({
  e,
  setIsDragEnter,
  setFileList,
  setValue,
}) => {
  e?.preventDefault();
  e?.stopPropagation();

  onChangeFile({ e, setFileList, setValue });
  setIsDragEnter(false);
};

export const initDragEvents = ({
  ref,
  setIsDragEnter,
  setFileList,
  setValue,
}) => {
  if (ref?.current !== null) {
    ref.current.addEventListener('dragenter', e =>
      handleDragEnter({ e, setIsDragEnter }),
    );
    ref.current.addEventListener('dragleave', e =>
      handleDragLeave({ e, setIsDragEnter }),
    );
    ref.current.addEventListener('dragover', e =>
      handleDragOver({ e, setIsDragEnter }),
    );
    ref.current.addEventListener('drop', e =>
      handleDragDrop({ e, setIsDragEnter, setFileList, setValue }),
    );
  }
};

export const resetDragEvents = ({ ref }) => {
  if (ref?.current !== null) {
    ref.current.removeEventListener('dragenter', handleDragEnter);
    ref.current.removeEventListener('dragleave', handleDragLeave);
    ref.current.removeEventListener('dragover', handleDragLeave);
    ref.current.removeEventListener('drop', handleDragDrop);
  }
};
