export const IMPORT_UNAVAILABLE = 'WSM001';
export const IMPORT_AVAILABLE = 'WSM002';
export const IMPORT_DELIVERY = 'WSM003';
export const IMPORT_RETURN = 'WSM004';

export const EXPORT_UNAVAILABLE = 'WSM004';
export const EXPORT_AVAILABLE = 'WSX001';
export const EXPORT_DELIVERY = 'WSX002';
export const EXPORT_RETURN = 'WSX003';

export const VAN_AVAILABLE = 'WSV001';
export const VAN_DELIVERY = 'WSV002';
export const VAN_RETURN = 'WSV003';

export const BROKERAGE_AVAILABLE = 'WSB001';
export const BROKERAGE_DELIVERY = 'WSB002';
export const BROKERAGE_RETURN = 'WSB003';

export const CY_TO_CY = 'WSN001';
export const BARE_CHASSIS = 'WSN001';
