import dayjs from 'dayjs';
import FloatingFilterInput from '../../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterInput';
import { CELL_TYPE_DATETIME } from '../../../../constants/Common/DataGrid/cellType';
import {
  getDateFontColor,
  getDateFontWeight,
} from '../../../TmsReport/Common/dateStyleGenerator';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';
import datetimeComparator from '../../../Common/Datagrid/Comparator/datetimeComparator';

const getDatetimeDef = field => {
  return {
    cellClass: 'datetime',
    comparator: datetimeComparator,
    floatingFilterComponent: FloatingFilterInput,
    floatingFilterComponentParams: {
      placeholder: 'MM/DD/YY HH:mm',
      maxLength: 14,
      inputType: 'datetime',
    },
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        cellType: CELL_TYPE_DATETIME,
        customStyle: {
          color: getDateFontColor({ field, data: gridParam?.data }),
          fontWeight: getDateFontWeight({ field, data: gridParam?.data }),
        },
      }),
    valueSetter: params => {
      const dateValue = params.data?.[field];
      return dayjs(dateValue).isValid()
        ? dayjs(dateValue).format('MM/DD/YY HH:mm')
        : null;
    },
    valueGetter: params => {
      const dateValue = params.data?.[field];
      return dayjs(dateValue).isValid()
        ? dayjs(dateValue).format('MM/DD/YY HH:mm')
        : null;
    },
  };
};

export default getDatetimeDef;
