import emailFormatValidateHandler from '../../Common/Handler/emailFormatValidateHandler';
import updateButtonDisabled from '../../Common/Handler/updateButtonDisabled';

const handleUserIDButton = async ({
  email,
  listSeq,
  postUserIDMutate,
  handleSubmit,
  setIsOpenErrorModal,
  setMessage,
  setIsSuccess,
  isFormInvalid,
  buttonRef,
  reset,
}) => {
  if (isFormInvalid) updateButtonDisabled({ ref: buttonRef, disabled: false });

  await handleSubmit(async submitData => {
    const emailData = submitData?.email || email;

    const emailValidation = emailFormatValidateHandler({
      email: emailData,
    });

    /** Email Validation */
    if (!emailValidation) {
      setIsSuccess(false);
      setIsOpenErrorModal(true);
      setMessage('Please enter an email format');
      updateButtonDisabled({ ref: buttonRef, disabled: false });
      return;
    }

    const emailList = emailData?.split(',')?.map(item => item?.trim());

    /** Set Post Data */
    const postData = {
      email: emailList,
      list_seq: listSeq,
    };

    await postUserIDMutate({ postData, buttonRef, reset });
  })();
};

export default handleUserIDButton;
