import React, { useRef } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import CommonButton from '../../../CommonButton/CommonButton';
import CommonMessageModal from '../../../CommonMessageModal/CommonMessageModal';
import TextMessageContent from './TextMessageContent';
import StyledCommonErrorModalButton from '../../../../styles/Common/CommonErrorModal/StyledCommonErrorModalButton';
import handleTextSubmit from '../../../../utils/MobileAdmin/PODCheckList/Handler/handleTextSubmit';

const TextMessageModal = ({ open, setOpen, postMessageMutator, cellNo }) => {
  const sendRef = useRef(null);

  const textController = useForm();
  const { reset } = textController;

  return (
    <FormProvider {...textController}>
      <form
        onSubmit={textController.handleSubmit(submitData =>
          handleTextSubmit({
            submitData,
            postMessageMutator,
            buttonRef: sendRef,
          }),
        )}
      >
        <CommonMessageModal
          open={open}
          setOpen={setOpen}
          header="NOTICED BY TEXT MESSAGE"
          content={<TextMessageContent cellNo={cellNo} />}
          buttonContent={
            <StyledCommonErrorModalButton className="gap-x-[15px]">
              <CommonButton
                onClick={() => reset()}
                category="clear"
                type="button"
              >
                Reset
              </CommonButton>
              <CommonButton
                ref={sendRef}
                category="send"
                type="submit"
                data-testid="send-text"
              >
                Send
              </CommonButton>
            </StyledCommonErrorModalButton>
          }
        />
      </form>
    </FormProvider>
  );
};

export default TextMessageModal;
