import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';
import memoDefaultAPi from '../../../Memo/api';

/**
 * @description
 * API function that get mobile memo
 * @returns {object}
 * Response object from API
 */
const getMobileMemo = async ({ seq }) => {
  try {
    const response = await memoDefaultAPi.get(`job/${seq}`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getMobileMemo;
