import bcDetailDefaultApi from '../../../BCDetail/api';

/**
 * @description
 * Function that get the auth token
 * @returns
 */
const getAuthToken = async ({ woNo }) => {
  try {
    const response = await bcDetailDefaultApi.get(`${woNo}/tracking-token`);
    const data = response?.data;
    return {
      token: data?.token,
      expiresInSeconds: data?.expiration,
    };
  } catch (error) {
    throw new Error({ error });
  }
};

export default getAuthToken;
