import styled from 'styled-components';

const StyledWeeklyItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  box-shadow: 0px 0.5px 1px 0px #00000040;
  min-height: 127px;
  height: 127px;
  cursor: pointer;
`;

export default StyledWeeklyItemWrapper;
