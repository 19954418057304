import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import masterDriverDefaultApi from '../api';

const patchDriverData = async data => {
  try {
    const response = await masterDriverDefaultApi.patch(
      `${data?.driver}`,
      data,
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }), { cause: error });
  }
};

export default patchDriverData;
