import React from 'react';
import StyledLabsButtonBoxWrapper from '../../../styles/Labs/Button/StyledLabsButtonBoxWrapper';
import CommonButton from '../../CommonButton/CommonButton';

const LabsButtonBoxWrapper = ({ ...props }) => {
  return (
    <StyledLabsButtonBoxWrapper>
      <div className="p-[10px] bg-[#EEE] border-b-2 border-black flex justify-center items-center font-bold">
        {props?.title}
      </div>
      <div className="bg-white h-full flex justify-center items-center flex-col">
        <div className="mb-[20px]">
          <CommonButton
            {...props}
            state="unselected"
            size={props?.small ? 'small' : null}
          >
            {props?.contents}
          </CommonButton>
        </div>
        <div className="mb-[20px]">
          <CommonButton
            {...props}
            size={props?.small ? 'small' : null}
            disabled
          >
            {props?.contents}
          </CommonButton>
        </div>
      </div>
    </StyledLabsButtonBoxWrapper>
  );
};

export default LabsButtonBoxWrapper;
