import React from 'react';
import StyledScheduleAPModalTableContentRowWrapper from '../../../../../../../../../styles/BCDetail/DetailInfo/Schedule/StyledScheduleAPModalTableContentRowWrapper';
import StyledScheduleAPModalTableContentRowDataWrapper from '../../../../../../../../../styles/BCDetail/DetailInfo/Schedule/StyledScheduleAPModalTableContentRowDataWrapper';
import ScheduleAPModalTableContentRowCell from '../../../../ScheduleAPModal/ScheduleAPModalTableContentRowCell';

const DualMoveAPModalExistAPInfoRow = ({ ...props }) => {
  const { ap } = props;

  return (
    <StyledScheduleAPModalTableContentRowWrapper>
      <StyledScheduleAPModalTableContentRowDataWrapper
        customStyle={{
          width: 'fit-content',
        }}
      >
        {/* Select component */}
        <ScheduleAPModalTableContentRowCell
          customStyle={{
            width: '154px',
          }}
        >
          {ap?.code?.product_code}
        </ScheduleAPModalTableContentRowCell>
        {/* Select component */}
        <ScheduleAPModalTableContentRowCell
          customStyle={{
            width: '227px',
          }}
        >
          {ap?.code?.product_code_name}
        </ScheduleAPModalTableContentRowCell>
        {/* Select component */}
        <ScheduleAPModalTableContentRowCell
          customStyle={{
            width: '119px',
            borderRight: 'none',
          }}
        >
          {ap?.amt}
        </ScheduleAPModalTableContentRowCell>
      </StyledScheduleAPModalTableContentRowDataWrapper>
    </StyledScheduleAPModalTableContentRowWrapper>
  );
};

export default DualMoveAPModalExistAPInfoRow;
