const judgeCheckboxRender = ({ row }) => {
  if (row?.category === 'VENDOR' && row?.bill_no) {
    return true;
  }
  if (row?.category === 'DRIVER') {
    return true;
  }
  return false;
};

export default judgeCheckboxRender;
