import generateFormDefaultValue from '../Generator/generateFormDefaultValue';

const resetForm = ({
  reset,
  setContainerCount,
  setContainerList,
  subCategory,
}) => {
  reset(generateFormDefaultValue({ subCategory }), {
    keepErrors: false,
    keepDirty: false,
    keepDirtValues: false,
    keepValues: false,
    keepDefaultValues: false,
    keepIsSubmitted: false,
    keepTouched: false,
    keepIsValid: false,
    keepSubmitCount: false,
  });
  reset();
  setContainerCount(1);
  setContainerList?.([
    {
      containerKey: 1,
      containerIdx: 1,
    },
  ]);
  const fileFields = document.querySelectorAll('input[type="file"]');
  fileFields.forEach(field => {
    field.value = '';
  });
};

export default resetForm;
