const getContextMenuItems = ({ e, category }) => [
  {
    name: 'Detail Info',
    action: () => {
      const bcDetailTab = window.open(
        `/bc-detail/detail-info/${category}/${e.node.data.wo_no}`,
        'bc-detail-tab',
      );
    },
  },
  {
    name: 'Billing Info',
    action: () => {
      const bcDetailTab = window.open(
        `/bc-detail/billing-info/${category}/${e.node.data.wo_no}`,
        'bc-detail-tab',
      );
    },
  },
  {
    name: 'Document',
    action: () => {
      const bcDetailTab = window.open(
        `/bc-detail/document/${category}/${e.node.data.wo_no}`,
        'bc-detail-tab',
      );
    },
  },
  {
    name: 'Memo',
    action: () => {
      const bcDetailTab = window.open(
        `/bc-detail/memo/${category}/${e.node.data.wo_no}`,
        'bc-detail-tab',
      );
    },
  },
  {
    name: 'Tracking',
    action: () => {
      const bcDetailTab = window.open(
        `/bc-detail/tracking/${category}/${e.node.data.wo_no}`,
        'bc-detail-tab',
      );
    },
  },
];

export default getContextMenuItems;
