import React, { useState } from 'react';
import { MAPPING_PAGE } from '../../../../constants/Admin/DivConf/adminDivConfElements';
import CommonButton from '../../../CommonButton/CommonButton';
import CommonAutoComplete from '../../../Inputs/CommonAutoComplete/CommonAutoComplete';
import CommonTextInput from '../../../Inputs/CommonTextInput/CommonTextInput';

const MobileContainer = ({
  dryRun,
  waiting,
  other,
  apProductCodeOption,
  div,
  postProductCodeMutation,
  patchProductCodeMutation,
  deleteProductCodeMutation,
  isLoadingAPProductCode,
}) => {
  const [dryRunProductCode, setTruckingChargeProductCode] = useState({});
  const [waitingProductCode, setFuelSurchargeProductCode] = useState({});
  const [otherProductCode, setChassisRateProductCode] = useState({});
  const [deletedType, setDeletedType] = useState('');

  const handleSubmit = ({ type, code, buttonRef }) => {
    const mappingPage = MAPPING_PAGE[type];
    if (code) {
      if (dryRun?.mapping_page === mappingPage) {
        patchProductCodeMutation({
          seq: dryRun?.seq,
          productCode: code?.value,
          buttonRef,
        });
        return;
      }
      if (waiting?.mapping_page === mappingPage) {
        patchProductCodeMutation({
          seq: waiting?.seq,
          productCode: code?.value,
          buttonRef,
        });
        return;
      }
      if (other?.mapping_page === mappingPage) {
        patchProductCodeMutation({
          seq: other?.seq,
          productCode: code?.value,
          buttonRef,
        });
        return;
      }
      postProductCodeMutation({
        div,
        productCode: code?.value,
        mappingPage,
        buttonRef,
      });
      return;
    }
    setDeletedType(type);
    if (dryRun?.mapping_page === mappingPage) {
      deleteProductCodeMutation({
        seq: dryRun?.seq,
        productCode: dryRun?.product_code,
        buttonRef,
      });
    }
    if (waiting?.mapping_page === mappingPage) {
      deleteProductCodeMutation({
        seq: waiting?.seq,
        productCode: waiting?.product_code,
        buttonRef,
      });
    }
    if (other?.mapping_page === mappingPage) {
      deleteProductCodeMutation({
        seq: other?.seq,
        productCode: other?.product_code,
        buttonRef,
      });
    }
  };

  return (
    <div className="flex ml-[119px] mt-[67px] align-center">
      <div className="flex flex-col">
        <div className="flex">
          <div className="flex flex-col">
            <div className="text-[15px] font-bold">MOBILE</div>
            <div className="text-stone-600 text-xs font-medium mt-[29px] mb-[10px]">
              DRY RUN
            </div>
            <div className="mt-[-22px] mb-[-10px]">
              <CommonAutoComplete
                inputName="dryRun"
                placeholder="Service Code"
                width="w-[131px]"
                color="#264B9F"
                option={apProductCodeOption}
                defaultValue={dryRun?.product_code}
                onChangeHandler={({ onChange, selectProps }) => {
                  setDeletedType('');
                  onChange?.(selectProps?.label?.split(' / ')[0]);
                  setTruckingChargeProductCode(selectProps);
                }}
                isLoading={isLoadingAPProductCode}
              />
            </div>
          </div>
          <div className="mt-[48px] ml-[7px]">
            <CommonTextInput
              inputName="dryRunName"
              placeholder="Service Code Name"
              width="w-[188px]"
              defaultValue={
                deletedType === 'DRY_RUN'
                  ? ''
                  : dryRunProductCode?.name || dryRun?.product_code_name
              }
              disabled
            />
          </div>
          <div className="w-full mt-[65px] ml-[7px]">
            <CommonButton
              category="complete"
              width="77px"
              preventDupClick
              onClick={({ buttonRef }) =>
                handleSubmit({
                  type: 'DRY_RUN',
                  code: dryRunProductCode,
                  buttonRef,
                })
              }
            >
              Apply
            </CommonButton>
          </div>
        </div>
        <div className="flex">
          <div className="flex flex-col">
            <div className="text-stone-600 text-xs font-medium mt-[20px] mb-[10px]">
              WAITING
            </div>
            <div className="mt-[-22px] mb-[-10px]">
              <CommonAutoComplete
                inputName="waiting"
                placeholder="Service Code"
                width="w-[131px]"
                color="#264B9F"
                option={apProductCodeOption}
                defaultValue={waiting?.product_code}
                onChangeHandler={({ onChange, selectProps }) => {
                  setDeletedType('');
                  onChange?.(selectProps?.label?.split(' / ')[0]);
                  setFuelSurchargeProductCode(selectProps);
                }}
                isLoading={isLoadingAPProductCode}
              />
            </div>
          </div>
          <div className="mt-[24px] ml-[7px]">
            <CommonTextInput
              inputName="waitingName"
              placeholder="Service Code Name"
              width="w-[188px]"
              defaultValue={
                deletedType === 'WAITING'
                  ? ''
                  : waitingProductCode?.name || waiting?.product_code_name
              }
              disabled
            />
          </div>
          <div className="w-full mt-[41px] ml-[7px]">
            <CommonButton
              category="complete"
              width="77px"
              preventDupClick
              onClick={({ buttonRef }) =>
                handleSubmit({
                  type: 'WAITING',
                  code: waitingProductCode,
                  buttonRef,
                })
              }
            >
              Apply
            </CommonButton>
          </div>
        </div>
        <div className="flex">
          <div className="flex flex-col">
            <div className="text-stone-600 text-xs font-medium mt-[20px] mb-[10px]">
              OTHER
            </div>
            <div className="mt-[-22px] mb-[-10px]">
              <CommonAutoComplete
                inputName="other"
                placeholder="Service Code"
                width="w-[131px]"
                color="#264B9F"
                option={apProductCodeOption}
                defaultValue={other?.product_code}
                onChangeHandler={({ onChange, selectProps }) => {
                  setDeletedType('');
                  onChange?.(selectProps?.label?.split(' / ')[0]);
                  setChassisRateProductCode(selectProps);
                }}
                isLoading={isLoadingAPProductCode}
              />
            </div>
          </div>
          <div className="mt-[24px] ml-[7px]">
            <CommonTextInput
              inputName="otherName"
              placeholder="Service Code Name"
              width="w-[188px]"
              defaultValue={
                deletedType === 'OTHERS'
                  ? ''
                  : otherProductCode?.name || other?.product_code_name
              }
              disabled
            />
          </div>
          <div className="w-full mt-[41px] ml-[7px]">
            <CommonButton
              category="complete"
              width="77px"
              preventDupClick
              onClick={({ buttonRef }) =>
                handleSubmit({
                  type: 'OTHERS',
                  code: otherProductCode,
                  buttonRef,
                })
              }
            >
              Apply
            </CommonButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileContainer;
