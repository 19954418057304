import commonDefaultApi from '../api';
import handleQueryError from '../../../utils/Common/Handler/handleQueryError';

const getAcctCodeList = async () => {
  try {
    const response = await commonDefaultApi.get('acct-list');
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getAcctCodeList;
