import React from 'react';
import APTableRow from '../APTableRow';

const APTableBody = ({
  APQuery,
  APProductCodeQuery,
  APDriverVendorList,
  setPaymentModal,
  setSelectedDriver,
  setRateCFMModal,
  setIsAlertOpen,
  setIsSuccess,
  setErrorMessage,
  setIsCompanyDriver,
  setIsQBDisabled,
  setIsCreateBillNoDisabled,
  isAPEditable,
  isDeletable,
  refetchAPData,
}) => {
  const APData = APQuery?.APData;

  return (
    APData &&
    APData?.map(apDataRow => (
      <APTableRow
        key={apDataRow?.seq}
        row={apDataRow}
        APData={APData}
        APProductCodeQuery={APProductCodeQuery}
        APDriverVendorList={APDriverVendorList}
        setPaymentModal={setPaymentModal}
        setSelectedDriver={setSelectedDriver}
        setRateCFMModal={setRateCFMModal}
        setIsAlertOpen={setIsAlertOpen}
        setIsSuccess={setIsSuccess}
        setErrorMessage={setErrorMessage}
        setIsCompanyDriver={setIsCompanyDriver}
        setIsQBDisabled={setIsQBDisabled}
        setIsCreateBillNoDisabled={setIsCreateBillNoDisabled}
        isAPEditable={isAPEditable}
        isDeletable={isDeletable}
        refetchAPData={refetchAPData}
      />
    ))
  );
};

export default APTableBody;
