import masterCompanyDefaultApi from '../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

/**
 *
 * This function is a function that get specific copmany data according to company ID
 *
 * @param {*} companyID Company ID
 * @returns Server response
 */
const getSelectedCompany = async companyID => {
  try {
    const response = await masterCompanyDefaultApi.get(`${companyID}`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getSelectedCompany;
