import React from 'react';
import CommonTextarea from '../../../components/Inputs/CommonTextarea/CommonTextarea';

const getTextareaComponent = ({ ...props }) => {
  const commonProperty = {
    disabled: props?.disabled,
    required: props?.required,
    labelStyle: props?.labelStyle,
    borderVisible: props?.borderVisible,
    onChangeHandler: ({ e, onChange, selectProps, ...handlerProps }) => {
      onChange?.(e?.target?.checked);
    },
  };

  const inputProperty = [
    {
      key: 0,
      label: 'Textarea Label Custom',
      inputName: 'textarea00',
      width: props?.customWidth,
      height: props?.customHeight,
      ...commonProperty,
    },
  ];

  return inputProperty?.map(element => (
    <CommonTextarea
      key={element?.key}
      {...element}
      labelStyle={props?.labelStyle && 'row'}
    />
  ));
};
export default getTextareaComponent;
