const commonKeys = {
  all: ['COMMON'],
  bookingNumber: () => [...commonKeys.all, 'BOOKING_NUMBER'],
  companyIDList: ({ urlParams }) => [
    ...commonKeys.all,
    'COMPANY_ID_LIST',
    urlParams,
  ],
  divList: () => [...commonKeys.all, 'DIVISION_LIST'],
  addrInfo: () => [...commonKeys.all, 'ADDR_INFO'],
  autoComplete: () => [...commonKeys.all, 'AUTO_COMPLETE'],
  specAutoComplete: ({ category }) => [...commonKeys?.autoComplete(), category],
};

export default commonKeys;
