import React from 'react';
import DetailContent from './DetailContent';

const LiveShareDetail = ({ ...props }) => {
  /** Get props data */
  const { liveShareData } = props;

  return (
    <div className="flex flex-col gap-[20px] pt-[52px]">
      <div data-testid="detailBox">
        <DetailContent
          detailTitle="Details"
          topData={liveShareData?.details?.vehicle_name}
          bottomData={liveShareData?.details?.driver_name}
        />
      </div>
      <div data-testid="contactBox">
        <DetailContent
          detailTitle="Contact"
          topData={liveShareData?.contact?.phone}
          bottomData={liveShareData?.contact?.email}
        />
      </div>
    </div>
  );
};

export default LiveShareDetail;
