import React, { useState } from 'react';
import usePatchGeofenceMutation from '../../../../hooks/Trace/Patch/usePatchGeofenceMutation';
import CommonErrorModal from '../../../CommonErrorModal/CommonErrorModal';
import CommonButton from '../../../CommonButton/CommonButton';

/**
 * SavePosition function is run when click the button,
 * and update the paths of the checked geofence.
 *
 * @returns
 * Geofence save button component.
 */
const GeofenceSaveBtn = ({ geofenceList, company }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [alertModalMessage, setAlertModalMessage] = useState('');

  const { mutate: patchGeofenceMutation } = usePatchGeofenceMutation({
    setIsAlertOpen: setAlertModalOpen,
    setIsSuccessAlert: setIsSuccess,
    setAlertMessage: setAlertModalMessage,
    company,
  });

  const savePosition = ({ buttonRef }) => {
    geofenceList.forEach(geofence => {
      patchGeofenceMutation({
        company: geofence.key,
        position: geofence.paths,
        buttonRef,
      });
    });
  };

  return (
    <>
      {!isSuccess && (
        <CommonErrorModal
          open={alertModalOpen}
          setIsOpen={setAlertModalOpen}
          message={alertModalMessage}
          isSuccess={isSuccess}
        />
      )}
      <div className="p-[8px] mr-[2px]">
        <CommonButton
          category="save"
          data-testid="save-button"
          preventDupClick
          onClick={({ buttonRef }) => {
            savePosition({ buttonRef });
          }}
        >
          Save
        </CommonButton>
      </div>
    </>
  );
};

export default GeofenceSaveBtn;
