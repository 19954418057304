import { acctDefaultAPI } from '../../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

const patchDriverPay = async ({ patchData }) => {
  try {
    const response = await acctDefaultAPI.patch(`bulk-paid`, patchData);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};
export default patchDriverPay;
