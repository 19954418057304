import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import divBasicDefaultApi from '../../divApi';

const getARProductCode = async () => {
  try {
    const response = await divBasicDefaultApi.get(`product-code/ar`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getARProductCode;
