import adminDefaultApi from '../../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

const getConfList = async ({ title, active }) => {
  try {
    const response = await adminDefaultApi.get(title, {
      params: { active },
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getConfList;
