import dayjs from 'dayjs';

const handleGridDataFilter = ({
  advancedFilter,
  gridCustomerData,
  customerData,
}) => {
  const dateType = advancedFilter?.dateType;
  const before =
    advancedFilter?.before && dayjs(advancedFilter?.before)?.isValid()
      ? dayjs(advancedFilter?.before)
      : null;
  const after =
    advancedFilter?.after && dayjs(advancedFilter?.after)?.isValid()
      ? dayjs(advancedFilter?.after)
      : null;
  if (dateType === '' || !dateType) return customerData?.result || [];

  return customerData?.result
    ?.map(row => {
      const specificDate =
        row?.[dateType] && dayjs(row?.[dateType])?.isValid()
          ? dayjs(row?.[dateType])
          : null;

      if (specificDate) {
        if (before && !after)
          return specificDate?.isSame(before) || specificDate?.isAfter(before)
            ? row
            : null;
        if (before && after) {
          return (specificDate?.isSame(before) ||
            specificDate?.isAfter(before)) &&
            (specificDate?.isSame(after) || specificDate?.isBefore(after))
            ? row
            : null;
        }
        if (!before && after) {
          return specificDate?.isSame(after) || specificDate?.isBefore(after)
            ? row
            : null;
        }
        return row;
      }
      return null;
    })
    ?.filter(item => item);
};

export default handleGridDataFilter;
