import styled from 'styled-components';

const StyledConfAccordionContainer = styled.div`
  width: 1768px;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .horizontal-layout {
    width: 100%;
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
  }

  .accordion {
    width: 100%;
  }

  .accordion-bar {
    width: 100%;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 0px 18px;
    background-color: #ccd3e3;
    color: #222222;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
  }

  .content {
    display: flex;
    background-color: #fff;
  }
`;

export default StyledConfAccordionContainer;
