import bcDetailWoDefaultApi from '../api';
import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';

const postRollbackToImport = async ({ woNo }) => {
  try {
    const response = await bcDetailWoDefaultApi.post(`${woNo}/rollback`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default postRollbackToImport;
