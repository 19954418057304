import { useMutation } from '@tanstack/react-query';
import { SUCCESS_UPDATE } from '../../../../constants/Common/successMessage';
import { patchEarlyWarning } from '../../../../services/TmsReport/EarlyWarning/Patch/patchEarlyWarning';
import resetRow from '../../../../utils/Common/Handler/resetRow';
import updateGridData from '../../../../utils/Common/Handler/updateGridData';
import useControlToast from '../../../Common/Toast/useControlToast';

const usePatchStatus = ({
  setErrorMessage,
  setIsErrorModalOpen,
  setIsSuccess,
  ref,
  queryObject,
}) => {
  const { addToast } = useControlToast();

  const status = {
    EWS001: queryObject?.confirm,
    EWS002: queryObject?.issue,
    EWS003: queryObject?.solved,
  };

  const mutate = useMutation({
    mutationFn: patchEarlyWarning,
    onSuccess: (res, variables) => {
      if (status[res?.data?.data?.status]) {
        res.data.data.seq = variables?.gridParam?.data?.seq;
        updateGridData({
          gridRef: ref,
          newData: res?.data?.data,
          isUpdate: true,
        });
      } else {
        updateGridData({
          variables,
          isUpdate: false,
        });
      }

      addToast({ message: SUCCESS_UPDATE });
    },
    onError: error => {
      resetRow({ gridRef: ref });
      setIsSuccess(false);
      setErrorMessage(error.message);
      setIsErrorModalOpen(true);
    },
  });
  return { ...mutate };
};

export default usePatchStatus;
