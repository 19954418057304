import React from 'react';
import { Outlet } from 'react-router-dom';
import MainLayOut from '../../components/Layout/MainLayOut';
import SubMenuNavigator from '../../components/Common/SubMenuNavigator';
import { PAGE_EDI } from '../../constants/Common/mapper';

const EDI = () => {
  const tabsData = [];

  return (
    <MainLayOut tabsData={tabsData}>
      <SubMenuNavigator page={PAGE_EDI} />
      <div className="w-full pl-[97px] h-full">
        <Outlet />
      </div>
    </MainLayOut>
  );
};

export default EDI;
