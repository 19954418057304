import styled from 'styled-components';

const StyledSaveItemContentWrapper = styled.div`
  width: 478px;
  height: fit-content;
  background-color: #f9f9f9;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
`;

export default StyledSaveItemContentWrapper;
