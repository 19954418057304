const moveBCDetailPage = ({ gridParam, columnID }) => {
  const headerName = gridParam?.colDef?.headerName;

  if (headerName !== 'FOLD') {
    let pageName;
    const woNo = gridParam?.data?.[columnID];
    const pageCode = woNo?.slice(1, 2);

    if (pageCode === 'M') {
      pageName = 'import';
    } else if (pageCode === 'X') {
      pageName = 'export';
    } else if (pageCode === 'V') {
      pageName = 'van';
    } else if (pageCode === 'B') {
      pageName = 'brokerage';
    } else if (pageCode === 'C') {
      pageName = 'bare-chassis';
    }

    const bcDetailTab = window.open(
      `/bc-detail/billing-info/${pageName}/${woNo}`,
      'bc-detail-tab',
    );
  }
};

export default moveBCDetailPage;
