import styled from 'styled-components';

const StyledBCDetailBillingInfoProfitText = styled.div`
  display: flex;
  width: 250px;
  height: 30px;
  font-size: 11px;
  color: #264b9f;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  background-color: #f2f4f8;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
`;

export default StyledBCDetailBillingInfoProfitText;
