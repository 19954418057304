import {
  PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
  PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
} from '../../../Common/path';

const IMPORT_COLORS = {
  importColor1: {
    backgroundColor: '#CCD3E3',
    color: 'black',
    icon: null,
  },
  importColor2: {
    backgroundColor: '#B3BCD9',
    color: 'black',
    icon: null,
  },
  importColor3: {
    backgroundColor: '#919FC1',
    color: 'black',
    icon: null,
  },
  importColor4: {
    backgroundColor: '#415688',
    color: 'white',
    icon: {
      fold: PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
      unfold: PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
    },
  },
  importColor5: {
    backgroundColor: '#223C77',
    color: 'white',
    icon: {
      fold: PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
      unfold: PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
    },
  },
  importColor6: {
    backgroundColor: '#002265',
    color: 'white',
    icon: {
      fold: PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
      unfold: PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
    },
  },
  grey: {
    backgroundColor: '#979797',
    color: 'white',
    icon: {
      fold: PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
      unfold: PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
    },
  },
  darkGrey: {
    backgroundColor: '#646464',
    color: 'white',
    icon: {
      fold: PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
      unfold: PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
    },
  },
  yard: {
    backgroundColor: '#FFF4CF',
    color: 'black',
    icon: null,
  },
};

export { IMPORT_COLORS };
