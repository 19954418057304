import {
  SUB_ORDER_TYPE_BARECHASSIS,
  SUB_ORDER_TYPE_BROKERAGE,
  SUB_ORDER_TYPE_VAN,
} from '../../../constants/Order/Common/basic';

/**
 * @description
 * Function to add target generator according to sub category
 * @param {string} subCategory
 * Sub category of order
 * @returns
 * Target
 */
const generateAddTarget = ({ subCategory }) => {
  if (subCategory === SUB_ORDER_TYPE_VAN) return 'Trailer';
  if (subCategory === SUB_ORDER_TYPE_BROKERAGE) return 'Equipment';
  if (subCategory === SUB_ORDER_TYPE_BARECHASSIS) return 'Chassis';
  return 'Container';
};

export default generateAddTarget;
