export const IMPORT_WORKORDER_CATEGORY_OBJECT = {
  REGULAR: 'WC0001',
  SHUTTLE: 'WC0002',
};

export const IMPORT_WORKORDER_CATEGORY = [
  {
    value: 'REGULAR',
    label: 'REGULAR',
  },
  {
    value: 'SHUTTLE',
    label: 'SHUTTLE',
  },
];

export const EXPORT_WORKORDER_CATEGORY_OBJECT = {
  REGULAR: 'WC0003',
  SHUTTLE: 'WC0004',
};

export const EXPORT_WORKORDER_CATEGORY = [
  {
    value: 'REGULAR',
    label: 'REGULAR',
  },
  {
    value: 'SHUTTLE',
    label: 'SHUTTLE',
  },
];

export const IMPORT_DEFAULT_COLUMN = [
  'wo_no',
  'billto',
  'mbl_no',
  'hbl_no',
  'ref_no',
  'purchase_no',
  'dilv_no',
  'cont_no',
  'cont_sz',
  'cont_type',
  'ssl',
  'vessel',
  'po_ch_no',
  'category',
  'po_pool',
  'ch_no',
  'pool',
  'tare',
  'seal',
  'commodity',
  'wgt',
  'qty',
  'unit',
  'sendto',
  'ava_date',
  'empty_cfm',
  'bond',
  'eta',
  'lfd',
  'fd_date',
  'rfd',
  'bk_no',
  'erd',
  'co',
  'st',
  'remarks',
  'sche_type',
  'profit',
  'a/r',
  'trucking',
  'fsc/r',
  'ch/r',
  'pe/r',
  'de/r',
  'dr/r',
  'inv',
  'a/p',
  'drv/p',
  'ch/p',
  'pe/p',
  'de/p',
  'dr/p',
  'pod',
  'b/l',
  'do',
  'it/p',
  'it/e',
  'po_loc',
  'po_sche_datetime',
  'po_actual_in_datetime',
  'po_actual_out_datetime',
  'po_driver',
  'dilv_loc',
  'dilv_sche_datetime',
  'dilv_actual_in_datetime',
  'dilv_actual_out_datetime',
  'dilv_driver',
  'pu_loc',
  'pu_sche_datetime',
  'pu_actual_in_datetime',
  'pu_actual_out_datetime',
  'pu_driver',
  'rtn_loc',
  'rtn_sche_datetime',
  'rtn_actual_in_datetime',
  'rtn_actual_out_datetime',
  'rtn_driver',
];

export const EXPORT_DEFAULT_COLUMN = [
  'wo_no',
  'billto',
  'hbl_no',
  'ref_no',
  'purchase_no',
  'dilv_no',
  'cont_no',
  'cont_sz',
  'cont_type',
  'po_ch_no',
  'category',
  'po_pool',
  'ch_no',
  'pool',
  'tare',
  'seal',
  'commodity',
  'wgt',
  'qty',
  'unit',
  'sendto',
  'load_cfm',
  'bond',
  'eta',
  'bk_no',
  'ssl',
  'fd_date',
  'erd',
  'co',
  'vessel',
  'rfd',
  'st',
  'remarks',
  'sche_type',
  'profit',
  'a/r',
  'trucking',
  'fsc/r',
  'ch/r',
  'pe/r',
  'de/r',
  'dr/r',
  'inv',
  'a/p',
  'drv/p',
  'ch/p',
  'pe/p',
  'de/p',
  'dr/p',
  'pod',
  'b/l',
  'do',
  'it/p',
  'it/e',
  'po_loc',
  'po_sche_datetime',
  'po_actual_in_datetime',
  'po_actual_out_datetime',
  'po_driver',
  'dilv_loc',
  'dilv_sche_datetime',
  'dilv_actual_in_datetime',
  'dilv_actual_out_datetime',
  'dilv_driver',
  'pu_loc',
  'pu_sche_datetime',
  'pu_actual_in_datetime',
  'pu_actual_out_datetime',
  'pu_driver',
  'rtn_loc',
  'rtn_sche_datetime',
  'rtn_actual_in_datetime',
  'rtn_actual_out_datetime',
  'rtn_driver',
];

export const VAN_DEFAULT_COLUMN = [
  'wo_no',
  'billto',
  'hbl_no',
  'ref_no',
  'purchase_no',
  'dilv_no',
  'trailer_no',
  'trailer_sz',
  'trailer_type',
  'seal',
  'sendto',
  'commodity',
  'wgt',
  'bond',
  'remarks',
  'sche_type',
  'qty',
  'empty_load_cfm',
  'unit',
  'profit',
  'a/r',
  'trucking',
  'fsc/r',
  'ch/r',
  'pe/r',
  'de/r',
  'dr/r',
  'inv',
  'a/p',
  'drv/p',
  'ch/p',
  'pe/p',
  'de/p',
  'dr/p',
  'pod',
  'b/l',
  'do',
  'it/p',
  'it/e',
  'po_loc',
  'po_sche_datetime',
  'po_actual_in_datetime',
  'po_actual_out_datetime',
  'po_driver',
  'dilv_loc',
  'dilv_sche_datetime',
  'dilv_actual_in_datetime',
  'dilv_actual_out_datetime',
  'dilv_driver',
  'pu_loc',
  'pu_sche_datetime',
  'pu_actual_in_datetime',
  'pu_actual_out_datetime',
  'pu_driver',
  'rtn_loc',
  'rtn_sche_datetime',
  'rtn_actual_in_datetime',
  'rtn_actual_out_datetime',
  'rtn_driver',
];

export const BARECHASSIS_DEFAULT_COLUMN = [
  'wo_no',
  'billto',
  'ref_no',
  'dilv_no',
  'po_ch_no',
  'po_pool',
  'ch_no',
  'pool',
  'tare',
  'sendto',
  'remarks',
  'sche_type',
  'profit',
  'a/r',
  'trucking',
  'fsc/r',
  'ch/r',
  'pe/r',
  'de/r',
  'dr/r',
  'inv',
  'a/p',
  'drv/p',
  'ch/p',
  'pe/p',
  'de/p',
  'dr/p',
  'pod',
  'b/l',
  'do',
  'it/p',
  'it/e',
  'po_loc',
  'po_sche_datetime',
  'po_actual_in_datetime',
  'po_actual_out_datetime',
  'po_driver',
  'dilv_loc',
  'dilv_sche_datetime',
  'dilv_actual_in_datetime',
  'dilv_actual_out_datetime',
  'dilv_driver',
  'pu_loc',
  'pu_sche_datetime',
  'pu_actual_in_datetime',
  'pu_actual_out_datetime',
  'pu_driver',
  'rtn_loc',
  'rtn_sche_datetime',
  'rtn_actual_in_datetime',
  'rtn_actual_out_datetime',
  'rtn_driver',
];
