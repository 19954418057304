import React from 'react';

const CommonTabModalHeader = ({ setTabIndexOpen, idx, open, header }) => {
  if (open && idx === 0) {
    return (
      <div className="flex border-b-[2px] border-b-[#001E5F] bg-white rounded-tl-[10px]">
        <div className="flex text-[14px] text-[#001E5F] font-bold mx-[20px] my-[12px]">
          {header}
        </div>
      </div>
    );
  }
  if (open) {
    return (
      <div className="flex border-b-[2px] border-b-[#001E5F] bg-white">
        <div className="flex text-[14px] text-[#001E5F] font-bold mx-[20px] my-[12px]">
          {header}
        </div>
      </div>
    );
  }
  return (
    <div
      className="flex cursor-pointer"
      onClick={() => {
        setTabIndexOpen(idx);
      }}
    >
      <div className="flex text-[14px] text-[#B2BDC9] font-bold mx-[20px] my-[12px]">
        {header}
      </div>
    </div>
  );
};

export default CommonTabModalHeader;
