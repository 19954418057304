import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  SUB_ORDER_TYPE_BARECHASSIS,
  SUB_ORDER_TYPE_BROKERAGE,
  SUB_ORDER_TYPE_EXPREG,
  SUB_ORDER_TYPE_EXPSHUTTLE,
  SUB_ORDER_TYPE_VAN,
} from '../../../../constants/Order/Common/basic';
import StyledTopInfo from '../../../../styles/Order/StyledTopInfo';
import createBookingNumberSelect from '../../../../utils/Common/Option/createBookingNumberSelect';
import createMandatorySelect from '../../../../utils/Common/Option/createMandatorySelect';
import generateOption from '../../../../utils/Order/Generator/optionGenerator';
import handleAutoCompleteChange from '../../../../utils/Order/Handler/InputHandler/handleAutoCompleteChange';
import handleBookingChange from '../../../../utils/Order/Handler/InputHandler/handleBookingChange';
import handleSelectChange from '../../../../utils/Order/Handler/InputHandler/handleSelectChange';
import handleTextInputChange from '../../../../utils/Order/Handler/InputHandler/handleTextInputChange';
import CommonAutoComplete from '../../../Inputs/CommonAutoComplete/CommonAutoComplete';
import CommonFile from '../../../Inputs/CommonFile/CommonFile';
import CommonSelect from '../../../Inputs/CommonSelect/CommonSelect';
import CommonTextInput from '../../../Inputs/CommonTextInput/CommonTextInput';

const OrderTopInfo = ({
  subCategory,
  commonOption,
  companyOption,
  bookingNumber,
  currDiv,
  setBookingSequence,
  doDisabled,
  isLoadingBookingNumber,
  isLoadingCompanyOption,
  ...props
}) => {
  const { setValue } = useFormContext();

  const watchBondEntry = !!useWatch({ name: 'bond_entry' });
  const watchCF = !!useWatch({ name: 'cf' });

  return (
    <div className="pt-[35px] pb-[11px] pl-[30px] pr-[30px]">
      <StyledTopInfo subCategory={subCategory}>
        <div>
          <CommonFile
            inputName="do"
            label="DO"
            required={
              ![SUB_ORDER_TYPE_EXPREG, SUB_ORDER_TYPE_EXPSHUTTLE].includes(
                subCategory,
              )
            }
            disabled={doDisabled}
          />
        </div>
        {subCategory !== SUB_ORDER_TYPE_BARECHASSIS &&
          subCategory !== SUB_ORDER_TYPE_BROKERAGE && (
            <>
              <div>
                <CommonFile
                  inputName="cf"
                  label="CF"
                  required={watchBondEntry}
                />
              </div>
              <div>
                <CommonTextInput
                  inputName="bond_entry"
                  label="BOND-ENTRY #"
                  maxLength={10}
                  onChangeHandler={handleTextInputChange}
                  required={watchCF}
                />
              </div>
            </>
          )}
        {subCategory !== SUB_ORDER_TYPE_BARECHASSIS && (
          <div>
            <CommonAutoComplete
              inputName="billto"
              label="BILL TO"
              required
              maxLength={9}
              option={companyOption?.billTo}
              onChangeHandler={handleAutoCompleteChange}
              isLoading={isLoadingCompanyOption}
            />
          </div>
        )}
        {(subCategory.startsWith('imp') ||
          subCategory.startsWith('van') ||
          subCategory.startsWith('brokerage')) && (
          <div>
            <CommonTextInput
              inputName="mbl_no"
              label={subCategory.startsWith('brokerage') ? 'LOAD #' : 'MBL #'}
              required
              maxLength={20}
              onChangeHandler={handleTextInputChange}
            />
          </div>
        )}
        {subCategory.startsWith('exp') && (
          <div>
            <CommonAutoComplete
              inputName="bk_no"
              label="BOOKING #"
              required
              maxLength={20}
              onChangeHandler={({ e, selectProps, ...handlerProps }) =>
                handleBookingChange({
                  e,
                  selectProps,
                  setBookingSequence,
                  setValue,
                  ...handlerProps,
                })
              }
              option={createBookingNumberSelect({
                bookingNumberData: bookingNumber?.bookingNumber,
                currDiv,
              })}
              isLoading={isLoadingBookingNumber}
            />
          </div>
        )}
        {subCategory !== SUB_ORDER_TYPE_BARECHASSIS && (
          <div>
            <CommonTextInput
              inputName="hbl_no"
              label={subCategory.startsWith('brokerage') ? 'BL #' : 'HBL #'}
              maxLength={20}
              onChangeHandler={handleTextInputChange}
            />
          </div>
        )}
        {subCategory !== SUB_ORDER_TYPE_VAN &&
          subCategory !== SUB_ORDER_TYPE_BROKERAGE &&
          subCategory !== SUB_ORDER_TYPE_BARECHASSIS && (
            <>
              <div>
                <CommonTextInput
                  inputName="ref_no"
                  label="REF #"
                  required
                  maxLength={30}
                  onChangeHandler={handleTextInputChange}
                />
              </div>
              <div>
                <CommonTextInput
                  inputName="purchase_no"
                  label="PO #"
                  maxLength={50}
                  onChangeHandler={handleTextInputChange}
                />
              </div>
              <div>
                <CommonTextInput
                  inputName="dilv_no"
                  label="DELIVERY #"
                  maxLength={20}
                  onChangeHandler={handleTextInputChange}
                />
              </div>
            </>
          )}
        {(subCategory === SUB_ORDER_TYPE_VAN ||
          subCategory === SUB_ORDER_TYPE_BROKERAGE) && (
          <>
            <div>
              <CommonTextInput
                inputName="ref_no"
                label="REF #"
                required
                maxLength={30}
                onChangeHandler={handleTextInputChange}
              />
            </div>
            <div>
              <CommonTextInput
                inputName="purchase_no"
                label="PO #"
                maxLength={50}
                onChangeHandler={handleTextInputChange}
              />
            </div>
            <div>
              <CommonTextInput
                inputName="dilv_no"
                label="DELIVERY #"
                maxLength={20}
                onChangeHandler={handleTextInputChange}
              />
            </div>
          </>
        )}
        {subCategory === SUB_ORDER_TYPE_BARECHASSIS && (
          <>
            <div>
              <CommonAutoComplete
                inputName="billto"
                label="BILL TO"
                required
                maxLength={8}
                option={companyOption?.billTo}
                onChangeHandler={handleAutoCompleteChange}
                isLoading={isLoadingCompanyOption}
              />
            </div>
            <div>
              <CommonTextInput
                inputName="ref_no"
                label="REF #"
                required
                maxLength={30}
                onChangeHandler={handleTextInputChange}
              />
            </div>
            <div>
              <CommonTextInput
                inputName="dilv_no"
                label="DELIVERY #"
                maxLength={20}
                onChangeHandler={handleTextInputChange}
              />
            </div>
          </>
        )}
        {![
          SUB_ORDER_TYPE_VAN,
          SUB_ORDER_TYPE_BROKERAGE,
          SUB_ORDER_TYPE_BARECHASSIS,
        ].includes(subCategory) &&
          subCategory !== SUB_ORDER_TYPE_BARECHASSIS && (
            <>
              <div>
                <CommonSelect
                  inputName="ssl"
                  label="SSL"
                  required
                  disabled={[
                    SUB_ORDER_TYPE_EXPREG,
                    SUB_ORDER_TYPE_EXPSHUTTLE,
                  ].includes(subCategory)}
                  onChangeHandler={handleSelectChange}
                  option={createMandatorySelect({
                    selectOption: generateOption({
                      items: commonOption?.ssl,
                      property: 'ssl',
                    }),
                  })}
                />
              </div>
              <div>
                <CommonTextInput
                  inputName="vessel"
                  label="VESSEL"
                  maxLength={30}
                  onChangeHandler={handleTextInputChange}
                  disabled={[
                    SUB_ORDER_TYPE_EXPREG,
                    SUB_ORDER_TYPE_EXPSHUTTLE,
                  ].includes(subCategory)}
                />
              </div>
            </>
          )}
      </StyledTopInfo>
    </div>
  );
};

export default OrderTopInfo;
