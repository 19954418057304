import styled from 'styled-components';

const StyledBCDetailBillingInfoCommonFooterTotalValue = styled.div`
  position: relative;
  font-size: 11px;
  color: #222;
  font-weight: 500;
  width: 90px;
  line-height: 24px;
  border-left: none;
  display: flex;
  align-items: center;
  padding-right: 10px;
  border-top: none;
  margin-left: 29px;
  margin-top: 2px;
`;

export default StyledBCDetailBillingInfoCommonFooterTotalValue;
