const generateAppliedColumnList = ({ checkOrderList }) => {
  const appliedColumnList = checkOrderList
    ?.filter(item => item.checked)
    ?.map(item => {
      if (item?.children) {
        const selectedChildren = item?.children
          ?.filter(child => child.checked)
          .map(childItem => childItem.value);
        return { [item.value]: selectedChildren };
      }
      return item.value;
    });

  return appliedColumnList;
};

export default generateAppliedColumnList;
