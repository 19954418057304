import React, { useRef } from 'react';
import CommonDataGrid from '../../../components/CommonDataGrid';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import HeaderButtons from '../../../components/Master/Common/HeaderButtons';
import StyledMasterCommonDataGridContainer from '../../../styles/Master/Common/DataGrid/StyledMasterCommonDataGridContainer';
import useGetAcctCode from '../../../hooks/Master/AccountCode/useGetAcctCode';
import defaultColumnDefsGenerator from '../../../utils/Common/Datagrid/Generator/defaultColumnDefsGenerator';
import createAcctCodeColumnDefs from '../../../utils/Master/AccountCode/Generator/createAcctCodeColumnDefs';
import TotalBottomBar from '../../../components/Common/CommonTotalBottomBar';
import useControlTotalInfo from '../../../hooks/Common/TotalBottomRow/useControlTotalInfo';

const AccountCode = () => {
  const defaultColumnDefs = defaultColumnDefsGenerator();
  const gridRef = useRef(null);

  const { isLoading, isError, data: acctCodeList } = useGetAcctCode();

  const { totalRows, setChangeFlag } = useControlTotalInfo({
    gridRef,
    queryData: acctCodeList?.data,
  });

  return (
    <>
      {(isLoading || isError) && <CommonLoading open={isLoading || isError} />}
      <div className="flex flex-col h-full">
        <HeaderButtons gridRef={gridRef} fileName="ACCOUNT CODE" />
        <StyledMasterCommonDataGridContainer>
          <CommonDataGrid
            gridRef={gridRef}
            sideBar={false}
            data={acctCodeList?.data}
            columnDefs={createAcctCodeColumnDefs()}
            defaultColDef={defaultColumnDefs}
            suppressDragLeaveHidesColumns
            onFilterChanged={() => {
              setChangeFlag(prev => !prev);
            }}
            onSelectionChanged={() => {
              setChangeFlag(prev => !prev);
            }}
          />
          <TotalBottomBar totalRows={totalRows} />
        </StyledMasterCommonDataGridContainer>
      </div>
    </>
  );
};

export default AccountCode;
