import React from 'react';
import StyledSavedListContentWrapper from '../../../../styles/CustomerReport/Common/StyledSavedListContentWrapper';

const SavedListContent = ({ ...props }) => {
  /** Get props data */
  const { selectedList, savedList } = props;

  /** Data Setter */
  const { setSelectedList } = props;

  return (
    <StyledSavedListContentWrapper>
      <div className="flex flex-col gap-[10px]">
        {savedList?.map((item, index) => {
          return (
            <div key={item?.seq}>
              <label
                htmlFor={item?.seq}
                className="flex items-center gap-[5px]"
              >
                <input
                  type="radio"
                  id={item?.seq}
                  value={item?.seq}
                  onChange={() => setSelectedList(item)}
                  checked={item?.list_name === selectedList?.list_name}
                />
                {item?.list_name}
              </label>
            </div>
          );
        })}
      </div>
    </StyledSavedListContentWrapper>
  );
};
export default SavedListContent;
