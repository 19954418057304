const COMPLETE_BUTTON = ['complete'];
const EXPORT_BUTTON = ['export_btn'];
const DRIVER_COLUMN_LIST_AVAILABLE = ['po_driver', 'dilv_driver'];
const DRIVER_COLUMN_LIST_DELIVERY = ['dilv_driver', 'pu_driver'];
const DRIVER_COLUMN_LIST_RETURN = ['pu_driver', 'rtn_driver'];

export const BUTTON_COLS = {
  imp_unava: DRIVER_COLUMN_LIST_AVAILABLE,
  imp_ava: DRIVER_COLUMN_LIST_AVAILABLE,
  imp_ava_yard: DRIVER_COLUMN_LIST_AVAILABLE,
  imp_dilv: DRIVER_COLUMN_LIST_DELIVERY,
  imp_dilv_yard: DRIVER_COLUMN_LIST_DELIVERY,
  imp_rtn: [...COMPLETE_BUTTON, ...DRIVER_COLUMN_LIST_RETURN],
  imp_rtn_yard: [...COMPLETE_BUTTON, ...DRIVER_COLUMN_LIST_RETURN],
  exp_unava: EXPORT_BUTTON,
  exp_unava_yard: EXPORT_BUTTON,
  exp_ava: DRIVER_COLUMN_LIST_AVAILABLE,
  exp_ava_yard: DRIVER_COLUMN_LIST_AVAILABLE,
  exp_dilv: DRIVER_COLUMN_LIST_DELIVERY,
  exp_dilv_yard: DRIVER_COLUMN_LIST_DELIVERY,
  exp_rtn: [...COMPLETE_BUTTON, ...DRIVER_COLUMN_LIST_RETURN],
  exp_rtn_yard: [...COMPLETE_BUTTON, ...DRIVER_COLUMN_LIST_RETURN],
  van_ava: DRIVER_COLUMN_LIST_AVAILABLE,
  van_dilv: DRIVER_COLUMN_LIST_DELIVERY,
  van_rtn: [...COMPLETE_BUTTON, ...DRIVER_COLUMN_LIST_RETURN],
  bkg_ava: DRIVER_COLUMN_LIST_AVAILABLE,
  bkg_dilv: DRIVER_COLUMN_LIST_DELIVERY,
  bkg_rtn: [...COMPLETE_BUTTON, ...DRIVER_COLUMN_LIST_RETURN],
};
