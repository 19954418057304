import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import getConfList from '../../../services/Admin/Conf/Get/getConfList';
import { ADMIN_CONF_ACTIVE_KEY_VALUES } from '../../../constants/Admin/Conf/keyValues';

const useGetConfList = ({ title, active, setIsLoading, setIsError }) => {
  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
  }, [active]);

  const result = useQuery({
    queryKey: ADMIN_CONF_ACTIVE_KEY_VALUES?.[title]?.({ active }),
    queryFn: () => getConfList({ title, active }),
    refetchOnWindowFocus: false,
    staleTime: 0,
  });

  useEffect(() => {
    setIsLoading(prev =>
      !prev ? prev : result?.isLoading && result?.isFetching,
    );
    setIsError(prev => prev || result?.isError);
  }, [result?.isLoading, result?.isError, result?.isFetching]);

  if (title === 'pool' || title === 'type') {
    const confData = result?.data?.data;
    const defaultIndex = confData?.findIndex(item => item.seq === 1);
    const defaultElement = confData?.splice?.(defaultIndex, 1);
    confData?.unshift(...defaultElement);
  }

  return result;
};

export default useGetConfList;
