import getDateComponent from './getDateComponent';
import getSelectComponent from './getSelectComponent';
import getTextInputComponent from './getTextInputComponent';
import getTimeComponent from './getTimeComponent';
import getAutoCompleteComponent from './getAutoCompleteComponent';

const getRenderComponent = ({ inputType, ...props }) => {
  if (inputType === 'TextInput') {
    return getTextInputComponent({ ...props });
  }
  if (inputType === 'Select') {
    return getSelectComponent({ ...props });
  }
  if (inputType === 'Date') {
    return getDateComponent({ ...props });
  }
  if (inputType === 'Time') {
    return getTimeComponent({ ...props });
  }
  if (inputType === 'AutoComplete') {
    return getAutoCompleteComponent({ ...props });
  }
  return null;
};
export default getRenderComponent;
