import React, { memo, useRef } from 'react';

const ScheduleTrackingModalMapContent = memo(
  ({ trackingInfo, ...props }) => {
    const mapRef = useRef(null);

    return trackingInfo?.tracking_id ? (
      <div className="w-[437px] h-[300px]" id="map_canvas" ref={mapRef}>
        Map
      </div>
    ) : (
      <div className="w-[437px] h-[300px] flex justify-center items-center">
        Tracking ID is not exist.
      </div>
    );
  },
  (prev, next) => {
    prev.trackingInfo.tracking_id === next.trackingInfo.tracking_id;
  },
);

export default ScheduleTrackingModalMapContent;
