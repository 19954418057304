/**
 * @description
 * Function that find pick-up type ordering 0 schedule in job_set or location_set
 * @param {list} jobSet
 * List that contains job_set data
 * @param {list} locationSet
 * List that contains location_set data
 * @returns {object}
 * Return pick-up type ordering 0 schedule
 */
const handlePickUpBaseSchedule = ({ jobSet, locationSet }) => {
  /** Find pick-up type base schedule in job_set */
  const getPickUpBaseRow = jobSet?.find(job => {
    const getFromLocation = job?.from_location;
    const getLiveDilvLocation = job?.live_dilv_location;
    const getToLocation = job?.to_location;

    if (
      getFromLocation &&
      getFromLocation?.type === 'P/U' &&
      getFromLocation?.ordering === 0
    )
      return getFromLocation;

    if (
      getLiveDilvLocation &&
      getLiveDilvLocation?.type === 'P/U' &&
      getLiveDilvLocation?.ordering === 0
    )
      return getLiveDilvLocation;

    if (
      getToLocation &&
      getToLocation?.type === 'P/U' &&
      getToLocation?.ordering === 0
    )
      return getToLocation;

    return null;
  });

  /** Return if pick-up type base schedule exist in job_set */
  if (getPickUpBaseRow) return getPickUpBaseRow;

  /** Return if pick-up type base schedule exist in location_set */
  if (!getPickUpBaseRow)
    return locationSet?.find(
      location => location?.type === 'P/U' && location?.ordering === 0,
    );

  /** Return null if location_set is not exist */
  return null;
};

export default handlePickUpBaseSchedule;
