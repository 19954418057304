import React from 'react';
import SyltedLiveShareContentWrapper from '../../../styles/LiveShare/Common/StyledLiveShareContentWrapper';
import LiveShareInfo from '../LiveShareInfo';
import LiveShareMap from '../LiveShareMap';

const LiveShareContent = ({ ...props }) => {
  /** Get props data */
  const { liveShareData } = props;

  return (
    liveShareData && (
      <SyltedLiveShareContentWrapper>
        <LiveShareInfo {...props} />
        <LiveShareMap {...props} />
      </SyltedLiveShareContentWrapper>
    )
  );
};
export default LiveShareContent;
