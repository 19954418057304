import React from 'react';
import { useFormContext } from 'react-hook-form';
import CommonButton from '../../../../CommonButton/CommonButton';

const ButtonContent = ({ setIsSearchModalOpen, handleSearch }) => {
  const { handleSubmit } = useFormContext();

  return (
    <div className="flex justify-center mt-[-8px]">
      <div className="mr-[18px]">
        <CommonButton
          category="cancel"
          onClick={() => setIsSearchModalOpen(false)}
        >
          cancel
        </CommonButton>
      </div>
      <CommonButton
        type="button"
        category="search"
        onClick={handleSubmit(handleSearch)}
      >
        Search
      </CommonButton>
    </div>
  );
};

export default ButtonContent;
