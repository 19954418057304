import React from 'react';
import BGColor from '../BGColor/BGColor';

const BGColorContainer = ({
  bgColor,
  div,
  patchBGColorActivateMutation,
  patchBGColorMutation,
  patchBGColorLBSMutation,
  setIsMessageOpen,
  setMessage,
  setIsSuccess,
}) => {
  return (
    <>
      <div className="text-stone-600 text-xs font-medium mb-[10px]">
        BG COLOR
      </div>
      <BGColor
        name="CF"
        inputName="CF"
        isBorder
        isActive={bgColor[0].active}
        color={bgColor[0].color}
        div={div}
        patchBGColorActivateMutation={patchBGColorActivateMutation}
        patchBGColorMutation={patchBGColorMutation}
      />
      <BGColor
        name="CFS"
        inputName="CFS"
        isBorder
        isActive={bgColor[1].active}
        color={bgColor[1].color}
        div={div}
        patchBGColorActivateMutation={patchBGColorActivateMutation}
        patchBGColorMutation={patchBGColorMutation}
      />
      <BGColor
        name="HAZMAT"
        inputName="Hazmat"
        isBorder
        isActive={bgColor[2].active}
        color={bgColor[2].color}
        div={div}
        patchBGColorActivateMutation={patchBGColorActivateMutation}
        patchBGColorMutation={patchBGColorMutation}
      />
      <BGColor
        name="HOT"
        inputName="Hot"
        isBorder
        isActive={bgColor[3].active}
        color={bgColor[3].color}
        div={div}
        patchBGColorActivateMutation={patchBGColorActivateMutation}
        patchBGColorMutation={patchBGColorMutation}
      />
      <BGColor
        name="OVERWEIGHT"
        inputName="Overweight"
        isBorder
        isActive={bgColor[4].active}
        color={bgColor[4].color}
        lbs={bgColor[4].lbs}
        div={div}
        patchBGColorActivateMutation={patchBGColorActivateMutation}
        patchBGColorMutation={patchBGColorMutation}
        patchBGColorLBSMutation={patchBGColorLBSMutation}
        setIsMessageOpen={setIsMessageOpen}
        setMessage={setMessage}
        setIsSuccess={setIsSuccess}
      />
      <BGColor
        name="OVERHEIGHT"
        inputName="Overheight"
        isBorder
        isActive={bgColor[5].active}
        color={bgColor[5].color}
        div={div}
        patchBGColorActivateMutation={patchBGColorActivateMutation}
        patchBGColorMutation={patchBGColorMutation}
      />
      <BGColor
        name="CHASSIS TW"
        inputName="Chassis TW"
        isBorder
        isActive={bgColor[6].active}
        color={bgColor[6].color}
        lbs={bgColor[6].lbs}
        div={div}
        patchBGColorActivateMutation={patchBGColorActivateMutation}
        patchBGColorMutation={patchBGColorMutation}
        patchBGColorLBSMutation={patchBGColorLBSMutation}
        setIsMessageOpen={setIsMessageOpen}
        setMessage={setMessage}
        setIsSuccess={setIsSuccess}
      />
    </>
  );
};

export default BGColorContainer;
