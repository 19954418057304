import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useDrop } from 'react-dnd';
import itemTypes from '../../../constants/Common/CustomColumnModal/itemTypes';
import {
  boxGenerator,
  newCheckOrderListGenerator,
} from '../../../utils/Common/CustomColumnModal/Generator/dragAndDropGenerator';
import SelectedColumnGroup from './SelectedColumnGroup';
import SelectedColumnItem from './SelectedColumnItem';

const SCROLL_THRESHOLD = 100;
const SCROLL_SPEED = 3;

const OrderedListArea = memo(
  ({ checkOrderList, setCheckOrderList, pageType }) => {
    const containerRef = useRef(null);

    const findBox = useCallback(
      (id, orderList) => boxGenerator({ id, checkOrderList: orderList }),
      [checkOrderList],
    );

    const moveBox = useCallback(
      (id, atIndex, orderList, isGroup, groupID) => {
        const { box, index } = findBox(id, orderList);

        const newCheckOrderList = newCheckOrderListGenerator({
          checkOrderList: orderList,
          index,
          atIndex,
          box,
        });

        if (isGroup) {
          setCheckOrderList(prev => {
            const { box: groupBox, index: groupIndex } = findBox(
              groupID,
              checkOrderList,
            );
            groupBox.children = newCheckOrderList;
            const updatedOrderList = [...prev];
            updatedOrderList[groupIndex] = groupBox;

            return updatedOrderList;
          });
        } else setCheckOrderList(newCheckOrderList);
      },
      [findBox, checkOrderList, setCheckOrderList],
    );

    const [, drop] = useDrop(() => ({ accept: itemTypes.ORDERBOX }));

    useEffect(() => {
      const handleDragOver = e => {
        const container = containerRef?.current;
        const { clientY } = e;
        const { top, bottom } = container?.getBoundingClientRect();

        if (clientY - top < SCROLL_THRESHOLD) {
          container?.scrollBy(0, -SCROLL_SPEED);
        } else if (bottom - clientY < SCROLL_THRESHOLD) {
          container?.scrollBy(0, SCROLL_SPEED);
        }
      };

      document?.addEventListener('dragover', handleDragOver);

      return () => {
        document?.removeEventListener('dragover', handleDragOver);
      };
    }, []);

    return (
      <div className="h-full overflow-y-auto" ref={containerRef}>
        {checkOrderList.map(item =>
          item?.children ? (
            <SelectedColumnGroup
              key={`${item?.id}`}
              id={item?.id}
              groupItem={item}
              findBox={findBox}
              moveBox={moveBox}
              checkOrderList={checkOrderList}
            />
          ) : (
            <SelectedColumnItem
              key={`${item?.id}`}
              id={item?.id}
              columnItem={item}
              findBox={findBox}
              moveBox={moveBox}
              checkOrderList={checkOrderList}
            />
          ),
        )}
      </div>
    );
  },
);

export default OrderedListArea;
