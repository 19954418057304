import React from 'react';
import CommonMessageModal from '../../../CommonMessageModal/CommonMessageModal';
import StyledCommonErrorModalWrapper from '../../../../styles/Common/CommonErrorModal/StyledCommonErrorModalWrapper';
import StyledMasterCommonAlertMessageButton from '../../../../styles/Master/Common/AlertMessage/StyledMastercommonAlertMessageButton';
import CommonButton from '../../../CommonButton/CommonButton';
import handleRowDelete from '../../../../utils/BCDetail/Document/Handler/handleRowDelete';

const DocDeleteConfirmModal = ({ delType, setDelType, ...props }) => {
  return (
    <CommonMessageModal
      open={delType}
      setOpen={setDelType}
      header="Alert"
      content={
        <StyledCommonErrorModalWrapper>
          <p>Are you sure to delete it?</p>
        </StyledCommonErrorModalWrapper>
      }
      buttonContent={
        <StyledMasterCommonAlertMessageButton className="gap-x-[15px]">
          <CommonButton category="no" onClick={() => setDelType(false)}>
            No
          </CommonButton>
          <CommonButton
            category="yes"
            preventDupClick
            onClick={({ buttonRef }) => {
              handleRowDelete({
                delType,
                buttonRef,
                ...props,
              });
            }}
          >
            Yes
          </CommonButton>
        </StyledMasterCommonAlertMessageButton>
      }
    />
  );
};

export default DocDeleteConfirmModal;
