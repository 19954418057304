import React from 'react';
import CommonSelect from '../../../components/Inputs/CommonSelect/CommonSelect';
import CommonAutoComplete from '../../../components/Inputs/CommonAutoComplete/CommonAutoComplete';

const getAutoCompleteComponent = ({ ...props }) => {
  const SELECT_OPTION = [
    {
      key: 0,
      label: 'My Name Is Super Handsome James',
      value: 'My Name Is Super Handsome James',
    },
    {
      key: 1,
      label: 'My Name Is Hwi',
      value: 'My Name Is Hwi',
    },
    {
      key: 2,
      label: 'My Name Is David',
      value: 'My Name Is David',
    },
    {
      key: 3,
      label: 'My Name Is Amy',
      value: 'My Name Is Amy',
    },
    {
      key: 4,
      label: 'This is Different Label and Value. Click Me',
      value: 'James Is Super Nice Guy',
    },
  ];

  const commonProperty = {
    disabled: props?.disabled,
    required: props?.required,
    labelStyle: props?.labelStyle,
    borderVisible: props?.borderVisible,
    option: SELECT_OPTION,
    onChangeHandler: ({ e, onChange, selectProps, ...handlerProps }) => {
      onChange?.(selectProps);
    },
    onInputChangeHandler: ({ e, onChange, selectProps, ...handlerProps }) => {
      onChange?.(selectProps);
    },
  };

  const inputProperty = [
    {
      key: 0,
      label: 'Auto Complete Label default',
      inputName: 'autoComplete00',
      width: null,
      ...commonProperty,
    },
    {
      key: 1,
      label: 'Auto Complete Label 25%',
      inputName: 'autoComplete00',
      width: 'w-1/4',
      ...commonProperty,
    },
    {
      key: 2,
      label: 'Auto Complete Label 50%',
      inputName: 'autoComplete00',
      width: 'w-2/4',
      ...commonProperty,
    },
    {
      key: 3,
      label: 'Auto Complete Label 75%',
      inputName: 'autoComplete00',
      width: 'w-3/4',
      ...commonProperty,
    },
    {
      key: 4,
      label: 'Auto Complete Label 100%',
      inputName: 'autoComplete00',
      width: 'w-full',
      ...commonProperty,
    },
    {
      key: 5,
      label: 'Auto Complete Label Custom',
      inputName: 'autoComplete00',
      width: props?.customWidth,
      ...commonProperty,
    },
  ];

  return inputProperty?.map(element => (
    <CommonAutoComplete
      key={element?.key}
      {...element}
      labelStyle={props?.labelStyle && 'row'}
    />
  ));
};
export default getAutoCompleteComponent;
