import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import getCompanyList from '../../../services/Master/Company/Get/getCompanyList';
import masterKeys from '../keys';
import createObjOption from '../../../utils/Master/Company/Generator/createObjOption';

const useGetCompanyData = ({ queryObject, setCompanyIDOption }) => {
  const result = useQuery({
    queryKey: masterKeys.companySearchList({ queryObject }),
    queryFn: () => getCompanyList({ queryObject }),
  });

  useEffect(() => {
    if (
      (!result?.isError && result?.isSuccess) ||
      (!result?.isError && result?.isFetching)
    )
      setCompanyIDOption(createObjOption(result?.data?.data, 'company'));
  }, [result?.isError, result?.isSuccess, result?.isFetching]);

  return result;
};

export default useGetCompanyData;
