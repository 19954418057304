const DAILY_REPORT_PULL_OUT_EDITABLE_FIELDS = [
  'lfd',
  'po_driver',
  'equipment',
  'cont_no',
  'trailer_no',
  'ch_no',
  'pool',
  'remarks',
];

const DAILY_REPORT_DELIVERY_EDITABLE_FIELDS = [
  'ch_no',
  'pool',
  'dilv_driver',
  'remarks',
];

const DAILY_REPORT_RETURN_EDITABLE_FIELDS = [
  'ch_no',
  'pool',
  'rtn_driver',
  'remarks',
];

const DAILY_REPORT_RETURN_YARD_EDITABLE_FIELDS = [
  'ch_no',
  'pool',
  'rtn_schedule',
  'rtn_driver',
  'remarks',
];

const DAILY_REPORT_EDITABLE_COLUMNS = {
  pull_out: DAILY_REPORT_PULL_OUT_EDITABLE_FIELDS,
  delivery: DAILY_REPORT_DELIVERY_EDITABLE_FIELDS,
  return: DAILY_REPORT_RETURN_EDITABLE_FIELDS,
  return_yard: DAILY_REPORT_RETURN_YARD_EDITABLE_FIELDS,
};

export default DAILY_REPORT_EDITABLE_COLUMNS;
