import { acctDefaultAPI } from '../../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

export const getChassisReconcile = async ({ queryString }) => {
  try {
    const response = await acctDefaultAPI.get(`chassis-recon?${queryString}`);
    return response.data;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};
