import styled from 'styled-components';

const StyledToputilityBarWrapper = styled.div`
  width: 100%;
  height: 64px;
  min-height: 64px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export default StyledToputilityBarWrapper;
