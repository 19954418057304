const expandSubTable = ({ gridRef, expandedRowNameList }) => {
  setTimeout(() => {
    gridRef?.current?.api?.forEachNode(node => {
      if (node?.data && expandedRowNameList?.includes(node?.data?.name)) {
        gridRef?.current?.api?.setRowNodeExpanded(node, true);
      }
    });
  }, 100);
};

export default expandSubTable;
