import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import CustomColumnHalfSection from '../CustomColumnHalfSection';
import AvailableCheckboxArea from '../AvailableCheckboxArea';
import OrderedListArea from '../OrderedListArea';

const CustomizeColsModalContent = ({ status, ...props }) => {
  return (
    <div className="divide-x-[0.4px] divide-[#000000] divide-opacity-60 border-y-[0.4px] border-[#000000]  border-opacity-60 h-[418px] flex w-full">
      <CustomColumnHalfSection
        title={status}
        content={<AvailableCheckboxArea {...props} />}
      />
      <CustomColumnHalfSection
        title="Selected Columns"
        content={
          <DndProvider backend={HTML5Backend}>
            <OrderedListArea {...props} />
          </DndProvider>
        }
      />
    </div>
  );
};

export default CustomizeColsModalContent;
