import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import bcDetailDefaultApi from '../../api';

/**
 * @description
 * Function that get booking number according to size, type, ssl
 * @returns {object}
 * API response
 */
const getBookingNumber = async ({ woNo, size, type, ssl }) => {
  try {
    const response = await bcDetailDefaultApi.get(`${woNo}/bk-list`, {
      params: {
        size,
        type,
        ssl,
      },
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getBookingNumber;
