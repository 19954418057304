import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import tmsReportAPI from '../../api';

const getDemurrageList = async ({ division, fromDate, toDate }) => {
  try {
    const response = await tmsReportAPI.get(
      `/penalty?type=Demurrage&div=${division}&created-from=${fromDate}&created-to=${toDate}`,
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getDemurrageList;
