import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthRouter from '../../../../../components/Common/AuthRouter';
import MainWrapper from '../../../../../pages/Main/MainWrapper';
import {
  MAIN_CATEGORY_BARE_CHASSIS,
  MAIN_CATEGORY_BROKERAGE,
  MAIN_CATEGORY_EXPORT,
  MAIN_CATEGORY_IMPORT,
  MAIN_CATEGORY_VAN,
} from '../../../../Main/Common/category';
import {
  PERM_MAIN_BARE,
  PERM_MAIN_BROK,
  PERM_MAIN_EXP,
  PERM_MAIN_IMP,
  PERM_MAIN_VAN,
} from '../../../permission';
import getAuthorizedMenu from '../../../../../utils/Common/Sidebar/Handler/getAuthorizedMenu';
import { SIDEBAR_SUB_MENU } from '../../../Option/Sidebar';

const MAIN_CHILDREN_ROUTE = [
  {
    index: true,
    element: (
      <Navigate
        to={
          getAuthorizedMenu({
            allMenu: SIDEBAR_SUB_MENU?.[2],
          })?.[0]?.to
        }
      />
    ),
  },
  {
    path: 'imp/*',
    element: (
      <AuthRouter permission={PERM_MAIN_IMP}>
        <MainWrapper category={MAIN_CATEGORY_IMPORT} />
      </AuthRouter>
    ),
  },
  {
    path: 'exp/*',
    element: (
      <AuthRouter permission={PERM_MAIN_EXP}>
        <MainWrapper category={MAIN_CATEGORY_EXPORT} />
      </AuthRouter>
    ),
  },
  {
    path: 'van/*',
    element: (
      <AuthRouter permission={PERM_MAIN_VAN}>
        <MainWrapper category={MAIN_CATEGORY_VAN} />
      </AuthRouter>
    ),
  },
  {
    path: 'brokerage/*',
    element: (
      <AuthRouter permission={PERM_MAIN_BROK}>
        <MainWrapper category={MAIN_CATEGORY_BROKERAGE} />
      </AuthRouter>
    ),
  },
  {
    path: 'barechassis/*',
    element: (
      <AuthRouter permission={PERM_MAIN_BARE}>
        <MainWrapper category={MAIN_CATEGORY_BARE_CHASSIS} />
      </AuthRouter>
    ),
  },
];

export default MAIN_CHILDREN_ROUTE;
