import { ASSIGN_DRIVER_RESTRICTION_CODE } from '../../../../constants/Dispatch/Common/assignDriverRestrictionMessage';
import { specificCodeErrorMessageGenerator } from '../../../Common/Generator/specificErrorMessageGenerator';

const generateAssignRestrictMessage = ({ restrictionCode, schedule }) => {
  if (restrictionCode === ASSIGN_DRIVER_RESTRICTION_CODE?.NO_JOB) {
    return 'Unable to assign driver. Please check BC Detail.';
  }
  if (restrictionCode === ASSIGN_DRIVER_RESTRICTION_CODE?.NO_LOCATION) {
    return 'Location is required.';
  }
  if (restrictionCode === ASSIGN_DRIVER_RESTRICTION_CODE?.NO_PENDING) {
    return 'This job is already started.';
  }
  if (restrictionCode === ASSIGN_DRIVER_RESTRICTION_CODE?.NO_SCHEDULE) {
    return specificCodeErrorMessageGenerator({
      code: 'empty_schedule_datetime',
      fields: [`${schedule}_schedule`],
    });
  }

  return 'Unable to assign driver. Please check BC Detail.';
};

export default generateAssignRestrictMessage;
