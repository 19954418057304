import React, { useEffect } from 'react';
import CommonMessageModal from '../../../../../CommonMessageModal/CommonMessageModal';
import ScheduleTrackingModalContent from './ScheduleTrackingModalContent';
import ScheduleTrackingModalButtonContent from './ScheduleTrackingModalButtonContent';

const ScheduleTrackingModal = ({
  open,
  setOpen,
  trackingInfo,
  setMessageModalOpener,
  setMessageModalMessage,
  mutatePostEmail,
  ...props
}) => {
  return (
    <CommonMessageModal
      open={open}
      setOpen={setOpen}
      header="Tracking"
      content={
        <ScheduleTrackingModalContent
          trackingInfo={trackingInfo}
          setOpen={setOpen}
          setMessageModalOpener={setMessageModalOpener}
          setMessageModalMessage={setMessageModalMessage}
        />
      }
      buttonContent={
        <ScheduleTrackingModalButtonContent
          trackingInfo={trackingInfo}
          mutatePostEmail={mutatePostEmail}
        />
      }
      width="537px"
      height="555px"
    />
  );
};

export default ScheduleTrackingModal;
