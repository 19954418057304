import getSortingDefs from '../Generator/getSortingDefs';

const handleSorting = ({ gridRef, status, subFilter }) => {
  if (gridRef?.current?.api) {
    const sortingDefs = getSortingDefs({ status, subFilter });
    const setting = {
      state: Object.keys(sortingDefs).map(key => ({
        colId: key,
        ...sortingDefs[key],
      })),
      defaultState: { sort: null },
    };
    gridRef?.current?.api?.applyColumnState(setting);
  }
};

export default handleSorting;
