export const MANIFEST_FILTER_STATUS = [
  {
    value: '',
    label: 'Select',
  },
  {
    value: 'Paid',
    label: 'Paid',
  },
  {
    value: 'PARTIAL PAID',
    label: 'Partial Paid',
  },
  {
    value: 'Invoiced',
    label: 'Invoiced',
  },
  {
    value: 'In Process',
    label: 'In Process',
  },
  {
    value: 'REVIEW',
    label: 'Review',
  },
  {
    value: 'REJECTED',
    label: 'Rejected',
  },
];

export const MANIFEST_FILTER_DATE = [
  {
    value: '',
    label: 'Select',
  },
  {
    value: 'Invoice Date',
    label: 'Invoice Date',
  },
  {
    value: 'Delivery Date',
    label: 'Delivery Date',
  },
];

export const MANIFEST_FILTER_PERIOD = [
  {
    value: '',
    label: 'Select',
  },
  {
    value: 'Week',
    label: 'Week',
    period: { count: 1, unit: 'week' },
  },
  {
    value: 'Month',
    label: 'Month',
    period: { count: 1, unit: 'month' },
  },
  {
    value: '3 Months',
    label: '3 Months',
    period: { count: 3, unit: 'month' },
  },
  {
    value: '6 Months',
    label: '6 Months',
    period: { count: 6, unit: 'month' },
  },
  {
    value: 'Year',
    label: 'Year',
    period: { count: 1, unit: 'year' },
  },
];
