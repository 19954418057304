import {
  AP_GROUP,
  AR_GROUP,
  DELIVERY_GROUP,
  DOCUMENT_GROUP,
  PICK_UP_GROUP,
  PULL_OUT_GROUP,
  RETURN_GROUP,
} from '../Common/columnGroups';

const BARE_CHASSIS_DEFAULT_FIELDS = {
  wms: {},
  created_date: {},
  wo_no: {},
  billto: {},
  ch_no: {},
  pool: {},
  ch_type: {},
  ref_no: {},
  hot: {},
  po_g: { children: PULL_OUT_GROUP },
  dilv_g: { children: DELIVERY_GROUP },
  pu_g: { children: PICK_UP_GROUP },
  rtn_g: { children: RETURN_GROUP },
  ar_g: { children: AR_GROUP },
  ap_g: { children: AP_GROUP },
  profit: {},
  inv: {},
  doc_g: { children: DOCUMENT_GROUP },
  sendto: {},
  remarks: {},
};

export { BARE_CHASSIS_DEFAULT_FIELDS };
