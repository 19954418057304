import divBasicDefaultAPI from '../../divApi';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

const patchEarlyWarning = async ({ seq, patchData }) => {
  try {
    const response = await divBasicDefaultAPI.patch(
      `early-warning/${seq}`,
      patchData,
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};
export default patchEarlyWarning;
