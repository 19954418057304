export const FACTORING_OPTION = [
  { label: 'ALL', value: 'ALL' },
  { label: 'Factoring', value: 'true' },
  { label: 'Non-Factoring', value: 'false' },
];

export const AP_AR_TYPE_OPTION = [
  { label: 'ALL', value: 'ALL' },
  { label: 'AP', value: 'AP' },
  { label: 'AR', value: 'AR' },
];

export const QB_AP_AR_OPTION = [
  { label: 'ALL', value: 'ALL' },
  { label: 'Not Sent', value: 'QS0001' },
  { label: 'Accepted', value: 'QS0002' },
  { label: 'Error', value: 'QS0003' },
];

export const AP_AR_RECONCILE_OPTION = [
  { label: 'ALL', value: 'ALL' },
  { label: 'Accepted', value: 'QS0002' },
  { label: 'Locked', value: 'QS0004' },
];

export const CATEGORY_OPTION = {
  ALL: [
    { label: 'Select', value: '' },
    { label: 'CUSTOMER', value: 'customer' },
    { label: 'DRIVER', value: 'driver' },
    { label: 'VENDOR', value: 'vendor' },
  ],
  AP: [
    { label: 'Select', value: '' },
    { label: 'DRIVER', value: 'driver' },
    { label: 'VENDOR', value: 'vendor' },
  ],
  AR: [
    { label: 'Select', value: '' },
    { label: 'CUSTOMER', value: 'customer' },
  ],
};

export const DRIVER_TYPE_OPTION = [
  { label: 'ALL', value: 'ALL' },
  { label: 'Company', value: 'DR0001' },
  { label: 'Owner', value: 'DR0002' },
  { label: 'Carrier', value: 'DR0003' },
];

export const DATE_UNINVOICE_OPTION = [
  { label: 'CREATE DATE', value: 'create' },
  { label: 'INVOICE DATE', value: 'inv' },
  { label: 'DELIVERY', value: 'dlv' },
];

export const DATE_OPTION = [
  { label: 'INVOICE DATE', value: 'inv' },
  { label: 'DELIVERY', value: 'dlv' },
];

export const QB_RECONCILE_DATE_OPTION = [
  { label: 'INVOICE DATE', value: 'inv' },
  { label: 'DELIVERY', value: 'dlv' },
  { label: 'QB DATE', value: 'qb' },
];

export const PERIOD_OPTION = [
  { label: 'Select', value: '' },
  { label: 'Today', value: 'today' },
  { label: 'This Week', value: 'thisWeek' },
  { label: 'Last Week', value: 'lastWeek' },
  { label: 'This Quater', value: 'thisQuater' },
  { label: 'Last Quater', value: 'lastQuater' },
];

export const STATUS_CHECK_BOX_OPTION = [
  {
    optionLabel: 'WAITING',
    defaultValue: true,
    inputName: 'waiting',
    code: 'RS0001',
  },
  {
    optionLabel: 'REVIEW',
    defaultValue: true,
    inputName: 'review',
    code: 'RS0002',
  },
  {
    optionLabel: 'DISPUTED',
    defaultValue: true,
    inputName: 'disputed',
    code: 'RS0003',
  },
  {
    optionLabel: 'COMPLETED',
    defaultValue: false,
    inputName: 'completed',
    code: 'RS0004',
  },
  {
    optionLabel: 'DENIED',
    defaultValue: false,
    inputName: 'denied',
    code: 'RS0005',
  },
];
