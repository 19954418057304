import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import getJobAssign from '../../../../services/BCDetail/BillingInfo/Common/Get/getJobAssign';
import bcDetailKeys from '../../keys';

const useGetJobAssign = ({ driverID, setIsAlertOpen, setErrorMessage }) => {
  const [jobAssignData, setJobAssignData] = useState([]);
  const woNo = document.URL ? document.URL.split('/').pop() : null;

  const query = useQuery({
    queryKey: bcDetailKeys.billingInfoJobAssign({ woNo, driverID }),
    queryFn: () => getJobAssign({ woNo, driverID }),
    refetchOnWindowFocus: false,
    enabled: woNo !== null && driverID !== null,
    retry: false,
  });

  useEffect(() => {
    if (query?.data?.data) {
      setJobAssignData(query?.data?.data);
    }
  }, [query?.data]);

  useEffect(() => {
    if (query?.error) {
      setIsAlertOpen(true);
      setErrorMessage('Failed to get Job Assign.');
    }
  }, [query?.error]);

  return { ...query, jobAssignData };
};

export default useGetJobAssign;
