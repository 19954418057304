import handleQueryError from '../../../utils/Common/Handler/handleQueryError';
import ediDefaultAPI from '../api';

const decline204 = async ({ id, data }) => {
  try {
    const response = await ediDefaultAPI.patch(`204/${id}/decline`, data);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default decline204;
