import dispatchDefaultAPI from '../../api';

const getDivisionColor = async () => {
  try {
    const response = await dispatchDefaultAPI.get(`div-color`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};
export default getDivisionColor;
