import React from 'react';
import CommonMessageModal from '../../CommonMessageModal/CommonMessageModal';
import CommonButton from '../../CommonButton/CommonButton';
import handleModalEmailButton from '../../../utils/CustomerReport/Handler/handleModalEmailButton';

const SendEmailModal = ({
  isSendEmailModal,
  setIsSendEmailModal,
  sendedEmailInfo,
  postEmailListMutate,
}) => {
  return (
    <CommonMessageModal
      header="Confirmation"
      open={isSendEmailModal}
      setOpen={setIsSendEmailModal}
      width="400px"
      height="203px"
      content={
        <div className="w-full flex flex-col">
          <div className="w-full min-w-[300px] min-h-[61px] h-fit bg-[#F9F9F9] flex justify-center items-center flex-col">
            <div className="flex flex-col justify-center items-center px-[20px] leading-[14.06px] text-center">
              <p className="text-[12px] font-[600]">
                Email has successfully added.
              </p>
              <p className="text-[12px] font-[400]">
                Do you want to send a &quot;Customer Report&quot; link?
              </p>
            </div>
          </div>
          <div className="flex justify-center center-item mt-[22px] gap-x-[16px]">
            <CommonButton
              category="cancel"
              onClick={() => setIsSendEmailModal(false)}
            >
              Cancel
            </CommonButton>
            <CommonButton
              category="email"
              preventDupClick
              onClick={({ buttonRef }) =>
                handleModalEmailButton({
                  buttonRef,
                  sendedEmailInfo,
                  postEmailListMutate,
                  setIsSendEmailModal,
                })
              }
            >
              Email
            </CommonButton>
          </div>
        </div>
      }
    />
  );
};

export default SendEmailModal;
