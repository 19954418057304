const createPairOption = ({ option, pair }) => {
  const pairOption = option?.map(item => ({
    label: `${item?.[pair[0]]} / ${item?.[pair[1]]}`,
    value: `${item?.[pair[0]]} / ${item?.[pair[1]]}`,
    pair: {
      [pair[0]]: item?.[pair[0]],
      [pair[1]]: item?.[pair[1]],
    },
  }));
  return pairOption;
};

export default createPairOption;
