import styled from 'styled-components';

const StyledContainerInfoContents = styled.div`
  display: flex;
  flex-direction: column;

  column-gap: 10px;

  padding-top: 10px;
  padding-bottom: 20px;
`;

export default StyledContainerInfoContents;
