export const statusOption = [
  {
    label: 'Select',
    value: '',
  },
  {
    label: 'COMPLETE',
    value: 'true',
  },
  {
    label: 'IN TRANSIT',
    value: 'false',
  },
];

export const dateOption = [
  {
    label: 'Select',
    value: '',
  },
  {
    label: 'CREATED DATE',
    value: 'created_date',
  },
  {
    label: 'P/O S DATE',
    value: 'po_sche_datetime',
  },
  {
    label: 'P/O A DATE',
    value: 'po_actual_out_datetime',
  },
  {
    label: 'DILV S DATE',
    value: 'dilv_sche_datetime',
  },
  {
    label: 'DILV A DATE',
    value: 'dilv_actual_out_datetime',
  },
  {
    label: 'P/U S DATE',
    value: 'pu_sche_datetime',
  },
  {
    label: 'P/U A DATE',
    value: 'pu_actual_out_datetime',
  },
  {
    label: 'RTN S DATE',
    value: 'rtn_sche_datetime',
  },
  {
    label: 'RTN A DATE',
    value: 'rtn_actual_out_datetime',
  },
  {
    label: 'L/E DATE',
    value: 'empty_cfm',
  },
  {
    label: 'CUT OFF',
    value: 'cutoff',
  },
  {
    label: 'LFD',
    value: 'lfd',
  },
  {
    label: 'ETA',
    value: 'eta',
  },
];

export const beforeAfterOption = [
  {
    label: 'Select',
    value: '',
  },
  {
    label: 'TODAY',
    value: 'today',
  },
  {
    label: '7 DAYS',
    value: '7days',
  },
  {
    label: '14 DAYS',
    value: '14days',
  },
  {
    label: '1 MONTH',
    value: '1month',
  },
  {
    label: '3 MONTH',
    value: '3month',
  },
  {
    label: 'QUARTER',
    value: 'quarter',
  },
  {
    label: '1 YEAR',
    value: '1year',
  },
];
