import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import MemoTextField from '../MemoTextfield';
import CommonButton from '../../../CommonButton/CommonButton';
import usePatchWOMemoContent from '../../../../hooks/BCDetail/Memo/usePatchWOMemoContent';
import useGetWOMemoList from '../../../../hooks/BCDetail/Memo/useGetWOMemoList';

const WoMemoArea = ({ ...props }) => {
  const woNo = document.URL?.split('/')?.pop();

  const { isEditable } = props;

  const userID = localStorage.getItem('userId');

  const { data: woMemoList } = useGetWOMemoList({ woNo });

  const [addContent, setAddContent] = useState(false);
  const { register, watch, setValue, resetField } = useForm();

  const patchWOMemoMutator = usePatchWOMemoContent({
    woNo,
    setValue,
    setAddContent,
    ...props,
  });

  return (
    <div className="pb-[50px]">
      <p className="text-[15px] mb-[30px] font-[700]">MEMO</p>
      <div className="flex justify-start w-[1700px] space-x-[15px]">
        {woMemoList?.data?.memo?.map((res, index) => (
          <div
            className="w-[282px] h-[156px] bg-[#F2F4F8] border-white rounded-[5px] "
            key={res.id}
          >
            <div className="h-[45px] pt-[22px] pb-[12px] px-[18px] text-[11px] text-[#666] flex justify-between">
              <p> {dayjs(res.created_date).format('MM/DD/YY')}</p>
              <p> {res.created_by}</p>
            </div>
            <hr className="border-white mx-[18px] group/textarea group-active/textarea:border-[#264B9F]" />
            <MemoTextField
              memoContent={res.content}
              woNo={woNo}
              id={res.id}
              isEditable={isEditable}
              isCreator={res.created_by === userID}
              patchWOMemoMutator={patchWOMemoMutator}
            />
          </div>
        ))}

        {(woMemoList?.data?.memo?.length < 6 || !woMemoList?.data?.memo) &&
        isEditable ? (
          <div className="relative ">
            <button
              onClick={() => {
                setAddContent(true);
              }}
              className={`${
                addContent ? 'hidden' : 'block'
              } mt-10 ml-[50px] bg-[#DDE2EC] rounded-[5px] text-white w-[80px] h-[80px] text-[60px] font-light flex justify-center items-center`}
              type="button"
            >
              +
            </button>
            <div className={`${addContent ? 'block' : 'hidden'}`}>
              <div className="w-[282px] h-[156px] bg-[#DDE2EC] border-b-[1px] border-white rounded-[5px]">
                <div className="h-[45px] pt-[22px] pb-[12px] px-[18px] text-[11px] text-[#666] flex justify-between">
                  <p> {dayjs().format('MM/DD/YY')}</p>
                  <p> {userID}</p>
                </div>
                <hr className="border-white mx-[18px]" />
                <textarea
                  aria-multiline="true"
                  rows={2}
                  defaultValue=""
                  className="w-[282px] my-0.5 h-[50px] resize-none outline-none text-[11px] font-[500] bg-[#DDE2EC] pt-[10px] px-[18px] rounded-[5px] "
                  {...register('memo')}
                />
                <div className="mt-[25px] mr-[15px] flex justify-end space-x-1.5  ">
                  <div>
                    <CommonButton
                      size="small"
                      borderColor="#B74747"
                      category="cancel"
                      onClick={() => {
                        setAddContent(false);
                        resetField('memo');
                      }}
                    >
                      Cancel
                    </CommonButton>
                  </div>
                  <div>
                    <CommonButton
                      preventDupClick
                      onClick={({ buttonRef }) => {
                        patchWOMemoMutator.mutate({
                          content: watch('memo'),
                          action: 'create',
                          buttonRef,
                        });
                      }}
                      size="small"
                      category="save"
                    >
                      Save
                    </CommonButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default WoMemoArea;
