const createObjOption = (list, property) => {
  const result = [];

  list?.forEach((element, idx) => {
    result?.push({
      key: idx,
      label: element?.[property],
      value: element?.[property],
    });
  });

  return result;
};

export default createObjOption;
