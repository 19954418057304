import React from 'react';
import {
  ICON_MAPPING,
  STATUS_TEXT_MAPPING,
} from '../../../../constants/TmsReport/EarlyWarning/confirmationStatusObject';

const EarlyWarningToast = ({ category }) => {
  const toastWidth = category === 'failed' ? 'w-[106px]' : 'w-[122px]';
  const bgColor = category === 'failed' ? 'bg-[#FFB6B6]' : 'bg-[#BADCCA]';
  const barColor = category === 'failed' ? 'bg-[#B74747]' : 'bg-[#3A825D]';
  const marginLeft = category === 'failed' ? 'ml-[16px]' : 'ml-[13px]';
  const textColor = category === 'failed' ? 'text-[#B74747]' : 'text-[#3A825D]';
  const icon = ICON_MAPPING[category] || null;
  const statusText = STATUS_TEXT_MAPPING[category] || '';

  return (
    <div
      className={`flex h-[32px] rounded-[5px] items-center ${bgColor} ${toastWidth}`}
    >
      <div
        className={`h-full w-[6px] bg-[#3A825D] rounded-l-[5px] ${barColor}`}
      />
      <div className={marginLeft}>
        {category && <img src={icon} alt={category} width={15} height={15} />}
      </div>
      <p className={`text-[12px] font-[700] ml-[15px] ${textColor}`}>
        {statusText}
      </p>
    </div>
  );
};

export default EarlyWarningToast;
