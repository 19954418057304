import React, { useEffect, useMemo, useState } from 'react';
import { FormHelperText, MenuItem, Select, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Controller, useFormContext } from 'react-hook-form';
import StyledCommonInputWrapper from '../../../styles/Common/CommonInput/StyledCommonInputWrapper';
import StyledCommonLabel from '../../../styles/Common/CommonInput/StyledCommonLabel';
import StyledCommonInputRequiredLabel from '../../../styles/Common/CommonInput/StyledCommonInputRequiredLabel';
import defaultInputRules from '../../../utils/Common/Input/defaultInputRules';

const CommonTextSelect = ({ ...props }) => {
  /**
   * @description
   * Get react-hook-form object from useFormContext.
   * @control Must-have elements for getting values from a form.
   * @unregister Allows to unregister a single input or an array of inputs.
   * @formState Object containing information about the form.
   */
  const {
    control,
    unregister,
    formState: { errors: formErrors },
    setValue,
  } = useFormContext();

  /**
   * @label {string} - Label of input
   * @maxLength {number} - Max length of input
   * @required {boolean} - Required input
   * @disabled {boolean} - Disable input
   * @defaultValue {string} - Default value of input
   * @option {array} - Option of select
   * @inputName {string} - Name of input
   */
  const [label, setLabel] = useState(props?.label);

  const [maxLength, setMaxLength] = useState(props?.maxLength || [20, 20]);
  useEffect(() => {
    setMaxLength(props?.maxLength);
  }, [props?.maxLength]);

  // Set default required as false
  const [required, setRequired] = useState(props?.required || [false, false]);
  useEffect(() => {
    /**
     * If default required value changed, component required value would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.required !== undefined && props?.required !== null) {
      setRequired(props?.required);
    }
  }, [props?.required]);

  // Set default disabled as false
  const [disabled, setDisabled] = useState(props?.disabled || [false, false]);
  useEffect(() => {
    /**
     * If default disabled value changed, component disabled value would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.disabled !== undefined && props?.disabled !== null) {
      setDisabled(props?.disabled);
    }
  }, [props?.disabled]);

  const [defaultValue, setDefaultValue] = useState(
    props?.defaultValue || ['', ''],
  );
  useEffect(() => {
    /**
     * If default value changed, component default value would be changed too.
     * Undefined exception handling. Null allowed value.
     */
    if (props?.defaultValue !== undefined && props?.defaultValue !== null) {
      setDefaultValue(props?.defaultValue);
      setValue(props?.inputName?.[0], props?.defaultValue?.[0]);
      setValue(props?.inputName?.[1], props?.defaultValue?.[1]);
    }
  }, [props?.defaultValue?.[0], props?.defaultValue?.[1]]);

  const [option, setOption] = useState(props?.option || []);
  useEffect(() => {
    /**
     * If option changed, component options would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.option !== undefined && props?.option !== null) {
      setOption(props?.option);
    }
  }, [props?.option]);

  const [inputName, setInputName] = useState(
    props?.inputName || ['multi_input_1', 'multi_input_2'],
  );

  useEffect(() => {
    // Unregister input when component unmount
    return () => {
      unregister(props?.inputName?.[0]);
      unregister(props?.inputName?.[1]);
    };
  }, []);

  /**
   * Render Auto-Complete component.
   * @control - Must-have elements for getting values from a form.
   * @name - Name of input.
   * @defaultValue - Default value of input.
   * @rules - Rules of input - Check constraints.
   * @render - Render target input component.
   * - field : Object containing field props, like name, value, onChange, onBlur.
   * - fieldState : Object containing field state, like error, invalid, etc.
   */
  return (
    <StyledCommonInputWrapper labelStyle={props?.labelStyle}>
      {(!!label || props.labelVisible !== false) && (
        <StyledCommonLabel
          labelStyle={props?.labelStyle}
          lbl-pd-right={props?.[`lbl-pd-right`]}
        >
          {!!label && (
            <>
              {!!required?.includes(true) && (
                <StyledCommonInputRequiredLabel>
                  *
                </StyledCommonInputRequiredLabel>
              )}
              {label}
            </>
          )}
        </StyledCommonLabel>
      )}
      <div
        className={`${
          props?.width ? `${props?.width}` : 'w-full'
        } flex focus:border-solid focus:border-2`}
      >
        <div className="flex-1 w-auto min-w-0">
          <Controller
            control={control}
            name={props?.inputName?.[0]}
            defaultValue={defaultValue?.[0]}
            rules={defaultInputRules({
              maxLength: maxLength?.[0],
              required: required?.[0],
              ...props,
            })}
            render={({
              field: { ref, onChange, value, ...field },
              fieldState: { invalid, error },
            }) => {
              // Package of handler props
              const handlerProps = {
                onChange,
                value,
                maxLength: maxLength?.[0],
                ...field,
                ...props,
              };
              // Render Control Input Component
              return (
                <TextField
                  {...field}
                  inputRef={ref}
                  error={invalid}
                  value={value}
                  /**
                   * onChange Handler. Only handlers of parameters work.
                   * To change value, should use Controller field's onChange changer.
                   * ex)
                   * (tempValue) => {
                   *    onChange(tempValue);
                   * }
                   */
                  onChange={e =>
                    props?.onChangeHandler?.[0]?.({
                      e,
                      ...handlerProps,
                    })
                  }
                  /**
                   * onClick Handler. Only handlers of parameters work.
                   */
                  onClick={e =>
                    props?.onClickHandler?.[0]?.({ e, ...handlerProps })
                  }
                  /**
                   * onKeyDown Handler. Only handlers of parameters work.
                   */
                  onKeyDown={e =>
                    props?.onKeyDownHandler?.[0]?.({ e, ...handlerProps })
                  }
                  variant="outlined"
                  className={`border-solid h-[30px] text-xs text-black w-full ${
                    disabled?.[0] ? 'bg-[#F9F9F9]' : 'bg-white'
                  }`}
                  sx={{
                    borderRadius: '5px 0 0 5px',
                    '.MuiInputBase-root': {
                      height: '30px',
                      fontSize: '12px',
                      fontWeight: '500',
                      borderRadius: '5px 0 0 5px',
                      '&:not(.Mui-focused):not(.Mui-disabled):hover fieldset':
                        !formErrors?.[props?.inputName?.[0]]?.message &&
                          !formErrors?.[props?.inputName?.[1]]?.message && {
                            border: '1px solid #8E9396 !important',
                          },

                      '&.Mui-disabled fieldset': {
                        pointerEvents: 'none',
                      },
                      '&.Mui-disabled input': {
                        WebkitTextFillColor: '#222 !important',
                      },
                      '&.Mui-focused fieldset': {
                        border:
                          !formErrors?.[props?.inputName?.[0]]?.message &&
                          !formErrors?.[props?.inputName?.[1]]?.message &&
                          '1.2px solid #264B9F !important',
                        boxShadow: '0px 0px 4px 0px #8BBCE9 !important',
                      },
                      fieldset:
                        formErrors?.[inputName?.[0]]?.message ||
                        formErrors?.[inputName?.[1]]?.message
                          ? {
                              border: '1.2px solid #C24D4D !important',
                              borderRight: 'none !important',
                            }
                          : {
                              borderRight: 'none',
                              borderColor: '#D9D9D9 !important',
                            },
                    },
                  }}
                  disabled={disabled?.[0]}
                  placeholder={props?.placeholder || ''}
                  inputProps={{ maxLength: maxLength?.[0] }}
                />
              );
            }}
          />
        </div>
        {/* Select Component */}
        {/**
         * Render Auto-Complete component.
         * @control - Must-have elements for getting values from a form.
         * @name - Name of input.
         * @defaultValue - Default value of input.
         * @rules - Rules of input - Check constraints.
         * @render - Render target input component.
         * - field : Object containing field props, like name, value, onChange, onBlur.
         * - fieldState : Object containing field state, like error, invalid, etc.
         */}
        <div className="flex-1 w-auto min-w-0">
          <Controller
            control={control}
            name={props?.inputName?.[1]}
            defaultValue={defaultValue?.[1]}
            rules={defaultInputRules({
              maxLength: maxLength?.[1],
              required: required?.[1],
              ...props,
            })}
            render={({
              field: { ref, onChange, value, ...field },
              fieldState: { invalid, error },
            }) => {
              // Package of handler props
              const handlerProps = {
                onChange,
                value,
                maxLength: maxLength?.[1],
                ...field,
                ...props,
              };

              // Render Control Input Component
              return (
                <Select
                  {...field}
                  inputRef={ref}
                  error={invalid}
                  value={value}
                  renderValue={selected =>
                    props?.renderValue
                      ? selected
                      : option?.find(item => item?.value === selected)?.label
                  }
                  onChange={(e, selectProps) =>
                    props?.onChangeHandler?.[1]?.({
                      e,
                      selectProps,
                      ...handlerProps,
                    })
                  }
                  className={`border-solid border-[#D9D9D9] h-[30px] rounded-r-[5px] text-xs text-black w-full ${
                    disabled?.[1] ? 'bg-[#F9F9F9]' : 'bg-white'
                  }`}
                  defaultValue={
                    props?.defaultValue || props?.option?.[0]?.value
                  }
                  disabled={disabled?.[1]}
                  inputProps={{ maxLength: maxLength?.[1] }}
                  MenuProps={{
                    disableScrollLock: true,
                    style: { zIndex: 9999 },
                  }}
                  IconComponent={ExpandMoreIcon}
                  sx={{
                    // Select Arrow Icon
                    '.Mui-disabled': {
                      WebkitTextFillColor: '#222 !important',
                    },
                    fontWeight: '500',
                    '.MuiSvgIcon-root': {
                      fontSize: '16px',
                      color: !disabled?.[1] && '#264B9F !important',
                    },
                    '.MuiSelect-select': {
                      paddingRight: '15px',
                    },
                    '&.MuiInputBase-root': {
                      borderRadius: '0 5px 5px 0',
                      '&:not(.Mui-focused):not(.Mui-disabled):hover fieldset':
                        !formErrors?.[props?.inputName?.[1]]?.message &&
                          !formErrors?.[props?.inputName?.[0]]?.message && {
                            border: '1px solid #8E9396 !important',
                          },
                      '&.Mui-focused fieldset': {
                        border:
                          !formErrors?.[props?.inputName?.[0]]?.message &&
                          !formErrors?.[props?.inputName?.[1]]?.message &&
                          '1.2px solid #264B9F !important',
                        boxShadow: '0px 0px 4px 0px #8BBCE9 !important',
                      },
                      fieldset: (formErrors?.[inputName?.[0]]?.message ||
                        formErrors?.[inputName?.[1]]?.message) && {
                        border: '1.2px solid #C24D4D !important',
                      },
                    },
                    fieldset: {
                      borderColor: '#D9D9D9 !important',
                    },
                  }}
                >
                  {props?.option?.map((item, index) => {
                    return (
                      <MenuItem
                        value={item?.value}
                        key={String(index + 1)}
                        className="text-xs"
                      >
                        {item?.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              );
            }}
          />
        </div>
      </div>
      <FormHelperText className="text-[#ff2424] text-[11px] font-normal min-h-[11px] h-[11px] mb-[3px] leading-none">
        {formErrors?.[inputName?.[0]]?.message ||
          formErrors?.[inputName?.[1]]?.message}
      </FormHelperText>
    </StyledCommonInputWrapper>
  );
};

export default CommonTextSelect;
