import { useQuery } from '@tanstack/react-query';
import { getChassisReconcile } from '../../../../services/Acct/ChassisReconcile/Get/getChassisReconcileList';
import acctKeys from '../../keys';
import queryStringGenerator from '../../../../utils/Acct/Common/Generator/queryStringGenerator';

const useGetChassisReconcileList = ({ queryObject }) => {
  const queryString = queryStringGenerator({ queryObject });

  const {
    data: chassisReconcileList,
    isLoading,
    isFetching,
    refetch: refetchChassisList,
  } = useQuery({
    queryKey: acctKeys.chassisReconcileList({ queryString }),
    queryFn: () => getChassisReconcile({ queryString }),
    staleTime: 0,
    refetchOnWindowFocus: false,
  });

  return {
    chassisReconcileList,
    isLoading,
    isFetching,
    refetchChassisList,
  };
};

export default useGetChassisReconcileList;
