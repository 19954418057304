import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SUCCESS_ACCEPT } from '../../../constants/Common/successMessage';
import accept204 from '../../../services/EDI/Patch/accept204';
import useControlToast from '../../Common/Toast/useControlToast';
import ediKeys from '../keys';
import updateButtonDisabled from '../../../utils/Common/Handler/updateButtonDisabled';

const useAccept204Mutation = ({
  setIsAlertOpen,
  setIsSuccessAlert,
  setAlertMessage,
  div,
  id,
  from,
  to,
  sender,
  setIsDetail204ModalOpen,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  return useMutation({
    mutationFn: accept204,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ediKeys?.get204List({ div, from, to, sender }),
      });
      queryClient.refetchQueries({ queryKey: ediKeys?.get204Detail({ id }) });
      addToast({
        message: SUCCESS_ACCEPT,
      });
      setIsDetail204ModalOpen(false);
    },
    onError: error => {
      queryClient.refetchQueries({
        queryKey: ediKeys?.get204List({ div, from, to, sender }),
      });
      setIsSuccessAlert(false);
      setAlertMessage(error.message);
      setIsAlertOpen(true);
    },
  });
};

export default useAccept204Mutation;
