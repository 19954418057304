import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import bookingDefaultAPI from '../../bookingAPI';

const postBookingData = async ({ bookingForm }) => {
  try {
    const response = await bookingDefaultAPI.post('create', bookingForm, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default postBookingData;
