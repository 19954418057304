import dayjs from 'dayjs';

const isOneDayLeft = ({ date }) => {
  const dateDiff = dayjs(dayjs(date)?.format('YYYY-MM-DD')).diff(
    dayjs().format('YYYY-MM-DD'),
    'day',
  );
  if (dateDiff <= 1 && dateDiff > 0) return true;
  return false;
};

const isDatePassed = ({ date }) => {
  const dateDiff = dayjs(dayjs(date)?.format('YYYY-MM-DD')).diff(
    dayjs().format('YYYY-MM-DD'),
    'day',
  );
  if (dateDiff <= 0) return true;
  return false;
};

/**
 * @description
 * If there is one day left until LFD, RFD, CUT OFF; or today is LFD, RFD, CUT OFF date and there is no ACTUAL IN, the text should change to RED and BOLD
 * If current date is passed from LFD, RFD, CUT OFF and there is no ACTUAL IN, the text should change to PURPLE and BOLD
 *
 * @target
 * LFD            : Check Pull Out Actual In
 * RFD & CUT OFF  : Check Return Actual In
 */
export const getDateFontColor = ({ field, data }) => {
  if (['lfd', 'rfd', 'cutoff'].includes(field) && data?.[field]) {
    if (
      (field === 'lfd' && !data?.po_actual_in) ||
      ((field === 'rfd' || field === 'cutoff') && !data?.rtn_actual_in)
    ) {
      if (isOneDayLeft({ date: data?.[field] })) return 'purple !important';
      if (isDatePassed({ date: data?.[field] })) return 'red !important';
    }
  }

  return 'black !important';
};

export const getDateFontWeight = ({ field, data }) => {
  if (['lfd', 'rfd', 'cutoff'].includes(field) && data?.[field]) {
    if (
      (field === 'lfd' && !data?.po_actual_in) ||
      ((field === 'rfd' || field === 'cutoff') && !data?.rtn_actual_in)
    ) {
      if (isOneDayLeft({ date: data?.[field] })) return 'bold !important';
      if (isDatePassed({ date: data?.[field] })) return 'bold !important';
    }
  }

  return '500 !important';
};
