import React from 'react';
import LocationRowHeader from './LocationRow/LocationRowHeader';
import LocationRowContent from './LocationRow/LocationRowContent';

const PDFModalContent = ({ ...props }) => {
  const woCategory = location?.pathname?.split('/')?.[3];
  const { detailInfoData } = props;
  const { job_set: jobSet, location_set: locationSet } = detailInfoData;

  return (
    <div className="w-full h-fit flex justify-center items-center flex-col">
      <LocationRowHeader />
      {woCategory === 'export' ? (
        <>
          {locationSet?.map((location, index) => {
            return (
              <>
                <div className="flex justify-center items-center flex-col gap-[2px]">
                  {location && <LocationRowContent scheduleData={location} />}
                </div>
                {index !== locationSet?.length - 1 && (
                  <div className="h-[32px]" />
                )}
                {index === locationSet?.length - 1 && jobSet?.length > 0 && (
                  <div className="h-[32px]" />
                )}
              </>
            );
          })}
          {jobSet?.map((job, index) => {
            return (
              <>
                <div className="flex justify-center items-center flex-col gap-[2px]">
                  {job?.from_location && (
                    <LocationRowContent scheduleData={job?.from_location} />
                  )}
                  {job?.live_dilv_location && (
                    <LocationRowContent
                      scheduleData={job?.live_dilv_location}
                    />
                  )}
                  {job?.to_location && (
                    <LocationRowContent scheduleData={job?.to_location} />
                  )}
                </div>
                {index !== jobSet?.length - 1 && <div className="h-[32px]" />}
              </>
            );
          })}
        </>
      ) : (
        <>
          {jobSet?.map((job, index) => {
            return (
              <>
                <div className="flex justify-center items-center flex-col gap-[2px]">
                  {job?.from_location && (
                    <LocationRowContent scheduleData={job?.from_location} />
                  )}
                  {job?.live_dilv_location && (
                    <LocationRowContent
                      scheduleData={job?.live_dilv_location}
                    />
                  )}
                  {job?.to_location && (
                    <LocationRowContent scheduleData={job?.to_location} />
                  )}
                </div>
                {index !== jobSet?.length - 1 && <div className="h-[32px]" />}
                {index === jobSet?.length - 1 && locationSet?.length > 0 && (
                  <div className="h-[32px]" />
                )}
              </>
            );
          })}
          {locationSet?.map((location, index) => {
            return (
              <>
                <div className="flex justify-center items-center flex-col gap-[2px]">
                  {location && <LocationRowContent scheduleData={location} />}
                </div>
                {index !== locationSet?.length - 1 && (
                  <div className="h-[32px]" />
                )}
              </>
            );
          })}
        </>
      )}
    </div>
  );
};

export default PDFModalContent;
