import { DRIVER_CATEGORY } from '../../../../constants/Acct/Common/acctElement';
import updateButtonDisabled from '../../../Common/Handler/updateButtonDisabled';
import convertQBPostData from './convertQBPostData';

const handleDriverPayClick = ({
  buttonRef,
  gridRef,
  driverPayPatchMutator,
  errorModalOptions,
}) => {
  const { setIsSuccess, setErrorMessage, setIsErrorModalOpen } =
    errorModalOptions;

  const selectedRows = gridRef?.current?.api
    ?.getSelectedNodes()
    ?.filter(row => row?.displayed)
    ?.map(node => convertQBPostData(node?.data));

  const selectedDriverRows = selectedRows?.filter(row => {
    const isCompanyDriver =
      row?.category === 'DRIVER' &&
      row?.category_type === DRIVER_CATEGORY?.COMPANY;

    return isCompanyDriver;
  });

  if (selectedRows?.length !== selectedDriverRows?.length) {
    setIsSuccess(false);
    setErrorMessage('Please check only company drivers.');
    setIsErrorModalOpen(true);
    updateButtonDisabled({ ref: buttonRef, disabled: false });
    return;
  }

  if (selectedDriverRows?.length === 0) {
    setIsSuccess(false);
    setErrorMessage('A company driver was not selected.');
    setIsErrorModalOpen(true);

    updateButtonDisabled({ ref: buttonRef, disabled: false });
    return;
  }

  const groupIDList = selectedDriverRows
    ?.map(row => row?.submitData?.group_id)
    ?.filter(row => row);

  driverPayPatchMutator({ patchData: { group_id: groupIDList }, buttonRef });
};

export default handleDriverPayClick;
