import handleQueryError from '../../../utils/Common/Handler/handleQueryError';
import adminDefaultApi from '../api';

const deleteAuthList = async ({ id }) => {
  try {
    const response = await adminDefaultApi.delete(`group/${id}`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default deleteAuthList;
