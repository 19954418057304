import React, { useState } from 'react';
import { MAPPING_PAGE } from '../../../../constants/Admin/DivConf/adminDivConfElements';
import CommonButton from '../../../CommonButton/CommonButton';
import CommonAutoComplete from '../../../Inputs/CommonAutoComplete/CommonAutoComplete';
import CommonTextInput from '../../../Inputs/CommonTextInput/CommonTextInput';

const APContainer = ({
  apRate,
  apProductCodeOption,
  div,
  postProductCodeMutation,
  patchProductCodeMutation,
  deleteProductCodeMutation,
  isLoadingAPProductCode,
}) => {
  const [productCode, setProductCode] = useState({});
  const [deletedType, setDeletedType] = useState('');

  const handleSubmit = ({ type, code, buttonRef }) => {
    const mappingPage = MAPPING_PAGE[type];
    if (code) {
      if (apRate?.mapping_page === mappingPage) {
        patchProductCodeMutation({
          seq: apRate?.seq,
          productCode: code?.value,
          buttonRef,
        });
        return;
      }
      postProductCodeMutation({
        div,
        productCode: code?.value,
        mappingPage,
        buttonRef,
      });
      return;
    }
    setDeletedType(type);
    if (apRate?.mapping_page === mappingPage) {
      deleteProductCodeMutation({
        seq: apRate?.seq,
        productCode: apRate?.product_code,
        buttonRef,
      });
    }
  };

  return (
    <div className="flex mt-[67px] align-center">
      <div className="flex flex-col">
        <div className="text-[15px] font-bold">AP</div>
        <div className="text-stone-600 text-xs font-medium mt-[29px] mb-[10px]">
          AP RATE
        </div>
        <div className="mt-[-22px] mb-[-10px]">
          <CommonAutoComplete
            inputName="productCode"
            placeholder="Service Code"
            width="w-[131px]"
            color="#264B9F"
            option={apProductCodeOption}
            defaultValue={apRate?.product_code}
            onChangeHandler={({ onChange, selectProps }) => {
              setDeletedType('');
              onChange?.(selectProps?.label?.split(' / ')[0]);
              setProductCode(selectProps);
            }}
            isLoading={isLoadingAPProductCode}
          />
        </div>
      </div>
      <div className="mt-[48px] ml-[7px]">
        <CommonTextInput
          inputName="productCodeName"
          placeholder="Service Code Name"
          width="w-[188px]"
          defaultValue={
            deletedType === 'AP_RATE'
              ? ''
              : productCode?.name || apRate?.product_code_name
          }
          disabled
        />
      </div>
      <div className="w-full mt-[65px] ml-[7px]">
        <CommonButton
          category="complete"
          width="77px"
          preventDupClick
          onClick={({ buttonRef }) =>
            handleSubmit({ type: 'AP_RATE', code: productCode, buttonRef })
          }
        >
          Apply
        </CommonButton>
      </div>
    </div>
  );
};

export default APContainer;
