/**
 * @description
 * Function of generating regular options for select component.
 * @param {object} items - Array of objects.
 * @param {string} property - Target property to be used as value or label.
 */
const generateOption = ({ items, property }) => {
  const result = [];

  items?.forEach(element => {
    const option = {
      label: element?.[property] || element?.name || element,
      value: element?.[property] || element,
    };
    result.push(option);
  });
  return result;
};

/**
 * @description
 * Function of generating options for whole booking info (size, type) select component.
 * Create select option include label, value, balance, disabled for booking option.
 * Compare to optionGenerator function, just balance and disabled property is added.
 */
export const generateBookingOption = ({ items, property }) => {
  const result = [];

  items?.forEach(element => {
    const option = {
      label: element?.name || element?.[property] || element,
      value: element?.[property] || element,
      balance: element?.balance,
      disabled: element?.balance <= 0,
    };
    result.push(option);
  });
  return result;
};

/**
 * @description
 * Function of generating options for each booking info (size, type) select component.
 * Separate booking option by size and type.
 * Create Size's option and Type's option.
 * [
 *    { size: '20ST', type: 'DRY', balance: 4 },
 *    { size: '20ST', type: 'REEPER', balance: 5 },
 *    { size: '40ST', type: 'REEPER', balance: 1},
 * ]
 *
 * In above case, result will be like below.
 *
 *    < Size option >
 * [
 *    { label: '20ST', value: '20ST', balance: 9, disabled: false },
 *    { label: '40ST', value: '40ST', balance: 1, disabled: false },
 * ]
 *
 *    < Type option >
 * [
 *    { label: 'DRY', value: 'DRY', balance: 4, disabled: false },
 *    { label: 'REEPER', value: 'REEPER', balance: 6, disabled: false },
 * ]
 */
export const generateBookingEachOption = ({
  items,
  property,
  containerList,
}) => {
  const uniqueOptionKey = [...new Set(items?.map(item => item?.[property]))];

  const uniqueOption = uniqueOptionKey?.map(key => {
    const filteredItems = items?.filter(item => item?.[property] === key);

    const totalBalance = filteredItems?.reduce(
      (acc, item) =>
        acc +
        item?.balance -
        containerList?.filter(container => container?.[property] === key)
          ?.length,
      0,
    );

    return {
      label: key,
      value: key,
      balance: totalBalance,
      disabled: totalBalance <= 0,
    };
  });

  return uniqueOption;
};

/**
 * @description
 * Function generating filtered options for booking info (size, type) according to watcher.
 * If type is selected when selecting size, only the matching selection should be printed to the screen.
 */
export const generateBookingFilteredOption = ({
  watcher,
  bookingOption,
  bookingSelectOption,
  optionProperty,
  selectOptionProperty,
  oppositeOptionProperty,
}) => {
  const result = watcher
    ? bookingOption?.[optionProperty]?.filter(element =>
        bookingSelectOption
          ?.filter(
            bkSelectOption =>
              bkSelectOption?.[oppositeOptionProperty] === watcher,
          )
          ?.map(bkSelectOption => bkSelectOption?.[selectOptionProperty])
          ?.includes(element?.label),
      )
    : bookingOption?.[optionProperty];

  return result;
};

export default generateOption;
