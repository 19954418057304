import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import getAutoComplete from '../../services/Common/Common/Get/getAutoComplete';
import commonKeys from './keys';

const useGetCommonAutoComplete = ({ setErrorModalOpener }) => {
  const [autoCompleteItem, setAutoCompleteItem] = useState(null);

  const query = useQuery({
    queryKey: commonKeys.specAutoComplete({ category: null }),
    queryFn: () => getAutoComplete(),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (query?.data) {
      setAutoCompleteItem(query?.data?.data);
    }
  }, [query?.data]);

  useEffect(() => {
    if (query?.isError) {
      setErrorModalOpener(true);
    }
  }, [query?.isError]);

  return { autoCompleteItem, ...query };
};

export default useGetCommonAutoComplete;
