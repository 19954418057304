import React from 'react';
import DispatchBookingTable from '../../Common/DispatchBookingTable';
import openBCDetail from '../../../../../utils/Dispatch/Common/Handler/openBCDetail';

const WorkOrderNumberContent = ({ data, ...props }) => {
  const tableHeader = [
    {
      data: 'CATEGORY',
      header: true,
      width: 'w-[100px]',
      inputName: 'category',
    },
    { data: 'BILL TO', header: true, width: 'w-[116px]', inputName: 'billto' },
    { data: 'WO#', header: true, width: 'w-[140px]', inputName: 'wo_no' },
    { data: 'CONT#', header: true, width: 'w-[140px]', inputName: 'cont_no' },
    { data: 'CHASSIS#', header: true, width: 'w-[142px]', inputName: 'ch_no' },
    { data: 'POOL', header: true, width: 'w-[126px]', inputName: 'pool' },
    { data: 'STATUS', header: true, width: 'w-[146px]', inputName: 'status' },
  ];
  const reformedData = [
    tableHeader,
    ...data.map(item => {
      return tableHeader.map(i => {
        return {
          data: item?.[i.inputName],
          width: i?.width,
          handleClick: () =>
            openBCDetail({
              woNo: item?.wo_no,
              category: item?.category?.toLowerCase(),
            }),
        };
      });
    }),
  ];

  return (
    <div className="h-[463px] w-fit overflow-y-auto">
      <DispatchBookingTable
        width="w-[910px]"
        height="h-fit"
        data={reformedData}
      />
    </div>
  );
};

export default WorkOrderNumberContent;
