export const FLOATING_FILTER_DEFAULT_OPTION = [{ label: 'All', value: '' }];

export const generateConstantOptionToFloatingOption = objectList => {
  const option = [...FLOATING_FILTER_DEFAULT_OPTION];

  if (!objectList) {
    return option;
  }

  const floatingOptionList = objectList
    ?.filter(
      obj =>
        obj?.value !== '' &&
        obj?.value !== 'SELECT' &&
        obj?.value !== 'select' &&
        obj?.value !== 'Select' &&
        obj?.value !== 'All' &&
        obj?.value !== 'ALL',
    )
    ?.map(obj => {
      return obj?.label;
    });

  floatingOptionList?.forEach(item => {
    option?.push({ label: item, value: item });
  });

  return option;
};

export const generateObjectToOption = (objList, itemName) => {
  const option = [...FLOATING_FILTER_DEFAULT_OPTION];

  Object?.values?.(objList)?.forEach(item => {
    option?.push({
      label: item || itemName || '',
      value: item || itemName || '',
    });
  });

  return option;
};

export const generateListToOption = (itemList, itemName) => {
  const option = [...FLOATING_FILTER_DEFAULT_OPTION];
  itemList?.forEach(item => {
    option?.push({
      label: item || itemName || '',
      value: item || itemName || '',
    });
  });

  return option;
};
