import styled from 'styled-components';

const StyledTMSReportCommonDataGridContainer = styled.div`
  width: 100%;

  flex: 1;
  padding-right: 40px;
`;

export default StyledTMSReportCommonDataGridContainer;
