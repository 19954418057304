import {
  PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
  PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
} from '../../../Common/path';

const VAN_COLORS = {
  vanColor1: {
    backgroundColor: '#D7FAEE',
    color: 'black',
    icon: null,
  },
  vanColor2: {
    backgroundColor: '#95E2C8',
    color: 'black',
    icon: null,
  },
  vanColor3: {
    backgroundColor: '#65B59A',
    color: 'white',
    icon: {
      fold: PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
      unfold: PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
    },
  },
  vanColor4: {
    backgroundColor: '#427966',
    color: 'white',
    icon: {
      fold: PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
      unfold: PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
    },
  },
  vanColor5: {
    backgroundColor: '#143c2e',
    color: 'white',
    icon: {
      fold: PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
      unfold: PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
    },
  },
  grey: {
    backgroundColor: '#979797',
    color: 'white',
    icon: {
      fold: PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
      unfold: PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
    },
  },
  darkGrey: {
    backgroundColor: '#646464',
    color: 'white',
    icon: {
      fold: PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
      unfold: PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
    },
  },
  yard: {
    backgroundColor: '#FFF4CF',
    color: 'black',
    icon: null,
  },
};

export { VAN_COLORS };
