const generateStatusData = ({ rowData }) => {
  const paidData = rowData?.ap_set.filter(items => items.status === 'PAID');
  const partialData = rowData?.ap_set.filter(
    items => items.status === 'PARTIAL PAID',
  );
  const invoicedData = rowData?.ap_set.filter(
    items => items.status === 'Invoiced',
  );
  const inProcessData = rowData?.ap_set.filter(
    items => items.status === 'In Process',
  );
  const inReviewData = rowData?.ap_set.filter(
    items => items.status === 'REVIEW',
  );
  const rejectedData = rowData?.ap_set.filter(
    items => items.status === 'REJECTED',
  );

  return {
    paidData: paidData.length === 0 ? null : paidData,
    partialData: partialData.length === 0 ? null : partialData,
    invoicedData: invoicedData.length === 0 ? null : invoicedData,
    inProcessData: inProcessData.length === 0 ? null : inProcessData[0]?.data,
    inReviewData: inReviewData.length === 0 ? null : inReviewData[0]?.data,
    rejectedData: rejectedData.length === 0 ? null : rejectedData[0]?.data,
  };
};

export default generateStatusData;
