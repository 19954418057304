import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import divBasicDefaultApi from '../../divApi';

const getWeightPerZIP = async ({ div }) => {
  try {
    const response = await divBasicDefaultApi.get(`${div}/weight-per-zip`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getWeightPerZIP;
