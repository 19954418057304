export const CUSTOMER_REPORT_Y_N_FILTER = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Y',
    value: 'Y',
  },
  {
    label: 'N',
    value: 'N',
  },
];
