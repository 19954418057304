import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import StyledSearchBarWrapper from '../../../../styles/EDI/SearchBar/StyledSearchBarWrapper';
import StyledSearchBarInput from '../../../../styles/EDI/SearchBar/StyledSearchBarInput';

let searchValue = '';

const SearchBar = ({ setTarget }) => (
  <StyledSearchBarWrapper>
    <StyledSearchBarInput
      data-testid="search-bar"
      placeholder="Search"
      onChange={e => {
        searchValue = e.target?.value;
      }}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          setTarget(searchValue?.trim());
          searchValue = '';
        }
      }}
    />
    <SearchIcon
      data-testid="search-icon"
      fontSize="large"
      sx={{ fontSize: '16px', fill: '#aaa', cursor: 'pointer' }}
      onClick={() => {
        setTarget(searchValue?.trim() || ' ');
        searchValue = ' ';
      }}
    />
  </StyledSearchBarWrapper>
);

export default SearchBar;
