import emailFormatValidateHandler from '../../Common/Handler/emailFormatValidateHandler';
import generateSaveItemData from '../Generator/generateSaveItemData';

const saveItem = ({
  listName,
  email,
  setMessageOpener,
  setIsSuccess,
  setModalMessage,
  setEmailConfirmationOpener,
}) => {
  /** Validate email format */
  const isValidEmail = emailFormatValidateHandler({ email });

  if (!isValidEmail) {
    setIsSuccess(false);
    setModalMessage('Email format is invalid');
    setMessageOpener(true);
    return false;
  }
  if (!listName || listName === '') {
    setIsSuccess(false);
    setModalMessage('Please enter valid list name');
    setMessageOpener(true);
    return false;
  }
  if (!email || email === '') {
    setIsSuccess(false);
    setModalMessage('Please enter valid email');
    setMessageOpener(true);
    return false;
  }

  setIsSuccess(true);
  setEmailConfirmationOpener(prev => true);

  return true;
};

export default saveItem;
