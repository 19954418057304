import React, { useEffect, useRef, useState } from 'react';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import GoogleMapComponentJob from '../../../components/Trace/Module/GoogleMapComponentJob';
import TraceSidebarJob from '../../../components/TraceSidebarJob';
import useGetMapContent from '../../../hooks/Trace/Get/useGetMapContent';
import useGetTraceJobDetail from '../../../hooks/Trace/Get/useGetTraceJobDetail';
import useGetTraceJobList from '../../../hooks/Trace/Get/useGetTraceJobList';
import getAuthToken from '../../../services/Trace/Job/Get/getAuthToken';
import StyledTraceContainer from '../../../styles/Trace/Common/StyledTraceContainer';

const Job = () => {
  const [checkedList, setCheckedList] = useState([]);
  const [checkedDriverList, setCheckedDriverList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [seq, setSeq] = useState();
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [alertModalMessage, setAlertModalMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const mapRef = useRef(null);
  const [category, setCategory] = useState('wo');
  const [target, setTarget] = useState('');

  const { traceJobDetail, isTraceJobDetailLoading } = useGetTraceJobDetail({
    seq,
  });

  const { isLoadingJobList, traceJobList } = useGetTraceJobList({
    category,
    target,
  });

  const { mapView } = useGetMapContent({
    projectId: 'nglmobilitytest',
    authTokenFetcher: getAuthToken,
    setAlertModalOpen,
    setAlertModalMessage,
    setIsSuccess,
    mapRef,
    checkedList,
    dataList,
    checkedDriverList,
  });

  useEffect(() => {
    if (!traceJobDetail) return;
    dataList.push({
      key: traceJobDetail.data.g_vehicle_id,
      data: traceJobDetail.data,
    });
  }, [traceJobDetail]);

  return (
    <>
      <CommonErrorModal
        open={alertModalOpen}
        setIsOpen={setAlertModalOpen}
        message={alertModalMessage}
        isSuccess={isSuccess}
      />
      <CommonLoading open={isTraceJobDetailLoading || isLoadingJobList} />
      <StyledTraceContainer>
        <TraceSidebarJob
          setCheckedList={setCheckedList}
          setCheckedDriverList={setCheckedDriverList}
          setSeq={setSeq}
          isTraceJobDetailLoading={isTraceJobDetailLoading}
          traceJobList={traceJobList}
          isLoadingJobList={isLoadingJobList}
          setTarget={setTarget}
          setCategory={setCategory}
        />
        <GoogleMapComponentJob mapRef={mapRef} />
      </StyledTraceContainer>
    </>
  );
};

export default Job;
