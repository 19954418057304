import { SCAN_FILE_INPUT_NAME } from '../../../../constants/Acct/Common/staticNames';
import resetFormModal from './resetFormModal';

const uploadOtherFile = ({
  e,
  setInvoiceList,
  resetScan,
  resetVendor,
  invoiceKey,
  setInvoiceKey,
}) => {
  e.stopPropagation();
  setInvoiceList([{ key: invoiceKey, data: {} }]);
  setInvoiceKey(prev => prev + 1);
  resetScan();
  const inputElement = document.getElementsByName(SCAN_FILE_INPUT_NAME)[0];
  inputElement?.click();
  inputElement.value = null;
};

export default uploadOtherFile;
