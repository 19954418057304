const handleAddClicked = ({ e, div, postWeightPerZIPMutation }) => {
  e?.setAddDisabled(true);
  postWeightPerZIPMutation({
    div,
    zip: e.gridParam.data.zip,
    state: e.gridParam.data.state,
    weight: e.gridParam.data.weight || '100',
    setAddDisabled: e?.setAddDisabled,
  });
};

export default handleAddClicked;
