import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import getDualJob from '../../../../services/BCDetail/DetailInfo/DualMove/Get/getDualJob';
import bcDetailKeys from '../../keys';

const useGetDualJob = ({ ...props }) => {
  const { loc, messageModalController } = props;
  const { setMessageModalOpen, setMessageModalMessage } =
    messageModalController;

  const [dualJob, setDualJob] = useState({
    poJob: null,
    rtnJob: null,
  });

  const query = useQuery({
    queryKey: bcDetailKeys.dualJob({ loc }),
    queryFn: () => getDualJob({ loc }),
    refetchOnWindowFocus: false,
    enabled: Boolean(loc),
  });

  useEffect(() => {
    if (query?.data) {
      setDualJob(prev => {
        return {
          poJob: query?.data?.data?.find(item => item?.type === 'P/O') || null,
          rtnJob: query?.data?.data?.find(item => item?.type === 'RTN') || null,
        };
      });
    } else {
      setDualJob(prev => {
        return {
          poJob: null,
          rtnJob: null,
        };
      });
    }
  }, [query?.data]);

  useEffect(() => {
    if (query?.isError) {
      setMessageModalMessage(
        query?.error?.message || 'Failed to get dual job data',
      );
      setMessageModalOpen(prev => true);
    }
  }, [query?.isError]);

  return { dualJob, ...query };
};

export default useGetDualJob;
