import React from 'react';
import CommonButton from '../../../../../../CommonButton/CommonButton';
import handleWOConvert from '../../../../../../../utils/BCDetail/DetailInfo/Common/Handler/handleWOConvert';
import updateButtonDisabled from '../../../../../../../utils/Common/Handler/updateButtonDisabled';

const RollbackConfirmModalButton = ({ ...props }) => {
  const { setOpen, convertProps } = props;
  return (
    <div className="flex justify-center items-center gap-[16px]">
      <div>
        <CommonButton
          category="cancel"
          onClick={() => {
            updateButtonDisabled({
              ref: convertProps.buttonRef,
              disabled: false,
            });
            setOpen(prev => false);
          }}
        >
          Cancel
        </CommonButton>
      </div>
      <div>
        <CommonButton
          category="gotIt"
          borderColor="#556DA1"
          preventDupClick
          onClick={({ buttonRef }) => {
            handleWOConvert({ ...convertProps, buttonRef });
            updateButtonDisabled({
              ref: convertProps.buttonRef,
              disabled: false,
            });
            setOpen(prev => false);
          }}
        >
          Got it
        </CommonButton>
      </div>
    </div>
  );
};

export default RollbackConfirmModalButton;
