import {
  SUB_ORDER_TYPE_BROKERAGE,
  SUB_ORDER_TYPE_VAN,
} from '../../../constants/Order/Common/basic';

const generateFormDefaultValue = ({ ...props }) => {
  const { subCategory } = props;
  return {
    do: null,
    cf: null,
    bond_entry: '',
    billto: '',
    mbl_no: '',
    hbl_no: '',
    ref_no: '',
    purchase_no: '',
    dilv_no: '',
    ch_no: '',
    pool: '',
    ch_type: 'REGULAR',
    wms: false,
    ssl: '',
    vessel: '',
    eta: null,
    lfd: null,
    freedays: '',
    freedays_type: 'FD0001',
    remarks: '',
    ava_dsc: false,
    ava_obl: false,
    ava_ca: false,
    ava_bo: false,
    ava_cus: false,
    ava_oth: false,
    ava_na: false,
    sendto: '',
    po_loc: '',
    po_date: null,
    po_time: null,
    dilv_loc: '',
    dilv_date: null,
    dilv_time: null,
    rtn_loc: '',
    rtn_date: null,
    rtn_time: null,
    over_height: false,
    over_weight: false,
    hazmat: false,
    hot: false,
    sche_type: [SUB_ORDER_TYPE_VAN].includes(subCategory) ? 'LVAN' : 'DROP',
    other: null,
    erd: null,
    cutoff_date: null,
    cutoff_time: null,
    bk_no: '',
    /** Below is the container, chassis, trailer info */
    cont_no_1: '',
    trailer_no_1: '',
    ch_no_1: '',
    pool_1: '',
    size_1: [SUB_ORDER_TYPE_VAN, SUB_ORDER_TYPE_BROKERAGE].includes(subCategory)
      ? '53'
      : '40 HC',
    ch_type_1: 'REGULAR',
    type_1: 'DRY',
    wgt_1: '',
    qty_1: '',
    unit_1: '',
    wms_1: false,
    seal_1: '',
    commodity_1: '',
    empty_load_status_1: '',
  };
};

export default generateFormDefaultValue;
