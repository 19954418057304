import tmsReportAPI from '../../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

const getStatisticsList = async ({ ...props }) => {
  try {
    const response = await tmsReportAPI.get(`/statistics`, {
      params: { ...props },
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getStatisticsList;
