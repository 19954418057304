import React from 'react';
import StyledScheduleAPModalTableContentHeaderWrapper from '../../../../../../../../../styles/BCDetail/DetailInfo/Schedule/StyledScheduleAPModalTableContentHeaderWrapper';
import StyledScheduleAPModalTableContentHeaderDataWrapper from '../../../../../../../../../styles/BCDetail/DetailInfo/Schedule/StyledScheduleAPModalTableContentHeaderDataWrapper';
import ScheduleAPModalTableContentHeaderCell from '../../../../ScheduleAPModal/ScheduleAPModalTableContentHeaderCell';

const DualMoveAPModalExistAPInfoHeader = ({ apData }) => {
  return (
    <div className="flex gap-[12px] flex-col">
      <div className="w-full h-[16px] min-h-[16px] text-[14px] font-bold flex items-center">
        Exist AP - {apData?.ap_exists?.length || 0} exist(s)
      </div>
      <StyledScheduleAPModalTableContentHeaderWrapper>
        <StyledScheduleAPModalTableContentHeaderDataWrapper
          customStyle={{
            width: 'fit-content',
          }}
        >
          <ScheduleAPModalTableContentHeaderCell
            customStyle={{
              width: '154px',
              borderRight: '1px solid #eeeeee',
            }}
          >
            Service Code
          </ScheduleAPModalTableContentHeaderCell>
          <ScheduleAPModalTableContentHeaderCell
            customStyle={{
              width: '227px',
              borderRight: '1px solid #eeeeee',
            }}
          >
            Service Code Name
          </ScheduleAPModalTableContentHeaderCell>
          <ScheduleAPModalTableContentHeaderCell
            customStyle={{
              width: '119px',
            }}
          >
            AMT ($)
          </ScheduleAPModalTableContentHeaderCell>
        </StyledScheduleAPModalTableContentHeaderDataWrapper>
      </StyledScheduleAPModalTableContentHeaderWrapper>
    </div>
  );
};

export default DualMoveAPModalExistAPInfoHeader;
