import React from 'react';
import dayjs from 'dayjs';
import StyledWeeklyItemContentWrapper from '../../../../styles/TmsReport/Schedules/StyledWeeklyItemContentWrapper';
import StyledWeeklyItemContentText from '../../../../styles/TmsReport/Schedules/StyledWeeklyItemContentText';
import StyledWeeklyItemLeftBorder from '../../../../styles/TmsReport/Schedules/StyledWeeklyItemLeftBorder';
import StyledWeeklyItemContentTextRow from '../../../../styles/TmsReport/Schedules/StyledWeeklyItemContentTextRow';
import StyledWeeklyItemWrapper from '../../../../styles/TmsReport/Schedules/StyledWeeklyItemWrapper';
import handleWOClick from '../../../../utils/TmsReport/Common/handleWOClick';
import WEEKLY_THEME from '../../../../constants/TmsReport/Schedules/weeklyTheme';
import {
  WO_CATEGORY_CODE,
  WO_CATEGORY_NAME,
} from '../../../../constants/TmsReport/Common';
import { WO_CATEGORY_SHORT_NAME } from '../../../../constants/TmsReport/Schedules';

const WeeklyTableItem = ({ pageType, wo }) => {
  /** Color */
  const colorTheme = WEEKLY_THEME?.[wo?.color];

  const woCategory = WO_CATEGORY_CODE?.[wo?.category];
  /** Schedule */
  const scheduleType = wo?.sche_type === 'LVAN' ? 'VAN' : wo?.sche_type || '';
  const scheduleTime = wo?.[`${pageType}_schedule`]
    ? `, ${dayjs?.(wo?.[`${pageType}_schedule`])?.format('HH:mm')}`
    : '';

  /** Equipment */
  const equipNo = [
    WO_CATEGORY_NAME?.VAN,
    WO_CATEGORY_NAME?.BROKERAGE,
  ]?.includes(wo?.category)
    ? wo.trailer_no
    : wo.cont_no;

  return (
    <StyledWeeklyItemWrapper
      onClick={() => {
        handleWOClick(wo);
      }}
    >
      <StyledWeeklyItemLeftBorder $backgroundColor={colorTheme?.borderColor} />
      <StyledWeeklyItemContentWrapper
        $backgroundColor={colorTheme?.backgroundColor}
      >
        <StyledWeeklyItemContentText $fontWeight={700}>
          {`${WO_CATEGORY_SHORT_NAME?.[woCategory] || woCategory}
          ${scheduleTime} ${scheduleType}`}
        </StyledWeeklyItemContentText>
        <StyledWeeklyItemContentText>
          <StyledWeeklyItemContentTextRow>
            {equipNo}
          </StyledWeeklyItemContentTextRow>
          <StyledWeeklyItemContentTextRow>
            {wo?.ch_no}
          </StyledWeeklyItemContentTextRow>
          <StyledWeeklyItemContentTextRow>
            {wo?.[`${pageType}_loc`]}
          </StyledWeeklyItemContentTextRow>
          <StyledWeeklyItemContentTextRow>
            {`${wo?.[`${pageType}_city`] || ''}
             ${wo?.[`${pageType}_state`] || ''}
             ${wo?.[`${pageType}_zip`] || ''}`}
          </StyledWeeklyItemContentTextRow>
          <StyledWeeklyItemContentTextRow>
            {`${wo?.[`${pageType}_driver`] || ''}
             ${wo?.[`${pageType}_driver_name`] || ''}`}
          </StyledWeeklyItemContentTextRow>
        </StyledWeeklyItemContentText>
      </StyledWeeklyItemContentWrapper>
    </StyledWeeklyItemWrapper>
  );
};

export default WeeklyTableItem;
