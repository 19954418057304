import React, { useState, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import CustomColumnItemMenuIcon from '../CustomColumnItemMenuIcon';
import ExpandGroupIcon from '../ExpandGroupIcon';
import SelectedItem from '../SelectedItem';
import StyledGroupDragBoxContainer from '../../../../styles/Dispatch/Common/StyledGroupDragBoxContainer';
import itemTypes from '../../../../constants/Common/CustomColumnModal/itemTypes';
import iconColorGenerator from '../../../../utils/Common/CustomColumnModal/Generator/iconColorGenerator';

const SelectedColumnGroup = ({
  id,
  groupItem,
  findBox,
  moveBox,
  checkOrderList,
  ...props
}) => {
  const originIdx = findBox(id, checkOrderList)?.index;

  const [selected, setSelected] = useState(false);
  const [expanded, setExpanded] = useState(true);

  const [bgColor, setBgColor] = useState(
    groupItem?.theme?.backgroundColor || '#FFFFFF',
  );
  const [fontColor, setFontColor] = useState(
    groupItem?.theme?.color || '#222222',
  );

  useEffect(() => {
    if (groupItem?.theme?.backgroundColor) {
      setBgColor(groupItem?.theme?.backgroundColor);
    }

    if (groupItem?.theme?.color) {
      setFontColor(prev =>
        groupItem?.theme?.color === 'black'
          ? '#222222'
          : groupItem?.theme?.color,
      );
    }
  }, [groupItem?.theme]);

  const [{ isDragging }, dragRef] = useDrag(
    () => ({
      type: itemTypes.ORDERBOX,
      item: { id, originIdx, isGroup: false },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id, originIdx, moveBox],
  );

  useEffect(() => {
    setSelected(isDragging);
  }, [isDragging]);

  const [, dropTop] = useDrop(
    () => ({
      accept: itemTypes.ORDERBOX,
      hover({ id: draggedID, isGroup }) {
        if (draggedID !== id && !isGroup) {
          const { index: overIndex } = findBox(id, checkOrderList);
          moveBox(draggedID, overIndex, checkOrderList);
        }
      },
    }),
    [findBox, moveBox],
  );

  const [, dropBottom] = useDrop(
    () => ({
      accept: itemTypes.ORDERBOX,
      hover({ id: draggedID, isGroup }) {
        if (draggedID !== id && !isGroup) {
          const { index: overIndex } = findBox(id, checkOrderList);
          moveBox(draggedID, overIndex + 1, checkOrderList);
        }
      },
    }),
    [findBox, moveBox],
  );

  return (
    <StyledGroupDragBoxContainer
      ref={dragRef}
      checked={groupItem?.checked}
      selected={selected}
      expanded={expanded}
      bgColor={bgColor}
      fontColor={fontColor}
      onClick={() => {
        setExpanded(prev => !prev);
      }}
    >
      <div className="header">
        <div className="flex gap-x-[7px] flex items-center">
          <ExpandGroupIcon expanded={expanded} iconColor={fontColor} />
          <span className="header-label">{groupItem.label}</span>
        </div>
        <div>
          <CustomColumnItemMenuIcon
            iconColor={iconColorGenerator({ selected, iconColor: fontColor })}
          />
        </div>
      </div>
      {expanded && (
        <div className="child-box">
          {groupItem.children?.map(item => (
            <SelectedItem
              key={`Box_${groupItem.value}_${item.value}`}
              id={item.id}
              item={item}
              findBox={findBox}
              moveBox={moveBox}
              iconColor={fontColor}
              groupID={id}
              groupList={groupItem.children}
            />
          ))}
        </div>
      )}
      <div ref={dropTop} className="absolute top-0 left-0 h-[12px] w-[208px]" />
      <div
        ref={dropBottom}
        className="absolute bottom-0 left-0 h-[12px] w-[208px]"
      />
    </StyledGroupDragBoxContainer>
  );
};

export default SelectedColumnGroup;
