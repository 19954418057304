import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import bcDetailDefaultAPI from '../../bcDetailAPI';

const postAssignDriverAPInput = async ({ woNo, seq, driver, apData }) => {
  try {
    const response = await bcDetailDefaultAPI.post(
      `${woNo}/assign-driver/ap-input/${seq}`,
      {
        driver,
        ap_data: apData,
      },
      {
        params: { page: 'dispatch' },
      },
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default postAssignDriverAPInput;
