import React, { useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import CommonButton from '../../../../CommonButton/CommonButton';
import CommonCheckbox from '../../../../Inputs/CommonCheckbox/CommonCheckbox';
import CommonFile from '../../../../Inputs/CommonFile/CommonFile';
import CommonRowHeader from '../../../../SecondInputs/CommonRowHeader.jsx/CommonRowHeader';
import StyledDisabledField from '../../../../../styles/BCDetail/Document/StyledDisabledField';
import generateDate from '../../../../../utils/BCDetail/Document/Generator/generateDate';
import handlePDFClick from '../../../../../utils/BCDetail/Document/Handler/handlePDFClick';
import handleRowSave from '../../../../../utils/BCDetail/Document/Handler/handleRowSave';
import handleRowDisable from '../../../../../utils/BCDetail/Document/Handler/handleRowDisable';

const DocumentRow = ({
  setDelType,
  row,
  preset,
  woNo,
  id,
  isEditable,
  ...props
}) => {
  const [fileURL, setFileURL] = useState(null);
  const { setValue, handleSubmit } = useFormContext();
  const buttonRef = useRef(null);

  const type = row.type;
  const isDisableRow = handleRowDisable({ woNo, type });
  const watchFile = useWatch({ name: `file.${id}.${type}` });

  useEffect(() => {
    setValue(`${id}.type`, row.type);
    setValue(`${id}.file_url`, row.file_url);
    setValue(`${id}.updated_by`, row.updated_by);
    setValue(`${id}.updated_date`, row.updated_date);
    setValue(`file.${id}.${type}`, undefined);
    setFileURL(row.file_url);
  }, [row]);

  useEffect(() => {
    if (watchFile) {
      setFileURL(URL.createObjectURL(watchFile));
    } else if (watchFile === null) {
      setValue(`${id}.file_url`, '');
      setFileURL('');
    }
  }, [watchFile]);

  return (
    <form
      onSubmit={handleSubmit((submitData, e) =>
        handleRowSave({
          submitData,
          type: e?.target?.name,
          row,
          id,
          woNo,
          buttonRef,
          ...props,
        }),
      )}
      name={row.type}
    >
      <div className="flex items-center gap-x-[2.5px] mb-[2px]">
        {/* DATE */}
        <div className="w-[174px] h-[30px]">
          <CommonRowHeader
            content={generateDate(row?.updated_date, 'MM/DD/YY')}
            isMinus={!preset}
            onMinusClick={() => {
              setDelType(row.type);
            }}
            disabled={[true, false]}
            submit={[false, false]}
            testId={['', 'delete-row']}
            disabledTheme={isDisableRow}
          />
        </div>
        {/* TIME */}
        <div className="w-[155px] h-[30px]">
          <StyledDisabledField>
            {generateDate(row?.updated_date, 'HH:mm')}
          </StyledDisabledField>
        </div>
        {/* DOCUMENT */}
        <div className="w-[771px] h-[30px]">
          <StyledDisabledField
            className={`justify-start pl-[15px] ${
              !isDisableRow && 'bg-[#F2F4F8]'
            }`}
          >
            {row.type}
          </StyledDisabledField>
        </div>
        {/* UPDATED BY */}
        <div className="w-[155px] h-[30px]">
          <StyledDisabledField>{row?.updated_by}</StyledDisabledField>
        </div>
        {/* CK */}
        <div className="w-[92px] h-[30px] bg-[#DDE2EC] shadow-[0_0.5px_1px_rgba(0,0,0,0.26)]">
          <CommonCheckbox
            inputName={`${id}.checked`}
            labelVisible={false}
            options={[
              {
                defaultValue: row.checked,
                inputName: `${id}.checked`,
              },
            ]}
            onChangeHandler={({ e, onChange }) => {
              onChange(e?.target?.checked);
            }}
            disabled={!isEditable || isDisableRow}
          />
        </div>
        {/* BROWSE */}
        <div className="w-[202px] h-[30px]">
          <CommonFile
            inputName={`file.${id}.${type}`}
            defaultValue={[row?.file_url?.split('/').pop(), '']}
            labelVisible={false}
            disabled={!isEditable || isDisableRow}
          />
        </div>
        {/* PDF */}
        <div className="w-[108px] h-[30px]">
          {fileURL && !isDisableRow ? (
            <CommonButton
              onClick={({ e }) => handlePDFClick(e, fileURL)}
              category="pdf"
              className="w-full h-full border-[#EAC5C5] hover:border-[#C24D4D] flex items-center "
            >
              <div className="pt-[1px] pr-[4px] w-full">PDF</div>
            </CommonButton>
          ) : (
            <StyledDisabledField className="bg-[#F8F2F2]" />
          )}
        </div>
        {/* SAVE */}
        <div className="w-[108px] h-[30px]">
          <CommonButton
            ref={buttonRef}
            category="save"
            type="submit"
            className={`w-full h-full ${
              (!isEditable || isDisableRow) && ' border-[#DADADA] bg-[#DADADA]'
            }`}
            disabled={!isEditable || isDisableRow}
          >
            <div className="pt-[1px] pr-[4px] w-full">SAVE</div>
          </CommonButton>
        </div>
      </div>
    </form>
  );
};

export default DocumentRow;
