import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MainLayOut from '../../components/Layout/MainLayOut';
import SubTitle from '../../components/SubTitle';
import LabsInput from './Input/LabsInput';
import LabsPageWrapper from '../../styles/Labs/Common/LabsPageWrapper';
import LabsActive from './Active/LabsActive';
import LabsButton from './Button/LabsButton';
import LabsGridMUI from './GridMUI/LabsGridMUI';
import LabsGridReact from './GridReact/LabsGridReact';
import SecondInput from './Input2/SecondInput';
import LabsGridAG from './GridAG/LabsGridAG';

const Labs = () => {
  const tabsData = [
    {
      label: 'Input',
      to: '/labs/input',
      pathValue: 0,
    },
    {
      label: 'Input-2',
      to: '/labs/input-2',
      pathValue: 1,
    },
    {
      label: 'Button',
      to: '/labs/button',
      pathValue: 2,
    },
    {
      label: 'Active',
      to: '/labs/active',
      pathValue: 3,
    },
    {
      label: 'Grid - MUI',
      to: '/labs/grid-mui',
      pathValue: 4,
    },
    {
      label: 'Grid - REACT',
      to: '/labs/grid-react',
      pathValue: 5,
    },
    {
      label: 'Grid - AG',
      to: '/labs/grid-ag',
      pathValue: 6,
    },
  ];

  return (
    <MainLayOut>
      <SubTitle tabsData={tabsData} MainTitle="LABS" />
      <LabsPageWrapper>
        <Routes>
          <Route path="" element={<LabsInput />} />
          <Route path="input" element={<LabsInput />} />
          <Route path="input-2" element={<SecondInput />} />
          <Route path="button" element={<LabsButton />} />
          <Route path="active" element={<LabsActive />} />
          <Route path="grid-mui" element={<LabsGridMUI />} />
          <Route path="grid-react" element={<LabsGridReact />} />
          <Route path="grid-ag" element={<LabsGridAG />} />
        </Routes>
      </LabsPageWrapper>
    </MainLayOut>
  );
};

export default Labs;
