import React from 'react';
import ScheduleTrackingModalContentHeader from '../ScheduleTrackingModalContentHeader';
import useGetMapContent from '../../../../../../../hooks/Common/Tracking/useGetMapContent';
import getAuthToken from '../../../../../../../services/Common/Tracking/Get/getAuthToken';
import ScheduleTrackingModalMapContent from '../ScheduleTrackingModalMapContent';

const ScheduleTrackingModalContent = ({ ...props }) => {
  /** Get props data */
  const { trackingInfo } = props;
  /** Setter */
  const {
    setOpen: setTrackingModalOpen,
    setMessageModalOpener,
    setMessageModalMessage,
  } = props;
  /** Get WO from URL */
  const woNo =
    document.URL?.split('/')?.[document?.URL?.split('/')?.length - 1];

  /** Custom hook to get map content */
  const { initMap, locationProvider, mapView } = useGetMapContent({
    projectId: 'nglmobilitytest',
    tripId: trackingInfo?.tracking_id,
    elementId: 'map_canvas',
    authTokenFetcher: getAuthToken,
    authTokenFetcherParam: {
      woNo,
    },
    setMessageModalOpener,
    setTrackingModalOpen,
    setMessageModalMessage,
  });

  return (
    <div className="flex gap-[8px] flex-col">
      <ScheduleTrackingModalContentHeader trackingInfo={trackingInfo} />
      <ScheduleTrackingModalMapContent trackingInfo={trackingInfo} />
    </div>
  );
};

export default ScheduleTrackingModalContent;
