const openBCDetail = e => {
  let categoryCode;
  const woNo = e?.gridParam?.data?.wo_no;
  const category = woNo?.slice(1, 2);
  if (e?.gridParam?.colDef?.headerName !== '204 File') {
    if (category === 'M') {
      categoryCode = 'import';
    } else if (category === 'X') {
      categoryCode = 'export';
    } else if (category === 'V') {
      categoryCode = 'van';
    } else if (category === 'B') {
      categoryCode = 'brokerage';
    } else if (category === 'C') {
      categoryCode = 'bare-chassis';
    }
    const bcDetailTab = window.open(
      `/bc-detail/detail-info/${categoryCode}/${woNo}`,
      'bc-detail-tab',
    );
  }
};

export default openBCDetail;
