import cellRendererGenerator from '../../../utils/Common/Datagrid/Generator/cellRendererGenerator';
import { CELL_TYPE_TEXT } from '../../Common/DataGrid/cellType';

const NUMBER_FIELD_DEF = {
  cellRendererSelector: gridParam =>
    cellRendererGenerator({
      gridParam,
      cellType: CELL_TYPE_TEXT,
      customStyle: {
        textAlign: 'right',
      },
    }),
};
export default NUMBER_FIELD_DEF;
