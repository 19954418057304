const convertQBPostData = data => {
  const qbData = { ...data };
  let submitData = {};

  if (qbData?.type === 'AP') {
    const categoryType = data?.category?.toLowerCase();
    submitData = {
      [categoryType]: qbData?.business_id,
      wo_no: qbData?.wo_no,
      group_id: Number(qbData?.group),
    };
  }
  if (qbData?.type === 'AR') {
    submitData = {
      inv_no: qbData?.inv_no,
    };
  }

  return { ...qbData, submitData };
};

export default convertQBPostData;
