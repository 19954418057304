import ocrDefaultAPI from '../../../Order/ocrAPI';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

const postScanChassis = async ({ file }) => {
  try {
    const response = await ocrDefaultAPI.post('chassis', { file });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default postScanChassis;
