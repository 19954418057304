import { useMutation } from '@tanstack/react-query';
import { SUCCESS_UPDATE } from '../../../constants/Common/successMessage';
import patchDemurrageList from '../../../services/TmsReport/Demurrage/Patch/patchDemurrageList';
import updateButtonDisabled from '../../../utils/Common/Handler/updateButtonDisabled';
import updateGridData from '../../../utils/Common/Handler/updateGridData';
import useControlToast from '../../Common/Toast/useControlToast';

const usePatchDemurrageMutation = ({
  setIsMessageOpen,
  setMessage,
  gridRef,
}) => {
  const { addToast } = useControlToast();

  return useMutation({
    mutationFn: patchDemurrageList,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: (res, variables) => {
      variables.body.wo_no = variables.wo_no;
      updateGridData({
        gridRef,
        newData: variables.body,
        isUpdate: true,
        key: 'wo_no',
      });
      addToast({
        message: SUCCESS_UPDATE,
      });
    },
    onError: error => {
      setMessage(error.message);
      setIsMessageOpen(true);
    },
  });
};

export default usePatchDemurrageMutation;
