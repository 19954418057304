import React from 'react';
import { DECLINE_CODE_OPTIONS } from '../../../../../constants/EDI/options';
import CommonSelect from '../../../../Inputs/CommonSelect/CommonSelect';
import CommonTextarea from '../../../../Inputs/CommonTextarea/CommonTextarea';

const ModalContent = () => {
  return (
    <div className="flex flex-col w-[300px] h-[174px] ">
      <div className="flex justify-between items-center bg-[#F9F9F9] h-[54px] pl-[12px] pr-[7px] mb-[10px] border border-zinc-100">
        <div className="text-stone-600 text-xs font-normal">DECLINE CODE</div>
        <div className="mt-[4px]">
          <CommonSelect
            inputName="decline_code"
            width="w-[141px]"
            option={DECLINE_CODE_OPTIONS}
            required
            onChangeHandler={({ e, onChange }) => {
              onChange?.(e?.target?.value);
            }}
          />
        </div>
      </div>
      <div>
        <CommonTextarea
          inputName="decline_reason"
          width="w-full"
          height="h-[110px]"
          placeholder="Decline Reason"
          labelVisible={false}
          required
          onChangeHandler={({ e, onChange }) => {
            onChange(e?.target?.value);
          }}
        />
      </div>
    </div>
  );
};

export default ModalContent;
