import React from 'react';
import { FormProvider } from 'react-hook-form';
import CommonCheckbox from '../../../Inputs/CommonCheckbox/CommonCheckbox';
import { STATUS_CHECKBOX_OPTIONS } from '../../../../constants/TmsReport/EarlyWarning/inputOption';

const SearchStatusCheckBox = ({ searchController, setQueryObject }) => {
  return (
    <FormProvider {...searchController}>
      <form>
        <div className="w-[200px]">
          <CommonCheckbox
            options={STATUS_CHECKBOX_OPTIONS}
            alignCenter
            helpTextVisible={false}
            onChangeHandler={({ e, onChange }) => {
              onChange(e?.target?.checked);
              setQueryObject(prev => ({
                ...prev,
                [e.target.name]: e?.target?.checked,
              }));
            }}
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default SearchStatusCheckBox;
