import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import getEarlyWarningList from '../../../../services/Admin/EarlyWarning/Get/getEarlyWarningList';
import adminKeys from '../../keys';

const useGetEarlyWarningList = ({
  division,
  setIsOpenErrorModal,
  setErrorMessage,
  setIsSuccess,
}) => {
  const { data, isLoading, isFetching, isError, error } = useQuery({
    queryKey: adminKeys?.earlyWarningList({ division }),
    queryFn: () => getEarlyWarningList({ division }),
    staleTime: 0,
    refetchOnWindowFocus: false,
  });

  const [earlyWarningList, setEarlyWarningList] = useState([]);

  useEffect(() => {
    setEarlyWarningList(data?.data);
  }, [data]);

  useEffect(() => {
    if (isError) {
      setIsSuccess(false);
      setIsOpenErrorModal(true);
      setErrorMessage(error?.message);
    }
  }, [isError]);

  return {
    earlyWarningList,
    isLoading,
    isFetching,
  };
};

export default useGetEarlyWarningList;
