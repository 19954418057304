import React, { useEffect, useState } from 'react';
import { FormHelperText, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import defaultInputRules from '../../../utils/Common/Input/defaultInputRules';

const CommonModalTextInput = ({ ...props }) => {
  /**
   * @description
   * Get react-hook-form object from useFormContext.
   * @control Must-have elements for getting values from a form.
   * @unregister Allows to unregister a single input or an array of inputs.
   * @setValue Setter value of input.
   * @formState Object containing information about the form.
   */
  const {
    control,
    unregister,
    setValue,
    formState: { errors: formErrors },
  } = useFormContext();

  /**
   * @textCenter {string} - Text align of input.
   * @maxLength {number} - Max length of input.
   * @label {string} - Label of input.
   * @backgroundColor {string} - Background color of input.
   * @textColor {string} - Text color of input.
   * @textAlign {string} - Text align of input.
   * @defaultValue {any} - Default value of input.
   * @disabled {boolean} - Disable input.
   * @required {boolean} - Required input.
   */

  const [textCenter, setTextCenter] = useState(props?.textCenter || false);
  // Set default max length of input as 20.
  const [maxLength, setMaxLength] = useState(props?.maxLength || 20);

  const [label, setLabel] = useState(props?.label);

  const [backgroundColor, setBackgroundColor] = useState(
    props?.backgroundColor || '#FFF',
  );

  const [textColor, setTextColor] = useState(props?.textColor || '#666');

  // Set default input value as empty string ('').
  const [defaultValue, setDefaultValue] = useState(props?.defaultValue || '');
  useEffect(() => {
    /**
     * If default value changed, component default value would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.defaultValue !== undefined && props?.defaultValue !== null) {
      setDefaultValue(props?.defaultValue);
      setValue(props?.inputName, props?.defaultValue);
    }
  }, [props?.defaultValue]);

  // Set default disabled as false
  const [disabled, setDisabled] = useState(props?.disabled || false);
  useEffect(() => {
    /**
     * If default disabled value changed, component disabled value would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.disabled !== undefined && props?.disabled !== null) {
      setDisabled(props?.disabled);
    }
  }, [props?.disabled]);

  // Set default required as false
  const [required, setRequired] = useState(props?.required || false);
  useEffect(() => {
    /**
     * If default required value changed, component required value would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.required !== undefined && props?.required !== null) {
      setRequired(props?.required);
    }
  }, [props?.required]);

  useEffect(() => {
    /**
     * Unregister input when component unmount.
     * Unregister single input only.
     */
    return () => unregister(props?.inputName);
  }, []);

  /**
   * @description
   * Render input component.
   * @control - Must-have elements for getting values from a form.
   * @name - Name of input.
   * @defaultValue - Default value of input.
   * @rules - Rules of input - Check constraints.
   * @render - Render target input component.
   * - field : Object containing field props, like name, value, onChange, onBlur.
   * - fieldState : Object containing field state, like error, invalid, etc.
   */
  return (
    <Controller
      control={control}
      name={props?.inputName}
      defaultValue={defaultValue}
      rules={defaultInputRules({ ...props })}
      render={({
        field: { ref, onChange, value, ...field },
        fieldState: { invalid, error },
      }) => {
        // Package of handler props
        const handlerProps = {
          onChange,
          value,
          maxLength,
          ...field,
          ...props,
        };

        // Render Control Input Component
        return (
          <div>
            <div className={`${props?.width ? `${props?.width}` : 'w-full'}`}>
              <TextField
                {...field}
                variant="outlined"
                inputRef={ref}
                error={invalid}
                value={value}
                onChange={e =>
                  props?.onChangeHandler?.({
                    e,
                    ...handlerProps,
                  })
                }
                /**
                 * onClick Handler. Only handlers of parameters work.
                 * To change value, should use Controller field's onChange changer.
                 * ex)
                 * (tempValue) => {
                 *    onChange(tempValue);
                 * }
                 */
                onClick={e => props?.onClickHandler?.({ e, ...handlerProps })}
                /**
                 * onKeyDown Handler. Only handlers of parameters work.
                 * To change value, should use Controller field's onChange changer.
                 * ex)
                 * (tempValue) => {
                 *    onChange(tempValue);
                 * }
                 */
                onKeyDown={e =>
                  props?.onKeyDownHandler?.({ e, ...handlerProps })
                }
                className={`border-solid border-[#D9D9D9] h-full text-xs text-black w-full ${
                  disabled ? 'bg-[#DDE2EC]' : 'bg-white'
                }
                  hover:border-[#8E9396]`}
                sx={{
                  input: {
                    textAlign: `${textCenter ? 'center' : ''}`,
                    color: `${textColor}`,
                    zIndex: '999',
                    ':focus': {
                      color: '#000 !important',
                    },
                  },
                  '.MuiInputBase-root': {
                    height: '30px',
                    fontSize: '12px',
                    fontWeight: '500',

                    '&.Mui-focused fieldset': {
                      border: '1.2px solid #264B9F !important',
                      boxShadow: '0px 0px 4px #8BBCE9 !important',
                      backgroundColor: '#fff !important',
                    },
                    '&.Mui-error fieldset': {
                      border: '1.2px solid #C24D4D !important',
                      backgroundColor: '#fff !important',
                    },
                    '&:not(.Mui-focused):not(.Mui-disabled):hover fieldset':
                      !formErrors?.[props?.inputName]?.message && {
                        border: '1px solid #8E9396 !important',
                      },
                    '&.Mui-disabled fieldset': {
                      backgroundColor: 'unset !important',
                    },
                  },
                  fieldset: {
                    border: 'none',
                    borderRadius: '0px',
                    backgroundColor: `${backgroundColor}`,
                    boxShadow: '0px 0.5px 1px rgba(0, 0, 0, 0.25)',
                  },
                }}
                disabled={disabled}
                placeholder={props?.placeholder || ''}
                // required={required}
                inputProps={{ maxLength }}
              />
            </div>
          </div>
        );
      }}
    />
  );
};

export default CommonModalTextInput;
