import React, { useEffect, useState } from 'react';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import CommonMessageModal from '../../../../../CommonMessageModal/CommonMessageModal';
import ScheduleAPModalContent from './ScheduleAPModalContent';
import bcDetailKeys from '../../../../../../hooks/BCDetail/keys';
import ScheduleAPModalButtonContent from './ScheduleAPModalButtonContent';

const ScheduleAPModal = ({ ...props }) => {
  /** Query Client */
  const queryClient = useQueryClient();

  /** Modal Opener & Setter */
  const { apModalOpener, setAPModalOpener } = props;

  /** API State */
  const { getAPIsFetching, getAPIsError } = props;

  /** Data Setter */
  const {
    setSelectedScheduleSeq,
    setSelectedDriverData,
    setMessageModalOpener,
    setMessageModalMessage,
    setLoadingOpener,
  } = props;

  /** Data */
  const { apInputData, selectedScheduleSeq } = props;

  /** AP Table content. Max 5 contents */
  const [apTableContent, setAPTableContent] = useState([
    {
      productCode: '',
      productCodeName: '',
      amount: 0,
    },
  ]);
  useEffect(() => {
    if (
      apInputData?.ap_recommendations &&
      apInputData?.ap_recommendations?.length > 0
    ) {
      setAPTableContent(prev => [
        ...apInputData?.ap_recommendations?.map(recommendation => {
          return {
            productCode: recommendation?.code?.product_code,
            productCodeName: recommendation?.code?.product_code_name,
            amount: recommendation?.amt,
          };
        }),
        ...prev,
      ]);
    }
  }, [apInputData?.ap_recommendations]);

  return (
    <CommonMessageModal
      open={apModalOpener && !getAPIsFetching && !getAPIsError}
      setOpen={setAPModalOpener}
      onCloseClick={() => {
        queryClient?.removeQueries({
          queryKey: bcDetailKeys?.driverListDefault(),
        });
        queryClient?.removeQueries({
          queryKey: bcDetailKeys?.apInputDefault(),
        });
        setSelectedScheduleSeq(null);
        setSelectedDriverData(null);
      }}
      width="602px"
      header="Driver AP Input"
      content={
        <ScheduleAPModalContent
          apInputData={apInputData}
          apTableContent={apTableContent}
          setAPTableContent={setAPTableContent}
        />
      }
      buttonContent={
        <ScheduleAPModalButtonContent
          apInputData={apInputData}
          selectedDriver={apInputData?.driver}
          apTableContent={apTableContent}
          setAPModalOpener={setAPModalOpener}
          setSelectedScheduleSeq={setSelectedScheduleSeq}
          setSelectedDriverData={setSelectedDriverData}
          selectedScheduleSeq={selectedScheduleSeq}
          setMessageModalOpener={setMessageModalOpener}
          setMessageModalMessage={setMessageModalMessage}
          setLoadingOpener={setLoadingOpener}
        />
      }
    />
  );
};

export default ScheduleAPModal;
