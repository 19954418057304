import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import getUserColumn from '../../../../services/Dispatch2/Common/Get/getUserColumn';
import dispatchKeys from '../../keys';

const useGetUserColumn = ({ category, isYard, status, setColumnList }) => {
  const { data, isLoading, isFetching } = useQuery({
    queryKey: dispatchKeys?.userColumn({ category, isYard, status }),
    queryFn: () => getUserColumn({ category, isYard, status }),
    refetchOnWindowFocus: false,
    enabled: status !== 'BKG',
  });

  useEffect(() => {
    if (data?.data) {
      setColumnList(data?.data);
    }
  }, [data, setColumnList]);

  return {
    isLoadingUserColumn: isLoading || isFetching,
  };
};

export default useGetUserColumn;
