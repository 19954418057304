import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { generateDivisionCheckboxOption } from '../../../../utils/Admin/User/Generator/optionGenerator';

const useControlDivisionOptions = ({
  getValues,
  setValue,
  divList,
  control,
}) => {
  const watchDivList = useWatch({
    control,
    name: divList?.map(div => div?.div),
  });

  const [isChangeDivision, setIsChangeDivision] = useState(false);
  const [defaultDivOptionList, setDefaultDivOptionList] = useState([]);
  const [divCheckboxOption, setDivCheckboxOption] = useState([]);
  const [selectedDivList, setSelectedDivList] = useState([]);

  useEffect(() => {
    const tempDivCheckboxOption = generateDivisionCheckboxOption({ divList });
    setDivCheckboxOption(tempDivCheckboxOption);
  }, [divList]);

  useEffect(() => {
    const newSelectedDivList = [...divList]
      ?.map(div => div?.div)
      ?.map(div => ({
        name: div,
        value: getValues(div) || false,
      }));
    setSelectedDivList(newSelectedDivList);
  }, [watchDivList]);

  useEffect(() => {
    const newOptionList = selectedDivList
      ?.filter(div => !!div?.value)
      ?.map((div, idx) => ({ key: idx, label: div?.name, value: div?.name }));
    setDefaultDivOptionList(newOptionList);

    const isAllCheckDiv =
      divList?.length ===
      selectedDivList?.filter?.(div => div.value === true)?.length;
    setValue('ALL', isAllCheckDiv);

    const isValidDefaultDiv = newOptionList?.find(
      option => option?.value === getValues('default_div'),
    );

    if (!isValidDefaultDiv && isChangeDivision) {
      setValue('default_div', null);
    }
  }, [selectedDivList]);

  return {
    isChangeDivision,
    setIsChangeDivision,
    divCheckboxOption,
    setDivCheckboxOption,
    defaultDivOptionList,
    setDefaultDivOptionList,
    selectedDivList,
    setSelectedDivList,
  };
};

export default useControlDivisionOptions;
