import {
  GROUP_HEADER_COLUMN_LIST,
  NOT_REPLACE_HEADER_COLUMNS_LIST,
} from '../../../../constants/Dispatch/Common/gridGroupHeaders';

const generateColumnList = ({ columnList }) => {
  const resultList = [];
  const headerFieldList = GROUP_HEADER_COLUMN_LIST || [];
  const notReplaceHeaderList = NOT_REPLACE_HEADER_COLUMNS_LIST;

  columnList?.forEach(column => {
    const matchedHeader = headerFieldList.find(header =>
      column.startsWith(`${header}_`),
    );

    if (matchedHeader) {
      const isReplaceColumn = notReplaceHeaderList?.includes(matchedHeader);

      const childrenColumn = isReplaceColumn
        ? column
        : column.replace(`${matchedHeader}_`, '');

      const headerGroupInResult = resultList.find(
        item => typeof item === 'object' && item[matchedHeader],
      );

      if (!headerGroupInResult) {
        resultList.push({
          [matchedHeader]: [childrenColumn],
        });
      } else {
        headerGroupInResult[matchedHeader].push(childrenColumn);
      }
    } else {
      resultList.push(column);
    }
  });

  return resultList;
};

export default generateColumnList;
