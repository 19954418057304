import React from 'react';
import { PLUS_ICON } from '../../../../../../../../../constants/Order/Common/path';
import checkPermission from '../../../../../../../../../utils/Common/Router/Handler/checkPermission';
import {
  PERM_BC_DETAIL_EXP_EDIT,
  PERM_BC_DETAIL_IMP_EDIT,
} from '../../../../../../../../../constants/Common/permission';

const DualMoveModalContentResultRow = ({ ...props }) => {
  const woNo = document?.URL?.split('/').pop();

  const { row, apModalController } = props;

  const splitedJobScheduleDate = row?.sche_date?.split('-');

  const isAssignable = checkPermission({
    permission:
      woNo?.[1] === 'M' ? PERM_BC_DETAIL_IMP_EDIT : PERM_BC_DETAIL_EXP_EDIT,
  });

  const { setAPModalOpen, setSelectedJob } = apModalController;

  return (
    <div className="h-[30px] min-h-[30px] text-[#666] text-[12px] font-medium flex flex-row gap-[2px]">
      <div className="flex justify-center items-center h-full w-[80px] text-[#264B9F] font-bold" />
      <div className="flex justify-center items-center h-full w-[127px] bg-[#F2F4F8]">
        {`${splitedJobScheduleDate?.[1]}/${
          splitedJobScheduleDate?.[2]
        }/${splitedJobScheduleDate?.[0].slice(2)} ${row?.sche_time?.substring(
          0,
          5,
        )}`}
      </div>
      <div className="flex justify-center items-center h-full w-[48px] bg-[#F2F4F8]">
        {row?.timezone || ''}
      </div>
      <div className="flex justify-center items-center h-full w-[99px] bg-[#F2F4F8]">
        {row?.company || ''}
      </div>
      <div className="flex justify-center items-center h-full w-[126px] bg-[#F2F4F8]">
        {row?.wo_no || ''}
      </div>
      <div className="flex justify-center items-center h-full w-[126px] bg-[#F2F4F8]">
        {row?.cont_no || ''}
      </div>
      <div className="flex justify-center items-center h-full w-[68px] bg-[#F2F4F8]">
        {row?.cont_size || ''}
      </div>
      <div className="flex justify-center items-center h-full w-[59px] bg-[#F2F4F8]">
        {row?.type || ''}
      </div>
      <div className="flex justify-center items-center h-full w-[95px] bg-[#F2F4F8]">
        {row?.driver || ''}
      </div>
      <div className="flex justify-center items-center h-full w-[27px] pl-[3px]">
        {isAssignable && (
          <div
            className="cursor-pointer w-[14px] h-[14px]"
            onClick={() => {
              setAPModalOpen(prev => true);
              setSelectedJob(prev => row);
            }}
          >
            <img src={PLUS_ICON} alt="plus-icon" className="w-full h-full" />
          </div>
        )}
      </div>
    </div>
  );
};

export default DualMoveModalContentResultRow;
