import styled from 'styled-components';

const StyledTraceCommonSidebarContainer = styled.div`
  min-width: 426px;
  width: 426px;
  background-color: #fff;
  max-height: 844px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export default StyledTraceCommonSidebarContainer;
