import { PERM_FORMAT } from '../../../constants/Admin/Auth/adminAuthElements';
import formatPermission from './formatPermission';

const handleAddClicked = ({ e, postAuthMutation }) => {
  e?.setAddDisabled?.(true);

  const convertedPermissions = PERM_FORMAT;
  const name = e?.gridParam?.data?.name;
  const permissions = e?.gridParam?.data;

  Object.keys(permissions).forEach(key => {
    convertedPermissions[key] = formatPermission(key, permissions[key]);
  });

  delete convertedPermissions.name;
  delete convertedPermissions.permissions;

  postAuthMutation({
    data: { name, permissions: convertedPermissions },
    setAddDisabled: e?.setAddDisabled,
  });
};

export default handleAddClicked;
