import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import mobileAdminDefaultUrl from '../../api';

const getBillConfirmList = async ({ division }) => {
  try {
    const response = await mobileAdminDefaultUrl.get('bill-confirm', {
      params: { div: division },
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getBillConfirmList;
