import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import getBasicInfo from '../../../../services/BCDetail/BasicInfo/Get/getBasicInfo';
import bcDetailKeys from '../../keys';
import { handleNotFoundError } from '../../../../utils/Common/Handler/errorStatusSpecificHandler';

const useGetBasicInfo = ({ ...props }) => {
  const { setModalOpen, setModalMessage, setOnOkClickHandler } = props;

  const woNo =
    document.URL?.split('/')?.[document?.URL?.split('/')?.length - 1];

  // Basic Info Use Query
  const {
    data: basicInfoData,
    isLoading: basicInfoLoading,
    refetch,
    isError,
    error,
  } = useQuery({
    queryKey: bcDetailKeys.detailInfo(woNo),
    queryFn: () => getBasicInfo(woNo),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  useEffect(() => {
    if (isError) {
      setModalOpen?.(prev => true);
      setModalMessage?.(prev => error?.message);
      setOnOkClickHandler?.(prev => {
        if (
          error?.message === handleNotFoundError() ||
          error?.message === 'WO # has been deleted.'
        ) {
          return () => {
            window.close();
          };
        }
        return null;
      });
    } else {
      setModalOpen?.(prev => false);
      setModalMessage?.(prev => '');
      setOnOkClickHandler?.(prev => null);
    }
  }, [isError]);

  return {
    basicInfoData: basicInfoData?.data,
    basicInfoLoading,
    refetch,
    isError,
  };
};

export default useGetBasicInfo;
