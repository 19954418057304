import { useMutation } from '@tanstack/react-query';
import patchDocumentList from '../../../services/BCDetail/Document/Patch/patchDocumentList';
import useControlToast from '../../Common/Toast/useControlToast';
import {
  SUCCESS_DELETE,
  SUCCESS_UPDATE,
} from '../../../constants/Common/successMessage';
import updateButtonDisabled from '../../../utils/Common/Handler/updateButtonDisabled';

const usePatchDocumentList = ({
  documentController,
  delType,
  setDelType,
  setMessageModalOpen,
  setMessageContent,
  docListRefech,
}) => {
  const { addToast } = useControlToast();

  const mutator = useMutation({
    mutationFn: patchDocumentList,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: () => {
      docListRefech();
      if (delType) {
        documentController?.unregister(delType);
        setDelType(false);
        addToast({ message: `"${delType}" ${SUCCESS_DELETE}` });
      } else {
        addToast({ message: SUCCESS_UPDATE });
      }
    },
    onError: error => {
      setMessageContent(error.message);
      setMessageModalOpen(true);
    },
  });
  return mutator;
};

export default usePatchDocumentList;
