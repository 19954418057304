import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import bookingDefaultAPI from '../../bookingAPI';

const getBookingList = ({ div, paramQueries }) => {
  try {
    const response = bookingDefaultAPI.get(`list`, {
      params: { div, ...paramQueries },
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getBookingList;
