import handleQueryError from '../../../utils/Common/Handler/handleQueryError';
import cusReportDefaultAPI from '../api';

const getSettingData = async ({ div, ...params }) => {
  try {
    const response = await cusReportDefaultAPI.get(`setting`, {
      params: {
        div,
      },
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getSettingData;
