import styled from 'styled-components';

const StyledWeeklyTableColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 251px;
  width: 100%;
`;

export default StyledWeeklyTableColumnWrapper;
