import adminDefaultAPI from '../../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

const patchUserStatus = async ({ id }) => {
  try {
    const response = await adminDefaultAPI.patch(`user/${id}/activate`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchUserStatus;
