import { useQuery } from '@tanstack/react-query';
import acctKeys from '../../keys';
import { getQBAPARList } from '../../../../services/Acct/QBAPAR/Get/getQBAPARList';
import generateQueryParamObject from '../../../../utils/Acct/Common/Generator/generateQueryParamObject';
import { ACCT_PAGE } from '../../../../constants/Acct/Common/staticNames';

const useGetQBAPARList = ({ queryObject }) => {
  const queryParam = generateQueryParamObject({
    queryObject,
    page: ACCT_PAGE?.qbReconcile,
  });

  const {
    data: qbAPARList,
    isLoading: isLoadingQBAPAR,
    isFetching: isFetchingQBAPAR,
    refetch: refetchQBAPAR,
  } = useQuery({
    queryKey: acctKeys.qbAPAR({ queryParam }),
    queryFn: () => getQBAPARList({ queryParam }),
    staleTime: 0,
    refetchOnWindowFocus: false,
  });

  return {
    qbAPARList,
    isLoadingQBAPAR,
    isFetchingQBAPAR,
    refetchQBAPAR,
  };
};

export default useGetQBAPARList;
