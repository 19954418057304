import { useQuery } from '@tanstack/react-query';
import tmsReportKeys from '../keys';
import getDemurrageList from '../../../services/TmsReport/Demurrage/Get/getDemurrageList';

const useGetDemurrageList = ({ division, fromDate, toDate }) => {
  const { isLoading, data, isRefetching, isError } = useQuery({
    queryKey: tmsReportKeys?.demurrageList({ division, fromDate, toDate }),
    queryFn: () => getDemurrageList({ division, fromDate, toDate }),
    refetchOnWindowFocus: false,
  });

  return { isLoading, data: data?.data, isRefetching, isError };
};

export default useGetDemurrageList;
