import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthRouter from '../../../../../components/Common/AuthRouter';
import {
  PERM_MASTER_ACCT_CODE,
  PERM_MASTER_AP_RATE,
  PERM_MASTER_AR_RATE,
  PERM_MASTER_CHASSIS_RATE,
  PERM_MASTER_CNEE,
  PERM_MASTER_COMPANY,
  PERM_MASTER_DRIVER,
  PERM_MASTER_PRODUCT_CODE,
  PERM_MASTER_TIER_WEIGHT,
  PERM_MASTER_DRIVER_EDIT,
  PERM_MASTER_COMPANY_EDIT,
  PERM_MASTER_PRODUCT_CODE_EDIT,
} from '../../../permission';
import Driver from '../../../../../pages/Master/Driver';
import Company from '../../../../../pages/Master/Company';
import AccountCode from '../../../../../pages/Master/AccountCode';
import ServiceCode from '../../../../../pages/Master/ServiceCode';
import APRate from '../../../../../pages/Master/APRate';
import ARRate from '../../../../../pages/Master/ARRate';
import ChassisRate from '../../../../../pages/Master/ChassisRate';
import TierWeight from '../../../../../pages/Master/TierWeight';
import getAuthorizedMenu from '../../../../../utils/Common/Sidebar/Handler/getAuthorizedMenu';
import { SIDEBAR_SUB_MENU } from '../../../Option/Sidebar';
import checkPermission from '../../../../../utils/Common/Router/Handler/checkPermission';

const MASTER_CHILDREN_ROUTE = [
  {
    index: true,
    element: (
      <Navigate
        to={
          getAuthorizedMenu({
            allMenu: SIDEBAR_SUB_MENU?.[9],
          })?.[0]?.to
        }
      />
    ),
  },
  {
    path: 'driver/*',
    element: (
      <AuthRouter permission={PERM_MASTER_DRIVER}>
        <Driver
          isEditable={checkPermission({
            permission: [PERM_MASTER_DRIVER_EDIT],
          })}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'company/*',
    element: (
      <AuthRouter permission={[...PERM_MASTER_COMPANY, ...PERM_MASTER_CNEE]}>
        <Company
          editPerm={{
            compEdit: checkPermission({
              permission: [PERM_MASTER_COMPANY_EDIT],
            }),
            cneeEdit: checkPermission({
              permission: PERM_MASTER_CNEE,
            }),
          }}
          viewPerm={{
            compView: checkPermission({
              permission: PERM_MASTER_COMPANY,
            }),
          }}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'accountcode/*',
    element: (
      <AuthRouter permission={PERM_MASTER_ACCT_CODE}>
        <AccountCode />
      </AuthRouter>
    ),
  },
  {
    path: 'servicecode/*',
    element: (
      <AuthRouter permission={PERM_MASTER_PRODUCT_CODE}>
        <ServiceCode
          isEditable={checkPermission({
            permission: [PERM_MASTER_PRODUCT_CODE_EDIT],
          })}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'aprate/*',
    element: (
      <AuthRouter permission={PERM_MASTER_AP_RATE}>
        <APRate />
      </AuthRouter>
    ),
  },
  {
    path: 'arrate/*',
    element: (
      <AuthRouter permission={PERM_MASTER_AR_RATE}>
        <ARRate />
      </AuthRouter>
    ),
  },
  {
    path: 'chassisrate/*',
    element: (
      <AuthRouter permission={PERM_MASTER_CHASSIS_RATE}>
        <ChassisRate />
      </AuthRouter>
    ),
  },
  {
    path: 'tierweight/*',
    element: (
      <AuthRouter permission={PERM_MASTER_TIER_WEIGHT}>
        <TierWeight />
      </AuthRouter>
    ),
  },
];

export default MASTER_CHILDREN_ROUTE;
