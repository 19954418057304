import React from 'react';

const RollbackConfirmModalContent = ({ ...props }) => {
  return (
    <div className="w-full min-w-[300px] min-h-[61px] h-fit bg-[#F9F9F9] flex justify-center items-center flex-col">
      <div className="flex justify-center items-center flex-col px-[20px]">
        <div className="text-[#222] text-[12px] leading-normal">
          All data will be deleted and reverted to a previous state.{' '}
          <b>Proceed?</b>
        </div>
      </div>
    </div>
  );
};

export default RollbackConfirmModalContent;
