import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';
import bcDetailDefaultAPI from '../../../bcDetailAPI';

const getBookingList = async ({ woNo, size, type, ssl }) => {
  try {
    const response = await bcDetailDefaultAPI.get(`${woNo}/bk-list`, {
      params: {
        size,
        type,
        ssl,
      },
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getBookingList;
