import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthRouter from '../../../../../components/Common/AuthRouter';
import {
  SUB_ORDER_TYPE_BARECHASSIS,
  SUB_ORDER_TYPE_BROKERAGE,
  SUB_ORDER_TYPE_EXPREG,
  SUB_ORDER_TYPE_EXPSHUTTLE,
  SUB_ORDER_TYPE_IMPREG,
  SUB_ORDER_TYPE_IMPSHUTTLE,
  SUB_ORDER_TYPE_VAN,
} from '../../../../Order/Common/basic';
import {
  PERM_ORDER_BARE,
  PERM_ORDER_BROK,
  PERM_ORDER_EXP_REG_SHU,
  PERM_ORDER_IMP_REG_SHU,
  PERM_ORDER_VAN,
} from '../../../permission';
import getAuthorizedMenu from '../../../../../utils/Common/Sidebar/Handler/getAuthorizedMenu';
import { SIDEBAR_SUB_MENU } from '../../../Option/Sidebar';
import OrderWrapper from '../../../../../components/Order/OrderWrapper';

const ORDER_CHILDREN_ROUTE = [
  {
    index: true,
    element: (
      <Navigate
        to={
          getAuthorizedMenu({
            allMenu: SIDEBAR_SUB_MENU?.[0],
          })?.[0]?.to
        }
      />
    ),
  },
  {
    path: 'impreg/*',
    element: (
      <AuthRouter permission={PERM_ORDER_IMP_REG_SHU}>
        <OrderWrapper subCategory={SUB_ORDER_TYPE_IMPREG} />
      </AuthRouter>
    ),
  },
  // {
  //   path: 'impshuttle/*',
  //   element: (
  //     <AuthRouter permission={PERM_ORDER_IMP_REG_SHU}>
  //       <OrderWrapper subCategory={SUB_ORDER_TYPE_IMPSHUTTLE} />
  //     </AuthRouter>
  //   ),
  // },
  {
    path: 'expreg/*',
    element: (
      <AuthRouter permission={PERM_ORDER_EXP_REG_SHU}>
        <OrderWrapper subCategory={SUB_ORDER_TYPE_EXPREG} />
      </AuthRouter>
    ),
  },
  // {
  //   path: 'expshuttle/*',
  //   element: (
  //     <AuthRouter permission={PERM_ORDER_EXP_REG_SHU}>
  //       <OrderWrapper subCategory={SUB_ORDER_TYPE_EXPSHUTTLE} />
  //     </AuthRouter>
  //   ),
  // },
  {
    path: 'van/*',
    element: (
      <AuthRouter permission={PERM_ORDER_VAN}>
        <OrderWrapper subCategory={SUB_ORDER_TYPE_VAN} />
      </AuthRouter>
    ),
  },
  {
    path: 'brokerage/*',
    element: (
      <AuthRouter permission={PERM_ORDER_BROK}>
        <OrderWrapper subCategory={SUB_ORDER_TYPE_BROKERAGE} />
      </AuthRouter>
    ),
  },
  {
    path: 'barechassis/*',
    element: (
      <AuthRouter permission={PERM_ORDER_BARE}>
        <OrderWrapper subCategory={SUB_ORDER_TYPE_BARECHASSIS} />
      </AuthRouter>
    ),
  },
];

export default ORDER_CHILDREN_ROUTE;
