import React, { useEffect, useState } from 'react';
import { FormControlLabel, FormGroup, Switch, TextField } from '@mui/material';
import LabsSecondInputBoxWrapper from '../../../components/Labs/SecondInput/LabsSecondInputBoxWrapper';
import CommonStatusBox from '../../../components/SecondInputs/CommonStatusBox/CommonStatusBox';
import CommonOpenModal from '../../../components/SecondInputs/CommonOpenModal/CommonOpenModal';

const SecondInput = () => {
  const boxElements = [
    {
      key: 0,
      inputName: 'Plain Text',
      inputType: 'TextInput',
    },
    {
      key: 1,
      inputName: 'Select',
      inputType: 'Select',
    },
    {
      key: 4,
      inputName: 'Date',
      inputType: 'Date',
    },
    {
      key: 5,
      inputName: 'Time',
      inputType: 'Time',
    },
    {
      key: 6,
      inputName: 'AutoComplete',
      inputType: 'AutoComplete',
    },
  ];

  const [controlState, setControlState] = useState({
    required: false,
    requiredArr: [false, false, false],
    disabled: false,
    disabledArr: [false, false, false],
    borderVisible: false,
    customWidth: '',
    customHeight: '',
    maxLength: 1000,
    labelStyle: false,
  });

  const controlElement = [
    {
      key: 1,
      label: 'Required',
      onClick: () => {
        setControlState({
          ...controlState,
          required: !controlState?.required,
        });
      },
    },
    {
      key: 2,
      label: 'Disabled',
      onClick: () => {
        setControlState({
          ...controlState,
          disabled: !controlState?.disabled,
        });
      },
    },
    {
      key: 3,
      label: 'Border Visible',
      onClick: () => {
        setControlState({
          ...controlState,
          borderVisible: !controlState?.borderVisible,
        });
      },
    },
    {
      key: 4,
      label: 'Label Style',
      onClick: () => {
        setControlState({
          ...controlState,
          labelStyle: !controlState?.labelStyle,
        });
      },
    },
  ];

  useEffect(() => {
    console.log(controlState?.customWidth);
  }, [controlState?.customWidth]);

  return (
    <div className="flex flex-col flex-wrap">
      <div className="flex w-full bg-[#FFF] p-[10px] mt-[10px] border-2 border-black flex-wrap">
        <FormGroup className="flex flex-row">
          {controlElement?.map(element => (
            <FormControlLabel
              style={{ pointerEvents: 'none' }}
              key={element?.key}
              control={<Switch style={{ pointerEvents: 'auto' }} />}
              {...element}
            />
          ))}
        </FormGroup>
      </div>
      <div className="flex flex-wrap">
        {boxElements.map(element => (
          <LabsSecondInputBoxWrapper
            key={element?.key}
            {...element}
            {...controlState}
          />
        ))}
        <div className="border-black h-[406px] mt-2 ml-1.5 border-[2px] bg-white">
          <div className="w-[496px] flex justify-center items-center h-[38px] bg-[#eee] border-b-[2px] border-black font-bold">
            <p>Status Box</p>
          </div>
          <div className=" space-y-3 mt-3">
            <div className="flex justify-center ">
              <CommonStatusBox status="complete" />
            </div>
            <div className="flex justify-center ">
              <CommonStatusBox status="pending" />
            </div>
            <div className="flex justify-center ">
              <CommonStatusBox status="inTransit" />
            </div>
          </div>
          <div className="mt-3 space-y-4">
            <div className="flex justify-center ">
              <CommonStatusBox width="w-[252px]" status="complete" />
            </div>
            <div className="flex justify-center ">
              <CommonStatusBox width="w-[252px]" status="pending" />
            </div>
            <div className="flex justify-center ">
              <CommonStatusBox width="w-[252px]" status="inTransit" />
            </div>
            <div className="flex justify-center ">
              <CommonStatusBox width="w-[252px]" status="gateIn" />
            </div>
            <div className="flex justify-center ">
              <CommonStatusBox width="w-[252px]" status="gateOut" />
            </div>
          </div>
        </div>
        <div className="border-black h-[406px] mt-2 ml-6 border-[2px] bg-white">
          <div className="w-[496px] flex justify-center items-center h-[38px] bg-[#eee] border-b-[2px] border-black font-bold ">
            <p>CLICK OPEN MODAL </p>
          </div>
          <div className="flex justify-center items-center mt-3">
            <CommonOpenModal />
          </div>
          <div className="flex justify-center items-center mt-3">
            <CommonOpenModal width="w-[121px]" content="RATE CFM" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondInput;
