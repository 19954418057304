import commonDefaultAPI from '../../../Common/api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

const getWOChassisFromList = async ({ queryString }) => {
  try {
    const response = await commonDefaultAPI.get(
      `wo-ch-from-list?${queryString}`,
    );
    return response?.data;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getWOChassisFromList;
