import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SUCCESS_DELETE } from '../../../constants/Common/successMessage';
import deleteChassisRate from '../../../services/Master/ChassisRate/Delete/deleteChassisRate';
import useControlToast from '../../Common/Toast/useControlToast';
import masterKeys from '../keys';

const useDeleteChassisRateMutation = ({
  setIsMessageOpen,
  setMessage,
  div,
  setIsSuccess,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  return useMutation({
    mutationFn: deleteChassisRate,
    onSuccess: res => {
      queryClient.refetchQueries({
        queryKey: masterKeys?.chassisRateList({ div }),
      });
      addToast({
        message: SUCCESS_DELETE,
      });
    },
    onError: error => {
      queryClient.refetchQueries({
        queryKey: masterKeys?.chassisRateList({ div }),
      });
      setIsSuccess(false);
      setMessage(error.message);
      setIsMessageOpen(true);
    },
  });
};

export default useDeleteChassisRateMutation;
