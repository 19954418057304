import handlePlaceSelect from './Handler/handlePlaceSelect';

const resetPlace = ({ e, setPlace, setValue }) => {
  if (e?.target?.value === '') {
    setPlace('');
    handlePlaceSelect({
      setValue,
      address: '',
      zipcode: '',
      city: '',
      state: '',
      country: '',
      placeId: '',
      lat: '',
      lng: '',
      timezone: '',
    });
  }
};

export default resetPlace;
