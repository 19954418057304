const AR_FIELDS = [
  'ar',
  'trucking',
  'ar_fsc',
  'ar_ch',
  'ar_dr',
  'ar_pe',
  'ar_de',
];

const AP_FIELDS = ['ap', 'ap_drv', 'ap_ch', 'ap_pe', 'ap_de', 'ap_dr'];

const DOC_FIELDS = ['doc_pod', 'doc_bl', 'doc_do', 'doc_it'];

const PULL_OUT_FIELDS = [
  'po_location',
  'po_sche_datetime',
  'po_actual_in_datetime',
  'po_actual_out_datetime',
  'po_driver',
];

const DELIVERY_FIELDS = [
  'dilv_location',
  'dilv_sche_datetime',
  'dilv_actual_in_datetime',
  'dilv_actual_out_datetime',
  'dilv_driver',
];

const PICK_UP_FIELDS = [
  'pu_location',
  'pu_sche_datetime',
  'pu_actual_in_datetime',
  'pu_actual_out_datetime',
  'pu_driver',
];

const RETURN_FIELDS = [
  'rtn_location',
  'rtn_sche_datetime',
  'rtn_actual_in_datetime',
  'rtn_actual_out_datetime',
  'rtn_driver',
];

export {
  AP_FIELDS,
  AR_FIELDS,
  DELIVERY_FIELDS,
  DOC_FIELDS,
  PICK_UP_FIELDS,
  PULL_OUT_FIELDS,
  RETURN_FIELDS,
};
