import { useMutation } from '@tanstack/react-query';
import patchPerdiemReconcile from '../../../../services/Acct/PerdiemReconcile/Patch/patchPerdiemReconcile';
import { deleteForm } from '../../../../utils/Acct/Common/Handler/formListHandler';

const usePatchWarningModalPerdiem = ({
  patchFormKey,
  setInvoiceList,
  setBasicErrorMessage,
  refetchList,
}) => {
  const mutate = useMutation({
    mutationFn: patchPerdiemReconcile,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      deleteForm({ formKey: patchFormKey, setInvoiceList });
      refetchList();
    },
    onError: error => {
      setBasicErrorMessage(prev => error?.message);
    },
  });
  return { ...mutate };
};

export default usePatchWarningModalPerdiem;
