import { useQuery } from '@tanstack/react-query';
import masterKeys from '../keys';
import getChassisRateList from '../../../services/Master/ChassisRate/Get/getChassisRateList';

const useGetChassisRateList = ({ div }) => {
  const {
    isLoading: loadingChassisRateList,
    data: chassisRateList,
    isRefetching,
    isError: errorChassisRateList,
  } = useQuery({
    queryKey: masterKeys?.chassisRateList({ div }),
    queryFn: () => getChassisRateList({ div }),
    refetchOnWindowFocus: false,
  });
  return {
    loadingChassisRateList,
    chassisRateList,
    isRefetching,
    errorChassisRateList,
  };
};

export default useGetChassisRateList;
