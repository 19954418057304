const getKeyByValue = (object, value) =>
  Object.keys(object).find(key => object[key] === value);

/**
 * This function converts a standard code to text by checking if it matches any values in a data array
 * and returning the corresponding key in an object if it does not match.
 * @param standardCode - The initial value of the code that needs to be converted to text.
 * @param data - The `data` parameter is likely an array of objects that contain key-value pairs, where
 * the keys are `value` and `label` and the values are the standard codes and corresponding text
 * @param object - The `object` parameter is likely an object that contains key-value pairs, where the
 * keys are standard codes and the values are corresponding text descriptions. The function
 * `convertStandardCodeToText` takes a standard code as input and returns the corresponding text
 * description by looking up the code in the `object`
 * @returns The function `convertStandardCodeToText` returns the `standardCode` variable after checking
 * if it matches any of the `value` properties in the `data` array. If it does not match, it uses the
 * `getKeyByValue` function to find the corresponding key in the `object` and assigns it to
 * `standardCode`. Finally, it returns the updated `standardCode` variable.
 */
const convertStandardCodeToText = (standardCode, data, object) => {
  data.some(item => {
    if (item.value !== standardCode) {
      standardCode = getKeyByValue(object, standardCode);
      return true;
    }
    return false;
  });
  return standardCode;
};

export default convertStandardCodeToText;
