import React from 'react';
import ToggleWithText from '../ToggleWithText';

const FunctionsContainer = ({ isActive, div, patchFunctionsMutation }) => {
  return (
    <div className="flex ml-[45px] mt-[67px] flex-col align-center">
      <div className="text-stone-600 text-xs font-medium mb-[10px]">
        FUNCTIONS
      </div>
      <div className="flex flex-col mt-[-8px] w-40 h-[30px] bg-white rounded-[5px] border border-zinc-300 gap-[7px]">
        <ToggleWithText
          name="Tracking ID"
          inputName="trackingID"
          isActive={isActive}
          div={div}
          mutation={patchFunctionsMutation}
        />
      </div>
    </div>
  );
};

export default FunctionsContainer;
