import React, { useState, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import CustomColumnItemMenuIcon from '../CustomColumnItemMenuIcon';
import itemTypes from '../../../../constants/Common/CustomColumnModal/itemTypes';
import iconColorGenerator from '../../../../utils/Common/CustomColumnModal/Generator/iconColorGenerator';

const SelectedColumnItem = ({
  id,
  columnItem,
  findBox,
  moveBox,
  checkOrderList,
}) => {
  const originIdx = findBox(id, checkOrderList).index;

  const [selected, setSelected] = useState(false);
  const [iconColor, setIconColor] = useState('#D9D9D9');

  useEffect(() => {
    if (selected) setIconColor('#264B9F');
    else setIconColor('#D9D9D9');
  }, [selected, iconColor]);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: itemTypes.ORDERBOX,
      item: { id, originIdx, isGroup: false },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id, originIdx, moveBox],
  );

  useEffect(() => {
    setSelected(isDragging);
  }, [isDragging]);

  const [, drop] = useDrop(
    () => ({
      accept: itemTypes.ORDERBOX,
      hover({ id: draggedID, isGroup }) {
        if (draggedID !== id && !isGroup) {
          const { index: overIndex } = findBox(id, checkOrderList);
          moveBox(draggedID, overIndex, checkOrderList);
        }
      },
    }),
    [findBox, moveBox],
  );

  return (
    <div
      ref={node => drag(drop(node))}
      className={`w-[208px] h-[30px] rounded-[5px] pl-[20px] pr-[15px] mb-[5px] mt-[5px] bg-white  ${
        columnItem.checked ? 'block' : 'hidden'
      } ${
        selected
          ? 'border-[1.2px] border-[#264B9F] shadow-[0_0_4px_0_#8BBCE9]'
          : 'border-[1px] border-[#D9D9D9] shadow-none'
      }`}
    >
      <div className="h-full flex flex-row justify-between items-center">
        <span className="text-[12px] font-[600] text-[#222222]">
          {columnItem.label}
        </span>
        <div>
          <CustomColumnItemMenuIcon
            iconColor={iconColorGenerator({ selected, iconColor })}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectedColumnItem;
