import dayjs from 'dayjs';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { SUCCESS_COPY } from '../../../../constants/Common/successMessage';
import useControlToast from '../../../../hooks/Common/Toast/useControlToast';
import useGetNGLCneeList from '../../../../hooks/Trace/Get/useGetNGLCneeList';
import usePostLiveShareMutation from '../../../../hooks/Trace/Post/usePostLiveShareMutation';
import createCompanyDropdown from '../../../../utils/Trace/Generator/createCompanyDropdown';
import CommonButton from '../../../CommonButton/CommonButton';
import CommonAutoComplete from '../../../Inputs/CommonAutoComplete/CommonAutoComplete';
import CommonDate from '../../../Inputs/CommonDate/CommonDate';
import CommonHyperLink from '../../../Inputs/CommonHyperLink/CommonHyperLink';
import CommonTextInput from '../../../Inputs/CommonTextInput/CommonTextInput';
import CommonTime from '../../../Inputs/CommonTime/CommonTime';

const ModalContent = ({
  setIsErrorModalOpen,
  setErrorMessage,
  setIsSuccess,
  selectedMarker,
}) => {
  const { setValue, getValues } = useFormContext();
  const { addToast } = useControlToast();

  const { isLoadingNGLCneeList, nglCneeList } = useGetNGLCneeList();
  const { mutate: postLiveShareMutation } = usePostLiveShareMutation({
    setIsErrorModalOpen,
    setErrorMessage,
    setIsSuccess,
    setValue,
  });

  return (
    <div>
      <div className="w-[268px] text-[#868686] text-xs font-medium font-['Roboto']">
        Share a public link for others to view this asset’s <br />
        ETA and Live location on a map.
      </div>
      <div className="mt-[18px]">
        <CommonTextInput
          label="Page Description"
          inputName="description"
          placeholder="Enter Description"
          onChangeHandler={({ e, onChange }) => {
            onChange?.(e.target.value);
          }}
        />
      </div>
      <div>
        <CommonAutoComplete
          label="Destination"
          inputName="company"
          option={createCompanyDropdown(nglCneeList)}
          isLoading={isLoadingNGLCneeList}
          onChangeHandler={({ e, onChange, selectProps }) => {
            onChange?.(selectProps?.value && selectProps?.value?.split('/')[0]);
          }}
        />
      </div>
      <div className="flex place-content-between ">
        <div>
          <CommonDate
            label="Link Expiration Date"
            inputName="expire_date"
            width="w-[142px]"
            onChangeHandler={({ e, onChange }) => {
              const value = dayjs(e)?.isValid()
                ? dayjs(e)?.format('YYYY-MM-DD')
                : null;
              onChange?.(value);
            }}
          />
        </div>
        <div>
          <CommonTime
            inputName="expire_time"
            width="w-[142px]"
            onChangeHandler={({ e, onChange }) => {
              if (dayjs(e).isValid()) onChange?.(dayjs(e).format('HH:mm:ss'));
              else onChange?.(null);
            }}
          />
        </div>
      </div>
      <div className="grid justify-items-end mt-[-8px]">
        <CommonButton
          category="send"
          preventDupClick
          onClick={({ buttonRef }) => {
            postLiveShareMutation({
              data: {
                id: selectedMarker.id,
                name: selectedMarker.name,
                company: getValues('company'),
                description: getValues('description'),
                expire_date: getValues('expire_date'),
                expire_time: getValues('expire_time'),
              },
              buttonRef,
            });
          }}
        >
          Share
        </CommonButton>
      </div>
      <div>
        <CommonHyperLink
          label="Copy Link"
          inputName="liveSharingUrl"
          disabled={getValues('liveSharingUrl') === ''}
          onClickHandler={() => {
            window.open(getValues('liveSharingUrl'), '_blank');
          }}
        />
      </div>
      <div className="grid justify-items-end mt-[-8px]">
        <CommonButton
          category="upload"
          disabled={getValues('liveSharingUrl') === ''}
          onClick={() => {
            navigator.clipboard.writeText(getValues('liveSharingUrl'));
            addToast({ message: SUCCESS_COPY });
          }}
        >
          Copy Link
        </CommonButton>
      </div>
    </div>
  );
};

export default ModalContent;
