import handleQueryError from '../../../utils/Common/Handler/handleQueryError';
import ediDefaultAPI from '../api';

const accept204 = async ({ id }) => {
  try {
    const response = await ediDefaultAPI.patch(`204/${id}/accept`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default accept204;
