import { useQuery } from '@tanstack/react-query';
import getProductCodeList from '../../../../services/Admin/DivConf/Get/getProductCodeList';
import adminKeys from '../../keys';

const useGetProductCodeList = ({ div }) => {
  const { isLoading: isLoadingProductCodeList, data } = useQuery({
    queryKey: adminKeys?.productCodeList({ div }),
    queryFn: () => getProductCodeList({ div }),
    refetchOnWindowFocus: false,
  });

  return {
    isLoadingProductCodeList,
    data: data?.data,
  };
};

export default useGetProductCodeList;
