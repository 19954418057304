import updateButtonDisabled from '../../Handler/updateButtonDisabled';

const handleApplyingColumn = ({
  appliedColumnList,
  onApplyClick,
  buttonRef,
  setErrorModalOpen,
  setErrorMessage,
}) => {
  if (appliedColumnList.length === 0) {
    setErrorMessage('Please select at least one column.');
    setErrorModalOpen(true);
  } else onApplyClick({ appliedColumnList, buttonRef });

  updateButtonDisabled({ ref: buttonRef, disabled: false });
};

export default handleApplyingColumn;
