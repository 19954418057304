import documentDefaultApi from '../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

const patchDocumentList = async ({ woNo, docData }) => {
  try {
    const response = await documentDefaultApi.patch(`${woNo}`, docData);
    return response.data.doc;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchDocumentList;
