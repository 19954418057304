import updateButtonDisabled from '../../../../Common/Handler/updateButtonDisabled';
import generateScheduleUpdateItem from '../Generator/generateScheduleUpdateItem';
import handleAddScheduleConstraintCheck from './handleAddScheduleConstraintCheck';
import manipulateAddScheduleData from './manipulateAddScheduleData';

/**
 * @description
 * Handler for adding schedule
 * @param {object} data
 * Row data to be added
 * @param {object} selectedRowData
 * Selected row data
 * @param {object} patchScheduleMutator
 * Mutation utility to patch schedule
 */
const handleScheduleAdd = async ({
  jobSet,
  selectedRowData,
  patchScheduleMutator,
  getValues,
  setMessageModalOpener,
  setMessageModalMessage,
  buttonRef,
}) => {
  /** Create empty list to save update and create item */
  const updateScheduleData = [];

  /** Generate update item */
  const getScheduleUpdateItem = generateScheduleUpdateItem({
    jobSet,
    selectedRowData,
  });

  /** Push update item into list */
  updateScheduleData.push(...getScheduleUpdateItem);

  /** Get the data to be added  */
  const getScheduleAddData = getValues('add');

  /** Check constraint */
  const constraintCheckResult = handleAddScheduleConstraintCheck({
    addData: getScheduleAddData,
    setMessageModalOpener,
    setMessageModalMessage,
  });

  /** If pass constraint check */
  if (constraintCheckResult) {
    const manipulatedAddData = manipulateAddScheduleData({
      addData: getScheduleAddData,
      selectedRowData,
    });

    /** Add create item into list */
    updateScheduleData?.push({
      method: 'CREATE',
      ...manipulatedAddData,
    });

    /** Create submit data */
    const submitData = {
      schedule: [...updateScheduleData],
    };

    /** Submit data */
    patchScheduleMutator({ data: submitData, buttonRef });
  } else {
    updateButtonDisabled({ ref: buttonRef, disabled: false });
  }
};

export default handleScheduleAdd;
