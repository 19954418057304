import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SUCCESS_SAVE } from '../../../constants/Common/successMessage';
import post322 from '../../../services/EDI/Post/post322';
import useControlToast from '../../Common/Toast/useControlToast';
import ediKeys from '../keys';
import updateButtonDisabled from '../../../utils/Common/Handler/updateButtonDisabled';

const usePost322Mutation = ({
  setIsAlertOpen,
  setIsSuccessAlert,
  setAlertMessage,
  div,
  from,
  to,
  receiver,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  return useMutation({
    mutationFn: post322,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ediKeys?.get322List({ div, from, to, receiver }),
      });
      addToast({
        message: SUCCESS_SAVE,
      });
    },
    onError: error => {
      queryClient.refetchQueries({
        queryKey: ediKeys?.get322List({ div, from, to, receiver }),
      });
      setIsSuccessAlert(false);
      setAlertMessage(error.message);
      setIsAlertOpen(true);
    },
  });
};

export default usePost322Mutation;
