import { useMutation } from '@tanstack/react-query';
import sendPODMessage from '../../../services/MobileAdmin/PODCheckList/Post/sendPODMessage';
import useControlToast from '../../Common/Toast/useControlToast';
import { SUCCESS_SEND } from '../../../constants/Common/successMessage';
import updateButtonDisabled from '../../../utils/Common/Handler/updateButtonDisabled';

const usePostPODMessage = ({
  setMessageModalOpen,
  setMessageContent,
  setOpenTextMessage,
}) => {
  const { addToast } = useControlToast();

  const mutator = useMutation({
    mutationFn: sendPODMessage,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: () => {
      setOpenTextMessage(false);
      addToast({ message: SUCCESS_SEND });
    },
    onError: error => {
      setMessageContent(error?.message);
      setMessageModalOpen(true);
    },
  });

  return mutator;
};

export default usePostPODMessage;
