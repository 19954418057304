import handleQueryError from '../../../utils/Common/Handler/handleQueryError';
import orderAPI from '../api';

const postOrderForm = async ({ orderPostForm, pageCategory }) => {
  try {
    const response = await orderAPI.post(`${pageCategory}`, orderPostForm);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default postOrderForm;
