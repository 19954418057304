import styled, { css } from 'styled-components';

const StyledCommonCheckboxList = styled.div`
  background: white;
  position: absolute;
  top: 25px;
  left: 0%;
  width: fit-content;
  min-width: fit-content;
  height: fit-content;
  text-align: left;
  box-shadow: 0px 0px 4px 0px #00000040;
  visibility: hidden;
  z-index: 99;
  font-size: 11px;
  font-weight: 500;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  ${props =>
    props?.$isOpen &&
    css`
      visibility: visible;
    `};

  ${props =>
    props?.$width &&
    css`
      width: ${props?.width};
    `}

  ${props =>
    props?.$height &&
    css`
      height: ${props?.height};
    `}
`;

export default StyledCommonCheckboxList;
