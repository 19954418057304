import { useQuery } from '@tanstack/react-query';
import getJobList from '../../../services/Trace/Job/Get/getJobList';
import traceKeys from '../keys';

// category will be 'driver' or 'wo'
const useGetTraceJobList = ({ category, target }) => {
  const {
    isLoading: isLoadingJobList,
    data: traceJobList,
    isRefetching: isRefetchingJobList,
  } = useQuery({
    queryKey: traceKeys.jobList({ category, target }),
    queryFn: () => getJobList({ category, target }),
    refetchOnWindowFocus: false,
  });

  return {
    isLoadingJobList,
    traceJobList: traceJobList?.data,
    isRefetchingJobList,
  };
};

export default useGetTraceJobList;
