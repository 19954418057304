import dayjs from 'dayjs';
import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { DISPATCH_BOOKING_STATUS_ADD } from '../../../../constants/Dispatch/Export/Common/bookingModalStatus';
import { DISPATCH_BOOKING_TAB_BK_DETAIL } from '../../../../constants/Dispatch/Export/Common/bookingModalTab';
import { INIT_BOOKING_ADD } from '../../../../constants/Dispatch/Export/Common/formInit';
import usePostBookingForm from '../../../../hooks/Dispatch/Common/usePostBookingForm';
import CommonBlur from '../../../CommonBlur/CommonBlur';
import CommonTabModal from '../../../CommonTabModal/CommonTabModal';
import AddBookingDetailContent from './AddBookingDetailContent';
import BookingModalButtonContent from '../Common/BookingModalButtonContent';

const AddBookingModal = ({
  isTabModalOpen,
  setIsTabModalOpen,
  tabIndexOpen,
  setTabIndexOpen,
  setMessageModalMessage,
  setIsMessageModalOpen,
  ...props
}) => {
  const now = dayjs();

  const addBookingController = useForm({
    shouldUnregister: true,
    defaultValues: {
      ...INIT_BOOKING_ADD,
      created_date: now.format('MM/DD/YY'),
      updated_date: now.format('MM/DD/YY'),
    },
  });

  const saveButtonRef = useRef(null);

  const { mutate: mutateNewBooking } = usePostBookingForm({
    setMessageModalMessage,
    setIsMessageModalOpen,
    addBookingController,
    setIsTabModalOpen,
    setTabIndexOpen,
  });

  return (
    <CommonBlur open={isTabModalOpen}>
      <CommonTabModal
        modalOpen={isTabModalOpen}
        setModalOpen={setIsTabModalOpen}
        tabIndexOpen={tabIndexOpen}
        setTabIndexOpen={setTabIndexOpen}
        contents={[
          {
            headerName: 'Booking Detail',
            content: (
              <AddBookingDetailContent
                mutateNewBooking={mutateNewBooking}
                addBookingController={addBookingController}
                today={now.format('MM/DD/YY')}
                saveButtonRef={saveButtonRef}
                {...props}
              />
            ),
            buttonContent: (
              <BookingModalButtonContent
                tab={DISPATCH_BOOKING_TAB_BK_DETAIL}
                status={DISPATCH_BOOKING_STATUS_ADD}
                saveFormID="newBookingForm"
                saveButtonRef={saveButtonRef}
              />
            ),
          },
        ]}
      />
    </CommonBlur>
  );
};

export default AddBookingModal;
