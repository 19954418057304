import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import dispatchDefaultAPI from '../../api';

const patchUpdateRow = async ({ category, pk, pageStatus, data }) => {
  try {
    const response = await dispatchDefaultAPI.patch(`${category}/${pk}`, data, {
      params: { status: pageStatus },
    });

    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchUpdateRow;
