import { useQuery } from '@tanstack/react-query';
import getDivisionColor from '../../../../services/Dispatch2/Common/Get/getDivisionColor';
import dispatchKeys from '../../keys';

const useGetDivisionColor = () => {
  const { data, isLoading, isFetching } = useQuery({
    queryKey: dispatchKeys?.divisionColor(),
    queryFn: () => getDivisionColor(),
    refetchOnWindowFocus: false,
  });

  return {
    colors: data?.data,
    isLoadingColors: isLoading || isFetching,
  };
};

export default useGetDivisionColor;
