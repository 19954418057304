import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import getDriverList from '../../../services/DailyReport/Get/getDriverList';
import dailyReportKeys from '../keys';

const useGetDriverList = ({
  woNo,
  driver,
  page,
  setErrorMessage,
  setIsErrorModalOpen,
}) => {
  const [driverList, setDriverList] = useState([]);
  const [paramDriver, setParamDriver] = useState('');

  useEffect(() => {
    if (!driver || driver === '') {
      setParamDriver('');
    } else {
      setParamDriver(`${driver}`);
    }
  }, [driver]);

  const query = useQuery({
    queryKey: dailyReportKeys?.driverList({ woNo, driver: paramDriver, page }),
    queryFn: () => getDriverList({ woNo, driver: paramDriver, page }),
    refetchOnWindowFocus: false,
    enabled: Boolean(woNo),
  });

  useEffect(() => {
    if (query?.data?.data) {
      setDriverList(query?.data?.data);
    }
  }, [query?.data]);

  useEffect(() => {
    if (query?.isError) {
      setIsErrorModalOpen(true);
      setErrorMessage(query?.error?.message);
    }
  }, [query?.isError]);

  return { driverList, ...query };
};

export default useGetDriverList;
