import React from 'react';
import StyledLiveShareInfoWrapper from '../../../styles/LiveShare/Common/StyledLiveShareInfoWrapper';
import LiveShareInfoLogo from './LiveShareInfoLogo';
import LiveShareETA from './LiveShareETA';
import LiveShareLocation from './LiveShareLocation';
import LiveShareDetail from './LiveShareDetail';

const LiveShareInfo = ({ ...props }) => {
  return (
    <StyledLiveShareInfoWrapper>
      <LiveShareInfoLogo />
      <LiveShareETA {...props} />
      <LiveShareLocation {...props} />
      <LiveShareDetail {...props} />
    </StyledLiveShareInfoWrapper>
  );
};

export default LiveShareInfo;
