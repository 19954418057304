import { useMutation } from '@tanstack/react-query';
import { SUCCESS_SAVE } from '../../../../constants/Common/successMessage';
import postAPData from '../../../../services/BCDetail/BillingInfo/AP/Post/postAPData';
import isServerResponseValid from '../../../../utils/Common/isServerResponseValid';
import useControlToast from '../../../Common/Toast/useControlToast';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';

const usePostAPData = ({
  setIsAlertOpen,
  setIsSuccess,
  setErrorMessage,
  reset,
  setIsSubmitting,
  refetchAPData,
}) => {
  const { addToast } = useControlToast();

  const mutator = useMutation({
    mutationFn: postAPData,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: success => {
      if (isServerResponseValid(success)) {
        reset();
        reset({ add: { bill_no: '', desc: '' } });
        refetchAPData();
        addToast({
          message: SUCCESS_SAVE,
        });
        setIsSubmitting(false);
      }
    },

    onError: error => {
      refetchAPData();
      setIsSuccess(false);
      setIsAlertOpen(true);
      setErrorMessage(error?.message);
      setIsSubmitting(false);
    },
    refetchOnWindowFocus: false,
  });

  return mutator;
};

export default usePostAPData;
