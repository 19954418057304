import dayjs from 'dayjs';
import { generateDateValue } from './datagridValueGenerator';
import { CATEGORY_NAMES } from '../../../../constants/TmsReport/EarlyWarning';

const getDataGridData = ({ dataGridData }) => {
  const dataGridDataList = [];
  dataGridData?.data?.forEach(dataObject => {
    const dataGridObject = {};
    Object?.entries(dataObject)?.forEach(([key, value]) => {
      switch (key) {
        case 'category':
          dataGridObject[key] = CATEGORY_NAMES[value];
          break;
        case 'created_date':
          dataGridObject[key] = generateDateValue(value);
          break;
        case 'updated_date': {
          const dateTimeString = `${dataObject?.updated_date} ${dataObject?.time}`;
          const formattedDateTime =
            dateTimeString && dayjs(dateTimeString)?.isValid()
              ? dayjs(dateTimeString).format('MM/DD/YY HH:mm')
              : '';
          dataGridObject[key] = formattedDateTime;
          break;
        }
        default:
          dataGridObject[key] = value;
          break;
      }
    });

    dataGridDataList?.push(dataGridObject);
  });

  return dataGridDataList;
};

export default getDataGridData;
